import { StatefulEventDefinition } from '@app-store/constants/event.constants';
import { Action } from '@ngrx/store';

export interface PublishOptions<E extends StatefulEventDefinition = any, P = unknown> {
	payload?: P;
	storeKeyArgs?: E['storeKeyArgs'];
}

export enum EventsActionTypes {
	PUBLISH_EVENT = '[Publish Event] Publish a new instance of an event.',
	REGISTER_EVENT_CONSUMED = '[Register Event Consumed] Register an event as consumed by a stateful component',
	REMOVE_CONSUMER_STATE = '[Remove Consumer State] Removes a consumer state.',
	REGISTER_CONSUMER_EVENT = '[Register Consumer Event] Registers an event for a specific consumer',
}

export class PublishEvent<E extends StatefulEventDefinition = any, P = unknown> implements Action {
	readonly type = EventsActionTypes.PUBLISH_EVENT;
	constructor(public payload: {eventDefinition: E, options?: PublishOptions<E, P>}) {
		payload.eventDefinition.storeKeyArgs = payload.options?.storeKeyArgs;
	}
}

export class RegisterEventConsumed implements Action {
	readonly type = EventsActionTypes.REGISTER_EVENT_CONSUMED;
	constructor(public payload: {consumerKey: string, eventKey: string, eventId: number}) {}
}

export class RemoveConsumerState implements Action {
	readonly type = EventsActionTypes.REMOVE_CONSUMER_STATE;
	constructor(public payload: {key: string}) {}
}

export class RegisterConsumerEvent implements Action {
	readonly type = EventsActionTypes.REGISTER_CONSUMER_EVENT;
	constructor(public payload: {consumerKey: string, eventKey: string}) {}
}

export type EventsActions =
	PublishEvent
	| RegisterEventConsumed
	| RemoveConsumerState
	| RegisterConsumerEvent;
