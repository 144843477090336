import { Component, Input } from '@angular/core';
import { EnumUtil } from 'morgana';
import { EncounterApprovalStatus, ScheduleItemStatus } from '@gandalf/constants';
import { SCHEDULE_STATUS_ICON_COLORS } from '@shared/constants/schedule-status-icon-colors.constants';

@Component({
	selector: 'pms-signed-status-icon',
	templateUrl: './signed-status-icon.component.html',
	styles: [],
})
export class SignedStatusIconComponent {

	@Input() status: ScheduleItemStatus;
	@Input() encounterApprovalStatus: EncounterApprovalStatus;

	constructor() {
	}

	getTooltipText(): string {
		return this.encounterApprovalStatus ? this.encounterApprovalStatus.label : 'Not Signed';
	}

	getColorClass(): string {
		return this.encounterApprovalStatus === EncounterApprovalStatus.APPROVED
			? SCHEDULE_STATUS_ICON_COLORS.GREEN : SCHEDULE_STATUS_ICON_COLORS.YELLOW;
	}

	displayIcon(): boolean {
		return EnumUtil.equalsOneOf(this.status, ScheduleItemStatus.IN_PROGRESS, ScheduleItemStatus.COMPLETED);
	}
}
