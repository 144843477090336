// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { MasterReferenceDataResponse } from './master-reference-data-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

/** See com.rev360.pms.api.controller.patient.implantabledevice.PatientImplantableDeviceResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientImplantableDeviceResponse extends GandalfModelBase {

	@GandalfProperty()
	bodyLocation: MasterReferenceDataResponse;

	@GandalfProperty()
	comments: string;

	@GandalfProperty()
	implantableDeviceId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	installedDate: Date;

	@GandalfProperty()
	isInstalledDateEstimated: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.ImplantableDeviceStatus;

	@GandalfProperty()
	surgeon: ProviderResponse;

	@GandalfProperty()
	udi: string;

}
