import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'hexToIntColor',
})
@Injectable({
	providedIn: 'root',
})
export class HexToIntColorPipe implements PipeTransform {

	transform(value: string): any {
		if (value === null || value === undefined) {
			return value;
		}
		return Number('0x' + value.substring(1, 7));
	}

}
