import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalBase, ModalManagerService } from 'morgana';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { FieldsSettingsModel } from '@syncfusion/ej2-navigations/src/treeview/treeview-model';
import { FolderService } from '@core/folder/folder.service';
import { TreeNode } from '@shared/interfaces/treeview';

interface PatientFolderModalData {
	submitText: string;
}

@Component({
	selector: 'pms-patient-folder-modal',
	templateUrl: './patient-folder-modal.component.html',
	styles: [],
	providers: [ModalManagerService],
})
export class PatientFolderModalComponent extends ModalBase<PatientFolderModalData, string> implements OnInit {
	@ViewChild('modal')
	modal: DialogComponent;

	submitText: string;
	selectedFolderId: string;
	treeFields: FieldsSettingsModel;

	constructor(
		private folderService: FolderService,
	) {
		super();
	}

	ngOnInit() {
		this.folderService.setupFolderTree().subscribe(folders => {
			this.treeFields = folders;
			this.selectedFolderId = this.treeFields.dataSource[0].data as string;
		});
		this.submitText = this.data.submitText;
	}

	selectValue(event: TreeNode) {
		this.selectedFolderId = event.nodeData.id;
	}

	closeModal(selectedFolderId: string = null) {
		this.dynamicModalRef.close(this.modal, selectedFolderId);
	}

	submit() {
		this.dynamicModalRef.close(this.modal, this.selectedFolderId);
	}
}
