// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { BusinessPhonesResponse } from './business-phones-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.schedule.sidebardetails.PersonInsuranceDetailsResponse */
/* istanbul ignore next */
@GandalfModel
export class PersonInsuranceDetailsResponse extends GandalfModelBase {

	@GandalfProperty()
	coInsurancePct: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	effectiveDate: Date;

	@GandalfProperty({ propertyType: 'Money' })
	familyDeductible: number;

	@GandalfProperty({ propertyType: 'Money' })
	groupContactLensFittingCoPay: number;

	@GandalfProperty({ propertyType: 'Money' })
	groupGeneralCoPay: number;

	@GandalfProperty({ propertyType: 'Money' })
	groupMaterials: number;

	@GandalfProperty({ propertyType: 'Money' })
	groupMedicalExamCoPay: number;

	@GandalfProperty()
	groupNumber: string;

	@GandalfProperty({ propertyType: 'Money' })
	groupRoutineExamCoPay: number;

	@GandalfProperty()
	id: number;

	@GandalfProperty({ propertyType: 'Money' })
	individualContactLensFittingCoPay: number;

	@GandalfProperty({ propertyType: 'Money' })
	individualGeneralCoPay: number;

	@GandalfProperty({ propertyType: 'Money' })
	individualMaterials: number;

	@GandalfProperty({ propertyType: 'Money' })
	individualMedicalExamCoPay: number;

	@GandalfProperty({ propertyType: 'Money' })
	individualRoutineExamCoPay: number;

	@GandalfProperty()
	insuranceCompanyAddress: AddressResponse;

	@GandalfProperty()
	insuranceCompanyName: string;

	@GandalfProperty()
	insuranceCompanyPhones: BusinessPhonesResponse;

	@GandalfProperty()
	insuranceCompanyWebSite: string;

	@GandalfProperty()
	payerId: string;

	@GandalfProperty()
	planName: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	policyHolderDob: Date;

	@GandalfProperty()
	policyHolderName: PersonNameResponse;

	@GandalfProperty()
	policyNumber: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	practiceInsuranceCompanyStatus: constants.InsuranceCompanyStatus;

	@GandalfProperty()
	priority: ReferenceDataResponse;

	@GandalfProperty()
	rxBinNumber: string;

	@GandalfProperty()
	rxPcnNumber: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.PersonInsuranceStatus;

	@GandalfProperty()
	type: ReferenceDataResponse;

}
