<button ejs-button
		type="button"
		cssClass="e-success margin-bottom-sm"
		[disabled]="isPaymentProcessing"
		(click)="addNewNote()"
		iconCss="fa fa-plus"
		[attr.data-test-id]="'notesAddButton'">Add</button>
<ejs-grid #grid
		  [pmsGridSubmit]="isSearching"
		  [pmsGridSubmitDefaultSortOptions]="sortSettings"
		  [dataSource]="notes"
		  [allowResizing]="true"
		  [allowSorting]="true"
		  [allowSelection]="!isPaymentProcessing"
		  gridLines="Horizontal"
		  [pageSettings]="pageSettings"
		  [sortSettings]="sortSettings"
		  allowPaging="true"
		  [allowExcelExport]="true"
		  class="table-rowlink"
		  (rowSelected)="editNote($event.data)"
		  [attr.data-test-id]="'notesGrid'">
	<ng-template #toolbarTemplate let-data>
		<rev-table-action-menu [table]="grid">
		</rev-table-action-menu>
	</ng-template>
	<e-columns>
		<e-column field="createdOn" headerText="Date" [format]="dateFormat"></e-column>
		<e-column field="createdBy" headerText="Author"></e-column>
		<e-column field="currentUser" headerText="User"></e-column>
		<e-column field="text" headerText="Description" clipMode="EllipsisWithTooltip"></e-column>
	</e-columns>
</ejs-grid>
