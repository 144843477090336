import { Component, Input, ViewChild } from '@angular/core';
import { CategoryResponseForDropdown, CategoryService } from '@core/category/category.service';
import { DropdownFilter } from '@core/dropdown-filter/dropdown-filter.decorator';
import { DialogUtil, EnumUtil, PAGE_LENGTH, PAGE_LENGTH_LIST, YesNoPipe } from 'morgana';
import { _isNil } from '@core/lodash/lodash';
import { QueryTriggerService } from '@core/query-trigger/query-trigger.service';
import { CategoryEntityType, QueryTemplateCategory, QueryTriggerStatus } from '@gandalf/constants';
import { QueryTriggerSummaryResponse } from '@gandalf/model/query-trigger-summary-response';
import { QueryBuilderComponent } from '@shared/component/query/query-builder/query-builder.component';
import { ExcelQueryCellInfoEventArgs, GridComponent } from '@syncfusion/ej2-angular-grids';
import { PageSettingsModel, QueryCellInfoEventArgs, RowSelectingEventArgs, SortSettingsModel } from '@syncfusion/ej2-grids';
import { combineLatest } from 'rxjs';

@Component({
	selector: 'pms-query-trigger-dashboard',
	templateUrl: './query-trigger-dashboard.component.html',
})
export class QueryTriggerDashboardComponent {

	@Input()
	queryTemplateCategory: QueryTemplateCategory;

	@Input()
	categoryEntityType: CategoryEntityType;

	@Input()
	showLockedColumn = false;

	@Input()
	headerText: string;

	@Input()
	backButtonText: string;

	@DropdownFilter()
	shouldFilter: (options: any[]) => boolean;

	@ViewChild('grid')
	grid: GridComponent;

	@ViewChild('queryBuilder')
	queryBuilder: QueryBuilderComponent;

	isSearching = false;
	pageSettings: PageSettingsModel = {
		pageSizes: PAGE_LENGTH_LIST,
		pageSize: PAGE_LENGTH.PAGE_10,
	};
	sortSettings: SortSettingsModel = {
		columns: [
			{field: 'name', direction: 'Ascending'},
		],
	};
	triggers: QueryTriggerSummaryResponse[] = [];
	categories: CategoryResponseForDropdown[];
	triggerStatuses = QueryTriggerStatus.VALUES.values;
	filterStatus = QueryTriggerStatus.ACTIVE;
	filterCategoryName: string;

	constructor(
		private queryTriggerService: QueryTriggerService,
		private categoryService: CategoryService,
		private yesNoPipe: YesNoPipe,
	) {
	}

	loadData() {
		this.isSearching = true;
		combineLatest([
			this.queryTriggerService.findByTemplateCategory(this.queryTemplateCategory),
			this.categoryService.findActiveByEntityType(this.categoryEntityType),
		]).subscribe(([triggers, categories]) => {
			this.isSearching = false;
			this.triggers = triggers;
			this.categories = categories;
			this.filterTriggers();
		});
	}

	filterTriggers() {
		// null enum value means all option
		if (!_isNil(this.filterStatus.value)) {
			this.grid.filterByColumn('status.label', 'equal', this.filterStatus.label);
		} else {
			this.grid.clearFiltering(['status.label']);
		}

		if (!_isNil(this.filterCategoryName)) {
			this.grid.filterByColumn('categoryName', 'equal', this.filterCategoryName);
		} else {
			this.grid.clearFiltering(['categoryName']);
		}
		this.grid.goToPage(1);
	}

	isTriggerActive(trigger: QueryTriggerSummaryResponse) {
		return EnumUtil.equals(trigger.status, QueryTriggerStatus.ACTIVE);
	}

	activate(trigger: QueryTriggerSummaryResponse) {
		this.isSearching = true;
		this.queryTriggerService.activate(trigger.id).subscribe(() => this.loadData());
	}

	deactivate(trigger: QueryTriggerSummaryResponse) {
		if (trigger.isLocked) {
			const confirmDialog = DialogUtil.confirm({
				title: 'Deactivate Rule',
				content:
					`<p>Deactivating a master trigger will break Meaningful Use Stage 2 requirements. Are you sure you want to deactivate this ${trigger.name} rule?</p>`,
				okButton: {
					click: () => {
						confirmDialog.close();
						this.deactivateTrigger(trigger.id);
					},
				},
			});
		} else {
			this.deactivateTrigger(trigger.id);
		}
	}

	deactivateTrigger(triggerId: number) {
		this.isSearching = true;
		this.queryTriggerService.deactivate(triggerId).subscribe(() => this.loadData());
	}

	triggerUpdated(updatedTrigger: QueryTriggerSummaryResponse) {
		// update trigger if it is in the list already; otherwise add it.
		const index = this.triggers.findIndex(trigger => trigger.id === updatedTrigger.id);
		if (index >= 0) {
			this.triggers[index] = updatedTrigger;
		} else {
			this.triggers.push(updatedTrigger);
		}
		this.grid.refresh();
	}

	editTrigger(event: RowSelectingEventArgs) {
		// do not actually select the row: clicking on any column except the action buttons will open the query builder
		event.cancel = true;
		const trigger = event.data as QueryTriggerSummaryResponse;
		if (this.grid.getRowInfo(event.target).column['field'] !== 'action' && !trigger.isLocked) {
			this.queryBuilder.editTrigger(trigger.id);
		}
	}

	queryCellInfo(event: QueryCellInfoEventArgs) {
		const trigger = event.data as QueryTriggerSummaryResponse;
		// do not show hand icon for triggers that cannot be edited
		if (trigger.isLocked) {
			event.cell.classList.add('rowlink-skip');
		}
	}

	exportFunction(cell: ExcelQueryCellInfoEventArgs) {
		if (cell.column.field === 'isLocked') {
			cell.value = this.yesNoPipe.transform(!cell.value);
		} else {
			cell.value = cell.value.toString();
		}
		return cell;
	}
}
