import { Action } from '@ngrx/store';

export enum RoutesActionTypes {
	UPDATE_ROUTE_VALUE = '[Update Route Value] Updates the value of the persisted route within a Stateful Component',
	REMOVE_ROUTE = '[Remove Route] Removes the route associated with the Stateful Component',
}

export class UpdateRouteValue implements Action {
	readonly type = RoutesActionTypes.UPDATE_ROUTE_VALUE;
	constructor(public payload: {key: string, route: string}) {}
}

export class RemoveRoute implements Action {
	readonly type = RoutesActionTypes.REMOVE_ROUTE;
	constructor(public payload: {key: string}) {}
}

export type RoutesActions =
	UpdateRouteValue
	| RemoveRoute;
