import { Component, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import { ReferenceDataService } from '@core/reference-data/reference-data.service';
import { ReferenceDataMasterCategory } from '@gandalf/constants';
import { OptionItem } from 'morgana';

@Component({
	selector: 'pms-family-information-form',
	templateUrl: './family-information-form.component.html',
	styles: [],
})
export class FamilyInformationFormComponent implements OnInit {

	familyRoleOptions: OptionItem[];
	componentForm: UntypedFormGroup;

	constructor(
		private referenceDataService: ReferenceDataService,
		public controlContainer: ControlContainer,
	) { }

	ngOnInit() {
		this.componentForm = this.controlContainer.control as UntypedFormGroup;
		this.getFormData();
	}

	getFormData() {
		this.referenceDataService.getActiveReferenceDataByCategoryIdForDropdown(
			ReferenceDataMasterCategory.FAMILY_MEMBER_ROLE.value,
		).subscribe(familyMemberRoles => {
			this.familyRoleOptions = familyMemberRoles;
		});
	}

}
