<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-sm"
	[attr.data-test-id]="'startAppointmentModal'">
	<ng-template #header>
		<div class="dlg-template">Start Appointment</div>
	</ng-template>
	<ng-template #content>
		<form #ngForm="ngForm" [formGroup]="formGroup" class="form-horizontal" (ngSubmit)="startAppointment()">
			@if (formGroup.invalid && ngForm.submitted) {
				<gandalf-lib-validation-messages
					[form]="formGroup"
					[requestObj]="request"
					[attr.data-test-id]="'startAppointmentModalValidationMessages'">
				</gandalf-lib-validation-messages>
			}
			<div class="form-horizontal">
				<p>Please confirm the primary resource for the appointment.</p>
				<div class="form-group">
					<label class="control-label col-sm-3">Provider</label>
					<div class="col-sm-9">
						<ejs-dropdownlist
							[dataSource]="providerList"
							revDropdownlistHideInactiveUnlessSelected
							[formControl]="formGroup.controls.providerId"
							placeholder="Select Provider"
							[showClearButton]="true"
							[attr.data-test-id]="'providerDropdown'">
						</ejs-dropdownlist>
					</div>
				</div>
				<div class="form-group">
					<label class="control-label col-sm-3">Employee</label>
					<div class="col-sm-9">
						<ejs-dropdownlist
							[dataSource]="employeeList"
							revDropdownlistHideInactiveUnlessSelected
							[formControl]="formGroup.controls.employeeId"
							placeholder="Select Employee"
							[showClearButton]="true"
							[attr.data-test-id]="'employeeDropdown'">
						</ejs-dropdownlist>
					</div>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button type="button" ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'startAppointmentModalCancelButton'">Cancel</button>
		<button type="button" ejs-button [isPrimary]="true" [disabled]="isSearching" (click)="ngForm.onSubmit($event)"
				[attr.data-test-id]="'startAppointmentModalStartButton'">Start
		</button>
	</ng-template>
</ejs-dialog>
