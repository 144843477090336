import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { DynamicModalRef, EnumUtil, GRID_MULTILINE_ROW_HEIGHT, GridUtil, GridUtilService, ModalConfig, PAGE_LENGTH, PAGE_LENGTH_LIST } from 'morgana';
import { PrescriptionUtil } from '@core/prescription-util/prescription-util';
import { EyeglassPrescriptionEyeResponse } from '@gandalf/model/eyeglass-prescription-eye-response';
import { EyeglassPrescriptionListResponse } from '@gandalf/model/eyeglass-prescription-list-response';
import { RefractionResponse } from '@gandalf/model/refraction-response';
import { OpticalHistoryService } from '@shared/component/optical-history/optical-history.service';
import { DATE_FORMATS, TABLE_DATE_FORMATS } from '@shared/constants/date-format.constants';
import { TabAnimationDefaults } from '@shared/constants/tab.constants';
import { TabComponent } from '@syncfusion/ej2-angular-navigations';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { PageSettingsModel, SortSettingsModel } from '@syncfusion/ej2-grids';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { ColDef } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { PrescriptionCurrentStatus } from '@gandalf/constants';
import { ClRxHistoryResult } from '../contact-lens-history-table/contact-lens-history-table.component';

export interface OpticalHistoryModalResult {
	clRxHistoryResult?: ClRxHistoryResult;
	eyeglassRxResult?: EyeglassPrescriptionListResponse;
	refractiveTestResult?: RefractionResponse;
}

@Component({
	selector: 'pms-optical-history-modal',
	templateUrl: './optical-history-modal.component.html',
})
export class OpticalHistoryModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('tabs')
	tabs: TabComponent;

	@ViewChild('eyeglassRxHistoryAgGrid')
	eyeglassRxHistoryAgGrid: AgGridAngular;

	@ViewChild('refractionHistoryAgGrid')
	refractionHistoryAgGrid: AgGridAngular;

	@ViewChild('eyeglassRxEye')
	eyeglassRxEyeTemplate: TemplateRef<any>;

	@ViewChild('refractionEye')
	refractionEyeTemplate: TemplateRef<any>;

	@ViewChild('sphere')
	sphereTemplate: TemplateRef<any>;

	@ViewChild('cylinder')
	cylinderTemplate: TemplateRef<any>;

	@ViewChild('axis')
	axisTemplate: TemplateRef<any>;

	@ViewChild('nearAdd')
	nearAddTemplate: TemplateRef<any>;

	@ViewChild('horizontalPrism')
	horizontalPrismTemplate: TemplateRef<any>;

	@ViewChild('verticalPrism')
	verticalPrismTemplate: TemplateRef<any>;

	private eyeglassRxHistoryGridColumns: ColDef[];
	private refractionHistoryGridColumns: ColDef[];

	patientId: number;
	encounterId: number;

	showContactLensHistory = true;
	showEyeglassHistory = true;
	showRefractionHistory = true;
	focusRefractiveTestTab = false;
	viewHistoryStatusColumnFeatureOn = false;
	showDiscontinuedEyeglassRx = false;

	eyeglassHx: EyeglassPrescriptionListResponse[];
	filteredEyeglassHx: EyeglassPrescriptionListResponse[];
	refractionResponses: RefractionResponse[];
	pageSettings: PageSettingsModel = {
		pageSize: PAGE_LENGTH.PAGE_10,
		pageSizes: PAGE_LENGTH_LIST,
	};
	tableDateFormat = TABLE_DATE_FORMATS.MM_DD_YYYY;
	PrescriptionUtil = PrescriptionUtil;
	tabAnimation = TabAnimationDefaults;
	sortSettings: SortSettingsModel = {
		columns: [
			{field: 'startDate', direction: 'Descending'},
		],
	};
	filterOptions = [PrescriptionCurrentStatus.ACTIVE, PrescriptionCurrentStatus.PENDING, PrescriptionCurrentStatus.EXPIRED];
	currentFilter: PrescriptionCurrentStatus;
	eyeglassRxHistoryGridOptions = GridUtil.buildGridOptions({
		suppressRowClickSelection: true,
		rowClass: 'row-link',
		rowHeight: GRID_MULTILINE_ROW_HEIGHT,
	});
	refractionHistoryGridOptions = GridUtil.buildGridOptions({
		suppressRowClickSelection: true,
		rowClass: 'row-link',
		rowHeight: GRID_MULTILINE_ROW_HEIGHT,
	});

	constructor(
		public dynamicModalRef: DynamicModalRef,
		public modalConfig: ModalConfig,
		public opticalHistoryService: OpticalHistoryService,
		private featureService: FeatureService,
		private gridUtilService: GridUtilService,
	) {
	}

	ngOnInit() {
		this.viewHistoryStatusColumnFeatureOn = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.PATIENTS.RX.EYEGLASS.VIEW_HISTORY_STATUS_COLUMN);
		this.parseConfigData(this.modalConfig.data);
		this.getData();
	}

	parseConfigData(data) {
		this.patientId = data.patientId;
		this.encounterId = data.encounterId;
		if (data.hideContactLensHistory) {
			this.showContactLensHistory = false;
		}
		if (data.hideEyeglassHistory) {
			this.showEyeglassHistory = false;
		}
		if (data.hideRefractionHistory) {
			this.showRefractionHistory = false;
		}
		if (data.focusRefractiveTestTab) {
			this.focusRefractiveTestTab = data.focusRefractiveTestTab;
		}
	}

	getData() {
		if (this.showEyeglassHistory) {
			this.opticalHistoryService.findEyeglassPrescriptionHistoryByPatientId(this.patientId).subscribe((result) => {
				this.eyeglassHx = result;
				if (this.viewHistoryStatusColumnFeatureOn) {
					this.filterEyeglassRxTable();
				}
			});
		}
		if (this.showRefractionHistory) {
			this.opticalHistoryService.searchRefractionTests(this.encounterId).subscribe((result) => {
				this.refractionResponses = result;
			});
		}
	}

	closeModal(result?: OpticalHistoryModalResult) {
		this.dynamicModalRef.close(this.modal, result);
	}

	setTabSelection() {
		if (this.focusRefractiveTestTab) {
			this.tabs.select(this.tabs.items.length - 1);
		}
	}

	clHistorySelection(clRxHistoryResult: ClRxHistoryResult) {
		this.closeModal({clRxHistoryResult});
	}

	eyeglassHxSelected(data: EyeglassPrescriptionListResponse) {
		this.closeModal({eyeglassRxResult: data});
	}

	refractiveTestSelected(data: RefractionResponse) {
		this.closeModal({refractiveTestResult: data});
	}

	hasOd(data: RefractionResponse) {
		return this.opticalHistoryService.eyeHasEnteredValues(data.od);
	}

	hasOs(data: RefractionResponse) {
		return this.opticalHistoryService.eyeHasEnteredValues(data.os);
	}

	eyeGlassHasValues(od: EyeglassPrescriptionEyeResponse) {
		return !_isNil(od.axis) ||
		!_isNil(od.sphere) ||
		!_isNil(od.cylinder) ||
		!_isNil(od.intermediateAdd) ||
		!_isNil(od.horizontalPrism) ||
		!!(od.balanced) ||
		!_isNil(od.nearAdd) ||
		!_isNil(od.verticalPrism);
	}

	filterEyeglassRxTable() {
		if (!this.eyeglassHx) {
			return;
		}

		const filterStatuses = [
			...(_isNil(this.currentFilter?.value) ? this.filterOptions : [this.currentFilter]),
			...(this.showDiscontinuedEyeglassRx ? [PrescriptionCurrentStatus.DISCONTINUED] : []),
		];

		this.filteredEyeglassHx = this.eyeglassHx.filter(eyeglassRx => EnumUtil.equalsOneOf(eyeglassRx.eyeglassPrescriptionStatus, ...filterStatuses));
	}

	onEyeglassRxHistoryGridReady() {
		this.buildEyeglassRxHistoryGridColumns();
		this.eyeglassRxHistoryAgGrid.api.setGridOption('columnDefs', this.eyeglassRxHistoryGridColumns);
	}

	/* istanbul ignore next */
	buildEyeglassRxHistoryGridColumns() {
		this.eyeglassRxHistoryGridColumns = [
			this.gridUtilService.buildDateColumn('Date', 'startDate', DATE_FORMATS.MM_DD_YYYY, {
				width: 90,
				sort: 'desc',
			}),
			this.gridUtilService.buildDateColumn('Exp Date', 'expirationDate', DATE_FORMATS.MM_DD_YYYY, {
				width: 100,
			}),
			GridUtil.buildColumn('Status', 'eyeglassPrescriptionStatus', {
				width: 100,
			}),
			GridUtil.buildColumnWithEllipses('Used For', 'usedFor.value', {
				tooltipField: 'usedFor.value',
				minWidth: 150,
				flex: 1,
			}),
			GridUtil.buildTemplateColumn('', '', this.eyeglassRxEyeTemplate, {
				width: 50,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('Sph', '', this.sphereTemplate, {
				width: 65,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('Cyl', '', this.cylinderTemplate, {
				width: 60,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('Axis', '', this.axisTemplate, {
				width: 60,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('Near Add', '', this.nearAddTemplate, {
				width: 80,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('H Prism', '', this.horizontalPrismTemplate, {
				width: 100,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('V Prism', '', this.verticalPrismTemplate, {
				width: 100,
				sortable: false,
			}),
		];
	}

	onRefractionHistoryGridReady() {
		this.buildRefractionHistoryGridColumns();
		this.refractionHistoryAgGrid.api.setGridOption('columnDefs', this.refractionHistoryGridColumns);
	}

	/* istanbul ignore next */
	buildRefractionHistoryGridColumns() {
		this.refractionHistoryGridColumns = [
			GridUtil.buildColumnWithEllipses('Test Name', 'refractionName', {
				tooltipField: 'refractionName',
				minWidth: 150,
				flex: 1,
			}),
			GridUtil.buildTemplateColumn('', '', this.refractionEyeTemplate, {
				width: 50,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('Sph', '', this.sphereTemplate, {
				width: 65,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('Cyl', '', this.cylinderTemplate, {
				width: 60,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('Axis', '', this.axisTemplate, {
				width: 60,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('Near Add', '', this.nearAddTemplate, {
				width: 80,
				sortable: false,
			}),
		];
	}
}
