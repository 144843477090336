// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressOptionalRequest } from './address-optional-request';

/** See com.rev360.pms.api.controller.provider.externalprovider.CreateExternalProviderRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateExternalProviderRequest extends GandalfModelBase {

	@GandalfLabel('Address')
	@GandalfValidator({ notNull: { message: 'Address is required' } })
	@GandalfProperty({ isRequired: true })
	address: AddressOptionalRequest;

	@GandalfLabel('Practice Name')
	@GandalfValidator({ sizeString: { message: 'Practice Name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	altPracticeName: string;

	@GandalfLabel('API Key')
	@GandalfValidator({ sizeString: { message: 'API Key cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	apiKey: string;

	@GandalfLabel('Cell Phone')
	@GandalfValidator({ sizeString: { message: 'Cell Phone cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfValidator({ phoneNumber: { message: 'Cell Phone must contain at least 10 digits' } })
	@GandalfProperty()
	cellPhone: string;

	@GandalfLabel('Cell Phone Ext')
	@GandalfValidator({ sizeString: { message: 'Cell Phone Ext cannot be longer than 10 characters', minLength: 0, maxLength: 10 } })
	@GandalfProperty()
	cellPhoneExt: string;

	@GandalfLabel('Credentials')
	@GandalfValidator({ sizeString: { message: 'Credentials cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty()
	credentials: string;

	@GandalfLabel('DEA #')
	@GandalfValidator({ sizeString: { message: 'DEA # cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	deaNum: string;

	@GandalfLabel('Description')
	@GandalfValidator({ sizeString: { message: 'Description cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	description: string;

	@GandalfLabel('Direct Address')
	@GandalfValidator({ sizeString: { message: 'Direct Address cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfValidator({ email: { message: 'Invalid format for Direct Address' } })
	@GandalfProperty()
	directAddress: string;

	@GandalfLabel('EIN #')
	@GandalfValidator({ sizeString: { message: 'EIN # cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfProperty()
	ein: string;

	@GandalfLabel('Email')
	@GandalfValidator({ sizeString: { message: 'Email cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfValidator({ email: { message: 'Invalid email format' } })
	@GandalfProperty()
	email: string;

	@GandalfLabel('Fax')
	@GandalfValidator({ sizeString: { message: 'Fax cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfValidator({ phoneNumber: { message: 'Fax must contain at least 10 digits' } })
	@GandalfProperty()
	fax: string;

	@GandalfLabel('First Name')
	@GandalfValidator({ notNull: { message: 'First Name is required' } })
	@GandalfValidator({ sizeString: { message: 'First Name must be between 1 and 50 characters', minLength: 1, maxLength: 50 } })
	@GandalfProperty({ isRequired: true })
	firstName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	gender: constants.Gender;

	@GandalfLabel('Home Phone')
	@GandalfValidator({ sizeString: { message: 'Home Phone cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfValidator({ phoneNumber: { message: 'Home Phone must contain at least 10 digits' } })
	@GandalfProperty()
	homePhone: string;

	@GandalfLabel('Home Phone Ext')
	@GandalfValidator({ sizeString: { message: 'Home Phone Ext cannot be longer than 10 characters', minLength: 0, maxLength: 10 } })
	@GandalfProperty()
	homePhoneExt: string;

	@GandalfLabel('Last Name')
	@GandalfValidator({ notNull: { message: 'Last Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Last Name must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ isRequired: true })
	lastName: string;

	@GandalfLabel('License #')
	@GandalfValidator({ sizeString: { message: 'License # cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	licenseNum: string;

	@GandalfLabel('Nickname')
	@GandalfValidator({ sizeString: { message: 'Nickname cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	nickName: string;

	@GandalfLabel('NPI')
	@GandalfValidator({ sizeString: { message: 'NPI must be between 10 and 15 characters', minLength: 10, maxLength: 15 } })
	@GandalfProperty()
	npi: string;

	@GandalfLabel('Pager')
	@GandalfValidator({ sizeString: { message: 'Pager cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfValidator({ phoneNumber: { message: 'Pager must contain at least 10 digits' } })
	@GandalfProperty()
	pager: string;

	@GandalfLabel('Practitioner #')
	@GandalfValidator({ sizeString: { message: 'Practitioner # cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	practitionerNum: string;

	@GandalfConstantDecorator()
	@GandalfLabel('Preferred Phone')
	@GandalfProperty()
	preferredPhoneType: constants.PreferredPhoneType;

	@GandalfLabel('Provider Type')
	@GandalfValidator({ notNull: { message: 'Provider Type is required' } })
	@GandalfProperty({ isRequired: true })
	providerTypeId: number;

	@GandalfLabel('Registration #')
	@GandalfValidator({ sizeString: { message: 'Registration # cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	registrationNum: string;

	@GandalfLabel('Suffix')
	@GandalfValidator({ sizeString: { message: 'Suffix cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfProperty()
	suffix: string;

	@GandalfLabel('Title')
	@GandalfProperty()
	titleId: number;

	@GandalfLabel('UPIN')
	@GandalfValidator({ sizeString: { message: 'UPIN cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	upin: string;

	@GandalfLabel('Work Phone')
	@GandalfValidator({ sizeString: { message: 'Work Phone cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfValidator({ phoneNumber: { message: 'Work Phone must contain at least 10 digits' } })
	@GandalfProperty()
	workPhone: string;

	@GandalfLabel('Work Phone Ext')
	@GandalfValidator({ sizeString: { message: 'Work Phone Ext cannot be longer than 10 characters', minLength: 0, maxLength: 10 } })
	@GandalfProperty()
	workPhoneExt: string;

}
