import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { featureToken } from '@core/injection-tokens/feature-flag-tokens/feature-flag-tokens';
import { FormattedPersonCoverageResponse, InsuranceService } from '@core/insurance/insurance.service';
import { DynamicModalRef, ModalConfig, ModalManagerService } from 'morgana';
import { InsuranceCoveragesResponse } from '@gandalf/model/insurance-coverages-response';
import { QuickAddContactModalComponent } from '@shared/component/add-patient/quick-add-contact-modal/quick-add-contact-modal.component';
import { QuickAddFamilyMemberModalComponent } from '@shared/component/add-patient/quick-add-family-member-modal/quick-add-family-member-modal.component';
import { AddInsuranceModalComponent } from '@shared/component/insurance/add-insurance/add-insurance-modal.component';
import { FamilyMemberAddModalComponent } from '@shared/component/search-patient/family-member-add-modal/family-member-add-modal.component';
import { GridComponent, RowSelectEventArgs } from '@syncfusion/ej2-angular-grids';
import { ListViewComponent } from '@syncfusion/ej2-angular-lists';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { combineLatest } from 'rxjs';

export const PolicyHolderOptions = {
	SELF: {
		label: 'Self',
		value: 0,
	},
	CONTACTS: {
		label: 'Contacts',
		value: 1,
	},
	FAMILY_MEMBERS: {
		label: 'Family Members',
		value: 2,
	},
};

@Component({
	selector: 'pms-policy-holder-modal',
	templateUrl: './policy-holder-modal.component.html',
	styles: [],
	providers: [ModalManagerService],
})
export class PolicyHolderModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('policyGrid')
	policyGrid: GridComponent;

	@ViewChild('policyHolderListView')
	listView: ListViewComponent;

	patientId: number;
	personId: number;
	selectedIntakeInsuranceId: number;

	policyHolderOptions = [
		PolicyHolderOptions.SELF,
		PolicyHolderOptions.CONTACTS,
		PolicyHolderOptions.FAMILY_MEMBERS,
	];
	selectedPerson: FormattedPersonCoverageResponse;
	isPersonSelected = false;
	isPolicySelected = false;
	isContactsGroupSelected = false;
	isFamilyMembersGroupSelected = false;
	isSelfGroupSelected = true;
	policies: FormattedPersonCoverageResponse[] = [];
	coverages = [];
	isSearching = false;
	readonly isPerformanceFeatureOn = inject(featureToken(FEATURE_FLAGS.FEATURES.PATIENTS.PERFORMANCE.FAMILY_CONTACTS));

	contactPolicyData: FormattedPersonCoverageResponse[] = [];
	familyMemberPolicyData: FormattedPersonCoverageResponse[] = [];

	constructor(
		private insuranceService: InsuranceService,
		private modalManagerService: ModalManagerService,
		public ref: DynamicModalRef,
		public modalConfig: ModalConfig,
	) {}

	ngOnInit() {
		this.patientId = this.modalConfig.data.patientId;
		this.personId = this.modalConfig.data.personId;
		this.selectedIntakeInsuranceId = this.modalConfig.data.selectedIntakeInsuranceId;
		this.getFormData();
	}

	getFormData() {
		if (this.isPerformanceFeatureOn) {
			combineLatest([
				this.insuranceService.findContactsWithRoleByPatientId(this.patientId),
				this.insuranceService.findFamilyMembersWithRoleByPatientId(this.patientId),
			]).subscribe(([contacts, familyMembers]) => {
				this.contactPolicyData = contacts;
				this.familyMemberPolicyData = familyMembers;
			});
		} else {
			// retrieve contact policies and family member policies using patientId
			combineLatest([
				this.insuranceService.getActiveContactsInsuranceCoverages(this.patientId),
				this.insuranceService.getActiveFamilyMembersInsuranceCoverages(this.patientId),
			]).subscribe(([contactsCoverages, familyMembersInsurances]) => {
				this.contactPolicyData = contactsCoverages;
				this.familyMemberPolicyData = familyMembersInsurances;
			});
		}
	}

	closeModal(result?) {
		this.ref.close(this.modal, result);
	}

	policyHolderCategoryOptionSelected(event: any) {
		this.isPersonSelected = false;
		this.isContactsGroupSelected = false;
		this.isFamilyMembersGroupSelected = false;
		this.isSelfGroupSelected = false;
		this.selectedPerson = null;

		switch (event.data.value) {
			case PolicyHolderOptions.SELF.value:
				this.isSelfGroupSelected = true;
				this.policies = [];
				break;
			case PolicyHolderOptions.CONTACTS.value:
				this.policies = this.contactPolicyData;
				this.isContactsGroupSelected = true;
				break;
			case PolicyHolderOptions.FAMILY_MEMBERS.value:
				this.policies = this.familyMemberPolicyData;
				this.isFamilyMembersGroupSelected = true;
				break;
		}

		this.setPolicySelected(false);
		this.coverages = [];
	}

	policyHolderSelected(event: RowSelectEventArgs) {
		this.selectedPerson = event.data as FormattedPersonCoverageResponse;
		if (this.isPerformanceFeatureOn) {
			this.isSearching = true;
			this.insuranceService.findActivePersonInsurances(this.selectedPerson.personNameResponse.personId)
				.subscribe((insuranceCoveragesResponse) => {
					this.coverages = insuranceCoveragesResponse;
					this.isSearching = false;
				});
		} else {
			this.coverages = this.selectedPerson.insuranceCoveragesResponse;
		}
		this.isPersonSelected = true;
		this.setPolicySelected(false);
	}

	listviewActionCompleted() {
		this.listView.selectItem({label: PolicyHolderOptions.SELF.label, value: PolicyHolderOptions.SELF.value});
	}

	setPolicySelected(selected: boolean) {
		this.isPolicySelected = selected;
	}

	selectPolicy() {
		const selectedPolicy: any = {
			isSelfSelect: false,
			personInsuranceId: null,
		};

		if (this.policyGrid.getSelectedRecords && this.policyGrid.getSelectedRecords().length !== 0) {
			selectedPolicy.personInsuranceId = (this.policyGrid.getSelectedRecords()[0] as InsuranceCoveragesResponse).personInsuranceId;
		} else {
			selectedPolicy.isSelfSelect = true;
		}

		this.closeModal(selectedPolicy);
	}

	/* istanbul ignore next */
	quickAddFamilyMember() {
		this.modalManagerService.open(QuickAddFamilyMemberModalComponent, {
			data: {
				patientId: this.patientId,
			},
		}).onClose.subscribe((newFamilyMember) => {
			if (newFamilyMember) {
				this.refreshFamilyMembers();
			}
		});
	}

	/* istanbul ignore next */
	quickAddContact() {
		this.modalManagerService.open(QuickAddContactModalComponent, {
			data: {
				patientId: this.patientId,
			},
		}).onClose.subscribe(contact => {
			if (contact) {
				this.refreshContacts();
			}
		});
	}

	/* istanbul ignore next */
	addInsurance(isContact = false) {
		this.modalManagerService.open(AddInsuranceModalComponent, {
			data: {
				canSelectPolicyHolder: false,
				patientId: this.selectedPerson.patientId,
				personId: this.selectedPerson.personNameResponse.personId,
				selectedIntakeInsuranceId: this.selectedIntakeInsuranceId,
				patientFilePatientId: this.patientId,
			},
		}).onClose.subscribe(() => {
			if (isContact) {
				this.refreshContacts();
			} else {
				this.refreshFamilyMembers();
			}
		});
	}

	refreshContacts() {
		this.insuranceService.getActiveContactsInsuranceCoverages(this.patientId).subscribe(contactsPolicyData => {
			this.contactPolicyData = contactsPolicyData;
			this.policies = this.contactPolicyData;
		});
	}

	refreshFamilyMembers() {
		this.insuranceService.getActiveFamilyMembersInsuranceCoverages(this.patientId).subscribe(familyMemberPolicyData => {
			this.familyMemberPolicyData = familyMemberPolicyData;
			this.policies = this.familyMemberPolicyData;
		});
	}

	/* istanbul ignore next */
	openAddPatientToFamilyAccountModal() {
		this.modalManagerService.open(FamilyMemberAddModalComponent, {
			data: {
				patientId: this.patientId,
			},
		}).onClose.subscribe(familyMemberAdded => {
			if (familyMemberAdded) {
				this.refreshFamilyMembers();
			}
		});
	}
}
