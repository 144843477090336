// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.vendorsandpartners.smartflow.SmartflowPracticeSupplierResponse */
/* istanbul ignore next */
@GandalfModel
export class SmartflowPracticeSupplierResponse extends GandalfModelBase {

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	masterSupplierId: number;

	@GandalfProperty()
	name: string;

	@GandalfProperty()
	smartflowCode: string;

	@GandalfProperty()
	webSite: string;

}
