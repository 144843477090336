import { CategoryEntityType, QueryCriteriaOperator, QueryFieldDataSourceList, QueryFieldType, ReferenceDataMasterCategory } from '@gandalf/constants';

export enum QueryCriteriaOperandComponent {
	TEXT,
	NUMBER,
	NUMBER_RANGE,
	DATE,
	DATE_RANGE,
	DATE_STANDARD_RANGE,
	DATE_WITHIN,
	SELECT,
	BOOLEAN,
}

export interface QueryBuilderOperatorConfig {
	operator: QueryCriteriaOperator;
	operandComponent?: QueryCriteriaOperandComponent;
}

export interface QueryBuilderFieldConfig {
	fieldType: QueryFieldType;
	operatorConfigs: QueryBuilderOperatorConfig[];
}

export const QUERY_BUILDER_NUMBER_OPERATORS: QueryBuilderOperatorConfig[] = [
	{operator: QueryCriteriaOperator.EQUALS, operandComponent: QueryCriteriaOperandComponent.NUMBER},
	{operator: QueryCriteriaOperator.NOT_EQUALS, operandComponent: QueryCriteriaOperandComponent.NUMBER},
	{operator: QueryCriteriaOperator.GREATER, operandComponent: QueryCriteriaOperandComponent.NUMBER},
	{operator: QueryCriteriaOperator.GREATER_EQUAL, operandComponent: QueryCriteriaOperandComponent.NUMBER},
	{operator: QueryCriteriaOperator.LESS, operandComponent: QueryCriteriaOperandComponent.NUMBER},
	{operator: QueryCriteriaOperator.LESS_EQUAL, operandComponent: QueryCriteriaOperandComponent.NUMBER},
	{operator: QueryCriteriaOperator.EMPTY},
	{operator: QueryCriteriaOperator.NOT_EMPTY},
	{operator: QueryCriteriaOperator.EQUALS_ONE_OF, operandComponent: QueryCriteriaOperandComponent.TEXT},
	{operator: QueryCriteriaOperator.NOT_EQUALS_ONE_OF, operandComponent: QueryCriteriaOperandComponent.TEXT},
	{operator: QueryCriteriaOperator.BETWEEN, operandComponent: QueryCriteriaOperandComponent.NUMBER_RANGE},
	{operator: QueryCriteriaOperator.NOT_BETWEEN, operandComponent: QueryCriteriaOperandComponent.NUMBER_RANGE},
];

export const QUERY_BUILDER_FIELD_CONFIGS: QueryBuilderFieldConfig[] = [
	{
		fieldType: QueryFieldType.STRING,
		operatorConfigs: [
			{operator: QueryCriteriaOperator.EQUALS, operandComponent: QueryCriteriaOperandComponent.TEXT},
			{operator: QueryCriteriaOperator.NOT_EQUALS, operandComponent: QueryCriteriaOperandComponent.TEXT},
			{operator: QueryCriteriaOperator.EMPTY},
			{operator: QueryCriteriaOperator.NOT_EMPTY},
			{operator: QueryCriteriaOperator.EQUALS_ONE_OF, operandComponent: QueryCriteriaOperandComponent.TEXT},
			{operator: QueryCriteriaOperator.NOT_EQUALS_ONE_OF, operandComponent: QueryCriteriaOperandComponent.TEXT},
			{operator: QueryCriteriaOperator.CONTAINS, operandComponent: QueryCriteriaOperandComponent.TEXT},
			{operator: QueryCriteriaOperator.NOT_CONTAINS, operandComponent: QueryCriteriaOperandComponent.TEXT},
			{operator: QueryCriteriaOperator.STARTS_WITH, operandComponent: QueryCriteriaOperandComponent.TEXT},
		],
	},
	{
		fieldType: QueryFieldType.DATE,
		operatorConfigs: [
			{operator: QueryCriteriaOperator.EQUALS, operandComponent: QueryCriteriaOperandComponent.DATE},
			{operator: QueryCriteriaOperator.NOT_EQUALS, operandComponent: QueryCriteriaOperandComponent.DATE},
			{operator: QueryCriteriaOperator.GREATER, operandComponent: QueryCriteriaOperandComponent.DATE},
			{operator: QueryCriteriaOperator.GREATER_EQUAL, operandComponent: QueryCriteriaOperandComponent.DATE},
			{operator: QueryCriteriaOperator.LESS, operandComponent: QueryCriteriaOperandComponent.DATE},
			{operator: QueryCriteriaOperator.LESS_EQUAL, operandComponent: QueryCriteriaOperandComponent.DATE},
			{operator: QueryCriteriaOperator.EMPTY},
			{operator: QueryCriteriaOperator.NOT_EMPTY},
			{operator: QueryCriteriaOperator.EQUALS_ONE_OF, operandComponent: QueryCriteriaOperandComponent.DATE},
			{operator: QueryCriteriaOperator.NOT_EQUALS_ONE_OF, operandComponent: QueryCriteriaOperandComponent.DATE},
			{operator: QueryCriteriaOperator.BETWEEN, operandComponent: QueryCriteriaOperandComponent.DATE_RANGE},
			{operator: QueryCriteriaOperator.NOT_BETWEEN, operandComponent: QueryCriteriaOperandComponent.DATE_RANGE},
			{operator: QueryCriteriaOperator.RANGE, operandComponent: QueryCriteriaOperandComponent.DATE_STANDARD_RANGE},
			{operator: QueryCriteriaOperator.WITHIN_LAST, operandComponent: QueryCriteriaOperandComponent.DATE_WITHIN},
			{operator: QueryCriteriaOperator.WITHIN_NEXT, operandComponent: QueryCriteriaOperandComponent.DATE_WITHIN},
			{operator: QueryCriteriaOperator.NOT_WITHIN_LAST, operandComponent: QueryCriteriaOperandComponent.DATE_WITHIN},
			{operator: QueryCriteriaOperator.NOT_WITHIN_NEXT, operandComponent: QueryCriteriaOperandComponent.DATE_WITHIN},
		],
	},
	{
		fieldType: QueryFieldType.INT,
		operatorConfigs: QUERY_BUILDER_NUMBER_OPERATORS,
	},
	{
		fieldType: QueryFieldType.LONG,
		operatorConfigs: QUERY_BUILDER_NUMBER_OPERATORS,
	},
	{
		fieldType: QueryFieldType.DOUBLE,
		operatorConfigs: QUERY_BUILDER_NUMBER_OPERATORS,
	},
	{
		fieldType: QueryFieldType.SELECT,
		operatorConfigs: [
			{operator: QueryCriteriaOperator.EQUALS, operandComponent: QueryCriteriaOperandComponent.SELECT},
			{operator: QueryCriteriaOperator.NOT_EQUALS, operandComponent: QueryCriteriaOperandComponent.SELECT},
			{operator: QueryCriteriaOperator.EMPTY},
			{operator: QueryCriteriaOperator.NOT_EMPTY},
		],
	},
	{
		fieldType: QueryFieldType.BOOLEAN,
		operatorConfigs: [
			{operator: QueryCriteriaOperator.EQUALS, operandComponent: QueryCriteriaOperandComponent.BOOLEAN},
		],
	},
];

export interface QueryBuilderDataSourceConfig {
	dataSourceList: QueryFieldDataSourceList;
	categoryEntityTypes?: CategoryEntityType[];
	referenceDataCategories?: ReferenceDataMasterCategory[];
}

export const QUERY_BUILDER_DATA_SOURCE_CONFIGS: QueryBuilderDataSourceConfig[] = [
	{
		dataSourceList: QueryFieldDataSourceList.CATEGORY_TASK,
		categoryEntityTypes: [CategoryEntityType.TASK_ITEM],
	},
	{
		dataSourceList: QueryFieldDataSourceList.CATEGORY_ALLERGY,
		categoryEntityTypes: [CategoryEntityType.ALLERGY],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_PERSON_DEMO_ETHNICITY,
		referenceDataCategories: [ReferenceDataMasterCategory.PERSON_DEMO_ETHNICITY, ReferenceDataMasterCategory.PERSON_DEMO_ETHNICITY_EXTENDED],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_PERSON_DEMO_RACE,
		referenceDataCategories: [ReferenceDataMasterCategory.PERSON_DEMO_RACE, ReferenceDataMasterCategory.PERSON_DEMO_RACE_EXTENDED],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_PERSON_DEMO_CITIZENSHIP,
		referenceDataCategories: [ReferenceDataMasterCategory.PERSON_DEMO_CITIZENSHIP],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_PERSON_DEMO_RELIGION,
		referenceDataCategories: [ReferenceDataMasterCategory.PERSON_DEMO_RELIGION],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_PERSON_DEMO_MARITAL_STATUS,
		referenceDataCategories: [ReferenceDataMasterCategory.PERSON_DEMO_MARITAL_STATUS],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_PERSON_DEMO_TITLE,
		referenceDataCategories: [ReferenceDataMasterCategory.PERSON_DEMO_TITLE],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_PERSON_DEMO_LANGUAGE,
		referenceDataCategories: [ReferenceDataMasterCategory.PERSON_DEMO_LANGUAGE],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_PERSON_DOMINANT_EYE,
		referenceDataCategories: [ReferenceDataMasterCategory.PERSON_DOMINANT_EYE],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_PERSON_DOMINANT_HAND,
		referenceDataCategories: [ReferenceDataMasterCategory.PERSON_DOMINANT_HAND],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_PERSON_DEMO_SEXUAL_ORIENTATION,
		referenceDataCategories: [ReferenceDataMasterCategory.PERSON_DEMO_SEXUAL_ORIENTATION],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_PERSON_DEMO_GENDER_IDENTITY,
		referenceDataCategories: [ReferenceDataMasterCategory.PERSON_DEMO_GENDER_IDENTITY],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_PATIENT_CATEGORIES,
		referenceDataCategories: [ReferenceDataMasterCategory.PATIENT_CATEGORIES],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_PATIENT_REFERRAL_SOURCES,
		referenceDataCategories: [ReferenceDataMasterCategory.PATIENT_REFERRAL_SOURCES],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_PATIENT_REFERRAL_PROGRAMS,
		referenceDataCategories: [ReferenceDataMasterCategory.PATIENT_REFERRAL_PROGRAMS],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_PROVIDER_TYPE,
		referenceDataCategories: [ReferenceDataMasterCategory.PROVIDER_TYPE],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_PROVIDER_ROLE,
		referenceDataCategories: [ReferenceDataMasterCategory.PROVIDER_ROLE],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_INSURANCE_PRIORITY,
		referenceDataCategories: [ReferenceDataMasterCategory.INSURANCE_PRIORITY],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_INSURANCE_TYPE,
		referenceDataCategories: [ReferenceDataMasterCategory.INSURANCE_TYPE],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_MED_DISPENSE_UNIT,
		referenceDataCategories: [ReferenceDataMasterCategory.MED_DISPENSE_UNIT],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_GENERAL_MEDS,
		referenceDataCategories: [ReferenceDataMasterCategory.GENERAL_MEDS],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_INSURANCE_CLASS,
		referenceDataCategories: [ReferenceDataMasterCategory.INSURANCE_CLASS],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_EYEGLASS_RX_USED_FOR_REASONS,
		referenceDataCategories: [ReferenceDataMasterCategory.EYEGLASS_RX_USED_FOR_REASONS],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_EYEGLASS_RX_MATERIALS,
		referenceDataCategories: [ReferenceDataMasterCategory.EYEGLASS_RX_MATERIALS],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_EYEGLASS_RX_TINTS,
		referenceDataCategories: [ReferenceDataMasterCategory.EYEGLASS_RX_TINTS],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_EYEGLASS_RX_LENS_TYPES,
		referenceDataCategories: [ReferenceDataMasterCategory.EYEGLASS_RX_LENS_TYPES],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_CL_RX_ADD_DESIGNATIONS,
		referenceDataCategories: [ReferenceDataMasterCategory.CL_RX_ADD_DESIGNATIONS],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_CL_SYNERGEYES_DESIGN,
		referenceDataCategories: [ReferenceDataMasterCategory.CL_SYNERGEYES_DESIGN],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_CL_SYNERGEYES_SKIRT,
		referenceDataCategories: [ReferenceDataMasterCategory.CL_SYNERGEYES_SKIRT],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_CL_SYNERGEYES_SEGMENTSIZE,
		referenceDataCategories: [ReferenceDataMasterCategory.CL_SYNERGEYES_SEGMENTSIZE],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_CL_SYNERGEYES_ADDZONESIZE,
		referenceDataCategories: [ReferenceDataMasterCategory.CL_SYNERGEYES_ADDZONESIZE],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_LAB_ORDER_TEST_TYPE,
		referenceDataCategories: [ReferenceDataMasterCategory.LAB_ORDER_TEST_TYPE],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_LAB_ORDER_SPEC_SOURCE,
		referenceDataCategories: [ReferenceDataMasterCategory.LAB_ORDER_SPEC_SOURCE],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_LAB_ORDER_RESULT_UNIT,
		referenceDataCategories: [ReferenceDataMasterCategory.LAB_ORDER_RESULT_UNIT],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_ORDER_REMAKE_REASONS,
		referenceDataCategories: [ReferenceDataMasterCategory.ORDER_REMAKE_REASONS],
	},
	{
		dataSourceList: QueryFieldDataSourceList.REFERENCE_DATA_ORDER_RETURN_REASONS,
		referenceDataCategories: [ReferenceDataMasterCategory.ORDER_RETURN_REASONS],
	},
];
