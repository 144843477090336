import { PatientConsentsActions, PatientConsentsActionTypes } from '@app-store/patient/actions/consents.actions';
import { LegacyStatefulComponentUtilsService } from '@app-store/utils/legacy-stateful-component-utils.service';
import { PatientConsentResponse } from '@gandalf/model/patient-consent-response';

export class PatientConsentsState {
	patientConsents: PatientConsentResponse[];
}

export type PatientConsentsStateMap = Map<number, PatientConsentsState>;

export const initialState: PatientConsentsStateMap = new Map();

export function reducer(state = initialState, action: PatientConsentsActions): PatientConsentsStateMap {
	switch (action.type) {

		case PatientConsentsActionTypes.UPDATE_PATIENT_CONSENTS_PROPERTY: {
			return LegacyStatefulComponentUtilsService.updateProperty(state, PatientConsentsState, action.payload);
		}

		default: {
			return state;
		}
	}
}
