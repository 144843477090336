// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.reporting.accounting.LedgerPostingReportResponse */
/* istanbul ignore next */
@GandalfModel
export class LedgerPostingReportResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	amount: number;

	@GandalfProperty()
	details: string;

	@GandalfProperty()
	invoiceId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	invoiceServiceDate: Date;

	@GandalfProperty()
	journalDescription: string;

	@GandalfProperty()
	ledgerName: string;

	@GandalfProperty()
	patientName: PatientNameResponse;

	@GandalfProperty()
	payerName: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	postedOn: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	type: constants.LedgerPostingType;

	@GandalfProperty()
	userName: string;

}
