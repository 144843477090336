<ejs-dialog #modal
			(close)="closeModal()"
			cssClass="modal-lg"
			[attr.data-test-id]="'transferItemsModal'">
	<ng-template #header>
		<div class="dlg-template">Transfer Items</div>
	</ng-template>
	<ng-template #content>
		<form class="form-horizontal"
			  [formGroup]="formGroup"
			  (ngSubmit)="transferItems()"
			  #templateForm="ngForm">
			@if (formGroup.invalid && templateForm.submitted) {
				<gandalf-lib-validation-messages
					[form]="formGroup"
					[requestObj]="request"
					[attr.data-test-id]="'transferItemsModalValdationMessages'"></gandalf-lib-validation-messages>
			}
			<h4 class="section-title bg-default mrgn-btm">Invoice #{{ invoiceId }} for <strong>{{ patientName | patientName }}</strong></h4>
			<div class="margin-bottom-sm">
				@if (canTransferOrWriteoff()) {
					<div class="form-group">
						<label class="col-sm-2 control-label required">{{ transferTypeText() }}</label>
						<div class="col-sm-10">
							<div class="form-inline">
								@if (canTransferAndWriteoff()) {
									<span class="display-inline-block margin-right-md">
                    <pms-enum-select-button
						formControlName="transferType"
						[enumList]="transferTypes"
						[allOption]="false">
                    </pms-enum-select-button>
                  </span>
								}
								<span class="display-inline-block margin-right-sm">
                  @if (showTransferReasonsDropdown) {
					  <ejs-dropdownlist id="transferReason"
										[dataSource]="transferReasons"
										formControlName="transferReferenceId"
										placeholder="Select Transfer Reason"
										[width]="400"
										[showClearButton]="true">
                    </ejs-dropdownlist>
				  }
									@if (showWriteoffReasonsDropdown) {
										<ejs-dropdownlist id="writeoffReason"
														  [dataSource]="writeoffReasons"
														  formControlName="writeoffReferenceId"
														  placeholder="Select Write-off Reason"
														  [width]="400"
														  [showClearButton]="true">
                    </ejs-dropdownlist>
									}
                </span>
								<span class="display-inline-block">
                  <ejs-checkbox #checkbox label="Include All Items in Transfer" name="includeAllItems"
								formControlName="includeAllItems" (keydown.tab)="onTabKeydown($event, false, true)"></ejs-checkbox>
                </span>
							</div>
						</div>
					</div>
					@if (showTransferReasonsDropdown) {
						<div class="form-group">
							<label class="col-sm-2 control-label required">{{ transferToText }}</label>
							<div class="col-sm-10">
								<div class="form-inline">
									@if (!removeTransferToInsuranceOption && !removeTransferToPatientOption) {
										<span class="display-inline-block margin-right-md vertical-align-middle"
										>
                      <pms-enum-select-button
						  formControlName="payerType"
						  (click)="updatePersonInsuranceDropdown()"
						  [enumList]="payerTypes"
						  [allOption]="false">
                      </pms-enum-select-button>
                    </span>
									}
									@if (!showPayerInvoiceOptions) {
										<span class="display-inline-block">
                      @if (personInsuranceList) {
						  <ejs-dropdownlist id="insurance"
											[dataSource]="personInsuranceList"
											formControlName="personInsuranceId"
											placeholder="Select Insurance"
											[width]="400"
											[showClearButton]="true"
											(keydown.tab)="onTabKeydown($event, false, false)">
                        </ejs-dropdownlist>
					  }
                    </span>
									}
									@if (showPayerInvoiceOptions) {
										<span class="display-inline-block margin-x-xs margin-right-sm">
                      <ejs-radiobutton label="New Invoice" name="transferToNewOrExistingInvoice"
									   [value]="TRANSFER_TO_NEW_INVOICE_LABEL"
									   formControlName="transferToNewOrExistingInvoice"
									   (keydown.tab)="onTabKeydown($event, false, false)"
									   [attr.data-test-id]="'transferToNewInvoiceRadioButton'">
                      </ejs-radiobutton>
                    </span>
										@if (showExistingInvoiceOption) {
											<span class="display-inline-block margin-x-xs margin-right-sm">
                        <ejs-radiobutton label="Existing Invoice"
										 name="transferToNewOrExistingInvoice" [value]="TRANSFER_TO_EXISTING_INVOICE_LABEL"
										 formControlName="transferToNewOrExistingInvoice"
										 (keydown.tab)="onTabKeydown($event, false, false)"
										 [attr.data-test-id]="'transferToExistingInvoiceRadioButton'">
                        </ejs-radiobutton>
                      </span>
										}
										@if (showExistingInvoiceDropdown) {
											<span class="display-inline-block margin-left-sm">
                        <ejs-dropdownlist id="invoices" #invoiceDropdown
										  [dataSource]="transferToInvoiceList"
										  formControlName="transferToExistingInvoiceId"
										  placeholder="Select Invoice"
										  [width]="275"
										  (keydown.tab)="onTabKeydown($event, true, false)">
                        </ejs-dropdownlist>
                      </span>
										}
									}
								</div>
							</div>
						</div>
					}
				} @else {
					<span>You do not have permission to write-off items associated with this invoice. Contact your administrator to get permission.</span>
				}
			</div>
			<div class="margin-bottom-sm">
				<ag-grid-angular class="ag-theme-alpine"
								 pmsAgAutoFitColumns
								 #agGrid
								 [domLayout]="'autoHeight'"
								 [gridOptions]="transferItemsGridOptions"
								 [rowData]="invoiceItems"
								 (gridReady)="onGridReady($event)"
								 (cellFocused)="onCellFocus($event)"
								 (cellMouseDown)="onCellClick($event)"
								 [pagination]="false"
								 [attr.data-test-id]="'transferItemsModalItemsGrid'">
				</ag-grid-angular>
			</div>
			<div class="row">
				<div class="col-sm-12 col-md-8 col-lg-9">
					<div class="margin-bottom-sm">
					</div>
				</div>
				<div class="col-sm-12 col-md-4 col-lg-3">
					<table class="table table-bordered table-condensed text-right margin-bottom-0">
						<tbody>
						<tr class="bg-default text-uppercase">
							<td>Current Balance</td>
							<td [attr.data-test-id]="'currentBalanceAmount'">{{ totalCurrentBalance }}</td>
						</tr>
						<tr>
							<td>Amount Transferred</td>
							<td [attr.data-test-id]="'amountTransferAmount'">{{ totalAmountTransferred }}</td>
						</tr>
						</tbody>
						<tfoot>
						<tr class="bg-info text-uppercase">
							<td><strong>After Payments &amp; Transfers</strong></td>
							<td [attr.data-test-id]="'totalBalanceAfterTransferAmount'"><strong>{{ totalBalanceAfterTransfer }}</strong></td>
						</tr>
						</tfoot>
					</table>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button
			ejs-button iconCss="fa fa-times"
			(keydown.tab)="onTabKeydown($event, false, false)"
			type="button" (click)="closeModal()"
			[attr.data-test-id]="'transferItemsModalCancelButton'">Cancel
		</button>
		<button [disabled]="!canTransferOrWriteoff()"
				ejs-button [isPrimary]="true"
				type="submit"
				(click)="submitForm($event)"
				[attr.data-test-id]="'transferItemsModalSaveButton'">Save
		</button>
	</ng-template>
</ejs-dialog>

<ng-template #noOptions>
	You do not have permission to write-off items associated with this invoice. Contact your administrator to get permission.
</ng-template>

<ng-template #transferColumn let-data>
	@if (getInvoiceItemControl('transferAmount', data?.invoiceItem?.id)) {
		@if (!isAggregateRow(data)) {
			<pms-always-set-currency-input
				[revFocusInput]="isCellSelected(data?.invoiceItem?.id, 'transferAmount')"
				[highlightInputOnFocus]="true"
				(inputBlur)="onInputBlur()"
				[formControl]="getInvoiceItemControl('transferAmount', data.invoiceItem.id)">
			</pms-always-set-currency-input>
		}
	}
	@if (isAggregateRow(data)) {
		<span>{{ data.transferAmount | currency }}</span>
	}
</ng-template>

<ng-template #adjustmentsColumn let-data>
	@if (getItemByItem(data?.invoiceItem?.id)) {
		@if (!isAggregateRow(data)) {
			<div>{{ getItemByItem(data.invoiceItem.id).adjustmentTotal| currency }}</div>
		}
	}
	@if (isAggregateRow(data)) {
		<span class="text-right">{{ data.adjustmentTotal | currency }}</span>
	}
</ng-template>

<ng-template #paymentTotalColumn let-data>
	@if (getItemByItem(data?.invoiceItem?.id)) {
		@if (!isAggregateRow(data)) {
			<div>{{ getItemByItem(data.invoiceItem.id)?.invoiceItem.amountPaid | currency }}</div>
		}
	}
	@if (isAggregateRow(data)) {
		<span class="text-right">{{ data.invoiceItem?.amountPaid | currency }}</span>
	}
</ng-template>

<ng-template #balanceColumn let-data>
	@if (getItemByItem(data?.invoiceItem?.id)) {
		@if (!isAggregateRow(data)) {
			<div [class.text-danger]="getItemByItem(data.invoiceItem.id).balance < 0"> {{ getItemByItem(data.invoiceItem.id).balance | currency }}</div>
		}
	}
	@if (isAggregateRow(data)) {
		<span class="text-right">{{ data.balance | currency }}</span>
	}
</ng-template>

<ng-template #adjustmentTooltip let-data>
	@if (shouldShowTooltip(data, false)) {
		<div class="rev-custom-tooltip-content" [attr.data-test-id]="'transferItemsAdjustmentTooltip'">
			<table class="table table-bordered table-condensed margin-bottom-0">
				<thead>
				<tr>
					<th>Adjustment</th>
					<th>Details</th>
					<th class="text-right">Amount</th>
				</tr>
				</thead>
				<tbody>
					@for (adjustment of sortBy(filterAdjustments(data?.invoiceItemDetails?.itemAdjustments, false), 'id'); track adjustment) {
						<tr>
							<td [attr.data-test-id]="'transferItemsAdjustmentTooltipTypeLabel'">{{ adjustment.type }}</td>
							<td [attr.data-test-id]="'transferItemsAdjustmentTooltipDetailsLabel'">{{ adjustment.details }}</td>
							<td class="text-right" [attr.data-test-id]="'transferItemsAdjustmentTooltipAmountLabel'">{{ adjustment.amount | currency }}</td>
						</tr>
					}
				</tbody>
			</table>
		</div>
	}
</ng-template>

<ng-template #totalDiscountTooltip let-data>
	@if (shouldShowTooltip(data, true)) {
		<div class="rev-custom-tooltip-content" [attr.data-test-id]="'transferItemsDiscountTooltip'">
			<table class="table table-bordered table-condensed margin-bottom-0">
				<thead>
				<tr>
					<th>Adjustment</th>
					<th>Details</th>
					<th class="text-right">Amount</th>
				</tr>
				</thead>
				<tbody>
					@for (adjustment of sortBy(filterAdjustments(data?.invoiceItemDetails?.itemAdjustments, true), 'id'); track adjustment) {
						<tr>
							<td [attr.data-test-id]="'transferItemsDiscountTooltipTypeLabel'">{{ adjustment.type }}</td>
							<td [attr.data-test-id]="'transferItemsDiscountTooltipDetailsLabel'">{{ adjustment.details }}</td>
							<td class="text-right" [attr.data-test-id]="'transferItemsDiscountTooltipAmountLabel'">{{ adjustment.amount | currency }}</td>
						</tr>
					}
				</tbody>
			</table>
		</div>
	}
</ng-template>
