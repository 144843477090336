import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent } from '../button/button.component';

export type ButtonMenuItemId = string | number;

export class ButtonMenuItem {
	id: ButtonMenuItemId;
	text: string;
	disabled: boolean;
	isDivider: boolean;

	constructor(id: ButtonMenuItemId, text: string, disabled: boolean = false, isDivider: boolean = false) {
		this.id = id;
		this.text = text;
		this.disabled = disabled;
		this.isDivider = isDivider;
	}
}

export class ButtonMenuDivider extends ButtonMenuItem {
	constructor() {
		super(undefined, undefined, undefined, true);
	}
}

@Component({
	selector: 'rev-button-menu',
	templateUrl: './button-menu.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonMenuComponent extends ButtonComponent {

	@Input()
	buttonMenuItems: ButtonMenuItem[];

	@Output()
	menuItemClick = new EventEmitter<ButtonMenuItem>();

	constructor() {
		super();
	}

	clickMenuItem(buttonMenuItem: ButtonMenuItem) {
		this.menuItemClick.emit(buttonMenuItem);
	}
}
