// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CarePlanItemTemplateResponse } from './care-plan-item-template-response';

// @ts-ignore
import { MasterReferenceDataResponse } from './master-reference-data-response';

/** See com.rev360.pms.api.controller.admin.dataconfiguration.careplan.CarePlanItemTemplateMedicationPrescriptionResponse */
/* istanbul ignore next */
@GandalfModel
export class CarePlanItemTemplateMedicationPrescriptionResponse extends CarePlanItemTemplateResponse {

	@GandalfProperty()
	carePlanItemTemplateId: number;

	@GandalfProperty()
	description: string;

	@GandalfProperty()
	dispenseAmount: number;

	@GandalfProperty()
	dispenseUnitId: MasterReferenceDataResponse;

	@GandalfProperty()
	doNotSubstitute: boolean;

	@GandalfProperty()
	dosageAmount: number;

	@GandalfProperty()
	dosageUnitId: MasterReferenceDataResponse;

	@GandalfProperty()
	drugId: number;

	@GandalfProperty()
	drugName: string;

	@GandalfProperty()
	durationAmount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	durationUnit: constants.DurationUnit;

	@GandalfProperty()
	frequencyId: MasterReferenceDataResponse;

	@GandalfProperty()
	instructions: string;

	@GandalfProperty()
	isCheckoutTask: boolean;

	@GandalfProperty()
	numRefillsAllowed: number;

	@GandalfProperty()
	routeId: MasterReferenceDataResponse;

	@GandalfProperty()
	staffCanRefill: boolean;

}
