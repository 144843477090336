// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.assessment.UpdateEvidenceBasedInterventionRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateEvidenceBasedInterventionRequest extends GandalfModelBase {

	@GandalfLabel('Action Taken')
	@GandalfValidator({ sizeString: { message: 'Action Taken cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	actionTaken: string;

	@GandalfLabel('Feedback')
	@GandalfValidator({ sizeString: { message: 'Feedback cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	feedback: string;

	@GandalfLabel('Patient Trigger Outcome')
	@GandalfValidator({ notNull: { message: 'Patient Trigger Outcome is required' } })
	@GandalfProperty({ isRequired: true })
	patientTriggerOutcomeId: number;

}
