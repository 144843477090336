<ejs-dialog
	[attr.data-test-id]="'editFileModal'"
	#modal
	(close)="closeModal()">
	<ng-template #header>
		<div class="dlg-template">Edit File</div>
	</ng-template>
	<ng-template #content>
		<form
			#ngForm="ngForm"
			class="form-horizontal"
			[formGroup]="formGroup"
			(ngSubmit)="updateFile()">
			<div class="row">
				<div class="col-sm-12">
					@if (formGroup.invalid && ngForm.submitted) {
						<gandalf-lib-validation-messages
							[form]="formGroup"
							[requestObj]="request"
							[attr.data-test-id]="'editFileModalValidationMessages'">
						</gandalf-lib-validation-messages>
					}
					<div [attr.data-test-id]="'editFileModalFileNameGroup'" class="form-group">
						<label class="col-sm-3 control-label required">File name</label>
						<div class="col-sm-9">
							<ejs-textbox formControlName="name"></ejs-textbox>
						</div>
					</div>
					@if (additionalInfo) {
						<div [attr.data-test-id]="'editFileModalFileGroup'" class="form-group">
							<label class="col-sm-3 control-label">File</label>
							<div class="col-sm-9">
								<p class="form-control-static">{{ additionalInfo.fileType }} ({{ additionalInfo.fileSize }})</p>
							</div>
						</div>
					}
					@if (additionalInfo) {
						<div [attr.data-test-id]="'editFileModalCreatedOnGroup'" class="form-group">
							<label class="col-sm-3 control-label">Created On</label>
							<div class="col-sm-9">
								<p class="form-control-static">{{ additionalInfo.createdOn | date:dateFormat }}</p>
							</div>
						</div>
					}
					@if (additionalInfo) {
						<div [attr.data-test-id]="'editFileModalCreatedByGroup'" class="form-group">
							<label class="col-sm-3 control-label">Created By</label>
							<div class="col-sm-9">
								<p class="form-control-static">{{ additionalInfo.createdBy }}</p>
							</div>
						</div>
					}
					<div [attr.data-test-id]="'editFileModalDescriptionGroup'" class="form-group">
						<label class="col-sm-3 control-label">Description</label>
						<div class="col-sm-9">
							<ejs-textbox [multiline]="true" class="h-rows-5" formControlName="description"></ejs-textbox>
						</div>
					</div>
				</div>
			</div>

		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button [attr.data-test-id]="'editFileModalCancelButton'" ejs-button type="button" (click)="closeModal()" iconCss="fa fa-close">Cancel</button>
		<button [attr.data-test-id]="'editFileModalFileSaveButton'" ejs-button type="button" (click)="submitForm($event)" [isPrimary]="true">Save</button>
	</ng-template>

</ejs-dialog>
