import { Injectable } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { BarcodeSearchRequest } from '@gandalf/model/barcode-search-request';
import { BarcodeSearchResponse } from '@gandalf/model/barcode-search-response';
import { BarcodeGandalfService } from '@gandalf/services';
import { map } from 'rxjs/operators';

export interface FormattedBarcodeSearchResponse extends BarcodeSearchResponse {
	description: string;
}

@Injectable({
	providedIn: 'root',
})
export class BarcodeService {

	constructor(
		public barcodesGandalfService: BarcodeGandalfService,
	) {
	}

	formatBarcodeSearchResponse(barcodeResponse: BarcodeSearchResponse) {
		const formattedBarcode: FormattedBarcodeSearchResponse = barcodeResponse as FormattedBarcodeSearchResponse;
		formattedBarcode.description = barcodeResponse.manufacturerName + ' / ' + barcodeResponse.productName;

		if (!_isNil(barcodeResponse.itemDescription)) {
			formattedBarcode.description += ' / ' + barcodeResponse.itemDescription;
		}

		return formattedBarcode;
	}

	searchBarcode(request: BarcodeSearchRequest) {
		return this.barcodesGandalfService.searchBarcodes(request).pipe(
			map(responses => responses.map(response => this.formatBarcodeSearchResponse(response))),
		);
	}
}
