// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ClaimsProcessorAttributeResponse } from './claims-processor-attribute-response';

/** See com.rev360.pms.api.controller.admin.practicepreferences.InsuranceClaimProcessorResponse */
/* istanbul ignore next */
@GandalfModel
export class InsuranceClaimProcessorResponse extends GandalfModelBase {

	@GandalfArray(ClaimsProcessorAttributeResponse)
	claimsProcessorAttributes: ClaimsProcessorAttributeResponse[];

	@GandalfProperty()
	description: string;

	@GandalfProperty()
	fax: string;

	@GandalfProperty()
	informationUrl: string;

	@GandalfProperty()
	loginUrl: string;

	@GandalfProperty()
	mainPhone: string;

	@GandalfProperty()
	name: string;

	@GandalfProperty()
	processorId: number;

	@GandalfProperty()
	statementProcessorId: number;

	@GandalfProperty()
	tollFreePhone: string;

}
