import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { _isNil } from '@core/lodash/lodash';
import { DynamicModalRef, ModalConfig, ModalManagerService } from 'morgana';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { BetaSystemCode, PreferenceDefaults, PreferenceName } from '@gandalf/constants';
import { TabAnimationDefaults } from '@shared/constants/tab.constants';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
	selector: 'pms-encounter-appointment-select-modal',
	templateUrl: './encounter-appointment-select-modal.component.html',
	providers: [ModalManagerService],
	styles: [],
})
export class EncounterAppointmentSelectModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('templateForm')
	templateForm: NgForm;

	tabAnimation = TabAnimationDefaults;
	invoiceId: number;
	patientId: number;
	invoiceEncounterId: number;
	invoiceAppointmentId: number;

	constructor(
		private ref: DynamicModalRef,
		private modalConfig: ModalConfig,
		private securityManagerService: SecurityManagerService,
	) {
	}

	ngOnInit() {
		this.parseConfigData(this.modalConfig.data);
	}

	parseConfigData(data: any) {
		this.invoiceId = data.invoiceId;
		this.patientId = data.patientId;
		this.invoiceEncounterId = data.encounterId;
		this.invoiceAppointmentId = data.appointmentId;
	}

	get showAppointmentsTab() {
		const hasBetaFlag = this.securityManagerService.hasBeta(BetaSystemCode.ALLOW_INVOICE_APPOINTMENT);
		const hasPreferenceFlag = this.securityManagerService.preferenceValueIsOn(
			PreferenceName.ACCOUNTING_ALLOW_INVOICE_APPOINTMENT.value, PreferenceDefaults.ALLOW_ASSIGNING_APPOINTMENT_TO_INVOICE.value,
		);
		return _isNil(this.invoiceEncounterId) && hasBetaFlag && hasPreferenceFlag;
	}

	get showEncountersTab() {
		return _isNil(this.invoiceAppointmentId);
	}

	closeModal(updated: boolean) {
		this.ref.close(this.modal, updated);
	}
}
