// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { OpticalOrderPodOrderResponse } from './optical-order-pod-order-response';

// @ts-ignore
import { ReferenceDataSummaryResponse } from './reference-data-summary-response';

/** See com.rev360.pms.api.controller.patient.opticalsummary.OpticalOrderPodEyeglassResponse */
/* istanbul ignore next */
@GandalfModel
export class OpticalOrderPodEyeglassResponse extends OpticalOrderPodOrderResponse {

	@GandalfProperty()
	brand: string;

	@GandalfProperty()
	color: string;

	@GandalfProperty()
	comments: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	createdOn: Date;

	@GandalfProperty()
	eye: number;

	@GandalfProperty()
	instructions: string;

	@GandalfProperty()
	model: string;

	@GandalfProperty()
	onHold: boolean;

	@GandalfProperty()
	orderId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	orderType: constants.OrderType;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.OrderStatusCode;

	@GandalfProperty({ propertyType: 'LocalDate' })
	statusDate: Date;

	@GandalfProperty()
	usedFor: ReferenceDataSummaryResponse;

}
