// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { EyeglassPrescriptionEyeResponse } from './eyeglass-prescription-eye-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

// @ts-ignore
import { ReferenceDataSummaryResponse } from './reference-data-summary-response';

/** See com.rev360.pms.api.controller.prescription.EyeglassPrescriptionResponse */
/* istanbul ignore next */
@GandalfModel
export class EyeglassPrescriptionResponse extends GandalfModelBase {

	@GandalfProperty()
	arCoating: boolean;

	@GandalfProperty()
	arCoatingComment: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	authorizationDate: Date;

	@GandalfProperty()
	authorizedBy: ProviderResponse;

	@GandalfProperty()
	distancePd: number;

	@GandalfProperty()
	encounterId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	expirationDate: Date;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	instructions: string;

	@GandalfProperty()
	lensType: ReferenceDataSummaryResponse;

	@GandalfProperty()
	lensTypeComment: string;

	@GandalfProperty()
	material: ReferenceDataSummaryResponse;

	@GandalfProperty()
	materialComment: string;

	@GandalfProperty()
	nearPd: number;

	@GandalfProperty()
	od: EyeglassPrescriptionEyeResponse;

	@GandalfProperty()
	os: EyeglassPrescriptionEyeResponse;

	@GandalfProperty()
	photochromic: boolean;

	@GandalfProperty()
	photochromicComment: string;

	@GandalfProperty()
	phrEnabled: boolean;

	@GandalfProperty()
	polarized: boolean;

	@GandalfProperty()
	polarizedComment: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	prescriptionAuthorizationType: constants.PrescriptionAuthorizationType;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.PrescriptionStatus;

	@GandalfProperty({ propertyType: 'LocalDate' })
	stopDate: Date;

	@GandalfProperty()
	tint: ReferenceDataSummaryResponse;

	@GandalfProperty()
	tintComment: string;

	@GandalfProperty()
	usedFor: ReferenceDataSummaryResponse;

	@GandalfProperty()
	uvTreatment: boolean;

	@GandalfProperty()
	uvTreatmentComment: string;

}
