import { Injectable } from '@angular/core';
import { _orderBy } from '../../utils/lodash/lodash';

/**
 * Sort order options defined by lodash.
 *
 * Note that orderBy is directly imported into this file (and isn't defined in lodash.ts to discourage its
 * use; sorting should be done through this service instead).
 */
type SortOrder = 'asc' | 'desc';

@Injectable({
	providedIn: 'root',
})
export class SortingService {

	constructor() { }

	static sortBy<T>(collection: Array<T>, properties: any, sortOrder: Array<SortOrder> = []): Array<T> {
		const propertiesArray = Array.isArray(properties) ? properties : [properties];
		const caseInsensitiveAccessors = propertiesArray.map(propertyName => this.makeCaseInsensitiveAccessor(propertyName));

		return _orderBy(collection, caseInsensitiveAccessors, sortOrder) as Array<T>;
	}

	/**
	 * Given a property name or a function which accesses an object's property, return a function which accesses that
	 * property. If the return value is a string, it must always be lower-cased
	 */
	static makeCaseInsensitiveAccessor(propertyAccessor: string | ((_: any) => any)): (object: any) => any {
		if (typeof propertyAccessor === 'string') {
			// The accessor is a property name, so return a function which accesses that property
			return object => {
				const propertyValue = object[propertyAccessor];

				if (typeof propertyValue === 'string') {
					return (propertyValue).toLowerCase();
				}

				return propertyValue;
			};
		} else {
			// The accessor is a function, so return a function which lower cases the function's result
			return object => {
				const propertyValue = propertyAccessor(object);

				if (typeof propertyValue === 'string') {
					return (propertyValue).toLowerCase();
				}

				return propertyValue;
			};
		}
	}
}
