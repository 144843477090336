<ejs-dialog
		#modal
		(beforeClose)="confirmCloseModal($event)"
		zIndex="{{zIndexBehindHitPmsHtmlModal}}"
		cssClass="modal-lg"
		[attr.data-test-id]="'receivePaymentsModal'">
	<ng-template #header>
		<div class="dlg-template" [attr.data-test-id]="'receivePaymentsModalTitle'">{{paymentComponentService.paymentTitle}}</div>
	</ng-template>
	<ng-template #content>
		<pms-payment-container
				[payer]="payer"
				[paymentGroupId]="paymentGroupId"
				[isModal]="true">
		</pms-payment-container>
	</ng-template>
	<ng-template #footerTemplate>
		<pms-receive-payments-actions
				[paymentGroupId]="paymentGroupId">
		</pms-receive-payments-actions>
	</ng-template>
</ejs-dialog>
