export const URL_BASE_PATHS = {
	PMS2: '/pms2/ws',
	STRUTS: '/interop',
};

export const URL_CONSTANTS = {
	PERSON_PHOTO: '/pms/ws/file/streamPhoto.htm',
	CONSENT: `${URL_BASE_PATHS.STRUTS}/pm/patient/uploadConsent.do`,
	CONSENT_FORM: `${URL_BASE_PATHS.STRUTS}/pm/practice/uploadConsentForm.do`,
	LOGOUT: `${URL_BASE_PATHS.PMS2}/employee/security/logout`,
};

export const URL_TARGETS = {
	BLANK: '_blank',
};

export const URL_PRINT_ENDPOINTS = {
	SCHEDULE_PRINT: `${URL_BASE_PATHS.STRUTS}/pm/reports/schedule/print.do`,
	PATIENT_SUMMARIES_PRINT: `${URL_BASE_PATHS.STRUTS}/pm/patients/patientinfo.do`,
	APPOINTMENT_MULTIPLE_SUMMARY_PRINT: `${URL_BASE_PATHS.STRUTS}/pm/reports/schedule/apptsummaries.do`,
	APPOINTMENT_SUMMARY_PRINT: `${URL_BASE_PATHS.STRUTS}/pm/reports/schedule/apptsummary.do`,
	BUSINESS_AGREEMENT: 'https://help.revehr.com/hc/en-us/articles/10117692403223-Business-Associate-Agreement',
	TWELVE84_AGREEMENT_PDF: 'assets/twelve84/twelve84-service-agreement.pdf',
	SOFTWARE_AGREEMENT: 'https://help.revehr.com/hc/en-us/articles/10117583115543-Software-Services-Agreement',
	CANADA_SOFTWARE_AGREEMENT: 'https://help.revehr.com/hc/en-us/articles/10118199713431-Software-Services-Agreement-Canada',
	PRINT_LABEL: {
		BASE: 'https://localhost:48121/ApiService',
		LIST: '/Print/GetPrinterList/',
		PRINT: '/Print/PrintLabel/',
	},
	DEPOSIT_SLIP_PRINT: `${URL_BASE_PATHS.STRUTS}/pm/reports/accounting/depositslipprint.do`,
	ADDRESS_LABEL: `${URL_BASE_PATHS.STRUTS}/pm/patients/addresslabels.do`,
	ENCOUNTER_SUMMARY: `${URL_BASE_PATHS.STRUTS}/pm/reports/encounter/detail.do`,
	ENCOUNTER_QUICK_PRINT: `${URL_BASE_PATHS.STRUTS}/pm/reports/encounter/eyemart/detail.do`,
	WALMART_QUICK_PRINT: `${URL_BASE_PATHS.STRUTS}/pm/reports/encounter/walmart/detail.do`,
	AGING_REPORT: `${URL_BASE_PATHS.STRUTS}/pm/reports/accounting/agingprint.do`,
	CLINICAL_SUMMARY: `${URL_BASE_PATHS.STRUTS}/pm/patient/clinicalSummary.do`,
	PATIENT_RECORD: `${URL_BASE_PATHS.STRUTS}/pm/patient/patientRecord.do`,
	QUERY_PRINT: `${URL_BASE_PATHS.STRUTS}/pm/query/print.do`,
	QUERY_EXPORT: `${URL_BASE_PATHS.STRUTS}/pm/query/export.do`,
	QUERY_ADDRESS_LABELS: `${URL_BASE_PATHS.STRUTS}/pm/query/addresslabels.do`,
	ORDERS_PRINT: {
		EYEGLASS: `${URL_BASE_PATHS.STRUTS}/pm/reports/eyeglassorderprint.do`,
		FRAME_ONLY: `${URL_BASE_PATHS.STRUTS}/pm/reports/frameorderprint.do`,
		CONTACT_LENS: `${URL_BASE_PATHS.STRUTS}/pm/reports/clorderprint.do`,
	},
	LAB_ORDER: `${URL_BASE_PATHS.STRUTS}/pm/reports/laborderprint.do`,
	LAB_ORDER_DETAILED: `${URL_BASE_PATHS.STRUTS}/pm/reports/laborderdetailprint.do`,
	PRESCRIPTION_MED_PRINT: `${URL_BASE_PATHS.STRUTS}/pm/reports/medrxprint.do`,
	REFUND_PRINT: `${URL_BASE_PATHS.STRUTS}/pm/reports/accounting/refund.do`,
	PRESCRIPTION_EYEGLASS_PRINT: `${URL_BASE_PATHS.STRUTS}/pm/reports/eyeglassrxprint.do`,
	CONTACT_LENS_PRESCRIPTION_PRINT: `${URL_BASE_PATHS.STRUTS}/pm/reports/clrxprint.do`,
	GLAUCOMA_HISTORY_PRINT: `${URL_BASE_PATHS.STRUTS}/pm/reports/ongoingcare/glaucomacare.do`,
	REFUND_HISTORY_PRINT: `${URL_BASE_PATHS.STRUTS}/pm/reports/accounting/refund.do`,
	INTERVIEW_HISTORY_PRINT: `${URL_BASE_PATHS.STRUTS}/pm/reports/interview/detail.do`,
};

export const URL_ORDER_ENDPOINTS = {
	SEND_TO_VISION_WEB: `${URL_BASE_PATHS.STRUTS}/pm/visionweb/createOrder.do`,
};

export const URL_PATIENT_FILE_ENDPOINTS = {
	UPLOAD: `${URL_BASE_PATHS.STRUTS}/pm/patient/uploadDocs.do`,
	DOWNLOAD: `${URL_BASE_PATHS.STRUTS}/pm/patient/downloadDoc.do`,
};

export const LAB_ORDER_FILE_ENDPOINTS = {
	UPLOAD: `${URL_BASE_PATHS.STRUTS}/pm/patient/uploadLabResults.do`,
};

export const URL_PRACTICE_FILE_ENDPOINTS = {
	UPLOAD: `${URL_BASE_PATHS.STRUTS}/pm/practice/uploadDocs.do`,
	DOWNLOAD: `${URL_BASE_PATHS.STRUTS}/pm/practice/downloadDoc.do`,
	LOGO: `${URL_BASE_PATHS.STRUTS}/pm/practice/locationLogo.do`,
};

/**
 * URLs to access the File Webservice
 */
export const URL_FILE_WEBSERVICE_ENDPOINTS = {
	PATIENT_DOWNLOAD: '/file/pms/patientFile',
	PHOTO: '/file/pms/photo',
};

export const URL_OMA_FILE_ENDPOINTS = {
	UPLOAD: `${URL_BASE_PATHS.STRUTS}/devices/uploadOmaFile.do`,
};

export const URL_ACCOUNTING_ENDPOINTS = {
	PREVIEW_CLAIM: `${URL_BASE_PATHS.STRUTS}/pm/reports/claimpreview.do`,
	PRINT_CLAIM: `${URL_BASE_PATHS.STRUTS}/pm/reports/claimprint.do`,
	VIEW_DATA_FILE: `${URL_BASE_PATHS.STRUTS}/pm/reports/rawclaim.do`,
	PRINT_INVOICE: `${URL_BASE_PATHS.STRUTS}/pm/reports/invoice.do`,
	PRINT_INVOICE_SUMMARIES: `${URL_BASE_PATHS.STRUTS}/pm/reports/accounting/invsummaries.do`,
	PRINT_STATEMENT_XML: `${URL_BASE_PATHS.STRUTS}/pm/reports/statementXml.do`,
	PRINT_STATEMENT_PDF: `${URL_BASE_PATHS.STRUTS}/pm/reports/statement.do`,
	SUBMIT_CLAIM: `${URL_BASE_PATHS.STRUTS}/pm/reports/claimsubmit.do`,
	SEND_STATEMENTS: `${URL_BASE_PATHS.STRUTS}/pm/reports/sendstatements.do`,
};

export const NO_PHOTO = {
	PIXELS_150X150: '/pms/resources/images/no_photo_150x150.png',
	PIXELS_300X300: '/pms/resources/images/no_photo_300x300.png',
};

export const FILE_THUMBNAILS = {
	DOC: '/pms/resources/images/thumbnails/doc.png',
	FOLDER: '/pms/resources/images/thumbnails/folder.png',
	HTML: '/pms/resources/images/thumbnails/html.png',
	JPG: '/pms/resources/images/thumbnails/jpg.png',
	OTHER: '/pms/resources/images/thumbnails/other.png',
	PDF: '/pms/resources/images/thumbnails/pdf.png',
	TXT: '/pms/resources/images/thumbnails/txt.png',
	XLS: '/pms/resources/images/thumbnails/xls.png',
};
