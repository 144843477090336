import { Directive, ElementRef, OnInit, Optional } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Directive({
	/* eslint-disable-next-line @angular-eslint/directive-selector */
	selector: 'form:not([noPmsValidationScroll])',
})
export class ValidationScrollDirective implements OnInit {


	constructor(
		private elementRef: ElementRef,
		@Optional() private controlContainer: ControlContainer,
	) {
	}

	/* istanbul ignore next */
	ngOnInit() {
		if (this.controlContainer) {
			this.subscribeToSubmit();
		}
	}

	/* istanbul ignore next */
	subscribeToSubmit() {
		(this.controlContainer as FormGroupDirective).ngSubmit.subscribe(() => {
			if ((this.controlContainer as FormGroupDirective).invalid) {
				// needed to ensure that the validation component is on the DOM before attempting to find it with a query selector
				setTimeout(() => {
					this.scrollToValidationMessages();
				});
			}
		});
	}

	scrollToValidationMessages() {
		const validationElement = this.elementRef.nativeElement.querySelector('.validationMessages');
		if (validationElement) {
			validationElement.scrollIntoView({
				behavior: 'smooth',
				block: 'end',
			});
		}
	}
}
