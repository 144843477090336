import { State as AppState } from '@app-store/reducers';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as practiceConsentsReducer from '@practice-store/reducers/consents.reducer';

export interface State extends AppState {
	practice: PracticeState;
}

export interface PracticeState {
	consents: practiceConsentsReducer.PracticeConsentsState;
}

export const reducers: ActionReducerMap<PracticeState> = {
	consents: practiceConsentsReducer.reducer,
};

export const selectPracticeState = createFeatureSelector<PracticeState>('practice');
