import { Injectable } from '@angular/core';
import { _max, _mean, _min, _sum } from '@core/lodash/lodash';

export interface PerformanceTime {
	label: string;
	durations: number[];
}

@Injectable({
	providedIn: 'root',
})
export class PerformanceService {

	methodTimes: PerformanceTime[] = [];
	uiTimes: PerformanceTime[] = [];

	constructor(
	) {
	}

	/**
	 * Adds a time entry related to running a method
	 * @param label The label for the method being executed
	 * @param startTimeMs The time the method started executing in ms from epoch
	 * @param endTimeMs The time the method finished executing in ms from epoch
	 */
	addMethodTime(label: string, startTimeMs: number, endTimeMs: number) {
		this.addPerformanceTime(this.methodTimes, label, endTimeMs - startTimeMs);
	}

	/**
	 * Adds a time entry related to UI loading
	 * @param label The label for the UI process being executed
	 * @param startTimeMs The time the process started executing in ms from epoch
	 * @param endTimeMs The time the process finished executing in ms from epoch
	 */
	addUiTime(label: string, startTimeMs: number, endTimeMs: number) {
		this.addPerformanceTime(this.uiTimes, label, endTimeMs - startTimeMs);
	}

	/**
	 * Adds a time entry to a performance time array
	 * @param times The current set of times to add this new entry to
	 * @param label The label for the UI process being executed
	 * @param duration The duration of the process in ms
	 */
	private addPerformanceTime(times: PerformanceTime[], label: string, duration: number) {
		let performanceTime: PerformanceTime = times.find(time => time.label === label);
		if (!performanceTime) {
			performanceTime = {label, durations: []} as PerformanceTime;
			times.push(performanceTime);
		}
		performanceTime.durations.push(duration);
	}

	/**
	 * Reports on the method times that have been collected.
	 * To be called from the console as: window.performanceService.computeMethodTimes();
	 */
	computeMethodTimes() {
		this.computePerformanceTimes(this.methodTimes);
	}

	/**
	 * Reports on the ui times that have been collected.
	 * To be called from the console as: window.performanceService.computeUiTimes();
	 */
	computeUiTimes() {
		this.computePerformanceTimes(this.uiTimes);
	}

	private computePerformanceTimes(times: PerformanceTime[]) {
		times.forEach(time => {
			const min = this.round(_min(time.durations), 4);
			const max = this.round(_max(time.durations), 4);
			const mean = this.round(_mean(time.durations), 4);
			const sum = this.round(_sum(time.durations), 4);
			/* eslint-disable-next-line no-console */
			console.log(`${time.label}: count=${time.durations.length} min=${min}ms max=${max}ms avg=${mean}ms total=${sum}ms`);
		});
	}

	/**
	 * Clears all performance times that have been collected.
	 * To be called from the console as: window.performanceService.clearPerformanceTimes();
	 */
	clearPerformanceTimes() {
		this.clearMethodTimes();
		this.clearUiTimes();
	}

	/**
	 * Clears all method times that have been collected.
	 * To be called from the console as: window.performanceService.clearMethodTimes();
	 */
	clearMethodTimes() {
		this.methodTimes = [];
	}

	/**
	 * Clears all UI times that have been collected.
	 * To be called from the console as: window.performanceService.clearUiTimes();
	 */
	clearUiTimes() {
		this.uiTimes = [];
	}

	/**
	 * Rounds a number to the specified precision
	 */
	private round(value: number, precision: number) {
		const multiplier = Math.pow(10, precision);
		return Math.round(value * multiplier) / multiplier;
	}

}
