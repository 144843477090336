// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.shared.person.PersonGeneralHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class PersonGeneralHistoryResponse extends GandalfModelBase {

	@GandalfProperty()
	drugAllergyComment: string;

	@GandalfProperty()
	insurance: string;

	@GandalfProperty()
	medications: string;

	@GandalfProperty()
	noKnownDrugAllergies: boolean;

	@GandalfProperty()
	noLatexSensitivity: boolean;

	@GandalfProperty()
	noOtherKnownAllergies: boolean;

	@GandalfProperty()
	otherAllergyComment: string;

	@GandalfProperty()
	personId: number;

}
