// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FieldValueRequest } from './field-value-request';

/** See com.rev360.pms.api.controller.shared.field.FieldValueDateRequest */
/* istanbul ignore next */
@GandalfModel
export class FieldValueDateRequest extends FieldValueRequest {

	@GandalfProperty()
	fieldId: number;

	@GandalfProperty()
	value: Date;

}
