// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { QueryCriteriaFieldResponse } from './query-criteria-field-response';

// @ts-ignore
import { QuerySelectFieldResponse } from './query-select-field-response';

// @ts-ignore
import { QuerySortFieldResponse } from './query-sort-field-response';

/** See com.rev360.pms.api.controller.query.QueryResponse */
/* istanbul ignore next */
@GandalfModel
export class QueryResponse extends GandalfModelBase {

	@GandalfProperty()
	allLocked: boolean;

	@GandalfProperty()
	allowAddressLabels: boolean;

	@GandalfProperty()
	categoryId: number;

	@GandalfArray(QueryCriteriaFieldResponse)
	criteriaFields: QueryCriteriaFieldResponse[];

	@GandalfProperty()
	criteriaLocked: boolean;

	@GandalfProperty()
	description: string;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	name: string;

	@GandalfArray(Number)
	roleIds: number[];

	@GandalfArray(QuerySelectFieldResponse)
	selectFields: QuerySelectFieldResponse[];

	@GandalfArray(QuerySortFieldResponse)
	sortFields: QuerySortFieldResponse[];

	@GandalfConstantDecorator()
	@GandalfProperty()
	templateCategory: constants.QueryTemplateCategory;

	@GandalfConstantDecorator()
	@GandalfProperty()
	type: constants.QueryType;

}
