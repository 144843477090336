// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { MasterReferenceDataResponse } from './master-reference-data-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

// @ts-ignore
import { SnomedCodeResponse } from './snomed-code-response';

/** See com.rev360.pms.api.controller.patient.surgeryhistory.SurgeryHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class SurgeryHistoryResponse extends GandalfModelBase {

	@GandalfProperty()
	comments: string;

	@GandalfProperty()
	eye: MasterReferenceDataResponse;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	snomed: SnomedCodeResponse;

	@GandalfProperty()
	surgeon: ProviderResponse;

	@GandalfProperty()
	surgeryDate: Date;

	@GandalfProperty()
	surgeryDateEstimated: boolean;

}
