import { _every, _find, _flatten, _isNil, _maxBy } from '@core/lodash/lodash';
import { SortingService } from 'morgana';
import { StatefulTabItem } from '@shared/directives/stateful-tab/stateful-tab-item';
import { StatefulTabItemCollection, StatefulTabItemTypeMap } from '@shared/directives/stateful-tab/stateful-tab.directive';

export class StatefulTabUtil {

	static getNextSortOrder<T extends StatefulTabItem<U>, U>(tabItemCollection: StatefulTabItemCollection<T, U>): number {
		let maxSortOrder = 1;
		if (_isNil(tabItemCollection) || this.getTabItemArray(tabItemCollection).length === 0) {
			return maxSortOrder;
		}

		const maxSortOrderTab = _maxBy(this.getTabItemArray(tabItemCollection), 'sortOrder');
		if (!_isNil(maxSortOrderTab)) {
			maxSortOrder = maxSortOrderTab.sortOrder + 1;
		}

		return maxSortOrder;
	}

	static getNextTab<T extends StatefulTabItem<U>, U>(tabItemCollection: StatefulTabItemCollection<T, U>, item: StatefulTabItem<U>): StatefulTabItem<U> {
		const tabItemArray = this.getTabItemArray(tabItemCollection);
		const tabIndex = tabItemArray.findIndex(tabItem => tabItem.id === item.id);

		if (tabIndex === tabItemArray.length - 1) {
			return tabItemArray[tabIndex - 1];
		} else {
			return tabItemArray[tabIndex + 1];
		}
	}

	static getIndexBySortOrder<T extends StatefulTabItem<U>, U>(tabItemCollection: StatefulTabItemCollection<T, U>, item: StatefulTabItem<U>, staticTabCount = 0) {
		const tabItemArray = this.getTabItemArray(tabItemCollection);
		const tabIndex = tabItemArray.findIndex(tabItem => tabItem.id === item.id);

		if (tabIndex >= 0) {
			return tabIndex + staticTabCount;
		}

		return staticTabCount + 1;
	}

	static getTabItemArray<T extends StatefulTabItem<U>, U>(tabItemCollection: StatefulTabItemCollection<T, U>, sort = true): StatefulTabItem<U>[] {
		let tabItemArray = StatefulTabUtil.isStatefulTabItemTypeMap(tabItemCollection)
			? this.flattenStatefulTabItemTypeMap(tabItemCollection)
			: Array.from(tabItemCollection.values());

		if (sort) {
			tabItemArray = SortingService.sortBy(tabItemArray, 'sortOrder');
		}

		return tabItemArray;
	}

	static getTabItemEntries<T extends StatefulTabItem<U>, U>(tabItemCollection: StatefulTabItemCollection<T, U>): [number, T][] {
		return StatefulTabUtil.isStatefulTabItemTypeMap(tabItemCollection)
			? _flatten(Array.from(tabItemCollection).map(tabItemTypeEntry => Array.from(tabItemTypeEntry[1])))
			: Array.from(tabItemCollection);
	}

	static getTabEntryByDefaultRoute<T extends StatefulTabItem<U>, U>(defaultRoute: string, tabItemCollection: StatefulTabItemCollection<T, U>): [number, T] {
		const tabItemEntries = StatefulTabUtil.getTabItemEntries(tabItemCollection);
		return _find(tabItemEntries, ([_tabItemId, tabItemState]) => tabItemState.defaultRoute === defaultRoute);
	}

	static flattenStatefulTabItemTypeMap<T extends StatefulTabItem<U>, U>(tabItemTypeMap: StatefulTabItemTypeMap<T, U>): StatefulTabItem<U>[] {
		return _flatten(Array.from(tabItemTypeMap.values()).map(tabItemMap => Array.from(tabItemMap.values())));
	}

	static isStatefulTabItemTypeMap<T extends StatefulTabItem<U>, U>(tabItemCollection: any): tabItemCollection is StatefulTabItemTypeMap<T, U> {
		return _every(Array.from(tabItemCollection.values()), item => item instanceof Map);
	}
}
