// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.inventory.LocationProductTableResponse */
/* istanbul ignore next */
@GandalfModel
export class LocationProductTableResponse extends GandalfModelBase {

	@GandalfProperty()
	brandName: string;

	@GandalfProperty()
	collectionName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	contactLensType: constants.ContactLensType;

	@GandalfProperty()
	eyeGlassLensTypeId: number;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	invoiceDescription: string;

	@GandalfProperty()
	manufacturerName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	masterProductCategory: constants.MasterProductCategory;

	@GandalfProperty()
	name: string;

	@GandalfProperty({ propertyType: 'Money' })
	price: number;

	@GandalfProperty()
	productCode: string;

	@GandalfProperty()
	productId: number;

	@GandalfProperty()
	productName: string;

	@GandalfProperty()
	smartflowMasterSupplierId: number;

	@GandalfProperty()
	subCategoryId: number;

	@GandalfProperty({ propertyType: 'Money' })
	wholesalePrice: number;

}
