import { Component, Input } from '@angular/core';
import { EnumUtil } from 'morgana';
import { _isNil } from '@core/lodash/lodash';
import { CodeSet } from '@gandalf/constants';
import { PracticeDiagnosisResponse } from '@gandalf/model/practice-diagnosis-response';
import { BaseComponent } from '@shared/component/base.component';

@Component({
	selector: 'pms-diagnosis-code',
	templateUrl: './diagnosis-code.component.html',
	styles: [],
})
export class DiagnosisCodeComponent extends BaseComponent {

	@Input()
	practiceDiagnosis: PracticeDiagnosisResponse;

	@Input()
	showShortDescription = true;

	static computeDescription(code: string, shortDescription: string): string {
		return code + ' - ' + shortDescription;
	}

	getDescription(): string {
		if (_isNil(this.practiceDiagnosis)) {
			return '';
		}

		if (this.showShortDescription) {
			return DiagnosisCodeComponent.computeDescription(this.practiceDiagnosis.code, this.practiceDiagnosis.shortDescription);
		} else {
			return this.practiceDiagnosis.code;
		}
	}

	isCodeSetICD9(): boolean {
		if (this.isCodeSetNil()) {
			return false;
		}

		return EnumUtil.equals(this.practiceDiagnosis.codeSet, CodeSet.ICD9);
	}

	isCodeSetICD10(): boolean {
		if (this.isCodeSetNil()) {
			return false;
		}
		return EnumUtil.equals(this.practiceDiagnosis.codeSet, CodeSet.ICD10);
	}

	isCodeSetSNOMED(): boolean {
		if (this.isCodeSetNil()) {
			return false;
		}
		return EnumUtil.equals(this.practiceDiagnosis.codeSet, CodeSet.SNOMED);
	}

	isCodeSetNil(): boolean {
		return _isNil(this.practiceDiagnosis) || _isNil(this.practiceDiagnosis.codeSet);
	}
}
