import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { _isEqual, _isNil } from '@core/lodash/lodash';
import { Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, skip, startWith, takeUntil } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class FormUtilsService {

	constructor() {
	}

	/**
	 * Takes a form control and either enables or disables it using the given
	 * condition as the predicate test based on enabled being true.
	 * @param control FormControl to toggle on or off.
	 * @param condition Condition to use to determine whether the control should be enabled or disabled.
	 */
	static enabledWhen(control: AbstractControl, condition: boolean) {
		const action = condition ? 'enable' : 'disable';
		control[action]();
	}

	/**
	 * Takes a form control and either enables or disables it using the given
	 * condition as the predicate test based on disabled being true.
	 * @param control FormControl to toggle on or off.
	 * @param condition Condition to use to determine whether the control should be enabled or disabled.
	 */
	static disabledWhen(control: AbstractControl, condition: boolean) {
		const action = condition ? 'disable' : 'enable';
		control[action]();
	}

	/**
	 * Takes a form control and nulls the value given the provided boolean resolves to true.
	 * @param control FormControl to make null.
	 * @param condition Condition to use to determine whether or not to nullify the control.
	 */
	static nullWhen(control: AbstractControl, condition: boolean) {
		if (condition) {
			control.setValue(null);
		}
	}

	/**
	 * Takes a form control and sets its value to null if the value is of type string and is empty
	 * @param control FormControl to make null
	 */
	static nullWhenEmptyString(control: AbstractControl) {
		if (typeof control.value === 'string' && control.value === '') {
			control.setValue(null);
		}
	}
	/**
	 * Takes a form control and sets the value given the provided boolean resolves to true.
	 * @param control FormControl to set the value of.
	 * @param value Value to assign to the form control given a true condition.
	 * @param condition Condition to base the value assignment off of.
	 */
	static setValueWhen(control: AbstractControl, value: any, condition: boolean) {
		if (condition) {
			control.setValue(value);
		}
	}

	/**
	 * Takes a form control and sets the value given the control's value is nil.
	 * @param control FormControl to set the value of.
	 * @param value Value to assign to the form control if its current value is nil.
	 */
	static setValueWhenNil(control: AbstractControl, value: any) {
		FormUtilsService.setValueWhen(control, value, _isNil(control.value));
	}

	/**
	 * Takes a form control and marks it as dirty given the provided boolean resolves to true.
	 * @param control Control to mark as dirty.
	 * @param condition Condition to use to determine whether or not to mark the control as dirty.
	 */
	static dirtyWhen(control: AbstractControl, condition: boolean) {
		if (condition) {
			control.markAsDirty();
		}
	}

	/**
	 * Mark Form Controls Dirty
	 * Recursively goes through a form and marks all of the controls as dirty. This is primarily for the
	 * sake of our libraries and their dependency on the Angular FormControl statuses to determine the correct
	 * style to apply
	 * @param formGroup FormGroup to mark controls on.
	 */
	static markFormControlsDirty(formGroup: UntypedFormGroup) {
		Object.keys(formGroup.controls).forEach((formControlName: string) => {
			const control = formGroup.get(formControlName);

			// Recursively apply the effect to FormGroups
			if (FormUtilsService.isFormGroup(control)) {
				FormUtilsService.markFormControlsDirty(control);

				// Recursively apply the effect to FormArrays
			} else if (FormUtilsService.isFormArray(control)) {
				control.controls.forEach(embeddedFormGroup => {
					FormUtilsService.markFormControlsDirty(embeddedFormGroup as UntypedFormGroup);
				});

			} else {
				control.markAsDirty();
			}
		});
	}

	/**
	 * React to Value Changes
	 * Reacts to value changes on the provided form through a subscription to valueChanges and injects the
	 * provided reactions.
	 * @param form Form to observe value changes on.
	 * @param reactions Reactions to inject into the subscriptions.
	 * @param runReactionsOnInit Runs the reactions once when method is initially called.
	 * @param unsubscribe Unsubscribe to listen for within the takeUntil operator.
	 */
	static reactToValueChanges<T>(
		form: AbstractControl,
		reactions: (values: T) => void,
		runReactionsOnInit = false,
		unsubscribe: Observable<void> = new Subject(),
	): Subscription {
		if (runReactionsOnInit) {
			reactions(form.value);
		}

		return form.valueChanges
			.pipe(
				startWith(form.value as object),
				distinctUntilChanged(_isEqual),
				skip(1),
				takeUntil(unsubscribe),
			).subscribe(reactions);
	}

	/**
	 * Typeguard to check whether an AbstractControl is a FormArray.
	 * @param control Control to check against.
	 */
	static isFormArray(control: AbstractControl): control is UntypedFormArray {
		return (control as UntypedFormArray).controls !== undefined && (control as UntypedFormArray).length !== undefined;
	}

	/**
	 * Typeguard to check whether an AbstractControl is a FormGroup.
	 * @param control Control to check against.
	 */
	static isFormGroup(control: AbstractControl): control is UntypedFormGroup {
		return (control as UntypedFormGroup).controls !== undefined && (control as UntypedFormArray).length === undefined;
	}

	/**
	 * Typeguard to check whether an AbstractControl is a FormControl.
	 * @param control Control to check against.
	 */
	static isFormControl(control: AbstractControl): control is UntypedFormControl {
		return (control as UntypedFormControl).value !== undefined;
	}

	/**
	 * Enables and disables the components provided by name in the form group.
	 * Note: If the form is disabled, components will not be enabled.
	 */
	static enableDisableControls(formGroup: UntypedFormGroup, enableControlName: string[], disableControlNames: string[]) {
		if (formGroup.enabled) {
			enableControlName.forEach(name => {
				formGroup.get(name).enable();
			});
		}
		disableControlNames.forEach(name => {
			formGroup.get(name).disable();
		});
	}

	/**
	 * Copies all the form controls and nested FormGroups from one parent FormGroup to another
	 * Note: We should really never need to do this, as the request object should be able to build all the needed
	 * formControls and nested formGroups. Ask your friendly neighborhood tech lead if using this method is truly necessary.
	 */
	static copyControls(originFormGroup: UntypedFormGroup, destinationFormGroup: UntypedFormGroup) {
		Object.keys(originFormGroup.controls).forEach((controlKey) => {
			if (FormUtilsService.isFormGroup(originFormGroup.get(controlKey))) {
				destinationFormGroup.addControl(controlKey, originFormGroup.get(controlKey));
			} else {
				destinationFormGroup.addControl(controlKey, new UntypedFormControl(originFormGroup.controls[controlKey].value));
			}
		});
	}

	/**
	 * Clears the controls from a form array
	 */
	static clearFormArray(formArray: UntypedFormArray) {
		while (formArray.length !== 0) {
			formArray.removeAt(0);
		}
	}

	static addValidationToFormControl(
		formControl: AbstractControl,
		newValidator: (...args) => ValidatorFn,
		... validatorArgs: any[]
	) {
		if (formControl.validator) {
			formControl.setValidators([
				formControl.validator,
				newValidator(...validatorArgs),
			]);
		} else {
			formControl.setValidators([
				newValidator(...validatorArgs),
			]);
		}
	}
}
