import { _get } from '@core/lodash/lodash';
import { StatefulTabType } from '@shared/directives/stateful-tab/stateful-tab-type';
import { HeaderModel, TabItemModel } from '@syncfusion/ej2-angular-navigations';
import { v4 as uuidv4 } from 'uuid';

export interface StatefulTabItemOptionalArgs<S = any> {
	headerText?: string;
	currentRoute?: string;
	parentId?: number | string;
	sortOrder?: number;
	subType?: S;
	tooltipContent?: string;
}

/**
 * Stateful Tab Item
 * This class represents that data model expected by the Stateful Tab Directive.
 */
export class StatefulTabItem<S = any> implements TabItemModel {
	id: string;
	content: string | HTMLElement;
	cssClass: string;
	currentRoute: string;
	disabled: boolean;
	header: HeaderModel;
	headerText: string;
	parentId: number | string;
	sortOrder: number;
	subType: S;
	tooltipContent: string;

	constructor(
		public defaultRoute: string,
		public type: StatefulTabType,
		options?: StatefulTabItemOptionalArgs<S>,
	) {
		this.id = uuidv4();
		this.headerText = _get(options, ['headerText']);
		this.currentRoute = _get(options, ['currentRoute']) || defaultRoute;
		this.parentId = _get(options, ['parentId']);
		this.sortOrder = _get(options, ['sortOrder']);
		this.subType = _get(options, ['subType']);
		this.tooltipContent = _get(options, ['tooltipContent']);
	}
}
