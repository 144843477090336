import { Component, Input } from '@angular/core';
import { GandalfConstant } from 'gandalf';

export interface SubBucket {
	active?: boolean;
	count?: number;
	name?: string;
	type?: string;
	onClick?: () => void;
	label?: string;
	labelAbbreviated?: string;
	id?: GandalfConstant<number>;
}

export interface Bucket {
	name?: string;
	label?: string;
	count?: number;
	type?: string;
	id?: GandalfConstant<number>;
	color?: string;
	active?: boolean;
	subBuckets?: SubBucket[];
	onTitleClick?: () => void;
	isTitleHighlighted?: boolean;
	pillCount?: number;
	displayPillCount?: boolean;
}

export const BUCKET_COLOR = {GREY: 'panel-default', YELLOW: 'panel-warning', GREEN: 'panel-success', BLUE: 'panel-info'};

@Component({
	selector: 'pms-bucket',
	templateUrl: './bucket.component.html',
	styles: [],
})
export class BucketComponent {

	@Input()
	bucket: Bucket;

	constructor() {
	}

}
