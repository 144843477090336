<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-lg"
	[attr.data-test-id]="'contactLensPrescriptionLookupModal'">
	<ng-template #header>
		<div>Contact Lens Rx</div>
	</ng-template>
	<ng-template #content>
		<ejs-grid
			#grid
			autoFitColumns
			[autoFitBlacklist]="!isTrialOrder ? ['comments'] : []"
			[pmsGridSubmit]="isSearching"
			[dataSource]="gridData"
			[allowResizing]="true"
			[allowSorting]="true"
			[allowSelection]="true"
			[allowFiltering]="true"
			class="table-rowlink"
			gridLines="Horizontal"
			(rowSelected)="closeModal($event.data.rxId, $event.data.isTrial)"
			[attr.data-test-id]="'contactLensPrescriptionLookupModalTable'">
			<ng-template #toolbarTemplate let-data *ngIf="isTrialOrder">
				<rev-table-action-menu [table]="grid">
					<ng-container rev-table-action-menu-left>
						<div class="e-toolbar-item">
							<label>Status</label>
							<pms-enum-select-button
								[(ngModel)]="statusFilter"
								[enumList]="filterOptions"
								[allOption]="true"
                                (valueChange)="debounceFilter()"
								[attr.data-test-id]="'contactLensPrescriptionLookupFilterButtons'">
							</pms-enum-select-button>
						</div>
					</ng-container>
				</rev-table-action-menu>
			</ng-template>
			<e-columns>
				<e-column field="startDate" headerText="Date" [format]="gridDateFormat"></e-column>
				<e-column field="rxDisplayStatus.label" headerText="Status" [allowSorting]="false"></e-column>
				<e-column field="authorizedBy" headerText="Auth By"></e-column>
				<e-column headerText="" [allowSorting]="false" [width]="50" [customAttributes]="{class: 'align-top'}">
					<ng-template #template let-data>
						<span class="label label-success">OD</span><br>
						<span class="label label-info">OS</span>
					</ng-template>
				</e-column>
				<e-column headerText="Model/Name" [allowSorting]="false" [customAttributes]="{class: 'align-top'}">
					<ng-template #template let-data>
						{{data.modelOd}}
						<br/>
						{{data.modelOs}}
					</ng-template>
				</e-column>
				<e-column headerText="Type" [allowSorting]="false" [width]="75" [customAttributes]="{class: 'align-top'}">
					<ng-template #template let-data>
						{{data.overallLensType.label}}
						<br/>
						{{data.overallLensType.label}}
					</ng-template>
				</e-column>
				<e-column headerText="MV" [allowSorting]="false" [width]="75" [customAttributes]="{class: 'align-top'}">
					<ng-template #template let-data>
						{{data.isMonovisionOd ? 'Yes' : ''}}
						<br/>
						{{data.isMonovisionOs ? 'Yes' : ''}}
					</ng-template>
				</e-column>
				<e-column headerText="BC" [allowSorting]="false" [width]="75" [customAttributes]="{class: 'align-top'}">
					<ng-template #template let-data>
						{{data.baseCurveOd | baseCurve}}
						<br/>
						{{data.baseCurveOs | baseCurve}}
					</ng-template>
				</e-column>
				<e-column headerText="Sph" [allowSorting]="false" [width]="75" [customAttributes]="{class: 'align-top'}">
					<ng-template #template let-data>
						{{data.sphereOd | plusMinusNoRounding}}
						<br/>
						{{data.sphereOs | plusMinusNoRounding}}
					</ng-template>
				</e-column>
				<e-column headerText="Cyl" [allowSorting]="false" [width]="75" [customAttributes]="{class: 'align-top'}">
					<ng-template #template let-data>
						{{data.cylinderOd | plusMinusNoRounding}}
						<br/>
						{{data.cylinderOs | plusMinusNoRounding}}
					</ng-template>
				</e-column>
				<e-column headerText="Axis" [allowSorting]="false" [width]="75" [customAttributes]="{class: 'align-top'}">
					<ng-template #template let-data>
						{{data.axisOd | padAxis}}
						<br/>
						{{data.axisOs | padAxis}}
					</ng-template>
				</e-column>
				<e-column headerText="Add" [allowSorting]="false" [width]="75" [customAttributes]="{class: 'align-top'}">
					<ng-template #template let-data>
						<ng-container *ngIf="!isOverallLensTypeSoft(data); else elseBlock">
							{{data.addPowerOd | plusMinus}}
							<br/>
							{{data.addPowerOs | plusMinus}}
						</ng-container>
						<ng-template #elseBlock>
							{{data.addDesignationOd}}
							<br/>
							{{data.addDesignationOs}}
						</ng-template>
					</ng-template>
				</e-column>
				<e-column headerText="Diam" [allowSorting]="false" [width]="75" [customAttributes]="{class: 'align-top'}">
					<ng-template #template let-data>
						{{data.diameterOd | forceDecimalNullSafe : 1}}
						<br/>
						{{data.diameterOs | forceDecimalNullSafe : 1}}
					</ng-template>
				</e-column>
				<e-column field="comments" headerText="Comments" clipMode="EllipsisWithTooltip" *ngIf="!isTrialOrder"></e-column>
			</e-columns>
		</ejs-grid>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" (click)="closeModal()" type="button" [attr.data-test-id]="'contactLensPrescriptionLookupCancelButton'">Cancel</button>
	</ng-template>
</ejs-dialog>
