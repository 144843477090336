import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';

@Component({
	selector: 'pms-permission-enabled-link',
	templateUrl: './permission-enabled-link.component.html',
})
export class PermissionEnabledLinkComponent {

	@Input()
	permissionId: number;

	@Input()
	linkText: string;

	@Input()
	disabledText: string;

	@Output()
	permittedClick = new EventEmitter();

	constructor(
		private readonly securityManagerService: SecurityManagerService) {
	}

	hasPermission() {
		return this.securityManagerService.hasPermission(this.permissionId);
	}
}
