import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import isArrayLike from 'lodash/isArrayLike';
import isString from 'lodash/isString';
/**
 * Validates that a form control's value is not null or undefined
 *
 * If the value is a string type, it will trim the value first and then perform the null check
 *
 * If the value is an array, it will not return validation errors
 */
export const requiredValidator: ValidatorFn = (control: AbstractControl) => {
	if (isString(control.value) && control.value.trim() === '') {
		return {
			required: true,
		};
	}

	// A non-empty array will always satisfy a Required field
	if (isArrayLike(control.value)) {
		return null;
	}

	return Validators.required(control);
};
