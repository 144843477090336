import { GridActions, GridActionTypes } from '@app-store/actions/grids.actions';
import { ColumnState } from 'ag-grid-community';

export class GridState {
	columnsState: ColumnState[];
	filterState: any;
	paginationState: PaginationState = new PaginationState();
	selectionState: any[];
	rowHighlighted: number;
}

export class PaginationState {
	constructor(
		public currentPage?: number,
		public pageSize?: number,
	) {}
}

export type GridStateMap = Map<string, GridState>;

export const initialState: GridStateMap = new Map();

export function reducer(state = initialState, action: GridActions): GridStateMap {
	switch (action.type) {

		case GridActionTypes.UPDATE_GRID_COLUMNS_STATE: {
			const {key, columnsState} = action.payload;
			const gridStateMap = new Map(state);
			const gridState = gridStateMap.get(key) || new GridState();
			return gridStateMap.set(key, {...gridState, columnsState});
		}

		case GridActionTypes.UPDATE_GRID_FILTER_STATE: {
			const {key, filterState} = action.payload;
			const gridStateMap = new Map(state);
			const gridState = gridStateMap.get(key) || new GridState();
			return gridStateMap.set(key, {...gridState, filterState});
		}

		case GridActionTypes.UPDATE_GRID_PAGINATION_STATE: {
			const {key, paginationState} = action.payload;
			const gridStateMap = new Map(state);
			const gridState = gridStateMap.get(key) || new GridState();
			return gridStateMap.set(key, {...gridState, paginationState});
		}

		case GridActionTypes.UPDATE_GRID_SELECTION_STATE: {
			const {key, selectionState} = action.payload;
			const gridStateMap = new Map(state);
			const gridState = gridStateMap.get(key) || new GridState();
			return gridStateMap.set(key, {...gridState, selectionState});
		}

		case GridActionTypes.UPDATE_GRID_HIGHLIGHTED_STATE: {
			const {key, highlightedRowIndex} = action.payload;
			const gridStateMap = new Map(state);
			const gridState = gridStateMap.get(key) || new GridState();
			gridState.rowHighlighted = highlightedRowIndex;
			return gridStateMap.set(key, {...gridState});
		}

		case GridActionTypes.REMOVE_STATE: {
			const gridStateMap = new Map(state);
			gridStateMap.delete(action.payload.key);
			return gridStateMap;
		}

		default: {
			return state;
		}
	}
}
