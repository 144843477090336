// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.connect.campaign.UpdateConnectCampaignScheduleRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateConnectCampaignScheduleRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Connect Campaign Id is required' } })
	@GandalfProperty({ isRequired: true })
	connectCampaignId: number;

	@GandalfValidator({ notNull: { message: 'Subject is required' } })
	@GandalfValidator({ sizeString: { message: 'Subject must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ isRequired: true })
	emailSubject: string;

	@GandalfValidator({ notNull: { message: 'Message is required' } })
	@GandalfValidator({ sizeString: { message: 'Message must be between 1 and 65535 characters', minLength: 1, maxLength: 65535 } })
	@GandalfProperty({ isRequired: true })
	emailTemplate: string;

	@GandalfValidator({ notNull: { message: 'Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Name must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ isRequired: true })
	name: string;

	@GandalfValidator({ notNull: { message: 'Practice Location is required' } })
	@GandalfProperty({ isRequired: true })
	practiceLocationId: number;

	@GandalfValidator({ notNull: { message: 'Recipients is required' } })
	@GandalfProperty({ isRequired: true })
	queryId: number;

	@GandalfProperty()
	scheduledDate: Date;

	@GandalfValidator({ notNull: { message: 'Use Patient Primary Location is required' } })
	@GandalfProperty({ isRequired: true })
	usePatientPrimaryLocation: boolean;

}
