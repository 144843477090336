import { Directive, HostListener } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';

@Directive({
	/* eslint-disable-next-line @angular-eslint/directive-selector */
	selector: 'ag-grid-angular:not([agGridNoFilteredRowsOverride])',
})
export class AgGridNoFilteredRowsDirective {

	constructor(
		private gridComponent: AgGridAngular,
	) {
	}

	@HostListener('gridReady')
	onGridReady() {
		this.checkRenderedNodes();
	}

	@HostListener('modelUpdated')
	onFiltering() {
		this.checkRenderedNodes();
	}

	checkRenderedNodes() {
		if (this.gridComponent.api.getRenderedNodes().length === 0) {
			this.gridComponent.api.showNoRowsOverlay();
		} else {
			this.gridComponent.api.hideOverlay();
		}
	}

}
