// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.schedule.scheduleitem.SearchAppointmentSlotRequest */
/* istanbul ignore next */
@GandalfModel
export class SearchAppointmentSlotRequest extends GandalfModelBase {

	@GandalfProperty()
	employeeId: number;

	@GandalfValidator({ notNull: { message: 'Type is required' } })
	@GandalfProperty({ isRequired: true })
	encounterTemplateId: number;

	@GandalfValidator({ notNull: { message: 'Appointment End Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	endDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	fridayTime: constants.SchedulingPreferencesTimeOfDay;

	@GandalfProperty()
	locationId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	mondayTime: constants.SchedulingPreferencesTimeOfDay;

	@GandalfProperty()
	roleId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	saturdayTime: constants.SchedulingPreferencesTimeOfDay;

	@GandalfValidator({ notNull: { message: 'Appointment Start Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	startDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	sundayTime: constants.SchedulingPreferencesTimeOfDay;

	@GandalfConstantDecorator()
	@GandalfProperty()
	thursdayTime: constants.SchedulingPreferencesTimeOfDay;

	@GandalfConstantDecorator()
	@GandalfProperty()
	tuesdayTime: constants.SchedulingPreferencesTimeOfDay;

	@GandalfConstantDecorator()
	@GandalfProperty()
	wednesdayTime: constants.SchedulingPreferencesTimeOfDay;

}
