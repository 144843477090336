// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { ContactLensOrderResponse } from './contact-lens-order-response';

// @ts-ignore
import { EncounterTableResponse } from './encounter-table-response';

// @ts-ignore
import { OrderContactLensPrescriptionResponse } from './order-contact-lens-prescription-response';

// @ts-ignore
import { OrderContactLensTrialResponse } from './order-contact-lens-trial-response';

// @ts-ignore
import { OrderProcessorConfigResponse } from './order-processor-config-response';

// @ts-ignore
import { OrderVendorResponse } from './order-vendor-response';

// @ts-ignore
import { PatientCommunicationResponse } from './patient-communication-response';

// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.order.ContactLensTrialOrderResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensTrialOrderResponse extends ContactLensOrderResponse {

	@GandalfProperty()
	comment: string;

	@GandalfProperty()
	createdOn: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dueDate: Date;

	@GandalfProperty()
	encounter: EncounterTableResponse;

	@GandalfProperty({ propertyType: 'Money' })
	estimatedDiscounts: number;

	@GandalfProperty({ propertyType: 'Money' })
	estimatedInsuranceBenefit: number;

	@GandalfProperty({ propertyType: 'Money' })
	estimatedTaxes: number;

	@GandalfProperty()
	externalSupplierIdentifier: string;

	@GandalfProperty()
	externalSupplierName: string;

	@GandalfProperty()
	instructions: string;

	@GandalfProperty({ propertyType: 'Money' })
	lensCost: number;

	@GandalfProperty({ propertyType: 'Money' })
	lensRemakeCost: number;

	@GandalfProperty()
	locationId: number;

	@GandalfProperty()
	locationName: string;

	@GandalfProperty()
	notificationComments: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	notificationDate: Date;

	@GandalfProperty()
	odColorId: number;

	@GandalfProperty()
	odDispenseQuantity: number;

	@GandalfProperty()
	odPackagingId: number;

	@GandalfProperty()
	odQuantity: number;

	@GandalfProperty()
	onHold: boolean;

	@GandalfProperty()
	orderId: number;

	@GandalfProperty()
	originalOrderId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	originalOrderReason: constants.OriginalOrderReason;

	@GandalfProperty()
	osColorId: number;

	@GandalfProperty()
	osDispenseQuantity: number;

	@GandalfProperty()
	osPackagingId: number;

	@GandalfProperty()
	osQuantity: number;

	@GandalfProperty({ propertyType: 'Money' })
	otherCost: number;

	@GandalfProperty({ propertyType: 'Money' })
	otherRemakeCost: number;

	@GandalfProperty()
	patient: PatientNameResponse;

	@GandalfProperty()
	patientCommunication: PatientCommunicationResponse;

	@GandalfProperty()
	patientNotified: boolean;

	@GandalfProperty()
	personId: number;

	@GandalfProperty()
	prescription: OrderContactLensPrescriptionResponse;

	@GandalfProperty()
	processor: OrderProcessorConfigResponse;

	@GandalfProperty()
	shipAddress: AddressResponse;

	@GandalfProperty({ propertyType: 'Money' })
	shipCost: number;

	@GandalfProperty({ propertyType: 'Money' })
	shipRemakeCost: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	shipToType: constants.OrderShipToType;

	@GandalfConstantDecorator()
	@GandalfProperty()
	shippingCompany: constants.OrderShipmentCompany;

	@GandalfProperty()
	smartflowOrderIdentifier: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	statusCode: constants.OrderStatusCode;

	@GandalfProperty()
	statusDate: Date;

	@GandalfProperty()
	trackingNumber: string;

	@GandalfProperty()
	tray: string;

	@GandalfProperty()
	trial: OrderContactLensTrialResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	type: constants.OrderType;

	@GandalfProperty()
	unitOd: string;

	@GandalfProperty()
	unitOs: string;

	@GandalfProperty()
	vendor: OrderVendorResponse;

	@GandalfProperty()
	vendorOrderIdentifier: string;

	@GandalfProperty()
	version: number;

}
