import { ChangeDetectorRef, Component } from '@angular/core';
import { PatientService } from '@core/patient/patient.service';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';
import { PatientSchedulingPreferenceResponse } from '@gandalf/model/patient-scheduling-preference-response';

@Component({
	selector: 'pms-patient-scheduling-preference-popover',
	templateUrl: './patient-scheduling-preference-popover.component.html',
	styles: [
		`
			:host {
				position: absolute;
				transition: opacity 1s;
			}

			:host.ag-tooltip-hiding {
				display: none;
			}
		`,
	],
})
export class PatientSchedulingPreferencePopoverComponent implements ITooltipAngularComp {
	patientSchedulingPreference: PatientSchedulingPreferenceResponse;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private patientService: PatientService,
	) {
	}

	agInit(params: ITooltipParams): void {
		this.patientService.findSchedulingPreferencesByPatientId(params.data.patientId).subscribe((data) => {
			this.patientSchedulingPreference = data;
			this.changeDetectorRef.detectChanges();
		});
	}

}
