<div class="e-btn-group">
	@for (option of options; track option; let i = $index) {
		<div class="e-control e-btn e-lib" #btn
			 [ngClass]="buildNgClass(option, btn)"
			 tabindex="0"
			 (click)="onItemClick($event, option, i)"
			 (keydown.enter)="onItemClick($event, option, i)"
			 [attr.title]="option.title"
			 [attr.aria-label]="option.label"
			 (focus)="onFocus($event)"
			 (blur)="onBlur($event)"
			 [attr.data-option-value]="option.value"
			 [attr.data-test-id]="buildDataTestIdForOption(option)">
			<ng-container>
				@if (option.icon) {
					<span [class]="option.icon"></span>
				}
				{{ option.label }}
			</ng-container>
		</div>
	}
</div>
