import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
@Pipe({ name: 'flatPercent' })
export class FlatPercentPipe implements PipeTransform {

	constructor() {
	}

	/**
	 * @ngdoc filter
	 * @description
	 * Takes a percent value and append the percent symbol.
	 *
	 * @param value - The value being formatted.
	 *
	 * @usage
	 * {{ 10 | flatPercent }}
	 */
	transform(value: number): string {
		return `${value || 0} %`;
	}

}
