import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {PreferredPhoneType} from '@gandalf/constants';
import {FormUtilsService} from '@core/form-utils/form-utils.service';

@Component({
	selector: 'pms-contact-information',
	templateUrl: './contact-information.component.html',
	styles: [
	],
})
export class ContactInformationComponent implements OnInit {

	preferredPhoneTypeOptions = [
		PreferredPhoneType.HOME,
		PreferredPhoneType.WORK,
		PreferredPhoneType.CELL,
	];

	@Input()
	componentForm: UntypedFormGroup;

	constructor(
	) { }

	ngOnInit() {
		this.setDefaultPreferredPhoneType();
	}

	setDefaultPreferredPhoneType() {
		FormUtilsService.setValueWhenNil(this.componentForm.get('phoneInformation.preferredPhoneType'), PreferredPhoneType.HOME);
	}
}
