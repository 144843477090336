import { inject, InjectionToken } from '@angular/core';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { MasterSecureResourceId } from '@gandalf/constants';

const permissionTokensMap = new Map<MasterSecureResourceId, InjectionToken<boolean>>();

function createPermissionToken(MasterSecureResourceId: MasterSecureResourceId) {
	return new InjectionToken<boolean>(MasterSecureResourceId.label, {
		providedIn: 'root',
		factory: () => inject(SecurityManagerService).hasPermission(MasterSecureResourceId.value),
	});
}

export function permissionToken(MasterSecureResourceId: MasterSecureResourceId): InjectionToken<boolean> {
	if (permissionTokensMap.has(MasterSecureResourceId)) {
		return permissionTokensMap.get(MasterSecureResourceId);
	}
	const token = createPermissionToken(MasterSecureResourceId);
	permissionTokensMap.set(MasterSecureResourceId, token);
	return token;
}
