// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.pod.PatientPodConnectMessageResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientPodConnectMessageResponse extends GandalfModelBase {

	@GandalfProperty()
	appointmentId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	appointmentStatus: constants.ScheduleItemStatus;

	@GandalfConstantDecorator()
	@GandalfProperty()
	category: constants.ConnectMessageCategory;

	@GandalfProperty()
	connectMessageId: number;

	@GandalfProperty()
	encounterId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	messageStatus: constants.ConnectMessageStatus;

	@GandalfConstantDecorator()
	@GandalfProperty()
	method: constants.ConnectMessageMethod;

	@GandalfProperty()
	orderId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	orderStatus: constants.OrderStatusCode;

	@GandalfProperty()
	recallAppointmentId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	recallAppointmentStatus: constants.ScheduleItemStatus;

	@GandalfProperty()
	recallId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	recallStatus: constants.RecallStatus;

	@GandalfProperty()
	scheduleDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	source: constants.ConnectMessageSource;

}
