// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.assessment.AssessmentAutoDiagnosisResponse */
/* istanbul ignore next */
@GandalfModel
export class AssessmentAutoDiagnosisResponse extends GandalfModelBase {

	@GandalfProperty()
	code: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	codeSet: constants.CodeSet;

	@GandalfConstantDecorator()
	@GandalfProperty()
	diagnosisLocation: constants.DiagnosisLocation;

	@GandalfProperty()
	encounterAutoDiagnosisId: number;

	@GandalfProperty()
	longDescription: string;

	@GandalfProperty()
	masterDiagnosis: number;

	@GandalfProperty()
	personDiagnosisId: number;

	@GandalfProperty()
	practiceDiagnosisId: number;

	@GandalfProperty()
	shortDescription: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.EncounterAutoDiagnosisStatus;

}
