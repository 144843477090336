import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { selectFormState } from '@app-store/selectors/forms.selectors';
import { UpdateStatePropertyPayload } from '@app-store/utils/update-state-property-payload';
import { EnumUtil, ModalManagerService } from 'morgana';
import { _find, _isNil } from '@core/lodash/lodash';
import { RouterUtilsService } from '@core/router-utils/router-utils.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { SECURITY_CONSTANTS } from '@core/security/security.constants';
import { UserLocationsService } from '@core/user-locations/user-locations.service';
import { PayerType } from '@gandalf/constants';
import { RemoveInvoiceTab, SaveInvoiceCurrentTab } from '@invoices-store/actions';
import { State } from '@invoices-store/reducers';
import { InvoiceCurrentTabState } from '@invoices-store/reducers/invoice-current-tab.reducer';
import { InvoiceModuleTabState, InvoiceTabStateMap } from '@invoices-store/reducers/invoice-tab.reducer';
import { selectInvoiceCurrentTabRoute, selectInvoiceTabState } from '@invoices-store/selectors/invoice.selector';
import { Store } from '@ngrx/store';
import { StatefulTabItem } from '@shared/directives/stateful-tab/stateful-tab-item';
import { MenuEventArgs, MenuItemModel } from '@syncfusion/ej2-angular-navigations';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { INVOICES_DASHBOARD_COMPONENT, InvoiceService } from '../core/accounting/invoice-service/invoice.service';

@Component({
	selector: 'pms-accounting-invoices',
	templateUrl: './invoices.component.html',
	styles: [],
})
export class InvoicesComponent implements OnInit, OnDestroy {
	private unsubscribe: Subject<void> = new Subject<void>();
	items: MenuItemModel[];
	invoiceTabs: InvoiceTabStateMap;
	private lastRoute = '/accounting/invoices/dashboard';

	staticTabs: StatefulTabItem[] = [
		{header: {text: 'Invoices', iconCss: 'fa fa-file-text'}, defaultRoute: '/accounting/invoices/dashboard'},
	] as StatefulTabItem[];
	private locationId: number;
	private INVOICE_BASE_ROUTE = '/accounting/invoices';
	userCanCreateInvoice = false;
	isMoveDropdownOptionsFeatureFlagOn: boolean;

	constructor(
		private store: Store<State>,
		public modalManagerService: ModalManagerService,
		public invoiceService: InvoiceService,
		private userLocationsService: UserLocationsService,
		private securityManagerService: SecurityManagerService,
		public routerUtilsService: RouterUtilsService,
		private router: Router,
		public featureService: FeatureService,
	) {
		this.store.select(selectInvoiceCurrentTabRoute).pipe(takeUntil(this.unsubscribe)).subscribe((route) => {
			this.lastRoute = route;
		});
		this.observeRouterEvents();
	}

	ngOnInit(): void {
		this.isMoveDropdownOptionsFeatureFlagOn = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.MOVE_ADD_DROPDOWN_OPTIONS);
		this.userCanCreateInvoice = this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_ACCOUNTING_INVOICE_CREATE);
		this.createItems();
		this.locationId = this.userLocationsService.getCurrentUserLocation().id;
		this.store.select(selectFormState({key: INVOICES_DASHBOARD_COMPONENT})).pipe(takeUntil(this.unsubscribe)).subscribe(formstate => {
			if (!_isNil(formstate?.value)) {
				this.locationId = formstate.value.locationId;
			}
		});
	}

	ngOnDestroy() {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	confirmationFunction = (item: InvoiceModuleTabState) => item.subType === 'payment' ? 'Are you sure you wish to close and lose any unsaved changes?' : null;

	observeRouterEvents() {
		this.routerUtilsService.observeNavigationEvents(NavigationEnd)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(() => this.handleRouteChanges());
	}

	async handleRouteChanges() {
		const currentRouteString = this.getCurrentRoute();
		if (currentRouteString === this.INVOICE_BASE_ROUTE) {
			// go to last available route
			await this.router.navigateByUrl(this.lastRoute);
		} else if (currentRouteString.startsWith(this.INVOICE_BASE_ROUTE)) {
			// persist last route
			this.store.dispatch(new SaveInvoiceCurrentTab(
				UpdateStatePropertyPayload.build(
					InvoiceCurrentTabState, 'route', currentRouteString,
				),
			));
		}
	}

	getCurrentRoute() {
		const currentRoute = this.router.getCurrentNavigation().finalUrl;
		return currentRoute ? currentRoute.toString() : '';
	}

	onTabsCreated() {
		this.store.select(selectInvoiceTabState).pipe(
			takeUntil(this.unsubscribe),
		).subscribe(invoiceTabState => this.invoiceTabs = invoiceTabState);
	}

	onTabRemoved(removedDefaultRoute: string) {
		const tab = _find(Array.from(this.invoiceTabs), ([_tabInvoiceId, invoiceTabState]) => invoiceTabState.defaultRoute === removedDefaultRoute);
		if (!_isNil(tab)) {
			const invoiceId = tab[0];
			this.store.dispatch(new RemoveInvoiceTab({invoiceId}));
			this.invoiceService.removeInvoiceDetailsState(invoiceId);
		}
	}


	handleNavAction(selectedItem: MenuEventArgs) {
		const payerType = EnumUtil.findEnumByValue(parseInt(selectedItem.item.id, 10), PayerType);
		switch (payerType) {
			case PayerType.PATIENT:
				this.openNewPatientInvoiceModal();
				break;
			case PayerType.INSURANCE:
				this.openNewInsuranceInvoiceModal();
				break;
			case PayerType.ANONYMOUS:
				this.openNewGuestInvoiceModal();
				break;
		}
	}

	createItems() {
		this.items = [
			{
				text: 'NEW',
				separator: true,
			},
			{
				text: `${PayerType.PATIENT.label} Invoice`,
				id: PayerType.PATIENT.value.toString(),
			},
			{
				text: `${PayerType.INSURANCE.label} Invoice`,
				id: PayerType.INSURANCE.value.toString(),
			},
			{
				text: `${PayerType.ANONYMOUS.label} Invoice`,
				id: PayerType.ANONYMOUS.value.toString(),
			},
		];
	}

	getValidLocation() {
		return _isNil(this.locationId) ? this.userLocationsService.getCurrentUserLocation().id : this.locationId;
	}

	openNewPatientInvoiceModal() {
		this.invoiceService.newPatientInvoiceModalWizard({locationId: this.getValidLocation()});
	}

	openNewInsuranceInvoiceModal() {
		this.invoiceService.newInsuranceInvoiceModalWizard({locationId: this.getValidLocation()});
	}

	openNewGuestInvoiceModal() {
		this.invoiceService.newGuestInvoiceModal();
	}

	canCreateInvoiceAndDropdownFeatureFlagOn() {
		return this.userCanCreateInvoice && this.isMoveDropdownOptionsFeatureFlagOn;
	}

	canCreateInvoiceAndDropdownFeatureFlagOff() {
		return this.userCanCreateInvoice && !this.isMoveDropdownOptionsFeatureFlagOn;
	}
}
