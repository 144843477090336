import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';

@NgModule({
	declarations: [
	],
	imports: [AgGridModule],
	exports: [
		AgGridModule,
	],
	providers: [
	],
})
export class MorganaAgGridModule { }
