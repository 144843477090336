import { _isNil } from '@core/lodash/lodash';
import { OptionItem } from 'morgana';

type LabelExpression<T> = (entity: T) => string;
type ValueExpression<T> = (entity: T) => any;

export class DropdownUtils {

	constructor() { }

	static createDropdownList<T, L extends keyof T, V extends keyof T>(entities: T[], labelKey: L | LabelExpression<T>, valueKey: V | ValueExpression<T>): OptionItem[] {
		return entities.map(entity => ({
			label: typeof labelKey !== 'string' ? (labelKey as LabelExpression<T>)(entity) : entity[labelKey],
			value: typeof valueKey !== 'string' ? (valueKey as LabelExpression<T>)(entity) : entity[valueKey],
		} as OptionItem));
	}

	static addOptionItem(optionItems: OptionItem[], label: string, value: any, addIndex?: number): OptionItem[] {
		const startIndex = !_isNil(addIndex) ? addIndex : optionItems.length;
		const itemToAdd = {label, value} as OptionItem;
		return [...optionItems.slice(0, startIndex), itemToAdd, ...optionItems.slice(startIndex)] as OptionItem[];
	}
}
