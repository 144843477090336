// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PracticeFileInfoResponse } from './practice-file-info-response';

/** See com.rev360.pms.api.controller.document.ConsentFormListResponse */
/* istanbul ignore next */
@GandalfModel
export class ConsentFormListResponse extends GandalfModelBase {

	@GandalfProperty()
	comments: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	expirationType: constants.PracticeConsentFormExpirationType;

	@GandalfProperty()
	expireDurationAmount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	expireDurationUnit: constants.DurationUnit;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	name: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	required: constants.PracticeConsentFormRequired;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.PracticeConsentFormStatus;

	@GandalfProperty()
	template: PracticeFileInfoResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	type: constants.PracticeConsentFormType;

}
