// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { MessageParticipantNameResponse } from './message-participant-name-response';

/** See com.rev360.pms.api.controller.messaging.ReplyAllDataResponse */
/* istanbul ignore next */
@GandalfModel
export class ReplyAllDataResponse extends GandalfModelBase {

	@GandalfProperty()
	createdOn: Date;

	@GandalfProperty()
	messageContent: string;

	@GandalfProperty()
	messageId: number;

	@GandalfArray(MessageParticipantNameResponse)
	nonReplyingAllMessageParticipants: MessageParticipantNameResponse[];

	@GandalfProperty()
	originalMessageSender: MessageParticipantNameResponse;

	@GandalfProperty()
	parentMessageId: number;

	@GandalfProperty()
	replyingAllMessageParticipantId: number;

	@GandalfProperty()
	rootMessageId: number;

	@GandalfProperty()
	subject: string;

}
