import { LegacyStatefulComponentUtilsService } from '@app-store/utils/legacy-stateful-component-utils.service';
import { InvoiceActions, InvoiceActionTypes } from '@invoices-store/actions';

export class InvoiceCurrentTabState {
	constructor(public route?: string) {
	}
}

export const initialState: InvoiceCurrentTabState = new InvoiceCurrentTabState('/accounting/invoices/dashboard');

export function reducer(state = initialState, action: InvoiceActions): InvoiceCurrentTabState {
	switch (action.type) {
		case InvoiceActionTypes.SAVE_INVOICE_CURRENT_TAB: {
			return LegacyStatefulComponentUtilsService.updateProperty(state, InvoiceCurrentTabState, action.payload);
		}
		default: {
			return state;
		}
	}
}
