import { _get } from '@core/lodash/lodash';
import { createSelector } from '@ngrx/store';
import { selectPatientsState } from '@patients-store/reducers';
import { PatientDashboardStateMap } from '@patients-store/reducers/patient-dashboard.reducer';

export const selectPatientDashboardState =
	createSelector(selectPatientsState, state => state.patientDashboardState);

export const selectPatientDashboardSummary = (props: {patientId: number}) =>
	createSelector(selectPatientDashboardState, (state: PatientDashboardStateMap) => _get(state.get(props.patientId), ['dashboardSummary']));
