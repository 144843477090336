// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { BusinessPhonesResponse } from './business-phones-response';

/** See com.rev360.pms.api.controller.admin.general.location.AdminPracticeLocationDetailsResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminPracticeLocationDetailsResponse extends GandalfModelBase {

	@GandalfProperty()
	address: AddressResponse;

	@GandalfProperty()
	barcodeIdentifier: string;

	@GandalfProperty()
	billingLocationClaimsId: number;

	@GandalfProperty()
	billingLocationStatementsId: number;

	@GandalfProperty()
	billingProviderName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	claimNpiEntity: constants.ClaimNpiEntity;

	@GandalfProperty()
	coInsuranceTaxId: number;

	@GandalfProperty()
	coPayTaxId: number;

	@GandalfProperty()
	deductibleTaxId: number;

	@GandalfProperty()
	defaultFinanceChargePlanId: number;

	@GandalfProperty()
	email: string;

	@GandalfProperty()
	facilityNumber: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	facilityType: constants.FacilityType;

	@GandalfProperty()
	fein: string;

	@GandalfProperty()
	logoPracticeFileId: number;

	@GandalfProperty()
	name: string;

	@GandalfProperty()
	npi: string;

	@GandalfProperty()
	payToAddress: AddressResponse;

	@GandalfProperty()
	phones: BusinessPhonesResponse;

	@GandalfProperty()
	printNameAs: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	providerBillingId: constants.BillingId;

	@GandalfConstantDecorator()
	@GandalfProperty()
	serviceLocationIndicator: constants.ServiceLocationIndicator;

	@GandalfProperty()
	storeNumber: string;

	@GandalfProperty()
	websiteUrl: string;

}
