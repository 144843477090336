import { Injectable } from '@angular/core';
import dayjs from 'dayjs';

@Injectable({
	providedIn: 'root',
})
export class CalendarUtilsService {

	constructor() {
	}

	/**
	 * Returns a year string like "1999:2029", for use in p-calendar components where the yearNavigator option is used.
	 */
	getYearRangeForYearNavigator(fullYear: number = new Date().getFullYear()) {
		return `${fullYear - 20}:${fullYear + 10}`;
	}

	getMinDateForNavigator(date: Date = new Date()) {
		return dayjs(date).subtract(20, 'year').startOf('year').toDate();
	}

	getMaxDateForNavigator(date: Date = new Date()) {
		return dayjs(date).add(10, 'year').endOf('year').toDate();
	}

}
