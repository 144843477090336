<div [formGroup]="parentFormGroup">
	<input class="e-input"
		   [disabled]="disabled"
		   [(ngModel)]="phoneNumber"
		   [ngModelOptions]="{standalone: true}"
		   (blur)="validateAndFormatPhoneNumber()"
		   (keydown.enter)="validateAndFormatPhoneNumber()"
		   type="text"
	/>
</div>
