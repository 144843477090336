import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { CalendarUtilsService } from '@core/calendar-utils/calendar-utils.service';
import { DynamicModalRef, ModalConfig, OptionItem, PAGE_LENGTH, PAGE_LENGTH_LIST } from 'morgana';
import { PatientFamilyService } from '@core/patient/family/patient-family.service';
import { ReferenceDataService } from '@core/reference-data/reference-data.service';
import { UserLocationsService } from '@core/user-locations/user-locations.service';
import { PatientSearchStatus, ReferenceDataMasterCategory } from '@gandalf/constants';
import { AddFamilyMemberRequest } from '@gandalf/model/add-family-member-request';
import { PatientSearchRequest } from '@gandalf/model/patient-search-request';
import { FormattedPatientSearchResponse, SearchPatientService } from '@shared/component/search-patient/search-patient.service';
import { DATE_FORMATS, TABLE_DATE_FORMATS } from '@shared/constants/date-format.constants';
import { atLeastOne } from '@shared/validators/atleastOne.validation';
import { GridComponent, SortSettingsModel } from '@syncfusion/ej2-angular-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';
import { FeatureService } from '@core/feature/feature.service';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { AddFamilyMemberWithEmergencyContactRequest } from '@gandalf/model/add-family-member-with-emergency-contact-request';
import { OptionItemResponse } from '@core/option-item/option-item.service';
import { PracticeLocation } from '@core/security-manager/security-manager.service';
import { EventService } from '@core/event/event.service';
import { _isEmpty, _isNil } from '@core/lodash/lodash';

@Component({
	selector: 'pms-family-member-add-modal',
	templateUrl: './family-member-add-modal.component.html',
	styles: [],
})
export class FamilyMemberAddModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('templateForm')
	templateForm: FormGroupDirective;

	@ViewChild('grid')
	grid: GridComponent;

	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	dateMin: Date;
	dateMax: Date;
	locations: OptionItemResponse<PracticeLocation, number>[];
	patientSearchStatus = PatientSearchStatus.VALUES.values;
	isSearching = false;
	searchForm: UntypedFormGroup;
	componentForm: UntypedFormGroup;
	patientSearchRequest: PatientSearchRequest;
	addPatientToFamilyRequest: AddFamilyMemberWithEmergencyContactRequest | AddFamilyMemberRequest;
	sortOptions: SortSettingsModel;
	dobFormatter = TABLE_DATE_FORMATS.MM_DD_YYYY;
	patientId: number;
	familyRoleOptions: OptionItem[];
	isEmergencyContactFeatureOn: boolean;

	patientData: FormattedPatientSearchResponse[];
	pageSettings = {
		pageSizes: PAGE_LENGTH_LIST,
		pageSize: PAGE_LENGTH.PAGE_10,
	};

	constructor(
		public dynamicModalRef: DynamicModalRef,
		private modalConfig: ModalConfig,
		public searchPatientService: SearchPatientService,
		private gandalfFormBuilder: GandalfFormBuilder,
		private calendarUtilsService: CalendarUtilsService,
		private userLocationsService: UserLocationsService,
		private referenceDataService: ReferenceDataService,
		private patientFamilyService: PatientFamilyService,
		private featureService: FeatureService,
		private eventService: EventService,
	) {
	}

	ngOnInit() {
		this.patientId = this.modalConfig.data.patientId;
		this.isEmergencyContactFeatureOn = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.EMERGENCY_CONTACT);

		this.dateMin = this.calendarUtilsService.getMinDateForNavigator();
		this.dateMax = this.calendarUtilsService.getMaxDateForNavigator();
		this.locations = this.userLocationsService.getUserLocations();

		this.sortOptions = {
			columns: [
				{field: 'formattedName', direction: 'Ascending'},
			],
		};
		this.createForm();
		this.getFormData();
		this.patientData = [];
	}

	createForm() {
		this.patientSearchRequest = new PatientSearchRequest();
		this.patientSearchRequest.patientSearchStatus = PatientSearchStatus.ACTIVE;
		this.searchForm = this.gandalfFormBuilder.group(this.patientSearchRequest, {
			validators: [
				atLeastOne(
					Validators.required,
					this.searchPatientService.getAtLeastOneFields(),
					this.searchPatientService.getAtLeastOneMessage(),
				)],
		});

		if (this.isEmergencyContactFeatureOn) {
			const addRequest = new AddFamilyMemberWithEmergencyContactRequest();
			addRequest.isEmergencyContact = false;
			addRequest.isPrimaryEmergencyContact = false;
			this.addPatientToFamilyRequest = addRequest;
		} else {
			this.addPatientToFamilyRequest = new AddFamilyMemberRequest();
		}
		this.addPatientToFamilyRequest.familyPatientId = this.patientId;
		this.addPatientToFamilyRequest.headOfHousehold = false;
		this.componentForm = this.gandalfFormBuilder.group(this.addPatientToFamilyRequest);
	}

	getFormData() {
		this.referenceDataService.getActiveReferenceDataByCategoryIdForDropdown(
			ReferenceDataMasterCategory.FAMILY_MEMBER_ROLE.value,
		).subscribe(familyMemberRoles => {
			this.familyRoleOptions = familyMemberRoles;
		});
	}

	clear(templateForm: FormGroupDirective) {
		templateForm.resetForm(this.patientSearchRequest);
		this.patientData = [];
	}

	search() {
		if (this.searchForm.invalid) {
			return;
		}
		this.isSearching = true;
		this.searchPatientService.searchPatients(this.searchForm.value).subscribe(data => {
			this.isSearching = false;
			this.patientData = data.filter(patient => patient.patientId !== this.patientId);
		});
	}

	patientSelected(event) {
		this.componentForm.get('patientIdToAdd').setValue(event.data.patientId);
	}

	/* istanbul ignore next */
	closeModal(result?) {
		this.dynamicModalRef.close(this.modal, result);
	}

	addToFamilyAccount() {
		if (this.componentForm.invalid) {
			return;
		}

		if (this.isEmergencyContactFeatureOn) {
			this.patientFamilyService.addPatientToFamilyAccountWithEmergencyContact(this.componentForm.value).subscribe(() => {
				this.eventService.publishUpdatePatientFamilyMembers(this.patientId);
				this.closeModal(true);
			});
		} else {
			this.patientFamilyService.addPatientToFamilyAccount(this.componentForm.value).subscribe(() => {
				this.eventService.publishUpdatePatientFamilyMembers(this.patientId);
				this.closeModal(true);
			});
		}
	}

	/* istanbul ignore next */
	submitForm(event) {
		this.templateForm.onSubmit(event);
	}

	nothingIsFilled() {
		if (_isNil(this.searchForm)) {
			return true;
		}

		return !!(_isEmpty(this.searchForm.get('firstName').value) &&
			_isEmpty(this.searchForm.get('lastName').value) &&
			_isEmpty(this.searchForm.get('phoneNumber').value) &&
			_isEmpty(this.searchForm.get('socialSecurityNumber').value) &&
			_isEmpty(this.searchForm.get('socialInsuranceNumber').value) &&
			_isEmpty(this.searchForm.get('patientId').value) &&
			_isNil(this.searchForm.get('dateOfBirth').value));
	}

	isSearchDisabled() {
		if (this.nothingIsFilled() || this.isSearching) {
			return true;
		}

		// disable searching if first or last name are less than 2 characters (only if not empty)
		if (!_isEmpty(this.searchForm.get('firstName').value) && this.searchForm.get('firstName').value.length < 2) {
			return true;
		}

		if (!_isEmpty(this.searchForm.get('lastName').value) && this.searchForm.get('lastName').value.length < 2) {
			return true;
		}

		return false;
	}

}
