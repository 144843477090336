import { Component, Input } from '@angular/core';
import { ContactLensPrescriptionResponse } from '@gandalf/model/contact-lens-prescription-response';
import { ContactLensTrialResponse } from '@gandalf/model/contact-lens-trial-response';

@Component({
	selector: 'pms-contact-lens-type-rgp',
	templateUrl: './contact-lens-type-rgp.component.html',
})
export class ContactLensTypeRgpComponent {

	@Input()
	contactLensPrescription: ContactLensPrescriptionResponse | ContactLensTrialResponse;

	constructor() {
	}

}
