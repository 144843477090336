import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { DynamicModalRef, ModalConfig, OptionItem } from 'morgana';
import { ReferenceDataService } from '@core/reference-data/reference-data.service';
import { ReferenceDataMasterCategory } from '@gandalf/constants';
import { CancelPrescriptionRequest } from '@gandalf/model/cancel-prescription-request';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';
import { Observable } from 'rxjs';

@Component({
	selector: 'pms-cancel-prescription-modal',
	templateUrl: './cancel-prescription-modal.component.html',
	styles: [],
})
export class CancelPrescriptionModalComponent implements OnInit {

	@ViewChild('modal')
	cancelPrescriptionModal: DialogComponent;

	@ViewChild('cancelPrescriptionForm')
	cancelPrescription: NgForm;

	prescriptionId: number;
	onSave: (request) => Observable<any>;
	request: CancelPrescriptionRequest;
	componentForm: UntypedFormGroup;
	reasons: OptionItem[];

	constructor(
		private dynamicModalRef: DynamicModalRef,
		private config: ModalConfig,
		private referenceDataService: ReferenceDataService,
		private gandalfFormBuilder: GandalfFormBuilder,
	) {

	}

	ngOnInit() {
		this.parseConfigData(this.config.data);
		this.request = this.createRequest(new Date());
		this.componentForm = this.createForm();
		this.getFormData();
	}

	parseConfigData(data) {
		this.prescriptionId = data.prescriptionId;
		this.onSave = data.onSave;
	}

	closeModal(request?: CancelPrescriptionRequest) {
		this.dynamicModalRef.close(this.cancelPrescriptionModal, request);
	}

	createRequest(stopDate: Date): CancelPrescriptionRequest {
		const request = new CancelPrescriptionRequest();
		request.id = this.prescriptionId;
		request.stopDate = stopDate;
		return request;
	}

	createForm(): UntypedFormGroup {
		return this.gandalfFormBuilder.group(this.request);
	}

	getFormData(): void {
		const categoryId = ReferenceDataMasterCategory.PRESCRIPTION_STOP_REASONS.value;
		this.referenceDataService.getActiveReferenceDataByCategoryIdForDropdown(categoryId).subscribe(reasons => {
			this.reasons = reasons;
		});
	}

	submitForm(event): void {
		this.cancelPrescription.onSubmit(event);
	}

	save(): void {
		if (this.componentForm.invalid) {
			return;
		}

		if (this.onSave) {
			this.onSave(this.componentForm.value).subscribe(() => {
				this.closeModal(this.componentForm.value);
			});
		} else {
			this.closeModal(this.componentForm.value);
		}
	}
}
