import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { EnumUtil, ForceDecimalPipe, GRID_STABILIZE_DEBOUNCE, GridUtil, PAGE_LENGTH, PAGE_LENGTH_LIST } from 'morgana';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { InventoryService } from '@core/inventory/inventory.service';
import { HIT_PMS_HTML_EVENTS } from '@core/legacy/hit-pms-html.constants';
import { _filter, _isNil, _map } from '@core/lodash/lodash';
import { ReferenceDataService } from '@core/reference-data/reference-data.service';
import { LocationProductItemTableResponse } from '@gandalf/model/location-product-item-table-response';
import { FindActiveLocationProductsRequest } from '@gandalf/model/find-active-location-products-request';
import { LocationProductTableResponse } from '@gandalf/model/location-product-table-response';
import { ContactLensType, MasterProductCategory, ReferenceDataMasterCategory, ReferenceDataMasterId } from '@gandalf/constants';
import { ProductCategoryResponse } from '@gandalf/model/product-category-response';
import { ContactLensFilterOptions } from '@shared/component/barcode-search/barcode-search.component';
import { PadAxisPipe } from '@shared/pipes/pad-axis/pad-axis.pipe';
import { PlusMinusPipe } from '@shared/pipes/plus-minus/plus-minus.pipe';
import { FilterService, GridComponent } from '@syncfusion/ej2-angular-grids';
import { PageSettingsModel } from '@syncfusion/ej2-grids';
import { Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

export interface FormattedLocationProduct {
	name: string;
	id: number;
	manufacturerName: string;
	collectionName: string;
	contactLensType: ContactLensType;
	eyeGlassLensTypeId: number;
	productCode: string;
	brandName: string;
	price: string;
	original: LocationProductTableResponse;
	$validators?: any;
	$label?: any;
}

export interface FormattedLocationProductItem {
	id: number;
	description: string;
	locationProductId: number;
	upc: string;
	addPower: string;
	addName: string;
	color: string;
	eye: string;
	bridge: string;
	temple: string;
	packaging: string;
	retail: string;
	stockQuantity: string;
	material: string;
	sku: string;
	diameter: string;
	axis: string;
	lensType: string;
	stocked: boolean;
	cylinder: string;
	sphere: string;
	isTrial: boolean;
	baseCurve: string;
	colorCode: string;
	$validators?: any;
	$label?: any;
	original: LocationProductItemTableResponse;
}

@Component({
	selector: 'pms-product-selection',
	templateUrl: './product-selection.component.html',
	providers: [FilterService],
})
export class ProductSelectionComponent implements OnInit, OnDestroy {
	@ViewChild('productGrid')
	productGrid: GridComponent;

	@ViewChild('itemsGrid')
	itemsGrid: GridComponent;

	@Output()
	locationProductSelected = new EventEmitter<LocationProductTableResponse>();

	@Output()
	locationProductItemSelected = new EventEmitter<LocationProductItemTableResponse>();

	@Input()
	set locationId(value: number) {
		this._locationId = value;
		this.getLocationProducts();
	}

	get locationId(): number {
		return this._locationId;
	}

	@Input()
	set productCategory(value: ProductCategoryResponse) {
		this._productCategory = value;
		this.focusedProduct = undefined;
		this.getLocationProducts();
	}

	get productCategory(): ProductCategoryResponse {
		return this._productCategory;
	}

	@Input()
	productSubCategoryId: ReferenceDataMasterId;

	@Input()
	smartflowMasterSupplierId: number;

	@Input()
	doNotFocus: boolean;

	@Input()
	trialFilter = ContactLensFilterOptions.NONE;

	@Input()
	contactLensType: ContactLensType;

	@Input()
	onlyProductSelection = false;

	isSearching = false;
	locationProducts: FormattedLocationProduct[] = [];
	filteredLocationProducts: FormattedLocationProduct[] = [];
	eyeGlassLensTypes: any[] = [];
	defaultContactLensType = ContactLensType.SOFT;
	locationProductItems: FormattedLocationProductItem[] = [];
	isSearchingProductItems = false;
	focusedProduct: LocationProductTableResponse;
	contactTypeOptions = [{label: 'Trial', value: true}, {label: 'Rx', value: false}];
	stockedTypeOptions = [{label: 'Yes', value: true}, {label: 'No', value: false}];
	_productCategory: ProductCategoryResponse;
	_locationId: number;
	pageSettings: PageSettingsModel = {
		pageSizes: PAGE_LENGTH_LIST,
		pageSize: PAGE_LENGTH.PAGE_10,
		enableQueryString: false,
	};
	contactLensTypes = {
		label: 'Contact Lens Type',
		values: [ContactLensType.RGP, ContactLensType.SOFT, ContactLensType.SYNERGEYES],
	};
	sortComparer = GridUtil.gridNumberStringsComparator;
	needsFocus = true;
	unsubscribe$: Subject<void> = new Subject();
	focusFilter = new Subject<void>();
	trialFilterInitialValue = null;

	constructor(
		public inventoryService: InventoryService,
		public referenceDataService: ReferenceDataService,
		public eventsManagerService: EventsManagerService,
		private currencyPipe: CurrencyPipe,
		private padAxisPipe: PadAxisPipe,
		private plusMinusPipe: PlusMinusPipe,
		private forceDecimalPipe: ForceDecimalPipe,
	) {
	}

	ngOnInit() {
		this.externallySetContactLensType();
		this.getEyeGlassLensTypes();
		if (this.trialFilter !== ContactLensFilterOptions.NONE) {
			this.trialFilterInitialValue = ContactLensFilterOptions.TRIAL === this.trialFilter;
		}
		this.prepareFocus();
	}

	externallySetContactLensType() {
		if (!_isNil(this.contactLensType)) {
			this.defaultContactLensType = this.contactLensType;
		}
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	/* istanbul ignore next */
	gridDataBound(grid: GridComponent) {
		GridUtil.gridStringSearchDefaultContains(grid);
	}

	productGridDataBound(grid: GridComponent) {
		this.gridDataBound(grid);
		this.tryFocusFilter();
	}

	tryFocusFilter() {
		if (this.needsFocus && !this.doNotFocus) {
			this.focusFilter.next();
		}
	}

	getLocationProducts() {
		if (this.productGrid) {
			this.productGrid.clearFiltering();
		}
		if (!_isNil(this.productCategory) && !_isNil(this._locationId)) {
			this.isSearching = true;
			const request = new FindActiveLocationProductsRequest();
			request.locationId = this._locationId;
			request.productCategoryId = this._productCategory.id;
			this.inventoryService.findActiveLocationProductSummaries(request)
				.pipe(map(items => _map(items, item => this.makeFormattedLocationProduct(item))))
				.subscribe(responses => {
					this.handleLocationProductResponses(responses);
				});
		}
	}

	handleLocationProductResponses(responses) {
		this.locationProducts = responses;
		this.filteredLocationProducts = this.locationProducts;
		if (this.isContactLensCategory()) {
			// this has a default filter applied so apply it immediately
			this.filterByContactLensType(this.defaultContactLensType);
		}
		this.filterBySmartflowSupplier(this.smartflowMasterSupplierId);
		this.filterByProductSubCategory(this.productSubCategoryId);
		this.isSearching = false;
		this.needsFocus = true;
		this.tryFocusFilter();
	}

	filterBySmartflowSupplier(smartflowMasterSupplierId: number) {
		if (!_isNil(smartflowMasterSupplierId)) {
			this.filteredLocationProducts = _filter(this.filteredLocationProducts,
				(item: FormattedLocationProduct) => item.original.smartflowMasterSupplierId === smartflowMasterSupplierId);
		}
	}

	filterByProductSubCategory(productSubCategoryId: ReferenceDataMasterId) {
		if (!_isNil(productSubCategoryId)) {
			this.filteredLocationProducts = _filter(this.filteredLocationProducts,
				(item: FormattedLocationProduct) => item.original.subCategoryId === productSubCategoryId.value);
		}
	}

	makeFormattedLocationProduct(item) {
		return {...item, price: this.currencyPipe.transform(item.price), original: item};
	}

	onSelectButtonClick() {
		if (this.productGrid.getSelectedRecords().length > 0) {
			this.locationProductSelected.emit(this.productGrid.getSelectedRecords()[0]['original']);
		}
	}

	isContactLensCategory() {
		return this.productCategory && !_isNil(this.productCategory.masterCategory) &&
			EnumUtil.equals(this.productCategory.masterCategory, MasterProductCategory.CONTACT_LENSES);
	}

	getEyeGlassLensTypes() {
		this.referenceDataService.getActiveMasterReferenceDataByCategory(ReferenceDataMasterCategory.EYEGLASS_RX_LENS_TYPES).subscribe(data => {
			this.eyeGlassLensTypes = data;
		});
	}

	isEyeGlassLensCategory() {
		return this.productCategory && !_isNil(this.productCategory.masterCategory) &&
			EnumUtil.equals(this.productCategory.masterCategory, MasterProductCategory.EYEGLASS_LENSES);
	}

	isEyeGlassFrameCategory() {
		return this.productCategory && !_isNil(this.productCategory.masterCategory) &&
			EnumUtil.equals(this.productCategory.masterCategory, MasterProductCategory.EYEGLASS_FRAMES);
	}

	filterByEyeGlassLensType(lensTypeId) {
		if (!_isNil(lensTypeId) && this.isEyeGlassLensCategory()) {
			this.filteredLocationProducts = _filter(this.locationProducts, (item: FormattedLocationProduct) => item.eyeGlassLensTypeId === lensTypeId);
		} else {
			this.filteredLocationProducts = this.locationProducts;
		}
	}

	filterByContactLensType(type) {
		if (!_isNil(type) && this.isContactLensCategory()) {
			this.filteredLocationProducts = _filter(this.locationProducts,
				(item: FormattedLocationProduct) => !item.contactLensType || item.contactLensType.value === type.value);
		} else {
			this.filteredLocationProducts = this.locationProducts;
		}
	}

	get headerText() {
		return `Products - ${this.productCategory.name}`;
	}

	refreshItems() {
		this.getItems();
	}

	getItems() {
		this.isSearchingProductItems = true;
		this.inventoryService.findActiveLocationProductItems(this.focusedProduct.id)
			.pipe(map(items => _map(items, item => this.makeFormattedLocationProductItem(item))))
			.subscribe(results => {
				this.locationProductItems = results;
				this.isSearchingProductItems = false;
			});
	}

	makeFormattedLocationProductItem(item) {
		return {
			...item,
			baseCurve: this.forceDecimalPipe.transform(item.baseCurve, 2),
			cylinder: this.plusMinusPipe.transform(item.cylinder),
			axis: this.padAxisPipe.transform(item.axis),
			diameter: this.forceDecimalPipe.transform(item.diameter, 2),
			addPower: this.plusMinusPipe.transform(item.addPower),
			eye: this.forceDecimalPipe.transform(item.eye, 1),
			bridge: this.forceDecimalPipe.transform(item.bridge, 1),
			temple: this.forceDecimalPipe.transform(item.temple, 1),
			sphere: this.plusMinusPipe.transform(item.sphere),
			stockQuantity: item.stockQuantity.toString(),
			retail: this.currencyPipe.transform(item.retail),
			original: item,
		};
	}

	productFocused(data: LocationProductTableResponse) {
		if (this.onlyProductSelection) {
			this.locationProductSelected.emit(data);
		} else {
			this.focusedProduct = data;
			if (this.itemsGrid) {
				this.itemsGrid.clearFiltering();
				this.itemsGrid.refreshColumns();
				this.itemsGrid.refreshHeader();
			}
			this.getItems();
		}
	}

	productUnFocused() {
		this.focusedProduct = undefined;
	}

	rowDoubleClicked(data: LocationProductTableResponse) {
		this.productUnFocused();
		this.locationProductSelected.emit(data);
	}

	searchUPC(data) {
		this.eventsManagerService.publish(HIT_PMS_HTML_EVENTS.INVENTORY.INVENTORY_MODULE_PRODUCT_ITEM_INVENTORY_LOOKUP, {productItemId: data.id});
	}

	itemSelected(data: LocationProductItemTableResponse) {
		this.locationProductItemSelected.emit(data);
	}

	filterData($event, field) {
		if (_isNil($event)) {
			this.itemsGrid.removeFilteredColsByField(field);
		} else {
			this.itemsGrid.filterByColumn(field, 'equal', $event);
		}
	}

	private prepareFocus() {
		this.focusFilter.asObservable().pipe(debounceTime(GRID_STABILIZE_DEBOUNCE), takeUntil(this.unsubscribe$)).subscribe(() => {
			GridUtil.setFocus('name_filterBarcell');
			this.needsFocus = false;
		},
		);
	}
}
