/* eslint-disable max-len */
import { UpdateStatePropertyPayload } from '@app-store/utils/update-state-property-payload';
import { InvoiceResponse } from '@gandalf/model/invoice-response';
import { Action } from '@ngrx/store';

export enum InvoiceActionTypes {
	UPSERT_INVOICE_DETAILS = '[Add OR UPDATE INVOICE DETAILS] Adds or Updates the INVOICE DETAILS',
	UPDATE_INVOICE_DETAILS_SHOW_ALL = '[UPDATE INVOICE DETAILS] Updates the INVOICE DETAILS show all state',
	REMOVE_INVOICE_DETAILS_STATE = '[REMOVE INVOICE DETAILS] Removes the INVOICE DETAILS state within the store.',

	UPDATE_INVOICE_CLAIM_HISTORY_TABLE_VALUE = '[Update INVOICE CLAIM_HISTORY Table State] Updates the value of the INVOICE CLAIM_HISTORY table',
	REMOVE_INVOICE_CLAIM_HISTORY_TABLE_STATE = '[Remove INVOICE CLAIM_HISTORY Table State] Removes the value of the INVOICE CLAIM_HISTORY table within the store.',

	UPDATE_INVOICE_PAYMENT_HISTORY_TABLE_VALUE = '[Update INVOICE PAYMENT_HISTORY Table State] Updates the value of the INVOICE PAYMENT_HISTORY table',
	REMOVE_INVOICE_PAYMENT_HISTORY_TABLE_STATE = '[Remove INVOICE PAYMENT_HISTORY Table State] Removes the value of the INVOICE PAYMENT_HISTORY table within the store.',

	UPDATE_INVOICE_STATEMENT_HISTORY_TABLE_VALUE = '[Update INVOICE STATEMENT_HISTORY Table State] Updates the value of the INVOICE STATEMENT_HISTORY table',
	REMOVE_INVOICE_STATEMENT_HISTORY_TABLE_STATE = '[Remove INVOICE STATEMENT_HISTORY Table State] Removes the value of the INVOICE STATEMENT_HISTORY table within the store.',

	UPDATE_INVOICE_DOCUMENTS_AND_IMAGES_TABLE_VALUE = '[Update INVOICE DOCUMENTS_AND_IMAGES Table State] Updates the value of the INVOICE DOCUMENTS_AND_IMAGES table',
	REMOVE_INVOICE_DOCUMENTS_AND_IMAGES_TABLE_STATE = '[Remove INVOICE DOCUMENTS_AND_IMAGES Table State] Removes the value of the INVOICE DOCUMENTS_AND_IMAGES table within the store.',

	SAVE_INVOICE_CURRENT_TAB = '[SAVE CURRENT INVOICE TAB] Saves the current invoice tab',
}

export class UpsertInvoiceDetails implements Action {
	readonly type = InvoiceActionTypes.UPSERT_INVOICE_DETAILS;
	constructor(public payload: { invoiceId: number, invoice: InvoiceResponse, currentTab?: number, showAll?: boolean }) {}
}
export class UpdateInvoiceDetailsShowAll implements Action {
	readonly type = InvoiceActionTypes.UPDATE_INVOICE_DETAILS_SHOW_ALL;
	constructor(public payload: { invoiceId: number, showAll: boolean }) {}
}

export class RemoveInvoiceDetailsState implements Action {
	readonly type = InvoiceActionTypes.REMOVE_INVOICE_DETAILS_STATE;
	constructor(public payload: { invoiceId: number }) {}
}

export class UpdateInvoiceClaimHistoryTableValue implements Action {
	readonly type = InvoiceActionTypes.UPDATE_INVOICE_CLAIM_HISTORY_TABLE_VALUE;
	constructor(public payload: { invoiceId: number, value: string }) {}
}

export class RemoveInvoiceClaimHistoryTableValueState implements Action {
	readonly type = InvoiceActionTypes.REMOVE_INVOICE_CLAIM_HISTORY_TABLE_STATE;
	constructor(public payload: { invoiceId: number }) {}
}

export class UpdateInvoicePaymentHistoryTableValue implements Action {
	readonly type = InvoiceActionTypes.UPDATE_INVOICE_PAYMENT_HISTORY_TABLE_VALUE;
	constructor(public payload: { invoiceId: number, value: string }) {}
}

export class RemoveInvoicePaymentHistoryTableValueState implements Action {
	readonly type = InvoiceActionTypes.REMOVE_INVOICE_PAYMENT_HISTORY_TABLE_STATE;
	constructor(public payload: { invoiceId: number }) {}
}

export class UpdateInvoiceStatementHistoryTableValue implements Action {
	readonly type = InvoiceActionTypes.UPDATE_INVOICE_STATEMENT_HISTORY_TABLE_VALUE;
	constructor(public payload: { invoiceId: number, value: string }) {}
}

export class RemoveInvoiceStatementHistoryTableValueState implements Action {
	readonly type = InvoiceActionTypes.REMOVE_INVOICE_STATEMENT_HISTORY_TABLE_STATE;
	constructor(public payload: { invoiceId: number }) {}
}

export class UpdateInvoiceDocumentsAndImagesTableValue implements Action {
	readonly type = InvoiceActionTypes.UPDATE_INVOICE_DOCUMENTS_AND_IMAGES_TABLE_VALUE;
	constructor(public payload: { invoiceId: number, value: string }) {}
}

export class RemoveInvoiceDocumentsAndImagesTableValueState implements Action {
	readonly type = InvoiceActionTypes.REMOVE_INVOICE_DOCUMENTS_AND_IMAGES_TABLE_STATE;
	constructor(public payload: { invoiceId: number }) {}
}

export class SaveInvoiceCurrentTab implements Action {
	readonly type = InvoiceActionTypes.SAVE_INVOICE_CURRENT_TAB;
	constructor(public payload: UpdateStatePropertyPayload) {}
}

export type InvoiceActions =
	| UpsertInvoiceDetails
	| UpdateInvoiceDetailsShowAll
	| UpdateInvoiceClaimHistoryTableValue
	| UpdateInvoicePaymentHistoryTableValue
	| UpdateInvoiceStatementHistoryTableValue
	| UpdateInvoiceDocumentsAndImagesTableValue
	| RemoveInvoiceDetailsState
	| RemoveInvoiceClaimHistoryTableValueState
	| RemoveInvoicePaymentHistoryTableValueState
	| RemoveInvoiceStatementHistoryTableValueState
	| RemoveInvoiceDocumentsAndImagesTableValueState
	| SaveInvoiceCurrentTab;
