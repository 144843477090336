import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { EditProfileModalComponent } from './edit-profile-modal/edit-profile-modal.component';
import { ManageUpdatesModalComponent } from './manage-updates-modal/manage-updates-modal.component';
import { HeaderComponent } from './header.component';
import { AskRevAssureModalComponent } from './ask-rev-assure-modal/ask-rev-assure-modal.component';
import { AskMeaningfulUseModalComponent } from './ask-meaningful-use-modal/ask-meaningful-use-modal.component';
import { SendFeedbackModalComponent } from './send-feedback-modal/send-feedback-modal.component';
import { TodaysPatientsDockableModalComponent } from './todays-patients-dockable-modal/todays-patients-dockable-modal.component';
import { PatientSearchComponent } from './patient-search/patient-search.component';
import { ExternalCredentialsComponent } from './edit-profile-modal/external-credentials/external-credentials.component';

@NgModule({
	declarations: [
		ManageUpdatesModalComponent,
		AskRevAssureModalComponent,
		AskMeaningfulUseModalComponent,
		SendFeedbackModalComponent,
		EditProfileModalComponent,
		HeaderComponent,
		TodaysPatientsDockableModalComponent,
		PatientSearchComponent,
		ExternalCredentialsComponent,
	],
	imports: [
		SharedModule,
	],
	exports: [
		HeaderComponent,
	],
})
export class HeaderModule {

	constructor() {
	}

}
