// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeResponse } from './contact-lens-eye-response';

// @ts-ignore
import { MasterReferenceDataResponse } from './master-reference-data-response';

// @ts-ignore
import { ProductForPrescriptionResponse } from './product-for-prescription-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

/** See com.rev360.pms.api.controller.encounter.contactlens.ContactLensPrescriptionDetailsResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensPrescriptionDetailsResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	authorizationDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	authorizationType: constants.PrescriptionAuthorizationType;

	@GandalfProperty()
	authorizedBy: ProviderResponse;

	@GandalfProperty()
	contactLensEyeOd: ContactLensEyeResponse;

	@GandalfProperty()
	contactLensEyeOs: ContactLensEyeResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	contactLensModality: constants.ContactLensModality;

	@GandalfProperty()
	deliveryMethodId: number;

	@GandalfProperty()
	description: string;

	@GandalfProperty()
	dispenseOdAmount: number;

	@GandalfProperty()
	dispenseOdUnit: MasterReferenceDataResponse;

	@GandalfProperty()
	dispenseOsAmount: number;

	@GandalfProperty()
	dispenseOsUnit: MasterReferenceDataResponse;

	@GandalfProperty()
	dropsName: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate: Date;

	@GandalfProperty()
	enzymaticCleanerName: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	expirationDate: Date;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	lensCleanerName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	overallLensType: constants.ContactLensPrescriptionType;

	@GandalfProperty()
	productOd: ProductForPrescriptionResponse;

	@GandalfProperty()
	productOs: ProductForPrescriptionResponse;

	@GandalfProperty()
	solutionName: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.PrescriptionStatus;

}
