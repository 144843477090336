// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeRequest } from './contact-lens-eye-request';

/** See com.rev360.pms.api.controller.prescription.ContactLensEyeHybridRequest */
/* istanbul ignore next */
@GandalfModel
export class ContactLensEyeHybridRequest extends ContactLensEyeRequest {

	@GandalfProperty()
	addPower: number;

	@GandalfProperty()
	addZoneSizeId: number;

	@GandalfProperty()
	axis: number;

	@GandalfProperty()
	baseCurve: number;

	@GandalfProperty()
	cylinder: number;

	@GandalfProperty()
	designId: number;

	@GandalfProperty()
	diameter: number;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	isMonovision: boolean;

	@GandalfProperty()
	locationProductId: number;

	@GandalfProperty()
	locationProductItemId: number;

	@GandalfProperty()
	overnightDurationAmount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	overnightDurationUnit: constants.DurationUnit;

	@GandalfProperty()
	productColorId: number;

	@GandalfProperty()
	replacementDurationAmount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	replacementDurationUnit: constants.DurationUnit;

	@GandalfProperty()
	segmentSizeId: number;

	@GandalfProperty()
	skirtCurveRadiusId: number;

	@GandalfProperty()
	sphere: number;

	@GandalfProperty()
	vault: number;

	@GandalfProperty()
	version: number;

}
