import { Directive } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { StatefulFormService } from '@app-store/services/stateful-form.service';
import { StatefulBaseComponent } from '@app-store/stateful-base.component';
import { FormMode } from '@shared/constants/form-mode.enum';
import { Observable } from 'rxjs';

type Constructor<T> = new (...args: any[]) => T;

export const statefulFormBaseKey = Symbol('StatefulForm');

export interface StatefulFormBase<F = any> extends StatefulBaseComponent {
	formMode: FormMode;
	formRestored: boolean;
	formSubmitted: boolean;
	componentForm: UntypedFormGroup | UntypedFormArray;
	statefulFormService: StatefulFormService;

	buildForm: () => Observable<F>;
}

export const StatefulForm = <T extends Constructor<StatefulBaseComponent>, F extends AbstractControl>(base: T) => {
	@Directive()
	abstract class StatefulFormMixin extends base implements StatefulFormBase<F> {
		private _formMode: FormMode;
		private _formRestored: boolean;
		private _formSubmitted: boolean;

		abstract componentForm: UntypedFormGroup | UntypedFormArray;
		abstract statefulFormService: StatefulFormService;

		abstract buildForm(): Observable<F>;

		patchFormData?(form: F): Observable<F>;

		get formMode(): FormMode {
			return this._formMode;
		}

		set formMode(formMode: FormMode) {
			this._formMode = formMode;
		}

		get formRestored(): boolean {
			return this._formRestored;
		}

		set formRestored(value: boolean) {
			this._formRestored = value;
		}

		get formSubmitted(): boolean {
			return this._formSubmitted;
		}

		set formSubmitted(submitted: boolean) {
			this._formSubmitted = submitted;
		}
	}

	// Mark prototype as StatefulForm for the StatefulComponent Decorator to consume.
	Reflect.defineMetadata(statefulFormBaseKey, true, StatefulFormMixin);
	return StatefulFormMixin;
};
