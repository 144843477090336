import { AbstractControl, ValidatorFn } from '@angular/forms';

const DIGITS_REQUIRED = 10;

/**
 * Validates that a form control's input contains at least 10 digits.
 *
 * Any additional formatting characters are also allowed.
 */
export const validatePhoneNumber: ValidatorFn = (control: AbstractControl) => {
	const value: any = control.value;
	if (!value) {
		return null;
	}

	const input = value.toString();
	if (input === '') {
		return null;
	}

	// Count the number of digits in the input
	let digits = 0;
	for (const char of input) {
		if (!isNaN(parseInt(char, 10))) {
			++digits;
		}
	}

	// Input containing 10 or more digits anywhere is valid
	if (digits >= DIGITS_REQUIRED) {
		return null;
	}

	return {
		phoneNumber: {
			valid: false,
		},
	};
};
