<form class="form-horizontal" [formGroup]="componentForm">
	<div class="table-responsive">
		<table class="table table-hover table-condensed table-nowrap">
			<thead>
			<tr>
				<th>
					<ejs-checkbox label="Select All"
							[disabled]="controlContainer.disabled"
							[attr.data-test-id]="'communicationSelectAll'"
							(change)="setAllValidOptions($event.checked)">
					</ejs-checkbox>
				</th>
				<th class="nowrap" [attr.data-test-id]="'optInLabel'">Opt-in</th>
				<th revTooltip [attr.data-test-id]="'homePhoneLabel'" tooltipContent="Home Phone" (click)="setAllValidHomePhoneOptions(true)">
					H<i class="fa fa-phone"></i>
				</th>
				<th revTooltip [attr.data-test-id]="'workPhoneLabel'" tooltipContent="Work Phone" (click)="setAllValidWorkPhoneOptions(true)">
					W<i class="fa fa-phone"></i>
				</th>
				<th revTooltip [attr.data-test-id]="'cellPhoneLabel'" tooltipContent="Cell Phone" (click)="setAllValidCellPhoneOptions(true)">
					C<i class="fa fa-phone"></i>
				</th>
				<th revTooltip [attr.data-test-id]="'emailLabel'" tooltipContent="Email" (click)="setAllValidEmailOptions(true)">
					<i class="fa fa-envelope"></i>
				</th>
				<th revTooltip [attr.data-test-id]="'textLabel'" tooltipContent="Text" (click)="setAllValidTextOptions(true)">
					<i class="fa fa-commenting"></i>
				</th>
				<th revTooltip [attr.data-test-id]="'mailLabel'" tooltipContent="Mail" (click)="setAllValidMailOptions(true)">
					<i class="fa fa-file-text"></i>
				</th>
			</tr>
			</thead>
			<tbody>
			<tr>
				<td>Appt. Conf.</td>
				<td class="nowrap">
					<pms-enum-select-button [enumList]="communicationOptInOptions"
											formControlName="appointmentsOptIn">
					</pms-enum-select-button>
				</td>
				<td>
					<ejs-checkbox formControlName="appointmentsHomePhoneAllowed"></ejs-checkbox>
				</td>
				<td>
					<ejs-checkbox formControlName="appointmentsWorkPhoneAllowed"></ejs-checkbox>
				</td>
				<td>
					<ejs-checkbox formControlName="appointmentsCellPhoneAllowed"></ejs-checkbox>
				</td>
				<td>
					<ejs-checkbox formControlName="appointmentsEmailAllowed"></ejs-checkbox>
				</td>
				<td>
					<ejs-checkbox formControlName="appointmentsTextAllowed"></ejs-checkbox>
				</td>
				<td>
					<ejs-checkbox formControlName="appointmentsMailAllowed"></ejs-checkbox>
				</td>
			</tr>
			<tr>
				<td>Recall</td>
				<td class="nowrap">
					<pms-enum-select-button [enumList]="communicationOptInOptions"
											formControlName="recallsOptIn">
					</pms-enum-select-button>
				</td>
				<td>
					<ejs-checkbox formControlName="recallsHomePhoneAllowed"></ejs-checkbox>
				</td>
				<td>
					<ejs-checkbox formControlName="recallsWorkPhoneAllowed"></ejs-checkbox>
				</td>
				<td>
					<ejs-checkbox formControlName="recallsCellPhoneAllowed"></ejs-checkbox>
				</td>
				<td>
					<ejs-checkbox formControlName="recallsEmailAllowed"></ejs-checkbox>
				</td>
				<td>
					<ejs-checkbox formControlName="recallsTextAllowed"></ejs-checkbox>
				</td>
				<td>
					<ejs-checkbox formControlName="recallsMailAllowed"></ejs-checkbox>
				</td>
			</tr>
			<tr>
				<td>Orders</td>
				<td class="nowrap">
					<pms-enum-select-button [enumList]="communicationOptInOptions"
											formControlName="ordersOptIn">
					</pms-enum-select-button>
				</td>
				<td>
					<ejs-checkbox formControlName="ordersHomePhoneAllowed"></ejs-checkbox>
				</td>
				<td>
					<ejs-checkbox formControlName="ordersWorkPhoneAllowed"></ejs-checkbox>
				</td>
				<td>
					<ejs-checkbox formControlName="ordersCellPhoneAllowed"></ejs-checkbox>
				</td>
				<td>
					<ejs-checkbox formControlName="ordersEmailAllowed"></ejs-checkbox>
				</td>
				<td>
					<ejs-checkbox formControlName="ordersTextAllowed"></ejs-checkbox>
				</td>
				<td>
					<ejs-checkbox formControlName="ordersMailAllowed"></ejs-checkbox>
				</td>
			</tr>
			<tr>
				<td>General</td>
				<td class="nowrap">
					<pms-enum-select-button [enumList]="communicationOptInOptions"
											formControlName="generalOptIn">
					</pms-enum-select-button>
				</td>
				<td>
					<ejs-checkbox formControlName="generalHomePhoneAllowed"></ejs-checkbox>
				</td>
				<td>
					<ejs-checkbox formControlName="generalWorkPhoneAllowed"></ejs-checkbox>
				</td>
				<td>
					<ejs-checkbox formControlName="generalCellPhoneAllowed"></ejs-checkbox>
				</td>
				<td>
					<ejs-checkbox formControlName="generalEmailAllowed"></ejs-checkbox>
				</td>
				<td>
					<ejs-checkbox formControlName="generalTextAllowed"></ejs-checkbox>
				</td>
				<td>
					<ejs-checkbox formControlName="generalMailAllowed"></ejs-checkbox>
				</td>
			</tr>
			</tbody>
			<tfoot>
			<tr>
				<td colspan="2">Opt out</td>
				<td>
					<ejs-checkbox formControlName="doNotCallHome"></ejs-checkbox>
				</td>
				<td>
					<ejs-checkbox formControlName="doNotCallWork"></ejs-checkbox>
				</td>
				<td>
					<ejs-checkbox formControlName="doNotCallCell"></ejs-checkbox>
				</td>
				<td>
					<ejs-checkbox formControlName="doNotEmail"></ejs-checkbox>
				</td>
				<td>
					<span revTooltip [tooltipContent]="buildDoNotTextTooltip()">
						<ejs-checkbox formControlName="doNotText"></ejs-checkbox>
					</span>
				</td>
				<td>
					<ejs-checkbox formControlName="doNotMail"></ejs-checkbox>
				</td>
			</tr>
			</tfoot>
		</table>
	</div>

	<div class="margin-bottom-sm">
		<label class="control-label">Comments</label>
		<ejs-textbox type="text"
					 class="e-input h-rows-3"
					 formControlName="comments"
					 [multiline]="true">
		</ejs-textbox>
	</div>
</form>
