import { _isNil } from '@core/lodash/lodash';
import { InvoiceResponse } from '@gandalf/model/invoice-response';
import { InvoiceActions, InvoiceActionTypes } from '@invoices-store/actions/invoice.actions';

export class InvoiceDetailsState {
	invoice: InvoiceResponse = null;
	showAll = false;
	currentTab: number = null;
}

export type InvoiceDetailsStateMap = Map<number, InvoiceDetailsState>;

export const initialState: InvoiceDetailsStateMap = new Map();

export function reducer(state = initialState, action: InvoiceActions): InvoiceDetailsStateMap {
	switch (action.type) {
		case InvoiceActionTypes.UPSERT_INVOICE_DETAILS: {
			const invoiceTableStateMap = new Map(state);
			const invoiceDetailsState = invoiceTableStateMap.get(action.payload.invoiceId) || new InvoiceDetailsState();
			invoiceTableStateMap.set(action.payload.invoiceId, {
				...invoiceDetailsState,
				invoice: action.payload.invoice,
				currentTab: action.payload.currentTab,
				showAll: _isNil(action.payload.showAll) ? invoiceDetailsState.showAll : action.payload.showAll,
			});
			return invoiceTableStateMap;
		}
		case InvoiceActionTypes.UPDATE_INVOICE_DETAILS_SHOW_ALL: {
			const invoiceTableStateMap = new Map(state);
			const invoiceDetailsState = invoiceTableStateMap.get(action.payload.invoiceId);
			invoiceTableStateMap.set(action.payload.invoiceId, {
				...invoiceDetailsState,
				showAll: action.payload.showAll,
			});
			return invoiceTableStateMap;
		}
		case InvoiceActionTypes.REMOVE_INVOICE_DETAILS_STATE: {
			const invoiceStateMap = new Map(state);
			invoiceStateMap.delete(action.payload.invoiceId);
			return invoiceStateMap;
		}

		default: {
			return state;
		}
	}
}
