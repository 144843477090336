import { Deferred, Query, RequestOptions, ReturnOption, UrlAdaptor } from '@syncfusion/ej2-data';
import { Observable } from 'rxjs';

/**
 * Contains the list of results for the current page and total number of results.
 */
export interface GridResults {
	pageResults: any[];
	total: number;
}

/**
 * Custom grid adapter that allows server side paging/sorting for search results. Supports initial results for the first request.
 */
export class ObservableGridAdapter<T extends GridResults> extends UrlAdaptor {

	// set to false to prevent additional requests
	active = true;
	// true if the previous request is still running to prevent additional requests
	searching = false;

	constructor(
		private initialResults: T,
		private sendRequest: () => Observable<T>,
	) {
		super();
	}

	/**
	 * WARNING do not rename this function or change the parameters: it is called internally from DataManager every time data is requested.
	 */
	/* eslint-disable-next-line unused-imports/no-unused-vars */
	makeRequest(url: ReturnOption, deferred: Deferred, args: RequestOptions, query: Query) {
		if (!this.active || this.searching) {
			return; // do not resolve the promise: assuming an new adapter or subsequent request will provide the data
		} else if (this.initialResults) {
			// use initial results for the first time to avoid a duplicate request
			this.resolveResults(this.initialResults, deferred, args);
		} else {
			// send a new request for additional data
			this.searching = true;
			this.sendRequest().subscribe(results => this.resolveResults(results, deferred, args));
		}
	}

	private resolveResults(results: T, deferred: Deferred, args?: RequestOptions) {
		this.searching = false;
		// clear out initial results so they cannot be used again
		this.initialResults = null;
		// determines the total number of results/pages in the grid
		args.count = results.total;
		// results for the current (visible) page
		args.result = results.pageResults as ReturnOption;
		// resolve the promise to update the grid with the new data
		deferred.resolve(args);
	}
}
