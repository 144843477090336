import { CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { FlatPercentPipe } from '../../pipes/percent/flat-percent.pipe';
import { YesNoPipe } from '../../pipes/yes-no/yes-no.pipe';
import { _isNil } from '../lodash/lodash';

@Injectable({
	providedIn: 'root',
})
export class CellFormattingUtils {

	constructor(
		private currencyPipe: CurrencyPipe,
		private yesNoPipe: YesNoPipe,
		private datePipe: DatePipe,
		private flatPercentPipe: FlatPercentPipe,
		private percentPipe: PercentPipe,
	) {
	}

	currency = (value?) => {
		if (!_isNil(value)) {
			return this.currencyPipe.transform(value);
		} else {
			return (params) => this.currencyPipe.transform(params.value);
		}
	};

	yesNo() {
		return (params) => this.yesNoPipe.transform(params.value);
	}

	date(format: string) {
		return (params) => this.datePipe.transform(params.value, format);
	}

	percent(value: number) {
		return this.flatPercentPipe.transform(value);
	}

	percentFormat(format?: string) {
		return (params) => this.percentPipe.transform(params.value ?? 0, format);
	}
}
