import { Component, OnInit, ViewChild } from '@angular/core';
import { LoadingOverlayMethod, ModalBase } from 'morgana';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { EventsManagerService, EventsManagerSubscription } from '@core/events-manager/events-manager.service';
import { EVENT_MANAGER_CONSTANTS } from '@core/events-manager/events-manager.constants';
import { TodaysPatientsAppointmentResponse } from '@gandalf/model/todays-patients-appointment-response';
import { EstablishedStatus, ScheduleItemStatus } from '@gandalf/constants';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { NavigationService } from '@core/navigation/navigation.service';
import { URL_PRINT_ENDPOINTS } from '@shared/constants/url.constants';
import { UrlService } from '@core/url-util/url.service';
import { StartAppointmentRequest } from '@gandalf/model/start-appointment-request';
import { EncounterService } from '@core/encounter/encounter.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { UserLocationsService } from '@core/user-locations/user-locations.service';
import { _isEmpty, _isNil } from '@core/lodash/lodash';
import { Subscription } from 'rxjs';
import { HIT_PMS_HTML_PREFERENCES } from '@core/legacy/hit-pms-html.constants';
import { FindTodaysPatientsAppointmentsForProviderRequest } from '@gandalf/model/find-todays-patients-appointments-for-provider-request';
import { NavigationMenuService } from '../services/navigation-menu.service';

@Component({
	selector: 'pms-todays-patients-dockable-modal',
	templateUrl: './todays-patients-dockable-modal.component.html',
	styles: [],
})
export class TodaysPatientsDockableModalComponent extends ModalBase implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	request = new FindTodaysPatientsAppointmentsForProviderRequest();
	subscribeToToggle: EventsManagerSubscription;
	subscribeToLocationChange: Subscription;
	todaysPatientsAppointmentResponses: TodaysPatientsAppointmentResponse[];
	maximized = true;
	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	scheduleItemStatus = ScheduleItemStatus;
	connectSubscriber: boolean;

	_isLoading: boolean;

	constructor(
		private encounterService: EncounterService,
		private eventsManagerService: EventsManagerService,
		private navigationMenuService: NavigationMenuService,
		private navigationService: NavigationService,
		private securityManagerService: SecurityManagerService,
		private userLocationService: UserLocationsService,
		private urlService: UrlService,
	) {
		super();
	}

	ngOnInit() {
		this.subscribeTodaysPatientsToggle();
		this.subscribeLocationData();
		this.connectSubscriber = this.securityManagerService.allowConnectAppointmentReminders();
	}

	getData() {
		this.findTodaysPatients().subscribe(responses => {
			this.todaysPatientsAppointmentResponses = responses;
		});
	}

	subscribeLocationData() {
		this.subscribeToLocationChange = this.userLocationService.observeCurrentUserLocation().subscribe(() => {
			this.getData();
		});
	}

	findTodaysPatients() {
		if (this.securityManagerService.userIsProvider()
			&& this.securityManagerService.preferenceValueIsOn(
				HIT_PMS_HTML_PREFERENCES.SCHEDULE_MY_PATIENTS_ONLY.name,
				HIT_PMS_HTML_PREFERENCES.SCHEDULE_MY_PATIENTS_ONLY.defaultValue,
			)
		) {
			return this.findTodaysPatientsAppointmentsForProvider();
		} else {
			return this.findTodaysPatientsAppointments();
		}
	}

	@LoadingOverlayMethod()
	findTodaysPatientsAppointments() {
		return this.navigationMenuService.findTodaysPatientsAppointments(this.userLocationService.getCurrentUserLocation().id);
	}

	@LoadingOverlayMethod()
	findTodaysPatientsAppointmentsForProvider() {
		this.request.providerId = this.securityManagerService.getUserProviderId();
		this.request.locationId = this.userLocationService.getCurrentUserLocation().id;
		return this.navigationMenuService.findTodaysPatientsAppointmentsForProvider(this.request);
	}

	closeModal() {
		this.eventsManagerService.unsubscribeEvent(this.subscribeToToggle);
		this.subscribeToLocationChange.unsubscribe();
		this.dynamicModalRef.close(this.modal, null);
	}

	maximize(): void {
		this.getData();
		this.modal.position = {X: 'right', Y: 'top'};
		this.modal.height = '85%';
		this.maximized = true;
	}

	minimize(): void {
		this.modal.position = {X: 'right', Y: 'bottom'};
		this.modal.height = 44;
		this.maximized = false;
	}

	subscribeTodaysPatientsToggle() {
		this.subscribeToToggle = this.eventsManagerService.subscribe(EVENT_MANAGER_CONSTANTS.HEADER.TODAYS_PATIENTS_RESIZE, () => {
			this.todaysPatientsToggle();
		});
	}

	todaysPatientsToggle() {
		if (this.maximized) {
			this.minimize();
		} else {
			this.maximize();
		}
	}

	goToScheduleItem(scheduleItemId: number) {
		this.navigationService.navigateToScheduleAppointment(scheduleItemId);
	}

	async goToPatient(patientId: number) {
		await this.navigationService.navigateToPatient(patientId);
	}

	async goToAppointmentOrEncounter(appointment: TodaysPatientsAppointmentResponse) {
		if (_isNil(appointment.encounterId)) {
			await this.navigationService.navigateToPatientAppointment(appointment.patientName.patientId, appointment.appointmentId);
		} else {
			await this.navigationService.navigateToEncounter(appointment.encounterId, appointment.patientName.patientId);
		}
	}

	printEncounter(encounterId: number) {
		this.urlService.openTabWithPost(URL_PRINT_ENDPOINTS.ENCOUNTER_SUMMARY, {encounterId});
	}

	startAppointment(appointment: TodaysPatientsAppointmentResponse) {
		const request = new StartAppointmentRequest();
		request.appointmentId = appointment.appointmentId;
		request.employeeId = appointment.employeeName?.employeeId;
		request.providerId = appointment.provider?.providerId;
		this.navigationMenuService.startAppointment(request).subscribe(async (encounterId) => {
			await this.navigationService.navigateToEncounter(encounterId, appointment.patientName.patientId);
			this.getData();
		});
	}

	completeEncounter(encounterId: number) {
		this.encounterService.completeEncounter(encounterId).subscribe(() => {
			this.getData();
		});
	}

	isPatientNew(establishedStatus: EstablishedStatus) {
		return establishedStatus === EstablishedStatus.NEW;
	}

	connectMessageExistsAndDelivered(connectMessageExists: boolean, connectMessageDelivered: boolean) {
		return this.connectSubscriber && connectMessageExists && connectMessageDelivered;
	}

	connectMessageExistsAndNotDelivered(connectMessageExists: boolean, connectMessageDelivered: boolean) {
		return this.connectSubscriber && connectMessageExists && !connectMessageDelivered;
	}

	showNoDataMessage() {
		if (this._isLoading) {
			return false;
		}
		return _isEmpty(this.todaysPatientsAppointmentResponses) && this.maximized;
	}
}
