import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Optional,
	Output,
	Self,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { FILTER_THRESHOLD } from '@core/dropdown-filter/dropdown-filter.decorator';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { _filter, _find, _isNil } from '@core/lodash/lodash';
import { ObjectUtils, Tooltip, TooltipService } from 'morgana';
import { BaseComponent } from '@shared/component/base.component';

@Component({
	selector: 'pms-dropdown',
	templateUrl: './dropdown.component.html',
	styles: [
	],
})
export class DropdownComponent extends BaseComponent implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor, OnDestroy {


	@ViewChild('matSelect')
	// eslint-disable-next-line deprecation/deprecation
	matSelect: MatSelect;

	@ViewChild('tooltipElement', { read: ElementRef, static: true })
	tooltipElement: ElementRef;

	@Input()
	filterBarPlaceholder = 'Search';

	@Input()
	dataSource: any[];

	@Input()
	labelField = 'label';

	@Input()
	valueField = 'value';

	@Input()
	abbreviationField = 'abbreviation';

	@Input()
	cssClass: string;

	@Input()
	optionFilterThreshold = FILTER_THRESHOLD;

	@Input()
	showClearButton = false;

	@Output()
	enteredDataWithKey = new EventEmitter();

	@Input()
	get shouldFocus(): boolean {
		return this._shouldFocus;
	}
	set shouldFocus(value: boolean) {
		this._shouldFocus = value;
		if (this.shouldFocus) {
			this.matSelect?.focus();
		}
	}
	private _shouldFocus = false;

	@Output()
	inputFocus = new EventEmitter<void>();

	@Output()
	cleared = new EventEmitter<void>();

	@Output()
	valueChanges = new EventEmitter<any[]>();

	disabled = false;

	focusSearchBar = 0;

	filterText = '';

	filteredOptions: any[];

	private tooltip: Tooltip;

	private tooltipValue: string;

	shouldDisplayFilter = false;

	private isSelectOpen = false;

	get selectedValue() {
		return this.ngControl?.control?.value;
	}

	get selectedOption() {
		return _find(this.dataSource, option => option[this.valueField] === this.selectedValue);
	}

	get selectedLabel() {
		const selectedOption = this.selectedOption;
		return !_isNil(selectedOption) ? selectedOption[this.labelField] : null;
	}

	get selectedAbbreviation() {
		const selectedOption = this.selectedOption;
		if (_isNil(selectedOption)) {
			return null;
		}

		if (!ObjectUtils.isNilOrEmpty(selectedOption[this.abbreviationField])) {
			return selectedOption[this.abbreviationField];
		}
		return selectedOption[this.labelField];
	}

	constructor(
		private tooltipService: TooltipService,
		@Optional() @Self() public ngControl: NgControl,
	) {
		super();
		this.ngControl.valueAccessor = this;
	}

	ngOnInit(): void {
		this.initializeTooltip();
	}

	ngAfterViewInit() {
		this.handleFormUpdates();
		this.checkShouldFocus();
	}

	onModelChange: (_: any) => void = () => {
	};
	onModelTouched: () => void = () => {
	};

	writeValue(_value: any): void {
	}

	registerOnChange(fn: (_: any) => void): void {
		this.onModelChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onModelTouched = fn;
	}

	/**
	 * If the dataSource changes, the tooltip may need to updated if additional selected options are now present
	 */
	ngOnChanges(simpleChanges: SimpleChanges) {
		if (simpleChanges?.dataSource?.currentValue !== simpleChanges?.dataSource?.previousValue) {
			this.updateTooltip();
		}
	}

	ngOnDestroy() {
		if (!_isNil(this.tooltip)) {
			this.tooltip.instance.destroy();
		}
	}

	/**
	 * Tracks changes to the control and updates the tooltip if applicable
	 */
	handleFormUpdates() {
		FormUtilsService.reactToValueChanges(
			this.ngControl.control,
			() => {
				// only update the tooltip if changes are made with the select popup closed
				if (!this.isSelectOpen) {
					this.updateTooltip();
				}

				this.valueChanges.emit(this.selectedValue);
			},
			false,
			this.unsubscribe,
		);
	}

	/**
	 * Checks shouldFocus input to autofocus on the mat-select by default
	 */
	checkShouldFocus() {
		if (this._shouldFocus) {
			this.matSelect.focus();
		}
	}

	trackByValue(index: number, item: any) {
		return item[this.valueField];
	}

	onFocus() {
		this.inputFocus.emit();
	}

	/**
	 * Clears any selected options and marks control as dirty
	 */
	clear($event) {
		if (!_isNil($event)) {
			$event.stopPropagation();
		}

		this.ngControl.control.setValue(null);
		this.ngControl.control.markAsDirty();

		this.cleared.emit();
	}

	/**
	 * Case insensitive search that searches all available labels in the dataSource
	 */
	filter() {
		this.filteredOptions = _filter(this.dataSource, option => option[this.labelField].toLowerCase().search(this.filterText.toLowerCase()) !== -1);
	}

	/**
	 * If dropdown menu is open, it will autofocus on the filter bar (if present) and shouldDisplayFilter is checked
	 * If dropdown menu is closed, it resets any filter text and checks if tooltip content needs to be applied
	 */
	onOpenChanged(isOpen) {
		this.isSelectOpen = isOpen;

		if (isOpen) {
			this.focusSearchBar++;
			this.shouldDisplayFilter = this.dataSource.length >= this.optionFilterThreshold;
		} else {
			this.filterText = '';
			this.filteredOptions = null;
			this.updateTooltip();
		}
	}

	/**
	 * returns true or false based on whether an option is selected
	 */
	hasSelectedLabel() {
		return !ObjectUtils.isNilOrEmpty(this.selectedLabel);
	}

	/**
	 * By default mat-select will cycle through options with the left arrow, this will prevent the left arrow usage
	 * inside of the mat-select
	 */
	/* istanbul ignore next */
	onKeydownLeftArrow(event) {
		event.stopPropagation();
	}

	/**
	 * By default mat-select will cycle through options with the right arrow, this will prevent the right arrow usage
	 * inside of the mat-select
	 */
	/* istanbul ignore next */
	onKeydownRightArrow(event) {
		event.stopPropagation();
	}

	/* istanbul ignore next */
	isDisabled() {
		return this.ngControl?.control?.disabled;
	}

	onEnter(_event: KeyboardEvent) {
		this.matSelect.close();
		this.enteredDataWithKey.emit();
	}

	/**
	 * Initializes the tooltip based on the currently selected label
	 */
	initializeTooltip() {
		this.tooltipValue = !_isNil(this.selectedLabel) ? this.selectedLabel : '';
		this.tooltip = this.tooltipService.buildTooltip(this.tooltipValue, this.tooltipElement.nativeElement);
	}

	/**
	 * Determines if the new label need to be updated on the tooltip and adds them
	 */
	updateTooltip() {
		const newSelectedLabel = this.selectedLabel;
		// check if tooltip content has changed
		if (this.tooltipValue !== newSelectedLabel && !_isNil(this.tooltip)) {
			this.tooltipValue = newSelectedLabel;
			this.tooltip.setContent(this.tooltipValue);
		}
	}
}
