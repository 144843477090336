<div class="e-input-group">
	<input
		#patientSearchInput
		[readOnly]="isPatientSet()"
		(keydown.enter)="openSearchPatientModal($event)"
		type="text"
		class="e-input"
		placeholder="Search Patients"
		[disabled]="disabled"
		[(ngModel)]="patientName"
		[ngModelOptions]="{standalone: true}"
		[revFocusInput]="isFocused"/>
	@if (!disabled && !isPatientSet() && !isPatientProvidedFromParent()) {
		<span
			(click)="openSearchPatientModal()"
			revTooltip
			tooltipContent="Search Patients"
			[attr.data-test-id]="'openSearchPatientModal'"
			class="e-input-group-icon fa fa-user">
		</span>
	}
	@if (shouldShowPatientNavigateButton()) {
		<span
			#openPatient
			(click)="navigateToPatient()"
			[attr.data-test-id]="'openCurrentPatient'"
			class="e-input-group-icon fa fa-folder-open">
		</span>
	}
	@if (!disabled && displayAlertsFlagButton()) {
		<span
			(click)="displayStatusAlertsModal()"
			revTooltip tooltipContent="View Alerts"
			[attr.data-test-id]="'openPatientAlerts'"
			class="e-input-group-icon fa fa-flag text-{{firstSortedActiveAlert.severity.label | severityClass}}">
		</span>
	}
	@if (!disabled && isPatientSet() && !isPatientProvidedFromParent()) {
		<span
			(click)="clearPatient()"
			[attr.data-test-id]="'clearPatient'"
			class="e-input-group-icon fa fa-times">
		</span>
	}
	@if (!disabled && includeSelectPatientFromWaitList === true && !isPatientProvidedFromParent()) {
		<span
			(click)="openViewWaitList()"
			revTooltip tooltipContent="View Wait List"
			[attr.data-test-id]="'openViewWaitList'"
			class="e-input-group-icon fa fa-list">
		</span>
	}
	@if (!disabled && includeAddNewPatient === true && !isPatientProvidedFromParent()) {
		<span
			(click)="openAddPatientModal()"
			revTooltip tooltipContent="Add New Patient"
			[attr.data-test-id]="'openAddPatientModal'"
			class="e-input-group-icon fa fa-plus">
		</span>
	}
</div>
<div #patientTooltip [hidden]="true">
	<h5>{{ patientForPopover?.patientName | patientName }}</h5>
	<dl class='dl-horizontal'>
		<dt>DOB</dt>
		<dd>{{ patientForPopover?.dateOfBirth | date:dateType }}<br>({{ patientForPopover?.dateOfBirth | personAge }})
		</dd>
		<dt>Sex</dt>
		<dd>{{ patientForPopover?.gender.label }}</dd>
		<dt>Address</dt>
		<dd>
			{{ patientForPopover?.address?.address1 }}@if (patientForPopover?.address?.address2) {
			<span>, {{ patientForPopover?.address?.address2 }}</span>
		}
			<br>
			{{ patientForPopover?.address?.city }},
			{{ patientForPopover?.address?.stateProvince }}
			{{ patientForPopover?.address?.postalCode }}
		</dd>
		<dt>Home</dt>
		<dd>{{ patientForPopover?.phoneNumbers.homePhone }} {{ patientForPopover?.phoneNumbers.homePhoneExt }}
			@if (preferredPhoneType.HOME === patientForPopover?.phoneNumbers.preferredPhoneType) {
				<i
					class='fa fa-star text-success'></i>
			}</dd>
		<dt>Work</dt>
		<dd>{{ patientForPopover?.phoneNumbers.workPhone }} {{ patientForPopover?.phoneNumbers.workPhoneExt }}
			@if (preferredPhoneType.WORK === patientForPopover?.phoneNumbers.preferredPhoneType) {
				<i
					class='fa fa-star text-success'></i>
			}</dd>
		<dt>Cell</dt>
		<dd>{{ patientForPopover?.phoneNumbers.cellPhone }} {{ patientForPopover?.phoneNumbers.cellPhoneExt }}
			@if (preferredPhoneType.CELL === patientForPopover?.phoneNumbers.preferredPhoneType) {
				<i
					class='fa fa-star text-success'></i>
			}</dd>
		<dt>Email</dt>
		<dd>{{ patientForPopover?.email }}</dd>
		<dt>PHR Login</dt>
		<dd>{{ patientForPopover?.phrLogin ? "Yes" : "No" }}</dd>
	</dl>
</div>
