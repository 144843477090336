import { Injectable, NgZone } from '@angular/core';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { INTER_APP_CONSTANTS } from '@core/legacy/inter-app.constants';
import { NavigationService } from '@core/navigation/navigation.service';
import { OrderProcessorType, OrderType } from '@gandalf/constants';
import { EnumUtil } from 'morgana';

@Injectable({
	providedIn: 'root',
})
export class OrdersEventService {

	constructor(
		public eventsManagerService: EventsManagerService,
		public navigationService: NavigationService,
		private ngZone: NgZone,
	) {
	}

	subscribeToEvents() {
		this.eventsManagerService.subscribe(INTER_APP_CONSTANTS.REV360.ORDERS.OPEN_ORDER_FROM_SUMMARY_POD, async (event) => {
			// Legacy passes over these values as primitives, so we have to convert them to the appropriate Enum value
			const orderType: OrderType = EnumUtil.findEnumByValue(event.argument.type, OrderType);
			const processorType: OrderProcessorType = EnumUtil.findEnumByValue(event.argument.masterProcessorId, OrderProcessorType);
			return this.ngZone.run(
				() => this.navigationService.openOrder(event.argument.orderId, orderType, event.argument.isNew, processorType, event.argument.vendorSmartflowCode),
			);
		});
	}
}
