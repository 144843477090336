import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'forceDecimal',
})
@Injectable({
	providedIn: 'root',
})
export class ForceDecimalPipe implements PipeTransform {

	transform(value: string | number, decimalPlaces: number, returnValueWhenNaN?: string): string {
		if (isNaN(Number(value))) {
			return returnValueWhenNaN;
		}
		return Number(value).toFixed(decimalPlaces);
	}

}
