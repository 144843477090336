import {
	ApplicationRef,
	ComponentFactoryResolver,
	ComponentRef,
	EmbeddedViewRef,
	Injectable,
	Injector,
	isDevMode,
	Type
} from '@angular/core';
import { take } from 'rxjs/operators';
import { _isNil } from '../../utils/lodash/lodash';
import { TypeSafeDynamicModalRef, TypeSafeEmptyDynamicModalRef } from '../../components/modal/type-safe-dynamic-modal-ref';
import { ModalBase } from '../../components/modal/modal-base';
import { TypeSafeDynamicModalComponent } from '../../components/modal/type-safe-dynamic-modal.component';

@Injectable()
export class TypeSafeModalManagerService {

	private dialogComponentRef: ComponentRef<TypeSafeDynamicModalComponent<any, any>>;
	// An instance of modal manager service only supports one modal currently
	isOpen = false;

	constructor(
		/* eslint-disable-next-line deprecation/deprecation */
		private componentFactoryResolver: ComponentFactoryResolver,
		private appRef: ApplicationRef,
		private injector: Injector,
	) {}

	open<T, R>(componentType: Type<ModalBase<T, R>>, data: T): TypeSafeDynamicModalRef<R> {
		if (this.isOpen) {
			if (isDevMode()) {
				console.error(
					'Two modals cannot be opened at the same time from modal manager service!',
					'Provide a new instance of the service to the opening component if you intended to stack modals.',
				);
			}
			// There is already an active modal open, with the valid onClose subscription for the component;
			// so returning an empty subject here will ensure runtime safety while not triggering a second
			// onClose from occurring.
			return new TypeSafeEmptyDynamicModalRef();
		}
		this.isOpen = true;
		return this.openModal(componentType, data);
	}

	private openModal<T, R>(componentType: Type<ModalBase<T, R>>, data: T) {
		this.appendDialogComponentToBody<T, R>();

		const dialogRef = new TypeSafeDynamicModalRef<R>();
		dialogRef.onClose.pipe(take(1)).subscribe(() => {
			this.onClose();
		});

		this.dialogComponentRef.instance.childComponentType = componentType;
		this.dialogComponentRef.instance.componentData = data;
		this.dialogComponentRef.instance.dynamicComponentRef = dialogRef;

		return dialogRef;
	}

	private appendDialogComponentToBody<T, R>() {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory<TypeSafeDynamicModalComponent<T, R>>(TypeSafeDynamicModalComponent);
		const componentRef = componentFactory.create(this.injector);

		this.appRef.attachView(componentRef.hostView);

		const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
		document.body.appendChild(domElem);
		this.dialogComponentRef = componentRef;
	}

	private removeDialogComponentFromBody() {
		this.appRef.detachView(this.dialogComponentRef.hostView);
		this.dialogComponentRef.destroy();
	}

	onClose() {
		this.removeDialogComponentFromBody();
		this.isOpen = false;
	}

	close() {
		if (!this.isOpen || _isNil(this.dialogComponentRef)) {
			return;
		}
		this.onClose();
	}

}
