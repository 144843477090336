// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.payment.AccountingUpdatePaymentGroupRequest */
/* istanbul ignore next */
@GandalfModel
export class AccountingUpdatePaymentGroupRequest extends GandalfModelBase {

	@GandalfLabel('Payment date')
	@GandalfValidator({ notNull: { message: 'Payment date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	paymentDate: Date;

	@GandalfLabel('Payment Group')
	@GandalfValidator({ notNull: { message: 'Payment Group is required' } })
	@GandalfProperty({ isRequired: true })
	paymentGroupId: number;

	@GandalfConstantDecorator()
	@GandalfLabel('Payment method credit card type')
	@GandalfProperty()
	paymentMethodCreditCardType: constants.PaymentMethodCreditCardType;

	@GandalfConstantDecorator()
	@GandalfLabel('Payment method type')
	@GandalfValidator({ notNull: { message: 'Payment method type is required' } })
	@GandalfProperty({ isRequired: true })
	paymentMethodType: constants.PaymentMethodType;

	@GandalfLabel('Reference number')
	@GandalfValidator({ sizeString: { message: 'Reference number cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	referenceNumber: string;

}
