<ejs-dialog
	#modal
	cssClass="modal-lg"
	(close)="closeModal()"
	[attr.data-test-id]="'webcamModal'">
	<ng-template #header>
		<div class="dlg-template">Image Capture</div>
	</ng-template>
	<ng-template #content>
		<div class="col-sm-6" [attr.data-test-id]="'webcamModalWebcam'">
			@if (trigger) {
				<webcam
					[width]="300"
					(imageCapture)="captureImage($event)"
					[trigger]="triggerObservable"
					(initError)="handleInitError($event)"></webcam>
			}
		</div>
		<div class="col-sm-6" [attr.data-test-id]="'webcamModalImg'">
			@if (capturedImage) {
				<img
					[width]="300"
					[src]="capturedImage.imageAsDataUrl">
			}
		</div>

	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button type="button" cssClass="pull-left" (click)="triggerSnapshot()" [attr.data-test-id]="'webcamModalCaptureImage'">Capture Image</button>
		<button ejs-button type="button" (click)="closeModal()" iconCss="fa fa-close" [attr.data-test-id]="'webcamModalCancelButton'">Cancel</button>
		<button ejs-button [disabled]="!capturedImage" type="button" [isPrimary]="true" (click)="closeModal(true)"
				[attr.data-test-id]="'webcamModalSaveButton'">Save
		</button>
	</ng-template>
</ejs-dialog>
