// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.eyefinity.EyefinityOrderItemRequest */
/* istanbul ignore next */
@GandalfModel
export class EyefinityOrderItemRequest extends GandalfModelBase {

	@GandalfLabel('Billable Item')
	@GandalfProperty()
	billableItemId: number;

	@GandalfLabel('VSP Chargeback')
	@GandalfValidator({ notNull: { message: 'VSP Chargeback is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	eyefinityChargeback: number;

	@GandalfLabel('VSP Chargeback')
	@GandalfProperty({ propertyType: 'Money' })
	eyefinityChargebackBeforeCopay: number;

	@GandalfLabel('VSP Fee Option')
	@GandalfProperty()
	eyefinityFeeOptionId: number;

	@GandalfLabel('Eyefinity Generated')
	@GandalfValidator({ notNull: { message: 'Eyefinity Generated is required' } })
	@GandalfProperty({ isRequired: true })
	eyefinityGenerated: boolean;

	@GandalfLabel('VSP Order Item')
	@GandalfProperty()
	eyefinityOrderItemId: number;

	@GandalfLabel('VSP Pays')
	@GandalfValidator({ notNull: { message: 'VSP Pays is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	eyefinityPays: number;

	@GandalfLabel('VSP Reimbursement')
	@GandalfProperty({ propertyType: 'Money' })
	eyefinityReimbursement: number;

	@GandalfLabel('VSP Service Fee')
	@GandalfProperty({ propertyType: 'Money' })
	eyefinityServiceFee: number;

	@GandalfLabel('VSP Total')
	@GandalfValidator({ notNull: { message: 'VSP Total is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	eyefinityTotal: number;

	@GandalfLabel('Code')
	@GandalfValidator({ notNull: { message: 'Code is required' } })
	@GandalfValidator({ sizeString: { message: 'Code must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ isRequired: true })
	hcpcsCode: string;

	@GandalfLabel('Total Cost')
	@GandalfValidator({ notNull: { message: 'Total Cost is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	invoiceCost: number;

	@GandalfLabel('Covered')
	@GandalfValidator({ notNull: { message: 'Covered is required' } })
	@GandalfProperty({ isRequired: true })
	itemIsCovered: boolean;

	@GandalfConstantDecorator()
	@GandalfLabel('Item Type')
	@GandalfValidator({ notNull: { message: 'Item Type is required' } })
	@GandalfProperty({ isRequired: true })
	itemType: constants.EyefinityOrderItemType;

	@GandalfLabel('Product')
	@GandalfProperty()
	locationProductId: number;

	@GandalfLabel('Product Item')
	@GandalfProperty()
	locationProductItemId: number;

	@GandalfLabel('Name')
	@GandalfValidator({ sizeString: { message: 'Name cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	name: string;

	@GandalfLabel('Patient Copay')
	@GandalfValidator({ notNull: { message: 'Patient Copay is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	patientCopay: number;

	@GandalfLabel('Patient Copay Amount')
	@GandalfValidator({ notNull: { message: 'Patient Copay Amount is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	patientCopayAmount: number;

	@GandalfLabel('Patient Portion')
	@GandalfValidator({ notNull: { message: 'Patient Portion is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	patientPortion: number;

	@GandalfLabel('Patient Portion Exceeding Allowance')
	@GandalfProperty({ propertyType: 'Money' })
	patientPortionExceedingAllowance: number;

	@GandalfLabel('Patient Charge')
	@GandalfProperty({ propertyType: 'Money' })
	patientPortionForNonCoveredItem: number;

	@GandalfLabel('Patient Charged Remaining Costs')
	@GandalfProperty({ propertyType: 'Money' })
	patientPortionFromUnallocatedCost: number;

	@GandalfLabel('Patient Pays')
	@GandalfValidator({ notNull: { message: 'Patient Pays is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	patientTotal: number;

	@GandalfLabel('Patient Transfer Amount')
	@GandalfValidator({ notNull: { message: 'Patient Transfer Amount is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	patientTransferAmount: number;

	@GandalfLabel('Quantity')
	@GandalfValidator({ min: { min: -99, message: 'Quantity may not be more than 2 digits' } })
	@GandalfValidator({ max: { max: 99, message: 'Quantity may not be more than 2 digits' } })
	@GandalfValidator({ notNull: { message: 'Quantity is required' } })
	@GandalfProperty({ isRequired: true })
	quantity: number;

	@GandalfLabel('Recoupment Amount')
	@GandalfValidator({ notNull: { message: 'Recoupment Amount is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	recoupmentAmount: number;

	@GandalfLabel('Update Catalog')
	@GandalfValidator({ notNull: { message: 'Update Catalog is required' } })
	@GandalfProperty({ isRequired: true })
	rememberItem: boolean;

	@GandalfLabel('Retail Cost')
	@GandalfValidator({ notNull: { message: 'Retail Cost is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	retailCost: number;

	@GandalfLabel('Service')
	@GandalfProperty()
	serviceId: number;

	@GandalfLabel('Unallocated Cost')
	@GandalfValidator({ notNull: { message: 'Unallocated Cost is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	unallocatedCost: number;

	@GandalfLabel('Write Off Amount')
	@GandalfValidator({ notNull: { message: 'Write Off Amount is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	writeoffAmount: number;

}
