import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { _isNil } from '@core/lodash/lodash';

@Component({
	selector: 'pms-drug-selection-input',
	templateUrl: './drug-selection-input.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DrugSelectionInputComponent),
			multi: true,
		},
	],
})
export class DrugSelectionInputComponent implements ControlValueAccessor {

	@Input()
	htmlId: string;

	@Input()
	placeholder: string;

	@Input()
	isDrugSelected: boolean;

	@Output()
	openDrugPicker = new EventEmitter<string>();

	@Output()
	clearDrug = new EventEmitter<void>();

	drugText = '';
	disabled = false;
	onModelChange: (_: any) => void = () => { };
	onModelTouched: () => void = () => { };

	registerOnChange(fn: (_: any) => void) {
		this.onModelChange = fn;
	}

	registerOnTouched(fn: () => void) {
		this.onModelTouched = fn;
	}

	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}

	writeValue(value: string) {
		this.drugText = value;
	}

	triggerDrugSelection(event?: KeyboardEvent) {
		if (!_isNil(event)) {
			event.preventDefault();
		}

		if (!this.disabled) {
			this.openDrugPicker.emit(this.drugText);
		}
	}

	clearSelectedDrug() {
		if (!this.disabled) {
			this.clearDrug.emit();
		}
	}

}
