// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PracticeLocationSummaryResponse } from './practice-location-summary-response';

/** See com.rev360.pms.api.controller.admin.solutions.revclear.RevClearExternalCredentialResponse */
/* istanbul ignore next */
@GandalfModel
export class RevClearExternalCredentialResponse extends GandalfModelBase {

	@GandalfProperty()
	active: boolean;

	@GandalfProperty()
	autoPostingEnabled: boolean;

	@GandalfArray(PracticeLocationSummaryResponse)
	locations: PracticeLocationSummaryResponse[];

	@GandalfProperty()
	password: string;

	@GandalfProperty()
	revClearExternalCredentialId: number;

	@GandalfProperty()
	userName: string;

}
