// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.opticalsummary.OpticalOrderPodOrderResponse */
/* istanbul ignore next */
@GandalfModel
export class OpticalOrderPodOrderResponse extends GandalfModelBase {

	@GandalfProperty()
	comments: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	createdOn: Date;

	@GandalfProperty()
	instructions: string;

	@GandalfProperty()
	onHold: boolean;

	@GandalfProperty()
	orderId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	orderType: constants.OrderType;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.OrderStatusCode;

	@GandalfProperty({ propertyType: 'LocalDate' })
	statusDate: Date;

}
