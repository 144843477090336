// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { ReferenceDataSummaryResponse } from './reference-data-summary-response';

/** See com.rev360.pms.api.controller.patient.pod.PatientPodPersonInsuranceResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientPodPersonInsuranceResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	effectiveDate: Date;

	@GandalfProperty({ propertyType: 'Money' })
	fittingContactLensCoPay: number;

	@GandalfProperty({ propertyType: 'Money' })
	generalCoPay: number;

	@GandalfProperty()
	groupNumber: string;

	@GandalfProperty()
	insuranceCompanyAddress: AddressResponse;

	@GandalfProperty()
	insuranceCompanyFaxNumber: string;

	@GandalfProperty()
	insuranceCompanyName: string;

	@GandalfProperty()
	insuranceCompanyPhoneNumber: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	insuranceCompanyStatus: constants.InsuranceCompanyStatus;

	@GandalfProperty()
	insurancePriority: ReferenceDataSummaryResponse;

	@GandalfProperty()
	insuranceType: ReferenceDataSummaryResponse;

	@GandalfProperty()
	insuranceWebsite: string;

	@GandalfProperty({ propertyType: 'Money' })
	materialCoPay: number;

	@GandalfProperty({ propertyType: 'Money' })
	medicalExamCoPay: number;

	@GandalfProperty()
	personInsuranceId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	personInsuranceStatus: constants.PersonInsuranceStatus;

	@GandalfProperty()
	planName: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	policyHolderDob: Date;

	@GandalfProperty()
	policyHolderName: PersonNameResponse;

	@GandalfProperty()
	policyNumber: string;

	@GandalfProperty({ propertyType: 'Money' })
	routineExamCoPay: number;

}
