// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.person.AllergyRequest */
/* istanbul ignore next */
@GandalfModel
export class AllergyRequest extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	deactivationDate: Date;

	@GandalfValidator({ sizeString: { message: 'Description cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	description: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate: Date;

	@GandalfValidator({ sizeString: { message: 'External ID cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	externalId: string;

	@GandalfValidator({ notNull: { message: 'Patient Id is required' } })
	@GandalfProperty({ isRequired: true })
	patientId: number;

	@GandalfArray(Number)
	reactionIds: number[];

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Severity is required' } })
	@GandalfProperty({ isRequired: true })
	severity: constants.AllergySeverity;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.AllergyStatus;

	@GandalfValidator({ sizeString: { message: 'Treatment cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	treatment: string;

}
