import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { FooterComponent } from './footer.component';
import { LiveChatComponent } from './live-chat.component';

@NgModule({
	declarations: [FooterComponent, LiveChatComponent],
	imports: [
		SharedModule,
	],
	exports: [
		FooterComponent,
		LiveChatComponent,
	],
})
export class FooterModule {

	constructor() {
	}
}
