// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.reporting.schedule.AppointmentReportResponse */
/* istanbul ignore next */
@GandalfModel
export class AppointmentReportResponse extends GandalfModelBase {

	@GandalfProperty()
	appointmentConfirmationStatus: boolean;

	@GandalfProperty()
	appointmentId: number;

	@GandalfProperty()
	employeeFirstName: string;

	@GandalfProperty()
	employeeLastName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	encounterApprovalStatus: constants.EncounterApprovalStatus;

	@GandalfConstantDecorator()
	@GandalfProperty()
	establishedStatus: constants.EstablishedStatus;

	@GandalfProperty()
	insuranceVerificationComment: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	interviewStatus: constants.InterviewStatus;

	@GandalfProperty()
	locationName: string;

	@GandalfProperty()
	patientName: PatientNameResponse;

	@GandalfProperty()
	preferredPhoneNumber: string;

	@GandalfProperty()
	providerFirstName: string;

	@GandalfProperty()
	providerLastName: string;

	@GandalfProperty()
	roleName: string;

	@GandalfProperty()
	startDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.ScheduleItemStatus;

	@GandalfProperty()
	subTypeName: string;

	@GandalfProperty()
	templateName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	verificationStatus: constants.VerificationStatus;

}
