import { Directive, HostListener, Input, Optional } from '@angular/core';
import { ButtonComponent, ButtonStyle } from '../../../components/button/button.component';
import { ButtonMenuComponent } from '../../../components/button-menu/button-menu.component';
import { _isNil } from '../../../utils/lodash/lodash';

export class ButtonDefinition {
	buttonIcon: string;
	buttonStyle: ButtonStyle;
	buttonTooltip: string;

	constructor(buttonIcon: string, buttonStyle: ButtonStyle, buttonTooltip: string) {
		this.buttonIcon = buttonIcon;
		this.buttonStyle = buttonStyle;
		this.buttonTooltip = buttonTooltip;
	}
}

export class ButtonDefinitions {
	activate = new ButtonDefinition('check', 'success', 'Activate');
	add = new ButtonDefinition('plus', 'success', 'Add');
	assign = new ButtonDefinition('plus', 'success', 'Assign');
	copy = new ButtonDefinition('clone', 'primary', 'Copy');
	createOrder = new ButtonDefinition('shopping-cart', 'primary', 'Create Order');
	deactivate = new ButtonDefinition('close', 'danger', 'Deactivate');
	delete = new ButtonDefinition('trash', 'danger', 'Delete');
	information = new ButtonDefinition('info-circle', 'primary', 'Information');
	moreActions = new ButtonDefinition('ellipsis-h', 'default', 'More Actions');
	preview = new ButtonDefinition('eye', 'primary', 'Preview');
	print = new ButtonDefinition('print', 'primary', 'Print');
	remove = new ButtonDefinition('minus', 'danger', 'Remove');
}

export const BUTTON_DEFINITIONS = new ButtonDefinitions();

@Directive({
	selector: '[revGridButton]',
})
export class GridButtonDirective {

	constructor(
		@Optional() private buttonComponent: ButtonComponent,
		@Optional() private buttonMenuComponent: ButtonMenuComponent,
	) {
	}

	@Input()
	set revGridButton(definition: keyof ButtonDefinitions) {
		const buttonDefinition: ButtonDefinition = BUTTON_DEFINITIONS[definition];
		if (!_isNil(this.buttonComponent)) {
			this.populateButtonProperties(buttonDefinition.buttonIcon, buttonDefinition.buttonStyle, buttonDefinition.buttonTooltip);
		} else if (!_isNil(this.buttonMenuComponent)) {
			this.populateButtonMenuProperties(buttonDefinition.buttonIcon, buttonDefinition.buttonStyle, buttonDefinition.buttonTooltip);
		}
	}

	populateButtonProperties(buttonIcon: string, buttonStyle: ButtonStyle, buttonTooltip: string) {
		this.buttonComponent.buttonIcon = buttonIcon;
		this.buttonComponent.buttonStyle = buttonStyle;
		this.buttonComponent.buttonTooltip = buttonTooltip;
	}

	populateButtonMenuProperties(buttonIcon: string, buttonStyle: ButtonStyle, buttonTooltip: string) {
		this.buttonMenuComponent.buttonIcon = buttonIcon;
		this.buttonMenuComponent.buttonStyle = buttonStyle;
		this.buttonMenuComponent.buttonTooltip = buttonTooltip;
	}

	@HostListener('click', ['$event'])
	onClick(event: MouseEvent) {
		event.stopPropagation();
	}

	@HostListener('dblclick', ['$event'])
	onDblClick(event: MouseEvent) {
		event.stopPropagation();
	}

	@HostListener('keydown', ['$event'])
	onKeydown(event: KeyboardEvent) {
		event.stopPropagation();
	}

}
