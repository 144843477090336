// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PreferenceResponse } from './preference-response';

/** See com.rev360.pms.api.controller.admin.practicepreferences.SystemPreferencesResponse */
/* istanbul ignore next */
@GandalfModel
export class SystemPreferencesResponse extends GandalfModelBase {

	@GandalfArray(PreferenceResponse)
	additionalPreferences: PreferenceResponse[];

	@GandalfProperty()
	autoLockPeriod: number;

	@GandalfProperty()
	expirationDays: number;

	@GandalfProperty()
	minDigits: number;

	@GandalfProperty()
	minLength: number;

	@GandalfProperty()
	minLowerCase: number;

	@GandalfProperty()
	minSpecialChars: number;

	@GandalfProperty()
	minUpperCase: number;

	@GandalfProperty()
	numPasswordChanges: number;

	@GandalfProperty()
	passwordExpires: boolean;

}
