@if (shouldShowHeader()) {
	<header #header id="header" class="navbar navbar-inverse navbar-fixed-top">
		<div class="container-fluid">
			<div class="navbar-header">
				<button type="button" class="navbar-toggle" (click)="toggleMobileMenu(header)">
					<span class="sr-only">Toggle navigation</span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
				</button>
				<a class="navbar-brand" (click)="navigateHome()" revTooltip tooltipContent="{{homeTab.LABEL}}" [attr.data-test-id]="'headerHomeNavigateButton'">
					<img src="./assets/header-logo-icon.png" alt="RevolutionEHR">
				</a>
			</div>
			<div id="header-navbar" class="collapse navbar-collapse">
				<ul class="nav nav-primary navbar-nav">
					@for (tab of tabData.tabs; track tab) {
						<li
							#menu
							[ngClass]="{'active' : isTabActive(tab.name), 'dropdown' : hasSubTabs(tab)}"
							(mouseenter)="openMenu(menu)"
							(mouseleave)="closeMenu(menu)">
							@if (showTab(tab.name)) {
								<a
									(click)="changeTab(tab, menu)"
									revTooltip
									tooltipContent="{{tab?.displayLabel}}"
									[tooltipDisabled]="hasSubTabs(tab)"
									[attr.data-test-id]="'headerParentNavigateButton' + tab.name">
									<i class="{{tab.icon}}"></i><span class="nav-name"> {{ tab.displayLabel }}</span>
									@if (shouldDisplayCount(tab.name, taskTab.NAME, taskCount)) {
										<span class="badge badge-success">
                      {{ taskCount }}
                    </span>
									}
									@if (shouldDisplayCount(tab.name, messageTab.NAME, messageCount)) {
										<span class="badge badge-success">
                      {{ messageCount }}
                    </span>
									}
									@if (shouldDisplayCount(tab.name, connectTab.NAME, unreadConversationsCount.value)) {
										<span class="badge badge-success">
                      {{ unreadConversationsCount | async }}
                    </span>
									}
								</a>
							}
							@if (hasSubTabs(tab)) {
								<ul class="dropdown-menu" role="menu">
									<li class="dropdown-header">{{ tab.displayLabel }}</li>
									@for (subTab of tab.subNavigationTabs; track subTab) {
										<li
											[ngClass]="{'active' : isSubTabActive(tab.name, subTab.name)}"
											(click)="changeTab(subTab, menu)"
											(touchend)="touchClose(menu, subTab, $event)">
											<a title="" [attr.data-test-id]="'headerChildNavigateButton' + subTab.name"><i
												class="{{subTab?.icon}} fa-fw"></i> {{ subTab.displayLabel }}</a>
										</li>
									}
								</ul>
							}
						</li>
					}
				</ul>
				<div class="navbar-right">
					<form class="navbar-form navbar-left visible-lg">
						<pms-patient-search></pms-patient-search>
					</form>
					<ul id="nav-secondary" class="nav nav-secondary navbar-nav navbar-left">
						<li [ngClass]="{'tempNavTabletSearchOpen': showDropdownSearch}" class="dropdown hidden-lg" tabindex="-1"
							(focusout)="blurDropdownSearch($event)">
							<a (click)="searchIconClick()" [attr.data-test-id]="'headerSmallWindowSearchToggle'">
								<i class="fa fa-search"></i>
								<span class="caret"></span>
							</a>
							@if (showDropdownSearch) {
								<div class="tempSearchDropdown">
									<form class="navbar-form" role="search">
										<pms-patient-search></pms-patient-search>
									</form>
								</div>
							}
						</li>
						@if (viewSchedulePermission) {
							<li (click)="openTodaysPatientsDockableModal()" [attr.data-test-id]="'headerTodaysPatientsMenu'">
								<a class="todays-patients-btn">
									<i class="fa fa-users fa-lg"></i>
									<i class="fa fa-ellipsis-v margin-left-xs"></i>
								</a>
							</li>
						}
						<li #supportAndResourcesMenu
							class="dropdown"
							(mouseenter)="openMenu(supportAndResourcesMenu)"
							(mouseleave)="closeMenu(supportAndResourcesMenu)"
							[ngClass]="{'active' : isDropdownOpen(supportAndResourcesMenu)}"
							tabindex="1"
							[attr.data-test-id]="'headerSupportMenu'">
							<a>
								<i class="fa fa-question-circle"></i>
								<span class="caret"></span>
							</a>
							<ul class="dropdown-menu" role="menu">
								<li role="presentation" class="dropdown-header">SUPPORT</li>
								<li (click)="closeMenu(supportAndResourcesMenu)">
									<rev-external-link
										[externalLink]="knowledgeBaseAndFaq"
										dataTestId="knowledgeBaseAndFaq"
										externalLinkDisplayName="Knowledge Base & FAQ"
										[leftIconClass]="'book fa-fw'">
									</rev-external-link>
								</li>
								@if (!isWalmart) {
									<li (click)="closeMenu(supportAndResourcesMenu)">
										<rev-external-link
											[externalLink]="contactSupport"
											dataTestId="contactSupport"
											externalLinkDisplayName="Contact Support"
											[leftIconClass]="'envelope fa-fw'">
										</rev-external-link>
									</li>
									<li><a (click)="openSendFeedbackModal()">
										<i class="fa fa-comment fa-fw"></i>
										Send Feedback
									</a></li>
									<li><a (click)="openAskMeaningfulUseModal()">
										<i class="fa fa-comments fa-fw"></i>
										Ask about MIPS
									</a></li>
									<li><a (click)="openAskRevAssureModal()">
										<i class="fa fa-comments fa-fw"></i>
										Ask RevAssure
									</a></li>
									<li class="divider" role="presentation"></li>
									<li role="presentation" class="dropdown-header">RESOURCES</li>
									<li (click)="closeMenu(supportAndResourcesMenu)">
										<rev-external-link
											[externalLink]="mipsResourceCenter"
											dataTestId="mipsResourceCenter"
											externalLinkDisplayName="MIPS Resource Center"
											[leftIconClass]="'file fa-fw'">
										</rev-external-link>
									</li>
									<li (click)="closeMenu(supportAndResourcesMenu)">
										<rev-external-link
											[externalLink]="recordedWebinars"
											dataTestId="recordedWebinars"
											externalLinkDisplayName="Recorded Webinars"
											[leftIconClass]="'file fa-fw'">
										</rev-external-link>
									</li>
									<li (click)="closeMenu(supportAndResourcesMenu)">
										<rev-external-link
											[externalLink]="referAColleague"
											dataTestId="referAColleague"
											externalLinkDisplayName="Refer-A-Colleague"
											[leftIconClass]="'user-plus fa-fw'">
										</rev-external-link>
									</li>
								}
							</ul>
						</li>
						<li #locationMenu
							class="dropdown"
							(mouseenter)="openMenu(locationMenu)"
							(mouseleave)="closeMenu(locationMenu)"
							[ngClass]="{'active' : isDropdownOpen(locationMenu)}"
							tabindex="1"
							[attr.data-test-id]="'headerLocationMenu'">
							<a>
								<i class="fa fa-building"></i>
								<span class="nav-name hidden-sm hidden-md">
                  {{ (currentUserLocation | async)?.name | textTruncate: 25 }}
                </span>
								<span class="caret"></span>
							</a>
							<ul class="scrollable-menu dropdown-menu" role="menu">
								<li role="presentation" class="dropdown-header">LOCATIONS</li>
								@for (location of userActiveLocations; track location) {
									<li
										[ngClass]="{'active' : isCurrentUserLocation(location)}">
										<a (click)="changeUserLocation(locationMenu, location)"
										   [attr.data-test-id]="'headerLocationSelect'">{{ location.name | textTruncate: 25 }}</a>
									</li>
								}
							</ul>
						</li>
						<li #userMenu
							class="dropdown"
							(mouseenter)="openMenu(userMenu)"
							(mouseleave)="closeMenu(userMenu)"
							[ngClass]="{'active' : isDropdownOpen(userMenu)}"
							tabindex="1"
							[attr.data-test-id]="'headerUserMenu'">
							<a>
								<i class="fa fa-user"></i>
								<span class="nav-name hidden-sm hidden-md">
                  {{ userFirstName | textProperCase | textTruncate: 10 }} {{ userLastName | textProperCase | slice:0:1 }}.
                </span>
								<span class="caret"></span>
							</a>
							<ul class="dropdown-menu" role="menu">
								<li role="presentation" class="dropdown-header">{{ userFirstName }} {{ userLastName }}</li>
								<li><a (click)="editProfile()" [attr.data-test-id]="'headerUserEditProfile'"><i class="fa fa-edit fa-fw"></i> Edit Profile</a>
								</li>
								<li><a (click)="openManageUpdatesModal()" [attr.data-test-id]="'headerUserManageUpdates'"><i class="fa fa-bell fa-fw"></i>
									Manage Updates</a></li>
								<li class="divider" role="presentation"></li>
								<li><a (click)="lockScreen()" [attr.data-test-id]="'headerUserLockScreen'"><i class="fa fa-lock fa-fw"></i> Lock</a></li>
							</ul>
						</li>
						<li (click)="logout()" [attr.data-test-id]="'headerLogoutButton'">
							<a>
								<i class="fa fa-power-off"></i>
							</a>
						</li>
					</ul>
				</div>
			</div>
			<div id="header-navbar-mobile">
				<div class="text-right padding-sm margin-bottom-sm">
					<rev-button
						buttonStyle="primary"
						buttonIcon="close"
						class="btn-close"
						(click)="toggleMobileMenu(header)">
					</rev-button>
				</div>
				<form class="navbar-form">
					<pms-patient-search></pms-patient-search>
				</form>
				<ul class="nav nav-primary navbar-nav">
					@for (tab of tabData.tabs; track tab) {
						<li
							#menuMobile
							[ngClass]="{'active' : isTabActive(tab.name), 'dropdown' : hasSubTabs(tab)}">
							@if (tab.name !== homeTab.NAME) {
								<a
									(click)="changeTabMobile(tab, menuMobile)"
									[attr.data-test-id]="'headerParentNavigateButtonMobile' + tab.name">
									<i class="{{tab.icon}} fa-fw"></i><span class="nav-name"> {{ tab.displayLabel }}</span> @if (hasSubTabs(tab)) {
									<span class="caret margin-left-xs"></span>
								}
									@if (shouldDisplayCount(tab.name, taskTab.NAME, taskCount)) {
										<span class="badge badge-success">
                    {{ taskCount }}
                  </span>
									}
									@if (shouldDisplayCount(tab.name, messageTab.NAME, messageCount)) {
										<span class="badge badge-success">
                    {{ messageCount }}
                  </span>
									}
									@if (shouldDisplayCount(tab.name, connectTab.NAME, unreadConversationsCount.value)) {
										<span class="badge badge-success">
                    {{ unreadConversationsCount | async }}
                  </span>
									}
								</a>
							}
							@if (hasSubTabs(tab)) {
								<ul class="dropdown-menu" role="menu">
									<li class="dropdown-header">{{ tab.displayLabel }}</li>
									@for (subTab of tab.subNavigationTabs; track subTab) {
										<li
											[ngClass]="{'active' : isSubTabActive(tab.name, subTab.name)}"
											(click)="changeTabMobile(subTab, menuMobile)">
											<a title="" [attr.data-test-id]="'headerChildNavigateButton' + subTab.name"><i
												class="{{subTab?.icon}} fa-fw"></i> {{ subTab.displayLabel }}</a>
										</li>
									}
								</ul>
							}
						</li>
					}
				</ul>
				<div>
					<ul id="nav-secondary-mobile" class="nav nav-secondary navbar-nav">
						@if (viewSchedulePermission) {
							<li (click)="openTodaysPatientsDockableModal()" [attr.data-test-id]="'headerTodaysPatientsMenuMobile'">
								<a class="todays-patients-btn">
									<i class="fa fa-users fa-lg"></i>
									<i class="fa fa-ellipsis-v margin-left-xs"></i>
									<span class="nav-name">
                  {{ 'Today\'s Patients' }}
                </span>
								</a>
							</li>
						}
						<li #supportAndResourcesMenuMobile
							class="dropdown"
							(click)="toggleDropdownMobile(supportAndResourcesMenuMobile)"
							(focusout)="closeMenu(supportAndResourcesMenuMobile)"
							[ngClass]="{'active' : isDropdownOpen(supportAndResourcesMenuMobile)}"
							[attr.data-test-id]="'headerSupportMenuMobile'">
							<a>
								<i class="fa fa-question-circle fa-fw"></i>
								<span class="nav-name">
                {{ 'Help' }}
              </span>
								<span class="caret"></span>
							</a>
							<ul class="dropdown-menu" role="menu">
								<li role="presentation" class="dropdown-header">SUPPORT</li>
								<li>
									<rev-external-link
										[externalLink]="knowledgeBaseAndFaq"
										dataTestId="knowledgeBaseAndFaq"
										externalLinkDisplayName="Knowledge Base & FAQ"
										[leftIconClass]="'book fa-fw'">
									</rev-external-link>
								</li>
								@if (!isWalmart) {
									<li>
										<rev-external-link
											[externalLink]="contactSupport"
											dataTestId="contactSupport"
											externalLinkDisplayName="Contact Support"
											[leftIconClass]="'envelope fa-fw'">
										</rev-external-link>
									</li>
									<li><a (click)="openSendFeedbackModal()">
										<i class="fa fa-comment fa-fw"></i>
										Send Feedback
									</a></li>
									<li><a (click)="openAskMeaningfulUseModal()">
										<i class="fa fa-comments fa-fw"></i>
										Ask about MIPS
									</a></li>
									<li><a (click)="openAskRevAssureModal()">
										<i class="fa fa-comments fa-fw"></i>
										Ask RevAssure
									</a></li>
									<li class="divider" role="presentation"></li>
									<li role="presentation" class="dropdown-header">RESOURCES</li>
									<li>
										<rev-external-link
											[externalLink]="mipsResourceCenter"
											dataTestId="mipsResourceCenter"
											externalLinkDisplayName="MIPS Resource Center"
											[leftIconClass]="'file fa-fw'">
										</rev-external-link>
									</li>
									<li>
										<rev-external-link
											[externalLink]="recordedWebinars"
											dataTestId="recordedWebinars"
											externalLinkDisplayName="Recorded Webinars"
											[leftIconClass]="'file fa-fw'">
										</rev-external-link>
									</li>
									<li>
										<rev-external-link
											[externalLink]="referAColleague"
											dataTestId="referAColleague"
											externalLinkDisplayName="Refer-A-Colleague"
											[leftIconClass]="'user-plus fa-fw'">
										</rev-external-link>
									</li>
								}
							</ul>
						</li>
						<li #locationMenuMobile
							class="dropdown"
							(click)="toggleDropdownMobile(locationMenuMobile)"
							(focusout)="closeMenu(locationMenuMobile)"
							[ngClass]="{'active' : isDropdownOpen(locationMenuMobile)}"
							[attr.data-test-id]="'headerLocationMenuMobile'">
							<a>
								<i class="fa fa-building fa-fw"></i>
								<span class="nav-name">
                {{ (currentUserLocation | async)?.name | textTruncate: 25 }}
              </span>
								<span class="caret"></span>
							</a>
							<ul class="scrollable-menu dropdown-menu" role="menu">
								<li role="presentation" class="dropdown-header">LOCATIONS</li>
								@for (location of userActiveLocations; track location) {
									<li
										[ngClass]="{'active' : isCurrentUserLocation(location)}">
										<a (click)="changeUserLocationMobile(location)"
										   [attr.data-test-id]="'headerLocationSelectMobile'">{{ location.name | textTruncate: 25 }}</a>
									</li>
								}
							</ul>
						</li>
						<li #userMenuMobile
							class="dropdown"
							(click)="toggleDropdownMobile(userMenuMobile)"
							(focusout)="closeMenu(userMenuMobile)"
							[ngClass]="{'active' : isDropdownOpen(userMenuMobile)}"
							[attr.data-test-id]="'headerUserMenuMobile'">
							<a>
								<i class="fa fa-user fa-fw"></i>
								<span class="nav-name">
                {{ userFirstName | textProperCase | textTruncate: 10 }} {{ userLastName | textProperCase | slice:0:1 }}.
              </span>
								<span class="caret"></span>
							</a>
							<ul class="dropdown-menu" role="menu">
								<li role="presentation" class="dropdown-header">{{ userFirstName }} {{ userLastName }}</li>
								<li><a (click)="editProfile()" [attr.data-test-id]="'headerUserEditProfileMobile'"><i class="fa fa-edit fa-fw"></i> Edit Profile</a>
								</li>
								<li><a (click)="openManageUpdatesModal()" [attr.data-test-id]="'headerUserManageUpdatesMobile'"><i class="fa fa-bell fa-fw"></i>
									Manage Updates</a></li>
								<li class="divider" role="presentation"></li>
								<li><a (click)="lockScreen()" [attr.data-test-id]="'headerUserLockScreenMobile'"><i class="fa fa-lock fa-fw"></i> Lock</a></li>
							</ul>
						</li>
						<li (click)="logout()" [attr.data-test-id]="'headerLogoutButtonMobile'">
							<a>
								<i class="fa fa-power-off fa-fw"></i>
								<span class="nav-name">
                {{ 'Logout' }}
              </span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</header>
}
