import { Injectable } from '@angular/core';
import { _assign, _map } from '@core/lodash/lodash';
import { OptionItem, SortingService } from 'morgana';

export type OptionItemResponse<ResponseType, ValueType = any> = OptionItem<ValueType> & ResponseType;

@Injectable({
	providedIn: 'root',
})
export class OptionItemService {

	constructor() {
	}

	/**
	 * Extends an object to act as an OptionItem using the properties given.
	 * If the properties are nested, they can still be referenced like usual: property.subProperty.thing.
	 *
	 * Usage example:
	 * .pipe(
	 * map(templates => templates.map(template => OptionItemService.toOptionItem(template, template => template.carePlan.id, template => template.name)))
	 * );
	 */
	static toOptionItem<ResponseType extends {[key: string]: any}, ValueType>(
		response: ResponseType,
		valueGetter: (item: ResponseType) => ValueType,
		labelGetter: (item: ResponseType) => string,
		iconGetter?: (item: ResponseType) => string,
		disabledGetter?: (item: ResponseType) => boolean,
		styleClassGetter?: (item: ResponseType) => string,
		titlePropertyGetter?: (item: ResponseType) => string,
	): OptionItemResponse<ResponseType, ValueType> {
		return _assign(response, {
			value: valueGetter(response),
			label: labelGetter(response),
			icon: iconGetter ? iconGetter(response) : undefined,
			disabled: disabledGetter ? disabledGetter(response) : undefined,
			styleClass: styleClassGetter ? styleClassGetter(response) : undefined,
			title: titlePropertyGetter ? titlePropertyGetter(response) : undefined,
		});
	}

	/**
	 * Calls {@link toOptionItem} for each item in the provided responses
	 */
	static toOptionItems<ResponseType extends {[key: string]: any}, ValueType>(
		responses: ResponseType[],
		valueGetter: (item: ResponseType) => ValueType,
		labelGetter: (item: ResponseType) => string,
		iconGetter?: (item: ResponseType) => string,
		disabledGetter?: (item: ResponseType) => boolean,
		styleClassGetter?: (item: ResponseType) => string,
		titlePropertyGetter?: (item: ResponseType) => string,
	): OptionItemResponse<ResponseType, ValueType>[] {
		return _map(responses, response => this.toOptionItem(
			response,
			valueGetter,
			labelGetter,
			iconGetter,
			disabledGetter,
			styleClassGetter,
			titlePropertyGetter,
		));
	}

	static sortOptionItems(optionItems: OptionItem[]) {
		return SortingService.sortBy(optionItems, ['label', 'value'], ['asc', 'asc']);
	}

	static toSortedOptionItems<ResponseType extends {[key: string]: any}, ValueType>(
		responses: ResponseType[],
		valueGetter: (item: ResponseType) => ValueType,
		labelGetter: (item: ResponseType) => string,
		iconGetter?: (item: ResponseType) => string,
		disabledGetter?: (item: ResponseType) => boolean,
		styleClassGetter?: (item: ResponseType) => string,
		titlePropertyGetter?: (item: ResponseType) => string,
	): OptionItemResponse<ResponseType, ValueType>[] {
		const optionItems = responses.map(response => OptionItemService.toOptionItem(
			response,
			valueGetter,
			labelGetter,
			iconGetter,
			disabledGetter,
			styleClassGetter,
			titlePropertyGetter,
		));
		return SortingService.sortBy(optionItems, ['label', 'value'], ['asc', 'asc']);
	}
}
