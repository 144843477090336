// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { TwoWayTextPatientMatchResponse } from './two-way-text-patient-match-response';

/** See com.rev360.pms.api.controller.twowaytext.TwoWayTextPatientMatchesResponse */
/* istanbul ignore next */
@GandalfModel
export class TwoWayTextPatientMatchesResponse extends GandalfModelBase {

	@GandalfProperty()
	hasPatientWithInaccessibleLocations: boolean;

	@GandalfArray(TwoWayTextPatientMatchResponse)
	patients: TwoWayTextPatientMatchResponse[];

}
