import { Directive, HostListener } from '@angular/core';
import { GridComponent } from '@syncfusion/ej2-angular-grids';

@Directive({
	/* eslint-disable-next-line @angular-eslint/directive-selector */
	selector: 'ejs-grid:not([pmsGridResizeOverride])',
})
export class GridResizeDirective {

	constructor(
		private gridComponent: GridComponent,
	) {
	}

	@HostListener('resizeStop')
	onResizeStop() {
		if (this.gridComponent) {
			this.gridComponent.hideScroll();
		}
	}
}
