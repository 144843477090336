import { Component } from '@angular/core';

@Component({
	selector: 'pms-no-access',
	templateUrl: './no-access.component.html',
	styles: [],
})
export class NoAccessComponent {

	constructor() { }

}
