// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { ContactResponse } from './contact-response';

// @ts-ignore
import { EmergencyContactStatusResponse } from './emergency-contact-status-response';

// @ts-ignore
import { PhoneNumbersResponse } from './phone-numbers-response';

/** See com.rev360.pms.api.controller.patient.contact.ContactWithEmergencyContactResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactWithEmergencyContactResponse extends ContactResponse {

	@GandalfProperty()
	address: AddressResponse;

	@GandalfProperty()
	credentials: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dob: Date;

	@GandalfProperty()
	email: string;

	@GandalfProperty()
	emergencyContactStatusResponse: EmergencyContactStatusResponse;

	@GandalfProperty()
	firstName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	gender: constants.Gender;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	lastName: string;

	@GandalfProperty()
	middleName: string;

	@GandalfProperty()
	nickname: string;

	@GandalfProperty()
	personId: number;

	@GandalfProperty()
	phones: PhoneNumbersResponse;

	@GandalfProperty()
	roleId: number;

	@GandalfProperty()
	ssn: string;

	@GandalfProperty()
	suffix: string;

	@GandalfProperty()
	titleId: number;

}
