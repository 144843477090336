import { _some } from '@core/lodash/lodash';

export class NavigationRequest {

	primaryContext: string;
	subContexts: (string | number)[];
	previousPrimaryContext: string;
	showHideContainers = true;
	switchTabs = true;
	isPorted = false;

	constructor(
		private _primaryContext: string,
		private _subContexts?: (string | number)[],
		private _previousPrimaryContext?: string,
	) {
		this.primaryContext = this._primaryContext;
		this.subContexts = this._subContexts;
		this.previousPrimaryContext = this._previousPrimaryContext;
	}

	/**
	 * This checks whether a context exists as a legacy module.
	 * If the NavigationRequest is marked as ported, the legacy context will be ignored
	 * @param primaryContexts all legacy modules/apps
	 */
	appliesToPrimaryContexts(primaryContexts: string[]) {
		return _some(primaryContexts, (primaryContext) => (primaryContext === this.primaryContext && !this.isPorted));
	}
}
