// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.ordersoptical.PatientOrderHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientOrderHistoryResponse extends GandalfModelBase {

	@GandalfProperty()
	comment: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	completionDate: Date;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	notifiedComments: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	notifiedDate: Date;

	@GandalfProperty()
	onHold: boolean;

	@GandalfProperty({ propertyType: 'LocalDate' })
	orderDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	orderProcessorType: constants.OrderProcessorType;

	@GandalfConstantDecorator()
	@GandalfProperty()
	orderType: constants.OrderType;

	@GandalfConstantDecorator()
	@GandalfProperty()
	shipToType: constants.OrderShipToType;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.OrderStatusCode;

	@GandalfProperty({ propertyType: 'LocalDate' })
	statusDate: Date;

	@GandalfProperty()
	vendorName: string;

	@GandalfProperty()
	vendorSmartflowCode: string;

}
