import { ItemModel } from '@syncfusion/ej2-angular-splitbuttons';
import { _assign, _map } from '../lodash/lodash';

export type ItemModelResponse<ResponseType> = ItemModel & ResponseType;

export class ItemModelUtils {

	constructor() {
	}

	static toItemModel<ResponseType extends {[key: string]: any}>(
		response: ResponseType,
		textGetter: (item: ResponseType) => string,
	): ItemModelResponse<ResponseType> {
		return _assign(response, {text: textGetter(response)});
	}

	static toItemModels<ResponseType extends {[key: string]: any}>(
		responses: ResponseType[],
		textGetter: (item: ResponseType) => string,
	): ItemModelResponse<ResponseType>[] {
		return _map(responses, response => this.toItemModel(response, textGetter));
	}
}
