import { Component, Input } from '@angular/core';

@Component({
	selector: 'pms-credit-card-form',
	templateUrl: './credit-card-form.component.html',
})
export class CreditCardFormComponent {
	@Input()
	isCardFormLoaded = false;

	@Input()
	isProcessing = false;

	constructor() {
	}

	processPaymentDisabled() {
		return !this.isCardFormLoaded || this.isProcessing;
	}
}
