import { Component, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { OptionItem } from 'morgana';

@Component({
	selector: 'pms-general-benefits-form',
	templateUrl: './general-benefits-form.component.html',
	styles: [],
})
export class GeneralBenefitsFormComponent implements OnInit {

	componentForm: UntypedFormGroup;

	routineExamOptions: OptionItem[] = [
		{label: 'Yes', value: true},
		{label: 'No', value: false},
	];
	contactLensFittingOptions: OptionItem[] = [
		{label: 'Yes', value: true},
		{label: 'No', value: false},
	];

	dateFormat = DATE_FORMATS.MM_DD_YYYY;

	constructor(
		public controlContainer: ControlContainer,
	) {
	}

	ngOnInit() {
		this.componentForm = this.controlContainer.control as UntypedFormGroup;
	}

}
