import { Injectable, Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';


/**
 * @ngdoc pipe
 * @description
 * Angular filter for taking a dob and returning their age in years, or months if less than age 1.
 *
 * @param dob - the date of birth to calculate with
 *
 * @usage
 * {{somebody.dob | personAge}}
 */

@Pipe({
	name: 'personAge',
})
@Injectable({
	providedIn: 'root',
})
export class PersonAgePipe implements PipeTransform {

	transform(dob: string | Date | dayjs.Dayjs, includeUnit = true): string {
		const compDob = dayjs(dob).startOf('day');
		const today = dayjs().startOf('day');
		if (Number(today.diff(compDob, 'year')) < 1) {
			return today.diff(compDob, 'month').toString() + (includeUnit ? ' Months' : '');
		}
		return today.diff(compDob, 'year').toString() + (includeUnit ? ' Years' : '');
	}

}
