import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	ComponentRef,
	Type,
	ViewChild,
	ViewContainerRef
} from '@angular/core';
import { ModalBase } from './modal-base';
import { TypeSafeDynamicModalRef } from './type-safe-dynamic-modal-ref';

@Component({
	selector: 'rev-dynamic-dialog',
	templateUrl: './dynamic-modal.component.html',
})
export class TypeSafeDynamicModalComponent<T, R> implements AfterViewInit {

	@ViewChild('revDynamicModalContent', {read: ViewContainerRef})
	insertionPoint: ViewContainerRef;

	componentRef: ComponentRef<ModalBase<T, R>>;
	childComponentType: Type<ModalBase<T, R>>;
	componentData: T;
	dynamicComponentRef: TypeSafeDynamicModalRef<R>;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		/* eslint-disable-next-line deprecation/deprecation */
		private componentFactoryResolver: ComponentFactoryResolver,
	) {
	}

	ngAfterViewInit() {
		this.loadChildComponent();
		this.changeDetectorRef.detectChanges();
	}

	loadChildComponent() {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.childComponentType);

		const viewContainerRef = this.insertionPoint;
		viewContainerRef.clear();

		/* eslint-disable-next-line deprecation/deprecation */
		this.componentRef = viewContainerRef.createComponent(componentFactory);
		this.componentRef.instance.data = this.componentData;
		this.componentRef.instance.dynamicModalRef = this.dynamicComponentRef;
	}

}
