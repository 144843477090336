import { InvoicePayment, ReceivePaymentsPayer } from '@accounting/invoices/receive-payments/receive-payments.service';
import { CreditCardInput } from '@accounting/invoices/receive-payments/receive-payments/receive-payments.component';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DialogUtil, EnumUtil } from 'morgana';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { _find, _isNil, _some } from '@core/lodash/lodash';
import { UserLocationsService } from '@core/user-locations/user-locations.service';
import { CreatePaymentGroupRequest } from '@gandalf/model/create-payment-group-request';
import { UpdatePaymentGroupRequest } from '@gandalf/model/update-payment-group-request';
import { PaymentGroupResponse } from '@gandalf/model/payment-group-response';
import { CreditCardType, ExternalSystem, PayerType, PaymentGroupSourceType, PaymentGroupStatus, PaymentMethodType } from '@gandalf/constants';
import { OpenEdgeCardReaderResponse } from '@gandalf/model/open-edge-card-reader-response';
import { PracticeLocationExternalAccessSummaryResponse } from '@gandalf/model/practice-location-external-access-summary-response';
import { ReceivePaymentState } from '@payments-store/reducers/payment-tab.reducer';
import { GandalfFormArray } from 'gandalf';
import { Subject } from 'rxjs';
import { OptionItemResponse } from '@core/option-item/option-item.service';
import { PracticeLocation } from '@core/security-manager/security-manager.service';

export type PaymentGroupRequest = CreatePaymentGroupRequest | UpdatePaymentGroupRequest;

@Injectable()
export class PaymentComponentService {

	private paymentSubmittedSubject = new Subject<MouseEvent>();
	private receivePaymentStateChangedSubject = new Subject<ReceivePaymentState>();
	private paymentClosedSubject = new Subject<void>();

	readonly paymentSubmitted = this.paymentSubmittedSubject.asObservable();
	readonly receivePaymentStateChanged = this.receivePaymentStateChangedSubject.asObservable();
	readonly paymentClosed = this.paymentClosedSubject.asObservable();
	openEdgeFeatureFlag: boolean;
	locations: OptionItemResponse<PracticeLocation, number>[];
	errors: string[];
	paymentGroup: PaymentGroupResponse;
	request: PaymentGroupRequest;
	formGroup: UntypedFormGroup;
	isPaymentSubmitted = false;
	payer: ReceivePaymentsPayer;
	creditCardTypes: CreditCardType[];
	applyingPayment: boolean;
	applyingPaymentWithoutProcessing: boolean;
	invoicePayments: InvoicePayment[];
	practiceLocationCredentials: PracticeLocationExternalAccessSummaryResponse[];
	openEdgeCardReaders: OpenEdgeCardReaderResponse[];

	constructor(
		private userLocationService: UserLocationsService,
		private featureService: FeatureService,
	) {
		this.initData();
	}

	initData() {
		this.openEdgeFeatureFlag = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.OPEN_EDGE);
		this.locations = this.userLocationService.getUserActiveLocations();
	}

	get paymentTitle() {
		return this.isReadOnly ? 'Payment Details' : 'Receive Payment(s)';
	}

	get isReadOnly() {
		return EnumUtil.equalsOneOf(this.paymentGroup?.status, PaymentGroupStatus.APPROVED, PaymentGroupStatus.CANCELED, PaymentGroupStatus.PROCESSING);
	}

	get isPaymentFormGroupLoaded() {
		return !_isNil(this.formGroup);
	}

	get formGroupValidationFailed() {
		return this.isPaymentFormGroupLoaded && this.formGroup.invalid && this.isPaymentSubmitted;
	}

	get isOnlinePayment() {
		return EnumUtil.equals(this.paymentGroup?.sourceType, PaymentGroupSourceType.EPAY);
	}

	applyPaymentWithoutProcessing(event: MouseEvent){
		this.applyingPaymentWithoutProcessing = true;
		this.applyingPayment = true;
		this.updateValidations();

		this.paymentSubmittedSubject.next(event);
		this.isPaymentSubmitted = true;
		return this.formGroup.valid ? this.buildRequest() : null;
	}

	buildValidPaymentGroupRequest(event: MouseEvent, applyingPayment: boolean) {
		this.applyingPaymentWithoutProcessing = false;
		this.applyingPayment = applyingPayment;
		this.updateValidations();
		this.paymentSubmittedSubject.next(event);
		this.isPaymentSubmitted = true;
		if (this.formGroup.invalid) {
			return null;
		}

		return this.buildRequest();
	}

	buildRequest() {
		const request = this.formGroup.getRawValue() as PaymentGroupRequest;
		request.payments = request.payments.filter(payment => {
			const invoicePayment = this.getInvoicePaymentById(payment.invoiceId);
			if (!invoicePayment.selected) {
				return false;
			}
			payment.paymentTransfers = invoicePayment.transfersAndPayments.transferItemRequests;
			payment.paymentItems = invoicePayment.transfersAndPayments.paymentItemRequests;
			return true;
		});
		return request;
	}

	/* istanbul ignore next */
	updateValidations() {
		this.formGroup.updateValueAndValidity();
		(this.formGroup.get('payments') as GandalfFormArray).controls.forEach(group => group.updateValueAndValidity());
	}

	getInvoicePaymentById(invoiceId: number) {
		return _find(this.invoicePayments, {invoiceId});
	}

	isCreditCardPaymentMethod() {
		return EnumUtil.equals(this.formGroup.get('paymentMethodType').value, PaymentMethodType.CREDIT_CARD);
	}

	isOpenEdgeActive() {
		const location = _find(this.locations, {id: this.formGroup.get('paymentLocationId').value});
		return !this.isOnlinePayment && this.openEdgeFeatureFlag && location?.openEdgeIsActive;
	}

	isBlueFinActive() {
		const locationCredentials = _find(this.practiceLocationCredentials, {id: this.formGroup.get('paymentLocationId').value});
		return !this.isOnlinePayment && _some(locationCredentials?.externalSystems, value => EnumUtil.equals(value, ExternalSystem.BLUEFIN));
	}

	showCardProcessing() {
		return this.isOpenEdgeActive() || this.isBlueFinActive();
	}

	getSelectedCreditCardInput(): CreditCardInput {
		return this.formGroup.get('selectedCreditCardInput').value;
	}

	getSelectedCardReader() {
		return this.openEdgeCardReaders.find(cardReader => cardReader.openEdgeCardReaderId === this.formGroup.get('selectedOpenEdgeCardReader').value);
	}

	getSelectedOpenEdgeOnFileCard(): CreditCardInput {
		return this.formGroup.get('selectedOpenEdgeOnFileCard').value;
	}

	isInsurancePayer() {
		return EnumUtil.equals(this.getPayerType(), PayerType.INSURANCE);
	}

	isPatientPayer() {
		return EnumUtil.equals(this.getPayerType(), PayerType.PATIENT);
	}

	getPayerType() {
		return this.paymentGroup?.payerType || this.payer?.type;
	}

	closePayment() {
		this.paymentClosedSubject.next();
	}

	confirmClosePayment() {
		if (this.isReadOnly) {
			this.closePayment();
		} else {
			const dialog = DialogUtil.confirm({
				title: 'Close Confirmation',
				content: 'Are you sure you wish to close and lose any unsaved changes?',
				okButton: {
					click: () => {
						this.closePayment();
						dialog.close();
					},
				},
			});
		}
	}

	changeReceivePaymentState(state: ReceivePaymentState) {
		this.receivePaymentStateChangedSubject.next(state);
	}
}
