<ejs-dialog
	#modal
	(close)="closeModal(false)"
	cssClass="modal-sm"
	[attr.data-test-id]="'selectFinanceChargePlanModal'">
	<ng-template #header>
		<div class="dlg-template">Select Finance Charge Plan</div>
	</ng-template>
	<ng-template #content>
		<form>
			<div class="form-group" [attr.data-test-id]="'selectFinanceChargePlanDropdown'">
				<label class="col-sm-4 control-label">Finance Charge Plans</label>
				<div class="col-sm-8">
					<ejs-dropdownlist
						id="financeChargePlan"
						[dataSource]="financeChargePlansList"
						[(ngModel)]="financeChargePlanId"
						name="financeChargePlan"
						[showClearButton]="true"
						placeholder="Select Finance Charge Plan">
					</ejs-dropdownlist>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" (click)="closeModal(false)" [attr.data-test-id]="'selectFinanceChargePlanCancelButton'">Cancel</button>
		<button ejs-button [isPrimary]="true" (click)="saveFinanceChargePlanAndRefreshInvoice()" [attr.data-test-id]="'selectFinanceChargePlanSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>
