import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CarePlanItemService } from '@core/diagnosis/care-plan-item.service';
import { DiagnosisService, SortablePersonDiagnosisResponse } from '@core/diagnosis/diagnosis.service';
import { _cloneDeep, _filter, _isNil } from '@core/lodash/lodash';
import { DynamicModalRef, ModalConfig, ModalManagerService } from 'morgana';
import { CarePlanItemHistoryResponse } from '@gandalf/model/care-plan-item-history-response';
import { EncounterAddCarePlanItemsToPersonDiagnosisRequest } from '@gandalf/model/encounter-add-care-plan-items-to-person-diagnosis-request';
import { PersonDiagnosisResponse } from '@gandalf/model/person-diagnosis-response';
import { PracticeDiagnosisResponse } from '@gandalf/model/practice-diagnosis-response';
import { DATE_FORMATS, TABLE_DATE_FORMATS } from '@shared/constants/date-format.constants';
import { GridComponent, RowDataBoundEventArgs } from '@syncfusion/ej2-angular-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { QueryCellInfoEventArgs, SortSettingsModel } from '@syncfusion/ej2-grids';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BACKGROUND_COLOR_CONSTANTS } from '@shared/constants/background-colors.constants';

export enum CPIMode {
	VIEW,
	SELECT,
}

export const CarePlanStatus = {
	ACTIVE: {
		label: 'Active',
		value: 1,
	},
	INACTIVE: {
		label: 'Inactive',
		value: 2,
	},
};

@Component({
	selector: 'pms-care-plan-item-history-modal',
	templateUrl: './care-plan-item-history-modal.component.html',
	styles: [],
})
export class CarePlanItemHistoryModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('grid')
	grid: GridComponent;

	itemsSelected = 0;
	mode: CPIMode = CPIMode.VIEW;
	cpiMode = CPIMode;
	personDiagnosisId: number;
	carePlanItemHistory: CarePlanItemHistoryResponse[] = [];
	filteredCarePlanItems: CarePlanItemHistoryResponse[] = [];
	personDiagnosis: PersonDiagnosisResponse;
	originalPersonDiagnosis: PersonDiagnosisResponse;
	isSearching = false;
	tableDateFormat = TABLE_DATE_FORMATS.MM_DD_YYYY;
	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	carePlanFilterOptions = [
		CarePlanStatus.ACTIVE,
		CarePlanStatus.INACTIVE,
	];

	carePlanFilterStatus = CarePlanStatus.ACTIVE;
	sortSettings: SortSettingsModel = {
		columns: [
			{field: 'carePlanItem.startDate', direction: 'Descending'},
			{field: 'practiceDiagnosis', direction: 'Descending'},
			{field: 'carePlanItem.id', direction: 'Ascending'},
		],
	};

	constructor(
		private carePlanItemService: CarePlanItemService,
		private diagnosisService: DiagnosisService,
		private dynamicModalRef: DynamicModalRef,
		private config: ModalConfig,
		public modalManagerService: ModalManagerService,
		public datePipe: DatePipe,
	) { }

	ngOnInit() {
		this.parseConfigData(this.config.data);
		this.configureData();
	}

	codeColumnComparator = (a: PracticeDiagnosisResponse, b: PracticeDiagnosisResponse) => a.codeSet.value - b.codeSet.value;

	parseConfigData(data: any) {
		this.mode = data.mode;
		this.personDiagnosisId = data.personDiagnosisId;
	}

	configureData() {
		this.isSearching = true;

		this.getData().subscribe(([personDiagnosis, carePlanItemHistory, originalPersonDiagnosis]) => {
			this.carePlanItemHistory = carePlanItemHistory;
			this.personDiagnosis = personDiagnosis;
			this.originalPersonDiagnosis = originalPersonDiagnosis;
			this.isSearching = false;

			this.filterGrid();
		});
	}

	getData() {
		return this.diagnosisService.getPersonDiagnosisById(this.personDiagnosisId).pipe(
			switchMap(personDiagnosis => combineLatest([of(personDiagnosis),
				this.carePlanItemService.findCarePlanItemsByPersonDiagnosisId(personDiagnosis.originalDiagnosisId || this.personDiagnosisId),
				this.getOriginalDiagnosis(personDiagnosis)])),
		);
	}

	getOriginalDiagnosis(personDiagnosis: PersonDiagnosisResponse): Observable<PersonDiagnosisResponse> {
		if (_isNil(personDiagnosis)) {
			return of(null  as PersonDiagnosisResponse);
		}

		if (_isNil(personDiagnosis.originalDiagnosisId)) {
			return of(personDiagnosis);
		}

		return this.diagnosisService.getPersonDiagnosisById(personDiagnosis.originalDiagnosisId);
	}

	closeModal(refreshRequested = false) {
		this.dynamicModalRef.close(this.modal, {doRefresh: refreshRequested});
	}

	applyBackgroundColor(args: QueryCellInfoEventArgs) {
		if (args.column.field === 'practiceDiagnosis.code' && (args.data as SortablePersonDiagnosisResponse).isShowDisabledWarning) {
			args.cell.classList.add(BACKGROUND_COLOR_CONSTANTS.DANGER);
		}
	}

	filterGrid() {
		if (this.carePlanFilterStatus && this.carePlanFilterStatus.value === CarePlanStatus.ACTIVE.value) {
			this.filteredCarePlanItems = _filter(this.carePlanItemHistory, carePlanItemHistory => _isNil(carePlanItemHistory.carePlanItem.stopDate));
		} else if (this.carePlanFilterStatus && this.carePlanFilterStatus.value === CarePlanStatus.INACTIVE.value) {
			this.filteredCarePlanItems = _filter(this.carePlanItemHistory, carePlanItemHistory => !_isNil(carePlanItemHistory.carePlanItem.stopDate));
		} else {
			this.filteredCarePlanItems = _cloneDeep(this.carePlanItemHistory);
		}
	}

	setItemsSelected() {
		this.itemsSelected = this.grid.getSelectedRecords().length;
	}

	selectCPI() {
		const carePlanItemIds = (this.grid.getSelectedRecords() as CarePlanItemHistoryResponse[]).map(carePlanItemHistory => carePlanItemHistory.carePlanItem.id);
		const addCarePlanItemsToPersonDiagnosisRequest = new EncounterAddCarePlanItemsToPersonDiagnosisRequest();
		addCarePlanItemsToPersonDiagnosisRequest.carePlanItemIds = carePlanItemIds;
		addCarePlanItemsToPersonDiagnosisRequest.personDiagnosisId = this.personDiagnosisId;
		this.carePlanItemService.addHistoricCarePlanItemsToPersonDiagnosis(addCarePlanItemsToPersonDiagnosisRequest).subscribe(
			() => this.closeModal(true),
		);
	}

	setSelection(args: any) {
		if (!args.data.carePlanItem.allowPullForward) {
			args.cancel = true;
		}
	}

	disableCheckbox(args: RowDataBoundEventArgs) {
		if (_isNil(args)) {
			return;
		}

		if (!(args.data as CarePlanItemHistoryResponse).carePlanItem.allowPullForward) {
			args.row.getElementsByClassName('e-checkbox-wrapper')[0].classList.add('hidden');
			this.grid.getColumnHeaderByField('checkbox').getElementsByClassName('e-checkbox-wrapper')[0].classList.add('hidden');
		}
	}

	getTooltipContent(data) {
		return data.carePlanItem.allowPullForward ? '' : 'This CPI has been deleted in Admin.';
	}
}
