import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { _isNil } from '@core/lodash/lodash';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { CreateCategoryRequest } from '@gandalf/model/create-category-request';
import { UpdateCategoryRequest } from '@gandalf/model/update-category-request';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';

@Component({
	selector: 'pms-sub-category-modal',
	templateUrl: './sub-category-modal.component.html',
})
export class SubCategoryModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('ngForm')
	ngForm: NgForm;

	formGroup: UntypedFormGroup;
	editMode = false;
	category: CreateCategoryRequest | UpdateCategoryRequest;

	constructor(
		private dynamicModalRef: DynamicModalRef,
		private modalConfig: ModalConfig,
		private gandalfFormBuilder: GandalfFormBuilder,
	) {
	}

	ngOnInit(): void {
		this.parseModalConfig(this.modalConfig);
		this.createFormGroup();
	}

	parseModalConfig(modalConfig: ModalConfig) {
		if (!_isNil(modalConfig.data.category)) {
			this.category = new UpdateCategoryRequest();
			this.category.active = modalConfig.data.category.active;
			this.category.name = modalConfig.data.category.name;
			this.category.description = modalConfig.data.category.description;
			this.category.id = modalConfig.data.category.id;
			this.editMode = true;
		} else {
			this.category = new CreateCategoryRequest();
			this.category.active = true;
			this.category.entityType = modalConfig.data.entityType;
		}
	}

	createFormGroup() {
		this.formGroup = this.gandalfFormBuilder.group(this.category);
	}

	/* istanbul ignore next: closeModal */
	closeDialog(category?) {
		this.dynamicModalRef.close(this.modal, category);
	}

	submitForm(event) {
		this.ngForm.onSubmit(event);
	}

	submit() {
		if (this.formGroup.invalid) {
			return;
		}
		this.closeDialog(this.formGroup.value);
	}
}
