import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm } from '@angular/forms';
import { DynamicModalRef, ModalConfig, PAGE_LENGTH, PAGE_LENGTH_LIST } from 'morgana';
import { QueryService } from '@core/query/query.service';
import { ImportMasterQueriesRequest } from '@gandalf/model/import-master-queries-request';
import { MasterQueryResponse } from '@gandalf/model/master-query-response';
import { QuerySummaryResponse } from '@gandalf/model/query-summary-response';
import { arrayLengthValidator } from '@shared/validators/array-length-validation';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { PageSettingsModel, SelectionSettingsModel, SortSettingsModel } from '@syncfusion/ej2-grids';

@Component({
	selector: 'pms-import-queries',
	templateUrl: './import-queries.component.html',
	styles: [],
})
export class ImportQueriesComponent implements OnInit {

	@ViewChild('dialog')
	dialog: DialogComponent;

	@ViewChild('form')
	form: NgForm;

	@ViewChild('grid')
	grid: GridComponent;

	request: ImportMasterQueriesRequest;
	formGroup: UntypedFormGroup;
	masterQueries: MasterQueryResponse[];
	isSearching: boolean;
	sortSettings: SortSettingsModel = {
		columns: [
			{field: 'name', direction: 'Ascending'},
		],
	};
	pageSettings: PageSettingsModel = {
		pageSizes: PAGE_LENGTH_LIST,
		pageSize: PAGE_LENGTH.PAGE_10,
	};
	selectionSettings: SelectionSettingsModel = {
		type: 'Multiple',
		persistSelection: true,
	};

	constructor(
		private formBuilder: UntypedFormBuilder,
		private queryService: QueryService,
		private dynamicModalRef: DynamicModalRef,
		public modalConfig: ModalConfig,
	) {
	}

	ngOnInit() {
		this.createForm();
		this.isSearching = true;
		this.queryService.findMasterQueriesByTemplateCategoryAndCustomReportsMasterCategory(
			this.modalConfig.data.queryTemplateCategory,
			this.modalConfig.data.customReportsMasterCategory,
		).subscribe(masterQueries => {
			this.isSearching = false;
			this.masterQueries = masterQueries;
		});
	}

	createForm() {
		this.request = new ImportMasterQueriesRequest();
		this.request.masterQueryIds = [];
		this.formGroup = this.formBuilder.group({masterQueryIds: [this.request.masterQueryIds]},
			{validators: [arrayLengthValidator('masterQueryIds', 1, 'masterQueryIdsLength', 'Must select query to import')]});
	}

	updateSelectedQueries() {
		this.formGroup.controls.masterQueryIds.setValue(this.grid.getSelectedRecords().map((query: MasterQueryResponse) => query.id));
	}

	importQueries() {
		if (this.formGroup.invalid) {
			return;
		}
		this.queryService.importMasterQueries(this.formGroup.value).subscribe(importedQueries => this.close(importedQueries));
	}

	close(importedQueries?: QuerySummaryResponse[]) {
		this.dynamicModalRef.close(this.dialog, importedQueries || []);
	}

}
