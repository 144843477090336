import { getMetadataPropertyValues } from '../metadata/gandalf-metadata-util';
import { GANDALF_LABEL_METADATA_KEY } from './gandalf-label.decorator';
import { GANDALF_SUBREQUEST_METADATA_KEY } from './gandalf-subrequest-label.decorator';
import { GANDALF_VALIDATOR_METADATA_KEY } from './gandalf-validator.decorator';
import { GANDALF_IS_REQUIRED_METADATA_KEY } from './gandalf-property.decorator';

/**
 * Decorator to apply to all Gandalf Model classes.
 *
 * This assembles Gandalf metadata provided by other decorators, and extends the class instances with properties to access the metadata.
 *
 * $isGandalfModel - true for all Gandalf Models
 * $label - dictionary of labels for each class property
 * $subRequestLabel - dictionary of subrequest labels for each class property
 * $validators - dictionary of validators for each class property
 * $isRequired - dictionary of requirement for each class property
 */
export function GandalfModel(target: any) {

	/*
	 * Save a reference to the original constructor so we can use it later
 	 */
	const original = target;

	/*
	 * Generates an instance of the class when it is called for.
	 * This allows us to override the normal class definition so that we can add
	 * in the $label, $subRequestLabel, $validators, and any other properties we want to add that are defined by other decorators.
	 */
	function construct(constructor, _args) {
		/*
		 * Create $label getter
		 * We are removing the original $label property so the decorator can replace it with its own.
		 */
		/* istanbul ignore else */
		if (constructor.prototype['$label']) {
			delete constructor.prototype['$label'];
		}
		// Create $label property with getter
		Object.defineProperty(constructor.prototype, '$label', {
			get: labelGetter,
			enumerable: false,
			configurable: true,
		});

		if (constructor.prototype['$isGandalfModel']) {
			delete constructor.prototype['$isGandalfModel'];
		}
		// Create $isGandalfModel property with getter
		Object.defineProperty(constructor.prototype, '$isGandalfModel', {
			get: () => true,
			enumerable: false,
			configurable: true,
		});

		/*
		 * Create $subRequestLabel getter
		 * We are removing the original $subRequestLabel property so the decorator can replace it with its own.
		 */
		if (constructor.prototype['$subRequestLabel']) {
			delete constructor.prototype['$subRequestLabel'];
		}
		// Create $subRequestLabel property with getter
		Object.defineProperty(constructor.prototype, '$subRequestLabel', {
			get: subRequestLabelGetter,
			enumerable: false,
			configurable: true,
		});

		/*
		 * Create $validators getter
		 * We are removing the original $validators property so the decorator can replace it with its own.
		 */
		/* istanbul ignore else */
		if (constructor.prototype['$validators']) {
			delete constructor.prototype['$validators'];
		}
		// Create $validators property with getter
		Object.defineProperty(constructor.prototype, '$validators', {
			get: validatorsGetter,
			enumerable: false,
			configurable: true,
		});

		/*
		 * Create $isRequired getter
		 * Remove the original $isRequired property so the decorator can replace it with its own.
		 */
		/* istanbul ignore else */
		if (constructor.prototype['$isRequired']) {
			delete constructor.prototype['$isRequired'];
		}
		// Create $isRequired property with getter
		Object.defineProperty(constructor.prototype, '$isRequired', {
			get: isRequiredGetter,
			enumerable: false,
			configurable: true,
		});

		return Object.create(constructor.prototype);
	}

	/*
	 * Defines the new constructor behaviour
	 */
	/* eslint-disable-next-line prefer-arrow/prefer-arrow-functions */
	const newConstructor: any = function(...args) {
		return construct(original, args);
	};

	/*
	 * Copy prototype so instanceof operator still works
	 */
	newConstructor.prototype = original.prototype;

	/*
	 * Return new constructor (will override original)
	 */
	return newConstructor;
}

/**
 * Gets a dictionary mapping each property name to its label metadata
 */
function labelGetter() {
	return getMetadataPropertyValues(this, GANDALF_LABEL_METADATA_KEY);
}

/**
 * Gets a dictionary mapping each property name to its subrequest label metadata
 */
function subRequestLabelGetter() {
	return getMetadataPropertyValues(this, GANDALF_SUBREQUEST_METADATA_KEY);
}

/**
 * Gets a dictionary mapping each property name to its validator metadata
 */
function validatorsGetter() {
	return getMetadataPropertyValues(this, GANDALF_VALIDATOR_METADATA_KEY);
}

/**
 * Gets a dictionary mapping each property name to its requirement metadata
 */
function isRequiredGetter() {
	return getMetadataPropertyValues(this, GANDALF_IS_REQUIRED_METADATA_KEY);
}
