<rev-loading-overlay [loading]="_isLoading">
	<rev-button
		(buttonClick)="paymentComponentService.confirmClosePayment()"
		[buttonDisabled]="_isLoading"
		buttonIcon="close"
		buttonLabel="Cancel"
		dataTestId="paymentCancelButton">
	</rev-button>
	@if (showEditPayment()) {
		<rev-button
			(buttonClick)="launchEditPaymentModal()"
			dataTestId="editPaymentButton"
			[buttonDisabled]="_isLoading"
			buttonStyle="primary"
			buttonLabel="Edit Payment">
		</rev-button>
	}
	@if (paymentComponentService.isPaymentFormGroupLoaded) {
		@if (showApplyPaymentWithoutProcessing()) {
			<rev-button
				class="pull-left"
				(buttonClick)="applyPaymentWithoutProcessing($event)"
				[buttonDisabled]="disableApplyPaymentsWithoutProcessing()"
				dataTestId="applyPaymentsWithoutProcessingButton"
				buttonLabel="Apply payment without processing card">
			</rev-button>
		}
		<rev-button
			(buttonClick)="saveOrApplyPayment($event, false, false)"
			[buttonDisabled]="_isLoading"
			dataTestId="paymentSaveAsPendingButton"
			buttonLabel="Save as Pending">
		</rev-button>
		@if (showPrintReceipts()) {
			<rev-button
				(buttonClick)="saveOrApplyPayment($event, true, true)"
				[buttonDisabled]="_isLoading"
				buttonStyle="primary"
				dataTestId="applyPaymentsAndPrintReceiptsButton"
				buttonLabel="Apply Payments & Print Receipts">
			</rev-button>
		}
		@if (!showProcessPayment()) {
			<rev-button
				(buttonClick)="saveOrApplyPayment($event, true, false)"
				[buttonDisabled]="_isLoading"
				buttonStyle="primary"
				dataTestId="applyPaymentsButton"
				buttonLabel="Apply Payments">
			</rev-button>
		}
		@if (showProcessPaymentAndPrintReceipts()) {
			<rev-button
				(buttonClick)="processPayment($event, true)"
				[buttonDisabled]="_isLoading"
				buttonStyle="primary"
				dataTestId="processPaymentAndPrintReceiptsButton"
				buttonLabel="Process Payment & Print Receipts">
			</rev-button>
		}
		@if (showProcessPayment()) {
			<rev-button
				(buttonClick)="processPayment($event, false)"
				[buttonDisabled]="_isLoading"
				buttonStyle="primary"
				dataTestId="processPaymentsButton"
				buttonLabel="Process Payment">
			</rev-button>
		}
	}
</rev-loading-overlay>
