<ejs-dialog
	#modal
	cssClass="modal-md"
	(close)="closeModal()"
	[showCloseIcon]="!isCreditCardProcessing()"
	[attr.data-test-id]="'addCreditCardModal'">
	<ng-template #header>
		<div class="dlg-template">Add Credit Card</div>
	</ng-template>
	<ng-template #content>
		@if (errors.length) {
			<div class="alert alert-danger">
				<ul>
					@for (error of errors; track error) {
						<li><strong>{{ error }}</strong></li>
					}
				</ul>
			</div>
		}
		<pms-credit-card-form [isCardFormLoaded]="isCardFormLoaded()" [isProcessing]="isCreditCardProcessing()"></pms-credit-card-form>
	</ng-template>
	<ng-template #footerTemplate>
		<ng-container>
			<button ejs-button iconCss="fa fa-times" type="button" (click)="closeModal()" [attr.data-test-id]="'addCreditCardCancelButton'">Cancel</button>
			<button ejs-button [isPrimary]="true" type="button" (click)="saveCardInfo()" [disabled]="processPaymentDisabled()"
					[attr.data-test-id]="'addCreditCardSaveButton'">Save
			</button>
		</ng-container>
	</ng-template>
</ejs-dialog>
