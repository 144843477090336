import { Component, Input } from '@angular/core';
import { PatientPopoverResponse } from '@gandalf/model/patient-popover-response';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { PreferredPhoneType } from '@gandalf/constants';
import { EnumUtil } from 'morgana';
import { PatientService } from '@core/patient/patient.service';
import { PatientNameResponse } from '@gandalf/model/patient-name-response';

@Component({
	selector: 'pms-patient-popover',
	templateUrl: './patient-popover.component.html',
})
export class PatientPopoverComponent {
	_patientName: PatientNameResponse;
	patientPopoverData: PatientPopoverResponse;
	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	preferredPhoneType = PreferredPhoneType;

	constructor(
		private patientService: PatientService,
	) {
	}

	get patientName(): PatientNameResponse {
		return this._patientName;
	}

	@Input()
	set patientName(patientName: PatientNameResponse) {
		const idChanged = this.patientPopoverData?.patientId !== patientName.patientId;
		this._patientName = patientName;
		if(idChanged) {
			this.patientPopoverData = null;
			this.patientService.getPatientForPopover(patientName.patientId).subscribe((data) => {
				this.patientPopoverData = data;
			});
		}
	}

	isPreferredPhoneType(preferredPhoneType: PreferredPhoneType) {
		return EnumUtil.equals(preferredPhoneType, this.patientPopoverData?.phoneNumbers.preferredPhoneType);
	}
}
