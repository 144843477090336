// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.shared.category.CategoryResponse */
/* istanbul ignore next */
@GandalfModel
export class CategoryResponse extends GandalfModelBase {

	@GandalfProperty()
	active: boolean;

	@GandalfProperty()
	description: string;

	@GandalfProperty()
	editable: boolean;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	name: string;

	@GandalfProperty()
	sortOrder: number;

	@GandalfArray(CategoryResponse)
	subCategories: CategoryResponse[];

}
