import { Injectable } from '@angular/core';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { PatientSearchRequest } from '@gandalf/model/patient-search-request';
import { PatientSearchResponse } from '@gandalf/model/patient-search-response';
import { PatientGandalfService } from '@gandalf/services';
import { FormattedSingleLineAddressPipe } from '@shared/pipes/formatted-single-line-address/formatted-single-line-address.pipe';
import { PatientNamePipe } from '@shared/pipes/patient-name/patient-name.pipe';
import { map } from 'rxjs/operators';

export interface FormattedPatientSearchResponse extends PatientSearchResponse {
	formattedName?: string;
	formattedAddress?: string;
}

@Injectable({
	providedIn: 'root',
})
export class SearchPatientService {

	constructor(
		public patientGandalfService: PatientGandalfService,
		public patientNamePipe: PatientNamePipe,
		public formattedSingleLineAddressPipe: FormattedSingleLineAddressPipe,
		private securityManagerService: SecurityManagerService,
	) {
	}

	searchPatients(request: PatientSearchRequest) {
		return this.patientGandalfService.findPatients(request).pipe(
			map(patients => patients.map(patient => this.mapToFormattedPatient(patient))),
		);
	}

	getPatientForPopover(patientId: number) {
		return this.patientGandalfService.getPatientForPopoverById(patientId);
	}

	getPatientAlerts(patientId: number) {
		return this.patientGandalfService.getAlertsByPatientId(patientId);
	}

	mapToFormattedPatient(patient: PatientSearchResponse) {
		const formattedPatient: FormattedPatientSearchResponse = patient;
		formattedPatient.formattedName = this.patientNamePipe.transform(patient.patientNameResponse, true);
		formattedPatient.formattedAddress = this.formattedSingleLineAddressPipe.transform(patient.addressResponse);
		return formattedPatient;
	}

	getAtLeastOneFields() {
		return [
			'lastName',
			'firstName',
			'patientId',
			this.securityManagerService.isCanada() ? 'socialInsuranceNumber' : 'socialSecurityNumber',
			'dateOfBirth',
			'phoneNumber',
		];
	}

	getAtLeastOneMessage() {
		return `Please specify a Last name, First name, ID, ${this.securityManagerService.isCanada() ? 'SIN/PHN' : 'SSN'}, DOB, or Phone`;
	}
}
