<div class="table-responsive margin-bottom-md">
	<table class="table table-hover table-nowrap table-bordered text-right margin-0" [attr.data-test-id]="'contactLensTypeSoftTable'">
		<thead>
		<tr>
			<th></th>
			<th>MV</th>
			<th class="text-right">BC</th>
			<th class="text-right">Sph</th>
			<th class="text-right">Cyl</th>
			<th class="text-right">Axis</th>
			<th class="text-right">Diam</th>
			<th class="text-right">Add</th>
			<th>Add Des</th>
			<th>Color</th>
		</tr>
		</thead>
		<tbody>
		<tr>
			<td class="nostretch text-left">
				<span class="label label-success">OD</span><br>
				<span class="label label-info">OS</span>
			</td>
			<td class="text-left" [attr.data-test-id]="'contactLensTypeSoftOdIsMonovisionCell'">
				{{ contactLensPrescription.contactLensEyeSoftOd?.isMonovision | yesNo : 'Yes' : '' }}<br>
				{{ contactLensPrescription.contactLensEyeSoftOs?.isMonovision | yesNo : 'Yes' : '' }}
			</td>
			<td [attr.data-test-id]="'contactLensTypeSoftOdIsBaseCurveCell'">
				{{ contactLensPrescription.contactLensEyeSoftOd?.baseCurve | baseCurve }}<br>
				{{ contactLensPrescription.contactLensEyeSoftOs?.baseCurve | baseCurve  }}
			</td>
			<td [attr.data-test-id]="'contactLensTypeSoftOdIsSphereCell'">
				{{ contactLensPrescription.contactLensEyeSoftOd?.sphere | plusMinus  }}<br>
				{{ contactLensPrescription.contactLensEyeSoftOs?.sphere | plusMinus  }}
			</td>
			<td [attr.data-test-id]="'contactLensTypeSoftOdIsCylinderCell'">
				{{ contactLensPrescription.contactLensEyeSoftOd?.cylinder | plusMinus  }}<br>
				{{ contactLensPrescription.contactLensEyeSoftOs?.cylinder | plusMinus  }}
			</td>
			<td [attr.data-test-id]="'contactLensTypeSoftOdIsAxisCell'">
				{{ contactLensPrescription.contactLensEyeSoftOd?.axis | padAxis }}<br>
				{{ contactLensPrescription.contactLensEyeSoftOs?.axis | padAxis }}
			</td>
			<td [attr.data-test-id]="'contactLensTypeSoftOdIsDiameterCell'">
				{{ contactLensPrescription.contactLensEyeSoftOd?.diameter | forceDecimalNullSafe : 1 }}<br>
				{{ contactLensPrescription.contactLensEyeSoftOs?.diameter | forceDecimalNullSafe : 1 }}
			</td>
			<td [attr.data-test-id]="'contactLensTypeSoftOdIsAddPowerCell'">
				{{ contactLensPrescription.contactLensEyeSoftOd?.addPower | plusMinus  }}<br>
				{{ contactLensPrescription.contactLensEyeSoftOs?.addPower | plusMinus  }}
			</td>
			<td class="text-left" [attr.data-test-id]="'contactLensTypeSoftOdIsAddDesignationCell'">
				{{ contactLensPrescription.contactLensEyeSoftOd?.addDesignation?.value }}<br>
				{{ contactLensPrescription.contactLensEyeSoftOs?.addDesignation?.value }}
			</td>
			<td class="text-left" [attr.data-test-id]="'contactLensTypeSoftOdIsTintCell'">
				{{ contactLensPrescription.contactLensEyeSoftOd?.tint }}<br>
				{{ contactLensPrescription.contactLensEyeSoftOs?.tint }}
			</td>
		</tr>
		</tbody>
	</table>
</div>
