import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class PrintUtilService {

	constructor(
		@Inject(DOCUMENT) private document: Document,
	) {
	}

	/**
	 * Opens the browser's standard print window with the provided content.
	 * The content is wrapped in a <pre> tag to preserve formatting.
	 * @param pages The page content to print. If there are multiple arguments, each will be on its own page.
	 */
	/* istanbul ignore next */
	printFormattedText(...pages: string[]) {
		const iFrame = this.document.createElement('iframe');
		iFrame.style.position = 'fixed';
		iFrame.style.right = '0';
		iFrame.style.bottom = '0';
		iFrame.style.width = '0';
		iFrame.style.height = '0';
		iFrame.style.border = '0';
		this.document.body.appendChild(iFrame);
		iFrame.contentWindow.document.open();
		pages.forEach(page => {
			const pageContent = this.document.createElement('pre');
			pageContent.style.pageBreakAfter = 'always';
			pageContent.textContent = page;
			iFrame.contentWindow.document.write(pageContent.outerHTML);
		});
		iFrame.contentWindow.document.close();
		iFrame.focus();
		// Some versions of Chrome won't open the print window unless there is a timeout
		setTimeout(() => {
			iFrame.contentWindow.print();
			this.document.body.removeChild(iFrame);
		}, 500);
	}
}
