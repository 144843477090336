import { Component, ViewChild } from '@angular/core';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { UrlService } from '@core/url-util/url.service';
import { URL_PRINT_ENDPOINTS } from '@shared/constants/url.constants';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
	selector: 'pms-address-label-modal-component',
	templateUrl: './address-label-modal.component.html',
})
export class AddressLabelModalComponent {

	@ViewChild('modal')
	modal: DialogComponent;

	labelFormat = 'Avery5160';

	isVisible = true;

	constructor(
		private dynamicModalRef: DynamicModalRef,
		private modalConfig: ModalConfig,
		private urlService: UrlService,
	) {
	}

	printLabels() {
		this.closeDialog();
		this.urlService.openTabWithPost(URL_PRINT_ENDPOINTS.ADDRESS_LABEL, {patientId: this.modalConfig.data.patientIds});
	}

	closeDialog() {
		this.dynamicModalRef.close(this.modal);
	}

}
