import { Component, Input } from '@angular/core';
import { _isEmpty } from '../../utils/lodash/lodash';

@Component({
	selector: 'rev-external-link',
	templateUrl: './external-link.component.html',
	styles: [],
})
export class ExternalLinkComponent {

	@Input()
	externalLink: string;

	@Input()
	externalLinkDisplayName: string;

	@Input()
	windowName: string;

	@Input()
	dataTestId: string;

	@Input()
	leftIconClass: string;

	constructor() {
	}

	get linkDisplayName(): string {
		return this.externalLinkDisplayName ?? this.externalLink;
	}

	get target(): string {
		return this.windowName ?? '_blank';
	}

	isNotEmpty(value): boolean {
		return !_isEmpty(value);
	}
}
