import { FormMode } from '../constants/form-mode.enum';

export class FormState<T = any> {
	value: T;
	mode: FormMode;
	submitted?: boolean;
}

export class LegacyStatefulComponentBaseState {
	formState: FormState = new FormState();
	gridState: string;
	gridSelectedPrimaryKeys: any[];
}
