// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AdminAccessTimeRequest } from './admin-access-time-request';

/** See com.rev360.pms.api.controller.admin.employee.detailed.request.AdminEmployeeLoginRestrictionRequest */
/* istanbul ignore next */
@GandalfModel
export class AdminEmployeeLoginRestrictionRequest extends GandalfModelBase {

	@GandalfProperty()
	friday: AdminAccessTimeRequest;

	@GandalfProperty()
	monday: AdminAccessTimeRequest;

	@GandalfProperty()
	saturday: AdminAccessTimeRequest;

	@GandalfProperty()
	sunday: AdminAccessTimeRequest;

	@GandalfProperty()
	thursday: AdminAccessTimeRequest;

	@GandalfProperty()
	tuesday: AdminAccessTimeRequest;

	@GandalfProperty()
	wednesday: AdminAccessTimeRequest;

}
