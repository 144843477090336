import { Pipe, PipeTransform } from '@angular/core';
import { EstablishedStatus } from '@gandalf/constants';

@Pipe({
	name: 'patientEstablished',
})
export class PatientEstablishedPipe implements PipeTransform {

	transform(patientEstablishedStatus: EstablishedStatus): any {
		let establishedText = '';
		if (patientEstablishedStatus === EstablishedStatus.NEW) {
			establishedText = '(New)';
		}
		return establishedText;
	}

}
