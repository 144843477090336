import { Injectable } from '@angular/core';
import { CellFormattingUtils } from '../cell-formatting-utils/cell-formatting-utils.service';
import { _cloneDeep } from '../lodash/lodash';
import { defaultColDef, GridUtil, RevColDef } from './grid-util';

@Injectable({
	providedIn: 'root',
})
export class GridUtilService {

	constructor(
		private cellFormattingUtils: CellFormattingUtils,
	) {
	}

	currencyComparator(valueA, valueB) {
		return valueA - valueB;
	}

	buildHyperlinkCurrencyColumn<TData>(
		headerName: string,
		field: string,
		navigationCall: (rowData: TData) => void,
		assertion?: (rowData: TData) => boolean,
		overrides?: RevColDef<TData>,
	): RevColDef<TData> {
		const column = GridUtil.buildHyperlinkColumn<TData>(headerName, field, navigationCall, assertion);
		column.type = 'numericColumn';
		column.comparator = this.currencyComparator;
		column.valueFormatter = this.cellFormattingUtils.currency();
		return GridUtil.applyOverrides(column, overrides);
	}

	buildDateColumn<TData>(headerName: string, field: string, dateFormat?: string, overrides?: RevColDef<TData>): RevColDef<TData> {
		const dateColumn = _cloneDeep(defaultColDef);
		dateColumn.headerName = headerName;
		dateColumn.field = field;

		dateColumn.valueFormatter = this.cellFormattingUtils.date(dateFormat);

		return GridUtil.applyOverrides(dateColumn, overrides);
	}

	buildCurrencyColumn<TData>(headerName: string, field: string, overrides?: RevColDef<TData>): RevColDef<TData> {
		const currencyColumn = _cloneDeep(defaultColDef);
		currencyColumn.headerName = headerName;
		currencyColumn.field = field;
		currencyColumn.type = 'numericColumn';
		currencyColumn.comparator = this.currencyComparator;
		currencyColumn.valueFormatter = this.cellFormattingUtils.currency();

		return GridUtil.applyOverrides(currencyColumn, overrides);
	}

	buildPercentColumn<TData>(headerName: string, field: string, format?: string, overrides?: RevColDef<TData>): RevColDef<TData> {
		const percentColumn = _cloneDeep(defaultColDef);
		percentColumn.headerName = headerName;
		percentColumn.field = field;
		percentColumn.type = 'numericColumn';
		percentColumn.valueFormatter = this.cellFormattingUtils.percentFormat(format);

		return GridUtil.applyOverrides(percentColumn, overrides);
	}
}
