// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreatePatientBasicInformationRequest } from './create-patient-basic-information-request';

/** See com.rev360.pms.api.controller.patient.PatientBasicInformationRequest */
/* istanbul ignore next */
@GandalfModel
export class PatientBasicInformationRequest extends CreatePatientBasicInformationRequest {

	@GandalfValidator({ sizeString: { message: 'Credentials cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty()
	credentials: string;

	@GandalfValidator({ notNull: { message: 'First name is required' } })
	@GandalfValidator({ sizeString: { message: 'First name must be between 1 and 50 characters', minLength: 1, maxLength: 50 } })
	@GandalfProperty({ isRequired: true })
	firstName: string;

	@GandalfValidator({ notNull: { message: 'Last name is required' } })
	@GandalfValidator({ sizeString: { message: 'Last name must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ isRequired: true })
	lastName: string;

	@GandalfValidator({ notNull: { message: 'Location is required' } })
	@GandalfProperty({ isRequired: true })
	locationId: number;

	@GandalfValidator({ sizeString: { message: 'Middle name cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	middleName: string;

	@GandalfValidator({ sizeString: { message: 'Nickname cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	nickname: string;

	@GandalfValidator({ sizeString: { message: 'Pronunciation cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	pronunciation: string;

	@GandalfValidator({ notNull: { message: 'Provider is required' } })
	@GandalfProperty({ isRequired: true })
	providerId: number;

	@GandalfValidator({ sizeString: { message: 'Suffix cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfProperty()
	suffix: string;

	@GandalfProperty()
	titleId: number;

}
