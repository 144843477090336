// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { Hl7ValueSetResponse } from './hl7-value-set-response';

// @ts-ignore
import { LoincCodeResponse } from './loinc-code-response';

/** See com.rev360.pms.api.controller.laborder.LabOrderResponse */
/* istanbul ignore next */
@GandalfModel
export class LabOrderResponse extends GandalfModelBase {

	@GandalfArray(Hl7ValueSetResponse)
	abnormalFlags: Hl7ValueSetResponse[];

	@GandalfProperty()
	description: string;

	@GandalfProperty()
	encounterId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate: Date;

	@GandalfProperty()
	frequencyId: number;

	@GandalfProperty()
	hl7upload: boolean;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	interpretationNotes: string;

	@GandalfProperty()
	interpretationOtherComments: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	interpretationStatus: constants.LabOrderInterpretationStatus;

	@GandalfConstantDecorator()
	@GandalfProperty()
	labType: constants.LabOrderLabType;

	@GandalfProperty()
	labVendorId: number;

	@GandalfProperty()
	nonCpoeEntry: boolean;

	@GandalfProperty()
	normalRange: string;

	@GandalfProperty()
	observationResultStatus: Hl7ValueSetResponse;

	@GandalfProperty({ propertyType: 'LocalDate' })
	observationStartedOn: Date;

	@GandalfProperty()
	order: LoincCodeResponse;

	@GandalfProperty()
	orderComment: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	orderDate: Date;

	@GandalfProperty()
	orderInstructions: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	orderStatus: constants.LabOrderStatus;

	@GandalfConstantDecorator()
	@GandalfProperty()
	orderType: constants.LabOrderType;

	@GandalfProperty()
	orderedByProviderId: number;

	@GandalfProperty()
	parentId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	reportDate: Date;

	@GandalfProperty()
	resultComments: string;

	@GandalfProperty()
	resultLabNote: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	resultStatus: constants.LabOrderResultStatus;

	@GandalfConstantDecorator()
	@GandalfProperty()
	resultType: constants.LabOrderResultType;

	@GandalfProperty()
	resultUnitId: number;

	@GandalfProperty()
	resultValue: string;

	@GandalfProperty()
	specimenAppropriateness: Hl7ValueSetResponse;

	@GandalfProperty({ propertyType: 'LocalDate' })
	specimenCollectionEndedOn: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	specimenCollectionStartedOn: Date;

	@GandalfArray(Hl7ValueSetResponse)
	specimenConditions: Hl7ValueSetResponse[];

	@GandalfProperty()
	specimenDisposition: string;

	@GandalfProperty()
	specimenQuality: Hl7ValueSetResponse;

	@GandalfArray(Hl7ValueSetResponse)
	specimenRejectReasons: Hl7ValueSetResponse[];

	@GandalfProperty()
	specimenSourceId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate: Date;

	@GandalfProperty()
	testName: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	testPerformedDate: Date;

	@GandalfProperty()
	testTypeId: number;

	@GandalfProperty()
	version: number;

}
