import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { _isNil } from '@core/lodash/lodash';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { UrlService } from '@core/url-util/url.service';
import { CreatePatientFileRequest } from '@gandalf/model/create-patient-file-request';
import { UpdatePatientFileRequest } from '@gandalf/model/update-patient-file-request';
import { URL_PATIENT_FILE_ENDPOINTS } from '@shared/constants/url.constants';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { PinturaEditorComponent } from '@pqina/angular-pintura';
import { GandalfFormBuilder } from 'gandalf';
import { getEditorDefaults, PinturaEditorOptions } from '@pqina/pintura';
import { PatientFileEntityType } from '@gandalf/constants';
import { PatientFileListResponse } from '@gandalf/model/patient-file-list-response';

interface DrawingTemplate {
	label: string;
	filePath: string;
}

export const DrawingTemplates: DrawingTemplate[] = [
	{ label: 'Anterior Segment OD', filePath: 'assets/drawingTool/ant-seg-od-small.jpg' },
	{ label: 'Anterior Segment OS', filePath: 'assets/drawingTool/ant-seg-os-small.jpg' },
	{ label: 'Posterior Pole OD', filePath: 'assets/drawingTool/post-pole-od-small.jpg' },
	{ label: 'Posterior Pole OS', filePath: 'assets/drawingTool/post-pole-os-small.jpg' },
	{ label: 'Retina OD', filePath: 'assets/drawingTool/wide-angle-retina-od-small.jpg' },
	{ label: 'Retina OS', filePath: 'assets/drawingTool/wide-angle-retina-os-small.jpg' },
	{ label: 'Cornea', filePath: 'assets/drawingTool/cornea-small.jpg' },
	{ label: 'Optic Disk', filePath: 'assets/drawingTool/optic-disc-small.jpg' },
	{ label: 'Full Face', filePath: 'assets/drawingTool/full-face-small.jpg' },
];

@Component({
	selector: 'pms-drawing-tool-modal',
	templateUrl: './drawing-tool-modal.component.html',
	styles: [
		'.thumbnail > img { width: 60px; }',
	],
})
export class DrawingToolModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('doka')
	doka: PinturaEditorComponent<any>;

	@ViewChild('drawingForm')
	drawingForm: NgForm;

	folderId: string;
	patientId: number;
	fileData: PatientFileListResponse;
	parentId: number;
	entityType: PatientFileEntityType;
	encounterId: number;

	componentForm: UntypedFormGroup;
	drawingRequest: CreatePatientFileRequest | UpdatePatientFileRequest;

	drawingTemplates = DrawingTemplates;
	currentDrawingTemplate: DrawingTemplate = null;

	// default image source 300x300 blank with white background
	// eslint-disable-next-line max-len
	src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAW0AAAEsCAQAAACiDCpgAAACTUlEQVR42u3SQREAAAgDINc/9Kzg24MMpB14KGqjNqgNaoPaoDZqq43aoDaoDWqD2qitNmqD2qA2qA1qo7baqA1qg9qgNqiN2mqjNqgNaoPaoDZqq43aoDaoDWqD2qitNmqD2qA2qA1qo7baqA1qg9qgNqiN2mqjNqgNaoPaoDZqq43aoDaoDWqD2qitNmqD2qA2qA1qo7baqA1qg9qgNqiN2mqjNqgNaoPaoDZqq43aoDaoDWqD2qitNmqD2qA2qA1qo7baqA1qg9qgNqiN2mqjNqgNaoPaoDZqq43aoDaoDWqD2qitNmqD2qA2qA1qo7baqA1qg9qgNqiN2mqjNqgNaoPaoDZqq43aoDaoDWqD2qitNmqD2qA2qA1qo7baqA1qg9qgNqiN2mqjNqgNaoPaoDZqq43aoDaoDWqD2qitNmqD2qA2qA1qo7baqA1qg9qgNqiN2mqjNqgNaoPaoDZqq43aoDaoDWqD2qitNmqD2qA2qA1qo7baqA1qg9qgNqiN2mqjNqgNaoPaoDZqq43aoDaoDWqD2qitNmqD2qA2qA1qo7baqA1qg9qgNqiN2mqjNqgNaoPaoDZqq43aoDaoDWqD2qitNmqD2qA2qA1qo7baqA1qg9qgNqiN2mqjNqgNaoPaoDZqq43aoDaoDWqD2qitNmqD2qA2qA1qo7baqA1qg9qgNqiN2mqjNqgNaoPaoDZqq43aoDaoDWqD2qitNmqD2qA2qA1qo7baqA1qg9qgNqiN2mqjNqgNaoPaoDZqq43aoDaoDWrD3QL581bzg2JcTAAAAABJRU5ErkJggg==';

	// image output
	result: any = null;

	// doka configuration options
	options: PinturaEditorOptions = {
		...getEditorDefaults(),
		enableButtonClose: false,
		enableButtonExport: false,
		enableButtonRevert: false,
		utils: ['crop', 'filter', 'finetune', 'annotate', 'resize'],
		cropEnableButtonFlipVertical: true,
		cropSelectPresetOptions: [
			[undefined, 'Free'],
			[3 / 4, 'Portrait'],
			[1, 'Square'],
			[4 / 3, 'Landscape'],
		],
	} as PinturaEditorOptions;

	constructor(
		public dynamicModalRef: DynamicModalRef,
		public modalConfig: ModalConfig,
		private urlService: UrlService,
		private gandalfFormBuilder: GandalfFormBuilder,
		private httpClient: HttpClient,
	) {
	}

	ngOnInit() {
		this.parseConfigData(this.modalConfig.data);
		this.loadExistingDrawing();
		this.buildForm();
	}

	parseConfigData(data: any) {
		this.folderId = data.folderId;
		this.patientId = data.patientId;
		this.fileData = data.fileData;
		this.parentId = data.parentId;
		this.entityType = data.entityType;
		this.encounterId = data.encounterId;
	}

	loadExistingDrawing() {
		if (this.isExistingDrawing()) {
			this.src = this.urlService.getPatientFileUrl(this.patientId, this.fileData.patientFileId);
		}
	}

	buildForm() {
		this.drawingRequest = this.createRequest();
		this.componentForm = this.gandalfFormBuilder.group(this.drawingRequest);
	}

	isExistingDrawing() {
		return !_isNil(this.fileData);
	}

	createRequest(): CreatePatientFileRequest | UpdatePatientFileRequest {
		let request;
		if (this.isExistingDrawing()) {
			request = new UpdatePatientFileRequest();
			request.patientFileId = this.fileData.patientFileId;
			request.description = this.fileData.fileDescription || '';
			request.name = this.fileData.fileName;
		} else {
			request = new CreatePatientFileRequest();
			request.description = '';
			request.folderId = this.folderId;
		}

		request.patientId = this.patientId;
		return request;
	}

	submitForm(event) {
		this.drawingForm.onSubmit(event);
	}

	async saveDrawing() {
		if (!this.componentForm.valid) {
			return;
		}
		const output = await this.doka.editor.processImage();

		const params = {
			folderId: this.folderId,
			patientId: this.patientId,
			rawImage: 'true',
			format: 'jpg',
			fileName: this.componentForm.get('name').value,
			fileDesc: this.componentForm.get('description').value,
		};
		this.appendEncounterTestDrawingParams(params);
		this.appendUpdateDrawingParams(params);
		this.urlService.standardUrlPost(params);

		this.httpClient.post(
			URL_PATIENT_FILE_ENDPOINTS.UPLOAD,
			output.dest.slice(),
			{
				headers: { 'Content-Type': output.dest.type },
				params,
			},
		).subscribe(() => {
			this.closeModal(true);
		});
	}

	appendEncounterTestDrawingParams(params: any) {
		if (!_isNil(this.encounterId)) {
			params.entityId = this.entityType.value;
			params.parentId = this.parentId;
			params.encounterId = this.encounterId;
		}
	}

	appendUpdateDrawingParams(params: any) {
		if (this.isExistingDrawing()) {
			params.fileId = this.fileData.patientFileId;
		}
	}

	applyDrawing(drawingTemplate: DrawingTemplate) {
		this.currentDrawingTemplate = drawingTemplate;
		this.src = drawingTemplate.filePath;

		if (!this.isExistingDrawing()) {
			this.componentForm.get('name').setValue(`${drawingTemplate.label}.jpg`);
		}
	}

	isCurrentDrawing(drawingTemplate: DrawingTemplate): boolean {
		return drawingTemplate === this.currentDrawingTemplate;
	}

	closeModal(refresh = false) {
		this.dynamicModalRef.close(this.modal, refresh);
	}
}
