<div class="media">
	<div class="media-body">
		<h4 class="media-heading display-inline-block vertical-align-top margin-top-0 margin-bottom-0 margin-right-lg"
			[attr.data-test-id]="'invoiceHeaderLabel'">{{ invoice?.payerType?.label }} Invoice #{{ invoice?.id }}
			<pms-invoice-status
				[invoice]="invoice"
				[isReadOnly]="isReadOnly"
				[isPaymentProcessing]="isPaymentProcessing">
			</pms-invoice-status>
		</h4>
		<ul class="list-inline display-inline-block vertical-align-top margin-bottom-0">
			<li>
				<i class="fa fa-user text-primary margin-right-xs"></i>
				@if (!isGuestInvoice()) {
					@if (hasPermissionToGoToPatient()) {
						<a (click)="navigateToPatient()" [attr.data-test-id]="'invoiceHeaderPatientNameLink'">{{ patientName | patientName }}</a>
					}
					@if (!hasPermissionToGoToPatient()) {
						<span>{{ patientName | patientName }}</span>
					}
					@if (firstSortedActiveAlert) {
						<a class="margin-left-sm"><i class="fa fa-flag text-{{firstSortedActiveAlert?.severity?.label | severityClass}}" revTooltip
													 tooltipContent="Alerts" (click)="displayStatusAlertsModal()"
													 [attr.data-test-id]="'invoiceDetailsHeaderAlertIcon'"></i></a>
					}
				}
				@if (isGuestInvoice()) {
					{{ invoice.payerName }}
				}
			</li>
			<li>
				<i class="fa fa-building text-primary margin-right-xs"></i>{{ invoice?.locationName }}
				@if (canChangeLocation()) {
					<a (click)="editLocation()" class="margin-left-xs" revTooltip tooltipContent="Edit" [attr.data-test-id]="'invoiceLocationButton'"><i
						class="fa fa-pencil"></i></a>
				}
			<li>
				<i class="fa fa-user-md text-primary margin-right-xs"></i>{{ invoice?.providerName ? invoice.providerName : "None" }}
				@if (canChangeProvider()) {
					<a (click)="editProvider()" class="margin-left-xs" revTooltip tooltipContent="Edit" [attr.data-test-id]="'invoiceProviderEditButton'"><i
						class="fa fa-pencil"></i></a>
				}
				@if (canRemoveProvider()) {
					<a class="margin-left-xs" (click)="checkRemoveProvider()" revTooltip tooltipContent="Remove"
					   [attr.data-test-id]="'invoiceProviderRemoveButton'"><i class="fa fa-close"></i></a>
				}
			</li>
			<li>
				<pms-invoice-date [invoice]="invoice" [isReadOnly]="isReadOnly" [isPaymentProcessing]="isPaymentProcessing"></pms-invoice-date>
			</li>
		</ul>
	</div>
</div>
