// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ProviderResponse } from './provider-response';

/** See com.rev360.pms.api.controller.prescription.MedicationPrescriptionListResponse */
/* istanbul ignore next */
@GandalfModel
export class MedicationPrescriptionListResponse extends GandalfModelBase {

	@GandalfConstantDecorator()
	@GandalfProperty()
	authorizationType: constants.PrescriptionAuthorizationType;

	@GandalfProperty()
	authorizedBy: ProviderResponse;

	@GandalfProperty()
	authorizingProviderFirstName: string;

	@GandalfProperty()
	authorizingProviderLastName: string;

	@GandalfProperty()
	description: string;

	@GandalfProperty()
	drugCode: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	expirationDate: Date;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	isRxNormDrug: boolean;

	@GandalfProperty({ propertyType: 'LocalDate' })
	modifiedDate: Date;

	@GandalfProperty()
	primaryProviderFirstName: string;

	@GandalfProperty()
	primaryProviderLastName: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.PrescriptionStatus;

	@GandalfConstantDecorator()
	@GandalfProperty()
	type: constants.MedicationPrescriptionType;

	@GandalfProperty()
	useProviderSignature: boolean;

}
