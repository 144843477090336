import { ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EmployeeWithRolesDropdownResponse } from '@core/employee/employee.service';
import { _cloneDeep, _filter, _isNil, _map, _some } from '@core/lodash/lodash';
import { SecurityRoleDropdownResponse } from '@core/security/security-role.service';

@Component({
	selector: 'pms-employee-list-with-group-filter',
	templateUrl: './employee-list-with-group-filter.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => EmployeeListWithGroupFilterComponent),
			multi: true,
		},
	],
})
export class EmployeeListWithGroupFilterComponent implements ControlValueAccessor {

	@Input()
	set employees(value: EmployeeWithRolesDropdownResponse[]) {
		this._employees = _cloneDeep(value);
		this.handleGroupSelectionChanged({});
	}

	private _employees: EmployeeWithRolesDropdownResponse[];

	displayedEmployees: EmployeeWithRolesDropdownResponse[];

	@Input()
	groups: SecurityRoleDropdownResponse[];

	value: number[];

	constructor(
		public changeDetectorRef: ChangeDetectorRef,
	) {
	}

	onModelChange: (_: any) => void = () => {
	};
	onModelTouched: () => void = () => {
	};

	handleGroupSelectionChanged(event: any) {
		if (_isNil(event.value)) {
			this.displayedEmployees = this._employees;
		} else {
			this.displayedEmployees = _filter(this._employees,
				(employee: EmployeeWithRolesDropdownResponse) => _some(employee.securityRoles, role => role.id === event.value),
			);
			this.value = _map(this.displayedEmployees, employee => employee.personId);
			this.onModelChange(this.value);
		}
	}

	registerOnChange(fn: (_: any) => void) {
		this.onModelChange = fn;
	}

	registerOnTouched(fn: () => void) {
		this.onModelTouched = fn;
	}

	writeValue(value: number[]) {
		this.value = value;
		this.changeDetectorRef.markForCheck();
	}

	valueChanged() {
		this.onModelChange(this.value);
	}
}
