// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.UpdatePatientFileRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdatePatientFileRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Description cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	description: string;

	@GandalfValidator({ notNull: { message: 'File Name is required' } })
	@GandalfValidator({ sizeString: { message: 'File Name must be between 1 and 200 characters', minLength: 1, maxLength: 200 } })
	@GandalfProperty({ isRequired: true })
	name: string;

	@GandalfValidator({ notNull: { message: 'Patient File is required' } })
	@GandalfProperty({ isRequired: true })
	patientFileId: number;

}
