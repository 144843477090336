@if (showUrlHyperlink) {
	<div class="col-sm-8">
		<p class="form-control-static">
			<rev-external-link [externalLink]="urlText" [dataTestId]="getDataTestId('UrlHyperlink')"></rev-external-link>
			<a (click)="enableUrlEdit()" class="margin-left-xs" revTooltip tooltipContent="Edit" [attr.data-test-id]="getDataTestId('UrlEditButton')"><i
				class="fa fa-pencil"></i></a>
		</p>
	</div>
}
@if (!showUrlHyperlink) {
	<div class="col-sm-8">
		<input #urlInput type="text" class="e-input" [(ngModel)]="urlText" (change)="onInputValueChange()">
	</div>
}
