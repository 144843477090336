import { FEATURE_FLAGS } from '@core/feature/feature.constants';

export interface AdminModuleMap {
	NAME: string;
	FEATURE_FLAG?: string;
	FULL_URL: string;
}

const BASE_ADMIN_URL = '/admin';

export const ADMIN_MODULE_MAPPINGS: AdminModuleMap[] = [
	{
		NAME: 'BasicInformation',
		FULL_URL: `${BASE_ADMIN_URL}/general/basic-information`,
	},
	{
		NAME: 'Locations',
		FULL_URL: `${BASE_ADMIN_URL}/general/locations`,
	},
	{
		NAME: 'Agreements',
		FULL_URL: `${BASE_ADMIN_URL}/general/agreements`,
	},
	{
		NAME: 'Contacts',
		FULL_URL: `${BASE_ADMIN_URL}/general/contacts`,
	},
	{
		NAME: 'PracticePreferences',
		FULL_URL: `${BASE_ADMIN_URL}/general/practice-preferences`,
	},
	{
		NAME: 'Security',
		FULL_URL: `${BASE_ADMIN_URL}/general/security`,
	},
	{
		NAME: 'AutoPostingAdjustmentCodeAssocations',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/accounting/autoposting-adjustment-code-associations`,
	},
	{
		NAME: 'BalanceTransferReasons',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/accounting/balance-transfer-reasons`,
	},
	{
		NAME: 'BalanceWriteoffReasons',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/accounting/balance-writeoff-reasons`,
	},
	{
		NAME: 'DiscountReasons',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/accounting/discount-reasons`,
	},
	{
		NAME: 'StatementDueDateReasons',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/accounting/statement-due-date-reasons`,
	},
	{
		NAME: 'StatementMessageReasons',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/accounting/statement-message-reasons`,
	},
	{
		NAME: 'AllergicReactions',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/allergies/allergy-reactions`,
	},
	{
		NAME: 'AllergyCategories',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/allergies/allergy-categories`,
	},
	{
		NAME: 'CarePlanTemplates',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/care-plans/care-plan-templates`,
	},
	{
		NAME: 'Education',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/care-plans/care-plan-item-library/education-care-plan-item`,
	},
	{
		NAME: 'CarePlansGeneral',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/care-plans/care-plan-item-library/general-care-plan-item`,
	},
	{
		NAME: 'Goal',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/care-plans/care-plan-item-library/goal-care-plan-item`,
	},
	{
		NAME: 'MedRX',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/care-plans/care-plan-item-library/medrx-care-plan-item`,
	},
	{
		NAME: 'OrdersMedical',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/care-plans/care-plan-item-library/orders-medical-care-plan-item`,
	},
	{
		NAME: 'CarePlansRecall',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/care-plans/care-plan-item-library/recall-care-plan-item`,
	},
	{
		NAME: 'CommonICD10Diagnoses',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/diagnosis/common-icd-ten-diagnoses`,
	},
	{
		NAME: 'CommonICD9Diagnoses',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/diagnosis/common-icd-nine-diagnoses`,
	},
	{
		NAME: 'CommonSNOMEDDiagnoses',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/diagnosis/common-snomed-diagnoses`,
	},
	{
		NAME: 'FamilyHistoryFiltersMedical',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/family-history-filters/medical`,
	},
	{
		NAME: 'Ocular',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/family-history-filters/ocular`,
	},
	{
		NAME: 'Priorities',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/insurance-information/priorities`,
	},
	{
		NAME: 'Types',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/insurance-information/types`,
	},
	{
		NAME: 'AdjustQuantityReasons',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/inventory-information/adjust-quantity-reasons`,
	},
	{
		NAME: 'ReconciliationReasons',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/inventory-information/reconciliation-reasons`,
	},
	{
		NAME: 'CommonMedications',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/medications/common-medications`,
	},
	{
		NAME: 'GeneralMedications',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/medications/general-medications`,
	},
	{
		NAME: 'CommonModifiers',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/modifiers/common-modifiers`,
	},
	{
		NAME: 'AppointmentNotes',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/note-categories/appointment-notes`,
	},
	{
		NAME: 'DiagnosisNotes',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/note-categories/diagnosis-notes`,
	},
	{
		NAME: 'EmployeeNotes',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/note-categories/employee-notes`,
	},
	{
		NAME: 'EncounterNotes',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/note-categories/encounter-notes`,
	},
	{
		NAME: 'EncounterTestNotes',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/note-categories/encounter-test-notes`,
	},
	{
		NAME: 'InsuranceNotes',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/note-categories/insurance-notes`,
	},
	{
		NAME: 'PatientNotes',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/note-categories/patient-notes`,
	},
	{
		NAME: 'PharmacyNotes',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/note-categories/pharmacy-notes`,
	},
	{
		NAME: 'PracticeNotes',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/note-categories/practice-notes`,
	},
	{
		NAME: 'ProviderNotes',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/note-categories/provider-notes`,
	},
	{
		NAME: 'TaskNotes',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/note-categories/task-notes`,
	},
	{
		NAME: 'LabResultUnits',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/orders-medical/lab-result-units`,
	},
	{
		NAME: 'SpecimenSources',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/orders-medical/specimen-sources`,
	},
	{
		NAME: 'TestTypes',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/orders-medical/test-types`,
	},
	{
		NAME: 'RemakeReasons',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/orders-optical/remake-reasons`,
	},
	{
		NAME: 'ReturnReasons',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/orders-optical/return-reasons`,
	},
	{
		NAME: 'Citizenship',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/patient-information/citizenship`,
	},
	{
		NAME: 'ContactRoles',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/patient-information/contact-roles`,
	},
	{
		NAME: 'DominantEye',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/patient-information/dominant-eye`,

	},
	{
		NAME: 'DominantHand',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/patient-information/dominant-hand`,
	},
	{
		NAME: 'Ethnicity',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/patient-information/ethnicity`,
	},
	{
		NAME: 'FamilyMemberRole',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/patient-information/family-member-role`,
	},
	{
		NAME: 'MaritalStatus',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/patient-information/marital-status`,
	},
	{
		NAME: 'PrefLanguage',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/patient-information/preferred-language`,
	},
	{
		NAME: 'Race',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/patient-information/race`,
	},
	{
		NAME: 'Religion',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/patient-information/religion`,
	},
	{
		NAME: 'Salutation',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/patient-information/salutation`,
	},
	{
		NAME: 'SearchCategories',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/patient-information/search-categories`,
	},
	{
		NAME: 'StatesProvinces',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/patient-information/states-provinces`,
	},
	{
		NAME: 'CLAddDesignation',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/prescriptions/cl-add-designation`,
	},
	{
		NAME: 'CLTrialOrderInstructions',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/prescriptions/cl-trial-order-instructions`,
	},
	{
		NAME: 'RGPMaterial',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/prescriptions/rgp-material`,
	},
	{
		NAME: 'SpectaclesUsedFor',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/prescriptions/spectacles-used-for`,

	},
	{
		NAME: 'StopCancelReasons',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/prescriptions/stop-cancel-reasons`,
	},
	{
		NAME: 'CLElectronicDeliveryMethods',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/prescriptions/cl-electronic-delivery-methods`,
	},
	{
		NAME: 'ProviderRoles',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/provider-information/provider-roles`,
	},
	{
		NAME: 'ProviderTypes',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/provider-information/provider-types`,
	},
	{
		NAME: 'ClinicalDecisionSupport',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/query-rule-categories/clinical-decision-support`,
	},
	{
		NAME: 'PatientEducationRules',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/query-rule-categories/patient-education-rules`,
	},
	{
		NAME: 'PatientQueries',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/query-rule-categories/patient-queries`,
	},
	{
		NAME: 'ReferralRewardsPrograms',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/referrals/referral-rewards-program`,
	},
	{
		NAME: 'ReferralSources',
		FULL_URL: `${BASE_ADMIN_URL}/data-configuration/referrals/referral-sources`,
	},
	{
		NAME: 'TaskCategories',
		FULL_URL: `${BASE_ADMIN_URL}/tasks/task-categories`,
	},
	{
		NAME: 'EncounterStepLibrary',
		FULL_URL: `${BASE_ADMIN_URL}/encounters-interviews/encounter-step-library`,
	},
	{
		NAME: 'EncounterTemplates',
		FULL_URL: `${BASE_ADMIN_URL}/encounters-interviews/encounter-templates`,
	},
	{
		NAME: 'ExamTestLibrary',
		FULL_URL: `${BASE_ADMIN_URL}/encounters-interviews/exam-test-library`,
	},
	{
		NAME: 'InterviewTemplates',
		FULL_URL: `${BASE_ADMIN_URL}/encounters-interviews/interview-templates`,
	},
	{
		NAME: 'ScreenLibrary',
		FULL_URL: `${BASE_ADMIN_URL}/encounters-interviews/screen-library`,
	},
	{
		NAME: 'ConsentForms',
		FULL_URL: `${BASE_ADMIN_URL}/documents-and-forms/consent-forms`,
	},
	{
		NAME: 'OfficePolicies',
		FULL_URL: `${BASE_ADMIN_URL}/documents-and-forms/office-policies`,
	},
	{
		NAME: 'OtherDocuments',
		FULL_URL: `${BASE_ADMIN_URL}/documents-and-forms/other-documents`,
	},
	{
		NAME: 'PatientEducation',
		FULL_URL: `${BASE_ADMIN_URL}/documents-and-forms/patient-education`,
	},
	{
		NAME: 'EncounterSummaries',
		FULL_URL: `${BASE_ADMIN_URL}/communication-templates/encounter-summaries`,
	},
	{
		NAME: 'PatientLetters',
		FULL_URL: `${BASE_ADMIN_URL}/communication-templates/patient-letters`,
	},
	{
		NAME: 'AutoDiagnosing',
		FULL_URL: `${BASE_ADMIN_URL}/system-rules/auto-diagnosing`,
	},
	{
		NAME: 'ClinicalDecisionSupport',
		FULL_URL: `${BASE_ADMIN_URL}/system-rules/clinical-decision-support`,
	},
	{
		NAME: 'PatientEducationRules',
		FULL_URL: `${BASE_ADMIN_URL}/system-rules/patient-education-rules`,
	},
	{
		NAME: 'FeeSchedules',
		FULL_URL: `${BASE_ADMIN_URL}/accounting/fee-schedules`,
	},
	{
		NAME: 'FinanceChargePlans',
		FULL_URL: `${BASE_ADMIN_URL}/accounting/finance-charge-plans`,
	},
	{
		NAME: 'TransferTaxes',
		FULL_URL: `${BASE_ADMIN_URL}/accounting/transfer-taxes`,
	},
	{
		NAME: 'VSPFeeSchedules',
		FEATURE_FLAG: FEATURE_FLAGS.FEATURES.VSP_ESTIMATOR,
		FULL_URL: `${BASE_ADMIN_URL}/accounting/vsp-fee-schedules`,
	},
	{
		NAME: 'ReportsCategories',
		FEATURE_FLAG: FEATURE_FLAGS.FEATURES.ADMIN.ACCOUNTING_CATEGORIES.REPORTS_CATEGORIES,
		FULL_URL: `${BASE_ADMIN_URL}/accounting-categories/reports-categories`,
	},
	{
		NAME: 'EmployeeLimitedView',
		FULL_URL: `${BASE_ADMIN_URL}/enterprise-admin/employee-limited-access`,
	},
	{
		NAME: 'Employees',
		FULL_URL: `${BASE_ADMIN_URL}/employee-roles/employees`,
	},
	{
		NAME: 'EncounterRoles',
		FULL_URL: `${BASE_ADMIN_URL}/employee-roles/encounter-roles`,
	},
	{
		NAME: 'UserRoles',
		FULL_URL: `${BASE_ADMIN_URL}/employee-roles/user-roles`,
	},
	{
		NAME: 'Merge',
		FULL_URL: `${BASE_ADMIN_URL}/patient-management/merge`,
	},
	{
		NAME: 'Search',
		FULL_URL: `${BASE_ADMIN_URL}/patient-management/search`,
	},
	{
		NAME: 'CcdaExport',
		FULL_URL: `${BASE_ADMIN_URL}/patient-management/ccda-export`,
	},
	{
		NAME: 'ImportQrda',
		FULL_URL: `${BASE_ADMIN_URL}/patient-management/import-qrda`,
	},
	{
		NAME: 'ServiceCategories',
		FEATURE_FLAG: FEATURE_FLAGS.FEATURES.ADMIN.SERVICE_CATEGORIES.SERVICE_CATEGORIES,
		FULL_URL: `${BASE_ADMIN_URL}/service-categories/service-category-items`,
	},
	{
		NAME: 'ServiceAgreement',
		FULL_URL: `${BASE_ADMIN_URL}/twelve-eighty-four/service-agreement`,
	},
	{
		NAME: 'Subscriptions',
		FULL_URL: `${BASE_ADMIN_URL}/twelve-eighty-four/subscriptions`,
	},
	{
		NAME: 'AlternateServiceLocations',
		FULL_URL: `${BASE_ADMIN_URL}/vendors-partners/alternate-service-locations`,
	},
	{
		NAME: 'Apex',
		FULL_URL: `${BASE_ADMIN_URL}/vendors-partners/apex`,
	},
	{
		NAME: 'Bluefin',
		FULL_URL: `${BASE_ADMIN_URL}/vendors-partners/bluefin`,
	},
	{
		NAME: 'CollectionAgencies',
		FULL_URL: `${BASE_ADMIN_URL}/vendors-partners/collection-agencies`,
	},
	{
		NAME: 'ContactLensSuppliers',
		FULL_URL: `${BASE_ADMIN_URL}/vendors-partners/contact-lens-suppliers`,
	},
	{
		NAME: 'ExternalProviders',
		FULL_URL: `${BASE_ADMIN_URL}/vendors-partners/external-providers`,
	},
	{
		NAME: 'FrameSuppliers',
		FULL_URL: `${BASE_ADMIN_URL}/vendors-partners/frame-suppliers`,
	},
	{
		NAME: 'InsuranceCompanies',
		FULL_URL: `${BASE_ADMIN_URL}/vendors-partners/insurance-companies`,
	},
	{
		NAME: 'LabsImagingLabs',
		FULL_URL: `${BASE_ADMIN_URL}/vendors-partners/labs-imaging-labs`,
	},
	{
		NAME: 'Manufacturer',
		FULL_URL: `${BASE_ADMIN_URL}/vendors-partners/manufacturer`,
	},
	{
		NAME: 'Pharmacies',
		FULL_URL: `${BASE_ADMIN_URL}/vendors-partners/pharmacies`,
	},
	{
		NAME: 'SalesRepresentative',
		FULL_URL: `${BASE_ADMIN_URL}/vendors-partners/sales-representative`,
	},
	{
		NAME: 'SpectacleLabs',
		FULL_URL: `${BASE_ADMIN_URL}/vendors-partners/spectacle-labs`,
	},
	{
		NAME: 'ThirdPartyAdministrator',
		FULL_URL: `${BASE_ADMIN_URL}/vendors-partners/third-party-administrator`,
	},
	{
		NAME: 'Trizetto',
		FULL_URL: `${BASE_ADMIN_URL}/vendors-partners/trizetto`,
	},
	{
		NAME: 'VSPLabs',
		FEATURE_FLAG: FEATURE_FLAGS.FEATURES.VSP_ESTIMATOR,
		FULL_URL: `${BASE_ADMIN_URL}/vendors-partners/vsp-labs`,
	},
	{
		NAME: 'ClinicAid',
		FULL_URL: `${BASE_ADMIN_URL}/vendors-partners/clinicaid`,
	},
	{
		NAME: 'RevClear',
		FULL_URL: `${BASE_ADMIN_URL}/solutions/rev-clear`,
	},
	{
		NAME: 'RevDirect',
		FULL_URL: `${BASE_ADMIN_URL}/solutions/rev-direct`,
	},
	{
		NAME: 'Global Payments',
		FEATURE_FLAG: FEATURE_FLAGS.FEATURES.ADMIN.VENDORS_PARTNERS.OPENEDGE,
		FULL_URL: `${BASE_ADMIN_URL}/solutions/openedge`,
	},
	{
		NAME: 'Product Partners',
		FULL_URL: `${BASE_ADMIN_URL}/solutions/product-partners`,
	},

];
