import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { VendorsAndPartnersService } from '../../vendors-and-partners.service';

@Component({
	selector: 'pms-location-config-confirm-delete',
	templateUrl: './location-config-confirm-delete.component.html',
})
export class LocationConfigConfirmDeleteComponent {

	@ViewChild('locationConfigDeleteModal')
	modal: DialogComponent;

	constructor(
		public dynamicModalRef: DynamicModalRef,
		public viewContainer: ViewContainerRef,
		public modalConfig: ModalConfig,
		public vendorService: VendorsAndPartnersService,
	) {
	}

	closeModal(refreshRequested: boolean) {
		this.dynamicModalRef.close(this.modal, refreshRequested);
	}

	deleteLocationConfig() {
		this.vendorService.deleteVendorLocationConfig(this.modalConfig.data.locationConfigId).subscribe(() => {
			this.closeModal(true);
		});
	}
}
