// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ServiceLineResponse } from './service-line-response';

/** See com.rev360.pms.api.controller.accounting.payment.PaymentInfoResponse */
/* istanbul ignore next */
@GandalfModel
export class PaymentInfoResponse extends GandalfModelBase {

	@GandalfProperty()
	claimId: string;

	@GandalfProperty()
	claimStatus: string;

	@GandalfProperty()
	insuredFirstName: string;

	@GandalfProperty()
	insuredIdentificationNumber: string;

	@GandalfProperty()
	insuredLastName: string;

	@GandalfProperty()
	insuredMiddleName: string;

	@GandalfProperty()
	insuredSuffix: string;

	@GandalfProperty()
	memberIdentificationNumber: string;

	@GandalfProperty()
	patientFirstName: string;

	@GandalfProperty()
	patientLastName: string;

	@GandalfProperty()
	patientMiddleName: string;

	@GandalfProperty({ propertyType: 'Money' })
	patientResponsibility: number;

	@GandalfProperty()
	patientSuffix: string;

	@GandalfProperty()
	payerClaimControlNumber: string;

	@GandalfProperty()
	renderingProviderFirstName: string;

	@GandalfProperty()
	renderingProviderLastName: string;

	@GandalfProperty()
	renderingProviderNPI: string;

	@GandalfArray(ServiceLineResponse)
	serviceLines: ServiceLineResponse[];

}
