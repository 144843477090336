// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreateRecurPatternDailyRequest } from './create-recur-pattern-daily-request';

// @ts-ignore
import { CreateRecurPatternMonthlyRequest } from './create-recur-pattern-monthly-request';

// @ts-ignore
import { CreateRecurPatternWeeklyRequest } from './create-recur-pattern-weekly-request';

// @ts-ignore
import { CreateRecurPatternYearlyRequest } from './create-recur-pattern-yearly-request';

/** See com.rev360.pms.api.controller.schedule.scheduleitem.recurrence.CreateRecurringDefinitionRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateRecurringDefinitionRequest extends GandalfModelBase {

	@GandalfProperty()
	dailyRecurPattern: CreateRecurPatternDailyRequest;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate: Date;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'End Type is required' } })
	@GandalfProperty({ isRequired: true })
	endType: constants.RecurEndType;

	@GandalfProperty()
	monthlyRecurPattern: CreateRecurPatternMonthlyRequest;

	@GandalfValidator({ max: { max: 100, message: 'Number of occurrences cannot exceed 100' } })
	@GandalfValidator({ min: { min: 0, message: 'Number of occurrences must be positive' } })
	@GandalfProperty()
	numRecurOccurrences: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	recurPatternType: constants.RecurPatternType;

	@GandalfValidator({ notNull: { message: 'Recurrence Start Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	startDate: Date;

	@GandalfProperty()
	weeklyRecurPattern: CreateRecurPatternWeeklyRequest;

	@GandalfProperty()
	yearlyRecurPattern: CreateRecurPatternYearlyRequest;

}
