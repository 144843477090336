import { State as AppState } from '@app-store/reducers';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as patientConsentsReducer from '@patient-store/reducers/consents.reducer';

export interface State extends AppState {
	patient: PatientState;
}

export interface PatientState {
	consents: patientConsentsReducer.PatientConsentsStateMap;
}

export const reducers: ActionReducerMap<PatientState> = {
	consents: patientConsentsReducer.reducer,
};

export const selectPatientState = createFeatureSelector<PatientState>('patient');
