<ejs-dialog #locationConfigDeleteModal
			cssClass="modal-sm"
			(close)="closeModal(false)">
	<ng-template #header>
		<div class="dlg-template"  [attr.data-test-id]="'locationConfigDeleteModalTitle'">Delete Location Details</div>
	</ng-template>
	<ng-template #content>
		Are you sure you want to delete the location configuration?
	</ng-template>
	<ng-template #footerTemplate>
		<div>
			<button ejs-button iconCss="fa fa-times" (click)="closeModal(false)" [attr.data-test-id]="'locationConfigDeleteModalCloseButton'">Cancel</button>
			<button ejs-button
					isPrimary="true" cssClass="e-danger" iconCss="fa fa-trash" [attr.data-test-id]="'locationConfigDeleteModalDeleteButton'"
					(click)="deleteLocationConfig()">Delete</button>
		</div>
	</ng-template>
</ejs-dialog>
