import { Injectable } from '@angular/core';
import { PersonDetailsGandalfService } from '@gandalf/services';

@Injectable({
	providedIn: 'root',
})
export class PersonDetailsService {

	constructor(
		private personDetailsGandalfService: PersonDetailsGandalfService,
	) {
	}

	/* istanbul ignore next: gandalf */
	getPersonNameByPersonId(personId) {
		return this.personDetailsGandalfService.getPersonNameByPersonId(personId);
	}
}
