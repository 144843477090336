// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.inventory.LocationProductItemTableResponse */
/* istanbul ignore next */
@GandalfModel
export class LocationProductItemTableResponse extends GandalfModelBase {

	@GandalfProperty()
	a: number;

	@GandalfProperty()
	addDesignation: string;

	@GandalfProperty()
	addDesignationId: number;

	@GandalfProperty()
	addPower: number;

	@GandalfProperty()
	axis: number;

	@GandalfProperty()
	b: number;

	@GandalfProperty()
	baseCurve: number;

	@GandalfProperty()
	bridge: number;

	@GandalfProperty()
	circumference: number;

	@GandalfProperty()
	color: string;

	@GandalfProperty()
	colorCode: string;

	@GandalfProperty()
	cylinder: number;

	@GandalfProperty()
	dbl: number;

	@GandalfProperty()
	description: string;

	@GandalfProperty()
	diameter: number;

	@GandalfProperty()
	ed: number;

	@GandalfProperty()
	edAngle: number;

	@GandalfProperty()
	eye: number;

	@GandalfProperty()
	frameColorId: number;

	@GandalfProperty()
	frameSizeId: number;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	isTrial: boolean;

	@GandalfProperty()
	lensColorId: number;

	@GandalfProperty()
	lensType: string;

	@GandalfProperty()
	locationProductId: number;

	@GandalfProperty()
	material: string;

	@GandalfProperty()
	packaging: string;

	@GandalfProperty()
	productName: string;

	@GandalfProperty({ propertyType: 'Money' })
	retail: number;

	@GandalfProperty()
	screwToScrew: number;

	@GandalfProperty()
	sku: string;

	@GandalfProperty()
	sphere: number;

	@GandalfProperty()
	stockQuantity: number;

	@GandalfProperty()
	stocked: boolean;

	@GandalfProperty()
	temple: number;

	@GandalfProperty()
	templeUnit: string;

	@GandalfProperty()
	upc: string;

}
