import { Directive, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FieldUtilsService } from '@core/field/field-utils.service';
import { _uniq } from '@core/lodash/lodash';
import { FieldDefinitionResponse } from '@gandalf/model/field-definition-response';
import { FieldOptionResponse } from '@gandalf/model/field-option-response';
import { MultiSelectComponent } from '@syncfusion/ej2-angular-dropdowns';

@Directive({
	/* eslint-disable-next-line @angular-eslint/directive-selector */
	selector: 'ejs-multiselect[multiSelectionFieldDefinition]',
	exportAs: 'MultiSelectionFieldDirective',
})
export class MultiSelectionFieldDirective implements OnInit {
	@Input() multiSelectionFieldDefinition: FieldDefinitionResponse;

	constructor(
		public component: MultiSelectComponent,
		public formControl: NgControl,
	) { }

	ngOnInit() {
		this.component.dataSource = this.multiSelectionFieldDefinition.values as any;
	}

	setValue(fieldOptions: FieldOptionResponse[]) {
		this.formControl.control.setValue(FieldUtilsService.fieldOptionsToIdArray(fieldOptions));
	}

	setNormalValue() {
		const currentValue = this.formControl.control.value ? this.formControl.control.value : [];
		const defaultNormals = FieldUtilsService.getFieldValueMultiSelectionNormals(this.multiSelectionFieldDefinition);

		this.formControl.control.setValue(_uniq(currentValue.concat(defaultNormals)));
	}
}
