import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { FormControlMeta, FormMeta } from '@app-store/reducers/forms.reducer';
import { FormUtilsService } from '@core/form-utils/form-utils.service';

export const ROOT_FORM_META_KEY = 'ROOT';

export class StatefulFormUtil {

	static getFormControlMeta(control: AbstractControl): FormControlMeta {
		const {dirty, touched} = control;
		return {dirty, touched};
	}

	/**
	 * Gets the FormMeta from the provided form.
	 * @param form Form to extract FormMeta from.
	 */
	static getFormMeta(form: UntypedFormGroup | UntypedFormArray): FormMeta {
		const formMeta: FormMeta = new Map<string, FormControlMeta>();

		// Recursive closure used to traverse the form and gather metadata.
		const processAbstractControl = (control: AbstractControl, path: string = ROOT_FORM_META_KEY) => {
			const getControlPath = (key: string | number) => `${path}.${key}`;

			if (FormUtilsService.isFormArray(control)) {
				// Process FormArray as an individual control
				formMeta.set(path, StatefulFormUtil.getFormControlMeta(control));
				// Process Form Array Groups/Controls
				control.controls.forEach((item, index) => {
					processAbstractControl(item, getControlPath(index));
				});

			} else if (FormUtilsService.isFormGroup(control)) {
				// Process FormGroup as an individual control
				formMeta.set(path, StatefulFormUtil.getFormControlMeta(control));
				// Process FormGroup Controls
				Object.keys(control.controls).map(controlKey => {
					processAbstractControl(control.controls[controlKey], getControlPath(controlKey));
				});

			} else {
				formMeta.set(path, StatefulFormUtil.getFormControlMeta(control));
			}
		};

		// Kick off the recursive processing of the form
		processAbstractControl(form);

		return formMeta;
	}

	/**
	 * Patches existing FormMeta into the provided form.
	 * @param form Form to patch meta into.
	 * @param meta Meta to patch into the provided form.
	 */
	static patchFormMeta(form: UntypedFormGroup | UntypedFormArray, meta: FormMeta) {
		// Process the form as an individual control
		StatefulFormUtil.patchFormControlMeta(form, meta.get(ROOT_FORM_META_KEY));

		Array.from(meta.entries())
			.filter(([key, _controlMeta]) => key !== ROOT_FORM_META_KEY)
			.forEach(([key, controlMeta]) => {
				key = key.replace(`${ROOT_FORM_META_KEY}.`, '');
				StatefulFormUtil.patchFormControlMeta(form.get(key), controlMeta);
			});
	}

	/**
	 * Patches form control meta into the provided control.
	 * @param control Control to patch meta into.
	 * @param meta Meta to patch into the provided form.
	 */
	static patchFormControlMeta(control: AbstractControl, meta: FormControlMeta) {
		if (meta.dirty) {
			control.markAsDirty({onlySelf: true});
		}

		if (meta.touched) {
			control.markAsTouched({onlySelf: true});
		}
	}
}
