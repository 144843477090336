import { Injectable } from '@angular/core';
import { SortingService } from 'morgana';
import { SecurityRoleResponse } from '@gandalf/model/security-role-response';
import { PracticeGandalfService } from '@gandalf/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface SecurityRoleDropdownResponse extends SecurityRoleResponse {
	label: string;
	value: any;
}

@Injectable({
	providedIn: 'root',
})
export class SecurityRoleService {

	constructor(
		private practiceGandalfService: PracticeGandalfService,
	) {
	}

	findSecurityRolesForDropdown(): Observable<SecurityRoleDropdownResponse[]> {
		return this.practiceGandalfService.findSecurityRoles().pipe(
			map(roles => SortingService.sortBy(roles, ['name', 'id'], ['asc', 'asc'])),
			map(roles => roles.map(role => this.formatSecurityRolesForDropdown(role))),
		);
	}

	/* istanbul ignore next: gandalf */
	findSecurityRoles() {
		return this.practiceGandalfService.findSecurityRoles();
	}

	formatSecurityRolesForDropdown(role: SecurityRoleResponse): SecurityRoleDropdownResponse {
		const formattedRole = role as SecurityRoleDropdownResponse;
		formattedRole.label = role.name;
		formattedRole.value = role.id;

		return formattedRole;
	}
}
