// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { EyeglassPrescriptionEyeRequest } from './eyeglass-prescription-eye-request';

/** See com.rev360.pms.api.controller.prescription.CreateEyeglassPrescriptionBaseRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateEyeglassPrescriptionBaseRequest extends GandalfModelBase {

	@GandalfLabel('AR Coating')
	@GandalfValidator({ notNull: { message: 'AR Coating is required' } })
	@GandalfProperty({ isRequired: true })
	arCoating: boolean;

	@GandalfLabel('AR Coating Comment')
	@GandalfValidator({ sizeString: { message: 'AR Coating Comment cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	arCoatingComment: string;

	@GandalfLabel('Authorized By')
	@GandalfProperty()
	authorizedByProviderId: number;

	@GandalfLabel('Distance PD')
	@GandalfProperty()
	distancePd: number;

	@GandalfLabel('Encounter')
	@GandalfProperty()
	encounterId: number;

	@GandalfLabel('Expiration Date')
	@GandalfValidator({ notNull: { message: 'Expiration Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	expirationDate: Date;

	@GandalfLabel('Instructions')
	@GandalfValidator({ sizeString: { message: 'Instructions cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	instructions: string;

	@GandalfLabel('Lens Type Comment')
	@GandalfValidator({ sizeString: { message: 'Lens Type Comment cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	lensTypeComment: string;

	@GandalfLabel('Lens Type')
	@GandalfProperty()
	lensTypeId: number;

	@GandalfLabel('Material Comment')
	@GandalfValidator({ sizeString: { message: 'Material Comment cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	materialComment: string;

	@GandalfLabel('Material')
	@GandalfProperty()
	materialId: number;

	@GandalfLabel('Near PD')
	@GandalfProperty()
	nearPd: number;

	@GandalfSubRequestLabel('OD')
	@GandalfLabel('OD')
	@GandalfValidator({ notNull: { message: 'OD is required' } })
	@GandalfProperty({ isRequired: true })
	od: EyeglassPrescriptionEyeRequest;

	@GandalfSubRequestLabel('OS')
	@GandalfLabel('OS')
	@GandalfValidator({ notNull: { message: 'OS is required' } })
	@GandalfProperty({ isRequired: true })
	os: EyeglassPrescriptionEyeRequest;

	@GandalfLabel('Patient')
	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientId: number;

	@GandalfLabel('Photochromic')
	@GandalfValidator({ notNull: { message: 'Photochromic is required' } })
	@GandalfProperty({ isRequired: true })
	photochromic: boolean;

	@GandalfLabel('Photochromic Comment')
	@GandalfValidator({ sizeString: { message: 'Photochromic Comment cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	photochromicComment: string;

	@GandalfLabel('Polarized')
	@GandalfValidator({ notNull: { message: 'Polarized is required' } })
	@GandalfProperty({ isRequired: true })
	polarized: boolean;

	@GandalfLabel('Polarized Comment')
	@GandalfValidator({ sizeString: { message: 'Polarized Comment cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	polarizedComment: string;

	@GandalfConstantDecorator()
	@GandalfLabel('Authorization Type')
	@GandalfValidator({ notNull: { message: 'Authorization Type is required' } })
	@GandalfProperty({ isRequired: true })
	prescriptionAuthorizationType: constants.PrescriptionAuthorizationType;

	@GandalfLabel('Start Date')
	@GandalfValidator({ notNull: { message: 'Start Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	startDate: Date;

	@GandalfLabel('Tint Comment')
	@GandalfValidator({ sizeString: { message: 'Tint Comment cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	tintComment: string;

	@GandalfLabel('Tint')
	@GandalfProperty()
	tintId: number;

	@GandalfLabel('Used For')
	@GandalfProperty()
	usedForId: number;

	@GandalfLabel('UV Treatment')
	@GandalfValidator({ notNull: { message: 'UV Treatment is required' } })
	@GandalfProperty({ isRequired: true })
	uvTreatment: boolean;

	@GandalfLabel('UV Treatment Comment')
	@GandalfValidator({ sizeString: { message: 'UV Treatment Comment cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	uvTreatmentComment: string;

}
