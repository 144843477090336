import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EnumUtil, ModalManagerService } from 'morgana';
import { _isEmpty, _isNil } from '@core/lodash/lodash';
import { CodeSet } from '@gandalf/constants';
import { CreatePracticeDiagnosisRequest } from '@gandalf/model/create-practice-diagnosis-request';
import { DiagnosisSearchResponse } from '@gandalf/model/diagnosis-search-response';
import { PracticeDiagnosisResponse } from '@gandalf/model/practice-diagnosis-response';
import { SnomedCodeResponse } from '@gandalf/model/snomed-code-response';
import { UpdatePracticeDiagnosisRequest } from '@gandalf/model/update-practice-diagnosis-request';
import { GandalfFormBuilder } from 'gandalf';
import { AdminDiagnosisService, CarePlanTemplateResponseForDropdown } from '../admin-diagnosis.service';
import { IcdDiagnosisAddModalComponent } from '../modals/icd-diagnosis-add-modal/icd-diagnosis-add-modal.component';
import { SnomedDiagnosisAddModalComponent } from '../modals/snomed-diagnosis-add-modal/snomed-diagnosis-add-modal.component';

@Component({
	selector: 'pms-update-diagnosis-detail',
	templateUrl: './diagnosis-detail.component.html',
	providers: [ModalManagerService],
})
export class DiagnosisDetailComponent implements OnInit {

	@Input()
	diagnosisData: PracticeDiagnosisResponse;

	@Input()
	codeSet: CodeSet;

	@Input()
	carePlanTemplates: CarePlanTemplateResponseForDropdown[];

	@Output()
	returnEvent = new EventEmitter<boolean>();

	carePlanTemplateId: number;
	isCreating: boolean;
	query = '';
	createUpdateRequest: CreatePracticeDiagnosisRequest | UpdatePracticeDiagnosisRequest;
	formGroup: UntypedFormGroup;

	constructor(
		public diagnosisService: AdminDiagnosisService,
		public modalManagerService: ModalManagerService,
		public gandalfFormBuilder: GandalfFormBuilder,
	) {
	}

	ngOnInit() {
		this.isCreating = !this.diagnosisData;
		this.createForm();
	}

	createForm() {
		this.createUpdateRequest = this.isCreating ? new CreatePracticeDiagnosisRequest() : new UpdatePracticeDiagnosisRequest();
		this.formGroup = this.gandalfFormBuilder.group(this.createUpdateRequest);

		if (!this.isCreating) {
			this.formGroup.get('practiceDiagnosisId').setValue(this.diagnosisData.id);
			if (this.diagnosisData.carePlanTemplate) {
				this.formGroup.get('carePlanTemplateId').setValue(this.diagnosisData.carePlanTemplate.carePlanTemplateId);
			}
		} else {
			this.diagnosisData = new PracticeDiagnosisResponse();
			this.formGroup.get('codeSet').setValue(this.codeSet);
		}
	}

	/*istanbul ignore next*/
	showClearButton() {
		return this.isCreating && !_isEmpty(this.diagnosisData.code);
	}

	clearForm() {
		if (this.isCreating) {
			this.query = '';
			this.diagnosisData = new PracticeDiagnosisResponse();
			this.formGroup.get('diagnosisId').setValue(null);
		}
	}

	/*istanbul ignore next*/
	openAddDiagnosisModal(event?: KeyboardEvent) {
		if (this.isCreating) {
			if (!_isNil(event)) {
				event.preventDefault();
			}
			if (EnumUtil.equalsOneOf(this.codeSet, CodeSet.ICD9, CodeSet.ICD10)) {
				this.modalManagerService.open(IcdDiagnosisAddModalComponent, {
					data: {
						query: this.query,
						codeSet: this.codeSet,
					},
				}).onClose.subscribe((diagnosis: DiagnosisSearchResponse) => {
					if (diagnosis) {
						this.diagnosisData.code = diagnosis.code;
						this.diagnosisData.shortDescription = diagnosis.shortDescription;
						this.diagnosisData.longDescription = diagnosis.longDescription;
						this.formGroup.get('diagnosisId').setValue(diagnosis.id);
					}
				});
			} else if (EnumUtil.equals(this.codeSet, CodeSet.SNOMED)) {

				this.modalManagerService.open(SnomedDiagnosisAddModalComponent, {
					data: {query: this.query},
				})
					.onClose.subscribe((snomedCode: SnomedCodeResponse) => {
						if (snomedCode) {
							this.diagnosisData.code = snomedCode.code.toString();
							this.diagnosisData.shortDescription = snomedCode.description;
							this.diagnosisData.longDescription = snomedCode.description;
							this.formGroup.get('diagnosisId').setValue(snomedCode.id);
						}
					});
			}
		}
	}

	saveChanges() {
		if (this.formGroup.invalid) {
			return;
		}

		const request = this.formGroup.value;
		if (this.isCreating) {
			this.diagnosisService.createPracticeDiagnosis(request).subscribe(() => {
				this.returnToList(true);
			});
		} else {
			this.diagnosisService.updatePracticeDiagnosis(request).subscribe(() => {
				this.returnToList(true);
			});
		}
	}

	/*istanbul ignore next*/
	returnToList(refreshRequested = false) {
		this.returnEvent.emit(refreshRequested);
	}
}
