<ejs-dialog
		#modal
		(close)="closeModal()"
		cssClass="modal-md"
		[attr.data-test-id]="'claimStatusSummaryModal'">
	<ng-template #header>
		<div class="dlg-template">Claim Status Summary</div>
	</ng-template>
	<ng-template #content>
		<form class="form-horizontal">
			<h4 class="section-title bg-default mrgn-btm">Claim Information</h4>
			<div class="row">
				<div class="col-md-6">
					<div class="form-group" [attr.data-test-id]="'insuranceClaimIdLabelGroup'">
						<label class="col-sm-4 control-label">Claim ID</label>
						<div class="col-sm-8">
							<p class="form-control-static">{{insuranceClaim.id}}</p>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'insuranceClaimDateLabelGroup'">
						<label class="col-sm-4 control-label">Claim Date</label>
						<div class="col-sm-8">
							<p class="form-control-static">{{insuranceClaim.claimDate | date : dateFormat}}</p>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'insuranceClaimInvoiceIdLabelGroup'">
						<label class="col-sm-4 control-label">Invoice ID</label>
						<div class="col-sm-8">
							<p class="form-control-static">{{invoice.id}}</p>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'insuranceClaimInvoiceDateLabelGroup'">
						<label class="col-sm-4 control-label">Invoice Date</label>
						<div class="col-sm-8">
							<p class="form-control-static">{{invoice.invoiceDate | date : dateFormat}}</p>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group" [attr.data-test-id]="'insuranceClaimCompanyNameLabelGroup'">
						<label class="col-sm-4 control-label">Insurance</label>
						<div class="col-sm-8">
							<p class="form-control-static">{{insuranceCompanyName}}</p>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'insuranceClaimPatientNameLabelGroup'">
						<label class="col-sm-4 control-label">Patient</label>
						<div class="col-sm-8">
							<p class="form-control-static">{{patientName | patientName}}</p>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'insuranceClaimSubmitDateLabelGroup'">
						<label class="col-sm-4 control-label">Submitted</label>
						<div class="col-sm-8">
							<p class="form-control-static">{{insuranceClaim.claimSubmitDate | date : dateFormat}}</p>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'insuranceClaimStatusLabelGroup'">
						<label class="col-sm-4 control-label">Status</label>
						<div class="col-sm-8">
							<p class="form-control-static">{{insuranceClaim.status.label}}</p>
						</div>
					</div>
				</div>
			</div>
			<h4 class="section-title bg-default mrgn-btm">Status Messages</h4>
			<ejs-grid #grid
					  [pmsGridSubmit]="isSearching"
					  [dataSource]="insuranceClaimStatus"
					  [allowPaging]="false"
					  [allowSelection]="false"
					  [allowSorting]="true"
					  [allowResizing]="true"
					  gridLines="Horizontal"
					  allowPaging="true"
					  [attr.data-test-id]="'claimStatusSummaryModalStatusMessageGrid'">
				<e-columns>
					<e-column field="status" headerText="Status" width="75" [isPrimaryKey]="true"></e-column>
					<e-column field="statusDate" width="75" headerText="Date" [format]="tableDateFormat"></e-column>
					<e-column clipMode="EllipsisWithTooltip" field="statusMessage" headerText="Status Message"></e-column>
				</e-columns>
			</ejs-grid>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button type="button" iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'claimStatusSummaryModalCancelButton'">Cancel</button>
	</ng-template>
</ejs-dialog>
