import { Directive, HostListener, Input, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';
import { _isNil } from '@core/lodash/lodash';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';

@Directive({
	selector: 'ejs-dropdownlist[pmsAutoSelect]',
	exportAs: 'pmsAutoSelect',
})
export class AutoSelectDirective {

	private dataBoundHasFired = false;

	@Input()
	autoSelectOnlyOption = false;

	constructor(
		private dropdownlistComponent: DropDownListComponent,
		@Optional() private control: NgControl,
	) {
	}

	/**
	 * This is a workaround to satisfy a bug with the dataBound event not being fired properly.
	 * The dataBound event will not be fired if the formControlName value is null until the popup is opened,
	 * this will open and close the popup so the dataBound event can be fired.
	 */
	@HostListener('created')
	onCreation() {
		if (!this.dataBoundHasFired) {
			this.dropdownlistComponent.showPopup();
			this.dropdownlistComponent.hidePopup();
			this.dropdownlistComponent.focusOut();
		}
	}

	/**
	 * The auto select functionality happens on the dataBound event. It will only attempt to auto select if
	 * the field is required, is not part of a form, or the autoSelectOnlyOption override has been passed true.
	 * It will auto select the only available option if the data source has exactly 1 available option.
	 */
	@HostListener('dataBound')
	autoSelect() {
		const isRequired = this.hasRequiredValidator();
		const items = this.dropdownlistComponent.dataSource as any[];
		const fieldValue = this.dropdownlistComponent.fields.value;

		if (this.shouldAutoSelect(isRequired, items)) {
			this.dropdownlistComponent.value = items[0][fieldValue];
		}
		this.dataBoundHasFired = true;
	}

	shouldAutoSelect(isRequired: boolean, items: any[]) {
		return (isRequired || _isNil(this.control) || this.autoSelectOnlyOption) && items?.length === 1;
	}

	/**
	 * This will determine whether the required validator is on the form control. If the control does not have the
	 * required validator or is not part of an angular form, it will return false.
	 */
	hasRequiredValidator() {
		if (_isNil(this.control)) {
			return false;
		}

		const boundControl = this.control.control as any;

		if (boundControl && boundControl.validator) {
			return boundControl.validator('') && boundControl.validator('').required;
		}

		return false;
	}
}
