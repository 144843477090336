<rev-loading-overlay [loading]="_isLoading">
	<ng-container *ngIf="showPayment">
		<gandalf-lib-validation-messages
				*ngIf="paymentComponentService.formGroupValidationFailed"
				[form]="paymentComponentService.formGroup"
				[requestObj]="paymentComponentService.request"
				[attr.data-test-id]="'showPaymentComponentValidationMessages'">
		</gandalf-lib-validation-messages>
		<div *ngIf="paymentComponentService.errors?.length" class="alert alert-danger">
			<ul>
				<li *ngFor="let error of paymentComponentService.errors"><strong>{{error}}</strong></li>
			</ul>
		</div>
		<div *ngIf="isPendingERAPaymentGroup()" class="text-left alert alert-warning">Please review payment notes, tasks, and the ERA before applying the payment to ensure accuracy</div>
		<div class="media mrgn-btm">
			<div class="media-body">
				<h4 class="media-heading display-inline-block vertical-align-baseline margin-top-0 margin-bottom-0" [attr.data-test-id]="'showPaymentComponentPaymentNumberLabelGroup'">
					<span *ngIf="paymentGroupId">{{payerType}} Payment #{{paymentGroupId}}</span>
					<span *ngIf="!paymentGroupId" class="margin-right-lg">New {{payerType}} Payment</span>
					<span *ngIf="paymentComponentService.paymentGroup" class="small text-uppercase margin-left-sm margin-right-lg"><strong>{{paymentComponentService.paymentGroup.status}}</strong></span>
				</h4>
				<ul class="list-inline display-inline-block vertical-align-baseline margin-bottom-0">
					<li><i class="fa fa-user text-primary margin-right-xs"></i>{{payerName}}</li>
				</ul>
			</div>
			<div *ngIf="displayViewRemittanceAdvice" class="media-right">
				<rev-button
					(buttonClick)="openReadableERA()"
					dataTestId="viewRemittanceAdviceButton"
					[buttonDisabled]="_isLoading"
					buttonLabel="View Remittance Advice">
				</rev-button>
			</div>
		</div>
		<pms-receive-payments
				*ngIf="!paymentGroupId"
				[state]="state"
				[isModal]="isModal"
				(openInvoiceDetail)="openInvoiceDetailModal($event)">
		</pms-receive-payments>
		<ejs-tab *ngIf="paymentGroupId" overflowMode="Popup" [animation]="tabAnimation">
			<e-tabitems>
				<e-tabitem>
					<ng-template #headerText>
						<span [attr.data-test-id]="'paymentInformationTab'">Details</span>
					</ng-template>
					<ng-template #content>
						<pms-receive-payments
								*ngIf="!paymentComponentService.isReadOnly"
								[state]="state"
								[isModal]="isModal"
								(openInvoiceDetail)="openInvoiceDetailModal($event)">
						</pms-receive-payments>
						<pms-read-only-payment
								*ngIf="paymentComponentService.isReadOnly"
								(openInvoiceDetail)="openInvoiceDetailModal($event)">
						</pms-read-only-payment>
					</ng-template>
				</e-tabitem>
				<e-tabitem>
					<ng-template #headerText>
						<span [attr.data-test-id]="'paymentNotesTab'">Notes</span>
					</ng-template>
					<ng-template #content>
						<pms-notes [entityType]="paymentEntityType" [parentId]="paymentGroupId"></pms-notes>
					</ng-template>
				</e-tabitem>
			</e-tabitems>
		</ejs-tab>
	</ng-container>
</rev-loading-overlay>
