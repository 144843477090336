import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { NavigationService } from '@core/navigation/navigation.service';
import { SECURITY_CONSTANTS } from '@core/security/security.constants';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { ToasterService } from '@core/toaster/toaster.service';
import { PreferenceDefaults, PreferenceName } from '@gandalf/constants';
import { AskQuestionRequest } from '@gandalf/model/ask-question-request';
import { FindTodaysPatientsAppointmentsForProviderRequest } from '@gandalf/model/find-todays-patients-appointments-for-provider-request';
import { SendFeedbackRequest } from '@gandalf/model/send-feedback-request';
import { StartAppointmentRequest } from '@gandalf/model/start-appointment-request';
import { EmployeeTaskGandalfService, HeaderGandalfService } from '@gandalf/services';
import { ONE_MINUTE } from '@shared/constants/time.constants';
import { SortingService } from 'morgana';
import { filter, map } from 'rxjs/operators';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { UrlService } from '@core/url-util/url.service';
import { LockManagerService } from '@core/lock-manager/lock-manager.service';

@Injectable({
	providedIn: 'root',
})
export class NavigationMenuService {

	unreadConversationsCount = new BehaviorSubject<number>(0);
	displayNotification = false;
	private unreadConversationsCountIntervalSubscription: Subscription;

	constructor(
		private headerGandalfService: HeaderGandalfService,
		private employeeTaskGandalfService: EmployeeTaskGandalfService,
		private featureService: FeatureService,
		private securityManagerService: SecurityManagerService,
		private toasterService: ToasterService,
		private navigationService: NavigationService,
		private router: Router,
		public securityManager: SecurityManagerService,
		private urlService: UrlService,
		private lockManagerService: LockManagerService,
	) {
	}

	/* istanbul ignore next: gandalf */
	getNewTaskCountForEmployee() {
		return this.employeeTaskGandalfService.getActiveTaskCountForEmployee();
	}

	/* istanbul ignore next: gandalf */
	getNewMessagesCountForEmployee() {
		return this.headerGandalfService.getNewMessageParticipantCountForEmployee();
	}

	/* istanbul ignore next: gandalf */
	askRevAssureQuestion(request: AskQuestionRequest) {
		return this.headerGandalfService.askRevAssureQuestion(request);
	}

	/* istanbul ignore next: gandalf */
	askMeaningfulUseQuestion(request: AskQuestionRequest) {
		return this.headerGandalfService.askMeaningfulUseQuestion(request);
	}

	/* istanbul ignore next: gandalf */
	sendFeedback(request: SendFeedbackRequest) {
		return this.headerGandalfService.sendFeedback(request);
	}

	findTodaysPatientsAppointments(locationId: number) {
		return this.headerGandalfService.findTodaysPatientsAppointments(locationId).pipe(
			map(appointments => SortingService.sortBy(appointments, ['startDate', 'appointmentId'], ['asc', 'asc'])),
		);
	}

	findTodaysPatientsAppointmentsForProvider(request: FindTodaysPatientsAppointmentsForProviderRequest) {
		return this.headerGandalfService.findTodaysPatientsAppointmentsForProvider(request).pipe(
			map(appointments => SortingService.sortBy(appointments, ['startDate', 'appointmentId'], ['asc', 'asc'])),
		);
	}

	startAppointment(request: StartAppointmentRequest) {
		return this.headerGandalfService.startEncounterByAppointment(request);
	}

	refreshUnreadConversationsCount() {
		this.headerGandalfService.getUnreadConversationsCount().subscribe((count) => {
			this.unreadConversationsCount.next(count);
		});
	}

	displayUnreadConversationToaster() {
		const count = this.unreadConversationsCount.value;
		if (count === 0) {
			return;
		}

		this.toasterService.showDefaultNotificationToast({
			title: `You have ${count} Unread RevConnect Two-Way Texting Conversations`,
			buttons: [
				{
					model: {
						content: 'More Info',
						cssClass: 'e-link',
					},
					click: () => this.navigationService.navigateToTwoWayText(),
				},
			],
		});

		this.router.events.pipe(filter(
			event => event instanceof NavigationStart))
			.subscribe((event: NavigationStart) => {
				if (event.url.indexOf('home') === -1) {
					this.clearToasts();
				}
			});
	}

	incrementUnreadConversationsCount() {
		const newCount = this.unreadConversationsCount.value + 1;
		this.unreadConversationsCount.next(newCount);
	}

	decrementUnreadConversationsCount() {
		let newCount = this.unreadConversationsCount.value - 1;
		if (newCount < 0) {
			newCount = 0;
		}
		this.unreadConversationsCount.next(newCount);
	}

	subscribeToTwtUnreadConversationsInterval() {
		if (this.shouldSubscribeToUnreadConversation()) {
			this.unreadConversationsCount.subscribe(() => {
				if (this.displayNotification) {
					this.displayNotification = false;
					this.displayUnreadConversationToaster();
				}
			});
			this.displayNotification = this.hasTwoWayTextNotificationEnabled();
			this.subscribeToUnreadConversationsInterval();
			this.lockManagerService.locked.subscribe(() => this.unreadConversationsCountIntervalSubscription.unsubscribe());
			this.lockManagerService.unlocked.subscribe(() => this.subscribeToUnreadConversationsInterval());
		}
	}

	subscribeToUnreadConversationsInterval() {
		this.unreadConversationsCountIntervalSubscription = timer(0, ONE_MINUTE).subscribe(() => this.refreshUnreadConversationsCount());
	}

	hasTwoWayTextNotificationEnabled() {
		return this.securityManagerService.preferenceValueIsOn(
			PreferenceName.TWO_WAY_TEXT_NOTIFICATIONS.value,
			PreferenceDefaults.TWO_WAY_TEXT_NOTIFICATIONS.value,
		);
	}

	clearToasts() {
		this.toasterService.hideDefaultNotificationToast();
	}

	shouldSubscribeToUnreadConversation() {
		return this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.REVCONNECT.TWO_WAY_TEXT)
			&& this.securityManagerService.allowTwoWayTexting()
			&& this.securityManager.hasPermission(SECURITY_CONSTANTS.RESOURCE_CONNECT_VIEW)
			&& this.securityManager.hasPermission(SECURITY_CONSTANTS.RESOURCE_CONNECT_VIEW_TWO_WAY_TEXT);
	}

	openDoctibleSsoTab() {
		this.headerGandalfService.getDoctibleSsoUrl().subscribe(url => this.urlService.goToWebpage(url, 'revengage_doctible'));
	}
}
