import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EnumUtil } from 'morgana';
import { _isNil } from '@core/lodash/lodash';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { PatientEmploymentStatus, PatientReferralSourceType, PreferenceDefaults, PreferenceName } from '@gandalf/constants';
import { PatientPortalAuthorizedPatientResponse } from '@gandalf/model/patient-portal-authorized-patient-response';
import { PatientResponse } from '@gandalf/model/patient-response';
import { ValidateUsernameRequest } from '@gandalf/model/validate-username-request';
import { CheckinDemographicsGandalfService } from '@gandalf/services';
import { PatientNamePipe } from '@shared/pipes/patient-name/patient-name.pipe';
import { conditionallyRequiredValidator } from '@shared/validators/conditionally-required-validation';
import { RemovePatientTab } from '@patients-store/actions';
import { HIT_PMS_HTML_EVENTS } from '@core/legacy/hit-pms-html.constants';
import { Store } from '@ngrx/store';
import { State } from '@patients-store/reducers';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { Subject } from 'rxjs';

export interface FormattedPatientPortalAuthorizedPatientResponse extends PatientPortalAuthorizedPatientResponse {
	patientName: string;
	patientId: number;
}

@Injectable({
	providedIn: 'root',
})
export class DemographicsService {

	resolvedCellPhone = new Subject<void>();

	constructor(
		public checkinDemographicsGandalfService: CheckinDemographicsGandalfService,
		public securityManagerService: SecurityManagerService,
		public patientNamePipe: PatientNamePipe,
		private store: Store<State>,
		private eventsManagerService: EventsManagerService,
	) {
	}

	/* istanbul ignore next: gandalf */
	validateUsername(request: ValidateUsernameRequest) {
		return this.checkinDemographicsGandalfService.validateUsername(request);
	}

	/* istanbul ignore next: gandalf */
	generatePassword() {
		return this.checkinDemographicsGandalfService.generatePassword();
	}

	formatPatientPortalRepresentative(response: PatientPortalAuthorizedPatientResponse): FormattedPatientPortalAuthorizedPatientResponse {
		const formattedResponse = response as FormattedPatientPortalAuthorizedPatientResponse;
		formattedResponse.patientName = this.patientNamePipe.transform(response.patient);
		formattedResponse.patientId = response.patient.patientId;

		return formattedResponse;
	}

	setReferralInformationValidators(patientReferralFormGroup: UntypedFormGroup, patientResponse: PatientResponse) {
		patientReferralFormGroup.setValidators([
			conditionallyRequiredValidator(
				'sourceType',
				() => this.isReferredByRequired(patientResponse) === true,
				'sourceTypeIsRequired',
				'Referred By is required',
			),
			conditionallyRequiredValidator(
				'sourceId',
				() => this.isOtherSourceType(patientReferralFormGroup),
				'sourceTypeIsRequired',
				'Other is required',
			),
		]);
	}

	setPatientEmploymentValidators(patientEmploymentFormGroup: UntypedFormGroup) {
		patientEmploymentFormGroup.setValidators([
			conditionallyRequiredValidator(
				'employerName',
				() => this.isEmployerRequired(patientEmploymentFormGroup),
				'employerRequired',
				'Employer is required',
			),
			conditionallyRequiredValidator(
				'status',
				() => this.isEmploymentSchoolStatusRequired(),
				'employmentSchoolStatusRequired',
				'Employment/School status is required',
			),
			conditionallyRequiredValidator(
				'address.city',
				() => this.isEmployerCityRequired(patientEmploymentFormGroup),
				'employerCityIsRequired',
				'Employer city is required',
			),
		]);
	}

	setAdditionalInformationValidators(additionalInformationFormGroup: UntypedFormGroup) {
		additionalInformationFormGroup.setValidators([
			conditionallyRequiredValidator(
				'temporaryAddressStartDate',
				() => additionalInformationFormGroup.get('hasTemporaryAddress').value,
				'startDateIsRequired',
				'Temporary Address Start Date is required',
			),
		]);
	}

	setPortalCredentialsValidators(portalCredentialsFormGroup: UntypedFormGroup, patientResponse: PatientResponse) {
		portalCredentialsFormGroup.setValidators([
			conditionallyRequiredValidator(
				'userName',
				() => portalCredentialsFormGroup.get('allowLogin').value,
				'userNameIsRequired',
				'Username is required',
			),
			conditionallyRequiredValidator(
				'temporaryPassword',
				() => portalCredentialsFormGroup.get('allowLogin').value && !patientResponse.hasPassword,
				'temporaryPasswordRequired',
				'Password is required',
			),
		]);
	}

	handleHasPreviousAddress(additionalInformationFormGroup: UntypedFormGroup) {
		if(!additionalInformationFormGroup.get('hasPreviousAddress').value) {
			// Remove previous Address control until user clicks to add a previous address
			additionalInformationFormGroup.removeControl('previousAddress');
		}
	}

	isEmployed(patientEmploymentFormGroup: UntypedFormGroup) {
		return EnumUtil.equalsOneOf(patientEmploymentFormGroup.get('status').value,
			PatientEmploymentStatus.EMPLOYED_PART_TIME, PatientEmploymentStatus.EMPLOYED_FULL_TIME);
	}

	isOtherSourceType(patientReferralFormGroup: UntypedFormGroup) {
		return EnumUtil.equals(patientReferralFormGroup.get('sourceType').value, PatientReferralSourceType.OTHER);
	}

	isEmployerRequired(patientEmploymentFormGroup: UntypedFormGroup) {
		return this.securityManagerService.preferenceValueIsOn(
			PreferenceName.DEMOGRAPHICS_EMPLOYER_REQUIRED.value,
			PreferenceDefaults.DEMOGRAPHICS_EMPLOYER_REQUIRED.value,
		) && this.isEmployed(patientEmploymentFormGroup);
	}

	isEmployerCityRequired(patientEmploymentFormGroup: UntypedFormGroup) {
		return this.securityManagerService.preferenceValueIsOn(
			PreferenceName.DEMOGRAPHICS_EMPLOYER_CITY_REQUIRED.value,
			PreferenceDefaults.DEMOGRAPHICS_EMPLOYER_CITY_REQUIRED.value,
		) && this.isEmployed(patientEmploymentFormGroup);
	}

	isEmploymentSchoolStatusRequired() {
		return this.securityManagerService.preferenceValueIsOn(
			PreferenceName.DEMOGRAPHICS_EMPLOYMENT_SCHOOL_STATUS_REQUIRED.value,
			PreferenceDefaults.DEMOGRAPHICS_EMPLOYMENT_SCHOOL_STATUS_REQUIRED.value,
		);
	}

	isReferredByRequired(patientResponse: PatientResponse) {
		return this.securityManagerService.preferenceValueIsOn(
			PreferenceName.DEMOGRAPHICS_REFERREDBY_REQUIRED.value,
			PreferenceDefaults.DEMOGRAPHICS_REFERREDBY_REQUIRED.value,
		) && _isNil(patientResponse);
	}

	closePatientTab(patientId: number) {
		this.store.dispatch(new RemovePatientTab({patientId}));
		this.eventsManagerService.publish(
			HIT_PMS_HTML_EVENTS.PATIENTS.PATIENTS_SUMMARY_DASHBOARD_CLOSE_TAB_FOR_PATIENT,
			{patientId},
		);
	}

}
