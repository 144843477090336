import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { _filter, _findIndex, _isNil, _map } from '@core/lodash/lodash';
import { AddDiscountItemRequest } from '@gandalf/model/add-discount-item-request';
import { AddDiscountItemsRequest } from '@gandalf/model/add-discount-items-request';
import { InvoiceItemResponse } from '@gandalf/model/invoice-item-response';
import { InvoiceItemStatus, InvoiceItemType } from '@gandalf/constants';
import { assertTrue } from '@shared/validators/assert-true.validation';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { AgGridAngular } from 'ag-grid-angular';
import {
	CellFocusedEvent,
	CellMouseDownEvent,
	ColDef,
	Column,
	GridApi,
	GridReadyEvent,
	IRowNode
} from 'ag-grid-community';
import { GandalfFormArray, GandalfFormBuilder } from 'gandalf';
import {
	DynamicModalRef,
	GridUtil,
	GridUtilService,
	ModalConfig,
	ModalManagerService,
	StatusSelectButtonComponent,
	TemplateCellRendererComponent
} from 'morgana';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountingService } from '../../core/accounting/accounting.service';
import { InvoiceItemPricingUtil } from '../../core/accounting/invoice-item-pricing/invoice-item-pricing-util';
import { InvoiceService } from '../../core/accounting/invoice-service/invoice.service';

export interface DiscountItem {
	invoiceItem: InvoiceItemResponse;
	subtotal: number;
	totalDiscount: number;
	tax: number;
	balance: number;
	extPrice: number;
	discountPercent: number;
	discountAmount: number;
}

export const DISCOUNT_PERCENT_FIELD = 'discountPercent';
export const DISCOUNT_AMOUNT_FIELD = 'discountAmount';
export const DISCOUNT_ITEMS_FIELD = 'discountItems';
export const EDITABLE_DOLLARS_FIELD = 'editableDollars';
export const EDITABLE_PERCENT_FIELD = 'editablePercent';

export const DISCOUNT_PERCENT_TYPE = 'discountPercent';
export const DISCOUNT_DOLLAR_TYPE = 'dollar';

@Component({
	selector: 'pms-discount-items-modal',
	templateUrl: './discount-items-modal.component.html',
	styles: [],
})
export class DiscountItemsModalComponent implements OnInit, OnDestroy {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('templateForm')
	templateForm: NgForm;

	@ViewChild('discountType')
	discountType: StatusSelectButtonComponent;

	@ViewChild('agGrid')
	agGrid: AgGridAngular<DiscountItem>;

	@ViewChild('balanceTemplate')
	balanceTemplate: TemplateRef<any>;

	@ViewChild('discountPercentColumn')
	discountPercentColumn: TemplateRef<any>;

	@ViewChild('discountAmountColumn')
	discountAmountColumn: TemplateRef<any>;

	discountReasons: any[];
	request: AddDiscountItemsRequest;
	formGroup: UntypedFormGroup;
	isSearching = true;
	invoiceItems: DiscountItem[];
	invoiceId: number;
	discountTypeOptions = [{value: DISCOUNT_DOLLAR_TYPE, label: 'Dollar'}, {value: DISCOUNT_PERCENT_TYPE, label: 'Percent'}];
	percentInputValue = 0;
	percentInputDisabled = true;
	private unsubscribe$ = new Subject<void>();

	discountItemsGridOptions = GridUtil.buildGridOptions({});
	private gridColumns: ColDef[];
	agGridApi: GridApi;
	lastFocusedCell: any;
	cancelFocusEvent = false;
	focusedRowId: number;
	focusedColumnKey: string;

	constructor(
		public ref: DynamicModalRef,
		public modalManagerService: ModalManagerService,
		public modalConfig: ModalConfig,
		public accountingService: AccountingService,
		public formBuilder: GandalfFormBuilder,
		public invoiceService: InvoiceService,
		private gridUtilService: GridUtilService,
	) {
	}

	static updateItemForAgGrid(discountItem: DiscountItem, discountAmount: number, discountPercent: number) {
		discountItem.discountAmount = Number(discountAmount);
		discountItem.discountPercent = Number(discountPercent);
		discountItem.totalDiscount = Number(InvoiceItemPricingUtil.combineDiscounts(discountItem.invoiceItem.discountTotal, discountItem.discountAmount));
		const taxableAmount = InvoiceItemPricingUtil.calculateTaxableAmount(discountItem.subtotal, discountItem.totalDiscount);
		discountItem.tax = Number(InvoiceItemPricingUtil.calculateTaxAmount(taxableAmount, discountItem.invoiceItem.taxRate));
		discountItem.extPrice = Number(InvoiceItemPricingUtil.calculateExtPrice(
			discountItem.invoiceItem.unitPrice,
			discountItem.invoiceItem.quantity,
			discountItem.totalDiscount,
			discountItem.invoiceItem.taxRate,
		));
		discountItem.balance = Number(InvoiceItemPricingUtil.calculateBalance(discountItem.extPrice,  discountItem.invoiceItem.adjustmentTotal)
			.minus(GridUtil.roundCurrency(discountItem.invoiceItem.amountPaid)));

		return discountItem;
	}

	ngOnInit() {
		this.invoiceId = this.modalConfig.data.invoiceId;
		this.getData();
		this.buildRequest();
		this.buildForm();
	}

	closeModal() {
		this.ref.close(this.modal);
	}

	changeDiscountType($event) {
		if ($event.value === DISCOUNT_DOLLAR_TYPE) {
			this.disablePercentInput();
			this.enableDollarInput();
			this.updateAllItemAmounts();
		} else {
			this.enablePercentInput();
			this.disableDollarInput();
			this.updateAllItemPercentages();
		}
	}

	updateAllItemPercentages() {
		if (this.invoiceItems) {
			this.invoiceItems.forEach(item => this.percentChange(item.invoiceItem.id));
		}
	}

	updateAllItemAmounts() {
		if (this.invoiceItems) {
			this.invoiceItems.forEach(item => this.amountChangeForAgGrid(item.invoiceItem.id));
		}
	}

	getData() {
		combineLatest([this.accountingService.findDiscountReasonsForDropdown(), this.accountingService.getInvoiceById(this.invoiceId)])
			.subscribe(([discountReasons, invoice]) => {
				this.handleFetchedData(discountReasons, invoice);
			});
	}

	handleFetchedData(discountReasons, invoice) {
		this.isSearching = false;
		this.discountReasons = discountReasons;
		const discountableItems = _filter(invoice.items, item => this.canDiscount(item));
		this.prepareFormAndRequestWithItems(discountableItems);
		this.invoiceItems = _map(discountableItems, (item) => DiscountItemsModalComponent.updateItemForAgGrid({
			invoiceItem: item,
			subtotal: Number(InvoiceItemPricingUtil.calculateSubTotal(item.unitPrice, item.quantity)),
			totalDiscount: 0,
			tax: 0,
			balance: 0,
			extPrice: 0,
			discountPercent: 0,
			discountAmount: 0,
		}, 0, 0));
		this.changeDiscountType({value: DISCOUNT_DOLLAR_TYPE});
		this.updateDiscountItems(this.invoiceItems);
	}

	submit() {
		if (this.formGroup.invalid) {
			return;
		}
		this.accountingService.addDiscountItems(this.formGroup.getRawValue()).subscribe(() => {
			this.invoiceService.refreshInvoice(this.invoiceId);
			this.closeModal();
		});
	}

	disablePercentInput() {
		this.percentInputValue = 0;
		this.percentInputDisabled = true;
		(this.formGroup.get(DISCOUNT_ITEMS_FIELD) as GandalfFormArray).controls.forEach(group => {
			group.get(DISCOUNT_PERCENT_FIELD).disable();
			group.get(DISCOUNT_PERCENT_FIELD).setValue(0);
		});
	}

	enablePercentInput() {
		this.percentInputDisabled = false;
		(this.formGroup.get(DISCOUNT_ITEMS_FIELD) as GandalfFormArray).controls.forEach(group => {
			group.get(DISCOUNT_PERCENT_FIELD).enable();
		});
	}

	/* istanbul ignore next */
	enableDollarInput() {
		(this.formGroup.get(DISCOUNT_ITEMS_FIELD) as GandalfFormArray).controls.forEach(group => {
			group.get(DISCOUNT_AMOUNT_FIELD).enable();
		});
	}

	/* istanbul ignore next */
	disableDollarInput() {
		(this.formGroup.get(DISCOUNT_ITEMS_FIELD) as GandalfFormArray).controls.forEach(group => {
			group.get(DISCOUNT_AMOUNT_FIELD).disable();
			group.get(DISCOUNT_AMOUNT_FIELD).setValue(0);
		});
	}

	buildRequest() {
		this.request = new AddDiscountItemsRequest();
		this.request.invoiceId = this.invoiceId;
	}

	buildForm() {
		this.formGroup = this.formBuilder.group(this.request);
	}

	setPercentages(percent) {
		(this.formGroup.get(DISCOUNT_ITEMS_FIELD) as GandalfFormArray).controls.forEach(group => {
			group.get(DISCOUNT_PERCENT_FIELD).setValue(percent);
		});
		this.updateAllItemPercentages();
	}

	prepareFormAndRequestWithItems(items: InvoiceItemResponse[]) {
		this.request.discountItems = [];
		items.forEach((item, index) => {
			const discountItemsFormArray = this.formGroup.get(DISCOUNT_ITEMS_FIELD) as GandalfFormArray;
			const addDiscountItemRequest = new AddDiscountItemRequest();
			addDiscountItemRequest.discountAmount = 0;
			addDiscountItemRequest.discountPercent = 0;
			addDiscountItemRequest.invoiceItemId = item.id;
			discountItemsFormArray.pushRequestItem(addDiscountItemRequest);
			const formArrayGroup = discountItemsFormArray.get(index.toString());
			const formGroupValidator = formArrayGroup.validator;
			const controls = [DISCOUNT_AMOUNT_FIELD, DISCOUNT_PERCENT_FIELD];
			const assertion = () => this.invoiceItems[index].subtotal >= this.invoiceItems[index].discountAmount;
			const newValidator = assertTrue(assertion, controls, 'Balance', 'Discount amount cannot be greater than the Total');
			if (formGroupValidator) {
				formArrayGroup.setValidators([formGroupValidator, newValidator]);
			} else {
				formArrayGroup.setValidators([newValidator]);
			}
			formArrayGroup.get(DISCOUNT_AMOUNT_FIELD).valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((_value) => {
				this.amountChangeForAgGrid(addDiscountItemRequest.invoiceItemId);
			});
			formArrayGroup.get(DISCOUNT_PERCENT_FIELD).valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
				this.percentChange(addDiscountItemRequest.invoiceItemId);
			});
			this.request.discountItems.push(addDiscountItemRequest);
		});
	}

	getInvoiceItemControl(field: string, id) {
		const index = this.getItemIndexById(id);
		return this.formGroup.get(DISCOUNT_ITEMS_FIELD).get(index.toString()).get(field);
	}

	private getItemIndexById(id) {
		return _findIndex(this.invoiceItems, (item) => item.invoiceItem.id === id);
	}

	getInvoiceItemValue(field: string, id): number {
		let value = this.getInvoiceItemControl(field, id).value;
		if (!value) {
			value = Number(0);
		}
		return Number(value);
	}

	percentChange(id) {
		const index = this.getItemIndexById(id);
		const item = this.invoiceItems[index];
		const discountAmount = Number(InvoiceItemPricingUtil.calculatePercentOf(item.subtotal, this.getInvoiceItemValue(DISCOUNT_PERCENT_FIELD, id)));
		if (item.discountAmount !== Number(discountAmount)) {
			this.getInvoiceItemControl(DISCOUNT_AMOUNT_FIELD, id).setValue(discountAmount);
		}
	}

	amountChangeForAgGrid(id: number) {
		const index = this.getItemIndexById(id);
		const item = this.invoiceItems[index];
		const previousDiscountAmount = item.discountAmount;
		const discountAmount  = this.getInvoiceItemValue(DISCOUNT_AMOUNT_FIELD, id);
		const discountPercent = this.getInvoiceItemValue(DISCOUNT_PERCENT_FIELD, id);
		DiscountItemsModalComponent.updateItemForAgGrid(item, discountAmount, discountPercent);
		if (previousDiscountAmount !== discountAmount) {
			if (!_isNil(this.agGrid?.api)) {
				this.agGrid.api.refreshCells({
					columns: [
						DISCOUNT_AMOUNT_FIELD,
						DISCOUNT_PERCENT_FIELD,
						'totalDiscount',
						'tax',
						'extPrice',
						'balance',
					],
					force: true,
				});
			}
		}
	}

	/**
	 * Determines if an invoice item can be discounted
	 */
	canDiscount(item: InvoiceItemResponse) {
		// Item must be a product or service or adhoc
		// Item status must be active
		// Is not a split item (split == false && wasSplit == false)
		return (item.type === InvoiceItemType.PRODUCT || item.type === InvoiceItemType.SERVICE || item.type === InvoiceItemType.AD_HOC)
		&&
		item.status === InvoiceItemStatus.ACTIVE
		&&
		(!item.split && !item.wasSplit);
	}

	onGridReady(event: GridReadyEvent) {
		this.agGridApi = event.api;

		this.buildGridColumns();
		this.agGridApi.setGridOption('columnDefs', this.gridColumns);
		// on init we want to update the grid correctly
		this.updateDiscountItems(this.invoiceItems);
	}

	updateDiscountItems(discountItems) {
		if (!_isNil(this.agGrid?.api)) {
			this.agGrid.api.setGridOption('rowData', discountItems);
		}
	}

	getDiscountAmountFormControl = (rowNode: IRowNode<DiscountItem>) => this.getInvoiceItemControl(DISCOUNT_AMOUNT_FIELD, rowNode?.data?.invoiceItem.id);

	getDiscountPercentFormControl = (rowNode: IRowNode<DiscountItem>) => this.getInvoiceItemControl(DISCOUNT_PERCENT_FIELD, rowNode?.data?.invoiceItem.id);

	isDiscountAmountCellEditable = (rowNode: IRowNode<DiscountItem>) => this.getDiscountAmountFormControl(rowNode)?.enabled;

	isDiscountPercentCellEditable = (rowNode: IRowNode<DiscountItem>) => this.getDiscountPercentFormControl(rowNode)?.enabled;

	isDollarDiscount() {
		return this.discountType.value === DISCOUNT_DOLLAR_TYPE;
	}

	onTabKeydownDiscountType(event: KeyboardEvent) {
		if (this.isDollarDiscount() && this.discountType.focusedItem.dataset.optionValue === DISCOUNT_PERCENT_TYPE) {
			event.preventDefault();
			this.agGridApi.ensureIndexVisible(0);
			this.agGridApi.setFocusedCell(0, EDITABLE_DOLLARS_FIELD);
		}
	}

	onTabKeydownPercent(event: KeyboardEvent) {
		event.preventDefault();
		this.agGridApi.ensureIndexVisible(0);
		this.agGridApi.setFocusedCell(0, EDITABLE_PERCENT_FIELD);
	}

	isCellSelected(id: number, field: string) {
		if (!_isNil(id) && !_isNil(this.focusedRowId) && this.focusedColumnKey) {
			return this.focusedRowId === id && this.focusedColumnKey === field;
		}

		return false;
	}

	onInputBlur(){
		this.focusedRowId = null;
		this.focusedColumnKey = null;
	}

	onCellFocus(event: CellFocusedEvent) {
		const column = event?.column as Column;
		if (!column?.getColId() || this.cancelFocusEvent) {
			this.cancelFocusEvent = false;
			return;
		}

		const focusedCell = {
			rowIndex: event.rowIndex,
			colId: column.getColId(),
		};

		if (
			focusedCell.rowIndex === this.lastFocusedCell?.rowIndex &&
			focusedCell.colId === this.lastFocusedCell?.colId
		) {
			if (event.rowPinned !== 'bottom') {
				this.lastFocusedCell = {
					rowIndex: event.rowIndex,
					colId: column.getColId(),
				};
				this.agGrid.api.stopEditing();
			} else {
				this.lastFocusedCell = {
					rowIndex: null,
					colId: null,
				};
			}

			return;
		}

		const rowNode = this.agGrid.api.getDisplayedRowAtIndex(event.rowIndex);

		if (column.isSuppressNavigable(rowNode)) {
			this.lastFocusedCell = {
				rowIndex: event.rowIndex,
				colId: column.getColId(),
			};
			this.agGridApi.tabToNextCell();
			return;
		}

		const isEditable = this.isCellEditable(column.getColId(), rowNode);

		this.lastFocusedCell = {
			rowIndex: event.rowIndex,
			colId: column.getColId(),
		};

		if (isEditable) {
			this.agGrid.api.ensureIndexVisible(event.rowIndex);
			this.focusedRowId = rowNode.data.invoiceItem.id;
			this.focusedColumnKey = column.getColId();
		} else {
			this.agGridApi.tabToNextCell();
		}
	}

	onCellClick(event: CellMouseDownEvent) {
		const rowNode = this.agGridApi.getDisplayedRowAtIndex(event.rowIndex);
		const isEditable = this.isCellEditable(event.column.getColId(), rowNode);

		this.cancelFocusEvent = !isEditable;
	}

	isCellEditable(colKey: string, rowNode: IRowNode<DiscountItem>) {
		if (colKey === EDITABLE_DOLLARS_FIELD) {
			return this.isDiscountAmountCellEditable(rowNode);
		}

		if (colKey === EDITABLE_PERCENT_FIELD) {
			return this.isDiscountPercentCellEditable(rowNode);
		}

		return false;
	}

	buildGridColumns() {
		this.gridColumns = [
			GridUtil.buildColumn('Code', 'invoiceItem.code', {
				width: 85,
				tooltipField: 'invoiceItem.code',
				suppressNavigable: true,
			}),
			GridUtil.buildColumn('Description', 'invoiceItem.description', {
				tooltipField: 'invoiceItem.description',
				minWidth: 200,
				flex: 1,
				suppressNavigable: true,
			}),
			GridUtil.buildNumericColumn('Qty', 'invoiceItem.quantity', {
				width: 65,
				suppressNavigable: true,
			}),
			this.gridUtilService.buildCurrencyColumn('Unit Price', 'invoiceItem.unitPrice', {
				width: 100,
				suppressNavigable: true,
			}),
			this.gridUtilService.buildCurrencyColumn('Sub-Total', 'subtotal', {
				width: 100,
				suppressNavigable: true,
			}),
			GridUtil.buildNumericColumn('Percent Hidden', DISCOUNT_PERCENT_FIELD, {
				hide: true,
			}),
			this.gridUtilService.buildCurrencyColumn('Dollars Hidden', DISCOUNT_AMOUNT_FIELD, {
				hide: true,
			}),
			GridUtil.buildTemplateColumn('Percent', EDITABLE_PERCENT_FIELD, this.discountPercentColumn,{
				width: 100,
				sortable: false,
				valueGetter: params => this.getDiscountPercentFormControl(params.node)?.value,
				suppressNavigable: () => this.isDollarDiscount(),
			}),
			GridUtil.buildTemplateColumn('Dollars', EDITABLE_DOLLARS_FIELD, this.discountAmountColumn, {
				width: 100,
				sortable: false,
				valueGetter: params => this.getDiscountAmountFormControl(params.node)?.value,
				suppressNavigable: () => !this.isDollarDiscount(),
			}),
			this.gridUtilService.buildCurrencyColumn('Discounts', 'totalDiscount', {
				width: 100,
				suppressNavigable: true,
			}),
			this.gridUtilService.buildCurrencyColumn('Taxes', 'tax', {
				width: 80,
				suppressNavigable: true,
			}),
			this.gridUtilService.buildCurrencyColumn('Ext. Price', 'extPrice', {
				width: 100,
				suppressNavigable: true,
			}),
			this.gridUtilService.buildCurrencyColumn('Adjustments', 'invoiceItem.adjustmentTotal', {
				width: 120,
				suppressNavigable: true,
			}),
			this.gridUtilService.buildCurrencyColumn('Balance', 'balance', {
				width: 100,
				cellRenderer: TemplateCellRendererComponent,
				cellRendererParams: {
					ngTemplate: this.balanceTemplate,
				},
				suppressNavigable: true,
			}),
		];
	}

	/* istanbul ignore next */
	submitForm(event) {
		this.templateForm.onSubmit(event);
	}

	/*istanbul ignore next */
	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
