// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.accounting.CreditPatientResponse */
/* istanbul ignore next */
@GandalfModel
export class CreditPatientResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	amount: number;

	@GandalfProperty()
	comments: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	creditDate: Date;

	@GandalfProperty()
	creditId: number;

	@GandalfProperty()
	invoiceId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.CreditStatus;

}
