// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AppointmentInsuranceVerificationResponse } from './appointment-insurance-verification-response';

// @ts-ignore
import { EncounterTemplateResponse } from './encounter-template-response';

// @ts-ignore
import { PhoneNumbersResponse } from './phone-numbers-response';

// @ts-ignore
import { PracticeLocationSummaryResponse } from './practice-location-summary-response';

// @ts-ignore
import { RecurringDefinitionResponse } from './recurring-definition-response';

// @ts-ignore
import { ScheduleItemSidebarResponse } from './schedule-item-sidebar-response';

/** See com.rev360.pms.api.controller.schedule.scheduleitem.sidebar.AppointmentSidebarResponse */
/* istanbul ignore next */
@GandalfModel
export class AppointmentSidebarResponse extends ScheduleItemSidebarResponse {

	@GandalfProperty()
	actualEndDate: Date;

	@GandalfProperty()
	actualStartDate: Date;

	@GandalfProperty()
	appointmentInsuranceVerificationResponse: AppointmentInsuranceVerificationResponse;

	@GandalfProperty()
	confirmationRequired: boolean;

	@GandalfProperty()
	confirmed: boolean;

	@GandalfProperty()
	createdOn: Date;

	@GandalfProperty()
	description: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	encounterApprovalStatus: constants.EncounterApprovalStatus;

	@GandalfProperty()
	encounterId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	encounterStatus: constants.EncounterStatus;

	@GandalfConstantDecorator()
	@GandalfProperty()
	interviewStatus: constants.InterviewStatus;

	@GandalfProperty()
	locationSummary: PracticeLocationSummaryResponse;

	@GandalfProperty()
	patientEmail: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	patientEstablishedStatus: constants.EstablishedStatus;

	@GandalfConstantDecorator()
	@GandalfProperty()
	patientGender: constants.Gender;

	@GandalfProperty()
	patientId: number;

	@GandalfProperty()
	patientPersonId: number;

	@GandalfProperty()
	patientPhoneNumbers: PhoneNumbersResponse;

	@GandalfProperty()
	patientPhrLogin: boolean;

	@GandalfProperty()
	patientScheduled: boolean;

	@GandalfProperty()
	recurringDefinition: RecurringDefinitionResponse;

	@GandalfProperty()
	relatedAppointmentId: number;

	@GandalfProperty()
	scheduleItemId: number;

	@GandalfProperty()
	subTypeId: number;

	@GandalfProperty()
	summary: string;

	@GandalfProperty()
	template: EncounterTemplateResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	type: constants.ScheduleItemType;

}
