import { Pipe, PipeTransform } from '@angular/core';

/**
 * @ngdoc pipe
 * @description
 * Angular pipe for displaying 'x' before a phone number extension if an extension exists
 *
 * @usage
 * {{ phoneNumber | phoneExtension }}
 */

@Pipe({
	name: 'phoneExtension',
})

export class PhoneExtensionPipe implements PipeTransform {

	transform(value: string): string {
		if (!value) {
			return value;
		} else {
			return 'x' + value;
		}
	}

}
