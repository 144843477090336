import { Injectable } from '@angular/core';
import { ToasterService } from '@core/toaster/toaster.service';
import { HTTP_STATUS_CODE } from '@shared/constants/http-status-code.constants';
import { XHR_READY_STATES } from '@shared/constants/xhr-ready-state.constants';
import { Observable } from 'rxjs';

export const POST = 'POST';

@Injectable({
	providedIn: 'root',
})
export class PhotoFileTransferService {

	constructor(
		private toasterService: ToasterService,
	) {
	}

	/*istanbul ignore next */
	private dataURItoBlob(dataURI: string) {
		// convert base64/URLEncoded data component to raw binary data held in a string
		let byteString;
		if (dataURI.split(',')[0].indexOf('base64') >= 0) {
			byteString = atob(dataURI.split(',')[1]);
		} else {
			return undefined;
		}

		// separate out the mime component
		const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

		// write the bytes of the string to a typed array
		const ia = new Uint8Array(byteString.length);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		return new Blob([ia], {type: mimeString});
	}

	/*istanbul ignore next */
	uploadPhotoFromWebcam(uploadUrl: string, imgData: string, fileName: string) {
		return new Observable(observer => {
			const blob = this.dataURItoBlob(imgData);
			const formData = new FormData();
			formData.append('webcamImage', blob, fileName);

			const xhr = new XMLHttpRequest();
			xhr.onreadystatechange = () => {
				if (xhr.readyState === XHR_READY_STATES.DONE) {
					if (xhr.status === HTTP_STATUS_CODE.SUCCESS.OK) {
						observer.next(xhr.response);
						observer.complete();
						this.toasterService.showSavedSuccess();
					} else {
						observer.error(xhr.response);
						this.toasterService.showError();
					}
				}
			};
			xhr.open(POST, uploadUrl, true);
			xhr.send(formData);
			return () => {
				xhr.abort();
			};
		});


	}
}
