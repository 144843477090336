import { EnvironmentService } from '@core/environment.service';
import { PerformanceService } from '@core/performance/performance.service';
import { InjectorContainerModule } from '../../injector-container.module';

export function LogMethodTime(label: string) {
	return function(target: any, propertyKey: string, descriptor) {
		const originalMethod = descriptor.value;

		descriptor.value = function(...args: any[]) {
			const environmentService = InjectorContainerModule.injector.get(EnvironmentService);
			if (!environmentService.isDevMode()) {
				return originalMethod.apply(this, args);
			}

			const performanceService = InjectorContainerModule.injector.get(PerformanceService);
			const startTime = performance.now();
			const returnVal = originalMethod.apply(this, args);
			performanceService.addMethodTime(label, startTime, performance.now());

			return returnVal;
		};
	};
}
