import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { EmployeeStatus } from '@gandalf/constants';
import { EmployeeNameResponse } from '@gandalf/model/employee-name-response';
import { EmployeeResponse } from '@gandalf/model/employee-response';
import { EmployeeWithSecurityRolesResponse } from '@gandalf/model/employee-with-security-roles-response';
import { _isNil } from '@core/lodash/lodash';

@Pipe({
	name: 'employeeName',
})
@Injectable({
	providedIn: 'root',
})
export class EmployeeNamePipe implements PipeTransform {

	transform(employee: EmployeeResponse | EmployeeWithSecurityRolesResponse | EmployeeNameResponse, shouldHaveInactiveLabel = false): string {
		if (_isNil(employee)) {
			return '';
		}

		let fullName = '';
		if (employee.status === EmployeeStatus.INACTIVE && shouldHaveInactiveLabel) {
			fullName = '(Inactive) ';
		}
		fullName = fullName.concat(employee.lastName, ', ', employee.firstName);

		return fullName;
	}

}
