import { Component, ViewChild } from '@angular/core';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { AppointmentResponse } from '@gandalf/model/appointment-response';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { ScheduleService } from '../../../schedule/schedule-main/schedule.service';

@Component({
	selector: 'pms-no-show-appointment-modal',
	templateUrl: './no-show-appointment-modal.component.html',
	styles: [],
})
export class NoShowAppointmentModalComponent {
	@ViewChild('modal')
	modal: DialogComponent;

	constructor(
		public dynamicModalRef: DynamicModalRef,
		private modalConfig: ModalConfig,
		private scheduleService: ScheduleService,
	) {
	}

	closeModal(data?: AppointmentResponse) {
		this.dynamicModalRef.close(this.modal, data);
	}

	setNoShowAppointment() {
		this.scheduleService.noShowAppointmentLegacy(this.modalConfig.data.appointmentId)
			.subscribe((data) => {
				this.closeModal(data);
			});
	}
}
