import { ChangeDetectorRef, Component, EventEmitter, OnInit, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NgControl } from '@angular/forms';
import { _isNil } from '../../../core/lodash/lodash';
import { PhonePipe } from '../../pipes/phone/phone.pipe';

@Component({
	selector: 'pms-phone-number-input',
	templateUrl: './phone-number-input.component.html',
	providers: [],
})
export class PhoneNumberInputComponent implements OnInit, ControlValueAccessor {

	@Output()
	valueChange = new EventEmitter();

	phoneNumber: string;

	disabled = false;

	parentFormGroup: UntypedFormGroup;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private phonePipe: PhonePipe,
		@Optional() @Self() public ngControl: NgControl,
	) {
		this.ngControl.valueAccessor = this;
	}

	ngOnInit(): void {
		this.parentFormGroup = this.ngControl.control.parent as UntypedFormGroup;
	}

	onModelChange: (_: any) => void = () => {
	};
	onModelTouched: () => void = () => {
	};

	validateAndFormatPhoneNumber() {
		this.formatPhoneNumber();
		this.onModelTouched();
		this.onModelChange(this.phoneNumber);
		this.valueChange.emit();
	}

	formatPhoneNumber(): void {
		if (!_isNil(this.phoneNumber)) {
			const inputDigits = this.phoneNumber.replace(/\D/g, '');
			if (inputDigits.length === 10 && !_isNil(this.ngControl)) {
				this.phoneNumber = this.phonePipe.transform(this.phoneNumber);
			}
		}
	}

	/*istanbul ignore next*/
	writeValue(value?: string): void {
		this.phoneNumber = value;
		this.changeDetectorRef.markForCheck();
	}

	/*istanbul ignore next*/
	registerOnChange(fn: (_: any) => void): void {
		this.onModelChange = fn;
	}

	/*istanbul ignore next*/
	registerOnTouched(fn: any): void {
		this.onModelTouched = fn;
	}

	/*istanbul ignore next*/
	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
