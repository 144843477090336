<div class="form-horizontal" [formGroup]="componentForm">
		<div class="form-group" [formGroup]="getFormGroup('contactInformation.phoneInformation')" [attr.data-test-id]="'familyMemberPreferredPhoneFormGroup'">
			<label class="col-sm-3 control-label">Pref.</label>
			<div class="col-sm-9">
				<pms-enum-select-button
						[enumList]="preferredPhoneTypeOptions"
						formControlName="preferredPhoneType">
				</pms-enum-select-button>
			</div>
		</div>
		<div class="form-group" [formGroup]="getFormGroup('contactInformation.phoneInformation')" [attr.data-test-id]="'homePhoneFormGroup'">
			<label class="col-xs-12 col-sm-3 control-label" [class.required]="isHomePhoneRequired">Home</label>
			<div class="col-xs-7 col-sm-6 col-md-5">
				<pms-phone-number-input formControlName="homePhone"></pms-phone-number-input>
			</div>
			<div class="col-xs-5 col-sm-3 col-md-4">
				<div class="e-input-group">
					<span class="e-input-group-icon">x</span>
					<input class="e-input" type="text" formControlName="homePhoneExtension" />
				</div>
			</div>
		</div>
		<div class="form-group" [formGroup]="getFormGroup('contactInformation.phoneInformation')" [attr.data-test-id]="'workPhoneFormGroup'">
			<label class="col-xs-12 col-sm-3 control-label" [class.required]="isWorkPhoneRequired">Work</label>
			<div class="col-xs-7 col-sm-6 col-md-5">
				<pms-phone-number-input formControlName="workPhone"></pms-phone-number-input>
			</div>
			<div class="col-xs-5 col-sm-3 col-md-4">
				<div class="e-input-group">
					<span class="e-input-group-icon">x</span>
					<input class="e-input" type="text" formControlName="workPhoneExtension" />
				</div>
			</div>
		</div>
		<div class="form-group" [formGroup]="getFormGroup('contactInformation.phoneInformation')" [attr.data-test-id]="'cellPhoneFormGroup'">
			<label class="col-xs-12 col-sm-3 control-label" [class.required]="isCellPhoneRequired">Cell</label>
			<div class="col-xs-7 col-sm-6 col-md-5">
				<pms-phone-number-input formControlName="cellPhone"></pms-phone-number-input>
			</div>
			<div class="col-xs-5 col-sm-3 col-md-4">
				<div class="e-input-group">
					<span class="e-input-group-icon">x</span>
					<input class="e-input" type="text" formControlName="cellPhoneExtension" />
				</div>
			</div>
		</div>
		<div class="form-group" [formGroup]="getFormGroup('contactInformation.phoneInformation')" [attr.data-test-id]="'pagerFormGroup'">
			<label class="col-sm-3 control-label">Pager</label>
			<div class="col-sm-9">
				<pms-phone-number-input formControlName="pager"></pms-phone-number-input>
			</div>
		</div>
		<div class="form-group" [formGroup]="getFormGroup('contactInformation')" [attr.data-test-id]="'emailFormGroup'">
			<label class="col-sm-3 control-label" [class.required]="isEmailRequired && !isEmailDeclined">Email</label>
			<div class="col-sm-9">
				<input class="e-input margin-bottom-sm" type="text" formControlName="email" />
				<ejs-checkbox label="Declined to provide email" formControlName="emailDeclined"></ejs-checkbox>
			</div>
		</div>
</div>
