import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CategoryService } from '@core/category/category.service';
import { _find } from '@core/lodash/lodash';
import { CategoryEntityType } from '@gandalf/constants';
import { CategoryResponse } from '@gandalf/model/category-response';
import { ExcelQueryCellInfoEventArgs, GridComponent } from '@syncfusion/ej2-angular-grids';
import { GandalfConstant } from 'gandalf';
import { YesNoPipe } from 'morgana';

@Component({
	selector: 'pms-category-list',
	templateUrl: './category-list.component.html',
	styles: [],
})
export class CategoryListComponent {

	@Input()
	entityType: CategoryEntityType;

	@Output()
	openEvent = new EventEmitter<CategoryResponse>();

	@ViewChild('grid')
	grid: GridComponent;

	categories: CategoryResponse[] = [];
	isSearching = false;
	dragStarted = false;

	statusFilterOptions = [
		new GandalfConstant(true, 'Active'),
		new GandalfConstant(false, 'Inactive'),
	];
	statusFilter = this.statusFilterOptions[0];

	constructor(
		public categoryService: CategoryService,
		public yesNoPipe: YesNoPipe,
	) {
	}

	getCategoriesList(): void {
		this.isSearching = true;
		this.categoryService.findByEntityTypeForTable(this.entityType).subscribe((data) => {
			this.categories = data;
			this.filterTable();
			this.isSearching = false;
		});
	}

	openCategoryDetails(row: any): void {
		this.openEvent.emit(_find(this.categories, category => category.id === row.id));
	}

	clearSelectedRow() {
		this.grid.clearSelection();
	}

	/* istanbul ignore next*/
	gridCreated() {
		(this.grid as any).toolTipObj.beforeOpen = this.dragSourceTooltip.bind(this);
		this.getCategoriesList();
	}

	rowDropReorder() {
		this.grid.clearSorting();
		setTimeout(() => {
			this.reorderCategories();
		});
	}

	reorderCategories() {
		this.categories.forEach((category: CategoryResponse, index) => {
			category.sortOrder = index;
		});
		this.categoryService.updateCategorySortOrder(this.categories).subscribe((data) => {
			this.categories = data;
		});
	}

	/* istanbul ignore next*/
	dragSourceTooltip(event: any) {
		if (this.dragStarted) {
			event.cancel = true;
		}
	}

	/* istanbul ignore next */
	rowDragStartHelper(event) {
		if (this.statusFilter.value !== null) {
			event.cancel = true;
			return;
		}
		this.dragStarted = true;
	}

	/* istanbul ignore next */
	rowDrag() {
		this.dragStarted = true;
	}

	/* istanbul ignore next */
	rowDrop() {
		this.dragStarted = false;
	}

	exportFunction(cell: ExcelQueryCellInfoEventArgs) {
		if (cell.column.field === 'active') {
			cell.value = this.yesNoPipe.transform(cell.value, 'Active', 'Inactive');
		} else {
			cell.value = cell.value.toString();
		}
		return cell;
	}

	filterTable() {
		if (this.statusFilter?.value !== null) {
			this.grid.filterByColumn('active', 'equal', this.statusFilter.value);
		} else {
			this.grid.clearFiltering(['active']);
		}
	}
}
