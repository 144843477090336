// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CarePlanTemplateResponse } from './care-plan-template-response';

/** See com.rev360.pms.api.controller.practice.PracticeDiagnosisListResponse */
/* istanbul ignore next */
@GandalfModel
export class PracticeDiagnosisListResponse extends GandalfModelBase {

	@GandalfProperty()
	carePlanTemplate: CarePlanTemplateResponse;

	@GandalfProperty()
	code: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	codeSet: constants.CodeSet;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	longDescription: string;

	@GandalfProperty()
	masterCodeSetId: number;

	@GandalfProperty()
	shortDescription: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.PracticeDiagnosisStatus;

}
