// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.schedule.scheduleitem.ScheduleItemPrintResponse */
/* istanbul ignore next */
@GandalfModel
export class ScheduleItemPrintResponse extends GandalfModelBase {

	@GandalfProperty()
	description: string;

	@GandalfProperty()
	encounterTemplateName: string;

	@GandalfArray(String)
	encounterTemplateNames: string[];

	@GandalfProperty()
	encounterTemplateSubCategory: string;

	@GandalfProperty()
	endDate: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	patientDob: Date;

	@GandalfProperty()
	patientName: PatientNameResponse;

	@GandalfProperty()
	patientPreferredPhone: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	patientPreferredPhoneType: constants.PreferredPhoneType;

	@GandalfProperty()
	relatedScheduleItemId: number;

	@GandalfProperty()
	roleName: string;

	@GandalfProperty()
	scheduleItemId: number;

	@GandalfProperty()
	scheduleItemPerson: PersonNameResponse;

	@GandalfProperty()
	scheduleItemPersonCredentials: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	scheduleItemType: constants.ScheduleItemType;

	@GandalfProperty()
	startDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.ScheduleItemStatus;

	@GandalfProperty()
	summary: string;

}
