// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.history.medication.MedicationReviewHistoryRequest */
/* istanbul ignore next */
@GandalfModel
export class MedicationReviewHistoryRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	comments: string;

	@GandalfProperty()
	encounterId: number;

	@GandalfValidator({ notNull: { message: 'No Known Medications is required' } })
	@GandalfProperty({ isRequired: true })
	noKnownMedications: boolean;

	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientId: number;

	@GandalfValidator({ notNull: { message: 'Provider Review Attested is required' } })
	@GandalfProperty({ isRequired: true })
	providerReviewAttested: boolean;

	@GandalfValidator({ notNull: { message: 'Reconciliation Performed is required' } })
	@GandalfProperty({ isRequired: true })
	reconciliationPerformed: boolean;

}
