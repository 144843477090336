import { ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { FileService } from '@core/file/file.service';
import { InsuranceService } from '@core/insurance/insurance.service';
import { _isNil } from '@core/lodash/lodash';
import { DynamicModalRef, ModalConfig, ModalManagerService } from 'morgana';
import { PatientFileEntityType, PersonInsuranceStatus, PolicyHolderRelationship } from '@gandalf/constants';
import { CreatePatientGeneralBenefitsRequest } from '@gandalf/model/create-patient-general-benefits-request';
import { CreatePatientInsuranceBasicInformationRequest } from '@gandalf/model/create-patient-insurance-basic-information-request';
import { CreatePatientMaterialBenefitsRequest } from '@gandalf/model/create-patient-material-benefits-request';
import { CreatePersonInsuranceRequest } from '@gandalf/model/create-person-insurance-request';
import { IntakeInsuranceListResponse } from '@gandalf/model/intake-insurance-list-response';
import { PatientFileInfoResponse } from '@gandalf/model/patient-file-info-response';
import { PersonInsuranceResponse } from '@gandalf/model/person-insurance-response';
import { UpdatePatientGeneralBenefitsSelfPolicyRequest } from '@gandalf/model/update-patient-general-benefits-self-policy-request';
import { UpdatePatientInsuranceBasicInformationSelfPolicyRequest } from '@gandalf/model/update-patient-insurance-basic-information-self-policy-request';
import { UpdatePatientMaterialBenefitsSelfPolicyRequest } from '@gandalf/model/update-patient-material-benefits-self-policy-request';
import { UpdatePersonInsuranceAndPolicyRequest } from '@gandalf/model/update-person-insurance-and-policy-request';
import {
	InsuranceBasicInformationFormComponent
} from '@shared/component/insurance/insurance-details/basic-information/insurance-basic-information-form.component';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { TabAnimationDefaults } from '@shared/constants/tab.constants';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'pms-add-insurance-modal',
	templateUrl: './add-insurance-modal.component.html',
	providers: [ModalManagerService],
})
export class AddInsuranceModalComponent implements OnInit, OnDestroy {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('templateForm')
	templateForm: NgForm;

	@ViewChildren('basicInformationComponent')
	basicInformationComponent: QueryList<InsuranceBasicInformationFormComponent>;

	componentForm: UntypedFormGroup;
	createInsuranceRequest: CreatePersonInsuranceRequest;
	updateInsuranceRequest: UpdatePersonInsuranceAndPolicyRequest;
	personId: number;
	patientId: number;
	canSelectPolicyHolder = true;
	selectedIntakeInsuranceId: number;
	patientFilePatientId: number;
	tabAnimation = TabAnimationDefaults;
	isActive = true;
	personInsuranceId: number;
	showDocsAndImages = true;
	existingPersonInsurance: PersonInsuranceResponse;
	private shouldRefresh = false;
	private unsubscribe$ = new Subject<void>();
	insurancePatientFileEntityType = PatientFileEntityType.INSURANCE_POLICY;
	selectedIntakeInsurance: IntakeInsuranceListResponse;
	showSelectedIntakeInsurance = false;
	dateFormat = DATE_FORMATS.MM_DD_YYYY;

	constructor(
		public modalConfig: ModalConfig,
		private ref: DynamicModalRef,
		private gandalfFormBuilder: GandalfFormBuilder,
		private insuranceService: InsuranceService,
		private cd: ChangeDetectorRef,
		private fileService: FileService,
	) { }

	ngOnInit() {
		this.patientId = this.modalConfig.data.patientId;
		this.personId = this.modalConfig.data.personId;
		this.personInsuranceId = this.modalConfig.data.personInsuranceId;
		this.showDocsAndImages = !!this.modalConfig.data.showDocsAndImages;
		this.canSelectPolicyHolder = !!this.modalConfig.data.canSelectPolicyHolder;
		this.selectedIntakeInsuranceId = this.modalConfig.data.selectedIntakeInsuranceId;
		this.patientFilePatientId = this.modalConfig.data.patientFilePatientId;
		this.createForm();

		if (!_isNil(this.personInsuranceId)) {
			this.retrievePersonInsurance();
		}

		if (this.selectedIntakeInsuranceId) {
			this.updateFormFromIntake();
		}
	}

	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	createForm() {
		if (this.personInsuranceId) {
			this.updateInsuranceRequest = new UpdatePersonInsuranceAndPolicyRequest();
			this.updateInsuranceRequest.patientInsuranceBasicInformationRequest = new UpdatePatientInsuranceBasicInformationSelfPolicyRequest();
			this.updateInsuranceRequest.patientGeneralBenefitsRequest = new UpdatePatientGeneralBenefitsSelfPolicyRequest();
			this.updateInsuranceRequest.patientMaterialBenefitsRequest = new UpdatePatientMaterialBenefitsSelfPolicyRequest();
			this.componentForm = this.gandalfFormBuilder.group(this.updateInsuranceRequest);
		} else {
			this.createInsuranceRequest = new CreatePersonInsuranceRequest();
			this.createInsuranceRequest.personId = this.personId;
			this.createInsuranceRequest.patientInsuranceBasicInformationRequest = new CreatePatientInsuranceBasicInformationRequest();
			this.createInsuranceRequest.patientInsuranceBasicInformationRequest.referralRequired = false;
			this.createInsuranceRequest.patientInsuranceBasicInformationRequest.policyHolderRelationship = PolicyHolderRelationship.SELF;
			this.createInsuranceRequest.patientGeneralBenefitsRequest = new CreatePatientGeneralBenefitsRequest();
			this.createInsuranceRequest.patientMaterialBenefitsRequest = new CreatePatientMaterialBenefitsRequest();
			this.componentForm = this.gandalfFormBuilder.group(this.createInsuranceRequest);
		}
	}

	isUpdating(): boolean {
		return !_isNil(this.personInsuranceId);
	}

	updateForm(personInsurance: PersonInsuranceResponse) {
		this.buildUpdateRequest(personInsurance);

		this.componentForm.patchValue(this.updateInsuranceRequest);

		if (!this.updateBasicInformationComponent(personInsurance)) {
			this.basicInformationComponent.changes.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
				this.updateBasicInformationComponent(personInsurance);
			});
		}

		if (!this.isSelfPolicy()) {
			this.disableForNonSelfPolicy();
		} else {
			const patientInsuranceBasicInfo = this.componentForm.get('patientInsuranceBasicInformationRequest');
			patientInsuranceBasicInfo.get('companyId').disable();
		}
		this.cd.detectChanges();
	}

	buildUpdateRequest(personInsurance: PersonInsuranceResponse) {
		this.updateInsuranceRequest = new UpdatePersonInsuranceAndPolicyRequest();

		this.updateInsuranceRequest.personInsuranceId = personInsurance.personInsuranceId;
		this.updateInsuranceRequest.patientBenefits = personInsurance.patientBenefits;
		this.updateInsuranceRequest.policyHolderBenefits = personInsurance.policyHolderBenefits;

		this.updateInsuranceRequest.patientInsuranceBasicInformationRequest = new UpdatePatientInsuranceBasicInformationSelfPolicyRequest();
		this.updateInsuranceRequest.patientInsuranceBasicInformationRequest.personInsurancePolicyId = personInsurance.policyId;
		this.updateInsuranceRequest.patientInsuranceBasicInformationRequest.insuranceTypeId = personInsurance.insuranceTypeId;
		this.updateInsuranceRequest.patientInsuranceBasicInformationRequest.policyHolderRelationship = personInsurance.policyHolderRelationship;
		this.updateInsuranceRequest.patientInsuranceBasicInformationRequest.policyNumber = personInsurance.policyNumber;
		this.updateInsuranceRequest.patientInsuranceBasicInformationRequest.priorityId = personInsurance.priorityId;
		this.updateInsuranceRequest.patientInsuranceBasicInformationRequest.referralRequired = personInsurance.referralRequired;
		this.updateInsuranceRequest.patientInsuranceBasicInformationRequest.termedDate = personInsurance.termedDate;
		this.updateInsuranceRequest.patientInsuranceBasicInformationRequest.companyId = personInsurance.companyId;
		this.updateInsuranceRequest.patientInsuranceBasicInformationRequest.feeScheduleId = personInsurance.feeScheduleId;
		this.updateInsuranceRequest.patientInsuranceBasicInformationRequest.rxBinNumber = personInsurance.policyRxBinNumber;
		this.updateInsuranceRequest.patientInsuranceBasicInformationRequest.planName = personInsurance.planName;
		this.updateInsuranceRequest.patientInsuranceBasicInformationRequest.rxPcnNumber = personInsurance.policyRxPcnNumber;
		this.updateInsuranceRequest.patientInsuranceBasicInformationRequest.effectiveDate = personInsurance.effectiveDate;
		this.updateInsuranceRequest.patientInsuranceBasicInformationRequest.generalCoPay = personInsurance.policyGeneralCoPay;
		this.updateInsuranceRequest.patientInsuranceBasicInformationRequest.groupNumber = personInsurance.groupNumber;
		this.updateInsuranceRequest.patientInsuranceBasicInformationRequest.materialCoPay = personInsurance.policyMaterialCoPay;
		this.updateInsuranceRequest.patientInsuranceBasicInformationRequest.minFrameAllowance = personInsurance.policyMinFrameAllowance;
		this.updateInsuranceRequest.patientInsuranceBasicInformationRequest.maxFrameAllowance = personInsurance.policyMaxFrameAllowance;
		this.updateInsuranceRequest.patientInsuranceBasicInformationRequest.contactLensAllowance = personInsurance.policyContactLensAllowance;
		this.updateInsuranceRequest.patientInsuranceBasicInformationRequest.contactLensFittingCoPay =
			personInsurance.policyContactLensFittingCoPay;
		this.updateInsuranceRequest.patientInsuranceBasicInformationRequest.medicalExamCoPay = personInsurance.policyMedicalExamCoPay;
		this.updateInsuranceRequest.patientInsuranceBasicInformationRequest.familyDeductible = personInsurance.familyDeductible;
		this.updateInsuranceRequest.patientInsuranceBasicInformationRequest.routineExamCoPay = personInsurance.policyRoutineExamCoPay;
		this.updateInsuranceRequest.patientInsuranceBasicInformationRequest.coInsurancePercentage = personInsurance.coInsurancePercentage;

		this.updateInsuranceRequest.patientGeneralBenefitsRequest = new UpdatePatientGeneralBenefitsSelfPolicyRequest();
		this.updateInsuranceRequest.patientGeneralBenefitsRequest.contactLensFittingBenefitResetDate =
			personInsurance.contactLensFittingBenefitResetDate;
		this.updateInsuranceRequest.patientGeneralBenefitsRequest.contactLensFittingCoPay = personInsurance.contactLensFittingCoPay;
		this.updateInsuranceRequest.patientGeneralBenefitsRequest.deductible = personInsurance.deductible;
		this.updateInsuranceRequest.patientGeneralBenefitsRequest.generalCoPay = personInsurance.generalCoPay;
		this.updateInsuranceRequest.patientGeneralBenefitsRequest.hasContactLensFittingBenefit = personInsurance.hasContactLensFittingBenefit;
		this.updateInsuranceRequest.patientGeneralBenefitsRequest.hasRoutineExamBenefit = personInsurance.hasRoutineExamBenefit;
		this.updateInsuranceRequest.patientGeneralBenefitsRequest.medicalExamCoPay = personInsurance.medicalExamCoPay;
		this.updateInsuranceRequest.patientGeneralBenefitsRequest.routineExamBenefitResetDate = personInsurance.routineExamBenefitResetDate;
		this.updateInsuranceRequest.patientGeneralBenefitsRequest.routineExamCoPay = personInsurance.routineExamCoPay;

		this.updateInsuranceRequest.patientMaterialBenefitsRequest = new UpdatePatientMaterialBenefitsSelfPolicyRequest();
		this.updateInsuranceRequest.patientMaterialBenefitsRequest.materialCoPay = personInsurance.materialCoPay;
		this.updateInsuranceRequest.patientMaterialBenefitsRequest.authorizationDate = personInsurance.authorizationDate;
		this.updateInsuranceRequest.patientMaterialBenefitsRequest.lensBenefitResetDate = personInsurance.lensBenefitResetDate;
		this.updateInsuranceRequest.patientMaterialBenefitsRequest.hasLensBenefit = personInsurance.hasLensBenefit;
		this.updateInsuranceRequest.patientMaterialBenefitsRequest.frameBenefitResetDate = personInsurance.frameBenefitResetDate;
		this.updateInsuranceRequest.patientMaterialBenefitsRequest.maxFrameAllowance = personInsurance.maxFrameAllowance;
		this.updateInsuranceRequest.patientMaterialBenefitsRequest.contactLensAllowance = personInsurance.contactLensAllowance;
		this.updateInsuranceRequest.patientMaterialBenefitsRequest.minFrameAllowance = personInsurance.minFrameAllowance;
		this.updateInsuranceRequest.patientMaterialBenefitsRequest.hasFrameBenefit = personInsurance.hasFrameBenefit;
		this.updateInsuranceRequest.patientMaterialBenefitsRequest.contactLensBenefitResetDate = personInsurance.contactLensBenefitResetDate;
		this.updateInsuranceRequest.patientMaterialBenefitsRequest.authorizationNumber = personInsurance.authorizationNumber;
		this.updateInsuranceRequest.patientMaterialBenefitsRequest.hasContactLensBenefit = personInsurance.hasContactLensBenefit;
	}

	private enableForSelfPolicy() {
		this.componentForm.get('policyHolderBenefits').enable();
	}

	private disableForNonSelfPolicy() {
		const patientInsuranceBasicInfo = this.componentForm.get('patientInsuranceBasicInformationRequest');
		patientInsuranceBasicInfo.get('companyId').disable();
		patientInsuranceBasicInfo.get('planName').disable();
		patientInsuranceBasicInfo.get('groupNumber').disable();
		patientInsuranceBasicInfo.get('feeScheduleId').disable();
		patientInsuranceBasicInfo.get('planName').disable();
		patientInsuranceBasicInfo.get('effectiveDate').disable();
		patientInsuranceBasicInfo.get('termedDate').disable();
		patientInsuranceBasicInfo.get('familyDeductible').disable();
		patientInsuranceBasicInfo.get('generalCoPay').disable();
		patientInsuranceBasicInfo.get('medicalExamCoPay').disable();
		patientInsuranceBasicInfo.get('routineExamCoPay').disable();
		patientInsuranceBasicInfo.get('contactLensFittingCoPay').disable();
		patientInsuranceBasicInfo.get('coInsurancePercentage').disable();
		patientInsuranceBasicInfo.get('materialCoPay').disable();
		patientInsuranceBasicInfo.get('minFrameAllowance').disable();
		patientInsuranceBasicInfo.get('maxFrameAllowance').disable();
		patientInsuranceBasicInfo.get('contactLensAllowance').disable();
		patientInsuranceBasicInfo.get('rxBinNumber').disable();
		patientInsuranceBasicInfo.get('rxPcnNumber').disable();
		this.componentForm.get('policyHolderBenefits').disable();
	}

	private updateBasicInformationComponent(personInsurance: PersonInsuranceResponse) {
		const currentInformationComponent = this.basicInformationComponent.first;
		if (!_isNil(currentInformationComponent)) {
			currentInformationComponent.refreshDropdowns();
			if (!this.isSelfPolicy()) {
				currentInformationComponent.setNonSelfPolicy(personInsurance);
				currentInformationComponent.disablePolicyHolderChanges = true;
			} else {
				currentInformationComponent.setSelfPolicy();
				currentInformationComponent.setPersonInfo(personInsurance);
			}

			this.cd.detectChanges();
			return true;
		}
		return false;
	}

	isSelfPolicy() {
		const relationshipControl = this.componentForm.get('patientInsuranceBasicInformationRequest.policyHolderRelationship');
		return relationshipControl && relationshipControl.value === PolicyHolderRelationship.SELF;
	}

	wasSelfPolicy() {
		return this.existingPersonInsurance.policyHolderPersonId === this.existingPersonInsurance.personId;
	}

	copyPolicyBenefitsToPatientBenefits() {
		this.componentForm.patchValue({
			patientBenefits: this.componentForm.get('policyHolderBenefits').value,
		});
	}

	closeModal(refresh = false) {
		this.ref.close(this.modal, this.shouldRefresh || refresh);
	}

	copyBasicInformationToGeneralSubrequest() {
		const basicSubrequest = this.componentForm.get('patientInsuranceBasicInformationRequest');
		const generalSubrequest = this.componentForm.get('patientGeneralBenefitsRequest');

		generalSubrequest.patchValue({
			generalCoPay : basicSubrequest.get('generalCoPay').value,
			medicalExamCoPay : basicSubrequest.get('medicalExamCoPay').value,
			routineExamCoPay : basicSubrequest.get('routineExamCoPay').value,
			contactLensFittingCoPay : basicSubrequest.get('contactLensFittingCoPay').value,
		});
	}

	copyBasicInformationToMaterialSubrequest() {
		const basicSubrequest = this.componentForm.get('patientInsuranceBasicInformationRequest');
		const materialSubrequest = this.componentForm.get('patientMaterialBenefitsRequest');

		materialSubrequest.patchValue({
			materialCoPay : basicSubrequest.get('materialCoPay').value,
			minFrameAllowance : basicSubrequest.get('minFrameAllowance').value,
			maxFrameAllowance : basicSubrequest.get('maxFrameAllowance').value,
			contactLensAllowance : basicSubrequest.get('contactLensAllowance').value,
		});
	}

	save() {
		if (this.componentForm.invalid) {
			return;
		}
		this.shouldRefresh = true;

		if (this.existingPersonInsurance) {
			if (this.wasSelfPolicy()) {
				this.insuranceService.updatePersonInsuranceAndPolicy(this.componentForm.getRawValue())
					.subscribe((result) => {
						this.prepareEditPersonInsurance(result);
					});
			} else {
				this.insuranceService.updatePersonInsurance(this.componentForm.getRawValue())
					.subscribe((result) => {
						this.prepareEditPersonInsurance(result);
					});
			}
		} else {
			this.insuranceService.createPersonInsurance(this.componentForm.getRawValue()).subscribe((result) => {
				this.templateForm.resetForm(this.componentForm.getRawValue());
				this.personInsuranceId = result.personInsuranceId;
				this.createForm();
				this.retrievePersonInsurance();
				this.showSelectedIntakeInsurance = false;
			});
		}
	}

	/* istanbul ignore next */
	submitForm(event) {
		this.templateForm.onSubmit(event);
	}

	activatePersonInsurance() {
		this.insuranceService.activatePersonInsurance(this.personInsuranceId).subscribe(() => {
			this.isActive = true;
			this.shouldRefresh = true;
		});
	}

	deactivatePersonInsurance() {
		this.insuranceService.deactivatePersonInsurance(this.personInsuranceId).subscribe(() => {
			this.isActive = false;
			this.shouldRefresh = true;
		});
	}

	retrievePersonInsurance() {
		this.insuranceService.getPersonInsuranceById(this.personInsuranceId).subscribe((personInsurance) => {
			this.prepareEditPersonInsurance(personInsurance);
		});
	}

	private prepareEditPersonInsurance(personInsurance: PersonInsuranceResponse) {
		this.personInsuranceId = personInsurance.personInsuranceId;
		this.existingPersonInsurance = personInsurance;
		this.isActive = personInsurance.status === PersonInsuranceStatus.ACTIVE;
		this.updateForm(personInsurance);
	}

	policyHolderIsSelf(isSelf: boolean) {
		if (isSelf) {
			this.enableForSelfPolicy();
		} else {
			this.disableForNonSelfPolicy();
		}
	}

	onNonPolicyHolderPersonInsuranceChange(personInsuranceResponse: PersonInsuranceResponse) {
		this.componentForm.get('policyHolderBenefits').setValue(personInsuranceResponse.policyHolderBenefits);
	}

	updateFormFromIntake() {
		this.insuranceService.getIntakeInsuranceByIdWithPatientFile(this.selectedIntakeInsuranceId).subscribe((intakeInsurance) => {
			this.selectedIntakeInsurance = intakeInsurance;
			const patientInsuranceBasicInfo = this.componentForm.get('patientInsuranceBasicInformationRequest');
			patientInsuranceBasicInfo.get('policyNumber').setValue(intakeInsurance.policyNumber);
			patientInsuranceBasicInfo.get('companyId').setValue(intakeInsurance.practiceInsuranceCompanyId);
			this.showSelectedIntakeInsurance = true;
		},
		);
	}

	viewInsuranceFile(file: PatientFileInfoResponse) {
		this.fileService.viewOrDownloadPatientFile(this.patientFilePatientId, file);
	}
}
