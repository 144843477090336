<pms-eyeglass-rx
	[eyeglassPrescription]="eyeglassPrescription"
	[showViewRxHistoryButton]="showViewRxHistoryButton">
</pms-eyeglass-rx>
<div class="row">
	<div class="col-md-6">
		<pms-eyeglass-rx-other-information
			[eyeglassPrescription]="eyeglassPrescription"></pms-eyeglass-rx-other-information>
	</div>
	<div class="col-md-6">
		<pms-eyeglass-rx-recommendations
			[eyeglassPrescription]="eyeglassPrescription"></pms-eyeglass-rx-recommendations>
	</div>
	<div class="col-md-6">
		<pms-eyeglass-rx-authorization
			[eyeglassPrescription]="eyeglassPrescription"></pms-eyeglass-rx-authorization>
	</div>
	<div class="col-md-6">
		<pms-eyeglass-rx-dates
			[eyeglassPrescription]="eyeglassPrescription"></pms-eyeglass-rx-dates>
	</div>
</div>
