import { PropertiesStateMap } from '@app-store/reducers/properties.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectPropertiesState = createFeatureSelector<PropertiesStateMap>('properties');

export const selectComponentPropertiesState = (props: {key: string}) =>
	createSelector(selectPropertiesState, (state: PropertiesStateMap) => state.get(props.key));

export const selectComponentPropertyState = (props: {key: string, propertyKey: string}) =>
	createSelector(selectPropertiesState, (state: PropertiesStateMap) => (state.get(props.key) || {})[props.propertyKey]);
