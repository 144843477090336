// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { EmployeeNameResponse } from './employee-name-response';

// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

// @ts-ignore
import { ScheduleItemCalendarResponse } from './schedule-item-calendar-response';

/** See com.rev360.pms.api.controller.schedule.scheduleitem.calendar.AppointmentCalendarResponse */
/* istanbul ignore next */
@GandalfModel
export class AppointmentCalendarResponse extends ScheduleItemCalendarResponse {

	@GandalfProperty()
	confirmed: boolean;

	@GandalfProperty()
	displayColor: number;

	@GandalfProperty()
	employee: EmployeeNameResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	encounterApprovalStatus: constants.EncounterApprovalStatus;

	@GandalfConstantDecorator()
	@GandalfProperty()
	encounterStatus: constants.EncounterStatus;

	@GandalfProperty()
	endDate: Date;

	@GandalfProperty()
	groupingPersonId: number;

	@GandalfProperty()
	hasInterviewTemplate: boolean;

	@GandalfProperty()
	insuranceVerificationComment: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	insuranceVerificationStatus: constants.VerificationStatus;

	@GandalfConstantDecorator()
	@GandalfProperty()
	interviewStatus: constants.InterviewStatus;

	@GandalfProperty()
	locationId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	patientDob: Date;

	@GandalfProperty()
	patientName: PatientNameResponse;

	@GandalfProperty()
	provider: ProviderResponse;

	@GandalfProperty()
	roleId: number;

	@GandalfProperty()
	roleName: string;

	@GandalfProperty()
	scheduleItemId: number;

	@GandalfProperty()
	startDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.ScheduleItemStatus;

	@GandalfProperty()
	subType: string;

	@GandalfProperty()
	template: string;

	@GandalfProperty()
	templateDisplayColor: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	type: constants.ScheduleItemType;

	@GandalfProperty()
	updatedOn: Date;

}
