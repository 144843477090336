// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.healthconcern.PatientHealthConcernRequest */
/* istanbul ignore next */
@GandalfModel
export class PatientHealthConcernRequest extends GandalfModelBase {

	@GandalfLabel('Concern')
	@GandalfValidator({ notNull: { message: 'Concern is required' } })
	@GandalfValidator({ sizeString: { message: 'Concern must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ isRequired: true })
	concern: string;

	@GandalfLabel('Date')
	@GandalfValidator({ notNull: { message: 'Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	dateReported: Date;

	@GandalfLabel('Inactive Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	inactiveDate: Date;

	@GandalfConstantDecorator()
	@GandalfLabel('Reporter')
	@GandalfProperty()
	reporter: constants.PatientHealthConcernReporter;

	@GandalfConstantDecorator()
	@GandalfLabel('Type')
	@GandalfValidator({ notNull: { message: 'Type is required' } })
	@GandalfProperty({ isRequired: true })
	type: constants.PatientHealthConcernType;

}
