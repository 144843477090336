import { Component, OnInit, ViewChild } from '@angular/core';
import { EncounterService } from '@core/encounter/encounter.service';
import { DynamicModalRef, ModalConfig, ModalManagerService } from 'morgana';
import { EncounterResponse } from '@gandalf/model/encounter-response';
import { TABLE_DATE_FORMATS } from '@shared/constants/date-format.constants';
import { RowSelectEventArgs, SortSettingsModel } from '@syncfusion/ej2-angular-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
	selector: 'pms-encounter-select-modal',
	templateUrl: './encounter-select-by-patient-modal.component.html',
	styles: [],
})
export class EncounterSelectByPatientModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;
	patientId: number;
	encountersData: EncounterResponse[];
	sortOptions: SortSettingsModel;
	encounterDateFormatter = TABLE_DATE_FORMATS.MM_DD_YYYY;
	isSearching = false;

	constructor(
		public ref: DynamicModalRef,
		public modalManagerService: ModalManagerService,
		public modalConfig: ModalConfig,
		public encounterService: EncounterService,
	) { }

	ngOnInit() {
		this.patientId = this.modalConfig.data.patientId;
		this.sortOptions = {
			columns: [
				{field: 'encounterDate', direction: 'Descending'},
			],
		};
		this.encountersData = [];
	}

	search() {
		this.isSearching = true;
		this.encounterService.findEncountersByPatientId(this.patientId).subscribe((data) => {
			this.isSearching = false;
			this.encountersData = data;
		});
	}

	closeModal(selectEvent?: RowSelectEventArgs) {
		const returnObject = selectEvent ? {
			skipped: false,
			encounter: selectEvent.data as EncounterResponse,
		} : {
			skipped: true,
		};

		this.ref.close(this.modal, returnObject);
	}

	cancelModal() {
		this.ref.close(this.modal);
	}
}
