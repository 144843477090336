import { Injectable } from '@angular/core';
import {
	RemoveGridState,
	UpdateGridColumnsState,
	UpdateGridFilterState,
	UpdateGridPaginationState,
	UpdateGridRowHighlightedState,
	UpdateGridSelectionState
} from '@app-store/actions/grids.actions';
import { State } from '@app-store/reducers';
import { GridState, PaginationState } from '@app-store/reducers/grid.reducer';
import { selectGridState } from '@app-store/selectors/grid.selectors';
import { StatefulServiceBase } from '@app-store/services/stateful-service-base';
import { Store } from '@ngrx/store';
import { ColumnState } from 'ag-grid-community';
import { Observable } from 'rxjs';

@Injectable()
export class StatefulGridService extends StatefulServiceBase {

	constructor(
		private store: Store<State>,
	) {
		super();
	}

	updateGridColumnsState(columnsState: ColumnState[]) {
		this.store.dispatch(new UpdateGridColumnsState({key: this.storeKey, columnsState}));
	}

	updateGridFilterState(filterState: any) {
		this.store.dispatch(new UpdateGridFilterState({key: this.storeKey, filterState}));
	}

	updateGridPaginationState(currentPage: number, pageSize: number) {
		this.store.dispatch(new UpdateGridPaginationState({
			key: this.storeKey,
			paginationState: new PaginationState(currentPage, pageSize),
		}));
	}

	updateGridSelectionState(selectionState: any[]) {
		this.store.dispatch(new UpdateGridSelectionState({key: this.storeKey, selectionState}));
	}

	updateHighlightedRowState(rowIndex: number) {
		this.store.dispatch(new UpdateGridRowHighlightedState({key: this.storeKey, highlightedRowIndex: rowIndex}));
	}

	selectGridState(): Observable<GridState> {
		return this.store.select(selectGridState({key: this.storeKey}));
	}

	removeGridState() {
		this.store.dispatch(new RemoveGridState({key: this.storeKey}));
	}
}
