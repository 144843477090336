// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.pod.PatientPodContactLensEyeResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientPodContactLensEyeResponse extends GandalfModelBase {

	@GandalfProperty()
	addDesignation: string;

	@GandalfProperty()
	addPower: number;

	@GandalfProperty()
	axis: number;

	@GandalfProperty()
	baseCurve: number;

	@GandalfProperty()
	cylinder: number;

	@GandalfProperty()
	diameter: number;

	@GandalfProperty()
	lensName: string;

	@GandalfProperty()
	material: string;

	@GandalfProperty()
	monovision: boolean;

	@GandalfProperty()
	sphere: number;

}
