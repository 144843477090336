/* eslint-disable @typescript-eslint/naming-convention */

import { PayerType } from '@gandalf/constants';
import { Action } from '@ngrx/store';
import { ReceivePaymentState } from '@payments-store/reducers/payment-tab.reducer';
import { ReceivePaymentsPayer } from '@accounting/invoices/receive-payments/receive-payments.service';

export enum InvoiceTabActionTypes {
	ADD_INVOICE_TAB = '[Add Invoice Tab] Add Invoice Tab to the Invoices Tab Collection',
	REMOVE_INVOICE_TAB = '[Remove Invoice Tab] Remove Invoice Tab from the Invoices Tab Collection',
	SYNC_INVOICE_TAB = '[Sync Invoice Tab] Sync the Invoice Tab Header and the Invoice Tab Content',
	UPDATE_INVOICE_TAB_CURRENT_ROUTE = '[Update Invoice Tab Current Route] Update the Invoice Tabs Current Route',

	ADD_PAYMENT_TAB = '[Add Invoice Payment Tab] Add Payment Tab to the Payments Tab Collection',
	REMOVE_PAYMENT_TAB = '[Remove Invoice Payment Tab] Remove Payment Tab from the Payments Tab Collection',
	SYNC_PAYMENT_TAB = '[Sync Invoice Payment Tab] Sync the Payment Tab Header and the Payment Tab Content',
	UPDATE_PAYMENT_TAB_CURRENT_ROUTE = '[Update Invoice Payment Tab Current Route] Update the Payment Tabs Current Route',
	UPDATE_PAYMENT_TAB_PAYER_SELECTION_STATE = '[Update Invoice Payment Tab Payer Selection] Update the Payment Tabs payer selection current state',
}

export class AddInvoiceTab implements Action {
	readonly type = InvoiceTabActionTypes.ADD_INVOICE_TAB;
	constructor(public payload: { invoiceId: number }) {}
}

export class RemoveInvoiceTab implements Action {
	readonly type = InvoiceTabActionTypes.REMOVE_INVOICE_TAB;
	constructor(public payload: { invoiceId: number }) {}
}

export class SyncInvoiceTab implements Action {
	readonly type = InvoiceTabActionTypes.SYNC_INVOICE_TAB;
	constructor(public payload: { invoiceId: number }) {}
}

export class UpdateInvoiceTabCurrentRoute implements Action {
	readonly type = InvoiceTabActionTypes.UPDATE_INVOICE_TAB_CURRENT_ROUTE;
	constructor(public payload: { invoiceId: number, currentRoute: string }) {}
}


export class AddInvoicePaymentTab implements Action {
	readonly type = InvoiceTabActionTypes.ADD_PAYMENT_TAB;
	constructor(public payload: { paymentId: number, payerType?: PayerType, locationId?: number }) {}
}

export class RemoveInvoicePaymentTab implements Action {
	readonly type = InvoiceTabActionTypes.REMOVE_PAYMENT_TAB;
	constructor(public payload: { paymentId: number }) {}
}

export class SyncInvoicePaymentTab implements Action {
	readonly type = InvoiceTabActionTypes.SYNC_PAYMENT_TAB;
	constructor(public payload: { paymentId: number }) {}
}

export class UpdateInvoicePaymentTabCurrentRoute implements Action {
	readonly type = InvoiceTabActionTypes.UPDATE_PAYMENT_TAB_CURRENT_ROUTE;
	constructor(public payload: { paymentId: number, currentRoute: string }) {}
}

export class UpdateInvoicePaymentTabPayerSelectionState implements Action {
	readonly type = InvoiceTabActionTypes.UPDATE_PAYMENT_TAB_PAYER_SELECTION_STATE;
	constructor(public payload: {
		paymentId: number;
		payerType: PayerType;
		nameFilter: string;
		manuallySelect: boolean;
		payer?: ReceivePaymentsPayer;
		receivePaymentState?: ReceivePaymentState;
	}) {
	}
}

export type InvoiceTabActions =
	AddInvoiceTab
	| RemoveInvoiceTab
	| SyncInvoiceTab
	| UpdateInvoiceTabCurrentRoute
	| AddInvoicePaymentTab
	| RemoveInvoicePaymentTab
	| SyncInvoicePaymentTab
	| UpdateInvoicePaymentTabCurrentRoute
	| UpdateInvoicePaymentTabPayerSelectionState;
