<div class="margin-bottom-sm overflow-hidden">
	@if (canAddFolder) {
		<button ejs-button
				class="e-success"
				type="button"
				iconCss="fa fa-plus "
				(click)="addFolderToSelectedFolder()"
				[disabled]="!selectedFolder"
				[attr.data-test-id]="'documentsImageAddFolderButton'">
			Add Folder
		</button>
	}
	@if (expanded) {
		<button ejs-button cssClass="margin-left-sm pull-right" iconCss="fa fa-compress" [attr.data-test-id]="'documentsImageCollapseFoldersButton'" revTooltip
				tooltipContent="Collapse" (click)="collapseAll()">
		</button>
	}
	@if (!expanded) {
		<button ejs-button cssClass="margin-left-sm pull-right" iconCss="fa fa-expand" [attr.data-test-id]="'documentsImageExpandFoldersButton'" revTooltip
				tooltipContent="Expand" (click)="expandAll()">
		</button>
	}
</div>
<div class="panel panel-default" id="PatientDirectoryTreeViewComponent">
	@if (!hideTree) {
		<ejs-treeview #treeViewComponent
					  (nodeSelected)="nodeSelected($event.nodeData)"
					  [fields]="fieldSettingsModel">
		</ejs-treeview>
	}
</div>
<ejs-contextmenu #menu (beforeOpen)="disableContextMenuItems(menu)" target='#PatientDirectoryTreeViewComponent' [items]='menuItems'
				 (select)="menuItemSelected($event)"
				 [attr.data-test-id]="'documentsImageFolderContextMenu'"></ejs-contextmenu>
