// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeHybridResponse } from './contact-lens-eye-hybrid-response';

// @ts-ignore
import { ContactLensEyeRgpResponse } from './contact-lens-eye-rgp-response';

// @ts-ignore
import { ContactLensEyeSoftResponse } from './contact-lens-eye-soft-response';

// @ts-ignore
import { ProductForPrescriptionResponse } from './product-for-prescription-response';

/** See com.rev360.pms.api.controller.prescription.ContactLensTrialResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensTrialResponse extends GandalfModelBase {

	@GandalfProperty()
	commentsOd: string;

	@GandalfProperty()
	commentsOs: string;

	@GandalfProperty()
	contactLensEyeHybridOd: ContactLensEyeHybridResponse;

	@GandalfProperty()
	contactLensEyeHybridOs: ContactLensEyeHybridResponse;

	@GandalfProperty()
	contactLensEyeRgpOd: ContactLensEyeRgpResponse;

	@GandalfProperty()
	contactLensEyeRgpOs: ContactLensEyeRgpResponse;

	@GandalfProperty()
	contactLensEyeSoftOd: ContactLensEyeSoftResponse;

	@GandalfProperty()
	contactLensEyeSoftOs: ContactLensEyeSoftResponse;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	isDispensedOd: boolean;

	@GandalfProperty()
	isDispensedOs: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	overallLensType: constants.ContactLensPrescriptionType;

	@GandalfProperty()
	productOd: ProductForPrescriptionResponse;

	@GandalfProperty()
	productOs: ProductForPrescriptionResponse;

}
