import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
	selector: '[revDynamicModalContent]',
})
export class DynamicModalContentDirective {

	constructor(
		public viewContainerRef: ViewContainerRef,
	) {
	}

}
