// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.accounting.feeschedule.AssignFeeScheduleItemRequest */
/* istanbul ignore next */
@GandalfModel
export class AssignFeeScheduleItemRequest extends GandalfModelBase {

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'FeeScheduleItemType is required' } })
	@GandalfProperty({ isRequired: true })
	feeScheduleItemType: constants.FeeScheduleItemType;

	@GandalfProperty()
	feeScheduleRangeId: number;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	isOtherService: boolean;

	@GandalfProperty()
	locationProductId: number;

	@GandalfProperty({ propertyType: 'Money' })
	maximumAllowable: number;

	@GandalfProperty()
	serviceId: number;

}
