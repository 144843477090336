import { Component } from '@angular/core';

@Component({
	selector: 'pms-app-wrapper',
	templateUrl: './app-wrapper.component.html',
})
export class AppWrapperComponent {

	constructor() { }

}
