import { AfterViewInit, Directive, HostListener, OnDestroy } from '@angular/core';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { _isNil } from '../../utils/lodash/lodash';

@Directive({
	/* eslint-disable-next-line @angular-eslint/directive-selector */
	selector: 'ejs-dropdownlist:not([pmsOverrideDropdownlistClear])',
})
export class DropdownlistClearDirective implements AfterViewInit, OnDestroy {

	unsubscribe: Subject<void> = new Subject<void>();

	constructor(
		private dropdownListComponent: DropDownListComponent,
	) {
	}

	ngAfterViewInit(): void {
		this.observeValueChange();
	}

	ngOnDestroy(): void {
		this.closeSubscriptions();
	}

	@HostListener('created')
	onCreated() {
		if (this.shouldDisplayClearIcon(this.dropdownListComponent.value)) {
			this.showClearIcon();
		}
	}

	@HostListener('blur')
	onBlur() {
		this.conditionallyDisplayClearIcon(this.dropdownListComponent.value);
	}

	shouldDisplayClearIcon(dropdownListValue: any) {
		return !_isNil(dropdownListValue) && this.dropdownListComponent.showClearButton;
	}

	showClearIcon() {
		((this.dropdownListComponent as any).inputWrapper.clearButton as HTMLElement)?.classList.add('e-clear-icon-show');
	}

	hideClearIcon() {
		((this.dropdownListComponent as any).inputWrapper.clearButton as HTMLElement)?.classList.remove('e-clear-icon-show');
	}

	/* istanbul ignore next */
	observeValueChange() {
		this.dropdownListComponent.valueChange
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(value => this.conditionallyDisplayClearIcon(value));
	}

	conditionallyDisplayClearIcon(newValue) {
		if (this.shouldDisplayClearIcon(newValue)) {
			this.showClearIcon();
		} else {
			this.hideClearIcon();
		}
	}

	/* istanbul ignore next */
	closeSubscriptions() {
		if (!this.unsubscribe.closed) {
			this.unsubscribe.next();
			this.unsubscribe.complete();
		}
	}
}
