import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { DynamicModalRef, ModalManagerService } from 'morgana';
import { PatientService } from '@core/patient/patient.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { CreatePatientBasicInformationRequest } from '@gandalf/model/create-patient-basic-information-request';
import { CreatePatientCommunicationPreferencesRequest } from '@gandalf/model/create-patient-communication-preferences-request';
import { CreatePatientOtherInformationRequest } from '@gandalf/model/create-patient-other-information-request';
import { CreatePatientPhoneInformationRequest } from '@gandalf/model/create-patient-phone-information-request';
import { PatientDuplicateSearchRequest } from '@gandalf/model/patient-duplicate-search-request';
import { PatientDuplicateSearchResponse } from '@gandalf/model/patient-duplicate-search-response';
import { PatientNameResponse } from '@gandalf/model/patient-name-response';
import { QuickAddPatientRequest } from '@gandalf/model/quick-add-patient-request';
import { DuplicatePatientsModalComponent } from '@shared/component/add-patient/duplicate-patients-modal/duplicate-patients-modal.component';
import { FormattedNamePipe } from '@shared/pipes/formatted-name/formatted-name.pipe';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';

export interface FormattedQuickAddPatient extends PatientNameResponse {
	formattedName: string;
}

@Component({
	selector: 'pms-add-patient',
	templateUrl: './quick-add-patient-modal.component.html',
	styles: [],
	providers: [ModalManagerService],
})
export class QuickAddPatientModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('templateForm')
	templateForm: NgForm;

	componentForm: UntypedFormGroup;
	quickAddRequest: QuickAddPatientRequest;

	constructor(
		private ref: DynamicModalRef,
		private gandalfFormBuilder: GandalfFormBuilder,
		private securityManagerService: SecurityManagerService,
		private patientService: PatientService,
		private formattedNamePipe: FormattedNamePipe,
		private modalManagerService: ModalManagerService,
	) {
	}

	ngOnInit() {
		this.createForm();
	}

	createForm() {
		this.quickAddRequest = new QuickAddPatientRequest();
		this.quickAddRequest.address = this.patientService.getEmptyAddressRequest();
		this.quickAddRequest.patientBasicInformation = new CreatePatientBasicInformationRequest();
		this.quickAddRequest.patientOtherInformation = new CreatePatientOtherInformationRequest();
		this.quickAddRequest.patientPhoneInformation = new CreatePatientPhoneInformationRequest();
		this.quickAddRequest.patientCommunicationPreferences = new CreatePatientCommunicationPreferencesRequest();

		this.componentForm = this.gandalfFormBuilder.group(this.quickAddRequest);

		this.patientService.setRequiredValidatorPostalCode(this.componentForm.get('address') as UntypedFormGroup);
	}

	submitForm(event: any) {
		this.templateForm.onSubmit(event);
	}

	save() {
		if (this.componentForm.invalid) {
			return;
		}
		const patientQuickAddRequest = this.componentForm.value as QuickAddPatientRequest;
		const patientDuplicateSearchRequest = new PatientDuplicateSearchRequest();
		patientDuplicateSearchRequest.firstName = patientQuickAddRequest.patientBasicInformation.firstName;
		patientDuplicateSearchRequest.lastName = patientQuickAddRequest.patientBasicInformation.lastName;
		patientDuplicateSearchRequest.dateOfBirth = patientQuickAddRequest.patientOtherInformation.dob;
		if (this.securityManagerService.isCanada()) {
			patientDuplicateSearchRequest.sin = patientQuickAddRequest.patientOtherInformation.sin;
		} else {
			patientDuplicateSearchRequest.socialSecurityNumber = patientQuickAddRequest.patientOtherInformation.ssn;
			patientDuplicateSearchRequest.last4Ssn = patientQuickAddRequest.patientOtherInformation.last4Ssn;
		}

		this.patientService.findPatientDuplicates(patientDuplicateSearchRequest).subscribe(patientDuplicates => {
			if (patientDuplicates && patientDuplicates.length !== 0) {
				this.showDuplicateResultsModal(patientDuplicates);
			} else {
				this.createPatient();
			}
		});
	}

	showDuplicateResultsModal(duplicates: PatientDuplicateSearchResponse[]) {
		this.modalManagerService.open(DuplicatePatientsModalComponent, {
			data: {
				duplicatePatients: duplicates,
			},
		}).onClose.subscribe(shouldContinue => {
			if (shouldContinue) {
				this.createPatient();
			}
		});
	}

	createPatient() {
		this.patientService.quickAddPatient(this.componentForm.value).subscribe(patient => {
			const formattedPatient = patient as FormattedQuickAddPatient;
			formattedPatient.formattedName = this.formattedNamePipe.transform(
				patient.firstName,
				patient.lastName,
			);

			this.closeModal(formattedPatient);
		});
	}

	/* istanbul ignore next */
	closeModal(result?: FormattedQuickAddPatient) {
		this.ref.close(this.modal, result);
	}
}
