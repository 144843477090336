<ejs-dialog #modal (close)="closeModal()" [attr.data-test-id]="'resolveDiagnosisModal'">
	<ng-template #header>
		<div class="dlg-template">Resolve Diagnosis</div>
	</ng-template>

	<ng-template #content>
		<form #resolveDiagnosisForm="ngForm" class="form-horizontal" [formGroup]="componentForm" (ngSubmit)="save()">
			@if (componentForm.invalid && resolveDiagnosisForm.submitted) {
				<gandalf-lib-validation-messages
					[form]="componentForm"
					[requestObj]="request"
					[attr.data-test-id]="'resolveDiagnosisModalValidationMessages'">
				</gandalf-lib-validation-messages>
			}
			<div class="form-group" [attr.data-test-id]="'resolveDiagnosisCodeLabelGroup'">
				<label class="col-sm-4 control-label required">Code</label>
				<div class="col-sm-8">
					<p class="form-control-static">
						<pms-diagnosis-code [practiceDiagnosis]="personDiagnosis?.practiceDiagnosis"></pms-diagnosis-code>
					</p>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'resolveDiagnosisDateResolvedLabelGroup'">
				<label for="dateResolved" class="col-sm-4 control-label required">Date Resolved</label>
				<div class="col-sm-8">
					<ejs-datepicker id="dateResolved"
									placeholder="mm/dd/yyyy"
									formControlName="dateResolved"
									[format]="dateFormat">
					</ejs-datepicker>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'resolveDiagnosisCommentsLabelGroup'">
				<label for="comment" class="col-sm-4 control-label required">Comments</label>
				<div class="col-sm-8">
					<ejs-textbox id="comment" formControlName="comment" class="h-rows-5"
								 [revFocusInput]="true"
								 [multiline]="true">
					</ejs-textbox>
				</div>
			</div>
		</form>
	</ng-template>

	<ng-template #footerTemplate>
		<button ejs-button type="button" iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'resolveDiagnosisCancelButton'">Cancel</button>
		<button ejs-button type="button" [isPrimary]="true" (click)="submitForm($event)" [attr.data-test-id]="'resolveDiagnosisSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>
