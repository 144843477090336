<h4 class="section-title bg-default mrgn-btm" [attr.data-test-id]="'eyeglassPrescriptionHeader'">Rx</h4>
@if (showViewRxHistoryButton) {
	<button
		cssClass="margin-bottom-sm"
		ejs-button
		(click)="openRxSelectModal()"
		type="button"
		[attr.data-test-id]="'viewRxHistoryButton'">
		View Rx History
	</button>
}
@if (eyeglassPrescription) {
	<div class="table-responsive margin-bottom-md">
		<table class="table table-hover table-nowrap table-bordered text-right" [attr.data-test-id]="'eyeglassPrescriptionTable'">
			<thead>
			<tr>
				<th></th>
				<th class="text-left">Bal</th>
				<th class="text-right">Sph</th>
				<th class="text-right">Cyl</th>
				<th class="text-right">Axis</th>
				<th class="text-right">Near Add</th>
				<th class="text-right">Int Add</th>
				<th class="text-right">H Prism</th>
				<th></th>
				<th class="text-right">V Prism</th>
				<th></th>
			</tr>
			</thead>
			<tbody>
			<tr>
				<td class="nostretch text-left">
					<span class="label label-success">OD</span>
					<br/>
					<span class="label label-info">OS</span>
				</td>
				<td class="text-left">
					{{ eyeglassPrescription.od.balanced| yesNo: 'Yes':'' }}
					<br/>
					{{ eyeglassPrescription.os.balanced| yesNo: 'Yes':'' }}
				</td>
				<td>
					{{ PrescriptionUtil.formatSphere(eyeglassPrescription.od.sphere) }}
					<br/>
					{{ PrescriptionUtil.formatSphere(eyeglassPrescription.os.sphere) }}
				</td>
				<td>
					{{ PrescriptionUtil.formatCylinder(eyeglassPrescription.od.cylinder) }}
					<br/>
					{{ PrescriptionUtil.formatCylinder(eyeglassPrescription.os.cylinder) }}
				</td>
				<td>
					{{ PrescriptionUtil.formatAxis(eyeglassPrescription.od.axis) }}
					<br/>
					{{ PrescriptionUtil.formatAxis(eyeglassPrescription.os.axis) }}
				</td>
				<td>
					{{ PrescriptionUtil.formatAddPower(eyeglassPrescription.od.nearAdd) }}
					<br/>
					{{ PrescriptionUtil.formatAddPower(eyeglassPrescription.os.nearAdd) }}
				</td>
				<td>
					{{ PrescriptionUtil.formatAddPower(eyeglassPrescription.od.intermediateAdd) }}
					<br/>
					{{ PrescriptionUtil.formatAddPower(eyeglassPrescription.os.intermediateAdd) }}
				</td>
				<td>
					{{ PrescriptionUtil.formatPrism(eyeglassPrescription.od.horizontalPrism) }}
					<br/>
					{{ PrescriptionUtil.formatPrism(eyeglassPrescription.os.horizontalPrism) }}
				</td>
				<td class="nostretch text-left">
					{{ eyeglassPrescription.od.horizontalPrismOrientation }}
					<br/>
					{{ eyeglassPrescription.os.horizontalPrismOrientation }}
				</td>
				<td>
					{{ PrescriptionUtil.formatPrism(eyeglassPrescription.od.verticalPrism) }}
					<br/>
					{{ PrescriptionUtil.formatPrism(eyeglassPrescription.os.verticalPrism) }}
				</td>
				<td class="nostretch text-left">
					{{ eyeglassPrescription.od.verticalPrismOrientation }}
					<br/>
					{{ eyeglassPrescription.os.verticalPrismOrientation }}
				</td>
			</tr>
			</tbody>
		</table>
	</div>
} @else {
	<br/>No Data
}

@if (eyeglassPrescription?.instructions) {
	<h5 class="media-heading"><strong>Special Instructions</strong></h5>
}
<p>{{ eyeglassPrescription?.instructions }}</p>
