<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-lg"
	[attr.data-test-id]="'quickAddContactModal'">
	<ng-template #header>
		<div class="dlg-template">Contact Quick Add</div>
	</ng-template>
	<ng-template #content>
		<form [formGroup]="componentForm" (ngSubmit)="save()" #templateForm="ngForm">
			<div class="row">
				<div class="col-sm-12">
					@if (componentForm.invalid && templateForm.submitted) {
						<gandalf-lib-validation-messages
							[form]="componentForm"
							[requestObj]="quickAddContactRequest">
						</gandalf-lib-validation-messages>
					}
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<div [attr.data-test-id]="'contactDemographicsSection'">
						<h4 class="section-title bg-default mrgn-btm">Contact Demographics</h4>
						<pms-contact-demographics-form formGroupName="contactDemographicsRequest"></pms-contact-demographics-form>
					</div>
					<div [attr.data-test-id]="'contactRoleSection'">
						<h4 class="section-title bg-default mrgn-btm">Contact Role</h4>
						<div class="form-horizontal">
							<div class="form-group">
								<label class="col-sm-4 col-md-3 control-label required">Contact Role</label>
								<div class="col-sm-8 col-md-9">
									<ejs-dropdownlist [dataSource]="roleOptions"
													  placeholder="Select Role"
													  formControlName="roleId">
									</ejs-dropdownlist>
								</div>
							</div>
						</div>
						<div class="form-group">
							@if (emergencyContactFeatureFlagOn) {
								<div class="col-sm-offset-4 col-md-offset-3 col-sm-8 col-md-9">
									<ejs-checkbox
										(change)="onEmergencyContactCheck($event)"
										label="Emergency Contact"
										formControlName="isEmergencyContact"
										[attr.data-test-id]="'contactEmergencyContactCheckbox'">
									</ejs-checkbox>
									@if (isEmergencyContact) {
										<ejs-checkbox
											label="Set as Primary"
											formControlName="isPrimaryEmergencyContact"
											cssClass="margin-left-md"
											[attr.data-test-id]="'contactEmergencyContactPrimaryCheckbox'">
										</ejs-checkbox>
									}
								</div>
							}
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<h4 class="section-title bg-default mrgn-btm">Address Information</h4>
					<div class="form-horizontal" [attr.data-test-id]="'contactAddressInformationSection'">
						<pms-address-form [isCreating]="true" [autoselectStateProvince]="false" [parentFormGroup]="getFormGroup('address')"
										  class="form-layout-col-sm-4-8-md-3-9"></pms-address-form>
					</div>
					@if (emergencyContactFeatureFlagOn) {
						<div [attr.data-test-id]="'contactInformationSection'">
							<h4 class="section-title bg-default mrgn-btm">Phone/Email</h4>
							<pms-contact-information [componentForm]="getFormGroup('contactInformation')"></pms-contact-information>
						</div>
					}
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button type="button" iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'quickAddContactModalCancelButton'">Cancel</button>
		<button ejs-button type="button" [isPrimary]="true" (click)="submitForm($event)" [attr.data-test-id]="'quickAddContactModalAddButton'">Add</button>
	</ng-template>
</ejs-dialog>
