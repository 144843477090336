<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-sm"
	[attr.data-test-id]="'enterClaimSubmissionDateModal'">
	<ng-template #header>
		<div class="dlg-template">Enter Claim Submission Date</div>
	</ng-template>
	<ng-template #content>
		<form class="form-horizontal"
			  [formGroup]="formGroup"
			  (ngSubmit)="saveMarkAsSubmitted()"
			  #templateForm="ngForm">
			@if (formGroup.invalid && templateForm.submitted) {
				<gandalf-lib-validation-messages
					[form]="formGroup"
					[requestObj]="request"
					[attr.data-test-id]="'enterClaimSubmissionDateValidationMessages'">
				</gandalf-lib-validation-messages>
			}
			<div class="form-group">
				<label class="col-sm-4 control-label required">Date</label>
				<div class="col-sm-8">
					<ejs-datepicker
						class="e-input"
						formControlName="submissionDate"
						[format]="dateFormat"
						placeholder="mm/dd/yyyy"
						[attr.data-test-id]="'enterClaimSubmissionDateModal'">
					</ejs-datepicker>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'enterClaimSubmissionDateModalCancelDate'">Cancel</button>
		<button ejs-button type="submit" [isPrimary]="true" (click)="submitForm($event)" [attr.data-test-id]="'enterClaimSubmissionDateModalSaveButton'">Save
		</button>
	</ng-template>
</ejs-dialog>
