// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.reporting.accounting.AgingReportResponse */
/* istanbul ignore next */
@GandalfModel
export class AgingReportResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	balance0To30: number;

	@GandalfProperty({ propertyType: 'Money' })
	balance30To60: number;

	@GandalfProperty({ propertyType: 'Money' })
	balance60To90: number;

	@GandalfProperty({ propertyType: 'Money' })
	balance90To120: number;

	@GandalfProperty({ propertyType: 'Money' })
	balanceOver120: number;

	@GandalfProperty({ propertyType: 'Money' })
	balanceTotal: number;

	@GandalfProperty()
	locationName: string;

	@GandalfProperty()
	patientNameResponse: PatientNameResponse;

	@GandalfProperty()
	payerName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	payerType: constants.PayerType;

}
