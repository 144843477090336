import { AbstractControl, ValidatorFn } from '@angular/forms';
import isNil from 'lodash/isNil';

/**
 * Validates that an array's length falls between the min and max values.
 */
export function sizeArrayValidator(min, max): ValidatorFn {
	return (control: AbstractControl) => {
		const value: any = control.value;
		if (!value) {
			return null;
		}

		const valid = (isNil(min) || value.length >= min)
				&& (isNil(max) || value.length <= max);

		return valid ? null : {
			sizeArray: {
				valid: false,
			},
		};
	};

}
