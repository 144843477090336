// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { SnomedCodeResponse } from './snomed-code-response';

/** See com.rev360.pms.api.controller.encounter.history.familyhealthhistory.FamilyHealthHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class FamilyHealthHistoryResponse extends GandalfModelBase {

	@GandalfProperty()
	active: boolean;

	@GandalfProperty()
	comments: string;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	patient: PatientNameResponse;

	@GandalfProperty()
	snomedCode: SnomedCodeResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	statusBrother: constants.FamilyHealthHistoryHealthCondition;

	@GandalfConstantDecorator()
	@GandalfProperty()
	statusDaughter: constants.FamilyHealthHistoryHealthCondition;

	@GandalfConstantDecorator()
	@GandalfProperty()
	statusFather: constants.FamilyHealthHistoryHealthCondition;

	@GandalfConstantDecorator()
	@GandalfProperty()
	statusMother: constants.FamilyHealthHistoryHealthCondition;

	@GandalfProperty()
	statusNoImmediateFamilyMember: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	statusSister: constants.FamilyHealthHistoryHealthCondition;

	@GandalfConstantDecorator()
	@GandalfProperty()
	statusSon: constants.FamilyHealthHistoryHealthCondition;

	@GandalfProperty()
	updatedOn: Date;

	@GandalfProperty()
	version: number;

}
