// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.prescription.MedicationPrescriptionRefillRequest */
/* istanbul ignore next */
@GandalfModel
export class MedicationPrescriptionRefillRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Dispensing Info is required' } })
	@GandalfValidator({ min: { min: 0, message: 'Dispensing Info must be greater than or equal to 0' } })
	@GandalfProperty({ isRequired: true })
	dispenseAmount: number;

	@GandalfValidator({ notNull: { message: 'Dispensing Unit is required' } })
	@GandalfProperty({ isRequired: true })
	dispenseUnitId: number;

	@GandalfValidator({ min: { min: 0, message: 'Duration must be greater than or equal to 0' } })
	@GandalfProperty()
	durationAmount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	durationUnit: constants.DurationUnit;

	@GandalfValidator({ notNull: { message: 'Expiration Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	expirationDate: Date;

	@GandalfProperty()
	frequencyId: number;

	@GandalfValidator({ notNull: { message: 'Medication Prescription Refill is required' } })
	@GandalfProperty({ isRequired: true })
	id: number;

	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	instructions: string;

	@GandalfValidator({ notNull: { message: 'Number of Refills is required' } })
	@GandalfValidator({ min: { min: 0, message: 'Number of Refills must be greater than or equal to 0' } })
	@GandalfProperty({ isRequired: true })
	numRefillsAllowed: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate: Date;

}
