// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.history.pfsh.SocialHistoryRequest */
/* istanbul ignore next */
@GandalfModel
export class SocialHistoryRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Drinking Amount cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	alcoholAmount: string;

	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	comments: string;

	@GandalfValidator({ sizeString: { message: 'Hobbies cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	hobbies: string;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Is Drinker is required' } })
	@GandalfProperty({ isRequired: true })
	isDrinker: constants.IsDrinker;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Is Smoker is required' } })
	@GandalfProperty({ isRequired: true })
	isSmoker: constants.IsSmoker;

	@GandalfValidator({ sizeString: { message: 'Tobacco Amount cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	smokingAmount: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	smokingPreference: constants.SmokingPreference;

	@GandalfConstantDecorator()
	@GandalfProperty()
	smokingStatus: constants.SmokingStatus;

}
