<form class="form-horizontal" [formGroup]="componentForm">
	<div class="form-group" [attr.data-test-id]="'familyPatientInsuranceCopyInsuranceFormGroup'">
		<div class="col-sm-offset-4 col-md-offset-3 col-sm-8 col-md-9">
			<ejs-checkbox formControlName="copyInsurance" label="Copy Insurance"></ejs-checkbox>
		</div>
	</div>
	<div class="form-group">
		<label class="col-sm-4 col-md-3 control-label">Relation to Insured</label>
		<div class="col-sm-8 col-md-9">
			<pms-constant-dropdown [constantList]="policyHolderRelationshipOptions"
							  formControlName="relationshipToInsured">
			</pms-constant-dropdown>
		</div>
	</div>
</form>
