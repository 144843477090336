import { Injectable } from '@angular/core';
import { EnumUtil } from 'morgana';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { _filter, _find, _isEmpty, _isNil, _some } from '@core/lodash/lodash';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { SECURITY_CONSTANTS } from '@core/security/security.constants';
import { UserLocationsService } from '@core/user-locations/user-locations.service';
import { InvoiceItemListResponse } from '@gandalf/model/invoice-item-list-response';
import {
	BetaSystemCode,
	InvoiceItemStatus,
	InvoiceItemType,
	InvoiceStatus,
	PayerType,
	PaymentStatus,
	PreferenceDefaults,
	PreferenceName
} from '@gandalf/constants';
import { AccountingInvoicePaymentResponse } from '@gandalf/model/accounting-invoice-payment-response';
import { FinanceChargePlanNameResponse } from '@gandalf/model/finance-charge-plan-name-response';
import { InvoiceResponse } from '@gandalf/model/invoice-response';
import { Big } from 'big.js';

@Injectable({
	providedIn: 'root',
})
export class AccountingViewService {

	constructor(
		private securityManagerService: SecurityManagerService,
		private featureService: FeatureService,
		private userLocationsService: UserLocationsService,
	) {
	}

	/** Item Quantity Edit Rules:
	 * 1. Item must be a product or service (type == 1 or type == 2 or type 40, changed by JSS)
	 * 2. Item status must be active
	 * 3. Containing invoice must be active
	 * 4. Containing invoice must not yet be approved
	 * 5. Is not a split item (split == false && wasSplit == false)
	 * 6. Containing invoice must not have fee schedule applied
	 * 7. Containing invoice must not have had taxes transferred
	 * 8. Is the original item
	 */
	canEditQuantity(invoice: InvoiceResponse, item: InvoiceItemListResponse) {
		return EnumUtil.equalsOneOf(item.type, InvoiceItemType.PRODUCT, InvoiceItemType.SERVICE, InvoiceItemType.AD_HOC)
			&& EnumUtil.equals(item.status, InvoiceItemStatus.ACTIVE)
			&& EnumUtil.equals(invoice.status, InvoiceStatus.ACTIVE)
			&& !invoice.approved
			&& (!item.split && !item.wasSplit)
			&& _isNil(invoice.feeScheduleId)
			&& _isNil(invoice.transferTaxInvoiceId)
			&& _isNil(item.originalItemId);
	}

	canChangeLocation(invoice: InvoiceResponse) {
		return EnumUtil.equals(invoice.status, InvoiceStatus.ACTIVE)
			&& !invoice.approved
			&& Big(invoice.amountPaid).eq(0)
			&& _isNil(invoice.feeScheduleId)
			&& _isNil(invoice.transferTaxInvoiceId);
	}

	/**
	 * 1. invoice is not approved
	 * 2. invoice is active
	 * 3. has no encounter
	 */
	canChangeProvider(invoice: InvoiceResponse) {
		return EnumUtil.equals(invoice.status, InvoiceStatus.ACTIVE)
			&& !invoice.approved
			&& _isNil(invoice.encounterId)
			&& this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_ACCOUNTING_INVOICE_PROVIDER);
	}

	/**
	 * 1. can change provider
	 * 2. invoice has a provider
	 * 3. Allow walkins preference is on
	 */
	canRemoveProvider(invoice: InvoiceResponse) {
		return this.canChangeProvider(invoice)
			&& !_isNil(invoice.providerId)
			&& this.securityManagerService.preferenceValueIsOn(
				PreferenceName.ACCOUNTING_ALLOW_WALK_INS.value,
				PreferenceDefaults.ACCOUNTING_ALLOW_WALK_INS.value,
			);
	}

	/**
	 * Item Discount rules
	 *  1. Can add items
	 *  2. Has permission
	 *  3. Preference is set
	 */
	canDiscount(invoice: InvoiceResponse) {
		return this.canAddItems(invoice)
			&& this.hasDiscountPermission()
			&& this.securityManagerService.preferenceValueIsOn(PreferenceName.ACCOUNTING_ALLOW_DISCOUNT_BUTTON.value,
				PreferenceDefaults.ACCOUNTING_ALLOW_DISCOUNT_BUTTON.value);
	}

	canDiscountItems(invoice: InvoiceResponse) {
		return this.hasDiscountPermission() && this.canAddItems(invoice)
			&& (
				EnumUtil.equals(invoice.payerType, PayerType.PATIENT)
				|| (EnumUtil.equals(invoice.payerType, PayerType.INSURANCE) && invoice.payerAllowItemDiscounts)
			);
	}

	hasDiscountPermission(): boolean {
		return this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_ACCOUNTING_DISCOUNT);
	}

	/** Item Add Rules:
	 * 1. Containing invoice must be active
	 * 2. Containing invoice must not yet be approved
	 * 3. Containing invoice must not have fee schedule applied
	 * 4. Containing invoice must not have had taxes transferred
	 */
	canAddItems(invoice: InvoiceResponse) {
		return !_isNil(invoice)
			&& EnumUtil.equals(invoice.status, InvoiceStatus.ACTIVE)
			&& !invoice.approved
			&& _isNil(invoice.feeScheduleId)
			&& _isNil(invoice.transferTaxInvoiceId);
	}

	/**
	 * 1. Item status must be active
	 * 2. Containing invoice must be active
	 * 3. Containing invoice must not yet be approved
	 * 4. Containing invoice must not have fee schedule applied
	 * 5. Containing invoice must not have had taxes transferred
	 * 6. Is the original item
	 * 7. User has permission
	 */
	canRemoveItem(invoice: InvoiceResponse, item: InvoiceItemListResponse) {
		return EnumUtil.equals(item.status, InvoiceItemStatus.ACTIVE)
			&& EnumUtil.equals(invoice.status, InvoiceStatus.ACTIVE)
			&& !invoice.approved
			&& _isNil(invoice.feeScheduleId)
			&& _isNil(invoice.transferTaxInvoiceId)
			&& _isNil(item.originalItemId)
			&& this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_ACCOUNTING_ITEM_REMOVE);
	}

	/**
	 * 1. Invoice is active
	 * 2. Balance is greater than 0
	 * 3. Payer type is patient or insurance
	 */
	canTransferItems(invoice: InvoiceResponse) {
		return EnumUtil.equals(invoice.status, InvoiceStatus.ACTIVE)
			&& Big(invoice.balance).gt(0)
			&& EnumUtil.equalsOneOf(invoice.payerType, PayerType.PATIENT, PayerType.INSURANCE);
	}

	/**
	 * 1. Can add items
	 * 2. has permissions to add adhoc
	 */
	canAddAdhoc(invoice: InvoiceResponse) {
		return this.canAddItems(invoice)
			&& this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_ACCOUNTING_ADD_ADHOC);
	}

	/**
	 * 1. invoice status must be active
	 * 2. Invoice must be approved
	 * 3. Employee must have permission to receive payment
	 */
	canReceivePayment(invoice: InvoiceResponse) {
		return EnumUtil.equals(invoice.status, InvoiceStatus.ACTIVE)
			&& invoice.approved
			&& this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_ACCOUNTING_PAYMENT_RECEIVE);
	}

	/**
	 * 1. Invoice status must be active or paid
	 * 2. invoice must be approved
	 * 3. invoice payer type must be patient
	 * 4. employee must have permission to issue refunds
	 * 5. allow refund preference is on
	 */
	canIssueRefund(invoice: InvoiceResponse) {
		return EnumUtil.equalsOneOf(invoice.status, InvoiceStatus.ACTIVE, InvoiceStatus.PAID)
			&& invoice.approved
			&& EnumUtil.equals(invoice.payerType, PayerType.PATIENT)
			&& this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_ACCOUNTING_REFUND)
			&& this.securityManagerService.preferenceValueIsOn(
				PreferenceName.ACCOUNTING_ALLOW_REFUND_BUTTON.value,
				PreferenceDefaults.ACCOUNTING_ALLOW_REFUND_BUTTON.value,
			);
	}

	/**
	 * 1. Invoice status must be active
	 * 2. Invoice must be approved
	 * 3. Invoice balance must be greater than 0
	 * 4. Invoice payer type must be one of: Patient, Insurance, Collections
	 */
	canTransfer(invoice: InvoiceResponse) {
		return EnumUtil.equals(invoice.status, InvoiceStatus.ACTIVE)
			&& invoice.approved
			&& Big(invoice.balance).gt(0)
			&& EnumUtil.equalsOneOf(invoice.payerType, PayerType.PATIENT, PayerType.INSURANCE, PayerType.COLLECTIONS);
	}

	/**
	 * 1. Payer type must be insurance
	 * 2. invoice must be active
	 * 3. user preference must allow patient portion button (not implemented yet)
	 * 4. Allow patient portion preference must be on
	 */
	canAddPatientPortion(invoice: InvoiceResponse) {
		return EnumUtil.equals(invoice.payerType, PayerType.INSURANCE)
			&& EnumUtil.equals(invoice.status, InvoiceStatus.ACTIVE)
			&& !invoice.approved
			&& this.securityManagerService.preferenceValueIsOn(
				PreferenceName.ACCOUNTING_ALLOW_PORTION_BUTTON.value,
				PreferenceDefaults.ACCOUNTING_ALLOW_PORTION_BUTTON.value,
			);
	}

	/**
	 * 1. can change fee schedule
	 * 2. invoice does not have a fee schedule
	 */
	canAddFeeSchedule(invoice: InvoiceResponse) {
		return this.canChangeFeeSchedule(invoice)
			&& _isNil(invoice.feeScheduleId);
	}

	/**
	 * 1. can change fee schedule
	 * 2. Invoice has a fee schedule
	 */
	canRemoveFeeSchedule(invoice: InvoiceResponse) {
		return this.canChangeFeeSchedule(invoice)
			&& !_isNil(invoice.feeScheduleId);
	}

	/**
	 * 1. Invoice cannot be approved
	 * 2. payer type must be insurance
	 * 3. The payer must have a fee schedule in the insurance
	 */
	canChangeFeeSchedule(invoice: InvoiceResponse) {
		return !invoice.approved
			&& EnumUtil.equals(invoice.payerType, PayerType.INSURANCE)
			&& !_isNil(invoice.payerPolicyFeeScheduleId);
	}

	/**
	 * 1. Payer must be Insurance
	 * 2. Invoice must be approved
	 */
	canPrintClaim(invoice: InvoiceResponse) {
		return EnumUtil.equals(invoice.payerType, PayerType.INSURANCE)
			&& invoice.approved;
	}

	/**
	 * 1. Payer type is insurance
	 * 2. Invoice does not have a fee schedule
	 * 3. Invoice does not have a transferred taxes
	 * 4. invoice is active
	 * 5. invoice is not approved
	 * 6. item type is a product, service or adhoc
	 * 7. item is the original item
	 * 8. User Preference includes ACCOUNTING_ALLOW_SPLIT_BUTTON
	 * 9. Item has not already been split when the accounting uplift FF is on
	 */
	canSplitItem(invoice: InvoiceResponse, item: InvoiceItemListResponse) {
		return EnumUtil.equals(invoice.payerType, PayerType.INSURANCE)
			&& _isNil(invoice.feeScheduleId)
			&& _isNil(invoice.transferTaxInvoiceId)
			&& EnumUtil.equals(invoice.status, InvoiceStatus.ACTIVE)
			&& EnumUtil.equals(item.status, InvoiceItemStatus.ACTIVE)
			&& !invoice.approved
			&& EnumUtil.equalsOneOf(item.type, InvoiceItemType.PRODUCT, InvoiceItemType.SERVICE, InvoiceItemType.AD_HOC)
			&& _isNil(item.originalItemId)
			&& this.securityManagerService.preferenceValueIsOn(
				PreferenceName.ACCOUNTING_ALLOW_SPLIT_BUTTON.value,
				PreferenceDefaults.ACCOUNTING_ALLOW_SPLIT_BUTTON.value,
			)
			&& !item.wasSplit;
	}

	/**
	 * 1. Payer type is NOT anonymous
	 * 2. invoice status is active
	 * 3. invoice is not approved
	 * 4. invoice has no order id
	 * 5. User has RESOURCE_ACCOUNTING_INVOICE_ENCOUNTER permission
	 */
	canChangeEncounter(invoice: InvoiceResponse) {
		return !EnumUtil.equals(invoice.payerType, PayerType.ANONYMOUS)
			&& EnumUtil.equals(invoice.status, InvoiceStatus.ACTIVE)
			&& !invoice.approved
			&& _isNil(invoice.orderId)
			&& this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_ACCOUNTING_INVOICE_ENCOUNTER);
	}

	/**
	 * 1. invoice status is active
	 * 2. invoice is not approved
	 * 3. invoice has an encounter
	 * 4. invoice does not have an order
	 * 5. User has RESOURCE_ACCOUNTING_INVOICE_ENCOUNTER permission
	 */
	canDisassociateEncounter(invoice: InvoiceResponse) {
		return EnumUtil.equals(invoice.status, InvoiceStatus.ACTIVE)
			&& !invoice.approved
			&& !_isNil(invoice.encounterId)
			&& _isNil(invoice.orderId)
			&& this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_ACCOUNTING_INVOICE_ENCOUNTER);
	}

	/**
	 * 1. invoice status is active
	 * 2. invoice is not approved
	 * 3. invoice has an appointment
	 * 4. invoice does not have an order
	 * 5. Practice has beta feature enabled
	 * 6. Practice has preference enabled
	 * 7. User has RESOURCE_ACCOUNTING_INVOICE_ENCOUNTER permission
	 */
	canDisassociateAppointment(invoice: InvoiceResponse) {
		return EnumUtil.equals(invoice.status, InvoiceStatus.ACTIVE)
			&& !invoice.approved
			&& !_isNil(invoice.appointmentId)
			&& _isNil(invoice.orderId)
			&& this.securityManagerService.hasBeta(BetaSystemCode.ALLOW_INVOICE_APPOINTMENT)
			&& this.securityManagerService.preferenceValueIsOn(
				PreferenceName.ACCOUNTING_ALLOW_INVOICE_APPOINTMENT.value, PreferenceDefaults.ALLOW_ASSIGNING_APPOINTMENT_TO_INVOICE.value,
			)
			&& this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_ACCOUNTING_INVOICE_ENCOUNTER);
	}

	/**
	 * 1. Invoice status is active
	 * 2. user has RESOURCE_ACCOUNTING_INVOICE_VOID permission
	 */
	canVoid(invoice: InvoiceResponse) {
		return EnumUtil.equals(invoice.status, InvoiceStatus.ACTIVE)
			&& this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_ACCOUNTING_INVOICE_VOID);
	}

	/**
	 * 1. invoice is active
	 * 2. invoice is not approved
	 * 3. practice has at least one finance charge plan
	 * 4. user has RESOURCE_ACCOUNTING_EDIT_INV_FIN_CHG permission
	 *
	 * In order to avoid multiple checks for finance charge plans caused by the interface, the component should supply
	 * the current list of finance charge plans for the practice.
	 */
	canChangeFinanceChargePlan(invoice: InvoiceResponse, practiceFinanceChargePlans: FinanceChargePlanNameResponse[]) {
		return EnumUtil.equals(invoice.status, InvoiceStatus.ACTIVE)
			&& !invoice.approved
			&& !_isEmpty(practiceFinanceChargePlans)
			&& this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_ACCOUNTING_EDIT_INV_FIN_CHG);
	}

	/**
	 * 1. invoice is on hold, or invoice is paid and approved
	 */
	canResetActive(invoice: InvoiceResponse) {
		return EnumUtil.equals(invoice.status, InvoiceStatus.ON_HOLD)
			|| (EnumUtil.equals(invoice.status, InvoiceStatus.PAID) && invoice.approved);
	}

	/**
	 * 1. invoice status is active or on hold
	 * 2. invoice is approved
	 * 3. invoice balance is 0
	 */
	canMarkPaid(invoice: InvoiceResponse) {
		return EnumUtil.equalsOneOf(invoice.status, InvoiceStatus.ON_HOLD, InvoiceStatus.ACTIVE)
			&& invoice.approved
			&& Big(invoice.balance).eq(0);
	}

	/**
	 * 1. invoice status is active
	 */
	canPutOnHold(invoice: InvoiceResponse) {
		return EnumUtil.equals(invoice.status, InvoiceStatus.ACTIVE);
	}

	/**
	 * 1. invoice status is active
	 * 2. invoice is not approved
	 * OR
	 * 2. invoice is approved
	 * 3. the user has permission
	 * OR
	 * 3. invoice has no finance charges
	 */
	canChangeAuthorizedStatus(invoice: InvoiceResponse) {
		return EnumUtil.equals(invoice.status, InvoiceStatus.ACTIVE)
			&& (!invoice.approved
				|| (invoice.approved
					&& (!_some(invoice.items, (item) => EnumUtil.equals(item.type, InvoiceItemType.FINANCE_CHARGE))
						|| this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_ACCOUNTING_UNAUTH_FIN_CHG_INV))
				)
			);
	}

	/**
	 * 1. invoice status is active
	 * 2. invoice is not approved
	 * 3. invoice does not have a fee schedule
	 * 4. employee has RESOURCE_ACCOUNTING_INVOICE_DATE permission
	 */
	canChangeInvoiceDate(invoice: InvoiceResponse) {
		return EnumUtil.equals(invoice.status, InvoiceStatus.ACTIVE)
			&& !invoice.approved
			&& _isNil(invoice.feeScheduleId)
			&& this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_ACCOUNTING_INVOICE_DATE);
	}

	/**
	 * 1. Payer type is insurance
	 * 2. payer allows transfer tax
	 */
	canTransferTax(invoice: InvoiceResponse) {
		return EnumUtil.equals(invoice.payerType, PayerType.INSURANCE) && invoice.payerAllowTransferTax;
	}

	/**
	 * return count of active taxable items in an invoice
	 */
	getTaxableItemCount(invoice: InvoiceResponse) {
		return _filter(invoice.items, item => EnumUtil.equals(item.status, InvoiceItemStatus.ACTIVE) && !_isNil(item.taxAppliedId)).length;
	}

	/**
	 * 1. invoice balance is less than 0
	 * 2. Invoice payer is not a guest
	 * 3. has RESOURCE_ACCOUNTING_CREDIT permission
	 */
	canGrantCredit(invoice: InvoiceResponse) {
		return this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_ACCOUNTING_CREDIT)
			&& !EnumUtil.equals(invoice.payerType, PayerType.ANONYMOUS)
			&& Big(invoice.balance).lt(0);
	}

	/**
	 * 1. payment is not null
	 * 2. payment status is active or null
	 * 3. Invoice has not been voided
	 * 4. User has permission to void payments
	 */
	canVoidPayment(invoice: InvoiceResponse, payment: AccountingInvoicePaymentResponse) {
		return !_isNil(payment)
			&& !EnumUtil.equals(invoice.status, InvoiceStatus.VOIDED)
			&& (EnumUtil.equals(payment.paymentStatus, PaymentStatus.APPROVED) || _isNil(payment.paymentStatus))
			&& (this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_ACCOUNTING_PAYMENT_VOID)
				|| this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_ACCOUNTING_VOID_OPENEDGE_PAYMENT)
			);
	}

	/**
	 * 1. OpenEdge refund/void feature flag is on
	 * 2. The user has the appropriate permission
	 * 3. The payment location has OpenEdge active
	 */
	canVoidOpenEdgePayment(paymentLocationId: number): boolean {
		if (_isNil(paymentLocationId)) {
			return false;
		}
		const currentLocation = _find(this.userLocationsService.getUserActiveLocations(), location => location.value === paymentLocationId);
		return this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.ACCOUNTING.INVOICE.OPEN_EDGE_VOID_REFUND_PAYMENT)
			&& this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_ACCOUNTING_VOID_OPENEDGE_PAYMENT)
			&& currentLocation.openEdgeIsActive;
	}

	isOpenEdgeEnabledForAnyLocation(): boolean {
		return _some(this.securityManagerService.getPracticeLocations(), location => location.openEdgeIsActive);
	}
}
