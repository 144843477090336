import { CdaSections } from '@gandalf/constants';

export const CDA_CLINICAL_SUMMARY_DOCUMENT_SECTIONS = [
	CdaSections.CHIEF_COMPLAINT,
	CdaSections.ENCOUNTERS,
	CdaSections.FAMILY_HISTORY,
	CdaSections.FUNCTIONAL_STATUS,
	CdaSections.GENERAL_STATUS,
	CdaSections.IMMUNIZATIONS,
	CdaSections.MEDICAL_EQUIPMENT,
	CdaSections.MENTAL_STATUS,
	CdaSections.PROBLEM_LIST,
	CdaSections.PROCEDURES,
	CdaSections.REVIEW_OF_SYSTEMS,
	CdaSections.SOCIAL_HISTORY,
	CdaSections.VITAL_SIGNS,
	CdaSections.CONSULTATION_NOTES,
	CdaSections.HISTORY_AND_PHYSICAL_NOTES,
	CdaSections.IMAGING_NARRATIVE_NOTES,
	CdaSections.PROCEDURE_NOTES,
	CdaSections.PROGRESS_NOTES,
	CdaSections.LABORATORY_NARRATIVE_NOTES,
	CdaSections.PATHOLOGY_NARRATIVE_NOTES,
];

export const CDA_TRANSITION_OF_CARE_DOCUMENT_SECTIONS = [
	CdaSections.ENCOUNTERS,
	CdaSections.FAMILY_HISTORY,
	CdaSections.GENERAL_STATUS,
	CdaSections.HISTORY_OF_PAST_ILLNESS,
	CdaSections.HISTORY_OF_PRESENT_ILLNESS,
	CdaSections.IMMUNIZATIONS,
	CdaSections.MEDICAL_EQUIPMENT,
	CdaSections.MENTAL_STATUS,
	CdaSections.PHYSICAL_EXAM,
	CdaSections.PROCEDURES,
	CdaSections.REVIEW_OF_SYSTEMS,
	CdaSections.SOCIAL_HISTORY,
	CdaSections.VITAL_SIGNS,
	CdaSections.CONSULTATION_NOTES,
	CdaSections.HISTORY_AND_PHYSICAL_NOTES,
	CdaSections.IMAGING_NARRATIVE_NOTES,
	CdaSections.PROCEDURE_NOTES,
	CdaSections.PROGRESS_NOTES,
	CdaSections.LABORATORY_NARRATIVE_NOTES,
	CdaSections.PATHOLOGY_NARRATIVE_NOTES,
];

export const CDA_PATENT_RECORD_DOCUMENT_SECTIONS = [
	CdaSections.ENCOUNTERS,
	CdaSections.FAMILY_HISTORY,
	CdaSections.FUNCTIONAL_STATUS,
	CdaSections.IMMUNIZATIONS,
	CdaSections.MEDICAL_EQUIPMENT,
	CdaSections.MENTAL_STATUS,
	CdaSections.PAYORS,
	CdaSections.CONSULTATION_NOTES,
	CdaSections.HISTORY_AND_PHYSICAL_NOTES,
	CdaSections.IMAGING_NARRATIVE_NOTES,
	CdaSections.PROCEDURE_NOTES,
	CdaSections.PROGRESS_NOTES,
	CdaSections.LABORATORY_NARRATIVE_NOTES,
	CdaSections.PATHOLOGY_NARRATIVE_NOTES,
];
