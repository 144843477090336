// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { UpdateRecurringDefinitionRequest } from './update-recurring-definition-request';

/** See com.rev360.pms.api.controller.schedule.scheduleitem.UpdateRecurringEmployeeOfficeHoursRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateRecurringEmployeeOfficeHoursRequest extends GandalfModelBase {

	@GandalfConstantDecorator()
	@GandalfProperty()
	changeMode: constants.UpdateMode;

	@GandalfProperty()
	displayColor: number;

	@GandalfValidator({ notNull: { message: 'End Time is required' } })
	@GandalfProperty({ isRequired: true })
	endDate: Date;

	@GandalfValidator({ notNull: { message: 'Employee Office Hours ID is required' } })
	@GandalfProperty({ isRequired: true })
	id: number;

	@GandalfProperty()
	recurringDefinition: UpdateRecurringDefinitionRequest;

	@GandalfValidator({ notNull: { message: 'Start Time is required' } })
	@GandalfProperty({ isRequired: true })
	startDate: Date;

}
