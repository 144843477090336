/* eslint-disable @angular-eslint/directive-class-suffix */
import { AfterViewInit, Directive, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { StatefulGridBase } from '@app-store/mixins/stateful-grid.mixin';
import { StatefulEventService } from '@app-store/services/stateful-event.service';
import { StatefulFormService } from '@app-store/services/stateful-form.service';
import { StatefulGridService } from '@app-store/services/stateful-grid.service';
import { StatefulPropertyService } from '@app-store/services/stateful-property.service';
import { StatefulRouteService } from '@app-store/services/stateful-route.service';
import { HIGHLIGHTING_POSITION, StatefulBaseComponentUtil } from '@app-store/stateful-base.component.util';
import { StatefulComponentUtil } from '@app-store/utils/stateful-component-util';
import { StatefulFormUtil } from '@app-store/utils/stateful-form-util';
import { AgGridAngular } from 'ag-grid-angular';
import { RowClickedEvent, RowNode } from 'ag-grid-community';
import { skip, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@shared/component/base.component';

@Directive({
	/* eslint-disable-next-line @angular-eslint/directive-selector */
	selector: 'pmsStatefulBase',
})
export class StatefulBaseComponent extends BaseComponent implements AfterViewInit, OnDestroy, OnInit {
	static _componentName: string;
	private _storeKey: string;
	agGrid?: AgGridAngular;
	componentForm?: UntypedFormGroup | UntypedFormArray;
	statefulFormService?: StatefulFormService;
	statefulGridService?: StatefulGridService;
	statefulPropertyService?: StatefulPropertyService;
	statefulEventService?: StatefulEventService;
	statefulRouteService?: StatefulRouteService;

	constructor() {
		super();
	}

	ngOnInit(): Promise<void> {
		this._storeKey = StatefulComponentUtil.buildStoreKeyFromInstance(this);
		if (StatefulBaseComponentUtil.implementsRegisterStatefulChild(this)) {
			StatefulBaseComponentUtil.registerStatefulChild(this);
		}

		return this.initializeState();
	}

	ngAfterViewInit() {
		/* eslint-disable-next-line deprecation/deprecation */
		if (StatefulBaseComponentUtil.implementsStatefulGrid(this) && this.agGrid && !this.unsubscribe.isStopped) {
			this.agGrid.api.setGridOption('onGridPreDestroyed', () => this.onGridDestroy());
			this.agGrid.rowClicked.pipe(takeUntil(this.unsubscribe)).subscribe((event) => this.setRowClickHighlighting(event));

			this.agGrid.rowDataUpdated.pipe(
				// skip the initial grid populate so the highlighted row doesn't clear out
				skip(1),
				takeUntil(this.unsubscribe)).subscribe((_event) => {
				this.setRowChangedHighlighting();
			});
			StatefulBaseComponentUtil.patchGridState(this as StatefulGridBase).subscribe(() => {
				// For some reason getting these at onDestroy renders an empty object, but this method works.
				StatefulBaseComponentUtil.observeGridFilter(this as StatefulGridBase).subscribe();
				StatefulBaseComponentUtil.observeColumnState(this as StatefulGridBase).subscribe();
			});
		}
		StatefulBaseComponentUtil.statefulRoutingCheck(this);
	}

	setRowClickHighlighting(event: RowClickedEvent) {
		this.agGrid.api.forEachNode(this.setHighlightedToNull);
		(event.node as RowNode).setHighlighted(HIGHLIGHTING_POSITION);
		this.statefulGridService.updateHighlightedRowState(event.node.rowIndex);
	}

	setHighlightedToNull = (node: RowNode) => node.setHighlighted(null);

	setRowChangedHighlighting() {
		// check for highlighted row and save it to the store
		const highlightedRowNode = this.agGrid.api.getRenderedNodes()?.find((node: RowNode) => node.highlighted === HIGHLIGHTING_POSITION);
		this.statefulGridService.updateHighlightedRowState(highlightedRowNode?.rowIndex);
	}

	ngOnDestroy() {
		/* eslint-disable-next-line deprecation/deprecation */
		if (StatefulBaseComponentUtil.implementsStatefulForm(this) && this.componentForm && !this.unsubscribe.isStopped) {
			this.statefulFormService.updateFormValue(this.componentForm.getRawValue());
			this.statefulFormService.updateFormMeta(StatefulFormUtil.getFormMeta(this.componentForm));
		}

		super.ngOnDestroy();
	}

	private onGridDestroy() {
		/* eslint-disable-next-line deprecation/deprecation */
		if (StatefulBaseComponentUtil.implementsStatefulGrid(this) && this.agGrid && !this.unsubscribe.isStopped) {
			this.statefulGridService.updateGridPaginationState(
				this.agGrid.api.paginationGetCurrentPage(),
				this.agGrid.api.paginationGetPageSize(),
			);
			this.statefulGridService.updateGridSelectionState(this.agGrid.api.getSelectedNodes()?.map(row => row.id));
		}
	}

	private async initializeState() {
		if (StatefulBaseComponentUtil.implementsStatefulEvents(this)) {
			this.statefulEventService.storeKey = this.storeKey;
		}

		if (StatefulBaseComponentUtil.implementsStatefulProperties(this)) {
			this.statefulPropertyService.storeKey = this.storeKey;
			StatefulBaseComponentUtil.patchProperties(this).subscribe();
		}

		if (StatefulBaseComponentUtil.implementsStatefulForm(this)) {
			this.statefulFormService.storeKey = this.storeKey;
			StatefulBaseComponentUtil.observeFormSubmitted(this).subscribe();
		}

		if (StatefulBaseComponentUtil.implementsStatefulGrid(this)) {
			this.statefulGridService.storeKey = this.storeKey;
		}

		if (StatefulBaseComponentUtil.implementsStatefulRoute(this)) {
			this.statefulRouteService.storeKey = this.storeKey;
			await StatefulBaseComponentUtil.navigateToPersistedChildRoute(this);
			StatefulBaseComponentUtil.observeAndPersistChildRoutes(this).subscribe();
		}
	}

	get componentName() {
		return this.constructor.prototype._componentName;
	}

	get storeKey() {
		return this._storeKey;
	}
}
