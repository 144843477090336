<ejs-dropdownlist [dataSource]="constantList.values"
				  [disabled]="disabled"
				  #dropdownlist
				  revOverrideDropdownlistBase
				  [fields]="{text: label, value: 'value', id: 'value'}"
				  [(ngModel)]="selectedValue"
				  (change)="onSelectionChange($event)"
				  [allowFiltering]="shouldFilter(constantList.values)"
				  [showClearButton]="showClearButton"
				  (created)="dropdownlistCreated()"
				  [placeholder]="placeholder"
				  [attr.data-test-id]="buildDataTestIdForDropdown()">
</ejs-dropdownlist>
