// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { ReferenceDataSummaryResponse } from './reference-data-summary-response';

/** See com.rev360.pms.api.controller.patient.family.FamilyAccountMemberDetailResponse */
/* istanbul ignore next */
@GandalfModel
export class FamilyAccountMemberDetailResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	balance: number;

	@GandalfProperty({ propertyType: 'Money' })
	credit: number;

	@GandalfProperty()
	email: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	emergencyContactType: constants.EmergencyContactType;

	@GandalfProperty()
	headOfHousehold: boolean;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	isEmergencyContact: boolean;

	@GandalfProperty({ propertyType: 'LocalDate' })
	lastComprehensiveEncounterDate: Date;

	@GandalfProperty()
	lastComprehensiveEncounterId: number;

	@GandalfProperty()
	lastComprehensiveEncounterType: string;

	@GandalfProperty()
	name: PersonNameResponse;

	@GandalfProperty()
	patientId: number;

	@GandalfProperty()
	phone: string;

	@GandalfProperty()
	role: ReferenceDataSummaryResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.PatientStatus;

}
