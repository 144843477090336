import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { _find } from '@core/lodash/lodash';
import { ReferenceDataResponseForTable } from '@core/reference-data/reference-data.service';
import { ReferenceDataMasterCategory } from '@gandalf/constants';
import { ReferenceDataResponse } from '@gandalf/model/reference-data-response';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { GandalfConstant } from 'gandalf';
import { AdminReferenceDataService } from '../admin-reference-data.service';

@Component({
	selector: 'pms-reference-data-list',
	templateUrl: './reference-data-list.component.html',
	styles: [],
})
export class ReferenceDataListComponent {

	@Input()
	category: ReferenceDataMasterCategory;

	@Output()
	openEvent = new EventEmitter<ReferenceDataResponse>();

	@ViewChild('grid')
	grid: GridComponent;

	referenceDataList: ReferenceDataResponseForTable[] = [];
	isSearching = false;
	dragStarted = false;

	statusFilterOptions = [
		new GandalfConstant(true, 'Active'),
		new GandalfConstant(false, 'Inactive'),
	];
	statusFilter = this.statusFilterOptions[0];

	constructor(
		protected referenceDataService: AdminReferenceDataService,
	) {
	}

	getReferenceDataList(): void {
		this.isSearching = true;
		this.referenceDataService.getReferenceDataByCategoryIdForTable(this.category.value).subscribe((data) => {
			this.referenceDataList = data;
			this.filterTable();
			this.isSearching = false;
		});
	}

	openReferenceDataDetails(row: any): void {
		this.openEvent.emit(_find(this.referenceDataList, referenceData => referenceData.id === row.id));
	}

	clearSelectedRow() {
		this.grid.clearSelection();
	}

	gridCreated() {
		(this.grid as any).toolTipObj.beforeOpen = this.dragSourceTooltip.bind(this);
		this.getReferenceDataList();
	}

	filterTable() {
		if (this.statusFilter && this.statusFilter.value !== null) {
			this.grid.filterByColumn('active', 'equal', this.statusFilter.value);
		} else {
			this.grid.clearFiltering(['active']);
		}
	}

	rowDropReorder() {
		this.grid.clearSorting();
		setTimeout(() => {
			this.reorderReferenceData();
		});
	}

	reorderReferenceData() {
		this.referenceDataList.forEach((referenceData: ReferenceDataResponse, index) => {
			referenceData.sortOrder = index;
		});
		this.referenceDataService.updateReferenceDataSortOrder(this.referenceDataList).subscribe((data) => {
			this.referenceDataList = data;
		});
	}

	dragSourceTooltip(event: any) {
		if (this.dragStarted) {
			event.cancel = true;
		}
	}

	rowDragStartHelper(event: any) {
		if (this.statusFilter.value !== null) {
			event.cancel = true;
			return;
		}
		this.dragStarted = true;
	}

	rowDrag() {
		this.dragStarted = true;
	}

	rowDrop() {
		this.dragStarted = false;
	}
}
