import { Component, Input } from '@angular/core';
import { ItemModel, MenuEventArgs } from '@syncfusion/ej2-angular-splitbuttons';

export interface ItemModelWithAction extends ItemModel {
	selectCallback?: () => any;
}

export interface PodComponentConfiguration {
	expandCallback?: () => any;
	addCallback?: () => any;
	refreshCallback?: () => any;
	expandTooltip?: string;
	addTooltip?: string;
	refreshTooltip?: string;
	addDropdown?: ItemModelWithAction[];
	title?: string;
	subTitle?: string;
	icon?: string;
	podId?: string;
	hasTabs?: boolean;
	useDynamicHeight?: boolean;
}

@Component({
	selector: 'rev-pod',
	templateUrl: './pod.component.html',
})
export class PodComponent {

	@Input()
	configuration: PodComponentConfiguration;

	constructor() {
	}

	addMenuItemClick(event: MenuEventArgs) {
		(event.item as ItemModelWithAction).selectCallback();
	}

	getDataTestId(name: string) {
		return this.configuration.podId + name;
	}

	onHeadingDoubleClick() {
		this.configuration.expandCallback?.();
	}

	showBasicAddButton() {
		return !this.configuration.addDropdown && !!this.configuration.addCallback;
	}
}
