/* eslint-disable @typescript-eslint/naming-convention */

import { PayerType } from '@gandalf/constants';
import { Action } from '@ngrx/store';
import { ReceivePaymentState } from '@payments-store/reducers/payment-tab.reducer';
import { ReceivePaymentsPayer } from '@accounting/invoices/receive-payments/receive-payments.service';

export enum PaymentTabActionTypes {
	ADD_PAYMENT_TAB = '[Add Payment Tab] Add Payment Tab to the Payments Tab Collection',
	REMOVE_PAYMENT_TAB = '[Remove Payment Tab] Remove Payment Tab from the Payments Tab Collection',
	SYNC_PAYMENT_TAB = '[Sync Payment Tab] Sync the Payment Tab Header and the Payment Tab Content',
	UPDATE_PAYMENT_TAB_CURRENT_ROUTE = '[Update Payment Tab Current Route] Update the Payment Tabs Current Route',
	UPDATE_PAYMENT_TAB_PAYER_SELECTION_STATE = '[Update Payment Tab Payer Selection] Update the Payment Tabs payer selection current state',
}

export class AddPaymentTab implements Action {
	readonly type = PaymentTabActionTypes.ADD_PAYMENT_TAB;
	constructor(public payload: { paymentId: number, payerType?: PayerType, locationId?: number }) {}
}

export class RemovePaymentTab implements Action {
	readonly type = PaymentTabActionTypes.REMOVE_PAYMENT_TAB;
	constructor(public payload: { paymentId: number }) {}
}

export class SyncPaymentTab implements Action {
	readonly type = PaymentTabActionTypes.SYNC_PAYMENT_TAB;
	constructor(public payload: { paymentId: number }) {}
}

export class UpdatePaymentTabCurrentRoute implements Action {
	readonly type = PaymentTabActionTypes.UPDATE_PAYMENT_TAB_CURRENT_ROUTE;
	constructor(public payload: { paymentId: number, currentRoute: string }) {}
}

export class UpdatePaymentTabPayerSelectionState implements Action {
	readonly type = PaymentTabActionTypes.UPDATE_PAYMENT_TAB_PAYER_SELECTION_STATE;
	constructor(public payload: {
		paymentId: number;
		payerType: PayerType;
		nameFilter: string;
		manuallySelect: boolean;
		payer?: ReceivePaymentsPayer;
		receivePaymentState?: ReceivePaymentState;
	}) {
	}
}

export type PaymentTabActions =
	AddPaymentTab
	| RemovePaymentTab
	| SyncPaymentTab
	| UpdatePaymentTabCurrentRoute
	| UpdatePaymentTabPayerSelectionState;
