<div class="text-center">
	<img [src]="imgSrc">
	<div class="btn-toolbar mrgn-top" role="toolbar">
		<button
			ejs-button
			iconCss="fa fa-camera"
			type="button"
			(click)="openWebcamModal()"
			revTooltip
			tooltipContent="Capture image from webcam">
		</button>
		<button
			ejs-button
			iconCss="fa fa-upload"
			type="button"
			(click)="fileUpload()"
			revTooltip
			tooltipContent="Upload image (.jpg, .gif, .png)">
		</button>
		<button
			[disabled]="noImage()"
			ejs-button
			type="button"
			iconCss="fa fa-search-plus"
			revTooltip
			tooltipContent="Magnify image"
			(click)="preview()">
		</button>
		<button
			[disabled]="noImage()"
			ejs-button
			type="button"
			iconCss="fa fa-close"
			revTooltip
			tooltipContent="Remove image"
			(click)="(updatePracticeFileId(null, true))">
		</button>
	</div>
</div>
