import { Component, OnInit, ViewChild } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { DynamicModalRef, MODAL, ModalConfig, ModalManagerService } from 'morgana';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
	selector: 'pms-invoice-details-modal',
	templateUrl: './invoice-details-modal.component.html',
	styles: [],
	providers: [ModalManagerService],
})

export class InvoiceDetailsModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	zIndexBehindHitPmsHtmlModal = MODAL.Z_INDEX_BEHIND_HIT_PMS_HTML_MODAL;
	invoiceId: number;
	isReadOnly = false;

	constructor(
		private ref: DynamicModalRef,
		private modalConfig: ModalConfig,
	) {
	}

	ngOnInit() {
		this.invoiceId = this.modalConfig.data.invoiceId;
		if (!_isNil(this.modalConfig.data.isReadOnly)) {
			this.isReadOnly = this.modalConfig.data.isReadOnly;
		}
	}

	closeModal(closeParent = false) {
		closeParent ? this.ref.close(this.modal, {closeParent: true}) : this.ref.close(this.modal);
	}
}
