// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ReferenceDataSummaryResponse } from './reference-data-summary-response';

/** See com.rev360.pms.api.controller.shared.patient.order.EyeglassOrderSearchResponse */
/* istanbul ignore next */
@GandalfModel
export class EyeglassOrderSearchResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	expirationDate: Date;

	@GandalfProperty()
	eyeglassPrescriptionId: number;

	@GandalfProperty()
	odAxis: number;

	@GandalfProperty()
	odBalance: boolean;

	@GandalfProperty()
	odCylinder: number;

	@GandalfProperty()
	odHorizontalPrism: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	odHorizontalPrismOrientation: constants.HorizontalPrismOrientation;

	@GandalfProperty()
	odNearAdd: number;

	@GandalfProperty()
	odSphere: number;

	@GandalfProperty()
	odVerticalPrism: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	odVerticalPrismOrientation: constants.VerticalPrismOrientation;

	@GandalfProperty()
	osAxis: number;

	@GandalfProperty()
	osBalance: boolean;

	@GandalfProperty()
	osCylinder: number;

	@GandalfProperty()
	osHorizontalPrism: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	osHorizontalPrismOrientation: constants.HorizontalPrismOrientation;

	@GandalfProperty()
	osNearAdd: number;

	@GandalfProperty()
	osSphere: number;

	@GandalfProperty()
	osVerticalPrism: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	osVerticalPrismOrientation: constants.VerticalPrismOrientation;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate: Date;

	@GandalfProperty()
	usedFor: ReferenceDataSummaryResponse;

}
