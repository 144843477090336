// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FieldValueMultiSelectionResponse } from './field-value-multi-selection-response';

/** See com.rev360.pms.api.controller.encounter.history.ros.ReviewOfSystemsResponse */
/* istanbul ignore next */
@GandalfModel
export class ReviewOfSystemsResponse extends GandalfModelBase {

	@GandalfProperty()
	cardiovascular: FieldValueMultiSelectionResponse;

	@GandalfProperty()
	comments: string;

	@GandalfProperty()
	constitutional: FieldValueMultiSelectionResponse;

	@GandalfProperty()
	endocrine: FieldValueMultiSelectionResponse;

	@GandalfProperty()
	ent: FieldValueMultiSelectionResponse;

	@GandalfProperty()
	gastrointestinal: FieldValueMultiSelectionResponse;

	@GandalfProperty()
	genitourinary: FieldValueMultiSelectionResponse;

	@GandalfProperty()
	hematLymphatic: FieldValueMultiSelectionResponse;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	immunological: FieldValueMultiSelectionResponse;

	@GandalfProperty()
	musculoskeletal: FieldValueMultiSelectionResponse;

	@GandalfProperty()
	neurological: FieldValueMultiSelectionResponse;

	@GandalfProperty()
	psychological: FieldValueMultiSelectionResponse;

	@GandalfProperty()
	respiratory: FieldValueMultiSelectionResponse;

	@GandalfProperty()
	skin: FieldValueMultiSelectionResponse;

}
