import { Injectable } from '@angular/core';
import { _isNaN, _isNil } from '@core/lodash/lodash';
import dayjs from 'dayjs';

const RADIX = 10;

@Injectable({
	providedIn: 'root',
})
export class TimepickerFormatterService {

	constructor() { }

	/**
	 * Retrieves the valid or invalid parsed time from the inputted value
	 */
	parseTime(value: string) {
		let parsedDate = null;
		value = value.toLowerCase().replace(/ /g, '');
		// Check if time is on the hour
		switch (value) {
			case '1':
			case '1a':
			case '1am':
				parsedDate = dayjs().startOf('day').hour(1);
				break;
			case '2':
			case '2a':
			case '2am':
				parsedDate = dayjs().startOf('day').hour(2);
				break;
			case '3':
			case '3a':
			case '3am':
				parsedDate = dayjs().startOf('day').hour(3);
				break;
			case '4':
			case '4a':
			case '4am':
				parsedDate = dayjs().startOf('day').hour(4);
				break;
			case '5':
			case '5a':
			case '5am':
				parsedDate = dayjs().startOf('day').hour(5);
				break;
			case '6':
			case '6a':
			case '6am':
				parsedDate = dayjs().startOf('day').hour(6);
				break;
			case '7':
			case '7a':
			case '7am':
				parsedDate = dayjs().startOf('day').hour(7);
				break;
			case '8':
			case '8a':
			case '8am':
				parsedDate = dayjs().startOf('day').hour(8);
				break;
			case '9':
			case '9a':
			case '9am':
				parsedDate = dayjs().startOf('day').hour(9);
				break;
			case '10':
			case '10a':
			case '10am':
				parsedDate = dayjs().startOf('day').hour(10);
				break;
			case '11':
			case '11a':
			case '11am':
				parsedDate = dayjs().startOf('day').hour(11);
				break;
			case '12':
			case '12a':
			case '12am':
				parsedDate = dayjs().startOf('day').hour(24);
				break;
			case '1p':
			case '1pm':
				parsedDate = dayjs().startOf('day').hour(13);
				break;
			case '2p':
			case '2pm':
				parsedDate = dayjs().startOf('day').hour(14);
				break;
			case '3p':
			case '3pm':
				parsedDate = dayjs().startOf('day').hour(15);
				break;
			case '4p':
			case '4pm':
				parsedDate = dayjs().startOf('day').hour(16);
				break;
			case '5p':
			case '5pm':
				parsedDate = dayjs().startOf('day').hour(17);
				break;
			case '6p':
			case '6pm':
				parsedDate = dayjs().startOf('day').hour(18);
				break;
			case '7p':
			case '7pm':
				parsedDate = dayjs().startOf('day').hour(19);
				break;
			case '8p':
			case '8pm':
				parsedDate = dayjs().startOf('day').hour(20);
				break;
			case '9p':
			case '9pm':
				parsedDate = dayjs().startOf('day').hour(21);
				break;
			case '10p':
			case '10pm':
				parsedDate = dayjs().startOf('day').hour(22);
				break;
			case '11p':
			case '11pm':
				parsedDate = dayjs().startOf('day').hour(23);
				break;
			case '12p':
			case '12pm':
				parsedDate = dayjs().startOf('day').hour(12);
				break;
			default:
				if (this.isValidTime(value)) {
					parsedDate = this.calculateTime(value);
				}
				break;
		}

		return parsedDate;
	}

	/**
	 * Calculates a time from an inputted string value.
	 * This accepts a format of any valid time (with or without a colon) and with or without an am/pm identifier
	 */
	calculateTime(value: string): dayjs.Dayjs {
		if (_isNil(parseInt(value, RADIX)) && !this.isAmOrPm(value)) {
			return null;
		}
		let calculatedDate = null;
		value = value.replace(':', '');
		const isTimeAM = this.isAM(value);
		value = this.normalizeInput(value);

		if (value.length === 4 || value.length === 3) {
			const parsedMinutes = this.getMinutes(value);
			let parsedHour = this.getHour(value);

			// special case with 12am or 12pm
			if (parsedHour === 12) {
				parsedHour = isTimeAM ? parsedHour + 12 : parsedHour;
			} else {
				parsedHour = isTimeAM ? parsedHour : parsedHour + 12;
			}

			calculatedDate = dayjs().startOf('day').hour(parsedHour).minute(parsedMinutes);
		}

		return calculatedDate;
	}

	/**
	 * Returns true if value ends with 'a', 'am', 'p' or 'pm
	 */
	isAmOrPm(value: string) {
		return value.endsWith('a') || value.endsWith('am') ||
			value.endsWith('p') || value.endsWith('pm');
	}

	/**
	 * Returns true if value ends with 'a', 'am' or is a strictly parsable number
	 */
	isAM(value: string) {
		return value.endsWith('a') || value.endsWith('am') || !_isNaN(Number(value)) ;
	}

	/**
	 * Removes any instance of 'a', 'am', 'p', or 'pm'
	 */
	normalizeInput(value: string) {
		return value.replace('am', '')
			.replace('a', '')
			.replace('pm', '')
			.replace('p', '');
	}

	/**
	 * parses the hour from a 4 or 3 digit integer-like string (12 hour time)
	 */
	getHour(value: string) {
		if (value.length !== 3 && value.length !== 4) {
			return null;
		}

		if (value.length === 4) {
			return Number(value.substring(0, 2));
		} else {
			return Number(value.substring(0, 1));
		}
	}

	/**
	 * parses the minutes from a 4 or 3 digit integer-like string
	 */
	getMinutes(value: string) {
		if (value.length !== 3 && value.length !== 4) {
			return null;
		}

		if (value.length === 4) {
			return Number(value.substring(2, 4));
		} else {
			return Number(value.substring(1, 3));
		}
	}

	/**
	 * Verifies via regex, whether the inputted string is a valid time format
	 */
	isValidTime(value: string) {
		const validTimeRegex = /^(10|11|12|0?[0-9])[:]?[0-5][0-9](a|am|pm|p)?$/i;
		return value.match(validTimeRegex) !== null;
	}

}
