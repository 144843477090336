import { Injectable } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { OptionItem, SortingService, YesNoPipe } from 'morgana';
import { CategoryEntityType } from '@gandalf/constants';
import { CategoryResponse } from '@gandalf/model/category-response';
import { CreateCategoryRequest } from '@gandalf/model/create-category-request';
import { FindCategoriesRequest } from '@gandalf/model/find-categories-request';
import { UpdateCategoryRequest } from '@gandalf/model/update-category-request';
import { UpdateCategorySortOrderRequest } from '@gandalf/model/update-category-sort-order-request';
import { CategoryGandalfService } from '@gandalf/services';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ShowSavedSuccessToast } from '../toaster/toaster-decorators';

export interface CategoryResponseForDropdown extends CategoryResponse, OptionItem {
	label: string;
}

export interface CategoryResponseForTable extends CategoryResponse {
	editableText: string;
}

@Injectable({
	providedIn: 'root',
})
export class CategoryService {

	constructor(
		private categoryGandalfService: CategoryGandalfService,
		private yesNoPipe: YesNoPipe,
	) {
	}

	private static sortCategories = (categories: CategoryResponse[]) => SortingService.sortBy(
		categories,
		['sortOrder', 'name', 'id'],
		['asc', 'asc', 'asc'],
	);

	private static sortCategoriesActiveFirst = (categories: CategoryResponse[]) => SortingService.sortBy(
		categories,
		['active','sortOrder', 'name', 'id'],
		['desc','asc', 'asc', 'asc'],
	);

	findByEntityTypeForDropdown(entityType: CategoryEntityType): Observable<CategoryResponseForDropdown[]> {
		if (_isNil(entityType)) {
			return of([]);
		}
		const request = new FindCategoriesRequest();
		request.entityType = entityType;
		return this.categoryGandalfService.findByEntityType(request).pipe(
			map(CategoryService.sortCategoriesActiveFirst),
			map(categories => categories.map(category => this.formatCategoryForDropdown(category))),
		);
	}

	findActiveByEntityType(entityType: CategoryEntityType): Observable<CategoryResponseForDropdown[]> {
		if (_isNil(entityType)) {
			return of([]);
		}
		const request = new FindCategoriesRequest();
		request.entityType = entityType;
		return this.categoryGandalfService.findActiveByEntityType(request).pipe(
			map(CategoryService.sortCategories),
			map(categories => categories.map(category => this.formatCategoryForDropdown(category))),
		);
	}

	findActiveByEntityTypeForTable(entityType: CategoryEntityType): Observable<CategoryResponseForTable[]> {
		if (_isNil(entityType)) {
			return of([]);
		}
		const request = new FindCategoriesRequest();
		request.entityType = entityType;
		return this.categoryGandalfService.findActiveByEntityType(request).pipe(
			map(CategoryService.sortCategories),
			map(categories => categories.map(category => this.formatCategoryForTable(category))),
		);
	}

	findByEntityTypeForTable(entityType: CategoryEntityType): Observable<CategoryResponseForTable[]> {
		if (_isNil(entityType)) {
			return of([]);
		}
		const request = new FindCategoriesRequest();
		request.entityType = entityType;
		return this.categoryGandalfService.findByEntityType(request).pipe(
			map(CategoryService.sortCategories),
			map(categories => categories.map(category => this.formatCategoryForTable(category))),
		);
	}

	@ShowSavedSuccessToast()
	createCategory(createRequest: CreateCategoryRequest) {
		return this.categoryGandalfService.createCategory(createRequest);
	}

	@ShowSavedSuccessToast()
	updateCategory(updateRequest: UpdateCategoryRequest) {
		return this.categoryGandalfService.updateCategory(updateRequest);
	}

	updateCategorySortOrder(categories: CategoryResponse[]): Observable<CategoryResponseForTable[]> {
		const requestArray = categories.map((category) => {
			const request = new UpdateCategorySortOrderRequest();
			request.categoryId = category.id;
			request.sortOrder = category.sortOrder;
			return request;
		});
		return this.categoryGandalfService.updateCategoryOrder(requestArray).pipe(
			map(CategoryService.sortCategories),
			map(sortedCategories => sortedCategories.map(category => this.formatCategoryForTable(category))),
		);
	}

	formatCategoryForTable(category: CategoryResponse): CategoryResponseForTable {
		const tableCategory = category as CategoryResponseForTable;
		tableCategory.editableText = this.yesNoPipe.transform(tableCategory.editable);
		return tableCategory;
	}

	formatCategoryForDropdown(category: CategoryResponse): CategoryResponseForDropdown {
		const formattedCategory = category as CategoryResponseForDropdown;
		formattedCategory.value = category.id;
		if (category.active) {
			formattedCategory.label = category.name;
		} else {
			formattedCategory.label = `(INACTIVE) ${category.name}`;
		}

		return formattedCategory;
	}

}
