// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { MasterDeviceResponse } from './master-device-response';

/** See com.rev360.pms.api.controller.shared.device.PracticeLocationDeviceResponse */
/* istanbul ignore next */
@GandalfModel
export class PracticeLocationDeviceResponse extends GandalfModelBase {

	@GandalfProperty()
	disablePreview: boolean;

	@GandalfProperty()
	displayName: string;

	@GandalfProperty()
	masterDevice: MasterDeviceResponse;

	@GandalfProperty()
	practiceLocationDeviceId: number;

}
