import { _isNil } from '@core/lodash/lodash';
import { InvoiceItemTaxResponse } from '@gandalf/model/invoice-item-tax-response';
import { Big } from 'big.js';
import { GridUtil } from 'morgana';

export class InvoiceItemPricingUtil {
	constructor(
	) {
		// sets the rounding mode of all round, toPrecision and toFixed usages
		Big.RM = Big.roundHalfUp;
	}

	/**
	 * Calculates the Subtotal Price using unitPrice x quantity
	 * unitPrice is rounded (half-up) to 2 decimal places before calculated
	 */
	static calculateSubTotal(unitPrice: Big | number, quantity: Big | number): Big {
		if (_isNil(unitPrice) || _isNil(quantity)) {
			return Big(0);
		}
		this.throwExceptionIfDecimal(quantity);

		// unitPrice and quantity need to be rounded before calculations are done
		const unitPriceWithPrecision = GridUtil.roundCurrency(unitPrice);
		const subTotal = unitPriceWithPrecision.times(quantity);
		return GridUtil.roundCurrency(subTotal);
	}

	static calculateTaxableAmount(subTotal: Big | number, discount: Big | number): Big {
		const subTotalWithPrecision = GridUtil.roundCurrency(subTotal);
		const discountWithPrecision = GridUtil.roundCurrency(discount);

		// discounts should always be negative, performing an absolute value on discount will ensure we always are discounting an item
		return subTotalWithPrecision.minus(discountWithPrecision.abs());
	}

	/**
	 * Calculates the total taxes given a total and a tax rate
	 * taxRate is the total tax percentage (not the decimal value)
	 *
	 * All calculations are rounded (half-up)
	 */
	static calculateTaxAmount(taxableAmount: Big | number, taxRate: Big | number): Big {
		return this.calculatePercentOf(taxableAmount, taxRate);
	}

	/**
	 * Calculates the percent of some amount provided
	 * percent is not in decimal form
	 * All calculations are rounded (half-up)
	 */
	static calculatePercentOf(amount: Big | number, percent: Big | number): Big {
		if (_isNil(amount) || _isNil(percent)) {
			return Big(0);
		}

		// No precision rounding is done on percent
		const taxDecimal = Big(percent).div(100);
		const subTotalWithPrecision = GridUtil.roundCurrency(amount);

		const totalTax = subTotalWithPrecision.times(taxDecimal);
		return GridUtil.roundCurrency(totalTax);
	}

	/**
	 * Calculates total Ext price by using subTotal, discountAmount and known tax detail responses
	 * All values coming in and out (except taxRate) are rounded (half-up)
	 */
	static calculateExtPriceWithTaxes(unitPrice: Big | number, quantity: Big | number,  discount?: Big | number, itemTaxes?: InvoiceItemTaxResponse[]): Big {
		if (_isNil(unitPrice) || _isNil(quantity)) {
			return Big(0);
		}
		this.throwExceptionIfDecimal(quantity);

		const unitPriceWithPrecision = GridUtil.roundCurrency(unitPrice);
		const discountWithPrecision = GridUtil.roundCurrency(discount);

		const subTotal = this.calculateSubTotal(unitPriceWithPrecision, quantity);
		const taxableAmount = this.calculateTaxableAmount(subTotal, discountWithPrecision);
		const subTotalAfterDiscounts = taxableAmount;
		const totalTaxes = _isNil(itemTaxes) ? 0 : GridUtil.sumCurrencyItems(itemTaxes, 'taxAmount');

		const extPrice = subTotalAfterDiscounts.plus(totalTaxes);
		return GridUtil.roundCurrency(extPrice);
	}

	/**
	 * Calculates total Ext price by using subTotal, discountAmount and tax calculations
	 * All values coming in and out (except taxRate) are rounded (half-up)
	 */
	static calculateExtPrice(unitPrice: Big | number, quantity: Big | number,  discount?: Big | number, taxRate?: Big | number): Big {
		if (_isNil(unitPrice) || _isNil(quantity)) {
			return Big(0);
		}
		this.throwExceptionIfDecimal(quantity);

		const unitPriceWithPrecision = GridUtil.roundCurrency(unitPrice);
		const discountWithPrecision = GridUtil.roundCurrency(discount);

		const subTotal = this.calculateSubTotal(unitPriceWithPrecision, quantity);
		const taxableAmount = this.calculateTaxableAmount(subTotal, discountWithPrecision);
		const subTotalAfterDiscounts = taxableAmount;
		const totalTaxes = this.calculateTaxAmount(taxableAmount, taxRate);

		const extPrice = subTotalAfterDiscounts.plus(totalTaxes);
		return GridUtil.roundCurrency(extPrice);
	}

	/**
	 * Calculates the balance from the extPrice and adjustments
	 */
	static calculateBalance(extPrice: Big | number, adjustments: Big | number): Big {
		if (_isNil(extPrice)) {
			extPrice = Big(0);
		}
		if (_isNil(adjustments)) {
			adjustments = Big(0);
		}
		const extPriceWithPrecision = GridUtil.roundCurrency(extPrice);
		const adjustmentsWithPrecision = GridUtil.roundCurrency(adjustments);

		return extPriceWithPrecision.plus(adjustmentsWithPrecision);
	}

	/**
	 * Combines and existing discount amount with a new discount
	 * This will always result in a negative by adding the two arguments absolute values and subtracting it from 0
	 */
	static combineDiscounts(existingDiscount: Big | number, addedDiscount: Big | number): Big {
		if (_isNil(existingDiscount)) {
			existingDiscount = Big(0);
		}
		if (_isNil(addedDiscount)) {
			addedDiscount = Big(0);
		}
		const existingDiscountWithPrecision = GridUtil.roundCurrency(existingDiscount);
		const addedDiscountWithPrecision = GridUtil.roundCurrency(addedDiscount);

		return Big(0).minus(existingDiscountWithPrecision.abs().plus(addedDiscountWithPrecision.abs()));
	}

	/**
	 * Gets total for array of values
	 */
	static sumBalances(balances: (Big | number)[]): Big {
		return Big(balances.reduce(
			(sum, item) => {
				const existingSum = GridUtil.roundCurrency(sum);
				const newItem = GridUtil.roundCurrency(item);
				return existingSum.plus(newItem);
			}, 0,
		));
	}

	/**
	 * Check if the provided values are equal
	 */
	static checkItemEquality(item1: Big | number, item2: Big | number) {
		if (_isNil(item1)) {
			return false;
		}
		if (_isNil(item2)) {
			return false;
		}
		return GridUtil.roundCurrency(item1).eq(GridUtil.roundCurrency(item2));
	}

	static throwExceptionIfDecimal(value: Big | number) {
		if (_isNil(value)) {
			return;
		}

		const roundedValue = Big(value).round(0, Big.roundDown);
		if (!roundedValue.eq(Big(value))) {
			throw new Error(`Value ${value} must be a whole number`);
		}
	}
}
