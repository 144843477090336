import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { PatientDuplicateMatchType } from '@gandalf/constants';
import { PatientDuplicateSearchResponse } from '@gandalf/model/patient-duplicate-search-response';
import { CanadaLabelPipe } from '@shared/pipes/canada-label/canada-label.pipe';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
	selector: 'pms-duplicate-patients-modal',
	templateUrl: './duplicate-patients-modal.component.html',
	styles: [],
})
export class DuplicatePatientsModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	duplicates: PatientDuplicateSearchResponse[];
	patientDuplicateMatchType = PatientDuplicateMatchType;
	constructor(
		private ref: DynamicModalRef,
		private modalConfig: ModalConfig,
		private canadaLabelPipe: CanadaLabelPipe,
	) { }

	ngOnInit() {
		this.duplicates = this.modalConfig.data.duplicatePatients;
	}

	createPatient() {
		this.closeModal(true);
	}

	/* istanbul ignore next */
	closeModal(shouldCreatePatient = false) {
		this.ref.close(this.modal, shouldCreatePatient);
	}

	getLast4Ssn(match: PatientDuplicateSearchResponse) {
		if (match.last4Ssn) {
			return `xxx-xx-${match.last4Ssn}`;
		}

		if (match.ssn) {
			return match.ssn;
		}

		return '';
	}

	getMatchTypeLabel(matchType: PatientDuplicateMatchType): string {
		switch (matchType.value) {
			case PatientDuplicateMatchType.SSN.value:
				return this.canadaLabelPipe.transform(matchType.label, 'SIN/PHN match');
			case PatientDuplicateMatchType.LAST_4_SSN.value:
				return this.canadaLabelPipe.transform(matchType.label, 'Last name + Last 4 SIN/PHN match');
			default:
				return matchType.label;
		}
	}
}
