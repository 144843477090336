/* istanbul ignore file */
import { selectRouterState } from '@app-store/reducers';
import { createSelector } from '@ngrx/store';

export enum RouterQueryParam {
	CONSENTS_PRACTICE_CONSENT_FORM_ID = 'practiceConsentFormId',
}

export enum RouterParam {
	CLAIMS_CLAIM_ID = 'claimsClaimId',
	ENCOUNTERS_APPOINTMENT_ID = 'encountersAppointmentId',
	ENCOUNTERS_ENCOUNTER_ID = 'encountersEncounterId',
	ENCOUNTERS_WORKFLOW_SCREEN_ID = 'encountersWorkflowScreenId',
	MESSAGING_MESSAGE_ID = 'messagingMessageId',
	PATIENTS_PATIENT_ID = 'patientsPatientId',
	PATIENTS_ACCOUNT_TAB_ID = 'patientAccountTabId',
	PATIENTS_CONSENTS_CONSENT_ID = 'patientsConsentsConsentId',
	PATIENTS_DRUG_ALLERGY_ID = 'patientsDrugAllergyId',
	PATIENTS_OTHER_ALLERGY_ID = 'patientsOtherAllergyId',
	PATIENTS_FAMILY_HEALTH_HISTORY_ID = 'patientsFamilyHealthHistoryId',
	PATIENTS_RX_PRESCRIPTION_ID = 'patientsRxPrescriptionId',
	PATIENTS_RX_REFILL = 'patientsRxRefill',
	PATIENTS_CONTACT_LENS_ID = 'patientsContactLensId',
	INVOICES_INVOICE_ID = 'invoicesInvoiceId',
	INVOICES_INVOICE_PAYMENT_ID = 'invoicesInvoicePaymentId',
	ORDERS_ORDER_ID = 'ordersOrderId',
	PAYMENTS_PAYMENT_ID = 'paymentsPaymentId',
	PATIENTS_SURGERY_HISTORY_ID = 'patientsSurgeryHistoryId',
	PATIENTS_AMENDMENTS_AMENDMENT_ID = 'patientsAmendmentsAmendmentId',
	PATIENTS_ALERTS_ALERT_ID = 'patientsAlertsAlertId',
	PATIENTS_EXTERNAL_DATA_ID = 'patientsExternalDataId',
	PATIENTS_HEALTHGOALS_HEALTHGOAL_ID = 'patientsHealthGoalsHealthGoalId',
	PATIENTS_TASKS_TASK_ID = 'patientTasksTaskId',
	PATIENTS_HEALTH_CONCERNS_ID = 'patientsHealthConcernsId',
	PATIENTS_RECALLS_RECALL_ID = 'patientsRecallsRecallId',
	PATIENTS_NOTE_ID = 'patientsNoteId',
	PATIENTS_IMPLANTABLE_DEVICES_ID = 'patientsImplantableDevicesId',
	PATIENTS_LATEST_FINDINGS_TAB_ID = 'patientLatestFindingsTabId',
	PATIENTS_IMMUNIZATIONS_ID = 'patientImmunizationsId',
}

export enum RouterDataKey {
	PATIENTS_FAMILY_HEALTH_HISTORY_CONDITION_TYPE = 'familyHealthHistoryConditionType',
	PATIENTS_POD_PARENT = 'podParent',
}

export const selectRouterParams = createSelector(selectRouterState, state => state.state.params);
