import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NgForm } from '@angular/forms';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { InsuranceService } from '@core/insurance/insurance.service';
import { _isNil } from '@core/lodash/lodash';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { InvoiceResponse } from '@gandalf/model/invoice-response';
import { PatientPortionRequest } from '@gandalf/model/patient-portion-request';
import { PersonInsuranceResponse } from '@gandalf/model/person-insurance-response';
import { conditionallyRequiredValidator } from '@shared/validators/conditionally-required-validation';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { assertTrue } from '@shared/validators/assert-true.validation';
import { AccountingService, FormattedInvoiceDateResponse } from '../../core/accounting/accounting.service';
import { InvoiceItemPricingUtil } from '../../core/accounting/invoice-item-pricing/invoice-item-pricing-util';
import { InvoiceService } from '../../core/accounting/invoice-service/invoice.service';

@Component({
	selector: 'pms-patient-portion-modal',
	templateUrl: './patient-portion-modal.component.html',
	styles: [],
})
export class PatientPortionModalComponent implements OnInit, OnDestroy {
	private unsubscribe$ = new Subject<void>();

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('templateForm')
	templateForm: NgForm;

	request: PatientPortionRequest;
	formGroup: UntypedFormGroup;
	invoiceId: number;
	patientId: number;
	transferTaxItemCount: number;
	personInsuranceId: number;
	personInsurance: PersonInsuranceResponse;
	currentInvoiceTotal: number;
	allowTransferTax: boolean;
	transferTaxInvoiceId: number;
	transferTaxInvoiceIdIsValid: boolean;
	transferToInvoiceList: FormattedInvoiceDateResponse[];
	TRANSFER_TO_NEW_INVOICE_LABEL = 'new';
	TRANSFER_TO_EXISTING_INVOICE_LABEL = 'existing';
	showTransferToExistingInvoiceOption = false;

	constructor(
		private ref: DynamicModalRef,
		public modalConfig: ModalConfig,
		public formBuilder: GandalfFormBuilder,
		public invoiceService: InvoiceService,
		private insuranceService: InsuranceService,
		private accountingService: AccountingService,
	) {
	}

	ngOnInit() {
		this.parseConfigData(this.modalConfig.data);

		this.getData();
		this.buildRequest();
		this.buildForm();
	}

	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	parseConfigData(data: any) {
		this.invoiceId = data.invoiceId;
		this.personInsuranceId = data.personInsuranceId;
		this.patientId = data.patientId;
		this.transferTaxItemCount = data.transferTaxItemCount;
		this.currentInvoiceTotal = data.currentInvoiceTotal;
		this.allowTransferTax = data.allowTransferTax;
		this.transferTaxInvoiceId = data.transferTaxInvoiceId;
		this.transferTaxInvoiceIdIsValid = !_isNil(this.transferTaxInvoiceId) && this.transferTaxInvoiceId !== 0;
	}

	getData() {
		combineLatest([
			this.insuranceService.getPersonInsuranceById(this.personInsuranceId),
			this.accountingService.findPatientPendingInvoicesForDropdown(this.patientId),
		]).subscribe(([personInsurance, patientPendingInvoices]) => {
			this.personInsurance = personInsurance;
			this.transferToInvoiceList = patientPendingInvoices;
			this.handlePendingPatientInvoices();
			this.initForm();
		});
	}

	handlePendingPatientInvoices() {
		if (this.transferToInvoiceList.length > 0) {
			this.showTransferToExistingInvoiceOption = true;
		}
	}

	/* istanbul ignore next */
	closeModal() {
		this.ref.close(this.modal);
	}

	buildRequest() {
		this.request = new PatientPortionRequest();
		this.request.invoiceId = this.invoiceId;
	}

	isPatientPortionSelected(): boolean {
		return !!this.formGroup.get('generalCoPaySelected').value
			|| !!this.formGroup.get('medicalExamCoPaySelected').value
			|| !!this.formGroup.get('routineExamCoPaySelected').value
			|| !!this.formGroup.get('contactLensFittingCoPaySelected').value
			|| !!this.formGroup.get('materialCoPaySelected').value
			|| !!this.formGroup.get('coInsuranceSelected').value
			|| !!this.formGroup.get('deductibleSelected').value
			|| !!this.formGroup.get('removeTaxesSelected').value
			|| !!this.formGroup.get('transferTaxesSelected').value;
	}

	buildForm() {
		this.formGroup = this.formBuilder.group(this.request, {
			validators: [
				assertTrue(
					() => this.formGroup && this.isPatientPortionSelected(),
					[],
					'PatientPortionRequired',
					'Patient Portion selection is required',
				),
				conditionallyRequiredValidator(
					'generalCoPay',
					() => this.formGroup && this.formGroup.get('generalCoPaySelected').value,
					'generalCoPayRequired',
					'General Co-Pay value is required',
				),
				conditionallyRequiredValidator(
					'medicalExamCoPay',
					() => this.formGroup && this.formGroup.get('medicalExamCoPaySelected').value,
					'medicalExamCoPayRequired',
					'Medical Exam Co-Pay value is required',
				),
				conditionallyRequiredValidator(
					'routineExamCoPay',
					() => this.formGroup && this.formGroup.get('routineExamCoPaySelected').value,
					'routineExamCoPayRequired',
					'Routine Exam Co-Pay value is required',
				),
				conditionallyRequiredValidator(
					'contactLensFittingCoPay',
					() => this.formGroup && this.formGroup.get('contactLensFittingCoPaySelected').value,
					'contactLensFittingCoPayRequired',
					'Contact Lens Fitting Co-Pay value is required',
				),
				conditionallyRequiredValidator(
					'materialCoPay',
					() => this.formGroup && this.formGroup.get('materialCoPaySelected').value,
					'materialCoPayRequired',
					'Material Co-Pay value is required',
				),
				conditionallyRequiredValidator(
					'coInsuranceAmount',
					() => this.formGroup && this.formGroup.get('coInsuranceSelected').value,
					'coInsuranceAmountRequired',
					'Co Insurance value is required',
				),
				conditionallyRequiredValidator(
					'deductible',
					() => this.formGroup && this.formGroup.get('deductibleSelected').value,
					'deductibleRequired',
					'Deductible value is required',
				),
			],
		});
		this.formGroup.addControl('transferToNewOrExistingInvoice', new UntypedFormControl());
		this.formGroup.addControl('coInsurancePercentage', new UntypedFormControl());
		this.handleFormState();
	}

	initForm() {
		this.formGroup.get('generalCoPay').setValue(this.getCoPayAmountHelper(this.personInsurance.generalCoPay, this.personInsurance.policyGeneralCoPay));
		this.formGroup.get('generalCoPaySelected').setValue(false);
		this.formGroup.get('medicalExamCoPay').setValue(
			this.getCoPayAmountHelper(this.personInsurance.medicalExamCoPay, this.personInsurance.policyMedicalExamCoPay),
		);
		this.formGroup.get('medicalExamCoPaySelected').setValue(false);
		this.formGroup.get('routineExamCoPay').setValue(
			this.getCoPayAmountHelper(this.personInsurance.routineExamCoPay, this.personInsurance.policyRoutineExamCoPay),
		);
		this.formGroup.get('routineExamCoPaySelected').setValue(false);
		this.formGroup.get('contactLensFittingCoPay').setValue(
			this.getCoPayAmountHelper(this.personInsurance.contactLensFittingCoPay, this.personInsurance.policyContactLensFittingCoPay),
		);
		this.formGroup.get('contactLensFittingCoPaySelected').setValue(false);
		this.formGroup.get('materialCoPay').setValue(this.getCoPayAmountHelper(this.personInsurance.materialCoPay, this.personInsurance.policyMaterialCoPay));
		this.formGroup.get('materialCoPaySelected').setValue(false);
		this.formGroup.get('coInsuranceAmount').setValue(0);
		this.formGroup.get('coInsurancePercentage').setValue(this.personInsurance.coInsurancePercentage);
		this.formGroup.get('coInsuranceSelected').setValue(false);
		this.formGroup.get('deductible').setValue(this.personInsurance.deductible);
		this.formGroup.get('deductibleSelected').setValue(false);
		this.formGroup.get('transferTaxesSelected').setValue(false);
		this.formGroup.get('removeTaxesSelected').setValue(false);

		if (this.showTransferToExistingInvoiceOption) {
			this.formGroup.get('transferToNewOrExistingInvoice').setValue(this.TRANSFER_TO_EXISTING_INVOICE_LABEL);
			this.formGroup.get('transferToExistingInvoiceId').setValue(this.transferToInvoiceList[0].value);
		} else {
			this.formGroup.get('transferToNewOrExistingInvoice').setValue(this.TRANSFER_TO_NEW_INVOICE_LABEL);
		}
	}

	getCoPayAmountHelper(individualAmount: number, policyAmount: number) {
		return !_isNil(individualAmount) ? individualAmount : policyAmount;
	}

	handleFormState() {
		this.formGroup.get('generalCoPaySelected').valueChanges
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(val => {
				FormUtilsService.disabledWhen(
					this.formGroup.get('generalCoPay'),
					val === false,
				);
			});

		this.formGroup.get('medicalExamCoPaySelected').valueChanges
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(val => {
				FormUtilsService.disabledWhen(
					this.formGroup.get('medicalExamCoPay'),
					val === false,
				);
			});

		this.formGroup.get('routineExamCoPaySelected').valueChanges
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(val => {
				FormUtilsService.disabledWhen(
					this.formGroup.get('routineExamCoPay'),
					val === false,
				);
			});

		this.formGroup.get('contactLensFittingCoPaySelected').valueChanges
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(val => {
				FormUtilsService.disabledWhen(
					this.formGroup.get('contactLensFittingCoPay'),
					val === false,
				);
			});

		this.formGroup.get('materialCoPaySelected').valueChanges
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(val => {
				FormUtilsService.disabledWhen(
					this.formGroup.get('materialCoPay'),
					val === false,
				);
			});

		this.formGroup.get('coInsuranceSelected').valueChanges
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(val => {
				FormUtilsService.disabledWhen(
					this.formGroup.get('coInsurancePercentage'),
					val === false,
				);
				FormUtilsService.disabledWhen(
					this.formGroup.get('coInsuranceAmount'),
					val === false,
				);
			});

		this.formGroup.get('deductibleSelected').valueChanges
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(val => {
				FormUtilsService.disabledWhen(
					this.formGroup.get('deductible'),
					val === false,
				);
			});

		this.formGroup.get('coInsurancePercentage').valueChanges
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(val => {
				this.formGroup.get('coInsuranceAmount').setValue(InvoiceItemPricingUtil.calculatePercentOf(this.currentInvoiceTotal, val));
			});

		this.formGroup.get('transferToNewOrExistingInvoice').valueChanges
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(val => {
				this.formGroup.get('transferToExistingInvoiceId').enable();
				FormUtilsService.disabledWhen(
					this.formGroup.get('transferToExistingInvoiceId'),
					val === this.TRANSFER_TO_NEW_INVOICE_LABEL,
				);
			});
	}

	/* istanbul ignore next */
	submitForm(event) {
		this.templateForm.onSubmit(event);
	}

	savePatientPortion() {
		if (this.formGroup.invalid) {
			return;
		}

		const patientPortionrequest: PatientPortionRequest = this.formGroup.getRawValue();
		// if transfer to new or existing is 'new' don't send the existing invoice id
		patientPortionrequest.transferToExistingInvoiceId = patientPortionrequest['transferToNewOrExistingInvoice'] !== this.TRANSFER_TO_NEW_INVOICE_LABEL
			? patientPortionrequest.transferToExistingInvoiceId
			: null;

		this.accountingService.addPatientPortion(patientPortionrequest).subscribe((data) => {
			this.refreshInvoices(data);
			this.closeModal();
		});
	}

	refreshInvoices(invoices: InvoiceResponse[]) {
		invoices.forEach(invoiceResponse => {
			this.invoiceService.refreshInvoice(invoiceResponse.id);
		});
	}

}
