import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Optional,
	Output,
	Self,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { _escape, _filter, _isNil, _map } from '@core/lodash/lodash';
import { ObjectUtils, Tooltip, TooltipService } from 'morgana';
import { BaseComponent } from '@shared/component/base.component';

@Component({
	selector: 'pms-multiselect',
	templateUrl: './multiselect.component.html',
	styles: [
	],
})
export class MultiselectComponent extends BaseComponent implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor, OnDestroy {

	@ViewChild('matSelect')
	// eslint-disable-next-line deprecation/deprecation
	matSelect: MatSelect;

	@ViewChild('tooltipElement', { read: ElementRef, static: true })
	tooltipElement: ElementRef;

	@Input()
	panelClass = 'mat-select-panel-normal';

	@Input()
	inputPlaceholder: string;

	@Input()
	filterBarPlaceholder: string;

	@Input()
	dataSource: any[];

	@Input()
	showSelectAll = true;

	@Input()
	labelField = 'label';

	@Input()
	valueField = 'value';

	@Input()
	abbreviationField = 'abbreviation';

	@Input()
	cssClass: string;

	@Input()
	get shouldFocus(): boolean {
		return this._shouldFocus;
	}
	set shouldFocus(value: boolean) {
		this._shouldFocus = value;
		if (this.shouldFocus) {
			this.matSelect?.focus();
		}
	}
	private _shouldFocus = false;

	@Output()
	inputFocus = new EventEmitter<void>();

	@Output()
	cleared = new EventEmitter<void>();

	@Output()
	valueChanges = new EventEmitter<any[]>();

	disabled = false;

	focusSearchBar = 0;

	filterText = '';

	filteredOptions: any[];

	private tooltip: Tooltip;

	private tooltipValues: string[];

	private isSelectOpen = false;

	get values() {
		return this.ngControl?.control?.value;
	}

	get selectedOptions() {
		return _filter(this.dataSource, (option) => this.values?.indexOf(option[this.valueField]) !== -1);
	}

	get selectedLabels() {
		return _map(this.selectedOptions, option => _escape(option[this.labelField]));
	}

	get selectedAbbreviations() {
		return _map(this.selectedOptions, option => {
			if (!ObjectUtils.isNilOrEmpty(option[this.abbreviationField])) {
				return option[this.abbreviationField];
			}
			return option[this.labelField];
		});
	}

	get isIntermediate() {
		return this.ngControl?.control?.value?.length > 0 && this.ngControl?.control?.value?.length < this.dataSource?.length;
	}

	get allSelected() {
		return !_isNil(this.ngControl) && !_isNil(this.ngControl.control.value) && (this.ngControl.control.value.length === this.dataSource?.length);
	}

	constructor(
		private tooltipService: TooltipService,
		@Optional() @Self() public ngControl: NgControl,
	) {
		super();
		this.ngControl.valueAccessor = this;
	}

	ngOnInit(): void {
		this.initializeTooltip();
	}

	ngAfterViewInit() {
		this.handleFormUpdates();
		this.checkShouldFocus();
	}

	onModelChange: (_: any) => void = () => {
	};

	onModelTouched: () => void = () => {
	};

	writeValue(_value: any): void {
	}

	registerOnChange(fn: (_: any) => void): void {
		this.onModelChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onModelTouched = fn;
	}

	/**
	 * If the dataSource changes, the tooltip may need to updated if additional selected options are now present
	 */
	ngOnChanges(simpleChanges: SimpleChanges) {
		if (simpleChanges?.dataSource?.currentValue !== simpleChanges?.dataSource?.previousValue) {
			this.updateTooltip();
		}
	}

	ngOnDestroy() {
		if (!_isNil(this.tooltip)) {
			this.tooltip.instance.destroy();
		}
	}

	/**
	 * Tracks changes to the control and updates the tooltip if applicable
	 */
	handleFormUpdates() {
		FormUtilsService.reactToValueChanges(
			this.ngControl.control,
			() => {
				// only update the tooltip if changes are made with the select popup closed
				if (!this.isSelectOpen) {
					this.updateTooltip();
				}

				this.valueChanges.emit(this.values);
			},
			false,
			this.unsubscribe,
		);
	}

	/**
	 * Checks shouldFocus input to autofocus on the mat-select by default
	 */
	checkShouldFocus() {
		if (this.shouldFocus) {
			this.matSelect.focus();
		}
	}

	/**
	 * Handles select and deselect all checkbox. Marks the control dirty
	 */
	// eslint-disable-next-line deprecation/deprecation
	toggleSelection(change: MatCheckboxChange) {
		const values = _map(this.dataSource, this.valueField);

		if (change.checked) {
			this.ngControl.control.setValue(values);
		} else {
			this.ngControl.control.setValue(null);
		}

		this.ngControl.control.markAsDirty();
	}

	trackByValue(index: number, item: any) {
		return item[this.valueField];
	}

	onFocus() {
		this.inputFocus.emit();
	}

	/**
	 * Clears any selected options and marks control as dirty
	 */
	clear($event) {
		if (!_isNil($event)) {
			$event.stopPropagation();
		}

		this.ngControl.control.setValue(null);
		this.ngControl.control.markAsDirty();

		this.cleared.emit();
	}

	/**
	 * Case insensitive search that searches all available labels in the dataSource
	 */
	filter() {
		this.filteredOptions = _map(this.dataSource, option => {
			if (option[this.labelField].toLowerCase().search(this.filterText.toLowerCase()) === -1) {
				option.hide = true;
			} else {
				option.hide = false;
			}

			return option;
		});
	}

	/**
	 * Prevents multiselect from stealing search bar focus
	 */
	onSpacebarKeydown(event) {
		event.stopImmediatePropagation();
	}

	/**
	 * If dropdown menu is open, it will autofocus on the filter bar (if present)
	 * If dropdown menu is closed, it resets any filter text and checks if tooltip content needs to be applied
	 */
	onOpenChanged(isOpen) {
		this.isSelectOpen = isOpen;

		if (isOpen) {
			this.focusSearchBar++;
		} else {
			this.filterText = '';
			this.filteredOptions = null;
			this.updateTooltip();
		}
	}

	/**
	 * Determines if new labels need to be updated on the tooltip and adds them
	 */
	updateTooltip() {
		const newSelectedLabels = this.selectedLabels;
		// check if tooltip content has changed
		if (this.tooltipValues !== newSelectedLabels && !_isNil(this.tooltip)) {
			this.tooltipValues = newSelectedLabels;
			this.tooltip.setContent(this.tooltipValues.join('<br>'));
		}
	}

	/**
	 * Initializes the tooltip based on the currently selected labels
	 */
	initializeTooltip() {
		this.tooltipValues = !_isNil(this.selectedLabels) ? this.selectedLabels : [];
		this.tooltip = this.tooltipService.buildTooltip(this.tooltipValues.join('<br>'), this.tooltipElement.nativeElement, null, {}, true);
	}

	/**
	 * By default mat-select will cycle through options with the left arrow, this will prevent the left arrow usage
	 * inside of the mat-select
	 */
	/* istanbul ignore next */
	onKeydownLeftArrow(event) {
		event.stopPropagation();
	}

	/**
	 * By default mat-select will cycle through options with the right arrow, this will prevent the right arrow usage
	 * inside of the mat-select
	 */
	/* istanbul ignore next */
	onKeydownRightArrow(event) {
		event.stopPropagation();
	}

	/**
	 * By default mat-select will tab through options in the dropdown, this forces the dropdown menu to close and focus
	 * the select component
	 */
	onKeyDownTab(event) {
		if (this.matSelect.panelOpen) {
			this.matSelect.close();
			this.matSelect.focus();
			event.stopPropagation();
		}
	}

	/* istanbul ignore next */
	isDisabled() {
		return this.ngControl?.control?.disabled;
	}

}

