<ejs-dialog
	#modal
	[showCloseIcon]="!isWaitingOnReader()"
	[closeOnEscape]="false"
	(close)="closeModal()"
	[attr.data-test-id]="'openEdgeCardReadModal'">
	<ng-template #header>
		<div class="dlg-template">Process {{ cardReaderMode }}</div>
	</ng-template>
	<ng-template #content>
		<div class="text-center">
			@if (isLoading()) {
				<ng-container [ngTemplateOutlet]="loadingIcon"></ng-container>
			}
			@if (isWaitingOnReader()) {
				<ng-container [ngTemplateOutlet]="waiting"></ng-container>
			}
			@if (isError()) {
				<ng-container [ngTemplateOutlet]="error"></ng-container>
			}
		</div>

		<ng-template #waiting>
			<ng-container *ngTemplateOutlet="staticInfo"></ng-container>
			<h4 class="margin-x-md">Please swipe/insert card...</h4>
			<ng-container *ngTemplateOutlet="loadingIcon"></ng-container>
		</ng-template>

		<ng-template #error>
			<div class="text-left alert alert-danger">
				<ul>
					<li><strong>{{ errorMessage }}</strong></li>
				</ul>
			</div>
			@if (isCardSwiped()) {
				<div class="text-left alert alert-warning">
					<strong>Please remove the credit card from the reader and click Retry.</strong>
				</div>
			}
			<ng-container *ngTemplateOutlet="staticInfo"></ng-container>
		</ng-template>

		<ng-template #staticInfo>
			<p>Total Amount<strong class="margin-left-sm">{{ totalAmount | currency : 'USD' }}</strong></p>
			<p>Reader<strong class="margin-left-sm">{{ reader.name }}</strong></p>
		</ng-template>

		<ng-template #loadingIcon>
			<div class="card_loader"></div>
			@if (showSaveCardOption()) {
				<div class="margin-top-lg">
					<ejs-checkbox #checkbox [disabled]="isApproved" label="Save credit card on file"
								  [attr.data-test-id]="'openEdgeCardReadSaveCCOptionCheckbox'" [(ngModel)]="saveCreditCard"></ejs-checkbox>
				</div>
			}
		</ng-template>
	</ng-template>
	<ng-template #footerTemplate>
		@if (!isWaitingOnReader()) {
			<button ejs-button iconCss="fa fa-times" type="button" [attr.data-test-id]="'openEdgeCardReadModalCancelButton'" (click)="closeModal()">
				Cancel
			</button>
		}
		@if (isError()) {
			<button ejs-button [isPrimary]="true" type="button" [attr.data-test-id]="'openEdgeCardReadModalSaveButton'" (click)="setupTransaction()">
				Retry
			</button>
		}
	</ng-template>
</ejs-dialog>
