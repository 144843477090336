// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressOptionalRequest } from './address-optional-request';

// @ts-ignore
import { PatientAdditionalInformationRequest } from './patient-additional-information-request';

// @ts-ignore
import { PatientBasicInformationRequest } from './patient-basic-information-request';

// @ts-ignore
import { PatientCommunicationPreferencesRequest } from './patient-communication-preferences-request';

// @ts-ignore
import { PatientEmploymentRequest } from './patient-employment-request';

// @ts-ignore
import { PatientOtherInformationRequest } from './patient-other-information-request';

// @ts-ignore
import { PatientPhoneInformationRequest } from './patient-phone-information-request';

// @ts-ignore
import { PatientPortalAccessRequest } from './patient-portal-access-request';

// @ts-ignore
import { PatientReferralRequest } from './patient-referral-request';

/** See com.rev360.pms.api.controller.patient.CreatePatientRequest */
/* istanbul ignore next */
@GandalfModel
export class CreatePatientRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Address is Required' } })
	@GandalfProperty({ isRequired: true })
	address: AddressOptionalRequest;

	@GandalfValidator({ notNull: { message: 'Additional Information is Required' } })
	@GandalfProperty({ isRequired: true })
	patientAdditionalInformation: PatientAdditionalInformationRequest;

	@GandalfValidator({ notNull: { message: 'Basic Information is required' } })
	@GandalfProperty({ isRequired: true })
	patientBasicInformation: PatientBasicInformationRequest;

	@GandalfValidator({ notNull: { message: 'Communication Preferences is Required' } })
	@GandalfProperty({ isRequired: true })
	patientCommunicationPreferences: PatientCommunicationPreferencesRequest;

	@GandalfProperty()
	patientEmployment: PatientEmploymentRequest;

	@GandalfValidator({ notNull: { message: 'Other Information is Required' } })
	@GandalfProperty({ isRequired: true })
	patientOtherInformation: PatientOtherInformationRequest;

	@GandalfValidator({ notNull: { message: 'Phone Information is Required' } })
	@GandalfProperty({ isRequired: true })
	patientPhoneInformation: PatientPhoneInformationRequest;

	@GandalfProperty()
	patientReferral: PatientReferralRequest;

	@GandalfValidator({ notNull: { message: 'Login Information is required' } })
	@GandalfProperty({ isRequired: true })
	portalCredentials: PatientPortalAccessRequest;

}
