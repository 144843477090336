<ejs-dialog
    #modal
	(close)="closeModal()"
	[attr.data-test-id]="'imagePreviewModal'">
	<ng-template #header>
		<div class="dlg-template">File Preview</div>
	</ng-template>
	<ng-template #content>
		<canvas #imagePreview></canvas>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button type="button" (click)="closeModal()" iconCss="fa fa-close">Close</button>
	</ng-template>

</ejs-dialog>
