import { OptionItem } from '../../constants/items';
import { _isNil } from '../lodash/lodash';

type LabelExpression<Type, LabelKey extends keyof Type> = ((entity: Type) => string) | LabelKey;
type ValueExpression<Type, ValueKey extends keyof Type> = ((entity: Type) => Type[ValueKey]) | ValueKey;

export class DropdownOptions<Type, LabelKey extends keyof Type, ValueKey extends keyof Type> {

	readonly labelKey: LabelExpression<Type, LabelKey>;
	readonly valueKey: ValueExpression<Type, ValueKey>;
	private _options: OptionItem[];

	private constructor(options: Type[], labelKey: LabelExpression<Type, LabelKey>, valueKey: ValueExpression<Type, ValueKey>) {
		this.labelKey = labelKey;
		this.valueKey = valueKey;

		this._options = this.parseOptions(options);
	}

	/* eslint-disable-next-line @typescript-eslint/no-shadow */
	static createDropdownOptions<Type, LabelKey extends keyof Type, ValueKey extends keyof Type>(
		options: Type[],
		labelKey: LabelExpression<Type, LabelKey>,
		valueKey: ValueExpression<Type, ValueKey>,
	) {
		return new DropdownOptions(options, labelKey, valueKey);
	}

	private parseOptions(options: Type[]): OptionItem[] {
		return options.map(entity => ({
			label: typeof this.labelKey === 'function' ? (this.labelKey)(entity) : entity[this.labelKey],
			value: typeof this.valueKey === 'function' ? (this.valueKey)(entity) : entity[this.valueKey],
		}) as OptionItem);
	}

	addOptionItem(label: string, value: Type[ValueKey], addIndex?: number) {
		const startIndex = !_isNil(addIndex) ? addIndex : this.options.length;
		const itemToAdd = {label, value} as OptionItem;
		this._options = [...this.options.slice(0, startIndex), itemToAdd, ...this.options.slice(startIndex)] as OptionItem[];
	}

	get options() {
		return this._options;
	}
}
