// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.recalls.PatientRecallDetailResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientRecallDetailResponse extends GandalfModelBase {

	@GandalfProperty()
	appointmentDate: Date;

	@GandalfProperty()
	appointmentId: number;

	@GandalfProperty()
	appointmentLocation: string;

	@GandalfProperty()
	comments: string;

	@GandalfProperty()
	encounterTemplateId: number;

	@GandalfProperty()
	patientRecallId: number;

	@GandalfProperty()
	practiceLocationId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	recallDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.RecallStatus;

}
