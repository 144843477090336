import { Directive, ElementRef, Input } from '@angular/core';
import { _isNil } from '../../utils/lodash/lodash';

@Directive({
	selector: '[revFocusInput]',
})
export class FocusInputDirective {

	@Input()
	highlightInputOnFocus = false;

	constructor(public el: ElementRef) {
	}

	@Input()
	set revFocusInput(focus: boolean | number | string) {
		if (focus || focus === '' || _isNil(focus)) {
			setTimeout(() => {
				const subInput = this.el.nativeElement.querySelector('input') || this.el.nativeElement.querySelector('.e-input');
				if (subInput) {
					subInput.focus();
				} else {
					this.el.nativeElement.focus();
				}
				if (this.highlightInputOnFocus) {
					subInput?.select();
				}
			}, 10);
		}

	}
}
