import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FolderService } from '@core/folder/folder.service';
import { HIT_PMS_HTML_PREFERENCES } from '@core/legacy/hit-pms-html.constants';
import { _isNil } from '@core/lodash/lodash';
import { DynamicModalRef, ModalConfig, ObjectUtils, OptionItem } from 'morgana';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { UrlService } from '@core/url-util/url.service';
import { CdaSections, InteropFileFormat } from '@gandalf/constants';
import { GenerateClinicalSummaryRequest } from '@gandalf/model/generate-clinical-summary-request';
import { AdditionalPreferencesService } from '@shared/component/clinical-summary/additional-preferences.service';
import { CDA_CLINICAL_SUMMARY_DOCUMENT_SECTIONS, CDA_PATENT_RECORD_DOCUMENT_SECTIONS } from '@shared/constants/cda-section-lists.constants';
import { URL_PRINT_ENDPOINTS } from '@shared/constants/url.constants';
import { TreeNode } from '@shared/interfaces/treeview';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { FieldsSettingsModel } from '@syncfusion/ej2-navigations/src/treeview/treeview-model';

interface SummaryType {
	title: string;
	defaultFolderPreference: { name: string };
	endpoint: string;
	sections: CdaSections[];
}

export const SummaryTypes: {[key: string]: SummaryType} = Object.freeze({
	CLINICAL: {
		title: 'Clinical Summary',
		defaultFolderPreference: HIT_PMS_HTML_PREFERENCES.CCDA_CLINICAL_SUMMARY_DEFAULT_FOLDER,
		endpoint: URL_PRINT_ENDPOINTS.CLINICAL_SUMMARY,
		sections: CDA_CLINICAL_SUMMARY_DOCUMENT_SECTIONS,
	},
	PATIENT_RECORD: {
		title: 'Record Summary',
		defaultFolderPreference: HIT_PMS_HTML_PREFERENCES.CCDA_PATIENT_RECORD_DEFAULT_FOLDER,
		endpoint: URL_PRINT_ENDPOINTS.PATIENT_RECORD,
		sections: CDA_PATENT_RECORD_DOCUMENT_SECTIONS,
	},
});

@Component({
	selector: 'pms-clinical-summary',
	templateUrl: './clinical-summary.component.html',
	styles: [],
})
export class ClinicalSummaryComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	fileFormats: OptionItem[];

	clinicalSummaryFormGroup: UntypedFormGroup;

	patientFolders: TreeNode[];

	selectedFolderId: string[];

	sectionOptions = [];

	fields: FieldsSettingsModel;

	summaryType: SummaryType;

	patientId: number;

	encounterId: number;

	constructor(
		private dynamicModalRef: DynamicModalRef,
		private formBuilder: UntypedFormBuilder,
		private folderService: FolderService,
		private urlService: UrlService,
		private modalConfig: ModalConfig,
		private securityManager: SecurityManagerService,
		private additionalPreferencesService: AdditionalPreferencesService,
	) {
	}

	ngOnInit() {
		this.parseConfigData(this.modalConfig.data);
		this.fileFormats = [
			InteropFileFormat.CDA_PDF,
			InteropFileFormat.CDA_HTML,
			InteropFileFormat.CDA_XML,
		];
		this.clinicalSummaryFormGroup = this.formBuilder.group({
			exportFileFormat: InteropFileFormat.CDA_PDF,
			selectedFolderId: '',
			selectedOptions: [],
		});
		this.folderService.getPatientFolders().subscribe(tree => {
			this.patientFolders = tree;
			this.fields = {
				dataSource: this.patientFolders,
				id: 'data',
				text: 'label',
				tooltip: 'label',
				child: 'children',
				expanded: 'expanded',
				selected: 'selected',
			};
			this.setTreeToDefault();
		});
		this.initSectionOptions();
	}

	parseConfigData(data) {
		this.summaryType = data.summaryType || SummaryTypes.CLINICAL;
		this.patientId = data.patientId;
		this.encounterId = data.encounterId;

	}

	initSectionOptions() {
		this.sectionOptions = this.summaryType.sections;

		this.clinicalSummaryFormGroup.get('selectedOptions').setValue([]);

		if (this.summaryType === SummaryTypes.PATIENT_RECORD) {
			this.additionalPreferencesService.findCcdaPatientRecordSectionPreferences().subscribe(sectionIds => {
				this.clinicalSummaryFormGroup.get('selectedOptions').setValue(sectionIds);
			});
		} else if (this.summaryType === SummaryTypes.CLINICAL) {
			this.additionalPreferencesService.findCcdaClinicalSummarySectionPreferences().subscribe(sectionIds => {
				this.clinicalSummaryFormGroup.get('selectedOptions').setValue(sectionIds);
			});
		}
	}

	setTreeToDefault() {
		this.selectedFolderId = [];
		const defaultFolderPreference = this.summaryType.defaultFolderPreference.name;
		const foundNode = this.folderService.searchTree(this.patientFolders,
			this.securityManager.preferenceValue(defaultFolderPreference));
		if (foundNode !== null) {
			this.selectedFolderId = [foundNode.data];
		}

	}

	updateSelectedFolder(event) {
		this.selectedFolderId = [event.node.dataset.uid];
		this.clinicalSummaryFormGroup.get('selectedFolderId').setValue(event.node.dataset.uid);
	}

	clearSelectedFolder() {
		this.selectedFolderId = [];
		this.clinicalSummaryFormGroup.get('selectedFolderId').setValue(undefined);
	}

	/* istanbul ignore next */
	closeDialog() {
		this.dynamicModalRef.close(this.modal);
	}

	/* istanbul ignore next */
	generate() {
		const context = this.securityManager.createSecurityContext();
		const request = new GenerateClinicalSummaryRequest();
		request.optionalSectionIds = this.clinicalSummaryFormGroup.get('selectedOptions').value.toString();
		if (!ObjectUtils.isNilOrEmpty(this.selectedFolderId)) {
			request.folderId = this.selectedFolderId[0];
		}
		request.fileFormatType = this.clinicalSummaryFormGroup.get('exportFileFormat').value.value;
		request.locationId = context.locationId;
		request.customerId = context.customerId;
		request.practiceId = context.practiceId;
		request.employeeId = this.securityManager.getUserSession().userEntityId;
		request.patientId = this.patientId;
		if (!_isNil(this.encounterId)) {
			request.encounterId = this.encounterId;
		}
		this.urlService.openTabWithPost(this.summaryType.endpoint, request);
		this.closeDialog();
	}
}
