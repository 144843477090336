import { OpenEdgePaymentSaveResult } from '@accounting/invoices/open-edge-manual-card-modal/open-edge-manual-card-modal.component';
// eslint-disable-next-line max-len
import {
	OpenEdgeValidationKeyMismatchModalComponent
} from '@accounting/invoices/open-edge-read-card-modal/open-edge-validation-key-mismatch-modal/open-edge-validation-key-mismatch-modal.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { _isEmpty, _isNil } from '@core/lodash/lodash';
import { DynamicModalRef, ModalConfig, ModalManagerService } from 'morgana';
import { OpenEdgePaymentService, SalesResponse } from '@core/open-edge-payment/open-edge-payment.service';
import { OpenEdgeCommonPaymentStatus, OpenEdgeTransactionType } from '@gandalf/constants';
import { OpenEdgeCardReaderResponse } from '@gandalf/model/open-edge-card-reader-response';
import { OpenEdgeFinishTransactionRequest } from '@gandalf/model/open-edge-finish-transaction-request';
import { OpenEdgeSetupTransportKeysRequest } from '@gandalf/model/open-edge-setup-transport-keys-request';
import { OpenEdgeTransactionDetailsResponse } from '@gandalf/model/open-edge-transaction-details-response';
import { OpenEdgeTransportKeysResponse } from '@gandalf/model/open-edge-transport-keys-response';
import { OPEN_EDGE_CARD_ENTRY_MODES, OPEN_EDGE_CARD_PAYMENT_TYPES, OPEN_EDGE_STATUSES } from '@shared/constants/open-edge-card-reader-status.constants';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

export enum PROCESS_STEP {
	LOADING,
	WAITING_ON_READER,
	ERROR,
}

export const PENDING_TRANSACTION_TIMEOUT = 120000;

export enum CardReaderMode {
	PAYMENT = 'Payment',
	REFUND = 'Refund',
}

export class OpenEdgeReadCardModalResult {
	transaction: OpenEdgeTransactionDetailsResponse;
	storedPaymentResult: OpenEdgePaymentSaveResult;
}

@Component({
	selector: 'pms-open-edge-read-card-modal',
	templateUrl: './open-edge-read-card-modal.component.html',
	providers: [ModalManagerService],
})
export class OpenEdgeReadCardModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	totalAmount: number;
	reader: OpenEdgeCardReaderResponse;
	referenceIdentifier: string;
	transportKey: string;
	validationKey: string;
	currentStep = PROCESS_STEP.LOADING;
	errorMessage: string;
	cardEntryMode: OPEN_EDGE_CARD_ENTRY_MODES;
	cardPaymentType: OPEN_EDGE_CARD_PAYMENT_TYPES;
	cardReaderMode;
	personId: number;
	saveCreditCard = false;
	isApproved = false;
	cancelTransactionTimeout;

	constructor(
		private dynamicModalRef: DynamicModalRef,
		private modalConfig: ModalConfig,
		private openEdgePaymentService: OpenEdgePaymentService,
		private featureService: FeatureService,
		public modalManagerService: ModalManagerService,
	) {
	}

	ngOnInit(): void {
		this.parseModalConfig(this.modalConfig);
		this.setupTransaction();
	}

	parseModalConfig(modalConfig: ModalConfig) {
		this.totalAmount = modalConfig.data.totalAmount;
		this.reader = modalConfig.data.reader;
		this.cardReaderMode = modalConfig.data.cardReaderMode;
		this.personId = modalConfig.data.personId;
	}

	setupTransaction() {
		const request = new OpenEdgeSetupTransportKeysRequest();
		request.amount = this.totalAmount;
		request.openEdgeCardReaderId = this.reader.openEdgeCardReaderId;
		this.setupTransportKey(request);
	}

	setupTransportKey(request) {
		switch (this.cardReaderMode) {
			case CardReaderMode.PAYMENT:
				this.openEdgePaymentService.setupTransportKeysForSale(request).subscribe({
					next: this.setupTransactionCallback,
					error: () => this.closeModal(),
				});
				break;
			case CardReaderMode.REFUND:
				this.openEdgePaymentService.setupTransportKeysForRefund(request).subscribe({
					next: this.setupTransactionCallback,
					error: () => this.closeModal(),
				});
				break;
		}
	}

	setupTransactionCallback = (data: OpenEdgeTransportKeysResponse) => {
		if (!_isNil(data.errorCode) || !_isNil(data.errorMessage)) {
			this.showError(data.errorCode, data.errorMessage);
			this.currentStep = PROCESS_STEP.ERROR;
		} else {
			this.referenceIdentifier = data.referenceIdentifier;
			this.validationKey = data.validationKey;
			this.transportKey = data.transportKey;

			this.currentStep = PROCESS_STEP.WAITING_ON_READER;
			window.addEventListener('beforeunload', this.cancelTransaction);
			this.cancelTransactionTimeout = setTimeout(this.cancelTransaction, PENDING_TRANSACTION_TIMEOUT);
			this.openEdgePaymentService.callCardReader(this.transportKey, this.reader).subscribe({
				next: this.cardReaderCallback,
				error: () => this.showCardReadError('Could not connect to the selected reader. Please check the connection and/or host name and try again.'),
			});
		}
	};

	cardReaderCallback = (cardResponse: SalesResponse) => {
		this.cardEntryMode = cardResponse.EntryMode;
		this.cardPaymentType = cardResponse.PaymentType;
		if (this.isValidationKeyMismatch(cardResponse)) {
			this.showValidationKeyMismatchError(cardResponse);
			return;
		}
		switch (cardResponse.Status?.toUpperCase() as OPEN_EDGE_STATUSES) {
			case OPEN_EDGE_STATUSES.APPROVED:
				this.onCardApproved();
				break;
			case OPEN_EDGE_STATUSES.DECLINED:
			case OPEN_EDGE_STATUSES.DECLINED_DUPLICATE:
				this.showError('Declined', cardResponse.ErrorMessage);
				break;
			case OPEN_EDGE_STATUSES.ERROR:
				this.showError('Error', cardResponse.ErrorMessage);
				break;
			case OPEN_EDGE_STATUSES.FAILED:
				this.showError('Failed', cardResponse.ErrorMessage);
				break;
			case OPEN_EDGE_STATUSES.USER_CANCELED:
				this.showError('User Canceled', cardResponse.ErrorMessage);
				break;
			case OPEN_EDGE_STATUSES.POS_CANCELED:
				this.showError('Point Of Sale Canceled', cardResponse.ErrorMessage);
				break;
			default:
				this.showError('Unknown', cardResponse.ErrorMessage);
				break;
		}
	};

	showError(errorType: string, errorMessage?: string) {
		this.currentStep = PROCESS_STEP.ERROR;
		this.errorMessage = errorType + (_isEmpty(errorMessage) ? '' : `: ${errorMessage}`);
	}

	showCardReadError(errorMessage: string) {
		this.currentStep = PROCESS_STEP.ERROR;
		this.errorMessage = errorMessage;
	}

	onCardApproved() {
		this.deleteTimeoutAndOnReload();
		this.isApproved = true;
		const request = new OpenEdgeFinishTransactionRequest();
		request.openEdgeCardReaderId = this.reader.openEdgeCardReaderId;
		request.referenceIdentifier = this.referenceIdentifier;
		request.openEdgeTransactionType = this.getOpenEdgeTransactionType();
		request.personId = this.saveCreditCard ? this.personId : null;
		this.openEdgePaymentService.finishTransaction(request).subscribe({
			next: this.handleOpenEdgeTransaction,
			error: () => this.closeModal(),
		});
	}

	handleOpenEdgeTransaction = (transaction: OpenEdgeTransactionDetailsResponse) => {
		switch (transaction.paymentStatus.value) {
			case OpenEdgeCommonPaymentStatus.APPROVED.value: {
				const modalResult = new OpenEdgeReadCardModalResult();
				modalResult.transaction = transaction;
				modalResult.storedPaymentResult = this.getPaymentStoreResult(transaction.paymentStoredTokenId);
				this.closeModal(modalResult);
				break;
			}
			case OpenEdgeCommonPaymentStatus.PARTIAL_APPROVAL_VOIDED.value:
			case OpenEdgeCommonPaymentStatus.PARTIAL_APPROVAL_RETURNED.value:
				this.showError('Payment declined. Please use a different card and try again.');
				break;
			case OpenEdgeCommonPaymentStatus.PARTIAL_APPROVAL_ERROR.value:
				this.closeModal();
				this.openEdgePaymentService.showPaymentNotAppliedAlert(transaction);
				break;
			default:
				this.showError(transaction.status?.label || transaction.errorMessage || transaction.paymentStatus.label);
				break;
		}
	};

	getPaymentStoreResult(tokenId: number): OpenEdgePaymentSaveResult {
		if (!this.saveCreditCard) {
			return OpenEdgePaymentSaveResult.NOT_REQUESTED;
		}
		if (_isNil(tokenId)) {
			return OpenEdgePaymentSaveResult.NOT_SAVED;
		}
		return OpenEdgePaymentSaveResult.SAVED;
	}

	getOpenEdgeTransactionType() {
		switch (this.cardReaderMode) {
			case CardReaderMode.PAYMENT:
				return (this.cardPaymentType === OPEN_EDGE_CARD_PAYMENT_TYPES.DEBIT) ? OpenEdgeTransactionType.DEBIT_SALE : OpenEdgeTransactionType.SALE;
			case CardReaderMode.REFUND:
				return (this.cardPaymentType === OPEN_EDGE_CARD_PAYMENT_TYPES.DEBIT) ? OpenEdgeTransactionType.DEBIT_RETURN : OpenEdgeTransactionType.RETURN;
		}
	}

	/* istanbul ignore next: closeModal */
	closeModal(data?: OpenEdgeReadCardModalResult) {
		if (!_isNil(this.cancelTransactionTimeout)) {
			this.cancelTransaction();
		}
		this.dynamicModalRef.close(this.modal, data);
	}

	isLoading() {
		return this.currentStep === PROCESS_STEP.LOADING;
	}

	isWaitingOnReader() {
		return this.currentStep === PROCESS_STEP.WAITING_ON_READER;
	}

	isError() {
		return this.currentStep === PROCESS_STEP.ERROR;
	}

	isValidationKeyMismatch(cardResponse: SalesResponse) {
		return (cardResponse.ValidationKey !== this.validationKey
			&& ((cardResponse.Status?.toUpperCase() as OPEN_EDGE_STATUSES) === OPEN_EDGE_STATUSES.APPROVED || !_isEmpty(cardResponse.ValidationKey)));
	}

	isCardSwiped() {
		return this.cardEntryMode === OPEN_EDGE_CARD_ENTRY_MODES.SWIPE;
	}

	showSaveCardOption() {
		return this.cardReaderMode === CardReaderMode.PAYMENT
			&& !_isNil(this.personId)
			&& this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.PATIENTS.ACCOUNT.GLOBAL_PAYMENT_RECEIVE_PAYMENT_CARD_ON_FILE);
	}

	cancelTransaction = () => {
		this.openEdgePaymentService.cancelTransaction(this.reader).subscribe();
		this.deleteTimeoutAndOnReload();
	};

	deleteTimeoutAndOnReload() {
		clearTimeout(this.cancelTransactionTimeout);
		this.cancelTransactionTimeout = undefined;
		window.removeEventListener('beforeunload', this.cancelTransaction);
	}

	showValidationKeyMismatchError(cardResponse: SalesResponse) {
		this.closeModal();
		this.modalManagerService.open(OpenEdgeValidationKeyMismatchModalComponent, {
			data: {
				transactionDate: cardResponse.TransactionDate,
				paymentType: cardResponse.PaymentType,
				amountApproved: cardResponse.AmountApproved,
				authorizationCode: cardResponse.AuthorizationCode,
				accountNumber: cardResponse.AccountNumber,
				referenceId: this.referenceIdentifier,
			},
		});
	}
}
