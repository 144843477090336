// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { TwoWayTextMessageSummaryResponse } from './two-way-text-message-summary-response';

/** See com.rev360.pms.api.controller.twowaytext.TwoWayTextConversationListResponse */
/* istanbul ignore next */
@GandalfModel
export class TwoWayTextConversationListResponse extends GandalfModelBase {

	@GandalfProperty()
	cellPhone: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	classification: constants.ConnectConversationClassification;

	@GandalfProperty()
	connectConversationId: number;

	@GandalfProperty()
	lastMessage: TwoWayTextMessageSummaryResponse;

	@GandalfProperty()
	patientName: PatientNameResponse;

	@GandalfProperty()
	patientPhotoId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	readStatus: constants.ConnectConversationReadStatus;

	@GandalfConstantDecorator()
	@GandalfProperty()
	resolutionStatus: constants.ConnectConversationResolutionStatus;

}
