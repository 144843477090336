import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Validates that a value is a number that is allowed to be positive, negative, and/or zero
 */
export function moneyConstraintsValidator(positiveAllowed: boolean, zeroAllowed: boolean, negativeAllowed: boolean): ValidatorFn {
	return (control: AbstractControl) => {
		const value: any = control.value;
		if (value == null) {
			return null;
		}

		let valid = true;
		if (typeof value === 'number') {
			const parsedValue = parseFloat(value.toFixed(2));
			if ((!positiveAllowed && parsedValue > 0) || (!zeroAllowed && parsedValue === 0) || (!negativeAllowed && parsedValue < 0)) {
				valid = false;
			}
		} else {
			valid = false;
		}

		return valid ? null : {
			moneyConstraints: {
				valid: false,
			},
		};
	};
}
