<ejs-dialog
	#modal
	(close)="closeModal()"
	zIndex="{{zIndexBehindHitPmsHtmlModal}}"
	cssClass="modal-lg"
	[attr.data-test-id]="'invoiceDetailsModal'">
	<ng-template #header>
		<div class="dlg-template">Invoice Details</div>
	</ng-template>
	<ng-template #content>
		<pms-invoice-details [invoiceId]="invoiceId" (returnEvent)="closeModal(true)" [isReadOnly]="isReadOnly"></pms-invoice-details>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'invoiceDetailsModalCloseButton'">Close</button>
	</ng-template>
</ejs-dialog>
