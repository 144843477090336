import { RoutesActions, RoutesActionTypes } from '@app-store/actions/routes.actions';

export type RoutesStateMap = Map<string, string>;

export const initialState: RoutesStateMap = new Map();

export function reducer(state = initialState, action: RoutesActions): RoutesStateMap {
	switch (action.type) {

		case RoutesActionTypes.UPDATE_ROUTE_VALUE: {
			const {key, route} = action.payload;
			const routesStateMap = new Map(state);
			return routesStateMap.set(key, route);
		}

		case RoutesActionTypes.REMOVE_ROUTE: {
			const routesStateMap = new Map(state);
			routesStateMap.delete(action.payload.key);
			return routesStateMap;
		}

		default: {
			return state;
		}
	}
}
