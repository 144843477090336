import { Injectable } from '@angular/core';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';

export enum Feature_Status {
	Feature_Off = 0,
	Feature_On = 1,
}

@Injectable({
	providedIn: 'root',
})
export class FeatureService {

	constructor(
		private securityManager: SecurityManagerService,
	) {
	}

	/**
	 * Performs a feature flag check
	 * @param flag feature flag to check
	 * @param featureOnCallback optional callback to be triggered if the feature is on
	 * @param featureOffCallback optional callback to be triggered if the feature is off
	 */
	executeFeature(flag: string, featureOnCallback: () => void, featureOffCallback: () => void) {
		const featureFlag = this.isFeatureOn(flag);

		if (featureOnCallback || featureOffCallback) {
			featureFlag ? featureOnCallback() : featureOffCallback();
		}
	}

	getFeatureStatus(flag: string) {
		return this.isFeatureOn(flag) ? Feature_Status.Feature_On : Feature_Status.Feature_Off;
	}

	isFeatureOn(flag: string) {
		return this.securityManager.getUserSession().enabledFeatureFlags.indexOf(flag) !== -1;
	}

	isFeatureOff(flag: string) {
		return !this.isFeatureOn(flag);
	}
}
