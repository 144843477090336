import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { GRID_MULTILINE_ROW_HEIGHT, GridUtil, GridUtilService, ModalBase, RevColDef } from 'morgana';
import { PrescriptionUtil } from '@core/prescription-util/prescription-util';
import { OrderType, PrescriptionCurrentStatus, RxDisplayStatusLegacy } from '@gandalf/constants';
import { PatientNameResponse } from '@gandalf/model/patient-name-response';
import { RxSelectContactLensResponse } from '@gandalf/model/rx-select-contact-lens-response';
import { RxSelectEyeglassResponse } from '@gandalf/model/rx-select-eyeglass-response';
import { RxSelectService } from '@shared/component/rx-select-modal/rx-select.service';
import { DATE_FORMATS, TABLE_DATE_FORMATS } from '@shared/constants/date-format.constants';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { Observable } from 'rxjs';
import { FeatureService } from '@core/feature/feature.service';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { PrescriptionSelectContactLensResponse } from '@gandalf/model/prescription-select-contact-lens-response';
import { AgGridAngular } from 'ag-grid-angular';
import { ProviderNamePipe } from '@shared/pipes/provider-name/provider-name.pipe';
import { GridOptions } from 'ag-grid-community';
import { TooltipCellRendererComponent } from '@shared/component/tooltip-cell-renderer/tooltip-cell-renderer.component';
import { PrescriptionSelectContactLensEyeResponse } from '@gandalf/model/prescription-select-contact-lens-eye-response';
import { EyeglassOrderSearchResponse } from '@gandalf/model/eyeglass-order-search-response';

export enum RxSelectReturnEnum {
	Nothing,
	GoPrevious,
	CreateOrder,
}

export interface RxSelectData {
	orderType: OrderType;
	patientName: PatientNameResponse;
	hasPrevious?: boolean;
	canSkip?: boolean;
	useOrdersEndpoint?: boolean;
}

export interface RxSelectReturnValue {
	returnEnum: RxSelectReturnEnum;
	rxId?: number;
	isTrial?: boolean;
}

@Component({
	selector: 'pms-rx-select-modal',
	templateUrl: './rx-select-modal.component.html',
	styles: [],
})
export class RxSelectModalComponent extends ModalBase<RxSelectData, RxSelectReturnValue> implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('contactLensGrid')
	contactLensGrid: AgGridAngular;

	@ViewChild('eyeglassAgGrid')
	eyeglassAgGrid: AgGridAngular<RxSelectEyeglassResponse>;

	@ViewChild('authByTooltip')
	authByTooltip: TemplateRef<any>;

	@ViewChild('osOd')
	osOdColumn: TemplateRef<any>;

	@ViewChild('model')
	modelColumn: TemplateRef<any>;

	@ViewChild('type')
	typeColumn: TemplateRef<any>;

	@ViewChild('mv')
	mvColumn: TemplateRef<any>;

	@ViewChild('bc')
	bcColumn: TemplateRef<any>;

	@ViewChild('sph')
	sphColumn: TemplateRef<any>;

	@ViewChild('cyl')
	cylColumn: TemplateRef<any>;

	@ViewChild('axis')
	axisColumn: TemplateRef<any>;

	@ViewChild('add')
	addColumn: TemplateRef<any>;

	@ViewChild('addTooltipTemplate')
	addTooltipTemplate: TemplateRef<any>;

	@ViewChild('diam')
	diamColumn: TemplateRef<any>;

	@ViewChild('eyeglassEyeColumn')
	eyeglassEyeColumn: TemplateRef<any>;

	@ViewChild('eyeglassSphereColumn')
	eyeglassSphereColumn: TemplateRef<any>;

	@ViewChild('eyeglassCylinderColumn')
	eyeglassCylinderColumn: TemplateRef<any>;

	@ViewChild('eyeglassAxisColumn')
	eyeglassAxisColumn: TemplateRef<any>;

	@ViewChild('eyeglassNearAddColumn')
	eyeglassNearAddColumn: TemplateRef<any>;

	@ViewChild('eyeglassHorizontalPrismColumn')
	eyeglassHorizontalPrismColumn: TemplateRef<any>;

	@ViewChild('eyeglassVerticalPrismColumn')
	eyeglassVerticalPrismColumn: TemplateRef<any>;

	RxSelectReturnEnum = RxSelectReturnEnum;
	OrderTypes = OrderType;
	modalTitle: string;
	prescriptionUtil = PrescriptionUtil;
	isSearching = false;
	gridData: (RxSelectEyeglassResponse | RxSelectContactLensResponse | PrescriptionSelectContactLensResponse)[];
	gridDateFormat = TABLE_DATE_FORMATS.MM_DD_YYYY;
	useOrdersEndpoints = true;
	isContactEnhancementsFeatureOn: boolean;
	private readonly gridOptions: Partial<GridOptions> = {
		rowClass: 'row-link',
		rowHeight: GRID_MULTILINE_ROW_HEIGHT,
		overlayNoRowsTemplate: `<span class="ag-overlay-no-rows-wrapper">No prescriptions at this time.</span>`,
	};
	eyeglassGridOptions = GridUtil.buildGridOptions<RxSelectEyeglassResponse>(this.gridOptions);
	contactLensGridOptions = GridUtil.buildGridOptions(this.gridOptions);
	PrescriptionUtil = PrescriptionUtil;

	constructor(
		private rxSelectService: RxSelectService,
		private featureService: FeatureService,
		private gridUtilService: GridUtilService,
		private providerNamePipe: ProviderNamePipe,
	) {
		super();
	}

	ngOnInit() {
		this.isContactEnhancementsFeatureOn = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.ORDERS.CONTACT_LENS_MODAL);
		this.useOrdersEndpoints = !_isNil(this.data.useOrdersEndpoint) ? this.data.useOrdersEndpoint : true;
		this.search();
	}

	search() {
		let searchObservable: Observable<(RxSelectEyeglassResponse | RxSelectContactLensResponse | PrescriptionSelectContactLensResponse)[]>;
		switch (this.data.orderType) {
			case OrderType.EYEGLASS:
				this.modalTitle = 'Select an Eyeglass Rx';
				searchObservable = this.determineEyeglassObservable();
				break;
			case OrderType.CONTACT_LENS:
				this.modalTitle = 'Select a Contact Lens Rx';
				searchObservable = this.isContactEnhancementsFeatureOn
					? this.rxSelectService.searchContactLensForOrders(this.data.patientName.patientId)
					: this.rxSelectService.searchContactLensRxForOrders(this.data.patientName.personId);
				break;
			case OrderType.CONTACT_LENS_TRIAL:
				this.modalTitle = 'Select a Contact Lens Trial Rx';
				searchObservable = this.isContactEnhancementsFeatureOn
					? this.rxSelectService.searchContactLensWithTrialsForOrders(this.data.patientName.patientId)
					: this.rxSelectService.searchContactLensTrialRxForOrders(this.data.patientName.personId);
				break;
		}
		searchObservable.subscribe(data => {
			this.gridData = data;
		});
	}

	determineEyeglassObservable() {
		if(!this.useOrdersEndpoints) {
			return this.rxSelectService.searchEyeglassRx(this.data.patientName.patientId);
		}

		if(this.isContactEnhancementsFeatureOn) {
			return this.rxSelectService.findEyeglassPrescriptionsForOrders(this.data.patientName.patientId);
		} else {
			return this.rxSelectService.searchEyeglassRxForOrders(this.data.patientName.personId);
		}
	}

	isSkippable() {
		return this.data.orderType === OrderType.EYEGLASS && (this.data.hasPrevious || !!this.data.canSkip);
	}

	closeDialog(returnEnum: RxSelectReturnEnum, rxId?: number, rxStatus?: RxDisplayStatusLegacy) {
		const rxSelectReturnValue: RxSelectReturnValue = {
			returnEnum,
			rxId,
			isTrial: rxStatus === RxDisplayStatusLegacy.TRIAL,
		};
		this.dynamicModalRef.close(this.modal, rxSelectReturnValue);
	}

	closeDialogAgGrid(returnEnum: RxSelectReturnEnum, prescriptionId: number, trialId) {
		const rxSelectReturnValue: RxSelectReturnValue = {
			returnEnum,
			rxId: !_isNil(prescriptionId) ? prescriptionId : trialId,
			isTrial: !_isNil(trialId),
		};

		this.dynamicModalRef.close(this.modal, rxSelectReturnValue);
	}

	onContactLensGridReady() {
		this.buildContactLensColumns();
	}

	buildContactLensColumns() {
		this.contactLensGrid.api.setGridOption('columnDefs', [
			this.gridUtilService.buildDateColumn('Date', 'createdDate', DATE_FORMATS.MM_DD_YYYY, {
				width: 90,
			}),
			this.gridUtilService.buildDateColumn('Exp Date', 'expirationDate', DATE_FORMATS.MM_DD_YYYY, {
				width: 100,
			}),
			GridUtil.buildColumn('Status', 'status', {
				width: 100,
				sortable: false,
				valueGetter: params => this.handleStatusLabel(params.data.status, params.data.trialId),
			}),
			GridUtil.buildColumn('Auth By', 'authorizedBy', {
				minWidth: 150,
				sortable: false,
				flex: 1,
				tooltipField: 'authorizedBy',
				tooltipComponent: TooltipCellRendererComponent,
				tooltipComponentParams: {
					ngTemplate: this.authByTooltip,
				},
				valueGetter: params => this.providerNamePipe.transform(params.data.authorizedBy as any),
			}),
			GridUtil.buildTemplateColumn('', '', this.osOdColumn, {
				width: 50,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('Model', 'locationProductName', this.modelColumn, {
				minWidth: 100,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('Type', 'overallLensType', this.typeColumn, {
				width: 65,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('MV', 'isMonovision', this.mvColumn, {
				width: 60,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('BC', 'baseCurve', this.bcColumn, {
				width: 60,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('Sph', 'sphere', this.sphColumn, {
				width: 60,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('Cyl', 'cylinder', this.cylColumn, {
				width: 60,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('Axis', 'axis', this.axisColumn, {
				width: 60,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('Add', 'addPower', this.addColumn, {
				width: 80,
				sortable: false,
				tooltipField: 'odEye',
				tooltipComponent: TooltipCellRendererComponent,
				tooltipComponentParams: {
					ngTemplate: this.addTooltipTemplate,
				},
			}),
			GridUtil.buildTemplateColumn('Diam', 'diameter', this.diamColumn, {
				width: 70,
				sortable: false,
			}),
		] as RevColDef<PrescriptionSelectContactLensResponse>[]);
	}

	onEyeglassGridReady() {
		this.buildEyeglassColumns();
	}

	buildEyeglassColumns() {
		const columnDefs: RevColDef<RxSelectEyeglassResponse>[] = [
			this.gridUtilService.buildDateColumn('Date', 'startDate', DATE_FORMATS.MM_DD_YYYY, {
				width: 90,
				sort: 'desc',
			}),
			this.gridUtilService.buildDateColumn('Exp Date', 'expirationDate', DATE_FORMATS.MM_DD_YYYY, {
				width: 100,
			}),
			GridUtil.buildColumn('Used For', 'usedForReasonName', {
				minWidth: 150,
				flex: 1,
				tooltipField: 'usedForReasonName',
				valueGetter: params => this.handleUsedForLabel(params.data),
			}),
			GridUtil.buildTemplateColumn('', '', this.eyeglassEyeColumn, {
				width: 50,
				sortable: false,
				wrapText: true,
			}),
			GridUtil.buildTemplateColumn('Sph', '', this.eyeglassSphereColumn, {
				width: 65,
				sortable: false,
				wrapText: true,
			}),
			GridUtil.buildTemplateColumn('Cyl', '', this.eyeglassCylinderColumn, {
				width: 60,
				sortable: false,
				wrapText: true,
			}),
			GridUtil.buildTemplateColumn('Axis', '', this.eyeglassAxisColumn, {
				width: 60,
				sortable: false,
				wrapText: true,
			}),
			GridUtil.buildTemplateColumn('Near Add', '', this.eyeglassNearAddColumn, {
				width: 80,
				sortable: false,
				wrapText: true,
			}),
			GridUtil.buildTemplateColumn('H Prism', '', this.eyeglassHorizontalPrismColumn, {
				width: 100,
				sortable: false,
				wrapText: true,
			}),
			GridUtil.buildTemplateColumn('V Prism', '', this.eyeglassVerticalPrismColumn, {
				width: 100,
				sortable: false,
				wrapText: true,
			}),
		];
		this.eyeglassAgGrid.api.setGridOption('columnDefs', columnDefs);
	}

	handleStatusLabel(status: PrescriptionCurrentStatus, trialId: number) {
		return !_isNil(trialId) ? PrescriptionUtil.CL_TRIAL_STATUS : status?.label;
	}

	shouldDisplayAddTooltip(odEye: PrescriptionSelectContactLensEyeResponse, osEye: PrescriptionSelectContactLensEyeResponse) {
		return !!(odEye?.addPower || odEye?.addDesignation || osEye?.addPower || osEye?.addDesignation);
	}

	handleUsedForLabel(data: RxSelectEyeglassResponse | EyeglassOrderSearchResponse) {
		if (data instanceof RxSelectEyeglassResponse) {
			return data?.usedForReasonName;
		}
		return data?.usedFor?.value;
	}
}
