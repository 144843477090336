<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-md"
	[attr.data-test-id]="'labOrderLookupModal'">
	<ng-template #header>
		<div class="dlg-template">Select Order Type</div>
	</ng-template>
	<ng-template #content>
		<div class="modal-feature">
			<form class="form-inline" [formGroup]="componentForm" #templateForm="ngForm" (ngSubmit)="searchLabOrders()">
				<div class="row">
					<div class="col-md-12">
						@if (componentForm.invalid && templateForm.submitted) {
							<gandalf-lib-validation-messages
								[form]="componentForm"
								[requestObj]="labOrderSearchRequest"
								[attr.data-test-id]="'labOrderLookupModalValidationMessages'">
							</gandalf-lib-validation-messages>
						}
					</div>
				</div>
				<div class="form-group" [attr.data-test-id]="'labOrderLookupModalSearchFormGroup'">
					<label for="lastName">Search</label>
					<input #lastName id="lastName" revFocusInput type="text" class="e-input"
						   formControlName="searchTerm"/>
				</div>

				<div class="form-group" [attr.data-test-id]="'labOrderLookupModalSearchButtonGroup'">
					<button ejs-button type="submit" iconCss="fa fa-search" [isPrimary]="true" [attr.data-test-id]="'labOrderLookupModalSearchButton'">Search
					</button>
					<button ejs-button type="button" iconCss="fa fa-close" (click)="clearSearch(templateForm)"
							[attr.data-test-id]="'labOrderLookupModalClearButton'">Clear
					</button>
				</div>

			</form>
		</div>

		<ejs-grid #grid
				  class="table-rowlink"
				  [allowResizing]="true"
				  [dataSource]="labOrders"
				  [allowSorting]="true"
				  [allowSelection]="true"
				  gridLines="Horizontal"
				  allowPaging="true"
				  (rowSelected)="labOrderSelected($event)"
				  [pmsGridSubmit]="isSearching"
				  [allowPaging]="true"
				  [pageSettings]="pageSettings"
				  [attr.data-test-id]="'labOrderLookupGrid'">
			<e-columns>
				<e-column field="formattedCode" headerText="Lab/Image"></e-column>
			</e-columns>
		</ejs-grid>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'labOrderLookupModalCancelButton'">Cancel</button>
	</ng-template>
</ejs-dialog>
