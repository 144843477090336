// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.insurance.CreatePracticeInsuranceCompanyModalRequest */
/* istanbul ignore next */
@GandalfModel
export class CreatePracticeInsuranceCompanyModalRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Abbreviation cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfProperty()
	abbr: string;

	@GandalfValidator({ sizeString: { message: 'Address Line 1 cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	addressLine1: string;

	@GandalfValidator({ sizeString: { message: 'Address Line 2 cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	addressLine2: string;

	@GandalfProperty()
	allowItemDiscounts: boolean;

	@GandalfProperty()
	chargedTax: boolean;

	@GandalfValidator({ sizeString: { message: 'City cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	city: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	claimFilingCode: constants.ClaimFilingCode;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Claim Submission is required' } })
	@GandalfProperty({ isRequired: true })
	claimSubmissionMechanism: constants.ClaimSubmitMechanism;

	@GandalfProperty()
	defaultInsuranceTypeReferenceId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	defaultPaymentMethod: constants.PaymentMethodType;

	@GandalfProperty()
	defaultPriorityReferenceId: number;

	@GandalfValidator({ sizeString: { message: 'Fax cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfProperty()
	fax: string;

	@GandalfValidator({ sizeString: { message: 'Group Number cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	groupNumber: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	groupNumberQualifier: constants.GroupNumberQualifier;

	@GandalfProperty()
	includePqrsAlert: boolean;

	@GandalfValidator({ notNull: { message: 'Classification is required' } })
	@GandalfProperty({ isRequired: true })
	insuranceClassMasterReferenceId: number;

	@GandalfValidator({ sizeString: { message: 'Main Phone cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfValidator({ phoneNumber: { message: 'Phone number must contain at least 10 digits' } })
	@GandalfProperty()
	mainPhone: string;

	@GandalfValidator({ notNull: { message: 'Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Name must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ isRequired: true })
	name: string;

	@GandalfValidator({ sizeString: { message: 'Zip Code cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty()
	nonStandardPostalCode: string;

	@GandalfValidator({ sizeString: { message: 'Notes cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	notes: string;

	@GandalfProperty()
	npiOnly: boolean;

	@GandalfProperty()
	participatingPractice: boolean;

	@GandalfValidator({ sizeString: { message: 'Payer ID cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	payerId: string;

	@GandalfValidator({ sizeString: { message: 'Zip Code cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfValidator({ postalCode: { message: 'Zip code is invalid' } })
	@GandalfProperty()
	postalCode: string;

	@GandalfProperty()
	removeMatchingServiceNpi: boolean;

	@GandalfProperty()
	renderingIsReferringProvider: boolean;

	@GandalfValidator({ sizeString: { message: 'State/Province cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	stateProvince: string;

	@GandalfProperty()
	transferTax: boolean;

	@GandalfValidator({ sizeString: { message: 'Web Site cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	webSite: string;

}
