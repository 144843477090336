import { Component, OnInit } from '@angular/core';
import { DebounceSearch } from '@core/debounce/debounce-search';
import { PatientSearchStringRequest } from '@gandalf/model/patient-search-string-request';
import { GandalfFormBuilder } from 'gandalf';
import { UntypedFormGroup } from '@angular/forms';
import { PatientSearchResponse } from '@gandalf/model/patient-search-response';
import { Subscription, timer } from 'rxjs';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { NavigationService } from '@core/navigation/navigation.service';
import { PatientSearchService } from '../services/patient-search.service';

@Component({
	selector: 'pms-patient-search',
	templateUrl: './patient-search.component.html',
	styles: [],
})
export class PatientSearchComponent implements OnInit {

	searchRequest: PatientSearchStringRequest;
	formGroup: UntypedFormGroup;
	patientResults: PatientSearchResponse[];
	searchString: string;
	debouncedSearch = new DebounceSearch(() => this.validateSearchString(), 500);
	timeOutTimer: Subscription;
	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	showResults = false;
	showMaxResultsText = false;

	constructor(
		private gandalfFormBuilder: GandalfFormBuilder,
		private patientSearchService: PatientSearchService,
		private navigationService: NavigationService,
	) {
	}

	ngOnInit() {
		this.buildRequest();
	}

	buildRequest() {
		this.searchRequest = new PatientSearchStringRequest();
		this.formGroup = this.gandalfFormBuilder.group(this.searchRequest);
	}

	debounceSearch() {
		this.debouncedSearch.valueChanged.next({
			searchString: this.searchString,
		});
	}

	validateSearchString() {
		if (this.searchString === '') {
			this.resetSearchForm();
			return;
		}

		const searchString = this.searchString.trim();
		if (searchString === '' || searchString.length < 2) {
			return;
		}

		this.formGroup.get('searchString').setValue(searchString);
		if (this.formGroup.invalid) {
			return;
		}

		this.submitSearch();
	}

	submitSearch() {
		this.patientSearchService.searchPatientsByString(this.formGroup.value).subscribe(result => {
			this.patientResults = result.patients;
			this.showMaxResultsText = result.moreResults;
			this.showResults = true;
		});
	}

	onBlur(event: FocusEvent) {
		const currentTarget = event.currentTarget as HTMLDivElement;
		// Prevent closing menu when clicking on a child element
		if (!currentTarget.contains(event.relatedTarget as Node)) {
			this.resetSearchForm();
		}
	}

	resetSearchForm() {
		this.clearInput();
		this.showResults = false;
	}

	clearInput() {
		this.searchString = '';
	}

	navigateToPatient(patientId: number) {
		this.resetSearchForm();
		return this.navigationService.navigateToPatient(patientId);
	}

	showNoResultsText(results: PatientSearchResponse[]) {
		return results?.length === 0;
	}

	startTimeoutTimer() {
		this.timeOutTimer = timer(3000, 0).subscribe(() => {
			this.resetSearchForm();
			this.timeOutTimer.unsubscribe();
		});
	}

	stopTimeoutTimer() {
		if (this.timeOutTimer) {
			this.timeOutTimer.unsubscribe();
		}
	}

}
