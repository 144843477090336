<ejs-dialog #modal cssClass="modal-md" (close)="closeModal()" [attr.data-test-id]="'selectDiagnosisModal'">
	<ng-template #header>
		<div class="dlg-template">Select {{codeSet.label}} Diagnosis</div>
	</ng-template>
	<ng-template #content>
		<ejs-grid #grid
				  [allowFiltering]="true"
				  [allowPaging]="true"
				  [pageSettings]="pageSettings"
				  [allowSorting]="true"
				  autoFitColumns
				  [autoFitBlacklist]="['shortDescription']"
				  class="table-rowlink margin-bottom-md"
				  [dataSource]="filteredDiagnoses"
				  gridLines="Horizontal"
				  [filterSettings]="{showFilterBarStatus: false}"
				  [pmsGridSubmit]="isSearching"
				  (rowSelected)="onRowSelected($event)"
				  [attr.data-test-id]="'selectDiagnosisModalGrid'">
			<ng-template #toolbarTemplate let-data>
				<rev-table-action-menu [table]="grid">
					<ng-container rev-table-action-menu-left>
						<div class="e-toolbar-item">
							<input class="e-input" type="text" id="codeFilter" placeholder="Filter by Code/Description"
								   (input)="debounceFilter()" revFocusInput="true" [(ngModel)]="codeFilter"/>
						</div>
					</ng-container>
				</rev-table-action-menu>
			</ng-template>
			<e-columns>
				<e-column field="code" headerText="Code" clipMode="EllipsisWithTooltip"></e-column>
				<e-column field="shortDescription" headerText="Description" clipMode="EllipsisWithTooltip"></e-column>
			</e-columns>
		</ejs-grid>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'selectDiagnosisModalCancelButton'">Cancel</button>
	</ng-template>
</ejs-dialog>
