// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.connect.campaign.ConnectCampaignMessageListResponse */
/* istanbul ignore next */
@GandalfModel
export class ConnectCampaignMessageListResponse extends GandalfModelBase {

	@GandalfProperty()
	campaignMessageId: number;

	@GandalfProperty()
	patientName: PatientNameResponse;

	@GandalfProperty()
	scheduleDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.ConnectCampaignMessageStatus;

}
