// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { EncounterTableResponse } from './encounter-table-response';

// @ts-ignore
import { OrderProcessorConfigResponse } from './order-processor-config-response';

// @ts-ignore
import { OrderVendorResponse } from './order-vendor-response';

// @ts-ignore
import { PatientCommunicationResponse } from './patient-communication-response';

// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.order.OrderResponse */
/* istanbul ignore next */
@GandalfModel
export class OrderResponse extends GandalfModelBase {

	@GandalfProperty()
	comment: string;

	@GandalfProperty()
	createdOn: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dueDate: Date;

	@GandalfProperty()
	encounter: EncounterTableResponse;

	@GandalfProperty({ propertyType: 'Money' })
	estimatedDiscounts: number;

	@GandalfProperty({ propertyType: 'Money' })
	estimatedInsuranceBenefit: number;

	@GandalfProperty({ propertyType: 'Money' })
	estimatedTaxes: number;

	@GandalfProperty()
	externalSupplierIdentifier: string;

	@GandalfProperty()
	externalSupplierName: string;

	@GandalfProperty()
	instructions: string;

	@GandalfProperty()
	locationId: number;

	@GandalfProperty()
	locationName: string;

	@GandalfProperty()
	notificationComments: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	notificationDate: Date;

	@GandalfProperty()
	onHold: boolean;

	@GandalfProperty()
	orderId: number;

	@GandalfProperty()
	originalOrderId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	originalOrderReason: constants.OriginalOrderReason;

	@GandalfProperty({ propertyType: 'Money' })
	otherCost: number;

	@GandalfProperty({ propertyType: 'Money' })
	otherRemakeCost: number;

	@GandalfProperty()
	patient: PatientNameResponse;

	@GandalfProperty()
	patientCommunication: PatientCommunicationResponse;

	@GandalfProperty()
	patientNotified: boolean;

	@GandalfProperty()
	personId: number;

	@GandalfProperty()
	processor: OrderProcessorConfigResponse;

	@GandalfProperty()
	shipAddress: AddressResponse;

	@GandalfProperty({ propertyType: 'Money' })
	shipCost: number;

	@GandalfProperty({ propertyType: 'Money' })
	shipRemakeCost: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	shipToType: constants.OrderShipToType;

	@GandalfConstantDecorator()
	@GandalfProperty()
	shippingCompany: constants.OrderShipmentCompany;

	@GandalfProperty()
	smartflowOrderIdentifier: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	statusCode: constants.OrderStatusCode;

	@GandalfProperty()
	statusDate: Date;

	@GandalfProperty()
	trackingNumber: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	type: constants.OrderType;

	@GandalfProperty()
	vendor: OrderVendorResponse;

	@GandalfProperty()
	vendorOrderIdentifier: string;

	@GandalfProperty()
	version: number;

}
