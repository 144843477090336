import { Injectable } from '@angular/core';
import dayjs from 'dayjs';

export enum ModelTypes {
	literalType,
	arrayType,
}

@Injectable({
	providedIn: 'root',
})
export class TypeTranslationService {

	/**
	 * We are setting up a list of all the "whitelisted" types that we expect
	 * to see coming out of Java.  We specify whether it is a literal, map, or array
	 * and provide a constructor function if it is a literal.
	 */
	/* istanbul ignore next */
	/* eslint-disable quote-props */
	readonly propertyFactory = {
		'Void': [ ModelTypes.literalType, () => this.voidConstructor() ],
		'Null': [ ModelTypes.literalType, () => this.voidConstructor() ],
		'Boolean': [ ModelTypes.literalType, literal => this.booleanConstructor(literal) ],
		'Number': [ ModelTypes.literalType, literal => this.passThroughConstructor(literal) ],
		'Object': [ ModelTypes.literalType, literal => this.passThroughConstructor(literal) ],
		'String': [ ModelTypes.literalType, literal => this.passThroughConstructor(literal) ],
		'Array': [ ModelTypes.arrayType ],
		'Date': [ ModelTypes.literalType, literal => this.dateConstructor(literal) ],
		'LocalDate': [ ModelTypes.literalType, literal => this.localDateConstructor(literal) ],
		'LocalDateTime': [ ModelTypes.literalType, literal => this.localDateTimeConstructor(literal) ],
		'Money': [ ModelTypes.literalType, literal => this.passThroughConstructor(literal) ],
	};

	constructor() {
	}

	static parseDate(dateString: string): Date {
		return dayjs(dateString).toDate();
	}

	/**
	 * BEGIN Java to Javascript constructor translators
	 */
	voidConstructor(): void {
		return undefined;
	}

	/**
	 * We might actually want to have the initial perform a deep copy..but still unsure.
	 * the issue is that it has to be an object or an array in order to do this.  However,
	 * I really don't want java.lang.Object to ever actually get used.
	 */
	passThroughConstructor<T>(initial: T): T {
		if (typeof initial !== 'undefined') {
			return initial;
		}
		return undefined;
	}


	booleanConstructor(initial: any): boolean {
		if (initial === null) {
			return null;
		}
		if (typeof initial !== 'undefined') {
			return Boolean(initial);
		}
		return undefined;
	}

	/**
	 * The date constructor is used for fully qualified Java Instants (UTC time)
	 */
	dateConstructor(initial: any): Date {
		if (initial === null) {
			return null;
		}
		if (typeof initial !== 'undefined') {
			return new Date(initial);
		}
		return undefined;
	}

	/**
	 * Used for local date (YYYY-MM-DD) - corresponds to Java LocalDate (date without time or timezone information)
	 */
	localDateConstructor(initial: any): Date {
		if (initial === null) {
			return null;
		}
		if (typeof initial === 'string') {
			return TypeTranslationService.parseDate(initial);
		}
		return undefined;
	}

	/**
	 * Used for local date and time (YYYY-MM-DDTHH:mm:SS.xxx) - corresponds to Java LocalDateTime (date and time without
	 * timezone information)
	 */
	localDateTimeConstructor(initial: any): Date {
		if (initial === null) {
			return null;
		}
		if (typeof initial === 'string') {
			return TypeTranslationService.parseDate(initial);
		}
		return undefined;
	}

	isArray(value: any): boolean {
		return Object.prototype.toString.call(value) === '[object Array]';
	}

	/**
	 * The parameterized typing allows for nested generics to be present.  We create a small
	 * nested object with arrays that contains the individual typings.
	 *
	 * NOTE: We currently don't have more than a single nested set of generics, so our unit tests
	 * don't test any deeper than the one level.
	 */
	parameterize(type: any) {
		const parameterizedType: any = {};
		parameterizedType.ptype = type;
		parameterizedType.type = parameterizedType.ptype.replace(/<.*>/, '');
		parameterizedType.params = [];
		if (type.indexOf('<') > -1) {
			// Remove the main type and starting <, then remove the >, then remove all of the spaces after ,
			const subtype = type.replace(/^[A-Za-z0-9_.]+</, '').replace(/>$/, '');
			const subtypes = subtype.split(',').map((item) => item.trim());
			for (const loopType of subtypes) {
				parameterizedType.params.push(this.parameterize(loopType));
			}
		}
		return parameterizedType;
	}

	typeIsArray(type: string): boolean {
		return type.indexOf('[]') > -1;
	}


}
