// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.history.allergy.AllergyReviewHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class AllergyReviewHistoryResponse extends GandalfModelBase {

	@GandalfConstantDecorator()
	@GandalfProperty()
	category: constants.ReviewHistoryCategory;

	@GandalfProperty()
	comments: string;

	@GandalfProperty()
	drugAllergyComment: string;

	@GandalfProperty()
	encounterId: number;

	@GandalfProperty()
	noKnownDrugAllergies: boolean;

	@GandalfProperty()
	noLatexSensitivity: boolean;

	@GandalfProperty()
	noOtherKnownAllergies: boolean;

	@GandalfProperty()
	otherAllergyComment: string;

	@GandalfProperty()
	personId: number;

	@GandalfProperty()
	reviewDate: Date;

	@GandalfProperty()
	reviewId: number;

	@GandalfProperty()
	reviewedByName: string;

	@GandalfProperty()
	reviewedByUserId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	subCategory: constants.ReviewHistorySubCategory;

}
