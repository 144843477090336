// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.performedservice.AddDiagnosesToPerformedServicesRequest */
/* istanbul ignore next */
@GandalfModel
export class AddDiagnosesToPerformedServicesRequest extends GandalfModelBase {

	@GandalfValidator({ sizeArray: { min: 1, max: 2147483647, message: 'A performed service is required' } })
	@GandalfValidator({ notNull: { message: 'A performed service is required' } })
	@GandalfArray(Number)
	performedServiceIds: number[];

	@GandalfValidator({ sizeArray: { min: 1, max: 2147483647, message: 'A diagnosis is required' } })
	@GandalfValidator({ notNull: { message: 'A diagnosis is required' } })
	@GandalfArray(Number)
	personDiagnosisIds: number[];

}
