@if (true) {
	<div class="panel panel-default">
		<div class="panel-heading">
			<h4 class="panel-title">TriZetto</h4>
			@if (locations?.length > 0) {
				<ul class="nav-tools">
					<li>
						<a class="e-control e-btn e-lib e-success" (click)="openModalWithRefresh()" revTooltip tooltipContent="Add">
							<i class="fa fa-plus"></i>
						</a>
					</li>
				</ul>
			}
		</div>
		<div class="panel-body">
			<div class="alert alert-warning">
				This should only be used if your Location has TriZetto credentials
				which are not the same as your Practice level TriZetto credentials
			</div>
			<rev-ag-grid-container [agGrid]="agGrid">
				<ng-container rev-table-action-menu-left>
					<div class="e-toolbar-item" [attr.data-test-id]="'trizettoCredentialStatusSection'">
						<label>Status</label>
						<rev-status-select-button
							[(ngModel)]="statusFilter"
							[options]="statusFilterOptions"
							(onChange)="filterTable()"
							[attr.data-test-id]="'trizettoCredentialStatusButton'">
						</rev-status-select-button>
					</div>
				</ng-container>
				<ng-container rev-container-grid>
					<ag-grid-angular
						#agGrid
						class="ag-theme-alpine"
						(gridReady)="onGridReady($event)"
						pmsAgAutoFitColumns
						[rowData]="trizettoExternalCredentials"
						(rowDataUpdated)="filterTable()"
						[gridOptions]="gridOptions"
						(rowClicked)="openModalWithRefresh($event.data)"
						[attr.data-test-id]="'trizettoCredentialTable'">
					</ag-grid-angular>
				</ng-container>
			</rev-ag-grid-container>
		</div>
	</div>
}

<ng-template #enableDisableButtonColumn let-data>
	@if (!data.active) {
		<rev-button
			[revGridButton]="'activate'"
			(buttonClick)="updateStatus(data)"
			dataTestId="encounterRolesActivateButton">
		</rev-button>
	}
	@if (data.active) {
		<rev-button
			[revGridButton]="'deactivate'"
			(buttonClick)="updateStatus(data)"
			dataTestId="encounterRolesDeactivateButton">
		</rev-button>
	}
</ng-template>
