// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressOptionalRequest } from './address-optional-request';

// @ts-ignore
import { CreatePatientFamilyDemographicsRequest } from './create-patient-family-demographics-request';

// @ts-ignore
import { CreatePatientFamilyInformationRequest } from './create-patient-family-information-request';

// @ts-ignore
import { CreatePatientFamilyInsuranceRequest } from './create-patient-family-insurance-request';

// @ts-ignore
import { FamilyMemberContactInformationRequest } from './family-member-contact-information-request';

/** See com.rev360.pms.api.controller.patient.family.QuickAddFamilyMemberRequest */
/* istanbul ignore next */
@GandalfModel
export class QuickAddFamilyMemberRequest extends GandalfModelBase {

	@GandalfProperty()
	addressOptionalRequest: AddressOptionalRequest;

	@GandalfProperty()
	contactInformation: FamilyMemberContactInformationRequest;

	@GandalfProperty()
	createPatientFamilyDemographicsRequest: CreatePatientFamilyDemographicsRequest;

	@GandalfProperty()
	createPatientFamilyInformationRequest: CreatePatientFamilyInformationRequest;

	@GandalfProperty()
	createPatientFamilyInsuranceRequest: CreatePatientFamilyInsuranceRequest;

	@GandalfLabel('Is Emergency Contact')
	@GandalfProperty()
	isEmergencyContact: boolean;

	@GandalfLabel('Primary Emergency Contact')
	@GandalfProperty()
	isPrimaryEmergencyContact: boolean;

	@GandalfLabel('Patient Id')
	@GandalfValidator({ notNull: { message: 'Patient Id is required' } })
	@GandalfProperty({ isRequired: true })
	patientId: number;

	@GandalfValidator({ notNull: { message: 'Same Address is required' } })
	@GandalfProperty({ isRequired: true })
	usePatientAddress: boolean;

}
