<div class="ag-cell-label-container" (click)="onHeaderClick()">
	<div class="ag-header-cell-label">
		<ng-container *ngTemplateOutlet="template; context: templateContext"></ng-container>
		@if (isFiltering) {
			<span class="ag-header-icon ag-header-label-icon ag-filter-icon"><span class="ag-icon ag-icon-filter"></span></span>
		}
		@if (params.enableSorting) {
			@if (isAscSort) {
				<span class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon"><span class="ag-icon ag-icon-asc"></span></span>
			}
			@if (isDescSort) {
				<span class="ag-header-icon ag-header-label-icon ag-sort-descending-icon"><span class="ag-icon ag-icon-desc"></span></span>
			}
		}
	</div>
</div>
