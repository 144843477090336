<form
	#templateForm="ngForm"
	(ngSubmit)="updateInvoiceEncounter()"
	[formGroup]="componentForm"
	class="form-horizontal">
	@if (componentForm.invalid && templateForm.submitted) {
		<gandalf-lib-validation-messages
			[form]="componentForm"
			[requestObj]="updateInvoiceEncounterRequest">
		</gandalf-lib-validation-messages>
	}
	<ejs-grid
		autoFitColumns
		class="table-rowlink"
		gridLines="Horizontal"
		[dataSource]="encountersList"
		[autoFitBlacklist]="['templateName']"
		[allowSorting]="true"
		[allowSelection]="true"
		[allowResizing]="true"
		(rowSelected)="submitForm($event)"
		[pmsGridSubmit]="isSearchingOrSubmitting"
		[pmsGridSubmitDefaultSortOptions]="sortOptions">
		<e-columns>
			<e-column field="encounterDate" headerText="Date" [format]="tableDateFormat"></e-column>
			<e-column field="locationName" headerText="Location"></e-column>
			<e-column field="templateName" headerText="Encounter Type"></e-column>
			<e-column field="subTypeValue" headerText="Category"></e-column>
		</e-columns>
	</ejs-grid>
</form>
