<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-sm"
	[attr.data-test-id]="'addDiscountModal'">
	<ng-template #header>
		<div class="dlg-template">Add Discount</div>
	</ng-template>
	<ng-template #content>
		<div>
			<form
				#templateForm="ngForm"
				(ngSubmit)="addDiscount()"
				[formGroup]="componentForm"
				class="form-horizontal">
				@if (componentForm.invalid && templateForm.submitted) {
					<gandalf-lib-validation-messages
						[form]="componentForm"
						[requestObj]="request"
						[attr.data-test-id]="'addDiscountModalValidationMessages'">
					</gandalf-lib-validation-messages>
				}
				<div class="form-group" [attr.data-test-id]="'discountAmountFormGroup'">
					<label class="col-sm-4 control-label required">Discount Amount</label>
					<div class="col-sm-8">
						<pms-always-set-currency-input min="0" formControlName="amount"></pms-always-set-currency-input>
					</div>
				</div>
				<div class="form-group" [attr.data-test-id]="'discountReasonFormGroup'">
					<label class="col-sm-4 control-label">Discount Reason</label>
					<div class="col-sm-8">
						<ejs-dropdownlist
							formControlName="discountReferenceId"
							[dataSource]="discountReasons"
							[showClearButton]="false"
							(change)="populateDescriptionField($event)"
							placeholder="Select Discount Reason">
						</ejs-dropdownlist>
					</div>
				</div>
				<div class="form-group" [attr.data-test-id]="'discountDescriptionFormGroup'">
					<label class="col-sm-4 control-label required">Description</label>
					<div class="col-sm-8">
						<input type="text" class="e-input" formControlName="description">
					</div>
				</div>
			</form>
		</div>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'addDiscountCancelButton'">Cancel</button>
		<button ejs-button [disabled]="isSaving" [isPrimary]="true" (click)="submitForm($event)" [attr.data-test-id]="'addDiscountSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>
