import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { EnumUtil } from 'morgana';
import { _isNil } from '@core/lodash/lodash';
import { ProviderStatus } from '@gandalf/constants';
import { ProviderResponse } from '@gandalf/model/provider-response';

@Pipe({
	name: 'providerName',
})
@Injectable({
	providedIn: 'root',
})
export class ProviderNamePipe implements PipeTransform {

	transform(provider: ProviderResponse, shouldHaveInactiveLabel = false): string {
		if (_isNil(provider)) {
			return '';
		}
		let fullName = '';
		if (EnumUtil.equals(provider.status, ProviderStatus.INACTIVE) && shouldHaveInactiveLabel) {
			fullName = '(Inactive) ';
		}
		fullName = fullName.concat(provider.lastName, ', ', provider.firstName);

		return fullName;
	}

}
