// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { IntakeAllergyListResponse } from './intake-allergy-list-response';

/** See com.rev360.pms.api.controller.person.IntakeAllergyResponse */
/* istanbul ignore next */
@GandalfModel
export class IntakeAllergyResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	dateReported: Date;

	@GandalfArray(IntakeAllergyListResponse)
	intakeAllergies: IntakeAllergyListResponse[];

	@GandalfProperty()
	intakeId: number;

	@GandalfProperty()
	noKnownDrugAllergies: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	noKnownDrugAllergiesReconciledStatus: constants.IntakeReconciledStatus;

	@GandalfProperty()
	noLatexSensitivity: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	noLatexSensitivityReconciledStatus: constants.IntakeReconciledStatus;

	@GandalfProperty()
	noOtherKnownAllergies: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	noOtherKnownAllergiesReconciledStatus: constants.IntakeReconciledStatus;

}
