import { Injectable } from '@angular/core';
import { HIT_PMS_HTML_EVENTS } from '@core/legacy/hit-pms-html.constants';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { SECURITY_CONSTANTS } from '@core/security/security.constants';
import { SortingService } from 'morgana';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { CreateNoteRequest } from '@gandalf/model/create-note-request';
import { FindNotesByParentEntityRequest } from '@gandalf/model/find-notes-by-parent-entity-request';
import { NoteResponse } from '@gandalf/model/note-response';
import { UpdateNoteRequest } from '@gandalf/model/update-note-request';
import { NoteGandalfService } from '@gandalf/services';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class NotesService {

	constructor(
		private noteGandalfService: NoteGandalfService,
		private securityManagerService: SecurityManagerService,
	) { }

	findNotes(request: FindNotesByParentEntityRequest) {
		return this.noteGandalfService.findByParentIdAndEntityType(request).pipe(
			map(notes => this.sortNotes(notes)),
		);
	}

	sortNotes(notes: NoteResponse[]) {
		return SortingService.sortBy(notes, ['id'], ['desc']);
	}

	/* istanbul ignore next: gandalf */
	@ShowSavedSuccessToast()
	updateNote(updateNoteRequest: UpdateNoteRequest) {
		return this.noteGandalfService.update(updateNoteRequest);
	}

	/* istanbul ignore next: gandalf */
	@ShowSavedSuccessToast()
	createNote(createNoteRequest: CreateNoteRequest) {
		return this.noteGandalfService.create(createNoteRequest);
	}

	/* istanbul ignore next: gandalf */
	activateNote(noteId: number) {
		return this.noteGandalfService.activateNote(noteId);
	}

	/* istanbul ignore next: gandalf */
	deactivateNote(noteId: number) {
		return this.noteGandalfService.deactivateNote(noteId);
	}

	/* istanbul ignore next: gandalf */
	getNoteById(noteId: number) {
		return this.noteGandalfService.getNoteById(noteId);
	}

	canEditNote(note: NoteResponse): boolean {
		return note.createdBy !== HIT_PMS_HTML_EVENTS.NOTES.SYSTEM_USER
			&& note.editable
			&& (
				this.securityManagerService.getUserName() === note.createdBy
				|| this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_PATIENT_NOTE_OVERRIDE)
			);
	}
}
