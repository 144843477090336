<ejs-richtexteditor
	[toolbarSettings]="toolbar"
    #richTextEditor
	[(ngModel)]="editorValue"
	(drop)="dropHandler($event)"
	(ngModelChange)="onModelChange(editorValue)"
	[height]="height"
	[pasteCleanupSettings]="pasteCleanupSettings"
	[showCharCount]="true"
	[insertImageSettings]="{allowedTypes: []}">
</ejs-richtexteditor>
