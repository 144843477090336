<ejs-dialog
    #modal
	(close)="cancelModal()"
	cssClass="modal-md"
	[attr.data-test-id]="'selectEncounterModal'">
	<ng-template #header>
		<div class="dlg-template">Select Encounter</div>
	</ng-template>
	<ng-template #content>
		<form>
			<ejs-grid #grid
					  autoFitColumns
					  class="table-rowlink"
					  [autoFitBlacklist]="[]"
					  [allowResizing]="true"
					  [dataSource]="encountersData"
					  [allowSorting]="true"
					  [allowSelection]="true"
					  [pmsGridSubmit]="isSearching"
					  [pmsGridSubmitDefaultSortOptions]="sortOptions"
					  (created)="search()"
					  gridLines="Horizontal"
					  (rowSelected)="closeModal($event)">
				<e-columns>
					<e-column field="encounterDate" headerText="Date" [format]="encounterDateFormatter"></e-column>
					<e-column field="templateName" headerText="Type"></e-column>
					<e-column field="provider" headerText="Provider">
						<ng-template #template let-data>
							{{data.provider | providerName}}
						</ng-template>
					</e-column>
					<e-column field="status" headerText="Status"></e-column>
				</e-columns>
			</ejs-grid>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" (click)="cancelModal()">Cancel</button>
		<button ejs-button (click)="closeModal()" [attr.data-test-id]="'selectEncounterModalSkipButton'">Skip</button>
	</ng-template>
</ejs-dialog>
