// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.PrintStatementsRequest */
/* istanbul ignore next */
@GandalfModel
export class PrintStatementsRequest extends GandalfModelBase {

	@GandalfLabel('Due Date Text')
	@GandalfProperty()
	dueDateText: string;

	@GandalfConstantDecorator()
	@GandalfLabel('Format')
	@GandalfValidator({ notNull: { message: 'Format is required' } })
	@GandalfProperty({ isRequired: true })
	format: constants.StatementFormat;

	@GandalfLabel('Message Text')
	@GandalfProperty()
	messageText: string;

	@GandalfLabel('Print Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	printDate: Date;

}
