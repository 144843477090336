// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PaymentTransactionResponse } from './payment-transaction-response';

/** See com.rev360.pms.api.controller.accounting.payment.AccountingInvoicePaymentResponse */
/* istanbul ignore next */
@GandalfModel
export class AccountingInvoicePaymentResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	amount: number;

	@GandalfProperty()
	comment: string;

	@GandalfProperty()
	id: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	paymentDate: Date;

	@GandalfProperty()
	paymentGroupId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	paymentMethod: constants.PaymentMethodType;

	@GandalfConstantDecorator()
	@GandalfProperty()
	paymentMethodCardType: constants.PaymentMethodCreditCardType;

	@GandalfProperty()
	paymentPayerName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	paymentStatus: constants.PaymentStatus;

	@GandalfProperty()
	paymentTransaction: PaymentTransactionResponse;

	@GandalfProperty()
	practiceLocationId: number;

	@GandalfProperty()
	referenceNumber: string;

}
