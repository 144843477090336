// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { BasicCostReportRequest } from './basic-cost-report-request';

/** See com.rev360.pms.api.controller.reporting.inventory.cost.AdvancedCostReportRequest */
/* istanbul ignore next */
@GandalfModel
export class AdvancedCostReportRequest extends BasicCostReportRequest {

	@GandalfValidator({ sizeString: { fieldLabel: 'Brand', maxLength: 1000, minLength: 0, message: 'Brand must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	brand: string;

	@GandalfValidator({ sizeString: { fieldLabel: 'Collection', maxLength: 1000, minLength: 0, message: 'Collection must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	collection: string;

	@GandalfValidator({ notNull: { message: 'Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	createdOn: Date;

	@GandalfValidator({ sizeString: { fieldLabel: 'Description', maxLength: 1000, minLength: 0, message: 'Description must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	description: string;

	@GandalfValidator({ sizeString: { fieldLabel: 'Filter content', maxLength: 1000, minLength: 0, message: 'Filter content must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	generalFilter: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	inventoryItemStatus: constants.InventoryItemStatus;

	@GandalfProperty()
	locationId: number;

	@GandalfProperty()
	locationProductCategoryId: number;

	@GandalfValidator({ sizeString: { fieldLabel: 'Manufacturer', maxLength: 1000, minLength: 0, message: 'Manufacturer must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	manufacturer: string;

	@GandalfValidator({ sizeString: { fieldLabel: 'Model', maxLength: 1000, minLength: 0, message: 'Model must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	model: string;

	@GandalfProperty()
	quantityInStockMax: number;

	@GandalfProperty()
	quantityInStockMin: number;

	@GandalfProperty({ propertyType: 'Money' })
	retailPriceMax: number;

	@GandalfProperty({ propertyType: 'Money' })
	retailPriceMin: number;

	@GandalfValidator({ sizeString: { fieldLabel: 'SKU', maxLength: 1000, minLength: 0, message: 'SKU must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	sku: string;

	@GandalfValidator({ sizeString: { fieldLabel: 'UPC', maxLength: 1000, minLength: 0, message: 'UPC must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	upc: string;

}
