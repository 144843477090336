// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.encounter.examtestlibrary.AdminFindWorkflowTestTemplateSvcTriggerRequest */
/* istanbul ignore next */
@GandalfModel
export class AdminFindWorkflowTestTemplateSvcTriggerRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Practice Location is required' } })
	@GandalfProperty({ isRequired: true })
	practiceLocationId: number;

	@GandalfValidator({ notNull: { message: 'Template is required' } })
	@GandalfProperty({ isRequired: true })
	templateId: number;

}
