import { Component, OnInit, ViewChild } from '@angular/core';
import { EncounterService } from '@core/encounter/encounter.service';
import { DynamicModalRef, ModalConfig, PAGE_LENGTH, PAGE_LENGTH_LIST } from 'morgana';
import { EncounterPullForwardTableResponse } from '@gandalf/model/encounter-pull-forward-table-response';
import { TABLE_DATE_FORMATS } from '@shared/constants/date-format.constants';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { PageSettingsModel, SortSettingsModel } from '@syncfusion/ej2-grids';

@Component({
	selector: 'pms-encounter-select-by-patient-non-cancelled-pull-forward-modal',
	templateUrl: './encounter-select-by-patient-non-cancelled-pull-forward-modal.component.html',
})
export class EncounterSelectByPatientNonCancelledPullForwardModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	patientId: number;
	encounterId: number;
	encounters: EncounterPullForwardTableResponse[] = [];
	tableDateFormat = TABLE_DATE_FORMATS.MM_DD_YYYY;
	isSearching = false;
	sortOptions: SortSettingsModel = {
		columns: [
			{
				field: 'encounterDate',
				direction: 'Descending',
			},
		],
	};
	pageSettings: PageSettingsModel = {
		pageSize: PAGE_LENGTH.PAGE_10,
		pageSizes: PAGE_LENGTH_LIST,
	};

	constructor(
		public dynamicModalRef: DynamicModalRef,
		public modalConfig: ModalConfig,
		public encounterService: EncounterService,
	) {
	}


	ngOnInit() {
		this.parseConfigData(this.modalConfig.data);
		this.search();
	}

	parseConfigData(data: any) {
		this.patientId = data.patientId;
		this.encounterId = data.encounterId;
	}

	search() {
		this.isSearching = true;
		this.encounterService
			.findNonCanceledEncountersByPatientIdForPullForward(
				this.patientId,
				this.encounterId,
			)
			.subscribe((data) => {
				this.isSearching = false;
				this.encounters = data;
			});
	}

	/* istanbul ignore next: closeModal */
	closeModal(encounter?: EncounterPullForwardTableResponse) {
		this.dynamicModalRef.close(this.modal, encounter);
	}

}
