import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { HIT_PMS_HTML_EVENTS } from '@core/legacy/hit-pms-html.constants';
import { _isNil } from '@core/lodash/lodash';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import {
	AutoDxDefaultTabPreferenceValues,
	OngoingCareDefaultTabPreferenceValues,
	OpticalSummaryDefaultViewPreferenceValues,
	PatientDefaultSummaryViewPreferenceValues,
	PreferenceName,
	PreferenceYesNoOptions,
	SchedulePodDefaultTabPreferenceValues
} from '@gandalf/constants';
import { ScheduleFilterRequest } from '@gandalf/model/schedule-filter-request';
import { ScheduleFilterModalComponent } from '@shared/component/schedule-filter-modal/schedule-filter-modal.component';
import { GandalfConstant } from 'gandalf';
import { EnumUtil, ModalManagerService } from 'morgana';
import { SECURITY_CONSTANTS } from '@core/security/security.constants';

const HEADER_HEIGHT = 40;

@Component({
	selector: 'pms-employee-preferences',
	templateUrl: './employee-preferences.component.html',
	providers: [ModalManagerService],
})
export class EmployeePreferencesComponent implements OnInit {

	@Input()
	formGroup: UntypedFormGroup;

	@Input()
	isUserProfile = false;

	isSignatureFlagOn: boolean;
	isTwoWayTextFlagOn: boolean;
	PreferenceName = PreferenceName;
	preferenceYesNoOptions = PreferenceYesNoOptions.VALUES.values;
	preferenceDefaultPatientViewOptions = PatientDefaultSummaryViewPreferenceValues.VALUES.values;
	preferenceOpticalViewOptions = OpticalSummaryDefaultViewPreferenceValues.VALUES.values;
	preferenceOngoingCareDefaultTabViewOptions = OngoingCareDefaultTabPreferenceValues.VALUES.values;
	preferenceSchedulePodDefaultTabOptions = SchedulePodDefaultTabPreferenceValues.VALUES.values;
	preferenceAutoDxDefaultTabOptions = AutoDxDefaultTabPreferenceValues.VALUES.values;
	maxStepper = 99;
	revConnectEnabled = false;
	revConnectTwoWayTextingEnabled = false;
	isEyeglassSignatureChecked: boolean;
	isContactLensSignatureChecked: boolean;
	isMedicationSignatureChecked: boolean;

	constructor(
		private securityManagerService: SecurityManagerService,
		public modalManagerService: ModalManagerService,
		private eventsManagerService: EventsManagerService,
		private featureService: FeatureService,
		public securityManager: SecurityManagerService,
	) {
	}

	ngOnInit() {
		this.revConnectEnabled = this.securityManagerService.practiceHasAnyNonCampaignsSolution();
		this.revConnectTwoWayTextingEnabled = this.securityManagerService.allowTwoWayTexting();
		this.onScheduleValidationEnabledChange();
		this.eventsManagerService.subscribe(HIT_PMS_HTML_EVENTS.ADMIN.UPDATE_EMPLOYEE_CCDA_DISPLAY, data => {
			this.getFormControl(PreferenceName.EMPLOYEE_CCDA_DISPLAY).setValue(data.argument);
		});
		this.initializeFeatures();
		this.initializeCheckBoxValues();
	}

	getFormControl(preferenceName: PreferenceName): UntypedFormControl {
		return (this.formGroup.get('stringAdditionalPreferences') as UntypedFormArray).controls.find(
			(formGroup: UntypedFormGroup) => EnumUtil.equals(formGroup.get('preferenceName').value, preferenceName)).get('value') as UntypedFormControl;
	}

	enforceRange(preferenceName: PreferenceName, maxValue = this.maxStepper) {
		const control = this.getFormControl(preferenceName);
		if (control.value > maxValue) {
			control.setValue(maxValue);
		} else if (control.value < 0) {
			control.setValue(0);
		}
	}

	initializeFeatures() {
		this.isSignatureFlagOn = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.EMPLOYEES.EMPLOYEE_PREFERENCES);
		this.isTwoWayTextFlagOn = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.REVCONNECT.TWO_WAY_TEXT);
	}

	/* istanbul ignore next */
	openCustomFilterModal() {
		this.modalManagerService.open(ScheduleFilterModalComponent, {
			data: {
				scheduleFilters: this.formGroup.get('scheduleFilters').value,
			},
		}).onClose.subscribe((data: ScheduleFilterRequest) => {
			if (!_isNil(data)) {
				this.formGroup.get('scheduleFilters').setValue(data);
			}
		});
	}

	/* istanbul ignore next: FormUtilsService.enabledWhen */
	private onControlChange(changedControl: UntypedFormControl, enableValues: GandalfConstant<string>[], affectedControls: UntypedFormControl[]) {
		const enable = !!enableValues.find(value => value.value === changedControl.value);
		affectedControls.forEach(control => FormUtilsService.enabledWhen(control, enable));
	}

	/* istanbul ignore next: onControlChange */
	onScheduleValidationEnabledChange() {
		this.onControlChange(
			this.getFormControl(PreferenceName.SCHEDULE_VALIDATION_ENABLED),
			[PreferenceYesNoOptions.YES],
			[
				this.getFormControl(PreferenceName.SCHEDULE_OFFICE_HOURS),
				this.getFormControl(PreferenceName.SCHEDULE_EMPLOYEE_HOURS),
				this.getFormControl(PreferenceName.SCHEDULE_BUSY_TIME),
				this.getFormControl(PreferenceName.SCHEDULE_MULTIPLE_ACTIVE_APPOINTMENTS),
			],
		);
	}

	/* istanbul ignore next */
	openCcdaModal() {
		this.eventsManagerService.publish(HIT_PMS_HTML_EVENTS.ADMIN.CCDA_DISPLAY, this.getFormControl(PreferenceName.EMPLOYEE_CCDA_DISPLAY).value);
	}

	scrollTo(element) {
		if (this.isUserProfile) {
			const container = document.getElementsByClassName('e-dlg-content')[0];
			container.scrollTo(
				{
					top: element.getBoundingClientRect().top - container.getBoundingClientRect().top + container.scrollTop,
					behavior: 'smooth',
				},
			);
		} else {
			window.scrollTo({
				top: element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - HEADER_HEIGHT,
				behavior: 'smooth',
			});
		}

	}

	initializeCheckBoxValues() {
		if (!this.isSignatureFlagOn) {
			return;
		}
		this.isEyeglassSignatureChecked = this.getFormControl(PreferenceName.PROVIDER_EYEGLASS_USE_SIGNATURE_IMAGE).value === PreferenceYesNoOptions.YES.value;
		this.isContactLensSignatureChecked = this.getFormControl(PreferenceName.PROVIDER_CONTACT_LENS_USE_SIGNATURE_IMAGE).value === PreferenceYesNoOptions.YES.value;
		this.isMedicationSignatureChecked = this.getFormControl(PreferenceName.PROVIDER_MEDICATION_USE_SIGNATURE_IMAGE).value === PreferenceYesNoOptions.YES.value;
	}

	onCheckboxChange(preferenceName: PreferenceName, value: boolean) {
		this.getFormControl(preferenceName).setValue(value.toString());
	}

	showRevConnectSection() {
		return this.isTwoWayTextFlagOn
			&& this.revConnectTwoWayTextingEnabled
			&& this.securityManager.hasPermission(SECURITY_CONSTANTS.RESOURCE_CONNECT_VIEW)
			&& this.securityManager.hasPermission(SECURITY_CONSTANTS.RESOURCE_CONNECT_VIEW_TWO_WAY_TEXT);
	}
}
