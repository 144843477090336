@for (option of options; track option; let i = $index) {
	<ejs-radiobutton
		#btn
		cssClass="e-control e-lib margin-x-xs margin-right-md"
		[ngClass]="buildNgClass(option, btn)"
		[name]="name"
		[value]="option.value"
		[label]="option.label"
		[checked]="isSelected(option)"
		[disabled]="disabled"
		[attr.aria-label]="option.label"
		[attr.data-test-id]="buildDataTestIdForOption(option)"
		(click)="onItemClick($event, option, i)"
		(keydown.enter)="onItemClick($event, option, i)"
		(focus)="onFocus($event)"
		(blur)="onBlur($event)"
	></ejs-radiobutton>
}
