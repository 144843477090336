<ejs-dialog
	#modal
	[cssClass]="isParsing() || isFinishing() ? 'modal-sm' : 'modal-lg'"
	(close)="closeDialog()">
	<ng-template #header>
		{{getTitle()}}
	</ng-template>
	<ng-template #content>
		<div *ngIf="isParsing() || isFinishing()" class="text-center"><i class="fa fa-spinner fa-pulse fa-spin fa-3x"></i></div>
		<div *ngIf="isShowingParsingErrors()">
			<div class="text-danger">{{importResult.cdaIncorporationAlertMessage}}</div>
			<ejs-tab [animation]="tabAnimation">
				<e-tabitems>
					<e-tabitem [header]="{text: 'Errors'}">
						<ng-template #content>
							<table class="table table-hover table-bordered margin-bottom-sm table-striped">
								<tbody>
									<tr *ngFor="let error of importResult.errorMessages">
										<td>
											{{error}}
										</td>
									</tr>
								</tbody>
							</table>
						</ng-template>
					</e-tabitem>
					<e-tabitem [header]="{text: 'Warnings'}">
						<ng-template #content>
							<table class="table table-hover table-bordered margin-bottom-sm table-striped">
								<tbody>
								<tr *ngFor="let warning of importResult.warningMessages">
									<td>
										{{warning}}
									</td>
								</tr>
								</tbody>
							</table>
						</ng-template>
					</e-tabitem>
				</e-tabitems>
			</ejs-tab>
		</div>
		<div *ngIf="isReconciling()">
			<ejs-tab [animation]="tabAnimation" #reconciliationTabs>
				<e-tabitems>
					<e-tabitem [header]="{text: 'View Documents'}" [disabled]="true">
						<ng-template #content>
							<div *ngIf="!!importResult.cdaIncorporationAlertMessage" class="alert alert-danger margin-bottom-sm">
								{{importResult.cdaIncorporationAlertMessage}}
							</div>
							<button ejs-button [isPrimary]="true" (click)="viewDocument()">View Document</button>
						</ng-template>
					</e-tabitem>
					<e-tabitem [header]="{text: 'Diagnoses'}" [disabled]="true">
						<ng-template #content>
							<div class="row" *ngIf="!consolidatedDiagnoses">
								<div class="col-md-6">
									<h4 class="section-title bg-default mrgn-btm">Incoming Document</h4>
									<div class="margin-bottom-sm">
										<ejs-grid #importedDiagnosesGrid
												  autoFitColumns
												  [autoFitBlacklist]="['shortDescription']"
												  [staticColumns]="['buttons', 'diagnosisDate', 'lastModificationDate']"
												  [dataSource]="importedDiagnoses"
												  gridLines="Horizontal"
												  [allowSorting]="true"
												  [allowSelection]="false"
												  [allowPaging]="true"
												  [pageSettings]="pageSettings"
												  [allowResizing]="true"
												  [allowFiltering]="true"
												  [allowExcelExport]="true"
												  class="table-rowlink"
												  [filterSettings]="{showFilterBarStatus: false}">
											<e-columns>
												<e-column field="diagnosisDate" headerText="Dx Date" [width]="105" [format]="tableDateFormat"></e-column>
												<e-column field="code" headerText="Code"></e-column>
												<e-column field="shortDescription" headerText="Description" clipMode="EllipsisWithTooltip"></e-column>
												<e-column field="status.label" headerText="Status"></e-column>
												<e-column field="lastModificationDate" headerText="Last Mod. Date" [width]="142" [format]="tableDateFormat"></e-column>
												<e-column field="buttons"
														  headerText=""
														  [customAttributes]="{exportable: false}"
														  [allowResizing]="false"
														  [allowSorting]="false"
														  [width]="100">
													<ng-template #template let-data>
														<rev-button
																[buttonDisabled]="currentPatientDiagnosesGrid.getSelectedRecords()?.length < 1"
																buttonIcon="sign-in"
																buttonStyle="success"
																buttonTooltip="Merge"
																revEventStopPropagation
																(buttonClick)="mergeDiagnoses(data, currentPatientDiagnosesGrid)"
																dataTestId="incorporateIncomingDiagnosisMergeButton">
														</rev-button>
														<rev-button
																[revGridButton]="'remove'"
																(buttonClick)="removeFromImportedDiagnoses(data)"
																dataTestId="incorporateIncomingDiagnosisRemoveButton">
														</rev-button>
													</ng-template>
												</e-column>
											</e-columns>
										</ejs-grid>
									</div>
								</div>
								<div class="col-md-6">
									<h4 class="section-title bg-default mrgn-btm">Patient Record</h4>
									<div class="margin-bottom-sm">
										<ejs-grid #currentPatientDiagnosesGrid
												  autoFitColumns
												  [autoFitBlacklist]="['shortDescription']"
												  [staticColumns]="['buttons', 'diagnosisDate', 'updatedOn']"
												  [dataSource]="currentPatientDiagnoses"
												  gridLines="Horizontal"
												  [allowSorting]="true"
												  [allowSelection]="true"
												  [allowPaging]="true"
												  [pageSettings]="pageSettings"
												  [allowResizing]="true"
												  [allowFiltering]="true"
												  [allowExcelExport]="true"
												  class="table-rowlink"
												  [filterSettings]="{showFilterBarStatus: false}">
											<e-columns>
												<e-column field="diagnosisDate" headerText="Dx Date" [width]="105" [format]="tableDateFormat"></e-column>
												<e-column field="code" headerText="Code"></e-column>
												<e-column field="shortDescription" headerText="Description" clipMode="EllipsisWithTooltip"></e-column>
												<e-column field="status.label" headerText="Status"></e-column>
												<e-column field="updatedOn" headerText="Last Mod. Date" [width]="142" [format]="tableDateFormat"></e-column>
												<e-column field="buttons"
														  headerText=""
														  [customAttributes]="{exportable: false}"
														  [allowResizing]="false"
														  [allowSorting]="false"
														  [width]="50">
													<ng-template #template let-data>
														<rev-button
																[revGridButton]="'remove'"
																(buttonClick)="removeFromPatientDiagnoses(data)"
																dataTestId="incorporateCurrentDiagnosisRemoveButton">
														</rev-button>
													</ng-template>
												</e-column>
											</e-columns>
										</ejs-grid>
									</div>
								</div>
							</div>
							<div class="row" *ngIf="consolidatedDiagnoses">
								<div class="col-md-12">
									<h4 class="section-title bg-default mrgn-btm">Consolidated</h4>
									<div class="margin-bottom-sm">
										<ejs-grid #importedDiagnosesGrid
												  autoFitColumns
												  [autoFitBlacklist]="['shortDescription']"
												  [staticColumns]="['buttons', 'diagnosisDate', 'lastModificationDate']"
												  [dataSource]="consolidatedDiagnoses"
												  gridLines="Horizontal"
												  [allowSorting]="true"
												  [allowSelection]="false"
												  [allowPaging]="true"
												  [pageSettings]="pageSettings"
												  [allowResizing]="true"
												  [allowFiltering]="true"
												  [allowExcelExport]="true"
												  class="table-rowlink"
												  [filterSettings]="{showFilterBarStatus: false}">
											<e-columns>
												<e-column field="diagnosisDate" headerText="Dx Date" [width]="105" [format]="tableDateFormat"></e-column>
												<e-column field="code" headerText="Code"></e-column>
												<e-column field="shortDescription" headerText="Description" clipMode="EllipsisWithTooltip"></e-column>
												<e-column field="status.label" headerText="Status"></e-column>
												<e-column field="lastModificationDate" headerText="Last Mod. Date" [width]="142" [format]="tableDateFormat"></e-column>
												<e-column field="buttons"
														  headerText=""
														  [customAttributes]="{exportable: false}"
														  [allowResizing]="false"
														  [allowSorting]="false"
														  [width]="100">
													<ng-template #template let-data>
														<rev-button
																[revGridButton]="'remove'"
																(buttonClick)="removeFromConsolidatedDiagnoses(data)"
																dataTestId="incorporateConsolidatedDiagnosisRemoveButton">
														</rev-button>
													</ng-template>
												</e-column>
											</e-columns>
										</ejs-grid>
									</div>
								</div>
							</div>
							<div class="margin-top-sm text-right">
								<button ejs-button *ngIf="!consolidatedDiagnoses" (click)="resetDiagnoses()">Reset</button>
								<button ejs-button *ngIf="!consolidatedDiagnoses" (click)="consolidateDiagnoses()">Consolidate</button>
								<button ejs-button *ngIf="consolidatedDiagnoses" (click)="undoDiagnosesConsolidation()">Undo</button>
							</div>
						</ng-template>
					</e-tabitem>
					<e-tabitem [header]="{text: 'Medication Prescriptions'}" [disabled]="true">
						<ng-template #content>
							<div class="row" *ngIf="!consolidatedMedications">
								<div class="col-md-6">
									<h4 class="section-title bg-default mrgn-btm">Incoming Document</h4>
									<div class="margin-bottom-sm">
										<ejs-grid #importedMedicationsGrid
												  autoFitColumns
												  [autoFitBlacklist]="['description']"
												  [staticColumns]="['buttons', 'startDate', 'lastModificationDate']"
												  [dataSource]="importedMedicationPrescriptions"
												  gridLines="Horizontal"
												  [allowSorting]="true"
												  [allowSelection]="false"
												  [allowPaging]="true"
												  [pageSettings]="pageSettings"
												  [allowResizing]="true"
												  [allowFiltering]="true"
												  [allowExcelExport]="true"
												  class="table-rowlink"
												  [filterSettings]="{showFilterBarStatus: false}">
											<e-columns>
												<e-column field="startDate" headerText="Start Date" [width]="105" [format]="tableDateFormat"></e-column>
												<e-column field="description" headerText="Description" clipMode="EllipsisWithTooltip"></e-column>
												<e-column field="code" headerText="Code"></e-column>
												<e-column field="statusString" headerText="Status"></e-column>
												<e-column field="lastModificationDate" headerText="Last Mod. Date" [width]="142" [format]="tableDateFormat"></e-column>
												<e-column field="buttons"
														  headerText=""
														  [customAttributes]="{exportable: false}"
														  [allowResizing]="false"
														  [allowSorting]="false"
														  [width]="100">
													<ng-template #template let-data>
														<rev-button
																[buttonDisabled]="currentPatientMedicationsGrid.getSelectedRecords()?.length < 1"
																buttonIcon="sign-in"
																buttonStyle="success"
																buttonTooltip="Merge"
																revEventStopPropagation
																(buttonClick)="mergeMedications(data, currentPatientMedicationsGrid)"
																dataTestId="incorporateIncomingMedicationMergeButton">
														</rev-button>
														<rev-button
																[revGridButton]="'remove'"
																(buttonClick)="removeFromImportedMedicationPrescriptions(data)"
																dataTestId="incorporateIncomingMedicationRemoveButton">
														</rev-button>
													</ng-template>
												</e-column>
											</e-columns>
										</ejs-grid>
									</div>
								</div>
								<div class="col-md-6">
									<h4 class="section-title bg-default mrgn-btm">Patient Record</h4>
									<div class="margin-bottom-sm">
										<ejs-grid #currentPatientMedicationsGrid
												  autoFitColumns
												  [autoFitBlacklist]="['description']"
												  [staticColumns]="['buttons', 'startDate', 'updatedOn']"
												  [dataSource]="currentMedicationPrescriptions"
												  gridLines="Horizontal"
												  [allowSorting]="true"
												  [allowSelection]="true"
												  [allowPaging]="true"
												  [pageSettings]="pageSettings"
												  [allowResizing]="true"
												  [allowFiltering]="true"
												  [allowExcelExport]="true"
												  class="table-rowlink"
												  [filterSettings]="{showFilterBarStatus: false}">
											<e-columns>
												<e-column field="startDate" headerText="Start Date" [width]="105" [format]="tableDateFormat"></e-column>
												<e-column field="description" headerText="Description" clipMode="EllipsisWithTooltip"></e-column>
												<e-column field="code" headerText="Code"></e-column>
												<e-column field="statusForDisplay" headerText="Status"></e-column>
												<e-column field="modifiedDate" headerText="Last Mod. Date" [width]="142" [format]="tableDateFormat"></e-column>
												<e-column field="buttons"
														  headerText=""
														  [customAttributes]="{exportable: false}"
														  [allowResizing]="false"
														  [allowSorting]="false"
														  [width]="50">
													<ng-template #template let-data>
														<rev-button
																[revGridButton]="'remove'"
																(buttonClick)="removeFromPatientMedicationPrescriptions(data)"
																dataTestId="incorporateCurrentMedicationRemoveButton">
														</rev-button>
													</ng-template>
												</e-column>
											</e-columns>
										</ejs-grid>
									</div>
								</div>
							</div>
							<div class="row" *ngIf="consolidatedMedications">
								<div class="col-md-12">
									<h4 class="section-title bg-default mrgn-btm">Incoming Document</h4>
									<div class="margin-bottom-sm">
										<ejs-grid #importedMedicationsGrid
												  autoFitColumns
												  [autoFitBlacklist]="['description']"
												  [staticColumns]="['buttons', 'startDate', 'lastModificationDate']"
												  [dataSource]="consolidatedMedications"
												  gridLines="Horizontal"
												  [allowSorting]="true"
												  [allowSelection]="false"
												  [allowPaging]="true"
												  [pageSettings]="pageSettings"
												  [allowResizing]="true"
												  [allowFiltering]="true"
												  [allowExcelExport]="true"
												  class="table-rowlink"
												  [filterSettings]="{showFilterBarStatus: false}">
											<e-columns>
												<e-column field="startDate" headerText="Start Date" [width]="105" [format]="tableDateFormat"></e-column>
												<e-column field="description" headerText="Description" clipMode="EllipsisWithTooltip"></e-column>
												<e-column field="code" headerText="Code"></e-column>
												<e-column field="statusString" headerText="Status"></e-column>
												<e-column field="lastModificationDate" headerText="Last Mod. Date" [width]="142" [format]="tableDateFormat"></e-column>
												<e-column field="buttons"
														  headerText=""
														  [customAttributes]="{exportable: false}"
														  [allowResizing]="false"
														  [allowSorting]="false"
														  [width]="100">
													<ng-template #template let-data>
														<rev-button
																[revGridButton]="'remove'"
																(buttonClick)="removeFromConsolidatedMedicationPrescriptions(data)"
																dataTestId="incorporateConsolidatedMedicationRemoveButton">
														</rev-button>
													</ng-template>
												</e-column>
											</e-columns>
										</ejs-grid>
									</div>
								</div>
							</div>
							<div class="margin-top-sm text-right">
								<button ejs-button *ngIf="!consolidatedMedications" (click)="resetMedications()">Reset</button>
								<button ejs-button *ngIf="!consolidatedMedications" (click)="consolidateMedications()">Consolidate</button>
								<button ejs-button *ngIf="consolidatedMedications" (click)="undoMedicationConsolidation()">Undo</button>
							</div>
						</ng-template>
					</e-tabitem>
					<e-tabitem [header]="{text: 'Drug Allergies'}" [disabled]="true">
						<ng-template #content>
							<div class="row" *ngIf="!consolidatedAllergies">
								<div class="col-md-6">
									<h4 class="section-title bg-default mrgn-btm">Incoming Document</h4>
									<div class="margin-bottom-sm">
										<ejs-grid #importedDrugAllergiesGrid
												  autoFitColumns
												  [autoFitBlacklist]="['drugName', 'reactionsDisplayValue']"
												  [staticColumns]="['buttons', 'startDate', 'lastModificationDate']"
												  [dataSource]="importedAllergies"
												  gridLines="Horizontal"
												  [allowSorting]="true"
												  [sortSettings]="sortSettings"
												  [allowSelection]="true"
												  [allowPaging]="true"
												  [pageSettings]="pageSettings"
												  [allowResizing]="true"
												  [allowFiltering]="true"
												  [allowExcelExport]="true"
												  class="table-rowlink"
												  [filterSettings]="{showFilterBarStatus: false}">
											<e-columns>
												<e-column field="startDate" headerText="Start Date" [width]="105" [format]="tableDateFormat"></e-column>
												<e-column field="drugCode" headerText="Code"></e-column>
												<e-column field="drugName" headerText="Med Name"></e-column>
												<e-column field="status.label" headerText="Status"></e-column>
												<e-column field="lastModificationDate" headerText="Last Mod. Date" [width]="142" [format]="tableDateFormat"></e-column>
												<e-column field="reactionsDisplayValue" headerText="Reactions" clipMode="EllipsisWithTooltip"></e-column>
												<e-column field="buttons"
														  headerText=""
														  [customAttributes]="{exportable: false}"
														  [allowResizing]="false"
														  [allowSorting]="false"
														  [width]="100">
													<ng-template #template let-data>
														<rev-button
																[buttonDisabled]="currentPatientDrugAllergiesGrid.getSelectedRecords()?.length < 1"
																buttonIcon="sign-in"
																buttonStyle="success"
																buttonTooltip="Merge"
																revEventStopPropagation
																(buttonClick)="mergeAllergies(data, currentPatientDrugAllergiesGrid)"
																dataTestId="incorporateIncomingAllergyMergeButton">
														</rev-button>
														<rev-button
																[revGridButton]="'remove'"
																(buttonClick)="removeFromImportedDrugAllergies(data)"
																dataTestId="incorporateIncomingAllergyRemoveButton">
														</rev-button>
													</ng-template>
												</e-column>
											</e-columns>
										</ejs-grid>
									</div>
								</div>
								<div class="col-md-6">
									<h4 class="section-title bg-default mrgn-btm">Patient Record</h4>
									<div class="margin-bottom-sm">
										<ejs-grid #currentPatientDrugAllergiesGrid
												  autoFitColumns
												  [autoFitBlacklist]="['displayName', 'reactionsDisplayValue']"
												  [staticColumns]="['buttons', 'startDate', 'updatedOn']"
												  [dataSource]="currentDrugAllergies"
												  gridLines="Horizontal"
												  [allowSorting]="true"
												  [allowSelection]="true"
												  [allowPaging]="true"
												  [pageSettings]="pageSettings"
												  [allowResizing]="true"
												  [allowFiltering]="true"
												  [allowExcelExport]="true"
												  class="table-rowlink"
												  [filterSettings]="{showFilterBarStatus: false}">
											<e-columns>
												<e-column field="startDate" headerText="Start Date" [width]="105" [format]="tableDateFormat"></e-column>
												<e-column field="drugCode" headerText="Code"></e-column>
												<e-column field="displayName" headerText="Med Name"></e-column>
												<e-column field="status.label" headerText="Status"></e-column>
												<e-column field="updatedDate" headerText="Last Mod. Date" [width]="142" [format]="tableDateFormat"></e-column>
												<e-column field="reactionsDisplayValue" headerText="Reactions" clipMode="EllipsisWithTooltip"></e-column>
												<e-column field="buttons"
														  headerText=""
														  [customAttributes]="{exportable: false}"
														  [allowResizing]="false"
														  [allowSorting]="false"
														  [width]="50">
													<ng-template #template let-data>
														<rev-button
																[revGridButton]="'remove'"
																(buttonClick)="removeFromPatientDrugAllergies(data)"
																dataTestId="incorporateCurrentAllergyRemoveButton">
														</rev-button>
													</ng-template>
												</e-column>
											</e-columns>
										</ejs-grid>
									</div>
								</div>
							</div>
							<div class="row" *ngIf="consolidatedAllergies">
								<div class="col-md-12">
									<h4 class="section-title bg-default mrgn-btm">Consolidated</h4>
									<div class="margin-bottom-sm">
										<ejs-grid #importedDrugAllergiesGrid
												  autoFitColumns
												  [autoFitBlacklist]="['drugName', 'reactionsDisplayValue']"
												  [staticColumns]="['buttons', 'startDate', 'lastModificationDate']"
												  [dataSource]="consolidatedAllergies"
												  gridLines="Horizontal"
												  [allowSorting]="true"
												  [sortSettings]="sortSettings"
												  [allowSelection]="true"
												  [allowPaging]="true"
												  [pageSettings]="pageSettings"
												  [allowResizing]="true"
												  [allowFiltering]="true"
												  [allowExcelExport]="true"
												  class="table-rowlink"
												  [filterSettings]="{showFilterBarStatus: false}">
											<e-columns>
												<e-column field="startDate" headerText="Start Date" [width]="105" [format]="tableDateFormat"></e-column>
												<e-column field="drugCode" headerText="Code"></e-column>
												<e-column field="drugName" headerText="Med Name"></e-column>
												<e-column field="status.label" headerText="Status"></e-column>
												<e-column field="lastModificationDate" headerText="Last Mod. Date" [width]="142" [format]="tableDateFormat"></e-column>
												<e-column field="reactionsDisplayValue" headerText="Reactions" clipMode="EllipsisWithTooltip"></e-column>
												<e-column field="buttons"
														  headerText=""
														  [customAttributes]="{exportable: false}"
														  [allowResizing]="false"
														  [allowSorting]="false"
														  [width]="100">
													<ng-template #template let-data>
														<rev-button
																[revGridButton]="'remove'"
																(buttonClick)="removeFromConsolidatedDrugAllergies(data)"
																dataTestId="incorporateConsolidatedAllergyRemoveButton">
														</rev-button>
													</ng-template>
												</e-column>
											</e-columns>
										</ejs-grid>
									</div>
								</div>
							</div>
							<div class="margin-top-sm text-right">
								<button ejs-button *ngIf="!consolidatedAllergies" (click)="resetDrugAllergies()">Reset</button>
								<button ejs-button *ngIf="!consolidatedAllergies" (click)="consolidateAllergies()">Consolidate</button>
								<button ejs-button *ngIf="consolidatedAllergies" (click)="undoAllergyConsolidation()">Undo</button>
							</div>
						</ng-template>
					</e-tabitem>
				</e-tabitems>
			</ejs-tab>
		</div>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button type="button" class="pull-left mrgn-0" (click)="copyWarningsAndErrorsToClipboard()" *ngIf="isShowingParsingErrors()">Copy</button>
		<button ejs-button type="button" class="pull-left margin-left-sm" (click)="viewDocument()" *ngIf="isShowingParsingErrors()">View Document</button>
		<button ejs-button type="button" (click)="closeDialog()" *ngIf="isShowingParsingErrors()">Close</button>
		<button ejs-button type="button" (click)="backStep()" *ngIf="isReconciling()">Back</button>
		<button ejs-button type="button" [isPrimary]="true" (click)="continueToNextStep()" *ngIf="showContinueButton()">Continue</button>
	</ng-template>
</ejs-dialog>
