// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.reporting.admin.UserSessionLogResponse */
/* istanbul ignore next */
@GandalfModel
export class UserSessionLogResponse extends GandalfModelBase {

	@GandalfConstantDecorator()
	@GandalfProperty()
	action: constants.UserSessionLogAction;

	@GandalfProperty()
	additionalInfo: string;

	@GandalfProperty()
	dataLogId: number;

	@GandalfProperty()
	date: Date;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	isCorrupt: boolean;

	@GandalfProperty()
	patientName: PatientNameResponse;

	@GandalfProperty()
	primaryEntityId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	primaryEntityType: constants.UserSessionLogEntityType;

	@GandalfProperty()
	secondaryEntityId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	secondaryEntityType: constants.UserSessionLogEntityType;

	@GandalfProperty()
	userId: number;

	@GandalfProperty()
	userName: string;

}
