import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicModalRef, ModalConfig, ModalManagerService } from 'morgana';
import { UpdateInvoiceFinanceChargePlanRequest } from '@gandalf/model/update-invoice-finance-charge-plan-request';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { AccountingService } from '../../core/accounting/accounting.service';
import { InvoiceService } from '../../core/accounting/invoice-service/invoice.service';

@Component({
	selector: 'pms-finance-charge-plan-select-modal',
	templateUrl: './finance-charge-plan-select-modal.component.html',
	styles: [],
})
export class FinanceChargePlanSelectModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	invoiceId: number;
	financeChargePlanId: number;
	financeChargePlansList;

	constructor(
		public ref: DynamicModalRef,
		public modalManagerService: ModalManagerService,
		public modalConfig: ModalConfig,
		public accountingService: AccountingService,
		public invoiceService: InvoiceService,
	) { }

	ngOnInit() {
		this.getFinanceChargePlans();
		this.invoiceId = this.modalConfig.data.invoiceId;
	}

	getFinanceChargePlans() {
		this.accountingService.findActiveFinanceChargePlansForDropdown().subscribe((financeChargePlans) => {
			this.financeChargePlansList = financeChargePlans;
			this.financeChargePlanId = this.modalConfig.data.financeChargePlanId;
		});
	}

	closeModal(updated: boolean) {
		this.ref.close(this.modal, updated);
	}

	saveFinanceChargePlanAndRefreshInvoice() {
		const request: UpdateInvoiceFinanceChargePlanRequest = new UpdateInvoiceFinanceChargePlanRequest();
		request.invoiceId = this.invoiceId;
		request.financeChargePlanId = this.financeChargePlanId;
		this.accountingService.updateInvoiceFinanceChargePlan(request).subscribe(() => {
			this.invoiceService.refreshInvoice(this.invoiceId);
			this.closeModal(true);
		});
	}
}
