import { Directive, HostListener } from '@angular/core';
import { SelectingEventArgs } from '@syncfusion/ej2-navigations';

@Directive({
	/* eslint-disable-next-line @angular-eslint/directive-selector */
	selector: 'ejs-tab:not([pmsOverrideTabDefaults])',
})
export class TabDefaultsDirective {

	constructor() {
	}

	@HostListener('selecting', ['$event'])
	onSelecting(event: SelectingEventArgs) {
		if (event.isSwiped) {
			event.cancel = true;
		}
	}
}
