import { AbstractControl } from '@angular/forms';
import { _isNil } from '@core/lodash/lodash';

/**
 * Test that an array has a minimum length
 * @return null if tests past {errorProperty: true} if test fails
 */
export function arrayLengthValidator(
	arrayControlName: string,
	minLength: number,
	errorProperty: string,
	message: string,
) {
	return (formGroup: AbstractControl) => {
		const arrayControl = formGroup.get(arrayControlName);

		if (_isNil(arrayControl.value) || arrayControl.value.length < minLength) {
			const errorObject = {};
			errorObject[errorProperty] = true;
			arrayControl.setErrors(Object.assign(arrayControl.errors || {}, errorObject));
			const returnObject = {};
			returnObject[errorProperty] = {arrayLength: true, message};
			return returnObject;
		} else {
			let arrayControlErrors = arrayControl.errors;

			let groupErrors = formGroup.errors;

			if (arrayControlErrors !== null) {
				delete (arrayControlErrors[errorProperty]);
				if (Object.keys(arrayControlErrors).length === 0) {
					arrayControlErrors = null;
				}
			}

			if (groupErrors !== null && groupErrors !== undefined) {
				delete (groupErrors[errorProperty]);
				if (Object.keys(groupErrors).length === 0) {
					groupErrors = null;
				}
			}

			arrayControl.setErrors(arrayControlErrors);
			return groupErrors;
		}
	};
}
