import { FormMeta } from '@app-store/reducers/forms.reducer';
import { Action } from '@ngrx/store';
import { FormMode } from '@shared/constants/form-mode.enum';

export enum FormsActionTypes {
	UPDATE_FORM_META = '[Update Form Meta] Updates the form meta property of the form state',
	UPDATE_FORM_MODE = '[Update Form Mode] Updates the form mode property of the form state.',
	UPDATE_FORM_VALUE = '[Update Form Value] Updates the form value property of the form state.',
	UPDATE_FORM_SUBMITTED = '[Update Form Submitted] Updates the form submitted property of the form state.',
	REMOVE_FORM_STATE = '[Remove Form State] Removes the form state from the store.',
}

export class UpdateFormMeta implements Action {
	readonly type = FormsActionTypes.UPDATE_FORM_META;
	constructor(public payload: {key: string, meta: FormMeta}) {}
}

export class UpdateFormMode implements Action {
	readonly type = FormsActionTypes.UPDATE_FORM_MODE;
	constructor(public payload: {key: string, mode: FormMode}) {}
}

export class UpdateFormValue implements Action {
	readonly type = FormsActionTypes.UPDATE_FORM_VALUE;
	constructor(public payload: {key: string, value: any}) {}
}

export class UpdateFormSubmitted implements Action {
	readonly type = FormsActionTypes.UPDATE_FORM_SUBMITTED;
	constructor(public payload: {key: string, submitted: boolean}) {}
}

export class RemoveFormState implements Action {
	readonly type = FormsActionTypes.REMOVE_FORM_STATE;
	constructor(public payload: {key: string}) {}
}

export type FormsActions =
	UpdateFormMeta
	| UpdateFormMode
	| UpdateFormValue
	| UpdateFormSubmitted
	| RemoveFormState;
