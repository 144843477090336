// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { TransferItemResponse } from './transfer-item-response';

/** See com.rev360.pms.api.controller.accounting.payment.PaymentTransferInvoiceItemsResponse */
/* istanbul ignore next */
@GandalfModel
export class PaymentTransferInvoiceItemsResponse extends GandalfModelBase {

	@GandalfProperty()
	includeAllItems: boolean;

	@GandalfProperty()
	payerEntityId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	payerType: constants.PayerType;

	@GandalfProperty()
	personInsuranceId: number;

	@GandalfProperty({ propertyType: 'Money' })
	transferAmount: number;

	@GandalfArray(TransferItemResponse)
	transferItems: TransferItemResponse[];

	@GandalfProperty()
	transferReferenceId: number;

	@GandalfProperty()
	transferToExistingInvoiceId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	transferType: constants.TransferType;

	@GandalfProperty()
	writeoffReferenceId: number;

}
