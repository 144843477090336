import { Injectable } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/* istanbul ignore file */
@Injectable({
	providedIn: 'root',
})
export class GandalfTheGreyService {

	constructor(
		private securityManager: SecurityManagerService,
	) {
	}

	/**
	 * Should be called for any dates being passed in a legacy request before using the execute method
	 * @param date The original date on the rev360 request
	 */
	static createLegacyRequestDate(date: Date): Date {
		if (!_isNil(date)) {
			date.setHours(12, 0, 0, 0);
			return date;
		}
	}

	/* istanbul ignore next */
	createRequestInstance(serviceName: string, endpointName: string): any {
		return this.securityManager.serviceFactory().getService(serviceName)[endpointName].createRequestInstance();
	}

	/* istanbul ignore next */
	execute(serviceName: string, endpointName, argument: object): Observable<any> {
		return from(
			this.securityManager.serviceFactory().getService(serviceName)[endpointName](argument),
		).pipe(map((responses: any) => responses.data));
	}

}
