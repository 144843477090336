import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { PersonInsuranceTableResponse } from '@gandalf/model/person-insurance-table-response';
import { TABLE_DATE_FORMATS } from '@shared/constants/date-format.constants';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { RowSelectEventArgs, SortSettingsModel } from '@syncfusion/ej2-grids';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountingService } from '../../core/accounting/accounting.service';

@Component({
	selector: 'pms-insurance-select-modal',
	templateUrl: './insurance-select-modal.component.html',
	styles: [],
})
export class InsuranceSelectModalComponent implements OnInit, OnDestroy {

	@ViewChild('modal')
	modal: DialogComponent;
	@ViewChild('templateForm')
	templateForm: NgForm;
	isSearchingOrSubmitting = false;
	patientId: number;
	insuranceList: PersonInsuranceTableResponse[];
	tableDateFormat = TABLE_DATE_FORMATS.MM_DD_YYYY;
	unsubscribe$ = new Subject<void>();
	componentForm: UntypedFormGroup;

	sortOptions: SortSettingsModel = {
		columns: [
			{field: 'status.label', direction: 'Ascending'},
			{field: 'companyName', direction: 'Ascending'},
		],
	};

	constructor(
		public dynamicModalRef: DynamicModalRef,
		private modalConfig: ModalConfig,
		private accountingService: AccountingService,
	) {
	}

	ngOnInit() {
		this.patientId = this.modalConfig.data.patientId;
		this.getInsuranceList();
	}

	getInsuranceList() {
		this.isSearchingOrSubmitting = true;
		this.accountingService.findInsuranceByPatientId(this.patientId).pipe(takeUntil(this.unsubscribe$)).subscribe((insurance) => {
			this.insuranceList = insurance;
			this.isSearchingOrSubmitting = false;
		});
	}

	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	closeModal(selectEvent?: RowSelectEventArgs) {
		const returnObject = selectEvent ? { insurance: selectEvent.data } : null;

		this.dynamicModalRef.close(this.modal, returnObject);
	}

}
