import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GandalfModule } from 'gandalf';
import { AgGridContainerComponent } from './components/ag-grid-container/ag-grid-container.component';
import { AgGridPaginationComponent } from './components/ag-grid-pagination/ag-grid-pagination.component';
import { ConditionalHyperlinkCellRendererComponent } from './components/conditional-hyperlink-cell-renderer/conditional-hyperlink-cell-renderer.component';
import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';
import { DynamicModalComponent } from './components/modal/dynamic-modal.component';
import { PodComponent } from './components/pod/pod.component';
import { StatusSelectButtonComponent } from './components/status-button-group/status-select-button.component';
import { TableActionMenuComponent } from './components/table-action-menu/table-action-menu.component';
import { TemplateCellRendererComponent } from './components/template-cell-renderer/template-cell-renderer.component';
import { DropdownlistBaseDirective } from './directives/dropdowns/dropdownlist-base.directive';
import { DropdownlistClearDirective } from './directives/dropdowns/dropdownlist-clear.directive';
import { DropdownlistFilteringDirective } from './directives/dropdowns/dropdownlist-filtering.directive';
import { AgGridNoFilteredRowsDirective } from './directives/grid/ag-grid-no-filtered-rows.directive';
import { DynamicModalContentDirective } from './directives/modal/dynamic-modal-content.directive';
import { ModalBaseDirective } from './directives/modal/modal-base.directive';
import { MorganaAgGridModule } from './modules/ag-grid/morgana-ag-grid.module';
import { MorganaCoreModule } from './modules/core/morgana-core.module';
import { MorganaSyncfusionModule } from './modules/syncfusion/morgana-syncfusion.module';
import { TypeSafeDynamicModalComponent } from './components/modal/type-safe-dynamic-modal.component';
import { DropdownlistHideInactiveUnlessSelectedDirective } from './directives/dropdowns/dropdownlist-hide-inactive-unless-selected.directive';

@NgModule({
	declarations: [
		AgGridContainerComponent,
		AgGridNoFilteredRowsDirective,
		AgGridPaginationComponent,
		DynamicModalComponent,
		TypeSafeDynamicModalComponent,
		StatusSelectButtonComponent,
		TableActionMenuComponent,
		TemplateCellRendererComponent,
		ModalBaseDirective,
		DynamicModalContentDirective,
		ConditionalHyperlinkCellRendererComponent,
		DropdownlistBaseDirective,
		DropdownlistClearDirective,
		DropdownlistFilteringDirective,
		LoadingOverlayComponent,
		PodComponent,
		DropdownlistHideInactiveUnlessSelectedDirective,
	],
	imports: [
		MorganaAgGridModule,
		MorganaCoreModule,
		MorganaSyncfusionModule,
		GandalfModule,
		FormsModule,
		ReactiveFormsModule,
	],
	exports: [
		MorganaAgGridModule,
		MorganaCoreModule,
		MorganaSyncfusionModule,
		GandalfModule,
		FormsModule,
		ReactiveFormsModule,
		AgGridNoFilteredRowsDirective,
		AgGridContainerComponent,
		AgGridPaginationComponent,
		DynamicModalComponent,
		TypeSafeDynamicModalComponent,
		StatusSelectButtonComponent,
		TableActionMenuComponent,
		TemplateCellRendererComponent,
		ModalBaseDirective,
		DynamicModalContentDirective,
		ConditionalHyperlinkCellRendererComponent,
		DropdownlistBaseDirective,
		DropdownlistClearDirective,
		DropdownlistFilteringDirective,
		LoadingOverlayComponent,
		PodComponent,
		DropdownlistHideInactiveUnlessSelectedDirective,
	],
	providers: [],
})
export class MorganaModule {
}
