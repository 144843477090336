import { Injectable, isDevMode } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { HIT_PMS_HTML_EVENTS, LOAD_MODULE_WITH_ACTION, LOAD_ROUTE } from '@core/legacy/hit-pms-html.constants';
import { RouterUtilsService } from '@core/router-utils/router-utils.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class UrlParamService {

	constructor(
		private router: Router,
		private eventsManagerService: EventsManagerService,
		private securityManagerService: SecurityManagerService,
		private routerUtilsService: RouterUtilsService,
	) {
	}

	/* istanbul ignore next */
	subscribeForUrlParams() {
		if (!isDevMode()) {
			return;
		}

		this.routerUtilsService.observeNavigationEvents(NavigationStart, false)
			.pipe(take(2))
			.subscribe((event: NavigationStart) => {
				this.parseUrlParams(event.url);
			});
	}

	parseUrlParams(url: string) {
		const paramMap = this.router.parseUrl(url).queryParamMap;

		this.transferUrlParamToWindow(paramMap, 'username', 'devUsername');
		this.transferUrlParamToWindow(paramMap, 'password', 'devPassword');
		const devEvent = this.transferUrlParamToWindow(paramMap, 'event', 'devEvent');
		const devArgument = this.transferUrlParamToWindow(paramMap, 'argument', 'devArgument');
		const devModule = this.transferUrlParamToWindow(paramMap, 'module', 'devModule');
		const devRoute = this.transferUrlParamToWindow(paramMap, 'route', 'devRoute');

		this.publishEvents(devRoute, devModule, devEvent, devArgument);
	}

	transferUrlParamToWindow(paramMap, paramName, windowName): string {
		const paramValue = paramMap.get(paramName);
		if (paramValue) {
			this.setWindowValue(windowName, paramValue);
		}
		return paramValue;
	}

	/* istanbul ignore next */
	setWindowValue(windowName: string, value: string) {
		window[windowName] = value;
	}

	publishEvents(route, module, event, argument) {
		if (!this.securityManagerService.checkLogin()) {
			this.eventsManagerService.publish(HIT_PMS_HTML_EVENTS.REVMONKEY.LOGIN);
		} else if (route) {
			this.eventsManagerService.publish(LOAD_ROUTE, {route});
		} else if (module && event && argument) {
			this.eventsManagerService.publish(LOAD_MODULE_WITH_ACTION, {
				module,
				action: event,
				payload: JSON.parse(argument),
			});
		}
	}
}
