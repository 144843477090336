// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ProviderInsuranceIdentifierResponse } from './provider-insurance-identifier-response';

/** See com.rev360.pms.api.controller.admin.employee.detailed.response.AdminEmployeeProviderDetailsResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminEmployeeProviderDetailsResponse extends GandalfModelBase {

	@GandalfProperty()
	apiKey: string;

	@GandalfProperty()
	deaNumber: string;

	@GandalfProperty()
	displayColor: number;

	@GandalfProperty()
	einNumber: string;

	@GandalfProperty()
	employeeIsProvider: boolean;

	@GandalfProperty()
	externalPhotoId: number;

	@GandalfArray(ProviderInsuranceIdentifierResponse)
	insuranceIdentifiers: ProviderInsuranceIdentifierResponse[];

	@GandalfProperty()
	licenseNumber: string;

	@GandalfProperty()
	npi: string;

	@GandalfProperty()
	portalDescription: string;

	@GandalfProperty()
	practitionerNum: string;

	@GandalfProperty()
	providerId: number;

	@GandalfProperty()
	providerTypeId: number;

	@GandalfProperty()
	registrationNum: string;

	@GandalfProperty()
	showPublic: boolean;

	@GandalfProperty()
	signatureId: number;

	@GandalfProperty()
	upin: string;

}
