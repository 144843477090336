import { _get } from '@core/lodash/lodash';
import { RouteBuilderService } from '@core/router-utils/route-builder.service';
import { PayerType } from '@gandalf/constants';
import { PaymentTabActions, PaymentTabActionTypes } from '@payments-store/actions/payment-tab.actions';
import { FormState } from '@shared/decorators/legacy-stateful-component.decorator';
import { StatefulTabItem } from '@shared/directives/stateful-tab/stateful-tab-item';
import { StatefulTabType } from '@shared/directives/stateful-tab/stateful-tab-type';
import { InvoicePayment, ReceivePaymentsPayer } from '@accounting/invoices/receive-payments/receive-payments.service';

export type PaymentTabStateMap = Map<number, PaymentTabState>;

export const initialState: PaymentTabStateMap = new Map();

export class ReceivePaymentState {
	formState: FormState;
	bulkWriteOffReasons: any[];
	invoicePayments: InvoicePayment[];
	topDisplayedRowIndex: number;
}

export interface PaymentTabStateOptions {
	nameFilter?: string;
	manuallySelect?: boolean;
	selectedPayer?: ReceivePaymentsPayer;
	receivePaymentState?: ReceivePaymentState;
}

export class PaymentTabState extends StatefulTabItem {
	payerType: PayerType;
	locationId: number;
	manuallySelect: boolean;
	nameFilter: string;
	selectedPayer: ReceivePaymentsPayer;
	receivePaymentState: ReceivePaymentState;

	constructor(
		paymentId: number,
		payerType: PayerType,
		locationId: number,
		options?: PaymentTabStateOptions,
	) {
		const defaultRoute = RouteBuilderService.getPaymentRoute(paymentId);
		const headerText = paymentId > 0 ? `#${paymentId}` : `New payment`;
		super(defaultRoute, StatefulTabType.PAYMENTS_PAYMENT_TAB, {headerText});
		this.payerType = payerType;
		this.locationId = locationId;
		this.manuallySelect = _get(options, ['manuallySelect'], false);
		this.nameFilter = _get(options, ['nameFilter'], '');
		this.selectedPayer = _get(options, ['selectedPayer']);
		this.receivePaymentState = _get(options, ['receivePaymentState']);
	}
}

export function reducer(state = initialState, action: PaymentTabActions): PaymentTabStateMap {
	switch (action.type) {

		case PaymentTabActionTypes.ADD_PAYMENT_TAB: {
			const paymentTabStateMap = new Map(state);
			if (!paymentTabStateMap.get(action.payload.paymentId)) {
				paymentTabStateMap.set(action.payload.paymentId,  new PaymentTabState(
					action.payload.paymentId,
					action.payload.payerType,
					action.payload.locationId,
				));
				return paymentTabStateMap;
			} else {
				return state;
			}
		}
		case PaymentTabActionTypes.UPDATE_PAYMENT_TAB_PAYER_SELECTION_STATE: {
			const paymentTabStateMap = new Map(state);
			const currentTabState = paymentTabStateMap.get(action.payload.paymentId);
			if (currentTabState) {
				paymentTabStateMap.set(action.payload.paymentId, {...currentTabState,
					payerType: action.payload.payerType,
					nameFilter: action.payload.nameFilter,
					manuallySelect: action.payload.manuallySelect,
					selectedPayer: action.payload.payer,
					receivePaymentState: action.payload.receivePaymentState,
				});
				return paymentTabStateMap;
			}
			return state;
		}

		case PaymentTabActionTypes.REMOVE_PAYMENT_TAB: {
			const paymentTabStateMap = new Map(state);
			paymentTabStateMap.delete(action.payload.paymentId);
			return paymentTabStateMap;
		}

		case PaymentTabActionTypes.UPDATE_PAYMENT_TAB_CURRENT_ROUTE: {
			const paymentTabStateMap = new Map(state);
			const paymentTabState = paymentTabStateMap.get(action.payload.paymentId);
			paymentTabState.currentRoute = action.payload.currentRoute;
			paymentTabStateMap.set(action.payload.paymentId, paymentTabState);
			return paymentTabStateMap;
		}

		default: {
			return state;
		}
	}
}
