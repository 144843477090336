import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { _isNil } from '@core/lodash/lodash';

export interface EventsManagerSubscription {
	eventName: string;
	callback: any;
}

export interface EventManagerEvent {
	eventName: string;
	arg?: any;
}

@Injectable({
	providedIn: 'root',
})
export class EventsManagerService {

	private eventSubject = new Subject<EventManagerEvent>();
	private subscribedEventNames = [];

	constructor(
		private router: Router,
	) { }

	get subscriptions() {
		return window.eventsManager.subscriptions;
	}

	publish<T>(eventName: string, argument?: T) {
		window.eventsManager.publish(eventName, argument);
	}

	async hideContainerAndPublish<T>(eventName: string, argument?: T) {
		await this.router.navigateByUrl('/legacy');
		this.publish(eventName, argument);
	}

	subscribe(eventName: string, callback: (arg?: any) => void, context?: any) {
		window.eventsManager.subscribe(eventName, callback, context);
		return {
			eventName,
			callback,
		};
	}

	subscribeObservable(eventName: string): Observable<EventManagerEvent> {
		if (!this.subscribedEventNames.includes(eventName)) {
			const callback: (arg?: any) => void = (arg) => this.eventSubject.next({eventName, arg});
			window.eventsManager.subscribe(eventName, callback, null);
			this.subscribedEventNames.push(eventName);
		}
		return this.eventSubject.pipe(filter(event => event.eventName === eventName));
	}

	unsubscribe(eventName: string, callback: (any)) {
		window.eventsManager.unsubscribe(eventName, callback);
	}

	unsubscribeEvent(event: EventsManagerSubscription) {
		if (event && event.eventName && event.callback) {
			this.unsubscribe(event.eventName, event.callback);
		}
	}

	subscribePatientObservable(eventName: string, patientId: number): Observable<EventManagerEvent> {
		return this.subscribeObservable(eventName).pipe(
			filter(event => _isNil(event?.arg?.argument?.patientId) || event?.arg?.argument?.patientId === patientId),
		);
	}
}
