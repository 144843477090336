// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { DurationResponse } from './duration-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.patient.opticalsummary.OpticalSummaryLatestFindingsContactLensResponse */
/* istanbul ignore next */
@GandalfModel
export class OpticalSummaryLatestFindingsContactLensResponse extends GandalfModelBase {

	@GandalfConstantDecorator()
	@GandalfProperty()
	authorizationType: constants.PrescriptionAuthorizationType;

	@GandalfProperty()
	authorizedPrescriber: PersonNameResponse;

	@GandalfProperty()
	comments: string;

	@GandalfProperty()
	contactLensPrescriptionId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	contactLensPrescriptionStatus: constants.PrescriptionCurrentStatus;

	@GandalfProperty()
	dropsName: string;

	@GandalfProperty()
	enzymaticCleanerName: string;

	@GandalfProperty()
	lensCleanerName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	modality: constants.ContactLensModality;

	@GandalfProperty()
	odAddDesignation: string;

	@GandalfProperty()
	odAddPower: number;

	@GandalfProperty()
	odAxis: number;

	@GandalfProperty()
	odBaseCurve: number;

	@GandalfProperty()
	odCylinder: number;

	@GandalfProperty()
	odDiameter: number;

	@GandalfProperty()
	odIsMonovision: boolean;

	@GandalfProperty()
	odLocationProductName: string;

	@GandalfProperty()
	odOvernightSchedule: DurationResponse;

	@GandalfProperty()
	odReplacementSchedule: DurationResponse;

	@GandalfProperty()
	odSphere: number;

	@GandalfProperty()
	osAddDesignation: string;

	@GandalfProperty()
	osAddPower: number;

	@GandalfProperty()
	osAxis: number;

	@GandalfProperty()
	osBaseCurve: number;

	@GandalfProperty()
	osCylinder: number;

	@GandalfProperty()
	osDiameter: number;

	@GandalfProperty()
	osIsMonovision: boolean;

	@GandalfProperty()
	osLocationProductName: string;

	@GandalfProperty()
	osOvernightSchedule: DurationResponse;

	@GandalfProperty()
	osReplacementSchedule: DurationResponse;

	@GandalfProperty()
	osSphere: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	overallLensType: constants.ContactLensPrescriptionType;

	@GandalfProperty({ propertyType: 'LocalDate' })
	prescriptionExpireDate: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	prescriptionStartDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	prescriptionStatus: constants.PrescriptionStatus;

	@GandalfProperty()
	solutionName: string;

}
