<div class="table-responsive margin-bottom-md">
	<table class="table table-hover table-nowrap table-bordered text-right margin-0" [attr.data-test-id]="'contactLensTypeRgpTableOne'">
		<thead>
		<tr>
			<th></th>
			<th>MV</th>
			<th class="text-right">BC</th>
			<th class="text-right">BC2</th>
			<th class="text-right">Sph</th>
			<th class="text-right">Sph2</th>
			<th class="text-right">Cyl</th>
			<th class="text-right">Axis</th>
			<th class="text-right">Diam</th>
			<th class="text-right">Opt Zone</th>
			<th class="text-right">Center Thick</th>
			<th class="text-right">Add</th>
			<th class="text-right">Add Diam</th>
		</tr>
		</thead>
		<tbody>
		<tr>
			<td class="nostretch text-left">
				<span class="label label-success">OD</span><br>
				<span class="label label-info">OS</span>
			</td>
			<td class="text-left">
				{{ contactLensPrescription.contactLensEyeRgpOd?.isMonovision | yesNo : 'Yes' : '' }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.isMonovision | yesNo : 'Yes' : '' }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.baseCurve | baseCurve : contactLensPrescription.contactLensEyeRgpOd?.baseCurveUnit }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.baseCurve | baseCurve : contactLensPrescription.contactLensEyeRgpOs?.baseCurveUnit }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.baseCurve2 | baseCurve : contactLensPrescription.contactLensEyeRgpOd?.baseCurveUnit }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.baseCurve2 | baseCurve : contactLensPrescription.contactLensEyeRgpOs?.baseCurveUnit }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.sphere | plusMinusNoRounding }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.sphere | plusMinusNoRounding }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.sphere2 | plusMinusNoRounding }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.sphere2 | plusMinusNoRounding }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.cylinder | plusMinusNoRounding }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.cylinder | plusMinusNoRounding }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.axis | padAxis }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.axis | padAxis }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.diameter | forceDecimalNullSafe : 1 }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.diameter | forceDecimalNullSafe : 1 }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.opticZone }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.opticZone }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.centerThickness }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.centerThickness }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.addPower | plusMinus }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.addPower | plusMinus }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.addDiameter | forceDecimalNullSafe : 2 }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.addDiameter | forceDecimalNullSafe : 2 }}
			</td>
		</tr>
		</tbody>
	</table>
</div>
<div class="table-responsive margin-bottom-md">
	<table class="table table-hover table-nowrap table-bordered text-right margin-0" [attr.data-test-id]="'contactLensTypeRgpTableTwo'">
		<thead>
		<tr>
			<th></th>
			<th class="text-right">Seg Ht</th>
			<th class="text-right">Int Seg</th>
			<th class="text-right">Truncation</th>
			<th class="text-right">Dist Zone</th>
			<th class="text-right">Back Opt Diam</th>
			<th>Color</th>
			<th>Edge Lift</th>
			<th>Special Chars</th>
			<th>DOT</th>
		</tr>
		</thead>
		<tbody>
		<tr>
			<td class="nostretch text-left">
				<span class="label label-success">OD</span><br>
				<span class="label label-info">OS</span>
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.segmentHeight | forceDecimalNullSafe : 1 }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.segmentHeight | forceDecimalNullSafe : 1 }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.intermediateSegment | forceDecimalNullSafe : 1 }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.intermediateSegment | forceDecimalNullSafe : 1 }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.truncation | forceDecimalNullSafe : 1 }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.truncation | forceDecimalNullSafe : 1 }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.distanceZone | forceDecimalNullSafe : 1 }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.distanceZone | forceDecimalNullSafe : 1 }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.backOpticalDiameter | forceDecimalNullSafe : 1 }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.backOpticalDiameter | forceDecimalNullSafe : 1 }}
			</td>
			<td class="text-left">
				{{ contactLensPrescription.contactLensEyeRgpOd?.tint }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.tint }}
			</td>
			<td class="text-left">
				{{ contactLensPrescription.contactLensEyeRgpOd?.edgeLift?.value }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.edgeLift?.value }}
			</td>
			<td class="text-left">
				{{ contactLensPrescription.contactLensEyeRgpOd?.specialCharacteristics?.value }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.specialCharacteristics?.value }}
			</td>
			<td class="text-left">
				{{ contactLensPrescription.contactLensEyeRgpOd?.dot | yesNo : 'Yes' : ''  }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.dot | yesNo : 'Yes' : ''  }}
			</td>
		</tr>
		</tbody>
	</table>
</div>
<div class="table-responsive margin-bottom-md">
	<table class="table table-hover table-nowrap table-bordered text-right margin-0" [attr.data-test-id]="'contactLensTypeRgpTableThree'">
		<thead>
		<tr>
			<th></th>
			<th class="text-right">Sec Rad</th>
			<th class="text-right">Sec Wid</th>
			<th class="text-right">Per Rad</th>
			<th class="text-right">Per Wid</th>
			<th class="text-right">1st Rad</th>
			<th class="text-right">1st Wid</th>
			<th class="text-right">2nd Rad</th>
			<th class="text-right">2nd Wid</th>
			<th class="text-right">3rd Rad</th>
			<th class="text-right">3rd Wid</th>
			<th class="text-right">4th Rad</th>
			<th class="text-right">4th Wid</th>
			<th class="text-right">Edge Lift Amt</th>
			<th>Fenstr</th>
			<th>Plasma Trt</th>
		</tr>
		</thead>
		<tbody>
		<tr>
			<td class="nostretch text-left">
				<span class="label label-success">OD</span><br>
				<span class="label label-info">OS</span>
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.secondaryCurveRadius | forceDecimalNullSafe : 2 }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.secondaryCurveRadius | forceDecimalNullSafe : 2 }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.secondaryCurveWidth | forceDecimalNullSafe : 2 }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.secondaryCurveWidth | forceDecimalNullSafe : 2 }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.peripheralCurveRadius | forceDecimalNullSafe : 2 }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.peripheralCurveRadius | forceDecimalNullSafe : 2 }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.peripheralCurveWidth | forceDecimalNullSafe : 2 }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.peripheralCurveWidth | forceDecimalNullSafe : 2 }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.firstCurveRadius | forceDecimalNullSafe : 2 }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.firstCurveRadius | forceDecimalNullSafe : 2 }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.firstCurveWidth | forceDecimalNullSafe : 2 }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.firstCurveWidth | forceDecimalNullSafe : 2 }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.secondCurveRadius | forceDecimalNullSafe : 2 }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.secondCurveRadius | forceDecimalNullSafe : 2 }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.secondCurveWidth | forceDecimalNullSafe : 2 }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.secondCurveWidth | forceDecimalNullSafe : 2 }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.thirdCurveRadius | forceDecimalNullSafe : 2 }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.thirdCurveRadius | forceDecimalNullSafe : 2 }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.thirdCurveWidth | forceDecimalNullSafe : 2 }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.thirdCurveWidth | forceDecimalNullSafe : 2 }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.fourthCurveRadius | forceDecimalNullSafe : 2 }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.fourthCurveRadius | forceDecimalNullSafe : 2 }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.fourthCurveWidth | forceDecimalNullSafe : 2 }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.fourthCurveWidth | forceDecimalNullSafe : 2 }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.edgeLiftAmount | plusMinus }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.edgeLiftAmount | plusMinus }}
			</td>
			<td class="text-left">
				{{ contactLensPrescription.contactLensEyeRgpOd?.fenestration | yesNo : 'Yes' : ''   }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.fenestration | yesNo : 'Yes' : ''   }}
			</td>
			<td class="text-left">
				{{ contactLensPrescription.contactLensEyeRgpOd?.plasmaTreatment | yesNo : 'Yes' : ''   }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.plasmaTreatment | yesNo : 'Yes' : ''   }}
			</td>
		</tr>
		</tbody>
	</table>
</div>
<div class="table-responsive margin-bottom-md">
	<table class="table table-hover table-nowrap table-bordered text-right margin-0" [attr.data-test-id]="'contactLensTypeRgpTableFour'">
		<thead>
		<tr>
			<th></th>
			<th class="text-right">Sag Depth</th>
			<th class="text-right">Profile</th>
			<th>Per Edge</th>
			<th class="text-right">RZD</th>
			<th class="text-right">LZA</th>
			<th>Mid-Per Limbal Clr</th>
			<th>Notes</th>
		</tr>
		</thead>
		<tbody>
		<tr>
			<td class="nostretch text-left">
				<span class="label label-success">OD</span><br>
				<span class="label label-info">OS</span>
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.sagittalDepth | forceDecimalNullSafe : 2 }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.sagittalDepth | forceDecimalNullSafe : 2 }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.profile?.value }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.profile?.value }}
			</td>
			<td class="text-left">
				{{ contactLensPrescription.contactLensEyeRgpOd?.peripheralEdge?.value }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.peripheralEdge?.value }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.returnZoneDiameter }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.returnZoneDiameter }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeRgpOd?.landingZoneAngle }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.landingZoneAngle }}
			</td>
			<td class="text-left">
				{{ contactLensPrescription.contactLensEyeRgpOd?.limbalClearance?.value }}<br>
				{{ contactLensPrescription.contactLensEyeRgpOs?.limbalClearance?.value }}
			</td>
			<td class="text-left">
				<div class="truncate"><span>{{ contactLensPrescription.contactLensEyeRgpOd?.notes }}</span></div>
				<div class="truncate"><span>{{ contactLensPrescription.contactLensEyeRgpOs?.notes }}</span></div>
			</td>
		</tr>
		</tbody>
	</table>
</div>
