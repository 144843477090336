// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressOptionalRequest } from './address-optional-request';

// @ts-ignore
import { AdminLocationPhoneRequest } from './admin-location-phone-request';

// @ts-ignore
import { AdminPracticeLocationDetailsRequest } from './admin-practice-location-details-request';

/** See com.rev360.pms.api.controller.admin.general.location.AdminCreatePracticeLocationDetailsRequest */
/* istanbul ignore next */
@GandalfModel
export class AdminCreatePracticeLocationDetailsRequest extends AdminPracticeLocationDetailsRequest {

	@GandalfProperty()
	address: AddressOptionalRequest;

	@GandalfLabel('Barcode Label Location #')
	@GandalfValidator({ sizeString: { message: 'Barcode Label Location # cannot be longer than 2 characters', minLength: 0, maxLength: 2 } })
	@GandalfProperty()
	barcodeIdentifier: string;

	@GandalfLabel('Claims Billing Location')
	@GandalfProperty()
	billingLocationClaimsId: number;

	@GandalfLabel('Statements Billing Location')
	@GandalfProperty()
	billingLocationStatementsId: number;

	@GandalfLabel('Billing Provider Name')
	@GandalfValidator({ sizeString: { message: 'Billing Provider Name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	billingProviderName: string;

	@GandalfConstantDecorator()
	@GandalfLabel('Claim NPI Entity')
	@GandalfProperty()
	claimNpiEntity: constants.ClaimNpiEntity;

	@GandalfLabel('Co-Insurance Tax')
	@GandalfProperty()
	coInsuranceTaxId: number;

	@GandalfLabel('Co-Pay Tax')
	@GandalfProperty()
	coPayTaxId: number;

	@GandalfLabel('Deductible Tax')
	@GandalfProperty()
	deductibleTaxId: number;

	@GandalfLabel('Default Finance Charge Plan')
	@GandalfProperty()
	defaultFinanceChargePlanId: number;

	@GandalfLabel('Email')
	@GandalfValidator({ sizeString: { message: 'Email cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfValidator({ email: { message: 'Invalid email format' } })
	@GandalfProperty()
	email: string;

	@GandalfLabel('Facility Number')
	@GandalfValidator({ sizeString: { message: 'Facility Number cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	facilityNumber: string;

	@GandalfConstantDecorator()
	@GandalfLabel('Facility Type')
	@GandalfProperty()
	facilityType: constants.FacilityType;

	@GandalfLabel('FEIN')
	@GandalfValidator({ sizeString: { message: 'FEIN cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	fein: string;

	@GandalfLabel('Logo')
	@GandalfProperty()
	logoPracticeFileId: number;

	@GandalfLabel('Location Name')
	@GandalfValidator({ notNull: { message: 'Location Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Location Name must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ isRequired: true })
	name: string;

	@GandalfLabel('NPI')
	@GandalfValidator({ sizeString: { message: 'NPI must be between 10 and 15 characters', minLength: 10, maxLength: 15 } })
	@GandalfProperty()
	npi: string;

	@GandalfSubRequestLabel('Pay-To Address')
	@GandalfProperty()
	payToAddress: AddressOptionalRequest;

	@GandalfValidator({ notNull: { message: 'Phones are required' } })
	@GandalfProperty({ isRequired: true })
	phones: AdminLocationPhoneRequest;

	@GandalfLabel('Print Name As')
	@GandalfValidator({ sizeString: { message: 'Print Name As cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	printNameAs: string;

	@GandalfConstantDecorator()
	@GandalfLabel('Provider Billing ID')
	@GandalfProperty()
	providerBillingId: constants.BillingId;

	@GandalfConstantDecorator()
	@GandalfLabel('Service Location Indicator')
	@GandalfProperty()
	serviceLocationIndicator: constants.ServiceLocationIndicator;

	@GandalfLabel('Location #')
	@GandalfValidator({ sizeString: { message: 'Location # cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	storeNumber: string;

	@GandalfLabel('Use Current Location For Claims')
	@GandalfValidator({ notNull: { message: 'Use Current Location For Claims is required' } })
	@GandalfProperty({ isRequired: true })
	useCurrentLocationForClaims: boolean;

	@GandalfLabel('Use Current Location For Statements')
	@GandalfValidator({ notNull: { message: 'Use Current Location For Statements is required' } })
	@GandalfProperty({ isRequired: true })
	useCurrentLocationForStatements: boolean;

	@GandalfLabel('Web Site')
	@GandalfValidator({ sizeString: { message: 'Web Site cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	websiteUrl: string;

}
