import { HttpErrorResponse } from '@angular/common/http';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { HIT_PMS_HTML_EVENTS } from '@core/legacy/hit-pms-html.constants';
import { MODAL } from 'morgana';

interface ErrorMessage {
	message?: string;
	useServerMessage?: boolean;
	action?: () => void;
}

interface ErrorMap {
	[key: number]: ErrorMessage;
}

const HttpStatusCodes = {
	HTTP_FORBIDDEN: 403,
	VALIDATION_EXCEPTION: 406,
	OPTIMISTIC_LOCKING: 409,
	UNCAUGHT_EXCEPTION: 500,
};

export class ErrorMappings {

	errorMappings: ErrorMap = {
		[HttpStatusCodes.HTTP_FORBIDDEN]: {
			action: () => {
				this.eventsManager.publish(HIT_PMS_HTML_EVENTS.SECURITY.SECURITY_SESSION_IS_NOT_AUTHORIZED);
			},
		},
		[HttpStatusCodes.VALIDATION_EXCEPTION]: {
			useServerMessage: true,
		},
		[HttpStatusCodes.OPTIMISTIC_LOCKING]: {
			message: 'Please refresh the page',
		},
		[HttpStatusCodes.UNCAUGHT_EXCEPTION]: {
			message: 'An Unknown Error has occurred',
		},
	};

	constructor(
		private eventsManager: EventsManagerService,
	) {
	}

}

export class HttpError {

	title = 'An Error Has Occurred';
	showCloseIcon = true;
	zIndex = MODAL.Z_INDEX_STANDARD;
	cssClass = 'modal-md http-error-dialog';
	isModal = true;
	content;

	/* istanbul ignore next */
	constructor(error: HttpErrorResponse | Error) {
		if (error instanceof Error) {
			this.content = `${error.message}\n${error.stack}`;
		} else {
			this.content = error.message;
		}
	}
}

