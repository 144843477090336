import { AccountingService } from '@accounting/core/accounting/accounting.service';
import { InvoiceService } from '@accounting/core/accounting/invoice-service/invoice.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { ProviderDropdownResponse, ProviderService } from '@core/provider/provider.service';
import { InvoiceResponse } from '@gandalf/model/invoice-response';
import { AdditionalClaimInformationCanadaRequest } from '@gandalf/model/additional-claim-information-canada-request';
import { AdditionalClaimInformationCanadaResponse } from '@gandalf/model/additional-claim-information-canada-response';
import { BaseComponent } from '@shared/component/base.component';
import { GandalfFormBuilder } from 'gandalf';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'pms-additional-claim-information-canada',
	templateUrl: './additional-claim-information-canada.component.html',
	styles: [],
})
export class AdditionalClaimInformationCanadaComponent extends BaseComponent implements OnInit {
	@Input()
	invoiceId: number;

	@ViewChild('templateForm')
	templateForm: NgForm;

	request = new AdditionalClaimInformationCanadaRequest();
	invoice: InvoiceResponse = null;
	formGroup: UntypedFormGroup;
	addlClaimInfo: AdditionalClaimInformationCanadaResponse;
	providerList: ProviderDropdownResponse[];
	isReadOnly: boolean;

	constructor(
		private accountingService: AccountingService,
		private providerService: ProviderService,
		public formBuilder: GandalfFormBuilder,
		private invoiceService: InvoiceService,
	) {
		super();
	}

	ngOnInit() {
		this.listenForInvoiceUpdates();
		this.formGroup = this.formBuilder.group(this.request);
	}

	listenForInvoiceUpdates() {
		this.invoiceService.getInvoiceDetailsInvoiceState(this.invoiceId)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((invoice) => {
				this.invoice = invoice;
				this.getData();
			});
	}

	getData() {
		combineLatest([
			this.accountingService.findAdditionalClaimInformationCanadaByInvoiceId(this.invoice.id),
			this.providerService.findAllActiveProvidersForDropdown(),
		]).subscribe(([addlClaimInfo, allActiveProviders]) => {
			this.addlClaimInfo = addlClaimInfo;
			this.providerList = allActiveProviders;
			this.setFormValues();
			this.setFormState();
		});
	}

	setFormValues() {
		this.formGroup.patchValue(this.addlClaimInfo);
	}

	setFormState() {
		this.isReadOnly = this.invoice.approved;
		if (this.isReadOnly) {
			this.formGroup.disable();
		} else {
			this.formGroup.enable();
		}
	}

	submitForm(event) {
		this.templateForm.onSubmit(event);
	}

	save() {
		if (this.formGroup.invalid) {
			return;
		}

		const request = this.formGroup.value as AdditionalClaimInformationCanadaRequest;
		this.accountingService.updateAdditionalClaimInformationCanada(request).subscribe(data => {
			this.addlClaimInfo = data;
			this.setFormValues();
		});
	}

	cancel() {
		this.setFormValues();
	}
}
