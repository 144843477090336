import { Injectable } from '@angular/core';
import { GandalfTheGreyService } from '@core/gandalf-the-grey/gandalf-the-grey.service';
import { ConnectMessageForPatientAppointmentRequest } from '@gandalf/model/connect-message-for-patient-appointment-request';
import { ConnectMessageForPatientRecallRequest } from '@gandalf/model/connect-message-for-patient-recall-request';

@Injectable({
	providedIn: 'root',
})
export class SendConnectMessageService {

	constructor(
		private gandalfTheGreyService: GandalfTheGreyService,
	) {
	}

	/* istanbul ignore next */
	buildLegacyAppointmentRequest(
		serviceName: string,
		endpointName: string,
		connectMessageForPatientAppointmentRequest: ConnectMessageForPatientAppointmentRequest): any {
		const legacyRequest = this.gandalfTheGreyService.createRequestInstance(serviceName, endpointName);
		legacyRequest.typeId = connectMessageForPatientAppointmentRequest.method.value;
		legacyRequest.patientId = connectMessageForPatientAppointmentRequest.patientId;
		legacyRequest.appointmentId = connectMessageForPatientAppointmentRequest.appointmentId;

		return legacyRequest;
	}

	/* istanbul ignore next */
	buildLegacyRecallRequest(
		serviceName: string,
		endpointName: string,
		connectMessageForPatientRecallRequest: ConnectMessageForPatientRecallRequest): any {
		const legacyRequest = this.gandalfTheGreyService.createRequestInstance(serviceName, endpointName);
		legacyRequest.typeId = connectMessageForPatientRecallRequest.connectMessageMethod.value;
		legacyRequest.patientId = connectMessageForPatientRecallRequest.patientId;
		legacyRequest.recallId = connectMessageForPatientRecallRequest.patientRecallId;

		return legacyRequest;
	}

	sendConnectMessageForPatientAppointment(connectMessageForPatientAppointmentRequest: ConnectMessageForPatientAppointmentRequest) {
		const serviceName = 'Connect';
		const endpointName = 'sendConnectMessageForPatientAppointment';
		const legacyRequest = this.buildLegacyAppointmentRequest(serviceName, endpointName, connectMessageForPatientAppointmentRequest);

		return this.gandalfTheGreyService.execute(serviceName, endpointName, legacyRequest);
	}

	sendConnectMessageForPatientRecall(connectMessageForPatientRecallRequest: ConnectMessageForPatientRecallRequest) {
		const serviceName = 'Connect';
		const endpointName = 'sendConnectMessageForPatientRecall';
		const legacyRequest = this.buildLegacyRecallRequest(serviceName, endpointName, connectMessageForPatientRecallRequest);

		return this.gandalfTheGreyService.execute(serviceName, endpointName, legacyRequest);
	}
}
