import { Injectable } from '@angular/core';
import { SortingService } from 'morgana';
import { CarePlanTemplateListResponse } from '@gandalf/model/care-plan-template-list-response';
import { CarePlanTemplateGandalfService } from '@gandalf/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class CarePlanTemplateService {

	constructor(
		private carePlanTemplateGandalfService: CarePlanTemplateGandalfService,
	) {
	}

	getCarePlanTemplateById(carePlanTemplateId: number): Observable<CarePlanTemplateListResponse> {
		return this.carePlanTemplateGandalfService.getCarePlanTemplateById(carePlanTemplateId).pipe(
			map(carePlanTemplate => this.sortCarePlanTemplateItems(carePlanTemplate)),
		);
	}

	sortCarePlanTemplateItems(carePlanTemplate: CarePlanTemplateListResponse) {
		carePlanTemplate.items = SortingService.sortBy(carePlanTemplate.items, ['description', 'id'], ['asc', 'asc']);
		return carePlanTemplate;
	}
}
