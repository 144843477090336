// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.pupillarydistance.PupillaryDistanceHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class PupillaryDistanceHistoryResponse extends GandalfModelBase {

	@GandalfProperty()
	odMonoDistancePupillaryDistance: number;

	@GandalfProperty()
	odMonoNearPupillaryDistance: number;

	@GandalfProperty()
	osMonoDistancePupillaryDistance: number;

	@GandalfProperty()
	osMonoNearPupillaryDistance: number;

	@GandalfProperty()
	ouDistancePupillaryDistance: number;

	@GandalfProperty()
	ouNearPupillaryDistance: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	source: constants.PupillaryDistanceSource;

	@GandalfProperty()
	sourceDate: Date;

	@GandalfProperty()
	sourceName: string;

}
