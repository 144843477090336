// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AdminEyefinityFeeLensRequest } from './admin-eyefinity-fee-lens-request';

// @ts-ignore
import { AdminEyefinityFeeOptionRequest } from './admin-eyefinity-fee-option-request';

// @ts-ignore
import { AdminEyefinityFeeServiceRequest } from './admin-eyefinity-fee-service-request';

/** See com.rev360.pms.api.controller.admin.accounting.eyefinity.AdminEyefinityFeeScheduleRequest */
/* istanbul ignore next */
@GandalfModel
export class AdminEyefinityFeeScheduleRequest extends GandalfModelBase {

	@GandalfArray(AdminEyefinityFeeLensRequest)
	baseLenses: AdminEyefinityFeeLensRequest[];

	@GandalfLabel('Begin Date')
	@GandalfValidator({ notNull: { message: 'Begin Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	beginDate: Date;

	@GandalfLabel('Comments')
	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	comments: string;

	@GandalfLabel('CL Fitting % Covered')
	@GandalfValidator({ min: { min: 0, message: 'CL Fitting % Covered must be between 0 and 100' } })
	@GandalfValidator({ max: { max: 100, message: 'CL Fitting % Covered must be between 0 and 100' } })
	@GandalfValidator({ notNull: { message: 'CL Fitting % Covered is required' } })
	@GandalfProperty({ isRequired: true })
	contactLensExamPercentage: number;

	@GandalfLabel('End Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate: Date;

	@GandalfLabel('Frame Service Fee')
	@GandalfValidator({ notNull: { message: 'Frame Service Fee is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	frameDispensingFee: number;

	@GandalfArray(AdminEyefinityFeeOptionRequest)
	lensAddons: AdminEyefinityFeeOptionRequest[];

	@GandalfLabel('Name')
	@GandalfValidator({ notNull: { message: 'Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Name must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ isRequired: true })
	name: string;

	@GandalfLabel('Patient Copay')
	@GandalfValidator({ notNull: { message: 'Patient Copay is required' } })
	@GandalfProperty({ isRequired: true })
	patientCopayReasonId: number;

	@GandalfLabel('Payment Transfer')
	@GandalfValidator({ notNull: { message: 'Payment Transfer is required' } })
	@GandalfProperty({ isRequired: true })
	patientTransferReasonId: number;

	@GandalfLabel('Recoupment')
	@GandalfValidator({ notNull: { message: 'Recoupment is required' } })
	@GandalfProperty({ isRequired: true })
	recoupmentReasonId: number;

	@GandalfArray(AdminEyefinityFeeServiceRequest)
	services: AdminEyefinityFeeServiceRequest[];

	@GandalfLabel('Write-Off')
	@GandalfValidator({ notNull: { message: 'Write-Off is required' } })
	@GandalfProperty({ isRequired: true })
	writeoffReasonId: number;

}
