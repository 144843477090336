// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.inventory.MinMaxInventoryReportResponse */
/* istanbul ignore next */
@GandalfModel
export class MinMaxInventoryReportResponse extends GandalfModelBase {

	@GandalfProperty()
	averageAge: number;

	@GandalfProperty()
	averageAgeOfSales: number;

	@GandalfProperty()
	averageAgeOfStock: number;

	@GandalfProperty()
	costOfSales: number;

	@GandalfProperty()
	costOfStock: number;

	@GandalfProperty()
	itemContactAddDesignation: string;

	@GandalfProperty()
	itemContactAddPower: number;

	@GandalfProperty()
	itemContactAxis: number;

	@GandalfProperty()
	itemContactBaseCurve: number;

	@GandalfProperty()
	itemContactCylinder: number;

	@GandalfProperty()
	itemContactDiameter: number;

	@GandalfProperty()
	itemContactIsTrial: boolean;

	@GandalfProperty()
	itemContactLensColor: string;

	@GandalfProperty()
	itemContactLensColorCode: string;

	@GandalfProperty()
	itemContactSphere: number;

	@GandalfProperty()
	itemDescription: string;

	@GandalfProperty()
	itemFrameBridge: string;

	@GandalfProperty()
	itemFrameColor: string;

	@GandalfProperty()
	itemFrameColorCode: string;

	@GandalfProperty()
	itemFrameEye: string;

	@GandalfProperty()
	itemFrameTemple: string;

	@GandalfProperty()
	itemFrameTempleUnit: string;

	@GandalfProperty()
	itemId: number;

	@GandalfProperty()
	itemIsStocked: boolean;

	@GandalfProperty()
	itemMinStockQty: number;

	@GandalfProperty()
	itemPrice: number;

	@GandalfProperty()
	itemQuantityInStock: number;

	@GandalfProperty()
	itemSku: string;

	@GandalfProperty()
	itemUpc: string;

	@GandalfProperty()
	locationProductBrandName: string;

	@GandalfProperty()
	locationProductCategoryName: string;

	@GandalfProperty()
	locationProductCode: string;

	@GandalfProperty()
	locationProductCollectionName: string;

	@GandalfProperty()
	locationProductCost: number;

	@GandalfProperty()
	locationProductId: number;

	@GandalfProperty()
	locationProductManufacturerName: string;

	@GandalfProperty()
	locationProductModel: string;

	@GandalfProperty()
	locationProductPrice: number;

	@GandalfProperty()
	locationProductWholesale: number;

	@GandalfProperty()
	maximumAge: number;

	@GandalfProperty()
	maximumAgeOfStock: number;

	@GandalfProperty()
	minimumAge: number;

	@GandalfProperty()
	minimumAgeOfStock: number;

	@GandalfProperty()
	practiceLocationId: number;

	@GandalfProperty()
	practiceLocationName: string;

	@GandalfProperty()
	retailOfStock: number;

	@GandalfProperty()
	retailPrice: number;

	@GandalfProperty()
	salesAdjustments: number;

	@GandalfProperty()
	salesDiscountAmount: number;

	@GandalfProperty()
	salesGrossSales: number;

	@GandalfProperty()
	salesNetSales: number;

	@GandalfProperty()
	salesQuantity: number;

	@GandalfProperty()
	salesTaxAmount: number;

	@GandalfProperty()
	wholesaleOfSales: number;

	@GandalfProperty()
	wholesaleOfStock: number;

}
