<form class="form-horizontal" [formGroup]="componentForm">
	<div class="form-group">
		<label class="col-sm-4 col-md-3 control-label required">First Name</label>
		<div class="col-sm-8 col-md-9">
			<input type="text" class="e-input" formControlName="firstName" revFocusInput pmsCapitalizeFirstLetter/>
		</div>
	</div>
	<div class="form-group">
		<label class="col-sm-4 col-md-3 control-label required">Last Name</label>
		<div class="col-sm-8 col-md-9">
			<input type="text" class="e-input" formControlName="lastName" pmsCapitalizeFirstLetter/>
		</div>
	</div>
	<div class="form-group">
		<label class="col-sm-4 col-md-3 control-label">Middle Name</label>
		<div class="col-sm-8 col-md-9">
			<input type="text" class="e-input" formControlName="middleName" pmsCapitalizeFirstLetter/>
		</div>
	</div>
	<div class="form-group">
		<label class="col-sm-4 col-md-3 control-label">Suffix</label>
		<div class="col-sm-4 col-md-5">
			<input type="text" class="e-input" formControlName="suffix" />
		</div>
		<div class="col-sm-4">
			<span class="help-block">(eg. Jr, Sr)</span>
		</div>
	</div>
	<div class="form-group">
		<label class="col-sm-4 col-md-3 control-label">Nickname</label>
		<div class="col-sm-8 col-md-9">
			<input type="text" class="e-input" formControlName="nickname" pmsCapitalizeFirstLetter/>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'familyDemographicsDateOfBirthFormGroup'">
		<label class="col-sm-4 col-md-3 control-label required">DOB</label>
		<div class="col-sm-8 col-md-9">
			<ejs-datepicker placeholder="mm/dd/yyyy"
							formControlName="dob"
							[format]="dateFormat">
			</ejs-datepicker>
		</div>
	</div>
	<div class="form-group" *pmsUsaOnly>
		<label class="col-sm-4 col-md-3 control-label">SSN</label>
		<div class="col-sm-8 col-md-9">
			<ejs-maskedtextbox mask="000-00-0000"
							   formControlName="ssn">
			</ejs-maskedtextbox>
		</div>
	</div>
	<div class="form-group" *pmsCanadaOnly>
		<label class="col-sm-4 col-md-3 control-label">SIN/PHN</label>
		<div class="col-sm-8 col-md-9">
			<input class="e-input" type="text" formControlName="sin" pmsSinInput/>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'familyDemographicsSexFormGroup'">
		<label class="col-sm-4 col-md-3 control-label required">Sex</label>
		<div class="col-sm-8 col-md-9">
			<pms-enum-select-button [enumList]="genderOptions"
									formControlName="gender"
			></pms-enum-select-button>
		</div>
	</div>
</form>
