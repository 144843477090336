import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { statefulFormBaseKey } from '@app-store/mixins/stateful-form.mixin';
import { FormState } from '@app-store/reducers/forms.reducer';
import { FormPersistenceStrategy } from '@app-store/services/stateful-form.service';
import { StatefulBaseComponent } from '@app-store/stateful-base.component';
import { StatefulBaseComponentUtil } from '@app-store/stateful-base.component.util';
import { StatefulFormUtil } from '@app-store/utils/stateful-form-util';
import { _get, _isEqual } from '@core/lodash/lodash';
import { FormMode } from '@shared/constants/form-mode.enum';
import { of } from 'rxjs';
import { distinctUntilChanged, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

export interface StatefulComponentConfig {
	name: string;
}

export function StatefulComponent(config: StatefulComponentConfig) {
	return function <T extends new(...args: any[]) => StatefulBaseComponent>(target: T) {
		const buildFormOriginalImpl = target.prototype.buildForm;
		const submitFormOriginalImpl = target.prototype.submitForm;

		Object.defineProperty(target.prototype, '_componentName', {
			value: config.name,
		});

		/*****************************************************************
		 * STATEFUL PROPERTY LOGIC
		 *****************************************************************/

		/**
		 * Define Property
		 * Loops through properties marked as a @StatefulProperty and redefines getter and setter behavior.
		 * @param Prototype The prototype of the class to be examined.
		 */
		StatefulBaseComponentUtil.getStatefulProperties(target.prototype)?.forEach(propertyKey => {
			// Add the property to the class to be accessed by the getters and setters.
			target.prototype[`_${propertyKey}`] = undefined;
			Object.defineProperty(target.prototype, propertyKey, {
				get: getProperty(propertyKey),
				set: setProperty(propertyKey),
			});
		});

		/**
		 * Get Property
		 * Overrides the getter property of the provided property key.
		 * @param propertyKey The property key for the getter that needs to be defined.
		 */
		/* eslint-disable-next-line prefer-arrow/prefer-arrow-functions */
		function getProperty(propertyKey: string) {
			return function() {
				return this[`_${propertyKey}`];
			};
		}

		/**
		 * Set Property
		 * Overrides the setter property of the provided property key. It is important to note
		 * that setting the property should only be done during or after the Angular OnInit
		 * lifecycle hook has been fired.
		 * @param propertyKey The property key for the setter that needs to be defined.
		 */
		/* eslint-disable-next-line prefer-arrow/prefer-arrow-functions */
		function setProperty(propertyKey: string) {
			return function(value) {
				this.statefulPropertyService.updatePropertyValue(propertyKey, value);
				this[`_${propertyKey}`] = value;
			};
		}

		/*****************************************************************
		 * STATEFUL FORM LOGIC
		 *****************************************************************/

		if (implementsStatefulForm()) {
			Object.defineProperty(target.prototype, 'buildForm', {
				value: buildForm,
			});
			Object.defineProperty(target.prototype, 'formMode', {
				get: getFormMode,
				set: setFormMode,
			});
			Object.defineProperty(target.prototype, 'submitForm', {
				value: submitForm,
			});
		}

		/**
		 * Build Form
		 * Overrides the provided build form implementation to include the necessary components to
		 * make the form stateful.
		 */
		function buildForm() {
			return buildFormOriginalImpl.apply(this).pipe(
				withLatestFrom(this.statefulFormService.selectFormState()),
				switchMap(patchFormStateOrData.bind(this)),
				tap(observeAndUpdateFormState.bind(this)),
			);
		}

		/**
		 * Get Form Mode
		 * Gets the current value of the form mode.
		 */
		function getFormMode() {
			return this._formMode;
		}

		/**
		 * Set Form Mode
		 * Sets the form mode of the component while also persisting the new value into the store.
		 * @param mode Mode value to be use to set the value.
		 */
		function setFormMode(mode: FormMode) {
			this.statefulFormService.updateFormMode(mode);
			this._formMode = mode;
		}

		/**
		 * Submit Form
		 * Overrides the submit form implementation provided within the component to mark the form as
		 * submitted within the component state.
		 */
		function submitForm(...args: any[]) {
			this.statefulFormService.updateFormSubmitted(true);

			return submitFormOriginalImpl.apply(this, args);
		}

		/**
		 * Patch Form Or Data
		 * This handles the patching of existing form state or data into the form. This is the hook
		 * that should be used to retrieve data to populate the form asynchronously before rendering the
		 * form to the screen and allowing edits.
		 * @param form The form that needs to have value patched in.
		 * @param formState The current FormState of the form within in the store.
		 */
		function patchFormStateOrData([form, formState]: [UntypedFormGroup | UntypedFormArray, FormState]) {
			if (_get(formState, ['value'])) {
				form.patchValue(formState.value);
				StatefulFormUtil.patchFormMeta(form, formState.meta);
				this.formRestored = true;
				return of(form);
			} else {
				return this.patchFormData ? this.patchFormData(form) : of(form);
			}
		}

		/**
		 * Observe And Update Form State
		 * Observes and updates the form value upon change.
		 * @param form Form to observe for value changes.
		 */
		function observeAndUpdateFormState(form: UntypedFormGroup | UntypedFormArray) {
			if (this.statefulFormService.persistenceStrategy === FormPersistenceStrategy.EAGER && !this.unsubscribe.isStopped) {
				form.valueChanges.pipe(
					distinctUntilChanged(_isEqual),
					takeUntil(this.unsubscribe),
				).subscribe(() => {
					this.statefulFormService.updateFormValue(form.getRawValue());
					this.statefulFormService.updateFormMeta(StatefulFormUtil.getFormMeta(form));
				});
			}
		}

		/* eslint-disable-next-line prefer-arrow/prefer-arrow-functions */
		function implementsStatefulForm(): boolean {
			return Reflect.getMetadata(statefulFormBaseKey, target);
		}
	};
}
