import { PrintBarcodeLabelDataRequest } from '@core/printer/PrintBarcodeLabelDataRequest';
import { TemplateSearchObjectRequest } from '@core/printer/TemplateSearchObjectRequest';

export class PrintBarcodeLabelRequest {
	readonly DocumentName: string;

	readonly LabelData: PrintBarcodeLabelDataRequest[][];

	readonly PrinterName: string;

	readonly TemplateSearchObject: TemplateSearchObjectRequest[];

	constructor(
		documentName: string,
		labelData: PrintBarcodeLabelDataRequest[][],
		printerName: string,
		templateSearchObject: TemplateSearchObjectRequest[],
	) {
		this.DocumentName = documentName;
		this.LabelData = labelData;
		this.PrinterName = printerName;
		this.TemplateSearchObject = templateSearchObject;
	}
}
