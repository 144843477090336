<div>
	<ejs-grid #grid
			  [dataSource]="files"
			  [allowSelection]="true"
			  [allowSorting]="true"
			  [allowResizing]="true"
			  [allowPaging]="false"
			  gridLines="Horizontal"
			  height="320"
			  [attr.data-test-id]="'patientDocumentsComponentTable'">
		<ng-template #toolbarTemplate let-data>
			<rev-table-action-menu [table]="grid">
				<ng-container rev-table-action-menu-left>
					<div class="e-toolbar-item">
						<button ejs-button type="button" [isPrimary]="true" [disabled]="disabled" (click)="openUploadModal()"
								[attr.data-test-id]="'patientDocumentsUploadButton'">Upload
						</button>
						@if (canScan) {
							<button ejs-button type="button" [disabled]="disabled" (click)="openScanModal()" [attr.data-test-id]="'patientDocumentsScanButton'">
								Scan
							</button>
						}
						@if (shouldShowDrawButton()) {
							<button ejs-button type="button" (click)="openPatientFolderModalForDrawingTool()"
									[attr.data-test-id]="'patientDocumentsDrawButton'">Draw
							</button>
						}
					</div>
				</ng-container>
			</rev-table-action-menu>
		</ng-template>
		<e-columns>
			<e-column width="150" field="fileName" headerText="Name" clipMode="EllipsisWithTooltip"></e-column>
			<e-column width="90" field="fileDate" headerText="Date" [format]="dayFormatter" clipMode="EllipsisWithTooltip"></e-column>
			<e-column width="150" field="fileDescription" headerText="Description" clipMode="EllipsisWithTooltip"></e-column>
			<e-column width="110" field="actionButtons" headerText="" [allowSorting]="false">
				<ng-template #template let-data>
					@if (isPreviewable(data)) {
						<rev-button
							[revGridButton]="'preview'"
							(buttonClick)="openPreview(data)"
							dataTestId="patientDocumentPreviewButton">
						</rev-button>
					}
					<rev-button
						buttonIcon="download"
						buttonStyle="primary"
						buttonTooltip="Download"
						revEventStopPropagation
						(buttonClick)="downloadImage(data)"
						dataTestId="patientDocumentDownloadButton">
					</rev-button>
					@if (isImageEditable(data)) {
						<rev-button
							buttonIcon="pencil"
							buttonStyle="primary"
							buttonTooltip="Edit Image"
							revEventStopPropagation
							(buttonClick)="editImage(data)"
							dataTestId="PatientDocumentEditButton">
						</rev-button>
					}
					<rev-button
						[revGridButton]="'delete'"
						(buttonClick)="showDeleteModal(data)"
						dataTestId="patientDocumentDeleteButton">
					</rev-button>
				</ng-template>
			</e-column>
		</e-columns>
	</ejs-grid>
</div>
