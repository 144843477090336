import { Injectable, NgZone } from '@angular/core';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { INTER_APP_CONSTANTS } from '@core/legacy/inter-app.constants';
import { PrintService } from '@core/print/print.service';
import { SortingService } from 'morgana';
import { FindActiveLocationProductsRequest } from '@gandalf/model/find-active-location-products-request';
import { InventoryReconciliationPrintComponent } from '@shared/component/inventory-reconciliation-print/inventory-reconciliation-print.component';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { _find } from '@core/lodash/lodash';
import { MasterProductCategory } from '@gandalf/constants';
import { ProductCategoryResponse } from '@gandalf/model/product-category-response';
import { InventoryGandalfService } from '@gandalf/services';
import { InventoryServiceResponse } from '@gandalf/model/inventory-service-response';
import { LocationProductItemTableResponse } from '@gandalf/model/location-product-item-table-response';

@Injectable({
	providedIn: 'root',
})
export class InventoryService {

	constructor(
		private eventsManagerService: EventsManagerService,
		private ngZone: NgZone,
		private printService: PrintService,
		private securityManagerService: SecurityManagerService,
		private inventoryGandalfService: InventoryGandalfService,
	) {
	}

	findActiveLocationProductSummaries(request: FindActiveLocationProductsRequest) {
		return this.inventoryGandalfService.findActiveLocationProducts(request)
			.pipe(map(items => SortingService.sortBy(items, ['manufacturerName', 'name', 'id'], ['asc', 'asc', 'desc'])));
	}

	findActiveLocationProductItems(id: number) {
		return this.inventoryGandalfService.findActiveLocationProductItems(id)
			.pipe(map((items: LocationProductItemTableResponse[]) => SortingService.sortBy(items, ['upc', 'sku', 'id'], ['asc', 'asc', 'desc'])));
	}

	/* istanbul ignore next: gandalf */
	getLocationProductItemById(id: number) {
		return this.inventoryGandalfService.getLocationProductItemById(id);
	}

	/* istanbul ignore next: gandalf */
	getLocationProductById(id: number) {
		return this.inventoryGandalfService.getLocationProductById(id);
	}

	findActiveCPTServices(locationId: number) {
		return this.inventoryGandalfService.findActiveCptServices(locationId)
			.pipe(map((items: InventoryServiceResponse[]) => SortingService.sortBy(items, ['name', 'id'], ['asc', 'desc'])));
	}

	findActiveOtherServices(locationId: number) {
		return this.inventoryGandalfService.findActiveOtherServices(locationId)
			.pipe(map((items: InventoryServiceResponse[]) => SortingService.sortBy(items, ['name', 'id'], ['asc', 'desc'])));
	}

	findAllActiveServices(locationId: number) {
		return combineLatest([
			this.findActiveCPTServices(locationId),
			this.findActiveOtherServices(locationId),
		]).pipe(map(([cptServices, otherServices]) => [...cptServices, ...otherServices]));
	}

	subscribeToLegacyEvents() {
		this.eventsManagerService.subscribe(INTER_APP_CONSTANTS.REV360.RECONCILIATION.OPEN_PRINT_VIEW, (_event) => {
			this.ngZone.run(() => {
				this.printService.openPrint(InventoryReconciliationPrintComponent);
			});
		});
	}

	getMasterProductCategory(masterProductCategory: MasterProductCategory): ProductCategoryResponse {
		return _find(this.securityManagerService.environmentData.masterProductCategories, ['masterCategory', masterProductCategory]);
	}
}
