// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientFileInfoResponse } from './patient-file-info-response';

/** See com.rev360.pms.api.controller.encounter.consent.PatientConsentResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientConsentResponse extends GandalfModelBase {

	@GandalfProperty()
	comments: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	consentDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	consentMechanism: constants.PatientConsentMechanism;

	@GandalfProperty({ propertyType: 'LocalDate' })
	expirationDate: Date;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	practiceConsentFormId: number;

	@GandalfProperty()
	practiceConsentFormName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	practiceConsentFormType: constants.PracticeConsentFormType;

	@GandalfProperty()
	signedDocument: PatientFileInfoResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.PatientConsentStatus;

}
