// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.payment.PaymentDashboardSearchRequest */
/* istanbul ignore next */
@GandalfModel
export class PaymentDashboardSearchRequest extends GandalfModelBase {

	@GandalfLabel('Payment Date End')
	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate: Date;

	@GandalfLabel('Payer Name')
	@GandalfValidator({ sizeString: { fieldLabel: 'Payer Name', maxLength: 1000, minLength: 0, message: 'Payer Name must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	payerName: string;

	@GandalfConstantDecorator()
	@GandalfLabel('Payer Type')
	@GandalfProperty()
	payerType: constants.PayerType;

	@GandalfConstantDecorator()
	@GandalfLabel('Payment Method')
	@GandalfProperty()
	paymentMethodType: constants.PaymentMethodType;

	@GandalfConstantDecorator()
	@GandalfLabel('Status')
	@GandalfProperty()
	paymentSearchStatus: constants.PaymentSearchStatus;

	@GandalfLabel('Location')
	@GandalfProperty()
	practiceLocationId: number;

	@GandalfLabel('Reference #')
	@GandalfValidator({ sizeString: { fieldLabel: 'Reference #', maxLength: 1000, minLength: 0, message: 'Reference # must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	referenceNumber: string;

	@GandalfConstantDecorator()
	@GandalfLabel('Payment Source')
	@GandalfProperty()
	sourceTypeSearch: constants.PaymentGroupSourceTypeSearch;

	@GandalfLabel('Payment Date Start')
	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate: Date;

}
