// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.encounter.EncounterTemplateListResponse */
/* istanbul ignore next */
@GandalfModel
export class EncounterTemplateListResponse extends GandalfModelBase {

	@GandalfProperty()
	abbreviation: string;

	@GandalfProperty()
	displayColor: number;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	inClinic: boolean;

	@GandalfProperty()
	includeInMuCalcs: boolean;

	@GandalfProperty()
	interviewTemplateName: string;

	@GandalfProperty()
	isComprehensive: boolean;

	@GandalfProperty()
	name: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.TemplateStatus;

	@GandalfProperty()
	totalDuration: number;

}
