// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.history.familyhealthhistory.ReviewPatientFamilyHealthHistoryReviewSetRequest */
/* istanbul ignore next */
@GandalfModel
export class ReviewPatientFamilyHealthHistoryReviewSetRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Family Health History Review Set is required' } })
	@GandalfProperty({ isRequired: true })
	reviewSetId: number;

	@GandalfProperty()
	reviewed: boolean;

}
