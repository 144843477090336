import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { CreditCardFormService } from '@core/credit-card-form/credit-card-form.service';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { _isNil } from '@core/lodash/lodash';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { OpenEdgePaymentService } from '@core/open-edge-payment/open-edge-payment.service';
import { OpenEdgeCommonPaymentStatus } from '@gandalf/constants';
import { OpenEdgeCreatePayFieldsTransactionRequest } from '@gandalf/model/open-edge-create-pay-fields-transaction-request';
import { OpenEdgeTransactionDetailsResponse } from '@gandalf/model/open-edge-transaction-details-response';
import { BaseComponent } from '@shared/component/base.component';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { takeUntil } from 'rxjs/operators';

export class OpenEdgeManualCardModalResult {
	manualProcessRequested: boolean;
	transaction: OpenEdgeTransactionDetailsResponse;
	paymentSaveResult: OpenEdgePaymentSaveResult;
}

export enum OpenEdgePaymentSaveResult {
	NOT_REQUESTED,
	SAVED,
	NOT_SAVED,
}

@Component({
	selector: 'pms-open-edge-manual-card-modal',
	templateUrl: './open-edge-manual-card-modal.component.html',
	providers: [CreditCardFormService],
})
export class OpenEdgeManualCardModalComponent extends BaseComponent implements OnInit, AfterViewInit {

	@ViewChild('modal')
	modal: DialogComponent;

	locationId: number;
	amount: number;
	payerId: number;
	personId: number;
	cardForm: any;
	errors: string[] = [];
	processPaymentText = 'Process Payment';
	saveCreditCard = false;

	constructor(
		private modalConfig: ModalConfig,
		private dynamicModalRef: DynamicModalRef,
		private openEdgePaymentService: OpenEdgePaymentService,
		private featureService: FeatureService,
		private creditCardFormService: CreditCardFormService,
	) {
		super();
	}

	ngOnInit(): void {
		this.parseConfigData(this.modalConfig.data);
	}

	ngAfterViewInit() {
		this.loadConfiguration();
	}

	parseConfigData(data) {
		this.locationId = data.locationId;
		this.amount = data.amount;
		this.payerId = data.payerId;
		this.personId = data.personId;
	}

	processPaymentDisabled() {
		return this.creditCardFormService.creditCardFormDisabled();
	}

	isCardFormLoaded() {
		return this.creditCardFormService.getCardFormLoaded();
	}

	isCreditCardProcessing() {
		return this.creditCardFormService.getCreditCardProcessing();
	}

	loadConfiguration() {
		this.openEdgePaymentService.globalPaymentsErrorSubject.pipe(takeUntil(this.unsubscribe)).subscribe(error => this.handleGlobalPaymentsError(error));
		this.openEdgePaymentService.configureOpenEdgeGlobalPayments();
		setTimeout(this.initializeCardForm);
	}

	initializeCardForm = () => {
		this.creditCardFormService.getCreditCardForm(this.createSaleTransaction, this.creditCardProcessingError);
	};

	handleGlobalPaymentsError(error: any) {
		this.creditCardFormService.handleGlobalPaymentsError(error);
		this.errors = this.creditCardFormService.getCreditCardProcessingError();
	}

	processPayment() {
		this.errors = [];
		this.creditCardFormService.processCard();
	}

	creditCardProcessingError = (errors) => {
		this.errors = errors;
	};

	createSaleTransaction = (temporaryToken: string) => {
		const request = new OpenEdgeCreatePayFieldsTransactionRequest();
		request.practiceLocationId = this.locationId;
		request.amount = this.amount;
		request.payerId = this.payerId;
		request.personId = this.personId;
		request.saveCreditCard = this.saveCreditCard;
		request.temporaryToken = temporaryToken;

		this.openEdgePaymentService.createSaleTransaction(request).subscribe({
			next: transaction => this.handleOpenEdgeTransaction(transaction),
			error: () => this.creditCardFormService.setProcessing(false),
		});
	};

	handleOpenEdgeTransaction(transaction: OpenEdgeTransactionDetailsResponse) {
		switch (transaction.paymentStatus.value) {
			case OpenEdgeCommonPaymentStatus.APPROVED.value: {
				const openEdgeManualCardModalResult = new OpenEdgeManualCardModalResult();
				openEdgeManualCardModalResult.transaction = transaction;
				openEdgeManualCardModalResult.manualProcessRequested = false;
				openEdgeManualCardModalResult.paymentSaveResult = this.getPaymentStoreResult(transaction.paymentStoredTokenId);
				this.closeModal(openEdgeManualCardModalResult);
				break;
			}
			case OpenEdgeCommonPaymentStatus.PARTIAL_APPROVAL_VOIDED.value:
			case OpenEdgeCommonPaymentStatus.PARTIAL_APPROVAL_RETURNED.value:
				this.errors = ['Payment declined. Please use a different card and try again.'];
				this.processPaymentText = 'Retry';
				this.creditCardFormService.setProcessing(false);
				break;
			case OpenEdgeCommonPaymentStatus.PARTIAL_APPROVAL_ERROR.value:
				this.closeModal();
				this.openEdgePaymentService.showPaymentNotAppliedAlert(transaction);
				break;
			default:
				this.errors = [transaction.status?.label || transaction.errorMessage || transaction.paymentStatus.label];
				this.processPaymentText = 'Retry';
				this.creditCardFormService.setProcessing(false);
				break;
		}
	}

	getPaymentStoreResult(tokenId: number): OpenEdgePaymentSaveResult {
		if (!this.saveCreditCard) {
			return OpenEdgePaymentSaveResult.NOT_REQUESTED;
		}
		if (_isNil(tokenId)) {
			return OpenEdgePaymentSaveResult.NOT_SAVED;
		}
		return OpenEdgePaymentSaveResult.SAVED;
	}

	applyPaymentWithoutProcessingCard() {
		const openEdgeManualCardModalResult = new OpenEdgeManualCardModalResult();
		openEdgeManualCardModalResult.manualProcessRequested = true;
		this.closeModal(openEdgeManualCardModalResult);
	}

	/* istanbul ignore next: closeModal */
	closeModal(result?) {
		this.cardForm?.dispose();
		this.dynamicModalRef.close(this.modal, result);
	}

	// Display the option only when:
	// 1. Modal is not processing
	// 2. personId is present (payer type is patient)
	// 3. Receive payments flag is on
	showSaveCreditCardOption() {
		return !this.creditCardFormService.getCreditCardProcessing()
			&& !_isNil(this.personId)
			&& this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.PATIENTS.ACCOUNT.GLOBAL_PAYMENT_RECEIVE_PAYMENT_CARD_ON_FILE);
	}
}
