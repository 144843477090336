<div class="e-input-group" [class.e-disabled]="disabled" [class.e-input-focus]="focused">
	<input #inputElement
			type="text"
			[class]="class"
			[disabled]="disabled"
			(keydown.Home)="onKeydownHome($event)"
			(keydown.End)="onKeydownEnd($event)"
			(keydown.ArrowDown)="onKeydownDownArrow($event)"
			(keydown.ArrowUp)="onKeydownUpArrow($event)"
			(keydown.ArrowLeft)="onKeydownLeftArrow($event)"
			(keydown.ArrowRight)="onKeydownRightArrow($event)"
			[placeholder]="placeholder"
			(focus)="onFocus()"
			(blur)="onBlur()"
			[style.width]="appendPxOrUndefined(width)"
			[style.height]="appendPxOrUndefined(height)"
			[(ngModel)]="formattedValue"/>
	<span (click)="onStepperUp()" (mousedown)="stepperMouseDown(stepperDirection.UP)" class="e-input-group-icon fa fa-caret-up"></span>
	<span (click)="onStepperDown()" (mousedown)="stepperMouseDown(stepperDirection.DOWN)" class="e-input-group-icon fa fa-caret-down"></span>
</div>
