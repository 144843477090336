import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownFilter } from '@core/dropdown-filter/dropdown-filter.decorator';
import { _isNil } from '@core/lodash/lodash';
import { ObjectUtils } from 'morgana';
import { ChangeEventArgs, DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { GandalfConstantList } from 'gandalf';

@Component({
	selector: 'pms-constant-dropdown',
	templateUrl: './constant-dropdown.component.html',
	styles: [],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ConstantDropdownComponent),
			multi: true,
		},
	],
})
export class ConstantDropdownComponent implements OnChanges, ControlValueAccessor {

	@ViewChild('dropdownlist')
	dropdownlist: DropDownListComponent;

	@Input()
	constantList: GandalfConstantList;

	@Input()
	showClearButton: boolean;

	@Input()
	placeholder: string;

	@Input()
	disabled = false;

	@Input()
	label = 'label';

	@Input()
	dataTestIdPrefix = '';

	@Output()
	selectionChange = new EventEmitter<any>();

	@DropdownFilter()
	shouldFilter: (options: any[]) => boolean;

	isCreated = false;

	selectedValue: any;

	constructor(private changeDetectorRef: ChangeDetectorRef) {
	}

	onModelChange: (_: any) => void = () => {
	};

	onModelTouched: () => void = () => {
	};

	ngOnChanges(changes: SimpleChanges) {
		if (changes && changes.constantList) {
			if (this.isCreated && !changes.constantList.isFirstChange()) {
				this.dataBind();
			}
		}
	}

	writeValue(value: any): void {
		if (!_isNil(value)) {
			this.selectedValue = value.value;
		} else {
			this.selectedValue = null;
		}
		this.changeDetectorRef.markForCheck();
	}

	registerOnChange(fn: (_: any) => void): void {
		this.onModelChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onModelTouched = fn;
	}

	onSelectionChange(event: ChangeEventArgs) {
		const itemValue = !_isNil(event.itemData) ? event.itemData.value : null;
		if (itemValue !== this.selectedValue) {
			this.onModelChange(event.itemData);
			this.selectedValue = itemValue;
			this.selectionChange.emit(event);
		}
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	dropdownlistCreated() {
		this.isCreated = true;
	}

	dataBind() {
		if (this.isCreated) {
			this.dropdownlist.value = this.selectedValue;
		}
	}

	buildDataTestIdForDropdown() {
		const label = this.constantList.label?.toLowerCase().split(' ').join('.');
		if (ObjectUtils.isNilOrEmpty(label) && ObjectUtils.isNilOrEmpty(this.dataTestIdPrefix)) {
			return `constant.dropdown`;
		} else if (ObjectUtils.isNilOrEmpty(label)) {
			return `${this.dataTestIdPrefix}.dropdown`;
		} else if (ObjectUtils.isNilOrEmpty(this.dataTestIdPrefix)) {
			return label + '.dropdown';
		} else {
			return `${this.dataTestIdPrefix}-${label}.dropdown`;
		}
	}
}
