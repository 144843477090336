export class StatefulEventDefinition<A = unknown> {
	storeKeyArgs: A;

	constructor(public key: string) {}
}

export const UPDATE_PATIENT_INVOICES = createStatefulEvent<{patientId?: number, invoiceId?: number}>('UPDATE_PATIENT_INVOICES');

export const UPDATE_PATIENT_INSURANCES = createStatefulEvent<{patientId: number}>('UPDATE_PATIENT_INSURANCES');

export const UPDATE_INSURANCE_COMPANIES = createStatefulEvent<never>('UPDATE_INSURANCE_COMPANIES');

export const UPDATE_PATIENT_NOTES = createStatefulEvent<{patientId: number}>('UPDATE_PATIENT_NOTES');

export const UPDATE_PATIENT_EYEGLASS_PRESCRIPTIONS = createStatefulEvent<{patientId: number}>('UPDATE_PATIENT_EYEGLASS_PRESCRIPTIONS');

export const UPDATE_PATIENT_CONTACT_LENS_PRESCRIPTIONS = createStatefulEvent<{patientId: number}>('UPDATE_PATIENT_CONTACT_LENS_PRESCRIPTION');

export const UPDATE_PATIENT_MEDICATION_PRESCRIPTIONS = createStatefulEvent<{patientId: number}>('UPDATE_PATIENT_MEDICATION_PRESCRIPTIONS');

export const UPDATE_PATIENT_ACCOUNT = createStatefulEvent<{patientId: number}>('UPDATE_PATIENT_ACCOUNT');

export const UPDATE_PATIENT_LAB_ORDERS = createStatefulEvent<{patientId: number}>('UPDATE_PATIENT_LAB_ORDERS');

export const UPDATE_PATIENT_ORDERS = createStatefulEvent<{patientId: number}>('UPDATE_PATIENT_ORDERS');

export const UPDATE_PATIENT_FAMILY_MEMBERS = createStatefulEvent<{patientId: number}>('UPDATE_PATIENT_FAMILY_MEMBERS');

export const UPDATE_PATIENT_CONTACTS = createStatefulEvent<{patientId: number}>('UPDATE_PATIENT_CONTACTS');

export const UPDATE_PATIENT_ENCOUNTERS = createStatefulEvent<{patientId: number}>('UPDATE_PATIENT_ENCOUNTERS');

export const UPDATE_PATIENT_APPOINTMENTS = createStatefulEvent<{patientId: number}>('UPDATE_PATIENT_APPOINTMENTS');

export const UPDATE_PATIENT_RECALLS = createStatefulEvent<{patientId: number}>('UPDATE_PATIENT_RECALLS');

export const UPDATE_PATIENT_DIAGNOSES = createStatefulEvent<{patientId: number}>('UPDATE_PATIENT_DIAGNOSES');

export function createStatefulEvent<A>(event: string) {
	return new StatefulEventDefinition<A>(event);
}
