import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { State } from '@app-store/reducers';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { SortingService } from 'morgana';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { ParseExternalPatientDataFileRequest } from '@gandalf/model/parse-external-patient-data-file-request';
import { BetaSystemCode, PreferenceDefaults, PreferenceName } from '@gandalf/constants';
import { AddressOptionalRequest } from '@gandalf/model/address-optional-request';
import { AddressRequiredRequest } from '@gandalf/model/address-required-request';
import { AlertResponse } from '@gandalf/model/alert-response';
import { CreatePatientRequest } from '@gandalf/model/create-patient-request';
import { MovePatientFileRequest } from '@gandalf/model/move-patient-file-request';
import { PatientDuplicateSearchRequest } from '@gandalf/model/patient-duplicate-search-request';
import { PatientDuplicateSearchResponse } from '@gandalf/model/patient-duplicate-search-response';
import { PatientFileListRequest } from '@gandalf/model/patient-file-list-request';
import { PatientAccountGandalfService, PatientFileGandalfService, PatientGandalfService } from '@gandalf/services';
import { PatientNameResponse } from '@gandalf/model/patient-name-response';
import { PatientSearchByIdsRequest } from '@gandalf/model/patient-search-by-ids-request';
import { PatientSearchRequest } from '@gandalf/model/patient-search-request';
import { PatientSearchResponse } from '@gandalf/model/patient-search-response';
import { QuickAddPatientRequest } from '@gandalf/model/quick-add-patient-request';
import { UpdatePatientFileRequest } from '@gandalf/model/update-patient-file-request';
import { UpdatePatientRequest } from '@gandalf/model/update-patient-request';
import { Store } from '@ngrx/store';
import { GetPatientDashboardSummary } from '@patients-store/actions';
import { revRequiredValidator } from '@shared/validators/rev-required-validation';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UpdatePatientQuickViewRequest } from '@gandalf/model/update-patient-quick-view-request';
import { PatientQuickViewResponse } from '@gandalf/model/patient-quick-view-response';
import { PatientSimpleSearchRequest } from '@gandalf/model/patient-simple-search-request';
import { GandalfTheGreyService } from '@core/gandalf-the-grey/gandalf-the-grey.service';
import { ImportResultResponse } from '@gandalf/model/import-result-response';
import { ModelManipulationService } from 'gandalf';

@Injectable({
	providedIn: 'root',
})
export class PatientService {

	constructor(
		private patientGandalfService: PatientGandalfService,
		private store: Store<State>,
		private securityManagerService: SecurityManagerService,
		private featureService: FeatureService,
		private patientAccountGandalfService: PatientAccountGandalfService,
		private patientFileGandalfService: PatientFileGandalfService,
		private gandalfTheGreyService: GandalfTheGreyService,
		private modelManipulationService: ModelManipulationService,
	) {
	}

	/* istanbul ignore next: gandalf */
	getPatientNameById(patientId: number): Observable<PatientNameResponse> {
		return this.patientGandalfService.getPatientNameById(patientId);
	}

	/* istanbul ignore next: gandalf */
	getGuarantorsByPatientId(patientId: number) {
		return this.patientGandalfService.getGuarantorsByPatientId(patientId);
	}

	/* istanbul ignore next: gandalf */
	getPersonIdByPatientId(patientId: number) {
		return this.patientGandalfService.getPatientPersonId(patientId);
	}

	/* istanbul ignore next: gandalf */
	getPatientFullSsnById(patientId: number): Observable<string> {
		return this.patientGandalfService.getPatientFullSsnById(patientId);
	}

	/* istanbul ignore next: gandalf */
	getAlertsByPatientId(patientId: number): Observable<AlertResponse[]> {
		return this.patientGandalfService.getAlertsByPatientId(patientId);
	}

	/* istanbul ignore next: gandalf */
	quickAddPatient(request: QuickAddPatientRequest): Observable<PatientNameResponse> {
		return this.patientGandalfService.quickAddPatient(request);
	}

	/* istanbul ignore next: gandalf */
	createPatient(request: CreatePatientRequest): Observable<number> {
		return this.patientGandalfService.createPatient(request);
	}

	/* istanbul ignore next: gandalf */
	findPatientDuplicates(patientDuplicateSearchRequest: PatientDuplicateSearchRequest): Observable<PatientDuplicateSearchResponse[]> {
		return this.patientGandalfService.findDuplicatePatients(patientDuplicateSearchRequest);
	}

	findPatients(patientSearchRequest: PatientSearchRequest): Observable<PatientSearchResponse[]> {
		return this.patientGandalfService.findPatients(patientSearchRequest).pipe(
			map(patients => SortingService.sortBy(
				patients,
				[
					patient => patient.patientNameResponse.lastName,
					patient => patient.patientNameResponse.firstName,
					patient => patient.dateOfBirth,
					patient => patient.patientNameResponse.patientId,
				], [
					'asc',
					'asc',
					'asc',
					'asc',
				]),
			),
		);
	}

	findPatientByHeuristic(request: PatientSimpleSearchRequest): Observable<PatientSearchResponse[]> {
		return this.patientGandalfService.findBySimpleSearch(request).pipe(
			map(patients => SortingService.sortBy(
				patients,
				[
					patient => patient.patientNameResponse.lastName,
					patient => patient.patientNameResponse.firstName,
					patient => patient.dateOfBirth,
					patient => patient.patientNameResponse.patientId,
				],
				[
					'asc',
					'asc',
					'asc',
					'asc',
				],
			)),
		);
	}

	/* istanbul ignore next: gandalf */
	findPatientsByIds(patientSearchByIdsRequest: PatientSearchByIdsRequest): Observable<PatientSearchResponse[]> {
		return this.patientGandalfService.findPatientsByIds(patientSearchByIdsRequest);
	}

	/* istanbul ignore next: gandalf */
	getPatientAccount(patientId: number) {
		return this.patientGandalfService.getPatientAccount(patientId);
	}

	/* istanbul ignore next: gandalf */
	getPatientById(patientId: number) {
		return this.patientGandalfService.getPatientById(patientId);
	}

	/* istanbul ignore next: gandalf */
	patientHasAccessToMessages(patientId: number) {
		return this.patientGandalfService.patientHasAccessToMessages(patientId);
	}

	@ShowSavedSuccessToast()
	/* istanbul ignore next: gandalf */
	activatePatient(patientId: number) {
		return this.patientGandalfService.activatePatient(patientId);
	}

	@ShowSavedSuccessToast()
	/* istanbul ignore next: gandalf */
	deactivatePatient(patientId: number) {
		return this.patientGandalfService.deactivatePatient(patientId);
	}

	/* istanbul ignore next: gandalf */
	getPatientForPopover(patientId: number) {
		return this.patientGandalfService.getPatientForPopoverById(patientId);
	}

	/* istanbul ignore next: gandalf */
	findSchedulingPreferencesByPatientId(patientId: number) {
		return this.patientGandalfService.findSchedulingPreferencesByPatientId(patientId);
	}

	updatePatient(request: UpdatePatientRequest) {
		return this.patientGandalfService.updatePatient(request).pipe(
			tap(() => {
				this.store.dispatch(new GetPatientDashboardSummary({patientId: request.patientId}));
			}),
		);
	}

	/* istanbul ignore next: gandalf */
	@ShowSavedSuccessToast()
	updatePatientWithToaster(request: UpdatePatientRequest) {
		return this.updatePatient(request);
	}

	/* istanbul ignore next: gandalf */
	updatePatientWithoutToaster(request: UpdatePatientRequest) {
		return this.updatePatient(request);
	}

	/* istanbul ignore next: gandalf */
	findPatientFilesInFolder(patientId: number, folderId: string) {
		const request = new PatientFileListRequest();
		request.patientId = patientId;
		request.patientFolderId = folderId;
		return this.patientGandalfService.findPatientFilesInFolder(request).pipe(
			map(files => SortingService.sortBy(files, ['createdOn', 'fileId'], ['desc', 'desc'])),
		);
	}

	/* istanbul ignore next: gandalf */
	updatePatientFile(request: UpdatePatientFileRequest) {
		return this.patientGandalfService.updatePatientFile(request);
	}

	/* istanbul ignore next: gandalf */
	deletePatientFile(patientFileId: number): Observable<void> {
		return this.patientFileGandalfService.markPatientFileAsDeleted(patientFileId);
	}

	/* istanbul ignore next: gandalf */
	movePatientFile(patientFileId: number, folderId: string) {
		const movePatientFileRequest = new MovePatientFileRequest();
		movePatientFileRequest.folderId = folderId;
		movePatientFileRequest.patientFileId = patientFileId;
		return this.patientGandalfService.movePatientFile(movePatientFileRequest);
	}

	parseExternalPatientDataFile(patientFileId: number, isCdaValidation: boolean) {
		const request = new ParseExternalPatientDataFileRequest();
		request.isCdaValidation = isCdaValidation;
		request.patientFileId = patientFileId;
		const serviceName = 'Person';
		const endpointName = 'parseExternalPatientDataFile';
		return this.gandalfTheGreyService.execute(serviceName, endpointName, request).pipe(
			/**
			 * This is because GtG goes through the legacy service factory and needs the exact @class value that does not match the value that parseGandalfModel expects.
			 * The parseExternalPatientDataFile endpoint response uses inheritance and multiple nested responses (see ContinuityOfCareImportResultResponse).
			 * This approach assumes the GtG response matches the expected json data for the uplifted response exactly for parseGandalfModel to create the uplifted response.
			 * Limitations:
			 * 	The uplifted @class field is different in the uplifted response vs GtG response and must be removed after GtG has returned
			 * 	The GtG response fields can only contain primitives or nested objects/arrays due to simulating the raw json data that would come from an uplifted endpoint
			 * 	Assume GtG response matches GtW response so no direct type safety
			 */
			map(response => {
				response['@class'] = response['@class'].replace('com.hit.web.services.person.', '');
				return this.modelManipulationService.parseGandalfModel(ImportResultResponse, response);
			}),
		);
	}

	getEmptyAddressRequest() {
		return this.isPatientAddressRequired() ? new AddressRequiredRequest() : new AddressOptionalRequest();
	}

	setRequiredValidatorPostalCode(formGroup: UntypedFormGroup) {
		if (this.isPatientAddressRequired()) {
			FormUtilsService.addValidationToFormControl(formGroup.get(
				this.securityManagerService.hasBeta(BetaSystemCode.NON_STANDARD_ZIP_CODES)
					? 'nonStandardPostalCode'
					: 'postalCode',
			), () => revRequiredValidator('Zip code is required'));
		}
	}

	/* istanbul ignore next: gandalf */
	getAccountSummaryWithInsurance(patientId: number) {
		return this.patientAccountGandalfService.getPatientAccountBalances(patientId);
	}

	/* istanbul ignore next: gandalf */
	getGuarantorForPatientById(patientId: number) {
		return this.patientGandalfService.getGuarantorForPatientById(patientId);
	}

	/* istanbul ignore next: gandalf */
	updatePatientComment(request) {
		return this.patientGandalfService.updatePatientComment(request);
	}

	findPatientEmergencyContacts(patientId: number) {
		return this.patientGandalfService.findPatientEmergencyContacts(patientId).pipe(map(
			emergencyContacts => SortingService.sortBy(
				emergencyContacts,
				['emergencyContactType', emergencyContact => emergencyContact.person.lastName, emergencyContact => emergencyContact.person.firstName, 'emergencyContactId'],
				['asc', 'asc', 'asc', 'asc'],
			),
		));
	}

	/* istanbul ignore next: gandalf */
	reactivatePatientCellPhoneIfExists(patientId: number) {
		return this.patientGandalfService.reactivatePatientCellPhoneIfExists(patientId);
	}

	/* istanbul ignore next: gandalf */
	getPatientQuickView(patientId: number): Observable<PatientQuickViewResponse> {
		return this.patientGandalfService.getPatientQuickViewById(patientId);
	}

	/* istanbul ignore next: gandalf */
	updatePatientQuickView(request: UpdatePatientQuickViewRequest) {
		return this.patientGandalfService.updatePatientQuickView(request);
	}

	isPatientAddressRequired() {
		const preferenceAvailable = this.securityManagerService.hasBeta(BetaSystemCode.PATIENT_ADDRESS_NOT_REQUIRED) ||
			this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.ADMIN.GENERAL.PATIENT_ADDRESS_NOT_REQUIRED);
		return !preferenceAvailable || this.securityManagerService.preferenceValueIsOn(
			PreferenceName.DEMOGRAPHICS_ADDRESS_REQUIRED.value,
			PreferenceDefaults.DEMOGRAPHICS_ADDRESS_REQUIRED.value,
		);
	}
}
