import { Pipe, PipeTransform } from '@angular/core';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';

@Pipe({
	name: 'canadaLabel',
})
export class CanadaLabelPipe implements PipeTransform {

	constructor(private securityManagerService: SecurityManagerService) {

	}

	transform(defaultLabel: string, canadaLabel: string): string {
		return this.securityManagerService.isCanada() ? canadaLabel : defaultLabel;
	}

}
