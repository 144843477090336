<form class="form-horizontal" [formGroup]="componentForm">
	<div class="form-group" [attr.data-test-id]="'generalBenefitsGeneralCoPayFormGroup'">
		<label class="col-sm-4 control-label">General Co-Pay</label>
		<div class="col-sm-8">
			<pms-currency-input formControlName="generalCoPay" placeholder="" nanValue=""></pms-currency-input>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'generalBenefitsMedicalCoPayFormGroup'">
		<label class="col-sm-4 control-label">Medical Exam Co-Pay</label>
		<div class="col-sm-8">
			<pms-currency-input formControlName="medicalExamCoPay" placeholder="" nanValue=""></pms-currency-input>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'generalBenefitsRoutineCoPayFormGroup'">
		<label class="col-sm-4 control-label">Routine Exam Co-Pay</label>
		<div class="col-sm-8">
			<pms-currency-input formControlName="routineExamCoPay" placeholder="" nanValue=""></pms-currency-input>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'generalBenefitsContactFittingCoPayFormGroup'">
		<label class="col-sm-4 control-label">CL Fitting Co-Pay</label>
		<div class="col-sm-8">
			<pms-currency-input formControlName="contactLensFittingCoPay" placeholder="" nanValue=""></pms-currency-input>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'generalBenefitsDeductibleFormGroup'">
		<label class="col-sm-4 control-label">Deductible</label>
		<div class="col-sm-8">
			<pms-currency-input formControlName="deductible" placeholder="" nanValue=""></pms-currency-input>
		</div>
	</div>
	<div class="margin-bottom-sm">
		<div class="table-responsive">
			<table class="table table-hover" [attr.data-test-id]="'generalBenefitsEligibilityTable'">
				<thead>
				<tr>
					<th>Eligible For</th>
					<th></th>
					<th>Resets On</th>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td>Routine Exam</td>
					<td>
						<rev-status-select-button [options]="routineExamOptions"
												  formControlName="hasRoutineExamBenefit">
						</rev-status-select-button>
					</td>
					<td>
						<ejs-datepicker placeholder="mm/dd/yyyy"
										formControlName="routineExamBenefitResetDate"
										[format]="dateFormat">
						</ejs-datepicker>
					</td>
				</tr>
				<tr>
					<td>Contact Lens Fitting</td>
					<td>
						<rev-status-select-button [options]="contactLensFittingOptions"
												  formControlName="hasContactLensFittingBenefit">
						</rev-status-select-button>
					</td>
					<td>
						<ejs-datepicker placeholder="mm/dd/yyyy"
										formControlName="contactLensFittingBenefitResetDate"
										[format]="dateFormat">
						</ejs-datepicker>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</form>
