// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressOptionalRequest } from './address-optional-request';

// @ts-ignore
import { EyefinityOrderItemRequest } from './eyefinity-order-item-request';

// @ts-ignore
import { UpdateOrderRequest } from './update-order-request';

/** See com.rev360.pms.api.controller.order.eyefinity.UpdateEyefinityOrderRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateEyefinityOrderRequest extends UpdateOrderRequest {

	@GandalfLabel('Associated Authorization')
	@GandalfValidator({ sizeString: { message: 'Associated Authorization cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	associatedAuthorization: string;

	@GandalfLabel('Contact Lens Benefit')
	@GandalfProperty()
	benefitContactLens: boolean;

	@GandalfLabel('Exam Benefit')
	@GandalfProperty()
	benefitExam: boolean;

	@GandalfLabel('Frame Benefit')
	@GandalfProperty()
	benefitFrame: boolean;

	@GandalfLabel('Lens Benefit')
	@GandalfProperty()
	benefitLens: boolean;

	@GandalfValidator({ sizeString: { message: 'Comment cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	comment: string;

	@GandalfLabel('Exam Copay')
	@GandalfProperty({ propertyType: 'Money' })
	copayExam: number;

	@GandalfLabel('Materials Copay')
	@GandalfProperty({ propertyType: 'Money' })
	copayMaterials: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dueDate: Date;

	@GandalfProperty()
	encounterId: number;

	@GandalfLabel('Estimate Discounts')
	@GandalfProperty({ propertyType: 'Money' })
	estimatedDiscounts: number;

	@GandalfLabel('Estimated Insurance Benefit')
	@GandalfProperty({ propertyType: 'Money' })
	estimatedInsuranceBenefit: number;

	@GandalfValidator({ sizeString: { message: 'Supplier cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	externalSupplierIdentifier: string;

	@GandalfValidator({ sizeString: { message: 'Supplier Name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	externalSupplierName: string;

	@GandalfLabel('Fee Schedule')
	@GandalfProperty()
	eyefinityFeeScheduleId: number;

	@GandalfLabel('Order Items')
	@GandalfArray(EyefinityOrderItemRequest)
	eyefinityOrderItems: EyefinityOrderItemRequest[];

	@GandalfLabel('Allowance')
	@GandalfProperty({ propertyType: 'Money' })
	frameAllowance: number;

	@GandalfLabel('Wholesale Allowance')
	@GandalfProperty({ propertyType: 'Money' })
	frameWholesaleAllowance: number;

	@GandalfValidator({ sizeString: { message: 'Instructions cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	instructions: string;

	@GandalfLabel('Is Valid')
	@GandalfProperty()
	isValid: boolean;

	@GandalfValidator({ notNull: { message: 'Location is required' } })
	@GandalfProperty({ isRequired: true })
	locationId: number;

	@GandalfValidator({ sizeString: { message: 'Notification Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	notificationComments: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	notificationDate: Date;

	@GandalfValidator({ notNull: { message: 'Order is required' } })
	@GandalfProperty({ isRequired: true })
	orderId: number;

	@GandalfProperty({ propertyType: 'Money' })
	otherCost: number;

	@GandalfProperty({ propertyType: 'Money' })
	otherRemakeCost: number;

	@GandalfValidator({ notNull: { message: 'Patient Notified is required' } })
	@GandalfProperty({ isRequired: true })
	patientNotified: boolean;

	@GandalfProperty()
	processorId: number;

	@GandalfProperty({ propertyType: 'Money' })
	shipCost: number;

	@GandalfProperty({ propertyType: 'Money' })
	shipRemakeCost: number;

	@GandalfProperty()
	shipToOtherAddress: AddressOptionalRequest;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Ship To is required' } })
	@GandalfProperty({ isRequired: true })
	shipToType: constants.OrderShipToType;

	@GandalfConstantDecorator()
	@GandalfProperty()
	shippingCompany: constants.OrderShipmentCompany;

	@GandalfValidator({ sizeString: { message: 'Tracking # cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	trackingNumber: string;

	@GandalfProperty()
	vendorId: number;

	@GandalfValidator({ sizeString: { message: 'Vendor Order # cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	vendorOrderIdentifier: string;

	@GandalfValidator({ notNull: { message: 'Version is required' } })
	@GandalfProperty({ isRequired: true })
	version: number;

}
