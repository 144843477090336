// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.insurance.updatepersoninsurance.UpdatePatientMaterialBenefitsRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdatePatientMaterialBenefitsRequest extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	authorizationDate: Date;

	@GandalfValidator({ sizeString: { message: 'Authorization Number cannot be longer than 40 characters', minLength: 0, maxLength: 40 } })
	@GandalfProperty()
	authorizationNumber: string;

	@GandalfProperty({ propertyType: 'Money' })
	contactLensAllowance: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	contactLensBenefitResetDate: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	frameBenefitResetDate: Date;

	@GandalfProperty()
	hasContactLensBenefit: boolean;

	@GandalfProperty()
	hasFrameBenefit: boolean;

	@GandalfProperty()
	hasLensBenefit: boolean;

	@GandalfProperty({ propertyType: 'LocalDate' })
	lensBenefitResetDate: Date;

	@GandalfProperty({ propertyType: 'Money' })
	materialCoPay: number;

	@GandalfProperty({ propertyType: 'Money' })
	maxFrameAllowance: number;

	@GandalfProperty({ propertyType: 'Money' })
	minFrameAllowance: number;

}
