// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CarePlanItemTemplateListResponse } from './care-plan-item-template-list-response';

/** See com.rev360.pms.api.controller.shared.diagnosis.CarePlanTemplateListResponse */
/* istanbul ignore next */
@GandalfModel
export class CarePlanTemplateListResponse extends GandalfModelBase {

	@GandalfProperty()
	carePlanTemplateId: number;

	@GandalfArray(CarePlanItemTemplateListResponse)
	items: CarePlanItemTemplateListResponse[];

	@GandalfProperty()
	name: string;

}
