import { Injectable } from '@angular/core';
import { EnumUtil, SortingService } from 'morgana';
import { _isEmpty } from '@core/lodash/lodash';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { InsuranceCompanyStatus } from '@gandalf/constants';
import { CreatePersonInsuranceRequest } from '@gandalf/model/create-person-insurance-request';
import { CreatePracticeInsuranceCompanyRequest } from '@gandalf/model/create-practice-insurance-company-request';
import { FeeScheduleDropdownResponse } from '@gandalf/model/fee-schedule-dropdown-response';
import { PersonCoverageResponse } from '@gandalf/model/person-coverage-response';
import { PersonInsuranceDetailsResponse } from '@gandalf/model/person-insurance-details-response';
import { PersonInsuranceTooltipResponse } from '@gandalf/model/person-insurance-tooltip-response';
import { PracticeInsuranceCompanyDropdownResponse } from '@gandalf/model/practice-insurance-company-dropdown-response';
import { UpdatePersonInsuranceAndPolicyRequest } from '@gandalf/model/update-person-insurance-and-policy-request';
import { UpdatePersonInsuranceRequest } from '@gandalf/model/update-person-insurance-request';
import { InsuranceGandalfService } from '@gandalf/services';
import { FormattedNamePipe } from '@shared/pipes/formatted-name/formatted-name.pipe';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface FormattedPersonCoverageResponse extends PersonCoverageResponse {
	formattedName: string;
}

export interface FormattedFeeScheduleDropdownResponse extends FeeScheduleDropdownResponse {
	label: string;
	value: number;
}

export interface FormattedPracticeInsuranceCompanyResponse extends PracticeInsuranceCompanyDropdownResponse {
	label: string;
	value: number;
}

export interface FormattedPersonInsuranceDetails extends PersonInsuranceDetailsResponse {
	phoneString: string;
	cityStateZip: string;
}

export interface FormattedPersonInsuranceTooltipResponse extends PersonInsuranceTooltipResponse {
	formattedInsuranceCompanyName: string;
}

@Injectable({
	providedIn: 'root',
})
export class InsuranceService {

	constructor(
		private insuranceGandalfService: InsuranceGandalfService,
		private formattedNamePipe: FormattedNamePipe,
	) { }

	static formatActiveCompanyForDropdown(company: PracticeInsuranceCompanyDropdownResponse): FormattedPracticeInsuranceCompanyResponse {
		const companyDropdownResponse = company as FormattedPracticeInsuranceCompanyResponse;
		companyDropdownResponse.label = companyDropdownResponse.name;
		companyDropdownResponse.value = companyDropdownResponse.id;

		return companyDropdownResponse;
	}

	static formatFeeScheduleForDropdown(company: FeeScheduleDropdownResponse): FormattedFeeScheduleDropdownResponse {
		const companyDropdownResponse = company as FormattedPracticeInsuranceCompanyResponse;
		companyDropdownResponse.label = companyDropdownResponse.name;
		companyDropdownResponse.value = companyDropdownResponse.id;

		return companyDropdownResponse;
	}

	static formatInsuranceCompanyForDropdown(insuranceCompany: PracticeInsuranceCompanyDropdownResponse): FormattedPracticeInsuranceCompanyResponse {
		const formattedPracticeInsuranceCompany = insuranceCompany as FormattedPracticeInsuranceCompanyResponse;
		if (EnumUtil.equals(insuranceCompany.status, InsuranceCompanyStatus.INACTIVE)) {
			formattedPracticeInsuranceCompany.label = `(Inactive Co.) ${insuranceCompany.name}`;
		} else {
			formattedPracticeInsuranceCompany.label = insuranceCompany.name;
		}
		if (!_isEmpty(insuranceCompany.payerId)) {
			formattedPracticeInsuranceCompany.label += ' (' + insuranceCompany.payerId + ')';
		}
		formattedPracticeInsuranceCompany.value = insuranceCompany.id;

		return formattedPracticeInsuranceCompany;
	}

	getPersonInsuranceById(personInsuranceId: number) {
		return this.insuranceGandalfService.getPersonInsuranceById(personInsuranceId);
	}

	findPersonInsurancesByPersonId(personId: number): Observable<FormattedPersonInsuranceDetails[]> {
		return this.insuranceGandalfService.findPersonInsurancesByPersonId(personId).pipe(
			map(policies => policies.map(policy => this.formatInsurancePolicy(policy))),
			map(policies => SortingService.sortBy(policies, ['id'], ['asc'])),
		);
	}

	findPersonInsurancesByPatientId(patientId: number): Observable<FormattedPersonInsuranceDetails[]> {
		return this.insuranceGandalfService.findPersonInsurancesByPatientId(patientId).pipe(
			map(policies => policies.map(policy => this.formatInsurancePolicy(policy))),
			map(policies => SortingService.sortBy(policies, ['id'], ['asc'])),
		);
	}

	getCompanyNameByPersonInsuranceId(personInsuranceId: number) {
		return this.insuranceGandalfService.getCompanyNameByPersonInsuranceId(personInsuranceId);
	}

	findActivePracticeInsuranceCompanies(): Observable<FormattedPracticeInsuranceCompanyResponse[]> {
		return this.insuranceGandalfService.findActivePracticeInsuranceCompanies().pipe(
			map(companies => SortingService.sortBy(companies, ['name', 'id'], ['asc', 'asc'])),
			map(companies => companies.map(company => InsuranceService.formatInsuranceCompanyForDropdown(company))),
		);
	}

	findPracticeInsuranceCompanies(): Observable<FormattedPracticeInsuranceCompanyResponse[]> {
		return this.insuranceGandalfService.findPracticeInsuranceCompanies().pipe(
			map(companies => SortingService.sortBy(companies, ['status', 'name', 'id'], ['asc', 'asc', 'asc'])),
			map(companies => companies.map(company => InsuranceService.formatInsuranceCompanyForDropdown(company))),
		);
	}

	/**
	 * Returns an array to match the return of findActivePracticeInsuranceCompanies for combineLatest
	 */
	getPracticeInsuranceCompanyByPersonInsuranceId(personInsuranceId: number): Observable<FormattedPracticeInsuranceCompanyResponse[]> {
		return this.insuranceGandalfService.getPracticeInsuranceCompanyByPersonInsuranceId(personInsuranceId).pipe(
			map(company => [InsuranceService.formatInsuranceCompanyForDropdown(company)]),
		);
	}

	findActivePracticeFeeSchedules() {
		return this.insuranceGandalfService.findActivePracticeFeeSchedules().pipe(
			map(feeSchedules => SortingService.sortBy(feeSchedules, ['name', 'id'], ['asc', 'asc'])),
			map(feeSchedules => feeSchedules.map(feeSchedule => InsuranceService.formatFeeScheduleForDropdown(feeSchedule))),
		);
	}

	getActiveContactsInsuranceCoverages(patientId: number) {
		return this.insuranceGandalfService.findActivePoliciesForPatientContacts(patientId).pipe(
			map(coverages => coverages.map(coverage => this.formatCoverageInformation(coverage))),
		);
	}

	getActiveFamilyMembersInsuranceCoverages(patientId: number) {
		return this.insuranceGandalfService.findActivePoliciesForPatientFamilyMembers(patientId).pipe(
			map(coverages => coverages.map(coverage => this.formatCoverageInformation(coverage))),
		);
	}

	findContactsWithRoleByPatientId(patientId: number) {
		return this.insuranceGandalfService.findContactsWithRoleByPatientId(patientId).pipe(
			map(coverages => coverages.map(coverage => this.formatCoverageInformation(coverage))),
			map(coverages => SortingService.sortBy(coverages, ['formattedName'], ['asc'])),
		);
	}

	findFamilyMembersWithRoleByPatientId(patientId: number) {
		return this.insuranceGandalfService.findFamilyMembersWithRoleByPatientId(patientId).pipe(
			map(coverages => coverages.map(coverage => this.formatCoverageInformation(coverage))),
			map(coverages => SortingService.sortBy(coverages, ['formattedName'], ['asc'])),
		);
	}

	/* istanbul ignore next: gandalf */
	findActivePersonInsurances(personId: number) {
		return this.insuranceGandalfService.findActivePersonInsurancesByPersonId(personId);
	}

	@ShowSavedSuccessToast()
	createPersonInsurance(request: CreatePersonInsuranceRequest) {
		return this.insuranceGandalfService.createPersonInsurance(request);
	}

	@ShowSavedSuccessToast()
	updatePersonInsuranceAndPolicy(value: UpdatePersonInsuranceAndPolicyRequest) {
		return this.insuranceGandalfService.updatePersonInsuranceAndPolicy(value);
	}

	@ShowSavedSuccessToast()
	updatePersonInsurance(value: UpdatePersonInsuranceRequest) {
		return this.insuranceGandalfService.updatePersonInsurance(value);
	}

	@ShowSavedSuccessToast()
	activatePersonInsurance(personInsuranceId: number) {
		return this.insuranceGandalfService.activatePersonInsuranceById(personInsuranceId);
	}

	@ShowSavedSuccessToast()
	deactivatePersonInsurance(personInsuranceId: number) {
		return this.insuranceGandalfService.deactivatePersonInsuranceById(personInsuranceId);
	}

	formatCoverageInformation(coverage: PersonCoverageResponse) {
		const formattedCoverageResponse = coverage as FormattedPersonCoverageResponse;
		formattedCoverageResponse.formattedName = this.formattedNamePipe.transform(
			coverage.personNameResponse.firstName,
			coverage.personNameResponse.lastName,
		);
		return formattedCoverageResponse;
	}

	@ShowSavedSuccessToast()
	/* istanbul ignore next: gandalf */
	createPracticeInsuranceCompany(request: CreatePracticeInsuranceCompanyRequest) {
		return this.insuranceGandalfService.createPracticeInsuranceCompany(request);
	}

	findPracticePaymentMethods() {
		return this.insuranceGandalfService.findPaymentPreferences().pipe(
			map(preferences => preferences.paymentMethods),
			map(paymentMethods => SortingService.sortBy(paymentMethods, ['value'])),
		);
	}

	formatInsurancePolicy(policy: PersonInsuranceDetailsResponse) {
		const formattedInsuranceDetails = policy as FormattedPersonInsuranceDetails;
		formattedInsuranceDetails.phoneString = '';
		if (policy.insuranceCompanyPhones && policy.insuranceCompanyPhones.mainPhone) {
			formattedInsuranceDetails.phoneString = `Main: ${policy.insuranceCompanyPhones.mainPhone} `;
		}

		if (policy.insuranceCompanyPhones && policy.insuranceCompanyPhones.fax) {
			formattedInsuranceDetails.phoneString += `Fax: ${policy.insuranceCompanyPhones.fax}`;
		}

		if (policy.insuranceCompanyAddress) {
			formattedInsuranceDetails.cityStateZip = '';
			if (policy.insuranceCompanyAddress.city) {
				formattedInsuranceDetails.cityStateZip += policy.insuranceCompanyAddress.city;
			}
			if (policy.insuranceCompanyAddress.stateProvince) {
				if (formattedInsuranceDetails.cityStateZip) {
					formattedInsuranceDetails.cityStateZip += ', ';
				}
				formattedInsuranceDetails.cityStateZip += policy.insuranceCompanyAddress.stateProvince;
			}
			if (policy.insuranceCompanyAddress.postalCode) {
				if (formattedInsuranceDetails.cityStateZip) {
					formattedInsuranceDetails.cityStateZip += ' ';
				}
				formattedInsuranceDetails.cityStateZip += policy.insuranceCompanyAddress.postalCode;
			}
		}

		return formattedInsuranceDetails;
	}

	/* istanbul ignore next: gandalf */
	hasPatientPQRSInsurance(patientId) {
		return this.insuranceGandalfService.hasPatientPQRSInsurance(patientId);
	}

	findMostRecentIntakeByPatientId(patientId: number) {
		return this.insuranceGandalfService.findMostRecentIntakeByPatientId(patientId);
	}

	/* istanbul ignore next: gandalf */
	acceptIntakeInsurance(intakeInsuranceId: number) {
		return this.insuranceGandalfService.acceptIntakeInsurance(intakeInsuranceId);
	}

	/* istanbul ignore next: gandalf */
	getIntakeInsuranceByIdWithPatientFile(intakeInsuranceId: number) {
		return this.insuranceGandalfService.getIntakeInsuranceByIdWithPatientFile(intakeInsuranceId);
	}

	/* istanbul ignore next: gandalf */
	declineIntakeInsurance(intakeInsuranceId: number) {
		return this.insuranceGandalfService.declineIntakeInsurance(intakeInsuranceId);
	}

	/* istanbul ignore next: gandalf */
	getPersonInsuranceTooltip(patientId: number): Observable<FormattedPersonInsuranceTooltipResponse[]> {
		return this.insuranceGandalfService.getPersonInsuranceTooltip(patientId).pipe(
			map(insurances => insurances.map(insurance => this.formatInsuranceCompanyName(insurance))),
		);
	}

	formatInsuranceCompanyName(insurance: PersonInsuranceTooltipResponse): FormattedPersonInsuranceTooltipResponse {
		const formattedInsurance = insurance as FormattedPersonInsuranceTooltipResponse;

		formattedInsurance.formattedInsuranceCompanyName = (EnumUtil.equals(insurance.insuranceCompanyStatus, InsuranceCompanyStatus.INACTIVE)
			? '(Inactive Co.) '
			: ''
		) + insurance.insuranceCompanyName;

		return formattedInsurance;
	}
}
