import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { EnumUtil, ModalManagerService } from 'morgana';
import { ContactLensPrescriptionResponse } from '@gandalf/model/contact-lens-prescription-response';
import { ContactLensTrialResponse } from '@gandalf/model/contact-lens-trial-response';
import { ProductForPrescriptionResponse } from '@gandalf/model/product-for-prescription-response';
import { ContactLensPrescriptionDetailsService } from '@shared/component/contact-lens-prescription-details/contact-lens-prescription-details.service';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { ContactLensModality } from '@gandalf/constants';
import { ContactLensPrescriptionLookupModalComponent } from './contact-lens-prescription-lookup-modal/contact-lens-prescription-lookup-modal.component';

@Component({
	selector: 'pms-contact-lens-prescription-details',
	templateUrl: './contact-lens-prescription-details.component.html',
	styles: [
		`.eyedock-icon {
			display: inline-block;
			width: 18px;
			height: 18px;
			background-size: contain;
			background-image: url("assets/eyedock.png");
			margin-top: -3px;
		}`,
	],
})
export class ContactLensPrescriptionDetailsComponent implements OnInit, OnChanges {

	@Input()
	prescriptionId: number;

	@Input()
	trialId: number;

	@Input()
	isTrialOrder: boolean;

	@Input()
	personId: number;

	@Input()
	showViewRxHistoryButton: boolean;

	@Input()
	showAdditionalInfo = false;

	@Output()
	prescriptionChanged = new EventEmitter();

	@Output()
	trialChanged = new EventEmitter();

	contactLensPrescription: ContactLensPrescriptionResponse | ContactLensTrialResponse;
	dateFormat = DATE_FORMATS.MM_DD_YYYY;

	constructor(
		private contactLensPrescriptionDetailsService: ContactLensPrescriptionDetailsService,
		public modalManagerService: ModalManagerService,
	) {
	}

	ngOnInit() {
		if (!_isNil(this.prescriptionId)) {
			this.refreshPrescription();
		}

		if (!_isNil(this.trialId)) {
			this.refreshTrial();
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (!_isNil(changes)) {
			if (
				changes.prescriptionId
				&& !changes.prescriptionId.firstChange
				&& changes.prescriptionId.currentValue !== changes.prescriptionId.previousValue
				&& !_isNil(changes.prescriptionId.currentValue)
			) {
				this.refreshPrescription();
			} else if (
				changes.trialId
				&& !changes.trialId.firstChange
				&& changes.trialId.currentValue !== changes.trialId.previousValue
				&& !_isNil(changes.trialId.currentValue)
			) {
				this.refreshTrial();
			}
		}
	}

	refreshPrescription() {
		this.trialId = null;
		this.contactLensPrescriptionDetailsService.getPrescriptionById(this.prescriptionId).subscribe((data) => {
			this.contactLensPrescription = data;
		});
	}

	refreshTrial() {
		this.prescriptionId = null;
		this.contactLensPrescriptionDetailsService.getTrialById(this.trialId).subscribe((data) => {
			this.contactLensPrescription = data;
		});
	}

	getRxType() {
		return this.contactLensPrescriptionDetailsService.getRxType(this.contactLensPrescription as ContactLensPrescriptionResponse);
	}

	getAuthByName() {
		return this.contactLensPrescriptionDetailsService.getAuthByName(this.contactLensPrescription as ContactLensPrescriptionResponse);
	}

	isOverallLensTypeSoft() {
		return this.contactLensPrescriptionDetailsService.isOverallLensTypeSoft(this.contactLensPrescription);
	}

	isOverallLensTypeRgp() {
		return this.contactLensPrescriptionDetailsService.isOverallLensTypeRgp(this.contactLensPrescription);
	}

	isOverallLensTypeHybrid() {
		return this.contactLensPrescriptionDetailsService.isOverallLensTypeHybrid(this.contactLensPrescription);
	}

	isContactLensTrial() {
		return !_isNil(this.trialId);
	}

	viewRxHistory() {
		this.modalManagerService.open(ContactLensPrescriptionLookupModalComponent, {
			data: {
				personId: this.personId,
				isTrialOrder: this.isTrialOrder,
			},
		}).onClose.subscribe((response) => {
			if (response && response.prescriptionId) {
				this.prescriptionChanged.emit(response.prescriptionId);
			} else if (response && response.trialId) {
				this.trialChanged.emit(response.trialId);
			}
		});
	}

	createEyedockUrl(product: ProductForPrescriptionResponse) {
		return this.contactLensPrescriptionDetailsService.createEyedockUrl(product, this.contactLensPrescription.overallLensType);
	}

	canShowEyedockButton(product: ProductForPrescriptionResponse) {
		return this.contactLensPrescriptionDetailsService.canShowEyedockButton(product);
	}

	get contactLensPrescriptionNoTrial() {
		return this.contactLensPrescription instanceof ContactLensPrescriptionResponse
			? this.contactLensPrescription
			: undefined;
	}

	getOvernightScheduleAmount(isOd: boolean): string {
		const contactLensEye = this.contactLensPrescriptionDetailsService.getContactLensPrescriptionEye(this.contactLensPrescription, isOd);
		if (_isNil(contactLensEye?.overnightSchedule?.amount) || contactLensEye?.overnightSchedule?.amount === 0) {
			return '';
		} else {
			return `${contactLensEye.overnightSchedule.amount} ${contactLensEye.overnightSchedule.durationUnit}`;
		}
	}

	getReplacementScheduleAmount(isOd: boolean): string {
		const contactLensEye = this.contactLensPrescriptionDetailsService.getContactLensPrescriptionEye(this.contactLensPrescription, isOd);
		if (_isNil(contactLensEye?.replacementSchedule?.amount) || contactLensEye?.replacementSchedule?.amount === 0) {
			return '';
		} else {
			return `${contactLensEye.replacementSchedule.amount} ${contactLensEye.replacementSchedule.durationUnit}`;
		}
	}

	isModalityNotUnknown(modality: ContactLensModality) {
		return !EnumUtil.equals(modality, ContactLensModality.UNKNOWN);
	}
}
