// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CarePlanItemTemplateResponse } from './care-plan-item-template-response';

// @ts-ignore
import { LoincCodeResponse } from './loinc-code-response';

/** See com.rev360.pms.api.controller.admin.dataconfiguration.careplan.CarePlanItemTemplateLabOrderResponse */
/* istanbul ignore next */
@GandalfModel
export class CarePlanItemTemplateLabOrderResponse extends CarePlanItemTemplateResponse {

	@GandalfProperty()
	carePlanItemTemplateId: number;

	@GandalfProperty()
	description: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	endDateRangeUnit: constants.CarePlanItemLabOrderDuration;

	@GandalfProperty()
	endDateRangeValue: number;

	@GandalfProperty()
	isCheckoutTask: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	labType: constants.LabOrderLabType;

	@GandalfProperty()
	loinc: LoincCodeResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	resultType: constants.LabOrderResultType;

	@GandalfConstantDecorator()
	@GandalfProperty()
	startDateRangeUnit: constants.CarePlanItemLabOrderDuration;

	@GandalfProperty()
	startDateRangeValue: number;

}
