import { ElementRef, Injectable } from '@angular/core';
import {
	DefaultErrorToastModel,
	DefaultInfoToastModel,
	DefaultSuccessToastModel,
	FeatureFlagModificationToastModel,
	DefaultNotificationToastModel,
	DefaultWarningToastModel
} from '@core/toaster/toaster.constants';
import { Toast, ToastModel } from '@syncfusion/ej2-notifications';

@Injectable({
	providedIn: 'root',
})
export class ToasterService {
	private successElement: ElementRef;
	private errorElement: ElementRef;
	private infoElement: ElementRef;
	private warningElement: ElementRef;
	private featureFlagModificationElement: ElementRef;
	defaultNotificationElement: ElementRef;
	toastElement: ElementRef;
	defaultNotificationToast: Toast;


	constructor() {
	}

	showSavedSuccess(overrides?: ToastModel) {
		const toastModel = this.mergeToastModel(DefaultSuccessToastModel, overrides);
		this.showToast(toastModel, this.successElement);
	}

	showError(overrides?: ToastModel) {
		const toastModel = this.mergeToastModel(DefaultErrorToastModel, overrides);
		this.showToast(toastModel, this.errorElement);
	}

	showInfo(overrides?: ToastModel) {
		const toastModel = this.mergeToastModel(DefaultInfoToastModel, overrides);
		this.showToast(toastModel, this.infoElement);
	}

	showWarning(overrides?: ToastModel) {
		const toastModel = this.mergeToastModel(DefaultWarningToastModel, overrides);
		this.showToast(toastModel, this.warningElement);
	}

	showFeatureFlagModificationToast(overrides?: ToastModel) {
		const toastModel = this.mergeToastModel(FeatureFlagModificationToastModel, overrides);
		this.showToast(toastModel, this.featureFlagModificationElement);
	}

	showDefaultNotificationToast(overrides?: ToastModel) {
		const toastModel = this.mergeToastModel(DefaultNotificationToastModel, overrides);
		this.defaultNotificationToast = this.showToast(toastModel, this.defaultNotificationElement);
	}

	showDefaultToast(overrides?: ToastModel) {
		const toastModel = this.mergeToastModel(DefaultInfoToastModel, overrides);
		return this.showToast(toastModel, this.toastElement);
	}

	setSuccessToastElement(element: ElementRef) {
		this.successElement = element;
	}

	setErrorToastElement(element: ElementRef) {
		this.errorElement = element;
	}

	setInfoToastElement(element: ElementRef) {
		this.infoElement = element;
	}

	setWarningToastElement(element: ElementRef) {
		this.warningElement = element;
	}

	setFeatureFlagModificationToastElement(element: ElementRef) {
		this.featureFlagModificationElement = element;
	}

	setDefaultNotificationToastElement(element: ElementRef) {
		this.defaultNotificationElement = element;
	}

	setToastElement(element: ElementRef) {
		this.toastElement = element;
	}

	mergeToastModel(toastModel: ToastModel, overrides?: ToastModel) {
		return overrides ? Object.assign({}, toastModel, overrides) : toastModel;
	}

	showToast(toastModel: ToastModel, element: ElementRef) {
		if (element) {
			const newToast: Toast = new Toast(toastModel, element.nativeElement);
			newToast.show();
			return newToast;
		}
		return;
	}

	hideDefaultNotificationToast() {
		if (this.defaultNotificationToast && this.defaultNotificationElement) {
			this.defaultNotificationToast.hide(this.defaultNotificationElement.nativeElement);
		}
	}
}

