// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.order.OrdersSearchResponse */
/* istanbul ignore next */
@GandalfModel
export class OrdersSearchResponse extends GandalfModelBase {

	@GandalfProperty()
	comment: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	createdOn: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dueDate: Date;

	@GandalfProperty()
	masterProcessorId: number;

	@GandalfProperty()
	masterProcessorName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	nextWorkflowStatus: constants.OrderStatusCode;

	@GandalfProperty()
	notificationComments: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	notificationDate: Date;

	@GandalfProperty()
	onHold: boolean;

	@GandalfProperty()
	orderId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	orderStatusCode: constants.OrderStatusCode;

	@GandalfConstantDecorator()
	@GandalfProperty()
	orderType: constants.OrderType;

	@GandalfProperty()
	patientName: PatientNameResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	shipToType: constants.OrderShipToType;

	@GandalfProperty({ propertyType: 'LocalDate' })
	statusDate: Date;

	@GandalfProperty()
	vendorId: number;

	@GandalfProperty()
	vendorName: string;

	@GandalfProperty()
	vendorWebSite: string;

}
