import { Component, Input, OnInit } from '@angular/core';
import { TABLE_DATE_FORMATS } from '@shared/constants/date-format.constants';
import { SortSettingsModel } from '@syncfusion/ej2-angular-grids';
import { StatementInvoiceResponse } from '@gandalf/model/statement-invoice-response';
import { AccountingService } from '../../../core/accounting/accounting.service';

@Component({
	selector: 'pms-invoice-statement-history',
	templateUrl: './invoice-statement-history.component.html',
	styles: [],
})
export class InvoiceStatementHistoryComponent implements OnInit {

	@Input()
	invoiceId: number;

	invoiceStatements: StatementInvoiceResponse[];
	tableDateFormat = TABLE_DATE_FORMATS.MM_DD_YYYY;
	sortOptions: SortSettingsModel;

	constructor(
		private accountingService: AccountingService,
	) {
	}

	ngOnInit() {
		this.getInvoiceStatements();
		this.sortOptions = {
			columns: [{
				field: 'printDate',
				direction: 'Descending',
			}],
		};
	}

	getInvoiceStatements() {
		this.accountingService.findInvoiceStatementsByInvoiceId(this.invoiceId).subscribe((invoiceStatements) => {
			this.invoiceStatements = invoiceStatements;
		});
	}

}
