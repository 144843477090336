import { Injectable } from '@angular/core';
import { EnumUtil, SortingService } from 'morgana';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { CarePlanItemCategory, QueryTemplateCategory } from '@gandalf/constants';
import { CreateQueryTriggerRequest } from '@gandalf/model/create-query-trigger-request';
import { FindQueryTriggersRequest } from '@gandalf/model/find-query-triggers-request';
import { QueryTriggerCarePlanItemTemplateResponse } from '@gandalf/model/query-trigger-care-plan-item-template-response';
import { QueryTriggerCarePlanTemplateResponse } from '@gandalf/model/query-trigger-care-plan-template-response';
import { QueryTriggerResponse } from '@gandalf/model/query-trigger-response';
import { UpdateQueryTriggerRequest } from '@gandalf/model/update-query-trigger-request';
import { QueryTriggerGandalfService } from '@gandalf/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class QueryTriggerSearchResult {
	uid: string;
	type: string;
	description: string;
	carePlanTemplate: QueryTriggerCarePlanTemplateResponse;
	carePlanItemTemplate: QueryTriggerCarePlanItemTemplateResponse;
}

@Injectable({
	providedIn: 'root',
})
export class QueryTriggerService {

	constructor(
		public queryTriggerGandalfService: QueryTriggerGandalfService,
	) {
	}

	findByTemplateCategory(templateCategory: QueryTemplateCategory) {
		const request = new FindQueryTriggersRequest();
		request.templateCategory = templateCategory;
		return this.queryTriggerGandalfService.findPracticeQueryTriggersByTemplateCategory(request).pipe(
			map(queries => SortingService.sortBy(queries, ['id'], ['asc'])),
		);
	}

	/* istanbul ignore next: gandalf */
	activate(queryTriggerId: number) {
		return this.queryTriggerGandalfService.activate(queryTriggerId);
	}

	/* istanbul ignore next: gandalf */
	deactivate(queryTriggerId: number) {
		return this.queryTriggerGandalfService.deactivate(queryTriggerId);
	}

	findCarePlanTemplates(): Observable<QueryTriggerCarePlanTemplateResponse[]> {
		return this.queryTriggerGandalfService.findCarePlanTemplates().pipe(
			map(templates => SortingService.sortBy(templates, ['name', 'id'], ['asc', 'asc'])),
		);
	}

	findCarePlanItemTemplates(): Observable<QueryTriggerCarePlanItemTemplateResponse[]> {
		return this.queryTriggerGandalfService.findCarePlanItemTemplates().pipe(
			map(itemTemplates => this.filterCarePlanItemTemplates(itemTemplates)),
			map(itemTemplates => SortingService.sortBy(itemTemplates, ['description', 'id'], ['asc', 'asc'])),
		);
	}

	filterCarePlanItemTemplates(itemTemplates: QueryTriggerCarePlanItemTemplateResponse[]) {
		// cannot add CQM items to a query trigger
		return itemTemplates.filter(item => !EnumUtil.equals(item.category, CarePlanItemCategory.CQM));
	}

	buildCarePlanTemplateSearchResult(carePlanTemplate: QueryTriggerCarePlanTemplateResponse) {
		const searchResult = new QueryTriggerSearchResult();
		searchResult.uid = `cpt-${carePlanTemplate.id}`;
		searchResult.type = 'Template';
		searchResult.description = carePlanTemplate.name;
		searchResult.carePlanTemplate = carePlanTemplate;
		return searchResult;
	}

	buildCarePlanItemTemplateSearchResult(carePlanItemTemplate: QueryTriggerCarePlanItemTemplateResponse) {
		const searchResult = new QueryTriggerSearchResult();
		searchResult.uid = `cpit-${carePlanItemTemplate.id}`;
		searchResult.type = carePlanItemTemplate.category.label;
		searchResult.description = carePlanItemTemplate.description;
		searchResult.carePlanItemTemplate = carePlanItemTemplate;
		return searchResult;
	}

	sortSearchResults(searchResults: QueryTriggerSearchResult[]) {
		return SortingService.sortBy(searchResults, ['description', 'uid'], ['asc', 'asc']);
	}

	/* istanbul ignore next: gandalf */
	@ShowSavedSuccessToast()
	createQueryTrigger(request: CreateQueryTriggerRequest) {
		return this.queryTriggerGandalfService.createQueryTrigger(request);
	}

	/* istanbul ignore next: gandalf */
	@ShowSavedSuccessToast()
	updateQueryTrigger(request: UpdateQueryTriggerRequest) {
		return this.queryTriggerGandalfService.updateQueryTrigger(request);
	}

	/* istanbul ignore next: gandalf */
	getQueryTriggerById(queryTriggerId: number) {
		return this.queryTriggerGandalfService.getQueryTriggerById(queryTriggerId);
	}

	buildQueryTriggerSearchResults(trigger: QueryTriggerResponse) {
		return this.sortSearchResults([
			...trigger.carePlanTemplates.map(template => this.buildCarePlanTemplateSearchResult(template)),
			...trigger.carePlanItemTemplates.map(item => this.buildCarePlanItemTemplateSearchResult(item)),
		]);
	}
}
