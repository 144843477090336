// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.practicepreferences.ConnectPracticeResponse */
/* istanbul ignore next */
@GandalfModel
export class ConnectPracticeResponse extends GandalfModelBase {

	@GandalfProperty()
	businessName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	connectBusinessType: constants.ConnectBusinessType;

	@GandalfConstantDecorator()
	@GandalfProperty()
	connectCompanyType: constants.ConnectCompanyType;

	@GandalfProperty()
	connectFein: string;

	@GandalfProperty()
	connectPracticeId: number;

	@GandalfProperty()
	connectWebsite: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	stockExchange: constants.StockExchange;

	@GandalfProperty()
	stockTicker: string;

}
