import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { AddressResponse } from '@gandalf/model/address-response';

/**
 * @ngdoc pipe
 * @description
 * Angular pipe for displaying a formatted street address
 *
 * @param address - an AddressResponse
 * @usage
 * {{ address | formattedSingleLineAddress }}
 */

@Pipe({
	name: 'formattedSingleLineAddress',
})
@Injectable({
	providedIn: 'root',
})
export class FormattedSingleLineAddressPipe implements PipeTransform {
	isEmpty(value: any, type?: string): boolean {
		return (typeof value === 'undefined'
			|| value === null || value === '' || (type === 'int' && isNaN(parseInt(value, 10))) || (type === 'float' && isNaN(parseFloat(value))));
	}

	transform(address: AddressResponse): string {
		if (_isNil(address)) {
			return address;
		}

		let formattedAddress = '';

		if (!this.isEmpty(address.address1)) {
			formattedAddress = formattedAddress + address.address1;
		}

		if (!this.isEmpty(address.address2)) {
			if (formattedAddress !== '') {
				formattedAddress = formattedAddress + ', ';
			}
			formattedAddress =  formattedAddress + address.address2;
		}

		if (!this.isEmpty(address.city)) {
			if (formattedAddress !== '') {
				formattedAddress = formattedAddress + ', ';
			}
			formattedAddress =  formattedAddress + address.city;
		}

		if (!this.isEmpty(address.stateProvince)) {
			if (formattedAddress !== '') {
				formattedAddress = formattedAddress + ', ';
			}
			formattedAddress = formattedAddress + address.stateProvince;
		}
		if (!this.isEmpty(address.postalCode)) {
			formattedAddress = formattedAddress + ' ' + address.postalCode;
		}

		return formattedAddress.trim();
	}

}
