<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-sm"
	[attr.data-test-id]="'updateDirectoryModal'">
	<ng-template #header>
		<div class="dlg-template" [attr.data-test-id]="'updateDirectoryModalTitle'">{{ isUpdate ? 'Rename' : 'Add' }} Folder</div>
	</ng-template>
	<ng-template #content>
		<form class="form-horizontal"
			  [formGroup]="componentForm"
			  (ngSubmit)="addOrUpdateDirectory()"
			  #templateForm="ngForm">
			@if (templateForm.invalid && templateForm.submitted) {
				<gandalf-lib-validation-messages
					[form]="componentForm"
					[requestObj]="request"
					[attr.data-test-id]="'updateDirectoryModalValidationMessages'">
				</gandalf-lib-validation-messages>
			}
			<div class="form-group">
				<label class="col-sm-3 control-label required" [attr.data-test-id]="'updateDirectoryModalNameLabel'">Name</label>
				<div class="col-sm-9">
					<ejs-textbox formControlName="name" [revFocusInput]="true" [highlightInputOnFocus]="true"></ejs-textbox>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button type="button" iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'updateDirectoryModalCancelButton'">Cancel</button>
		<button ejs-button type="submit" [isPrimary]="true" (click)="submitForm($event)" [attr.data-test-id]="'updateDirectoryModalSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>
