import { Directive, HostListener, OnDestroy, OnInit } from '@angular/core';
import { _escape, _find, _get, _isNil } from '@core/lodash/lodash';
import { Tooltip, TooltipService } from 'morgana';
import { FieldSettingsModel, MultiSelectComponent } from '@syncfusion/ej2-angular-dropdowns';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
	/* eslint-disable-next-line @angular-eslint/directive-selector */
	selector: 'ejs-multiselect:not([pmsOverrideMultiselectTooltip])',
})
export class MultiselectTooltipDirective implements OnInit, OnDestroy {

	tooltip: Tooltip;
	unsubscribe: Subject<void> = new Subject<void>();

	constructor(
		private multiselectDropdown: MultiSelectComponent,
		private tooltipService: TooltipService,
	) {
	}

	ngOnInit() {
		this.initialize();
	}

	initialize() {
		this.multiselectDropdown.valueChange.pipe(takeUntil(this.unsubscribe)).subscribe(this.onChange);
	}

	onChange = (value: any[]) => {
		if ((_isNil(value) || value.length === 0)) {
			if (!_isNil(this.tooltip)) {
				this.tooltip.instance.destroy();
				this.tooltip = null;
			}
			return;
		}

		if (this.tooltip) {
			this.tooltip.setContent(this.getLabelFromValues());
		} else {
			this.createTooltip();
		}
	};

	@HostListener('created')
	onCreated() {
		if (!_isNil(this.multiselectDropdown.value) && this.multiselectDropdown.value.length > 0) {
			this.createTooltip();
		}
	}

	createTooltip() {
		this.tooltip = this.tooltipService.buildTooltip(this.getLabelFromValues(), this.multiselectDropdown.element, null, {}, true);
	}

	getLabelFromValues() {
		const labels = [];
		const fields: FieldSettingsModel = this.multiselectDropdown.fields;
		const values = this.multiselectDropdown.value as [];

		(this.multiselectDropdown.dataSource as []).forEach((option) => {
			const optionValue = _get(option, fields.value);
			const item = _find(values, value => optionValue === value);
			if (item) {
				labels.push(_escape(option[fields.text]));
			}
		});

		return labels.join('<br>');
	}

	closeSubscriptions() {
		if (!this.unsubscribe.closed) {
			this.unsubscribe.next();
			this.unsubscribe.complete();
		}
	}

	ngOnDestroy() {
		this.closeSubscriptions();
		if (!_isNil(this.tooltip)) {
			this.tooltip.instance.destroy();
		}
	}
}
