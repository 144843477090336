import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { AlertDisplayType, AlertStatus } from '@gandalf/constants';
import { AlertResponse } from '@gandalf/model/alert-response';
import { ALERTS_MODAL_CONSTANTS } from '@shared/constants/alerts-modal.constants';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
	selector: 'pms-adjust-quantity',
	templateUrl: './alerts-modal.component.html',
	styles: [],
})
export class AlertsModalComponent implements OnInit {

	@ViewChild('grid')
	grid: GridComponent;
	@ViewChild('modal')
	modal: DialogComponent;
	modalType: ALERTS_MODAL_CONSTANTS;
	patientAlerts: AlertResponse[];
	modalFiltered = true;
	modalTypes = ALERTS_MODAL_CONSTANTS;

	constructor(
		public dynamicModalRef: DynamicModalRef,
		public modalConfig: ModalConfig,
	) {
	}

	ngOnInit() {
		this.modalType = this.modalConfig.data.type;
		this.patientAlerts = this.modalConfig.data.alerts;
	}

	applyFilter() {
		if (this.modalType === ALERTS_MODAL_CONSTANTS.DISPLAY_TYPE) {
			this.filterTableByModalDisplayType();
		}

		if (this.modalType === ALERTS_MODAL_CONSTANTS.STATUS) {
			this.filterTableByActiveStatus();
		}
	}

	filterAlertsByModalDisplayType(event) {
		event.checked ? this.filterTableByModalDisplayType() : this.grid.clearFiltering();
	}

	filterAlertsByActiveStatus(event) {
		event.checked ? this.filterTableByActiveStatus() : this.grid.clearFiltering();
	}

	filterTableByModalDisplayType() {
		this.grid.filterByColumn('displayType.label', 'equal', AlertDisplayType.MODAL.label);
	}

	filterTableByActiveStatus() {
		this.grid.filterByColumn('status.label', 'equal', AlertStatus.ACTIVE.label);
	}

	closeDialog() {
		this.dynamicModalRef.close(this.modal);
	}
}
