// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FormComponentPositionResponse } from './form-component-position-response';

/** See com.rev360.pms.api.controller.encounter.dynamic.form.FormComponentResponse */
/* istanbul ignore next */
@GandalfModel
export class FormComponentResponse extends GandalfModelBase {

	@GandalfProperty()
	position: FormComponentPositionResponse;

	@GandalfProperty()
	uiComponentId: number;

}
