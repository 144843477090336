import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { TypeSafeModalManagerService } from 'morgana';
import { OrderType } from '@gandalf/constants';
import { EyeglassPrescriptionResponse } from '@gandalf/model/eyeglass-prescription-response';
import { PatientNameResponse } from '@gandalf/model/patient-name-response';
import { BaseComponent } from '@shared/component/base.component';
import { EyeglassLensRxService } from '@shared/component/eyeglass-lens-rx-container/eyeglass-lens-rx.service';
import { RxSelectModalComponent, RxSelectReturnValue } from '@shared/component/rx-select-modal/rx-select-modal.component';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'pms-eyeglass-lens-rx-container',
	templateUrl: './eyeglass-lens-rx-container.component.html',
	providers: [TypeSafeModalManagerService],
})
export class EyeglassLensRxContainerComponent extends BaseComponent implements OnInit {

	@Input()
	set prescriptionId(prescriptionId: number) {
		if (!_isNil(prescriptionId)) {
			this.eyeglassLensRxService.getPrescriptionById(prescriptionId).subscribe(eyeglassRx => {
				this.eyeglassPrescription = eyeglassRx;
			});
		} else {
			this.eyeglassPrescription = null;
		}
	}

	@Input()
	patient: PatientNameResponse;

	@Input()
	showViewRxHistoryButton: boolean;

	@Output()
	rxSelected = new EventEmitter<number>();

	eyeglassPrescription: EyeglassPrescriptionResponse;

	constructor(
		public eyeglassLensRxService: EyeglassLensRxService,
		public typeSafeModalManagerService: TypeSafeModalManagerService,
	) {
		super();
	}

	ngOnInit() {
		this.eyeglassLensRxService.getOpenRxSelectModalSubject().pipe(takeUntil(this.unsubscribe)).subscribe(() => {
			this.openRxSelectModal();
		});
	}

	openRxSelectModal() {
		this.typeSafeModalManagerService.open(RxSelectModalComponent, {
			orderType: OrderType.EYEGLASS,
			patientName: this.patient,
			hasPrevious: false,
		}).onClose.subscribe((rxSelectReturnValue: RxSelectReturnValue) => {
			if (rxSelectReturnValue && rxSelectReturnValue.rxId) {
				this.rxSelected.emit(rxSelectReturnValue.rxId);
			}
		});
	}

}
