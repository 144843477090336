import { TabAnimationSettingsModel } from '@syncfusion/ej2-angular-navigations';

export const TabAnimationDefaults: TabAnimationSettingsModel = {
	next: {
		effect: 'None',
	},
	previous: {
		effect: 'None',
	},
};
