import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { InvoiceResponse } from '@gandalf/model/invoice-response';
import { TransferInvoiceBalanceToPatientRequest } from '@gandalf/model/transfer-invoice-balance-to-patient-request';
import { PatientNameResponse } from '@gandalf/model/patient-name-response';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';
import { AccountingService } from '../../core/accounting/accounting.service';
import { InvoiceService } from '../../core/accounting/invoice-service/invoice.service';

@Component({
	selector: 'pms-transfer-collections-invoice-modal',
	templateUrl: './transfer-collections-invoice-modal.component.html',
	styles: [],
})

export class TransferCollectionsInvoiceModalComponent implements OnInit {
	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('templateForm')
	templateForm: NgForm;

	invoiceId: number;
	invoice: InvoiceResponse;
	patientName: PatientNameResponse;
	transferInvoiceBalanceToPatientRequest: TransferInvoiceBalanceToPatientRequest;
	componentForm: UntypedFormGroup;

	constructor(
		private ref: DynamicModalRef,
		private modalConfig: ModalConfig,
		private accountingService: AccountingService,
		private gandalfFormBuilder: GandalfFormBuilder,
		private invoiceService: InvoiceService,
	) {
	}

	ngOnInit() {
		this.parseModalConfig(this.modalConfig.data);
		this.populateModal();
		this.initializeForm();
	}

	parseModalConfig(data: any) {
		this.invoiceId = data.invoiceId;
	}

	prepareInitialRequest(): TransferInvoiceBalanceToPatientRequest {
		const request = new TransferInvoiceBalanceToPatientRequest();
		request.invoiceId = this.invoiceId;
		return request;
	}

	initializeForm() {
		this.transferInvoiceBalanceToPatientRequest = this.prepareInitialRequest();
		this.componentForm = this.gandalfFormBuilder.group(this.transferInvoiceBalanceToPatientRequest);
	}

	closeModal() {
		this.ref.close(this.modal);
	}

	populateModal() {
		this.accountingService.getInvoiceById(this.invoiceId).subscribe(invoice => {
			this.invoice = invoice;
		});
	}

	submitForm(event) {
		this.templateForm.onSubmit(event);
	}

	save() {
		if (this.componentForm.invalid) {
			return;
		}
		const request = this.componentForm.value as TransferInvoiceBalanceToPatientRequest;
		this.accountingService.transferInvoiceToPatient(request).subscribe(data => {
			this.invoiceService.refreshInvoice(data.newInvoiceId, this.invoice.patientId);
			this.invoiceService.refreshInvoice(data.oldInvoiceId, this.invoice.patientId);
			this.closeModal();
		});
	}
}
