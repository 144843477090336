import { Injectable } from '@angular/core';
import { PracticeLocationLabelPrinterGandalfService } from '@gandalf/services';

@Injectable({
	providedIn: 'root',
})
export class PracticeLocationLabelPrinterService {

	constructor(
		private practiceLocationLabelPrinterGandalfService: PracticeLocationLabelPrinterGandalfService,
	) {
	}

	findDefaultPrinterByPracticeLocationId(locationId: number) {
		return this.practiceLocationLabelPrinterGandalfService.findDefaultPrinterByPracticeLocationId(locationId);
	}
}
