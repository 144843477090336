import { Injectable } from '@angular/core';
import { _assign, _filter, _isNil, _map } from '@core/lodash/lodash';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { CodeSet, EncounterAutoDiagnosisStatus } from '@gandalf/constants';
import { AssessmentAutoDiagnosisResponse } from '@gandalf/model/assessment-auto-diagnosis-response';
import { AssessmentPracticeDiagnosisResponse } from '@gandalf/model/assessment-practice-diagnosis-response';
import { CreateDiagnosisReviewHistoryRequest } from '@gandalf/model/create-diagnosis-review-history-request';
import { CreatePersonDiagnosisRequest } from '@gandalf/model/create-person-diagnosis-request';
import { DiagnosisSearchRequest } from '@gandalf/model/diagnosis-search-request';
import { DiagnosisSearchResponse } from '@gandalf/model/diagnosis-search-response';
import { FindPracticeDiagnosesRequest } from '@gandalf/model/find-practice-diagnoses-request';
import { PersonDiagnosisResponse } from '@gandalf/model/person-diagnosis-response';
import { PracticeDiagnosisResponse } from '@gandalf/model/practice-diagnosis-response';
import { ResolvePersonDiagnosisRequest } from '@gandalf/model/resolve-person-diagnosis-request';
import { SearchActivePracticeDiagnosesForAssessmentRequest } from '@gandalf/model/search-active-practice-diagnoses-for-assessment-request';
import { SearchPracticeDiagnosesRequest } from '@gandalf/model/search-practice-diagnoses-request';
import { UpdatePersonDiagnosisLocationAndQualifierRequest } from '@gandalf/model/update-person-diagnosis-location-and-qualifier-request';
import { UpdatePersonDiagnosisRequest } from '@gandalf/model/update-person-diagnosis-request';
import {
	AdminDiagnosisGandalfService,
	AssessmentDiagnosisReviewHistoryGandalfService,
	InfoButtonGandalfService,
	PersonDiagnosisGandalfService
} from '@gandalf/services';
import { EncounterDiagnosisLocationPipe } from '@shared/pipes/diagnosis/encounter-diagnosis-location/encounter-diagnosis-location.pipe';
import { EnumUtil, OptionItem, SortingService } from 'morgana';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface SortablePersonDiagnosisResponse extends PersonDiagnosisResponse {
	codeSetOrder: number;
}

export class PracticeDiagnosesWithCodeDescription extends PracticeDiagnosisResponse {
	codeDescription: string;
}

@Injectable({
	providedIn: 'root',
})
export class DiagnosisService {

	constructor(
		private diagnosisGandalfService: AdminDiagnosisGandalfService,
		private assessmentDiagnosisReviewHistoryGandalfService: AssessmentDiagnosisReviewHistoryGandalfService,
		private personDiagnosisGandalfService: PersonDiagnosisGandalfService,
		private diagnosisLocationPipe: EncounterDiagnosisLocationPipe,
		private infoButtonGandalfService: InfoButtonGandalfService,
	) {
	}

	/* istanbul ignore next: gandalf */
	resolveDiagnosis(resolvePersonDiagnosisRequest: ResolvePersonDiagnosisRequest) {
		return this.personDiagnosisGandalfService.resolvePersonDiagnosis(resolvePersonDiagnosisRequest);
	}

	/*istanbul ignore next: gandalf*/
	@ShowSavedSuccessToast()
	createPersonDiagnosis(request: CreatePersonDiagnosisRequest) {
		return this.personDiagnosisGandalfService.createPersonDiagnosis(request);
	}

	/*istanbul ignore next: gandalf*/
	@ShowSavedSuccessToast()
	updatePersonDiagnosis(request: UpdatePersonDiagnosisRequest) {
		return this.personDiagnosisGandalfService.updatePersonDiagnosis(request);
	}

	/*istanbul ignore next: gandalf*/
	@ShowSavedSuccessToast()
	updatePersonDiagnosisLocationAndQualifier(request: UpdatePersonDiagnosisLocationAndQualifierRequest) {
		return this.personDiagnosisGandalfService.updatePersonDiagnosisLocationAndQualifier(request);
	}

	findMasterDiagnoses(request: DiagnosisSearchRequest): Observable<DiagnosisSearchResponse[]> {
		return this.diagnosisGandalfService.findMasterDiagnoses(request).pipe(
			map(diagnosisResponses => SortingService.sortBy(diagnosisResponses, ['code'], ['asc'])),
		);
	}

	/*istanbul ignore next: gandalf*/
	getPersonDiagnosisById(personDiagnosisId: number) {
		return this.personDiagnosisGandalfService.getPersonDiagnosis(personDiagnosisId);
	}

	/*istanbul ignore next: gandalf*/
	findActivePracticeDiagnoses(request: FindPracticeDiagnosesRequest) {
		return this.personDiagnosisGandalfService.findActivePracticeDiagnoses(request);
	}

	/*istanbul ignore next: gandalf*/
	findMostRecentDiagnosisReviewByPatientId(patientId: number) {
		return this.personDiagnosisGandalfService.findMostRecentDiagnosisReviewByPatientId(patientId);
	}

	@ShowSavedSuccessToast()
	createDiagnosisReview(request: CreateDiagnosisReviewHistoryRequest) {
		return this.personDiagnosisGandalfService.createDiagnosisReview(request);
	}

	searchActivePracticeDiagnoses(request: SearchPracticeDiagnosesRequest) {
		return this.personDiagnosisGandalfService.searchActivePracticeDiagnoses(request).pipe(
			map(diagnosisResponses => SortingService.sortBy(diagnosisResponses, ['code', 'id'], ['asc', 'asc'])),
			map(practiceDiagnoses => practiceDiagnoses.map(practiceDiagnosis => this.formatPracticeDiagnosisForDropdown(practiceDiagnosis))),
		);
	}

	searchPracticeDiagnosesByEncounterId(request: SearchActivePracticeDiagnosesForAssessmentRequest): Observable<PracticeDiagnosesWithCodeDescription[]> {
		return this.assessmentDiagnosisReviewHistoryGandalfService.searchActivePracticeDiagnoses(request).pipe(
			map(diagnosisResponses => SortingService.sortBy(diagnosisResponses, ['code', 'id'], ['asc', 'asc'])),
			map(practiceDiagnoses => practiceDiagnoses.map(practiceDiagnosis => this.formatPracticeDiagnosisForDropdown(practiceDiagnosis))),
		);
	}

	findPersonDiagnosesForDropdown(patientId: number): Observable<OptionItem[]> {
		return this.personDiagnosisGandalfService.findPersonDiagnosesByPatientId(patientId)
			.pipe(
				map((responses: PersonDiagnosisResponse[]) => _map(responses, response => this.createSortablePersonDiagnosisResponse(response))),
				map((diagnoses: SortablePersonDiagnosisResponse[]) => this.sortDiagnoses(diagnoses)),
				map((diagnoses: SortablePersonDiagnosisResponse[]) => diagnoses.map(diagnosis => this.createDiagnosisOptionItem(diagnosis))),
			);
	}

	createSortablePersonDiagnosisResponse(response: PersonDiagnosisResponse): SortablePersonDiagnosisResponse {
		return _assign({}, response, {
			codeSetOrder: this.lookupDiagnosisCodeSetOrder(response.practiceDiagnosis.codeSet),
		} as SortablePersonDiagnosisResponse);
	}

	lookupDiagnosisCodeSetOrder(codeSet: CodeSet): number {
		switch (codeSet) {
			case CodeSet.ICD10:
				return 1;
			case CodeSet.ICD9:
				return 2;
			case CodeSet.SNOMED:
				return 3;
			default:
				throw new Error(`Unrecognized CodeSet found: ${codeSet}`);
		}
	}

	sortDiagnoses(diagnoses: SortablePersonDiagnosisResponse[]): SortablePersonDiagnosisResponse[] {
		return SortingService.sortBy(diagnoses, ['diagnosisDate', 'codeSetOrder', 'id'], ['desc', 'asc', 'desc']);
	}

	createDiagnosisOptionItem(diagnosis: SortablePersonDiagnosisResponse): OptionItem {
		const practiceDiagnosis = diagnosis.practiceDiagnosis;
		return {
			value: diagnosis.id,
			label: `${practiceDiagnosis.code}: ${practiceDiagnosis.shortDescription}`,
		} as OptionItem;
	}

	findSortablePersonDiagnosesForEncounterByPatientId(patientId: number, encounterId: number): Observable<SortablePersonDiagnosisResponse[]> {
		return this.personDiagnosisGandalfService.findPersonDiagnosesByPatientId(patientId).pipe(
			map((diagnoses) => this.filterDiagnosesNotInEncounter(diagnoses, encounterId)),
			map((diagnoses: PersonDiagnosisResponse[]) => _map(diagnoses, diagnosis => this.createSortablePersonDiagnosisResponse(diagnosis))),
			map((sortableDiagnoses: SortablePersonDiagnosisResponse[]) => this.sortDiagnoses(sortableDiagnoses)),
		);
	}

	/**
	 * Keeps the PersonDiagnosisResponses that either do not have the same encounterId
	 * as the one provided, or if its originalDiagnosisId is null.
	 */
	filterDiagnosesNotInEncounter(diagnoses: PersonDiagnosisResponse[], encounterId: number) {
		return _filter(diagnoses, diagnosis => diagnosis.encounterId !== encounterId || _isNil(diagnosis.originalDiagnosisId));
	}

	filterAutoDiagnosesAndTransformDiagnosisLocation(autoDiagnosis: AssessmentAutoDiagnosisResponse[]): AssessmentAutoDiagnosisResponse[] {
		const filteredAutoDiagnoses = autoDiagnosis.filter(diagnosis => this.filterAutoDiagnosisOnStatusPersonDiagnosisId(diagnosis));
		filteredAutoDiagnoses.forEach(encounterAutoDiagnosis => encounterAutoDiagnosis.diagnosisLocation =
			this.diagnosisLocationPipe.transform(
				encounterAutoDiagnosis.codeSet,
				encounterAutoDiagnosis.longDescription,
				encounterAutoDiagnosis.diagnosisLocation,
			),
		);
		return filteredAutoDiagnoses;
	}

	filterAndTransformEncounterDiagnosis(personDiagnoses: SortablePersonDiagnosisResponse[], encounterId): SortablePersonDiagnosisResponse[] {
		const filteredDiagnoses = personDiagnoses.filter(encounterDiagnosis => encounterDiagnosis.encounterId === encounterId);
		return this.transformPersonDiagnosisLocation(filteredDiagnoses);
	}

	transformPersonDiagnosisLocation(diagnoses: SortablePersonDiagnosisResponse[]): SortablePersonDiagnosisResponse[] {
		if (!diagnoses) {
			return;
		}
		diagnoses.forEach(
			assessmentDiagnosis => assessmentDiagnosis.diagnosisLocation =
				this.diagnosisLocationPipe.transform(
					assessmentDiagnosis.practiceDiagnosis.codeSet,
					assessmentDiagnosis.practiceDiagnosis.longDescription,
					assessmentDiagnosis.diagnosisLocation,
				),
		);
		return diagnoses;
	}

	findSortedMasterDiagnosesWithPatientId(patientId: number): Observable<SortablePersonDiagnosisResponse[]> {
		return this.personDiagnosisGandalfService.findMasterDiagnosesByPatientId(patientId).pipe(
			map((diagnoses: PersonDiagnosisResponse[]) => this.createSortedPersonDiagnoses(diagnoses)),
		);
	}

	findSortedActiveMasterDiagnosesWithPatientId(patientId: number): Observable<SortablePersonDiagnosisResponse[]> {
		return this.personDiagnosisGandalfService.findActiveMasterDiagnosesByPatientId(patientId).pipe(
			map((diagnoses: PersonDiagnosisResponse[]) => this.createSortedPersonDiagnoses(diagnoses)),
		);
	}

	findSortedPersonDiagnosesByEncounterId(encounterId: number): Observable<SortablePersonDiagnosisResponse[]> {
		return this.personDiagnosisGandalfService.findPersonDiagnosesByEncounterId(encounterId).pipe(
			map((diagnoses: PersonDiagnosisResponse[]) => this.createSortedPersonDiagnoses(diagnoses)),
		);
	}

	/**
	 * Given an array of PersonDiagnosisResponse objects, returns them as a sorted array of SortablePersonDiagnosisResponse objects
	 */
	createSortedPersonDiagnoses(diagnoses: PersonDiagnosisResponse[]): SortablePersonDiagnosisResponse[] {
		const sortableDiagnoses = _map(diagnoses, diagnosis => this.createSortablePersonDiagnosisResponse(diagnosis));
		return this.sortDiagnoses(sortableDiagnoses);
	}

	formatPracticeDiagnosisForDropdown(practiceDiagnosis: AssessmentPracticeDiagnosisResponse): PracticeDiagnosesWithCodeDescription {
		const practiceDiagnosisWithCodeDescription = practiceDiagnosis as PracticeDiagnosesWithCodeDescription;
		practiceDiagnosisWithCodeDescription.codeDescription = practiceDiagnosis.code + ' - ' + practiceDiagnosis.shortDescription;
		return practiceDiagnosisWithCodeDescription;
	}

	findActiveMasterDiagnosesByPatientId(patientId: number): Observable<SortablePersonDiagnosisResponse[]> {
		return this.personDiagnosisGandalfService.findActiveMasterDiagnosesByPatientId(patientId)
			.pipe(
				map((responses: PersonDiagnosisResponse[]) => this.createSortedPersonDiagnoses(responses)),
			);
	}

	/**
	 * Filter auto diagnosis on status and person diagnosis Id
	 */
	filterAutoDiagnosisOnStatusPersonDiagnosisId(autoDiagnosis: AssessmentAutoDiagnosisResponse) {
		if (_isNil(autoDiagnosis)) {
			return false;
		}
		return !autoDiagnosis.personDiagnosisId && EnumUtil.equals(autoDiagnosis.status, EncounterAutoDiagnosisStatus.ACTIVE);
	}

	getDiagnosisInfoButtonUrl(diagnosisId: number): Observable<string> {
		return this.infoButtonGandalfService.getDiagnosisInfoButtonUrl(diagnosisId);
	}
}
