import { UpdateStatePropertyPayload } from '@app-store/utils/update-state-property-payload';
import { Action } from '@ngrx/store';

export enum PatientConsentsActionTypes {
	GET_PATIENT_CONSENTS = '[Get Patient Consents] Get Patient Consents',
	UPDATE_PATIENT_CONSENTS_PROPERTY = '[Update Patient Consents Property] Update Patient Consents Property',
}

export class GetPatientConsents implements Action {
	readonly type = PatientConsentsActionTypes.GET_PATIENT_CONSENTS;
	constructor(public payload: { patientId: number }) {}
}

export class UpdatePatientConsentsProperty implements Action {
	readonly type = PatientConsentsActionTypes.UPDATE_PATIENT_CONSENTS_PROPERTY;
	constructor(public payload: UpdateStatePropertyPayload) {}
}

export type PatientConsentsActions =
	GetPatientConsents
	| UpdatePatientConsentsProperty;
