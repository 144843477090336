// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.vendorsandpartners.vendor.UpdateVendorRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateVendorRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Account Number cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	accountNumber: string;

	@GandalfValidator({ sizeString: { message: 'Address cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	addressLine1: string;

	@GandalfValidator({ sizeString: { message: 'Address cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	addressLine2: string;

	@GandalfValidator({ sizeString: { message: 'City cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	city: string;

	@GandalfValidator({ sizeString: { message: 'Description cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	description: string;

	@GandalfValidator({ sizeString: { message: 'Director External ID cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty()
	directorExternalId: string;

	@GandalfValidator({ sizeString: { message: 'Director name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	directorName: string;

	@GandalfValidator({ sizeString: { message: 'Email cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfValidator({ email: { message: 'Invalid email format' } })
	@GandalfProperty()
	email: string;

	@GandalfValidator({ sizeString: { message: 'External ID cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	externalId: string;

	@GandalfValidator({ sizeString: { message: 'External Source cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	externalSource: string;

	@GandalfValidator({ sizeString: { message: 'Fax cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfValidator({ phoneNumber: { message: 'Fax must contain at least 10 digits' } })
	@GandalfProperty()
	fax: string;

	@GandalfValidator({ notNull: { message: 'Id is required' } })
	@GandalfProperty({ isRequired: true })
	id: number;

	@GandalfProperty()
	labLocationId: number;

	@GandalfValidator({ sizeString: { message: 'Phone cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfValidator({ phoneNumber: { message: 'Phone number must contain at least 10 digits' } })
	@GandalfProperty()
	mainPhone: string;

	@GandalfValidator({ notNull: { message: 'Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Name must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ isRequired: true })
	name: string;

	@GandalfValidator({ sizeString: { message: 'Zip code cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty()
	nonStandardPostalCode: string;

	@GandalfValidator({ postalCode: { message: 'Zip Code is invalid' } })
	@GandalfValidator({ sizeString: { message: 'Zip code cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty()
	postalCode: string;

	@GandalfProperty()
	smartflowMasterSupplierId: number;

	@GandalfValidator({ sizeString: { message: 'State/Province cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	stateProvince: string;

	@GandalfValidator({ sizeString: { message: 'Toll Free cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfValidator({ phoneNumber: { message: 'Toll free must contain at least 10 digits' } })
	@GandalfProperty()
	tollFree: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	vendorOrderSubmissionMechanism: constants.VendorOrderSubmissionMechanism;

	@GandalfValidator({ sizeString: { message: 'Web Site cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	webSite: string;

}
