// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.billing.CreateBillingInvoiceRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateBillingInvoiceRequest extends GandalfModelBase {

	@GandalfLabel('Encounter')
	@GandalfProperty()
	encounterId: number;

	@GandalfLabel('Location')
	@GandalfProperty()
	locationId: number;

	@GandalfLabel('Order')
	@GandalfProperty()
	orderId: number;

	@GandalfLabel('Patient')
	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientId: number;

	@GandalfLabel('Insurance')
	@GandalfProperty()
	personInsuranceId: number;

}
