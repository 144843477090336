// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { DrugAllergyImportResponse } from './drug-allergy-import-response';

// @ts-ignore
import { ImportResultResponse } from './import-result-response';

// @ts-ignore
import { MedicationPrescriptionImportResponse } from './medication-prescription-import-response';

// @ts-ignore
import { PersonDiagnosisImportResponse } from './person-diagnosis-import-response';

/** See com.rev360.pms.api.controller.patient.file.ContinuityOfCareImportResultResponse */
/* istanbul ignore next */
@GandalfModel
export class ContinuityOfCareImportResultResponse extends ImportResultResponse {

	@GandalfProperty()
	cdaIncorporationAlertMessage: string;

	@GandalfArray(PersonDiagnosisImportResponse)
	diagnoses: PersonDiagnosisImportResponse[];

	@GandalfArray(DrugAllergyImportResponse)
	drugAllergies: DrugAllergyImportResponse[];

	@GandalfArray(String)
	errorMessages: string[];

	@GandalfProperty()
	fileId: number;

	@GandalfProperty()
	hasHaltingError: boolean;

	@GandalfProperty()
	isSuccessful: boolean;

	@GandalfArray(MedicationPrescriptionImportResponse)
	medicationPrescriptions: MedicationPrescriptionImportResponse[];

	@GandalfProperty()
	patientFileId: number;

	@GandalfProperty()
	patientId: number;

	@GandalfArray(String)
	warningMessages: string[];

}
