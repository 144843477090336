// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.document.UpdatePracticeConsentFormRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdatePracticeConsentFormRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Allow electronic click is required' } })
	@GandalfProperty({ isRequired: true })
	allowElectronicClick: boolean;

	@GandalfValidator({ notNull: { message: 'Allow paper is required' } })
	@GandalfProperty({ isRequired: true })
	allowPaper: boolean;

	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	comments: string;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Expiration type is required' } })
	@GandalfProperty({ isRequired: true })
	expirationType: constants.PracticeConsentFormExpirationType;

	@GandalfProperty()
	expireDurationAmount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	expireDurationUnit: constants.DurationUnit;

	@GandalfValidator({ notNull: { message: 'Consent Form is required' } })
	@GandalfProperty({ isRequired: true })
	id: number;

	@GandalfValidator({ notNull: { message: 'Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Name must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ isRequired: true })
	name: string;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Required is required' } })
	@GandalfProperty({ isRequired: true })
	required: constants.PracticeConsentFormRequired;

	@GandalfProperty()
	templateId: number;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Type is required' } })
	@GandalfProperty({ isRequired: true })
	type: constants.PracticeConsentFormType;

}
