@if (useDropdown) {
	<button ejs-dropdownbutton [items]="messageTypes" (select)="confirmAppointmentMessage($event)" iconCss="fa fa-comment"
			class="e-primary margin-left-sm margin-bottom-xs"
			revTooltip tooltipContent="Send Appointment Reminder" [disabled]="appointmentStarted"
			[attr.data-test-id]="'appointmentSendReminderCommentButton'"></button>
}
@if (!useDropdown && isEmailable) {
	<button ejs-button [disabled]="appointmentStarted" type="button" iconCss="fa fa-envelope" revTooltip tooltipContent="Send Email Appointment Reminder"
			class="margin-left-sm margin-bottom-xs" [isPrimary]="true" (click)="confirmAppointmentMessage({item: emailComType})"
			[attr.data-test-id]="'appointmentSendReminderEmailButton'"></button>
}
@if (!useDropdown && isTextable) {
	<button ejs-button [disabled]="appointmentStarted" type="button" iconCss="fa fa-commenting" revTooltip tooltipContent="Send Text Appointment Reminder"
			class="margin-left-sm margin-bottom-xs" [isPrimary]="true" (click)="confirmAppointmentMessage({item: textComType})"
			[attr.data-test-id]="'appointmentSendReminderTextButton'"></button>
}
