<ejs-dialog
		#modal
		(close)="closeModal()"
		cssClass="modal-md"
		[attr.data-test-id]="'insuranceCoverageSelectionModal'">
	<ng-template #header>
		<div class="dlg-template">Coverage Selection</div>
	</ng-template>
	<ng-template #content>
		<div class="row">
			<div class="col-sm-4" [attr.data-test-id]="'insuranceCoverageSelectionModalGroupSection'">
				<h4 class="section-title bg-default mrgn-btm">Group</h4>
				<ejs-listview #policyHolderListView
							  [dataSource]="policyHolderOptions"
							  [fields]="{text: 'label', id: 'value'}"
							  (actionComplete)="listviewActionCompleted()"
							  (select)="policyHolderCategoryOptionSelected($event)">
				</ejs-listview>
			</div>
			<div class="col-sm-8">
				<div class="margin-bottom-md" [attr.data-test-id]="'insuranceCoverageSelectionModalPolicyHolderSection'">
					<h4 class="section-title bg-default mrgn-btm">Policy Holder</h4>
					<button ejs-button [isPrimary]="true" [disabled]="!isFamilyMembersGroupSelected" (click)="openAddPatientToFamilyAccountModal()" cssClass="margin-bottom-sm" iconCss="fa fa-search" [attr.data-test-id]="'insuranceCoverageSelectionModalSearchPatientsButton'">Search Patients</button>
					<button ejs-button [disabled]="!isFamilyMembersGroupSelected" (click)="quickAddFamilyMember()" cssClass="e-success margin-bottom-sm" iconCss="fa fa-plus" [attr.data-test-id]="'insuranceCoverageSelectionModalAddFamilyMemberButton'">Add Family Member</button>
					<button ejs-button [disabled]="!isContactsGroupSelected" (click)="quickAddContact()" cssClass="e-success margin-bottom-sm" iconCss="fa fa-plus" [attr.data-test-id]="'insuranceCoverageSelectionModalAddContactButton'">Add Contact</button>
					<ejs-grid
							#policyHoldersGrid
							[dataSource]="policies"
							[allowSorting]="false"
							[allowSelection]="true"
							[selectionSettings]="{enableToggle: false}"
							(rowSelected)="policyHolderSelected($event)"
							class="table-rowlink"
							gridLines="Horizontal"
							[attr.data-test-id]="'insuranceCoverageSelectionModalPolicyHolderSectionTable'">
						<e-columns>
							<e-column field="formattedName" headerText="Name" clipMode="EllipsisWithTooltip"></e-column>
							<e-column field="role.value" headerText="Role" clipMode="EllipsisWithTooltip"></e-column>
						</e-columns>
					</ejs-grid>
				</div>
				<div class="margin-bottom-md" [attr.data-test-id]="'insuranceCoverageSelectionModalPoliciesSection'">
					<h4 class="section-title bg-default mrgn-btm">Policies</h4>
					<button ejs-button [disabled]="!((isFamilyMembersGroupSelected || isContactsGroupSelected) && isPersonSelected)" (click)="addInsurance(isContactsGroupSelected)" cssClass="e-success margin-bottom-sm" iconCss="fa fa-plus" [attr.data-test-id]="'insuranceCoverageSelectionModalAddInsuranceButton'">Add Insurance</button>
					<ejs-grid
							#policyGrid
							[dataSource]="coverages"
							[allowSorting]="false"
							[allowSelection]="true"
							[selectionSettings]="{enableToggle: false}"
							[pmsGridSubmit]="isSearching"
							(rowSelected)="setPolicySelected(true)"
							class="table-rowlink"
							gridLines="Horizontal"
							[attr.data-test-id]="'insuranceCoverageSelectionModalPolicesSectionTable'">
						<e-columns>
							<e-column field="insuranceCompanyName" headerText="Company" clipMode="EllipsisWithTooltip"></e-column>
							<e-column field="insuranceType.value" headerText="Type" clipMode="EllipsisWithTooltip"></e-column>
						</e-columns>
					</ejs-grid>
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button (click)="closeModal()" iconCss="fa fa-close" [attr.data-test-id]="'insuranceCoverageSelectionModalCancelButton'">Cancel</button>
		<button ejs-button [isPrimary]="true" (click)="selectPolicy()" [disabled]="!isPolicySelected && !isSelfGroupSelected" [attr.data-test-id]="'insuranceCoverageSelectionModalSelectButton'">Select</button>
	</ng-template>
</ejs-dialog>
