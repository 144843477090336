import { Component, OnInit, ViewChild } from '@angular/core';
import { FormattedBarcodeSearchResponse } from '@core/barcode/barcode.service';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
	selector: 'pms-confirm-barcode-selection-modal',
	templateUrl: './confirm-barcode-selection-modal.component.html',
})
export class ConfirmBarcodeSelectionModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;
	tableData: FormattedBarcodeSearchResponse[];

	constructor(
		public ref: DynamicModalRef,
		public modalConfig: ModalConfig,
	) {
	}

	ngOnInit() {
		this.tableData = this.modalConfig.data.results;
	}

	closeModal(result?) {
		this.ref.close(this.modal, result);
	}
}
