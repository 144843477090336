<div>
	<ejs-grid #grid
			  autoFitColumns
			  [autoFitBlacklist]="['fileDescription']"
			  [staticColumns]="['icon', 'formattedType', 'buttons']"
			  [dataSource]="filesAndFolders"
			  [pmsGridSubmit]="isSearching"
			  gridLines="Horizontal"
			  [allowSorting]="true"
			  [allowSelection]="true"
			  [allowResizing]="true"
			  [allowFiltering]="true"
			  [allowPaging]="true"
			  [pageSettings]="pageSettings"
			  [allowExcelExport]="true"
			  class="table-rowlink"
			  [contextMenuItems]="contextMenuItems"
			  (contextMenuClick)="contextMenuClick($event)"
			  (contextmenu)="contextMenuOpeningClick()"
			  (contextMenuOpen)="contextMenuOpeningFromSyncfusion($event)"
			  (created)="tableIsRendered.next(true)"
			  (actionComplete)="actionComplete.next(true)"
			  (rowSelected)="rowSelected($event)"
			  [attr.data-test-id]="'documentsImagesFileTable'">
		<ng-template #toolbarTemplate let-data>
			<rev-table-action-menu [table]="grid" [items]="toolbar">
				<ng-container rev-table-action-menu-left>
					<div class="e-toolbar-item">
						<button ejs-button type="button" [isPrimary]="true" (click)="openUploadModal()" *ngIf="!!folder" [attr.data-test-id]="'filePreviewGridUploadButton'">Upload</button>
						<button ejs-button type="button" (click)="scan()" *ngIf="!!folder && canScan" [attr.data-test-id]="'filePreviewGridScanButton'">Scan</button>
						<button ejs-button type="button" (click)="openDrawingToolModal()" *ngIf="!!folder" [attr.data-test-id]="'filePreviewGridDrawButton'">Draw</button>
					</div>
				</ng-container>
			</rev-table-action-menu>
		</ng-template>

		<e-columns>
			<e-column field="icon" headerText="" [customAttributes]="{exportable: false}" [width]="52" [allowResizing]="false" [allowSorting]="false">
				<ng-template #template let-data>
					<img [alt]="data.formattedType" [src]="data.formattedType | fileIcon" width="35" class="mw35 mh35">
				</ng-template>
			</e-column>
			<e-column field="fileName" headerText="Name" clipMode="EllipsisWithTooltip"></e-column>
			<e-column field="formattedType" width="70px" headerText="Type" clipMode="EllipsisWithTooltip"></e-column>
			<e-column field="formattedSize" headerText="Size" clipMode="EllipsisWithTooltip"></e-column>
			<e-column field="createdOn" headerText="Date" [format]="tableDateFormat" clipMode="EllipsisWithTooltip"></e-column>
			<e-column field="fileDescription" headerText="Description" clipMode="EllipsisWithTooltip"></e-column>
			<e-column field="buttons" headerText="" width="160px" [customAttributes]="{exportable: false}" [allowSorting]="false" [allowResizing]="false">
				<ng-template #template let-data>
					<rev-button
							*ngIf="isPreviewable(data)"
							[revGridButton]="'preview'"
							(buttonClick)="previewItem(data)"
							dataTestId="folderFileListPreviewButton">
					</rev-button>
					<rev-button
							*ngIf="isDownloadable(data)"
							buttonIcon="download"
							buttonStyle="primary"
							buttonTooltip="Download"
							revEventStopPropagation
							(buttonClick)="downloadItem(data)"
							dataTestId="folderFileListDownloadButton">
					</rev-button>
					<rev-button
							*ngIf="isImageEditable(data)"
							buttonIcon="pencil"
							buttonStyle="primary"
							buttonTooltip="Edit Image"
							revEventStopPropagation
							(buttonClick)="editImage(data)"
							dataTestId="folderFileListEditImageButton">
					</rev-button>
					<rev-button
							*ngIf="isParseable(data)"
							buttonIcon="sign-in"
							buttonStyle="primary"
							buttonTooltip="Incorporate Document"
							revEventStopPropagation
							(buttonClick)="incorporateDocument(data)"
							dataTestId="folderFileListIncorporateButton">
					</rev-button>
					<rev-button
							*ngIf="isDeletable(data)"
							[revGridButton]="'delete'"
							(buttonClick)="deleteItem(data)"
							dataTestId="folderFileListDeleteButton">
					</rev-button>
				</ng-template>
			</e-column>
		</e-columns>
	</ejs-grid>
</div>
