import { Injectable } from '@angular/core';
import { PatientStatus } from '@gandalf/constants';
import { PatientNameResponse } from '@gandalf/model/patient-name-response';
import _isNil from 'lodash/isNil';
import { EnumUtil } from 'morgana';

export interface PatientComponentConfiguration {
	isFlex: boolean;
	tabIndex: number;
	featureFlag: string;
	patientId: number;
	isEmr?: boolean;
}
@Injectable({
	providedIn: 'root',
})
export class PatientUtilService {

	constructor(
	) { }

	static isNewPatient(patientId: number) {
		return patientId < 0;
	}

	static isPatientInactiveOrDeceased(patientNameResponse: PatientNameResponse) {
		if (_isNil(patientNameResponse)) {
			return false;
		}
		return EnumUtil.equalsOneOf(patientNameResponse.activeStatus, PatientStatus.DECEASED, PatientStatus.INACTIVE);
	}
}
