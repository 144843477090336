// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.plan.EncounterStopCarePlanItemForPersonDiagnosisRequest */
/* istanbul ignore next */
@GandalfModel
export class EncounterStopCarePlanItemForPersonDiagnosisRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Care Plan Item is required' } })
	@GandalfProperty({ isRequired: true })
	carePlanItemId: number;

	@GandalfValidator({ notNull: { message: 'Comment is required' } })
	@GandalfValidator({ sizeString: { message: 'Comment must be between 1 and 4000 characters', minLength: 1, maxLength: 4000 } })
	@GandalfProperty({ isRequired: true })
	comment: string;

	@GandalfValidator({ notNull: { message: 'Stop Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	stopDate: Date;

}
