// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreateCarePlanItemTemplateLabOrderRequest } from './create-care-plan-item-template-lab-order-request';

/** See com.rev360.pms.api.controller.admin.dataconfiguration.careplan.UpdateCarePlanItemTemplateLabOrderRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateCarePlanItemTemplateLabOrderRequest extends CreateCarePlanItemTemplateLabOrderRequest {

	@GandalfValidator({ notNull: { message: 'Lab Order is required' } })
	@GandalfProperty({ isRequired: true })
	carePlanItemTemplateId: number;

	@GandalfValidator({ notNull: { message: 'Description is required' } })
	@GandalfValidator({ sizeString: { message: 'Description must be between 1 and 4000 characters', minLength: 1, maxLength: 4000 } })
	@GandalfProperty({ isRequired: true })
	description: string;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'End Range Unit is required' } })
	@GandalfProperty({ isRequired: true })
	endDateRangeUnit: constants.CarePlanItemLabOrderDuration;

	@GandalfValidator({ notNull: { message: 'End Range Value is required' } })
	@GandalfProperty({ isRequired: true })
	endDateRangeValue: number;

	@GandalfValidator({ notNull: { message: 'Checkout Task is required' } })
	@GandalfProperty({ isRequired: true })
	isCheckoutTask: boolean;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Lab Type is required' } })
	@GandalfProperty({ isRequired: true })
	labType: constants.LabOrderLabType;

	@GandalfValidator({ notNull: { message: 'Order is required' } })
	@GandalfValidator({ sizeString: { message: 'Order must be between 1 and 8 characters', minLength: 1, maxLength: 8 } })
	@GandalfProperty({ isRequired: true })
	loincNum: string;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Result Type is required' } })
	@GandalfProperty({ isRequired: true })
	resultType: constants.LabOrderResultType;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Start Range Unit is required' } })
	@GandalfProperty({ isRequired: true })
	startDateRangeUnit: constants.CarePlanItemLabOrderDuration;

	@GandalfValidator({ notNull: { message: 'Start Range Value is required' } })
	@GandalfProperty({ isRequired: true })
	startDateRangeValue: number;

}
