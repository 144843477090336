// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.dataconfiguration.medication.PracticeDrugResponse */
/* istanbul ignore next */
@GandalfModel
export class PracticeDrugResponse extends GandalfModelBase {

	@GandalfProperty()
	dispensingInfoAmount: number;

	@GandalfProperty()
	dispensingInfoUnitId: number;

	@GandalfProperty()
	doNotSubstitute: boolean;

	@GandalfProperty()
	dosageAmount: number;

	@GandalfProperty()
	dosageUnitId: number;

	@GandalfProperty()
	drugName: string;

	@GandalfProperty()
	durationAmount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	durationUnit: constants.DurationUnit;

	@GandalfProperty()
	frequencyId: number;

	@GandalfProperty()
	instructions: string;

	@GandalfProperty()
	numRefillsAllowed: number;

	@GandalfProperty()
	practiceDrugId: number;

	@GandalfProperty()
	routeId: number;

}
