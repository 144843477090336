import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { ProviderService } from '@core/provider/provider.service';
import { ProviderResponse } from '@gandalf/model/provider-response';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

export interface SelectProviderModalData {
	providerId: number;
}

@Component({
	selector: 'pms-select-provider-modal',
	templateUrl: './select-provider-modal.component.html',
	styles: [],
})
export class SelectProviderModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	providerId: number;
	providerList: ProviderResponse[];

	constructor(
		private dynamicModalRef: DynamicModalRef,
		private modalConfig: ModalConfig,
		private providerService: ProviderService,
	) { }

	ngOnInit() {
		this.providerService.findActivePracticeProvidersForDropdown().subscribe(providers => {
			this.initializeProviderDropdown(providers);
		});
	}

	initializeProviderDropdown(providers: ProviderResponse[]) {
		this.providerList = providers;
		this.providerId = (this.modalConfig.data as SelectProviderModalData).providerId;
	}

	closeModal(providerId?: number) {
		this.dynamicModalRef.close(this.modal, providerId);
	}

}
