import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { DropdownFilter } from '@core/dropdown-filter/dropdown-filter.decorator';
import { DynamicModalRef, ModalConfig, SortingService } from 'morgana';
import { CreateVendorLocationConfigRequest } from '@gandalf/model/create-vendor-location-config-request';
import { UpdateVendorLocationConfigRequest } from '@gandalf/model/update-vendor-location-config-request';
import { VendorLocationConfigResponse } from '@gandalf/model/vendor-location-config-response';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';
import { VendorsAndPartnersService } from '../../vendors-and-partners.service';

@Component({
	selector: 'pms-location-config-modal',
	templateUrl: './location-config-modal.component.html',
})
export class LocationConfigModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('componentForm')
	componentForm: NgForm;

	@DropdownFilter()
	shouldFilter: (options: any[]) => boolean;

	locationConfig: VendorLocationConfigResponse;
	locationsList: any[];
	isCreating: boolean;
	formGroup: UntypedFormGroup;
	createOrUpdateRequest: CreateVendorLocationConfigRequest | UpdateVendorLocationConfigRequest;
	vendorId: number;

	constructor(
		public dynamicModalRef: DynamicModalRef,
		public modalConfig: ModalConfig,
		public gandalfFormBuilder: GandalfFormBuilder,
		public vendorService: VendorsAndPartnersService,
	) {
	}

	ngOnInit() {
		this.initialize();
	}

	private initialize() {
		this.isCreating = this.modalConfig.data.isCreating;
		this.vendorId = this.modalConfig.data.vendorId;
		this.isCreating ? this.initializeNew() : this.initializeEdit();

		this.vendorService.findLocationsWithoutVendorConfigsForVendor(this.vendorId).subscribe((data) => {
			this.locationsList = this.locationsList.concat(data);
			this.locationsList = SortingService.sortBy(this.locationsList, ['id'], ['asc']);
			this.formGroup.enable();
		});
	}

	initializeNew() {
		this.locationsList = [];
		this.createOrUpdateRequest = new CreateVendorLocationConfigRequest();
		this.createOrUpdateRequest.vendorId = this.vendorId;
		this.createForm();
	}

	initializeEdit() {
		this.locationConfig = this.modalConfig.data.response;
		this.locationsList = [{
			id: this.locationConfig.practiceLocationId,
			name: this.locationConfig.practiceLocationName,
		}];
		this.createOrUpdateRequest = new UpdateVendorLocationConfigRequest();
		this.createForm();
		this.formGroup.reset(this.locationConfig);
	}

	/*istanbul ignore next*/
	createForm() {
		this.formGroup = this.gandalfFormBuilder.group(this.createOrUpdateRequest);
		this.formGroup.disable();
	}

	saveChanges() {
		// If it's a new location config and nothing has been entered.
		if (this.isCreating && this.formGroup.pristine) {
			this.closeModal(false);
			return;
		}

		if (this.formGroup.invalid) {
			return;
		}

		const createOrUpdateRequest = this.formGroup.value;
		if (this.isCreating) {
			this.vendorService.createVendorLocationConfig(createOrUpdateRequest).subscribe(() => {
				this.closeModal(true);
			});
		} else {
			this.vendorService.updateVendorLocationConfig(createOrUpdateRequest).subscribe(() => {
				this.closeModal(true);
			});
		}
	}

	closeModal(refreshRequested: boolean) {
		this.dynamicModalRef.close(this.modal, refreshRequested);
	}

	submitForm(event: any) {
		this.componentForm.onSubmit(event);
	}
}
