// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.accounting.DepositsReportRequest */
/* istanbul ignore next */
@GandalfModel
export class DepositsReportRequest extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	dateRangeEnd: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dateRangeStart: Date;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Date range is required' } })
	@GandalfProperty({ isRequired: true })
	dateRangeType: constants.DateRangeType;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Report type is required' } })
	@GandalfProperty({ isRequired: true })
	depositsReportType: constants.DepositsReportType;

	@GandalfProperty()
	locationId: number;

	@GandalfProperty()
	providerId: number;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Report output is required' } })
	@GandalfProperty({ isRequired: true })
	reportOutputType: constants.AccountingReportOutputType;

}
