import { _get } from '@core/lodash/lodash';
import { createSelector } from '@ngrx/store';
import { selectPatientsState } from '@patients-store/reducers';
import { PatientProviderStateMap } from '@patients-store/reducers/patient-providers.reducer';

export const selectPatientProviderState =
	createSelector(selectPatientsState, state => state.patientProviderState);

export const selectPatientProviderTableState = (props: {patientId: number}) =>
	createSelector(selectPatientProviderState, (state: PatientProviderStateMap) => _get(state.get(props.patientId), ['tableState']));

export const selectPatientProviderTableConfigState = (props: {patientId: number}) =>
	createSelector(selectPatientProviderState, (state: PatientProviderStateMap) => _get(state.get(props.patientId), ['tableState', 'tableConfigurationJson']));

export const selectPatientProviderTableResultsState = (props: {patientId: number}) =>
	createSelector(selectPatientProviderState, (state: PatientProviderStateMap) => _get(state.get(props.patientId), ['tableState', 'tableResults']));

export const selectPatientProviderDetailsMode = (props: {patientId: number}) =>
	createSelector(selectPatientProviderState, (state: PatientProviderStateMap) => state.get(props.patientId));

export const selectPatientProviderFormState = (props: {patientId: number}) =>
	createSelector(selectPatientProviderState, (state: PatientProviderStateMap) => _get(state.get(props.patientId), ['formState']));

export const selectPatientProviderSelectedTabState = (props: {patientId: number}) =>
	createSelector(selectPatientProviderState, (state: PatientProviderStateMap) => _get(state.get(props.patientId), ['selectedIndex']));
