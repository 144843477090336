// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { OpticalOrderPodOrderResponse } from './optical-order-pod-order-response';

/** See com.rev360.pms.api.controller.patient.opticalsummary.OpticalOrderPodContactLensResponse */
/* istanbul ignore next */
@GandalfModel
export class OpticalOrderPodContactLensResponse extends OpticalOrderPodOrderResponse {

	@GandalfProperty()
	comments: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	createdOn: Date;

	@GandalfProperty()
	instructions: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	lensType: constants.ContactLensPrescriptionType;

	@GandalfProperty()
	odColor: string;

	@GandalfProperty()
	odModel: string;

	@GandalfProperty()
	odPackagingType: string;

	@GandalfProperty()
	odQuantity: number;

	@GandalfProperty()
	onHold: boolean;

	@GandalfProperty()
	orderId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	orderType: constants.OrderType;

	@GandalfProperty()
	osColor: string;

	@GandalfProperty()
	osModel: string;

	@GandalfProperty()
	osPackagingType: string;

	@GandalfProperty()
	osQuantity: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.OrderStatusCode;

	@GandalfProperty({ propertyType: 'LocalDate' })
	statusDate: Date;

}
