import { CommonModule, CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from '../../components/button/button.component';
import { ButtonMenuComponent } from '../../components/button-menu/button-menu.component';
import { ExternalLinkComponent } from '../../components/external-link/external-link.component';
import { DigitOnlyInputDirective } from '../../directives/digit-only-input/digit-only-input.directive';
import { FocusInputDirective } from '../../directives/focus-input/focus-input.directive';
import { GridButtonDirective } from '../../directives/grid/button/grid-button.directive';
import { TooltipDirective } from '../../directives/tooltip/tooltip.directive';
import { EnsureHttpPipe } from '../../pipes/ensure-http/ensure-http.pipe';
import { ForceDecimalPipe } from '../../pipes/force-decimal/force-decimal.pipe';
import { FlatPercentPipe } from '../../pipes/percent/flat-percent.pipe';
import { YesNoPipe } from '../../pipes/yes-no/yes-no.pipe';
import { InjectorContainerModule } from '../injector-container.module';
import { EventStopPropagationDirective } from '../../directives/event-stop-propagation/event-stop-propagation.directive';
import { UrlEditorComponent } from '../../components/url-editor/url-editor.component';

@NgModule({
	declarations: [
		DigitOnlyInputDirective,
		EventStopPropagationDirective,
		FocusInputDirective,
		GridButtonDirective,
		TooltipDirective,
		EnsureHttpPipe,
		FlatPercentPipe,
		ForceDecimalPipe,
		YesNoPipe,
		ButtonComponent,
		ButtonMenuComponent,
		ExternalLinkComponent,
		UrlEditorComponent,
	],
	imports: [
		CommonModule,
		InjectorContainerModule,
		MatDividerModule,
		// eslint-disable-next-line deprecation/deprecation
		MatMenuModule,
		FormsModule,
	],
	exports: [
		CommonModule,
		InjectorContainerModule,
		MatDividerModule,
		// eslint-disable-next-line deprecation/deprecation
		MatMenuModule,
		DigitOnlyInputDirective,
		EventStopPropagationDirective,
		FocusInputDirective,
		GridButtonDirective,
		TooltipDirective,
		EnsureHttpPipe,
		FlatPercentPipe,
		YesNoPipe,
		ButtonComponent,
		ButtonMenuComponent,
		ExternalLinkComponent,
		UrlEditorComponent,
	],
	providers: [
		CurrencyPipe,
		DatePipe,
		EnsureHttpPipe,
		FlatPercentPipe,
		ForceDecimalPipe,
		YesNoPipe,
		PercentPipe,
	],
})
export class MorganaCoreModule {
}
