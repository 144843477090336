// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeRequest } from './contact-lens-eye-request';

// @ts-ignore
import { EncounterContactLensTrialRequest } from './encounter-contact-lens-trial-request';

// @ts-ignore
import { FieldValueRequest } from './field-value-request';

/** See com.rev360.pms.api.controller.encounter.contactlens.EncounterUpdateContactLensTrialRequest */
/* istanbul ignore next */
@GandalfModel
export class EncounterUpdateContactLensTrialRequest extends EncounterContactLensTrialRequest {

	@GandalfLabel('OD Comments')
	@GandalfValidator({ sizeString: { message: 'OD Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	commentsOd: string;

	@GandalfLabel('OS Comments')
	@GandalfValidator({ sizeString: { message: 'OS Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	commentsOs: string;

	@GandalfValidator({ notNull: { message: 'Contact Lens Trial is required' } })
	@GandalfProperty({ isRequired: true })
	contactLensTrialId: number;

	@GandalfLabel('Additional Comments')
	@GandalfValidator({ sizeString: { message: 'Additional Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	evaluationComment: string;

	@GandalfLabel('Evaluation')
	@GandalfValidator({ notNull: { message: 'Evaluation is required' } })
	@GandalfArray(FieldValueRequest)
	evaluationValues: FieldValueRequest[];

	@GandalfLabel('OD Dispensed')
	@GandalfProperty()
	isDispensedOd: boolean;

	@GandalfLabel('OS Dispensed')
	@GandalfProperty()
	isDispensedOs: boolean;

	@GandalfSubRequestLabel('OD')
	@GandalfValidator({ notNull: { message: 'OD details are required' } })
	@GandalfProperty({ isRequired: true })
	od: ContactLensEyeRequest;

	@GandalfValidator({ notNull: { message: 'OS details are required' } })
	@GandalfProperty({ isRequired: true })
	os: ContactLensEyeRequest;

	@GandalfConstantDecorator()
	@GandalfLabel('Lens Type')
	@GandalfValidator({ notNull: { message: 'Lens Type is required' } })
	@GandalfProperty({ isRequired: true })
	overallLensType: constants.ContactLensPrescriptionType;

	@GandalfLabel('Comments')
	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	refractionComment: string;

	@GandalfLabel('CL Over-Refraction')
	@GandalfValidator({ notNull: { message: 'CL Over-Refraction is required' } })
	@GandalfArray(FieldValueRequest)
	refractionValues: FieldValueRequest[];

	@GandalfLabel('Comments')
	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	thoroughDocComment: string;

	@GandalfLabel('Thorough Documentation')
	@GandalfProperty()
	thoroughDocValue: FieldValueRequest;

	@GandalfLabel('Comments')
	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	vaComment: string;

	@GandalfLabel('CL Aided VA')
	@GandalfValidator({ notNull: { message: 'CL Aided VA is required' } })
	@GandalfArray(FieldValueRequest)
	vaValues: FieldValueRequest[];

}
