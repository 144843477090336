export const HEADER_TABS = {
	TABS: {
		HOME: {
			NAME: 'home',
			ICON: '',
			LABEL: 'Home',
		},
		PATIENTS: {
			NAME: 'patients',
			ICON: 'fa fa-users',
			LABEL: 'Patients',
		},
		SCHEDULE: {
			NAME: 'schedule',
			ICON: 'fa fa-calendar',
			LABEL: 'Schedule',
		},
		ACCOUNTING: {
			NAME: 'accounting',
			ICON: 'fa fa-money',
			LABEL: 'Accounting',
		},
		INVOICES: {
			NAME: 'invoices',
			ICON: 'fa fa-file-text',
			LABEL: 'Invoices',
		},
		PAYMENTS: {
			NAME: 'payments',
			ICON: 'fa fa-usd',
			LABEL: 'Payments',
		},
		CLAIMS: {
			NAME: 'claims',
			ICON: 'fa fa-shield',
			LABEL: 'Claims',
		},
		ORDERS: {
			NAME: 'orders',
			ICON: 'fa fa-shopping-cart',
			LABEL: 'Orders',
		},
		INVENTORY: {
			NAME: 'inventory',
			ICON: 'fa fa-cubes',
			LABEL: 'Inventory',
		},
		PRODUCTS: {
			NAME: 'products',
			ICON: 'fa fa-cube',
			LABEL: 'Products',
		},
		SERVICES: {
			NAME: 'services',
			ICON: 'fa fa-list-alt',
			LABEL: 'Services',
		},
		TRANSFERS: {
			NAME: 'transfers',
			ICON: 'fa fa-exchange',
			LABEL: 'Transfers',
		},
		RECONCILIATION: {
			NAME: 'reconciliation',
			ICON: 'fa fa-check-square',
			LABEL: 'Reconciliation',
		},
		VSP: {
			NAME: 'vsp',
			ICON: 'fa fa-cube',
			LABEL: 'VSP Products',
		},
		TASKS: {
			NAME: 'tasks',
			ICON: 'fa fa-check-square-o',
			LABEL: 'Tasks',
		},
		MESSAGES: {
			NAME: 'messaging',
			ICON: 'fa fa-envelope',
			LABEL: 'Messages',
		},
		REPORTS: {
			NAME: 'marketing',
			ICON: 'fa fa-bar-chart',
			LABEL: 'Reports',
		},
		REVENGAGE: {
			NAME: 'revEngage',
			ICON: 'fa fa-comments-o',
			LABEL: 'RevEngage',
		},
		REVCONNECT: {
			NAME: 'connect',
			ICON: 'fa fa-comment',
			LABEL: 'RevConnect',
		},
		CONNECT_DASHBOARD: {
			NAME: 'connectDashboard',
			ICON: 'fa fa-dashboard',
			LABEL: 'Dashboard',
		},
		CONNECT_CAMPAIGNS: {
			NAME: 'connectCampaigns',
			ICON: 'fa fa-envelope',
			LABEL: 'Campaigns',
		},
		CONNECT_TWO_WAY_TEXT: {
			NAME: 'connectTwoWayText',
			ICON: 'fa fa-comments',
			LABEL: 'Two-Way Texting',
		},
		ADMIN: {
			NAME: 'admin',
			ICON: 'fa fa-gears',
			LABEL: 'Admin',
		},
	},
};

export const HEADER_TAB_VALUES = {
	'home': HEADER_TABS.TABS.HOME,
	'patients': HEADER_TABS.TABS.PATIENTS,
	'schedule': HEADER_TABS.TABS.SCHEDULE,
	'accounting': HEADER_TABS.TABS.ACCOUNTING,
	'invoices': HEADER_TABS.TABS.INVOICES,
	'payments': HEADER_TABS.TABS.PAYMENTS,
	'claims': HEADER_TABS.TABS.CLAIMS,
	'orders': HEADER_TABS.TABS.ORDERS,
	'inventory': HEADER_TABS.TABS.INVENTORY,
	'products': HEADER_TABS.TABS.PRODUCTS,
	'services': HEADER_TABS.TABS.SERVICES,
	'transfers': HEADER_TABS.TABS.TRANSFERS,
	'reconciliation': HEADER_TABS.TABS.RECONCILIATION,
	'vsp': HEADER_TABS.TABS.VSP,
	'tasks': HEADER_TABS.TABS.TASKS,
	'messaging': HEADER_TABS.TABS.MESSAGES,
	'marketing': HEADER_TABS.TABS.REPORTS,
	'revEngage': HEADER_TABS.TABS.REVENGAGE,
	'connect': HEADER_TABS.TABS.REVCONNECT,
	'connectDashboard': HEADER_TABS.TABS.CONNECT_DASHBOARD,
	'connectCampaigns': HEADER_TABS.TABS.CONNECT_CAMPAIGNS,
	'connectTwoWayText': HEADER_TABS.TABS.CONNECT_TWO_WAY_TEXT,
	'admin': HEADER_TABS.TABS.ADMIN,
};
