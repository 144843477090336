<ejs-dialog
	#modal
	(close)="closeModal()"
	id="todays-patients-dockable-modal"
	cssClass="dockable-modal"
	[ngClass]="{'dialog-maximized': maximized, 'dialog-minimized': !maximized}"
	[attr.data-test-id]="'dockableTodaysPatientsModal'"
	[width]="500"
	[height]="'85%'"
	[allowDragging]="false"
	[isModal]="false"
	[position]="{X: 'right', Y: 'top'}">
	<rev-loading-overlay [loading]="_isLoading">
		<ng-template #header>
			<span class='title'>Today's Patients</span>
			<span class="header-btns">
        @if (!maximized) {
			<rev-button buttonIcon="window-maximize" (click)='maximize()'></rev-button>
		}
				@if (maximized) {
					<rev-button buttonIcon="window-minimize" (click)='minimize()'></rev-button>
				}
				<rev-button (click)="getData()" buttonIcon="refresh"></rev-button>
      </span>
		</ng-template>
		<ng-template #content>
			@if (showNoDataMessage()) {
				<p>There are currently no patients scheduled for today.</p>
			}
			@if (todaysPatientsAppointmentResponses) {
				<div class="appointment-list">
					@for (appointment of todaysPatientsAppointmentResponses; track appointment) {
						<div class="appointment-item">
							<div class="appointment-time media-left" [style]="{'border-color': (appointment.templateDisplayColor | intToHexColor)}">
								<a (click)="goToScheduleItem(appointment.appointmentId)">
									<span class="appointment-time-start">{{ appointment.startDate | date:'shortTime' }}</span>
									<span class="appointment-time-end">{{ appointment.endDate | date:'shortTime' }}</span>
								</a>
							</div>
							<div class="appointment-info media-body container-fluid">
								<div class="row">
									<div class="col-sm-8 col-lg-9">
										<div class="appointment-name">
											<a (click)="goToPatient(appointment.patientName.patientId)" data-toggle="modal"
											   data-target="#patient-overview-modal">
												{{ appointment.patientName.lastName }}, {{ appointment.patientName.firstName }}
												@if (appointment.patientName.nickname) {
													<span>"{{ appointment.patientName.nickname }}"</span>
												}
											</a>
											@if (isPatientNew(appointment.patientName.status)) {
												<b class="text-large text-primary">*</b>
											}
											<span class="appointment-name-age text-muted">
                        {{ appointment.patientDob | date:dateFormat }} ({{ appointment.patientDob | yearsDiff }} yrs)
                      </span>
										</div>
										<div class="appointment-meta">
											@if (connectSubscriber) {
												<span>
                          @if (connectMessageExistsAndDelivered(appointment.connectMessageExists, appointment.connectMessageDelivered)) {
							  <i
								  class="fa fa-comment text-success">
                            </i>
						  }
													@if (connectMessageExistsAndNotDelivered(appointment.connectMessageExists, appointment.connectMessageDelivered)) {
														<i
															class="fa fa-comment text-danger">
                            </i>
													}
                        </span>
											}
											<span class="appointment-type">
                        <a (click)="goToAppointmentOrEncounter(appointment)">{{ appointment.templateDisplayName }}</a>
												@if (appointment.encounterId) {
													<a class="margin-left-xs" (click)="printEncounter(appointment.encounterId)"
													   target="_blank">
                            <i class="fa fa-info-circle"></i>
                          </a>
												}
												@if (appointment.appointmentSubTypeValue) {
													<span class="small text-muted margin-left-xs"
													>({{ appointment.appointmentSubTypeValue }})</span>
												}
                      </span>
											@if (appointment.provider?.personId) {
												<span class="appointment-provider">
                          <i class="fa fa-circle"
							 [style]="{'color': (appointment.provider.providerDisplayColor | intToHexColor)}"></i>{{ appointment.provider.lastName }}
													, {{ appointment.provider.firstName }}
                        </span>
											}
											@if (!appointment.provider && appointment.employeeName) {
												<span class="appointment-provider">
                          <i class="fa fa-circle"></i>{{ appointment.employeeName.lastName }}, {{ appointment.employeeName.firstName }}
                        </span>
											}
											@if (!appointment.provider && !appointment.employeeName) {
												<span class="appointment-provider">
                          <i class="fa fa-circle"></i>{{ appointment.roleName }}
                        </span>
											}
										</div>
									</div>
									<div class="appointment-actions col-sm-4 col-lg-3">
                    <span class="appointment-statuses">
                      <pms-confirmed-status-icon [status]="appointment.appointmentConfirmed"></pms-confirmed-status-icon>
                      <pms-insurance-status-icon
						  [status]="appointment.insuranceVerifiedStatus"
						  [comment]="appointment.insuranceComment">
                      </pms-insurance-status-icon>
                      <pms-interview-status-icon [status]="appointment.interviewStatus"></pms-interview-status-icon>
                      <pms-signed-status-icon [status]="appointment.scheduleItemStatus"
											  [encounterApprovalStatus]="appointment.encounterApprovalStatus"></pms-signed-status-icon>
                    </span>
										@if (appointment.scheduleItemStatus === scheduleItemStatus.ACTIVE) {
											<rev-button
												(buttonClick)="startAppointment(appointment)"
												buttonLabel="Start"
												dataTestId="todaysPatientsDockableModalStart">
											</rev-button>
										}
										@if (appointment.scheduleItemStatus === scheduleItemStatus.IN_PROGRESS) {
											<rev-button
												(buttonClick)="completeEncounter(appointment.encounterId)"
												buttonLabel="Complete"
												dataTestId="todaysPatientsDockableModalComplete">
											</rev-button>
										}
									</div>
								</div>
							</div>
						</div>
					}
				</div>
			}
		</ng-template>
	</rev-loading-overlay>
</ejs-dialog>
