import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import uniq from 'lodash/uniq';

@Injectable({
	providedIn: 'root',
})
export class ValidationMessagesService {

	constructor() {
	}

	getAllErrorMessages(formGroup: UntypedFormGroup, requestObj: any, validateSubrequests = true, validateSubArrays = true) {
		const errorList = this.buildAllErrorsList(formGroup, requestObj, validateSubrequests, validateSubArrays);
		return uniq(errorList).map((error) => ({severity: 'error', detail: error}));
	}

	private buildAllErrorsList(formGroup: UntypedFormGroup, requestObj: any, validateSubrequests, validateSubArrays) {
		let errorList = [];
		// Gets the validator that is passed to the options parameter in the FormBuilder.group().
		if (formGroup.errors) {
			Object.keys(formGroup.errors).forEach(key => {
				if (formGroup.errors[key].message) {
					errorList = errorList.concat(formGroup.errors[key].message);
				}
			});
		}
		errorList = this.getErrorMessages(formGroup, requestObj, errorList, validateSubrequests, validateSubArrays);
		return errorList;
	}

	private getErrorMessages(
		formGroup: UntypedFormGroup,
		requestObj: any,
		errorList: string[],
		validateSubrequests = true,
		validateSubArrays = true,
		subRequestLabel = '',
	) {
		// Loop over all controls on the formGroup.
		Object.keys(formGroup.controls).forEach(key => {
			const listOfErrors = this.getErrorsForControl(formGroup, key, requestObj, subRequestLabel);
			// Save a list of the errors that were returned.
			errorList = errorList.concat(listOfErrors);
			const control = formGroup.get(key);
			if (validateSubrequests && this.isFormGroup(control)) {
				let fullSubRequestLabel = subRequestLabel;
				if (requestObj.$subRequestLabel[key]) {
					if (fullSubRequestLabel) {
						fullSubRequestLabel += ' ';
					}
					fullSubRequestLabel += requestObj.$subRequestLabel[key];
				}
				errorList = this.getErrorMessages(control as UntypedFormGroup, requestObj[key], errorList, validateSubrequests, validateSubArrays, fullSubRequestLabel);
			} else if (validateSubArrays && this.isFormArray(control)) {
				errorList =	this.getArrayErrorMessages(control as UntypedFormArray, requestObj[key], errorList, validateSubrequests, validateSubArrays);
			}
		});
		return errorList;
	}

	private getArrayErrorMessages(
		formArray: UntypedFormArray,
		requestArray: any[],
		errorList: string[],
		validateSubrequests = true,
		validateSubArrays = true,
	) {
		// Loop over all form groups in the array
		if (validateSubrequests) {
			formArray.controls.forEach((control, index) => {
				if (this.isFormGroup(control)) {
					errorList = errorList.concat(this.buildAllErrorsList(control as UntypedFormGroup, requestArray[index], validateSubrequests, validateSubArrays));
				}
			});
		}
		return errorList;
	}

	/* istanbul ignore next */
	private isFormGroup(control: AbstractControl): boolean {
		return control instanceof UntypedFormGroup;
	}

	private getErrorsForControl(formGroup: UntypedFormGroup, key: string, requestObj: any, subRequestLabel: string) {
		const errorArray = [];
		const control = formGroup.get(key);
		if (control.errors) {
			Object.keys(control.errors).forEach(keyError => {
				errorArray.push(this.getErrorMessage(control.errors, key, keyError, requestObj, control.value, subRequestLabel));
			});
		}
		return errorArray;
	}

	private getErrorMessage(controlErrors: ValidationErrors, formGroupKey: string, errorKey: string, requestObj: any, value: any, subRequestLabel: string) {
		// check if validation message is part of gandalf validation else use the custom/non-gandalf validation message
		/* istanbul ignore else: Need to determine a proper test for the else branch */
		if (requestObj.$validators[formGroupKey] && requestObj.$validators[formGroupKey].messages[errorKey] !== undefined) {
			let message = requestObj.$validators[formGroupKey].messages[errorKey].replace('${validatedValue}', value);
			if (subRequestLabel) {
				if (message.indexOf('${subRequestLabel}') >= 0) {
					message = message.replace('${subRequestLabel}', subRequestLabel);
				} else {
					message = `${subRequestLabel} ${message}`;
				}
			} else {
				// Remove ${subRequestLabel} placeholder and any leading or trailing space if a parent label doesn't exist
				message = message.replace('${subRequestLabel} ', '');
				message = message.replace(' ${subRequestLabel}', '');
				message = message.replace('${subRequestLabel}', '');
			}
			return message;
		} else if (controlErrors[errorKey] && controlErrors[errorKey].message) {
			return controlErrors[errorKey].message;
		}
	}

	/* istanbul ignore next */
	private isFormArray(control: AbstractControl): boolean {
		return control instanceof UntypedFormArray;
	}
}
