// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.MergePatientsRequest */
/* istanbul ignore next */
@GandalfModel
export class MergePatientsRequest extends GandalfModelBase {

	@GandalfLabel('Merge From Patient')
	@GandalfValidator({ notNull: { message: 'Merge From Patient is required' } })
	@GandalfProperty({ isRequired: true })
	mergeFromPatientId: number;

	@GandalfLabel('Merge To Patient')
	@GandalfValidator({ notNull: { message: 'Merge To Patient is required' } })
	@GandalfProperty({ isRequired: true })
	mergeToPatientId: number;

}
