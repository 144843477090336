// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.shared.patient.order.ContactLensOrderSearchResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensOrderSearchResponse extends GandalfModelBase {

	@GandalfProperty()
	contactLensPrescriptionId: number;

	@GandalfProperty()
	contactLensTrialId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	expirationDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	lensType: constants.ContactLensPrescriptionType;

	@GandalfProperty()
	odManufacturer: string;

	@GandalfProperty()
	odModelName: string;

	@GandalfProperty()
	osManufacturer: string;

	@GandalfProperty()
	osModelName: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.PrescriptionCurrentStatus;

}
