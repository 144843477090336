export const PAGE_LENGTH = {
	PAGE_10 : 10,
	PAGE_20 : 20,
	PAGE_30 : 30,
	PAGE_40 : 40,
	PAGE_50 : 50,
	PAGE_100 : 100,
};

export const PAGE_LENGTH_LIST = [
	PAGE_LENGTH.PAGE_10,
	PAGE_LENGTH.PAGE_20,
	PAGE_LENGTH.PAGE_30,
	PAGE_LENGTH.PAGE_40,
	PAGE_LENGTH.PAGE_50,
	PAGE_LENGTH.PAGE_100,
];

export const PAGE_LENGTH_DROPDOWN_OPTIONS = [
	{label: '10', value: PAGE_LENGTH.PAGE_10},
	{label: '20', value: PAGE_LENGTH.PAGE_20},
	{label: '30', value: PAGE_LENGTH.PAGE_30},
	{label: '40', value: PAGE_LENGTH.PAGE_40},
	{label: '50', value: PAGE_LENGTH.PAGE_50},
	{label: '100', value: PAGE_LENGTH.PAGE_100},

];
