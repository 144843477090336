<ejs-dialog
		#modal
		(close)="closeModal()"
        [attr.data-test-id]="'assignAccountingCategoriesModal'">
	<ng-template #header>
		<div class="dlg-template">Assign Categories</div>
	</ng-template>
	<ng-template #content>
		<ejs-grid #grid
				  [dataSource]="categories"
				  [allowPaging]="false"
				  [allowSelection]="false"
				  [allowSorting]="true"
				  [allowResizing]="false"
				  gridLines="Horizontal"
		          [attr.data-test-id]="'assignAccountingCategoriesModalTable'">
			<e-columns>
				<e-column headerText="Category" field="name"></e-column>
				<e-column headerText="Value" [allowSorting]="false">
					<ng-template #template let-data>
						<ejs-dropdownlist [dataSource]="data.subCategories"
										  [showClearButton]="true"
										  (ngModelChange)="onSelectedSubCategoryIdUpdate(data.id, $event)"
										  [(ngModel)]="data.selectedSubCategoryId"
										  placeholder="Select Subcategory"></ejs-dropdownlist>
					</ng-template>
				</e-column>
			</e-columns>
		</ejs-grid>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button type="button" iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'assignAccountingCategoriesModalCancelButton'">Cancel</button>
		<button ejs-button type="button" [isPrimary]="true" (click)="save()" [attr.data-test-id]="'assignAccountingCategoriesModalSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>
