import { Directive, HostListener } from '@angular/core';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';

@Directive({
	selector: 'ejs-dropdownlist:[revDropdownlistHideInactiveUnlessSelected]',
})
export class DropdownlistHideInactiveUnlessSelectedDirective {
	constructor(
		private dropdownListComponent: DropDownListComponent,
	) {
	}

	@HostListener('open')
	onOpen() {
		this.dropdownListComponent.dataSource = (this.dropdownListComponent.dataSource as [])
			.filter((data: any) => !data.label.includes('(Inactive)') || data.value === this.dropdownListComponent.value);
	}
}
