// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.OrderTwelve84ConfigurationOptionResponse */
/* istanbul ignore next */
@GandalfModel
export class OrderTwelve84ConfigurationOptionResponse extends GandalfModelBase {

	@GandalfArray(OrderTwelve84ConfigurationOptionResponse)
	children: OrderTwelve84ConfigurationOptionResponse[];

	@GandalfProperty()
	defaultSelection: boolean;

	@GandalfProperty()
	locationProductId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	twelve84SmartflowCode: constants.Twelve84SmartflowCode;

}
