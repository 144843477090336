<form class="form-horizontal" [formGroup]="componentForm">
	<div class="form-group" [attr.data-test-id]="'individualBenefitsMaterialCoPayFormGroup'">
		<label class="col-sm-4 control-label">Material Co-Pay</label>
		<div class="col-sm-8">
			<pms-currency-input formControlName="materialCoPay" placeholder="" nanValue=""></pms-currency-input>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'individualBenefitsMinimumFrameAllowanceFormGroup'">
		<label class="col-sm-4 control-label">Min Frame Allowance</label>
		<div class="col-sm-8">
			<pms-currency-input formControlName="minFrameAllowance" placeholder="" nanValue=""></pms-currency-input>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'individualBenefitsMaximumFrameAllowanceFormGroup'">
		<label class="col-sm-4 control-label">Max Frame Allowance</label>
		<div class="col-sm-8">
			<pms-currency-input formControlName="maxFrameAllowance" placeholder="" nanValue=""></pms-currency-input>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'individualBenefitsContactLensAllowanceFormGroup'">
		<label class="col-sm-4 control-label">Contact Lens Allowance</label>
		<div class="col-sm-8">
			<pms-currency-input formControlName="contactLensAllowance" placeholder="" nanValue=""></pms-currency-input>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'individualBenefitsAuthorizationNumberFormGroup'">
		<label class="col-sm-4 control-label">Authorization #</label>
		<div class="col-sm-8">
			<input class="e-input form-control" formControlName="authorizationNumber" />
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'individualBenefitsAuthorizationDateFormGroup'">
		<label class="col-sm-4 control-label">Authorization Date</label>
		<div class="col-sm-8">
			<ejs-datepicker placeholder="mm/dd/yyyy"
							formControlName="authorizationDate"
							[format]="dateFormat">
			</ejs-datepicker>
		</div>
	</div>

	<div class="margin-bottom-sm">
		<div class="table-responsive">
			<table class="table table-hover" [attr.data-test-id]="'individualBenefitsEligibilityTable'">
				<thead>
				<tr>
					<th>Eligible For</th>
					<th></th>
					<th>Resets On</th>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td>Frame</td>
					<td>
						<rev-status-select-button [options]="frameOptions"
												  formControlName="hasFrameBenefit">
						</rev-status-select-button>
					</td>
					<td>
						<ejs-datepicker placeholder="mm/dd/yyyy"
										formControlName="frameBenefitResetDate"
										[format]="dateFormat">
						</ejs-datepicker>
					</td>
				</tr>
				<tr>
					<td>Lenses</td>
					<td>
						<rev-status-select-button [options]="lensOptions"
												  formControlName="hasLensBenefit">
						</rev-status-select-button>
					</td>
					<td>
						<ejs-datepicker placeholder="mm/dd/yyyy"
										formControlName="lensBenefitResetDate"
										[format]="dateFormat">
						</ejs-datepicker>
					</td>
				</tr>
				<tr>
					<td>Contact Lenses</td>
					<td>
						<rev-status-select-button [options]="contactLensOptions"
												  formControlName="hasContactLensBenefit">
						</rev-status-select-button>
					</td>
					<td>
						<ejs-datepicker placeholder="mm/dd/yyyy"
										formControlName="contactLensBenefitResetDate"
										[format]="dateFormat">
						</ejs-datepicker>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</form>
