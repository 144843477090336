// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeResponse } from './contact-lens-eye-response';

// @ts-ignore
import { DurationResponse } from './duration-response';

// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.prescription.ContactLensEyeSoftResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensEyeSoftResponse extends ContactLensEyeResponse {

	@GandalfProperty()
	addDesignation: ReferenceDataResponse;

	@GandalfProperty()
	addPower: number;

	@GandalfProperty()
	axis: number;

	@GandalfProperty()
	baseCurve: number;

	@GandalfProperty()
	cylinder: number;

	@GandalfProperty()
	diameter: number;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	isMonovision: boolean;

	@GandalfProperty()
	locationProductId: number;

	@GandalfProperty()
	locationProductItemId: number;

	@GandalfProperty()
	locationProductName: string;

	@GandalfProperty()
	overnightSchedule: DurationResponse;

	@GandalfProperty()
	productColorId: number;

	@GandalfProperty()
	productColorName: string;

	@GandalfProperty()
	replacementSchedule: DurationResponse;

	@GandalfProperty()
	sphere: number;

	@GandalfProperty()
	tint: string;

	@GandalfProperty()
	version: number;

}
