export const SECURITY_CONSTANTS = {
	RESOURCE_PATIENT_VIEW : 1,
	RESOURCE_PATIENT_VIEW_SSN : 2,
	RESOURCE_PATIENT_NOTE_OVERRIDE : 3,
	RESOURCE_PATIENT_GENERAL_MEDICATION_ADD: 6,
	RESOURCE_PATIENT_ADD_FOLDER: 20,
	RESOURCE_PATIENT_DELETE_FOLDER: 21,
	RESOURCE_PATIENT_CUT_PASTE_FOLDER: 22,
	RESOURCE_PATIENT_RENAME_FOLDER: 23,
	RESOURCE_PATIENT_CREATE_MULTIPLE_EYEGLASS_RX: 27,
	RESOURCE_PATIENT_VIEW_SAVED_CARDS: 28,
	RESOURCE_PATIENT_MANAGE_SAVED_CARDS: 29,

	RESOURCE_SCHEDULE_VIEW : 101,
	RESOURCE_SCHEDULE_CREATE : 102,
	RESOURCE_SCHEDULE_CANCEL : 103,
	RESOURCE_SCHEDULE_RESCHEDULE : 104,
	RESOURCE_SCHEDULE_SLOT_CREATE : 105,
	RESOURCE_SCHEDULE_SLOT_EDIT : 106,
	RESOURCE_SCHEDULE_SLOT_DELETE : 107,
	RESOURCE_SCHEDULE_BUSY_TIME_CREATE : 108,
	RESOURCE_SCHEDULE_BUSY_TIME_EDIT : 109,
	RESOURCE_SCHEDULE_BUSY_TIME_DELETE : 110,
	RESOURCE_SCHEDULE_OFF_CLOSED_CREATE : 111,
	RESOURCE_SCHEDULE_OFF_CLOSED_EDIT : 112,
	RESOURCE_SCHEDULE_OFF_CLOSED_DELETE : 113,
	RESOURCE_SCHEDULE_EMP_HOURS_CREATE : 114,
	RESOURCE_SCHEDULE_EMP_HOURS_EDIT : 115,
	RESOURCE_SCHEDULE_EMP_HOURS_DELETE : 116,
	RESOURCE_SCHEDULE_REPORTS : 117,
	RESOURCE_SCHEDULE_EMP_HOURS_OWN : 118,

	RESOURCE_ACCOUNTING_VIEW_INVOICES : 201,
	RESOURCE_ACCOUNTING_VIEW_PAYMENTS : 228,
	RESOURCE_ACCOUNTING_VIEW_CLAIMS : 250,
	RESOURCE_ACCOUNTING_INVOICE_CREATE : 202,
	RESOURCE_ACCOUNTING_INVOICE_VOID : 203,
	RESOURCE_ACCOUNTING_PAYMENT_RECEIVE : 204,
	RESOURCE_ACCOUNTING_PAYMENT_VOID : 205,
	RESOURCE_ACCOUNTING_DISCOUNT : 206,
	RESOURCE_ACCOUNTING_WRITEOFF : 207,
	RESOURCE_ACCOUNTING_REFUND : 208,
	RESOURCE_ACCOUNTING_CREDIT : 209,
	RESOURCE_ACCOUNTING_CREDIT_VOID : 212,
	RESOURCE_ACCOUNTING_REFUND_VOID : 213,
	RESOURCE_ACCOUNTING_STATEMENT_SEND : 214,
	RESOURCE_ACCOUNTING_ITEM_REMOVE : 215,
	RESOURCE_ACCOUNTING_EDIT_INV_FIN_CHG : 226,
	RESOURCE_ACCOUNTING_UNAUTH_FIN_CHG_INV : 227,
	RESOURCE_ACCOUNTING_INVOICE_ENCOUNTER : 229,
	RESOURCE_ACCOUNTING_INVOICE_DATE : 230,
	RESOURCE_ACCOUNTING_INVOICE_PROVIDER : 231,
	RESOURCE_ACCOUNTING_BILLING : 232,
	RESOURCE_ACCOUNTING_VOID_OPENEDGE_PAYMENT: 246,
	RESOURCE_ACCOUNTING_REFUND_OPENEDGE_PAYMENT: 247,
	RESOURCE_ACCOUNTING_VOID_OPENEDGE_REFUND: 248,
	RESOURCE_ACCOUNTING_PAYMENT_EDIT: 249,

	RESOURCE_INVENTORY_VIEW : 301,
	RESOURCE_INVENTORY_EDIT_PRICE : 302,
	RESOURCE_INVENTORY_EDIT_QUANTITY : 303,
	RESOURCE_INVENTORY_COPY_PRODUCTS : 304,
	RESOURCE_INVENTORY_COPY_SERVICES : 305,
	RESOURCE_INVENTORY_UPDATE_PROPERTIES : 306,
	RESOURCE_INVENTORY_UPDATE_PRICING : 307,
	RESOURCE_INVENTORY_EDIT : 308,
	RESOURCE_INVENTORY_VIEW_ACTUAL_OR_UNIT_COST : 309,

	RESOURCE_ORDERS_VIEW : 401,
	RESOURCE_ORDERS_CREATE : 402,
	RESOURCE_ORDERS_CANCEL : 403,
	RESOURCE_ORDERS_EDIT : 404,
	RESOURCE_ORDERS_CHANGE : 405,
	RESOURCE_ORDERS_COMPLETE : 406,
	RESOURCE_ORDERS_DUPLICATE : 407,
	RESOURCE_ORDERS_STATUS_UPDATE : 408,
	RESOURCE_ORDERS_REMAKE : 409,
	RESOURCE_ORDERS_MARK_NON_BILLABLE : 410,
	RESOURCE_ORDERS_STATUS_EDIT : 411,
	RESOURCE_ORDERS_VIEW_ORDER_COSTS : 412,
	RESOURCE_ORDERS_EDIT_ORDER_COSTS : 413,
	RESOURCE_ORDERS_RETURN : 414,
	RESOURCE_ORDERS_ON_HOLD: 415,

	RESOURCE_MARKETING_VIEW : 501,
	RESOURCE_REPORTS_PATIENTS : 511,
	RESOURCE_MARKETING_PATIENT_SEARCH_CREATE : 502,
	RESOURCE_MARKETING_PATIENT_SEARCH_EDIT : 503,
	RESOURCE_MARKETING_PATIENT_SEARCH_DELETE : 504,
	RESOURCE_MARKETING_PATIENT_SEARCH_EXECUTE : 505,
	RESOURCE_REPORTS_SCHEDULE : 506,
	RESOURCE_REPORTS_APPOINTMENTS : 512,
	RESOURCE_REPORTS_APPOINTMENT_SEARCH : 513,
	RESOURCE_REPORTS_ENCOUNTERS : 514,
	RESOURCE_REPORTS_ENCOUNTER_SEARCH : 515,
	RESOURCE_REPORTS_SCHEDULE_CUSTOM : 516,
	RESOURCE_ACCOUNTING_REPORTS : 210,
	RESOURCE_ACCOUNTING_REPORT_LEDGER_POSTING : 211,
	RESOURCE_ACCOUNTING_REPORT_UNASSIGNED_ITEMS : 216,
	RESOURCE_ACCOUNTING_REPORT_AGING : 217,
	RESOURCE_ACCOUNTING_REPORT_DEPOSITS : 218,
	RESOURCE_ACCOUNTING_REPORT_LEDGER : 219,
	RESOURCE_ACCOUNTING_REPORT_LEDGER_DAILY : 220,
	RESOURCE_ACCOUNTING_REPORT_SALES : 221,
	RESOURCE_ACCOUNTING_REPORT_RECEIPTS : 222,
	RESOURCE_ACCOUNTING_REPORT_REFUNDS : 223,
	RESOURCE_ACCOUNTING_SEARCH_INVOICES : 224,
	RESOURCE_ACCOUNTING_SEARCH_INVOICE_ITEMS : 225,
	RESOURCE_ACCOUNTING_ADD_ADHOC: 245,
	RESOURCE_REPORTS_ORDERS : 507,
	RESOURCE_REPORTS_TASKS : 508,
	RESOURCE_REPORTS_INVENTORY : 509,
	RESOURCE_REPORTS_ADMIN : 510,
	RESOURCE_REPORTS_INVENTORY_MIN_MAX : 517,
	RESOURCE_REPORTS_INVENTORY_COST : 518,
	RESOURCE_REPORTS_INVENTORY_AGE : 519,
	RESOURCE_REPORTS_INVENTORY_SALES : 520,
	RESOURCE_REPORTS_INVENTORY_PRODUCT_SEARCH : 521,
	RESOURCE_REPORTS_INVENTORY_SERVICE_SEARCH : 522,
	RESOURCE_REPORTS_INVENTORY_CUSTOM_REPORTS : 522,

	RESOURCE_ADMIN_VIEW : 601,
	RESOURCE_ADMIN_GENERAL : 602,
	RESOURCE_ADMIN_EMPLOYEE : 603,
	RESOURCE_ADMIN_DATA : 604,
	RESOURCE_ADMIN_TASKS : 605,
	RESOURCE_ADMIN_ENCOUNTERS : 606,
	RESOURCE_ADMIN_DOCUMENTS : 607,
	RESOURCE_ADMIN_COMMUNICATION : 608,
	RESOURCE_ADMIN_TRIGGERS : 612,
	RESOURCE_ADMIN_VENDORS : 609,
	RESOURCE_ADMIN_PATIENTS : 610,
	RESOURCE_ADMIN_ACCOUNTING : 611,
	RESOURCE_ADMIN_PRACTICE_CLAIMS_PROCESSING_SETTINGS : 620,
	RESOURCE_ADMIN_EXPORT_PATIENT_CCDA_FILES : 621,
	RESOURCE_ADMIN_IMPORT_QRDA_FILES : 622,
	RESOURCE_ADMIN_EMPLOYEE_LIMITED_VIEW : 623,
	RESOURCE_ADMIN_EDIT_PAYMENT_PROCESSOR_INFO : 630,
	RESOURCE_ADMIN_EDIT_REVDIRECT_INFO : 631,
	RESOURCE_ADMIN_EDIT_CLEARINGHOUSE_INFO : 632,
	RESOURCE_ADMIN_EDIT_EMPLOYEE_USERNAME : 633,
	RESOURCE_ADMIN_SOLUTIONS : 634,
	RESOURCE_ADMIN_VIEW_PARTNER_INTEGRATIONS: 635,

	RESOURCE_CONNECT_VIEW : 801,
	RESOURCE_CONNECT_VIEW_DASHBOARD : 802,
	RESOURCE_CONNECT_VIEW_SETTINGS : 803,
	RESOURCE_CONNECT_EDIT_SETTINGS : 804,
	RESOURCE_CONNECT_COPY_LOCATIONS : 805,
	RESOURCE_CONNECT_CAMPAIGN_VIEW : 806,
	RESOURCE_CONNECT_CAMPAIGN_SEND_UPDATE : 807,
	RESOURCE_CONNECT_VIEW_TWO_WAY_TEXT : 808,

	RESOURCE_REVENGAGE_ADMIN : 901,
	RESOURCE_REVENGAGE_SPECIALIST : 902,
	RESOURCE_REVENGAGE_STAFF : 903,
};
