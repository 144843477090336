// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.shared.common.BusinessPhonesResponse */
/* istanbul ignore next */
@GandalfModel
export class BusinessPhonesResponse extends GandalfModelBase {

	@GandalfProperty()
	altPhone: string;

	@GandalfProperty()
	emergencyPhone: string;

	@GandalfProperty()
	fax: string;

	@GandalfProperty()
	mainPhone: string;

	@GandalfProperty()
	tollFree: string;

}
