import { Injectable } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { BetaSystemCode, ExternalSystem, InsuranceClaimProcessorName } from '@gandalf/constants';

export interface PracticeLocation {
	id: number;
	isLocationManager?: boolean;
	locationBarcodeIdentifier?: string;
	name: string;
	openEdgeIsActive: boolean;
	printNameAs?: string;
	status?: number;
	twelve84OrderProcessorConfigId?: number;
	twelve84VendorId?: number;
	vspOrderProcessorConfigId?: number;
}

/* istanbul ignore file */
@Injectable({
	providedIn: 'root',
})
export class SecurityManagerService {

	constructor() {
	}

	get userData() {
		return window.securityManager.userData;
	}

	get environmentData() {
		return window.securityManager.environmentData;
	}

	get sessionData() {
		return this.userData.sessionData;
	}

	hasPermission(permissionId: number) {
		return window.securityManager.hasPermission(permissionId);
	}

	checkLogin() {
		return window.securityManager.checkLogin();
	}

	logout(timedOut, useAjax, forceLogout) {
		return window.securityManager.logout(timedOut, useAjax, forceLogout);
	}

	getUserSession() {
		return window.securityManager.getUserSession();
	}

	getPracticeLocations() {
		return window.securityManager.getPracticeLocations();
	}

	getActivePracticeLocations(): PracticeLocation[] {
		return window.securityManager.getActivePracticeLocations();
	}

	getPracticeLocationById(id: number) {
		return window.securityManager.getPracticeLocationById(id);
	}

	getUserLocations(): PracticeLocation[] {
		return window.securityManager.getUserLocations();
	}

	getUserActiveLocations(): PracticeLocation[] {
		return window.securityManager.getUserActiveLocations();
	}

	createSecurityContext() {
		return window.securityManager.createSecurityContext();
	}

	getUserLocationById(id: number): PracticeLocation {
		return window.securityManager.getUserLocationById(id);
	}

	preferenceValueIsOn(preferenceKey: string, preferenceDefault: string) {
		return window.securityManager.preferenceValueIsOn(preferenceKey, preferenceDefault);
	}

	preferenceValue(preferenceKey: string) {
		return window.securityManager.preferenceValue(preferenceKey);
	}

	getUserDefaultLocation() {
		return window.securityManager.getUserDefaultLocation();
	}

	hasBeta(betaSystemCode: BetaSystemCode) {
		return window.securityManager.hasBeta(betaSystemCode.value);
	}

	hasBetaAndPermission(betaSystemCode: BetaSystemCode, permissionId: number) {
		return this.hasBeta(betaSystemCode) && this.hasPermission(permissionId);
	}

	serviceFactory() {
		return window.securityManager.serviceFactory;
	}

	userCanAccessExternalSystem(externalSystem: ExternalSystem) {
		return window.securityManager.userCanAccessExternalSystem(externalSystem.value);
	}

	practiceHasRxntEnabled() {
		return window.securityManager.getUserSession().practiceHasRxntEnabled;
	}

	userIsProvider() {
		return !_isNil(this.sessionData.providerId);
	}

	getUserProviderId() {
		return this.sessionData.providerId;
	}

	getPracticeAffiliations() {
		return window.securityManager.userData.sessionData.affiliationIds;
	}

	isCanada(): boolean {
		return window.securityManager.isCanada();
	}

	hasAffiliation(id: number) {
		return !_isNil(this.getPracticeAffiliations().find(affiliation => affiliation === id));
	}

	allowConnectAppointmentReminders(): boolean {
		return window.securityManager.allowConnectAppointmentReminders();
	}

	allowConnectRecallReminders(): boolean {
		return window.securityManager.allowConnectRecallReminders();
	}

	allowConnectRecareReminders(): boolean {
		return window.securityManager.allowConnectRecareReminders();
	}

	allowConnectOrderNotifications(): boolean {
		return window.securityManager.allowConnectOrderNotifications();
	}

	allowConnectBirthdayMessages(): boolean {
		return window.securityManager.allowConnectBirthdayMessages();
	}

	allowConnectCampaigns(): boolean {
		return window.securityManager.allowConnectCampaigns();
	}

	allowTwoWayTexting(): boolean {
		return this.sessionData.connectSubscription?.allowTwoWayTexting ?? false;
	}

	getUserName() {
		return this.getUserSession().user.userName;
	}

	practiceHasGrandfatheredTriZetto() {
		return this.sessionData.hasGrandfatheredTriZetto;
	}

	practiceHasRevClearSubscription() {
		return this.sessionData.hasElectronicClaims || this.sessionData.hasPatientStatements;
	}

	practiceHasRevClearEraSubscription() {
		return this.sessionData.hasElectronicRemittanceAdvice;
	}

	providerWithNPIAndSmartApplicationEnabled() {
		return this.sessionData.hasProviderWithNpiAndSmartApplicationEnabled;
	}

	practiceHasAnyConnectSolution(): boolean {
		return this.allowConnectAppointmentReminders()
			|| this.allowConnectRecallReminders()
			|| this.allowConnectRecareReminders()
			|| this.allowConnectOrderNotifications()
			|| this.allowConnectBirthdayMessages()
			|| this.allowConnectCampaigns();
	}

	practiceHasAnyNonCampaignsSolution(): boolean {
		return this.allowConnectAppointmentReminders()
			|| this.allowConnectRecallReminders()
			|| this.allowConnectRecareReminders()
			|| this.allowConnectOrderNotifications()
			|| this.allowConnectBirthdayMessages();
	}

	practiceClearingHouseIsRevClear(): boolean {
		return this.sessionData.claimsProcessorId === InsuranceClaimProcessorName.TRIZETTO.value;
	}

	customerHasLicenseAndBaaOnFile(): boolean {
		return this.sessionData.hasLicenseAndBaaOnFile;
	}

}
