import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { EmployeeDropdownResponse, EmployeeService } from '@core/employee/employee.service';
import { DialogUtil, DynamicModalRef, EnumUtil, GridUtil, ModalConfig, ModalManagerService, SortingService } from 'morgana';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { _cloneDeep, _filter, _find, _isEmpty, _isNil, _isNull, _map, _remove } from '@core/lodash/lodash';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { InvoiceItemPersonDiagnosisResponse } from '@gandalf/model/invoice-item-person-diagnosis-response';
import { InvoiceItemAdjustmentResponse } from '@gandalf/model/invoice-item-adjustment-response';
import { InvoiceItemDetailResponse } from '@gandalf/model/invoice-item-detail-response';
import { InvoiceResponse } from '@gandalf/model/invoice-response';
import { UpdateInvoiceItemRequest } from '@gandalf/model/update-invoice-item-request';
import {
	BetaSystemCode,
	ClaimNoteCode,
	CodeSet,
	DosageUnitCode,
	FacilityType,
	InvoiceItemAdjustmentStatus,
	InvoiceItemAdjustmentType,
	InvoiceItemStatus,
	InvoiceItemType,
	InvoiceStatus,
	RxQualifier
} from '@gandalf/constants';
import { PersonDiagnosisResponse } from '@gandalf/model/person-diagnosis-response';
import { ProviderResponse } from '@gandalf/model/provider-response';
import { BaseComponent } from '@shared/component/base.component';
import { SelectExternalProviderModalComponent } from '@shared/component/select-external-provider-modal/select-external-provider-modal.component';
import { DATE_FORMATS, TABLE_DATE_FORMATS } from '@shared/constants/date-format.constants';
import { assertTrue } from '@shared/validators/assert-true.validation';
import { dateRangeValidator } from '@shared/validators/date-range-validation';
import { SortSettingsModel } from '@syncfusion/ej2-angular-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { Query } from '@syncfusion/ej2-data';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { GandalfFormBuilder } from 'gandalf';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BACKGROUND_COLOR_CONSTANTS } from '@shared/constants/background-colors.constants';
import { uniqueValuesValidator } from '@shared/validators/unique-values-validator';
import { AccountingService, FormattedModifierResponse, FormattedVendorSummaryResponse } from '../../core/accounting/accounting.service';
import { InvoiceService } from '../../core/accounting/invoice-service/invoice.service';

@Component({
	selector: 'pms-invoice-item-details-modal',
	templateUrl: './invoice-item-details-modal.component.html',
	styles: [],
	providers: [ModalManagerService],
})
export class InvoiceItemDetailsModalComponent extends BaseComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('templateForm')
	templateForm: NgForm;

	@ViewChild('selectedDiagnosesAgGrid')
	selectedDiagnosesAgGrid: AgGridAngular;

	@ViewChild('availableDiagnosesAgGrid')
	availableDiagnosesAgGrid: AgGridAngular;

	@ViewChild('diagnosesCodeColumnTemplate')
	diagnosesCodeColumnTemplate: TemplateRef<any>;

	@ViewChild('diagnosesRemoveButtonColumnTemplate')
	diagnosesRemoveButtonColumnTemplate: TemplateRef<any>;

	@ViewChild('diagnosesAddButtonColumnTemplate')
	diagnosesAddButtonColumnTemplate: TemplateRef<any>;

	invoice: InvoiceResponse;
	request: UpdateInvoiceItemRequest;
	selectedDiagnoses: InvoiceItemPersonDiagnosisResponse[] = [];
	availableDiagnoses: PersonDiagnosisResponse[] = [];
	disableForm = true;
	isSearching = true;
	hasPatient = false;
	sortQuery: Query;
	componentForm: UntypedFormGroup;
	invoiceId: number;
	itemId: number;
	isInvoiceFrozen: boolean;
	openedFromInvoiceDetailsModal: boolean;
	allVendors: FormattedVendorSummaryResponse[];
	invoiceItemDetails: InvoiceItemDetailResponse;
	modifiers: FormattedModifierResponse[];
	facilityTypes;
	claimNoteCodes = EnumUtil.buildGandalfEnumSubsetList(EnumUtil.removeValues(ClaimNoteCode.VALUES.values, [ClaimNoteCode.NONE]), 'Claim Note Codes');
	dosageUnitCodes;
	rxQualifiers;
	serviceLocationTooltip = '';
	claimNoteCodeTooltip = '';
	claimNoteTooltip = '';
	isAdhoc = false;
	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	tableDateFormat = TABLE_DATE_FORMATS.MM_DD_YYYY;
	hasServiceModifier = false;
	serviceModifier;
	modifierTooltip = '';
	additionalModifierTooltip0 = '';
	additionalModifierTooltip1 = '';
	additionalModifierTooltip2 = '';
	additionalModifierTooltip3 = '';
	icd9FilterFlagOn = false;
	isCanada = false;
	gridIcd9Filter = false;
	employeeListByPersonId: EmployeeDropdownResponse[];
	itemAdjustments: InvoiceItemAdjustmentResponse[];
	itemAdjustmentsToRemove: number[] = [];
	adjustmentSortSettings: SortSettingsModel = {
		columns: [
			{field: 'id', direction: 'Ascending'},
		],
	};
	selectedDxGridOptions: GridOptions = GridUtil.buildGridOptions({
		rowClass: 'row-link',
		getRowClass: params => this.applyBackgroundColorAgGrid(params),
	});
	availableDxGridOptions: GridOptions = GridUtil.buildGridOptions({
		rowClass: 'row-link',
		getRowClass: params => this.applyBackgroundColorAgGrid(params),
	});
	selectedDiagnosesGridColumns: ColDef[];
	availableDiagnosesGridColumns: ColDef[];

	constructor(
		private ref: DynamicModalRef,
		private modalConfig: ModalConfig,
		private accountingService: AccountingService,
		private gandalfFormBuilder: GandalfFormBuilder,
		private employeeService: EmployeeService,
		private invoiceService: InvoiceService,
		private modalManagerService: ModalManagerService,
		private securityManagerService: SecurityManagerService,
		public featureService: FeatureService,
	) {
		super();
	}

	ngOnInit() {
		this.isCanada = this.securityManagerService.isCanada();
		this.icd9FilterFlagOn = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.PATIENTS.DIAGNOSES.ICD9_FILTER);
		this.parseConfigData(this.modalConfig.data);
		this.facilityTypes = this.setEnumLabels(FacilityType.VALUES);
		this.dosageUnitCodes = this.setEnumLabels(DosageUnitCode.VALUES);
		this.rxQualifiers = this.setEnumLabels(RxQualifier.VALUES);
		this.initializeForm();
		this.getFormData();
		if (this.isInvoiceFrozen) {
			this.componentForm.disable();
		}
	}

	showIcd9Filter() {
		return !this.isCanada && this.icd9FilterFlagOn;
	}

	parseConfigData(data: any) {
		this.itemId = data.itemId;
		this.invoiceId = data.invoiceId;
		this.isInvoiceFrozen = data.isInvoiceFrozen;
		this.openedFromInvoiceDetailsModal = data.openedFromInvoiceDetailsModal;
	}

	updateInvoiceDetailsModalInvoice() {
		if (this.openedFromInvoiceDetailsModal) {
			this.invoiceService.refreshInvoiceDetailsInvoice(this.invoiceId);
		}
	}

	getFormData() {
		this.disableForm = true;
		this.componentForm.disable();
		combineLatest([
			this.accountingService.getInvoiceItemDetail(this.itemId),
			this.accountingService.findAlternateFacilities(),
			this.accountingService.findPracticeModifiers(),
			this.employeeService.findActiveEmployeesWithPersonIdForDropdown(),
			this.accountingService.getInvoiceById(this.invoiceId),
		]).subscribe(([item, vendors, modifiers, employees, invoice]) => {
			this.invoice = invoice;
			this.invoiceItemDetails = item;
			this.allVendors = vendors;
			this.selectedDiagnoses = item.additionalDiagnoses;
			this.isAdhoc = EnumUtil.equals(item.type, InvoiceItemType.AD_HOC);
			this.itemAdjustments = _filter(item.itemAdjustments, adjustment => EnumUtil.equals(adjustment.status, InvoiceItemAdjustmentStatus.ACTIVE));
			this.modifiers = modifiers;
			this.hasServiceModifier = !_isEmpty(item.modifier);
			if (this.hasServiceModifier) {
				this.checkForMissingModifier(item);
			}
			this.employeeListByPersonId = employees;
			this.componentForm.enable();
			FormUtilsService.enabledWhen(this.componentForm.get('responsiblePersonId'), EnumUtil.equalsOneOf(invoice.status, InvoiceStatus.ACTIVE, InvoiceStatus.COLLECTIONS));
			if (invoice.patientId) {
				this.hasPatient = true;
				this.accountingService.findAvailableDiagnosesByInvoiceId(this.invoiceId).subscribe((diagnoses) => {
					this.availableDiagnoses = diagnoses;
				});
			}
			this.componentForm.patchValue(item);
			this.componentForm.controls.additionalDiagnoses.setValue([]);
			this.componentForm.controls.itemAdjustmentIdsToRemove.setValue([]);
		});
	}

	checkForMissingModifier(item: InvoiceItemDetailResponse) {
		const foundModifier = _find(this.modifiers, modifier => modifier.code === item.modifier);
		if (foundModifier) {
			this.serviceModifier = foundModifier.value;
		} else {
			this.accountingService.getModifierByInvoiceItemId(item.invoiceItemId).subscribe((modifier) => {
				this.setFoundModifier(modifier);
			});
		}
	}

	setFoundModifier(modifier: FormattedModifierResponse) {
		this.serviceModifier = modifier.value;
		this.modifiers.push(modifier);
		this.modifiers = SortingService.sortBy(this.modifiers, ['code']);
	}

	initializeForm() {
		this.request = new UpdateInvoiceItemRequest();
		this.componentForm = this.gandalfFormBuilder.group(this.request, {
			validators: [
				dateRangeValidator(
					'serviceStartDate',
					'serviceEndDate',
					'serviceDateRange',
					'The start date cannot be after the end date',
				),
				assertTrue(
					() => this.componentForm ? _isNil(this.componentForm.get('serviceStartDate').value) === _isNil(this.componentForm.get('serviceEndDate').value) : false,
					['serviceStartDate', 'serviceEndDate'],
					'bothDatesOrNeither',
					'Both start date and end date must be set, or neither should be set',
				),
				uniqueValuesValidator(
					['additionalModifierId0', 'additionalModifierId1', 'additionalModifierId2', 'additionalModifierId3'],
					'uniqueModifiers',
					'The same modifier cannot be selected more than once',
				),
			],
		});
		this.componentForm.get('serviceLocationId').valueChanges.pipe(takeUntil(this.unsubscribe))
			.subscribe(id => this.serviceLocationTooltip = this.getSelectTooltip(id, this.allVendors));
		this.componentForm.get('additionalModifierId0').valueChanges.pipe(takeUntil(this.unsubscribe))
			.subscribe(id => this.additionalModifierTooltip0 = this.getSelectTooltip(id, this.modifiers));
		this.componentForm.get('additionalModifierId1').valueChanges.pipe(takeUntil(this.unsubscribe))
			.subscribe(id => this.additionalModifierTooltip1 = this.getSelectTooltip(id, this.modifiers));
		this.componentForm.get('additionalModifierId2').valueChanges.pipe(takeUntil(this.unsubscribe))
			.subscribe(id => this.additionalModifierTooltip2 = this.getSelectTooltip(id, this.modifiers));
		this.componentForm.get('additionalModifierId3').valueChanges.pipe(takeUntil(this.unsubscribe))
			.subscribe(id => this.additionalModifierTooltip3 = this.getSelectTooltip(id, this.modifiers));
		this.componentForm.get('claimNote').valueChanges.pipe(takeUntil(this.unsubscribe))
			.subscribe(value => this.claimNoteTooltip = value);
		this.componentForm.get('claimNoteCode').valueChanges.pipe(takeUntil(this.unsubscribe))
			.subscribe(value => this.claimNoteCodeTooltip = _isNull(value) ? '' : value.label);
	}

	getSelectTooltip(id: number, itemList): string {
		if (id) {
			const selectedItem = _find(itemList, item => item.id === id);
			return selectedItem.label;
		} else {
			return '';
		}
	}

	closeModal() {
		this.invoiceService.removeInvoiceDetailsState(this.invoiceId);
		this.ref.close(this.modal, null);
	}

	/**
	 * Adds the value in front of the constant label, since this is the expected format for this modal
	 */
	setEnumLabels(enumValues) {
		const constantList = _cloneDeep(enumValues);
		constantList.values.map(constant => {
			if (constant['_label'].length > 0) {
				constant['_label'] = `${constant.value} - ${constant.label}`;
			}
		});
		return constantList;
	}

	/**
	 * Makes final updates to the request and then saves it if the component form is valid
	 */
	updateInvoiceItemDetail() {
		const request = this.componentForm.value as UpdateInvoiceItemRequest;
		if (this.componentForm.valid) {
			request.additionalDiagnoses = _map(this.selectedDiagnoses, diagnosis => diagnosis.personDiagnosisId);
			request.itemAdjustmentIdsToRemove = this.itemAdjustmentsToRemove;

			this.accountingService.updateInvoiceItemDetail(request).subscribe(() => {
				this.invoiceService.refreshInvoice(this.invoiceId);
				this.updateInvoiceDetailsModalInvoice();
				this.closeModal();
			});
		}
	}

	/**
	 * Add a row to the grid.
	 */
	addToSelected(data: PersonDiagnosisResponse) {
		if (!_find(this.selectedDiagnoses, diagnosis => diagnosis.personDiagnosisId === data.id)) {
			this.selectedDiagnoses.push(this.formatAvailableToSelected(data));
			this.selectedDiagnosesAgGrid.api.setGridOption('rowData', this.selectedDiagnoses);
		}
	}

	formatAvailableToSelected(data: PersonDiagnosisResponse): InvoiceItemPersonDiagnosisResponse {
		const newSelectedDiagnoses = new InvoiceItemPersonDiagnosisResponse();
		newSelectedDiagnoses.personDiagnosisId = data.id;
		newSelectedDiagnoses.practiceDiagnosis = data.practiceDiagnosis;
		return newSelectedDiagnoses;
	}

	/**
	 * Remove a row from the grid.
	 */
	/* istanbul ignore next */
	removeFromSelected(data: InvoiceItemPersonDiagnosisResponse) {
		_remove(this.selectedDiagnoses, diagnosis => diagnosis.personDiagnosisId === data.personDiagnosisId);
		this.selectedDiagnosesAgGrid.api.setGridOption('rowData', this.selectedDiagnoses);
	}

	/**
	 * Called when a grid item is doubleclicked in the view. Since the cursor isn't removed from the item we first need to close the tooltip
	 */
	availableDiagnosesGridDoubleclick(event: any) {
		this.addToSelected(event.data);
	}

	/**
	 * Called when a grid item is doubleclicked in the view. Since the cursor isn't removed from the item we first need to close the tooltip
	 */
	selectedDiagnosesGridDoubleclick(event: any) {
		this.removeFromSelected(event.data);
	}

	/**
	 * Used to determine if a diagnosis is ICD10 and disabled.  If it is the tooltip in the view read differently.
	 */
	isCodeIcd10AndDisabled(personDiagnosis: PersonDiagnosisResponse) {
		return personDiagnosis.practiceDiagnosis.masterCodeSetId === CodeSet.ICD10.value && !personDiagnosis.practiceDiagnosis.active;
	}

	applyBackgroundColorAgGrid(params: any) {
		if (this.isCodeIcd10AndDisabled(params.node.data as PersonDiagnosisResponse)) {
			return BACKGROUND_COLOR_CONSTANTS.DANGER;
		}
	}

	/**
	 * Remove Adjustment Rules:
	 * Adjustment status must be active
	 * Adjustment type must not be a fee schedule (group)
	 * Adjustment type must not be a patient tax (group)
	 * Adjustment type must not be an insurance billed (group)
	 * Adjustment type must not be an other billed (group)
	 * Item status must be active
	 * Containing invoice must be active
	 * Containing invoice must not yet be approved
	 */
	isAdjustmentRemovable(adjustment: InvoiceItemAdjustmentResponse) {
		return EnumUtil.equals(adjustment.status, InvoiceItemAdjustmentStatus.ACTIVE)
			&& !EnumUtil.equals(adjustment.type, InvoiceItemAdjustmentType.FEE_SCHEDULE)
			&& !EnumUtil.equals(adjustment.type, InvoiceItemAdjustmentType.PATIENT_TAX)
			&& !EnumUtil.equals(adjustment.type, InvoiceItemAdjustmentType.INSURANCE_BILLED)
			&& !EnumUtil.equals(adjustment.type, InvoiceItemAdjustmentType.OTHER_BILLED)
			&& EnumUtil.equals(this.invoiceItemDetails.status, InvoiceItemStatus.ACTIVE)
			&& EnumUtil.equals(this.invoice.status, InvoiceStatus.ACTIVE)
			&& !this.invoice.approved;
	}

	/**
	 * Toggles whether or not a given adjustment should be removed when the invoice item is updated
	 */
	toggleRemoveAdjustment(checked: Event, id: number) {
		if (checked) {
			this.itemAdjustmentsToRemove.push(id);
		} else {
			_remove(this.itemAdjustmentsToRemove, adjustmentId => adjustmentId === id);
		}
	}

	/* istanbul ignore next */
	submitForm($event: MouseEvent) {
		this.templateForm.onSubmit($event);
	}

	/* istanbul ignore next */
	promptToClearExternalProvider() {
		const confirmClear = DialogUtil.confirm({
			title: 'Disassociate Provider',
			content: '<p>Are you sure want to disassociate this provider from the item?</p>',
			okButton: {
				click: () => {
					confirmClear.close();
					this.clearExternalProvider();
				},
			},
		});
	}

	/* istanbul ignore next */
	openSelectProviderModal() {
		this.modalManagerService.open(SelectExternalProviderModalComponent, {})
			.onClose.subscribe((provider: ProviderResponse) => {
				if (provider) {
					this.setSelectedExternalProvider(provider);
				}
			});
	}

	clearExternalProvider() {
		this.componentForm.get('externalProviderId').setValue(null);
		this.invoiceItemDetails.externalProviderId = null;
		this.invoiceItemDetails.formattedExternalProviderName = null;
	}

	setSelectedExternalProvider(provider: ProviderResponse) {
		this.componentForm.get('externalProviderId').setValue(provider.providerId);
		this.invoiceItemDetails.externalProviderId = provider.providerId;
		this.invoiceItemDetails.formattedExternalProviderName = `${provider.lastName}, ${provider.firstName}`;
	}

	/* istanbul ignore next */
	showBetaFeatureExternalProvider() {
		return this.securityManagerService.hasBeta(BetaSystemCode.ITEM_EXTERNAL_PROVIDER);
	}

	filterAvailableDiagnosesAgGrid() {
		GridUtil.applyFilter(this.availableDiagnosesAgGrid, this.applyCodeSetFilter, 'practiceDiagnosisCodeSet', CodeSet.ICD9.label, 'notEqual');
	}

	applyCodeSetFilter = () => this.showIcd9Filter() && !this.gridIcd9Filter;

	onSelectedDiagnosesAgGridReady(_params: GridReadyEvent) {
		this.buildSelectedDiagnosesGridColumns();
		this.selectedDiagnosesAgGrid.api.setGridOption('columnDefs', this.selectedDiagnosesGridColumns);
	}

	onAvailableDiagnosesAgGridReady(_params: GridReadyEvent) {
		this.buildAvailableDiagnosesGridColumns();
		this.availableDiagnosesAgGrid.api.setGridOption('columnDefs', this.availableDiagnosesGridColumns);
	}

	buildSelectedDiagnosesGridColumns() {
		this.selectedDiagnosesGridColumns = [
			GridUtil.buildTemplateColumn('Selected Diagnoses', 'id', this.diagnosesCodeColumnTemplate, {
				sortable: false,
				resizable: false,
				flex: 1,
			}),
			GridUtil.buildButtonColumn('', this.diagnosesRemoveButtonColumnTemplate, {
				sortable: false,
				resizable: false,
				width: 45,
			}),
		];
	}

	buildAvailableDiagnosesGridColumns() {
		this.availableDiagnosesGridColumns = [
			GridUtil.buildTemplateColumn('Available Diagnoses', 'id', this.diagnosesCodeColumnTemplate, {
				sortable: false,
				resizable: false,
				flex: 1,
			}),
			GridUtil.buildButtonColumn('', this.diagnosesAddButtonColumnTemplate, {
				sortable: false,
				resizable: false,
				width: 45,
			}),
			GridUtil.buildColumn('Code Set', 'practiceDiagnosis.codeSet', {
				colId: 'practiceDiagnosisCodeSet',
				hide: true,
			}),
		];
	}

	get showDefaultExternalProviderSelection() {
		return this.canEditExternalProvider && !this.invoiceItemDetails?.externalProviderId;
	}

	get canEditExternalProvider() {
		if (_isNil(this.invoice)) {
			return false;
		}
		return EnumUtil.equalsOneOf(this.invoice.status, InvoiceStatus.ACTIVE, InvoiceStatus.COLLECTIONS) && !this.isInvoiceFrozen;
	}
}
