import { ChangeDetectorRef, Component, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { _isNil } from '@core/lodash/lodash';
import { ObjectUtils, OptionItem } from 'morgana';

@Component({
	selector: 'pms-radio-button-group',
	templateUrl: './radio-button-group.component.html',
	styles: [],
})
export class RadioButtonGroupComponent implements ControlValueAccessor {

	@Input()
	name: string;

	@Input()
	options: OptionItem[];

	@Input()
	disabled: boolean;

	@Input()
	useGandalfConstants = false;

	@Output()
	valueChange: EventEmitter<any> = new EventEmitter();

	@Output()
	optionClick: EventEmitter<any> = new EventEmitter();

	focusedItem: HTMLDivElement;

	value: any;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		@Optional() @Self() public ngControl: NgControl,
	) {
		this.ngControl.valueAccessor = this;
	}

	onModelChange: (_: any) => void = () => {
	};

	onModelTouched: () => void = () => {
	};

	writeValue(value: any): void {
		this.value = this.useGandalfConstants ? value.value : value;
		setTimeout(() => {
			this.changeDetectorRef.markForCheck();
		});
	}

	registerOnChange(fn: (_: any) => void): void {
		this.onModelChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onModelTouched = fn;
	}

	onItemClick(event, option: OptionItem, index: number) {
		if (this.disabled || option.disabled) {
			return;
		}

		this.optionClick.emit({
			originalEvent: event,
			option,
			index,
		});

		if (this.value !== option.value) {
			this.value = option.value;

			this.onModelChange(this.useGandalfConstants ? option : option.value);
			this.valueChange.emit({
				originalEvent: event,
				value: this.value,
			});
		}
	}

	onFocus(event: Event) {
		this.focusedItem = event.target as HTMLDivElement;
	}

	onBlur(_event) {
		this.focusedItem = null;
		this.onModelTouched();
	}

	isSelected(option: OptionItem) {
		return option.value === this.value;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
		this.changeDetectorRef.markForCheck();
	}

	getStyleClass(option: OptionItem) {
		return _isNil(option.styleClass) ? '' : option.styleClass;
	}

	buildNgClass(option: OptionItem, btn) {
		return {
			[this.getStyleClass(option)]: this.isSelected(option),
			'e-active': this.isSelected(option),
			'e-disabled': this.disabled || option.disabled,
			'e-focus': btn === this.focusedItem,
		};
	}

	buildDataTestIdForOption(option: OptionItem) {
		if (ObjectUtils.isNilOrEmpty(this.ngControl.name)) {
			return option.value;
		}
		return `${this.ngControl.name}-${option.value}`;
	}

}
