import { Component, OnInit } from '@angular/core';
import { HumanReadableInsuranceRemittanceResponse } from '@gandalf/model/human-readable-insurance-remittance-response';
import {DynamicPrintRef} from '@core/print/dynamic-print-ref';
import {PrintComponentConfig} from '@core/print/print-component-config';

@Component({
	selector: 'pms-remittance-advice-print',
	templateUrl: './remittance-advice-print.component.html',
})
export class RemittanceAdvicePrintComponent implements OnInit {

	eraResponses: HumanReadableInsuranceRemittanceResponse[];

	constructor(
		private dynamicPrintRef: DynamicPrintRef,
		private printConfig: PrintComponentConfig,
	) {
	}

	ngOnInit(): void {
		this.parseDataConfig();
		this.dynamicPrintRef.printReady();
	}

	parseDataConfig() {
		this.eraResponses = this.printConfig.data.eraResponses as HumanReadableInsuranceRemittanceResponse[];
	}
}
