import { Component, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import { _isNil } from '@core/lodash/lodash';
import { ProviderDropdownResponse, ProviderService } from '@core/provider/provider.service';
import { ReferenceDataService } from '@core/reference-data/reference-data.service';
import { UserLocationsService } from '@core/user-locations/user-locations.service';
import { ReferenceDataMasterCategory } from '@gandalf/constants';
import { OptionItem } from 'morgana';
import { combineLatest } from 'rxjs';
import { OptionItemResponse } from '@core/option-item/option-item.service';
import { PracticeLocation } from '@core/security-manager/security-manager.service';

@Component({
	selector: 'pms-basic-information-form',
	templateUrl: './basic-information-form.component.html',
	styles: [],
})
export class BasicInformationFormComponent implements OnInit {

	providers: ProviderDropdownResponse[];
	titles: OptionItem[];
	locations: OptionItemResponse<PracticeLocation, number>[];

	componentForm: UntypedFormGroup;

	constructor(
		private providerService: ProviderService,
		private userLocationsService: UserLocationsService,
		private referenceDataService: ReferenceDataService,
		public controlContainer: ControlContainer,
	) {
	}

	ngOnInit() {
		this.componentForm = this.controlContainer.control as UntypedFormGroup;
		this.getFormData();
	}

	getFormData() {
		this.locations = this.userLocationsService.getUserLocations();

		combineLatest([
			this.providerService.findActivePracticeVisionProvidersForDropdown(),
			this.referenceDataService.getActiveReferenceDataByCategoryIdForDropdown(ReferenceDataMasterCategory.PERSON_DEMO_TITLE.value),
		]).subscribe(([providers, titles]) => {
			this.providers = providers;
			this.titles = titles;
			if (!this.hasLocationId() && !this.componentForm.get('locationId').dirty) {
				this.componentForm.get('locationId').setValue(this.userLocationsService.getCurrentUserLocation().id);
			}
		});
	}

	hasLocationId() {
		return !_isNil(this.componentForm.value.locationId);
	}
}
