<div class="margin-bottom-md">
	<div *ngIf="productCategory"[attr.data-test-id]="'productCategoryTable'">
		<h4 class="section-title bg-default mrgn-btm">{{ headerText }}</h4>
		<ejs-grid #productGrid
				  autoFitColumns
				  [autoFitBlacklist]="['brandName', 'collectionName']"
				  [staticColumns]="['productCode', 'price']"
				  [dataSource]="filteredLocationProducts"
				  (dataBound)="productGridDataBound(productGrid)"
				  [pmsGridSubmit]="isSearching"
				  gridLines="Horizontal"
				  [allowSorting]="true"
				  [allowSelection]="true"
				  [allowPaging]="true"
				  [pageSettings]="pageSettings"
				  [allowResizing]="true"
				  [allowFiltering]="true"
				  (recordDoubleClick)="rowDoubleClicked($event.rowData.original)"
				  [filterSettings]="{mode: 'Immediate', 'immediateModeDelay': 500, showFilterBarStatus: false}"
				  (rowDeselected)="productUnFocused()"
				  (rowSelected)="productFocused($event.data.original)"
				  class="table-rowlink e-filterbar-visible">
			<ng-template #toolbarTemplate let-data>
				<rev-table-action-menu [table]="productGrid" *ngIf="isContactLensCategory() || isEyeGlassLensCategory()">
					<ng-container rev-table-action-menu-left>
						<div class="e-toolbar-item">
							<pms-constant-dropdown
								[constantList]="contactLensTypes"
								[ngModel]="defaultContactLensType"
                                (selectionChange)="filterByContactLensType($event.itemData)"
								*ngIf="isContactLensCategory() && !contactLensType"></pms-constant-dropdown>
							<ejs-dropdownlist
								[dataSource]="eyeGlassLensTypes"
								placeholder="All Lens Types"
								[showClearButton]="true"
								(valueChange)="filterByEyeGlassLensType($event)"
								*ngIf="isEyeGlassLensCategory()"></ejs-dropdownlist>
						</div>
					</ng-container>
				</rev-table-action-menu>
			</ng-template>
			<e-columns>
				<e-column field="productCode" width="75" headerText="Code" clipMode="EllipsisWithTooltip"></e-column>
				<e-column field="manufacturerName" headerText="Manufacturer" [minWidth]="135" clipMode="EllipsisWithTooltip"></e-column>
				<e-column field="brandName" [customAttributes]="{ autofitMinWidth: 135 }" headerText="Brand" clipMode="EllipsisWithTooltip"></e-column>
				<e-column field="collectionName" [customAttributes]="{ autofitMinWidth: 135 }"headerText="Collection" clipMode="EllipsisWithTooltip"></e-column>
				<e-column field="name" headerText="Model" [minWidth]="200" clipMode="EllipsisWithTooltip"></e-column>
				<e-column field="price" width="120" [sortComparer]="sortComparer" textAlign="right" headerText="Retail" clipMode="EllipsisWithTooltip"></e-column>
			</e-columns>
		</ejs-grid>
		<div class="margin-top-sm text-right" *ngIf="!onlyProductSelection">
			<button ejs-button [isPrimary]="true" [disabled]="!productGrid || productGrid.getSelectedRecords().length < 1" (click)="onSelectButtonClick()" [attr.data-test-id]="'productSelectButton'">Select</button>
		</div>
	</div>
</div>
<div class="margin-bottom-md" *ngIf="focusedProduct" [attr.data-test-id]="'physicalInventoryTable'">
	<h4 class="section-title bg-default mrgn-btm">Physical Inventory</h4>
	<ejs-grid #itemsGrid
			  autoFitColumns
			  [autoFitBlacklist]="['packaging', 'description']"
			  [staticColumns]="['retail', 'stocked', 'stock', 'id', 'isTrial', 'baseCurve', 'sphere', 'cylinder', 'axis', 'diameter', 'addPower', 'addName', 'eye', 'bridge', 'temple']"
			  [dataSource]="locationProductItems"
			  [pmsGridSubmit]="isSearchingProductItems"
			  (dataBound)="gridDataBound(itemsGrid)"
			  gridLines="Horizontal"
			  [allowSorting]="true"
			  [allowSelection]="true"
			  [allowPaging]="true"
			  [pageSettings]="pageSettings"
			  [allowResizing]="true"
			  [allowFiltering]="true"
			  [filterSettings]="{mode: 'Immediate', 'immediateModeDelay': 500, showFilterBarStatus: false}"
			  (rowSelected)="itemSelected($event.data.original)"
			  class="table-rowlink e-filterbar-visible">
		<e-columns>
			<e-column field="description" [customAttributes]="{ autofitMinWidth: 50 }" headerText="Description" clipMode="EllipsisWithTooltip"></e-column>
			<e-column field="upc" headerText="UPC" clipMode="EllipsisWithTooltip"></e-column>
			<e-column field="sku" headerText="SKU" clipMode="EllipsisWithTooltip"></e-column>
			<e-column field="packaging" [customAttributes]="{ autofitMinWidth: 50 }" headerText="Packaging" clipMode="EllipsisWithTooltip"></e-column>
			<e-column [visible]="isContactLensCategory()" field="color" headerText="Color" clipMode="EllipsisWithTooltip"></e-column>
			<e-column [visible]="isContactLensCategory()" width="72" field="isTrial" headerText="Type">
				<ng-template #filterTemplate let-data>
					<ejs-dropdownlist placeholder="All" showClearButton="true"
									  [enabled]="!trialFilter"
									  [value]="trialFilterInitialValue"
									  (valueChange)="filterData($event, 'isTrial')" [dataSource]='contactTypeOptions' [attr.data-test-id]="'physicalInventoryTypeDropdown'"></ejs-dropdownlist>
				</ng-template>
				<ng-template #template let-data>
					{{ data.isTrial ? 'Trial' : 'Rx'}}
				</ng-template>
			</e-column>
			<e-column [visible]="isContactLensCategory()" [sortComparer]="sortComparer" width="65" field="baseCurve" headerText="BC"></e-column>
			<e-column [visible]="isContactLensCategory()" [sortComparer]="sortComparer" width="65" field="sphere" headerText="Sph"></e-column>
			<e-column [visible]="isContactLensCategory()" [sortComparer]="sortComparer" width="65" field="cylinder" headerText="Cyl"></e-column>
			<e-column [visible]="isContactLensCategory()" [sortComparer]="sortComparer" width="70" field="axis" headerText="Axis"></e-column>
			<e-column [visible]="isContactLensCategory()" [sortComparer]="sortComparer" width="72" field="diameter" headerText="Diam"></e-column>
			<e-column [visible]="isContactLensCategory()" [sortComparer]="sortComparer" width="67" field="addPower" headerText="Add"></e-column>
			<e-column [visible]="isContactLensCategory()" width="100" field="addDesignation" headerText="Add Des"></e-column>

			<e-column [visible]="isEyeGlassFrameCategory()" field="color" headerText="Color" clipMode="EllipsisWithTooltip"></e-column>
			<e-column [visible]="isEyeGlassFrameCategory()" field="colorCode" headerText="Code" clipMode="EllipsisWithTooltip"></e-column>
			<e-column [visible]="isEyeGlassFrameCategory()" [sortComparer]="sortComparer" width="64" field="eye" headerText="Eye"></e-column>
			<e-column [visible]="isEyeGlassFrameCategory()" [sortComparer]="sortComparer" width="83" field="bridge" headerText="Bridge"></e-column>
			<e-column [visible]="isEyeGlassFrameCategory()" [sortComparer]="sortComparer" width="89" field="temple" headerText="Temple"></e-column>

			<e-column [visible]="isEyeGlassLensCategory()" [sortComparer]="sortComparer" field="sphere" headerText="Sph" clipMode="EllipsisWithTooltip"></e-column>
			<e-column [visible]="isEyeGlassLensCategory()" [sortComparer]="sortComparer" field="cylinder" headerText="Cyl" clipMode="EllipsisWithTooltip"></e-column>
			<e-column [visible]="isEyeGlassLensCategory()" field="material" headerText="Material" clipMode="EllipsisWithTooltip"></e-column>
			<e-column [visible]="isEyeGlassLensCategory()" field="lensType" headerText="Lens Type" clipMode="EllipsisWithTooltip"></e-column>

			<e-column width="85" field="retail" [sortComparer]="sortComparer" textAlign="right" headerText="Retail" clipMode="EllipsisWithTooltip"></e-column>
			<e-column width="93" field="stocked" headerText="Stocked" clipMode="EllipsisWithTooltip">
				<ng-template #filterTemplate let-data>
					<ejs-dropdownlist placeholder="All" showClearButton="true"
									  (valueChange)="filterData($event, 'stocked')" [dataSource]='stockedTypeOptions' [attr.data-test-id]="'physicalInventoryStockedDropdown'"></ejs-dropdownlist>
				</ng-template>
				<ng-template #template let-data>
					{{ data.stocked | yesNo }}
				</ng-template>
			</e-column>
			<e-column width="80" field="stockQuantity" headerText="Stock" clipMode="EllipsisWithTooltip" >
				<ng-template #template let-data>
					<span [class.text-danger]="data.stockQuantity < 1"> {{ data.stockQuantity }} </span>
				</ng-template>
			</e-column>
			<e-column width="49" [allowSorting]="false" [allowFiltering]="false" field="id" headerText="">
				<ng-template #template let-data>
					<rev-button
							[buttonDisabled]="!data.upc"
							buttonIcon="search"
							buttonStyle="primary"
							buttonTooltip="Search UPC"
							revEventStopPropagation
							(buttonClick)="searchUPC(data)"
							dataTestId="productSelectionSearchUpcButton">
					</rev-button>
				</ng-template>
			</e-column>
		</e-columns>
	</ejs-grid>
</div>
