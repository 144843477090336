import { AfterViewInit, Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
	selector: 'input[pmsConvertZeroToNull]',
})
export class ConvertZeroToNullDirective implements AfterViewInit {

	/**
	 * Number of decimal places to support
	 */
	@Input()
	precision = 0;

	constructor(
		private renderer: Renderer2,
		private elementRef: ElementRef,
	) { }

	ngAfterViewInit() {
		this.processInput(this.elementRef.nativeElement.value);
	}

	@HostListener('blur', ['$event.target.value'])
	processInput(value: string): void {
		const parsedValue = parseFloat(Number(value).toFixed(this.precision));
		const smallestValue = 1 / Math.pow(10, this.precision);

		if (parsedValue >= smallestValue) {
			this.renderer.setProperty(this.elementRef.nativeElement, 'value', parsedValue);
		} else {
			this.renderer.setProperty(this.elementRef.nativeElement, 'value', null);
		}
	}
}
