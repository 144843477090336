import { AddCacheKeyEndpoint, CacheKeyActions, CacheKeyActionTypes } from '../actions/cacheKey.actions';

export class CacheKeyState {
	endpointIds: string[] = [];
}

export type CacheKeyStateMap = Map<string, CacheKeyState>;

export const initialState: CacheKeyStateMap = new Map();

export function reducer(state = initialState, action: CacheKeyActions): CacheKeyStateMap {
	switch (action.type) {

		case CacheKeyActionTypes.ADD_CACHE_KEY_ENDPOINT: {
			const cacheKeyStateMap = new Map(state);
			const cacheKeyState = cacheKeyStateMap.get(action.payload.cacheKey) || new CacheKeyState();
			cacheKeyState.endpointIds.push((action as AddCacheKeyEndpoint).payload.endpointId);
			cacheKeyStateMap.set(action.payload.cacheKey, cacheKeyState);
			return cacheKeyStateMap;
		}

		case CacheKeyActionTypes.REMOVE_CACHE_KEY: {
			const cacheKeyStateMap = new Map(state);
			cacheKeyStateMap.delete(action.payload.cacheKey);
			return cacheKeyStateMap;
		}

		default: {
			return state;
		}
	}
}

