// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { PatientCommunicationPreferencesResponse } from './patient-communication-preferences-response';

// @ts-ignore
import { PatientEmploymentResponse } from './patient-employment-response';

// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { PatientPortalAuthorizedPatientResponse } from './patient-portal-authorized-patient-response';

// @ts-ignore
import { PatientReferralResponse } from './patient-referral-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { PhoneNumbersResponse } from './phone-numbers-response';

// @ts-ignore
import { PreviousAddressResponse } from './previous-address-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.patient.PatientResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientResponse extends GandalfModelBase {

	@GandalfProperty()
	allowLogin: boolean;

	@GandalfProperty()
	alternateIdentifier: string;

	@GandalfArray(PatientPortalAuthorizedPatientResponse)
	authorizedPatients: PatientPortalAuthorizedPatientResponse[];

	@GandalfConstantDecorator()
	@GandalfProperty()
	bloodType: constants.BloodType;

	@GandalfProperty()
	cellPhoneDeactivatedDate: Date;

	@GandalfProperty()
	cellPhoneStoppedDate: Date;

	@GandalfProperty()
	citizenshipId: number;

	@GandalfProperty()
	communicationPreferences: PatientCommunicationPreferencesResponse;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dob: Date;

	@GandalfProperty()
	dod: Date;

	@GandalfProperty()
	dominantEye: ReferenceDataResponse;

	@GandalfProperty()
	dominantHand: ReferenceDataResponse;

	@GandalfProperty()
	driversLicense: string;

	@GandalfProperty()
	email: string;

	@GandalfProperty()
	emailDeclined: boolean;

	@GandalfProperty()
	emailValid: boolean;

	@GandalfProperty()
	employment: PatientEmploymentResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	establishedStatus: constants.EstablishedStatus;

	@GandalfArray(ReferenceDataResponse)
	ethnicities: ReferenceDataResponse[];

	@GandalfProperty()
	ethnicityDeclined: boolean;

	@GandalfProperty()
	fullSsnAvailable: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	gender: constants.Gender;

	@GandalfConstantDecorator()
	@GandalfProperty()
	genderIdentity: constants.GenderIdentity;

	@GandalfProperty()
	guarantor: PersonNameResponse;

	@GandalfProperty()
	hasPassword: boolean;

	@GandalfProperty()
	hasTemporaryAddress: boolean;

	@GandalfProperty()
	homeAddress: AddressResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	interpreterAssistance: constants.PersonInterpreterAssistance;

	@GandalfProperty()
	language: ReferenceDataResponse;

	@GandalfProperty()
	languageDeclined: boolean;

	@GandalfProperty()
	last4ssn: string;

	@GandalfProperty()
	maritalStatus: ReferenceDataResponse;

	@GandalfProperty()
	patientId: number;

	@GandalfProperty()
	patientName: PatientNameResponse;

	@GandalfArray(PatientNameResponse)
	patientPortalRepresentatives: PatientNameResponse[];

	@GandalfProperty()
	phoneNumbers: PhoneNumbersResponse;

	@GandalfProperty()
	photoId: number;

	@GandalfProperty()
	previousAddress: PreviousAddressResponse;

	@GandalfProperty()
	previousFirstName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	previousFirstNameType: constants.PreviousNameType;

	@GandalfProperty()
	previousLastName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	previousLastNameType: constants.PreviousNameType;

	@GandalfProperty()
	previousMiddleName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	previousMiddleNameType: constants.PreviousNameType;

	@GandalfProperty({ propertyType: 'LocalDate' })
	previousNameEndDate: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	previousNameStartDate: Date;

	@GandalfProperty()
	primaryLocationId: number;

	@GandalfProperty()
	provider: ProviderResponse;

	@GandalfProperty()
	raceDeclined: boolean;

	@GandalfArray(ReferenceDataResponse)
	races: ReferenceDataResponse[];

	@GandalfArray(PatientReferralResponse)
	referrals: PatientReferralResponse[];

	@GandalfProperty()
	referrer: PatientReferralResponse;

	@GandalfProperty()
	religion: ReferenceDataResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	sexualOrientation: constants.SexualOrientation;

	@GandalfProperty()
	sin: string;

	@GandalfProperty()
	temporaryAddress: AddressResponse;

	@GandalfProperty({ propertyType: 'LocalDate' })
	temporaryAddressEndDate: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	temporaryAddressStartDate: Date;

	@GandalfProperty()
	userName: string;

	@GandalfProperty()
	version: number;

}
