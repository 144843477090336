import { Injectable } from '@angular/core';
import { RouterState, selectRouterState } from '@app-store/reducers';
import { RouterParam, RouterQueryParam } from '@app-store/selectors';
import { _get, _isEqual } from '@core/lodash/lodash';
import { RouterReducerState } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class RouterStoreUtils {
	private routerState: BehaviorSubject<RouterReducerState<RouterState>> =
		new BehaviorSubject<RouterReducerState<RouterState>>(undefined);

	constructor(
		private store: Store<any>,
	) {
		this.store.select(selectRouterState).subscribe(this.routerState);
	}

	getRouterParam(param: RouterParam, toNumber = true) {
		const paramValue = _get(this.routerState.getValue().state.params, param);
		return this.toNumber(paramValue, toNumber);
	}

	getQueryParam(param: RouterQueryParam, toNumber = true) {
		const paramValue = _get(this.routerState.getValue().state.queryParams, param);
		return this.toNumber(paramValue, toNumber);
	}

	getCurrentRoute() {
		return this.routerState.getValue().state.url;
	}

	getRouteData(key: string) {
		return _get(this.routerState.getValue().state.data, key);
	}

	observeRouteParam(routeParam: RouterParam, toNumber = true) {
		return this.routerState.asObservable().pipe(
			map(routerState => this.toNumber(routerState.state.params[routeParam], toNumber)),
			distinctUntilChanged(_isEqual),
		);
	}

	toNumber(value: string, toNumber: boolean): any {
		if (toNumber) {
			return value ? Number(value) : undefined;
		} else {
			return value;
		}
	}
}
