import { AbstractControl } from '@angular/forms';
import { _isArrayLike, _isNil, _isString } from '@core/lodash/lodash';

/**
 * Required property if given condition is satisfied
 */
export function conditionallyRequiredValidator(controlName: string, condition: (form: AbstractControl) => boolean, errorProperty: string, message: string) {
	return (formGroup: AbstractControl) => {
		const control = formGroup.get(controlName);

		let errors = control.errors || {};
		let groupErrors = formGroup.errors;
		if (!_isNil(groupErrors)) {
			delete (groupErrors[errorProperty]);
			if (Object.keys(groupErrors).length === 0) {
				groupErrors = null;
			}
		}

		if (!_isNil(errors)) {
			delete (errors[errorProperty]);
			if (Object.keys(errors).length === 0) {
				errors = null;
			}
		}

		if (condition(formGroup)) {
			if (
				_isNil(control.value) ||
				(_isString(control.value) && control.value.toString().trim() === '') ||
				(_isArrayLike(control.value) && control.value.length === 0)
			) {

				if (_isNil(groupErrors)) {
					groupErrors = {};
				}
				if (_isNil(errors)) {
					errors = {};
				}
				groupErrors[errorProperty] = {message};

				errors[errorProperty] = message;
			}
		}

		control.setErrors(errors);
		return groupErrors;
	};
}
