import { Injector, NgModule, Type } from '@angular/core';
import isNil from 'lodash/isNil';

// @dynamic
@NgModule()
export class InjectorContainerModule {
	static injector: Injector;

	constructor(injector: Injector) {
		InjectorContainerModule.injector = injector;
	}

	static getInjectable<T>(token: Type<T>) {
		return isNil(this.injector) ? null : this.injector.get(token);
	}
}
