// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { BaseAgeReportResponse } from './base-age-report-response';

/** See com.rev360.pms.api.controller.reporting.inventory.age.ContactLensAgeReportResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensAgeReportResponse extends BaseAgeReportResponse {

	@GandalfProperty()
	addDesignation: string;

	@GandalfProperty()
	addPower: number;

	@GandalfProperty()
	averageAge: number;

	@GandalfProperty()
	averageAgeOfStock: number;

	@GandalfProperty()
	axis: number;

	@GandalfProperty()
	baseCurve: number;

	@GandalfProperty()
	brand: string;

	@GandalfProperty()
	category: string;

	@GandalfProperty()
	collection: string;

	@GandalfProperty()
	cylinder: number;

	@GandalfProperty()
	diameter: number;

	@GandalfProperty()
	isTrial: boolean;

	@GandalfProperty()
	itemDescription: string;

	@GandalfProperty()
	lensColor: string;

	@GandalfProperty()
	lensColorCode: string;

	@GandalfProperty()
	locationId: number;

	@GandalfProperty({ propertyType: 'Money' })
	locationProductActualCost: number;

	@GandalfProperty()
	locationProductItemId: number;

	@GandalfProperty()
	manufacturer: string;

	@GandalfProperty()
	maximumAge: number;

	@GandalfProperty()
	maximumAgeOfStock: number;

	@GandalfProperty()
	minimumAge: number;

	@GandalfProperty()
	minimumAgeOfStock: number;

	@GandalfProperty()
	model: string;

	@GandalfProperty()
	sku: string;

	@GandalfProperty()
	sphere: number;

	@GandalfProperty()
	stock: number;

	@GandalfProperty()
	upc: string;

}
