/* istanbul ignore file */
import { createSelector } from '@ngrx/store';
import { selectPatientsState } from '@patients-store/reducers';
import { PatientTabStateMap } from '@patients-store/reducers/patient-tab.reducer';

export const selectPatientTabState = createSelector(selectPatientsState, state => state.patientTabState);
export const isPatientStateLoaded = createSelector(selectPatientsState, state => !!state);

export const selectPatientTab = (props: {patientId: number}) =>
	createSelector(selectPatientTabState, (state: PatientTabStateMap) => state.get(props.patientId));
