// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.patient.PatientGuarantorResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientGuarantorResponse extends GandalfModelBase {

	@GandalfProperty()
	guarantorContactId: number;

	@GandalfProperty()
	guarantorIsSelf: boolean;

	@GandalfProperty()
	guarantorPatientId: number;

	@GandalfProperty()
	personName: PersonNameResponse;

}
