<ejs-dialog
		revOverrideModalBase
		[attr.data-test-id]="'loadingModal'"
		#modal
		[animationSettings]="{effect: 'None'}"
		[position]="{X: 'center', Y: 'top'}"
		[showCloseIcon]="false"
		[closeOnEscape]="false"
		[allowDragging]="false"
		[isModal]="true"
		[zIndex]="zIndex"
		cssClass="modal-sm">
	<ng-template #header>
		<div class="dlg-template">{{title}}</div>
	</ng-template>
	<ng-template #content>
		<div class="hFull wFull text-center">
			<i class="fa fa-spinner fa-spin fa-5x"></i>
			<div>
				<span>{{message}}</span>
			</div>
		</div>
	</ng-template>
</ejs-dialog>
