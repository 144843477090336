import { FieldUIComponentType } from '@gandalf/constants';

export const FORM_FIELD_DEFAULT_DIMENSIONS = {
	[FieldUIComponentType.NONE.value]: {
		width: 0,
		height: 0,
	},
	[FieldUIComponentType.TEXT.value]: {
		width: 55,
		height: 22,
	},
	[FieldUIComponentType.TEXTAREA.value]: {
		width: 185,
		height: 50,
	},
	[FieldUIComponentType.SELECTION.value]: {
		width: 70,
		height: 22,
	},
	[FieldUIComponentType.MULTI_SELECTION.value]: {
		width: 200,
		height: 22,
	},
	[FieldUIComponentType.DATE.value]: {
		width: 70,
		height: 22,
	},
	[FieldUIComponentType.TIME.value]: {
		width: 70,
		height: 22,
	},
	[FieldUIComponentType.NUMBER_STEPPER.value]: {
		width: 55,
		height: 22,
	},
	[FieldUIComponentType.CHECKBOX.value]: {
		width: 15,
		height: 22,
	},
};

export const DEFAULT_LABEL_WIDTH = 160;
export const DEFAULT_LABEL_HEIGHT = 22;
