import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PayerType } from '@gandalf/constants';
import { Store } from '@ngrx/store';
import { State } from '@patients-store/reducers';
import { AddPaymentTab } from '@payments-store/actions';
import { APP_ROUTING_URL_CONSTANTS } from '../../../../app-routing.constants';
import { NewEntityUniqueIdService } from '../../../../services/new-entity-unique-id-service/new-entity-unique-id.service';

@Injectable({
	providedIn: 'root',
})
export class PaymentService {
	private paymentsUpdatedSinceLastRefresh = false;

	constructor(
		private router: Router,
		private store: Store<State>,
		private newEntityUniqueIdService: NewEntityUniqueIdService,
	) {
	}

	openReceivePaymentTab(payerType: PayerType, locationId: number) {
		const newestId = this.newEntityUniqueIdService.nextId;
		this.store.dispatch(new AddPaymentTab({paymentId: newestId, locationId, payerType}));
		return this.openHTMLPaymentTab(newestId);
	}

	openHTMLPaymentTab(paymentId: number) {
		return this.router.navigate([APP_ROUTING_URL_CONSTANTS.ACCOUNTING_PAYMENTS.url, 'payment', paymentId]);
	}

	/**
	 * Indicates that the payments should be refreshed when next viewed
	 */
	refreshPayments() {
		this.paymentsUpdatedSinceLastRefresh = true;
	}

	/**
	 * Indicates the payments have been refreshed since last requested
	 */
	paymentsRefreshed() {
		this.paymentsUpdatedSinceLastRefresh = false;
	}

	/**
	 * Returns true if the payments should be refreshed
	 */
	shouldUpdatePayments() {
		return this.paymentsUpdatedSinceLastRefresh;
	}
}
