// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { DailyOfficeHoursAdminResponse } from './daily-office-hours-admin-response';

/** See com.rev360.pms.api.controller.admin.general.location.AdminPracticeLocationOfficeHoursResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminPracticeLocationOfficeHoursResponse extends GandalfModelBase {

	@GandalfProperty()
	friday: DailyOfficeHoursAdminResponse;

	@GandalfProperty()
	monday: DailyOfficeHoursAdminResponse;

	@GandalfProperty()
	saturday: DailyOfficeHoursAdminResponse;

	@GandalfProperty()
	sunday: DailyOfficeHoursAdminResponse;

	@GandalfProperty()
	thursday: DailyOfficeHoursAdminResponse;

	@GandalfProperty()
	tuesday: DailyOfficeHoursAdminResponse;

	@GandalfProperty()
	wednesday: DailyOfficeHoursAdminResponse;

}
