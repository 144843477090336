import { Component, OnInit, ViewChild } from '@angular/core';
import { InsuranceService } from '@core/insurance/insurance.service';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { PatientService } from '@core/patient/patient.service';
import { EClaimsInsuranceClaimResponse } from '@gandalf/model/e-claims-insurance-claim-response';
import { InsuranceClaimStatusResponse } from '@gandalf/model/insurance-claim-status-response';
import { InvoiceInsuranceClaimResponse } from '@gandalf/model/invoice-insurance-claim-response';
import { InvoiceResponse } from '@gandalf/model/invoice-response';
import { PatientNameResponse } from '@gandalf/model/patient-name-response';
import { DATE_FORMATS, TABLE_DATE_FORMATS } from '@shared/constants/date-format.constants';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { combineLatest } from 'rxjs';

@Component({
	selector: 'pms-claim-messages-modal',
	templateUrl: './claim-messages-modal.component.html',
	styles: [],
})
export class ClaimMessagesModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('grid')
	grid: GridComponent;

	invoice: InvoiceResponse;
	insuranceClaim: EClaimsInsuranceClaimResponse | InvoiceInsuranceClaimResponse;
	tableDateFormat = TABLE_DATE_FORMATS.MM_DD_YYYY;
	dateFormat: string = DATE_FORMATS.MM_DD_YYYY;
	insuranceClaimStatus: InsuranceClaimStatusResponse[];
	patientName: PatientNameResponse;
	insuranceCompanyName: string;
	isSearching = true;

	constructor(
		public ref: DynamicModalRef,
		private modalConfig: ModalConfig,
		private patientService: PatientService,
		private insuranceService: InsuranceService,
	) {
	}

	ngOnInit() {
		this.parseConfigData(this.modalConfig.data);

		this.getData();
	}

	parseConfigData(data: any) {
		this.invoice = data.invoice;
		this.insuranceClaim = data.insuranceClaim;
		this.insuranceClaimStatus = this.insuranceClaim.claimStatusMessages;
	}

	getData() {
		combineLatest([
			this.patientService.getPatientNameById(this.invoice.patientId),
			this.insuranceService.getCompanyNameByPersonInsuranceId(this.invoice.personInsuranceId),
		]).subscribe(([patientName, insuranceCompanyName]) => {
			this.patientName = patientName;
			this.insuranceCompanyName = insuranceCompanyName.companyName;
			this.isSearching = false;
		});
	}

	closeModal() {
		this.ref.close(this.modal);
	}
}
