import { Injectable } from '@angular/core';
import { StatefulEventUtil } from '@app-store/utils/stateful-event-util';
import {
	UPDATE_INSURANCE_COMPANIES,
	UPDATE_PATIENT_ACCOUNT,
	UPDATE_PATIENT_CONTACT_LENS_PRESCRIPTIONS,
	UPDATE_PATIENT_CONTACTS,
	UPDATE_PATIENT_EYEGLASS_PRESCRIPTIONS,
	UPDATE_PATIENT_FAMILY_MEMBERS,
	UPDATE_PATIENT_INSURANCES,
	UPDATE_PATIENT_MEDICATION_PRESCRIPTIONS,
	UPDATE_PATIENT_NOTES,
	UPDATE_PATIENT_LAB_ORDERS,
	UPDATE_PATIENT_ENCOUNTERS,
	UPDATE_PATIENT_APPOINTMENTS,
	UPDATE_PATIENT_ORDERS,
	UPDATE_PATIENT_RECALLS,
	UPDATE_PATIENT_DIAGNOSES
} from '@app-store/constants/event.constants';

@Injectable({
	providedIn: 'root',
})
export class EventService {

	publishUpdatePatientInsurances(patientId: number) {
		StatefulEventUtil.publish(UPDATE_PATIENT_INSURANCES, {storeKeyArgs: { patientId }});
	}

	publishUpdateInsuranceCompanies() {
		StatefulEventUtil.publish(UPDATE_INSURANCE_COMPANIES);
	}

	publishUpdatePatientEyeglassPrescriptions(patientId: number) {
		StatefulEventUtil.publish(UPDATE_PATIENT_EYEGLASS_PRESCRIPTIONS, {storeKeyArgs: {patientId}});
	}

	publishUpdatePatientNotes(patientId: number) {
		StatefulEventUtil.publish(UPDATE_PATIENT_NOTES, {storeKeyArgs: { patientId }});
	}

	publishUpdatePatientContactLensPrescriptions(patientId: number) {
		StatefulEventUtil.publish(UPDATE_PATIENT_CONTACT_LENS_PRESCRIPTIONS, {storeKeyArgs: {patientId}});
	}

	publishUpdatePatientMedicationPrescriptions(patientId: number) {
		StatefulEventUtil.publish(UPDATE_PATIENT_MEDICATION_PRESCRIPTIONS, {storeKeyArgs: {patientId}});
	}

	publishUpdatePatientAccount(patientId: number) {
		// Uncertain on exactly what actions in the UI should trigger refreshing the Account pod, but this will be called where needed to do so
		StatefulEventUtil.publish(UPDATE_PATIENT_ACCOUNT, {storeKeyArgs: { patientId }});
	}

	publishUpdatePatientLabOrders(patientId: number) {
		StatefulEventUtil.publish(UPDATE_PATIENT_LAB_ORDERS, {storeKeyArgs: { patientId }});
	}

	publishUpdatePatientOrders(patientId: number) {
		StatefulEventUtil.publish(UPDATE_PATIENT_ORDERS, {storeKeyArgs: { patientId }});
	}

	publishUpdatePatientFamilyMembers(patientId: number) {
		StatefulEventUtil.publish(UPDATE_PATIENT_FAMILY_MEMBERS, {storeKeyArgs: { patientId }});
	}

	publishUpdatePatientContacts(patientId: number) {
		StatefulEventUtil.publish(UPDATE_PATIENT_CONTACTS, {storeKeyArgs: { patientId }});
	}

	publishUpdatePatientEncounters(patientId: number) {
		StatefulEventUtil.publish(UPDATE_PATIENT_ENCOUNTERS, {storeKeyArgs: { patientId }});
	}

	publishUpdatePatientAppointments(patientId: number) {
		StatefulEventUtil.publish(UPDATE_PATIENT_APPOINTMENTS, {storeKeyArgs: { patientId }});
	}

	publishUpdatePatientRecalls(patientId: number) {
		StatefulEventUtil.publish(UPDATE_PATIENT_RECALLS, {storeKeyArgs: { patientId }});
	}

	publishUpdatePatientDiagnoses(patientId: number) {
		StatefulEventUtil.publish(UPDATE_PATIENT_DIAGNOSES, {storeKeyArgs: { patientId }});
	}
}
