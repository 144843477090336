import { Component, Input, OnInit } from '@angular/core';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { EyeglassPrescriptionResponse } from '@gandalf/model/eyeglass-prescription-response';

@Component({
	selector: 'pms-eyeglass-rx-other-information',
	templateUrl: './eyeglass-rx-other-information.component.html',
	styles: [],
})
export class EyeglassRxOtherInformationComponent implements OnInit {

	@Input()
	eyeglassPrescription: EyeglassPrescriptionResponse;

	isRxMonoPdFeatureFlagOn = false;

	constructor(private featureService: FeatureService) {

	}

	ngOnInit() {
		this.isRxMonoPdFeatureFlagOn = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.ORDER_CREATION_EFFICIENCIES.RX_MONO_PD);
	}

}
