// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FeatureFlagEmployeeSummaryResponse } from './feature-flag-employee-summary-response';

/** See com.rev360.pms.api.controller.feature.FeatureFlagDetailResponse */
/* istanbul ignore next */
@GandalfModel
export class FeatureFlagDetailResponse extends GandalfModelBase {

	@GandalfConstantDecorator()
	@GandalfProperty()
	access: constants.FeatureFlagAccess;

	@GandalfProperty()
	description: string;

	@GandalfProperty()
	documentationLink: string;

	@GandalfProperty()
	employeeFlag: FeatureFlagEmployeeSummaryResponse;

	@GandalfProperty()
	enabled: boolean;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	imageFileId: number;

	@GandalfProperty()
	internalName: string;

	@GandalfProperty()
	title: string;

}
