// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.insurance.updatepersoninsurance.selfpolicy.UpdatePatientGeneralBenefitsSelfPolicyRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdatePatientGeneralBenefitsSelfPolicyRequest extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	contactLensFittingBenefitResetDate: Date;

	@GandalfProperty({ propertyType: 'Money' })
	contactLensFittingCoPay: number;

	@GandalfProperty({ propertyType: 'Money' })
	deductible: number;

	@GandalfProperty({ propertyType: 'Money' })
	generalCoPay: number;

	@GandalfProperty()
	hasContactLensFittingBenefit: boolean;

	@GandalfProperty()
	hasRoutineExamBenefit: boolean;

	@GandalfProperty({ propertyType: 'Money' })
	medicalExamCoPay: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	routineExamBenefitResetDate: Date;

	@GandalfProperty({ propertyType: 'Money' })
	routineExamCoPay: number;

}
