import { ToastAnimationSettingsModel, ToastModel, ToastPositionModel } from '@syncfusion/ej2-notifications';

const defaultPosition: ToastPositionModel = {X: 'Right', Y: 'Top'};
const defaultTimeout = 3000; // 3 seconds
const defaultAnimation: ToastAnimationSettingsModel = {
	show: {
		duration: 0,
	},
	hide: {
		duration: 0,
	},
};
export const DefaultSuccessToastModel: ToastModel = {
	position: defaultPosition,
	content: 'Successfully updated',
	animation: defaultAnimation,
	timeOut: defaultTimeout,
	extendedTimeout: 0,
	showCloseButton: true,
	cssClass: 'e-toast-success',
	icon: 'fa fa-check',
};

export const DefaultErrorToastModel: ToastModel = {
	position: defaultPosition,
	content: 'An Error has occurred',
	animation: defaultAnimation,
	timeOut: defaultTimeout,
	extendedTimeout: 0,
	showCloseButton: true,
	cssClass: 'e-toast-danger',
	icon: 'fa fa-2x fa-exclamation-circle',
};

export const DefaultWarningToastModel: ToastModel = {
	position: defaultPosition,
	content: 'Warning Message',
	animation: defaultAnimation,
	timeOut: defaultTimeout,
	extendedTimeout: 0,
	showCloseButton: true,
	cssClass: 'e-toast-warning',
	icon: 'fa fa-2x fa-exclamation-triangle',
};

export const DefaultInfoToastModel: ToastModel = {
	position: defaultPosition,
	content: 'Info Message',
	animation: defaultAnimation,
	timeOut: defaultTimeout,
	extendedTimeout: 0,
	showCloseButton: true,
	cssClass: 'e-toast-info',
	icon: 'fa fa-2x fa-info-circle',
};

export const FeatureFlagModificationToastModel: ToastModel = {
	position: defaultPosition,
	title: 'Flag modification successful!',
	animation: defaultAnimation,
	timeOut: defaultTimeout,
	extendedTimeout: 0,
	cssClass: 'e-toast-success',
};

export const DefaultNotificationToastModel: ToastModel = {
	position: defaultPosition,
	timeOut: 10000,
	extendedTimeout: 0,
	animation: defaultAnimation,
	cssClass: 'e-toast-warning',
	showCloseButton: true,
	icon: 'fa fa-2x fa-bell-o',
};
