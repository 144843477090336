<div>
	<form #drugSearchForm="ngForm" class="form-inline" [formGroup]="componentForm" (ngSubmit)="searchDrugs()">
		@if (componentForm.invalid && drugSearchForm.submitted) {
			<gandalf-lib-validation-messages
				[form]="componentForm"
				[requestObj]="request">
			</gandalf-lib-validation-messages>
		}
		<ejs-grid #grid
				  autoFitColumns
				  [autoFitBlacklist]="['name']"
				  [dataSource]="searchResults"
				  [pmsGridSubmit]="isLoading"
				  [pmsGridSubmitDefaultSortOptions]="sortSettings"
				  class="table-rowlink margin-bottom-md"
				  gridLines="Horizontal"
				  (rowSelected)="onRowSelected($event)"
				  [allowResizing]="true"
				  [allowSorting]="true"
				  [allowFiltering]="true"
				  [allowPaging]="true"
				  [pageSettings]="pageSettings"
				  [filterSettings]="{showFilterBarStatus: false}"
				  [query]="query"
				  [attr.data-test-id]="'medicationSearchTable'">
			<ng-template #toolbarTemplate let-data>
				<rev-table-action-menu [table]="grid">
					<ng-container rev-table-action-menu-left>
						<div class="e-toolbar-item">
							<input class="e-input margin-right-sm" type="text" name="searchFilter" placeholder="Search" formControlName="query"
								   [revFocusInput]="true" [highlightInputOnFocus]="true"/>
							<rev-button
								buttonType="submit"
								buttonStyle="primary"
								[buttonDisabled]="isSearchDisabled()"
								buttonLabel="Search"
								buttonIcon="search"
								[dataTestId]="'medicationSearchSearchButton'">
							</rev-button>
							<rev-button
								(buttonClick)="clearFilter()"
								[buttonDisabled]="!componentForm.get('query').value"
								buttonLabel="Clear"
								buttonIcon="close"
								[dataTestId]="'medicationSearchClearButton'">
							</rev-button>
						</div>
						<div class="e-toolbar-item">
							@if (searchResults.length >= 100) {
								<div class="text-warning" [attr.data-test-id]="'medicationSearchResultsWarningMessage'">
									Search returned more than 100 results, please refine your search.
								</div>
							}
						</div>
					</ng-container>
				</rev-table-action-menu>
			</ng-template>
			<e-columns>
				<e-column field="name" headerText="Drug Description" clipMode="EllipsisWithTooltip"></e-column>
				<e-column field="type" headerText="Type" [minWidth]="100" *pmsUsaOnly></e-column>
				<e-column field="code" headerText="Code" [minWidth]="100"></e-column>
			</e-columns>
		</ejs-grid>
		<ejs-checkbox *pmsUsaOnly label="Show All"
					  revTooltip tooltipContent="If not selected, only Clinical drugs, Ingredients, and Synonyms will be displayed"
					  formControlName="isShowingAll">
		</ejs-checkbox>
	</form>
</div>
