import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { _filter, _isNil } from '@core/lodash/lodash';
import { PrescriptionUtil } from '@core/prescription-util/prescription-util';
import { PupillaryDistanceSource } from '@gandalf/constants';
import { PupillaryDistanceHistoryResponse } from '@gandalf/model/pupillary-distance-history-response';
import { BaseComponent } from '@shared/component/base.component';
import { EyeglassLensRxService } from '@shared/component/eyeglass-lens-rx-container/eyeglass-lens-rx.service';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, RowSelectedEvent } from 'ag-grid-community';
import { DynamicModalRef, GRID_MULTILINE_ROW_HEIGHT, GridUtil, GridUtilService, ModalConfig, OptionItem } from 'morgana';
import { PmsCellFormattingUtils } from '@core/cell-formatting-utils/cell-formatting-utils.service';

@Component({
	selector: 'pms-select-pd-history-modal',
	templateUrl: './select-pd-history-modal.component.html',
})
export class SelectPdHistoryModalComponent extends BaseComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('agGrid')
	agGrid: AgGridAngular;

	@ViewChild('eyeTemplate')
	eyeTemplate: TemplateRef<any>;

	@ViewChild('monoDistPdTemplate')
	monoDistPdTemplate: TemplateRef<any>;

	@ViewChild('monoNearPdTemplate')
	monoNearPdTemplate: TemplateRef<any>;

	patientId: number;
	prescriptionUtil = PrescriptionUtil;
	isSearching = false;
	pdHistory: PupillaryDistanceHistoryResponse[];
	filteredPdHistory: PupillaryDistanceHistoryResponse[];

	gridColumns: ColDef[];
	pdHistoryGridOptions = GridUtil.buildGridOptions({
		suppressRowClickSelection: true,
		rowClass: 'cursor-pointer',
		rowHeight: GRID_MULTILINE_ROW_HEIGHT,
	});

	sourceFilterOptions: OptionItem[] = [
		PupillaryDistanceSource.PRESCRIPTION,
		PupillaryDistanceSource.ORDER,
		PupillaryDistanceSource.TEST,
	];
	sourceFilter: PupillaryDistanceSource;

	constructor(
		public dynamicModalRef: DynamicModalRef,
		public modalConfig: ModalConfig,
		private eyeglassLensRxService: EyeglassLensRxService,
		private cellFormattingUtils: PmsCellFormattingUtils,
		private gridUtilService: GridUtilService,
	) {
		super();
	}

	ngOnInit(): void {
		this.parseConfigData(this.modalConfig.data);
		this.getData();
	}

	parseConfigData(data) {
		this.patientId = data.patientId;
	}

	getData() {
		this.isSearching = true;

		this.eyeglassLensRxService.getPupillaryDistanceHistoryByPatientId(this.patientId).subscribe(pds => {
			this.pdHistory = pds;
			this.filterTable();
			this.isSearching = false;
		});
	}

	onGridReady() {
		this.buildGridColumns();
		this.agGrid.api.setGridOption('columnDefs', this.gridColumns);
		this.updatePdHistoryInTable();
	}

	updatePdHistoryInTable() {
		if (!_isNil(this.agGrid?.api)) {
			this.agGrid.api.setGridOption('rowData', this.filteredPdHistory);
		}
	}

	filterTable() {
		this.filteredPdHistory = this.pdHistory;
		if (this.sourceFilter && !_isNil(this.sourceFilter.value)) {
			this.filteredPdHistory = _filter(this.pdHistory, pd => pd.source.value === this.sourceFilter.value);
		}
		this.updatePdHistoryInTable();
	}

	onPdSelected(event: RowSelectedEvent) {
		this.closeModal(event.data);
	}

	buildGridColumns() {
		this.gridColumns = [
			this.gridUtilService.buildDateColumn('Date', 'sourceDate', DATE_FORMATS.MM_DD_YYYY, {
				cellClass: 'display-flex align-items-center',
				width: 90,
				sort: 'desc',
			}),
			GridUtil.buildColumn('Source', 'sourceName', {
				cellClass: 'display-flex align-items-center',
				minWidth: 120,
				flex: 1,
			}),
			GridUtil.buildTemplateColumn('', '', this.eyeTemplate, {
				width: 50,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('Mono-Dist PD', 'monoDistPD', this.monoDistPdTemplate, {
				width: 150,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('Mono-Near PD', 'monoNearPD', this.monoNearPdTemplate, {
				width: 150,
				sortable: false,
			}),
			GridUtil.buildColumn('DPD', 'ouDistancePupillaryDistance', {
				cellClass: 'display-flex align-items-center',
				width: 150,
				sortable: false,
				valueFormatter: this.cellFormattingUtils.pupillaryDistance(1),
			}),
			GridUtil.buildColumn('NPD', 'ouNearPupillaryDistance', {
				cellClass: 'display-flex align-items-center',
				width: 150,
				sortable: false,
				valueFormatter: this.cellFormattingUtils.pupillaryDistance(1),
			}),
		];
	}

	closeModal(pupillaryDistanceHistory: PupillaryDistanceHistoryResponse = null) {
		this.dynamicModalRef.close(this.modal, pupillaryDistanceHistory);
	}
}
