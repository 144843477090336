// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.solutions.smartflow.SmartflowSupplierResponse */
/* istanbul ignore next */
@GandalfModel
export class SmartflowSupplierResponse extends GandalfModelBase {

	@GandalfProperty()
	isOptedIn: boolean;

	@GandalfProperty()
	name: string;

	@GandalfProperty()
	optInByFirstName: string;

	@GandalfProperty()
	optInByLastName: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	optInDate: Date;

	@GandalfProperty()
	phoneNumber: string;

	@GandalfProperty()
	smartflowMasterSupplierId: number;

	@GandalfProperty()
	smartflowPracticeSupplierId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	smartflowProductPartnerCategory: constants.SmartflowProductPartnerCategory;

	@GandalfProperty()
	webSite: string;

}
