import { DEBOUNCE_SEARCH_TIME } from '@shared/constants/filter.constants';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export class DebounceSearch {

	valueChanged: Subject<any> = new Subject<any>();

	constructor(
		functionCallback: (args?: any) => void,
		debounceAmount = DEBOUNCE_SEARCH_TIME,
	) {
		this.valueChanged.pipe(
			debounceTime(debounceAmount),
			distinctUntilChanged(),
		).subscribe(value => functionCallback(value));
	}
}
