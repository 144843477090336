import { Injectable } from '@angular/core';
import { _uniq } from '@core/lodash/lodash';
import { ObjectUtils } from 'morgana';
import { OpenEdgePaymentService } from '@core/open-edge-payment/open-edge-payment.service';
import { OpenEdgeCommonPaymentStatus } from '@gandalf/constants';

@Injectable()
export class CreditCardFormService {
	cardForm: any;
	isCardFormLoaded = false;
	isProcessing = false;
	errors: string[] = [];

	constructor(
		private openEdgePaymentService: OpenEdgePaymentService,
	) {
	}

	/* istanbul ignore next */
	getCreditCardForm(success, error) {
		const imageBaseUrl = 'https://js.paygateway.com/secure_payment/v1/images';
		this.cardForm = this.openEdgePaymentService.GlobalPayments.ui.form({
			fields: {
				'card-number': {
					target: '#card-number',
					placeholder: '',
				},
				'card-expiration': {
					target: '#card-expiration',
					placeholder: 'MM / YYYY',
				},
				'card-cvv': {
					target: '#card-cvv',
					placeholder: '',
				},
				'submit': {
					target: '#submit',
				},
			},
			styles: {
				'input': {
					'background': 'transparent',
					'border': 'none',
					'border-bottom': '1px solid white',
					'color': 'white',
					'font-family': '\'Open Sans\', sans-serif',
					'font-size': '16px',
					'letter-spacing': '2px',
					'outline': 'none',
					'height': '40px',
					'width': '100%',
					'border-radius': '0px',
					'margin': '0px',
					'padding': '0px',
				},
				'input.invalid': {
					'border-color': '#fc002a',
				},
				'input:focus': {
					'border-width': '2px',
					'border-color': '#0b9fcb !important',
				},
				'.card-number': {
					'height': '40px',
					'letter-spacing': '5px',
					'margin': '0px',
					'padding': '0px',
					'background': `rgba(0,0,0,0) url(${imageBaseUrl}/logo-unknown@2x.png) no-repeat right`,
					'background-size': '58px 30px',
				},
				'.card-number.valid.card-type-visa': {
					'background': `rgba(0,0,0,0) url(${imageBaseUrl}/logo-visa@2x.png) no-repeat right`,
					'background-position-y': '7px',
					'background-size': '55px 60px',
				},
				'.card-number.valid.card-type-mastercard': {
					'background': `rgba(0,0,0,0) url(${imageBaseUrl}/logo-mastercard@2x.png) no-repeat right`,
					'background-position-y': '11px',
					'background-size': '55px 60px',
				},
				'.card-number.valid.card-type-discover': {
					'background': `rgba(0,0,0,0) url(${imageBaseUrl}/logo-discover@2x.png) no-repeat right`,
					'background-position-y': '7px',
					'background-size': '55px 60px',
				},
				'.card-number.valid.card-type-jcb': {
					'background': `rgba(0,0,0,0) url(${imageBaseUrl}/logo-jcb@2x.png) no-repeat right`,
					'background-position-y': '9px',
					'background-size': '55px 60px',
				},
				'.card-number.valid.card-type-amex': {
					'background': `rgba(0,0,0,0) url(${imageBaseUrl}/logo-amex@2x.png) no-repeat right`,
					'background-position-y': '10px',
					'background-size': '55px 60px',
				},
				'.card-number.invalid.card-type-visa': {
					'background': `rgba(0,0,0,0) url(${imageBaseUrl}/logo-visa@2x.png) no-repeat right`,
					'background-position-y': '-25px',
					'background-size': '55px 60px',
				},
				'.card-number.invalid.card-type-mastercard': {
					'background': `rgba(0,0,0,0) url(${imageBaseUrl}/logo-mastercard@2x.png) no-repeat right`,
					'background-position-y': '-30px',
					'background-size': '55px 60px',
				},
				'.card-number.invalid.card-type-discover': {
					'background': `rgba(0,0,0,0) url(${imageBaseUrl}/logo-discover@2x.png) no-repeat right`,
					'background-position-y': '-25px',
					'background-size': '55px 60px',
				},
				'.card-number.invalid.card-type-jcb': {
					'background': `rgba(0,0,0,0) url(${imageBaseUrl}/logo-jcb@2x.png) no-repeat right`,
					'background-position-y': '-27px',
					'background-size': '55px 60px',
				},
				'.card-number.invalid.card-type-amex': {
					'background': `rgba(0,0,0,0) url(${imageBaseUrl}/logo-amex@2x.png) no-repeat right`,
					'background-position-y': '-26px',
					'background-size': '55px 60px',
				},
				'.card-cvv': {
					'background': `rgba(0,0,0,0) url(${imageBaseUrl}/cvv.png) no-repeat right`,
					'background-size': '50px 30px',
				},
				'.card-cvv.card-type-amex': {
					'background': `rgba(0,0,0,0) url(${imageBaseUrl}/cvv-amex.png) no-repeat right`,
					'background-size': '48px 30px',
				},
			},
		});

		// called when all iframe fields have been loaded
		this.cardForm.ready(() => {
			this.isCardFormLoaded = true;
			// refresh iframe fields so they display properly and focus on card number field
			setTimeout(() => {
				this.cardForm.addStylesheet({});
				this.cardForm.frames['card-number'].setFocus();
			});
		});

		// called when CC data is valid
		this.cardForm.on('token-success', response => success(response.temporary_token));

		// called when CC data is invalid
		this.cardForm.on('token-error', response => {
			this.handleCardFormError(response);
			error(this.errors);
		});
	}

	handleGlobalPaymentsError(error: any) {
		// user unlikely to see these global configuration/iframe related errors
		if (ObjectUtils.isNotEmptyArray(error?.reasons)) {
			this.errors = _uniq(error.reasons.map(reason => reason.message));
		} else {
			this.errors = ['Configuration Error'];
		}
	}

	handleCardFormError(response: any) {
		// convert internal error code or path + description to user friendly error message
		const errorLookup = {
			'authentication_failed': 'Authentication Failed. Please try again',
			'invalid_card': 'Card number is invalid. Check the card details or try a different card',
			'/card/card_number|Invalid data': 'Card number is required',
			'/card/card-number|Invalid data': 'Card number is required',
			'/card/card-expiration|Invalid data': 'Card expiration is required',
			'/card|Missing required property: expiry_month': 'Card expiration month is required',
			'/card/expiry_month|Missing required property: expiry_month.': 'Card expiration month is required',
			'/card/expiry_month|Invalid data': 'Card expiration month is invalid',
			'/card/expiry_year|Missing required property: expiry_year.': 'Card expiration year is required',
			'/card/expiry_year|Invalid data: String is too short': 'Card expiration year is required',
			'/card/expiry_year|Invalid data': 'Card expiration year is invalid',
			'/card/card_security_code|Invalid data: String is too short': 'Card security code (CVV) is required',
			'/card/card_security_code|Invalid data': 'Card security code (CVV) is invalid',
		};
		if (ObjectUtils.isNotEmptyArray(response?.error?.detail)) {
			this.errors = _uniq(response.error.detail.map(detail =>
				errorLookup[`${detail.data_path}|${detail.description}`] || `${detail.data_path} ${detail.description}`));
		} else if (ObjectUtils.isNotEmptyString(response?.error?.code) || ObjectUtils.isNotEmptyString(response?.error?.message)) {
			this.errors = [errorLookup[response.error.code] || response.error.message || response.error.code];
		} else {
			this.errors = [OpenEdgeCommonPaymentStatus.ERROR.label];
		}
		this.isProcessing = false;
	}

	creditCardFormDisabled() {
		return !this.isCardFormLoaded || this.isProcessing;
	}

	processCard() {
		if (this.creditCardFormDisabled()) {
			return;
		}

		this.isProcessing = true;
		this.errors = [];
		// hack to avoid using the iframe submit button
		this.cardForm.requestDataFromAll(this.cardForm.frames['card-number']);
	}

	setProcessing(processingFlag) {
		this.isProcessing = processingFlag;
	}

	getCreditCardProcessing() {
		return this.isProcessing;
	}

	getCreditCardProcessingError() {
		return this.errors;
	}

	getCardFormLoaded() {
		return this.isCardFormLoaded;
	}

	disposeCardForm() {
		this.cardForm?.dispose();
	}
}
