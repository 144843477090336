import { Injectable } from '@angular/core';
import { RevEngageGandalfService } from '@gandalf/services';
import { _isNil } from '@core/lodash/lodash';
import { UrlService } from '@core/url-util/url.service';
import { AuthenticationService } from '@core/authentication/authentication.service';
import { UserLocationsService } from '@core/user-locations/user-locations.service';
import { PracticeLocation } from '@core/security-manager/security-manager.service';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { PracticeLocationRevEngageSettingsResponse } from '@gandalf/model/practice-location-rev-engage-settings-response';

@Injectable({
	providedIn: 'root',
})
export class RevEngageService {

	private doctibleWindow: WindowProxy;
	private practiceLocationRevEngageSettings: PracticeLocationRevEngageSettingsResponse[] = [];

	constructor(
		private revEngageGandalfService: RevEngageGandalfService,
		private urlService: UrlService,
		private authenticationService: AuthenticationService,
		private userLocationsService: UserLocationsService,
		private featureService: FeatureService,
	) {
		// load location settings for header determine if we should show the RevEngage tab
		this.authenticationService.loggedIn.subscribe(() => this.loadPracticeLocationRevEngageSettings());
		// on logout close Doctible tab if it is open
		this.authenticationService.loggedOut.subscribe(() => this.closeDoctibleTab());
		// the Doctible URL JWT contains the location ID, so we need to reload the tab when the location changes
		this.userLocationsService.currentLocation.subscribe(location => this.updateDoctibleTab(location));
	}

	getDoctibleSsoUrl() {
		return this.revEngageGandalfService.getDoctibleSsoUrl();
	}

	openDoctibleSsoTab() {
		if (this.isDoctibleTabOpen()) {
			this.doctibleWindow.focus();
		} else {
			this.getDoctibleSsoUrl().subscribe(url => this.doctibleWindow = this.urlService.goToWebpage(url, 'revengage_doctible'));
		}
	}

	updateDoctibleTab(location: PracticeLocation) {
		if (this.isDoctibleTabOpen()) {
			if (this.featureService.isFeatureOff(FEATURE_FLAGS.FEATURES.REVENGAGE.USER_ROLES) || this.isLocationRevEngageEnabled(location.id)) {
				this.getDoctibleSsoUrl().subscribe(url => this.doctibleWindow.location = url);
			} else {
				// close the doctible tab if the current location does not have RevEngage enabled
				this.closeDoctibleTab();
			}
		}
	}

	closeDoctibleTab() {
		if (this.isDoctibleTabOpen()) {
			this.doctibleWindow.close();
		}
		this.doctibleWindow = null;
	}

	isDoctibleTabOpen() {
		return !_isNil(this.doctibleWindow) && !this.doctibleWindow.closed;
	}

	loadPracticeLocationRevEngageSettings() {
		if (this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.REVENGAGE.USER_ROLES)) {
			this.revEngageGandalfService.findPracticeLocationRevEngageSettings().subscribe(settings => this.practiceLocationRevEngageSettings = settings);
		}
	}

	isLocationRevEngageEnabled(locationId: number) {
		return this.practiceLocationRevEngageSettings.some(settings => settings.practiceLocationId === locationId && settings.isRevEngageEnabled);
	}
}
