import { Injectable } from '@angular/core';
import { SearchSnomedCodesRequest } from '@gandalf/model/search-snomed-codes-request';
import { SnomedCodeResponse } from '@gandalf/model/snomed-code-response';
import { SnomedCodeGandalfService } from '@gandalf/services';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SnomedCodeService {

	constructor(
		private snomedCodeGandalfService: SnomedCodeGandalfService,
	) { }

	/* istanbul ignore next: gandalf */
	searchSnomedCodes(request: SearchSnomedCodesRequest): Observable<SnomedCodeResponse[]> {
		return this.snomedCodeGandalfService.searchSnomedCodes(request);
	}
}
