import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UpdateRouteValue } from '@app-store/actions/routes.actions';
import { State } from '@app-store/reducers';
import { selectComponentRouteState } from '@app-store/selectors/routes.selectors';
import { StatefulServiceBase } from '@app-store/services/stateful-service-base';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable()
export class StatefulRouteService extends StatefulServiceBase {

	constructor(private store: Store<State>) {
		super();
	}

	static routePastPersistedRoute(router: Router, url: string | any[], extras?: any) {
		window['revGlobals'] = {...(window['revGlobals'] || {}), overridePersistedRoutes: true};
		if (typeof url === 'string') {
			return router.navigateByUrl(url);
		} else {
			return router.navigate(url, extras);
		}
	}

	updateRouteValue(route: string) {
		this.store.dispatch(new UpdateRouteValue({key: this.storeKey, route}));
	}

	selectRouteState(): Observable<string> {
		return this.store.select(selectComponentRouteState({key: this.storeKey}));
	}
}
