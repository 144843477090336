import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import concat from 'lodash/concat';
import { of } from 'rxjs';
import { mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { AddCacheKeyEndpoint, RemoveCacheKey } from '../actions/cacheKey.actions';
import { BustCache, CallEndpoint, GandalfActionTypes } from '../actions/gandalf.actions';
import { RemoveEndpointResponse, UpdateEndpointResponse } from '../actions/response.actions';
import { GandalfState } from '../reducers/root';
import { selectGandalfCacheKey } from '../selectors/cacheKey.selectors';

@Injectable()
export class GandalfEffects {

	bustCache = createEffect(() => this.actions.pipe(
		ofType(GandalfActionTypes.BUST_CACHE),
		mergeMap((action: BustCache) => of(action).pipe(
			withLatestFrom(this.store.select(selectGandalfCacheKey({key: action.payload.cacheKey}))),
		)),
		mergeMap(([action, cacheKeyState]) => {
			const removeEndpoints = cacheKeyState?.endpointIds.map(endpointId =>
				new RemoveEndpointResponse({endpointId}));
			const removeCacheKey = new RemoveCacheKey({cacheKey: action.payload.cacheKey});
			return removeEndpoints ? concat(removeEndpoints as Action[], removeCacheKey as Action) : [removeCacheKey as Action];
		}),
	));

	callEndpoint = createEffect(() => this.actions.pipe(
		ofType<CallEndpoint>(GandalfActionTypes.CALL_ENDPOINT),
		mergeMap(action => {
			const {cacheKeys, endpointId, endpoint} = action.payload;
			return endpoint.pipe(
				switchMap(response => {
					const actions: Action[] = this.buildCacheKeyEndpointActions(cacheKeys, endpointId);
					actions.push(new UpdateEndpointResponse({endpointId, response}));
					return actions;
				}),
			);
		}),
	));

	constructor(
		private actions: Actions,
		private store: Store<GandalfState>,
	) {
	}

	private buildCacheKeyEndpointActions(cacheKeys: string[], endpointId): AddCacheKeyEndpoint[] {
		return cacheKeys.map(cacheKey => new AddCacheKeyEndpoint({cacheKey, endpointId}));
	}
}
