import { Component, TemplateRef } from '@angular/core';
import { _cloneDeep } from '@core/lodash/lodash';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';

@Component({
	selector: 'pms-header-cell-renderer',
	templateUrl: './header-cell-renderer.component.html',
	styles: [],
})
export class HeaderCellRendererComponent implements IHeaderAngularComp {

	template: TemplateRef<any>;
	templateContext: { $implicit: any, params: any };
	params: IHeaderParams;

	isFiltering = false;
	isAscSort = false;
	isDescSort = false;

	refresh(params: any): boolean {
		this.templateContext = {
			$implicit: _cloneDeep(params.data),
			params,
		};
		return true;
	}

	agInit(params: IHeaderParams): void {
		this.params = params;
		this.template = params['ngTemplate'];

		params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
		this.onSortChanged();

		params.column.addEventListener('filterChanged', this.filterChanged.bind(this));
		this.filterChanged();

		// by default, columns begin in a special unsorted state
		// this forces a noSort state so the next sort is ascending
		if (this.params.enableSorting && this.params.column.getSort() === undefined) {
			this.params.setSort(null);
		}
		this.refresh(params);
	}

	onHeaderClick() {
		// if sorting is enabled, on click will progress the sort (i.e. asc=>desc=>none)
		if (this.params.enableSorting) {
			this.params.progressSort(true);
		}
	}

	filterChanged = () => {
		this.isFiltering = this.params.column.isFilterActive();
	};

	onSortChanged = () => {
		this.isAscSort = this.params.column.getSort() === 'asc';
		this.isDescSort = this.params.column.getSort() === 'desc';
	};
}
