import { Injectable } from '@angular/core';
import { _isNil, _uniq } from '@core/lodash/lodash';
import { FieldDefinitionResponse } from '@gandalf/model/field-definition-response';
import { FieldOptionResponse } from '@gandalf/model/field-option-response';
import { FieldValueMultiSelectionResponse } from '@gandalf/model/field-value-multi-selection-response';
import { AbstractControl } from '@angular/forms';

@Injectable({
	providedIn: 'root',
})
export class FieldUtilsService {

	constructor() { }

	static getFieldValueMultiSelectionValueIds(fieldValueMultiSelection: FieldValueMultiSelectionResponse) {
		if (_isNil(fieldValueMultiSelection) || _isNil(fieldValueMultiSelection.values)) {
			return null;
		}
		return fieldValueMultiSelection.values.map(fieldOption => fieldOption.id);
	}

	static getFieldValueMultiSelectionNormals(fieldValueMultiSelection: FieldDefinitionResponse) {
		if (_isNil(fieldValueMultiSelection) || _isNil(fieldValueMultiSelection.values)) {
			return null;
		}
		return fieldValueMultiSelection.values
			.filter(fieldOption => fieldOption.isDefaultNormal)
			.map(fieldOption => fieldOption.id);
	}

	static handleMultiSelectChangesWithNormalValues(
		formComponent: AbstractControl,
		currentSelectedIds: number[],
		multiSelect: FieldDefinitionResponse,
		previousSelectedIds: number[],
	) {
		const defaultNormalIds = this.getFieldValueMultiSelectionNormals(multiSelect);
		if (currentSelectedIds?.length > previousSelectedIds?.length // is a selection not deselection
			&& defaultNormalIds?.length > 0 // this has default normals
		) {
			const previouslySelectedDefaultNormalIds = defaultNormalIds.filter(value => previousSelectedIds.includes(value));
			const isNotDefaultNormalSelection = !!currentSelectedIds.filter(id => !previousSelectedIds.includes(id))
				.find((selectedId) => !defaultNormalIds.includes(selectedId));
			if (previouslySelectedDefaultNormalIds.length > 0 && isNotDefaultNormalSelection) {
				formComponent.setValue(currentSelectedIds.filter(id => !previouslySelectedDefaultNormalIds.includes(id)));
			}
		}
	}

	static hasNonDefaultNormalOption(componentValue: number[], multiSelect: FieldDefinitionResponse ){
		const defaultNormalIds = this.getFieldValueMultiSelectionNormals(multiSelect);
		return componentValue?.some(fieldId => !defaultNormalIds.includes(fieldId));
	}

	static setNormalValue(formComponent: AbstractControl, multiSelect: FieldDefinitionResponse){
		const currentValue = formComponent.value ?? [];
		const defaultNormals = this.getFieldValueMultiSelectionNormals(multiSelect);

		formComponent.setValue(_uniq(currentValue.concat(defaultNormals)));
	}

	static fieldOptionsToIdArray(fieldOptions: FieldOptionResponse[]) {
		return fieldOptions ? fieldOptions.map(fieldOption => fieldOption.id) : null;
	}
}
