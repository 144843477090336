// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.accounting.eyefinity.AdminEyefinityFeeServiceRequest */
/* istanbul ignore next */
@GandalfModel
export class AdminEyefinityFeeServiceRequest extends GandalfModelBase {

	@GandalfLabel('Exam Service')
	@GandalfProperty()
	eyefinityFeeServiceId: number;

	@GandalfLabel('Exam Service Code')
	@GandalfValidator({ notNull: { message: 'Exam Service Code is required' } })
	@GandalfValidator({ sizeString: { message: 'Exam Service Code must be between 1 and 5 characters', minLength: 1, maxLength: 5 } })
	@GandalfProperty({ isRequired: true })
	hcpcsCode: string;

	@GandalfLabel('Exam Service Name')
	@GandalfValidator({ notNull: { message: 'Exam Service Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Exam Service Name must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ isRequired: true })
	name: string;

	@GandalfLabel('Exam Service Reimbursement')
	@GandalfValidator({ notNull: { message: 'Exam Service Reimbursement is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	reimbursement: number;

}
