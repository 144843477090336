import { Directive, HostListener } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { GridComponent } from '@syncfusion/ej2-angular-grids';

@Directive({
	selector: 'ejs-grid[pmsInputSpecialKeyHandlerDirective]',
	exportAs: 'InputSpecialKeyHandlerDirective',
})
export class InputSpecialKeyHandlerDirective {

	private _activeElementIndex: number;

	get activeElementIndex() {
		return this._activeElementIndex;
	}

	set activeElementIndex(index: number) {
		this._activeElementIndex = index;
	}

	constructor(
		private gridComponent: GridComponent,
	) {
	}

	/**
	 * When the grid rerenders we need to attempt to set the currently active element
	 */
	@HostListener('actionComplete')
	actionComplete() {
		const inputArray = this.getActiveInputElements();
		if (!_isNil(this.activeElementIndex)) {
			this.focusActiveElement(inputArray);
		}
	}

	/**
	 * Focuses active element if not null
	 * SetTimeout used because the grid sometimes tries to focus other elements even with preventing the event and this needs to occur after
	 */
	focusActiveElement(inputArray: NodeListOf<HTMLInputElement>) {
		const activeElement = this.getActiveElement(inputArray);
		if (!_isNil(activeElement)) {
			setTimeout(() => {
				activeElement.focus();
				activeElement.select();
			});
		}
	}

	/**
	 * Attempts to find the active element by index in the input array
	 */
	getActiveElement(inputArray: NodeListOf<HTMLInputElement>) {
		return inputArray.length > this.activeElementIndex ? inputArray[this.activeElementIndex] : null;
	}

	/**
	 * Watches the keydown events and handles the tab button
	 */
	@HostListener('keydown', ['$event'])
	handleTableTabbing(event: KeyboardEvent) {
		if (event.code === 'Tab') {
			const inputArray = this.getActiveInputElements();
			if (inputArray.length) {
				const elementIndex = this.findElementIndexInInputArray(event.target, inputArray);
				if (!_isNil(elementIndex)) {
					this.onTabDown(event, elementIndex, inputArray);
				} else {
					this.activeElementIndex = 0;
					this.focusActiveElement(inputArray);
				}
				event.stopImmediatePropagation();
			}
		}
		if (event.code === 'Enter' || event.code === 'NumpadEnter') {
			const inputArray = this.getInputElements();
			if (inputArray.length) {
				const elementIndex = this.findElementIndexInInputArray(event.target, inputArray);
				if (!_isNil(elementIndex)) {
					this.onEnterDown(event, elementIndex, inputArray);
				}
				event.stopImmediatePropagation();
			}
		}
	}

	/**
	 * Gets the active non checkbox input elements from a table that do not ignore tab
	 */
	getActiveInputElements() {
		return this.gridComponent.element.querySelectorAll<HTMLInputElement>('input:not([disabled]):not([type="checkbox"]):not(.ignore-tab)');
	}

	/**
	 * Gets all non checkbox input elements from a table that do not ignore tab
	 */
	getInputElements() {
		return this.gridComponent.element.querySelectorAll<HTMLInputElement>('input:not([type="checkbox"]):not(.ignore-tab)');
	}

	@HostListener('click', ['$event'])
	handleTableClick(event) {
		const inputArray = this.getActiveInputElements();
		this.activeElementIndex = this.findElementIndexInInputArray(event.target, inputArray);
	}

	private onTabDown(event: KeyboardEvent, index: number, inputArray: NodeListOf<HTMLInputElement>) {
		if (event.shiftKey) {
			this.activeElementIndex = index - 1 >= 0 ? index - 1 : inputArray.length - 1;
		} else {
			this.activeElementIndex = index + 1 >= inputArray.length ? 0 :  index + 1;
		}

		this.focusActiveElement(inputArray);
	}

	private onEnterDown(event: KeyboardEvent, index: number, inputArray: NodeListOf<HTMLInputElement>) {
		let nextIndex = index;
		do {
			nextIndex = this.getNextRowIndex(event, nextIndex, inputArray);
		} while (this.isValidIndex(nextIndex, inputArray) && this.isDisabled(nextIndex, inputArray));

		if (this.isValidIndex(nextIndex, inputArray)) {
			this.activeElementIndex = nextIndex;
		}
		this.focusActiveElement(inputArray);
	}

	private getNextRowIndex(event: KeyboardEvent, index: number, inputArray: NodeListOf<HTMLInputElement>) {
		const rowCount = this.gridComponent?.getRows()?.length;
		const inputsPerRow = inputArray.length / rowCount;
		const inputIndexOnRow = index % inputsPerRow;
		const currentRow = Math.floor(index / inputsPerRow);

		if (event.shiftKey) {
			return ((currentRow - 1) * inputsPerRow) + inputIndexOnRow;
		} else {
			return ((currentRow + 1) * inputsPerRow) + inputIndexOnRow;
		}
	}

	/**
	 * This is a node list not an array so find doesn't exist on it
	 */
	findElementIndexInInputArray(currentTarget: EventTarget, inputArray: NodeListOf<HTMLInputElement>) {
		let foundIndex = null;
		inputArray.forEach((element, index) => {
			if (_isNil(foundIndex) && element === currentTarget) {
				foundIndex = index;
				return;
			}
		});
		return foundIndex;
	}

	private isDisabled(index: number, inputArray: NodeListOf<HTMLInputElement>) {
		return inputArray[index].disabled;
	}

	private isValidIndex(index: number, inputArray: NodeListOf<HTMLInputElement>) {
		return inputArray.length > index && index >= 0;
	}
}
