import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({providedIn: 'root'})
@Pipe({name: 'fileSize'})
export class FileSizePipe implements PipeTransform {

	transform(value: number) {
		let i = 0;
		const byteUnits = [' B', ' KB', ' MB', ' GB', ' TB', ' PB', ' EB', ' ZB', ' YB'];
		while (value > 1024 && i < byteUnits.length) {
			value = value / 1024;
			i++;
		}

		return value.toFixed(2) + byteUnits[i];
	}

}
