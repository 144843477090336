import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { _isNaN, _isNil, _padStart } from '@core/lodash/lodash';
import { Big } from 'big.js';

@Pipe({
	name: 'numberCustomFormatter',
})
@Injectable()
export class NumberCustomFormatterPipe implements PipeTransform {

	private removeFormatRegex = new RegExp(/(?!-)[^0-9.]/g);

	transform(value: string | number, format: string, precision = 0, truncate = false): any {
		if (_isNil(value) || _isNaN(value)) {
			return '';
		}

		if (!format) {
			if (_isNil(precision)) {
				return Big(value).toFixed(0);
			} else {
				return Big(value).toFixed(precision);
			}
		}

		value = this.stripFormatting(value);

		if (_isNaN(Number(value))) {
			return value;
		}

		const decimalOnlyRegex = /(.*)\./g;
		const bigValue = Big(value);
		let numberSignature = '';
		let leftPadCount = 0;
		let decimalPrecisionCount = 0;
		let decimalMode = false;
		let returnValue = '';

		const formatArray = format.split('');

		formatArray.forEach(formatChar => {
			switch (formatChar) {
				case '#':
					decimalMode ? decimalPrecisionCount++ : leftPadCount++;
					break;
				case '0':
					decimalMode ? decimalPrecisionCount++ : leftPadCount++;
					break;
				case '+':
					numberSignature = this.getNumberSignature(bigValue);
					break;
				case '-':
					numberSignature = this.getNumberSignature(bigValue);
					break;
				case '.':
					decimalMode = true;
					break;
			}
		});

		// number should still display negative even if format doesn't require a signed integer
		returnValue += bigValue.lt(0) ? '-' : numberSignature;

		// If this is a decimal we only want to allow one leading 0
		const leadingDigits = decimalMode ? Math.min(leftPadCount, 1) : leftPadCount;
		returnValue += _padStart(bigValue.abs().round(0, Big.roundDown).toFixed(0), leadingDigits, '0');

		if (decimalPrecisionCount !== 0) {
			if (truncate) {
				returnValue += '.' + bigValue.round(decimalPrecisionCount, Big.roundDown).toFixed(decimalPrecisionCount).replace(decimalOnlyRegex, '');
			} else {
				returnValue += '.' + bigValue.toFixed(decimalPrecisionCount).replace(decimalOnlyRegex, '');
			}
		}

		return returnValue;
	}

	/**
	 * If value is 0, return no sign
	 * If value is greater than 0, return +
	 * Otherwise return -
	 */
	private getNumberSignature(value: Big) {
		if (value.eq(0)) {
			return '';
		}

		return value.gt(0) ? '+' : '-';
	}

	private stripFormatting(value: string | number) {
		return value.toString().replace(this.removeFormatRegex, '');
	}

}
