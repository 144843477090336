import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { ToasterService } from '@core/toaster/toaster.service';
import { ClipboardUtil } from '@shared/Utils/ClipboardUtil';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
	selector: 'pms-open-edge-validation-key-mismatch-modal',
	templateUrl: './open-edge-validation-key-mismatch-modal.component.html',
})
export class OpenEdgeValidationKeyMismatchModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('divContent')
	divContent: ElementRef<HTMLDivElement>;

	transactionDate: string;
	amountApproved: string;
	paymentType: string;
	authorizationCode: string;
	referenceId: string;
	accountNumber: string;

	constructor(
		private dynamicModalRef: DynamicModalRef,
		private modalConfig: ModalConfig,
		private toasterService: ToasterService,
	) {
	}

	ngOnInit() {
		this.parseModalConfig(this.modalConfig.data);
	}

	parseModalConfig(data) {
		this.transactionDate = data.transactionDate;
		this.paymentType = data.paymentType;
		this.amountApproved = data.amountApproved;
		this.authorizationCode = data.authorizationCode;
		this.referenceId = data.referenceId;
		this.accountNumber = data.accountNumber;
	}

	get last4CardNumber() {
		return this.accountNumber?.slice(-4);
	}

	copyToClipboard() {
		ClipboardUtil.copyTextToClipboard(this.divContent.nativeElement.innerText);
		this.toasterService.showSavedSuccess({content: 'Successfully copied to clipboard'});
	}

	/* istanbul ignore next */
	closeModal() {
		this.dynamicModalRef.close(this.modal);
	}
}
