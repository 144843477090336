<div class="e-input-group e-float-icon-left">
	<span class="e-input-group-icon">$</span>
	<div class="e-input-in-wrap">
		<input [id]="htmlId" #inputElement
			   class="e-input"
			   [disabled]="disabled"
			   [placeholder]="placeholder"
			   [(ngModel)]="currencyString"
			   [min]="min"
			   (blur)="onblur()"
			   (keydown.enter)="formatCost()"
			   type="number"
			   [ngModelOptions]="{standalone: true}"/>
	</div>
</div>
