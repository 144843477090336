// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FeeScheduleRangeResponse } from './fee-schedule-range-response';

/** See com.rev360.pms.api.controller.admin.accounting.feeschedule.FeeScheduleResponse */
/* istanbul ignore next */
@GandalfModel
export class FeeScheduleResponse extends GandalfModelBase {

	@GandalfProperty()
	comments: string;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	name: string;

	@GandalfArray(FeeScheduleRangeResponse)
	ranges: FeeScheduleRangeResponse[];

}
