import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormGroup, NgForm} from '@angular/forms';
import {DynamicModalRef, ModalConfig, OptionItem} from 'morgana';
import {PatientContactService} from '@core/patient-contact/patient-contact.service';
import {PatientService} from '@core/patient/patient.service';
import {ReferenceDataService} from '@core/reference-data/reference-data.service';
import { PreferredPhoneType, ReferenceDataMasterCategory } from '@gandalf/constants';
import {ContactDemographicsRequest} from '@gandalf/model/contact-demographics-request';
import {QuickAddContactRequest} from '@gandalf/model/quick-add-contact-request';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {GandalfFormBuilder} from 'gandalf';
import {FeatureService} from '@core/feature/feature.service';
import {FEATURE_FLAGS} from '@core/feature/feature.constants';
import {AddressOptionalRequest} from '@gandalf/model/address-optional-request';
import {AddressRequiredRequest} from '@gandalf/model/address-required-request';
import {PhoneInformationRequest} from '@gandalf/model/phone-information-request';
import {ContactInformationRequest} from '@gandalf/model/contact-information-request';
import { EventService } from '@core/event/event.service';

@Component({
	selector: 'pms-quick-add-contact-modal',
	templateUrl: './quick-add-contact-modal.component.html',
	styles: [],
})
export class QuickAddContactModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('templateForm')
	templateForm: NgForm;

	componentForm: UntypedFormGroup;
	quickAddContactRequest: QuickAddContactRequest;
	patientId: number;
	roleOptions: OptionItem[];
	emergencyContactFeatureFlagOn: boolean;
	isEmergencyContact: boolean;
	isPatientAddressRequired: boolean;

	constructor(
		private ref: DynamicModalRef,
		private modalConfig: ModalConfig,
		private gandalfFormBuilder: GandalfFormBuilder,
		private referenceDataService: ReferenceDataService,
		private patientContactService: PatientContactService,
		private patientService: PatientService,
		private featureService: FeatureService,
		private eventService: EventService,
	) { }

	ngOnInit() {
		this.patientId = this.modalConfig.data.patientId;
		this.handleFeatures();
		this.createForm();
		this.getFormData();
	}

	handleFeatures() {
		this.emergencyContactFeatureFlagOn = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.EMERGENCY_CONTACT);
		this.isPatientAddressRequired = this.emergencyContactFeatureFlagOn ? this.patientService.isPatientAddressRequired() : false;
	}

	createForm() {
		this.quickAddContactRequest = new QuickAddContactRequest();
		this.quickAddContactRequest.contactDemographicsRequest = new ContactDemographicsRequest();
		this.quickAddContactRequest.address = this.patientService.getEmptyAddressRequest();
		this.quickAddContactRequest.patientId = this.patientId;
		this.quickAddContactRequest.contactInformation = new ContactInformationRequest();
		this.quickAddContactRequest.contactInformation.phoneInformation = new PhoneInformationRequest();
		this.initializePreferredPhone();
		this.componentForm = this.gandalfFormBuilder.group(this.quickAddContactRequest);

		this.patientService.setRequiredValidatorPostalCode(this.componentForm.get('address') as UntypedFormGroup);
	}

	initializePreferredPhone() {
		if (!this.emergencyContactFeatureFlagOn) {
			this.quickAddContactRequest.contactInformation.phoneInformation.preferredPhoneType = PreferredPhoneType.HOME;
		}
	}

	getFormData() {
		this.referenceDataService.getActiveReferenceDataByCategoryIdForDropdown(
			ReferenceDataMasterCategory.PATIENT_CONTACT_ROLE.value,
		).subscribe(roles => {
			this.roleOptions = roles;
		});
	}

	/* istanbul ignore next */
	closeModal(result?) {
		this.ref.close(this.modal, result);
	}

	save() {
		if (this.componentForm.invalid) {
			return;
		}

		this.handlePrimaryEmergencyContact();

		this.patientContactService.quickAddContact(this.componentForm.value).subscribe(response => {
			this.eventService.publishUpdatePatientContacts(this.patientId);
			this.closeModal(response);
		});
	}

	handlePrimaryEmergencyContact() {
		if (!this.isEmergencyContact) {
			this.componentForm.get('isPrimaryEmergencyContact').setValue(null);
		}
	}

	/* istanbul ignore next */
	submitForm(event: any) {
		this.templateForm.onSubmit(event);
	}

	onEmergencyContactCheck(event) {
		this.isEmergencyContact = event.checked;

		if (!this.isPatientAddressRequired) {
			return;
		}
		const addressValues = this.captureAddressFormValues();
		this.replaceAddressFormControl(event.checked);
		this.setAddressFormValues(addressValues);

		if (!this.isEmergencyContact) {
			this.patientService.setRequiredValidatorPostalCode(this.componentForm.get('address') as UntypedFormGroup);
		}
	}

	captureAddressFormValues() {
		return {
			addressLine1: this.componentForm.get('address').get('addressLine1').value,
			addressLine2: this.componentForm.get('address').get('addressLine2').value,
			stateProvince: this.componentForm.get('address').get('stateProvince').value,
			city: this.componentForm.get('address').get('city').value,
			postalCode: this.componentForm.get('address').get('postalCode').value,
			nonStandardPostalCode: this.componentForm.get('address').get('nonStandardPostalCode').value,
		};
	}

	setAddressFormValues(addressValues) {
		this.componentForm.get('address').get('addressLine1').setValue(addressValues.addressLine1);
		this.componentForm.get('address').get('addressLine2').setValue(addressValues.addressLine2);
		this.componentForm.get('address').get('stateProvince').setValue(addressValues.stateProvince);
		this.componentForm.get('address').get('city').setValue(addressValues.city);
		this.componentForm.get('address').get('postalCode').setValue(addressValues.postalCode);
		this.componentForm.get('address').get('nonStandardPostalCode').setValue(addressValues.nonStandardPostalCode);
	}

	replaceAddressFormControl(removeRequirement: boolean) {
		this.componentForm.removeControl('address');
		this.quickAddContactRequest.address = removeRequirement ? new AddressOptionalRequest() : new AddressRequiredRequest();
		this.componentForm.addControl('address', this.gandalfFormBuilder.group(this.quickAddContactRequest.address));
	}

	getFormGroup(formGroupName: string) {
		return this.componentForm.get(formGroupName) as UntypedFormGroup;
	}
}
