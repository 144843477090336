<ejs-dialog #modal cssClass="modal-lg" (close)="closeModal()" [attr.data-test-id]="'opticalHistoryModal'">
	<ng-template #header>
		Select Rx
	</ng-template>
	<ng-template #content>
		<ejs-tab #tabs [animation]="tabAnimation" (created)="setTabSelection()">
			<e-tabitems>
				<e-tabitem *ngIf="showContactLensHistory" [header]="{text: 'CL Rx History'}">
					<ng-template #content>
						<pms-contact-lens-history-table [patientId]="patientId" (selection)="clHistorySelection($event)" [encounterId]="encounterId"></pms-contact-lens-history-table>
					</ng-template>
				</e-tabitem>
				<e-tabitem *ngIf="showEyeglassHistory">
					<ng-template #headerText>
						<span [attr.data-test-id]="'eyeglassPrescriptionHistoryTab'">Eyeglass Rx History</span>
					</ng-template>
					<ng-template #content>
						<ejs-grid *ngIf="!viewHistoryStatusColumnFeatureOn else eyeglassRxAgGrid" #grid
								  autoFitColumns
								  [autoFitBlacklist]="['usedFor.value']"
								  [staticColumns]="[]"
								  [dataSource]="eyeglassHx"
								  gridLines="Horizontal"
								  [allowSorting]="true"
								  [allowSelection]="true"
								  [allowPaging]="true"
								  [pageSettings]="pageSettings"
								  [allowResizing]="true"
								  [allowFiltering]="true"
								  [sortSettings]="sortSettings"
								  class="table-rowlink"
								  (rowSelected)="eyeglassHxSelected($event.data)"
								  [attr.data-test-id]="'opticalHistoryModalGrid'">
							<e-columns>
								<e-column field="startDate" [format]="tableDateFormat" headerText="Rx Date" clipMode="EllipsisWithTooltip">
								</e-column>
								<e-column field="expirationDate" [format]="tableDateFormat" headerText="Exp Date" clipMode="EllipsisWithTooltip">
								</e-column>
								<e-column field="usedFor.value" headerText="Used for" clipMode="EllipsisWithTooltip"></e-column>
								<e-column field="eye" headerText="Eye" clipMode="EllipsisWithTooltip"
							  		[customAttributes]="{class: 'align-top'}">
									<ng-template #template let-data>
										<span class="label label-success"><span *ngIf="eyeGlassHasValues(data.od)">OD</span></span>
										<br>
										<span class="label label-info" *ngIf="eyeGlassHasValues(data.os)">OS</span>
									</ng-template>
								</e-column>
								<e-column field="sphere" headerText="Sph" clipMode="EllipsisWithTooltip"
							  		[customAttributes]="{class: 'align-top'}">
									<ng-template #template let-data>
										{{data.od?.balanced ? 'Balance' : PrescriptionUtil.formatSphere(data.od?.sphere) }}
										<br />
										{{data.os?.balanced ? 'Balance' : PrescriptionUtil.formatSphere(data.os?.sphere) }}
									</ng-template>
								</e-column>
								<e-column field="cylinder" headerText="Cyl" clipMode="EllipsisWithTooltip"
							  		[customAttributes]="{class: 'align-top'}">
									<ng-template #template let-data>
										{{ PrescriptionUtil.formatCylinder(data.od?.cylinder) }}
										<br />
										{{ PrescriptionUtil.formatCylinder(data.os?.cylinder) }}
									</ng-template>
								</e-column>
								<e-column field="axis" headerText="Axis" clipMode="EllipsisWithTooltip"
							  		[customAttributes]="{class: 'align-top'}">
									<ng-template #template let-data>
										{{ PrescriptionUtil.formatAxis(data.od?.axis) }}
										<br />
										{{ PrescriptionUtil.formatAxis(data.os?.axis) }}
									</ng-template>
								</e-column>
								<e-column field="nearAdd" headerText="Near Add" clipMode="EllipsisWithTooltip"
							  		[customAttributes]="{class: 'align-top'}">
									<ng-template #template let-data>
										{{ PrescriptionUtil.formatAddPower(data.od?.nearAdd) }}
										<br />
										{{ PrescriptionUtil.formatAddPower(data.os?.nearAdd) }}
									</ng-template>
								</e-column>
								<e-column field="horizontalPrism" headerText="H Prism" clipMode="EllipsisWithTooltip"
							  		[customAttributes]="{class: 'align-top'}">
									<ng-template #template let-data>
										{{ PrescriptionUtil.formatPrism(data.od?.horizontalPrism) }} {{ data.od?.horizontalPrismOrientation?.label }}
										<br />
										{{ PrescriptionUtil.formatPrism(data.os?.horizontalPrism) }} {{ data.os?.horizontalPrismOrientation?.label }}
									</ng-template>
								</e-column>
								<e-column field="verticalPrism" headerText="V Prism" clipMode="EllipsisWithTooltip"
							  		[customAttributes]="{class: 'align-top'}">
									<ng-template #template let-data>
										{{ PrescriptionUtil.formatPrism(data.od?.verticalPrism) }} {{ data.od?.verticalPrismOrientation?.label }}
										<br />
										{{ PrescriptionUtil.formatPrism(data.os?.verticalPrism) }} {{ data.os?.verticalPrismOrientation?.label }}
									</ng-template>
								</e-column>
							</e-columns>
						</ejs-grid>
						<ng-template #eyeglassRxAgGrid>
							<rev-ag-grid-container [agGrid]="eyeglassRxHistoryAgGrid">
								<ng-container rev-table-action-menu-left>
									<div class="e-toolbar-item" [attr.data-test-id]="'opticalHistoryEyeglassPrescriptionFilterSection'">
										<label>Status</label>
										<pms-enum-select-button
												[(ngModel)]="currentFilter"
												[enumList]="filterOptions"
												[allOption]="true"
												(valueChange)="filterEyeglassRxTable()">
										</pms-enum-select-button>
									</div>
									<div class="e-toolbar-item">
										<ejs-checkbox
												[(ngModel)]="showDiscontinuedEyeglassRx"
												label="Show Discontinued"
												(ngModelChange)="filterEyeglassRxTable()"
												[attr.data-test-id]="'opticalHistoryModalPrescriptionShowDiscontinuedSection'">
										</ejs-checkbox>
									</div>
								</ng-container>
								<ng-container rev-container-grid>
									<ag-grid-angular class="ag-theme-alpine"
													 pmsAgAutoFitColumns
													 #eyeglassRxHistoryAgGrid
													 [rowData]="filteredEyeglassHx"
													 (rowClicked)="eyeglassHxSelected($event.data)"
													 [gridOptions]="eyeglassRxHistoryGridOptions"
													 (gridReady)="onEyeglassRxHistoryGridReady()"
													 [pagination]="true"
													 [suppressCsvExport]="true"
													 [attr.data-test-id]="'eyeglassRxHistoryTable'">
									</ag-grid-angular>
								</ng-container>
							</rev-ag-grid-container>
						</ng-template>
					</ng-template>
				</e-tabitem>
				<e-tabitem *ngIf="showRefractionHistory">
					<ng-template #headerText>
						<span [attr.data-test-id]="'refractiveTestsTab'">Refractive Tests</span>
					</ng-template>
					<ng-template #content>
						<ejs-grid *ngIf="!viewHistoryStatusColumnFeatureOn else refractionAgGrid" #grid
								  autoFitColumns
								  [autoFitBlacklist]="['testName']"
								  [staticColumns]="[]"
								  [dataSource]="refractionResponses"
								  gridLines="Horizontal"
								  [allowSorting]="true"
								  [allowSelection]="true"
								  [allowPaging]="true"
								  [pageSettings]="pageSettings"
								  [allowResizing]="true"
								  [allowFiltering]="true"
								  class="table-rowlink"
								  (rowSelected)="refractiveTestSelected($event.data)"
								  [attr.data-test-id]="'opticalHistoryModalRefractionGrid'">
							<e-columns>
								<e-column field="refractionName" headerText="Test Name" clipMode="EllipsisWithTooltip">
								</e-column>
								<e-column field="eye" headerText="Eye" clipMode="EllipsisWithTooltip"
							  		[customAttributes]="{class: 'align-top'}">
									<ng-template #template let-data>
										<span class="label label-success" *ngIf="hasOd(data)">OD</span>
										<br>
										<span class="label label-info" *ngIf="hasOs(data)">OS</span>
									</ng-template>
								</e-column>
								<e-column field="sphere" headerText="Sph" clipMode="EllipsisWithTooltip"
							  		[customAttributes]="{class: 'align-top'}">
									<ng-template #template let-data>
										{{ PrescriptionUtil.formatSphere(data.od?.sphere) }}
										<br />
										{{ PrescriptionUtil.formatSphere(data.os?.sphere) }}
									</ng-template>
								</e-column>
								<e-column field="cylinder" headerText="Cyl" clipMode="EllipsisWithTooltip"
							  		[customAttributes]="{class: 'align-top'}">
									<ng-template #template let-data>
										{{ PrescriptionUtil.formatCylinder(data.od?.cylinder) }}
										<br />
										{{ PrescriptionUtil.formatCylinder(data.os?.cylinder) }}
									</ng-template>
								</e-column>
								<e-column field="axis" headerText="Axis" clipMode="EllipsisWithTooltip"
							  		[customAttributes]="{class: 'align-top'}">
									<ng-template #template let-data>
										{{ PrescriptionUtil.formatAxis(data.od?.axis) }}
										<br />
										{{ PrescriptionUtil.formatAxis(data.os?.axis) }}
									</ng-template>
								</e-column>
								<e-column field="nearAdd" headerText="Near Add" clipMode="EllipsisWithTooltip"
							  		[customAttributes]="{class: 'align-top'}">
									<ng-template #template let-data>
										{{ PrescriptionUtil.formatAddPower(data.od?.nearAdd) }}
										<br />
										{{ PrescriptionUtil.formatAddPower(data.os?.nearAdd) }}
									</ng-template>
								</e-column>
							</e-columns>
						</ejs-grid>
						<ng-template #refractionAgGrid>
							<rev-ag-grid-container [agGrid]="refractionHistoryAgGrid" [showToolbar]="false">
								<ng-container rev-container-grid>
									<ag-grid-angular class="ag-theme-alpine"
													 pmsAgAutoFitColumns
													 #refractionHistoryAgGrid
													 [rowData]="refractionResponses"
													 (rowClicked)="refractiveTestSelected($event.data)"
													 [gridOptions]="refractionHistoryGridOptions"
													 (gridReady)="onRefractionHistoryGridReady()"
													 [pagination]="true"
													 [suppressCsvExport]="true"
													 [attr.data-test-id]="'refractionHistoryTable'">
									</ag-grid-angular>
								</ng-container>
							</rev-ag-grid-container>
						</ng-template>
					</ng-template>
				</e-tabitem>
			</e-tabitems>
		</ejs-tab>
	</ng-template>
	<ng-template #footerTemplate>
		<button type="button" ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'opticalHistoryModalCancelButton'">Cancel</button>
	</ng-template>
</ejs-dialog>

<ng-template #eyeglassRxEye let-data>
	<span class="label label-success"><span *ngIf="eyeGlassHasValues(data.od)">OD</span></span>
	<br />
	<span class="label label-info" *ngIf="eyeGlassHasValues(data.os)">OS</span>
</ng-template>

<ng-template #refractionEye let-data>
	<span class="label label-success" *ngIf="hasOd(data)">OD</span>
	<br />
	<span class="label label-info" *ngIf="hasOs(data)">OS</span>
</ng-template>

<ng-template #sphere let-data>
	{{data.od?.balanced ? 'Balance' : PrescriptionUtil.formatSphere(data.od?.sphere)}}
	<br />
	{{data.os?.balanced ? 'Balance' : PrescriptionUtil.formatSphere(data.os?.sphere)}}
</ng-template>

<ng-template #cylinder let-data>
	{{PrescriptionUtil.formatCylinder(data.od?.cylinder)}}
	<br />
	{{PrescriptionUtil.formatCylinder(data.os?.cylinder)}}
</ng-template>

<ng-template #axis let-data>
	{{PrescriptionUtil.formatAxis(data.od?.axis)}}
	<br />
	{{PrescriptionUtil.formatAxis(data.os?.axis)}}
</ng-template>

<ng-template #nearAdd let-data>
	{{PrescriptionUtil.formatAddPower(data.od?.nearAdd)}}
	<br />
	{{PrescriptionUtil.formatAddPower(data.os?.nearAdd)}}
</ng-template>

<ng-template #horizontalPrism let-data>
	{{PrescriptionUtil.formatPrism(data.od?.horizontalPrism)}} {{data.od?.horizontalPrismOrientation?.label}}
	<br />
	{{PrescriptionUtil.formatPrism(data.os?.horizontalPrism)}} {{data.os?.horizontalPrismOrientation?.label}}
</ng-template>

<ng-template #verticalPrism let-data>
	{{PrescriptionUtil.formatPrism(data.od?.verticalPrism)}} {{data.od?.verticalPrismOrientation?.label}}
	<br />
	{{PrescriptionUtil.formatPrism(data.os?.verticalPrism)}} {{data.os?.verticalPrismOrientation?.label}}
</ng-template>
