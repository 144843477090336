// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.assessment.AssessmentPersonDiagnosisResponse */
/* istanbul ignore next */
@GandalfModel
export class AssessmentPersonDiagnosisResponse extends GandalfModelBase {

	@GandalfConstantDecorator()
	@GandalfProperty()
	autoDiagnosisStatus: constants.EncounterAutoDiagnosisStatus;

	@GandalfProperty()
	code: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	codeSet: constants.CodeSet;

	@GandalfProperty({ propertyType: 'LocalDate' })
	diagnosisDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	diagnosisLocation: constants.DiagnosisLocation;

	@GandalfConstantDecorator()
	@GandalfProperty()
	diagnosisLocationQualifier: constants.DiagnosisLocationQualifier;

	@GandalfProperty({ propertyType: 'LocalDate' })
	disabledDate: Date;

	@GandalfProperty()
	encounterAutoDiagnosisId: number;

	@GandalfProperty()
	encounterId: number;

	@GandalfProperty()
	isPrimary: boolean;

	@GandalfProperty()
	isShowDisabledWarning: boolean;

	@GandalfProperty()
	needConversion: boolean;

	@GandalfProperty()
	originalDiagnosisId: number;

	@GandalfProperty()
	personDiagnosisId: number;

	@GandalfProperty()
	personId: number;

	@GandalfProperty()
	practiceDiagnosisId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	resolutionDate: Date;

	@GandalfProperty()
	shortDescription: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.PersonDiagnosisStatus;

}
