// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FamilyHistoryResponse } from './family-history-response';

// @ts-ignore
import { OcularHistoryResponse } from './ocular-history-response';

// @ts-ignore
import { SocialHistoryResponse } from './social-history-response';

/** See com.rev360.pms.api.controller.encounter.history.pfsh.PfshResponse */
/* istanbul ignore next */
@GandalfModel
export class PfshResponse extends GandalfModelBase {

	@GandalfProperty()
	familyHistory: FamilyHistoryResponse;

	@GandalfProperty()
	ocularHistory: OcularHistoryResponse;

	@GandalfProperty()
	socialHistory: SocialHistoryResponse;

}
