import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { _filter, _find, _isEmpty, _isNil, _isUndefined } from '@core/lodash/lodash';
import { DynamicModalRef, ForceDecimalPipe, ModalConfig, OptionItem } from 'morgana';
import { PatientService } from '@core/patient/patient.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { SECURITY_CONSTANTS } from '@core/security/security.constants';
import { CollectionsInvoiceTransferSplitRequest } from '@gandalf/model/collections-invoice-transfer-split-request';
import { InsuranceInvoiceTransferSplitRequest } from '@gandalf/model/insurance-invoice-transfer-split-request';
import { InvoiceResponse } from '@gandalf/model/invoice-response';
import { InvoiceTransferRequest } from '@gandalf/model/invoice-transfer-request';
import { PatientInvoiceTransferSplitRequest } from '@gandalf/model/patient-invoice-transfer-split-request';
import { WriteoffInvoiceTransferSplitRequest } from '@gandalf/model/writeoff-invoice-transfer-split-request';

import { PayerType } from '@gandalf/constants';
import { PatientNameResponse } from '@gandalf/model/patient-name-response';
import { PersonInsuranceSummaryResponse } from '@gandalf/model/person-insurance-summary-response';
import { CurrencyInputComponent } from '@shared/component/currency-input/currency-input.component';
import { assertTrue } from '@shared/validators/assert-true.validation';
import { ChangeEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { Big } from 'big.js';
import { GandalfFormBuilder } from 'gandalf';
import { combineLatest } from 'rxjs';
import { AccountingService, FormattedCollectionAgencyResponse, FormattedInsuranceResponse } from '../../core/accounting/accounting.service';
import { InvoiceItemPricingUtil } from '../../core/accounting/invoice-item-pricing/invoice-item-pricing-util';
import { InvoiceService } from '../../core/accounting/invoice-service/invoice.service';

@Component({
	selector: 'pms-transfer-invoice-modal',
	templateUrl: './transfer-invoice-modal.component.html',
	styles: [],
})

export class TransferInvoiceModalComponent implements OnInit {

	@ViewChild('patientAmountInput')
	patientAmountInput: CurrencyInputComponent;

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('templateForm')
	templateForm: NgForm;

	invoiceId: number;
	invoice: InvoiceResponse;
	formattedInvoiceBalance: string;
	patientName: PatientNameResponse;
	collectionAgencies: FormattedCollectionAgencyResponse[];
	invoiceTransferRequest: InvoiceTransferRequest;
	componentForm: UntypedFormGroup;
	insurances: FormattedInsuranceResponse[];
	writeoffReasons: OptionItem[];
	transferReasons: OptionItem[];
	// initializing this as true guarantees that if focus has to be set in the field it is visible
	showPatientFields = true;
	endingBalance: Big;
	transferAmount: Big;
	formattedTransferAmount: string;
	formattedEndingBalance: string;
	displayEndingBalanceRed: boolean;
	showAltInsuranceOption: boolean;
	showWriteoffOption = false;

	constructor(
		private ref: DynamicModalRef,
		private modalConfig: ModalConfig,
		private patientService: PatientService,
		private accountingService: AccountingService,
		private forceDecimalPipe: ForceDecimalPipe,
		private gandalfFormBuilder: GandalfFormBuilder,
		private invoiceService: InvoiceService,
		private currencyPipe: CurrencyPipe,
		private securityManagerService: SecurityManagerService,
	) {
	}

	ngOnInit() {
		this.showWriteoffOption = this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_ACCOUNTING_WRITEOFF);
		this.invoiceId = this.modalConfig.data.invoiceId;
		this.populateModal();
		this.initializeForm();
	}

	prepareInitialRequest(): InvoiceTransferRequest {
		const request = new InvoiceTransferRequest();
		request.invoiceId = this.invoiceId;
		request.writeoffInvoiceTransferSplitRequest = new WriteoffInvoiceTransferSplitRequest();
		request.patientInvoiceTransferSplitRequest = new PatientInvoiceTransferSplitRequest();
		request.insuranceInvoiceTransferSplitRequest = new InsuranceInvoiceTransferSplitRequest();
		request.collectionsInvoiceTransferSplitRequest = new CollectionsInvoiceTransferSplitRequest();
		return request;
	}

	validateDropdown(formGroupKey: string, dropdownKey: string): boolean {
		if (!_isUndefined(this.componentForm)) {
			const amountInput = this.componentForm.get(`${formGroupKey}.amount`);
			const dropdownInput = this.componentForm.get(`${formGroupKey}.${dropdownKey}`);
			if (!this.isValueNullOrZero(amountInput.value)) {
				return !!(dropdownInput.value);
			} else {
				return true;
			}
		}
	}

	areInputsInFormGroupNotNull(formGroupKey: string, dropdownKey: string, additionalDropdownKey?: string): boolean {
		const amountInput = this.componentForm.get(`${formGroupKey}.amount`);
		const dropdownInput = this.componentForm.get(`${formGroupKey}.${dropdownKey}`);
		if (!_isNil(additionalDropdownKey)) {
			const secondDropdownInput = this.componentForm.get(`${formGroupKey}.${additionalDropdownKey}`);
			return !!(!this.isValueNullOrZero(amountInput.value) && dropdownInput.value && secondDropdownInput.value);
		} else {
			return !!(!this.isValueNullOrZero(amountInput.value) && dropdownInput.value);
		}
	}

	atLeastOneSelectedAssertion(): boolean {
		if (!_isUndefined(this.componentForm)) {
			return !!(
				this.areInputsInFormGroupNotNull('writeoffInvoiceTransferSplitRequest', 'reasonId')
				|| this.areInputsInFormGroupNotNull('insuranceInvoiceTransferSplitRequest', 'reasonId', 'personInsuranceId')
				|| this.areInputsInFormGroupNotNull('patientInvoiceTransferSplitRequest', 'reasonId')
				|| this.areInputsInFormGroupNotNull('collectionsInvoiceTransferSplitRequest', 'collectionsAgencyId')
			);
		}
	}

	transferAmountValidation(): boolean {
		if (this.endingBalance) {
			return this.endingBalance.gte(0);
		}
	}

	initializeForm() {
		this.invoiceTransferRequest = this.prepareInitialRequest();

		this.componentForm = this.gandalfFormBuilder.group(this.invoiceTransferRequest, {
			validators: [
				assertTrue(
					() => this.atLeastOneSelectedAssertion(),
					[],
					'atLeastOneSelection',
					'At least one transfer is required',
				),
				assertTrue(
					() => this.transferAmountValidation(),
					[],
					'greaterThanBalance',
					'Transfer amount is greater than the current balance on the invoice'),
			],
		});

		this.componentForm.get('insuranceInvoiceTransferSplitRequest').setValidators(
			[
				assertTrue(
					() => this.validateDropdown('insuranceInvoiceTransferSplitRequest', 'personInsuranceId'),
					['personInsuranceId'],
					'personInsuranceId',
					'Insurance company is required',
				),
				assertTrue(
					() => this.validateDropdown('insuranceInvoiceTransferSplitRequest', 'reasonId'),
					['reasonId'],
					'InsuranceReasonId',
					'Transfer reason is required',
				),
			],
		);

		this.componentForm.get('writeoffInvoiceTransferSplitRequest').setValidators(
			assertTrue(
				() => this.validateDropdown('writeoffInvoiceTransferSplitRequest', 'reasonId'),
				['reasonId'],
				'writeOffValidation',
				'Write-off reason is required',
			),
		);

		this.componentForm.get('patientInvoiceTransferSplitRequest').setValidators(
			assertTrue(
				() => this.validateDropdown('patientInvoiceTransferSplitRequest', 'reasonId'),
				['reasonId'],
				'patientValidation',
				'Transfer reason is required',
			),
		);

		this.componentForm.get('collectionsInvoiceTransferSplitRequest').setValidators(
			assertTrue(
				() => this.validateDropdown('collectionsInvoiceTransferSplitRequest', 'collectionsAgencyId'),
				['collectionsAgencyId'],
				'collectionsValidation',
				'Collection agency is required'),
		);

		FormUtilsService.reactToValueChanges(this.componentForm, () => this.handleFormState(), true);
	}

	handleFormState() {
		const insuranceAmountInput = this.componentForm.get('insuranceInvoiceTransferSplitRequest.amount');
		const insuranceReasonDropdown = this.componentForm.get('insuranceInvoiceTransferSplitRequest.reasonId');
		const writeoffAmountInput = this.componentForm.get('writeoffInvoiceTransferSplitRequest.amount');
		const writeoffReasonDropdown = this.componentForm.get('writeoffInvoiceTransferSplitRequest.reasonId');
		const patientReasonDropdown = this.componentForm.get('patientInvoiceTransferSplitRequest.reasonId');
		const patientAmountInput = this.componentForm.get('patientInvoiceTransferSplitRequest.amount');

		FormUtilsService.disabledWhen(insuranceReasonDropdown, this.isValueNullOrZero(insuranceAmountInput.value));
		FormUtilsService.disabledWhen(writeoffReasonDropdown, this.isValueNullOrZero(writeoffAmountInput.value));
		FormUtilsService.disabledWhen(patientReasonDropdown, this.isValueNullOrZero(patientAmountInput.value));
	}

	isValueNullOrZero(value: number): boolean {
		return _isNil(value) || value === 0;
	}

	closeModal() {
		this.ref.close(this.modal);
	}

	populateModal() {
		combineLatest([
			this.accountingService.getInvoiceById(this.invoiceId),
			this.accountingService.findActiveTransferReasonsForDropdown(),
			this.accountingService.findActiveWriteoffReasonsForDropdown(),
			this.accountingService.findCollectionAgencies(),
		]).subscribe(([ invoice, transferReasons, writeoffReasons, collectionAgencies]) => {
			this.invoice = invoice;
			this.transferReasons = transferReasons;
			this.writeoffReasons = writeoffReasons;
			this.collectionAgencies = collectionAgencies;
			this.getPatientName();
			this.setInvoiceBalance();
			this.getActiveInsurances();
			this.handlePatientInputDisplay();
		});
	}

	handlePatientInputDisplay() {
		this.showPatientFields = (this.invoice.payerType === PayerType.INSURANCE);
		if (this.showPatientFields) {
			this.patientAmountInput.setFocus();
			this.componentForm.get('insuranceInvoiceTransferSplitRequest.amount').setValue(null);
		}
	}

	getPatientName() {
		this.patientService.getPatientNameById(this.invoice.patientId).subscribe((patientName) => {
			this.patientName = patientName;
		});
	}

	getActiveInsurances() {
		this.accountingService.findActiveInsurancesByPatientIdWithPracticeInsuranceId(this.invoice.patientId).subscribe((insurances) => {
			this.insurances = _filter(insurances, insurance => insurance.id !== this.invoice.personInsuranceId);
			this.showAltInsuranceOption = !_isEmpty(this.insurances);
		});
	}

	setInvoiceBalance() {
		FormUtilsService.reactToValueChanges(this.componentForm, () => this.calculateTransferAmount(), true);
		FormUtilsService.reactToValueChanges(this.componentForm, () => this.calculateEndingBalance(), true);
		this.formattedInvoiceBalance = this.forceDecimalPipe.transform(this.invoice.balance.toString(), 2, '0.00');
	}

	submitForm(event) {
		this.templateForm.onSubmit(event);
	}

	setReasonsToForm() {
		const writeoffReasonId = this.componentForm.get('writeoffInvoiceTransferSplitRequest.reasonId').value;
		const insuranceReasonId = this.componentForm.get('insuranceInvoiceTransferSplitRequest.reasonId').value;
		const patientReasonId = this.componentForm.get('patientInvoiceTransferSplitRequest.reasonId').value;

		if (!_isNil(patientReasonId)) {
			const patientReason = _find(this.transferReasons, ['value', patientReasonId]);
			this.componentForm.get('patientInvoiceTransferSplitRequest.reason').patchValue(patientReason.label);
		}

		if (!_isNil(writeoffReasonId)) {
			const writeoffReason = _find(this.writeoffReasons, ['value', writeoffReasonId]);
			this.componentForm.get('writeoffInvoiceTransferSplitRequest.reason').patchValue(writeoffReason.label);
		}

		if (!_isNil(insuranceReasonId)) {
			const insuranceReason = _find(this.transferReasons, ['value', insuranceReasonId]);
			this.componentForm.get('insuranceInvoiceTransferSplitRequest.reason').patchValue(insuranceReason.label);
		}
	}

	enableFormGroups() {
		FormUtilsService.enabledWhen(this.componentForm.get('writeoffInvoiceTransferSplitRequest'), true);
		FormUtilsService.enabledWhen(this.componentForm.get('patientInvoiceTransferSplitRequest'), true);
		FormUtilsService.enabledWhen(this.componentForm.get('insuranceInvoiceTransferSplitRequest'), true);
		FormUtilsService.enabledWhen(this.componentForm.get('collectionsInvoiceTransferSplitRequest'), true);
	}

	save() {
		this.setReasonsToForm();
		this.disableIncompleteFormGroups();
		if (this.componentForm.invalid) {
			this.enableFormGroups();
			return;
		}
		const request = this.componentForm.value as InvoiceTransferRequest;

		this.accountingService.transferInvoice(request).subscribe(data => {
			this.invoiceService.refreshInvoice(data.id, this.invoice.patientId);
			this.closeModal();
		});
	}

	disableIncompleteFormGroups() {
		FormUtilsService.disabledWhen(
			this.componentForm.get('writeoffInvoiceTransferSplitRequest'),
			!this.areInputsInFormGroupNotNull('writeoffInvoiceTransferSplitRequest', 'reasonId'),
		);
		FormUtilsService.disabledWhen(
			this.componentForm.get('patientInvoiceTransferSplitRequest'),
			!this.areInputsInFormGroupNotNull('patientInvoiceTransferSplitRequest', 'reasonId'),
		);
		FormUtilsService.disabledWhen(
			this.componentForm.get('insuranceInvoiceTransferSplitRequest'),
			!this.areInputsInFormGroupNotNull('insuranceInvoiceTransferSplitRequest', 'reasonId', 'personInsuranceId'),
		);
		FormUtilsService.disabledWhen(
			this.componentForm.get('collectionsInvoiceTransferSplitRequest'),
			!this.areInputsInFormGroupNotNull('collectionsInvoiceTransferSplitRequest', 'collectionsAgencyId'),
		);
	}

	calculateTransferAmount() {
		const valueArray = [];
		valueArray.push(this.componentForm.get('insuranceInvoiceTransferSplitRequest.amount').value);
		valueArray.push(this.componentForm.get('writeoffInvoiceTransferSplitRequest.amount').value);
		valueArray.push(this.componentForm.get('patientInvoiceTransferSplitRequest.amount').value);
		valueArray.push(this.componentForm.get('collectionsInvoiceTransferSplitRequest.amount').value);
		this.transferAmount = InvoiceItemPricingUtil.sumBalances(valueArray);
		this.formattedTransferAmount = this.currencyPipe.transform(Number(this.transferAmount));
	}

	calculateEndingBalance() {
		this.endingBalance = InvoiceItemPricingUtil.calculateTaxableAmount(Big(this.invoice.balance || 0), this.transferAmount);
		this.displayEndingBalanceRed = this.endingBalance.lt(0);
		this.formattedEndingBalance = this.currencyPipe.transform(Number(this.endingBalance));
	}

	setPracticeInsuranceCompanyId(event: ChangeEventArgs) {
		let practiceInsuranceCompanyId = null;
		if (!_isNil(event.itemData)) {
			practiceInsuranceCompanyId = (event.itemData as PersonInsuranceSummaryResponse).practiceInsuranceCompanyId;
		}
		this.componentForm.get('insuranceInvoiceTransferSplitRequest.practiceInsuranceCompanyId').setValue(practiceInsuranceCompanyId);
	}
}
