import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';

@Pipe({
	name: 'forceDecimalNullSafe',
})
@Injectable({
	providedIn: 'root',
})
export class ForceDecimalNullSafePipe implements PipeTransform {

	transform(value: string | number, decimalPlaces: number, returnValueWhenNaN?: string): string {
		if (isNaN(Number(value))) {
			return !_isNil(returnValueWhenNaN) ? returnValueWhenNaN : '';
		}
		return !_isNil(value) ? Number(value).toFixed(decimalPlaces) : '';
	}

}
