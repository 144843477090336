import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { DiagnosisService } from '@core/diagnosis/diagnosis.service';
import { DynamicModalRef, EnumUtil, ModalConfig, PAGE_LENGTH, PAGE_LENGTH_LIST } from 'morgana';
import { CodeSet } from '@gandalf/constants';
import { DiagnosisSearchRequest } from '@gandalf/model/diagnosis-search-request';
import { DiagnosisSearchResponse } from '@gandalf/model/diagnosis-search-response';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { GridComponent, PageSettingsModel, QueryCellInfoEventArgs } from '@syncfusion/ej2-angular-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';
import { BACKGROUND_COLOR_CONSTANTS } from '@shared/constants/background-colors.constants';

@Component({
	selector: 'pms-icd-diagnosis-add-modal',
	templateUrl: './icd-diagnosis-add-modal.component.html',
	styles: [],
})
export class IcdDiagnosisAddModalComponent implements OnInit {

	@ViewChild('templateForm')
	templateForm: NgForm;

	@ViewChild('diagnosisAddModal')
	modal: DialogComponent;

	@ViewChild('searchResultsGrid')
	grid: GridComponent;

	pageSettings: PageSettingsModel = {
		pageSize: PAGE_LENGTH.PAGE_10,
		pageSizes: PAGE_LENGTH_LIST,
	};
	query = '';
	searchResults: DiagnosisSearchResponse[];
	isSearching = false;
	formGroup: UntypedFormGroup;
	searchRequest: DiagnosisSearchRequest;
	dateFormat = DATE_FORMATS.MM_DD_YYYY;

	constructor(
		private dynamicModalRef: DynamicModalRef,
		private modalConfig: ModalConfig,
		private diagnosisService: DiagnosisService,
		private gandalfFormBuilder: GandalfFormBuilder,
	) {
	}

	ngOnInit(): void {
		this.createForm();
		this.searchDiagnoses();
	}

	createForm() {
		this.searchRequest = new DiagnosisSearchRequest();
		this.searchRequest.codeSet = this.modalConfig.data.codeSet;
		this.query = this.searchRequest.searchString = this.modalConfig.data.query || '';
		this.formGroup = this.gandalfFormBuilder.group(this.searchRequest);
	}

	searchDiagnoses() {
		this.formGroup.get('searchString').setValue(this.query);
		if (this.formGroup.invalid) {
			return;
		}

		this.isSearching = true;
		const request = this.formGroup.value;
		this.diagnosisService.findMasterDiagnoses(request).subscribe((data) => {
			this.searchResults = data;
			this.isSearching = false;
		});
	}

	clearSearch() {
		this.query = '';
		this.searchResults = [];
	}

	showDisabledWarning(response: DiagnosisSearchResponse) {
		return EnumUtil.equals((response as any).masterCodeSet, CodeSet.ICD10) && !response.active;
	}

	/* istanbul ignore next: not testable*/
	applyBackgroundColor(args: QueryCellInfoEventArgs) {
		if (args.column.field === 'code' && this.showDisabledWarning(args.data as DiagnosisSearchResponse)) {
			args.cell.classList.add(BACKGROUND_COLOR_CONSTANTS.DANGER);
		}
	}

	/* istanbul ignore next */
	closeModal(result?: DiagnosisSearchResponse) {
		this.dynamicModalRef.close(this.modal, result);
	}
}
