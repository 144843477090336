import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalBase } from 'morgana';
import { PracticeLocationSummaryResponse } from '@gandalf/model/practice-location-summary-response';
import { GandalfFormBuilder } from 'gandalf';
import { CreateRevClearExternalCredentialRequest } from '@gandalf/model/create-rev-clear-external-credential-request';
import { UpdateRevClearExternalCredentialRequest } from '@gandalf/model/update-rev-clear-external-credential-request';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { _isNil } from '@core/lodash/lodash';
import { FormGroup, NgForm } from '@angular/forms';
import { OptionItemResponse } from '@core/option-item/option-item.service';
import { PracticeLocation } from '@core/security-manager/security-manager.service';
import { RevClearTemplateService } from '../rev-clear-template.service';

interface RevClearExternalCredentialData {
	revClearExternalCredentialId: number;
	userName: string;
	password: string;
	autoPostingEnabled: boolean;
	assignedLocations: PracticeLocationSummaryResponse[];
	availableLocations: OptionItemResponse<PracticeLocation, number>[];
	hasAutoPosting: boolean;
}

@Component({
	selector: 'pms-rev-clear-external-credential-modal',
	templateUrl: './rev-clear-external-credential-modal.component.html',
	styles: [],
})
export class RevClearExternalCredentialModalComponent extends ModalBase<RevClearExternalCredentialData, boolean> implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('ngForm')
	ngForm: NgForm;

	request: CreateRevClearExternalCredentialRequest | UpdateRevClearExternalCredentialRequest;
	formGroup: FormGroup;
	isCreate = false;
	hasAutoPosting = false;
	availableLocations: (PracticeLocationSummaryResponse | OptionItemResponse<PracticeLocation, number>)[];

	constructor(
		private gandalfFormBuilder: GandalfFormBuilder,
		private revClearTemplateService: RevClearTemplateService,
	) {
		super();
	}

	ngOnInit() {
		this.parseConfig();
	}

	parseConfig() {
		this.hasAutoPosting = this.data.hasAutoPosting;
		if (_isNil(this.data?.revClearExternalCredentialId)) {
			this.isCreate = true;
			this.request = new CreateRevClearExternalCredentialRequest();
			this.request.autoPostingEnabled = false;
			this.formGroup = this.gandalfFormBuilder.group(this.request);
		} else {
			const updateRequest = new UpdateRevClearExternalCredentialRequest();
			updateRequest.revClearExternalCredentialId = this.data.revClearExternalCredentialId;
			updateRequest.userName = this.data.userName;
			updateRequest.password = this.data.password;
			updateRequest.autoPostingEnabled = this.data.autoPostingEnabled;
			updateRequest.locationIds = this.data.assignedLocations.map(location => location.id);
			this.request = updateRequest;
			this.formGroup = this.gandalfFormBuilder.group(this.request);
		}
		this.availableLocations = [...this.data.availableLocations, ...(this.data.assignedLocations || [])];
	}

	/* istanbul ignore next: closeModal */
	closeModal(refreshData = false) {
		this.dynamicModalRef.close(this.modal, refreshData);
	}

	submitForm(event) {
		this.ngForm.onSubmit(event);
	}

	saveCredential() {
		if (this.formGroup.invalid) {
			return;
		}

		if (this.isCreate) {
			this.revClearTemplateService.createRevClearExternalCredential(this.formGroup.value).subscribe(() => this.closeModal(true));
		} else {
			this.revClearTemplateService.updateRevClearExternalCredential(this.formGroup.value).subscribe(() => this.closeModal(true));
		}
	}

}
