import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NgForm } from '@angular/forms';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { DrugSearchRequest } from '@gandalf/model/drug-search-request';
import { DrugSearchResponse } from '@gandalf/model/drug-search-response';
import { BaseComponent } from '@shared/component/base.component';
import { PAGE_LENGTH, PAGE_LENGTH_LIST } from 'morgana';
import { SelectedDrug } from '@shared/interfaces/selected-drug';
import { GridComponent, RowSelectEventArgs } from '@syncfusion/ej2-angular-grids';
import { Predicate, Query } from '@syncfusion/ej2-data';
import { PageSettingsModel, SortSettingsModel } from '@syncfusion/ej2-grids';
import { GandalfFormBuilder } from 'gandalf';
import { takeUntil } from 'rxjs/operators';
import { _isNil } from '@core/lodash/lodash';
import { DrugSearchService } from './drug-search.service';

@Component({
	selector: 'pms-drug-search',
	templateUrl: './drug-search.component.html',
	styles: [],
})
export class DrugSearchComponent extends BaseComponent implements OnInit, AfterViewInit {

	private static INGREDIENT_DRUG_TYPE = 'Ingredient';
	private static CLINICAL_DRUG_TYPE = 'Clinical drug';
	private static SYNONYM_DRUG_TYPE = 'Synonym';

	@Input()
	initialSearch: string;

	@Output()
	drugSelected = new EventEmitter<SelectedDrug>();

	@ViewChild('drugSearchForm')
	form: NgForm;
	@ViewChild('grid')
	grid: GridComponent;

	sortSettings: SortSettingsModel = {
		columns: [
			{ field: 'name', direction: 'Ascending' },
		],
	};
	pageSettings: PageSettingsModel = {
		pageSizes: PAGE_LENGTH_LIST,
		pageSize: PAGE_LENGTH.PAGE_10,

	};
	request: DrugSearchRequest;
	componentForm: UntypedFormGroup;
	isLoading = false;
	searchResults: DrugSearchResponse[] = [];
	private _query = this.createQuery();

	get query(): Query {
		if (!this.securityManagerService.isCanada()) {
			return this._query;
		} else {
			return null;
		}
	}

	constructor(
		private drugSearchService: DrugSearchService,
		private gandalfFormBuilder: GandalfFormBuilder,
		private cd: ChangeDetectorRef,
		private securityManagerService: SecurityManagerService,
	) {
		super();
	}

	ngOnInit(): void {
		this.createRequest();
		this.createForm();
		if (!this.securityManagerService.isCanada()) {
			this.listenForFiltering();
		}
	}

	ngAfterViewInit(): void {
		if (this.initialSearch) {
			this.form.onSubmit(null);
			this.cd.detectChanges();
		}
	}

	createRequest() {
		this.request = new DrugSearchRequest();
		this.request.query = this.initialSearch || '';
	}

	createForm() {
		this.componentForm = this.gandalfFormBuilder.group(this.request);
		this.componentForm.addControl('isShowingAll', new UntypedFormControl());
	}

	listenForFiltering() {
		this.componentForm.controls.isShowingAll.valueChanges
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(() => this.filterTable());
	}

	searchDrugs() {
		if (!this.componentForm.valid) {
			return;
		}

		this.isLoading = true;
		this.drugSearchService.searchDrugs(this.componentForm.value).subscribe(results => {
			this.searchResults = results;
			this.isLoading = false;
		});
	}

	/**
	 * Updates the filter so that the appropriate drug types are displayed
	 */
	private filterTable() {
		this._query = this.createQuery();
		this.grid.goToPage(1);
		this.cd.detectChanges();
		this.grid.refresh();
	}

	private createQuery(): Query {
		const isShowingAll = this.componentForm && this.componentForm.get('isShowingAll').value;
		if (!isShowingAll) {
			const predicate = new Predicate('type', 'equal', DrugSearchComponent.INGREDIENT_DRUG_TYPE)
				.or('type', 'equal', DrugSearchComponent.CLINICAL_DRUG_TYPE)
				.or('type', 'equal', DrugSearchComponent.SYNONYM_DRUG_TYPE);
			return new Query().where(predicate);
		} else {
			return new Query();
		}
	}

	onRowSelected(event: RowSelectEventArgs): void {
		const selectedRow = event.data as DrugSearchResponse;
		const selectedDrug: SelectedDrug = {
			drugId: selectedRow.drugId,
			name: selectedRow.name,
			fullName: selectedRow.name,
		};
		this.drugSelected.emit(selectedDrug);
	}

	clearFilter() {
		this.searchResults = [];
		this.grid.goToPage(1);
		this.grid.clearSorting();
		this.grid.sortSettings = this.sortSettings;

		const isShowingAll = this.componentForm.get('isShowingAll').value;
		this.form.resetForm({
			isShowingAll,
		});
	}

	isSearchDisabled() {
		if (this.isLoading || _isNil(this.componentForm)) {
			return true;
		}
		return _isNil(this.componentForm?.get('query').value)
			|| this.componentForm.get('query').value.length < 3;

	}

}
