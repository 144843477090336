// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.solutions.revclear.UpdateNotificationPersonsRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateNotificationPersonsRequest extends GandalfModelBase {

	@GandalfLabel('Persons')
	@GandalfArray(Number)
	personIds: number[];

	@GandalfLabel('External Credential')
	@GandalfValidator({ notNull: { message: 'External Credential is required' } })
	@GandalfProperty({ isRequired: true })
	revClearExternalCredentialId: number;

}
