<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-lg"
	[attr.data-test-id]="'selectInvoiceModal'">
	<ng-template #header>
		<div class="dlg-template">Select Invoices</div>
	</ng-template>
	<ng-template #content>
		<div class="modal-feature">
			<form class="form-horizontal" #templateForm="ngForm" [formGroup]="formGroup" (ngSubmit)="submitForm()">
				@if (templateForm.invalid && templateForm.submitted) {
					<gandalf-lib-validation-messages
						[form]="formGroup"
						[requestObj]="invoiceSearchRequest"
						[attr.data-test-id]="'selectInvoiceModalValidationMessages'">
					</gandalf-lib-validation-messages>
				}
				<div class="row">
					<div class="col-sm-12 col-md-6 col-lg-4">
						<div class="form-group">
							<label class="col-sm-4 control-label">Location</label>
							<div class="col-sm-8">
								<ejs-dropdownlist
									[dataSource]="locations"
									placeholder="All Locations"
									formControlName="locationId"
									[showClearButton]="true">
								</ejs-dropdownlist>
							</div>
						</div>
						<div class="form-group">
							<label class="col-sm-4 control-label">Invoice #</label>
							<div class="col-sm-8">
								<input revDigitOnlyInput class="e-input"
									   formControlName="invoiceId">
							</div>
						</div>
						<div class="form-group">
							<label class="col-sm-4 control-label">Patient Name</label>
							<div class="col-sm-8">
								<input type="text" class="e-input" formControlName="patientLastName"/>
							</div>
						</div>
						<div class="form-group">
							<label class="col-sm-4 control-label">Patient #</label>
							<div class="col-sm-8">
								<input revDigitOnlyInput class="e-input"
									   formControlName="patientId">
							</div>
						</div>
					</div>
					<div class="col-sm-12 col-md-6 col-lg-4">
						<div class="form-group">
							<label class="col-sm-4 control-label">Payer Type</label>
							<div class="col-sm-8">
								<ejs-dropdownlist
									placeholder="All Payer Types"
									[dataSource]="payerOptions"
									formControlName="payerType"
									[showClearButton]="true">
								</ejs-dropdownlist>
							</div>
						</div>
						<div class="form-group">
							<label class="col-sm-4 control-label">Type</label>
							<div class="col-sm-8">
								<ejs-dropdownlist
									placeholder="All Types"
									[dataSource]="payerInsuranceTypes"
									formControlName="payerInsuranceType"
									[showClearButton]="true">
								</ejs-dropdownlist>
							</div>
						</div>
						<div class="form-group">
							<label class="col-sm-4 control-label">Payer Name</label>
							<div class="col-sm-8">
								<p class="form-control-static">{{ payerName }}</p>
							</div>
						</div>
						<div class="form-group">
							<label class="col-sm-4 control-label">Provider</label>
							<div class="col-sm-8">
								<ejs-dropdownlist
									[dataSource]="providers"
									placeholder="All Providers"
									formControlName="providerId"
									[showClearButton]="true">
								</ejs-dropdownlist>
							</div>
						</div>
					</div>
					<div class="col-sm-12 col-md-6 col-lg-4">
						<div class="form-group">
							<label class="col-sm-4 control-label">Invoice Date</label>
							<div class="col-sm-8">
								<div class="date-range">
									<div class="date-range-input">
										<ejs-datepicker formControlName="invoiceDateStart"
														placeholder="mm/dd/yyyy"
														[format]="dateFormat">
										</ejs-datepicker>
									</div>
									<div class="date-range-label">
										<label class="control-label">to</label>
									</div>
									<div class="date-range-input">
										<ejs-datepicker formControlName="invoiceDateEnd"
														placeholder="mm/dd/yyyy"
														[format]="dateFormat">
										</ejs-datepicker>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group">
							<label class="col-sm-4 control-label">Service Date</label>
							<div class="col-sm-8">
								<div class="date-range">
									<div class="date-range-input">
										<ejs-datepicker formControlName="serviceDateStart"
														placeholder="mm/dd/yyyy"
														[format]="dateFormat">
										</ejs-datepicker>
									</div>
									<div class="date-range-label">
										<label class="control-label">to</label>
									</div>
									<div class="date-range-input">
										<ejs-datepicker formControlName="serviceDateEnd"
														placeholder="mm/dd/yyyy"
														[format]="dateFormat">
										</ejs-datepicker>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group">
							<label class="col-sm-4 control-label">Claim #</label>
							<div class="col-sm-8">
								<input revDigitOnlyInput class="e-input"
									   formControlName="claimId">
							</div>
						</div>
						<div class="form-group">
							<label class="col-sm-4 control-label">Claim Submit Date</label>
							<div class="col-sm-8">
								<div class="date-range">
									<div class="date-range-input">
										<ejs-datepicker formControlName="claimDateStart"
														placeholder="mm/dd/yyyy"
														[format]="dateFormat">
										</ejs-datepicker>
									</div>
									<div class="date-range-label">
										<label class="control-label">to</label>
									</div>
									<div class="date-range-input">
										<ejs-datepicker formControlName="claimDateEnd"
														placeholder="mm/dd/yyyy"
														[format]="dateFormat">
										</ejs-datepicker>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row margin-top-sm">
					<div class="col-sm-12">
						<button ejs-button type="submit" iconCss="fa fa-search" [isPrimary]="true" [attr.data-test-id]="'selectInvoiceSearchButton'">Search
						</button>
						<button ejs-button type="button" iconCss="fa fa-close" (click)="clear(templateForm)" [attr.data-test-id]="'selectInvoiceCancelButton'">
							Clear
						</button>
					</div>
				</div>
			</form>
		</div>
		<ejs-grid #grid
				  autoFitColumns
				  class="table-rowlink"
				  [autoFitBlacklist]="['patientName']"
				  [staticColumns]="['checkbox', 'invoice.balance', 'print']"
				  [allowResizing]="true"
				  [dataSource]="filteredInvoices"
				  [allowSorting]="true"
				  [allowSelection]="true"
				  [pageSettings]="pageSettings"
				  [selectionSettings]="{type: 'Multiple', persistSelection: true, checkboxOnly: true}"
				  gridLines="Horizontal"
				  [allowPaging]="true"
				  [aggregates]="aggregates"
				  [pmsGridSubmit]="isSearching"
				  (recordDoubleClick)="addInvoice($event)"
				  (rowSelected)="updateCheckedInvoices($event)"
				  (rowDeselected)="updateCheckedInvoices($event)"
				  [attr.data-test-id]="'selectInvoiceModalGrid'">
			<e-columns>
				<e-column field="checkbox" type="checkbox" headerText="" [width]="36"></e-column>
				<e-column field="patientName" headerText="Patient" clipMode="EllipsisWithTooltip">
					<ng-template #template let-data>
						@if (!isGuestPayer(data.invoice)) {
							<div>{{ data.patientName }}</div>
						}
					</ng-template>
				</e-column>
				<e-column field="invoice.serviceDate" headerText="Service Date" [format]="dateFormatter"></e-column>
				<e-column field="invoice.invoiceDate" headerText="Invoice Date" [format]="dateFormatter"></e-column>
				<e-column field="id" headerText="#" [isPrimaryKey]="true"></e-column>
				<e-column field="invoice.total" headerText="Amount" type="number" format="C2"></e-column>
				<e-column width="122" field="invoice.balance" headerText="Balance" type="number" format="C2"></e-column>
			</e-columns>
		</ejs-grid>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" type="button" (click)="closeModal()" [attr.data-test-id]="'selectInvoiceModalCloseButton'">Close</button>
		@if (checkedInvoices.length > 0) {
			<button ejs-button
					[isPrimary]="true"
					type="button"
					(click)="addSelectedInvoices()"
					[attr.data-test-id]="'selectInvoiceSaveButton'"
			>Select ({{ checkedInvoices.length }})
			</button>
		}
	</ng-template>
</ejs-dialog>
