import { EventsActions, EventsActionTypes } from '@app-store/actions/events.actions';
import { StatefulEventDefinition } from '@app-store/constants/event.constants';
import { StatefulComponentUtil } from '@app-store/utils/stateful-component-util';

export class StatefulEvent<E extends StatefulEventDefinition = any, P = any> {
	constructor(
		public id: number = 0,
		public definition: E,
		public payload?: P,
	) {}
}

export type PublishedEventStateMap = Map<number, StatefulEvent>;

export type PublishedEventsStateMap = Map<string, PublishedEventStateMap>;

export interface ConsumerEventState {
	lastConsumed: number;
}

export type ConsumerEventsStateMap = Map<string, ConsumerEventState>;

export type ConsumersEventsStateMap = Map<string, ConsumerEventsStateMap>;

export class EventsState {
	consumers: ConsumersEventsStateMap = new Map<string, ConsumerEventsStateMap>();
	events: PublishedEventsStateMap = new Map<string, PublishedEventStateMap>();
}

export const initialState: EventsState = new EventsState();

export function reducer(state = initialState, action: EventsActions): EventsState {
	switch (action.type) {

		case EventsActionTypes.PUBLISH_EVENT: {
			const {eventDefinition, options} = action.payload;
			const eventsState = {...state};
			const eventKey = StatefulComponentUtil.buildStoreKey(eventDefinition.key, options?.storeKeyArgs);
			const eventState = new Map(eventsState.events.get(eventKey)) || new Map<number, StatefulEvent>();
			const eventId = eventState.size + 1;
			eventState.set(eventId, new StatefulEvent(eventId, eventDefinition, options?.payload));
			eventsState.events.set(eventKey, eventState);
			return eventsState;
		}

		case EventsActionTypes.REGISTER_EVENT_CONSUMED: {
			const {consumerKey, eventKey, eventId} = action.payload;
			const eventsState = {...state};
			const consumerState = new Map(eventsState.consumers.get(consumerKey)) || new Map<string, ConsumerEventState>();
			consumerState.set(eventKey, {lastConsumed: eventId} as ConsumerEventState);
			eventsState.consumers.set(consumerKey, consumerState);
			return eventsState;
		}

		case EventsActionTypes.REGISTER_CONSUMER_EVENT: {
			const {consumerKey, eventKey} = action.payload;
			const eventsState = {...state};
			const consumerState = new Map(eventsState.consumers.get(consumerKey)) || new Map<string, ConsumerEventState>();
			if (consumerState.has(eventKey)) {
				return state;
			}
			const eventState = new Map(eventsState.events.get(eventKey)) || new Map<number, StatefulEvent>();
			consumerState.set(eventKey, {lastConsumed: eventState.size});
			eventsState.consumers.set(consumerKey, consumerState);
			eventsState.events.set(eventKey, eventState);
			return eventsState;
		}

		case EventsActionTypes.REMOVE_CONSUMER_STATE: {
			const eventsState = {...state};
			const consumerState = new Map(eventsState.consumers);
			consumerState.delete(action.payload.key);
			eventsState.consumers = consumerState;
			return eventsState;
		}

		default: {
			return state;
		}
	}
}
