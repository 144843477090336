import { inject, InjectionToken } from '@angular/core';
import { FeatureService } from '@core/feature/feature.service';

const featureFlagTokensMap = new Map<string, InjectionToken<boolean>>();

function createFeatureFlagToken(flagKey: string) {
	return new InjectionToken<boolean>(flagKey, {
		providedIn: 'root',
		factory: () => inject(FeatureService).isFeatureOn(flagKey),
	});
}

export function featureToken(flagKey: string): InjectionToken<boolean> {
	if (featureFlagTokensMap.has(flagKey)) {
		return featureFlagTokensMap.get(flagKey);
	}
	const token = createFeatureFlagToken(flagKey);
	featureFlagTokensMap.set(flagKey, token);
	return token;
}
