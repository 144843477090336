// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AttributeDropdownResponse } from './attribute-dropdown-response';

// @ts-ignore
import { WorkflowStepTemplateResponse } from './workflow-step-template-response';

/** See com.rev360.pms.api.controller.admin.encounter.AdminEncounterTemplateResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminEncounterTemplateResponse extends GandalfModelBase {

	@GandalfProperty()
	abbreviation: string;

	@GandalfProperty()
	allowPhrScheduling: boolean;

	@GandalfProperty()
	displayColor: number;

	@GandalfProperty()
	encounterTemplateId: number;

	@GandalfProperty()
	inClinic: boolean;

	@GandalfProperty()
	includeInMuCalcs: boolean;

	@GandalfProperty()
	interviewTemplateId: number;

	@GandalfProperty()
	isComprehensive: boolean;

	@GandalfProperty()
	name: string;

	@GandalfProperty()
	phrName: string;

	@GandalfArray(AttributeDropdownResponse)
	subTypes: AttributeDropdownResponse[];

	@GandalfProperty()
	totalDuration: number;

	@GandalfArray(WorkflowStepTemplateResponse)
	workflowStepTemplates: WorkflowStepTemplateResponse[];

}
