import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { DynamicModalRef, ModalConfig, OptionItem } from 'morgana';
import { VerificationStatus } from '@gandalf/constants';
import { VerifyInsuranceRequest } from '@gandalf/model/verify-insurance-request';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';

@Component({
	selector: 'pms-insurance-verification-modal',
	templateUrl: './insurance-verification-modal.component.html',
	styles: [],
})
export class InsuranceVerificationModalComponent implements OnInit {

	@ViewChild('templateForm')
	templateForm: NgForm;

	@ViewChild('modal')
	modal: DialogComponent;

	verifyInsuranceRequest: VerifyInsuranceRequest;
	componentForm: UntypedFormGroup;
	validityOptions: OptionItem[] = [
		VerificationStatus.VERIFIED_VALID,
		VerificationStatus.VERIFIED_INVALID,
	];

	constructor(
		private dynamicModalRef: DynamicModalRef,
		private gandalfFormBuilder: GandalfFormBuilder,
		private modalConfig: ModalConfig,
	) { }

	ngOnInit() {
		this.createForm();
	}

	createForm() {
		this.verifyInsuranceRequest = new VerifyInsuranceRequest();
		this.verifyInsuranceRequest.appointmentId = this.modalConfig.data.appointmentId;
		this.verifyInsuranceRequest.status = VerificationStatus.VERIFIED_VALID;
		this.componentForm = this.gandalfFormBuilder.group(this.verifyInsuranceRequest);
	}

	closeDialog() {
		this.dynamicModalRef.close(this.modal);
	}

	save() {
		if (this.componentForm.invalid) {
			return;
		}

		this.dynamicModalRef.close(this.modal, this.componentForm.value);
	}

	submitForm(event: any) {
		this.templateForm.onSubmit(event);
	}

}
