// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.file.PersonDiagnosisImportResponse */
/* istanbul ignore next */
@GandalfModel
export class PersonDiagnosisImportResponse extends GandalfModelBase {

	@GandalfProperty()
	code: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	codeSet: constants.CodeSet;

	@GandalfProperty({ propertyType: 'LocalDate' })
	diagnosisDate: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	lastModificationDate: Date;

	@GandalfProperty()
	longDescription: string;

	@GandalfProperty()
	shortDescription: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.PersonDiagnosisStatus;

}
