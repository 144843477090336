// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { PrescriptionSelectContactLensEyeResponse } from './prescription-select-contact-lens-eye-response';

/** See com.rev360.pms.api.controller.prescription.PrescriptionSelectContactLensResponse */
/* istanbul ignore next */
@GandalfModel
export class PrescriptionSelectContactLensResponse extends GandalfModelBase {

	@GandalfProperty()
	authorizedBy: PersonNameResponse;

	@GandalfProperty({ propertyType: 'LocalDate' })
	createdDate: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	expirationDate: Date;

	@GandalfProperty()
	isExternalProvider: boolean;

	@GandalfProperty()
	odEye: PrescriptionSelectContactLensEyeResponse;

	@GandalfProperty()
	osEye: PrescriptionSelectContactLensEyeResponse;

	@GandalfProperty()
	prescriptionId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.PrescriptionCurrentStatus;

	@GandalfProperty()
	trialId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	type: constants.ContactLensPrescriptionType;

}
