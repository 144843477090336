import { Component, ViewChild } from '@angular/core';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { SendConnectMessageService } from '@core/send-connect-message/send-connect-message.service';
import { ToasterService } from '@core/toaster/toaster.service';
import { ConnectMessageForPatientAppointmentRequest } from '@gandalf/model/connect-message-for-patient-appointment-request';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { BaseComponent } from '@shared/component/base.component';

@Component({
	selector: 'pms-appointment-send-message-confirm-modal-legacy',
	templateUrl: './appointment-send-message-confirm-modal-legacy.component.html',
	styles: [],
})
export class AppointmentSendMessageConfirmModalLegacyComponent extends BaseComponent {

	@ViewChild('modal')
	modal: DialogComponent;

	constructor(
		public dynamicModalRef: DynamicModalRef,
		private modalConfig: ModalConfig,
		private sendConnectMessageService: SendConnectMessageService,
		public toasterService: ToasterService,
	) {
		super();
	}

	submitForm() {
		this.sendAppointmentMessage();
	}

	/* istanbul ignore next */
	closeModal() {
		this.dynamicModalRef.close(this.modal);
	}

	sendAppointmentMessage() {
		const connectMessageForPatientAppointmentRequest = new ConnectMessageForPatientAppointmentRequest();
		connectMessageForPatientAppointmentRequest.method = this.modalConfig.data.communicationMethod;
		connectMessageForPatientAppointmentRequest.patientId = this.modalConfig.data.appointment.patientId;
		connectMessageForPatientAppointmentRequest.appointmentId = this.modalConfig.data.appointment.id;

		this.sendConnectMessageService.sendConnectMessageForPatientAppointment(connectMessageForPatientAppointmentRequest).subscribe();
		this.dynamicModalRef.close(this.modal, {success: true});
	}
}
