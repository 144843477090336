import { EMPTY, Observable, Subject } from 'rxjs';

export class DynamicPrintRef {
	private readonly _printReady = new Subject<void>();

	constructor() { }

	get onPrintReady(): Observable<any> {
		return this._printReady.asObservable();
	}

	printReady() {
		this._printReady.next();
		this._printReady.complete();
	}
}

/**
 * This class is provided so calls to the print service that fail do not throw errors when subscribed to onClose
 * These onClose subscriptions immediately complete and never emit.
 */
export class EmptyPrintModalRef extends DynamicPrintRef {

	get onPrintReady(): Observable<any> {
		return EMPTY;
	}
}
