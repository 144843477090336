<ejs-dialog
	#modal
	(close)="closeDialog()">
	<ng-template #header>
		<div class="dlg-template">Printing Barcode Labels</div>
	</ng-template>
	<ng-template #content>
		<div class="modal-body">
			<div class="alert alert-danger" [hidden]="errorArray.length === 0">
				<ul>
					@for (error of errorArray; track error) {
						<li><strong>{{ error }}</strong></li>
					}
				</ul>
			</div>
			<div class="text-center">
				@if (!printingComplete()) {
					<div>
						<i class="fa fa-spinner fa-spin" style="font-size: 32px;" aria-hidden="true"></i>
						<br>
					</div>
				}
				@if (printingComplete() && noErrors()) {
					<i class="fa fa-check text-success"
					   style="font-size: 32px; "></i>
				}
				@if (!noErrors()) {
					<i class="fa fa-times text-danger" style="font-size: 32px;"></i>
				}
				<div> {{ complete }} of {{ total }}</div>
			</div>
		</div>
	</ng-template>
	<ng-template #footerTemplate>
		@if (!printingComplete()) {
			<button type="button" ejs-button (click)="cancel()" icon="fa fa-times">Cancel</button>
		}
		@if (printingComplete()) {
			<button type="button" ejs-button (click)="closeDialog()">Close</button>
		}
	</ng-template>
</ejs-dialog>
