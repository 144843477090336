import { Injectable } from '@angular/core';
import { LocationProductItemBarcodeGandalfService } from '@gandalf/services';

@Injectable({
	providedIn: 'root',
})
export class LocationProductItemBarcodeService {

	constructor(
		private locationProductItemBarcodeGandalfService: LocationProductItemBarcodeGandalfService,
	) {
	}

	getLocationProductItemForBarcodePrinting(id: number) {
		return this.locationProductItemBarcodeGandalfService.getLocationProductItemForBarcodePrinting(id);
	}
}
