import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { EnumUtil } from 'morgana';
import { _isNil } from '@core/lodash/lodash';
import { BaseCurveUnit, ContactLensHistoryType, ContactLensPrescriptionType, ContactLensType } from '@gandalf/constants';
import { ContactLensEyeHybridRequest } from '@gandalf/model/contact-lens-eye-hybrid-request';
import { ContactLensEyeHybridResponse } from '@gandalf/model/contact-lens-eye-hybrid-response';
import { ContactLensEyeRequest } from '@gandalf/model/contact-lens-eye-request';
import { ContactLensEyeResponse } from '@gandalf/model/contact-lens-eye-response';
import { ContactLensEyeRgpRequest } from '@gandalf/model/contact-lens-eye-rgp-request';
import { ContactLensEyeRgpResponse } from '@gandalf/model/contact-lens-eye-rgp-response';
import { ContactLensEyeSoftRequest } from '@gandalf/model/contact-lens-eye-soft-request';
import { ContactLensEyeSoftResponse } from '@gandalf/model/contact-lens-eye-soft-response';
import { CreateContactLensPrescriptionRequest } from '@gandalf/model/create-contact-lens-prescription-request';
import { RefillContactLensPrescriptionRequest } from '@gandalf/model/refill-contact-lens-prescription-request';
import { UpdateContactLensPrescriptionRequest } from '@gandalf/model/update-contact-lens-prescription-request';

export enum EyeDesignator {
	OD = 'OD',
	OS = 'OS',
}

export class ContactLensDetailsUtil {

	static addTransientFieldsToForm(form: UntypedFormGroup) {
		form.addControl('productOdName', new UntypedFormControl(null));
		form.addControl('productOsName', new UntypedFormControl(null));
		form.addControl('odProductColorId', new UntypedFormControl(null));
		form.addControl('osProductColorId', new UntypedFormControl(null));
	}

	static setContactLensEyeRequests(
		request: CreateContactLensPrescriptionRequest | UpdateContactLensPrescriptionRequest | RefillContactLensPrescriptionRequest,
		contactLensType: ContactLensPrescriptionType,
	) {
		if (EnumUtil.equals(contactLensType, ContactLensPrescriptionType.SOFT)) {
			request.od = new ContactLensEyeSoftRequest();
			request.os = new ContactLensEyeSoftRequest();
			request.overallLensType = ContactLensPrescriptionType.SOFT;
		} else if (EnumUtil.equals(contactLensType, ContactLensPrescriptionType.RGP)) {
			request.od = new ContactLensEyeRgpRequest();
			request.os = new ContactLensEyeRgpRequest();
			(request.od as ContactLensEyeRgpRequest).baseCurveUnit = BaseCurveUnit.MILLIMETER;
			(request.os as ContactLensEyeRgpRequest).baseCurveUnit = BaseCurveUnit.MILLIMETER;
			request.overallLensType = ContactLensPrescriptionType.RGP;
		} else if (EnumUtil.equals(contactLensType, ContactLensPrescriptionType.HYBRID)) {
			request.od = new ContactLensEyeHybridRequest();
			request.os = new ContactLensEyeHybridRequest();
			request.overallLensType = ContactLensPrescriptionType.HYBRID;
		}
	}

	static translateClEyeResponseToRequest(lensType: ContactLensType, eyeResponse: ContactLensEyeResponse, copyId) {
		let translatedResponseToRequest = new ContactLensEyeRequest();

		/*
		* A CLRX does not always have an OD and and OS as part of it but we want to make sure to
		* generate all the correct request parts so we need to add an empty response object when mapping to the request
		* so it does not only generate the controls for the base contact lens eye type.
		* We do this inside the individual type blocks.
		*/

		// Translates the response values to the equivalent to be set in the form for the request type
		if (EnumUtil.equals(lensType, ContactLensType.SOFT)) {
			eyeResponse = !_isNil(eyeResponse) ? eyeResponse : new ContactLensEyeSoftResponse();
			translatedResponseToRequest = this.translateSoftResponseToRequestValues(
				eyeResponse as (ContactLensEyeSoftResponse),
				copyId,
			);
		} else if (EnumUtil.equals(lensType, ContactLensType.RGP)) {
			eyeResponse = !_isNil(eyeResponse) ? eyeResponse : new ContactLensEyeRgpResponse();
			translatedResponseToRequest = this.translateRGPResponseToRequestValues(
				eyeResponse as (ContactLensEyeRgpResponse),
				copyId,
			);
		} else if (EnumUtil.equals(lensType, ContactLensType.SYNERGEYES)) {
			eyeResponse = !_isNil(eyeResponse) ? eyeResponse : new ContactLensEyeHybridResponse();
			translatedResponseToRequest = this.translateHybridResponseToRequestValues(
				eyeResponse as (ContactLensEyeHybridResponse),
				copyId,
			);
		} else {
			throw Error(`Unsupported Contact Lens Type [${lensType}]`);
		}
		return translatedResponseToRequest;
	}

	static translateRGPResponseToRequestValues(eyeResponse: ContactLensEyeRgpResponse, copyId = true): ContactLensEyeRgpRequest {
		const rgpRequest = new ContactLensEyeRgpRequest();
		this.mapEyeResponseToRequest(eyeResponse, rgpRequest, copyId);
		rgpRequest.edgeLiftId = eyeResponse.edgeLift ? eyeResponse.edgeLift.id : null;
		rgpRequest.specialCharacteristicsId = eyeResponse.specialCharacteristics ? eyeResponse.specialCharacteristics.id : null;
		rgpRequest.profileId = eyeResponse.profile ? eyeResponse.profile.id : null;
		rgpRequest.peripheralEdgeId = eyeResponse.peripheralEdge ? eyeResponse.peripheralEdge.id : null;
		rgpRequest.limbalClearanceId = eyeResponse.limbalClearance ? eyeResponse.limbalClearance.id : null;
		rgpRequest.lensMaterialId = eyeResponse.lensMaterial ? eyeResponse.lensMaterial.id : null;
		rgpRequest.thirdCurveWidth = eyeResponse.thirdCurveWidth;
		rgpRequest.fourthCurveRadius = eyeResponse.fourthCurveRadius;
		rgpRequest.thirdCurveRadius = eyeResponse.thirdCurveRadius;
		rgpRequest.peripheralCurveRadius = eyeResponse.peripheralCurveRadius;
		rgpRequest.secondaryCurveWidth = eyeResponse.secondaryCurveWidth;
		rgpRequest.backOpticalDiameter = eyeResponse.backOpticalDiameter;
		rgpRequest.firstCurveWidth = eyeResponse.firstCurveWidth;
		rgpRequest.secondCurveRadius = eyeResponse.secondCurveRadius;
		rgpRequest.fourthCurveWidth = eyeResponse.fourthCurveWidth;
		rgpRequest.centerThickness = eyeResponse.centerThickness;
		rgpRequest.peripheralCurveWidth = eyeResponse.peripheralCurveWidth;
		rgpRequest.secondaryCurveRadius = eyeResponse.secondaryCurveRadius;
		rgpRequest.firstCurveRadius = eyeResponse.firstCurveRadius;
		rgpRequest.secondCurveWidth = eyeResponse.secondCurveWidth;
		rgpRequest.intermediateSegment = eyeResponse.intermediateSegment;
		rgpRequest.returnZoneDiameter = eyeResponse.returnZoneDiameter;
		rgpRequest.plasmaTreatment = eyeResponse.plasmaTreatment;
		rgpRequest.landingZoneAngle = eyeResponse.landingZoneAngle;
		rgpRequest.notes = eyeResponse.notes;
		rgpRequest.sagittalDepth = eyeResponse.sagittalDepth;
		rgpRequest.addDiameter = eyeResponse.addDiameter;
		rgpRequest.distanceZone = eyeResponse.distanceZone;
		rgpRequest.dot = eyeResponse.dot;
		rgpRequest.sphere2 = eyeResponse.sphere2;
		rgpRequest.baseCurveUnit = eyeResponse.baseCurveUnit;
		rgpRequest.opticZone = eyeResponse.opticZone;
		rgpRequest.truncation = eyeResponse.truncation;
		rgpRequest.baseCurve2 = eyeResponse.baseCurve2;
		rgpRequest.segmentHeight = eyeResponse.segmentHeight;
		rgpRequest.fenestration = eyeResponse.fenestration;
		rgpRequest.edgeLiftAmount = eyeResponse.edgeLiftAmount;
		return rgpRequest;
	}

	static translateSoftResponseToRequestValues(eyeResponse: ContactLensEyeSoftResponse, copyId = true) {
		const softRequest = new ContactLensEyeSoftRequest();
		this.mapEyeResponseToRequest(eyeResponse, softRequest, copyId);
		softRequest.addDesignationId = eyeResponse.addDesignation ? eyeResponse.addDesignation.id : null;
		return softRequest;
	}

	static translateHybridResponseToRequestValues(eyeResponse: ContactLensEyeHybridResponse, copyId = true) {
		const hybridRequest = new ContactLensEyeHybridRequest();
		this.mapEyeResponseToRequest(eyeResponse, hybridRequest, copyId);
		hybridRequest.addZoneSizeId = eyeResponse.addZoneSize ? eyeResponse.addZoneSize.id : null;
		hybridRequest.segmentSizeId = eyeResponse.segmentSize ? eyeResponse.segmentSize.id : null;
		hybridRequest.skirtCurveRadiusId = eyeResponse.skirtCurveRadius ? eyeResponse.skirtCurveRadius.id : null;
		hybridRequest.designId = eyeResponse.design ? eyeResponse.design.id : null;
		hybridRequest.vault = eyeResponse.vault;
		return hybridRequest;
	}


	static mapEyeResponseToRequest(
		eyeResponse: ContactLensEyeResponse,
		eyeRequest: ContactLensEyeRequest,
		copyId = true,
	) {
		if (copyId) {
			eyeRequest.id = eyeResponse.id;
			eyeRequest.version = eyeResponse.version;
		}
		eyeRequest.baseCurve = eyeResponse.baseCurve;
		eyeRequest.cylinder = eyeResponse.cylinder;
		eyeRequest.axis = eyeResponse.axis;
		eyeRequest.diameter = eyeResponse.diameter;
		eyeRequest.addPower = eyeResponse.addPower;
		eyeRequest.sphere = eyeResponse.sphere;
		eyeRequest.isMonovision = eyeResponse.isMonovision;
		eyeRequest.productColorId = eyeResponse.productColorId;
		eyeRequest.locationProductId = eyeResponse.locationProductId;
		eyeRequest.locationProductItemId = eyeResponse.locationProductItemId;
		eyeRequest.overnightDurationAmount = eyeResponse.overnightSchedule ? eyeResponse.overnightSchedule.amount : null;
		eyeRequest.overnightDurationUnit = eyeResponse.overnightSchedule ? eyeResponse.overnightSchedule.durationUnit : null;
		eyeRequest.replacementDurationAmount = eyeResponse.replacementSchedule ? eyeResponse.replacementSchedule.amount : null;
		eyeRequest.replacementDurationUnit = eyeResponse.replacementSchedule ? eyeResponse.replacementSchedule.durationUnit : null;
	}

	static isSoftLensType(lensType: ContactLensType | ContactLensHistoryType | ContactLensPrescriptionType) {
		if (lensType instanceof ContactLensType && EnumUtil.equals(lensType, ContactLensType.SOFT)) {
			return true;
		} else if (lensType instanceof ContactLensPrescriptionType && EnumUtil.equals(lensType, ContactLensPrescriptionType.SOFT)) {
			return true;
		} else if (lensType instanceof ContactLensHistoryType && EnumUtil.equals(lensType, ContactLensHistoryType.SOFT)) {
			return true;
		}
		return false;
	}

	static isRGPLensType(lensType: ContactLensType | ContactLensHistoryType | ContactLensPrescriptionType) {
		if (lensType instanceof ContactLensType && EnumUtil.equals(lensType, ContactLensType.RGP)) {
			return true;
		} else if (lensType instanceof ContactLensPrescriptionType && EnumUtil.equals(lensType, ContactLensPrescriptionType.RGP)) {
			return true;
		} else if (lensType instanceof ContactLensHistoryType && EnumUtil.equals(lensType, ContactLensHistoryType.RGP)) {
			return true;
		}
		return false;
	}

	static isHybridLensType(lensType: ContactLensType | ContactLensHistoryType | ContactLensPrescriptionType) {
		if (lensType instanceof ContactLensType && EnumUtil.equals(lensType, ContactLensType.SYNERGEYES)) {
			return true;
		} else if (lensType instanceof ContactLensPrescriptionType && EnumUtil.equals(lensType, ContactLensPrescriptionType.HYBRID)) {
			return true;
		} else if (lensType instanceof ContactLensHistoryType && EnumUtil.equals(lensType, ContactLensHistoryType.HYBRID)) {
			return true;
		}
		return false;
	}
}
