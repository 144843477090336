import { Pipe, PipeTransform } from '@angular/core';
import { EnumUtil } from 'morgana';
import { BusinessStatus } from '@gandalf/constants';

@Pipe({
	name: 'businessName',
})
export class BusinessNamePipe implements PipeTransform {

	transform(businessStatus: BusinessStatus, businessName: string): string {
		if (EnumUtil.equals(businessStatus, BusinessStatus.INACTIVE)) {
			return `(INACTIVE) ${businessName}`;
		}
		return businessName;
	}
}
