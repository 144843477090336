<div class="margin-bottom-md" [attr.data-test-id]="'serviceSelectionHeader'">
	<h4 class="section-title bg-default mrgn-btm">{{ headerText }}</h4>
	<ejs-grid #grid
			  autoFitColumns
			  [autoFitBlacklist]="['modifier', 'shortDescription']"
			  [staticColumns]="['price', 'common']"
			  [dataSource]="services"
			  [pmsGridSubmit]="isSearching"
			  gridLines="Horizontal"
			  (dataBound)="gridDataBound(grid)"
			  [allowSorting]="true"
			  [allowSelection]="true"
			  [allowPaging]="true"
			  [pageSettings]="pageSettings"
			  [allowResizing]="true"
			  [allowFiltering]="true"
			  [filterSettings]="{mode: 'Immediate', 'immediateModeDelay': 200, showFilterBarStatus: false}"
			  (rowSelected)="serviceSelected.emit($event.data.original)"
			  class="table-rowlink e-filterbar-visible"
			  [attr.data-test-id]="'serviceSelectionGrid'">
		<ng-template #toolbarTemplate let-data>
			@if (isForCoding) {
				<rev-table-action-menu [table]="grid">
					<ng-container rev-table-action-menu-left>
						<rev-status-select-button [options]="serviceTypeOptions"
												  [(ngModel)]="_serviceType"
												  (onChange)="setServiceType($event.value)"
												  [attr.data-test-id]="'serviceStatusSelectButtons'">
						</rev-status-select-button>
					</ng-container>
				</rev-table-action-menu>
			}
		</ng-template>
		<e-columns>
			<e-column field="name" headerText="Name/Code" clipMode="EllipsisWithTooltip"></e-column>
			<e-column [visible]="isCptType() || isAllTypes()" [customAttributes]="{ autofitMinWidth: 50 }" field="modifier"
					  headerText="Modifier" clipMode="EllipsisWithTooltip"></e-column>
			<e-column field="shortDescription" [customAttributes]="{ autofitMinWidth: 50 }" headerText="Description"
					  clipMode="EllipsisWithTooltip"></e-column>
			@if (!isForCoding) {
				<e-column
					width="79"
					field="price"
					textAlign="right"
					[sortComparer]="sortComparer"
					headerText="Fee"
					clipMode="EllipsisWithTooltip">
				</e-column>
			}
			@if (!isForCoding) {
				<e-column width="110" field="common" headerText="Common" clipMode="EllipsisWithTooltip">
					<ng-template #filterTemplate let-data>
						<ejs-dropdownlist placeholder="All" showClearButton="true" (valueChange)="filterData($event)"
										  [dataSource]='commonOptions'></ejs-dropdownlist>
					</ng-template>
					<ng-template #template let-data>
						{{ data.common | yesNo }}
					</ng-template>
				</e-column>
			}
		</e-columns>
	</ejs-grid>
</div>
