<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-lg"
	[attr.data-test-id]="'manageUpdatesModal'">
	<ng-template #header>
		<div class="dlg-template">Manage Updates</div>
	</ng-template>
	<ng-template #content>
		<div class="row">
			<div class="col-sm-5">
				<p><b>We are making changes!</b></p>
				<p>We have given you the ability to control access and provide early feedback on our enhancements.</p>
				<p>You can enable or disable updated sections as you desire. Changes will be applied and visible the next time you login.</p>
				<ejs-grid #grid
						  [dataSource]="featureFlags"
						  [pmsGridSubmit]="isSearching"
						  [pmsGridSubmitDefaultSortOptions]="sortSettings"
						  [allowSelection]="true"
						  [selectionSettings]="{enableToggle: false}"
						  (rowSelected)='selectFeatureFlag($event)'
						  (rowDeselected)='deselectFeatureFlag()'
						  gridLines="Horizontal"
						  [pageSettings]="pageSettings"
						  [sortSettings]="sortSettings"
						  class="e-gridheader-hidden table-rowlink"
						  [allowFiltering]="true"
						  [filterSettings]="{showFilterBarStatus: false}"
						  [allowPaging]="true"
						  [attr.data-test-id]="'manageUpdatesModalGrid'">
					<ng-template #toolbarTemplate let-data>
						<div class="e-control e-toolbar e-lib e-keyboard">
							<div class="e-toolbar-items e-tbar-pos">
								<div class="e-toolbar-left">
									<div class="e-toolbar-item">
										<label>Status</label>
										<rev-status-select-button
											[options]="statusOptions"
											[(ngModel)]="selectedStatus"
											[ngModelOptions]="{standalone: true}"
											(onChange)="filterGrid()">
										</rev-status-select-button>
									</div>
								</div>
							</div>
						</div>
					</ng-template>
					<e-columns>
						<e-column field="internalName" headerText="Feature Flag" width="20"></e-column>
						<e-column field="enabled" width="10" [customAttributes]="{class:['nowrap','nostretch','text-center']}">
							<ng-template #template let-data>
								<ejs-switch
									[(ngModel)]="data.enabled"
									[ngModelOptions]="{standalone: true}"
									(change)="handleSwitchChange($event, data)">
								</ejs-switch>
							</ng-template>
						</e-column>
					</e-columns>
				</ejs-grid>
			</div>
			<div class="col-sm-7">
				<div class="row text-center" [attr.data-test-id]="'manageUpdatesModalPreviePanel'">
					@if (selectedFeatureFlag) {
						<div class="col-sm-12">
							@if (selectedFeatureFlag?.imageFileId) {
								<div>
									<img [src]="getImageUrl()">
								</div>
							}
							<h2>{{ selectedFeatureFlag?.title }}</h2>
							<span [innerHTML]="selectedFeatureFlag?.description"></span>
							@if (selectedFeatureFlag?.documentationLink) {
								<span>
                  <a (click)="openDocumentationLink(selectedFeatureFlag?.documentationLink)">Learn More...</a>
                </span>
							}
						</div>
					}
					@if (!selectedFeatureFlag && featureFlags?.length) {
						<div class="col-sm-12">
							<i class="fa fa-image" style="font-size: 16em"></i>
							<p>Select a feature on the left to preview</p>
						</div>
					}
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button type="button" (click)="closeModal()" iconCss="fa fa-close" [attr.data-test-id]="'manageUpdatesModalCloseButton'">Close</button>
	</ng-template>
</ejs-dialog>
