import { AbstractControl, ValidatorFn } from '@angular/forms';

const SIN_REGEX_STRING = '^[a-zA-Z0-9-]*$';
/**
 * Validates that a form control's input contains alphanumeric or dash characters only.
 */
export const validateSin: ValidatorFn = (control: AbstractControl) => {
	const value: any = control.value;
	if (!value) {
		return null;
	}

	const input = value.toString();
	if (input === '') {
		return null;
	}

	if (input.match(SIN_REGEX_STRING)) {
		return null;
	}

	return {
		sin: {
			valid: false,
		},
	};
};
