<ejs-dialog
	#modal
	cssClass="modal-sm"
	(close)="closeDialog()"
    [attr.data-test-id]="'printAddressLabelsModal'">
	<ng-template #header>
		<div class="dlg-template">Print Address Labels</div>
	</ng-template>
	<ng-template #content>
		<form>
			<p>Select a label format</p>
			<ejs-radiobutton
					name="labelFormat"
					value="Avery5160"
					label="Avery 5160"
					[(ngModel)]="labelFormat">
			</ejs-radiobutton>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" (click)="closeDialog()" [attr.data-test-id]="'printAddressLabelsModalCancelButton'">Cancel</button>
		<button ejs-button [isPrimary]="true" iconCss="fa fa-print" (click)="printLabels()" [attr.data-test-id]="'printAddressLabelsModalPrintButton'">Print</button>
	</ng-template>
</ejs-dialog>
