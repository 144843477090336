import { Action } from '@ngrx/store';

export enum PropertiesActionTypes {
	UPDATE_PROPERTY_VALUE = '[Update Property Value] Updates the value of the property.',
	REMOVE_PROPERTIES = '[Remove Properties] Removes the properties associated with a component key.',
}

export class UpdatePropertyValue<T = any> implements Action {
	readonly type = PropertiesActionTypes.UPDATE_PROPERTY_VALUE;
	constructor(public payload: {key: string, propertyKey: string, value: T}) {}
}

export class RemoveProperties implements Action {
	readonly type = PropertiesActionTypes.REMOVE_PROPERTIES;
	constructor(public payload: {key: string}) {}
}

export type PropertiesActions =
	UpdatePropertyValue
	| RemoveProperties;
