import { DatePipe } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NgForm } from '@angular/forms';
import { DiagnosisService, PracticeDiagnosesWithCodeDescription } from '@core/diagnosis/diagnosis.service';
import { DialogUtil, DynamicModalRef, EnumUtil, ModalConfig, ModalManagerService } from 'morgana';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { _filter, _get, _includes, _isNil } from '@core/lodash/lodash';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { SummaryPodService } from '@core/summary-pod/summary-pod.service';
import { CodeSet, DiagnosisLocation, DiagnosisLocationQualifier, EntityType, PersonDiagnosisStatus } from '@gandalf/constants';
import { AssessmentPracticeDiagnosisResponse } from '@gandalf/model/assessment-practice-diagnosis-response';
import { CreatePersonDiagnosisRequest } from '@gandalf/model/create-person-diagnosis-request';
import { PersonDiagnosisResponse } from '@gandalf/model/person-diagnosis-response';
import { SearchActivePracticeDiagnosesForAssessmentRequest } from '@gandalf/model/search-active-practice-diagnoses-for-assessment-request';
import { SearchPracticeDiagnosesRequest } from '@gandalf/model/search-practice-diagnoses-request';
import { UpdatePersonDiagnosisRequest } from '@gandalf/model/update-person-diagnosis-request';
import { DiagnosisCodeComponent } from '@shared/component/diagnosis/diagnosis-code/diagnosis-code.component';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { TabAnimationDefaults } from '@shared/constants/tab.constants';
import { AutoCompleteComponent, FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfConstantList, GandalfFormBuilder } from 'gandalf';
import { EventService } from '@core/event/event.service';
import { SelectDiagnosisModalComponent } from '../select-diagnosis-modal/select-diagnosis-modal.component';

export enum MODAL_EDITABLE_STATUS {
	FULL,
	PARTIAL,
	NONE,
}

@Component({
	selector: 'pms-diagnosis-details-modal',
	templateUrl: './diagnosis-details-modal.component.html',
	styles: [],
	providers: [ModalManagerService],
})
export class DiagnosisDetailsModalComponent implements OnInit, AfterViewChecked {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('diagnosisDetailsForm')
	diagnosisDetailsForm: NgForm;

	@ViewChild('searchDiagnosisAutoComplete')
	searchDiagnosisComponent: AutoCompleteComponent;

	tabAnimation = TabAnimationDefaults;

	componentForm: UntypedFormGroup;
	request: CreatePersonDiagnosisRequest | UpdatePersonDiagnosisRequest;
	patientId: number;
	encounterId: number;
	showTabs = false;
	personDiagnosis: PersonDiagnosisResponse = null;
	description = '';
	diagnoses: PracticeDiagnosesWithCodeDescription[];
	notesEntityType = EntityType.DIAGNOSIS;
	readonly MIN_LENGTH = 3;

	codeSet = [CodeSet.ICD10, CodeSet.ICD9, CodeSet.SNOMED];
	selectedCodeSet: CodeSet;
	diagnosisLocation = EnumUtil.buildGandalfEnumSubsetList([
		DiagnosisLocation.UNKNOWN_EYE,
		DiagnosisLocation.RIGHT_EYE,
		DiagnosisLocation.LEFT_EYE,
		DiagnosisLocation.BOTH_EYES,
	],
	DiagnosisLocation.VALUES.label,
	);
	diagnosisLocationQualifiers: GandalfConstantList<DiagnosisLocationQualifier> = DiagnosisLocationQualifier.VALUES;
	fields: object = {value: 'codeDescription'};
	dateFormat = DATE_FORMATS.MM_DD_YYYY;

	constructor(
		private gandalfFormBuilder: GandalfFormBuilder,
		private dynamicModalRef: DynamicModalRef,
		private config: ModalConfig,
		private diagnosisService: DiagnosisService,
		public modalManagerService: ModalManagerService,
		private changeDetectorRef: ChangeDetectorRef,
		private summaryPodService: SummaryPodService,
		private securityManagerService: SecurityManagerService,
		private datePipe: DatePipe,
		private eventService: EventService,
	) {

	}

	ngOnInit() {
		this.selectedCodeSet = EnumUtil.findEnumByValue(this.securityManagerService.sessionData.systemConfig.defaultDiagnosisCodeSet, CodeSet);
		this.parseConfigData(this.config.data);
		this.setDescriptionShowTabs();
		this.createForm();
		if (!this.componentForm.touched) {
			if (_isNil(this.personDiagnosis)) {
				this.componentForm.get('diagnosisDate').setValue(_get(this.request, ['diagnosisDate'], new Date()));
				this.componentForm.get('diagnosisLocation').setValue(_get(this.request, ['diagnosisLocation'], DiagnosisLocation.UNKNOWN_EYE));
				this.componentForm.get('patientId').setValue(_get(this.request, ['patientId'], this.patientId));
				if (this.encounterId) {
					this.componentForm.get('encounterId').setValue(_get(this.request, ['encounterId'], this.encounterId));
				}
			} else {
				this.selectedCodeSet = this.personDiagnosis.practiceDiagnosis.codeSet;
				this.componentForm.addControl('codeSet', new UntypedFormControl(this.personDiagnosis.practiceDiagnosis.codeSet));
				this.componentForm.addControl('resolutionDate', new UntypedFormControl(this.personDiagnosis.resolutionDate));
			}
			this.componentForm.addControl('description', new UntypedFormControl(''));
		}
		this.componentForm.get('description').setValue(this.description);
		FormUtilsService.reactToValueChanges(this.componentForm, (values) => this.processFormChanges(values), true);
	}

	parseConfigData(data) {
		this.patientId = data.patientId;
		this.encounterId = data.encounterId;
		this.personDiagnosis = data.personDiagnosis;
	}

	createForm() {
		let request;
		if (this.personDiagnosis) {
			request = new UpdatePersonDiagnosisRequest();
			request.id = this.personDiagnosis.id;
			request.reason = this.personDiagnosis.reason;
			request.codeSet = this.personDiagnosis.practiceDiagnosis.codeSet;
			request.diagnosisLocation = this.personDiagnosis.diagnosisLocation;
			request.diagnosisLocationQualifier = this.personDiagnosis.diagnosisLocationQualifier;
			request.personDiagnosisId = this.personDiagnosis.id;
			request.diagnosisDate = this.personDiagnosis.diagnosisDate;
			request.needConversion = this.personDiagnosis.needConversion;
		} else {
			request = new CreatePersonDiagnosisRequest();
			request.patientId = this.patientId;
			if (this.encounterId) {
				request.encounterId = this.encounterId;
			}
		}
		this.request = request;
		this.componentForm = this.gandalfFormBuilder.group(this.request);
	}

	submitForm(event) {
		this.diagnosisDetailsForm.onSubmit(event);
	}

	save() {
		if (this.componentForm.invalid) {
			return;
		}

		if (!_isNil(this.personDiagnosis)) {
			if (this.isModalPartiallyEditable()) {
				this.diagnosisService.updatePersonDiagnosisLocationAndQualifier(this.componentForm.value).subscribe(() => {
					this.diagnosisUpdated();
					this.closeModal(true);
				});
			} else {
				this.diagnosisService.updatePersonDiagnosis(this.componentForm.value).subscribe(() => {
					this.diagnosisUpdated();
					this.closeModal(true);
				});
			}

		} else {
			this.diagnosisService.createPersonDiagnosis(this.componentForm.value).subscribe(() => {
				this.diagnosisUpdated();
				this.closeModal(true);
			});
		}
	}

	closeModal(refreshRequested = false) {
		this.dynamicModalRef.close(this.modal, refreshRequested);
	}

	updateNeedConversion() {
		this.componentForm.value.needConversion = !this.componentForm.get('needConversion').value;
		this.componentForm.markAsDirty();
	}

	processFormChanges(values) {
		if (_isNil(this.personDiagnosis)) {
			FormUtilsService.enabledWhen(this.componentForm.get('diagnosisLocation'),
				(EnumUtil.equals(this.selectedCodeSet, CodeSet.ICD9) || EnumUtil.equals(this.selectedCodeSet, CodeSet.SNOMED)));

			FormUtilsService.enabledWhen(this.componentForm.get('diagnosisLocationQualifier'),
				(EnumUtil.equals(this.selectedCodeSet, CodeSet.ICD9) ||
					EnumUtil.equals(this.selectedCodeSet, CodeSet.SNOMED)) &&
				EnumUtil.equals(values.diagnosisLocation, DiagnosisLocation.BOTH_EYES),
			);

			if (EnumUtil.equals(this.selectedCodeSet, CodeSet.ICD10)) {
				this.componentForm.get('diagnosisLocation').setValue(DiagnosisLocation.UNKNOWN_EYE);
				this.componentForm.get('diagnosisLocationQualifier').setValue(null);
			} else {
				const diagnosisLocationValue = values.diagnosisLocation || DiagnosisLocation.NOT_APPLICABLE;
				this.componentForm.get('diagnosisLocation').setValue(diagnosisLocationValue);
			}

			if (!EnumUtil.equals(values.diagnosisLocation, DiagnosisLocation.BOTH_EYES)) {
				this.componentForm.get('diagnosisLocationQualifier').setValue(null);
			}
		} else {
			FormUtilsService.enableDisableControls(this.componentForm, [], [
				'codeSet',
				'description',
			]);

			if (this.isModalReadOnly()) {
				FormUtilsService.enableDisableControls(this.componentForm, [], [
					'diagnosisLocation',
					'diagnosisLocationQualifier',
					'reason',
					'diagnosisDate',
					'resolutionDate',
					'needConversion',
				]);
			} else {
				if (this.isModalPartiallyEditable()) {
					FormUtilsService.enableDisableControls(this.componentForm, [], [
						'reason',
						'diagnosisDate',
						'resolutionDate',
						'needConversion',
					]);
				}
				FormUtilsService.enabledWhen(this.componentForm.get('diagnosisLocation'),
					EnumUtil.equals(this.personDiagnosis.practiceDiagnosis.codeSet, CodeSet.ICD9)
					|| EnumUtil.equals(this.personDiagnosis.practiceDiagnosis.codeSet, CodeSet.SNOMED));

				FormUtilsService.enabledWhen(this.componentForm.get('diagnosisLocationQualifier'),
					(EnumUtil.equals(this.selectedCodeSet, CodeSet.ICD9) || EnumUtil.equals(this.selectedCodeSet, CodeSet.SNOMED))
					&& EnumUtil.equals(values.diagnosisLocation, DiagnosisLocation.BOTH_EYES));

				if (EnumUtil.equals(this.personDiagnosis.practiceDiagnosis.codeSet, CodeSet.ICD10)) {
					this.componentForm.get('diagnosisLocation').setValue(DiagnosisLocation.UNKNOWN_EYE);
					this.componentForm.get('diagnosisLocationQualifier').setValue(null);
				}

				if (!EnumUtil.equals(values.diagnosisLocation, DiagnosisLocation.BOTH_EYES)) {
					this.componentForm.get('diagnosisLocationQualifier').setValue(null);
					this.personDiagnosis.diagnosisLocationQualifier = null;
				}
			}
		}
	}


	openSelectDiagnosisModal() {
		this.modalManagerService.open(SelectDiagnosisModalComponent, {
			data: {codeSet: this.selectedCodeSet},
		}).onClose.subscribe((selectedDiagnosis: AssessmentPracticeDiagnosisResponse) => this.onDiagnosisSelected(selectedDiagnosis));
	}

	private openConfirmUnmap(unmapHandler) {
		const dialog = DialogUtil.confirm({
			title: 'Unmap Diagnosis',
			content: '<p>Unmapping this diagnosis will remove the original mapping and allow the diagnosis to be re-converted.<br> '
				+ 'Are you sure you want to unmap this diagnosis?</p>',
			okButton: {
				click: () => {
					unmapHandler();
					dialog.close();
				},
			},
			cancelButton: {
				click: () => dialog.close(),
			},
		});
		return;
	}

	openConfirmUnmapParent(diagnosis) {
		this.openConfirmUnmap(() => this.unmapParentDiagnosis(diagnosis));
	}

	openConfirmUnmapChild(diagnosis) {
		this.openConfirmUnmap(() => this.unmapChildDiagnosis(diagnosis));
	}

	unmapParentDiagnosis(parentDiagnosis) {
		this.componentForm.value.parentIdToUnmap = parentDiagnosis.id;
		this.componentForm.markAsDirty();
	}

	unmapChildDiagnosis(childDiagnosis) {
		if (_isNil(this.componentForm.value.childIdsToUnmap)) {
			this.componentForm.value.childIdsToUnmap = [];
		}

		this.componentForm.value.childIdsToUnmap.push(childDiagnosis.id);
		this.componentForm.markAsDirty();
	}

	showParentDiagnosis(): boolean {
		if (this.isPersonDiagnosisNil()) {
			return false;
		}

		return _isNil(this.componentForm.value.parentIdToUnmap) && !_isNil(this.personDiagnosis.parentDiagnosis);
	}

	filterChildDiagnosesToShow(): PersonDiagnosisResponse[] {
		if (this.isPersonDiagnosisNil()) {
			return [];
		}
		// only show child diagnoses that are not flagged as to be unmapped
		return _filter(
			this.personDiagnosis.childDiagnoses,
			(childDiagnosis) => !_includes(this.componentForm.value.childIdsToUnmap, childDiagnosis.id));
	}

	isDiagnosisSelected() {
		return !!this.componentForm.get('description').value;
	}

	clearSelectedDiagnosis() {
		if (this.isDiagnosisSelected()) {
			this.componentForm.get('practiceDiagnosisId').setValue(null);
			this.componentForm.get('description').setValue('');
		}
	}

	onDiagnosisSelected(selectedDiagnosis: AssessmentPracticeDiagnosisResponse) {
		if (selectedDiagnosis) {
			this.componentForm.get('practiceDiagnosisId').setValue(selectedDiagnosis.id);
			this.searchDiagnosisComponent.value = DiagnosisCodeComponent.computeDescription(selectedDiagnosis.code, selectedDiagnosis.shortDescription);
		}
	}

	/* istanbul ignore next: gandalf */
	searchPracticeDiagnoses(text: string): void {
		if (this.encounterId) {
			const request = new SearchActivePracticeDiagnosesForAssessmentRequest();
			request.description = text;
			request.codeSet = this.selectedCodeSet;
			request.encounterId = this.encounterId;
			this.diagnosisService.searchPracticeDiagnosesByEncounterId(request).subscribe(data => {
				this.diagnoses = this.appendNonBillable(data);
			});
		} else {
			const request = new SearchPracticeDiagnosesRequest();
			request.description = text;
			request.codeSet = this.selectedCodeSet;
			this.diagnosisService.searchActivePracticeDiagnoses(request).subscribe(data => {
				this.diagnoses = this.appendNonBillable(data);
			});
		}

	}

	appendNonBillable(data: PracticeDiagnosesWithCodeDescription[]) {
		return data.map((diagnosis) => {
			if (diagnosis.masterCodeSetId === CodeSet.ICD10.value && !diagnosis.active) {
				diagnosis.codeDescription =
					`${diagnosis.codeDescription} (This code is no longer billable as of ${this.datePipe.transform(diagnosis.disabledDate, this.dateFormat)})`;
			}
			return diagnosis;
		});
	}

	onDataBound() {
		if (!_isNil(this.diagnoses)) {
			setTimeout(() => this.searchDiagnosisComponent.showPopup(), 400);
		}
	}

	loadDiagnoses(event: FilteringEventArgs) {
		if (event.text.length >= this.MIN_LENGTH) {
			this.searchPracticeDiagnoses(event.text);
			event.preventDefaultAction = true;
		} else if (event.text.length > 0) {
			this.diagnoses = null;
		}
	}

	updatePracticeDiagnosis(args: any): void {
		const practiceDiagnosis = args.itemData as PracticeDiagnosesWithCodeDescription;
		this.componentForm.get('practiceDiagnosisId').setValue(practiceDiagnosis.id);
	}

	setDescriptionShowTabs(): void {
		if (this.personDiagnosis) {
			this.showTabs = true;
			this.description = DiagnosisCodeComponent.computeDescription(
				this.personDiagnosis.practiceDiagnosis.code,
				this.personDiagnosis.practiceDiagnosis.shortDescription,
			);
		}
	}

	getModalStatus(): MODAL_EDITABLE_STATUS {
		if (this.personDiagnosis) {
			if (EnumUtil.equals(this.personDiagnosis.status, PersonDiagnosisStatus.RESOLVED)) {
				return MODAL_EDITABLE_STATUS.NONE;
			}
			if (!_isNil(this.personDiagnosis.originalDiagnosisId)) {
				return MODAL_EDITABLE_STATUS.PARTIAL;
			}
		}
		return MODAL_EDITABLE_STATUS.FULL;
	}

	isModalReadOnly() {
		return this.getModalStatus() === MODAL_EDITABLE_STATUS.NONE;
	}

	isModalPartiallyEditable() {
		return this.getModalStatus() === MODAL_EDITABLE_STATUS.PARTIAL;
	}

	isModalFullyEditable() {
		return this.getModalStatus() === MODAL_EDITABLE_STATUS.FULL;
	}

	showConvertFlag(): boolean {
		// `Do not convert to ICD-10` checkbox is displayed when:
		// 	- the form is editable
		// 	- the code has not been previously converted (when there is no parent diagnosis)
		// 	- it is an ICD-9 or disabled code

		if (!this.personDiagnosis) {
			return false;
		}

		if (!this.isModalFullyEditable()) {
			return false;
		}

		if (!_isNil(this.personDiagnosis.parentDiagnosis)) {
			return false;
		}

		return EnumUtil.equals(this.personDiagnosis.practiceDiagnosis.codeSet, CodeSet.ICD9) || this.personDiagnosis.isShowDisabledWarning;
	}

	ngAfterViewChecked() {
		this.changeDetectorRef.detectChanges();
	}

	isPersonDiagnosisNil() {
		return _isNil(this.personDiagnosis);
	}

	/* istanbul ignore next */
	focusAutoCompleteComponent(): void {
		setTimeout(() => {
			this.searchDiagnosisComponent.focusIn();
		});
	}

	diagnosisUpdated() {
		this.summaryPodService.updateDiagnosisHistorySummaryPod(this.patientId);
		this.eventService.publishUpdatePatientDiagnoses(this.patientId);
	}
}
