<div class="alert alert-danger validationMessages" #validationMessages>
	<ul>
		@for (error of errorList; track error) {
			@if (error.detail) {
				<li>
					<strong>{{ error.detail }}</strong>
				</li>
			}
		}
	</ul>
</div>
