export class PrintBarcodeLabelDataRequest {

	readonly DataId: string;

	readonly DataValue: string;

	readonly LabelId: string;

	readonly LabelText: string;

	constructor(dataId: string, dataValue: string, labelId: string, labelText: string) {
		this.DataId = dataId;
		this.DataValue = dataValue;
		this.LabelId = labelId;
		this.LabelText = labelText;
	}
}
