<ejs-dialog
		#modal
		(close)="closeModal(false)"
		cssClass="modal-sm"
		[attr.data-test-id]="'selectAProviderModal'">
	<ng-template #header>
		<div class="dlg-template">Select a Provider</div>
	</ng-template>
	<ng-template #content>
		<div class="form-group" [attr.data-test-id]="'selectAProviderDropdown'">
			<label for="provider" class="col-sm-4 control-label required">Provider</label>
			<div class="col-sm-8">
				<ejs-dropdownlist
						id="provider"
						[dataSource]="providerList"
						[(ngModel)]="invoiceProviderId"
						[showClearButton]="false"
						placeholder="Select Provider">
				</ejs-dropdownlist>
			</div>
		</div>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" (click)="closeModal(false)" [attr.data-test-id]="'selectAProviderCancelButton'">Cancel</button>
		<button ejs-button type="submit" [isPrimary]="true" (click)="saveProviderAndRefreshInvoice()" [attr.data-test-id]="'selectAProviderSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>
