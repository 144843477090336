<div>
	<h5 class="margin-top-0">{{ patientName | patientName }}</h5>
	@if (patientPopoverData) {
		<dl class='dl-horizontal margin-0'>
			<dt>DOB</dt>
			<dd>{{ patientPopoverData.dateOfBirth | date:dateFormat }}<br>({{ patientPopoverData.dateOfBirth | personAge }})
			</dd>
			<dt>Sex</dt>
			<dd>{{ patientPopoverData.gender.label }}</dd>
			<dt>Address</dt>
			<dd>
				<pms-address-display [address]="patientPopoverData.address"></pms-address-display>
			</dd>
			<dt>Home</dt>
			<dd>{{ patientPopoverData.phoneNumbers.homePhone }} {{ patientPopoverData.phoneNumbers.homePhoneExt }}
				@if (isPreferredPhoneType(preferredPhoneType.HOME)) {
					<i
						class='fa fa-star text-success'></i>
				}</dd>
			<dt>Work</dt>
			<dd>{{ patientPopoverData.phoneNumbers.workPhone }} {{ patientPopoverData.phoneNumbers.workPhoneExt }}
				@if (isPreferredPhoneType(preferredPhoneType.WORK)) {
					<i
						class='fa fa-star text-success'></i>
				}</dd>
			<dt>Cell</dt>
			<dd>{{ patientPopoverData.phoneNumbers.cellPhone }} {{ patientPopoverData.phoneNumbers.cellPhoneExt }}
				@if (isPreferredPhoneType(preferredPhoneType.CELL)) {
					<i
						class='fa fa-star text-success'></i>
				}</dd>
			<dt>Email</dt>
			<dd>{{ patientPopoverData.email }}</dd>
			<dt>PHR Login</dt>
			<dd>{{ patientPopoverData.phrLogin | yesNo }}</dd>
		</dl>
	}
</div>
