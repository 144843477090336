import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'formattedName',
})
@Injectable({
	providedIn: 'root',
})
export class FormattedNamePipe implements PipeTransform {

	transform(firstName: string, lastName?: string): any {
		let formattedName = firstName || '';

		if (lastName) {
			formattedName = lastName.concat(', ', formattedName);
		}
		return formattedName.trim();
	}

}
