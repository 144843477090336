import { Injectable } from '@angular/core';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { NavigationRequest } from './navigation-request.class';

@Injectable({
	providedIn: 'root',
})
export class LegacyNavigationService {

	previousPrimaryContext: string;

	constructor(
		private eventsManager: EventsManagerService,
	) {
	}

	sendNavigationRequest(navigationRequest: NavigationRequest) {
		this.eventsManager.publish('NAVIGATION_REQUESTED', navigationRequest);
		this.previousPrimaryContext = navigationRequest.primaryContext;
	}

	hideLegacy(primaryContext: string, subcontexts?: (string | number)[], isPorted = true) {
		const navigationRequest = new NavigationRequest(primaryContext, subcontexts, primaryContext);
		navigationRequest.isPorted = isPorted;
		navigationRequest.showHideContainers = true;
		this.sendNavigationRequest(navigationRequest);
	}

	bootstrapModule(primaryContext: string) {
		const navigationRequest = new NavigationRequest(primaryContext, [], primaryContext);
		navigationRequest.showHideContainers = false;
		this.sendNavigationRequest(navigationRequest);
	}

	showLegacy(primaryContext: string, subcontexts?: (string | number)[]) {
		const navigationRequest  = new NavigationRequest(primaryContext, subcontexts, primaryContext);
		this.sendNavigationRequest(navigationRequest);
	}
}
