import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Required property if given condition is satisfied
 */
export function revRequiredValidator(message: string): ValidatorFn {
	return (control: AbstractControl) => {
		if (control.value) {
			return null;
		} else {
			return {revRequired: {revRequired: true, message}, required: true};
		}
	};
}

