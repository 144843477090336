// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeRequest } from './contact-lens-eye-request';

/** See com.rev360.pms.api.controller.prescription.RefillContactLensPrescriptionRequest */
/* istanbul ignore next */
@GandalfModel
export class RefillContactLensPrescriptionRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Authorize Prescription indicator is required' } })
	@GandalfProperty({ isRequired: true })
	authorizePrescription: boolean;

	@GandalfLabel('Contact Lens Prescription')
	@GandalfValidator({ notNull: { message: 'Contact Lens Prescription is required' } })
	@GandalfProperty({ isRequired: true })
	contactLensPrescriptionId: number;

	@GandalfLabel('Delivery Method')
	@GandalfProperty()
	deliveryMethodReferenceId: number;

	@GandalfLabel('Comments')
	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	description: string;

	@GandalfLabel('Prescribed Lens Drop')
	@GandalfValidator({ sizeString: { message: 'Prescribed Lens Drop cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	dropsName: string;

	@GandalfLabel('Encounter')
	@GandalfProperty()
	encounterId: number;

	@GandalfLabel('Prescribed Enzymatic Cleaner')
	@GandalfValidator({ sizeString: { message: 'Prescribed Enzymatic Cleaner cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	enzymaticCleanerName: string;

	@GandalfLabel('Expiration Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	expirationDate: Date;

	@GandalfLabel('Prescribed Lens Cleaner')
	@GandalfValidator({ sizeString: { message: 'Prescribed Lens Cleaner cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	lensCleanerName: string;

	@GandalfConstantDecorator()
	@GandalfLabel('Modality')
	@GandalfValidator({ notNull: { message: 'Modality is required' } })
	@GandalfProperty({ isRequired: true })
	modality: constants.ContactLensModality;

	@GandalfSubRequestLabel('OD')
	@GandalfLabel('OD')
	@GandalfProperty()
	od: ContactLensEyeRequest;

	@GandalfLabel('OD Dispense Amount')
	@GandalfProperty()
	odDispenseAmount: number;

	@GandalfLabel('OD Dispense Unit')
	@GandalfProperty()
	odDispenseUnitId: number;

	@GandalfSubRequestLabel('OS')
	@GandalfLabel('OS')
	@GandalfProperty()
	os: ContactLensEyeRequest;

	@GandalfLabel('OS Dispense Amount')
	@GandalfProperty()
	osDispenseAmount: number;

	@GandalfLabel('OS Dispense Unit')
	@GandalfProperty()
	osDispenseUnitId: number;

	@GandalfConstantDecorator()
	@GandalfLabel('Lens Type')
	@GandalfValidator({ notNull: { message: 'Lens Type is required' } })
	@GandalfProperty({ isRequired: true })
	overallLensType: constants.ContactLensPrescriptionType;

	@GandalfLabel('Prescribed Disinfection Solution')
	@GandalfValidator({ sizeString: { message: 'Prescribed Disinfection Solution cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	solutionName: string;

	@GandalfLabel('Start Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate: Date;

	@GandalfLabel('Use Signature')
	@GandalfValidator({ notNull: { message: 'Use Signature is required' } })
	@GandalfProperty({ isRequired: true })
	useSignature: boolean;

}
