import { setMetadataPropertyValue } from '../metadata/gandalf-metadata-util';

/**
 * Metadata key indicating a property's Gandalf label
 */
export const GANDALF_LABEL_METADATA_KEY = Symbol('GandalfLabel');

/**
 * Decorator that identifies the label metadata for a Gandalf Model's property.
 *
 * This value is stored in Gandalf metadata and can be accessed via:
 * modelInstance.$label.propertyName
 */
export function GandalfLabel(labelString: string) {
	return (target: object, propertyKey: string) => {
		if (labelString !== undefined && labelString !== '') {
			setMetadataPropertyValue(target, GANDALF_LABEL_METADATA_KEY, propertyKey, labelString);
		}
	};
}
