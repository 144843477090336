// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeRequest } from './contact-lens-eye-request';

/** See com.rev360.pms.api.controller.encounter.history.contactlens.UpdateContactLensHistoryRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateContactLensHistoryRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	comments: string;

	@GandalfProperty()
	continuousWearPeriodAmount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	continuousWearPeriodUnit: constants.DurationUnit;

	@GandalfProperty()
	dailyWearingTimeAmount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	dailyWearingTimeUnit: constants.DurationUnit;

	@GandalfValidator({ sizeString: { message: 'Drops Used cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	dropsUsed: string;

	@GandalfValidator({ notNull: { message: 'History is required' } })
	@GandalfProperty({ isRequired: true })
	id: number;

	@GandalfProperty()
	lensAgeAmount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	lensAgeUnit: constants.DurationUnit;

	@GandalfProperty()
	od: ContactLensEyeRequest;

	@GandalfProperty()
	os: ContactLensEyeRequest;

	@GandalfValidator({ sizeString: { message: 'Other Supplies cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	otherSupplies: string;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Lens Type is required' } })
	@GandalfProperty({ isRequired: true })
	overallLensType: constants.ContactLensHistoryType;

	@GandalfProperty()
	replacementScheduleAmount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	replacementScheduleUnit: constants.DurationUnit;

	@GandalfValidator({ sizeString: { message: 'Solution Used cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	solutionUsed: string;

	@GandalfProperty()
	supplyRemainingAmount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	supplyRemainingPacking: constants.ContactLensHistoryPackaging;

	@GandalfProperty()
	todaysWearingTimeAmount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	todaysWearingTimeUnit: constants.DurationUnit;

}
