import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '@core/authentication/authentication.service';
import { _find } from '@core/lodash/lodash';
import { EnsureHttpPipe, ModalBase, PAGE_LENGTH, PAGE_LENGTH_LIST } from 'morgana';
import { UrlService } from '@core/url-util/url.service';
import { FeatureFlagAccess } from '@gandalf/constants';
import { FeatureFlagDetailResponse } from '@gandalf/model/feature-flag-detail-response';
import { FeatureFlagEmployeeSummaryResponse } from '@gandalf/model/feature-flag-employee-summary-response';
import { URL_BASE_PATHS } from '@shared/constants/url.constants';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { PageSettingsModel, RowSelectEventArgs, SortSettingsModel } from '@syncfusion/ej2-grids';
import { ManageUpdatesService } from '../services/manage-updates.service';

@Component({
	selector: 'pms-manage-updates-modal',
	templateUrl: './manage-updates-modal.component.html',
	styles: [],
})
export class ManageUpdatesModalComponent extends ModalBase implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('grid')
	grid: GridComponent;

	featureFlags: FeatureFlagDetailResponse[] = [];
	selectedFeatureFlag: FeatureFlagDetailResponse;

	statusOptions: any[];
	selectedStatus = false;

	sortSettings: SortSettingsModel = {
		columns: [
			{field: 'internalName', direction: 'Ascending'},
		],
	};

	pageSettings: PageSettingsModel = {
		pageSizes: PAGE_LENGTH_LIST,
		pageSize: PAGE_LENGTH.PAGE_10,
	};
	isSearching = false;

	constructor(
		public urlService: UrlService,
		public ensureHttpPipe: EnsureHttpPipe,
		public manageUpdatesService: ManageUpdatesService,
		public authenticationService: AuthenticationService,
	) {
		super();
	}

	ngOnInit() {
		this.statusOptions = [
			{label: 'All', value: null},
			{label: 'On', value: true},
			{label: 'Off', value: false},
		];

		this.loadFeatureFlags();
	}

	loadFeatureFlags() {
		this.isSearching = true;

		this.manageUpdatesService.getFeatureFlags().subscribe(data => {
			this.featureFlags = data;
			this.filterGrid();
			this.isSearching = false;
		});
	}

	filterGrid() {
		if (this.selectedStatus !== null) {
			this.grid.filterByColumn('enabled', 'equal', this.selectedStatus);
		} else {
			this.grid.clearFiltering(['enabled']);
		}
		this.grid.goToPage(1);
		this.deselectFeatureFlag();
	}

	selectFeatureFlag(event: RowSelectEventArgs) {
		this.selectedFeatureFlag = event.data as FeatureFlagDetailResponse;
	}

	deselectFeatureFlag() {
		this.selectedFeatureFlag = null;
	}

	closeModal() {
		this.dynamicModalRef.close(this.modal);
	}

	getImageUrl(): string {
		if (this.selectedFeatureFlag && this.selectedFeatureFlag.imageFileId) {
			return `${URL_BASE_PATHS.PMS2}/featureFlag/serveFile?systemFileId=${this.selectedFeatureFlag.imageFileId}`;
		}
		return '';
	}

	syncFlagsCheckedState(event: any, featureFlagCopy: FeatureFlagDetailResponse, data: FeatureFlagEmployeeSummaryResponse) {
		// the custom column template creates a copy of the feature flag object.
		// So we need to update the original one as well for the grid filtering to work.
		const featureFlag = _find(this.featureFlags, flag => flag.id === featureFlagCopy.id);
		featureFlag.employeeFlag = featureFlagCopy.employeeFlag = data;
		featureFlag.enabled = featureFlagCopy.enabled = event.checked;
	}

	handleSwitchChange(event: any, featureFlagCopy: FeatureFlagDetailResponse) {
		const newFeatureFlagAccess = (event.checked ? FeatureFlagAccess.ON : FeatureFlagAccess.OFF);

		const updatedEmployeeFlag = (featureFlagCopy.employeeFlag ?
			this.manageUpdatesService.updateFeatureFlagEmployeeAccess(featureFlagCopy.employeeFlag.id, newFeatureFlagAccess)
			: this.manageUpdatesService.createFeatureFlagEmployee(featureFlagCopy.id, newFeatureFlagAccess));
		updatedEmployeeFlag.subscribe(data => {
			this.syncFlagsCheckedState(event, featureFlagCopy, data);
		});
	}

	openDocumentationLink(link: string) {
		if (link) {
			this.urlService.goToWebpage(this.ensureHttpPipe.transform(link), '_blank');
		}
	}
}
