// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

/** See com.rev360.pms.api.controller.admin.vendorsandpartners.insurancecompanies.PracticeInsuranceCompanyResponse */
/* istanbul ignore next */
@GandalfModel
export class PracticeInsuranceCompanyResponse extends GandalfModelBase {

	@GandalfProperty()
	abbr: string;

	@GandalfProperty()
	address: AddressResponse;

	@GandalfProperty()
	allowItemDiscounts: boolean;

	@GandalfProperty()
	chargedTax: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	claimFilingCode: constants.ClaimFilingCode;

	@GandalfConstantDecorator()
	@GandalfProperty()
	claimSubmissionMechanism: constants.ClaimSubmitMechanism;

	@GandalfProperty({ propertyType: 'Money' })
	credit: number;

	@GandalfProperty()
	defaultInsuranceTypeReferenceId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	defaultPaymentMethod: constants.PaymentMethodType;

	@GandalfProperty()
	defaultPriorityReferenceId: number;

	@GandalfProperty()
	fax: string;

	@GandalfProperty()
	groupNumber: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	groupNumberQualifier: constants.GroupNumberQualifier;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	includePqrsAlert: boolean;

	@GandalfProperty()
	insuranceClassMasterReferenceId: number;

	@GandalfProperty()
	mainPhone: string;

	@GandalfProperty()
	name: string;

	@GandalfProperty()
	notes: string;

	@GandalfProperty()
	npiOnly: boolean;

	@GandalfProperty()
	participatingPractice: boolean;

	@GandalfProperty()
	payerId: string;

	@GandalfProperty()
	removeMatchingServiceNpi: boolean;

	@GandalfProperty()
	renderingIsReferringProvider: boolean;

	@GandalfProperty()
	transferTax: boolean;

	@GandalfProperty()
	webSite: string;

}
