import { Directive } from '@angular/core';
import { ColumnDirective, GridComponent } from '@syncfusion/ej2-angular-grids';

@Directive({
	/* eslint-disable-next-line @angular-eslint/directive-selector */
	selector: 'e-column:not([doNotAlignRightCurrency])',
})
export class AlignRightCurrencyDirective {

	private formatsToAlignRight = ['C2', 'C3'];

	constructor(
		private column: ColumnDirective,
		private grid: GridComponent,
	) {
		this.grid.created.subscribe(() => {
			this.applyAlignRightCurrency();
		});
	}

	private applyAlignRightCurrency() {
		if (this.formatsToAlignRight.includes(this.column.format)) {
			this.grid.getColumnByField(this.column.field).textAlign = 'Right';
			this.grid.refreshColumns();
		}
	}
}
