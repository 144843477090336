import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { HIT_PMS_HTML_EVENTS } from '@core/legacy/hit-pms-html.constants';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { UnlockSessionRequest } from '@gandalf/model/unlock-session-request';
import { EmployeeSecurityGandalfService } from '@gandalf/services';
import { BehaviorSubject } from 'rxjs';
import { ObservableUtils } from 'morgana';

@Injectable({
	providedIn: 'root',
})
export class LockManagerService {

	lastVisitedRoute: string;
	isLocked: BehaviorSubject<boolean> = new BehaviorSubject(false);
	locked = ObservableUtils.behaviorSubjectChange(this.isLocked, true);
	unlocked = ObservableUtils.behaviorSubjectChange(this.isLocked, false);

	constructor(
		private employeeSecurityService: EmployeeSecurityGandalfService,
		private securityManager: SecurityManagerService,
		private eventsManagerService: EventsManagerService,
		private router: Router,
		private zone: NgZone,
	) {
	}

	subscribeToLockEvent() {
		this.eventsManagerService.subscribe(HIT_PMS_HTML_EVENTS.SECURITY.SECURITY_SESSION_LOCKED, () => this.onLock(), this);
		this.eventsManagerService.subscribe(HIT_PMS_HTML_EVENTS.SECURITY.SECURITY_SESSION_UNLOCKED, () => this.onUnlock(), this);
	}

	onLock() {
		if (this.securityManager.checkLogin()) {
			this.isLocked.next(true);
			this.lastVisitedRoute = this.router.url;
			this.employeeSecurityService.lockEmployeeSession()
				.subscribe(() => this.zone.run(() => this.router.navigate(['lock'])));
		}
	}

	onUnlock() {
		if (this.securityManager.checkLogin()) {
			const request = new UnlockSessionRequest();
			request.userSessionId = this.securityManager.getUserSession().id;
			request.httpSessionId = this.securityManager.getUserSession().httpSessionId;
			this.employeeSecurityService.unlockEmployeeSession(request).subscribe(() =>
				this.zone.run(() => {
					this.isLocked.next(false);
					return this.router.navigateByUrl(this.lastVisitedRoute);
				}),
			);
		}
	}
}
