import { Component, ViewChild } from '@angular/core';
import { DynamicModalRef } from 'morgana';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
	selector: 'pms-void-insurance-refund-modal',
	templateUrl: './refund-history-comments-modal.component.html',
	styles: [],
})
export class VoidInsuranceRefundModalComponent {

	@ViewChild('modal') modal: DialogComponent;

	comments = '';

	constructor(
		private ref: DynamicModalRef,
	) { }

	closeModal(activateVoid = false) {
		this.ref.close(this.modal, { activateVoid, comments: this.comments });
	}
}
