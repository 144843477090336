// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.healthconcern.PatientHealthConcernResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientHealthConcernResponse extends GandalfModelBase {

	@GandalfProperty()
	concern: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dateReported: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	inactiveDate: Date;

	@GandalfProperty()
	patientHealthConcernId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	reporter: constants.PatientHealthConcernReporter;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.PatientHealthConcernStatus;

	@GandalfConstantDecorator()
	@GandalfProperty()
	type: constants.PatientHealthConcernType;

}
