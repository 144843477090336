import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'navIcon',
})
export class NavIconPipe implements PipeTransform {

	transform(value: any): any {
		if (!value) {
			return undefined;
		}

		const icons = {
			patients: 'fa-users',
			schedule: 'fa-calendar',
			accounting: 'fa-money',
			orders: 'fa-shopping-cart',
			tasks: 'fa-check-square-o',
			messaging: 'fa-envelope',
			marketing: 'fa-bar-chart',
			inventory: 'fa-cubes',
			products: 'fa-cube',
			vsp: 'fa-cube',
			services: 'fa-list-alt',
			transfers: 'fa-exchange',
			reconciliation: 'fa-check-square',
			connect: 'fa-comment',
			admin: 'fa-gears',
		};

		return icons[value];
	}

}
