// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeResponse } from './contact-lens-eye-response';

// @ts-ignore
import { DurationResponse } from './duration-response';

// @ts-ignore
import { MasterReferenceDataResponse } from './master-reference-data-response';

// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.prescription.ContactLensEyeRgpResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensEyeRgpResponse extends ContactLensEyeResponse {

	@GandalfProperty()
	addDiameter: number;

	@GandalfProperty()
	addPower: number;

	@GandalfProperty()
	axis: number;

	@GandalfProperty()
	backOpticalDiameter: number;

	@GandalfProperty()
	baseCurve: number;

	@GandalfProperty()
	baseCurve2: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	baseCurveUnit: constants.BaseCurveUnit;

	@GandalfProperty()
	centerThickness: number;

	@GandalfProperty()
	cylinder: number;

	@GandalfProperty()
	diameter: number;

	@GandalfProperty()
	distanceZone: number;

	@GandalfProperty()
	dot: boolean;

	@GandalfProperty()
	edgeLift: MasterReferenceDataResponse;

	@GandalfProperty()
	edgeLiftAmount: number;

	@GandalfProperty()
	fenestration: boolean;

	@GandalfProperty()
	firstCurveRadius: number;

	@GandalfProperty()
	firstCurveWidth: number;

	@GandalfProperty()
	fourthCurveRadius: number;

	@GandalfProperty()
	fourthCurveWidth: number;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	intermediateSegment: number;

	@GandalfProperty()
	isMonovision: boolean;

	@GandalfProperty()
	landingZoneAngle: number;

	@GandalfProperty()
	lensMaterial: ReferenceDataResponse;

	@GandalfProperty()
	limbalClearance: MasterReferenceDataResponse;

	@GandalfProperty()
	locationProductId: number;

	@GandalfProperty()
	locationProductItemId: number;

	@GandalfProperty()
	locationProductName: string;

	@GandalfProperty()
	notes: string;

	@GandalfProperty()
	opticZone: number;

	@GandalfProperty()
	overnightSchedule: DurationResponse;

	@GandalfProperty()
	peripheralCurveRadius: number;

	@GandalfProperty()
	peripheralCurveWidth: number;

	@GandalfProperty()
	peripheralEdge: MasterReferenceDataResponse;

	@GandalfProperty()
	plasmaTreatment: boolean;

	@GandalfProperty()
	productColorId: number;

	@GandalfProperty()
	productColorName: string;

	@GandalfProperty()
	profile: MasterReferenceDataResponse;

	@GandalfProperty()
	replacementSchedule: DurationResponse;

	@GandalfProperty()
	returnZoneDiameter: number;

	@GandalfProperty()
	sagittalDepth: number;

	@GandalfProperty()
	secondCurveRadius: number;

	@GandalfProperty()
	secondCurveWidth: number;

	@GandalfProperty()
	secondaryCurveRadius: number;

	@GandalfProperty()
	secondaryCurveWidth: number;

	@GandalfProperty()
	segmentHeight: number;

	@GandalfProperty()
	specialCharacteristics: MasterReferenceDataResponse;

	@GandalfProperty()
	sphere: number;

	@GandalfProperty()
	sphere2: number;

	@GandalfProperty()
	thirdCurveRadius: number;

	@GandalfProperty()
	thirdCurveWidth: number;

	@GandalfProperty()
	tint: string;

	@GandalfProperty()
	truncation: number;

	@GandalfProperty()
	version: number;

}
