// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { EncounterTableResponse } from './encounter-table-response';

// @ts-ignore
import { EyeglassLensMeasurementResponse } from './eyeglass-lens-measurement-response';

// @ts-ignore
import { EyeglassLensOrderDetailResponse } from './eyeglass-lens-order-detail-response';

// @ts-ignore
import { FrameColorResponse } from './frame-color-response';

// @ts-ignore
import { FrameSizeResponse } from './frame-size-response';

// @ts-ignore
import { LocationProductForOrderResponse } from './location-product-for-order-response';

// @ts-ignore
import { LocationProductItemForOrderResponse } from './location-product-item-for-order-response';

// @ts-ignore
import { OrderEyeglassPrescriptionResponse } from './order-eyeglass-prescription-response';

// @ts-ignore
import { OrderProcessorConfigResponse } from './order-processor-config-response';

// @ts-ignore
import { OrderProductOptionResponse } from './order-product-option-response';

// @ts-ignore
import { OrderResponse } from './order-response';

// @ts-ignore
import { OrderTwelve84Response } from './order-twelve84-response';

// @ts-ignore
import { OrderVendorResponse } from './order-vendor-response';

// @ts-ignore
import { PatientCommunicationResponse } from './patient-communication-response';

// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.order.EyeglassOrderResponse */
/* istanbul ignore next */
@GandalfModel
export class EyeglassOrderResponse extends OrderResponse {

	@GandalfProperty()
	arCoating: OrderProductOptionResponse;

	@GandalfProperty()
	arCoatingSide: string;

	@GandalfProperty()
	comment: string;

	@GandalfProperty()
	createdOn: Date;

	@GandalfProperty()
	density: number;

	@GandalfProperty()
	distancePd: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dueDate: Date;

	@GandalfProperty()
	edgeTreatment: OrderProductOptionResponse;

	@GandalfProperty()
	encounter: EncounterTableResponse;

	@GandalfProperty({ propertyType: 'Money' })
	estimatedDiscounts: number;

	@GandalfProperty({ propertyType: 'Money' })
	estimatedInsuranceBenefit: number;

	@GandalfProperty({ propertyType: 'Money' })
	estimatedTaxes: number;

	@GandalfProperty()
	externalFrameSupplierIdentifier: string;

	@GandalfProperty()
	externalFrameSupplierName: string;

	@GandalfProperty()
	externalSupplierIdentifier: string;

	@GandalfProperty()
	externalSupplierName: string;

	@GandalfProperty()
	frameColor: FrameColorResponse;

	@GandalfProperty({ propertyType: 'Money' })
	frameCost: number;

	@GandalfProperty({ propertyType: 'Money' })
	frameRemakeCost: number;

	@GandalfProperty()
	frameSize: FrameSizeResponse;

	@GandalfProperty()
	instructions: string;

	@GandalfProperty({ propertyType: 'Money' })
	lensCost: number;

	@GandalfProperty({ propertyType: 'Money' })
	lensRemakeCost: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	lensRequest: constants.OrderLensRequest;

	@GandalfProperty()
	locationId: number;

	@GandalfProperty()
	locationName: string;

	@GandalfProperty()
	material: OrderProductOptionResponse;

	@GandalfProperty()
	mirrorCoating: OrderProductOptionResponse;

	@GandalfProperty()
	mountingType: OrderProductOptionResponse;

	@GandalfProperty()
	nearPd: number;

	@GandalfProperty()
	notificationComments: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	notificationDate: Date;

	@GandalfProperty()
	odDetail: EyeglassLensOrderDetailResponse;

	@GandalfProperty()
	odLensMeasurements: EyeglassLensMeasurementResponse;

	@GandalfProperty()
	onHold: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	opticalCenterType: constants.OrderOpticalCenterType;

	@GandalfConstantDecorator()
	@GandalfProperty()
	orderFrameIndication: constants.OrderFrameIndication;

	@GandalfProperty()
	orderFrameProduct: LocationProductForOrderResponse;

	@GandalfProperty()
	orderFrameProductItem: LocationProductItemForOrderResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	orderFrameSource: constants.OrderFrameSource;

	@GandalfProperty()
	orderId: number;

	@GandalfProperty()
	originalOrderId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	originalOrderReason: constants.OriginalOrderReason;

	@GandalfProperty()
	osDetail: EyeglassLensOrderDetailResponse;

	@GandalfProperty()
	osLensMeasurements: EyeglassLensMeasurementResponse;

	@GandalfProperty()
	otherCoating: OrderProductOptionResponse;

	@GandalfProperty({ propertyType: 'Money' })
	otherCost: number;

	@GandalfProperty({ propertyType: 'Money' })
	otherRemakeCost: number;

	@GandalfProperty()
	pantoscopicTilt: number;

	@GandalfProperty()
	patient: PatientNameResponse;

	@GandalfProperty()
	patientCommunication: PatientCommunicationResponse;

	@GandalfProperty()
	patientNotified: boolean;

	@GandalfProperty()
	personId: number;

	@GandalfProperty()
	photochromic: OrderProductOptionResponse;

	@GandalfProperty()
	polarized: OrderProductOptionResponse;

	@GandalfProperty()
	prescription: OrderEyeglassPrescriptionResponse;

	@GandalfProperty()
	processor: OrderProcessorConfigResponse;

	@GandalfProperty()
	quantity: number;

	@GandalfProperty()
	scratchCoating: OrderProductOptionResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	segHeightType: constants.OrderSegHeightType;

	@GandalfProperty()
	shade: string;

	@GandalfProperty()
	shipAddress: AddressResponse;

	@GandalfProperty({ propertyType: 'Money' })
	shipCost: number;

	@GandalfProperty({ propertyType: 'Money' })
	shipRemakeCost: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	shipToType: constants.OrderShipToType;

	@GandalfConstantDecorator()
	@GandalfProperty()
	shippingCompany: constants.OrderShipmentCompany;

	@GandalfProperty()
	smartflowOrderIdentifier: number;

	@GandalfProperty()
	specialOption: OrderProductOptionResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	statusCode: constants.OrderStatusCode;

	@GandalfProperty()
	statusDate: Date;

	@GandalfProperty()
	thickness: OrderProductOptionResponse;

	@GandalfProperty()
	tintFactor: OrderProductOptionResponse;

	@GandalfProperty()
	tintType: OrderProductOptionResponse;

	@GandalfProperty()
	trackingNumber: string;

	@GandalfProperty()
	tray: string;

	@GandalfProperty()
	twelve84Response: OrderTwelve84Response;

	@GandalfConstantDecorator()
	@GandalfProperty()
	type: constants.OrderType;

	@GandalfProperty()
	uvTreatment: OrderProductOptionResponse;

	@GandalfProperty()
	vendor: OrderVendorResponse;

	@GandalfProperty()
	vendorOrderIdentifier: string;

	@GandalfProperty()
	version: number;

	@GandalfProperty()
	wrapAngle: number;

}
