import { Component, Input } from '@angular/core';

@Component({
	selector: 'rev-loading-overlay',
	templateUrl: './loading-overlay.component.html',
	styles: [],
})
export class LoadingOverlayComponent {

	@Input()
	loading: boolean;

	constructor() {
	}
}
