import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ValidationMessagesService } from './validation-messages.service';

@Component({
	selector: 'gandalf-lib-validation-messages',
	templateUrl: './validation-messages.component.html',
	styles: [],
})
export class ValidationMessagesComponent implements OnInit, OnDestroy {

	@Input()
	form: UntypedFormGroup;

	@Input()
	requestObj: any;

	@Input()
	validateSubrequests = true;

	@Input()
	validateSubArrays = true;

	private unsubscribe$ = new Subject<void>();

	errorList: any[];

	constructor(private validationMessagesService: ValidationMessagesService) {
	}

	ngOnInit() {
		this.displayValidationMessages();

		this.form.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
			this.displayValidationMessages();
		});
	}

	displayValidationMessages() {
		this.errorList = this.validationMessagesService.getAllErrorMessages(
			this.form,
			this.requestObj,
			this.validateSubrequests,
			this.validateSubArrays,
		);
	}

	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
