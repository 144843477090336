// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { SecurityRoleResponse } from './security-role-response';

/** See com.rev360.pms.api.controller.employee.EmployeeWithSecurityRolesResponse */
/* istanbul ignore next */
@GandalfModel
export class EmployeeWithSecurityRolesResponse extends GandalfModelBase {

	@GandalfProperty()
	employeeId: number;

	@GandalfProperty()
	firstName: string;

	@GandalfProperty()
	lastName: string;

	@GandalfProperty()
	personId: number;

	@GandalfArray(SecurityRoleResponse)
	securityRoles: SecurityRoleResponse[];

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.EmployeeStatus;

}
