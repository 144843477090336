<h4 class="section-title bg-default mrgn-btm">Other Information</h4>
@if (eyeglassPrescription) {
	<div>
		<div class="form-group" [attr.data-test-id]="'eyeglassUsedForSection'">
			<label class="col-sm-5 control-label padding-x-0">Used For</label>
			<div class="col-sm-7">
				<p class="margin-0">{{ eyeglassPrescription.usedFor?.value }}</p>
			</div>
		</div>
		@if (isRxMonoPdFeatureFlagOn) {
			<div [attr.data-test-id]="'otherInformationMonocularPdSection'">
				<hr>
				<div class="form-group">
					<div class="col-sm-5 control-label padding-x-0">
						<h5 class="margin-0"><span class="label label-success">OD</span></h5>
					</div>
					<div class="col-sm-7"></div>
				</div>
				<div class="form-group" [attr.data-test-id]="'odMonocularPdDistance'">
					<label class="col-sm-5 control-label padding-x-0">MPD-D</label>
					<div class="col-sm-7">
						<p class="margin-0">{{ eyeglassPrescription.od.monocularPdDistance }}</p>
					</div>
				</div>
				<div class="form-group" [attr.data-test-id]="'odMonocularPdNear'">
					<label class="col-sm-5 control-label padding-x-0">MPD-N</label>
					<div class="col-sm-7">
						<p class="margin-0">{{ eyeglassPrescription.od.monocularPdNear }}</p>
					</div>
				</div>
				<div class="form-group">
					<div class="col-sm-5 control-label padding-x-0">
						<h5 class="margin-0"><span class="label label-info">OS</span></h5>
					</div>
					<div class="col-sm-7"></div>
				</div>
				<div class="form-group" [attr.data-test-id]="'osMonocularPdDistance'">
					<label class="col-sm-5 control-label padding-x-0">MPD-D</label>
					<div class="col-sm-7">
						<p class="margin-0">{{ eyeglassPrescription.os.monocularPdDistance }}</p>
					</div>
				</div>
				<div class="form-group" [attr.data-test-id]="'osMonocularPdNear'">
					<label class="col-sm-5 control-label padding-x-0">MPD-N</label>
					<div class="col-sm-7">
						<p class="margin-0">{{ eyeglassPrescription.os.monocularPdNear }}</p>
					</div>
				</div>
				<hr>
			</div>
		}
		<div class="form-group" [attr.data-test-id]="'distancePdFormGroup'">
			<label class="col-sm-5 control-label padding-x-0">Distance PD</label>
			<div class="col-sm-7">
				<p class="margin-0">{{ eyeglassPrescription.distancePd }}</p>
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'nearPdFormGroup'">
			<label class="col-sm-5 control-label padding-x-0">Near PD</label>
			<div class="col-sm-7">
				<p class="margin-0">{{ eyeglassPrescription.nearPd }}</p>
			</div>
		</div>
	</div>
} @else {
	No Data
}
