import { Component, OnInit, ViewChild } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { CategoryEntityType, RelatedEntityType } from '@gandalf/constants';
import { AssignRelatedCategoriesRequest } from '@gandalf/model/assign-related-categories-request';
import { CategoryResponse } from '@gandalf/model/category-response';
import { CreateRelatedCategoryRequest } from '@gandalf/model/create-related-category-request';
import { UpdateRelatedCategoryRequest } from '@gandalf/model/update-related-category-request';
import { AssignCategoriesService } from '@shared/component/assign-categories-modal/assign-categories.service';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { combineLatest } from 'rxjs';

export interface CategoryWithSelectedSubCategory extends CategoryResponse {
	selectedSubCategoryId?: number;
	relatedCategoryId?: number;
}

@Component({
	selector: 'pms-assign-accounting-categories-modal',
	templateUrl: './assign-categories-modal.component.html',
})
export class AssignCategoriesModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	categoryType: CategoryEntityType;
	relatedEntity: RelatedEntityType;
	parentId: number;
	categories: CategoryWithSelectedSubCategory[];

	constructor(
		private dynamicModalRef: DynamicModalRef,
		private modalConfig: ModalConfig,
		private assignCategoriesService: AssignCategoriesService,
	) {
	}

	ngOnInit(): void {
		this.parseModalConfig(this.modalConfig);
		this.getAccountingCategories();
	}

	getAccountingCategories() {
		combineLatest([
			this.assignCategoriesService.findActiveByEntityType(this.categoryType),
			this.assignCategoriesService.findRelatedCategoriesForEntity(this.parentId, this.relatedEntity),
		]).subscribe(([categories, relatedCategories]) => {
			this.categories = categories;
			relatedCategories.forEach(relatedCategory => {
				const foundCategory = this.categories.find(category => relatedCategory.categoryId === category.id);
				if (!_isNil(foundCategory)) {
					foundCategory.selectedSubCategoryId = relatedCategory.valueId;
					foundCategory.relatedCategoryId = relatedCategory.relatedCategoryId;
				}
			});
		});
	}

	parseModalConfig(modalConfig: ModalConfig) {
		this.categoryType = modalConfig.data.categoryType;
		this.relatedEntity = modalConfig.data.relatedEntity;
		this.parentId = modalConfig.data.parentId;
	}

	onSelectedSubCategoryIdUpdate(categoryId: number, newValue: number) {
		this.categories.find(category => category.id === categoryId).selectedSubCategoryId = newValue;
	}

	/* istanbul ignore next: closeModal */
	closeModal() {
		this.dynamicModalRef.close(this.modal);
	}

	save() {
		const request = new AssignRelatedCategoriesRequest();
		request.parentId = this.parentId;
		request.relatedEntityType = this.relatedEntity;
		request.createRelatedCategoryRequests = [];
		request.updateRelatedCategoryRequests = [];
		this.categories.forEach(category => {
			if (!_isNil(category.relatedCategoryId)) {
				const updateRequest = new UpdateRelatedCategoryRequest();
				updateRequest.relatedCategoryId = category.relatedCategoryId;
				updateRequest.valueCategoryId = category.selectedSubCategoryId;
				request.updateRelatedCategoryRequests.push(updateRequest);
			} else if (!_isNil(category.selectedSubCategoryId)) {
				const createRequest = new CreateRelatedCategoryRequest();
				createRequest.categoryId = category.id;
				createRequest.valueCategoryId = category.selectedSubCategoryId;
				request.createRelatedCategoryRequests.push(createRequest);
			}
		});
		this.assignCategoriesService.assignRelatedCategories(request).subscribe(() => {
			this.closeModal();
		});
	}

}
