import { Component, OnInit, ViewChild } from '@angular/core';
import { MODAL, ModalConfig } from 'morgana';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
	selector: 'pms-loading-modal',
	templateUrl: './loading-modal.component.html',
	styles: [],
})
export class LoadingModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	zIndex = MODAL.Z_INDEX_STANDARD;
	title: string;
	message: string;

	constructor(
		public modalConfig: ModalConfig,
	) {
	}

	ngOnInit(): void {
		this.parseConfigData(this.modalConfig.data);
	}

	parseConfigData(data) {
		this.title = data.title;
		this.message = data.message;
	}
}
