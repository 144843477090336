import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'intToHexColor',
})
@Injectable({
	providedIn: 'root',
})
export class IntToHexColorPipe implements PipeTransform {

	transform(value: number): any {
		if (value === null || value === undefined) {
			return value;
		}
		return '#' + value.toString(16).padStart(6, '0').toUpperCase();
	}

}
