import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { EmployeeDropdownResponse, EmployeeService } from '@core/employee/employee.service';
import { DynamicModalRef, EnumUtil, ModalConfig } from 'morgana';
import { _findIndex } from '@core/lodash/lodash';
import { InvoiceItemResponse } from '@gandalf/model/invoice-item-response';
import { UpdateInvoiceItemResponsiblePersonListRequest } from '@gandalf/model/update-invoice-item-responsible-person-list-request';
import { UpdateInvoiceItemResponsiblePersonRequest } from '@gandalf/model/update-invoice-item-responsible-person-request';
import { InvoiceItemStatus } from '@gandalf/constants';
import { InvoiceResponse } from '@gandalf/model/invoice-response';
import { GridComponent, SortSettingsModel } from '@syncfusion/ej2-angular-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';
import { combineLatest } from 'rxjs';
import { AccountingService } from '../../core/accounting/accounting.service';
import { InvoiceService } from '../../core/accounting/invoice-service/invoice.service';

@Component({
	selector: 'pms-assign-employees-modal',
	templateUrl: './assign-employees-modal.component.html',
	styles: [],
})
export class AssignEmployeesModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('grid')
	grid: GridComponent;

	@ViewChild('templateForm')
	templateForm: NgForm;

	componentForm: UntypedFormGroup;
	invoiceItems: InvoiceItemResponse[];
	employeeListByPersonId: EmployeeDropdownResponse[];
	request: UpdateInvoiceItemResponsiblePersonListRequest;
	invoiceId: number;
	isSaving = false;
	sortOptions: SortSettingsModel;

	constructor(
		public ref: DynamicModalRef,
		private modalConfig: ModalConfig,
		private accountingService: AccountingService,
		private employeeService: EmployeeService,
		private gandalfFormBuilder: GandalfFormBuilder,
		public invoiceService: InvoiceService,
	) {
	}

	ngOnInit() {
		this.sortOptions = {
			columns: [
				{field: 'code', direction: 'Ascending'},
				{field: 'id', direction: 'Ascending'},
			],
		};
		this.invoiceId = this.modalConfig.data.invoiceId;
		combineLatest([
			this.accountingService.getInvoiceById(this.invoiceId),
			this.employeeService.findActiveEmployeesWithPersonIdForDropdown(),
		]).subscribe(([invoice, employees]) => {
			this.initializeForm(invoice);
			this.employeeListByPersonId = employees;
		});
	}


	getInvoiceItemControl(field: string, id: number) {
		const index = _findIndex(this.invoiceItems, (item) => item.id === id);
		return this.componentForm.get('invoiceItems').get(index.toString()).get(field);
	}

	closeModal() {
		this.ref.close(this.modal);
	}

	submitForm() {
		if (this.componentForm.invalid) {
			return;
		}
		this.accountingService.updateInvoiceItemResponsiblePersonList(this.componentForm.value).subscribe(() => {
			this.invoiceService.refreshInvoice(this.invoiceId);
			this.closeModal();
		});
	}

	prepareInitialRequest(): UpdateInvoiceItemResponsiblePersonListRequest {
		const request: UpdateInvoiceItemResponsiblePersonListRequest = new UpdateInvoiceItemResponsiblePersonListRequest();
		request.invoiceId = this.invoiceId;
		request.invoiceItems = this.invoiceItems.map((item) => {
			const requestItem = new UpdateInvoiceItemResponsiblePersonRequest();
			requestItem.invoiceItemId = item.id;
			requestItem.responsiblePersonId = item.responsiblePersonId;
			return requestItem;
		});
		return request;
	}

	initializeForm(invoice: InvoiceResponse) {
		this.invoiceItems = invoice.items.filter((item) => EnumUtil.equals(item.status, InvoiceItemStatus.ACTIVE));
		this.request = this.prepareInitialRequest();
		this.componentForm = this.gandalfFormBuilder.group(this.request);
	}
}
