import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import { EnumUtil, ModalManagerService, PAGE_LENGTH, PAGE_LENGTH_LIST } from 'morgana';
import { _remove } from '@core/lodash/lodash';
import { QueryTriggerSearchResult, QueryTriggerService } from '@core/query-trigger/query-trigger.service';
import { CarePlanItemCategory } from '@gandalf/constants';
import { QueryTriggerCarePlanItemTemplateResponse } from '@gandalf/model/query-trigger-care-plan-item-template-response';
import { QueryTriggerCarePlanTemplateResponse } from '@gandalf/model/query-trigger-care-plan-template-response';
import { QueryTriggerResponse } from '@gandalf/model/query-trigger-response';
import { CarePlanTemplateModalComponent } from '@shared/component/care-plan-template-modal/care-plan-template-modal.component';
import { TabAnimationDefaults } from '@shared/constants/tab.constants';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { Query } from '@syncfusion/ej2-data';
import { PageSettingsModel, RowSelectingEventArgs, SortSettingsModel } from '@syncfusion/ej2-grids';
import { combineLatest } from 'rxjs';

@Component({
	selector: 'pms-query-trigger-search-results',
	templateUrl: './query-trigger-search-results.component.html',
})
export class QueryTriggerSearchResultsComponent implements OnInit {

	@Input()
	trigger: QueryTriggerResponse;

	@ViewChild('searchResultGrid')
	searchResultGrid: GridComponent;

	@ViewChild('carePlanTemplateGrid')
	carePlanTemplateGrid: GridComponent;

	@ViewChild('carePlanItemTemplateGrid')
	carePlanItemTemplateGrid: GridComponent;

	formGroup: UntypedFormGroup;
	searchResults: QueryTriggerSearchResult[];
	carePlanTemplates: QueryTriggerCarePlanTemplateResponse[];
	carePlanItemTemplates: QueryTriggerCarePlanItemTemplateResponse[];
	carePlanItemCategories = EnumUtil.buildGandalfEnumSubsetList(EnumUtil.removeValues(CarePlanItemCategory.VALUES.values, [CarePlanItemCategory.CQM]), 'Type');
	carePlanItemTemplateCategory: CarePlanItemCategory;
	pageSettings: PageSettingsModel = {
		pageSize: PAGE_LENGTH.PAGE_10,
		pageSizes: PAGE_LENGTH_LIST,
	};
	searchResultSortSettings: SortSettingsModel = {
		columns: [
			{field: 'type', direction: 'Ascending'},
			{field: 'description', direction: 'Ascending'},
		],
	};
	templateSortSettings: SortSettingsModel = {
		columns: [
			{field: 'name', direction: 'Ascending'},
		],
	};
	itemTemplateSortSettings: SortSettingsModel = {
		columns: [
			{field: 'category.label', direction: 'Ascending'},
			{field: 'description', direction: 'Ascending'},
		],
	};
	tabAnimation = TabAnimationDefaults;
	carePlanTemplateQuery: Query;
	carePlanItemTemplateQuery: Query;
	isLoading: boolean;

	constructor(
		private queryTriggerService: QueryTriggerService,
		private controlContainer: ControlContainer,
		public changeDetectorRef: ChangeDetectorRef,
		public modalManagerService: ModalManagerService,
	) {
	}

	ngOnInit() {
		this.formGroup = this.controlContainer.control as UntypedFormGroup;
		this.loadData();
		// do not refresh grid since they have not been initialize yet
		this.updateTrigger(this.trigger, false);
	}

	loadData() {
		this.isLoading = true;
		combineLatest([
			this.queryTriggerService.findCarePlanTemplates(),
			this.queryTriggerService.findCarePlanItemTemplates(),
		]).subscribe(([carePlanTemplates, carePlanItemTemplates]) => {
			this.isLoading = false;
			this.carePlanTemplates = carePlanTemplates;
			this.carePlanItemTemplates = carePlanItemTemplates;
		});
	}

	updateTrigger(trigger: QueryTriggerResponse, refreshGrids = true) {
		this.trigger = trigger;
		this.searchResults = trigger ? this.queryTriggerService.buildQueryTriggerSearchResults(trigger) : [];
		this.updateCarePlans(refreshGrids);
	}

	addCarePlanTemplate(template: QueryTriggerCarePlanTemplateResponse) {
		this.addSearchResult(this.queryTriggerService.buildCarePlanTemplateSearchResult(template));
	}

	addCarePlanItemTemplate(itemTemplate: QueryTriggerCarePlanItemTemplateResponse) {
		this.addSearchResult(this.queryTriggerService.buildCarePlanItemTemplateSearchResult(itemTemplate));
	}

	addSearchResult(searchResult: QueryTriggerSearchResult) {
		if (!this.searchResults.find(result => result.uid === searchResult.uid)) {
			this.searchResults.push(searchResult);
			this.searchResults = this.queryTriggerService.sortSearchResults(this.searchResults);
		}
		this.updateCarePlans(true);
	}

	removeSearchResult(searchResult: QueryTriggerSearchResult) {
		_remove(this.searchResults, result => result.uid === searchResult.uid);
		this.updateCarePlans(true);
	}

	updateCarePlans(refreshGrids: boolean) {
		this.updateCarePlanTemplates();
		this.updateCarePlanItemTemplates();
		if (refreshGrids) {
			this.refreshGrids();
		}
	}

	updateCarePlanTemplates() {
		// update selected care plan template IDs
		this.formGroup.controls.carePlanTemplateIds.setValue(
			this.searchResults.filter(searchResult => searchResult.carePlanTemplate)
				.map(searchResult => searchResult.carePlanTemplate.id),
		);

		// hide selected care plan templates
		this.carePlanTemplateQuery = new Query();
		this.formGroup.controls.carePlanTemplateIds.value
			.forEach(carePlanTemplateId => this.carePlanTemplateQuery.where('id', 'notequal', carePlanTemplateId));
	}

	updateCarePlanItemTemplates() {
		// update selected care plan item template IDs
		this.formGroup.controls.carePlanItemTemplateIds.setValue(
			this.searchResults.filter(searchResult => searchResult.carePlanItemTemplate)
				.map(searchResult => searchResult.carePlanItemTemplate.id),
		);

		// hide selected care plan item templates
		this.carePlanItemTemplateQuery = new Query();
		this.formGroup.controls.carePlanItemTemplateIds.value
			.forEach(carePlanItemTemplateId => this.carePlanItemTemplateQuery.where('id', 'notequal', carePlanItemTemplateId));
	}

	refreshGrids() {
		// grid refresh won't pick up query changes unless we detectChanges first
		this.changeDetectorRef.detectChanges();
		if (this.searchResultGrid) {
			this.searchResultGrid.refresh();
		}
		if (this.carePlanTemplateGrid) {
			this.carePlanTemplateGrid.refresh();
		}
		if (this.carePlanItemTemplateGrid) {
			this.carePlanItemTemplateGrid.refresh();
		}
	}

	filterCarePlanItemTemplates() {
		if (this.carePlanItemTemplateCategory) {
			this.carePlanItemTemplateGrid.filterByColumn('category.label', 'equal', this.carePlanItemTemplateCategory.label);
		} else {
			this.carePlanItemTemplateGrid.clearFiltering(['category.label']);
		}
		this.carePlanItemTemplateGrid.goToPage(1);
	}

	openCarePlanTemplateModal(event: RowSelectingEventArgs) {
		// do not actually select the row; clicking on any column will open the preview modal
		event.cancel = true;
		const carePlanTemplate = event.data as QueryTriggerCarePlanTemplateResponse;
		this.modalManagerService.open(CarePlanTemplateModalComponent,
			{
				data: {
					carePlanTemplateId: carePlanTemplate.id,
				},
			},
		);
	}

}
