import { AccountingService } from '@accounting/core/accounting/accounting.service';
import { InvoiceService } from '@accounting/core/accounting/invoice-service/invoice.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { UpdateInvoiceEncounterRequest } from '@gandalf/model/update-invoice-encounter-request';
import { EncounterTableResponse } from '@gandalf/model/encounter-table-response';
import { BaseComponent } from '@shared/component/base.component';
import { TABLE_DATE_FORMATS } from '@shared/constants/date-format.constants';
import { SortSettingsModel } from '@syncfusion/ej2-grids';
import { GandalfFormBuilder } from 'gandalf';
import { Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'pms-encounter-select',
	templateUrl: './encounter-select.component.html',
	styles: [],
})
export class EncounterSelectComponent extends BaseComponent implements OnInit {

	@Input()
	patientId: number;

	@Input()
	invoiceId: number;

	@Output()
	closeModal = new EventEmitter();

	@ViewChild('templateForm')
	templateForm: NgForm;

	isSearchingOrSubmitting = false;
	encountersList: EncounterTableResponse[];
	tableDateFormat = TABLE_DATE_FORMATS.MM_DD_YYYY;
	unsubscribe$ = new Subject<void>();
	updateInvoiceEncounterRequest: UpdateInvoiceEncounterRequest;
	componentForm: UntypedFormGroup;
	sortOptions: SortSettingsModel = {
		columns: [
			{field: 'encounterDate', direction: 'Descending'},
		],
	};

	constructor(
		private invoiceService: InvoiceService,
		private accountingService: AccountingService,
		private gandalfFormBuilder: GandalfFormBuilder,
	) {
		super();
	}

	ngOnInit() {
		this.getEncounters();
		this.updateInvoiceEncounterRequest = this.prepareInitialRequest();
		this.componentForm = this.gandalfFormBuilder.group(this.updateInvoiceEncounterRequest);
	}

	prepareInitialRequest(): UpdateInvoiceEncounterRequest {
		const request = new UpdateInvoiceEncounterRequest();
		request.invoiceId = this.invoiceId;
		return request;
	}

	submitForm(event) {
		this.componentForm.get('encounterId').setValue(event.data.id);
		this.templateForm.onSubmit(event);
	}

	getEncounters() {
		this.isSearchingOrSubmitting = true;
		this.accountingService.findNonCanceledEncountersByPatientId(this.patientId).pipe(takeUntil(this.unsubscribe$)).subscribe((encounters) => {
			this.encountersList = encounters;
			this.isSearchingOrSubmitting = false;
		});
	}

	updateInvoiceEncounter() {
		if (this.componentForm.invalid) {
			return;
		}

		this.isSearchingOrSubmitting = true;
		const request = this.componentForm.value as UpdateInvoiceEncounterRequest;
		this.accountingService.updateInvoiceEncounter(request).pipe(
			catchError((error) => {
				this.isSearchingOrSubmitting = false;
				return throwError(() => error);
			}),
		).subscribe(() => {
			this.isSearchingOrSubmitting = false;
			this.invoiceService.refreshInvoice(this.invoiceId);
			this.closeModal.emit();
		});
	}
}
