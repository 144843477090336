// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FieldValueMultiSelectionResponse } from './field-value-multi-selection-response';

// @ts-ignore
import { FieldValueSelectionResponse } from './field-value-selection-response';

/** See com.rev360.pms.api.controller.encounter.history.presentillness.PresentIllnessResponse */
/* istanbul ignore next */
@GandalfModel
export class PresentIllnessResponse extends GandalfModelBase {

	@GandalfProperty()
	contextSelection: FieldValueSelectionResponse;

	@GandalfProperty()
	contextText: string;

	@GandalfProperty()
	diagnosisText: string;

	@GandalfProperty()
	durationAmount: number;

	@GandalfProperty()
	durationText: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	durationUnit: constants.DurationUnit;

	@GandalfProperty()
	factorsSelection: FieldValueMultiSelectionResponse;

	@GandalfProperty()
	factorsText: string;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	locationSelection: FieldValueSelectionResponse;

	@GandalfProperty()
	locationText: string;

	@GandalfProperty()
	qualitySelection: FieldValueMultiSelectionResponse;

	@GandalfProperty()
	qualityText: string;

	@GandalfProperty()
	severitySelection: FieldValueSelectionResponse;

	@GandalfProperty()
	severityText: string;

	@GandalfProperty()
	signsSelection: FieldValueMultiSelectionResponse;

	@GandalfProperty()
	signsText: string;

	@GandalfProperty()
	timingSelection: FieldValueSelectionResponse;

	@GandalfProperty()
	timingText: string;

}
