// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreatePaymentRequest } from './create-payment-request';

/** See com.rev360.pms.api.controller.accounting.payment.CreatePaymentGroupRequest */
/* istanbul ignore next */
@GandalfModel
export class CreatePaymentGroupRequest extends GandalfModelBase {

	@GandalfLabel('Apply In Full')
	@GandalfProperty()
	applyFull: boolean;

	@GandalfLabel('Comment')
	@GandalfValidator({ sizeString: { message: 'Comment cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	comment: string;

	@GandalfConstantDecorator()
	@GandalfLabel('Credit Card Type')
	@GandalfProperty()
	creditCardType: constants.CreditCardType;

	@GandalfLabel('Payer Entity')
	@GandalfProperty()
	payerEntityId: number;

	@GandalfConstantDecorator()
	@GandalfLabel('Payer Type')
	@GandalfValidator({ notNull: { message: 'Payer Type is required' } })
	@GandalfProperty({ isRequired: true })
	payerType: constants.PayerType;

	@GandalfLabel('Amount')
	@GandalfValidator({ notNull: { message: 'Amount is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	paymentAmount: number;

	@GandalfLabel('Payment Date')
	@GandalfValidator({ notNull: { message: 'Payment Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	paymentDate: Date;

	@GandalfLabel('Payment Location')
	@GandalfProperty()
	paymentLocationId: number;

	@GandalfConstantDecorator()
	@GandalfLabel('Payment Method')
	@GandalfValidator({ notNull: { message: 'Payment Method is required' } })
	@GandalfProperty({ isRequired: true })
	paymentMethodType: constants.PaymentMethodType;

	@GandalfLabel('Payment Transaction')
	@GandalfProperty()
	paymentTransactionId: number;

	@GandalfArray(CreatePaymentRequest)
	payments: CreatePaymentRequest[];

	@GandalfLabel('Reference Number')
	@GandalfValidator({ sizeString: { message: 'Reference Number cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	referenceNumber: string;

	@GandalfLabel('Practice Location')
	@GandalfProperty()
	sourcePracticeLocationId: number;

	@GandalfConstantDecorator()
	@GandalfLabel('Source Type')
	@GandalfValidator({ notNull: { message: 'Source Type is required' } })
	@GandalfProperty({ isRequired: true })
	sourceType: constants.PaymentGroupSourceType;

}
