import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { AuthenticationService } from '@core/authentication/authentication.service';
import { FeatureService } from '@core/feature/feature.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { Affiliation } from '@gandalf/constants';

@Component({
	selector: 'pms-live-chat',
	templateUrl: './live-chat.component.html',
	styles: [],
})

export class LiveChatComponent implements OnInit {

	@ViewChild('liveAgentOnline')
	online: ElementRef;

	@ViewChild('liveAgentOffline')
	offline: ElementRef;

	@ViewChild('liveAgentOnlineMigrated')
	onlineMigrated: ElementRef;

	@ViewChild('liveAgentOfflineMigrated')
	offlineMigrated: ElementRef;

	isFooterFeatureFlagOn = false;
	isLiveChatMigrationOn = false;
	isWalmart = false;
	readonly liveagentGuid = '5733A000000QLlw';
	readonly liveagentToken = '5723A000000QLMV';
	readonly liveagentKey = '00D300000006mk3';
	readonly liveagentUrl = 'https://c.la3-c1-ph2.salesforceliveagent.com/chat';
	readonly liveagentGuidMigrated = '5733Z000000PBRK';
	readonly liveagentTokenMigrated = '5723Z000000GxQX';
	readonly liveagentKeyMigrated = '00Dj0000001oF7z';

	constructor(
		private authenticationService: AuthenticationService,
		private featureService: FeatureService,
		private securityManager: SecurityManagerService,
	) {
	}

	ngOnInit() {
		if (!(window as any)._laq) {
			(window as any)._laq = [];
		}
		(window as any)._laq.push(() => {
			(window as any).liveagent.showWhenOnline(this.liveagentGuid, this.online.nativeElement);
			(window as any).liveagent.showWhenOffline(this.liveagentGuid, this.offline.nativeElement);
			(window as any).liveagent.showWhenOnline(this.liveagentGuidMigrated, this.onlineMigrated.nativeElement);
			(window as any).liveagent.showWhenOffline(this.liveagentGuidMigrated, this.offlineMigrated.nativeElement);
		});
		this.authenticationService.loggedIn.subscribe(() => this.loggedIn());
	}

	loggedIn() {
		this.isFooterFeatureFlagOn = this.featureService.isFeatureOn(FEATURE_FLAGS.MODULES.FOOTER);
		this.isLiveChatMigrationOn = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.LIVE_CHAT_MIGRATION);
		this.isWalmart = this.securityManager.hasAffiliation(Affiliation.AFFILIATE_WALMART_EMPLOYED.value);
		if (this.isFooterFeatureFlagOn && !this.isWalmart) {
			this.liveAgentSetup();
		}
	}

	liveAgentSetup() {
		if ((window as any).liveagent) {
			(window as any).liveagent.setChatWindowHeight(600);
			(window as any).liveagent.setChatWindowWidth(550);
			(window as any).liveagent.addCustomDetail('ECR_ID', this.securityManager.getUserSession().userEntityId);
			if (this.isLiveChatMigrationOn) {
				(window as any).liveagent.init(this.liveagentUrl, this.liveagentTokenMigrated, this.liveagentKeyMigrated);
			} else {
				(window as any).liveagent.init(this.liveagentUrl, this.liveagentToken, this.liveagentKey);
			}
		}
	}

	shouldShow() {
		return this.authenticationService.isAuthenticated.value && this.isFooterFeatureFlagOn && !this.isLiveChatMigrationOn && !this.isWalmart;
	}

	shouldShowMigrated() {
		return this.authenticationService.isAuthenticated.value && this.isFooterFeatureFlagOn && this.isLiveChatMigrationOn && !this.isWalmart;
	}


	startLiveAgentChat() {
		(window as any).liveagent.startChat(this.liveagentGuid);
	}

	startLiveAgentChatMigrated() {
		(window as any).liveagent.startChat(this.liveagentGuidMigrated);
	}
}
