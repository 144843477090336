// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CarePlanItemResponse } from './care-plan-item-response';

// @ts-ignore
import { PracticeDiagnosisResponse } from './practice-diagnosis-response';

/** See com.rev360.pms.api.controller.person.diagnosis.PersonDiagnosisResponse */
/* istanbul ignore next */
@GandalfModel
export class PersonDiagnosisResponse extends GandalfModelBase {

	@GandalfArray(CarePlanItemResponse)
	carePlanItemResponses: CarePlanItemResponse[];

	@GandalfArray(PersonDiagnosisResponse)
	childDiagnoses: PersonDiagnosisResponse[];

	@GandalfProperty()
	code: string;

	@GandalfProperty()
	comment: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	createdOn: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	diagnosisDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	diagnosisLocation: constants.DiagnosisLocation;

	@GandalfConstantDecorator()
	@GandalfProperty()
	diagnosisLocationQualifier: constants.DiagnosisLocationQualifier;

	@GandalfProperty()
	encounterAutoDiagnosisId: number;

	@GandalfProperty()
	encounterId: number;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	isPrimary: boolean;

	@GandalfProperty()
	isShowDisabledWarning: boolean;

	@GandalfProperty()
	needConversion: boolean;

	@GandalfProperty()
	originalDiagnosisId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	originalDiagnosisResolutionDate: Date;

	@GandalfProperty()
	parentDiagnosis: PersonDiagnosisResponse;

	@GandalfProperty()
	personId: number;

	@GandalfProperty()
	practiceDiagnosis: PracticeDiagnosisResponse;

	@GandalfProperty()
	reason: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	resolutionDate: Date;

	@GandalfProperty()
	shortDescription: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.PersonDiagnosisStatus;

	@GandalfProperty({ propertyType: 'LocalDate' })
	updatedOn: Date;

}
