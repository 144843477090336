// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.accounting.DepositSlipSearchRequest */
/* istanbul ignore next */
@GandalfModel
export class DepositSlipSearchRequest extends GandalfModelBase {

	@GandalfLabel('End Date')
	@GandalfValidator({ notNull: { message: 'End Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	endDate: Date;

	@GandalfProperty()
	locationId: number;

	@GandalfConstantDecorator()
	@GandalfLabel('Payment Method')
	@GandalfValidator({ sizeArray: { min: 1, max: 2147483647, message: 'Payment Method is required' } })
	@GandalfArray(constants.PaymentMethodType)
	paymentMethods: constants.PaymentMethodType[];

	@GandalfLabel('Start Date')
	@GandalfValidator({ notNull: { message: 'Start Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	startDate: Date;

}
