import { Component, Input } from '@angular/core';
import { SCHEDULE_STATUS_ICON_COLORS } from '@shared/constants/schedule-status-icon-colors.constants';

@Component({
	selector: 'pms-confirmed-status-icon',
	templateUrl: './confirmed-status-icon.component.html',
	styles: [],
})
export class ConfirmedStatusIconComponent {

	@Input() status: boolean;

	constructor() {
	}

	getTooltipText(): string {
		return this.status ? 'Confirmed' : 'Not Confirmed';
	}

	getColorClass(): string {
		return this.status ? SCHEDULE_STATUS_ICON_COLORS.GREEN : SCHEDULE_STATUS_ICON_COLORS.YELLOW;
	}
}
