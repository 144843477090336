import { Component, OnInit, ViewChild } from '@angular/core';
import { FormattedBarcodeSearchResponse } from '@core/barcode/barcode.service';
import { DynamicModalRef, MODAL, ModalConfig } from 'morgana';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { UserLocationsService } from '@core/user-locations/user-locations.service';
import { LocationProductItemTableResponse } from '@gandalf/model/location-product-item-table-response';
import { LocationProductTableResponse } from '@gandalf/model/location-product-table-response';
import { BarcodeSearchType, ContactLensType, PreferenceDefaults, PreferenceName } from '@gandalf/constants';
import { ProductCategoryResponse } from '@gandalf/model/product-category-response';
import { ContactLensFilterOptions } from '@shared/component/barcode-search/barcode-search.component';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

export interface ClProductSelectionResult {
	productName: string;

	locationProductItemId: number;

	locationProductId: number;
}

@Component({
	selector: 'pms-product-selection-modal',
	templateUrl: './product-selection-modal.component.html',
})
export class ProductSelectionModalComponent implements OnInit {
	@ViewChild('modal')
	modal: DialogComponent;
	locationId: number;
	searchType = BarcodeSearchType.PRODUCTS_AND_ITEMS;
	productCategoryId: number;
	productCategory = new ProductCategoryResponse();
	contactLensType = ContactLensType.SOFT;
	zIndexBehindHitPmsHtmlModal = MODAL.Z_INDEX_BEHIND_HIT_PMS_HTML_MODAL;
	contactLensFilter = ContactLensFilterOptions.NONE;
	onlyProductSelection = false;
	barcodeFocusPreference: boolean;

	constructor(
		private dynamicModalRef: DynamicModalRef,
		private modalConfig: ModalConfig,
		private userLocationsService: UserLocationsService,
		private securityManagerService: SecurityManagerService,
	) {
	}

	ngOnInit() {
		this.initialize();
	}

	initialize() {
		this.parseConfigData();
		this.barcodeFocusPreference = this.securityManagerService.preferenceValueIsOn(
			PreferenceName.FOCUS_BARCODE_SEARCH_CONTACTLENS.value,
			PreferenceDefaults.FOCUS_BARCODE_SEARCH_CONTACTLENS.value,
		);
	}

	handleBarcodeResults($event: FormattedBarcodeSearchResponse) {
		this.closeDialog({
			productName: $event.productName,
			locationProductId: $event.locationProductId,
			locationProductItemId: $event.locationProductItemId,
		});
	}

	parseConfigData() {
		this.locationId = this.userLocationsService.getCurrentUserLocation().id;
		this.contactLensType = this.modalConfig.data.contactLensType;
		const productCategory = this.modalConfig.data.productCategory;
		this.productCategory = productCategory;
		this.productCategoryId = productCategory.id;
		this.contactLensFilter = this.modalConfig.data.contactLensFilter;
		this.onlyProductSelection = !!this.modalConfig.data.onlyProductSelection;
	}

	/* istanbul ignore next */
	closeDialog(result?: ClProductSelectionResult) {
		this.dynamicModalRef.close(this.modal, result);
	}

	onLocationProductSelection($event: LocationProductTableResponse) {
		this.closeDialog({
			productName: $event.productName,
			locationProductId: $event.id,
			locationProductItemId: null,
		});
	}

	onLocationProductItemSelection($event: LocationProductItemTableResponse) {
		this.closeDialog({
			productName: $event.productName,
			locationProductId: $event.locationProductId,
			locationProductItemId: $event.id,
		});
	}
}
