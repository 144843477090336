import { Injectable } from '@angular/core';
import { RouterState } from '@app-store/reducers';
import { AddInvoicePaymentTab, AddInvoiceTab, InvoiceTabActionTypes, SyncInvoicePaymentTab, SyncInvoiceTab } from '@invoices-store/actions/invoice-tab.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RouterReducerState } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';

@Injectable()
export class InvoiceTabEffects {

	syncInvoiceTab = createEffect(() => this.actions.pipe(
		ofType<SyncInvoiceTab>(InvoiceTabActionTypes.SYNC_INVOICE_TAB),
		tap(action => {
			this.store.dispatch(new AddInvoiceTab({invoiceId: action.payload.invoiceId}));
		}),
	), {dispatch: false});

	syncInvoicePaymentTab = createEffect(() => this.actions.pipe(
		ofType<SyncInvoicePaymentTab>(InvoiceTabActionTypes.SYNC_PAYMENT_TAB),
		tap(action => {
			this.store.dispatch(new AddInvoicePaymentTab({paymentId: action.payload.paymentId}));
		}),
	), {dispatch: false});

	constructor(
		private actions: Actions,
		private store: Store<RouterReducerState<RouterState>>,
	) {
	}

}
