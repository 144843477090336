// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

/** See com.rev360.pms.api.controller.admin.vendorsandpartners.insurancecompanies.AdminPracticeInsuranceCompanySummaryResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminPracticeInsuranceCompanySummaryResponse extends GandalfModelBase {

	@GandalfProperty()
	address: AddressResponse;

	@GandalfProperty({ propertyType: 'Money' })
	credit: number;

	@GandalfProperty()
	fax: string;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	name: string;

	@GandalfProperty()
	payerId: string;

	@GandalfProperty()
	phone: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.InsuranceCompanyStatus;

	@GandalfConstantDecorator()
	@GandalfProperty()
	submitByType: constants.ClaimSubmitMechanism;

}
