/* eslint-disable @typescript-eslint/naming-convention */

import { AlertResponse } from '@gandalf/model/alert-response';
import { Action } from '@ngrx/store';

export enum PatientAlertActionTypes {
	GET_PATIENT_ALERTS = '[Get Patient Alerts] Get Patient Alerts',
	GET_PATIENT_ALERTS_SUCCESS = '[Get Patient Alerts Success] Get Patient Alerts Success',
	GET_PATIENT_ALERTS_FAILURE = '[Get Patient Alerts Failure] Get Patient Alerts Failure.',
	REMOVE_PATIENT_ALERT = '[Remove Patient Alert] Remove Patient Alert',
	SET_PATIENT_ALERT_MODAL_SHOWN = '[Set Patient Alert Modal Shown] Set Patient Alert Modal Shown',
}

export class GetPatientAlerts implements Action {
	readonly type = PatientAlertActionTypes.GET_PATIENT_ALERTS;
	constructor(public payload: { patientId: number }) {}
}

export class GetPatientAlerts_Success implements Action {
	readonly type = PatientAlertActionTypes.GET_PATIENT_ALERTS_SUCCESS;
	constructor(public payload: { patientId: number, alerts: AlertResponse[]}) {}
}

export class GetPatientAlerts_Failure implements Action {
	readonly type = PatientAlertActionTypes.GET_PATIENT_ALERTS_FAILURE;
	constructor(public payload: any) {}
}

export class RemovePatientAlert implements Action {
	readonly type = PatientAlertActionTypes.REMOVE_PATIENT_ALERT;
	constructor(public payload: { patientId: number }) {}
}

export class SetPatientAlertModalShown implements Action {
	readonly type = PatientAlertActionTypes.SET_PATIENT_ALERT_MODAL_SHOWN;
	constructor(public payload: { patientId: number }) {}
}

export type PatientAlertActions =
	GetPatientAlerts
	| GetPatientAlerts_Success
	| GetPatientAlerts_Failure
	| RemovePatientAlert
	| SetPatientAlertModalShown;
