<ejs-grid #grid
		  [pmsGridSubmit]="isSearching"
		  class="table-rowlink"
		  [dataSource]="insuranceClaimStatus"
		  autoFitColumns
		  [autoFitBlacklist]="['mostRecentStatusMessage']"
		  [staticColumns]="staticColumnList"
		  [allowPaging]="false"
		  [allowSelection]="false"
		  [allowSorting]="true"
		  [allowResizing]="true"
		  gridLines="Horizontal"
		  allowPaging="true"
		  [attr.data-test-id]="'invoiceDetailsClaimHistoryGrid'">
	<e-columns>
		<e-column field="insuranceClaim.id" headerText="Claim ID" width="110" [isPrimaryKey]="true"></e-column>
		<e-column field="insuranceClaim.claimDate" width="150" headerText="Claim Date" [format]="tableDateFormat"></e-column>
		<e-column field="insuranceClaim.priority" width="150" headerText="Priority"></e-column>
		<e-column field="processingStatusText" width="200" headerText="Submit Status" clipMode="EllipsisWithTooltip"></e-column>
		<e-column field="insuranceClaim.claimSubmitDate" width="150" headerText="Submit Date" [format]="tableDateFormat"></e-column>
		<e-column field="insuranceClaim.claimSubmissionMechanism" width="150" headerText="Submit Method"></e-column>
		<e-column clipMode="EllipsisWithTooltip" field="mostRecentStatusMessage" headerText="Most Recent Status Message" [customAttributes]="{ autofitMinWidth: 50 }"></e-column>
		<e-column field="allMessages" width="125" headerText="">
			<ng-template #template let-data>
				<a (click)="openAllMessagesModal(data.insuranceClaim)">All Messages</a>
			</ng-template>
		</e-column>
		<e-column field="buttons" width="90" headerText="">
			<ng-template #template let-data>
				<ng-container *ngIf="!isPaymentProcessing">
					<rev-button
							*ngIf="data.showSubmitButton"
							buttonIcon="share"
							buttonStyle="primary"
							buttonTooltip="Submit"
							revEventStopPropagation
							(buttonClick)="submitClaim(data.insuranceClaim.id)"
							dataTestId="claimsHistorySubmitButton">
					</rev-button>
					<rev-button-menu
							[revGridButton]="'moreActions'"
							[buttonMenuItems]="data.buttonList"
							(menuItemClick)="onEllipsisItemSelect($event.id, data.insuranceClaim.id)"
							dataTestId="claimsHistoryMenuButton">
					</rev-button-menu>
				</ng-container>
			</ng-template>
		</e-column>
	</e-columns>
</ejs-grid>
