// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.billing.BillingInvoiceResponse */
/* istanbul ignore next */
@GandalfModel
export class BillingInvoiceResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	balance: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	businessStatus: constants.BusinessStatus;

	@GandalfProperty()
	encounterId: number;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	invoiceDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	invoiceStatus: constants.InvoiceStatus;

	@GandalfProperty()
	isPreviousPayer: boolean;

	@GandalfProperty()
	locationId: number;

	@GandalfProperty()
	name: string;

	@GandalfProperty()
	orderId: number;

	@GandalfProperty()
	payerEntityId: number;

	@GandalfProperty()
	payerId: number;

	@GandalfProperty()
	payerPersonInsuranceId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	payerType: constants.PayerType;

	@GandalfConstantDecorator()
	@GandalfProperty()
	personInsuranceStatus: constants.PersonInsuranceStatus;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.BillingInvoiceStatus;

}
