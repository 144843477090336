import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { _isNil } from '@core/lodash/lodash';
import { PreferredPhoneType } from '@gandalf/constants';

export interface PhoneValidation {
	formControlName: string;
	message: string;
}
export interface PreferredPhoneObject {
	home?: PhoneValidation;
	work?: PhoneValidation;
	cell?: PhoneValidation;
	pager?: PhoneValidation;
	other?: PhoneValidation;
}

export const preferredPhoneTypeValidator = (
	validator: ValidatorFn,
	preferredPhoneType: string,
	preferredPhoneObj: PreferredPhoneObject,
) => (
	group: UntypedFormGroup,
): ValidationErrors | null => {
	let validateControl;
	const phoneTypeEnum = group.controls[preferredPhoneType].value;
	if (_isNil(phoneTypeEnum)) {
		return null;
	}

	resetAllPreferredPhoneValidators(group, preferredPhoneObj, preferredPhoneType);

	switch (phoneTypeEnum.value) {
		case PreferredPhoneType.CELL.value:
			validateControl = preferredPhoneObj.cell;
			break;
		case PreferredPhoneType.WORK.value:
			validateControl = preferredPhoneObj.work;
			break;
		case PreferredPhoneType.HOME.value:
			validateControl = preferredPhoneObj.home;
			break;
		case PreferredPhoneType.PAGER.value:
			validateControl = preferredPhoneObj.pager;
			break;
		case PreferredPhoneType.OTHER.value:
			validateControl = preferredPhoneObj.other;
			break;
		default:
			return null;
	}

	const errors = validator(group.controls[validateControl.formControlName]);
	let controlErrors = group.controls[validateControl.formControlName].errors;

	if (!_isNil(errors)) {
		controlErrors = controlErrors || {};
		controlErrors[preferredPhoneType] = {
			message: validateControl.message,
		};
	}

	group.controls[validateControl.formControlName].setErrors(controlErrors);

	return null;
};

function resetAllPreferredPhoneValidators(group: UntypedFormGroup, objectMap: PreferredPhoneObject, preferredPhoneType: string) {
	Object.keys(objectMap).forEach(key => {
		if (objectMap[key]) {
			let errors = group.controls[objectMap[key].formControlName].errors;
			if (!_isNil(errors)) {
				delete errors[preferredPhoneType];
				if (Object.keys(errors).length === 0) {
					errors = null;
				}
			}
			group.controls[objectMap[key].formControlName].setErrors(errors);
		}
	});
}
