// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { IopReadingResponse } from './iop-reading-response';

// @ts-ignore
import { TargetIopResponse } from './target-iop-response';

/** See com.rev360.pms.api.controller.patient.ongoingcare.GlaucomaDataResponse */
/* istanbul ignore next */
@GandalfModel
export class GlaucomaDataResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	encounterDate: Date;

	@GandalfProperty()
	encounterId: number;

	@GandalfArray(IopReadingResponse)
	iopReadings: IopReadingResponse[];

	@GandalfProperty()
	odHorizontalCd: number;

	@GandalfProperty()
	odVerticalCd: number;

	@GandalfProperty()
	osHorizontalCd: number;

	@GandalfProperty()
	osVerticalCd: number;

	@GandalfProperty()
	performed76514: boolean;

	@GandalfProperty()
	performed92020: boolean;

	@GandalfProperty()
	performed92083: boolean;

	@GandalfProperty()
	performed92132: boolean;

	@GandalfProperty()
	performed92134: boolean;

	@GandalfProperty()
	performed92135: boolean;

	@GandalfProperty()
	performed92250: boolean;

	@GandalfProperty()
	performed92275: boolean;

	@GandalfProperty()
	performed95930: boolean;

	@GandalfArray(TargetIopResponse)
	targetIops: TargetIopResponse[];

}
