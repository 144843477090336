import { Component, OnInit, ViewChild } from '@angular/core';
import { FormattedBarcodeSearchResponse } from '@core/barcode/barcode.service';
import { DynamicModalRef, MODAL, ModalConfig } from 'morgana';
import { AddInvoiceItemRequest } from '@gandalf/model/add-invoice-item-request';
import { InvoiceResponse } from '@gandalf/model/invoice-response';
import { LocationProductItemTableResponse } from '@gandalf/model/location-product-item-table-response';
import { LocationProductTableResponse } from '@gandalf/model/location-product-table-response';
import { BarcodeSearchType, InvoiceItemType } from '@gandalf/constants';
import { ProductCategoryResponse } from '@gandalf/model/product-category-response';
import { PanelMenuItem } from '@shared/component/panel-menu/panel-menu.component';
import { ProductSelectionComponent } from '@shared/component/product-selection/product-selection.component';
import { ServiceType } from '@shared/component/service-selection/service-selection.component';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { combineLatest } from 'rxjs';
import { InventoryServiceResponse } from '@gandalf/model/inventory-service-response';
import { AccountingService } from '../../core/accounting/accounting.service';
import { InvoiceService } from '../../core/accounting/invoice-service/invoice.service';

@Component({
	selector: 'pms-add-invoice-item-modal',
	templateUrl: './add-invoice-item-modal.component.html',
})
export class AddInvoiceItemModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('productSelectionComponent')
	productSelectionComponent: ProductSelectionComponent;

	menuItems: PanelMenuItem[] = [];
	invoice: InvoiceResponse;
	searchType = BarcodeSearchType.PRODUCTS_AND_ITEMS;
	invoiceId: number;
	productCategory: ProductCategoryResponse;
	serviceType: ServiceType;
	zIndexBehindHitPmsHtmlModal = MODAL.Z_INDEX_BEHIND_HIT_PMS_HTML_MODAL;

	constructor(
		public ref: DynamicModalRef,
		public modalConfig: ModalConfig,
		public invoiceService: InvoiceService,
		public accountingService: AccountingService,
	) {
	}

	ngOnInit() {
		this.invoiceId = this.modalConfig.data.invoiceId;
		this.fetchData();
	}

	closeModal() {
		this.ref.close(this.modal);
	}

	fetchData() {
		combineLatest([this.accountingService.getInvoiceById(this.invoiceId), this.accountingService.getActiveAlphabetizedProductCategories()])
			.subscribe(([invoice, productCategories]) => {
				this.invoice = invoice;
				this.buildProductsAndServiceMenu(productCategories);
			});
	}

	buildProductsAndServiceMenu(productCategories: ProductCategoryResponse[]) {
		const productCategoryMenuItems: PanelMenuItem[] = [];
		const serviceMenuItems: PanelMenuItem[] = [{
			label: 'CPT Services',
			command: () => this.cptServicesSelected(),
		}, {
			label: 'Other Services',
			command: () => this.otherServicesSelected(),
		}];
		this.menuItems = [
			{
				label: 'Products',
				items: productCategoryMenuItems,
				expanded: true,
			},
			{
				label: 'Services',
				items: serviceMenuItems,
				expanded: true,
			}];
		productCategories.forEach(productCategory => {
			productCategoryMenuItems.push({
				label: productCategory.name,
				id: productCategory.id.toString(),
				command: (_event) => this.productCategorySelected(productCategory),
			});
		});
	}

	productCategorySelected(productCategory: ProductCategoryResponse) {
		this.productCategory = productCategory;
		this.serviceType = undefined;
	}

	otherServicesSelected() {
		this.serviceType = ServiceType.OTHER;
		this.productCategory = undefined;
	}

	cptServicesSelected() {
		this.serviceType = ServiceType.CPT;
		this.productCategory = undefined;
	}

	onBarcodeResult(result: FormattedBarcodeSearchResponse) {
		const request = new AddInvoiceItemRequest();
		request.invoiceId = this.invoiceId;
		request.locationProductId = result.locationProductId;
		request.locationProductItemId = result.locationProductItemId;
		request.itemType = InvoiceItemType.PRODUCT;
		request.quantity = 1;
		this.addInvoiceItem(request);
	}

	private addInvoiceItem(request, close = false, refreshItems = false) {
		this.accountingService.addInvoiceItem(request).subscribe(() => {
			this.invoiceService.refreshInvoice(this.invoiceId);
			if (close) {
				this.closeModal();
			} else if (refreshItems) {
				this.productSelectionComponent.refreshItems();
			}
		});
	}

	onLocationProductSelection(locationProductSummaryResponse: LocationProductTableResponse) {
		const request = new AddInvoiceItemRequest();
		request.invoiceId = this.invoiceId;
		request.locationProductId = locationProductSummaryResponse.id;
		request.itemType = InvoiceItemType.PRODUCT;
		request.quantity = 1;
		this.addInvoiceItem(request, false);
	}

	onLocationProductItemSelection(locationProductItemTableResponse: LocationProductItemTableResponse) {
		const request = new AddInvoiceItemRequest();
		request.invoiceId = this.invoiceId;
		request.locationProductItemId = locationProductItemTableResponse.id;
		request.locationProductId = locationProductItemTableResponse.locationProductId;
		request.itemType = InvoiceItemType.PRODUCT;
		request.quantity = 1;
		this.addInvoiceItem(request, false, !!request.locationProductItemId);
	}

	onServiceSelection(serviceSummaryResponse: InventoryServiceResponse) {
		const request = new AddInvoiceItemRequest();
		request.invoiceId = this.invoiceId;
		request.serviceId = serviceSummaryResponse.id;
		request.itemType = InvoiceItemType.SERVICE;
		request.quantity = 1;
		this.addInvoiceItem(request, false);

	}
}
