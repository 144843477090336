import { Injectable } from '@angular/core';
import { PrescriptionUtil } from '@core/prescription-util/prescription-util';
import { CellFormattingUtils } from 'morgana';

@Injectable({
	providedIn: 'root',
})
export class PmsCellFormattingUtils extends CellFormattingUtils {

	pupillaryDistance(fractionDigit = 2) {
		return (params) => PrescriptionUtil.formatPupillaryDistance(params.value, fractionDigit);
	}
}
