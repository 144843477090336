// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AdminEyefinityFeeLensResponse } from './admin-eyefinity-fee-lens-response';

// @ts-ignore
import { AdminEyefinityFeeOptionResponse } from './admin-eyefinity-fee-option-response';

// @ts-ignore
import { AdminEyefinityFeeServiceResponse } from './admin-eyefinity-fee-service-response';

/** See com.rev360.pms.api.controller.admin.accounting.eyefinity.AdminEyefinityFeeScheduleResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminEyefinityFeeScheduleResponse extends GandalfModelBase {

	@GandalfProperty()
	active: boolean;

	@GandalfArray(AdminEyefinityFeeLensResponse)
	baseLenses: AdminEyefinityFeeLensResponse[];

	@GandalfProperty({ propertyType: 'LocalDate' })
	beginDate: Date;

	@GandalfProperty()
	comments: string;

	@GandalfProperty()
	contactLensExamPercentage: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate: Date;

	@GandalfProperty()
	eyefinityFeeScheduleId: number;

	@GandalfProperty({ propertyType: 'Money' })
	frameDispensingFee: number;

	@GandalfArray(AdminEyefinityFeeOptionResponse)
	lensAddons: AdminEyefinityFeeOptionResponse[];

	@GandalfProperty()
	name: string;

	@GandalfProperty()
	patientCopayReasonId: number;

	@GandalfProperty()
	patientTransferReasonId: number;

	@GandalfProperty()
	recoupmentReasonId: number;

	@GandalfArray(AdminEyefinityFeeServiceResponse)
	services: AdminEyefinityFeeServiceResponse[];

	@GandalfProperty()
	writeoffReasonId: number;

}
