/* eslint-disable max-len */
import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import { DropdownUtils } from '@core/dropdown-utils/dropdown.utils';
import { EnumUtil, OptionItem } from 'morgana';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { _isNil } from '@core/lodash/lodash';
import { PatientService } from '@core/patient/patient.service';
import { ReferenceDataService } from '@core/reference-data/reference-data.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import {
	EstablishedStatus,
	Gender,
	GenderIdentity,
	PersonInterpreterAssistance,
	PreferenceDefaults,
	PreferenceName,
	ReferenceDataMasterCategory,
	SexualOrientation
} from '@gandalf/constants';
import { PatientResponse } from '@gandalf/model/patient-response';
import { BaseComponent } from '@shared/component/base.component';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { PersonNamePipe } from '@shared/pipes/person-name/person-name.pipe';
import { assertTrue } from '@shared/validators/assert-true.validation';
import { conditionallyRequiredValidator } from '@shared/validators/conditionally-required-validation';
import { combineLatest } from 'rxjs';
import { DatePipe } from '@angular/common';
import { GuarantorNameResponse } from '@gandalf/model/guarantor-name-response';
import { PatientUtilService } from '../../../../patients/core/patient-util/patient-util.service';

@Component({
	selector: 'pms-other-information-form',
	templateUrl: './other-information-form.component.html',
	styles: [],
})
export class OtherInformationFormComponent extends BaseComponent implements OnInit {

	@Input()
	readonly patientId: number;

	@Input()
	readonly patientResponse: PatientResponse;

	get isNewPatient(): boolean {
		return _isNil(this.patientId) || (this.patientId && PatientUtilService.isNewPatient(this.patientId));
	}

	componentForm: UntypedFormGroup;
	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	genderOptions = [
		Gender.MALE,
		Gender.FEMALE,
		Gender.UNKNOWN,
	];
	sexualOrientationOptions = SexualOrientation.VALUES;
	genderIdentityOptions = GenderIdentity.VALUES;
	statusOptions = EstablishedStatus.VALUES.values;
	interpreterOptions = [
		PersonInterpreterAssistance.YES,
		PersonInterpreterAssistance.NO,
	];
	languageOptions: OptionItem[];
	raceOptions: OptionItem[];
	ethnicityOptions: OptionItem[];
	guarantorOptions: OptionItem[];

	isLanguageRequired = false;
	isEthnicityRequired = false;
	isRaceRequired = false;
	isInterpreterRequired = false;
	displayAlternateId = false;

	constructor(
		private patientService: PatientService,
		private personNamePipe: PersonNamePipe,
		private referenceDataService: ReferenceDataService,
		private securityManagerService: SecurityManagerService,
		public controlContainer: ControlContainer,
		private datePipe: DatePipe,
	) {
		super();
	}

	ngOnInit() {
		this.componentForm = this.controlContainer.control as UntypedFormGroup;
		if (_isNil(this.componentForm.get('establishedStatus').value)) {
			this.componentForm.get('establishedStatus').setValue(EstablishedStatus.NEW);
		}
		this.setRequiredFields();
		this.getFormData();
		this.observeEthnicityDeclined();
		this.observeLanguageDeclined();
		this.observeRaceDecline();
	}

	getFormData() {
		combineLatest([
			this.referenceDataService.getActiveReferenceDataByCategoryIdForDropdown(ReferenceDataMasterCategory.PERSON_DEMO_LANGUAGE.value),
			this.referenceDataService.getActiveReferenceDataByCategoryIdForDropdown(ReferenceDataMasterCategory.PERSON_DEMO_RACE.value),
			this.referenceDataService.getActiveReferenceDataByCategoryIdForDropdown(ReferenceDataMasterCategory.PERSON_DEMO_RACE_EXTENDED.value),
			this.referenceDataService.getActiveReferenceDataByCategoryIdForDropdown(ReferenceDataMasterCategory.PERSON_DEMO_ETHNICITY.value),
			this.referenceDataService.getActiveReferenceDataByCategoryIdForDropdown(ReferenceDataMasterCategory.PERSON_DEMO_ETHNICITY_EXTENDED.value),
		]).subscribe(([languages, racesBasic, racesExtended, ethnicitiesBasic, ethnicitiesExtended]) => {
			this.languageOptions = languages;
			this.raceOptions = [...racesBasic, ...racesExtended];
			this.ethnicityOptions = [...ethnicitiesBasic, ...ethnicitiesExtended];
		});

		if (!this.isNewPatient) {
			this.patientService.getGuarantorsByPatientId(this.patientId).subscribe(guarantors => {
				const guarantorOptions = DropdownUtils.createDropdownList(guarantors,
					(guarantor) => this.formatGuarantorName(guarantor),
					'personId',
				);
				this.guarantorOptions = DropdownUtils.addOptionItem(guarantorOptions, 'Self', 0, 0);
			});
		}
	}

	observeEthnicityDeclined() {
		const ethnicityDeclinedControl = this.componentForm.get('ethnicityDeclined');
		const ethnicityIdsControl = this.componentForm.get('ethnicityIds');
		const reactions = () => {
			FormUtilsService.disabledWhen(ethnicityIdsControl, !!ethnicityDeclinedControl.value || this.controlContainer.disabled);
			FormUtilsService.nullWhen(ethnicityIdsControl, !!ethnicityDeclinedControl.value);
		};

		FormUtilsService.reactToValueChanges(ethnicityDeclinedControl, reactions, true, this.unsubscribe);
	}

	observeLanguageDeclined() {
		const languageDeclinedControl = this.componentForm.get('languageDeclined');
		const languageIdControl = this.componentForm.get('languageId');
		const reactions = () => {
			FormUtilsService.disabledWhen(languageIdControl, !!languageDeclinedControl.value || this.controlContainer.disabled);
			FormUtilsService.nullWhen(languageIdControl, !!languageDeclinedControl.value);
		};

		FormUtilsService.reactToValueChanges(languageDeclinedControl, reactions, true, this.unsubscribe);
	}

	observeRaceDecline() {
		const raceDeclinedControl = this.componentForm.get('raceDeclined');
		const raceIdsControl = this.componentForm.get('raceIds');
		const reactions = () => {
			FormUtilsService.disabledWhen(raceIdsControl, !!raceDeclinedControl.value || this.controlContainer.disabled);
			FormUtilsService.nullWhen(raceIdsControl, !!raceDeclinedControl.value);
		};

		FormUtilsService.reactToValueChanges(raceDeclinedControl, reactions, true, this.unsubscribe);
	}

	setRequiredFields() {
		this.isLanguageRequired = this.securityManagerService.preferenceValueIsOn(
			PreferenceName.DEMOGRAPHICS_LANGUAGE_REQUIRED.value,
			PreferenceDefaults.DEMOGRAPHICS_LANGUAGE_REQUIRED.value,
		);

		this.isRaceRequired = this.securityManagerService.preferenceValueIsOn(
			PreferenceName.DEMOGRAPHICS_RACE_REQUIRED.value,
			PreferenceDefaults.DEMOGRAPHICS_RACE_REQUIRED.value,
		);

		this.isEthnicityRequired = this.securityManagerService.preferenceValueIsOn(
			PreferenceName.DEMOGRAPHICS_ETHNICITY_REQUIRED.value,
			PreferenceDefaults.DEMOGRAPHICS_ETHNICITY_REQUIRED.value,
		);

		this.isInterpreterRequired = this.securityManagerService.preferenceValueIsOn(
			PreferenceName.DEMOGRAPHICS_INTERPRETER_ASSISTANCE_REQUIRED.value,
			PreferenceDefaults.DEMOGRAPHICS_INTERPRETER_ASSISTANCE_REQUIRED.value,
		);

		this.displayAlternateId = this.securityManagerService.preferenceValueIsOn(
			PreferenceName.DEMOGRAPHICS_ALTERNATEID_ENABLED.value,
			PreferenceDefaults.DEMOGRAPHICS_ALTERNATEID_ENABLED.value,
		);

		this.componentForm.setValidators([
			conditionallyRequiredValidator(
				'languageId',
				() => this.componentForm && this.componentForm.get('languageId').enabled === true && this.isLanguageRequired,
				'languageRequired',
				'Language is required',
			),
			conditionallyRequiredValidator(
				'raceIds',
				() => this.componentForm && this.componentForm.get('raceIds').enabled === true && this.isRaceRequired,
				'raceRequired',
				'Race is required',
			),
			conditionallyRequiredValidator(
				'ethnicityIds',
				() => this.componentForm && this.componentForm.get('ethnicityIds').enabled === true && this.isEthnicityRequired,
				'ethnicityRequired',
				'Ethnicity is required',
			),
			assertTrue(
				() => !this.isInterpreterRequired || EnumUtil.equalsOneOf(this.componentForm.getRawValue().interpreterAssistance,
					PersonInterpreterAssistance.YES, PersonInterpreterAssistance.NO),
				['interpreterAssistance'],
				'interpreterAssistanceRequired',
				`Interpreter is required`,
			),

		]);
	}

	formatGuarantorName(guarantor: GuarantorNameResponse): string {
		return guarantor.dob
			? `${this.personNamePipe.transform(guarantor)} (${this.datePipe.transform(guarantor.dob, DATE_FORMATS.MM_DD_YYYY)})`
			: this.personNamePipe.transform(guarantor);
	}

}
