import { FormsActions, FormsActionTypes } from '@app-store/actions/forms.actions';
import { FormMode } from '@shared/constants/form-mode.enum';

export class FormState<T = any> {
	value: T;
	meta: FormMeta;
	mode: FormMode;
	submitted?: boolean;
}

export type FormMeta = Map<string, FormControlMeta>;

export interface FormControlMeta {
	dirty: boolean;
	touched: boolean;
}

export type FormStateMap = Map<string, FormState>;

export const initialState: FormStateMap = new Map();

export function reducer(state = initialState, action: FormsActions): FormStateMap {
	switch (action.type) {

		case FormsActionTypes.UPDATE_FORM_META: {
			const {key, meta} = action.payload;
			const formsStateMap = new Map(state);
			const formState = formsStateMap.get(key) || new FormState();
			return formsStateMap.set(key, {...formState, meta});
		}

		case FormsActionTypes.UPDATE_FORM_MODE: {
			const {key, mode} = action.payload;
			const formsStateMap = new Map(state);
			const formState = formsStateMap.get(key) || new FormState();
			return formsStateMap.set(key, {...formState, mode});
		}

		case FormsActionTypes.UPDATE_FORM_SUBMITTED: {
			const {key, submitted} = action.payload;
			const formsStateMap = new Map(state);
			const formState = formsStateMap.get(key) || new FormState();
			return formsStateMap.set(key, {...formState, submitted});
		}

		case FormsActionTypes.UPDATE_FORM_VALUE: {
			const {key, value} = action.payload;
			const formsStateMap = new Map(state);
			const formState = formsStateMap.get(key) || new FormState();
			return formsStateMap.set(key, {...formState, value});
		}

		case FormsActionTypes.REMOVE_FORM_STATE: {
			const formsStateMap = new Map(state);
			formsStateMap.delete(action.payload.key);
			return formsStateMap;
		}

		default: {
			return state;
		}
	}
}
