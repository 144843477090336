// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { EncounterTemplateResponse } from './encounter-template-response';

/** See com.rev360.pms.api.controller.schedule.scheduleitem.AppointmentHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class AppointmentHistoryResponse extends GandalfModelBase {

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	startDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.ScheduleItemStatus;

	@GandalfProperty()
	template: EncounterTemplateResponse;

}
