// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.history.pfsh.SocialHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class SocialHistoryResponse extends GandalfModelBase {

	@GandalfProperty()
	alcoholAmount: string;

	@GandalfProperty()
	comments: string;

	@GandalfProperty()
	hobbies: string;

	@GandalfProperty()
	id: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	isDrinker: constants.IsDrinker;

	@GandalfConstantDecorator()
	@GandalfProperty()
	isSmoker: constants.IsSmoker;

	@GandalfProperty()
	smokingAmount: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	smokingPreference: constants.SmokingPreference;

	@GandalfConstantDecorator()
	@GandalfProperty()
	smokingStatus: constants.SmokingStatus;

}
