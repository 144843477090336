// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.pod.PatientPodOrderResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientPodOrderResponse extends GandalfModelBase {

	@GandalfProperty()
	completionDate: Date;

	@GandalfProperty()
	createdOn: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	currentStatusCode: constants.OrderStatusCode;

	@GandalfProperty()
	currentStatusDate: Date;

	@GandalfProperty()
	externalSupplierName: string;

	@GandalfProperty()
	onHold: boolean;

	@GandalfProperty()
	orderId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	orderType: constants.OrderType;

	@GandalfProperty()
	patientNotified: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	shipToType: constants.OrderShipToType;

	@GandalfProperty()
	vendorName: string;

}
