// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { PaymentInfoResponse } from './payment-info-response';

/** See com.rev360.pms.api.controller.accounting.payment.TransactionResponse */
/* istanbul ignore next */
@GandalfModel
export class TransactionResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	checkAmount: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	checkDate: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dateGenerated: Date;

	@GandalfProperty()
	eftNumber: string;

	@GandalfProperty()
	payerAddress: AddressResponse;

	@GandalfProperty()
	payerName: string;

	@GandalfArray(PaymentInfoResponse)
	paymentInfo: PaymentInfoResponse[];

	@GandalfProperty()
	providerAddress: AddressResponse;

	@GandalfProperty({ propertyType: 'Money' })
	providerAdjustmentAmount: number;

	@GandalfProperty()
	providerNPI: string;

	@GandalfProperty()
	providerName: string;

	@GandalfProperty()
	providerTaxNumber: string;

}
