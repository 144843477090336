import { Injectable } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { EnumUtil, SortingService, YesNoPipe } from 'morgana';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { IntakeReconciledStatus, IntakeStatus } from '@gandalf/constants';
import { AllergyReviewHistoryResponse } from '@gandalf/model/allergy-review-history-response';
import { CreateDrugAllergyRequest } from '@gandalf/model/create-drug-allergy-request';
import { CreateOtherAllergyRequest } from '@gandalf/model/create-other-allergy-request';
import { IntakeAllergyResponse } from '@gandalf/model/intake-allergy-response';
import { PracticeDrugListResponse } from '@gandalf/model/practice-drug-list-response';
import { UpdateDrugAllergyRequest } from '@gandalf/model/update-drug-allergy-request';
import { UpdateIntakeAllergiesRequest } from '@gandalf/model/update-intake-allergies-request';
import { UpdateOtherAllergyRequest } from '@gandalf/model/update-other-allergy-request';
import { AllergyGandalfService, DrugGandalfService } from '@gandalf/services';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export enum IntakeAllergy {
	ALLERGY,
	NO_KNOWN_DRUG_ALLERGIES,
	NO_LATEX_SENSITIVITY,
	NO_OTHER_KNOWN_ALLERGIES,
}

export interface FormattedIntakeAllergy {
	intakeAllergyId: number;
	type: IntakeAllergy;
	question: string;
	answer: string;
	status: IntakeStatus;
	answerFlag: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class AllergyService {

	private _intakeAllergiesAccepted = new Subject<any>();

	constructor(
		private allergyGandalfService: AllergyGandalfService,
		private drugGandalfService: DrugGandalfService,
		private yesNoPipe: YesNoPipe,
	) {
	}

	findAllergiesByPatientId(patientId: number) {
		return this.allergyGandalfService.findAllergiesByPatientId(patientId);
	}

	getDrugAllergyById(allergyId: number) {
		return this.allergyGandalfService.getDrugAllergyById(allergyId);
	}

	getOtherAllergyById(allergyId: number) {
		return this.allergyGandalfService.getOtherAllergyById(allergyId);
	}

	findActiveDrugClasses() {
		return this.drugGandalfService.findActiveDrugClasses().pipe(map(items => SortingService.sortBy(items, 'name')));
	}

	findPracticeDrugs(): Observable<PracticeDrugListResponse[]> {
		return this.drugGandalfService.findPracticeDrugs();
	}

	@ShowSavedSuccessToast()
	createDrugAllergy(request: CreateDrugAllergyRequest) {
		return this.allergyGandalfService.createDrugAllergy(request);
	}

	@ShowSavedSuccessToast()
	updateDrugAllergy(request: UpdateDrugAllergyRequest) {
		return this.allergyGandalfService.updateDrugAllergy(request);
	}

	@ShowSavedSuccessToast()
	createOtherAllergy(request: CreateOtherAllergyRequest) {
		return this.allergyGandalfService.createOtherAllergy(request);
	}

	@ShowSavedSuccessToast()
	updateOtherAllergy(request: UpdateOtherAllergyRequest) {
		return this.allergyGandalfService.updateOtherAllergy(request);
	}

	activateAllergy(allergyId: number) {
		return this.allergyGandalfService.activateAllergy(allergyId);
	}

	deactivateAllergy(allergyId: number) {
		return this.allergyGandalfService.deactivateAllergy(allergyId);
	}

	findActiveDrugAllergies(patientId: number) {
		return this.allergyGandalfService.findActiveDrugAllergiesByPatientId(patientId).pipe(
			map(responses => SortingService.sortBy(responses, ['startDate', 'id'], ['desc'])),
		);
	}

	findMostRecentIntakeByPatientId(patientId: number) {
		return this.allergyGandalfService.findMostRecentIntakeByPatientId(patientId);
	}

	acceptIntakeAllergies(updateIntakeAllergiesRequest: UpdateIntakeAllergiesRequest) {
		return this.allergyGandalfService.acceptIntakeAllergies(updateIntakeAllergiesRequest)
			.pipe(tap(() => this.markIntakeAllergiesAccepted()));
	}

	get intakeAllergiesAccepted() {
		return this._intakeAllergiesAccepted.asObservable();
	}

	markIntakeAllergiesAccepted() {
		this._intakeAllergiesAccepted.next(true);
	}

	declineIntakeAllergies(updateIntakeAllergiesRequest: UpdateIntakeAllergiesRequest) {
		return this.allergyGandalfService.declineIntakeAllergies(updateIntakeAllergiesRequest);
	}

	formatIntakeAllergyResponse(
		intakeAllergyResponse: IntakeAllergyResponse,
		allergyReviewHistory: AllergyReviewHistoryResponse,
	) {
		// Add intakeAllergy items to the table data is being filtered from the server by its status;
		const allergies: FormattedIntakeAllergy[] = intakeAllergyResponse.intakeAllergies.map(item => ({
			intakeAllergyId: item.intakeAllergyId,
			type: IntakeAllergy.ALLERGY,
			question: item.allergyType + ' allergy',
			answer: item.description,
			status: item.status,
			answerFlag: false,
		}));

		// Add noKnownDrugAllergies checkbox value to the table if the checkbox is not being
		// accepted/declined and answer is different than the existing record
		const allergyReviewHistoryNoKnownDrugAllergies = allergyReviewHistory ? allergyReviewHistory.noKnownDrugAllergies : false;
		if (this.shouldShowCheckboxRow(
			intakeAllergyResponse.noKnownDrugAllergies,
			intakeAllergyResponse.noKnownDrugAllergiesReconciledStatus,
			allergyReviewHistoryNoKnownDrugAllergies,
		)) {
			allergies.push({
				intakeAllergyId: null,
				type: IntakeAllergy.NO_KNOWN_DRUG_ALLERGIES,
				question: 'No Known Drug Allergies',
				answer: this.yesNoPipe.transform(intakeAllergyResponse.noKnownDrugAllergies),
				status: IntakeStatus.NEW,
				answerFlag: intakeAllergyResponse.noKnownDrugAllergies,
			});
		}

		// Add noLatexSensitivity checkbox value to the table if the checkbox is not being
		// accepted/declined and answer is different than the existing record
		const allergyReviewHistoryNoLatexSensitivity = allergyReviewHistory ? allergyReviewHistory.noLatexSensitivity : false;
		if (this.shouldShowCheckboxRow(
			intakeAllergyResponse.noLatexSensitivity,
			intakeAllergyResponse.noLatexSensitivityReconciledStatus,
			allergyReviewHistoryNoLatexSensitivity,
		)) {
			allergies.push({
				intakeAllergyId: null,
				type: IntakeAllergy.NO_LATEX_SENSITIVITY,
				question: 'No Known Latex Sensitivity',
				answer: this.yesNoPipe.transform(intakeAllergyResponse.noLatexSensitivity),
				status: IntakeStatus.NEW,
				answerFlag: intakeAllergyResponse.noLatexSensitivity,
			});
		}

		// Add noOtherKnownDrugAllergies checkbox value to the table if the checkbox is not being
		// accepted/declined and answer is different than the existing record
		const allergyReviewHistoryNoOtherKnownAllergies = allergyReviewHistory ? allergyReviewHistory.noOtherKnownAllergies : false;
		if (this.shouldShowCheckboxRow(
			intakeAllergyResponse.noOtherKnownAllergies,
			intakeAllergyResponse.noOtherKnownAllergiesReconciledStatus,
			allergyReviewHistoryNoOtherKnownAllergies,
		)) {
			allergies.push({
				intakeAllergyId: null,
				type: IntakeAllergy.NO_OTHER_KNOWN_ALLERGIES,
				question: 'No Known Other Allergies',
				answer: this.yesNoPipe.transform(intakeAllergyResponse.noOtherKnownAllergies),
				status: IntakeStatus.NEW,
				answerFlag: intakeAllergyResponse.noOtherKnownAllergies,
			});
		}

		return allergies;
	}

	shouldShowCheckboxRow(
		responseValue: boolean,
		reconciledStatus: IntakeReconciledStatus,
		existingValue: boolean,
	): boolean {
		return !_isNil(responseValue)
			&& (EnumUtil.equals(reconciledStatus, IntakeReconciledStatus.PENDING)
				|| _isNil(reconciledStatus))
			&& existingValue !== responseValue;
	}
}
