// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.OrderStatusUpdateRequest */
/* istanbul ignore next */
@GandalfModel
export class OrderStatusUpdateRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Order is required' } })
	@GandalfProperty({ isRequired: true })
	orderId: number;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Status is required' } })
	@GandalfProperty({ isRequired: true })
	orderStatusCode: constants.OrderStatusCode;

}
