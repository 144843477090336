import { Pipe, PipeTransform } from '@angular/core';
import { PersonNameResponse } from '@gandalf/model/person-name-response';

@Pipe({
	name: 'personName',
})
export class PersonNamePipe implements PipeTransform {

	transform(personName: PersonNameResponse): string {
		if (!personName) {
			return '';
		}

		let formattedName = '';
		if (personName.lastName) {
			formattedName = formattedName.concat(personName.lastName);
		}
		if (personName.lastName && personName.firstName) {
			formattedName = formattedName.concat(', ');
		}
		if (personName.firstName) {
			formattedName = formattedName.concat(personName.firstName);
		}

		return formattedName;
	}

}
