// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.timer.CreateProfessionalTimeAdjustmentRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateProfessionalTimeAdjustmentRequest extends GandalfModelBase {

	@GandalfLabel('Encounter')
	@GandalfValidator({ notNull: { message: 'Encounter is required' } })
	@GandalfProperty({ isRequired: true })
	encounterId: number;

	@GandalfLabel('Minutes')
	@GandalfValidator({ notNull: { message: 'Minutes is required' } })
	@GandalfValidator({ min: { min: -999, message: 'Minutes must be greater than or equal to -999' } })
	@GandalfValidator({ max: { max: 999, message: 'Minutes must be less than or equal to 999' } })
	@GandalfProperty({ isRequired: true })
	minutes: number;

	@GandalfLabel('Reason')
	@GandalfValidator({ sizeString: { message: 'Reason cannot be longer than 81 characters', minLength: 0, maxLength: 81 } })
	@GandalfProperty()
	reason: string;

}
