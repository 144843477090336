import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';

@Injectable({providedIn: 'root'})
@Pipe({name: 'practiceLocationName'})
export class PracticeLocationNamePipe implements PipeTransform {

	constructor(
		public securityManagerService: SecurityManagerService,
	) {
	}

	transform(practiceLocationId: number): string {
		const location = this.securityManagerService.getPracticeLocationById(practiceLocationId);
		return !_isNil(location) ? location.name : practiceLocationId.toString();
	}

}
