import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DialogUtil, EnumUtil } from 'morgana';
import { _isEmpty, _isNil } from '@core/lodash/lodash';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { InvoiceItemListResponse } from '@gandalf/model/invoice-item-list-response';
import { InvoiceResponse } from '@gandalf/model/invoice-response';
import { EntityType, PatientFileEntityType, PayerType } from '@gandalf/constants';
import { SearchPatientFilesRequest } from '@gandalf/model/search-patient-files-request';
import { BaseComponent } from '@shared/component/base.component';
import { PatientDocumentsService } from '@shared/component/patient-documents/patient-documents.service';
import { TabAnimationDefaults } from '@shared/constants/tab.constants';
import { TabComponent } from '@syncfusion/ej2-angular-navigations';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { FeatureService } from '@core/feature/feature.service';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { InvoiceService } from '../../core/accounting/invoice-service/invoice.service';

@Component({
	selector: 'pms-invoice-details',
	templateUrl: './invoice-details.component.html',
	styles: [],
})
export class InvoiceDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
	@Input()
	invoiceId: number;

	@Output()
	returnEvent = new EventEmitter<any>();

	@Input()
	isReadOnly: boolean;

	@ViewChild('tabs')
	tabs: TabComponent;

	@Input()
	isTabView: boolean;

	refreshedInvoice: Observable<number>;

	invoice: InvoiceResponse;
	invoiceItems: InvoiceItemListResponse[];
	tabAnimation = TabAnimationDefaults;
	payerType = PayerType;
	invoiceEntityType = EntityType.INVOICE;
	invoicePatientFileEntityType = PatientFileEntityType.INVOICE;
	stateSelectedTab: number;
	tabsCreated = false;
	invoiceRetrieved = false;
	documentsCountTabHeaderText = '';
	processingPaymentGroupId: number;
	isLoadingPaymentGroupId = false;

	constructor(
		private invoiceService: InvoiceService,
		private securityManagerService: SecurityManagerService,
		private patientFileService: PatientDocumentsService,
		private featureService: FeatureService,
	) {
		super();
	}

	ngOnInit() {
		this.findProcessingPaymentGroupId();
		this.populateModal();
		if (!_isNil(this.invoiceService.refreshedInvoice)) {
			this.refreshedInvoice = this.invoiceService.refreshedInvoice.pipe(filter((id) => id === this.invoiceId));
		}
	}

	headingContainerStyling(): string {
		return this.isTabView ? 'mrgn-btm' : 'modal-feature';
	}

	get isInvoicePaymentProcessing(): boolean {
		return !_isNil(this.processingPaymentGroupId);
	}

	findProcessingPaymentGroupId() {
		if (!this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.ACCOUNTING.PAYMENTS.PERFORMANCE)) {
			return;
		}
		this.isLoadingPaymentGroupId = true;
		this.invoiceService.findProcessingPaymentGroupId(this.invoiceId).subscribe((processingPaymentGroupId) => {
			this.isLoadingPaymentGroupId = false;
			this.processingPaymentGroupId = processingPaymentGroupId;
			if (this.processingPaymentGroupId) {
				DialogUtil.alert({
					title: 'Processing',
					content: `Invoice cannot be modified. Payment #${processingPaymentGroupId} is processing.`,
				});
			}
		});
	}

	populateModal() {
		this.invoiceService.getInvoiceDetailsInvoiceState(this.invoiceId).pipe(takeUntil(this.unsubscribe)).subscribe((invoice) => {
			// Get the invoice is it's null when the component is created
			if (_isNil(invoice)) {
				if (!this.isTabView) {
					this.invoiceRetrieved = true; // if the tab view was the first to get the invoice then it needs to destroy this state as well on close
				}
				this.invoiceService.refreshInvoiceDetailsInvoice(this.invoiceId);
			} else {
				this.invoice = invoice;
				this.updateFileCount();
			}
		});
		// if this is a tab view its state isn't managed other than the invoice so that it can be refreshed from many locations easily
		if (this.isTabView) {
			// Listens for invoice state changes
			this.invoiceService.getInvoiceDetailsStateCurrentTab(this.invoiceId)
				.pipe(
					takeUntil(this.unsubscribe),
				)
				.subscribe((currentTab) => {
					this.handleTabState(currentTab);
				});
		}
	}

	handleTabState(currentTab) {
		this.stateSelectedTab = currentTab;
		if (this.tabsCreated) {
			this.setTabSelection();
		}
	}

	submitReturnEvent() {
		this.returnEvent.emit();
	}

	ngOnDestroy() {
		this.handleClosingState();
		super.ngOnDestroy();
	}

	handleClosingState() {
		if (this.isTabView) {
			this.invoiceService.refreshInvoiceDetails(this.invoice, this.stateSelectedTab);
		} else if (this.invoiceRetrieved) {
			this.unsubscribe.next();
			this.invoiceService.removeInvoiceDetailsState(this.invoiceId);
		}
	}

	setTabSelection(tabCreate = false) {
		this.tabsCreated = this.tabsCreated || tabCreate;
		if (!_isNil(this.stateSelectedTab)) {
			this.tabs.select(this.stateSelectedTab);
		}
		if (this.tabsCreated) {
			this.tabs.selected.subscribe(currentTab => {
				this.stateSelectedTab = currentTab.selectedIndex;
			});
		}
	}

	getPayerName() {
		if (EnumUtil.equals(this.invoice.payerType, PayerType.ANONYMOUS) && _isEmpty(this.invoice.payerName)) {
			return 'Guest';
		} else if (EnumUtil.equals(this.invoice.payerType, PayerType.INSURANCE)) {
			return `${this.invoice.payerName} (${this.invoice.insurancePriorityValue} ${this.invoice.insuranceTypeValue})`;
		} else {
			return this.invoice.payerName;
		}
	}

	get isCanada(): boolean {
		return this.securityManagerService.isCanada();
	}

	updateFileCount() {
		// Non-patients (guests) don't have files to display
		if (_isNil(this.invoice.patientId)) {
			return;
		}
		const req = new SearchPatientFilesRequest();
		req.patientId = this.invoice.patientId;
		req.parentId = this.invoice.id;
		req.entityType = PatientFileEntityType.INVOICE;
		this.patientFileService.getPatientFiles(req).subscribe(files => {
			this.documentsCountTabHeaderText = !_isNil(files) && files.length > 0 ? files.length.toString() : '';
		});
	}
}
