@if (report) {
	<div class="report-body print-reconciliation-report">
		<div class="margin-bottom-md">
			<h2 class="margin-0">Inventory Reconciliation Report - {{ report.name }}</h2>
		</div>
		<div class="margin-bottom-md">
			<table [attr.data-test-id]="'reconciliationReportTable'" class="table table-condensed-xs margin-0">
				<tbody>
				<tr>
					<td class="font-weight-bold">Created On</td>
					<td>{{ report.createdOn }}</td>
					<td class="font-weight-bold">Counted On</td>
					<td>{{ report.finalizedOn }}</td>
					<td class="font-weight-bold">Reconciled On</td>
					<td>{{ report.reconciledOn }}</td>
				</tr>
				<tr>
					<td class="font-weight-bold">Created By</td>
					<td>{{ report.createdBy.userName }}</td>
					<td class="font-weight-bold">Counted By</td>
					<td>{{ report.finalizedBy.userName }}</td>
					<td class="font-weight-bold">Reconciled By</td>
					<td>{{ report.reconciledBy.userName }}</td>
				</tr>
				</tbody>
			</table>
		</div>
		<h4>Report Summary</h4>
		<table [attr.data-test-id]="'reconciliationSummaryReportTable'" class="table table-condensed-xs margin-bottom-md">
			<thead>
			<tr class="font-weight-bold">
				<th class="bg-default">&nbsp;</th>
				<th class="bg-default text-right nowrap">Inventory</th>
				<th class="bg-default text-right nowrap">Retail Value</th>
				<th class="bg-default text-right nowrap">Actual Cost</th>
				<th class="bg-default text-right nowrap">Wholesale Price</th>
			</tr>
			</thead>
			<tbody>
			<tr>
				<td class="font-weight-bold">Expected</td>
				<td class="text-right nowrap">{{ report.reportedItems }}</td>
				<td class="text-right nowrap">{{ report.reportedValue }}</td>
				<td class="text-right nowrap">{{ report.reportedActualCost }}</td>
				<td class="text-right nowrap">{{ report.reportedWholesalePrice }}</td>
			</tr>
			<tr>
				<td class="font-weight-bold">Counted</td>
				<td class="text-right nowrap">{{ report.countedItems }}</td>
				<td class="text-right nowrap">{{ report.countedValue }}</td>
				<td class="text-right nowrap">{{ report.countedActualCost }}</td>
				<td class="text-right nowrap">{{ report.countedWholesalePrice }}</td>
			</tr>
			<tr>
				<td class="font-weight-bold">Reconciled</td>
				<td class="text-right nowrap">{{ report.reconciledItems }}</td>
				<td class="text-right nowrap">{{ report.reconciledValue }}</td>
				<td class="text-right nowrap">{{ report.reconciledActualCost }}</td>
				<td class="text-right nowrap">{{ report.reconciledWholesalePrice }}</td>
			</tr>
			</tbody>
		</table>
		<!-- Summary of batches -->
		<h4>Batches</h4>
		<table [attr.data-test-id]="'reconciliationBatchesTable'" class="table table-condensed-xs margin-bottom-md">
			<thead>
			<tr class="font-weight-bold">
				<th class="bg-default">Batch Name</th>
				<th class="bg-default">Created By</th>
				<th class="bg-default text-right nowrap">Counted</th>
				<th class="bg-default text-right nowrap">Counted Retail</th>
			</tr>
			</thead>
			<tbody>
				@for (batch of batches; track batch) {
					<tr>
						<td>{{ batch.name }}</td>
						<td>{{ batch.createdBy.userName }}</td>
						<td class="text-right nowrap">{{ batch.countedItems }}</td>
						<td class="text-right nowrap">{{ batch.countedValue }}</td>
					</tr>
				}
			</tbody>
		</table>
		<!-- Item details -->
		<h4>Reconciliation Details</h4>
		<table [attr.data-test-id]="'reconciliationDetailsTable'" class="table table-condensed-xs margin-bottom-md">
			<thead>
			<tr class="font-weight-bold">
				<th class="bg-default">Category</th>
				<th class="bg-default">Manufacturer</th>
				<th class="bg-default">Model</th>
				<th class="bg-default">Description</th>
				<th class="bg-default">UPC</th>
				<th class="bg-default">SKU</th>
				<th class="bg-default text-right nowrap">Expected</th>
				<th class="bg-default text-right nowrap">Counted</th>
				<th class="bg-default text-right nowrap" ng-show="report.isReconciled">
					Reconciled Inventory
				</th>
				<th class="bg-default" ng-show="report.isReconciled">
					Notes
				</th>
			</tr>
			</thead>
			<tbody>
				@for (item of reconciliationItems; track item) {
					<tr>
						<td>{{ item.productCategoryName }}</td>
						<td>{{ item.productManufacturerName }}</td>
						<td>{{ item.productName }}</td>
						<td>{{ item.itemDescription }}</td>
						<td>{{ item.itemUpc }}</td>
						<td>{{ item.itemSku }}</td>
						<td class="text-right nowrap">
							{{ item.reportedItems }}
						</td>
						<td class="text-right nowrap"
							ng-class="{discrepancy: item.countedItems != item.reportedItems}">
							{{ item.countedItems }}
						</td>
						@if (report.isReconciled) {
							<td class="text-right nowrap"
							>
								{{ item.reconciledItems }}
							</td>
						}
						@if (report.isReconciled) {
							<td>
								{{ item.notes }}
							</td>
						}
					</tr>
				}
			</tbody>
		</table>
	</div>
}
