// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { PhoneNumbersResponse } from './phone-numbers-response';

/** See com.rev360.pms.api.controller.shared.person.PersonDetailsResponse */
/* istanbul ignore next */
@GandalfModel
export class PersonDetailsResponse extends GandalfModelBase {

	@GandalfProperty()
	address: AddressResponse;

	@GandalfProperty()
	credentials: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dateOfBirth: Date;

	@GandalfProperty()
	email: string;

	@GandalfProperty()
	firstName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	gender: constants.Gender;

	@GandalfProperty()
	lastName: string;

	@GandalfProperty()
	middleName: string;

	@GandalfProperty()
	nickname: string;

	@GandalfProperty()
	phoneNumbers: PhoneNumbersResponse;

	@GandalfProperty()
	photoId: number;

	@GandalfProperty()
	ssn: string;

	@GandalfProperty()
	suffix: string;

	@GandalfProperty()
	titleId: number;

}
