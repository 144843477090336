import { ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EventsManagerService, EventsManagerSubscription } from '@core/events-manager/events-manager.service';
import { FEATURE_NAVIGATION_EVENT } from '@core/legacy/hit-pms-html.constants';
import { _isEmpty, _isNil } from '@core/lodash/lodash';
import { ModalManagerService, TooltipService } from 'morgana';
import { NavigationService } from '@core/navigation/navigation.service';
import { PreferredPhoneType } from '@gandalf/constants';
import { AlertResponse } from '@gandalf/model/alert-response';
import { PatientNameResponse } from '@gandalf/model/patient-name-response';
import { PatientPopoverResponse } from '@gandalf/model/patient-popover-response';
import { QuickAddFamilyMemberModalComponent } from '@shared/component/add-patient/quick-add-family-member-modal/quick-add-family-member-modal.component';
import { QuickAddPatientModalComponent } from '@shared/component/add-patient/quick-add-patient-modal/quick-add-patient-modal.component';
import { AlertsService } from '@shared/component/alerts/alerts.service';
import { PatientSearchModalComponent, PatientSearchReturnObject } from '@shared/component/search-patient/patient-search-modal/patient-search-modal.component';
import { FormattedPatientSearchResponse, SearchPatientService } from '@shared/component/search-patient/search-patient.service';
import { ALERTS_MODAL_CONSTANTS } from '@shared/constants/alerts-modal.constants';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { FormattedNamePipe } from '@shared/pipes/formatted-name/formatted-name.pipe';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'pms-search-patient',
	templateUrl: './search-patient.component.html',
	providers: [
		ModalManagerService,
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SearchPatientComponent),
			multi: true,
		},
	],
	styles: [],
})
export class SearchPatientComponent implements OnInit, OnDestroy, ControlValueAccessor {
	patientName: string;
	returnedPatient: FormattedPatientSearchResponse | PatientPopoverResponse;
	patientForPopover: PatientPopoverResponse;
	private openPatientTooltip;
	dateType = DATE_FORMATS.MM_DD_YYYY;
	preferredPhoneType = PreferredPhoneType;
	patientAlerts: AlertResponse[];
	firstSortedActiveAlert: AlertResponse;
	private _patientProvidedFromParent: PatientNameResponse = null;
	private navigationEvent: EventsManagerSubscription;

	@Input()
	includeSelectPatientFromWaitList: boolean;

	@Output()
	waitlistClicked = new EventEmitter<null>();

	@Input()
	includeAddNewPatient: boolean;

	@Input()
	includeAddFamilyMember: boolean;

	@Input()
	includeAlerts: boolean;

	@Input()
	isFocused = false;

	@Input()
	disabled: boolean;

	@Input()
	hideNavigateToPatient = false;

	@Input()
	activeOnly = false;

	@Input()
	set patientProvidedFromParent(value: PatientNameResponse) {
		if (!_isNil(value) && (_isNil(this._patientProvidedFromParent) || this._patientProvidedFromParent.patientId !== value.patientId)) {
			this._patientProvidedFromParent = value;
			this.handlePatientName(value.patientId);
		}
	}

	/* istanbul ignore next */
	get patientProvidedFromParent(): PatientNameResponse {
		return this._patientProvidedFromParent;
	}

	@ViewChild('patientSearchInput')
	patientSearchInput: ElementRef;

	@ViewChild('patientTooltip')
	patientTooltip: ElementRef;

	private openPatientRef: ElementRef;
	patientPopoverTooltip;
	private unsubscribe$ = new Subject<void>();

	@ViewChild('openPatient')
	set openPatient(elementRef: ElementRef) {
		this.openPatientRef = elementRef;
		if (!_isNil(this.openPatientRef)) {
			this.openPatientTooltip = this.tooltipService.buildTooltip('Open Patient', this.openPatientRef.nativeElement);
		} else {
			if (!_isNil(this.openPatientTooltip)) {
				this.openPatientTooltip.instance.destroy();
			}
			this.openPatientTooltip = undefined;
		}
	}

	constructor(
		private navigationService: NavigationService,
		public modalManagerService: ModalManagerService,
		private changeDetectorRef: ChangeDetectorRef,
		private tooltipService: TooltipService,
		public searchPatientService: SearchPatientService,
		private eventsManager: EventsManagerService,
		public formattedNamePipe: FormattedNamePipe,
		public alertsService: AlertsService,
	) {
	}

	/* istanbul ignore next */
	onModelChange: (_: any) => void = () => {
	};

	/* istanbul ignore next */
	onModelTouched: () => void = () => {
	};

	ngOnInit() {
		if (this.includeSelectPatientFromWaitList) {
			this.subscribeToFeatureEvent();
		}
	}

	/* istanbul ignore next */
	ngOnDestroy(): void {
		this.eventsManager.unsubscribeEvent(this.navigationEvent);
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	/* istanbul ignore next */
	registerOnChange(fn: (_: any) => void): void {
		this.onModelChange = fn;
	}

	/* istanbul ignore next */
	registerOnTouched(fn: () => void): void {
		this.onModelTouched = fn;
	}

	writeValue(value: string | number): void {
		if (typeof value === 'string') {
			this.patientName = value;
		} else if (typeof value === 'number') {
			this.getPatientForPopover(value);
		} else {
			this.clearPatient();
		}
		this.changeDetectorRef.markForCheck();
	}

	/* istanbul ignore next */
	openSearchPatientModal(event?: KeyboardEvent) {
		if (!_isNil(event)) {
			event.preventDefault();
		}

		this.modalManagerService.open(PatientSearchModalComponent, {
			data: {
				patientName: this.patientName,
				activeOnly: this.activeOnly,
				canAddFamilyMember: this.includeAddFamilyMember,
			},
		}).onClose
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe((patientSearchObject: PatientSearchReturnObject) => {
				if (!_isNil(patientSearchObject)) {
					if (patientSearchObject.addFamilyMember) {
						this.openAddFamilyMemberModal(patientSearchObject.patient);
					} else {
						this.patientName = patientSearchObject.patient.formattedName;
						this.returnedPatient = patientSearchObject.patient;
						this.handlePatientName(patientSearchObject.patient.patientId);
					}
				}
			});
	}

	getPatientForPopover(patientId: number) {
		this.searchPatientService.getPatientForPopover(patientId)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe((patientForPopover) => {
				this.setPatientPopover(patientForPopover);
			});
	}

	getPatientAlerts(patientId: number) {
		if (this.includeAlerts) {
			this.searchPatientService.getPatientAlerts(patientId)
				.pipe(takeUntil(this.unsubscribe$))
				.subscribe((alerts) => {
					this.handlePatientAlerts(AlertsService.sortAlertsBySeverityThenId(alerts));
				});
		}
	}

	handlePatientAlerts(alerts: AlertResponse[]) {
		const sortedActiveAlerts = AlertsService.filterForActiveAlerts(alerts);
		if (!_isEmpty(sortedActiveAlerts)) {
			this.patientAlerts = alerts;
			this.alertsService.openAlertsModal(sortedActiveAlerts, ALERTS_MODAL_CONSTANTS.DISPLAY_TYPE);
			this.alertsService.displayBannerAlerts(sortedActiveAlerts);
			this.firstSortedActiveAlert = sortedActiveAlerts[0];
		} else {
			this.patientAlerts = null;
			this.firstSortedActiveAlert = null;
		}
	}

	displayStatusAlertsModal() {
		this.alertsService.openAlertsModal(this.patientAlerts, ALERTS_MODAL_CONSTANTS.STATUS);
	}

	displayAlertsFlagButton(): boolean {
		return this.includeAlerts && this.isPatientSet() && !!this.firstSortedActiveAlert;
	}

	setPatientPopover(patientForPopover) {
		if (_isNil(this.patientName)) {
			this.patientName = this.formattedNamePipe.transform(
				patientForPopover.patientName.firstName,
				patientForPopover.patientName.lastName,
			);
			this.returnedPatient = patientForPopover;
		}

		this.patientForPopover = patientForPopover;
		if (!this.changeDetectorRef['destroyed']) {
			this.changeDetectorRef.detectChanges();
		}

		this.patientPopoverTooltip
			= this.tooltipService.buildTooltip(this.patientTooltip.nativeElement, this.patientSearchInput.nativeElement);
	}

	clearPatient() {
		this.patientName = null;
		this.returnedPatient = null;
		if (!_isNil(this.patientPopoverTooltip)) {
			this.patientPopoverTooltip.instance.destroy();
		}
		this.patientAlerts = null;
		this.firstSortedActiveAlert = null;
		this.patientPopoverTooltip = null;
		this.onModelChange(null);
	}

	navigateToPatient() {
		return this.navigationService.navigateToPatient(this.returnedPatient.patientId);
	}

	isPatientSet() {
		return !_isNil(this.returnedPatient);
	}

	isPatientProvidedFromParent() {
		return !_isNil(this._patientProvidedFromParent);
	}

	isPatientForPopoverSet() {
		return !_isNil(this.patientForPopover);
	}

	/* istanbul ignore next */
	openViewWaitList() {
		this.waitlistClicked.emit();
	}

	/* istanbul ignore next */
	subscribeToFeatureEvent() {
		this.navigationEvent = this.eventsManager.subscribe(FEATURE_NAVIGATION_EVENT, (event) => {
			if (!_isNil(event.argument.payload.patientId)) {
				this.clearPatient();
				this.handlePatientName(event.argument.payload.patientId);
			}
		}, this);
	}

	handlePatientName(patientId: number) {
		this.getPatientForPopover(patientId);
		this.getPatientAlerts(patientId);
		setTimeout(() => {
			this.onModelChange(patientId);
		});
	}

	/* istanbul ignore next */
	openAddPatientModal() {
		this.modalManagerService.open(QuickAddPatientModalComponent, {}).onClose
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(patient => {
				if (!_isNil(patient)) {
					this.patientName = patient.formattedName;
					this.returnedPatient = patient;
					this.getPatientForPopover(patient.patientId);
					this.onModelChange(patient.patientId);
				}
			});
	}

	openAddFamilyMemberModal(patient: FormattedPatientSearchResponse) {
		this.modalManagerService.open(QuickAddFamilyMemberModalComponent, {
			data: {
				patientId: patient.patientId,
			},
		}).onClose
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(familyMember => {
				if (!_isNil(familyMember)) {
					this.patientName = familyMember.formattedName;
					this.returnedPatient = familyMember;
					this.getPatientForPopover(familyMember.patientId);
					this.onModelChange(familyMember.patientId);
				}
			});
	}

	shouldShowPatientNavigateButton(): boolean {
		return !this.disabled && !this.hideNavigateToPatient && this.isPatientSet() && !this.isPatientProvidedFromParent();
	}

	/*istanbul ignore next*/
	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
		this.changeDetectorRef.markForCheck();
	}
}
