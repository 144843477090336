// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { InsuranceClaimStatusResponse } from './insurance-claim-status-response';

// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.accounting.claims.EClaimsInsuranceClaimResponse */
/* istanbul ignore next */
@GandalfModel
export class EClaimsInsuranceClaimResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	claimDate: Date;

	@GandalfArray(InsuranceClaimStatusResponse)
	claimStatusMessages: InsuranceClaimStatusResponse[];

	@GandalfProperty({ propertyType: 'LocalDate' })
	claimSubmitDate: Date;

	@GandalfProperty()
	encounterId: number;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	insuranceName: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	invoiceDate: Date;

	@GandalfProperty()
	invoiceId: number;

	@GandalfProperty()
	patientId: number;

	@GandalfProperty()
	patientName: PatientNameResponse;

	@GandalfProperty()
	priority: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	processingStatus: constants.ClaimProcessingStatus;

	@GandalfProperty()
	secondaryPayerPracticeInsuranceCompanyName: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	serviceDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.InsuranceClaimStatus;

	@GandalfProperty({ propertyType: 'LocalDate' })
	statusDate: Date;

}
