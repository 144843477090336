// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { QueryFieldOptionResponse } from './query-field-option-response';

/** See com.rev360.pms.api.controller.query.QueryFieldResponse */
/* istanbul ignore next */
@GandalfModel
export class QueryFieldResponse extends GandalfModelBase {

	@GandalfProperty()
	allowAll: boolean;

	@GandalfProperty()
	criteria: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	dataSource: constants.QueryFieldDataSource;

	@GandalfConstantDecorator()
	@GandalfProperty()
	dataSourceList: constants.QueryFieldDataSourceList;

	@GandalfProperty()
	id: string;

	@GandalfProperty()
	label: string;

	@GandalfArray(QueryFieldOptionResponse)
	options: QueryFieldOptionResponse[];

	@GandalfProperty()
	orderable: boolean;

	@GandalfProperty()
	selectable: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	type: constants.QueryFieldType;

}
