import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector, Type } from '@angular/core';
import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { _isNil } from '@core/lodash/lodash';
import { POPOUT_DATA, PopoutData } from '@core/popout/popout.token';

@Injectable({
	providedIn: 'root',
})
export class PopoutService {

	constructor(
		private injector: Injector,
		private applicationRef: ApplicationRef,
		/* eslint-disable-next-line deprecation/deprecation */
		private componentFactoryResolver: ComponentFactoryResolver,
	) {
	}

	openPopoutWindow(component: Type<any>, data: PopoutData) {
		const windowInstance = window.open('', '_blank', 'toolbar=0,location=0,directories=0,status=0,menubar=1,resizable=1,scrollbars=1');

		setTimeout(() => {
			this.createDomPortalOutlet(component, data, windowInstance);
		}, 1000);
	}

	/* istanbul ignore next */
	createDomPortalOutlet(component: Type<any>, data: PopoutData, windowInstance: Window) {
		if (!windowInstance) {
			return;
		}
		const outlet = new DomPortalOutlet(windowInstance.document.body, this.componentFactoryResolver, this.applicationRef, this.injector);
		document.querySelectorAll('style').forEach(htmlElement => {
			windowInstance.document.head.appendChild(htmlElement.cloneNode(true));
		});
		this.setPopoutStyleSheetElements(component, data, windowInstance, outlet);
	}

	/* istanbul ignore next */
	createComponentContainer(component: Type<any>, data: PopoutData, windowInstance: Window, outlet: DomPortalOutlet) {
		windowInstance.document.title = data.title;
		windowInstance.document.body.innerText = '';
		windowInstance.document.body.className = 'pms-popout-window';
		const injector = Injector.create({providers: [{provide: POPOUT_DATA, useValue: data}], parent: this.injector});
		const containerPortal = new ComponentPortal(component, null, injector);
		outlet.attach(containerPortal);
	}

	/* istanbul ignore next */
	setPopoutStyleSheetElements(component: Type<any>, data: PopoutData, windowInstance: Window, outlet: DomPortalOutlet) {
		let styleSheetElement: HTMLLinkElement;
		document.querySelectorAll('link').forEach(htmlElement => {
			if (htmlElement.rel === 'stylesheet') {
				styleSheetElement = document.createElement('link');
				const absoluteUrl = new URL(htmlElement.href).href;
				styleSheetElement.rel = 'stylesheet';
				styleSheetElement.href = absoluteUrl;
				windowInstance.document.head.appendChild(styleSheetElement);
			}
		});

		if (_isNil(styleSheetElement)) {
			this.createComponentContainer(component, data, windowInstance, outlet);
		} else {
			styleSheetElement.onload = () => {
				this.createComponentContainer(component, data, windowInstance, outlet);
			};
		}

	}
}
