<form
	#categoryForm="ngForm"
	class="form-horizontal"
	[formGroup]="formGroup"
	(ngSubmit)="saveChanges()">
	<div class="panel-feature">
		<button ejs-button iconCss="fa fa-chevron-left" (click)="returnToList(false)" [attr.data-test-id]="'allCategoriesButton'">
			All {{ categoryTitle }}
		</button>
	</div>
	<div class="panel-body">
		<div class="row">
			<div class="col-md-6 col-lg-4">
				<h4 class="section-title bg-default mrgn-btm">Details</h4>
				@if (formGroup.invalid && categoryForm.submitted) {
					<gandalf-lib-validation-messages
						[form]="formGroup"
						[requestObj]="createOrUpdateRequest">
					</gandalf-lib-validation-messages>
				}
				<div class="form-group">
					<label class="col-sm-4 col-md-3 control-label required">Name</label>
					<div class="col-sm-8 col-md-9">
						<input type="text" class="e-input" revFocusInput formControlName="name"/>
					</div>
				</div>
				<div class="form-group">
					<label class="col-sm-4 col-md-3 control-label">Description</label>
					<div class="col-sm-8 col-md-9">
						<input type="text" class="e-input" formControlName="description"/>
					</div>
				</div>
				<div class="form-group">
					<div class="col-sm-8 col-md-9 col-sm-offset-4 col-md-offset-3">
						<ejs-checkbox formControlName="active" label="Active"></ejs-checkbox>
					</div>
				</div>
			</div>
			<div class="col-md-12 col-lg-8" [attr.data-test-id]="'advancedCategoriesDetailSubcategoriesSection'">
				<h4 class="section-title bg-default mrgn-btm">Subcategories</h4>
				<button
					ejs-button
					(click)="openSubCategory()"
					type="button"
					cssClass="e-success margin-bottom-sm"
					iconCss="fa fa-plus"
					[attr.data-test-id]="'advancedCategoriesSubcategoriesAddButton'">
					Add
				</button>
				<ejs-grid #grid
						  [allowPaging]="false"
						  [allowResizing]="true"
						  [allowRowDragAndDrop]="true"
						  [allowSorting]="false"
						  class="table-rowlink"
						  [dataSource]="subCategoryResponses"
						  gridLines="Horizontal"
						  (actionComplete)="reorderCategories()"
						  (rowDrag)="rowDrag()"
						  (rowDragStartHelper)="rowDragStartHelper()"
						  (rowDrop)="rowDropReorder()"
						  (recordClick)="openSubCategory($event.rowData)"
						  [attr.data-test-id]="'advancedCategoriesSubcategoriesTable'">
					<e-columns>
						<e-column field="name" headerText="Name" clipMode="EllipsisWithTooltip"
								  [width]="250"></e-column>
						<e-column field="description" headerText="Description"
								  clipMode="EllipsisWithTooltip"></e-column>
						<e-column field="active" headerText="Status" clipMode="EllipsisWithTooltip" [width]="100">
							<ng-template #template let-data>
								<span>{{ data.active | yesNo : 'Active' : 'Inactive' }}</span>
							</ng-template>
						</e-column>
					</e-columns>
				</ejs-grid>
			</div>
		</div>
	</div>
	<div class="panel-footer">
		<button ejs-button type="submit" [isPrimary]="true" [attr.data-test-id]="'advancedCategoriesDetailSaveButton'">Save</button>
		<button ejs-button type="button" iconCss="fa fa-close" (click)="returnToList(false)" [attr.data-test-id]="'advancedCategoriesDetailCancelButton'">
			Cancel
		</button>
	</div>
</form>
