// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.person.AllergyListResponse */
/* istanbul ignore next */
@GandalfModel
export class AllergyListResponse extends GandalfModelBase {

	@GandalfProperty()
	allergyId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	modifiedDate: Date;

	@GandalfProperty()
	name: string;

	@GandalfArray(Number)
	reactionIds: number[];

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.AllergyStatus;

	@GandalfProperty()
	type: string;

}
