export class TemplateSearchObjectRequest {

	readonly Key: string;

	readonly Value: string;

	constructor(
		key: string,
		value: string,
	) {
		this.Key = key;
		this.Value = value;
	}
}
