// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PhoneInformationRequest } from './phone-information-request';

/** See com.rev360.pms.api.controller.patient.contact.ContactInformationRequest */
/* istanbul ignore next */
@GandalfModel
export class ContactInformationRequest extends GandalfModelBase {

	@GandalfLabel('Email')
	@GandalfValidator({ sizeString: { message: 'Email cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfValidator({ email: { message: 'Invalid email format' } })
	@GandalfProperty()
	email: string;

	@GandalfLabel('Phone numbers')
	@GandalfValidator({ notNull: { message: 'Phone numbers is required' } })
	@GandalfProperty({ isRequired: true })
	phoneInformation: PhoneInformationRequest;

}
