<ejs-dialog
    #modal
	cssClass="modal-lg"
	(close)="closeModal()"
    [attr.data-test-id]="'pullForwardModal'">
	<ng-template #header>
		<div class="dlg-template" [attr.data-test-id]="'pullForwardModalHeader'">Select Encounter</div>
	</ng-template>
	<ng-template #content>
		<div>
			<ejs-grid
                #grid
				autoFitColumns
				class="table-rowlink"
				gridLines="Horizontal"
				allowPaging="true"
				[dataSource]="encounters"
				[allowSorting]="true"
				[allowSelection]="true"
				[allowResizing]="true"
				(rowSelected)="closeModal($event.data)"
				[sortSettings]="sortOptions"
				[pageSettings]="pageSettings"
				[pmsGridSubmit]="isSearching"
				[pmsGridSubmitDefaultSortOptions]="sortOptions"
				[attr.data-test-id]="'pullForwardModalGrid'">
				<e-columns>
					<e-column field="encounterDate" headerText="Date" [format]="tableDateFormat"></e-column>
					<e-column field="templateName" headerText="Type"></e-column>
					<e-column field="subTypeValue" headerText="Category"></e-column>
					<e-column field="formattedEmployeeOrProvider" headerText="Provider"></e-column>
					<e-column field="formattedCPTCodes" headerText="CPT"></e-column>
					<e-column field="formattedICDCodes" headerText="Diagnosis Codes"></e-column>
v				</e-columns>
			</ejs-grid>
		</div>
	</ng-template>
	<ng-template #footerTemplate>
		<button type="button" ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'pullForwardModalCancelButton'">Cancel</button>
	</ng-template>
</ejs-dialog>
