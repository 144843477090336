// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ConnectCampaignMessageListResponse } from './connect-campaign-message-list-response';

/** See com.rev360.pms.api.controller.connect.campaign.ConnectCampaignMessagePagedResponse */
/* istanbul ignore next */
@GandalfModel
export class ConnectCampaignMessagePagedResponse extends GandalfModelBase {

	@GandalfArray(ConnectCampaignMessageListResponse)
	connectCampaignMessageListResponses: ConnectCampaignMessageListResponse[];

	@GandalfProperty()
	currentPage: number;

	@GandalfProperty()
	totalResults: number;

}
