import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

/**
 * Put this attribute directive on a text input field in order to force the field to only accept valid SIN/PHN characters.
 * This directive only allows alphanumeric and dash characters.
 */
@Directive({
	selector: 'input[pmsSinInput]',
})
export class SinInputDirective {

	constructor(
		public elementRef: ElementRef,
		public control: NgControl,
	) {
	}

	@HostListener('input')
	onInput() {
		const inputValue = this.elementRef.nativeElement.value;
		if (inputValue) {
			const updatedValue = inputValue.replace(/[^a-zA-Z0-9-]/g, '');
			if (updatedValue !== inputValue) {
				this.control.control.setValue(updatedValue);
			}
		}
	}

}
