import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { PrinterService } from '@core/printer/printer.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { ToasterService } from '@core/toaster/toaster.service';
import { LocationProductItemForBarcodePrintingResponse } from '@gandalf/model/location-product-item-for-barcode-printing-response';
import { PracticeLocationDefaultLabelPrinterResponse } from '@gandalf/model/practice-location-default-label-printer-response';
import { LocationProductItemBarcodeService } from '@shared/component/label-printing/location-product-item-barcode.service';
import { PracticeLocationLabelPrinterService } from '@shared/component/label-printing/practice-location-label-printer.service';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { lastValueFrom } from 'rxjs';

export interface LabelPrintingConfig {
	printArray: number[];
	singleLabels: boolean;
	successMessage: string;
}

@Component({
	selector: 'pms-label-printing',
	templateUrl: './label-printing.component.html',
})
export class LabelPrintingComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	singleLabels = true;
	complete = 0;
	total = 0;
	modalCanceled = false;
	errorArray: string[] = [];
	private labelPrintingConfig: LabelPrintingConfig;
	foundItems: LocationProductItemForBarcodePrintingResponse[];

	constructor(
		private locationProductItemBarcodeService: LocationProductItemBarcodeService,
		private practiceLocationLabelPrinterService: PracticeLocationLabelPrinterService,
		private printerService: PrinterService,
		private securityManagerService: SecurityManagerService,
		private dynamicModalRef: DynamicModalRef,
		private modalConfig: ModalConfig,
		public toasterService: ToasterService,
	) {
	}

	/* istanbul ignore next */
	ngOnInit() {
		this.labelPrintingConfig = this.modalConfig.data;
		this.foundItems = [];
		this.practiceLocationLabelPrinterService.findDefaultPrinterByPracticeLocationId(
			this.securityManagerService.createSecurityContext().locationId,
		).subscribe(printer => {
			if (printer) {
				let chain = new Promise<void>((resolve: any) => {
					resolve();
				});
				this.labelPrintingConfig.printArray.every(print => {
					chain = chain.then(() => this.printLabel(print, printer));
					return true;
				});

			} else {
				this.errorArray.push('No default printer was found for this Practice Location.');
				this.complete = this.labelPrintingConfig.printArray.length;
			}
		});
		this.total = this.labelPrintingConfig.printArray.length;
	}

	/* istanbul ignore next */
	private printLabel(id: number, printer: PracticeLocationDefaultLabelPrinterResponse) {
		const promise = new Promise<void>((resolve) => {
			resolve();
		});

		return promise.then(() => {
			const foundItem = this.foundItems.find(item => item.id === id);
			if (foundItem !== undefined) {
				return this.printLabelForItem(foundItem, printer, 1);
			} else {
				return this.getLocationProductItemForBarcodePrinting(id, printer);
			}
		},
		);
	}

	/* istanbul ignore next */
	private getLocationProductItemForBarcodePrinting(id: number, printer: PracticeLocationDefaultLabelPrinterResponse) {
		return lastValueFrom(this.locationProductItemBarcodeService.getLocationProductItemForBarcodePrinting(id))
			.then((item) => {
				const quantityToPrint = this.singleLabels ? 1 : item.quantityInStock;
				this.foundItems.push(item);
				if (quantityToPrint < 1) {
					this.complete++;
					return undefined;
				}
				return this.printLabelForItem(item, printer, quantityToPrint);
			});
	}

	printLabelForItem(
		item: LocationProductItemForBarcodePrintingResponse,
		printer: PracticeLocationDefaultLabelPrinterResponse,
		quantityToPrint: number,
	) {
		return this.printerService.printLabelsForItem(item, printer, quantityToPrint).then((result: any) => {
			if (this.modalCanceled) {
				return Promise.reject(new Error('Modal Has closed'));
			}
			this.complete++;
			if (result.Error !== '') {
				this.errorArray.push(item.productName + ' Print Failed');
			} else if (this.printingComplete()) {
				this.toasterService.showSavedSuccess({content: this.labelPrintingConfig.successMessage});
				this.closeDialog();
			}
			return undefined;
		}, () => {
			this.complete++;
			this.errorArray.push(item.productName + ' Print Failed');
		});
	}

	printingComplete() {
		return this.complete === this.total;
	}

	noErrors() {
		return this.errorArray.length === 0;
	}

	cancel() {
		this.modalCanceled = true;
		this.closeDialog();
	}

	closeDialog() {
		this.dynamicModalRef.close(this.modal);
	}
}
