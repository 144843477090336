import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { createSpinner, hideSpinner, showSpinner } from '@syncfusion/ej2-angular-popups';
import { MonoTypeOperatorFunction } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
	selector: 'pms-form-spinner',
	templateUrl: './form-spinner.component.html',
	styles: [],
})
export class FormSpinnerComponent implements OnInit, AfterViewInit {

	spinningCount = 0;
	spinnerActive: boolean;

	@ViewChild('formSpinner')
	formSpinner: ElementRef;

	constructor() {
	}

	ngOnInit() {
		this.spinnerActive = false;
	}

	ngAfterViewInit(): void {
		this.createSpinner();
	}

	addRequest() {
		this.spinningCount++;
		if (this.spinningCount > 0 && !this.spinnerActive) {
			// wait to show the spinner to avoid ExpressionChangedAfterItHasBeenCheckedError if the parent component calls this from ngAfterViewInit
			setTimeout(() => {
				if (this.spinningCount > 0 && !this.spinnerActive) {
					this.showSpinner();
				}
			});
		}
	}

	/**
	 *  delays hiding spinner for a bit to prevent UI issues if another request
	 *  immediately shows the spinner again
	 */
	removeRequest() {
		this.spinningCount--;
		if (this.spinningCount <= 0 && this.spinnerActive) {
			setTimeout(() => {
				if (this.spinningCount <= 0 && this.spinnerActive) {
					this.hideSpinner();
				}
			}, 100);
		}
	}

	loading<T>(): MonoTypeOperatorFunction<T> {
		this.addRequest();
		return tap({
			next: () => this.removeRequest(),
			error: () => this.removeRequest(),
		});
	}

	createSpinner() {
		createSpinner({target: this.formSpinner.nativeElement});
	}

	showSpinner() {
		this.spinnerActive = true;
		showSpinner(this.formSpinner.nativeElement);
	}

	hideSpinner() {
		hideSpinner(this.formSpinner.nativeElement);
		this.spinnerActive = false;
	}

}
