// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { MedicationPrescriptionResponse } from './medication-prescription-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

/** See com.rev360.pms.api.controller.prescription.ExternalMedicationPrescriptionResponse */
/* istanbul ignore next */
@GandalfModel
export class ExternalMedicationPrescriptionResponse extends MedicationPrescriptionResponse {

	@GandalfProperty({ propertyType: 'LocalDate' })
	authorizationDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	authorizationType: constants.PrescriptionAuthorizationType;

	@GandalfProperty()
	authorizedBy: ProviderResponse;

	@GandalfProperty()
	authorizingProviderFirstName: string;

	@GandalfProperty()
	authorizingProviderLastName: string;

	@GandalfProperty()
	bodyLocationId: number;

	@GandalfProperty()
	daysSupply: number;

	@GandalfProperty()
	description: string;

	@GandalfProperty()
	descriptionWithoutDosage: string;

	@GandalfProperty()
	diagnosisCode: string;

	@GandalfProperty()
	diagnosisId: number;

	@GandalfProperty()
	dispenseAmount: number;

	@GandalfProperty()
	dispenseUnitId: number;

	@GandalfProperty()
	doNotSubstitute: boolean;

	@GandalfProperty()
	dosage: string;

	@GandalfProperty()
	dosageAmount: number;

	@GandalfProperty()
	dosageUnitId: number;

	@GandalfProperty()
	drugId: number;

	@GandalfProperty()
	drugName: string;

	@GandalfProperty()
	duration: string;

	@GandalfProperty()
	durationAmount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	durationUnit: constants.DurationUnit;

	@GandalfProperty({ propertyType: 'LocalDate' })
	expirationDate: Date;

	@GandalfProperty()
	externalIdentifier: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	externalMedicationPrescriptionStatus: constants.PrescriptionCurrentStatus;

	@GandalfProperty()
	frequencyId: number;

	@GandalfProperty()
	generalMedicationId: number;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	instructions: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	modifiedDate: Date;

	@GandalfProperty()
	nonCpoeEntry: boolean;

	@GandalfProperty()
	numRefillsAllowed: number;

	@GandalfProperty()
	pharmacyAddress1: string;

	@GandalfProperty()
	pharmacyAddress2: string;

	@GandalfProperty()
	pharmacyCity: string;

	@GandalfProperty()
	pharmacyContactEmail: string;

	@GandalfProperty()
	pharmacyContactFax: string;

	@GandalfProperty()
	pharmacyContactPhone: string;

	@GandalfProperty()
	pharmacyName: string;

	@GandalfProperty()
	pharmacyState: string;

	@GandalfProperty()
	pharmacyZipCode: string;

	@GandalfProperty()
	primaryProviderFirstName: string;

	@GandalfProperty()
	primaryProviderLastName: string;

	@GandalfProperty()
	routeId: number;

	@GandalfProperty()
	startDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.PrescriptionStatus;

	@GandalfProperty()
	stopDate: Date;

	@GandalfProperty()
	useProviderSignature: boolean;

	@GandalfProperty()
	version: number;

}
