// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeResponse } from './contact-lens-eye-response';

/** See com.rev360.pms.api.controller.encounter.contactlens.ContactLensTrialListResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensTrialListResponse extends GandalfModelBase {

	@GandalfProperty()
	commentsOd: string;

	@GandalfProperty()
	commentsOs: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	encounterDate: Date;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	isDispensedOd: boolean;

	@GandalfProperty()
	isDispensedOs: boolean;

	@GandalfProperty()
	od: ContactLensEyeResponse;

	@GandalfProperty()
	os: ContactLensEyeResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	overallLensType: constants.ContactLensPrescriptionType;

	@GandalfProperty()
	productOdName: string;

	@GandalfProperty()
	productOsName: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	trialDate: Date;

}
