import { _cloneDeep } from '@core/lodash/lodash';
import { EncounterResponse } from '@gandalf/model/encounter-response';
import { EncounterHistoryActions, EncounterHistoryActionTypes } from '@patients-store/actions/encounter-history.actions';

export type EncounterHistoryStateMap = Map<number, EncounterHistoryState>;

export class EncounterHistoryState {
	encounters: EncounterResponse[];
	showNonCanceledOnly = false;
}

export const initialState: EncounterHistoryStateMap = new Map();

export function reducer(state = initialState, action: EncounterHistoryActions): EncounterHistoryStateMap {
	switch (action.type) {

		case EncounterHistoryActionTypes.GET_ENCOUNTER_HISTORY_SUCCESS: {
			const encounterHistoryStateMap = _cloneDeep(state);
			const encounterHistoryState = encounterHistoryStateMap.get(action.payload.patientId) || new EncounterHistoryState();
			encounterHistoryState.encounters = action.payload.encounters;
			encounterHistoryStateMap.set(action.payload.patientId, encounterHistoryState);
			return encounterHistoryStateMap;
		}

		case EncounterHistoryActionTypes.REMOVE_ENCOUNTER_HISTORY: {
			const encounterHistoryStateMap = _cloneDeep(state);
			encounterHistoryStateMap.delete(action.payload.patientId);
			return encounterHistoryStateMap;
		}

		case EncounterHistoryActionTypes.UPDATE_SHOW_NON_CANCELED_ONLY: {
			const encounterHistoryStateMap = _cloneDeep(state);
			const encounterHistoryState = encounterHistoryStateMap.get(action.payload.patientId) || new EncounterHistoryState();
			encounterHistoryState.showNonCanceledOnly = action.payload.showNonCanceledOnly;
			encounterHistoryStateMap.set(action.payload.patientId, encounterHistoryState);
			return encounterHistoryStateMap;
		}

		default: {
			return state;
		}
	}
}
