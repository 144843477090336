// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FinanceChargePlanPeriodResponse } from './finance-charge-plan-period-response';

/** See com.rev360.pms.api.controller.admin.accounting.FinanceChargePlanResponse */
/* istanbul ignore next */
@GandalfModel
export class FinanceChargePlanResponse extends GandalfModelBase {

	@GandalfProperty()
	description: string;

	@GandalfProperty()
	id: number;

	@GandalfProperty({ propertyType: 'Money' })
	minimumBalanceUsdDue: number;

	@GandalfProperty()
	name: string;

	@GandalfArray(FinanceChargePlanPeriodResponse)
	periods: FinanceChargePlanPeriodResponse[];

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.FinanceChargePlanStatus;

}
