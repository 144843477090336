// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { InvoiceItemAdjustmentResponse } from './invoice-item-adjustment-response';

// @ts-ignore
import { InvoiceItemDiagnosisResponse } from './invoice-item-diagnosis-response';

// @ts-ignore
import { InvoiceItemModifierResponse } from './invoice-item-modifier-response';

// @ts-ignore
import { InvoiceItemTaxResponse } from './invoice-item-tax-response';

/** See com.rev360.pms.api.controller.accounting.InvoiceItemListResponse */
/* istanbul ignore next */
@GandalfModel
export class InvoiceItemListResponse extends GandalfModelBase {

	@GandalfArray(InvoiceItemDiagnosisResponse)
	additionalDiagnoses: InvoiceItemDiagnosisResponse[];

	@GandalfArray(InvoiceItemModifierResponse)
	additionalModifiers: InvoiceItemModifierResponse[];

	@GandalfProperty({ propertyType: 'Money' })
	adjustmentTotal: number;

	@GandalfProperty({ propertyType: 'Money' })
	amountPaid: number;

	@GandalfProperty({ propertyType: 'Money' })
	balance: number;

	@GandalfProperty()
	code: string;

	@GandalfProperty()
	description: string;

	@GandalfProperty({ propertyType: 'Money' })
	discountTotal: number;

	@GandalfProperty({ propertyType: 'Money' })
	extendedPrice: number;

	@GandalfProperty()
	invoiceItemId: number;

	@GandalfArray(InvoiceItemAdjustmentResponse)
	itemAdjustments: InvoiceItemAdjustmentResponse[];

	@GandalfArray(InvoiceItemTaxResponse)
	itemTaxes: InvoiceItemTaxResponse[];

	@GandalfProperty()
	originalItemId: number;

	@GandalfProperty()
	postedOn: Date;

	@GandalfProperty()
	quantity: number;

	@GandalfProperty()
	split: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.InvoiceItemStatus;

	@GandalfProperty({ propertyType: 'Money' })
	taxAmount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	type: constants.InvoiceItemType;

	@GandalfProperty({ propertyType: 'Money' })
	unitPrice: number;

	@GandalfProperty()
	wasSplit: boolean;

}
