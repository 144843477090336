import { Component, OnInit, ViewChild } from '@angular/core';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { FolderService } from '@core/folder/folder.service';
import { HIT_PMS_HTML_EVENTS } from '@core/legacy/hit-pms-html.constants';
import { _isNil } from '@core/lodash/lodash';
import { DynamicModalRef, MODAL, ModalConfig } from 'morgana';
import { PatientFileEntityType } from '@gandalf/constants';
import { TreeNode } from '@shared/interfaces/treeview';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { FieldsSettingsModel } from '@syncfusion/ej2-navigations/src/treeview/treeview-model';

@Component({
	selector: 'pms-file-scan-modal',
	templateUrl: './file-scan-modal.component.html',
	styles: [],
})
export class FileScanModalComponent implements OnInit {
	@ViewChild('modal')
	modal: DialogComponent;

	folderList: TreeNode[] = [];
	selectedFolderId: string;
	treeFields: FieldsSettingsModel;
	parentId: number;
	patientId: number;
	entityType: PatientFileEntityType;
	encounterId: number;
	zIndexBehindHitPmsHtmlModal = MODAL.Z_INDEX_BEHIND_HIT_PMS_HTML_MODAL;

	constructor(
		private folderService: FolderService,
		private modalConfig: ModalConfig,
		public ref: DynamicModalRef,
		private eventsManager: EventsManagerService,

	) {
		this.patientId = this.modalConfig.data.patientId;
		this.parentId = this.modalConfig.data.parentId;
		this.entityType = this.modalConfig.data.entityType;
		this.encounterId = this.modalConfig.data.encounterId;
	}

	ngOnInit() {
		this.folderService.getPatientFolders().subscribe(folders => {
			this.setupFolderTree(folders);
		});
	}

	setupFolderTree(folders) {
		this.folderList = folders;
		this.folderList[0].expanded = true;
		this.folderList[0].selected = true;
		this.treeFields = {
			id: 'data',
			text: 'label',
			tooltip: 'label',
			child: 'children',
			expanded: 'expanded',
			selected: 'selected',
			dataSource: this.folderList,
		};
		this.selectedFolderId = this.folderList[0].data as string;
	}

	closeModal(result = false) {
		this.ref.close(this.modal, result);
	}

	selectValue(event) {
		this.selectedFolderId = event.nodeData.id;
	}

	buildScannerParams() {
		return {
			patientId: this.patientId,
			folderId: this.selectedFolderId,
			parentId: this.parentId,
			entityId: this.entityType.value,
			encounterId: _isNil(this.encounterId) ? 0 : this.encounterId,
		};
	}

	scan() {
		this.eventsManager.publish(HIT_PMS_HTML_EVENTS.SCANNER.SCANNER_MODULE_SCAN_DOCUMENT, this.buildScannerParams());
		this.eventsManager.subscribe(HIT_PMS_HTML_EVENTS.MODAL.MODAL_CLOSED, () => {
			// When the legacy modal closes, close this modal
			this.closeModal(true);
		});
	}
}
