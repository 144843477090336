<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-sm"
	[attr.data-test-id]="'patientPortionModal'">
	<ng-template #header>
		<div class="dlg-template">Patient Portion</div>
	</ng-template>
	<ng-template #content>
		<form class="form-horizontal"
			  [formGroup]="formGroup"
			  (ngSubmit)="savePatientPortion()"
			  #templateForm="ngForm">
			@if (formGroup.invalid && templateForm.submitted) {
				<gandalf-lib-validation-messages
					[form]="formGroup"
					[requestObj]="request"
					[attr.data-test-id]="'patientPortionModalValidationMessages'">
				</gandalf-lib-validation-messages>
			}
			<h4 class="section-title bg-default mrgn-btm">Patient Portion</h4>
			<div class="form-group">
				<div class="col-sm-5">
					<div class="margin-x-xs">
						<ejs-checkbox #checkbox label="General Co-Pay" name="generalCoPaySelected" formControlName="generalCoPaySelected"></ejs-checkbox>
					</div>
				</div>
				<div class="col-sm-7">
					<div class="row">
						<div class="col-sm-8 col-sm-push-4">
							<pms-currency-input
								htmlId="generalCoPay"
								formControlName="generalCoPay"
								min="0"
								placeholder="">
							</pms-currency-input>
						</div>
					</div>
				</div>
			</div>
			<hr>
			<div class="form-group">
				<div class="col-sm-5">
					<div class="margin-x-xs">
						<ejs-checkbox #checkbox label="Medical Exam Co-Pay" name="medicalExamCoPaySelected"
									  formControlName="medicalExamCoPaySelected"></ejs-checkbox>
					</div>
				</div>
				<div class="col-sm-7">
					<div class="row">
						<div class="col-sm-8 col-sm-push-4">
							<pms-currency-input
								htmlId="medicalExamCoPay"
								formControlName="medicalExamCoPay"
								min="0"
								placeholder="">
							</pms-currency-input>
						</div>
					</div>
				</div>
			</div>
			<hr>
			<div class="form-group">
				<div class="col-sm-5">
					<div class="margin-x-xs">
						<ejs-checkbox #checkbox label="Routine Exam Co-Pay" name="routineExamCoPaySelected"
									  formControlName="routineExamCoPaySelected"></ejs-checkbox>
					</div>
				</div>
				<div class="col-sm-7">
					<div class="row">
						<div class="col-sm-8 col-sm-push-4">
							<pms-currency-input
								htmlId="routineExamCoPay"
								formControlName="routineExamCoPay"
								min="0"
								placeholder="">
							</pms-currency-input>
						</div>
					</div>
				</div>
			</div>
			<hr>
			<div class="form-group">
				<div class="col-sm-5">
					<div class="margin-x-xs">
						<ejs-checkbox #checkbox label="CL Fitting Co-Pay" name="contactLensFittingCoPaySelected"
									  formControlName="contactLensFittingCoPaySelected"></ejs-checkbox>
					</div>
				</div>
				<div class="col-sm-7">
					<div class="row">
						<div class="col-sm-8 col-sm-push-4">
							<pms-currency-input
								htmlId="contactLensFittingCoPay"
								formControlName="contactLensFittingCoPay"
								min="0"
								placeholder="">
							</pms-currency-input>
						</div>
					</div>
				</div>
			</div>
			<hr>
			<div class="form-group">
				<div class="col-sm-5">
					<div class="margin-x-xs">
						<ejs-checkbox #checkbox label="Material Co-Pay" name="materialCoPaySelected" formControlName="materialCoPaySelected"></ejs-checkbox>
					</div>
				</div>
				<div class="col-sm-7">
					<div class="row">
						<div class="col-sm-8 col-sm-push-4">
							<pms-currency-input
								htmlId="materialCoPay"
								formControlName="materialCoPay"
								min="0"
								placeholder="">
							</pms-currency-input>
						</div>
					</div>
				</div>
			</div>
			<hr>
			<div class="form-group">
				<div class="col-sm-5">
					<div class="margin-x-xs">
						<ejs-checkbox #checkbox label="Co-Insurance" name="coInsuranceSelected" formControlName="coInsuranceSelected"></ejs-checkbox>
					</div>
				</div>
				<div class="col-sm-7">
					<div class="row">
						<div class="col-sm-4">
							<input id="coInsurancePercentage" type="number" class="e-input" formControlName="coInsurancePercentage"/>
						</div>
						<div class="col-sm-8">
							<pms-currency-input
								htmlId="coInsuranceAmount"
								formControlName="coInsuranceAmount"
								min="0"
								placeholder="">
							</pms-currency-input>
						</div>
					</div>
				</div>
			</div>
			<hr>
			<div class="form-group">
				<div class="col-sm-5">
					<div class="margin-x-xs">
						<ejs-checkbox #checkbox label="Deductible" name="deductibleSelected" formControlName="deductibleSelected"></ejs-checkbox>
					</div>
				</div>
				<div class="col-sm-7">
					<div class="row">
						<div class="col-sm-8 col-sm-push-4">
							<pms-currency-input
								htmlId="deductible"
								formControlName="deductible"
								min="0"
								placeholder="">
							</pms-currency-input>
						</div>
					</div>
				</div>
			</div>
			<hr>
			@if (allowTransferTax) {
				<div class="form-group">
					@if (transferTaxItemCount > 0 && !transferTaxInvoiceIdIsValid) {
						<div class="col-sm-5">
							<div class="margin-x-xs">
								<ejs-checkbox #checkbox label="Transfer Taxes" name="transferTaxesSelected"
											  formControlName="transferTaxesSelected"></ejs-checkbox>
							</div>
						</div>
					}
					@if (transferTaxItemCount > 0 && !transferTaxInvoiceIdIsValid) {
						<div class="col-sm-7">
							<div class="row">
								<div class="col-sm-8 col-sm-push-4">
									@if (transferTaxItemCount === 1) {
										<p class="form-control-static">{{ transferTaxItemCount }} Item</p>
									}
									@if (transferTaxItemCount !== 1) {
										<p class="form-control-static">{{ transferTaxItemCount }} Items</p>
									}
								</div>
							</div>
						</div>
					}
					@if (transferTaxInvoiceIdIsValid) {
						<div class="col-sm-5">
							<div class="margin-x-xs">
								<ejs-checkbox #checkbox label="Remove Taxes" name="removeTaxesSelected" formControlName="removeTaxesSelected"></ejs-checkbox>
							</div>
						</div>
					}
					@if (transferTaxInvoiceIdIsValid) {
						<div class="col-sm-7">
							<div class="row">
								<div class="col-sm-8 col-sm-push-4">
									<p class="form-control-static" [attr.data-test-id]="'patientPortionInvoiceLabel'">Pt.
										Invoice: {{ transferTaxInvoiceId }}</p>
								</div>
							</div>
						</div>
					}
				</div>
			}
			<h4 class="section-title bg-default mrgn-btm">Add to Invoice</h4>
			<div class="form-group" [attr.data-test-id]="'addToInvoiceFormGroup'">
				<div class="col-sm-5 margin-bottom-xs">
                        <span class="display-inline-block margin-x-xs margin-right-xs">
                          <ejs-radiobutton label="New"
										   name="transferToNewOrExistingInvoice"
										   [value]="TRANSFER_TO_NEW_INVOICE_LABEL"
										   formControlName="transferToNewOrExistingInvoice"
										   [attr.data-test-id]="'addToInvoiceNewRadio'">
                          </ejs-radiobutton>
                        </span>
					@if (showTransferToExistingInvoiceOption) {
						<span class="display-inline-block margin-x-xs margin-right-xs">
                            <ejs-radiobutton label="Existing"
											 name="transferToNewOrExistingInvoice"
											 [value]="TRANSFER_TO_EXISTING_INVOICE_LABEL"
											 formControlName="transferToNewOrExistingInvoice"
											 [attr.data-test-id]="'addToInvoiceExistingRadio'">
                            </ejs-radiobutton>
                          </span>
					}
				</div>
				@if (showTransferToExistingInvoiceOption) {
					<div class="col-sm-7">
						<ejs-dropdownlist id="invoices"
										  [dataSource]="transferToInvoiceList"
										  formControlName="transferToExistingInvoiceId"
										  placeholder="Select Invoice">
						</ejs-dropdownlist>
					</div>
				}
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" type="button" (click)="closeModal()" [attr.data-test-id]="'patientPortionCancelButton'">Cancel</button>
		<button ejs-button [isPrimary]="true" type="submit" (click)="submitForm($event)" [attr.data-test-id]="'patientPortionSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>
