import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	ComponentRef,
	Type,
	ViewChild,
	ViewContainerRef
} from '@angular/core';

/* istanbul ignore next */
@Component({
	selector: 'rev-dynamic-dialog',
	templateUrl: './dynamic-modal.component.html',
})
export class DynamicModalComponent implements AfterViewInit {

	childComponentType: Type<any>;
	@ViewChild('revDynamicModalContent', {read: ViewContainerRef}) insertionPoint: ViewContainerRef;
	componentRef: ComponentRef<any>;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		/* eslint-disable-next-line deprecation/deprecation */
		private componentFactoryResolver: ComponentFactoryResolver,
	) {
	}

	ngAfterViewInit() {
		this.loadChildComponent(this.childComponentType);
		this.changeDetectorRef.detectChanges();
	}

	loadChildComponent(componentType: Type<any>) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);

		const viewContainerRef = this.insertionPoint;
		viewContainerRef.clear();

		/* eslint-disable-next-line deprecation/deprecation */
		this.componentRef = viewContainerRef.createComponent(componentFactory);
	}

}
