import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DebounceSearch } from '@core/debounce/debounce-search';
import { EnumUtil, PAGE_LENGTH, PAGE_LENGTH_LIST } from 'morgana';
import { _filter } from '@core/lodash/lodash';
import { CodeSet, PracticeDiagnosisStatus } from '@gandalf/constants';
import { PracticeDiagnosisResponse } from '@gandalf/model/practice-diagnosis-response';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { GridComponent, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { AdminDiagnosisService, PracticeDiagnosisResponseForTable } from '../admin-diagnosis.service';

@Component({
	selector: 'pms-diagnosis-list',
	templateUrl: './diagnosis-list.component.html',
	styles: [],
})
export class DiagnosisListComponent implements OnInit {

	@Input()
	diagnosisType: CodeSet;

	@Output()
	openDiagnosisEvent = new EventEmitter<PracticeDiagnosisResponse>();

	@ViewChild('grid')
	grid: GridComponent;

	pageSettings: PageSettingsModel = {
		pageSize: PAGE_LENGTH.PAGE_10,
		pageSizes: PAGE_LENGTH_LIST,
	};
	allDiagnoses: PracticeDiagnosisResponseForTable[];
	filteredDiagnoses: PracticeDiagnosisResponseForTable[];

	diagnosisStatus = PracticeDiagnosisStatus;
	statusFilterOptions = [
		PracticeDiagnosisStatus.ACTIVE,
		PracticeDiagnosisStatus.INACTIVE,
	];
	statusFilter = this.diagnosisStatus.ACTIVE;
	debouncedFilter = new DebounceSearch(() => this.filterTable());
	codeFilter = '';
	isSearching = false;
	dateFormat = DATE_FORMATS.MM_DD_YYYY;

	constructor(
		public diagnosisService: AdminDiagnosisService,
	) {
	}

	ngOnInit(): void {
		this.getDiagnosesList();
	}

	getDiagnosesList(): void {
		this.isSearching = true;
		this.diagnosisService.findPracticeDiagnosesForPractice(this.diagnosisType).subscribe((data: PracticeDiagnosisResponseForTable[]) => {
			this.allDiagnoses = data;
			this.filterTable();
			this.isSearching = false;
		});
	}

	openDiagnosisDetails(row: PracticeDiagnosisResponse) {
		this.openDiagnosisEvent.emit(row);
	}

	/*istanbul ignore next*/
	debounceFilter(): void {
		this.debouncedFilter.valueChanged.next({
			codeFilter: this.codeFilter,
		});
	}

	filterTable(): void {
		if (this.statusFilter.value !== null || this.codeFilter !== '') {
			this.filteredDiagnoses = _filter(this.allDiagnoses, diagnosis => this.showDiagnosis(diagnosis));
		} else {
			this.filteredDiagnoses = this.allDiagnoses;
		}
	}

	/*istanbul ignore next*/
	showDiagnosis(diagnosis: PracticeDiagnosisResponseForTable): boolean {
		if (this.statusFilter.value !== null && !EnumUtil.equals(diagnosis.status, this.statusFilter)) {
			return false;
		}
		if (this.codeFilter !== null) {
			return (diagnosis.code.toLowerCase().indexOf(this.codeFilter.toLowerCase()) !== -1
				|| diagnosis.shortDescription.toLowerCase().indexOf(this.codeFilter.toLowerCase()) !== -1);
		}
		return true;
	}

	activateDiagnosis(diagnosis: PracticeDiagnosisResponseForTable) {
		if (EnumUtil.equals(diagnosis.status, this.diagnosisStatus.INACTIVE)) {
			this.isSearching = true;
			this.diagnosisService.activatePracticeDiagnosis(diagnosis.id).subscribe(() => {
				this.getDiagnosesList();
			});
		}
	}

	deactivateDiagnosis(diagnosis: PracticeDiagnosisResponseForTable) {
		if (EnumUtil.equals(diagnosis.status, this.diagnosisStatus.ACTIVE)) {
			this.isSearching = true;
			this.diagnosisService.deactivatePracticeDiagnosis(diagnosis.id).subscribe(() => {
				this.getDiagnosesList();
			});
		}
	}

	/*istanbul ignore next*/
	clearSelectedRow() {
		this.grid.clearSelection();
	}
}
