<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-sm"
	[attr.data-test-id]="'voidInvoiceOpenEdgeAlertModal'">
	<ng-template #header>
		<div class="dlg-template">Void Invoice</div>
	</ng-template>
	<ng-template #content>
		@if (isLoaded) {
			<div class="alert alert-warning" role="alert"><strong>Warning:</strong> Voiding cannot be undone.</div>
			@if (useOpenEdgeWarning) {
				<p [attr.data-test-id]="'voidInvoiceWarningLabel'">
					A payment processed via Global Payments is associated to this invoice. Voiding the invoice will also void the payment.
					<strong>We strongly recommend that you not proceed and instead void the payment first and then void the invoice.</strong> Are you sure you
					wish to proceed?
				</p>
			}
			@if (!useOpenEdgeWarning) {
				<p [attr.data-test-id]="'voidInvoicePaymentAssociationWarningLabel'">A payment is associated to this invoice. Voiding the invoice will also void
					the payment. Are you sure you wish to proceed?</p>
			}
		}
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button (click)="closeModal()" [attr.data-test-id]="'voidInvoiceCloseButton'">No</button>
		@if (isLoaded) {
			<button ejs-button [isPrimary]="true" (click)="proceedWithVoidInvoice()" [attr.data-test-id]="'voidInvoiceApproveButton'">Yes</button>
		}
	</ng-template>
</ejs-dialog>
