export const EVENT_MANAGER_CONSTANTS = {
	ACCOUNTING: {
		INVOICES_UPDATED: 'ACCOUNTING_MODULE_INVOICES_UPDATE',
		CREDITS_UPDATED: 'ACCOUNTING_MODULE_CREDITS_UPDATED',
		REFUNDS_UPDATED: 'ACCOUNTING_MODULE_REFUNDS_UPDATED',
		STATEMENTS_UPDATED: 'ACCOUNTING_MODULE_STATEMENTS_UPDATED',
		PAYMENTS_UPDATED: 'ACCOUNTING_MODULE_PAYMENTS_UPDATED',
	},
	ENCOUNTER: {
		CHECKOUT_TASKS_UPDATED: 'ENCOUNTER_MODULE_CHECKOUT_TASKS_UPDATE',
		CODING_SCREEN_ACTIVE: 'ENCOUNTER_MODULE_CODING_SCREEN_ACTIVE',
	},
	GLOBAL: {
		IMAGE_VIEWER: 'IMAGE_VIEWER',
		PDF_VIEWER: 'PDF_VIEWER',
	},
	PATIENTS: {
		EYEGLASS_PRESCRIPTIONS_UPDATED: 'EYEGLASS_PRESCRIPTIONS_UPDATED',
		AMENDMENTS_UPDATED: 'PATIENTS_AMENDMENTS_UPDATED',
		EXTERNAL_DATA_UPDATED: 'PATIENTS_EXTERNAL_DATA_UPDATED',
		ALERTS_UPDATED: 'PATIENT_ALERTS_UPDATED',
		HEALTH_GOALS_UPDATED: 'HEALTH_GOALS_UPDATED',
		TASKS_UPDATED: 'PATIENT_TASKS_UPDATED',
		HEALTH_CONCERNS_UPDATED: 'PATIENT_HEALTH_CONCERNS_UPDATED',
		RECALL_RELOAD_LIST: 'PATIENT_RECALL_RELOAD_LIST',
		NOTES_UPDATED: 'PATIENT_NOTES_UPDATED',
		IMPLANTABLE_DEVICES_UPDATED: 'PATIENT_IMPLANTABLE_DEVICES_UPDATED',
		SCHEDULING_PREFERENCES_UPDATED: 'PATIENT_SCHEDULING_PREFERENCES_UPDATED',
		IMMUNIZATIONS_UPDATED: 'PATIENT_IMMUNIZATIONS_UPDATED',
		LETTER_EDITOR: 'REV_360_PATIENT_LETTER_EDITOR',
	},
	SCHEDULE: {
		WAIT_LIST_UPDATED: 'WAIT_LIST_UPDATED',
	},
	HEADER: {
		TODAYS_PATIENTS_OPEN: 'TODAYS_PATIENTS_OPEN',
		TODAYS_PATIENTS_RESIZE: 'TODAYS_PATIENTS_RESIZE',
	},
};
