// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.query.QueryCriteriaFieldRequest */
/* istanbul ignore next */
@GandalfModel
export class QueryCriteriaFieldRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { min: 0, max: 100, message: 'Field ID length must be less than or equal to {max} characters.' } })
	@GandalfValidator({ notNull: { message: 'Field ID is required.' } })
	@GandalfProperty({ isRequired: true })
	id: string;

	@GandalfValidator({ sizeString: { min: 0, max: 100, message: 'Label length must be less than or equal to {max} characters.' } })
	@GandalfProperty()
	label: string;

	@GandalfValidator({ sizeString: { min: 0, max: 5000, message: 'Value 1 length must be less than or equal to {max} characters.' } })
	@GandalfProperty()
	operand1: string;

	@GandalfValidator({ sizeString: { min: 0, max: 100, message: 'Operand Label length must be less than or equal to {max} characters.' } })
	@GandalfProperty()
	operand1Label: string;

	@GandalfValidator({ sizeString: { min: 0, max: 5000, message: 'Value 2 length must be less than or equal to {max} characters.' } })
	@GandalfProperty()
	operand2: string;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Operator is required.' } })
	@GandalfProperty({ isRequired: true })
	operator: constants.QueryCriteriaOperator;

	@GandalfConstantDecorator()
	@GandalfProperty()
	outerOperator: constants.QueryCriteriaOuterOperator;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Field type is required.' } })
	@GandalfProperty({ isRequired: true })
	type: constants.QueryFieldType;

}
