// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.preferences.PatientSchedulingPreferenceResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientSchedulingPreferenceResponse extends GandalfModelBase {

	@GandalfProperty()
	allowPhrScheduling: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	friday: constants.SchedulingPreferencesTimeOfDay;

	@GandalfProperty()
	generalNote: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	monday: constants.SchedulingPreferencesTimeOfDay;

	@GandalfConstantDecorator()
	@GandalfProperty()
	saturday: constants.SchedulingPreferencesTimeOfDay;

	@GandalfConstantDecorator()
	@GandalfProperty()
	scheduleRisk: constants.SchedulingPreferencesScheduleRisk;

	@GandalfConstantDecorator()
	@GandalfProperty()
	sunday: constants.SchedulingPreferencesTimeOfDay;

	@GandalfConstantDecorator()
	@GandalfProperty()
	thursday: constants.SchedulingPreferencesTimeOfDay;

	@GandalfConstantDecorator()
	@GandalfProperty()
	tuesday: constants.SchedulingPreferencesTimeOfDay;

	@GandalfConstantDecorator()
	@GandalfProperty()
	wednesday: constants.SchedulingPreferencesTimeOfDay;

}
