import { Component, OnInit } from '@angular/core';
import { RouterParam } from '@app-store/selectors';
import { RouterStoreUtils } from '@app-store/utils/router-store-utils';
import { SyncInvoiceTab } from '@invoices-store/actions';
import { InvoiceTabStateMap } from '@invoices-store/reducers/invoice-tab.reducer';
import { Store } from '@ngrx/store';

@Component({
	selector: 'pms-invoice-tab',
	templateUrl: './invoice-tab.component.html',
	styles: [],
})
export class InvoiceTabComponent implements OnInit {

	invoiceId: number;

	constructor(
		private routerStoreUtils: RouterStoreUtils,
		private store: Store<InvoiceTabStateMap>,
	) {
	}

	ngOnInit() {
		this.setupInvoiceTab();
	}

	private setupInvoiceTab() {
		this.invoiceId = this.routerStoreUtils.getRouterParam(RouterParam.INVOICES_INVOICE_ID);
		this.store.dispatch(new SyncInvoiceTab({invoiceId: this.invoiceId}));
	}
}
