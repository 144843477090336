import { Component, OnInit, ViewChild } from '@angular/core';
import { CarePlanTemplateService } from '@core/diagnosis/care-plan-template.service';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { CarePlanTemplateListResponse } from '@gandalf/model/care-plan-template-list-response';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { SortSettingsModel } from '@syncfusion/ej2-grids';

@Component({
	selector: 'pms-care-plan-template-modal',
	templateUrl: './care-plan-template-modal.component.html',
})
export class CarePlanTemplateModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	carePlanTemplateId: number;
	carePlanTemplate: CarePlanTemplateListResponse;
	loading: boolean;
	sortSettings: SortSettingsModel = {
		columns: [
			{field: 'category.label', direction: 'Ascending'},
			{field: 'description', direction: 'Ascending'},
		],
	};

	constructor(
		private dynamicModalRef: DynamicModalRef,
		private modalConfig: ModalConfig,
		private carePlanTemplateService: CarePlanTemplateService,
	) {
	}

	ngOnInit() {
		this.parseModalConfig(this.modalConfig.data);
		this.loadData();
	}

	parseModalConfig(data: any) {
		this.carePlanTemplateId = data.carePlanTemplateId;
	}

	loadData() {
		this.loading = true;
		this.carePlanTemplateService.getCarePlanTemplateById(this.carePlanTemplateId).subscribe(carePlanTemplate => {
			this.loading = false;
			this.carePlanTemplate = carePlanTemplate;
		});
	}

	/* istanbul ignore next: closeModal */
	closeModal() {
		this.dynamicModalRef.close(this.modal);
	}

}
