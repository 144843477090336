import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GRID_STABILIZE_DEBOUNCE } from 'morgana';
import { _isNil } from '@core/lodash/lodash';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridOptions } from 'ag-grid-community';

@Component({
	selector: 'pms-ag-grid-print-template',
	templateUrl: './ag-grid-print-template.component.html',
	styles: [],
})
export class AgGridPrintTemplateComponent implements OnInit {

	@ViewChild('agGrid')
	agGrid: AgGridAngular;

	@Input()
	dataSource: any[];

	@Input()
	gridOptions: GridOptions;

	@Input()
	columns: ColDef[];

	@Output()
	gridReady = new EventEmitter<void>();

	constructor() {
	}

	ngOnInit(): void {
		this.modifyGridOptionsForPrint();
		this.modifyColumnsForPrint();
	}

	modifyGridOptionsForPrint() {
		this.gridOptions.suppressPaginationPanel = true;
		this.gridOptions.pagination = false;
		this.gridOptions.domLayout = 'print';
		this.gridOptions.suppressColumnVirtualisation = true;
		this.gridOptions.suppressMaxRenderedRowRestriction = true;
	}

	modifyColumnsForPrint() {
		this.columns.map((column) => {
			if (!_isNil(column['printWidth'])) {
				column.width = column['printWidth'];
			}

			column.flex = null;
			column.suppressAutoSize = true;
			column.suppressMovable = true;
			column.suppressSizeToFit = true;

			return column;
		});
	}

	onGridReady() {
		setTimeout(() => {
			this.gridReady.emit();
		}, GRID_STABILIZE_DEBOUNCE);
	}
}
