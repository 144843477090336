import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GridUtil, TypeSafeModalManagerService, YesNoPipe } from 'morgana';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { OptionItemResponse } from '@core/option-item/option-item.service';
import { PracticeLocation, SecurityManagerService } from '@core/security-manager/security-manager.service';
import { UserLocationsService } from '@core/user-locations/user-locations.service';
import { _isNil } from '@core/lodash/lodash';
import { RevClearExternalCredentialResponse } from '@gandalf/model/rev-clear-external-credential-response';
import { SecurityRoleDropdownResponse, SecurityRoleService } from '@core/security/security-role.service';
import { EmployeeService, EmployeeWithRolesDropdownResponse } from '@core/employee/employee.service';
import { combineLatest } from 'rxjs';
import { ToasterService } from '@core/toaster/toaster.service';
import { UpdateRevClearConfigurationRequest } from '@gandalf/model/update-rev-clear-configuration-request';
import { FeatureService } from '@core/feature/feature.service';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { GandalfFormBuilder, TypedFormGroup } from 'gandalf';
import { PreferenceDefaults, PreferenceName } from '@gandalf/constants';
import { conditionallyRequiredValidator } from '@shared/validators/conditionally-required-validation';
import { NgForm } from '@angular/forms';
import { FormattedRevClearExternalCredentialResponse, AutopostingConfigurationRevClearTemplateService } from './autoposting-configuration-rev-clear-template.service';
import {
	AutopostingConfigurationRevClearExternalCredentialModalComponent
} from './autoposting-configuration-rev-clear-external-credential-modal/autoposting-configuration-rev-clear-external-credential-modal.component';


export enum FilterOptions {
	ALL = 'All',
	ACTIVE = 'Active',
	INACTIVE = 'Inactive',
}

@Component({
	selector: 'pms-autoposting-configuration-rev-clear-template',
	templateUrl: './autoposting-configuration-rev-clear-template.component.html',
	providers: [TypeSafeModalManagerService],
})
export class AutopostingConfigurationRevClearTemplateComponent implements OnInit {

	@ViewChild('agGrid')
	agGrid: AgGridAngular;

	@ViewChild('enableDisableButtonColumn')
	enableDisableButtonColumn: TemplateRef<any>;

	@ViewChild('configurationForm')
	configurationForm: NgForm;

	hasAutoPosting: boolean;
	gridOptions = GridUtil.buildGridOptions({
		pagination: false,
		rowClass: 'row-link',
	});
	revClearExternalCredentials: FormattedRevClearExternalCredentialResponse[];
	gridColumns: ColDef[];
	isUpdating: boolean;
	locations: OptionItemResponse<PracticeLocation, number>[];
	statusFilter = FilterOptions.ACTIVE;
	statusFilterOptions = [
		{label: 'All', value: FilterOptions.ALL},
		{label: 'Active', value: FilterOptions.ACTIVE},
		{label: 'Inactive', value: FilterOptions.INACTIVE},
	];
	currentPersonIds: number[];
	selectedPersonIds: number[];
	securityRoles: SecurityRoleDropdownResponse[];
	employees: EmployeeWithRolesDropdownResponse[];
	isLoading = false;
	siteId: string;
	activeLocations: OptionItemResponse<PracticeLocation, number>[];
	defaultPaymentPracticeLocationId: number;
	shouldShowDefaultPaymentPracticeLocation: boolean;
	shouldShowTasks: boolean;
	formGroup: TypedFormGroup<UpdateRevClearConfigurationRequest>;
	updateRevClearConfigurationRequest: UpdateRevClearConfigurationRequest;

	constructor(
		private autopostingConfigurationRevClearTemplateService: AutopostingConfigurationRevClearTemplateService,
		private userLocationsService: UserLocationsService,
		private typeSafeModalManagerService: TypeSafeModalManagerService,
		private yesNoPipe: YesNoPipe,
		private securityRoleService: SecurityRoleService,
		private employeeService: EmployeeService,
		private toasterService: ToasterService,
		private featureService: FeatureService,
		private securityManager: SecurityManagerService,
		private gandalfFormBuilder: GandalfFormBuilder,
	) {
	}

	ngOnInit() {
		this.hasAutoPosting = this.autopostingConfigurationRevClearTemplateService.hasAutoPosting();
		this.activeLocations = this.userLocationsService.getAllActiveLocations();
		this.shouldShowDefaultPaymentPracticeLocation = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.ACCOUNTING.AUTO_POSTING_V2.LOCATION_PAYMENT_GROUPING)
			&& this.securityManager.preferenceValueIsOn(
				PreferenceName.ACCOUNTING_ERA_AUTOPOSTING_PAYMENT_GROUP_LOCATION.value,
				PreferenceDefaults.ACCOUNTING_ERA_AUTOPOSTING_PAYMENT_GROUP_LOCATION.value,
			);
		this.shouldShowTasks = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.ACCOUNTING.AUTO_POSTING_V2.TASK_ASSIGNMENT);
		this.getCredentialData();
		this.getDataDebounced();
	}

	getCredentialData() {
		this.autopostingConfigurationRevClearTemplateService.findRevClearExternalCredentials().subscribe(credentials => {
			this.revClearExternalCredentials = credentials;
			this.locations = this.getAvailableLocations();
		});
	}

	getDataDebounced() {
		if (!this.isLoading) {
			this.getData();
		}
	}

	getData() {
		this.isLoading = true;
		combineLatest([
			this.securityRoleService.findSecurityRolesForDropdown(),
			this.employeeService.findActiveEmployeesWithSecurityRolesByPractice(),
			this.autopostingConfigurationRevClearTemplateService.findRevClearConfiguration(),
		]).subscribe(([securityRoles, employees, revClearConfiguration]) => {
			this.securityRoles = securityRoles;
			this.employees = employees;
			this.selectedPersonIds = revClearConfiguration.personIdsToBeNotified;
			this.currentPersonIds = revClearConfiguration.personIdsToBeNotified;
			this.siteId = revClearConfiguration.siteId;
			this.defaultPaymentPracticeLocationId = revClearConfiguration.defaultPaymentLocationId;
			this.isLoading = false;
			this.buildForm();
		});
	}

	buildForm() {
		this.updateRevClearConfigurationRequest = new UpdateRevClearConfigurationRequest();
		this.updateRevClearConfigurationRequest.personIds = this.selectedPersonIds;
		this.updateRevClearConfigurationRequest.defaultPaymentPracticeLocationId = this.shouldShowDefaultPaymentPracticeLocation ? this.defaultPaymentPracticeLocationId : null;
		this.formGroup = this.gandalfFormBuilder.groupTyped(this.updateRevClearConfigurationRequest);
		this.formGroup.addValidators([
			conditionallyRequiredValidator(
				'defaultPaymentPracticeLocationId',
				() => this.shouldShowDefaultPaymentPracticeLocation,
				'defaultPaymentPracticeLocationIdRequired',
				'Default Payment Location is required',
			),
		]);
	}

	getAvailableLocations() {
		return this.userLocationsService.getAllActiveLocations()
			// Filter out locations already assigned a credential
			.filter(location => !this.revClearExternalCredentials.find(credential => credential.locations.find(credentialLocation => credentialLocation.id === location.id)));
	}

	onGridReady(_event: GridReadyEvent) {
		this.buildGridColumns();
		this.filterTable();
	}

	buildGridColumns() {
		this.gridColumns = [
			GridUtil.buildColumn('Site ID', 'userName', {
				minWidth: 105,
			}),
			GridUtil.buildColumn('Password', 'password', {
				minWidth: 105,
			}),
			GridUtil.buildColumnWithEllipses('Location(s)', 'formattedLocationListString', {
				flex: 1,
				minWidth: 150,
			}),
			GridUtil.buildColumn('Auto-Posting', 'autoPostingEnabled', {
				width: 125,
				hide: !this.hasAutoPosting,
				suppressExport: !this.hasAutoPosting,
				valueGetter: (params) => this.yesNoPipe.transform(params.data.autoPostingEnabled),
			}),
			GridUtil.buildColumn('Status', 'active', {
				width: 105,
				valueGetter: (params) => this.yesNoPipe.transform(params.data.active, 'Active', 'Inactive'),
			}),
			GridUtil.buildButtonColumn('', this.enableDisableButtonColumn, {
				width: 45,
			}),
		];

		this.agGrid.api.setGridOption('columnDefs', this.gridColumns);
	}

	filterTable() {
		GridUtil.applyFilter(this.agGrid, this.applyStatusFilter, 'active', this.statusFilter);
	}

	applyStatusFilter = () => !_isNil(this.statusFilter) && this.statusFilter !== FilterOptions.ALL;

	openModalWithRefresh(data?: RevClearExternalCredentialResponse) {
		this.openAutopostingConfigurationRevClearCredentialDetailModal(data).onClose.subscribe(refresh => {
			if (refresh) {
				this.getCredentialData();
			}
		});
	}

	updateStatus(data: RevClearExternalCredentialResponse) {
		if (this.isUpdating) {
			return;
		}

		this.isUpdating = true;
		if (data.active) {
			this.disableRevClearExternalCredential(data.revClearExternalCredentialId);
		} else {
			this.enableRevClearExternalCredential(data);
		}
	}

	disableRevClearExternalCredential(credentialId: number) {
		this.autopostingConfigurationRevClearTemplateService.disableRevClearExternalCredential(credentialId).subscribe(() => {
			this.getCredentialData();
			this.isUpdating = false;
		});
	}

	enableRevClearExternalCredential(data: RevClearExternalCredentialResponse) {
		this.openAutopostingConfigurationRevClearCredentialDetailModal(data).onClose.subscribe(() => {
			this.getCredentialData();
			this.isUpdating = false;
		});
	}

	handleEmployeeSecurityRoleSelect(event: any) {
		this.selectedPersonIds = [];
		this.employees.forEach(employee => {
			if (!_isNil(employee.securityRoles.find(role => role.id === event.itemData.id))) {
				this.selectedPersonIds.push(employee.personId);
			}
		});
		this.formGroup.controls.personIds.setValue(this.selectedPersonIds);
	}

	submitForm(event: Event) {
		this.configurationForm.onSubmit(event);
	}

	updateRevClearConfiguration() {
		if (this.shouldPreventConfigurationSave()) {
			return;
		}
		this.autopostingConfigurationRevClearTemplateService.updateRevClearConfiguration(this.formGroup.value as UpdateRevClearConfigurationRequest).subscribe(() => {
			this.toasterService.showSavedSuccess();
			this.getDataDebounced();
		});
	}

	shouldPreventConfigurationSave() {
		return this.currentPersonIds === this.formGroup.controls.personIds.value
			&& this.defaultPaymentPracticeLocationId === this.formGroup.controls.defaultPaymentPracticeLocationId?.value
			|| this.formGroup.invalid;
	}

	private openAutopostingConfigurationRevClearCredentialDetailModal(response?: RevClearExternalCredentialResponse) {
		return this.typeSafeModalManagerService.open(AutopostingConfigurationRevClearExternalCredentialModalComponent, {
			hasAutoPosting: this.hasAutoPosting,
			revClearExternalCredentialId: response?.revClearExternalCredentialId,
			userName: response?.userName,
			password: response?.password,
			autoPostingEnabled: response?.autoPostingEnabled,
			assignedLocations: response?.locations,
			availableLocations: this.locations,
			securityRoles: this.securityRoles,
			employees: this.employees,
		});
	}
}
