import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
@Pipe({ name: 'yesNo' })
export class YesNoPipe implements PipeTransform {

	constructor() {
	}

	/**
	 * @ngdoc filter
	 * @description
	 * Takes a value and determines whether its true or false, then returns Yes or No.
	 *
	 * @param value - The value being tested.
	 *
	 * @param trueText - Optional parameter to replace Yes with something else, such as Active.
	 *
	 * @param falseText - Optional parameter to replace No with something else, such as Inactive.
	 *
	 * @usage
	 * {{ true | yesNo }}
	 * {{ data.isActive | yesNo : 'Active' : 'Inactive' }}
	 */
	transform(value: any, trueText = 'Yes', falseText = 'No'): string {
		/* eslint-disable-next-line no-extra-boolean-cast */
		return !!value ? trueText : falseText;
	}

}
