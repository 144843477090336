import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'printableOnly',
})
@Injectable({
	providedIn: 'root',
})
export class PrintableOnlyPipe implements PipeTransform {

	transform(value: string): string {
		return value?.replace(/[^ -~]+/gi, '');
	}

}
