// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.connect.ConnectMessageForPatientRecallRequest */
/* istanbul ignore next */
@GandalfModel
export class ConnectMessageForPatientRecallRequest extends GandalfModelBase {

	@GandalfConstantDecorator()
	@GandalfLabel('Message Method')
	@GandalfValidator({ notNull: { message: 'Message Method is required' } })
	@GandalfProperty({ isRequired: true })
	connectMessageMethod: constants.ConnectMessageMethod;

	@GandalfLabel('Patient')
	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientId: number;

	@GandalfLabel('Recall')
	@GandalfValidator({ notNull: { message: 'Recall is required' } })
	@GandalfProperty({ isRequired: true })
	patientRecallId: number;

}
