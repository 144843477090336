// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.plan.EncounterCreateCarePlanItemForPersonDiagnosisRequest */
/* istanbul ignore next */
@GandalfModel
export class EncounterCreateCarePlanItemForPersonDiagnosisRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'addToLibrary is required' } })
	@GandalfProperty({ isRequired: true })
	addToLibrary: boolean;

	@GandalfValidator({ notNull: { message: 'Description is required' } })
	@GandalfValidator({ sizeString: { message: 'Description must be between 1 and 4000 characters', minLength: 1, maxLength: 4000 } })
	@GandalfProperty({ isRequired: true })
	description: string;

	@GandalfValidator({ notNull: { message: 'Checkout Task is required' } })
	@GandalfProperty({ isRequired: true })
	isCheckoutTask: boolean;

	@GandalfValidator({ notNull: { message: 'Diagnosis is required' } })
	@GandalfProperty({ isRequired: true })
	personDiagnosisId: number;

	@GandalfValidator({ notNull: { message: 'Start Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	startDate: Date;

}
