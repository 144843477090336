import { Directive, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { GridComponent, SortSettingsModel } from '@syncfusion/ej2-angular-grids';

@Directive({
	selector: 'ejs-grid[pmsGridSubmit]',
})
export class GridSubmitDirective implements OnChanges {

	@Input()
	pmsGridSubmit = false;

	@Input()
	pmsGridSubmitDefaultSortOptions: SortSettingsModel;

	private isGridInitialized = false;
	constructor(
		private gridComponent: GridComponent,
	) {
	}

	@HostListener('created')
	onCreated() {
		this.isGridInitialized = true;

		if (this.pmsGridSubmit) {
			// setTimeout is needed because gridComponent is not rendered yet in the created event hook
			setTimeout(() => {
				this.gridComponent.showSpinner();
			});
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (_isNil(changes.pmsGridSubmit) || changes.pmsGridSubmit.firstChange) {
			return;
		}

		// if the current pmsGridSubmit value is false and has changed from the previous value of true (when a table is finished searching)
		// reset filters and page
		if (!changes.pmsGridSubmit.currentValue && changes.pmsGridSubmit.previousValue) {
			this.gridComponent.goToPage(1);

			if (!_isNil(this.pmsGridSubmitDefaultSortOptions)) {
				setTimeout(() => {
					this.gridComponent.clearSorting();
					this.gridComponent.sortSettings = this.pmsGridSubmitDefaultSortOptions;
				});
			}
		}

		if (changes.pmsGridSubmit.currentValue && this.isGridInitialized) {
			setTimeout(() => {
				this.gridComponent.showSpinner();
			});
		}
		if (!changes.pmsGridSubmit.currentValue && this.isGridInitialized) {
			this.gridComponent.hideSpinner();
		}
	}
}
