import { Component, Input } from '@angular/core';
import { EyeglassPrescriptionResponse } from '@gandalf/model/eyeglass-prescription-response';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';

@Component({
	selector: 'pms-eyeglass-rx-authorization',
	templateUrl: './eyeglass-rx-authorization.component.html',
	styles: [],
})
export class EyeglassRxAuthorizationComponent {

	dateFormat = DATE_FORMATS.MM_DD_YYYY;

	@Input()
	eyeglassPrescription: EyeglassPrescriptionResponse;

	constructor() {
	}

}
