import { DialogUtil, EnumUtil } from 'morgana';
import { _isEmpty, _isFinite, _isNil } from '@core/lodash/lodash';
import { PrescriptionAuthorizationType, PrescriptionStatus } from '@gandalf/constants';
import { OdOsLocationProductIdsActiveResponse } from '@gandalf/model/od-os-location-product-ids-active-response';
import { HistoryTableRecord } from '@shared/component/optical-history/contact-lens-history-table/contact-lens-history-table.component';
import dayjs from 'dayjs';

export class PrescriptionUtil {

	static CL_TRIAL_STATUS = 'CL Trial';

	static formatAddPower(add: number): string {
		if (_isNil(add)) {
			return '';
		}

		return '+' + Number(add).toFixed(2);
	}

	static formatAxis(axis: number): string {
		if (_isNil(axis)) {
			return '';
		}

		return axis.toString().padStart(3, '0');
	}

	static formatSphere(sphere: number): string {
		if (_isNil(sphere)) {
			return '';
		}

		return (sphere > 0 ? '+' : '') + Number(sphere).toFixed(2);
	}

	static formatCylinder(cylinder: number): string {
		if (_isNil(cylinder)) {
			return '';
		}

		return (cylinder > 0 ? '+' : '') + Number(cylinder).toFixed(2);
	}

	static formatBaseCurve(baseCurve: number): string {
		if (_isNil(baseCurve)) {
			return '';
		}

		return Number(baseCurve).toFixed(2);
	}

	static formatPupillaryDistance(pupillaryDistance: number, fractionDigit = 2): string {
		if (_isNil(pupillaryDistance)) {
			return '';
		}

		return Number(pupillaryDistance).toFixed(fractionDigit);
	}

	static formatTrial(trial: boolean): string {
		if (_isNil(trial)) {
			return '';
		}

		return trial ? 'Trial' : 'Rx';
	}

	static formatPrism(prism: number): string {
		if (_isNil(prism)) {
			return '';
		}
		return Number(prism).toFixed(2);
	}

	static formatDiameter(diameter: number, fractionDigit = 1): string {
		if (_isNil(diameter)) {
			return '';
		}
		return Number(diameter).toFixed(fractionDigit);
	}

	static isExpired(expirationDate: Date): boolean {
		const now = dayjs();
		return expirationDate
			? dayjs(expirationDate).isBefore(now, 'day')
			: false;
	}

	/**
	 * Returns an expiration date for the prescription based on the start date and preference settings
	 */
	static getExpirationDate(startDate: Date, locationPreferenceMonths: string, preferenceMonths: string, preferenceDefault: string) {
		let expirationMonths = 12;
		if (!_isNil(locationPreferenceMonths) && locationPreferenceMonths !== '' && _isFinite(Number(locationPreferenceMonths))) {
			expirationMonths = Number(locationPreferenceMonths);
		} else if (!_isNil(preferenceMonths) && preferenceMonths !== '' && _isFinite(Number(preferenceMonths))) {
			expirationMonths = Number(preferenceMonths);
		} else if (!_isNil(preferenceDefault) && preferenceDefault !== '' && _isFinite(Number(preferenceDefault))) {
			expirationMonths = Number(preferenceDefault);
		}
		return dayjs(startDate).add(expirationMonths, 'month').toDate();
	}

	static showCreateOrder(
		authType: PrescriptionAuthorizationType,
		prescriptionStatus: PrescriptionStatus,
		expirationDate: Date,
		isContactLensTrial: boolean,
	) {
		if (isContactLensTrial) {
			return true;
		}

		if (PrescriptionUtil.isExpired(expirationDate)) {
			return false;
		}

		if (EnumUtil.equals(authType, PrescriptionAuthorizationType.EXTERNAL_PROVIDER)) {
			return EnumUtil.equalsOneOf(prescriptionStatus, PrescriptionStatus.ACTIVE, PrescriptionStatus.STOPPED);
		} else {
			return EnumUtil.equals(prescriptionStatus, PrescriptionStatus.AUTHORIZED);
		}
	}

	static isContactLensTrial(historyTableRecord: HistoryTableRecord) {
		return historyTableRecord.status === PrescriptionUtil.CL_TRIAL_STATUS;
	}

	static isAuthorizedByInternalProvider(authorizationType: PrescriptionAuthorizationType, prescriptionStatus: PrescriptionStatus) {
		return EnumUtil.equals(authorizationType, PrescriptionAuthorizationType.PROVIDER)
			&& EnumUtil.equals(prescriptionStatus, PrescriptionStatus.AUTHORIZED);
	}

	static isPendingByInternalProvider(prescriptionStatus: PrescriptionStatus, authorizationType: PrescriptionAuthorizationType) {
		return EnumUtil.equals(prescriptionStatus, PrescriptionStatus.ACTIVE)
			&& EnumUtil.equals(authorizationType, PrescriptionAuthorizationType.PROVIDER);
	}

	static createInactiveOdOrOsProductModalContent(
		checkActiveResult: OdOsLocationProductIdsActiveResponse,
	): string {
		let content = `	<div>This Rx contains the following inactive product(s) or inventory item(s)</div>
							<br />`;
		if (this.hasInactiveProduct(checkActiveResult.odLocationProductId, checkActiveResult.isOdLocationProductActive)) {
			content += `OD Lens: ${checkActiveResult.odLocationProductName}<br />`;
		}
		if (this.hasInactiveProduct(checkActiveResult.osLocationProductId, checkActiveResult.isOsLocationProductActive)) {
			content += `OS Lens: ${checkActiveResult.osLocationProductName}<br />`;
		}

		content += `<br />
				Inactive products/items may not reflect the most current information such as retail price. Would you like to go back and select a different product/item?`;
		return content;
	}

	static formatRefraction(sphere: number, cylinder: number, axis: number) {
		const formattedSphere = PrescriptionUtil.formatSphere(sphere);
		const formattedCylinder = PrescriptionUtil.formatCylinder(cylinder);
		const formattedAxis = PrescriptionUtil.formatAxis(axis);

		return `${formattedSphere} ${formattedCylinder} ${!_isEmpty(formattedAxis) ? 'x' : ''} ${formattedAxis}`;
	}

	static hasInactiveODProductOrOSProduct(checkActiveResult: OdOsLocationProductIdsActiveResponse) {
		return this.hasInactiveProduct(checkActiveResult.odLocationProductId, checkActiveResult.isOdLocationProductActive)
			|| this.hasInactiveProduct(checkActiveResult.osLocationProductId, checkActiveResult.isOsLocationProductActive);
	}

	static hasInactiveProduct(productId: number, isActive: boolean) {
		return !_isNil(productId) && !isActive;
	}

	static openInactiveOdOrOsProductModal(checkActiveResult: OdOsLocationProductIdsActiveResponse, confirmedCallback: () => void) {
		const content = this.createInactiveOdOrOsProductModalContent(checkActiveResult);
		const confirmDialog = DialogUtil.confirm({
			title: 'Inactive prescription products',
			content,
			okButton: {
				click: () => {
					confirmDialog.close();
				},
			},
			cancelButton: {
				click: () => {
					confirmDialog.close();
					confirmedCallback();
				},
			},
			showCloseIcon: false,
			closeOnEscape: false,
		});
	}

	static isEyeglassPrescriptionPrintable(status: PrescriptionStatus, authorizationType: PrescriptionAuthorizationType): boolean {
		return EnumUtil.equals(status, PrescriptionStatus.AUTHORIZED) && !EnumUtil.equals(authorizationType, PrescriptionAuthorizationType.EXTERNAL_PROVIDER);
	}

}
