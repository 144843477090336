import {
	ApplicationRef,
	ComponentFactoryResolver,
	ComponentRef,
	EmbeddedViewRef,
	Injectable,
	Injector,
	isDevMode,
	Type
} from '@angular/core';
import { take } from 'rxjs/operators';
import { DynamicDialogInjector } from '../../components/modal/dynamic-dialog.injector';
import { DynamicModalRef, EmptyDynamicModalRef } from '../../components/modal/dynamic-modal-ref';
import { DynamicModalComponent } from '../../components/modal/dynamic-modal.component';
import { ModalConfig } from '../../components/modal/modal-config';
import { _isNil } from '../../utils/lodash/lodash';

@Injectable({
	providedIn: 'root',
})
export class ModalManagerService {

	dialogComponentRef: ComponentRef<DynamicModalComponent>;
	// An instance of modal manager service only supports one modal currently
	isOpen = false;

	constructor(
		/* eslint-disable-next-line deprecation/deprecation */
		private componentFactoryResolver: ComponentFactoryResolver,
		private appRef: ApplicationRef,
		private injector: Injector,
	) {}

	open(componentType: Type<any>, config: ModalConfig) {
		if (this.isOpen) {
			if (isDevMode()) {
				console.error(
					'Two modals cannot be opened at the same time from modal manager service!',
					'Provide a new instance of the service to the opening component if you intended to stack modals.',
				);
			}
			// There is already an active modal open, with the valid onClose subscription for the component;
			// so returning an empty subject here will ensure runtime safety while not triggering a second
			// onClose from occurring.
			return new EmptyDynamicModalRef();
		}
		this.isOpen = true;
		return this.openModal(config, componentType);
	}

	private openModal(config: ModalConfig, componentType: Type<any>) {
		const dialogRef = this.appendDialogComponentToBody(config);

		this.dialogComponentRef.instance.childComponentType = componentType;

		return dialogRef;
	}

	appendDialogComponentToBody(config: ModalConfig) {
		const map = new WeakMap();
		map.set(ModalConfig, config);

		const dialogRef = new DynamicModalRef();
		map.set(DynamicModalRef, dialogRef);

		dialogRef.onClose.pipe(take(1)).subscribe(() => {
			this.onClose();
		});

		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DynamicModalComponent);
		const componentRef = componentFactory.create(new DynamicDialogInjector(this.injector, map));

		this.appRef.attachView(componentRef.hostView);

		const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
		document.body.appendChild(domElem);

		this.dialogComponentRef = componentRef;

		return dialogRef;
	}

	private removeDialogComponentFromBody() {
		this.appRef.detachView(this.dialogComponentRef.hostView);
		this.dialogComponentRef.destroy();
	}

	onClose() {
		this.removeDialogComponentFromBody();
		this.isOpen = false;
	}

	close() {
		if (!this.isOpen || _isNil(this.dialogComponentRef)) {
			return;
		}
		this.onClose();
	}
}
