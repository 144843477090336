// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.amendment.UpdateAmendmentRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateAmendmentRequest extends GandalfModelBase {

	@GandalfLabel('Amendment')
	@GandalfValidator({ notNull: { message: 'Amendment is required' } })
	@GandalfProperty({ isRequired: true })
	amendmentId: number;

	@GandalfConstantDecorator()
	@GandalfLabel('Source')
	@GandalfValidator({ notNull: { message: 'Source is required' } })
	@GandalfProperty({ isRequired: true })
	amendmentSource: constants.AmendmentSource;

	@GandalfLabel('Source Text')
	@GandalfValidator({ sizeString: { message: 'Source Text cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	amendmentSourceText: string;

	@GandalfLabel('Comments')
	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	comments: string;

	@GandalfLabel('Name')
	@GandalfValidator({ notNull: { message: 'Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Name must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ isRequired: true })
	name: string;

	@GandalfLabel('Request Date')
	@GandalfValidator({ notNull: { message: 'Request Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDateTime', isRequired: true })
	requestDate: Date;

	@GandalfConstantDecorator()
	@GandalfLabel('Status')
	@GandalfValidator({ notNull: { message: 'Status is required' } })
	@GandalfProperty({ isRequired: true })
	status: constants.AmendmentStatus;

}
