// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.encounter.review.EncounterReviewGeneralInformationResponse */
/* istanbul ignore next */
@GandalfModel
export class EncounterReviewGeneralInformationResponse extends GandalfModelBase {

	@GandalfProperty()
	contactLensRxCount: number;

	@GandalfProperty()
	contactLensTrialCount: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	encounterDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	encounterStatus: constants.EncounterStatus;

	@GandalfProperty()
	encounterType: string;

	@GandalfProperty()
	eyeglassRxCount: number;

	@GandalfProperty()
	patientRfv: string;

	@GandalfArray(String)
	primaryMedicalProviders: string[];

	@GandalfArray(String)
	primaryVisionProviders: string[];

	@GandalfProperty()
	providerName: PersonNameResponse;

	@GandalfProperty()
	providerRfv: string;

	@GandalfArray(String)
	serviceDetails: string[];

}
