@if (!editingStatus) {
	<small class="margin-left-xs text-uppercase">
		<strong>{{ invoice?.status?.label }}</strong>
		@if (allowEdit) {
			<a (click)="editStatus()" class="margin-left-sm" revTooltip tooltipContent="Edit" [attr.data-test-id]="'editStatusIconLink'"><i
				class="fa fa-pencil"></i></a>
		}
	</small>
} @else {
	<div class="inplace-editor">
		<div class="inplace-editor-wrapper">
			<div class="inplace-editor-component">
				<pms-constant-dropdown [(ngModel)]="invoiceStatus" [constantList]="statusList" [showClearButton]="false"
									   dataTestIdPrefix="invoiceStatus"></pms-constant-dropdown>
			</div>
			<div class="inplace-editor-actions">
				<button (click)="updateStatus()" ejs-button iconCss="fa fa-check" type="button" [attr.data-test-id]="'updateStatusButton'"></button>
				<button (click)="cancelEditStatus()" ejs-button iconCss="fa fa-times" type="button" [attr.data-test-id]="'cancelEditStatusButton'"></button>
			</div>
		</div>
	</div>
}

