// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.payment.ServiceAdjustmentResponse */
/* istanbul ignore next */
@GandalfModel
export class ServiceAdjustmentResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	adjustmentAmount1: number;

	@GandalfProperty({ propertyType: 'Money' })
	adjustmentAmount2: number;

	@GandalfProperty({ propertyType: 'Money' })
	adjustmentAmount3: number;

	@GandalfProperty({ propertyType: 'Money' })
	adjustmentAmount4: number;

	@GandalfProperty({ propertyType: 'Money' })
	adjustmentAmount5: number;

	@GandalfProperty({ propertyType: 'Money' })
	adjustmentAmount6: number;

	@GandalfProperty()
	adjustmentReasonCode1: string;

	@GandalfProperty()
	adjustmentReasonCode2: string;

	@GandalfProperty()
	adjustmentReasonCode3: string;

	@GandalfProperty()
	adjustmentReasonCode4: string;

	@GandalfProperty()
	adjustmentReasonCode5: string;

	@GandalfProperty()
	adjustmentReasonCode6: string;

	@GandalfProperty()
	claimAdjustmentGroupCode: string;

}
