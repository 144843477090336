import { Injectable } from '@angular/core';
import { UpdateStatePropertyPayload } from '@app-store/utils/update-state-property-payload';
import { ConsentsService } from '@core/consents/consents.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GetPracticeConsents, PracticeConsentsActionTypes, UpdatePracticeConsentsProperty } from '@practice-store/actions/consents.actions';
import { PracticeConsentsState } from '@practice-store/reducers/consents.reducer';
import { exhaustMap, map } from 'rxjs/operators';

@Injectable()
export class PracticeConsentsEffects {

	getPracticeConsents = createEffect(() => this.actions.pipe(
		ofType<GetPracticeConsents>(PracticeConsentsActionTypes.GET_PRACTICE_CONSENTS),
		exhaustMap(_action => this.consentsService.findActivePracticeConsentForms().pipe(
			map(consents => new UpdatePracticeConsentsProperty(UpdateStatePropertyPayload.build(
				PracticeConsentsState,
				'availableConsentForms',
				consents,
			))),
		)),
	));

	constructor(
		private actions: Actions,
		private consentsService: ConsentsService,
	) {}

}
