import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
	name: 'week',
})
export class WeekPipe implements PipeTransform {

	transform(date: Date): string {
		const firstDay = dayjs(date).startOf('week');
		const lastDay = dayjs(date).endOf('week');

		return firstDay.format('MMM') + ` ${firstDay.date()}` +
			(firstDay.year() !== lastDay.year() ? `, ${firstDay.year()}` : '') + ' - ' +
			(firstDay.month() !== lastDay.month() ? lastDay.format('MMM') + ' ' : '') + `${lastDay.date()}, ${lastDay.year()}`;
	}

}
