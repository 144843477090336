import { Injectable } from '@angular/core';
import { UpdatePropertyValue } from '@app-store/actions/properties.actions';
import { State } from '@app-store/reducers';
import { selectComponentPropertiesState, selectComponentPropertyState } from '@app-store/selectors/properties.selectors';
import { StatefulServiceBase } from '@app-store/services/stateful-service-base';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable()
export class StatefulPropertyService extends StatefulServiceBase {

	constructor(private store: Store<State>) {
		super();
	}

	static updatePropertyValueWithStoreKey<T>(propertyKey: string, value: T, store: Store<State>, storeKey) {
		store.dispatch(new UpdatePropertyValue({key: storeKey, propertyKey, value}));
	}

	static selectPropertyState(propertyKey: string, store: Store<State>, storeKey): Observable<any> {
		return store.select(selectComponentPropertyState({key: storeKey, propertyKey}));
	}

	updatePropertyValue<T>(propertyKey: string, value: T) {
		StatefulPropertyService.updatePropertyValueWithStoreKey(propertyKey, value, this.store, this.storeKey);
	}

	selectPropertiesState(): Observable<any> {
		return this.store.select(selectComponentPropertiesState({key: this.storeKey}));
	}
}
