<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-lg"
	[attr.data-test-id]="'addGuestInformationModal'">
	<ng-template #header>
		<div class="dlg-template">Add Guest Information</div>
	</ng-template>
	<ng-template #content>
		<form class="form-horizontal"
			  [formGroup]="formGroup"
			  (ngSubmit)="createGuestInvoice()"
			  #templateForm="ngForm">
			@if (formGroup.invalid && templateForm.submitted) {
				<gandalf-lib-validation-messages
					[form]="formGroup"
					[requestObj]="request"
					[attr.data-test-id]="'addGuestInformationModalValidationMessage'">
				</gandalf-lib-validation-messages>
			}
			<div class="panel-body">
				<div class="row">
					<div class="col-md-7 col-lg-5">
						<div class="form-group">
							<label for="name" class="col-sm-4 col-md-3 control-label">Name</label>
							<div class="col-sm-8 col-md-9">
								<input id="name" type="text" class="e-input" revFocusInput formControlName="name"/>
							</div>
						</div>
						<div class="form-group">
							<label class="col-sm-4 col-md-3 control-label">Phone</label>
							<div class="col-sm-8 col-md-9">
								<pms-phone-number-input formControlName="phoneNumber"></pms-phone-number-input>
							</div>
						</div>
						<div class="form-group">
							<label for="email" class="col-sm-4 col-md-3 control-label">Email</label>
							<div class="col-sm-8 col-md-9">
								<input id="email" type="text" class="e-input" formControlName="email"/>
							</div>
						</div>
					</div>
					<div class="col-md-7 col-lg-5" [attr.data-test-id]="'addGuestInformationModalAddressFormGroup'">
						<pms-address-form [parentFormGroup]="formGroup" [isCreating]="true" [autoselectStateProvince]="false"
										  class="form-layout-col-sm-4-8-md-3-9"></pms-address-form>
					</div>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" type="button" (click)="closeModal()" [attr.data-test-id]="'addGuestInformationCancelButton'">Cancel</button>
		<button ejs-button [isPrimary]="true" type="submit" (click)="submitForm($event)" [attr.data-test-id]="'addGuestInformationSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>
