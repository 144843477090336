import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicModalRef, EnumUtil, ModalConfig, OptionItem } from 'morgana';
import { _isNil } from '@core/lodash/lodash';
import { ReferenceDataService } from '@core/reference-data/reference-data.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { BetaSystemCode, PreferenceDefaults, PreferenceName, ReferenceDataMasterCategory, StatementFormat } from '@gandalf/constants';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { combineLatest } from 'rxjs';

export interface StatementConfig {
	printDate: Date;
	printDateString: string;
	dueDateText: string;
	messageText: string;
	formatType: StatementFormat;
}

@Component({
	selector: 'pms-print-invoice-statements-modal',
	templateUrl: './print-invoice-statements-modal.component.html',
})
export class PrintInvoiceStatementsModalComponent implements OnInit {
	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('dueDateDropDown')
	dueDateDropDown: DropDownListComponent;

	@ViewChild('messageDropDown')
	messageDropDown: DropDownListComponent;

	statementFormats =  StatementFormat.VALUES.values;
	dueDateReasons: OptionItem[];
	messageReasons: OptionItem[];
	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	statementFormat: StatementFormat;
	dueDateReason: any;
	messageReason: any;
	dueDate: Date;
	printDate: Date;
	markAsPrinted = true;
	message = '';
	useFileFormat = false;
	submitted = false;
	sendStatements: boolean;

	constructor(
		public ref: DynamicModalRef,
		public referenceDataService: ReferenceDataService,
		public securityManagerService: SecurityManagerService,
		public modalConfig: ModalConfig,
		public datePipe: DatePipe,
	) {
		this.parseData();
	}

	parseData() {
		if (this.modalConfig.data) {
			this.sendStatements = this.modalConfig.data.sendStatements;
		}
	}

	ngOnInit() {
		this.printDate = new Date();
		this.setupPreferences();
		this.setupPermissions();
		this.getData();
	}

	getData() {
		combineLatest([
			this.referenceDataService.getActiveReferenceDataByCategoryIdForDropdown(
				ReferenceDataMasterCategory.ACCOUNTING_STATEMENT_DUE_DATE_REASONS.value,
			),
			this.referenceDataService.getActiveReferenceDataByCategoryIdForDropdown(
				ReferenceDataMasterCategory.ACCOUNTING_STATEMENT_MESSAGE_REASONS.value,
			),
		]).subscribe(([reasons, messageReasons]) => {
			this.handleDataResults(reasons, messageReasons);
		});
	}

	handleDataResults(reasons, messageReasons) {
		this.dueDateReasons = [{label: 'Custom', value: -1}, ...reasons] as OptionItem[];
		this.messageReasons = [{label: 'Custom', value: -1}, ...messageReasons] as OptionItem[];
		this.dueDateReason = -1;
		this.messageReason = -1;
		this.dueDateDropDown.dataBind();
		this.messageDropDown.dataBind();
	}

	/* istanbul ignore next */
	closeModal(printStatementConfig?) {
		this.ref.close(this.modal, printStatementConfig);
	}

	submit() {
		this.submitted = true;
		if (this.isValid()) {
			this.closeModal(this.getPrintStatement());
		}
	}

	getPrintStatement(): StatementConfig {
		return {
			dueDateText: this.getDueDateText(),
			formatType: this.statementFormat,
			messageText: this.message,
			printDateString: this.markAsPrinted ? this.formatDate(this.printDate) : null,
			printDate: this.printDate,
		};
	}

	getDueDateText() {
		if (this.dueDateReason && this.dueDateReason !== -1) {
			return this.dueDateReasons.find(reason => reason.value === this.dueDateReason).label;
		}
		return this.formatDate(this.dueDate);
	}

	get isCustomDueDate() {
		return _isNil(this.dueDateReason) || this.dueDateReason === -1;
	}

	setMessageBox(value) {
		if (this.messageReasons && !_isNil(value) && value !== -1) {
			this.message = this.messageReasons.find(messageReason => messageReason.value === value).label;
		} else {
			this.message = '';
		}
	}

	formatDate(date: Date) {
		if (!date) {
			return '';
		}
		return this.datePipe.transform(date, DATE_FORMATS.MM_DD_YYYY);
	}

	/**
	 * Invalid if the markAsPrinted is true and the print date is not set
	 */
	isValid() {
		return !this.markAsPrinted || !_isNil(this.printDate);
	}

	setupPermissions() {
		this.useFileFormat = this.securityManagerService.hasBeta(BetaSystemCode.STATEMENTS_FILE_FORMAT);
	}

	setupPreferences() {
		let preference = this.securityManagerService.preferenceValue(PreferenceName.ACCOUNTING_STATEMENTS_FORMAT.value);
		if (_isNil(preference)) {
			preference = PreferenceDefaults.ACCOUNTING_STATEMENTS_FORMAT.value;
		}
		this.statementFormat = EnumUtil.findEnumByValue(Number(preference), StatementFormat);
	}

	printOrSend() {
		return this.sendStatements ? 'Send' : 'Print';
	}

}
