import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { FEATURE_NAVIGATION_EVENT, HIT_PMS_HTML_EVENTS, LegacyPMSEvent } from '@core/legacy/hit-pms-html.constants';
import { LegacyNavigationService } from '@core/legacy/legacy-navigation.service';
import { _find } from '@core/lodash/lodash';
import { StateManagementService } from '@core/state-management/state-management.service';
import { ADMIN_MODULE_MAPPINGS, AdminModuleMap } from '../adminFeature.constants';

@Injectable({
	providedIn: 'root',
})
export class AdminFeatureSubscriptionService {

	constructor(
		private router: Router,
		private ngZone: NgZone,
		private legacyNavigationService: LegacyNavigationService,
		private eventsManager: EventsManagerService,
		private stateManagementService: StateManagementService,
	) {
	}

	subscribeToFeatureEvent() {
		this.eventsManager.subscribe(FEATURE_NAVIGATION_EVENT, event => this.parseAdminSection(event), this);
		this.eventsManager.subscribe(
			HIT_PMS_HTML_EVENTS.APPLICATION.ADMINISTRATION_FLEX_MODULE_SELECTED,
			event => {
				this.saveVisitedUrl(event);
			},
			this,
		);
	}

	saveVisitedUrl(event: LegacyPMSEvent) {
		const adminModuleMapping = _find<AdminModuleMap>(ADMIN_MODULE_MAPPINGS, { FEATURE_FLAG: event.argument.featureKey});
		if (adminModuleMapping) {
			this.stateManagementService.storeUrl(adminModuleMapping.FULL_URL.split('/'));
		}
	}

	async parseAdminSection(event: LegacyPMSEvent) {
		const adminSectionUrl = _find<AdminModuleMap>(ADMIN_MODULE_MAPPINGS, { FEATURE_FLAG: event.argument.featureKey});
		if (adminSectionUrl) {
			await this.redirectToAdminSection(adminSectionUrl.FULL_URL);
		}
	}

	redirectToAdminSection(url: string) {
		return this.ngZone.run(() => {
			this.legacyNavigationService.hideLegacy('admin');
			return this.router.navigateByUrl(url);
		});
	}
}
