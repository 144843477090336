<ejs-dialog #modal cssClass="modal-lg" (close)="closeModal()">
	<ng-template #header>
		<div class="dlg-template">Select a Drug</div>
	</ng-template>
	<ng-template #content>
		<pms-drug-search [initialSearch]="query" (drugSelected)="closeModal($event)"></pms-drug-search>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" (click)="closeModal()">Cancel</button>
	</ng-template>
</ejs-dialog>
