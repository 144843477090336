// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.shared.person.PhoneInformationRequest */
/* istanbul ignore next */
@GandalfModel
export class PhoneInformationRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Cell phone number cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfValidator({ phoneNumber: { message: 'Cell phone number must contain at least 10 digits' } })
	@GandalfProperty()
	cellPhone: string;

	@GandalfValidator({ sizeString: { message: 'Cell phone extension cannot be longer than 10 characters', minLength: 0, maxLength: 10 } })
	@GandalfProperty()
	cellPhoneExtension: string;

	@GandalfValidator({ sizeString: { message: 'Home phone number cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfValidator({ phoneNumber: { message: 'Home phone number must contain at least 10 digits' } })
	@GandalfProperty()
	homePhone: string;

	@GandalfValidator({ sizeString: { message: 'Home phone extension cannot be longer than 10 characters', minLength: 0, maxLength: 10 } })
	@GandalfProperty()
	homePhoneExtension: string;

	@GandalfValidator({ sizeString: { message: 'Pager cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty()
	pager: string;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Preferred Phone Type is required' } })
	@GandalfProperty({ isRequired: true })
	preferredPhoneType: constants.PreferredPhoneType;

	@GandalfValidator({ sizeString: { message: 'Work phone number cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfValidator({ phoneNumber: { message: 'Work phone number must contain at least 10 digits' } })
	@GandalfProperty()
	workPhone: string;

	@GandalfValidator({ sizeString: { message: 'Work phone extension cannot be longer than 10 characters', minLength: 0, maxLength: 10 } })
	@GandalfProperty()
	workPhoneExtension: string;

}
