import { ResponseActions, ResponseActionTypes, UpdateEndpointResponse } from '../actions/response.actions';

export class ResponseState<T = any> {
	createdAt: number;
	response: T;

	constructor(response: T) {
		this.createdAt = new Date().getTime();
		this.response = response;
	}
}

export type ResponseStateMap = Map<string, ResponseState>;

export const initialState: ResponseStateMap = new Map();

export function reducer(state = initialState, action: ResponseActions): ResponseStateMap {
	switch (action.type) {

		case ResponseActionTypes.UPDATE_ENDPOINT_RESPONSE: {
			const endpointsState = new Map(state);
			endpointsState.set(action.payload.endpointId, new ResponseState((action as UpdateEndpointResponse).payload.response));
			return endpointsState;
		}

		case ResponseActionTypes.REMOVE_ENDPOINT_RESPONSE: {
			const endpointStateMap = new Map(state);
			endpointStateMap.delete(action.payload.endpointId);
			return endpointStateMap;
		}

		default: {
			return state;
		}
	}
}
