import { ChangeDetectorRef, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { _isNil } from '@core/lodash/lodash';
import { ModalManagerService } from 'morgana';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { UrlService } from '@core/url-util/url.service';
import { PracticeFolderIdentifier } from '@gandalf/constants';
import { ImagePreviewModalComponent } from '@shared/component/image-preview-modal/image-preview-modal.component';
import { PhotoFileTransferService } from '@shared/component/photo-file-transfer/photo-file-transfer.service';
import { UploadDocumentToFolderModalComponent } from '@shared/component/upload-document-to-folder-modal/upload-document-to-folder-modal.component';
import { WebcamModalComponent } from '@shared/component/webcam-modal/webcam-modal.component';
import { PersonPhotoPipe } from '@shared/pipes/person-photo/person-photo.pipe';

@Component({
	selector: 'pms-photo-file-transfer',
	templateUrl: './photo-file-transfer.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PhotoFileTransferComponent),
			multi: true,
		},
		ModalManagerService,
	],
})
export class PhotoFileTransferComponent implements ControlValueAccessor {

	@ViewChild('photoUpload')
	photoUpload: HTMLInputElement;

	@Input()
	uploadUrl: string;

	@Input()
	folderId: PracticeFolderIdentifier;

	@Input()
	fileName = 'employeeImage.png';

	practiceFileId: number;

	imgSrc: string;

	constructor(
		private personPhotoPipe: PersonPhotoPipe,
		private changeDetectorRef: ChangeDetectorRef,
		private modalManagerService: ModalManagerService,
		private securityManagerService: SecurityManagerService,
		private urlService: UrlService,
		private photoFileTransferService: PhotoFileTransferService,
	) {
	}

	onModelChange: (_: any) => void = () => {
	};

	onModelTouched: () => void = () => {
	};

	fileUpload() {
		this.modalManagerService.open(UploadDocumentToFolderModalComponent, {
			data: {
				folderId: this.folderId,
				uploadUrl: this.uploadUrl,
				extraUrlParams: `&outputPracticeFileIds=true`,
			},
		}).onClose.subscribe(practiceFileId => {
			if (practiceFileId) {
				this.updatePracticeFileId(practiceFileId);
			}
		});
	}

	noImage() {
		return _isNil(this.practiceFileId);
	}

	updatePracticeFileId(practiceFileId: number, runOnModelChange = true) {
		this.practiceFileId = practiceFileId;
		this.imgSrc = this.personPhotoPipe.transform(this.practiceFileId);
		if (runOnModelChange) {
			this.onModelChange(this.practiceFileId);
		}
	}

	get uploadFileUrl() {
		let urlParams = '?' + this.urlService.getStrutsParameters(new Date());
		urlParams += `&practiceId=${this.securityManagerService.getUserSession().practiceId}`;
		urlParams += `&folderId=${this.folderId.value}`;
		urlParams += `&outputPracticeFileIds=true`;
		return this.uploadUrl + urlParams;
	}

	openWebcamModal() {
		this.modalManagerService.open(WebcamModalComponent, {}).onClose.subscribe(this.onWebcamClose);
	}

	onWebcamClose = webcamImage => {
		if (!_isNil(webcamImage)) {
			this.photoFileTransferService.uploadPhotoFromWebcam(this.uploadFileUrl, webcamImage.imageAsDataUrl, this.fileName).subscribe(
				(response: string) => this.updatePracticeFileId(parseInt(response, 10)),
			);
		}
	};

	preview() {
		this.modalManagerService.open(ImagePreviewModalComponent, {
			data: {
				filePath: this.imgSrc,
			},
		});
	}

	registerOnChange(fn: (_: any) => void) {
		this.onModelChange = fn;
	}

	registerOnTouched(fn: () => void) {
		this.onModelTouched = fn;
	}

	writeValue(value: number) {
		if (_isNil(value)) {
			value = null;
		}
		this.updatePracticeFileId(value, false);

		this.changeDetectorRef.markForCheck();
	}


}
