import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { PersonNameResponse } from '@gandalf/model/person-name-response';


@Pipe({
	name: 'personNameTooltip',
})
@Injectable({
	providedIn: 'root',
})
export class PersonNameTooltipPipe implements PipeTransform {

	transform(personName: PersonNameResponse): any {
		let formattedName = '';
		if (personName && personName.firstName) {
			formattedName = formattedName.concat(personName.firstName);
		}
		if (personName && personName.lastName) {
			formattedName = formattedName.concat(' ', personName.lastName);
		}
		return formattedName;
	}

}
