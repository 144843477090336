import { Injectable } from '@angular/core';
import { TrizettoExternalCredentialGandalfService } from '@gandalf/services';
import { Observable } from 'rxjs';
import { TrizettoExternalCredentialResponse } from '@gandalf/model/trizetto-external-credential-response';
import { CreateTrizettoExternalCredentialRequest } from '@gandalf/model/create-trizetto-external-credential-request';
import { UpdateTrizettoExternalCredentialRequest } from '@gandalf/model/update-trizetto-external-credential-request';
import { map } from 'rxjs/operators';

export interface FormattedTrizettoExternalCredentialResponse extends TrizettoExternalCredentialResponse {
	formattedLocationListString: string;
}

@Injectable({
	providedIn: 'root',
})
export class TrizettoTemplateService {

	constructor(
		private trizettoExternalCredentialGandalfService: TrizettoExternalCredentialGandalfService,
	) {
	}

	findTrizettoExternalCredentials(): Observable<FormattedTrizettoExternalCredentialResponse[]> {
		return this.trizettoExternalCredentialGandalfService.findAllTrizettoExternalCredentials().pipe(
			map(credentials => credentials.map(credential => this.formatCredential(credential))),
		);
	}

	/* istanbul ignore next: gandalf */
	disableTrizettoExternalCredential(trizettoExternalCredentialId: number) {
		return this.trizettoExternalCredentialGandalfService.disableTrizettoExternalCredential(trizettoExternalCredentialId);
	}

	/* istanbul ignore next: gandalf */
	enableTrizettoExternalCredential(trizettoExternalCredentialId: number) {
		return this.trizettoExternalCredentialGandalfService.enableTrizettoExternalCredential(trizettoExternalCredentialId);
	}

	/* istanbul ignore next: gandalf */
	createTrizettoExternalCredential(request: CreateTrizettoExternalCredentialRequest) {
		return this.trizettoExternalCredentialGandalfService.createTrizettoExternalCredential(request);
	}

	/* istanbul ignore next: gandalf */
	updateTrizettoExternalCredential(request: UpdateTrizettoExternalCredentialRequest) {
		return this.trizettoExternalCredentialGandalfService.updateTrizettoExternalCredential(request);
	}

	formatCredential(credential: TrizettoExternalCredentialResponse) {
		const formattedCredential = credential as FormattedTrizettoExternalCredentialResponse;
		formattedCredential.formattedLocationListString = formattedCredential.locations.map(location => location.name).join(', ');
		return formattedCredential;
	}
}
