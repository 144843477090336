import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { FILE_THUMBNAILS } from '@shared/constants/url.constants';

@Injectable({providedIn: 'root'})
@Pipe({name: 'fileIcon'})
export class FileIconPipe implements PipeTransform {

	transform(value: string) {
		if (_isNil(value)) {
			value = '';
		}
		switch (value.toLowerCase()) {
			case 'csv':
			case 'xls':
			case 'xlsx':
			case 'excel':
				return FILE_THUMBNAILS.XLS;
			case 'doc':
			case 'docx':
			case 'word':
				return FILE_THUMBNAILS.DOC;
			case 'folder':
				return FILE_THUMBNAILS.FOLDER;
			case 'txt':
			case 'text':
				return FILE_THUMBNAILS.TXT;
			case 'pdf':
			case 'acrobat':
				return FILE_THUMBNAILS.PDF;
			case 'jpg':
			case 'jpeg':
			case 'png':
			case 'gif':
				return FILE_THUMBNAILS.JPG;
			case 'html':
				return FILE_THUMBNAILS.HTML;
			default:
				return FILE_THUMBNAILS.OTHER;
		}
	}

}
