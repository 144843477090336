// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.PatientPortalAccessRequest */
/* istanbul ignore next */
@GandalfModel
export class PatientPortalAccessRequest extends GandalfModelBase {

	@GandalfProperty()
	allowLogin: boolean;

	@GandalfValidator({ sizeString: { message: 'Temporary Password must be between 8 and 128 characters', minLength: 8, maxLength: 128 } })
	@GandalfProperty()
	temporaryPassword: string;

	@GandalfValidator({ sizeString: { message: 'Username must be between 8 and 100 characters', minLength: 8, maxLength: 100 } })
	@GandalfProperty()
	userName: string;

}
