// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.accounting.claims.ClaimsInsuranceClaimResponse */
/* istanbul ignore next */
@GandalfModel
export class ClaimsInsuranceClaimResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	amountDue: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	claimDate: Date;

	@GandalfProperty()
	encounterId: number;

	@GandalfProperty()
	externalMessage: string;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	insuranceName: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	invoiceDate: Date;

	@GandalfProperty()
	invoiceId: number;

	@GandalfProperty()
	patientId: number;

	@GandalfProperty()
	patientName: PatientNameResponse;

	@GandalfProperty()
	priority: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	processingStatus: constants.ClaimProcessingStatus;

	@GandalfProperty()
	secondaryPayerPracticeInsuranceCompanyName: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	serviceDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.InsuranceClaimStatus;

	@GandalfProperty({ propertyType: 'LocalDate' })
	submitDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	submitMechanism: constants.ClaimSubmitMechanism;

}
