<ejs-dialog
    #modal
	(close)="closeModal()"
	[attr.data-test-id]="'selectEncounterModal'">
	<ng-template #header>
		<div class="dlg-template">Select Encounter</div>
	</ng-template>
	<ng-template #content>
		<div>
			<ejs-grid
                #grid
				autoFitColumns
				[autoFitBlacklist]="['locationName']"
				class="table-rowlink"
				gridLines="Horizontal"
				[dataSource]="encounters"
				[allowSorting]="true"
				[allowSelection]="true"
				[allowResizing]="true"
				[pmsGridSubmit]="isSearching"
				(created)="search()"
				(rowSelected)="closeModal($event.data)"
				[pmsGridSubmitDefaultSortOptions]="sortOptions"
				[attr.data-test-id]="'selectEncounterTable'">
				<e-columns>
					<e-column field="encounterDate" headerText="Date" [format]="tableDateFormat"></e-column>
					<e-column field="locationName" headerText="Location"></e-column>
					<e-column field="templateName" headerText="Encounter Type"></e-column>
					<e-column field="subTypeValue" headerText="Category"></e-column>
				</e-columns>
			</ejs-grid>
		</div>
	</ng-template>
	<ng-template #footerTemplate>
		<button type="button" ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'selectEncounterModalCancelButton'">Cancel</button>
	</ng-template>
</ejs-dialog>
