// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.openedge.OpenEdgeVoidTransactionResponse */
/* istanbul ignore next */
@GandalfModel
export class OpenEdgeVoidTransactionResponse extends GandalfModelBase {

	@GandalfProperty()
	customerReceipt: string;

	@GandalfProperty()
	errorCode: string;

	@GandalfProperty()
	errorMessage: string;

	@GandalfProperty()
	paymentTransactionId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	result: constants.OpenEdgeCommonVoidStatus;

	@GandalfConstantDecorator()
	@GandalfProperty()
	returnStatus: constants.OpenEdgeCommonTransactionStatus;

	@GandalfConstantDecorator()
	@GandalfProperty()
	voidStatus: constants.OpenEdgeCommonTransactionStatus;

}
