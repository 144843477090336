// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.accounting.eyefinity.AdminEyefinityFeeOptionResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminEyefinityFeeOptionResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	chargeBack: number;

	@GandalfProperty()
	code: string;

	@GandalfProperty()
	eyefinityFeeOptionId: number;

	@GandalfProperty()
	optionName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	optionType: constants.EyefinityFeeOptionType;

	@GandalfProperty({ propertyType: 'Money' })
	patientFee: number;

	@GandalfProperty()
	patientPercentageRetailCost: number;

	@GandalfProperty({ propertyType: 'Money' })
	serviceFee: number;

}
