import { PatientDashboardSummaryResponse } from '@gandalf/model/patient-dashboard-summary-response';
import { PatientDashboardActions, PatientDashboardActionTypes } from '@patients-store/actions';

export type PatientDashboardStateMap = Map<number, PatientDashboardState>;

export class PatientDashboardState {
	dashboardSummary: PatientDashboardSummaryResponse;
}

export const initialState: PatientDashboardStateMap = new Map();

export function reducer(state = initialState, action: PatientDashboardActions): PatientDashboardStateMap {
	switch (action.type) {

		case PatientDashboardActionTypes.GET_PATIENT_DASHBOARD_SUMMARY_SUCCESS: {
			const patientDashboardStateMap = new Map(state);
			const patientDashboardState = patientDashboardStateMap.get(action.payload.patientId) || new PatientDashboardState();
			patientDashboardState.dashboardSummary = action.payload.dashboardSummary;
			patientDashboardStateMap.set(action.payload.patientId, patientDashboardState);
			return patientDashboardStateMap;
		}

		case PatientDashboardActionTypes.REMOVE_PATIENT_DASHBOARD: {
			const patientDashboardStateMap = new Map(state);
			patientDashboardStateMap.delete(action.payload.patientId);
			return patientDashboardStateMap;
		}

		default: {
			return state;
		}
	}
}
