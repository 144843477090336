import { InvoiceService } from '@accounting/core/accounting/invoice-service/invoice.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { EnumUtil, SortingService } from 'morgana';
import { _concat, _filter, _isNil } from '@core/lodash/lodash';
import { ProviderDropdownResponse, ProviderService } from '@core/provider/provider.service';
import { UpdateAdditionalClaimInformationRequest } from '@gandalf/model/update-additional-claim-information-request';
import { AdditionalClaimInformationResponse } from '@gandalf/model/additional-claim-information-response';
import { InvoiceResponse } from '@gandalf/model/invoice-response';
import {
	ClaimAttachmentTransmission,
	ClaimAttachmentType,
	ClaimCurrentIllnessDateQualifier,
	ClaimEpsdtApply,
	ClaimEpsdtCode,
	ClaimFrequencyCode,
	ClaimSimilarIllnessDateQualifier,
	ClaimVisionConditionApply,
	CodeSet,
	InsuranceCompanyStatus
} from '@gandalf/constants';
import { BaseComponent } from '@shared/component/base.component';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { GandalfFormBuilder } from 'gandalf';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountingService, FormattedInsuranceResponse, FormattedVendorSummaryResponse } from '../../../core/accounting/accounting.service';

@Component({
	selector: 'pms-additional-claim-information',
	templateUrl: './additional-claim-information.component.html',
	styles: [],
})
export class AdditionalClaimInformationComponent extends BaseComponent implements OnInit {

	@Input()
	invoiceId: number;

	@Input()
	isPaymentProcessing: boolean;

	@ViewChild('templateForm')
	templateForm: NgForm;

	invoice: InvoiceResponse = null;
	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	formGroup: UntypedFormGroup;
	addlClaimInfo: AdditionalClaimInformationResponse;
	providerList: ProviderDropdownResponse[];
	personInsuranceList: FormattedInsuranceResponse[];
	vendorsList: FormattedVendorSummaryResponse[];
	request: UpdateAdditionalClaimInformationRequest;
	pendingCodeSetOptions = [CodeSet.ICD9, CodeSet.ICD10];
	currentIllnessQualifierList = ClaimCurrentIllnessDateQualifier.VALUES;
	similarIllnessQualifierList = ClaimSimilarIllnessDateQualifier.VALUES;
	claimFrequencyCodeList = ClaimFrequencyCode.VALUES;
	claimVisionConditionApplyOptions = ClaimVisionConditionApply.VALUES.values;
	attachmentTypeList = ClaimAttachmentType.VALUES;
	attachmentTransmissionList = ClaimAttachmentTransmission.VALUES;
	claimEpsdtApplyOptions = ClaimEpsdtApply.VALUES.values;
	claimEpsdtCodeList = ClaimEpsdtCode.VALUES;
	isReadOnly: boolean;

	constructor(
		private accountingService: AccountingService,
		private providerService: ProviderService,
		public formBuilder: GandalfFormBuilder,
		private invoiceService: InvoiceService,
	) {
		super();
	}

	ngOnInit() {
		this.listenForInvoiceUpdates();
		this.buildRequest();
		this.buildForm();
	}

	setFormState() {
		this.isReadOnly = this.invoice.approved || this.isPaymentProcessing;
		if (this.isReadOnly) {
			this.formGroup.disable();
		} else {
			this.formGroup.enable();
		}
	}

	listenForInvoiceUpdates() {
		this.invoiceService.getInvoiceDetailsInvoiceState(this.invoiceId)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((invoice) => {
				this.invoice = invoice;
				this.getData();
			});
	}

	getData() {
		combineLatest([
			this.accountingService.findAdditionalClaimInformationByInvoiceId(this.invoice.id),
			this.providerService.findActiveExternalProvidersForDropdown(),
			this.providerService.findActivePracticeProvidersForDropdown(),
			this.accountingService.findActiveInsurancesByPatientId(this.invoice.patientId),
			this.accountingService.findAlternateFacilities(),
		]).subscribe(([addlClaimInfo, externalProviders, internalProviders, personInsurances, vendors]) => {
			this.addlClaimInfo = addlClaimInfo;
			this.combineAndSortProviders(externalProviders, internalProviders);
			this.setupSecondaryPayerDropdown(personInsurances);
			this.vendorsList = vendors;
			this.setFormValues();
			this.setResubmissionCode();
			this.setFormState();
		});
	}

	combineAndSortProviders(externalProviders: ProviderDropdownResponse[], internalProviders: ProviderDropdownResponse[]) {
		const unsortedProviders = _concat(externalProviders, internalProviders);
		this.providerList = SortingService.sortBy(unsortedProviders, ['lastName', 'firstName', 'id']);
	}

	setupSecondaryPayerDropdown(personInsurances: FormattedInsuranceResponse[]) {
		const inactiveLabeledList = personInsurances.map(personInsurance => this.addInactivePrefix(personInsurance));
		this.personInsuranceList = _filter(inactiveLabeledList, insurance => insurance.value !== this.invoice.personInsuranceId);
	}

	addInactivePrefix(personInsurance: FormattedInsuranceResponse): FormattedInsuranceResponse {
		if (EnumUtil.equals(personInsurance.practiceInsuranceCompanyStatus, InsuranceCompanyStatus.INACTIVE)) {
			personInsurance.label = `(Inactive Co.) ${personInsurance.label}`;
		}
		return personInsurance;
	}

	buildRequest() {
		this.request = new UpdateAdditionalClaimInformationRequest();
	}

	displayRequiresMessage(): boolean {
		return !EnumUtil.equals(this.formGroup.get('claimFrequencyCode').value, ClaimFrequencyCode.ORIGINAL);
	}

	buildForm() {
		this.formGroup = this.formBuilder.group(this.request);
	}

	setFormValues() {
		this.formGroup.patchValue(this.addlClaimInfo);
	}

	setResubmissionCode() {
		if (_isNil(this.formGroup.get('claimFrequencyCode').value)) {
			this.formGroup.get('claimFrequencyCode').setValue(ClaimFrequencyCode.ORIGINAL);
		}
	}

	submitForm(event) {
		this.templateForm.onSubmit(event);
	}

	save() {
		if (this.formGroup.invalid) {
			return;
		}

		const request = this.formGroup.value as UpdateAdditionalClaimInformationRequest;
		this.accountingService.updateAdditionalClaimInformation(request).subscribe(data => {
			this.addlClaimInfo = data;
			this.setFormValues();
		});
	}

	cancel() {
		this.setFormValues();
	}
}
