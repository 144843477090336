import { Component, ViewChild } from '@angular/core';
import { DynamicModalRef } from 'morgana';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

export enum UnsavedChangesAction {
	CANCEL,
	DISCARD,
	SAVE,
}

@Component({
	selector: 'pms-unsaved-changes',
	templateUrl: './unsaved-changes.component.html',
})
export class UnsavedChangesComponent {

	@ViewChild('modal')
	modal: DialogComponent;
	action = UnsavedChangesAction;

	constructor(
		private ref: DynamicModalRef,
	) {
	}

	/* istanbul ignore next: closeModal */
	closeModal(result: UnsavedChangesAction) {
		this.ref.close(this.modal, result);
	}

}
