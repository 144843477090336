import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { StatefulRouteService } from '@app-store/services/stateful-route.service';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { LOAD_MODULE_WITH_ACTION, LOAD_ROUTE, ModuleActionEvent } from '@core/legacy/hit-pms-html.constants';
import { _find, _isNil } from '@core/lodash/lodash';
import { APP_ROUTING_URL_MAP } from '../../app-routing.constants';

@Injectable({
	providedIn: 'root',
})
export class LegacyEventService {

	constructor(
		private eventsManagerService: EventsManagerService,
		private ngZone: NgZone,
		private router: Router,
	) {
	}

	/* istanbul ignore next */
	subscribeToActionEvent() {
		this.eventsManagerService.subscribe(
			LOAD_MODULE_WITH_ACTION,
			(event: ModuleActionEvent) => this.handleEvent(event),
			this,
		);
		this.eventsManagerService.subscribe(
			LOAD_ROUTE,
			(event: ModuleActionEvent) => this.handleRouteEvent(event),
			this,
		);
	}

	/* istanbul ignore next */
	async handleEvent(event: ModuleActionEvent) {
		if (_isNil(event)) {
			return;
		}

		if (event.argument.module) {
			// look up the url for the module
			const module = _find(APP_ROUTING_URL_MAP, {key: event.argument.module});

			if (module) {
				await this.ngZone.run(async () => {
					window.navigationManager.setPreviousPrimaryContext(module.url);
					if (event.argument.routePastPersistedRoute) {
						await StatefulRouteService.routePastPersistedRoute(this.router, module.url);
						setTimeout(() => {
							this.handleAction(event);
						});
					} else {
						await this.router.navigateByUrl(module.url);
						this.handleAction(event);
					}
				});
			}
		} else {
			this.handleAction(event);
		}
	}

	/* istanbul ignore next */
	handleAction(event: ModuleActionEvent) {
		if (event.argument.action) {
			this.eventsManagerService.publish(event.argument.action, event.argument.payload);
		}
	}

	/* istanbul ignore next */
	handleRouteEvent(event: ModuleActionEvent) {
		if (_isNil(event) || _isNil(event.argument) || _isNil(event.argument.route)) {
			return;
		}

		return this.ngZone.run(() => this.router.navigateByUrl(event.argument.route));
	}
}
