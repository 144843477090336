// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.ongoingcare.SearchRefractionTestsRequest */
/* istanbul ignore next */
@GandalfModel
export class SearchRefractionTestsRequest extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate: Date;

	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientId: number;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Refraction Type is required' } })
	@GandalfValidator({ sizeArray: { min: 1, max: 2147483647, message: 'At least 1 Refraction Type is required' } })
	@GandalfArray(constants.RefractionTestMasterId)
	refractionTests: constants.RefractionTestMasterId[];

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate: Date;

}
