import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { _assign, _isNil, _map } from '@core/lodash/lodash';
import { DynamicModalRef, ModalConfig, ObjectUtils, OptionItem } from 'morgana';
import { OptionItemService } from '@core/option-item/option-item.service';
import { AdminLocationTaxResponse } from '@gandalf/model/admin-location-tax-response';
import { PracticeLocationTaxRequest } from '@gandalf/model/practice-location-tax-request';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';
import { AdminLocationService } from '../../../admin-location.service';

@Component({
	selector: 'pms-location-tax-modal',
	templateUrl: './location-tax-modal.component.html',
})
export class LocationTaxModalComponent implements OnInit {

	@ViewChild('locationTaxModal')
	modal: DialogComponent;

	@ViewChild('componentForm')
	componentForm: NgForm;

	formGroup: UntypedFormGroup;
	request: PracticeLocationTaxRequest;
	response: AdminLocationTaxResponse;
	isChildTax: boolean;
	isGroupTax: boolean;
	isNewTax: boolean;
	availableTaxes: any;

	groupTaxOptions: OptionItem[] = [
		{label: 'Single', value: false},
		{label: 'Group', value: true},
	];

	constructor(
		public dynamicModalRef: DynamicModalRef,
		public modalConfig: ModalConfig,
		public gandalfFormBuilder: GandalfFormBuilder,
		public adminLocationService: AdminLocationService,
	) {
	}

	get showGroupTaxOption(): boolean {
		return !this.isChildTax;
	}

	ngOnInit() {
		this.parseData(this.modalConfig.data);
		this.initializeForm();
	}

	parseData(modalData: any) {
		this.response = modalData.tax;
		this.isChildTax = modalData.isChildTax;
		this.isNewTax = modalData.isNewTax;
		this.isGroupTax = !this.isChildTax && !_isNil(this.response) && !ObjectUtils.isNilOrEmpty(this.response.childTaxIds);
		this.availableTaxes = _map(modalData.availableTaxes, tax => OptionItemService.toOptionItem(tax, item => item.taxId, item => item.name));
	}

	initializeForm() {
		this.request = new PracticeLocationTaxRequest();
		this.formGroup = this.gandalfFormBuilder.group(this.request);
		if (!this.isNewTax) {
			this.formGroup.reset(this.response);
		}
	}

	clearFormData(event: any) {
		this.formGroup.get(event.value ? 'rate' : 'childTaxIds').setValue(null);
	}

	submitForm(event: any) {
		this.componentForm.onSubmit(event);
	}

	closeModal() {
		this.dynamicModalRef.close(this.modal, null);
	}

	save() {
		if (this.isGroupTax) {
			// The rate value doesn't matter as long as it passes validation.
			this.formGroup.get('rate').setValue(0);
		}
		if (this.formGroup.invalid) {
			return;
		}
		this.dynamicModalRef.close(this.modal, _assign(new AdminLocationTaxResponse(), this.formGroup.value));
	}
}
