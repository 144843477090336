import { AccountingService } from '@accounting/core/accounting/accounting.service';
import { ReceivePaymentsPayer, ReceivePaymentsService } from '@accounting/invoices/receive-payments/receive-payments.service';
import { RemittanceAdviceViewComponent, RemittanceAdviceViewComponentData } from '@accounting/invoices/receive-payments/remittance-advice-view/remittance-advice-view.component';
import { Component, Input, OnInit } from '@angular/core';
import { PaymentComponentService } from '@core/accounting/payment/payment-component.service';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { _isNil } from '@core/lodash/lodash';
import { PopoutService } from '@core/popout/popout.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { EntityType, PayerType, PaymentGroupSourceType, PaymentGroupStatus, PreferenceDefaults, PreferenceName } from '@gandalf/constants';
import { ReceivePaymentState } from '@payments-store/reducers/payment-tab.reducer';
import { TabAnimationDefaults } from '@shared/constants/tab.constants';
import { EnumUtil, LoadingOverlayMethod, ModalManagerService } from 'morgana';

@Component({
	selector: 'pms-payment-container',
	templateUrl: './payment-container.component.html',
})
export class PaymentContainerComponent implements OnInit {

	@Input()
	payer: ReceivePaymentsPayer;

	@Input()
	paymentGroupId: number;

	@Input()
	state: ReceivePaymentState;

	@Input()
	isModal = false;

	_isLoading: boolean;
	paymentEntityType = EntityType.PAYMENT;
	tabAnimation = TabAnimationDefaults;
	displayViewRemittanceAdvice = false;

	constructor(
		private modalManagerService: ModalManagerService,
		private accountingService: AccountingService,
		private receivePaymentsService: ReceivePaymentsService,
		private popoutService: PopoutService,
		private featureService: FeatureService,
		private securityManagerService: SecurityManagerService,
		public paymentComponentService: PaymentComponentService,
	) {
	}

	ngOnInit() {
		this.loadData();
	}

	loadData() {
		if (!_isNil(this.payer)) {
			this.paymentComponentService.payer = this.payer;
		} else {
			this.getPaymentGroup().subscribe(paymentGroup => {
				this.paymentComponentService.paymentGroup = paymentGroup;
				this.displayViewRemittanceAdvice = this.showViewRemittanceAdvice();
			});
		}
	}

	@LoadingOverlayMethod()
	getPaymentGroup() {
		return this.accountingService.getPaymentGroupById(this.paymentGroupId);
	}

	get showPayment() {
		return !_isNil(this.payer) || (!_isNil(this.paymentGroupId) && !_isNil(this.paymentComponentService.paymentGroup));
	}

	get payerName() {
		return this.payer?.name || this.paymentComponentService.paymentGroup?.payerName;
	}

	get payerType() {
		return this.payer?.type || this.paymentComponentService.paymentGroup?.payerType;
	}

	openInvoiceDetailModal(invoiceId) {
		this.receivePaymentsService.openInvoiceDetailModal(this.modalManagerService, invoiceId).subscribe(result => {
			if (this.isModal && result?.closeParent) {
				this.paymentComponentService.closePayment();
			}
		});
	}

	isPendingERAPaymentGroup() {
		return this.paymentComponentService.paymentGroup?.sourceType === PaymentGroupSourceType.ERA
			&& this.paymentComponentService.paymentGroup?.status === PaymentGroupStatus.PENDING;
	}

	hasRevClearEraSubscription() {
		return this.securityManagerService.practiceClearingHouseIsRevClear() && this.securityManagerService.practiceHasRevClearEraSubscription();
	}

	hasValidInsuranceEraPaymentGroup() {
		if (_isNil(this.paymentComponentService.paymentGroup)) {
			return false;
		}

		return EnumUtil.equals(this.paymentComponentService.paymentGroup.payerType, PayerType.INSURANCE)
			&& !EnumUtil.equals(this.paymentComponentService.paymentGroup.status, PaymentGroupStatus.CANCELED)
			&& EnumUtil.equals(this.paymentComponentService.paymentGroup.sourceType, PaymentGroupSourceType.ERA);
	}

	showViewRemittanceAdvice() {
		return this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.ACCOUNTING.AUTO_POSTING_V2.HUMAN_READABLE)
			&& this.hasRevClearEraSubscription()
			&& this.securityManagerService.preferenceValueIsOn(PreferenceName.ALLOW_ERA_AUTO_POSTING.value, PreferenceDefaults.ALLOW_ERA_AUTO_POSTING.value)
			&& this.hasValidInsuranceEraPaymentGroup();
	}

	openReadableERA() {
		const data = new RemittanceAdviceViewComponentData();
		data.paymentGroupId = this.paymentGroupId;
		this.popoutService.openPopoutWindow(RemittanceAdviceViewComponent, data);
	}
}
