import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { OpenEdgePaymentService } from '@core/open-edge-payment/open-edge-payment.service';
import { OpenEdgeCommonPaymentStatus } from '@gandalf/constants';
import { OpenEdgeCreateStoredCardTransactionRequest } from '@gandalf/model/open-edge-create-stored-card-transaction-request';
import { OpenEdgeTransactionDetailsResponse } from '@gandalf/model/open-edge-transaction-details-response';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';

export enum OnFileModalMode {
	PAYMENT = 'Payment',
	REFUND = 'Refund',
}

@Component({
	selector: 'pms-open-edge-on-file-card-modal',
	templateUrl: './open-edge-on-file-card-modal.component.html',
})
export class OpenEdgeOnFileCardModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	request: OpenEdgeCreateStoredCardTransactionRequest;
	formGroup: UntypedFormGroup;
	locationId: number;
	amount: number;
	payerId: number;
	personId: number;
	paymentStoredTokenId: number;
	isProcessing = true;
	error: string;
	showRetry: boolean;
	transactionMode: OnFileModalMode;

	constructor(
		private modalConfig: ModalConfig,
		private dynamicModalRef: DynamicModalRef,
		private openEdgePaymentService: OpenEdgePaymentService,
		private gandalfFormBuilder: GandalfFormBuilder,
	) {
	}

	ngOnInit(): void {
		this.parseConfigData(this.modalConfig.data);
		this.buildFormForValidation();
		this.createStoredTokenTransaction();
	}

	parseConfigData(data) {
		this.locationId = data.locationId;
		this.amount = data.amount;
		this.payerId = data.payerId;
		this.personId = data.personId;
		this.paymentStoredTokenId = data.paymentStoredTokenId;
		this.transactionMode = data.transactionMode ?? OnFileModalMode.PAYMENT;
	}

	buildFormForValidation() {
		this.request = new OpenEdgeCreateStoredCardTransactionRequest();
		this.request.amount = this.amount;
		this.request.practiceLocationId = this.locationId;
		this.request.personId = this.personId;
		this.request.payerId = this.payerId;
		this.request.paymentStoredTokenId = this.paymentStoredTokenId;

		this.formGroup = this.gandalfFormBuilder.group(this.request);
	}

	private returnCorrectTransactionEndpoint(request) {
		return this.transactionMode === OnFileModalMode.REFUND
			? this.openEdgePaymentService.createStoredTokenRefundTransaction(request)
			: this.openEdgePaymentService.createStoredTokenSaleTransaction(request);
	}

	createStoredTokenTransaction() {
		if (this.formGroup.invalid) {
			return;
		}
		this.showRetry = false;
		this.returnCorrectTransactionEndpoint(this.formGroup.value).subscribe({
			next: (response) => this.handleOpenEdgeTransaction(response),
			error: () => this.isProcessing = false,
		});
	}

	/* istanbul ignore next: closeModal */
	closeModal(result?) {
		this.dynamicModalRef.close(this.modal, result);
	}

	handleOpenEdgeTransaction(transaction: OpenEdgeTransactionDetailsResponse) {
		switch (transaction.paymentStatus.value) {
			case OpenEdgeCommonPaymentStatus.APPROVED.value:
				this.closeModal(transaction);
				break;
			case OpenEdgeCommonPaymentStatus.PARTIAL_APPROVAL_VOIDED.value:
			case OpenEdgeCommonPaymentStatus.PARTIAL_APPROVAL_RETURNED.value:
				this.error = `${this.transactionMode} declined. Please use a different card and try again.`;
				this.showRetry = true;
				this.isProcessing = false;
				break;
			case OpenEdgeCommonPaymentStatus.PARTIAL_APPROVAL_ERROR.value:
				this.openEdgePaymentService.showPaymentNotAppliedAlert(transaction);
				this.closeModal();
				break;
			default:
				this.error = transaction.status?.label || transaction.errorMessage || transaction.paymentStatus.label;
				this.showRetry = true;
				this.isProcessing = false;
				break;
		}
	}
}
