import { Injectable } from '@angular/core';
import { AdditionalPreferencesGandalfService } from '@gandalf/services';

@Injectable({
	providedIn: 'root',
})
export class AdditionalPreferencesService {

	constructor(
		private additionalPreferencesGandalfService: AdditionalPreferencesGandalfService,
	) {
	}

	/* istanbul ignore next: gandalf */
	findCcdaClinicalSummarySectionPreferences() {
		return this.additionalPreferencesGandalfService.findCcdaClinicalSummarySectionPreferences();
	}

	/* istanbul ignore next: gandalf */
	findCcdaPatientRecordSectionPreferences() {
		return this.additionalPreferencesGandalfService.findCcdaPatientRecordSectionPreferences();
	}
}
