<mat-select #matSelect
			[formControl]="ngControl.control"
			multiple
			(focus)="onFocus()"
			[class.disabled]="isDisabled()"
			(keydown.Tab)="onKeyDownTab($event)"
			(keydown.ArrowLeft)="onKeydownLeftArrow($event)"
			(keydown.ArrowRight)="onKeydownRightArrow($event)"
			disableRipple
			[disableOptionCentering]="true"
			[panelClass]="panelClass"
			[placeholder]="inputPlaceholder"
			(openedChange)="onOpenChanged($event)">
	<mat-select-trigger class="hide-border mat-select-trigger">
		<div class="e-multiselect e-input-group e-control-wrapper e-checkbox e-extra-small" tabindex="-1" #tooltipElement>
			<div class="e-multi-select-wrapper e-delimiter">
				@if (selectedAbbreviations?.length > 0) {
					<span class="e-delim-view e-delim-values">
            {{ selectedAbbreviations.join(', ') }}
          </span>
				}
				@if (!ngControl.control.disabled) {
					<span class="e-chips-close e-close-hooker" (click)="clear($event)"></span>
				}
			</div>
		</div>
	</mat-select-trigger>
	<div class="e-ddl e-popup e-multi-select-list-wrapper">
    <span class="e-filter-parent">
      <span class="e-input-group e-control-wrapper e-input-focus">
        <input [revFocusInput]="focusSearchBar"
			   class="e-input-filter e-input focus-visible"
			   [placeholder]="filterBarPlaceholder"
			   (keydown.Space)="onSpacebarKeydown($event)"
			   (input)="filter()"
			   [ngModelOptions]="{standalone: true}"
			   [(ngModel)]="filterText"/>
      </span>
    </span>
		@if (showSelectAll) {
			<div class="e-selectall-parent">
				<mat-checkbox class="mat-option"
							  [disableRipple]="true"
							  (click)="$event.stopPropagation()"
							  (change)="toggleSelection($event)"
							  [indeterminate]="isIntermediate"
							  [checked]="allSelected">
					Select All
				</mat-checkbox>
			</div>
		}
		<div class="e-content e-dropdownbase">
			@if (!filteredOptions) {
				@for (option of dataSource; track trackByValue($index, option)) {
					<mat-option [value]="option[valueField]">{{ option[labelField] }}</mat-option>
				}
			}
			@if (filteredOptions) {
				@for (option of filteredOptions; track trackByValue($index, option)) {
					<mat-option [class.hide]="option.hide" [value]="option[valueField]">{{ option[labelField] }}</mat-option>
				}
			}
		</div>
	</div>
</mat-select>
