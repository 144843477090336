// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { QueryResultFieldResponse } from './query-result-field-response';

// @ts-ignore
import { QueryResultResponse } from './query-result-response';

/** See com.rev360.pms.api.controller.query.QueryResultsResponse */
/* istanbul ignore next */
@GandalfModel
export class QueryResultsResponse extends GandalfModelBase {

	@GandalfProperty()
	allowAddressLabels: boolean;

	@GandalfArray(QueryResultFieldResponse)
	fields: QueryResultFieldResponse[];

	@GandalfProperty()
	queryId: number;

	@GandalfArray(QueryResultResponse)
	results: QueryResultResponse[];

	@GandalfProperty()
	total: number;

}
