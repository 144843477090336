// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.accounting.DepositSlipSearchResponse */
/* istanbul ignore next */
@GandalfModel
export class DepositSlipSearchResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	amount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	cardType: constants.PaymentMethodCreditCardType;

	@GandalfProperty()
	locationId: number;

	@GandalfProperty()
	locationName: string;

	@GandalfProperty()
	payerName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	payerType: constants.PayerType;

	@GandalfProperty()
	paymentDate: Date;

	@GandalfProperty()
	paymentId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	paymentMethod: constants.PaymentMethodType;

	@GandalfProperty()
	referenceNum: string;

}
