import { RouteBuilderService } from '@core/router-utils/route-builder.service';
import { PatientTabActions, PatientTabActionTypes } from '@patients-store/actions/patient-tab.actions';
import { StatefulTabItem } from '@shared/directives/stateful-tab/stateful-tab-item';
import { StatefulTabType } from '@shared/directives/stateful-tab/stateful-tab-type';
import { StatefulTabUtil } from '@shared/directives/stateful-tab/stateful-tab.util';
import { PatientUtilService } from '../../core/patient-util/patient-util.service';

export type PatientTabStateMap = Map<number, PatientTabState>;

export class PatientTabState extends StatefulTabItem {
	constructor(patientId: number, headerText: string, sortOrder: number) {
		super(
			PatientTabState.getDefaultRoute(patientId),
			StatefulTabType.PATIENTS_PATIENT_TAB,
			{headerText, sortOrder},
		);
	}

	private static getDefaultRoute(patientId: number): string {
		const patientRoute = RouteBuilderService.getPatientRoute(patientId);
		return PatientUtilService.isNewPatient(patientId) ? `${patientRoute}/demographics` : patientRoute;
	}
}

export const initialState: PatientTabStateMap = new Map();


export function reducer(state = initialState, action: PatientTabActions): PatientTabStateMap {
	switch (action.type) {

		case PatientTabActionTypes.ADD_PATIENT_TAB: {
			const patientTabStateMap = new Map(state);
			if (!patientTabStateMap.get(action.payload.patientId)) {
				// get sort order
				const sortOrder = StatefulTabUtil.getNextSortOrder(patientTabStateMap);
				patientTabStateMap.set(action.payload.patientId, new PatientTabState(
					action.payload.patientId,
					action.payload.headerText,
					sortOrder));
				return patientTabStateMap;
			} else {
				return state;
			}
		}

		case PatientTabActionTypes.REMOVE_PATIENT_TAB: {
			const patientTabStateMap = new Map(state);
			patientTabStateMap.delete(action.payload.patientId);
			return patientTabStateMap;
		}

		case PatientTabActionTypes.UPDATE_PATIENT_TAB_HEADER_TEXT: {
			const patientTabStateMap = new Map(state);
			const patientTabState = patientTabStateMap.get(action.payload.patientId);
			patientTabStateMap.set(action.payload.patientId, {...patientTabState, headerText: action.payload.headerText});
			return patientTabStateMap;
		}

		case PatientTabActionTypes.UPDATE_PATIENT_TAB_CURRENT_ROUTE: {
			const patientTabStateMap = new Map(state);
			const patientTabState = patientTabStateMap.get(action.payload.patientId);
			if (patientTabState) {
				patientTabState.currentRoute = action.payload.currentRoute;
				patientTabStateMap.set(action.payload.patientId, patientTabState);
			}
			return patientTabStateMap;
		}

		default: {
			return state;
		}
	}
}
