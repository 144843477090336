// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.prescription.IntakeMedicationListResponse */
/* istanbul ignore next */
@GandalfModel
export class IntakeMedicationListResponse extends GandalfModelBase {

	@GandalfProperty()
	description: string;

	@GandalfProperty()
	drugName: string;

	@GandalfProperty()
	intakeMedicationId: number;

	@GandalfProperty()
	prescriptionId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	reconciledStatus: constants.IntakeReconciledStatus;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.IntakeStatus;

	@GandalfConstantDecorator()
	@GandalfProperty()
	type: constants.MedicationPrescriptionType;

}
