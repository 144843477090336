import { Component, Input } from '@angular/core';
import { EyeglassPrescriptionResponse } from '@gandalf/model/eyeglass-prescription-response';

@Component({
	selector: 'pms-eyeglass-rx-recommendations',
	templateUrl: './eyeglass-rx-recommendations.component.html',
	styles: [],
})
export class EyeglassRxRecommendationsComponent {

	@Input()
	eyeglassPrescription: EyeglassPrescriptionResponse;

	constructor() {
	}

}
