<ejs-dialog
    #modal
	(close)="closeModal(false)"
	cssClass="modal-sm">
	<ng-template #header>
		<div class="dlg-template">Void Refund</div>
	</ng-template>
	<ng-template #content>
		<!--
		In the Flex, this is limited to 255, but because the service appends text to the end,
		actually inputting that many characters will cause an SQLException.
		To avoid this in the HTML version, the limit has been lowered to 235.
		-->
		<p>Enter any comments for voiding this refund:</p>
		<ejs-textbox type="text" class="e-input h-rows-5"
					 [multiline]="true" [(ngModel)]="comments" maxlength="235">
		</ejs-textbox>
		<p>{{ comments.length }} of 235 characters</p>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button (click)="closeModal(false)">Cancel</button>
		<button ejs-button [isPrimary]="true" (click)="closeModal(true)">Apply</button>
	</ng-template>
</ejs-dialog>
