import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { AddressResponse } from '@gandalf/model/address-response';

/**
 * @description
 * Angular pipe for displaying a formatted street address (just the address1 and address2 fields)
 *
 * @param address1 - a string
 * @param address2 - a string
 * @usage
 * {{ address1 | formattedSingleLineAddress : address2}}
 */

@Pipe({
	name: 'streetAddress',
})
@Injectable({
	providedIn: 'root',
})
export class StreetAddressPipe implements PipeTransform {

	transform(address: AddressResponse): string {
		if (!address) {
			return '';
		}

		let formattedAddress = '';

		if (address.address1) {
			formattedAddress = formattedAddress + address.address1;
		}

		if (address.address2) {
			if (formattedAddress !== '') {
				formattedAddress = formattedAddress + ' ';
			}
			formattedAddress = formattedAddress + address.address2;
		}

		return formattedAddress.trim();
	}

}
