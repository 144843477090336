// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { OrderStatusUpdateRequest } from './order-status-update-request';

/** See com.rev360.pms.api.controller.order.BulkUpdateOrderRequest */
/* istanbul ignore next */
@GandalfModel
export class BulkUpdateOrderRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Comment cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	comment: string;

	@GandalfValidator({ sizeArray: { min: 1, max: 2147483647, message: 'Orders to be updated are required' } })
	@GandalfValidator({ notNull: { message: 'Orders to be updated are required' } })
	@GandalfArray(OrderStatusUpdateRequest)
	orderStatusUpdates: OrderStatusUpdateRequest[];

}
