<ejs-dialog
	(close)="closeModal()"
	[attr.data-test-id]="'cancelPrescriptionModal'">
	<ng-template #header>
		<div class="dlg-template">Cancel Prescription</div>
	</ng-template>
	<ng-template #content>
		<p class="margin-bottom-md">Are you sure you want to cancel this prescription?</p>
		<form #cancelPrescriptionForm="ngForm" class="form-horizontal" [formGroup]="componentForm" (ngSubmit)="save()">
			@if (componentForm.invalid && cancelPrescriptionForm.submitted) {
				<gandalf-lib-validation-messages
					[form]="componentForm"
					[requestObj]="request"
					[attr.data-test-id]="'cancelPrescriptionModalValidationMessage'">
				</gandalf-lib-validation-messages>
			}

			<div class="form-group">
				<label for="stopDate" class="col-sm-4 control-label required">Date Resolved</label>
				<div class="col-sm-8">
					<ejs-datepicker id="stopDate" placeholder="mm/dd/yyyy" formControlName="stopDate"></ejs-datepicker>
				</div>
			</div>
			<div class="form-group">
				<label for="reasons" class="col-sm-4 control-label required">Reason</label>
				<div class="col-sm-8">
					<ejs-dropdownlist id="reasons"
									  formControlName="reasonId"
									  [dataSource]="reasons"
									  placeholder="Select Reason"
									  [attr.data-test-id]="'cancelPrescriptionModalReasonDropdown'">
					</ejs-dropdownlist>
				</div>
			</div>
			<div class="form-group">
				<label for="comment" class="col-sm-4 control-label">Comments</label>
				<div class="col-sm-8">
					<ejs-textbox id="comment" formControlName="comment" class="h-rows-5"
								 [multiline]="true"></ejs-textbox>
				</div>
			</div>
		</form>
	</ng-template>

	<ng-template #footerTemplate>
		<button ejs-button type="button" iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'cancelPrescriptionModalNoButton'">No</button>
		<button ejs-button type="button" [isPrimary]="true" (click)="submitForm($event)" [attr.data-test-id]="'cancelPrescriptionModalYesButton'">Yes</button>
	</ng-template>
</ejs-dialog>
