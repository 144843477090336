/* eslint-disable @typescript-eslint/naming-convention */

import { EncounterResponse } from '@gandalf/model/encounter-response';
import { Action } from '@ngrx/store';

export enum EncounterHistoryActionTypes {
	GET_ENCOUNTER_HISTORY = '[Get Encounter History] Get Encounter History for a Patient',
	GET_ENCOUNTER_HISTORY_SUCCESS = '[Get Encounter History Success] Get Encounter History for a Patient Success.',
	GET_ENCOUNTER_HISTORY_FAILURE = '[Get Encounter History Failure] Get Encounter History for a Patient Failure.',
	REMOVE_ENCOUNTER_HISTORY = '[Remove Encounter History] Remove Encounter History',
	UPDATE_SHOW_NON_CANCELED_ONLY = '[Update Show Non Canceled Only] Update Show Non-Canceled checkbox value.',
}

export class GetEncounterHistory implements Action {
	readonly type = EncounterHistoryActionTypes.GET_ENCOUNTER_HISTORY;
	constructor(public payload: { patientId: number }) {}
}

export class GetEncounterHistory_Success implements Action {
	readonly type = EncounterHistoryActionTypes.GET_ENCOUNTER_HISTORY_SUCCESS;
	constructor(public payload: { patientId: number, encounters: EncounterResponse[] }) {}
}

export class GetEncounterHistory_Failure implements Action {
	readonly type = EncounterHistoryActionTypes.GET_ENCOUNTER_HISTORY_FAILURE;
	constructor(public payload: any) {}
}

export class RemoveEncounterHistory implements Action {
	readonly type = EncounterHistoryActionTypes.REMOVE_ENCOUNTER_HISTORY;
	constructor(public payload: { patientId: number }) {}
}

export class UpdateShowNonCanceledOnly implements Action {
	readonly type = EncounterHistoryActionTypes.UPDATE_SHOW_NON_CANCELED_ONLY;
	constructor(public payload: { patientId: number, showNonCanceledOnly: boolean }) {}
}

export type EncounterHistoryActions =
	GetEncounterHistory
	| GetEncounterHistory_Success
	| GetEncounterHistory_Failure
	| RemoveEncounterHistory
	| UpdateShowNonCanceledOnly;
