// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FieldOptionResponse } from './field-option-response';

// @ts-ignore
import { FormComponentPositionResponse } from './form-component-position-response';

// @ts-ignore
import { FormFieldResponse } from './form-field-response';

/** See com.rev360.pms.api.controller.encounter.dynamic.form.FormFieldSelectionResponse */
/* istanbul ignore next */
@GandalfModel
export class FormFieldSelectionResponse extends FormFieldResponse {

	@GandalfProperty()
	defaultNormal: string;

	@GandalfProperty()
	defaultValue: string;

	@GandalfProperty()
	fieldId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	formFieldDataType: constants.FieldDataType;

	@GandalfProperty()
	masterIdentifier: string;

	@GandalfProperty()
	name: string;

	@GandalfArray(FieldOptionResponse)
	options: FieldOptionResponse[];

	@GandalfProperty()
	position: FormComponentPositionResponse;

	@GandalfProperty()
	refractionName: string;

	@GandalfProperty()
	tabIndex: number;

	@GandalfProperty()
	uiComponentId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	uiComponentType: constants.FieldUIComponentType;

}
