import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	selector: 'input[pmsCapitalizeFirstLetter]',
})
export class CapitalizeFirstLetterDirective {
	constructor(private ngControl: NgControl) { }

	@HostListener('focusout')
	onFocusOut() {
		const str: string = this.ngControl.control.value;
		if (str) {
			this.ngControl.control.setValue(str.charAt(0).toUpperCase() + str.slice(1));
		}
	}
}
