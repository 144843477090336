<ejs-dialog
	#modal
	cssClass="modal-lg"
	(close)="closeDialog(RxSelectReturnEnum.Nothing)"
	[attr.data-test-id]="'selectPrescriptionModal'">
	<ng-template #header>
		<div class="dlg-template">{{modalTitle}}</div>
	</ng-template>

	<ng-template #content>
		<h4 class="section-title bg-default mrgn-btm">For:
			<strong>{{data.patientName| patientName}}</strong></h4>
		<ng-container *ngIf="!isContactEnhancementsFeatureOn">
			<ejs-grid
				#eyeglassGrid
				*ngIf="data.orderType === OrderTypes.EYEGLASS"
				autoFitColumns
				[pmsGridSubmit]="isSearching"
				[dataSource]="gridData"
				[allowResizing]="true"
				[allowSorting]="true"
				[allowSelection]="true"
				class="table-rowlink"
				gridLines="Horizontal"
				(rowSelected)="closeDialog(RxSelectReturnEnum.CreateOrder, $event.data.eyeglassPrescriptionId)">
				<e-columns>
					<e-column field="startDate" headerText="Rx Date" [format]="gridDateFormat"></e-column>
					<e-column field="expirationDate" headerText="Exp Date" [format]="gridDateFormat"></e-column>
					<e-column field="usedForReasonName" headerText="Use For" [allowSorting]="false"></e-column>
					<e-column headerText="Eye" [allowSorting]="false" [width]="50">
						<ng-template #template let-data>
							OD
							<br/>
							OS
						</ng-template>
					</e-column>
					<e-column headerText="Sph" [allowSorting]="false" [width]="100" [customAttributes]="{class: 'align-top'}">
						<ng-template #template let-data>
							<span *ngIf="!data.odBalanced">{{prescriptionUtil.formatSphere(data.odSphere)}} </span>
							<span *ngIf="data.odBalanced">Balance</span>
							<br/>
							<span *ngIf="!data.osBalanced">{{prescriptionUtil.formatSphere(data.osSphere)}} </span>
							<span *ngIf="data.osBalanced">Balance</span>
						</ng-template>
					</e-column>
					<e-column headerText="Cyl" [allowSorting]="false" [width]="100" [customAttributes]="{class: 'align-top'}">
						<ng-template #template let-data>
							<span *ngIf="!data.odBalanced">{{prescriptionUtil.formatCylinder(data.odCylinder)}} </span>
							<br/>
							<span *ngIf="!data.osBalanced">{{prescriptionUtil.formatCylinder(data.osCylinder)}} </span>
						</ng-template>
					</e-column>
					<e-column headerText="Axis" [allowSorting]="false" [width]="100" [customAttributes]="{class: 'align-top'}">
						<ng-template #template let-data>
							<span *ngIf="!data.odBalanced">{{prescriptionUtil.formatAxis(data.odAxis)}} </span>
							<br/>
							<span *ngIf="!data.osBalanced">{{prescriptionUtil.formatAxis(data.osAxis)}} </span>
						</ng-template>
					</e-column>
					<e-column headerText="Near Add" [allowSorting]="false" [width]="100" [customAttributes]="{class: 'align-top'}">
						<ng-template #template let-data>
							<span *ngIf="!data.odBalanced">{{prescriptionUtil.formatAddPower(data.odNearAdd)}} </span>
							<br/>
							<span *ngIf="!data.osBalanced">{{prescriptionUtil.formatAddPower(data.osNearAdd)}} </span>
						</ng-template>
					</e-column>
					<e-column headerText="H Prism" [allowSorting]="false" [width]="100" [customAttributes]="{class: 'align-top'}">
						<ng-template #template let-data>
							<span *ngIf="!data.odBalanced">{{prescriptionUtil.formatPrism(data.odHorizontalPrism)}} {{data.odHorizontalPrismOrientation}}</span>
							<br/>
							<span *ngIf="!data.osBalanced">{{prescriptionUtil.formatPrism(data.osHorizontalPrism)}} {{data.osHorizontalPrismOrientation}}</span>
						</ng-template>
					</e-column>
					<e-column headerText="V Prism" [allowSorting]="false" [width]="100" [customAttributes]="{class: 'align-top'}">
						<ng-template #template let-data>
							<span *ngIf="!data.odBalanced">{{prescriptionUtil.formatPrism(data.odPrism)}} {{data.odPrismOrientation}}</span>
							<br/>
							<span *ngIf="!data.osBalanced">{{prescriptionUtil.formatPrism(data.osPrism)}} {{data.osPrismOrientation}}</span>
						</ng-template>
					</e-column>
				</e-columns>
			</ejs-grid>
			<ejs-grid
				#contactLensGrid
				*ngIf="data.orderType === OrderTypes.CONTACT_LENS || data.orderType === OrderTypes.CONTACT_LENS_TRIAL"
				autoFitColumns
				[pmsGridSubmit]="isSearching"
				[dataSource]="gridData"
				[allowResizing]="true"
				[allowSorting]="true"
				[allowSelection]="true"
				class="table-rowlink"
				gridLines="Horizontal"
				(rowSelected)="closeDialog(RxSelectReturnEnum.CreateOrder, $event.data.rxId, $event.data.rxDisplayStatus)">
				<e-columns>
					<e-column field="startDate" headerText="Rx Date" [format]="gridDateFormat"></e-column>
					<e-column field="expirationDate" headerText="Exp Date" [format]="gridDateFormat"></e-column>
					<e-column field="rxDisplayStatus.label" headerText="Status" [allowSorting]="false"
							  [width]="100"></e-column>
					<e-column headerText="Eye" [allowSorting]="false" [width]="50">
						<ng-template #template let-data>
							OD<br/>OS
						</ng-template>
					</e-column>
					<e-column headerText="Manufacturer" [allowSorting]="false" [customAttributes]="{class: 'align-top'}">
						<ng-template #template let-data>
							{{data.odManufacturerName}}
							<br/>
							{{data.osManufacturerName}}
						</ng-template>
					</e-column>
					<e-column headerText="Model/Name" [allowSorting]="false" [customAttributes]="{class: 'align-top'}">
						<ng-template #template let-data>
							{{data.odProductName}}
							<br/>
							{{data.osProductName}}
						</ng-template>
					</e-column>
					<e-column field="contactLensType.label" headerText="Type" [allowSorting]="false"
							  [width]="100"></e-column>
				</e-columns>
			</ejs-grid>
		</ng-container>
		<ng-container *ngIf="isContactEnhancementsFeatureOn">
			<rev-ag-grid-container [agGrid]="eyeglassAgGrid" [showToolbar]="false" *ngIf="data.orderType === OrderTypes.EYEGLASS">
				<ng-container rev-container-grid>
					<ag-grid-angular class="ag-theme-alpine"
									 pmsAgAutoFitColumns
									 #eyeglassAgGrid
									 [rowData]="gridData"
									 [gridOptions]="eyeglassGridOptions"
									 (rowClicked)="closeDialogAgGrid(RxSelectReturnEnum.CreateOrder, $event.data.eyeglassPrescriptionId, null)"
									 (gridReady)="onEyeglassGridReady()"
									 [attr.data-test-id]="'selectPrescriptionModalTable'">
					</ag-grid-angular>
				</ng-container>
			</rev-ag-grid-container>
			<rev-ag-grid-container [agGrid]="contactLensGrid" [showToolbar]="false" *ngIf="data.orderType === OrderTypes.CONTACT_LENS || data.orderType === OrderTypes.CONTACT_LENS_TRIAL">
				<ng-container rev-container-grid>
					<ag-grid-angular class="ag-theme-alpine"
									 pmsAgAutoFitColumns
									 #contactLensGrid
									 [rowData]="gridData"
									 [gridOptions]="contactLensGridOptions"
									 (rowClicked)="closeDialogAgGrid(RxSelectReturnEnum.CreateOrder, $event.data.prescriptionId, $event.data.trialId)"
									 (gridReady)="onContactLensGridReady()"
									 [attr.data-test-id]="'selectPrescriptionModalTable'">
					</ag-grid-angular>
				</ng-container>
			</rev-ag-grid-container>
		</ng-container>
	</ng-template>
	<ng-template #footerTemplate>
		<button type="button" ejs-button (click)="closeDialog(RxSelectReturnEnum.Nothing)" iconCss="fa fa-times" [attr.data-test-id]="'cancelButton'">Cancel</button>
		<button *ngIf="data.hasPrevious" type="button" ejs-button [isPrimary]="true" (click)="closeDialog(RxSelectReturnEnum.GoPrevious)"
				iconCss="fa fa-arrow-left" [attr.data-test-id]="'previousButton'">Previous
		</button>
		<button *ngIf="isSkippable()" type="button" ejs-button [isPrimary]="true" (click)="closeDialog(RxSelectReturnEnum.CreateOrder)" [attr.data-test-id]="'selectPrescriptionLaterButton'">
			Select Rx Later
		</button>
	</ng-template>
</ejs-dialog>

<ng-template #authByTooltip let-data>
	<div class="rev-custom-tooltip-content" *ngIf="data.authorizedBy">{{data.authorizedBy | providerName}}</div>
</ng-template>

<ng-template #osOd let-data>
	<span class="label label-success" *ngIf="data.odEye">OD</span>
	<br>
	<span class="label label-info" *ngIf="data.osEye">OS</span>
</ng-template>

<ng-template #model let-data>
	<span>{{ data.odEye?.locationProductName }}</span>
	<br/>
	<span>{{ data.osEye?.locationProductName }}</span>
</ng-template>

<ng-template #type let-data>
	<span *ngIf="data.odEye">{{data.type}}</span>
	<br/>
	<span *ngIf="data.osEye">{{data.type}}</span>
</ng-template>

<ng-template #mv let-data>
	<span *ngIf="data.odEye">{{ data.odEye?.monovision | yesNo:undefined:'' }}</span>
	<br/>
	<span *ngIf="data.osEye">{{data.osEye?.monovision | yesNo:undefined:'' }}</span>
</ng-template>

<ng-template #bc let-data>
	<span>{{PrescriptionUtil.formatBaseCurve(data.odEye?.baseCurve)}}</span>
	<br/>
	<span>{{PrescriptionUtil.formatBaseCurve(data.osEye?.baseCurve)}}</span>
</ng-template>

<ng-template #sph let-data>
	<span>{{PrescriptionUtil.formatSphere(data.odEye?.sphere)}}</span>
	<br/>
	<span>{{PrescriptionUtil.formatSphere(data.osEye?.sphere)}}</span>
</ng-template>

<ng-template #cyl let-data>
	<span>{{PrescriptionUtil.formatCylinder(data.odEye?.cylinder)}}</span>
	<br/>
	<span>{{PrescriptionUtil.formatCylinder(data.osEye?.cylinder)}}</span>
</ng-template>

<ng-template #axis let-data>
	<span>{{PrescriptionUtil.formatAxis(data.odEye?.axis)}}</span>
	<br/>
	<span>{{PrescriptionUtil.formatAxis(data.osEye?.axis)}}</span>
</ng-template>

<ng-template #add let-data>
	<span>{{PrescriptionUtil.formatAddPower(data.odEye?.addPower)}}</span>
	<span> {{data.odEye?.addDesignation}}</span>
	<br/>
	<span>{{PrescriptionUtil.formatAddPower(data.osEye?.addPower)}}</span>
	<span> {{data.osEye?.addDesignation}}</span>
</ng-template>

<ng-template #addTooltipTemplate let-data>
	<div class="rev-custom-tooltip-content" *ngIf="shouldDisplayAddTooltip(data.odEye, data.osEye)">
		<span>{{PrescriptionUtil.formatAddPower(data.odEye?.addPower)}}</span>
		<span> {{data.odEye?.addDesignation}}</span>
		<br/>
		<span>{{PrescriptionUtil.formatAddPower(data.osEye?.addPower)}}</span>
		<span> {{data.osEye?.addDesignation}}</span>
	</div>
</ng-template>

<ng-template #diam let-data>
	<span>{{ data.odEye?.diameter | forceDecimalNullSafe : 1}}</span>
	<br/>
	<span>{{data.osEye?.diameter | forceDecimalNullSafe : 1}}</span>
</ng-template>

<ng-template #eyeglassEyeColumn>
	<ng-container>
		<span class="label label-success">OD</span>
		<br>
		<span class="label label-info">OS</span>
	</ng-container>
</ng-template>

<ng-template #eyeglassSphereColumn let-data>
	<ng-container>
		<span *ngIf="!data.odBalance">{{PrescriptionUtil.formatSphere(data.odSphere)}}</span>
		<span *ngIf="data.odBalance">Balance</span>
		<br/>
		<span *ngIf="!data.osBalance">{{PrescriptionUtil.formatSphere(data.osSphere)}}</span>
		<span *ngIf="data.osBalance">Balance</span>
	</ng-container>
</ng-template>

<ng-template #eyeglassCylinderColumn let-data>
	<ng-container>
		<span *ngIf="!data.odBalance">{{PrescriptionUtil.formatCylinder(data.odCylinder)}}</span>
		<br/>
		<span *ngIf="!data.osBalance">{{PrescriptionUtil.formatCylinder(data.osCylinder)}}</span>
	</ng-container>
</ng-template>

<ng-template #eyeglassAxisColumn let-data>
	<ng-container>
		<span *ngIf="!data.odBalance">{{PrescriptionUtil.formatAxis(data.odAxis)}}</span>
		<br/>
		<span *ngIf="!data.osBalance">{{PrescriptionUtil.formatAxis(data.osAxis)}}</span>
	</ng-container>
</ng-template>

<ng-template #eyeglassNearAddColumn let-data>
	<ng-container>
		<span *ngIf="!data.odBalance">{{PrescriptionUtil.formatAddPower(data.odNearAdd)}}</span>
		<br/>
		<span *ngIf="!data.osBalance">{{PrescriptionUtil.formatAddPower(data.osNearAdd)}}</span>
	</ng-container>
</ng-template>

<ng-template #eyeglassHorizontalPrismColumn let-data>
	<ng-container>
		<span *ngIf="!data.odBalance">{{PrescriptionUtil.formatPrism(data.odHorizontalPrism)}} {{data.odHorizontalPrismOrientation}}</span>
		<br/>
		<span *ngIf="!data.osBalance">{{PrescriptionUtil.formatPrism(data.osHorizontalPrism)}} {{data.osHorizontalPrismOrientation}}</span>
	</ng-container>
</ng-template>

<ng-template #eyeglassVerticalPrismColumn let-data>
	<ng-container>
		<span *ngIf="!data.odBalance">{{PrescriptionUtil.formatPrism(data.odVerticalPrism)}} {{data.odVerticalPrismOrientation}}</span>
		<br/>
		<span *ngIf="!data.osBalance">{{PrescriptionUtil.formatPrism(data.osVerticalPrism)}} {{data.osVerticalPrismOrientation}}</span>
	</ng-container>
</ng-template>
