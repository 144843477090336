// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.schedule.scheduleitem.recurrence.RecurPatternWeeklyResponse */
/* istanbul ignore next */
@GandalfModel
export class RecurPatternWeeklyResponse extends GandalfModelBase {

	@GandalfProperty()
	weeklyAmount: number;

	@GandalfProperty()
	weeklyFriday: boolean;

	@GandalfProperty()
	weeklyMonday: boolean;

	@GandalfProperty()
	weeklySaturday: boolean;

	@GandalfProperty()
	weeklySunday: boolean;

	@GandalfProperty()
	weeklyThursday: boolean;

	@GandalfProperty()
	weeklyTuesday: boolean;

	@GandalfProperty()
	weeklyWednesday: boolean;

}
