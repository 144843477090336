import { Component, Input } from '@angular/core';
import { EncounterStatus } from '@gandalf/constants';

export const ENCOUNTER_STATUS_ICONS = {
	ENCOUNTER_IN_PROGRESS: 'fa-circle',
	ENCOUNTER_COMPLETED: 'fa-check',
};

@Component({
	selector: 'pms-encounter-status-icon',
	templateUrl: './encounter-status-icon.component.html',
	styles: [],
})
export class EncounterStatusIconComponent {

	@Input() status: EncounterStatus;

	constructor() {
	}

	getIcon(): string {
		if (this.status === EncounterStatus.IN_PROGRESS) {
			return ENCOUNTER_STATUS_ICONS.ENCOUNTER_IN_PROGRESS;
		} else if (this.status === EncounterStatus.COMPLETE) {
			return ENCOUNTER_STATUS_ICONS.ENCOUNTER_COMPLETED;
		}
	}
}
