import { Component, Input, ViewChild } from '@angular/core';
import { CategoryEntityType } from '@gandalf/constants';
import { CategoryResponse } from '@gandalf/model/category-response';
import { CategoryListComponent } from './category-list/category-list.component';

@Component({
	selector: 'pms-standard-categories',
	templateUrl: './standard-categories.component.html',
	styles: [],
})
export class StandardCategoriesComponent {

	@Input()
	entityType: CategoryEntityType;

	@Input()
	categoryTitle: string;

	@Input()
	hasSubCategories: boolean;

	categoryData: CategoryResponse;
	detailMode = false;

	@ViewChild('list')
	list: CategoryListComponent;

	constructor() {
	}

	openCategory(categoryData: CategoryResponse) {
		if (!this.detailMode) {
			this.categoryData = categoryData;
			this.detailMode = true;
		}
	}

	closeCategory(refreshRequested: boolean) {
		this.categoryData = null;
		this.detailMode = false;
		refreshRequested ? this.list.getCategoriesList() : this.list.clearSelectedRow();
	}
}
