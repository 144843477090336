// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { EmployeeNameResponse } from './employee-name-response';

// @ts-ignore
import { EncounterRoleResponse } from './encounter-role-response';

// @ts-ignore
import { SecurityRoleResponse } from './security-role-response';

/** See com.rev360.pms.api.controller.admin.employee.AdminEmployeeForEmployeeListResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminEmployeeForEmployeeListResponse extends GandalfModelBase {

	@GandalfProperty()
	address: string;

	@GandalfArray(EncounterRoleResponse)
	encounterRoles: EncounterRoleResponse[];

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	name: EmployeeNameResponse;

	@GandalfProperty()
	preferredPhone: string;

	@GandalfProperty()
	primaryLocationName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.EmployeeStatus;

	@GandalfArray(SecurityRoleResponse)
	userRoles: SecurityRoleResponse[];

	@GandalfProperty()
	username: string;

}
