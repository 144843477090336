// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.CreateGuestInvoiceRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateGuestInvoiceRequest extends GandalfModelBase {

	@GandalfLabel('Address Line 1')
	@GandalfValidator({ sizeString: { message: 'Address Line 1 cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	addressLine1: string;

	@GandalfLabel('Address Line 2')
	@GandalfValidator({ sizeString: { message: 'Address Line 2 cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	addressLine2: string;

	@GandalfLabel('City')
	@GandalfValidator({ sizeString: { message: 'City cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	city: string;

	@GandalfLabel('Email')
	@GandalfValidator({ sizeString: { message: 'Email cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfValidator({ email: { message: 'Invalid email format' } })
	@GandalfProperty()
	email: string;

	@GandalfLabel('Name')
	@GandalfValidator({ sizeString: { message: 'Name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	name: string;

	@GandalfLabel('Phone number')
	@GandalfValidator({ phoneNumber: { message: 'Contact phone must contain at least 10 digits' } })
	@GandalfProperty()
	phoneNumber: string;

	@GandalfLabel('Zip Code')
	@GandalfValidator({ sizeString: { message: 'Zip Code cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfValidator({ postalCode: { message: 'Zip code is invalid' } })
	@GandalfProperty()
	postalCode: string;

	@GandalfLabel('State Province')
	@GandalfValidator({ sizeString: { message: 'State Province cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	stateProvince: string;

}
