// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.PreviewBillableItemResponse */
/* istanbul ignore next */
@GandalfModel
export class PreviewBillableItemResponse extends GandalfModelBase {

	@GandalfProperty()
	description: string;

	@GandalfProperty()
	locationProductId: number;

	@GandalfProperty()
	productCode: string;

	@GandalfProperty()
	quantity: number;

	@GandalfProperty({ propertyType: 'Money' })
	totalPrice: number;

	@GandalfProperty({ propertyType: 'Money' })
	unitPrice: number;

}
