export const WEBCAM_NOT_ALLOWED_ERROR = {
	NAME: 'NotAllowedError',
	TITLE: 'Webcam access not available',
	CONTENT: 'Please grant RevolutionEHR access to your webcam.',
};

export const WEBCAM_NOT_FOUND_ERROR = {
	NAME: 'NotFoundError',
	TITLE: 'No webcam found',
	CONTENT: 'Please install a webcam or check your drivers.',
};

export const WEBCAM_ERRORS = [
	WEBCAM_NOT_ALLOWED_ERROR,
	WEBCAM_NOT_FOUND_ERROR,
];
