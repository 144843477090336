// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.vendorsandpartners.vendor.UpdateVendorLocationConfigRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateVendorLocationConfigRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Account # cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	accountNumber: string;

	@GandalfValidator({ sizeString: { message: 'External/Ship-To # cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	externalLocationId: string;

	@GandalfValidator({ notNull: { message: 'Id is required' } })
	@GandalfProperty({ isRequired: true })
	id: number;

	@GandalfValidator({ sizeString: { message: 'Password cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	password: string;

	@GandalfValidator({ notNull: { message: 'Location is required' } })
	@GandalfProperty({ isRequired: true })
	practiceLocationId: number;

	@GandalfValidator({ sizeString: { message: 'Username cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	userName: string;

}
