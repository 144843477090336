<ejs-dialog #modal cssClass="modal-lg" (close)="closeModal()" [attr.data-test-id]="'smartApplicationModal'">
	<ng-template #header>
		Select SMART Application
	</ng-template>
	<ng-template #content>
		<rev-loading-overlay [loading]="_isLoading">
			<ag-grid-angular class="ag-theme-alpine"
							 pmsAgAutoFitColumns
							 #agGrid
							 [rowData]="practiceSmartApplicationResponses"
							 [gridOptions]="launchSmartApplicationGridOptions"
							 (gridReady)="onGridReady()"
							 (rowClicked)="launchSmartApplication($event)"
							 [pagination]="false"
							 [attr.data-test-id]="'smartApplicationGrid'">
			</ag-grid-angular>
		</rev-loading-overlay>
	</ng-template>
	<ng-template #footerTemplate>
		<rev-button
				(buttonClick)="closeModal()"
				buttonLabel="Cancel"
				buttonIcon="times"
				dataTestId="smartApplicationModalCancelButton">
		</rev-button>
	</ng-template>
</ejs-dialog>
