import { Pipe, PipeTransform } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { Big } from 'big.js';

@Pipe({
	name: 'plusMinusNoRounding',
})
export class PlusMinusNoRoundingPipe implements PipeTransform {
	transform(value: any, precision = 2): string {
		if (!isNaN(Number(value)) && !_isNil(value)) {
			// .round here essentially truncates the value to precision by passing Big an rm of 0.
			// We then call .toFixed to add 0's for any value less than given precision.
			const roundedValue = Big(value).round(precision, 0).toFixed(precision);
			if (value >= 0) {
				return `+${roundedValue}`;
			} else {
				return roundedValue.toString(); // negative places - automatically
			}
		}
		return '';
	}
}
