// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.person.UpdateIntakeAllergiesRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateIntakeAllergiesRequest extends GandalfModelBase {

	@GandalfLabel('Intake Allergy')
	@GandalfArray(Number)
	intakeAllergyIds: number[];

	@GandalfLabel('Intake')
	@GandalfValidator({ notNull: { message: 'Intake is required' } })
	@GandalfProperty({ isRequired: true })
	intakeId: number;

	@GandalfConstantDecorator()
	@GandalfLabel('NoKnownDrugAllergiesReconciledStatus')
	@GandalfProperty()
	noKnownDrugAllergiesReconciledStatus: constants.IntakeReconciledStatus;

	@GandalfConstantDecorator()
	@GandalfLabel('NoLatexSensitivityReconciledStatus')
	@GandalfProperty()
	noLatexSensitivityReconciledStatus: constants.IntakeReconciledStatus;

	@GandalfConstantDecorator()
	@GandalfLabel('NoOtherKnownAllergiesReconciledStatus')
	@GandalfProperty()
	noOtherKnownAllergiesReconciledStatus: constants.IntakeReconciledStatus;

}
