import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { EmployeeDropdownResponse, EmployeeService } from '@core/employee/employee.service';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { ModalBase } from 'morgana';
import { NavigationService } from '@core/navigation/navigation.service';
import { ProviderService } from '@core/provider/provider.service';
import { AppointmentResponse } from '@gandalf/model/appointment-response';
import { StartAppointmentRequest } from '@gandalf/model/start-appointment-request';
import { atLeastOne } from '@shared/validators/atleastOne.validation';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder, TypedFormGroup } from 'gandalf';
import { combineLatest } from 'rxjs';
import { StartAppointmentCalendarResponse } from '@gandalf/model/start-appointment-calendar-response';
import { OptionItemResponse } from '@core/option-item/option-item.service';
import { ProviderWithLocationsResponse } from '@gandalf/model/provider-with-locations-response';
import { featureToken } from '@core/injection-tokens/feature-flag-tokens/feature-flag-tokens';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { ScheduleService } from '../../../schedule/schedule-main/schedule.service';

interface StartAppointmentModalData {
	providerId: number;
	employeeId: number;
	appointmentId: number;
	locationId: number;
	returnCalendarResponse: boolean;
}

@Component({
	selector: 'pms-start-appointment-modal',
	templateUrl: './start-appointment-modal.component.html',
	styles: [],
})
export class StartAppointmentModalComponent extends ModalBase<StartAppointmentModalData, AppointmentResponse | StartAppointmentCalendarResponse> implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('ngForm')
	ngForm: NgForm;

	featureFilterProvidersByLocationAccess = inject(featureToken(FEATURE_FLAGS.FEATURES.SCHEDULING.FILTER_PROVIDERS_BY_LOCATION));

	formGroup: TypedFormGroup<StartAppointmentRequest>;
	employeeList: EmployeeDropdownResponse[];
	providerList: OptionItemResponse<ProviderWithLocationsResponse, number>[];
	isSearching: boolean;
	request: StartAppointmentRequest;

	constructor(
		private gandalfFormBuilder: GandalfFormBuilder,
		private employeeService: EmployeeService,
		private providerService: ProviderService,
		private scheduleService: ScheduleService,
		public eventsManager: EventsManagerService,
		public navigationService: NavigationService,
	) {
		super();
	}

	ngOnInit() {
		this.createForm();
		this.requestData();
	}

	requestData() {
		this.isSearching = true;
		combineLatest([
			this.employeeService.findPracticeEmployeesForDropdown(),
			this.providerService.findPracticeProvidersForDropdown(),
		]).subscribe(([employeeList, providerList]) => {
			this.employeeList = employeeList;
			if (this.featureFilterProvidersByLocationAccess) {
				this.providerList = providerList.filter(
					provider => provider.accessiblePracticeLocationIds.some(accessibleLocationId => accessibleLocationId === this.data.locationId)
						// Super edge case. If the original appointment-provider lost access to the location before
						// starting the appointment, still display them in the provider list.
						|| this.data.providerId === provider.providerId,
				);
			} else {
				this.providerList = providerList;
			}

			this.isSearching = false;
			this.formGroup.enable();
		});
	}

	createForm() {
		this.request = new StartAppointmentRequest();
		this.request.providerId = this.data.providerId;
		this.request.employeeId = this.data.employeeId;
		this.request.appointmentId = this.data.appointmentId;
		this.formGroup = this.gandalfFormBuilder.groupTyped(
			this.request, {
				validators: [atLeastOne(
					Validators.required,
					['providerId', 'employeeId'],
					`Provider or Employee is required`),
				],
			});
		this.formGroup.disable();
	}

	startAppointment() {
		if (this.formGroup.invalid) {
			return;
		}
		const startRequest = this.formGroup.value as StartAppointmentRequest;
		if (this.data.returnCalendarResponse) {
			this.scheduleService.startAppointmentCalendar(startRequest).subscribe(data => {
				this.closeModal(data);
			});
		} else {
			this.scheduleService.startAppointment(startRequest).subscribe(data => {
				this.closeModal(data);
			});
		}
	}

	/* istanbul ignore next: closeModal */
	closeModal(data?: AppointmentResponse | StartAppointmentCalendarResponse) {
		this.dynamicModalRef.close(this.modal, data);
	}

}
