import { AccountingViewService } from '@accounting/core/accounting/accounting-view-util/accounting-view.service';
import { InvoiceService } from '@accounting/core/accounting/invoice-service/invoice.service';
import { EncounterAppointmentSelectModalComponent } from '@accounting/invoices/encounter-appointment-select-modal/encounter-appointment-select-modal.component';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogUtil, EnumUtil, ModalManagerService } from 'morgana';
import { _isNil } from '@core/lodash/lodash';
import { NavigationService } from '@core/navigation/navigation.service';
import { PatientService } from '@core/patient/patient.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { SECURITY_CONSTANTS } from '@core/security/security.constants';
import { InvoiceResponse } from '@gandalf/model/invoice-response';
import { PayerType } from '@gandalf/constants';
import { FinanceChargePlanNameResponse } from '@gandalf/model/finance-charge-plan-name-response';
import { BaseComponent } from '@shared/component/base.component';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { takeUntil } from 'rxjs/operators';
import { AccountingService } from '../../../core/accounting/accounting.service';
import { FinanceChargePlanSelectModalComponent } from '../../finance-charge-plan-select-modal/finance-charge-plan-select-modal.component';

@Component({
	selector: 'pms-invoice-details-basic-info',
	templateUrl: './invoice-details-basic-info.component.html',
	styles: [],
})
export class InvoiceDetailsBasicInfoComponent extends BaseComponent implements OnInit {

	@Input()
	invoiceId: number;

	invoice: InvoiceResponse;

	@Input()
	isReadOnly: boolean;

	@Input()
	isPaymentProcessing: boolean;

	@Output()
	returnEvent = new EventEmitter<any>();

	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	patientCredit: number;
	financeChargePlan: FinanceChargePlanNameResponse;
	practiceFinanceChargePlans: FinanceChargePlanNameResponse[];
	payerType = PayerType;

	constructor(
		private patientService: PatientService,
		private accountingService: AccountingService,
		private invoiceService: InvoiceService,
		private securityManagerService: SecurityManagerService,
		private navigationService: NavigationService,
		private modalManagerService: ModalManagerService,
		private accountingViewService: AccountingViewService,
	) {
		super();
	}

	ngOnInit() {
		this.listenForInvoiceUpdates();
	}

	hasPermissionToGoToPatientView(): boolean {
		return this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_PATIENT_VIEW);
	}

	populatePatientInvoiceInfo() {
		if (this.isInvoicePayerType(PayerType.PATIENT)) {
			this.getPatientCredit(this.invoice.patientId);
			this.getFinanceChargePlanById(this.invoice.financeChargePlanId);
		}
		this.getFinanceChargePlansForPractice();
	}

	listenForInvoiceUpdates() {
		this.invoiceService.getInvoiceDetailsInvoiceState(this.invoiceId)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((invoice) => {
				if (!_isNil(invoice)) {
					this.invoice = invoice;
					this.populatePatientInvoiceInfo();
				}
			});
	}

	getFinanceChargePlansForPractice() {
		this.accountingService.findActiveFinanceChargePlansForDropdown().subscribe(result => {
			this.practiceFinanceChargePlans = result;
		});
	}

	getPatientCredit(patientId: number) {
		this.patientService.getPatientAccount(patientId).subscribe((patientAccountInfo) => {
			this.patientCredit = patientAccountInfo.credit;
		});
	}

	getFinanceChargePlanById(invoiceFinanceChargePlanId: number) {
		if (!_isNil(invoiceFinanceChargePlanId)) {
			this.accountingService.getFinanceChargePlanById(invoiceFinanceChargePlanId).subscribe((financeChargePlan) => {
				this.financeChargePlan = financeChargePlan;
			});
		} else {
			// have to set this to null to cover case that finance charge plan is cleared after being set
			this.financeChargePlan = null;
		}
	}

	navigateToEncounterOrAppointment() {
		this.returnEvent.emit();
		if (!_isNil(this.invoice.encounterId)) {
			return this.navigationService.navigateToEncounter(this.invoice.encounterId, this.invoice.patientId);
		} else if (!_isNil(this.invoice.appointmentId)) {
			return this.navigationService.navigateToPatientAppointment(this.invoice.patientId, this.invoice.appointmentId);
		}
	}

	displayServiceDateLink(): boolean {
		return (this.hasEncounter || this.hasAppointment) && this.hasPermissionToGoToPatientView();
	}

	get hasEncounter(): boolean {
		return !_isNil(this.invoice?.encounterId);
	}

	get hasAppointment(): boolean {
		return !_isNil(this.invoice?.appointmentId);
	}

	get serviceDateTooltipContent(): string {
		if (this.hasEncounter) {
			return 'View Encounter';
		}

		if (this.hasAppointment) {
			return 'View Appointment';
		}

		return null;
	}

	isInvoicePayerType(payerType: PayerType): boolean {
		return !_isNil(this.invoice) && EnumUtil.equals(this.invoice.payerType, payerType);
	}

	isInvoiceApproved(): boolean {
		return !_isNil(this.invoice) && this.invoice.approved;
	}

	openEncounterOrAppointmentSelectModal() {
		this.modalManagerService.open(EncounterAppointmentSelectModalComponent, {
			data: {
				invoiceId: this.invoiceId,
				patientId: this.invoice.patientId,
				encounterId: this.invoice.encounterId,
				appointmentId: this.invoice.appointmentId,
			},
		}).onClose
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(updated => {
				if (updated) {
					this.invoiceService.refreshInvoiceDetailsInvoice(this.invoiceId);
				}
			});
	}

	openFinanceChargePlanSelectModal() {
		this.modalManagerService.open(FinanceChargePlanSelectModalComponent, {
			data: {
				invoiceId: this.invoiceId,
				patientId: this.invoice.patientId,
				financeChargePlanId: this.invoice.financeChargePlanId,
			},
		}).onClose
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(updated => {
				if (updated) {
					this.invoiceService.refreshInvoiceDetailsInvoice(this.invoiceId);
				}
			});
	}

	canChangeEncounter() {
		return !_isNil(this.invoice)
			&& !this.isReadOnly
			&& !this.isPaymentProcessing
			&& this.accountingViewService.canChangeEncounter(this.invoice);
	}

	canChangeFinanceChargePlan() {
		return !_isNil(this.invoice)
			&& !this.isReadOnly
			&& !this.isPaymentProcessing
			&& this.accountingViewService.canChangeFinanceChargePlan(this.invoice, this.practiceFinanceChargePlans);
	}

	canDisassociateEncounter() {
		return !_isNil(this.invoice)
			&& !this.isReadOnly
			&& !this.isPaymentProcessing
			&& this.accountingViewService.canDisassociateEncounter(this.invoice);
	}

	canDisassociateAppointment() {
		return !_isNil(this.invoice)
			&& !this.isReadOnly
			&& !this.isPaymentProcessing
			&& this.accountingViewService.canDisassociateAppointment(this.invoice);
	}

	openDisassociateModal(isEncounter: boolean) {
		const type = isEncounter ? 'encounter' : 'appointment';
		const dialog = DialogUtil.confirm({
			title: `Remove ${type}`,
			content: `Are you sure you want to remove this ${type} from the invoice?`,
			okButton: {
				click: () => {
					if (isEncounter) {
						this.disassociateEncounter(this.invoiceId, dialog);
					} else {
						this.disassociateAppointment(this.invoiceId, dialog);
					}
				},
			},
		});
	}

	disassociateEncounter(invoiceId, dialog) {
		this.accountingService.unassignInvoiceEncounter(invoiceId).subscribe(() => {
			this.invoiceService.refreshInvoiceDetailsInvoice(invoiceId);
			dialog.close();
		});
	}

	disassociateAppointment(invoiceId, dialog) {
		this.accountingService.unassignInvoiceAppointment(invoiceId).subscribe(() => {
			this.invoiceService.refreshInvoiceDetailsInvoice(invoiceId);
			dialog.close();
		});
	}
}
