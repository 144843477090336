import { Injectable } from '@angular/core';
import { PatientDocumentGandalfService } from '@gandalf/services';
import { Observable } from 'rxjs';
import { PatientDocumentResponse } from '@gandalf/model/patient-document-response';
import { PatientDocumentContentRequest } from '@gandalf/model/patient-document-content-request';
import { PatientFileInfoResponse } from '@gandalf/model/patient-file-info-response';

@Injectable({
	providedIn: 'root',
})
export class LetterEditorDockableModalService {

	constructor(
		private patientDocumentGandalfService: PatientDocumentGandalfService,
	) { }

	/* istanbul ignore next: gandalf */
	getPatientDocument(patientDocumentId): Observable<PatientDocumentResponse> {
		return this.patientDocumentGandalfService.getPatientDocument(patientDocumentId);
	}

	/* istanbul ignore next: gandalf */
	updatePatientDocumentContent(patientDocumentContentRequest: PatientDocumentContentRequest): Observable<void> {
		return this.patientDocumentGandalfService.updatePatientDocumentContent(patientDocumentContentRequest);
	}

	/* istanbul ignore next: gandalf */
	createPatientFileFromPatientDocument(patientDocumentContentRequest: PatientDocumentContentRequest): Observable<PatientFileInfoResponse> {
		return this.patientDocumentGandalfService.createPatientFileFromPatientDocument(patientDocumentContentRequest);
	}
}
