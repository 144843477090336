import { AbstractControl } from '@angular/forms';

/**
 * Test that non-null values are unique
 * @return null if tests pass {errorProperty: true} if test fails
 */
export function uniqueValuesValidator(
	valueControlNames: string[],
	errorProperty: string,
	message: string,
) {
	return (formGroup: AbstractControl) => {
		const valueControls = valueControlNames.map(controlName => formGroup.get(controlName));

		const populatedValues = valueControls.map(valueControl => valueControl.value).filter(value => value !== null);

		const uniqueValues = new Set(populatedValues);

		if (uniqueValues.size !== populatedValues.length) {
			const errorObject = { [errorProperty]: true };
			valueControls.forEach(valueControl => {
				valueControl.setErrors(Object.assign(valueControl.errors || {}, errorObject));
			});

			return { [errorProperty]: {range: true, message} };
		} else {
			valueControls.forEach(valueControl => {
				let valueControlError = valueControl.errors;
				if (valueControlError !== null) {
					delete valueControlError[errorProperty];
					if (Object.keys(valueControlError).length === 0) {
						valueControlError = null;
					}
				}
				valueControl.setErrors(valueControlError);
			});

			let groupErrors = formGroup.errors;

			if (groupErrors !== null && groupErrors !== undefined) {
				delete (groupErrors[errorProperty]);
				if (Object.keys(groupErrors).length === 0) {
					groupErrors = null;
				}
			}

			return groupErrors;
		}
	};
}
