// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.insurance.PersonInsuranceResponse */
/* istanbul ignore next */
@GandalfModel
export class PersonInsuranceResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	authorizationDate: Date;

	@GandalfProperty()
	authorizationNumber: string;

	@GandalfProperty()
	coInsurancePercentage: number;

	@GandalfProperty()
	companyId: number;

	@GandalfProperty({ propertyType: 'Money' })
	contactLensAllowance: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	contactLensBenefitResetDate: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	contactLensFittingBenefitResetDate: Date;

	@GandalfProperty({ propertyType: 'Money' })
	contactLensFittingCoPay: number;

	@GandalfProperty({ propertyType: 'Money' })
	deductible: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	effectiveDate: Date;

	@GandalfProperty({ propertyType: 'Money' })
	familyDeductible: number;

	@GandalfProperty()
	feeScheduleId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	frameBenefitResetDate: Date;

	@GandalfProperty({ propertyType: 'Money' })
	generalCoPay: number;

	@GandalfProperty()
	groupNumber: string;

	@GandalfProperty()
	hasContactLensBenefit: boolean;

	@GandalfProperty()
	hasContactLensFittingBenefit: boolean;

	@GandalfProperty()
	hasFrameBenefit: boolean;

	@GandalfProperty()
	hasLensBenefit: boolean;

	@GandalfProperty()
	hasRoutineExamBenefit: boolean;

	@GandalfProperty()
	insuranceTypeId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	lensBenefitResetDate: Date;

	@GandalfProperty({ propertyType: 'Money' })
	materialCoPay: number;

	@GandalfProperty({ propertyType: 'Money' })
	maxFrameAllowance: number;

	@GandalfProperty({ propertyType: 'Money' })
	medicalExamCoPay: number;

	@GandalfProperty({ propertyType: 'Money' })
	minFrameAllowance: number;

	@GandalfProperty()
	patientBenefits: string;

	@GandalfProperty()
	personId: number;

	@GandalfProperty()
	personInsuranceId: number;

	@GandalfProperty()
	planName: string;

	@GandalfProperty({ propertyType: 'Money' })
	policyContactLensAllowance: number;

	@GandalfProperty({ propertyType: 'Money' })
	policyContactLensFittingCoPay: number;

	@GandalfProperty({ propertyType: 'Money' })
	policyGeneralCoPay: number;

	@GandalfProperty()
	policyHolderBenefits: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	policyHolderDob: Date;

	@GandalfProperty()
	policyHolderName: PersonNameResponse;

	@GandalfProperty()
	policyHolderPersonId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	policyHolderRelationship: constants.PolicyHolderRelationship;

	@GandalfProperty()
	policyId: number;

	@GandalfProperty({ propertyType: 'Money' })
	policyMaterialCoPay: number;

	@GandalfProperty({ propertyType: 'Money' })
	policyMaxFrameAllowance: number;

	@GandalfProperty({ propertyType: 'Money' })
	policyMedicalExamCoPay: number;

	@GandalfProperty({ propertyType: 'Money' })
	policyMinFrameAllowance: number;

	@GandalfProperty()
	policyNumber: string;

	@GandalfProperty({ propertyType: 'Money' })
	policyRoutineExamCoPay: number;

	@GandalfProperty()
	policyRxBinNumber: string;

	@GandalfProperty()
	policyRxPcnNumber: string;

	@GandalfProperty()
	priorityId: number;

	@GandalfProperty()
	referralRequired: boolean;

	@GandalfProperty({ propertyType: 'LocalDate' })
	routineExamBenefitResetDate: Date;

	@GandalfProperty({ propertyType: 'Money' })
	routineExamCoPay: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.PersonInsuranceStatus;

	@GandalfProperty({ propertyType: 'LocalDate' })
	termedDate: Date;

}
