export class UpdateStatePropertyPayload {
	readonly key: any;
	readonly value: any;
	readonly objectId: number;

	private constructor(key: any, value: any, objectId: any) {
		this.key = key;
		this.value = value;
		this.objectId = objectId;
	}

	static build<T, U extends keyof T>(stateClass: new () => T, key: U, value: T[U], objectId?: number) {
		return new UpdateStatePropertyPayload(key, value, objectId);
	}
}
