import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicModalRef, MODAL, ModalConfig } from 'morgana';
import { PatientFileEntityType } from '@gandalf/constants';
import { PatientDocumentsComponent } from '@shared/component/patient-documents/patient-documents.component';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
	selector: 'pms-patient-documents-modal',
	templateUrl: './patient-documents-modal.component.html',
	styles: [],
})
export class PatientDocumentsModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('documents')
	patientDocumentsComponent: PatientDocumentsComponent;

	title: string;
	parentId: number;
	patientId: number;
	entityType: PatientFileEntityType;
	encounterId: number;
	disabled = true;
	zIndexBehindHitPmsHtmlModal = MODAL.Z_INDEX_BEHIND_HIT_PMS_HTML_MODAL;

	constructor(
		private config: ModalConfig,
		public dynamicModalRef: DynamicModalRef,

	) {
	}

	ngOnInit() {
		this.parseConfigData(this.config.data);
	}

	parseConfigData(data: any) {
		this.title = data.title;
		this.patientId = data.patientId;
		this.parentId = data.parentId;
		this.entityType = data.entityType;
		this.encounterId = data.encounterId;
		this.disabled = data.disabled;
	}

	/* istanbul ignore next: closeModal */
	closeModal() {
		this.dynamicModalRef.close(this.modal, this.patientDocumentsComponent.fileCount);
	}

}
