import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { FolderService, SimpleFolder } from '@core/folder/folder.service';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { CreateFolderRequest } from '@gandalf/model/create-folder-request';
import { UpdateFolderRequest } from '@gandalf/model/update-folder-request';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';

@Component({
	selector: 'pms-update-patient-directory',
	templateUrl: './update-patient-directory-modal.component.html',
})
export class UpdatePatientDirectoryModalComponent implements OnInit {
	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('templateForm')
	templateForm: NgForm;

	componentForm: UntypedFormGroup;
	selectedFolderId: string;
	request: CreateFolderRequest | UpdateFolderRequest;
	isUpdate = false;
	originalFolderName: string;

	constructor(
		public ref: DynamicModalRef,
		private modalConfig: ModalConfig,
		private folderService: FolderService,
		private gandalfFormBuilder: GandalfFormBuilder,
	) {
	}

	ngOnInit() {
		this.parseModalConfig(this.modalConfig.data);
		this.createRequest();
		this.createForm();
	}

	createForm() {
		this.componentForm = this.gandalfFormBuilder.group(this.request);
	}

	createRequest() {
		if (this.isUpdate) {
			this.request = new UpdateFolderRequest();
			this.request.folderId = this.selectedFolderId;
			this.request.name = this.originalFolderName;
		} else {
			const request  = new CreateFolderRequest();
			request.parentFolderId = this.selectedFolderId;
			this.request = request;
		}
	}

	parseModalConfig(data: any) {
		this.selectedFolderId = data.selectedFolderId;
		this.isUpdate = !!data.isUpdate;
		this.originalFolderName = data.folderName;
	}

	closeModal(newFolderId?: SimpleFolder) {
		this.ref.close(this.modal, newFolderId);
	}

	submitForm(event) {
		this.templateForm.onSubmit(event);
	}

	addOrUpdateDirectory() {
		if (this.componentForm.invalid) {
			return;
		}
		if (this.isUpdate) {
			const updateRequest: UpdateFolderRequest = this.componentForm.value;
			this.folderService.updateFolder(updateRequest).subscribe(() => this.closeModal({folderId: this.selectedFolderId, name: updateRequest.name, children: []}));
		} else {
			const createRequest: CreateFolderRequest = this.componentForm.value;
			this.folderService.addFolder(createRequest).subscribe((response) => this.closeModal({folderId: response.id, name: response.name, children: []}));
		}
	}
}
