<div tabindex="-1" (focusout)="onBlur($event)">
	<div class="form-group has-feedback" [attr.data-test-id]="'headerPatientSearch'">
		<input type="text" class="form-control" revFocusInput [(ngModel)]="searchString" (input)="debounceSearch()" placeholder="Patient Search"/>
		<span class="fa fa-search form-control-feedback" aria-hidden="true"></span>
	</div>
	@if (showResults) {
		<ul class="navbar-search-results dropdown-menu" (mouseenter)="stopTimeoutTimer()" (mouseleave)="startTimeoutTimer()"
			[attr.data-test-id]="'navbarSearchResultMenu'">
			<li class="search-results-heading dropdown-header">PATIENTS</li>
			@for (patient of patientResults; track patient; let index = $index) {
				<li class="search-results-item" [attr.data-test-id]="'headerSearchResult_' + index">
					<a revEventStopPropagation (click)="navigateToPatient(patient.patientId)">
						<span class="search-results-name">{{ patient.patientNameResponse | patientName: true }}</span>
						<span class="search-results-dob">{{ patient.dateOfBirth | date : dateFormat }}</span>
						<div class="search-results-info">
							<span class="search-results-phone">{{ patient.preferredPhoneNumber }}</span>
							<span class="search-results-address">
                @if (patient.addressResponse?.address1) {
					<span>{{ patient.addressResponse.address1 }} </span>
				}
								@if (patient.addressResponse?.address2) {
									<span>{{ patient.addressResponse.address2 }}, </span>
								}
								@if (patient.addressResponse?.city) {
									<span>{{ patient.addressResponse.city }}, </span>
								}
								@if (patient.addressResponse?.stateProvince) {
									<span>{{ patient.addressResponse.stateProvince }} </span>
								}
								@if (patient.addressResponse?.postalCode) {
									<span>{{ patient.addressResponse.postalCode }}</span>
								}
              </span>
						</div>
					</a>
				</li>
			}
			@if (showNoResultsText(patientResults)) {
				<li class="search-results-item">
					<a><span class="search-results-name">No results found</span></a>
				</li>
			}
			@if (showMaxResultsText) {
				<li class="search-results-item">
					<a><span class="search-results-name">Top 10 matches. Refine search for additional results.</span></a>
				</li>
			}
		</ul>
	}
</div>
