// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreateFinanceChargePlanPeriodRequest } from './create-finance-charge-plan-period-request';

/** See com.rev360.pms.api.controller.admin.accounting.CreateFinanceChargePlanRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateFinanceChargePlanRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Description is required' } })
	@GandalfValidator({ sizeString: { message: 'Description must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ isRequired: true })
	description: string;

	@GandalfValidator({ notNull: { message: 'Minimum Balance Due is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	minimumBalanceUsdDue: number;

	@GandalfValidator({ notNull: { message: 'Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Name must be between 1 and 70 characters', minLength: 1, maxLength: 70 } })
	@GandalfProperty({ isRequired: true })
	name: string;

	@GandalfArray(CreateFinanceChargePlanPeriodRequest)
	periods: CreateFinanceChargePlanPeriodRequest[];

}
