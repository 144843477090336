import { Data, Params } from '@angular/router';
import { EventsState, reducer as EventsReducer } from '@app-store/reducers/events.reducer';
import { FormStateMap, reducer as FormsReducer } from '@app-store/reducers/forms.reducer';
import { GridStateMap, reducer as GridReducer } from '@app-store/reducers/grid.reducer';
import { HierarchiesStateMap, reducer as HierarchiesReducer } from '@app-store/reducers/hierarchies.reducer';
import { PropertiesStateMap, reducer as PropertiesReducer } from '@app-store/reducers/properties.reducer';
import { reducer as RoutesReducer, RoutesStateMap } from '@app-store/reducers/routes.reducer';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, createSelector } from '@ngrx/store';

export interface RouterState {
	url: string;
	queryParams: Params;
	params: Params;
	data: Data;
}

export interface State {
	events: EventsState;
	forms: FormStateMap;
	grids: GridStateMap;
	hierarchies: HierarchiesStateMap;
	properties: PropertiesStateMap;
	router: RouterReducerState<RouterState>;
	routes: RoutesStateMap;
}

export interface ITableState {
	tableConfigurationJson: string;
	tableResults?: any[];
	isSearched?: boolean;
}

export class TableState {
	tableConfigurationJson = '';

	tableResults = [];
	isSearched = false;
	constructor() {
	}
}

export const reducers: ActionReducerMap<State> = {
	events: EventsReducer,
	forms: FormsReducer,
	grids: GridReducer,
	hierarchies: HierarchiesReducer,
	properties: PropertiesReducer,
	router: routerReducer,
	routes: RoutesReducer,
};

export const selectRouterState = createSelector((state: State) => state.router, value => value);
