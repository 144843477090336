import { Injectable } from '@angular/core';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { HIT_PMS_HTML_EVENTS } from '@core/legacy/hit-pms-html.constants';

@Injectable({
	providedIn: 'root',
})
export class SummaryPodService {

	constructor(
		private eventsManagerService: EventsManagerService,
	) { }

	updateRxSummaryPod(patientId: number) {
		this.eventsManagerService.publish(HIT_PMS_HTML_EVENTS.PATIENTS.RX_SUMMARY_UPDATED_PATIENT_ID, patientId);
	}

	updateExamHistorySummaryPod(patientId: number) {
		this.eventsManagerService.publish(HIT_PMS_HTML_EVENTS.PATIENTS.HISTORY_SUMMARY_UPDATED, patientId);
	}

	updateDiagnosisHistorySummaryPod(patientId: number) {
		this.eventsManagerService.publish(HIT_PMS_HTML_EVENTS.PATIENTS.DIAGNOSIS_SUMMARY_UPDATED_PATIENT_ID, patientId);
	}

	updateOrdersOpticalSummaryPod(patientId: number) {
		this.eventsManagerService.publish(HIT_PMS_HTML_EVENTS.PATIENTS.OPTICAL_SUMMARY_UPDATED, patientId);
	}

	updateFamilySummaryPod(patientId: number) {
		this.eventsManagerService.publish(HIT_PMS_HTML_EVENTS.PATIENTS.FAMILY_SUMMARY_UPDATED, patientId);
	}

	updateInsuranceSummaryPod(personId: number) {
		this.eventsManagerService.publish(HIT_PMS_HTML_EVENTS.PATIENTS.INSURANCE_SUMMARY_UPDATED, personId);
	}

	updateAccountingSummaryPod(patientId: number) {
		this.eventsManagerService.publish(HIT_PMS_HTML_EVENTS.PATIENTS.ACCOUNT_SUMMARY_UPDATED, patientId);
	}

	updateRecallSummaryPod(patientId: number) {
		this.eventsManagerService.publish(HIT_PMS_HTML_EVENTS.PATIENTS.RECALL_SUMMARY_UPDATED, patientId);
	}

	updateNotesSummaryPod(patientId: number) {
		this.eventsManagerService.publish(HIT_PMS_HTML_EVENTS.PATIENTS.NOTES_SUMMARY_UPDATED, patientId);
	}
}
