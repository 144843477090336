import { Component, Input, OnInit } from '@angular/core';
import { PreferenceName } from '@gandalf/constants';

@Component({
	selector: 'pms-additional-preference-label',
	templateUrl: './additional-preference-label.component.html',
	styles: [],
})
export class AdditionalPreferenceLabelComponent implements OnInit {

	tooltip: string;

	@Input()
	label: PreferenceName | string;

	@Input()
	tooltipContent: string;

	constructor() {
	}

	ngOnInit() {
		this.tooltip = `<h4>${this.label}</h4><hr/><p>${this.tooltipContent}</p>`;
	}


}
