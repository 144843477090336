// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { GlaucomaCareMedicationPrescriptionResponse } from './glaucoma-care-medication-prescription-response';

// @ts-ignore
import { GlaucomaCareServiceHistoryResponse } from './glaucoma-care-service-history-response';

// @ts-ignore
import { GlaucomaDataResponse } from './glaucoma-data-response';

// @ts-ignore
import { TargetIopResponse } from './target-iop-response';

/** See com.rev360.pms.api.controller.patient.ongoingcare.GlaucomaCareResponse */
/* istanbul ignore next */
@GandalfModel
export class GlaucomaCareResponse extends GandalfModelBase {

	@GandalfArray(GlaucomaDataResponse)
	iopHistory: GlaucomaDataResponse[];

	@GandalfArray(GlaucomaCareMedicationPrescriptionResponse)
	medications: GlaucomaCareMedicationPrescriptionResponse[];

	@GandalfProperty()
	odIopAdjusted: number;

	@GandalfProperty()
	odIopInitial: number;

	@GandalfProperty()
	odIopMaximum: number;

	@GandalfProperty()
	odIopTarget: number;

	@GandalfProperty()
	odOpticalPachymetry: string;

	@GandalfProperty()
	odPachymetry: string;

	@GandalfProperty()
	osIopAdjusted: number;

	@GandalfProperty()
	osIopInitial: number;

	@GandalfProperty()
	osIopMaximum: number;

	@GandalfProperty()
	osIopTarget: number;

	@GandalfProperty()
	osOpticalPachymetry: string;

	@GandalfProperty()
	osPachymetry: string;

	@GandalfArray(GlaucomaCareServiceHistoryResponse)
	serviceHistory: GlaucomaCareServiceHistoryResponse[];

	@GandalfArray(TargetIopResponse)
	targetIops: TargetIopResponse[];

}
