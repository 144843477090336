import { Injectable } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { SortingService } from 'morgana';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { RefractionTestMasterId } from '@gandalf/constants';
import { AuthorizeContactLensPrescriptionRequest } from '@gandalf/model/authorize-contact-lens-prescription-request';
import { CreateEyeglassPrescriptionRequest } from '@gandalf/model/create-eyeglass-prescription-request';
import { EyeglassPrescriptionEyeRequest } from '@gandalf/model/eyeglass-prescription-eye-request';
import { RefractionEyeResponse } from '@gandalf/model/refraction-eye-response';
import { RefractionResponse } from '@gandalf/model/refraction-response';
import {
	ContactLensHistoryGandalfService,
	ContactLensPrescriptionGandalfService,
	ContactLensTrialGandalfService,
	EyeglassPrescriptionGandalfService,
	RefractionGandalfService
} from '@gandalf/services';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class OpticalHistoryService {
	private _trialsUpdated = new Subject<any>();

	constructor(
		public contactLensTrialGandalfService: ContactLensTrialGandalfService,
		public contactLensPrescriptionGandalfService: ContactLensPrescriptionGandalfService,
		public eyeglassPrescriptionGandalfService: EyeglassPrescriptionGandalfService,
		public refractionGandalfService: RefractionGandalfService,
		public contactLensHistoryGandalfService: ContactLensHistoryGandalfService,
	) {
	}

	/* istanbul ignore next: gandalf */
	findContactLensTrialsForPatient(patientId: number) {
		return this.contactLensTrialGandalfService.findContactLensTrialsForPatient(patientId);
	}

	/* istanbul ignore next: gandalf */
	findEyeglassPrescriptionHistoryByPatientId(patientId: number) {
		return this.eyeglassPrescriptionGandalfService.findEyeglassPrescriptionHistoryByPatientId(patientId);
	}

	searchRefractionTests(encounterId: number) {
		return this.refractionGandalfService.findRefractionsByEncounterId(encounterId).pipe(map((results) =>
			// Sort tests in the following order:
			// 1. "Final Refraction With Prism" test that has entered values
			// 2. "Final Refraction With Prism - Metric" test that has entered values
			// 3. "Final Refraction" test that has entered values
			// 4. all other tests that have entered values from most recently updated to last updated
			// 5. all tests that have no entered values in order by ID ascending
			SortingService.sortBy(results, [
				value => this.hasEnteredValues(value), // first sorts by entered
					// first tie breaker is these names first if they are in the entered data
				value => this.hasEnteredValues(value) && value.masterId === RefractionTestMasterId.FINAL_REFRACTION_WITH_PRISM.value,
				value => this.hasEnteredValues(value) && value.masterId === RefractionTestMasterId.FINAL_REFRACTION_WITH_PRISM_METRIC.value,
				value => this.hasEnteredValues(value) && value.masterId === RefractionTestMasterId.FINAL_REFRACTION.value,
				value => value.lastUpdatedFieldTime, // sort by lastUpdatedFieldTime next
				value => value.masterId, // sort by masterid as last fallback
			], ['desc', 'desc', 'desc', 'desc', 'desc', 'desc'])
			,
		));
	}

	hasEnteredValues(refractionResponse: RefractionResponse) {
		return this.eyeHasEnteredValues(refractionResponse.od) || this.eyeHasEnteredValues(refractionResponse.os);
	}

	eyeHasEnteredValues(refractionEyeResponse: RefractionEyeResponse) {
		return !_isNil(refractionEyeResponse.axis)
			|| !_isNil(refractionEyeResponse.cylinder)
			|| !_isNil(refractionEyeResponse.sphere)
			|| !!(refractionEyeResponse.balanced)
			|| !_isNil(refractionEyeResponse.verticalPrism)
			|| !_isNil(refractionEyeResponse.nearAdd)
			|| !_isNil(refractionEyeResponse.intermediateAdd)
			|| !_isNil(refractionEyeResponse.verticalPrismOrientation)
			|| !_isNil(refractionEyeResponse.horizontalPrism)
			|| !_isNil(refractionEyeResponse.horizontalPrismOrientation);
	}

	/* istanbul ignore next: gandalf */
	findContactLensHistoryByEncounterId(encounterId: number) {
		return this.contactLensHistoryGandalfService.findOrCreateContactLensHistoryByEncounterId(encounterId);
	}

	@ShowSavedSuccessToast()
	authorizeContactLensPrescription(rxId: number) {
		const request = new AuthorizeContactLensPrescriptionRequest();
		request.useSignature = false;
		request.contactLensPrescriptionId = rxId;
		return this.contactLensPrescriptionGandalfService.authorizeContactLensPrescription(request);
	}

	@ShowSavedSuccessToast()
	authorizeAndSignContactLensPrescription(rxId: number) {
		const request = new AuthorizeContactLensPrescriptionRequest();
		request.useSignature = true;
		request.contactLensPrescriptionId = rxId;
		return this.contactLensPrescriptionGandalfService.authorizeContactLensPrescription(request);
	}

	get trialsUpdated() {
		return this._trialsUpdated.asObservable();
	}

	markTrialsUpdated() {
		this._trialsUpdated.next(true);
	}

	buildMultipleEyeglassRxsRequest(patientId, encounterId, response) {
		let data;
		if (!_isNil(response.refractiveTestResult)) {
			data = response.refractiveTestResult;
		}
		if (!_isNil(response.eyeglassRxResult)) {
			data = response.eyeglassRxResult;
		}

		const request = new CreateEyeglassPrescriptionRequest();
		request.patientId = patientId;
		request.encounterId = encounterId;
		request.od = new EyeglassPrescriptionEyeRequest();
		request.os = new EyeglassPrescriptionEyeRequest();

		request.od.sphere = data.od.sphere;
		request.os.sphere = data.os.sphere;
		request.od.nearAdd = data.od.nearAdd;
		request.os.nearAdd = data.os.nearAdd;
		request.od.cylinder = data.od.cylinder;
		request.os.cylinder = data.os.cylinder;
		request.od.axis = data.od.axis;
		request.os.axis = data.os.axis;
		request.od.horizontalPrism = data.od.horizontalPrism;
		request.os.horizontalPrism = data.os.horizontalPrism;
		request.od.horizontalPrismOrientation = data.od.horizontalPrismOrientation;
		request.os.horizontalPrismOrientation = data.os.horizontalPrismOrientation;
		request.od.verticalPrism = data.od.verticalPrism;
		request.os.verticalPrism = data.os.verticalPrism;
		request.od.verticalPrismOrientation = data.od.verticalPrismOrientation;
		request.os.verticalPrismOrientation = data.os.verticalPrismOrientation;

		return request;
	}
}
