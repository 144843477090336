// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeHybridResponse } from './contact-lens-eye-hybrid-response';

// @ts-ignore
import { ContactLensEyeRgpResponse } from './contact-lens-eye-rgp-response';

// @ts-ignore
import { ContactLensEyeSoftResponse } from './contact-lens-eye-soft-response';

// @ts-ignore
import { MasterReferenceDataResponse } from './master-reference-data-response';

// @ts-ignore
import { ProductForPrescriptionResponse } from './product-for-prescription-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.prescription.ContactLensPrescriptionResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensPrescriptionResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	authorizationDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	authorizationType: constants.PrescriptionAuthorizationType;

	@GandalfProperty()
	authorizedBy: ProviderResponse;

	@GandalfProperty()
	cleanerName: string;

	@GandalfProperty()
	contactLensEyeHybridOd: ContactLensEyeHybridResponse;

	@GandalfProperty()
	contactLensEyeHybridOs: ContactLensEyeHybridResponse;

	@GandalfProperty()
	contactLensEyeRgpOd: ContactLensEyeRgpResponse;

	@GandalfProperty()
	contactLensEyeRgpOs: ContactLensEyeRgpResponse;

	@GandalfProperty()
	contactLensEyeSoftOd: ContactLensEyeSoftResponse;

	@GandalfProperty()
	contactLensEyeSoftOs: ContactLensEyeSoftResponse;

	@GandalfProperty()
	deliveryMethod: ReferenceDataResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	derivedStatus: constants.PrescriptionCurrentStatus;

	@GandalfProperty()
	description: string;

	@GandalfProperty()
	dispenseOdAmount: number;

	@GandalfProperty()
	dispenseOdUnit: MasterReferenceDataResponse;

	@GandalfProperty()
	dispenseOsAmount: number;

	@GandalfProperty()
	dispenseOsUnit: MasterReferenceDataResponse;

	@GandalfProperty()
	dropsName: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate: Date;

	@GandalfProperty()
	enzymaticCleanerName: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	expirationDate: Date;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	isPHREnabled: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	modality: constants.ContactLensModality;

	@GandalfConstantDecorator()
	@GandalfProperty()
	overallLensType: constants.ContactLensPrescriptionType;

	@GandalfProperty()
	productOd: ProductForPrescriptionResponse;

	@GandalfProperty()
	productOs: ProductForPrescriptionResponse;

	@GandalfProperty()
	solutionName: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.PrescriptionStatus;

}
