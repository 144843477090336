<form class="form-horizontal" [formGroup]="componentForm">
	<div class="form-group">
		<label class="col-sm-4 col-md-3 control-label required">Family Role</label>
		<div class="col-sm-8 col-md-9">
			<ejs-dropdownlist [dataSource]="familyRoleOptions"
							  pmsAutoSelect
							  formControlName="familyRoleId"
							  placeholder="Select Family Role">
			</ejs-dropdownlist>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'familyInformationHeadOfHouseholdFormGroup'">
		<div class="col-sm-offset-4 col-md-offset-3 col-sm-8 col-md-9">
			<ejs-checkbox label="Head of Household" formControlName="headOfHousehold"></ejs-checkbox>
		</div>
	</div>
</form>
