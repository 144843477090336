import { Injectable } from '@angular/core';
import { EnumUtil, SortingService } from 'morgana';
import { GandalfTheGreyService } from '@core/gandalf-the-grey/gandalf-the-grey.service';
import { _isNil } from '@core/lodash/lodash';
import { ContactLensType, HorizontalPrismOrientation, PrescriptionStatus, RxDisplayStatusLegacy, VerticalPrismOrientation } from '@gandalf/constants';
import { EyeglassPrescriptionListResponse } from '@gandalf/model/eyeglass-prescription-list-response';
import { RxSelectContactLensResponse } from '@gandalf/model/rx-select-contact-lens-response';
import { RxSelectEyeglassResponse } from '@gandalf/model/rx-select-eyeglass-response';
import { ContactLensPrescriptionGandalfService, EyeglassPrescriptionGandalfService, SharedPatientOrderGandalfService } from '@gandalf/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PrescriptionSelectContactLensResponse } from '@gandalf/model/prescription-select-contact-lens-response';
import { EyeglassOrderSearchResponse } from '@gandalf/model/eyeglass-order-search-response';

@Injectable({
	providedIn: 'root',
})
export class RxSelectService {

	constructor(
		private gandalfTheGreyService: GandalfTheGreyService,
		private eyeglassPrescriptionGandalfService: EyeglassPrescriptionGandalfService,
		private contactLensPrescriptionGandalfService: ContactLensPrescriptionGandalfService,
		private sharedPatientOrderGandalfService: SharedPatientOrderGandalfService,
	) {
	}

	buildLegacyRequest(serviceName: string, endpointName: string, personId: number): any {
		const legacyRequest = this.gandalfTheGreyService.createRequestInstance(serviceName, endpointName);
		legacyRequest.id = personId;
		return legacyRequest;
	}

	handleLegacyEyeglassRxResponse(legacyResponse: any) {
		const response = new RxSelectEyeglassResponse();
		response.eyeglassPrescriptionId = legacyResponse.id;
		response.startDate = legacyResponse.startDate;
		response.expirationDate = legacyResponse.expirationDate;
		response.usedForReasonName = legacyResponse.usedForReasonName;
		response.odBalanced = legacyResponse.od.balanced;
		response.osBalanced = legacyResponse.os.balanced;
		response.odSphere = legacyResponse.od.sphere;
		response.osSphere = legacyResponse.os.sphere;
		response.odCylinder = legacyResponse.od.cylinder;
		response.osCylinder = legacyResponse.os.cylinder;
		response.odAxis = legacyResponse.od.axis;
		response.osAxis = legacyResponse.os.axis;
		response.odNearAdd = legacyResponse.od.nearAdd;
		response.osNearAdd = legacyResponse.os.nearAdd;
		response.odHorizontalPrism = legacyResponse.od.horizontalPrism;
		response.osHorizontalPrism = legacyResponse.os.horizontalPrism;
		response.odHorizontalPrismOrientation =
			EnumUtil.findEnumByValue(legacyResponse.od.horizontalPrismOrientation, HorizontalPrismOrientation);
		response.osHorizontalPrismOrientation =
			EnumUtil.findEnumByValue(legacyResponse.os.horizontalPrismOrientation, HorizontalPrismOrientation);
		response.odPrism = legacyResponse.od.prism;
		response.osPrism = legacyResponse.os.prism;
		response.odPrismOrientation = EnumUtil.findEnumByValue(legacyResponse.od.prismOrientation, VerticalPrismOrientation);
		response.osPrismOrientation = EnumUtil.findEnumByValue(legacyResponse.os.prismOrientation, VerticalPrismOrientation);

		return response;
	}

	handleLegacyContactLensRxResponse(legacyResponse: any) {
		const response = new RxSelectContactLensResponse();
		response.rxId = legacyResponse.id;
		response.startDate = legacyResponse.startDate;
		response.expirationDate = legacyResponse.expirationDate;
		response.rxDisplayStatus = EnumUtil.findEnumByValue(legacyResponse.displayStatus, RxDisplayStatusLegacy);
		response.contactLensType = EnumUtil.findEnumByValue(legacyResponse.overallLensType, ContactLensType);
		if (!_isNil(legacyResponse.od) && !_isNil(legacyResponse.od.product) && !_isNil(legacyResponse.od.product.details)) {
			response.odManufacturerName = legacyResponse.od.product.details.manufacturerName;
			response.odProductName = legacyResponse.od.product.details.name;
		}
		if (!_isNil(legacyResponse.os) && !_isNil(legacyResponse.os.product) && !_isNil(legacyResponse.os.product.details)) {
			response.osManufacturerName = legacyResponse.os.product.details.manufacturerName;
			response.osProductName = legacyResponse.os.product.details.name;
		}

		return response;
	}

	searchEyeglassRxForOrders(personId: number) {
		const serviceName = 'Order';
		const endpointName = 'getEyeglassPrescriptionsForOrders';
		const legacyRequest = this.buildLegacyRequest(serviceName, endpointName, personId);

		return this.gandalfTheGreyService.execute(serviceName, endpointName, legacyRequest).pipe(
			map(responses => responses.map(response => this.handleLegacyEyeglassRxResponse(response))),
		);
	}

	searchEyeglassRx(patientId: number): Observable<RxSelectEyeglassResponse[]> {
		return this.eyeglassPrescriptionGandalfService.findEyeglassPrescriptionHistoryByPatientId(patientId).pipe(
			map(responses => this.filterOutDiscontinuedEyeglassResponses(responses)),
			map(responses => this.mapEyeglassPrescriptionListResponses(responses)),
			map(responses => SortingService.sortBy(responses, ['startDate', 'id'], ['desc', 'desc'])),
		);
	}

	mapEyeglassPrescriptionListResponses(responses) {
		return responses.map(response => this.handleEyeglassPrescriptionListResponse(response));
	}

	filterOutDiscontinuedEyeglassResponses(responses) {
		return responses.filter(item => !EnumUtil.equals(item.status, PrescriptionStatus.STOPPED));
	}

	searchContactLensRxForOrders(personId: number): Observable<RxSelectContactLensResponse[]> {
		const serviceName = 'Order';
		const endpointName = 'getContactLensPrescriptionsForOrders';
		const legacyRequest = this.buildLegacyRequest(serviceName, endpointName, personId);

		return this.gandalfTheGreyService.execute(serviceName, endpointName, legacyRequest).pipe(
			map(responses => responses.map(response => this.handleLegacyContactLensRxResponse(response))),
			map(responses => SortingService.sortBy(responses, ['startDate', 'rxId'], ['desc', 'desc'])),
		);
	}

	searchContactLensTrialRxForOrders(personId: number) {
		const serviceName = 'Order';
		const endpointName = 'getContactLensTrialsForOrders';
		const legacyRequest = this.buildLegacyRequest(serviceName, endpointName, personId);

		return this.gandalfTheGreyService.execute(serviceName, endpointName, legacyRequest).pipe(
			map(responses => responses.map(response => this.handleLegacyContactLensRxResponse(response))),
		);
	}

	handleEyeglassPrescriptionListResponse(eyeglassPrescriptionListResponse: EyeglassPrescriptionListResponse) {
		const response = new RxSelectEyeglassResponse();
		response.eyeglassPrescriptionId = eyeglassPrescriptionListResponse.id;
		response.startDate = eyeglassPrescriptionListResponse.startDate;
		response.expirationDate = eyeglassPrescriptionListResponse.expirationDate;
		response.usedForReasonName = eyeglassPrescriptionListResponse.usedFor ? eyeglassPrescriptionListResponse.usedFor.description : '';
		response.odBalanced = eyeglassPrescriptionListResponse.od.balanced;
		response.osBalanced = eyeglassPrescriptionListResponse.os.balanced;
		response.odSphere = eyeglassPrescriptionListResponse.od.sphere;
		response.osSphere = eyeglassPrescriptionListResponse.os.sphere;
		response.odCylinder = eyeglassPrescriptionListResponse.od.cylinder;
		response.osCylinder = eyeglassPrescriptionListResponse.os.cylinder;
		response.odAxis = eyeglassPrescriptionListResponse.od.axis;
		response.osAxis = eyeglassPrescriptionListResponse.os.axis;
		response.odNearAdd = eyeglassPrescriptionListResponse.od.nearAdd;
		response.osNearAdd = eyeglassPrescriptionListResponse.os.nearAdd;
		response.odHorizontalPrism = eyeglassPrescriptionListResponse.od.horizontalPrism;
		response.osHorizontalPrism = eyeglassPrescriptionListResponse.os.horizontalPrism;
		response.odHorizontalPrismOrientation = eyeglassPrescriptionListResponse.od.horizontalPrismOrientation;
		response.osHorizontalPrismOrientation =	eyeglassPrescriptionListResponse.os.horizontalPrismOrientation;
		response.odPrism = eyeglassPrescriptionListResponse.od.verticalPrism;
		response.osPrism = eyeglassPrescriptionListResponse.os.verticalPrism;
		response.odPrismOrientation = eyeglassPrescriptionListResponse.od.verticalPrismOrientation;
		response.osPrismOrientation = eyeglassPrescriptionListResponse.os.verticalPrismOrientation;
		return response;
	}

	searchContactLensForOrders(patientId: number): Observable<PrescriptionSelectContactLensResponse[]> {
		return this.contactLensPrescriptionGandalfService.findContactLensForOrdersByPatient(patientId)
			.pipe(map(contactLensResponse => SortingService.sortBy(contactLensResponse, ['createdDate', 'prescriptionId'], ['desc', 'asc'])));
	}

	searchContactLensWithTrialsForOrders(patientId: number): Observable<PrescriptionSelectContactLensResponse[]> {
		return this.contactLensPrescriptionGandalfService.findContactLensWithTrialsForOrdersByPatient(patientId)
			.pipe(map(contactLensResponse => SortingService.sortBy(contactLensResponse, ['createdDate', 'prescriptionId', 'trialId'], ['desc', 'asc', 'asc'])));
	}

	findEyeglassPrescriptionsForOrders(patientId: number): Observable<EyeglassOrderSearchResponse[]> {
		return this.sharedPatientOrderGandalfService.findEyeglassPrescriptionsForOrders(patientId);
	}
}
