// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.patient.PatientDuplicateSearchResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientDuplicateSearchResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	dateOfBirth: Date;

	@GandalfProperty()
	last4Ssn: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	matchType: constants.PatientDuplicateMatchType;

	@GandalfProperty()
	patientNameResponse: PatientNameResponse;

	@GandalfProperty()
	ssn: string;

}
