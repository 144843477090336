// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { EmployeeNameResponse } from './employee-name-response';

// @ts-ignore
import { ScheduleItemCalendarResponse } from './schedule-item-calendar-response';

/** See com.rev360.pms.api.controller.schedule.scheduleitem.calendar.AppointmentSlotCalendarResponse */
/* istanbul ignore next */
@GandalfModel
export class AppointmentSlotCalendarResponse extends ScheduleItemCalendarResponse {

	@GandalfProperty()
	displayColor: number;

	@GandalfProperty()
	employee: EmployeeNameResponse;

	@GandalfArray(String)
	encounterTemplateNames: string[];

	@GandalfProperty()
	endDate: Date;

	@GandalfProperty()
	groupingPersonId: number;

	@GandalfProperty()
	locationId: number;

	@GandalfProperty()
	relatedScheduleItemId: number;

	@GandalfProperty()
	roleId: number;

	@GandalfProperty()
	roleName: string;

	@GandalfProperty()
	scheduleItemId: number;

	@GandalfProperty()
	startDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.ScheduleItemStatus;

	@GandalfConstantDecorator()
	@GandalfProperty()
	type: constants.ScheduleItemType;

	@GandalfProperty()
	updatedOn: Date;

}
