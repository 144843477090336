import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { GridUtil, OptionItem, PAGE_LENGTH, PAGE_LENGTH_LIST } from 'morgana';
import { InventoryService } from '@core/inventory/inventory.service';
import { _isNil, _map } from '@core/lodash/lodash';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { PageSettingsModel } from '@syncfusion/ej2-grids';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { InventoryServiceResponse } from '@gandalf/model/inventory-service-response';

export enum ServiceType {
	ALL = 'All',
	CPT = 'CPT',
	OTHER = 'Other',
}

interface FormatterServiceResponse {
	name: string;
	id: number;
	price: string;
	shortDesc: string;
	modifier: string;
	common: boolean;
	original: InventoryServiceResponse;
}

@Component({
	selector: 'pms-service-selection',
	templateUrl: './service-selection.component.html',
})
export class ServiceSelectionComponent implements OnInit, OnDestroy {

	@ViewChild('grid')
	grid: GridComponent;

	@Input()
	set locationId(value: number) {
		this._locationId = value;
		this.getServices();
	}

	get locationId(): number {
		return this._locationId;
	}

	@Input()
	set serviceType(value: ServiceType) {
		this._serviceType = value;
		this.getServices();
	}

	get serviceType(): ServiceType {
		return this._serviceType;
	}

	@Input()
	isForCoding: boolean;

	@Output()
	serviceSelected = new EventEmitter<InventoryServiceResponse>();

	readonly serviceTypeOptions: OptionItem[] = [
		{label: ServiceType.ALL, value: ServiceType.ALL},
		{label: ServiceType.CPT, value: ServiceType.CPT},
		{label: ServiceType.OTHER, value: ServiceType.OTHER},
	];

	services: FormatterServiceResponse[];
	isSearching = false;
	pageSettings: PageSettingsModel = {
		pageSizes: PAGE_LENGTH_LIST,
		pageSize: PAGE_LENGTH.PAGE_10,
		enableQueryString: false,
	};
	commonOptions = [{label: 'Yes', value: true}, {label: 'No', value: false}];
	_locationId: number;
	_serviceType: ServiceType;
	sortComparer = GridUtil.gridNumberStringsComparator;
	needsFocus = true;
	unsubscribe$: Subject<void> = new Subject();
	focusFilter = new Subject<void>();

	constructor(
		public inventoryService: InventoryService,
		private currencyPipe: CurrencyPipe,
	) {
	}

	ngOnInit() {
		if (this.isForCoding) {
			this._serviceType = ServiceType.ALL;
		}
		this.getServices();
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	getServices() {
		if (this.serviceType != null && this.locationId != null) {
			this.needsFocus = true;
			this.isSearching = true;

			let serviceCall;
			if (this.isAllTypes()) {
				serviceCall = this.inventoryService.findAllActiveServices(this.locationId);
			} else if (this.isCptType()) {
				serviceCall = this.inventoryService.findActiveCPTServices(this.locationId);
			} else {
				serviceCall = this.inventoryService.findActiveOtherServices(this.locationId);
			}

			serviceCall.pipe(
				map((items: InventoryServiceResponse[]) => _map(items, (item: InventoryServiceResponse) => this.makeFormattedServiceResponse(item))),
			).subscribe(results => {
				this.handleResults(results);
			});
		}
	}

	makeFormattedServiceResponse(item: InventoryServiceResponse) {
		return {
			...item,
			price: this.currencyPipe.transform(item.price),
			modifier: item.modifierCode ? `${item.modifierCode}:${item.modifierDescription}` : '',
			original: item,
		};
	}

	handleResults(results) {
		this.services = results;
		this.isSearching = false;
		if (this.grid) {
			this.grid.clearFiltering();
			this.grid.refreshColumns();
			this.grid.refreshHeader();

			GridUtil.setFocus('shortDescription_filterBarcell');
			this.needsFocus = false;
		}
	}

	isCptType() {
		return this.serviceType === ServiceType.CPT;
	}

	isAllTypes() {
		return this.serviceType === ServiceType.ALL;
	}

	filterData($event) {
		if (_isNil($event)) {
			this.grid.removeFilteredColsByField('common');
		} else {
			this.grid.filterByColumn('common', 'equal', $event);
		}
	}

	gridDataBound(grid: GridComponent) {
		GridUtil.gridStringSearchDefaultContains(grid);
		if (this.needsFocus) {
			this.focusFilter.next();
		}
	}

	setServiceType(serviceType: ServiceType): void {
		this.serviceType = serviceType;
	}

	get headerText() {
		return `Services - ${this.serviceType}`;
	}

}
