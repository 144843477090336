// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeRequest } from './contact-lens-eye-request';

/** See com.rev360.pms.api.controller.prescription.ContactLensEyeRgpRequest */
/* istanbul ignore next */
@GandalfModel
export class ContactLensEyeRgpRequest extends ContactLensEyeRequest {

	@GandalfProperty()
	addDiameter: number;

	@GandalfProperty()
	addPower: number;

	@GandalfProperty()
	axis: number;

	@GandalfProperty()
	backOpticalDiameter: number;

	@GandalfProperty()
	baseCurve: number;

	@GandalfProperty()
	baseCurve2: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	baseCurveUnit: constants.BaseCurveUnit;

	@GandalfProperty()
	centerThickness: number;

	@GandalfProperty()
	cylinder: number;

	@GandalfProperty()
	diameter: number;

	@GandalfProperty()
	distanceZone: number;

	@GandalfProperty()
	dot: boolean;

	@GandalfProperty()
	edgeLiftAmount: number;

	@GandalfProperty()
	edgeLiftId: number;

	@GandalfProperty()
	fenestration: boolean;

	@GandalfProperty()
	firstCurveRadius: number;

	@GandalfProperty()
	firstCurveWidth: number;

	@GandalfProperty()
	fourthCurveRadius: number;

	@GandalfProperty()
	fourthCurveWidth: number;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	intermediateSegment: number;

	@GandalfProperty()
	isMonovision: boolean;

	@GandalfProperty()
	landingZoneAngle: number;

	@GandalfProperty()
	lensMaterialId: number;

	@GandalfProperty()
	limbalClearanceId: number;

	@GandalfProperty()
	locationProductId: number;

	@GandalfProperty()
	locationProductItemId: number;

	@GandalfValidator({ sizeString: { message: 'Notes cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	notes: string;

	@GandalfProperty()
	opticZone: number;

	@GandalfProperty()
	overnightDurationAmount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	overnightDurationUnit: constants.DurationUnit;

	@GandalfProperty()
	peripheralCurveRadius: number;

	@GandalfProperty()
	peripheralCurveWidth: number;

	@GandalfProperty()
	peripheralEdgeId: number;

	@GandalfProperty()
	plasmaTreatment: boolean;

	@GandalfProperty()
	productColorId: number;

	@GandalfProperty()
	profileId: number;

	@GandalfProperty()
	replacementDurationAmount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	replacementDurationUnit: constants.DurationUnit;

	@GandalfProperty()
	returnZoneDiameter: number;

	@GandalfProperty()
	sagittalDepth: number;

	@GandalfProperty()
	secondCurveRadius: number;

	@GandalfProperty()
	secondCurveWidth: number;

	@GandalfProperty()
	secondaryCurveRadius: number;

	@GandalfProperty()
	secondaryCurveWidth: number;

	@GandalfProperty()
	segmentHeight: number;

	@GandalfProperty()
	specialCharacteristicsId: number;

	@GandalfProperty()
	sphere: number;

	@GandalfProperty()
	sphere2: number;

	@GandalfProperty()
	thirdCurveRadius: number;

	@GandalfProperty()
	thirdCurveWidth: number;

	@GandalfProperty()
	truncation: number;

	@GandalfProperty()
	version: number;

}
