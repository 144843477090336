<ejs-dialog #modal (close)="closeModal()" cssClass="modal-sm" [attr.data-test-id]="'internalSelectProviderModal'">
	<ng-template #header>
		<div class="dlg-template">Select Provider</div>
	</ng-template>
	<ng-template #content>
		<div class="form-horizontal">
			<div class="form-group">
				<label for="provider" class="col-sm-4 control-label required">Provider</label>
				<div class="col-sm-8">
					<ejs-dropdownlist
							id="provider"
							[dataSource]="providerList"
							[(ngModel)]="providerId"
							[showClearButton]="false"
							placeholder="Select Provider"
							[attr.data-test-id]="'internalSelectProviderDropdown'">
					</ejs-dropdownlist>
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'internalSelectProviderCancelButton'">Cancel</button>
		<button ejs-button type="submit" [isPrimary]="true" (click)="closeModal(providerId)" [attr.data-test-id]="'internalSelectProviderSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>
