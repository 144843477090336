<ejs-dialog
	#modal
	(close)="closeModal(false)"
	cssClass="modal-md"
	[attr.data-test-id]="'selectEncounterAppointmentModal'">
	<ng-template #header>
		<div class="dlg-template">Select Encounter/Appointment</div>
	</ng-template>
	<ng-template #content>
		<ejs-tab #tabs overflowMode="Popup" [animation]="tabAnimation">
			<e-tabitems>
				<e-tabitem *ngIf="showEncountersTab" [header]="{text: 'Encounter'}">
					<ng-template #content>
						<pms-encounter-select (closeModal)="closeModal(true)" [invoiceId]="invoiceId" [patientId]="patientId"></pms-encounter-select>
					</ng-template>
				</e-tabitem>
				<e-tabitem *ngIf="showAppointmentsTab" [header]="{text: 'Appointment'}">
					<ng-template #content>
						<pms-appointment-select (closeModal)="closeModal(true)" [invoiceId]="invoiceId" [patientId]="patientId"></pms-appointment-select>
					</ng-template>
				</e-tabitem>
			</e-tabitems>
		</ejs-tab>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" (click)="closeModal(false)" [attr.data-test-id]="'selectEncounterAppointmentCancelButton'">Cancel</button>
	</ng-template>
</ejs-dialog>
