// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.general.UpdatePracticeGeneralInfoRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdatePracticeGeneralInfoRequest extends GandalfModelBase {

	@GandalfLabel('FEIN')
	@GandalfValidator({ sizeString: { message: 'FEIN cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	fein: string;

	@GandalfLabel('Practice Name')
	@GandalfValidator({ notNull: { message: 'Practice Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Practice Name must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ isRequired: true })
	name: string;

	@GandalfLabel('NPI')
	@GandalfValidator({ sizeString: { message: 'NPI must be between 10 and 15 characters', minLength: 10, maxLength: 15 } })
	@GandalfProperty()
	npi: string;

	@GandalfLabel('WebSite')
	@GandalfValidator({ sizeString: { message: 'WebSite cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	webSite: string;

}
