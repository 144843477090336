import { SafeResourceUrl } from '@angular/platform-browser';
import { Type } from '@angular/core';
import { Observable } from 'rxjs';
import { _isNil } from '@core/lodash/lodash';

export class Iframe {
	// current URL set on the iframe (if it is enabled)
	url: SafeResourceUrl;
	// pending URL that will be set on the iframe once it is visible
	pendingUrl: SafeResourceUrl;
	// is the iframe (may not be visible yet) and its loading overlay added the HTML
	isEnabled = false;
	// is the iframe or loading overlay visible (if it is enabled and has a URL)
	isActive = true;
	// is the iframe or observable URL loading
	isLoaded = false;
	// unique owner component
	component: Type<any>;
	// called when the open in tab button is clicked. The button will be hidden if this is not set
	openInTab: () => any;
	// tooltip for the open in tab button
	openInTabTooltip: string;

	/**
	 * Show the iframe when the observable URL is received and the iframe is done loading, until then the loading overlay will be displayed. No loading will be
	 * done until the iframe is active. The URL must be trusted to be safe for the user.
	 */
	setObservableUrl(url: Observable<SafeResourceUrl>) {
		this.isEnabled = true;
		this.isLoaded = false;
		url.subscribe(url => this.setUrl(url));
	}

	/**
	 * Show the iframe when the URL is done loading, until then the loading overlay will be displayed. No loading will be done until the iframe is active. The
	 * URL must be trusted to be safe for the user.
	 */
	setUrl(url: SafeResourceUrl) {
		if (this.isActive) {
			this.url = url;
			this.pendingUrl = null;
		} else {
			// wait until the iframe is active (visible) to set the actual URL to avoid unnecessary loading
			this.pendingUrl = url;
		}
		this.isEnabled = true;
		this.isLoaded = false;
	}

	/**
	 * Reloads the iframe with the last set URL. No loading will be done until the iframe is active.
	 */
	reload() {
		this.unload();
		setTimeout(() => this.setUrl(this.url));
	}

	/**
	 * Removes the iframe and loading overlay from the HTML. The state of the iframe will be lost.
	 */
	unload() {
		this.isEnabled = false;
		this.isLoaded = false;
	}

	/**
	 * Called when the iframe is done loading the URL
	 */
	loaded(iframe: HTMLIFrameElement) {
		if (iframe.src) {
			// angular bug: the load event is triggered when the iframe is initialized, before the src is set
			this.isLoaded = true;
		}
	}

	/**
	 * Activate (show) the iframe. The loading overlay will be displayed if the iframe is not loaded yet.
	 */
	activate() {
		this.isActive = true;
		if (!_isNil(this.pendingUrl)) {
			this.url = this.pendingUrl;
			this.pendingUrl = null;
			this.isLoaded = false;
		}
	}

	/**
	 * Deactivate (hide) the iframe. The current state of the iframe will be retained.
	 */
	deactivate() {
		this.isActive = false;
	}
}
