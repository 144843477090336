<ejs-dialog
		#modal
		(close)="closeModal()"
		[attr.data-test-id]="'carePlanTemplateModal'">
	<ng-template #header>
		<div class="dlg-template">Care Plan Template</div>
	</ng-template>
	<ng-template #content>
		<h4 class="section-title bg-default mrgn-btm">{{loading ? 'Loading Care Plan Template' : carePlanTemplate?.name}}</h4>
		<ejs-grid
				autoFitColumns
				[autoFitBlacklist]="['description']"
				gridLines="Horizontal"
				[dataSource]="carePlanTemplate?.items"
				[allowSorting]="true"
				[allowSelection]="false"
				[allowResizing]="true"
				[pmsGridSubmit]="loading"
				[pmsGridSubmitDefaultSortOptions]="sortSettings"
				[sortSettings]="sortSettings"
				[attr.data-test-id]="'carePlanTemplateModalTable'"
		>
			<e-columns>
				<e-column field="category.label" headerText="Type"></e-column>
				<e-column field="description" headerText="Description" clipMode="EllipsisWithTooltip"></e-column>
			</e-columns>
		</ejs-grid>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button type="button" (click)="closeModal()" iconCss="fa fa-close" [attr.data-test-id]="'carePlanTemplateModalCancelButton'">Cancel</button>
	</ng-template>
</ejs-dialog>
