<form class="form-horizontal" *ngIf="invoice && !isLoadingPaymentGroupId">
	<div [ngClass]="headingContainerStyling()">
		<pms-invoice-details-header
			*ngIf="invoice"
			[invoice]="invoice"
			[isReadOnly]="isReadOnly"
			[isPaymentProcessing]="isInvoicePaymentProcessing"
			(returnEvent)="submitReturnEvent()">
		</pms-invoice-details-header>
		<pms-invoice-details-button-bar
			*ngIf="invoice"
			[invoice]="invoice"
			[isReadOnly]="isReadOnly"
			[isPaymentProcessing]="isInvoicePaymentProcessing">
		</pms-invoice-details-button-bar>
	</div>
	<div [ngClass]="{'panel panel-default': isTabView}">
		<div [class.panel-body]="isTabView">
			<div class="row">
				<div class="col-sm-4 col-lg-3">
					<div class="panel panel-default panel-sm">
						<div class="panel-heading">
							<h4 class="panel-title">Bill To</h4>
						</div>
						<div class="panel-body padding-top-xs">
							<p class="margin-0" [attr.data-test-id]="'payerDetailAddressLabel'">
								<strong>{{getPayerName()}}</strong><br>
								<pms-address-display [address]="invoice.payerAddress"></pms-address-display>
							</p>
							<p class="margin-0" *ngIf="invoice.payerType.value === payerType.ANONYMOUS.value && invoice.payerPhone" [attr.data-test-id]="'payerDetailPhoneLabel'">
								{{invoice?.payerPhone | phone}}
							</p>
							<p class="margin-0" *ngIf="invoice.payerType.value === payerType.ANONYMOUS.value && invoice.payerEmail" [attr.data-test-id]="'payerDetailEmailLabel'">
								{{invoice?.payerEmail}}
							</p>
						</div>
					</div>
				</div>
				<pms-invoice-details-basic-info [invoiceId]="invoiceId" [isReadOnly]="isReadOnly" [isPaymentProcessing]="isInvoicePaymentProcessing" (returnEvent)="submitReturnEvent()"></pms-invoice-details-basic-info>
			</div>
			<ejs-tab #tabs overflowMode="Popup" (created)="setTabSelection(true)" [animation]="tabAnimation">
				<e-tabitems>
					<e-tabitem>
						<ng-template #headerText>
							<span [attr.data-test-id]="'invoiceDetailsDetailTab'">Details</span>
						</ng-template>
						<ng-template #content>
							<pms-invoice-details-items-table [invoiceId]="invoiceId" [isReadOnly]="isReadOnly" [isPaymentProcessing]="isInvoicePaymentProcessing" [isTabView]="isTabView"></pms-invoice-details-items-table>
						</ng-template>
					</e-tabitem>
					<e-tabitem *ngIf="invoice.payerType === payerType.INSURANCE">
						<ng-template #headerText>
							<span [attr.data-test-id]="'invoiceDetailsAdditionalClaimInfoTab'">Additional Claim Info</span>
						</ng-template>
						<ng-template #content>
							<pms-additional-claim-information-canada *ngIf="isCanada" [invoiceId]="invoiceId"></pms-additional-claim-information-canada>
							<pms-additional-claim-information *ngIf="!isCanada" [isPaymentProcessing]="isInvoicePaymentProcessing" [invoiceId]="invoiceId"></pms-additional-claim-information>
						</ng-template>
					</e-tabitem>
					<e-tabitem *ngIf="invoice.payerType === payerType.INSURANCE">
						<ng-template #headerText>
							<span [attr.data-test-id]="'invoiceDetailsClaimHistoryTab'">Claim History</span>
						</ng-template>
						<ng-template #content>
							<pms-claims-history [updateOn]="refreshedInvoice" [invoice]="invoice" [isPaymentProcessing]="isInvoicePaymentProcessing"></pms-claims-history>
						</ng-template>
					</e-tabitem>
					<e-tabitem>
						<ng-template #headerText>
							<span [attr.data-test-id]="'invoiceDetailsPaymentHistoryTab'">Payment History</span>
						</ng-template>
						<ng-template #content>
							<pms-invoice-payment-history
								[invoiceId]="invoice.id"
								[amountPaid]="invoice.amountPaid"
								[isReadOnly]="isReadOnly"
								[isPaymentProcessing]="isInvoicePaymentProcessing">
							</pms-invoice-payment-history>
						</ng-template>
					</e-tabitem>
					<e-tabitem>
						<ng-template #headerText>
							<span [attr.data-test-id]="'invoiceDetailsStatementHistoryTab'">Statement History</span>
						</ng-template>
						<ng-template #content>
							<pms-invoice-statement-history [invoiceId]="invoice.id"></pms-invoice-statement-history>
						</ng-template>
					</e-tabitem>
					<e-tabitem *ngIf="invoice.patientId">
						<ng-template #headerText>
							<span [attr.data-test-id]="'invoiceDetailsDocsAndImagesTab'">
								Documents & Images
								<span class="badge margin-left-xs">{{ documentsCountTabHeaderText }}</span>
							</span>
						</ng-template>
						<ng-template #content>
							<pms-patient-documents
								[disabled]="isInvoicePaymentProcessing"
								[patientId]="invoice.patientId"
								[parentId]="invoice.id"
								[entityType]="invoicePatientFileEntityType">
							</pms-patient-documents>
						</ng-template>
					</e-tabitem>
					<e-tabitem>
						<ng-template #headerText>
							<span [attr.data-test-id]="'invoiceDetailsNotesTab'">Notes</span>
						</ng-template>
						<ng-template #content>
							<pms-notes
								[entityType]="invoiceEntityType"
								[parentId]="invoiceId"
								[updateOn]="refreshedInvoice"
								[isPaymentProcessing]="isInvoicePaymentProcessing">
							</pms-notes>
						</ng-template>
					</e-tabitem>
				</e-tabitems>
			</ejs-tab>
		</div>
	</div>
</form>
