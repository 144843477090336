import { Injectable } from '@angular/core';
import { AddressUtilService } from '@core/address/address-util.service';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { AdminCreatePracticeLocationRequest } from '@gandalf/model/admin-create-practice-location-request';
import { AdminPracticeLocationAddressResponse } from '@gandalf/model/admin-practice-location-address-response';
import { AdminPracticeLocationResponse } from '@gandalf/model/admin-practice-location-response';
import { AdminUpdatePracticeLocationRequest } from '@gandalf/model/admin-update-practice-location-request';
import { FinanceChargePlanNameResponse } from '@gandalf/model/finance-charge-plan-name-response';
import { UpdatePracticeLocationTaxesRequest } from '@gandalf/model/update-practice-location-taxes-request';
import { AdminLocationGandalfService } from '@gandalf/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import {StringAdditionalPreferenceResponse} from '@gandalf/model/string-additional-preference-response';

@Injectable({
	providedIn: 'root',
})
export class AdminLocationService {

	constructor(
		public adminLocationGandalfService: AdminLocationGandalfService,
	) {
	}

	/* istanbul ignore next: gandalf */
	findPracticeLocationsForList(): Observable<AdminPracticeLocationAddressResponse[]> {
		return this.adminLocationGandalfService.findPracticeLocationsForList();
	}

	@ShowSavedSuccessToast()
	/* istanbul ignore next: gandalf */
	updateLocationTaxes(request: UpdatePracticeLocationTaxesRequest) {
		return this.adminLocationGandalfService.updatePracticeLocationTaxes(request);
	}

	findPracticeLocationAndSubscribers(id: number): Observable<AdminPracticeLocationResponse> {
		return this.adminLocationGandalfService.findPracticeLocationAndSubscribers(id).pipe(map(data =>
			this.modifyPracticeLocationResponseAddresses(data),
		));
	}

	/* istanbul ignore next: gandalf */
	findDefaultPracticeLocationPreferences(): Observable<StringAdditionalPreferenceResponse[]> {
		return this.adminLocationGandalfService.findDefaultPracticeLocationPreferences();
	}

	/* istanbul ignore next: gandalf */
	findActiveFinanceChargePlansForDropdown(): Observable<FinanceChargePlanNameResponse[]> {
		return this.adminLocationGandalfService.findActiveFinanceChargePlansForDropdown();
	}

	/* istanbul ignore next: gandalf */
	findTaxesForPracticeLocation(id: number) {
		return this.adminLocationGandalfService.findTaxesByPracticeLocationId(id);
	}

	@ShowSavedSuccessToast()
	createPracticeLocation(request: AdminCreatePracticeLocationRequest): Observable<AdminPracticeLocationResponse> {
		return this.adminLocationGandalfService.createPracticeLocation(request).pipe(map(data =>
			this.modifyPracticeLocationResponseAddresses(data),
		));
	}

	@ShowSavedSuccessToast()
	updatePracticeLocation(request: AdminUpdatePracticeLocationRequest): Observable<AdminPracticeLocationResponse> {
		return this.adminLocationGandalfService.updatePracticeLocation(request).pipe(map(data =>
			this.modifyPracticeLocationResponseAddresses(data),
		));
	}

	/* istanbul ignore next: gandalf */
	generateUniqueAccessCode() {
		return uuidv4();
	}

	private modifyPracticeLocationResponseAddresses(data) {
		AddressUtilService.addressToAddressLine(data.details.address, data.details.payToAddress);
		return data;
	}
}
