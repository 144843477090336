import { Injectable } from '@angular/core';
import { GandalfTheGreyService } from '@core/gandalf-the-grey/gandalf-the-grey.service';
import { ConnectMessageCategory, ConnectMessageMethod } from '@gandalf/constants';
import { CanSendPatientMessageRequest } from '@gandalf/model/can-send-patient-message-request';

@Injectable({
	providedIn: 'root',
})
export class PatientCommunicationService {

	constructor(
		private gandalfTheGreyService: GandalfTheGreyService,
	) {
	}

	/* istanbul ignore next */
	buildLegacyRequest(serviceName: string, endpointName: string, canSendPatientMessageRequest: CanSendPatientMessageRequest): any {
		const legacyRequest = this.gandalfTheGreyService.createRequestInstance(serviceName, endpointName);
		legacyRequest.patientId = canSendPatientMessageRequest.patientId;
		legacyRequest.communicationCategory = canSendPatientMessageRequest.communicationCategory;
		legacyRequest.communicationTarget = canSendPatientMessageRequest.communicationTarget;

		return legacyRequest;
	}

	canSendPatientMessage(
		patientId: number,
		communicationTarget: ConnectMessageMethod,
		communicationCategory: ConnectMessageCategory,
	) {
		const canSendPatientMessageRequest = new CanSendPatientMessageRequest();
		canSendPatientMessageRequest.patientId = patientId;
		canSendPatientMessageRequest.communicationCategory = communicationCategory.value;
		canSendPatientMessageRequest.communicationTarget = communicationTarget.value;

		const serviceName = 'Patient';
		const endpointName = 'canSendPatientMessage';
		const legacyRequest = this.buildLegacyRequest(serviceName, endpointName, canSendPatientMessageRequest);

		return this.gandalfTheGreyService.execute(serviceName, endpointName, legacyRequest);
	}
}
