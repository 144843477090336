// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { OpticalSummaryLatestFindingsContactLensResponse } from './optical-summary-latest-findings-contact-lens-response';

// @ts-ignore
import { OpticalSummaryLatestFindingsContactLensTrialResponse } from './optical-summary-latest-findings-contact-lens-trial-response';

// @ts-ignore
import { OpticalSummaryLatestFindingsEyeglassResponse } from './optical-summary-latest-findings-eyeglass-response';

// @ts-ignore
import { OpticalSummaryLatestFindingsRefractionResponse } from './optical-summary-latest-findings-refraction-response';

/** See com.rev360.pms.api.controller.patient.opticalsummary.OpticalSummaryLatestFindingsResponse */
/* istanbul ignore next */
@GandalfModel
export class OpticalSummaryLatestFindingsResponse extends GandalfModelBase {

	@GandalfArray(OpticalSummaryLatestFindingsContactLensResponse)
	contactLensFindings: OpticalSummaryLatestFindingsContactLensResponse[];

	@GandalfArray(OpticalSummaryLatestFindingsEyeglassResponse)
	eyeglassFindings: OpticalSummaryLatestFindingsEyeglassResponse[];

	@GandalfArray(OpticalSummaryLatestFindingsRefractionResponse)
	refractionFindings: OpticalSummaryLatestFindingsRefractionResponse[];

	@GandalfArray(OpticalSummaryLatestFindingsContactLensTrialResponse)
	trialFindings: OpticalSummaryLatestFindingsContactLensTrialResponse[];

}
