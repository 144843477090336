import { Injectable } from '@angular/core';
import { EnumUtil, SortingService } from 'morgana';
import { GandalfTheGreyService } from '@core/gandalf-the-grey/gandalf-the-grey.service';
import { _isNil } from '@core/lodash/lodash';
import {
	ContactLensPrescriptionType,
	ContactLensType,
	ExternalProductSource,
	MasterProductCategory,
	PrescriptionAuthorizationType,
	PrescriptionStatus,
	RxDisplayStatusLegacy
} from '@gandalf/constants';
import { CancelPrescriptionRequest } from '@gandalf/model/cancel-prescription-request';
import { ContactLensPrescriptionLookupResponse } from '@gandalf/model/contact-lens-prescription-lookup-response';
import { ContactLensPrescriptionResponse } from '@gandalf/model/contact-lens-prescription-response';
import { ContactLensTrialResponse } from '@gandalf/model/contact-lens-trial-response';
import { ProductForPrescriptionResponse } from '@gandalf/model/product-for-prescription-response';
import { UpdateContactLensPrescriptionDeliveryMethodRequest } from '@gandalf/model/update-contact-lens-prescription-delivery-method-request';
import { ContactLensPrescriptionGandalfService } from '@gandalf/services';
import { FormattedNamePipe } from '@shared/pipes/formatted-name/formatted-name.pipe';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContactLensEyeResponse } from '@gandalf/model/contact-lens-eye-response';

interface EyedockSearch {
	type: string;
	tid?: string;
	q?: string;
}

@Injectable({
	providedIn: 'root',
})
export class ContactLensPrescriptionDetailsService {

	private _prescriptionsUpdated = new Subject<any>();

	constructor(
		private contactLensPrescriptionGandalfService: ContactLensPrescriptionGandalfService,
		public formattedNamePipe: FormattedNamePipe,
		private gandalfTheGreyService: GandalfTheGreyService,
	) { }

	/* istanbul ignore next: gandalf */
	getPrescriptionById(prescriptionId) {
		return this.contactLensPrescriptionGandalfService.getContactLensPrescriptionById(prescriptionId);
	}

	/* istanbul ignore next: gandalf */
	updateContactLensPrescriptionDeliveryMethod(request: UpdateContactLensPrescriptionDeliveryMethodRequest) {
		return this.contactLensPrescriptionGandalfService.updateContactLensPrescriptionDeliveryMethod(request);
	}

	/* istanbul ignore next: gandalf */
	getTrialById(trialId) {
		return this.contactLensPrescriptionGandalfService.getContactLensTrialById(trialId);
	}

	isOverallLensTypeSoft(contactLensPrescription: ContactLensPrescriptionResponse | ContactLensTrialResponse) {
		return EnumUtil.equals(contactLensPrescription.overallLensType, ContactLensPrescriptionType.SOFT);
	}

	isOverallLensTypeRgp(contactLensPrescription: ContactLensPrescriptionResponse | ContactLensTrialResponse) {
		return EnumUtil.equals(contactLensPrescription.overallLensType, ContactLensPrescriptionType.RGP);
	}

	isOverallLensTypeHybrid(contactLensPrescription: ContactLensPrescriptionResponse | ContactLensTrialResponse) {
		return EnumUtil.equals(contactLensPrescription.overallLensType, ContactLensPrescriptionType.HYBRID);
	}

	isPrescriptionPending(contactLensPrescription: ContactLensPrescriptionResponse) {
		return EnumUtil.equals(contactLensPrescription.status, PrescriptionStatus.ACTIVE) && _isNil(contactLensPrescription.authorizationDate);
	}

	isPrescriptionInternal(contactLensPrescription: ContactLensPrescriptionResponse) {
		return EnumUtil.equals(contactLensPrescription.authorizationType, PrescriptionAuthorizationType.PROVIDER);
	}

	isPrescriptionCanceled(contactLensPrescription: ContactLensPrescriptionResponse) {
		return EnumUtil.equals(contactLensPrescription.status, PrescriptionStatus.STOPPED);
	}

	getRxType(contactLensPrescription: ContactLensPrescriptionResponse) {
		return this.isPrescriptionInternal(contactLensPrescription) ? 'Internal' : 'External';
	}

	getAuthByName(contactLensPrescription: ContactLensPrescriptionResponse) {
		if ((this.isPrescriptionInternal(contactLensPrescription) && this.isPrescriptionPending(contactLensPrescription))
				|| (this.isPrescriptionInternal(contactLensPrescription) && this.isPrescriptionCanceled(contactLensPrescription))) {
			return 'Not Authorized';
		}
		return !_isNil(contactLensPrescription.authorizedBy)
			? this.formattedNamePipe.transform(contactLensPrescription.authorizedBy.firstName, contactLensPrescription.authorizedBy.lastName) : '';
	}

	buildLegacyRequest(serviceName: string, endpointName: string, personId: number): any {
		const legacyRequest = this.gandalfTheGreyService.createRequestInstance(serviceName, endpointName);
		legacyRequest.id = personId;
		return legacyRequest;
	}

	handleLegacyContactLensRxResponse(legacyResponse: any) {
		const response = new ContactLensPrescriptionLookupResponse();
		response.isTrial = legacyResponse.isTrial;
		response.rxId = legacyResponse.id;
		response.startDate = legacyResponse.startDate;
		response.rxDisplayStatus = EnumUtil.findEnumByValue(legacyResponse.displayStatus, RxDisplayStatusLegacy);
		if (!_isNil(legacyResponse.authorizedBy)) {
			response.authorizedBy = legacyResponse.authorizedBy.formattedName;
		}
		if (!_isNil(legacyResponse.od)) {
			response.isMonovisionOd = legacyResponse.od.isMonovision;
			response.baseCurveOd = legacyResponse.od.baseCurve;
			response.sphereOd = legacyResponse.od.sphere;
			response.cylinderOd = legacyResponse.od.cylinder;
			response.axisOd = legacyResponse.od.axis;
			response.addPowerOd = legacyResponse.od.addPower;
			response.addDesignationOd = legacyResponse.od.addDesignationName;
			response.diameterOd = legacyResponse.od.diameter;
		}
		if (!_isNil(legacyResponse.os)) {
			response.isMonovisionOs = legacyResponse.os.isMonovision;
			response.baseCurveOs = legacyResponse.os.baseCurve;
			response.sphereOs = legacyResponse.os.sphere;
			response.cylinderOs = legacyResponse.os.cylinder;
			response.axisOs = legacyResponse.os.axis;
			response.addPowerOs = legacyResponse.os.addPower;
			response.addDesignationOs = legacyResponse.os.addDesignationName;
			response.diameterOs = legacyResponse.os.diameter;
		}
		if (!_isNil(legacyResponse.od) && !_isNil(legacyResponse.od.product) && !_isNil(legacyResponse.od.product.details)) {
			response.modelOd = legacyResponse.od.product.details.name;
		}
		if (!_isNil(legacyResponse.os) && !_isNil(legacyResponse.os.product) && !_isNil(legacyResponse.os.product.details)) {
			response.modelOs = legacyResponse.os.product.details.name;
		}
		response.overallLensType = EnumUtil.findEnumByValue(legacyResponse.overallLensType, ContactLensType);
		response.comments = legacyResponse.description;

		return response;
	}

	searchContactLensRx(personId: number) {
		const serviceName = 'Order';
		const endpointName = 'getContactLensPrescriptionsForOrders';
		const legacyRequest = this.buildLegacyRequest(serviceName, endpointName, personId);

		return this.gandalfTheGreyService.execute(serviceName, endpointName, legacyRequest).pipe(
			map(responses => responses.filter(response => this.filterActiveContactLensRx(response))),
			map(responses => responses.map(response => this.handleLegacyContactLensRxResponse(response))),
			map(responses => SortingService.sortBy(responses, ['startDate', 'rxId'], ['desc', 'desc'])),
		);
	}

	searchContactLensTrials(personId: number) {
		const serviceName = 'Order';
		const endpointName = 'getContactLensTrialsForOrders';
		const legacyRequest = this.buildLegacyRequest(serviceName, endpointName, personId);

		return this.gandalfTheGreyService.execute(serviceName, endpointName, legacyRequest).pipe(
			map(responses => responses.map(response => this.handleLegacyContactLensRxResponse(response))),
			map(responses => SortingService.sortBy(responses, ['startDate', 'rxId'], ['desc', 'desc'])),
		);
	}

	filterActiveContactLensRx(response) {
		if (response.authorizationType === PrescriptionAuthorizationType.EXTERNAL_PROVIDER.value
				|| (response.authorizationType === PrescriptionAuthorizationType.PROVIDER.value && !_isNil(response.authorizationDate))) {
			return true;
		}

		return false;
	}

	createEyedockUrl(product: ProductForPrescriptionResponse, lensType: ContactLensPrescriptionType) {
		if (_isNil(product) || _isNil(lensType)) {
			return '';
		}

		const baseHref = 'http://www.eyedock.com/search-all-lenses?';
		const eyedockSearch: EyedockSearch = {
			type: '',
		};

		// 'soft' is the default lens type value for everything other than rgp
		eyedockSearch.type = EnumUtil.equals(lensType, ContactLensPrescriptionType.RGP) ? 'rgp' : 'soft';

		if (
			!_isNil(product.externalSource)
			&& product.externalSource === ExternalProductSource.EXTERNAL_SOURCE_EYEDOCK.value
			&& !_isNil(product.externalId) && product.externalId !== ''
		) {
			eyedockSearch.tid = product.externalId;
		} else {
			eyedockSearch.q = product.model;
		}

		return baseHref + Object.keys(eyedockSearch).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(eyedockSearch[key])}`).join('&');
	}

	canShowEyedockButton(product: ProductForPrescriptionResponse) {
		return !_isNil(product) ? product.productCategoryId === MasterProductCategory.CONTACT_LENSES.value : false;
	}

	/* istanbul ignore next: gandalf */
	findContactLensPrescriptionsByPatient(patientId) {
		return this.contactLensPrescriptionGandalfService.findByPatient(patientId);
	}

	/* istanbul ignore next: gandalf */
	cancelPrescription(request: CancelPrescriptionRequest) {
		return this.contactLensPrescriptionGandalfService.cancelContactLensPrescription(request);
	}

	/* istanbul ignore next: gandalf */
	enablePHR(id: number) {
		return this.contactLensPrescriptionGandalfService.enablePhr(id);
	}

	/* istanbul ignore next: gandalf */
	disablePhr(id: number) {
		return this.contactLensPrescriptionGandalfService.disablePhr(id);
	}

	get prescriptionsUpdated() {
		return this._prescriptionsUpdated.asObservable();
	}

	markPrescriptionsUpdated() {
		this._prescriptionsUpdated.next(true);
	}

	getContactLensPrescriptionEye(contactLensPrescription: ContactLensPrescriptionResponse | ContactLensTrialResponse, isOd: boolean): ContactLensEyeResponse{
		if (EnumUtil.equals(contactLensPrescription.overallLensType, ContactLensPrescriptionType.SOFT)) {
			return isOd ? contactLensPrescription.contactLensEyeSoftOd : contactLensPrescription.contactLensEyeSoftOs;
		} else if (EnumUtil.equals(contactLensPrescription.overallLensType, ContactLensPrescriptionType.RGP)) {
			return isOd ? contactLensPrescription.contactLensEyeRgpOd : contactLensPrescription.contactLensEyeRgpOs;
		} else if (EnumUtil.equals(contactLensPrescription.overallLensType, ContactLensPrescriptionType.HYBRID)) {
			return isOd ? contactLensPrescription.contactLensEyeHybridOd : contactLensPrescription.contactLensEyeHybridOs;
		}
	}
}
