import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { MarkClaimAsSubmittedRequest } from '@gandalf/model/mark-claim-as-submitted-request';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';
import { ClaimService } from '../../../../core/claim/claim.service';

@Component({
	selector: 'pms-enter-claim-submission-date-modal',
	templateUrl: './enter-claim-submission-date-modal.component.html',
	styles: [],
})
export class EnterClaimSubmissionDateModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('templateForm')
	templateForm: NgForm;

	claimIds: number[];
	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	formGroup: UntypedFormGroup;
	request: MarkClaimAsSubmittedRequest;


	constructor(
		public dynamicModalRef: DynamicModalRef,
		public ref: DynamicModalRef,
		public modalConfig: ModalConfig,
		private claimService: ClaimService,
		public formBuilder: GandalfFormBuilder,
	) {
	}

	ngOnInit() {
		this.claimIds = this.modalConfig.data.claimIds;
		this.buildRequest();
		this.buildForm();
	}

	buildRequest() {
		this.request = new MarkClaimAsSubmittedRequest();
		this.request.claimIds = this.claimIds;
		this.request.submissionDate = new Date();
	}

	buildForm() {
		this.formGroup = this.formBuilder.group(this.request);
	}

	saveMarkAsSubmitted() {
		if (this.formGroup.invalid) {
			return;
		}

		const transferRequest = new MarkClaimAsSubmittedRequest();
		transferRequest.submissionDate = this.formGroup.get('submissionDate').value;
		transferRequest.claimIds = this.claimIds;
		this.claimService.markClaimsAsSubmitted(transferRequest).subscribe(() => {
			this.closeModal();
		});
	}

	/* istanbul ignore next */
	submitForm(event) {
		this.templateForm.onSubmit(event);
	}

	closeModal() {
		this.dynamicModalRef.close(this.modal);
	}

}
