import { Injectable } from '@angular/core';
import { OptionItem, SortingService } from 'morgana';
import { ProviderStatus } from '@gandalf/constants';
import { ExternalProviderDropdownResponse } from '@gandalf/model/external-provider-dropdown-response';
import { ProviderResponse } from '@gandalf/model/provider-response';
import { ProviderGandalfService } from '@gandalf/services';
import { ProviderNamePipe } from '@shared/pipes/provider-name/provider-name.pipe';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OptionItemResponse, OptionItemService } from '@core/option-item/option-item.service';
import { ProviderWithLocationsResponse } from '@gandalf/model/provider-with-locations-response';


export interface ProviderDropdownResponse extends ProviderResponse, OptionItem {
	label: string;
	value: any;
}

@Injectable({
	providedIn: 'root',
})
export class ProviderService {

	constructor(
		private providerGandalfService: ProviderGandalfService,
		private providerNamePipe: ProviderNamePipe,
	) {
	}

	/**
	 * Sorts providers by status, lastName, firstName, id (Tiebreaker)
	 */
	static sortProvidersForDropdown(providerA: ProviderResponse, providerB: ProviderResponse) {
		// Status sort
		if (providerA.status === ProviderStatus.ACTIVE && providerB.status === ProviderStatus.INACTIVE) {
			return -1;
		}
		if (providerA.status === ProviderStatus.INACTIVE && providerB.status === ProviderStatus.ACTIVE) {
			return 1;
		}

		// lastName sort
		const lastNameSort = providerA.lastName.localeCompare(providerB.lastName);
		if (lastNameSort !== 0) {
			return lastNameSort;
		}

		// firstName sort
		const firstNameSort = providerA.firstName.localeCompare(providerB.firstName);
		if (firstNameSort !== 0) {
			return firstNameSort;
		}

		// Id sort
		return providerA.providerId - providerB.providerId;
	}

	findPracticeProvidersForDropdown(): Observable<OptionItemResponse<ProviderWithLocationsResponse, number>[]> {
		return this.providerGandalfService.findPracticeProviders().pipe(
			map(providers => OptionItemService.toOptionItems(providers, item => item.providerId, item => this.providerNamePipe.transform(item, true))),
			map(providers => providers.sort(ProviderService.sortProvidersForDropdown)),
		);
	}

	findActivePracticeVisionProvidersForDropdown(): Observable<ProviderDropdownResponse[]> {
		return this.providerGandalfService.findPracticeVisionProviders().pipe(
			map(providers => providers.filter(provider => provider.status === ProviderStatus.ACTIVE)),
			map(providers => providers.map(provider => this.formatProvidersForDropdown(provider))),
			map(providers => providers.sort(ProviderService.sortProvidersForDropdown)),
		);
	}

	findActivePracticeProvidersForDropdown(): Observable<OptionItemResponse<ProviderWithLocationsResponse, number>[]> {
		return this.providerGandalfService.findPracticeProviders().pipe(
			map(providers => providers.filter(provider => provider.status === ProviderStatus.ACTIVE)),
			map(providers => OptionItemService.toOptionItems(providers, item => item.providerId, item => this.providerNamePipe.transform(item, true))),
			map(providers => SortingService.sortBy(providers, ['lastName', 'firstName', 'id'])),
		);
	}

	findExternalProviders(): Observable<ExternalProviderDropdownResponse[]> {
		return this.providerGandalfService.findExternalProvidersForDropdown();
	}

	findActiveExternalProvidersForDropdown(): Observable<ProviderDropdownResponse[]> {
		return this.providerGandalfService.findExternalProvidersForDropdown().pipe(
			map(providers => providers.filter(provider => provider.status === ProviderStatus.ACTIVE)),
			map(providers => providers.map(provider => this.formatProvidersForDropdown(provider))),
			map(providers => SortingService.sortBy(providers, ['lastName', 'firstName', 'id'])),
		);
	}

	findAllActiveProvidersForDropdown(): Observable<ProviderDropdownResponse[]> {
		return this.providerGandalfService.findAllActiveProviders().pipe(
			map(providers => providers.map(provider => this.formatProvidersForDropdown(provider))),
			map(providers => SortingService.sortBy(providers, ['lastName', 'firstName', 'id'])),
		);
	}

	formatProvidersForDropdown(provider: ProviderResponse): ProviderDropdownResponse {
		const formattedProvider = provider as ProviderDropdownResponse;
		formattedProvider.value = provider.providerId;
		formattedProvider.label = this.providerNamePipe.transform(provider, true);

		return formattedProvider;
	}

}
