// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.schedule.AppointmentReportRequest */
/* istanbul ignore next */
@GandalfModel
export class AppointmentReportRequest extends GandalfModelBase {

	@GandalfConstantDecorator()
	@GandalfProperty()
	appointmentConfirmationStatus: constants.AppointmentConfirmationStatusSearch;

	@GandalfConstantDecorator()
	@GandalfProperty()
	appointmentStatus: constants.ScheduleItemStatus;

	@GandalfProperty({ propertyType: 'LocalDate' })
	confirmationEndDate: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	confirmationStartDate: Date;

	@GandalfProperty()
	createdByUserId: number;

	@GandalfProperty()
	employeeId: number;

	@GandalfProperty()
	encounterTypeId: number;

	@GandalfValidator({ notNull: { message: 'Appointment End Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	endDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	establishedStatus: constants.EstablishedStatus;

	@GandalfConstantDecorator()
	@GandalfProperty()
	insuranceVerifiedStatus: constants.InsuranceVerifiedStatusSearch;

	@GandalfProperty()
	locationId: number;

	@GandalfValidator({ sizeString: { fieldLabel: 'Patient', maxLength: 1000, minLength: 0, message: 'Patient must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	patientName: string;

	@GandalfProperty()
	providerId: number;

	@GandalfProperty()
	roleId: number;

	@GandalfValidator({ notNull: { message: 'Appointment Start Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	startDate: Date;

	@GandalfProperty()
	subTypeId: number;

}
