import { AbstractControl } from '@angular/forms';
import dayjs from 'dayjs';

/**
 * Test that start date range is before or same as end date range
 * @return null if tests pass {errorProperty: true} if test fails
 */
export function dateRangeValidator(
	startDateControlName: string,
	endDateControlName: string,
	errorProperty: string,
	message: string,
	timeOnly = false,
) {
	return (formGroup: AbstractControl) => {
		const startDateControl = formGroup.get(startDateControlName);
		const endDateControl = formGroup.get(endDateControlName);
		const startDate = startDateControl.value;
		let endDate = endDateControl.value;
		if (timeOnly && startDate !== null && endDate !== null) {
			endDate = dayjs(endDate).set('year', dayjs(startDate).get('year')).toDate();
			endDate = dayjs(endDate).set('month', dayjs(startDate).get('month')).toDate();
			endDate = dayjs(endDate).set('date', dayjs(startDate).get('date')).toDate();
		}
		if (startDate !== null && endDate !== null && (startDate > endDate)) {
			const errorObject = {};
			errorObject[errorProperty] = true;
			startDateControl.setErrors(Object.assign(startDateControl.errors || {}, errorObject));
			endDateControl.setErrors(Object.assign(endDateControl.errors || {}, errorObject));
			const returnObject = {};
			returnObject[errorProperty] = {range: true, message};
			return returnObject;
		} else {
			let startDateErrors = startDateControl.errors;
			let endDateErrors = endDateControl.errors;

			let groupErrors = formGroup.errors;

			if (startDateErrors !== null) {
				delete (startDateErrors[errorProperty]);
				if (Object.keys(startDateErrors).length === 0) {
					startDateErrors = null;
				}
			}

			if (endDateErrors !== null) {
				delete (endDateErrors[errorProperty]);
				if (Object.keys(endDateErrors).length === 0) {
					endDateErrors = null;
				}
			}

			if (groupErrors !== null && groupErrors !== undefined) {
				delete (groupErrors[errorProperty]);
				if (Object.keys(groupErrors).length === 0) {
					groupErrors = null;
				}
			}

			startDateControl.setErrors(startDateErrors);
			endDateControl.setErrors(endDateErrors);
			return groupErrors;
		}
	};
}
