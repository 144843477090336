// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.amendment.AmendmentResponse */
/* istanbul ignore next */
@GandalfModel
export class AmendmentResponse extends GandalfModelBase {

	@GandalfProperty()
	amendmentId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	amendmentSource: constants.AmendmentSource;

	@GandalfProperty()
	amendmentSourceText: string;

	@GandalfProperty()
	comments: string;

	@GandalfProperty()
	name: string;

	@GandalfProperty({ propertyType: 'LocalDateTime' })
	requestDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.AmendmentStatus;

	@GandalfProperty({ propertyType: 'LocalDateTime' })
	statusChangeDate: Date;

}
