/**
 * STATEFUL TAB DIRECTIVE
 * This directive is the merge of SyncFusion's tab component, the Angular Router and NgRx Store. The tab-set once
 * created will be persisted within the store as will the routes associated with the tab.
 *
 ***************************************************************************************************************
 *
 * IMPORTANT NOTES
 * + Admittedly, we are using the SyncFusion Tab Component outside of the context of its intended use. In a typical
 *   usage, we would provide content templates and SyncFusion would add/remove them from the view depending on which
 *   tab was selected. We have chosen to use the Router to control the view, and furthermore to use NgRx to persist
 *   the state of the tabs between page visits.
 * + The difference between the data driving the tabs and the tab elements themselves is crucial to understanding
 *   how this directive works. SyncFusion maintains a list of tab-items in the form of data alongside a NodeList
 *   of Elements actually rendered to the screen. These two lists are not necessarily kept in sync when it comes to
 *   their sort order. In most cases, when interacting with the SyncFusion Event API for the TabComponent, the index
 *   along with the HTMLElement is emitted. This often does not provide enough information to map the tab in question
 *   back to the data for the tab. For this reason we have added an id attribute onto each tab that can be used to map
 *   HTMLElements back to their tab item counterparts containing data that is stored within the state.
 *
 ***************************************************************************************************************
 *
 * KEY TERMS
 * + Tab Item - Data representation of a tab. This is the model that is persisted in the store and is often accessed
 *   to get information about a particular tab being interacted with.
 * + Tab Item Element - HTML Element representation of a tab. This is the actual HTML element of a tab that is rendered
 *   to the view.
 */

import { Directive, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RouterStoreUtils } from '@app-store/utils/router-store-utils';
import { _find, _findIndex, _get, _isEqual, _isNil } from '@core/lodash/lodash';
import { RouterUtilsService } from '@core/router-utils/router-utils.service';
import { StatefulTabItem } from '@shared/directives/stateful-tab/stateful-tab-item';
import { StatefulTabService } from '@shared/directives/stateful-tab/stateful-tab.service';
import { StatefulTabUtil } from '@shared/directives/stateful-tab/stateful-tab.util';
import { DialogUtil, TooltipService } from 'morgana';
import { AddEventArgs, RemoveEventArgs, SelectEventArgs, TabComponent } from '@syncfusion/ej2-angular-navigations';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

/**
 * Stateful Tab Item Map Interface
 * A basic implementation to fulfill the directive's input for tab data. We make the assumption that all tabs
 * can be mapped back to a number of some sort, whether it represent the ID of the entity represented by the tab
 * or something else. The general idea is that whatever number is used also serves as the route parameter predicating
 * which view should be displayed within the tab set.
 */
export type StatefulTabItemMap<T extends StatefulTabItem> = Map<number, T>;

/**
 * Stateful Tab Item Type Map Interface
 * This interface allows the use of a sub-type within tabs to differentiate between tabs that may represent different
 * entities within a tab-set. Preferably U in this case would represent an Enum of the options available as sub-types.
 */
export type StatefulTabItemTypeMap<T extends StatefulTabItem, U = any> = Map<U, StatefulTabItemMap<T>>;

/**
 * Stateful Tab Item Collection
 * This interface is provided to most methods, allowing them to either take the standard StatefulTabItemMap or the
 * StatefulTabItemTypeMap.
 */
export type StatefulTabItemCollection<T extends StatefulTabItem, U = any> = StatefulTabItemMap<T> | StatefulTabItemTypeMap<T, U>;

@Directive({
	selector: 'ejs-tab[pmsStatefulTab]',
})
export class StatefulTabDirective<T extends StatefulTabItem, U> implements OnChanges, OnDestroy {
	@Input()
	statefulTabItems: StatefulTabItemCollection<T, U>;

	@Input()
	staticTabs: StatefulTabItem[] = [];

	@Output()
	statefulTabRemoved: EventEmitter<string> = new EventEmitter<string>();

	/**
	 * Setting a string will cause a confirmation dialogue with that string to appear before the tab closes.
	 * Alternatively if a function is provided, it will show the confirmation if a string is returned from the function
	 */
	@Input()
	closeConfirmation: string | ((T) => string);

	/**
	 * This output triggers that the StatefulTabComponent has gone through one round of processing the given
	 * Tab Items and is now ready for interaction, or further extension by the host component.
	 */
	@Output()
	private initialized = new EventEmitter<void>();

	/**
	 * This is used to append information to the HTML elements that represent tabs so that in the event we need to
	 * map an event in the HTML to a tab item within the components data set, we can identify the correct entity.
	 */
	private readonly TAB_HEADER_TEXT_WRAPPER_ID = 'tabHeaderTextWrapper';

	private unsubscribe: Subject<void> = new Subject<void>();
	private closeTabWithoutConfirmation = false;
	private _isInitialized = false;
	private isTabComponentCreated = false;

	constructor(
		private router: Router,
		private routerUtils: RouterUtilsService,
		private routerStoreUtils: RouterStoreUtils,
		private statefulTabService: StatefulTabService,
		private tabComponent: TabComponent,
		private tooltipService: TooltipService,
	) {
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.statefulTabItems && changes.statefulTabItems.currentValue) {
			this.updateStatefulTabs(this.buildTabsFromStateMap(changes.statefulTabItems.currentValue));
		}
	}

	ngOnDestroy() {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	/***********************************************************************
	 * Host Listeners
	 ***********************************************************************/

	@HostListener('created')
	onCreated() {
		this.isTabComponentCreated = true;
		this.installStaticTabs();
		this.observeInnerTabRoutingEvents();
	}

	@HostListener('added', ['$event'])
	onAdded(addedArgs: AddEventArgs) {
		const addedItem = addedArgs.addedItems[0] as StatefulTabItem;
		if (this.routerUtils.isCurrentRouteChildRoute(this.router.url, addedItem.defaultRoute)) {
			this.tabComponent.select(this.getTabItemIndexById(addedItem.id));
		}
	}

	@HostListener('selected', ['$event'])
	async onSelected(event: SelectEventArgs): Promise<void> {
		if (this._isInitialized) {
			const tabItemId = this.getTabItemIdByElement(event.selectedItem);
			const currentRoute = this.routerStoreUtils.getCurrentRoute();
			const route = this.getTabItemRouteByTabItemId(tabItemId);

			if (!this.routerUtils.isCurrentRouteChildRoute(currentRoute, route)) {
				await this.router.navigateByUrl(route);
			}

			this.statefulTabService.emitTabSelection(this.getTabItemById(tabItemId));
		}
	}

	@HostListener('removing', ['$event'])
	async onRemoving(removeEvent: RemoveEventArgs) {
		const removeTabItem = this.getTabItemByElement(removeEvent.removedItem);
		if (this.isStaticTab(removeTabItem)) {
			// Static tabs should never be removed
			removeEvent.cancel = true;
			return;
		}
		if (!this.closeTabWithoutConfirmation && !_isNil(this.closeConfirmation)) {
			const confirmationString = this.getConfirmationString(removeTabItem);
			if (!_isNil(confirmationString)) {
				removeEvent.cancel = true;
				this.showConfirmationDialog(confirmationString, removeEvent.removedIndex);
				return;
			}
		}
		await this.closeTab(removeEvent);
	}

	private isStaticTab(removeTabItem) {
		return !_isNil(this.staticTabs.find((tabItem) => tabItem.id === removeTabItem.id));
	}

	/***********************************************************************
	 * Confirmation Dialog Methods
	 ***********************************************************************/

	showConfirmationDialog(confirmationString, tabIndex: number) {
		const dialog = DialogUtil.confirm(
			{
				title: 'Close Confirmation',
				content: confirmationString,
				okButton: {
					click: () => {
						this.closeTabWithoutConfirmation = true;
						this.tabComponent.removeTab(tabIndex);
						this.closeTabWithoutConfirmation = false;
						dialog.close();
					},
				},
			},
		);
	}

	getConfirmationString(removeTabItem: StatefulTabItem) {
		let confirmationString = null;
		if (typeof this.closeConfirmation === 'function') {
			confirmationString = this.closeConfirmation(removeTabItem);
		} else {
			confirmationString = this.closeConfirmation;
		}
		return confirmationString;
	}

	/***********************************************************************
	 * Routing Methods
	 ***********************************************************************/

	/**
	 * Observe Inner Tab Routing Events
	 * This allows us to select tabs based on the current route of the application given a navigation
	 * event occurs outside of the TabComponent causing the application to navigate to a separate route,
	 * this will update the TabComponent to reflect the correct selection.
	 */
	private observeInnerTabRoutingEvents() {
		this.routerUtils.observeNavigationEvents(NavigationEnd, false).pipe(
			distinctUntilChanged((prev, curr) => _isEqual(prev.urlAfterRedirects, curr.urlAfterRedirects)),
			takeUntil(this.unsubscribe),
		).subscribe(event => this.validateTabSelection(event.urlAfterRedirects));
	}

	validateTabSelection(currentUrl?: string) {
		const url = currentUrl || this.routerStoreUtils.getCurrentRoute();
		const tabItemId = _get(this.getTabItemByChildRoute(url), ['id']);
		if (!_isNil(tabItemId) && !this.isTabItemSelected(tabItemId)) {
			this.selectTabItemById(tabItemId);
		}
	}

	/**
	 * Close Tab
	 * Closes a tab and routes away from the route of the closed tab. Following the closing of the tab
	 * the method emits a statefulTabRemoved emission to alert components of the successful closing.
	 * @param removeEvent Remove event to process.
	 */
	async closeTab(removeEvent: RemoveEventArgs) {
		const tabItemId = this.getTabItemIdByElement(removeEvent.removedItem);
		const tabDefaultRoute = this.getTabItemById(tabItemId).defaultRoute;
		await this.routeAwayFromClosedTab(removeEvent);
		this.statefulTabRemoved.emit(tabDefaultRoute);
	}

	/**
	 * Route Away From Closed Tab
	 * Routes the user away from the closed tab. By default this routes the user to the tab to the
	 * left in the sequence.
	 * @param removeEvent Remove event to process.
	 * @returns Promise<boolean> Promise that resolves when routing is complete.
	 */
	async routeAwayFromClosedTab(removeEvent: RemoveEventArgs): Promise<boolean> {
		const removeTabItemId = this.getTabItemIdByElement(removeEvent.removedItem);
		if (this.isTabItemSelected(removeTabItemId)) {
			const route = this.getTabItemRouteByElementIndex(removeEvent.removedIndex - 1);
			return await this.router.navigateByUrl(route);
		} else {
			this.reselectTab();
		}
		return;
	}

	private reselectTab() {
		const selectItemId = this.getStatefulTabItems()[this.tabComponent.selectedItem].id;
		// When the remove tab event occurs we need to wait for syncfusion to update before we reselect the tab that it should stay on
		setTimeout(() => {
			this.selectTabItemById(selectItemId);
		});
	}

	/**
	 * Get Tab Route
	 * Gets the tab route. By default it will attempt to retrieve the current route, if the current
	 * route is undefined it will return the default route.
	 * @param tabItemElementIndex Index of the tab to get the route of.
	 */
	getTabItemRouteByElementIndex(tabItemElementIndex: number) {
		const tabItem = this.getTabItemByElementIndex(tabItemElementIndex);
		return tabItem.currentRoute || tabItem.defaultRoute;
	}

	/**
	 * Get Tab Item Route By Tab Item Id
	 * Gets the tab item route of the provided tab item id.
	 * @param tabItemId Tab Item id to use to retrieve the corresponding route.
	 */
	getTabItemRouteByTabItemId(tabItemId: string) {
		const tabItem = this.getTabItemById(tabItemId);
		return tabItem.currentRoute || tabItem.defaultRoute;
	}

	/**
	 * Get Tab Item By Child Route
	 * Gets the Tab Item associated with the provided Child Route.
	 * @param childRoute Child Route to use to identify the associated Tab Item.
	 * @returns StatefulTabItem Tab Item associated with the Child Route.
	 */
	getTabItemByChildRoute(childRoute: string): StatefulTabItem {
		return _find(this.getStatefulTabItems(), item => this.routerUtils.isCurrentRouteChildRoute(childRoute, item.defaultRoute));
	}

	/***********************************************************************
	 * Tab Construction
	 ***********************************************************************/

	/**
	 * Update Stateful Tabs
	 * Updates or Inserts the given array of Stateful Tab Items. If a tab is not present within the current
	 * TabComponent, it will be added, otherwise the existing tab will be found and updated.
	 * @param updatedTabItemSet Set of tabs to update into the TabComponent.
	 */
	updateStatefulTabs(updatedTabItemSet: StatefulTabItem[]): void {
		this.pruneTabItems(updatedTabItemSet);
		updatedTabItemSet.forEach(updatedTabItem => {
			const tabItemIndex = this.getTabItemIndexById(updatedTabItem.id);
			if (tabItemIndex === -1) {
				this.tabComponent.addTab([updatedTabItem],
					StatefulTabUtil.getIndexBySortOrder(this.statefulTabItems, updatedTabItem, this.staticTabs.length),
				);
			} else {
				this.getStatefulTabItems()[tabItemIndex] = {...this.getStatefulTabItems()[tabItemIndex],
					header: updatedTabItem.header,
					currentRoute: updatedTabItem.currentRoute,
				};
			}
		});

		if (this.isTabComponentCreated) {
			this.tabComponent.refresh();
		}

		this.isInitialized = true;
	}

	/**
	 * Prune Tab Items
	 * Takes an updated Tab Item Set and prunes the existing tab item set based on entries found in the
	 * provided set. This allows the programmatic removal of tab items through the store being updated.
	 * @param updatedTabItemSet Updated tab item set to use to prune the existing tab item set.
	 */
	pruneTabItems(updatedTabItemSet: StatefulTabItem[]) {
		this.getStatefulTabItems().forEach((currentTabItem, index) => {
			if (_findIndex(updatedTabItemSet, ['id', currentTabItem.id]) === -1 && index >= this.staticTabs.length) {
				this.closeTabWithoutConfirmation = true;
				this.tabComponent.removeTab(index);
				this.closeTabWithoutConfirmation = false;
				const tabLength = this.getStatefulTabItems().length;
				if (this.tabComponent.selectedItem >= tabLength) {
					this.tabComponent.selectedItem = tabLength - 1;
				}
			}
		});
	}

	/**
	 * Install Static Tabs
	 * Installs static tabs into the tab component.
	 */
	installStaticTabs() {
		this.tabComponent.addTab(this.staticTabs.map(staticTab => {
			staticTab.id = uuidv4();
			staticTab.cssClass = 'e-toolbar-item-non-closable';
			staticTab.content = '';
			staticTab.header = {...staticTab.header,
				text: this.buildTabHeaderTextElement(staticTab.id, staticTab.header.text as string)};
			return staticTab;
		}));
	}

	/**
	 * Build Tabs
	 * This takes the data provided by the store and builds the tabs needed to represent the currently opened entities.
	 * @param tabItemCollection Map or Array of patient tabs currently open.
	 */
	buildTabsFromStateMap(tabItemCollection: StatefulTabItemCollection<T>): StatefulTabItem[] {
		return StatefulTabUtil.getTabItemArray(tabItemCollection).map(tabItem => {
			const headerText = _isNil(tabItem.headerText) ? '' : tabItem.headerText;
			return {
				id: tabItem.id,
				header: {text: this.buildTabHeaderTextElement(tabItem.id, headerText, tabItem.tooltipContent)},
				currentRoute: tabItem.currentRoute,
				defaultRoute: tabItem.defaultRoute,
				sortOrder: tabItem.sortOrder ? tabItem.sortOrder : 0,
				subType: tabItem.subType,
				type: tabItem.type,
				content: '',
			} as StatefulTabItem;
		});
	}

	/**
	 * Build Tab Header Text Element
	 * Builds the tab header span element to wrap the provided text.
	 * @param tabItemId Tab Item Id to use for the wrapper.
	 * @param tabItemHeaderText Tab Item Header Text to use within the span element.
	 * @param tooltipContent optional tooltip content for the tab.
	 */
	buildTabHeaderTextElement(tabItemId: string, tabItemHeaderText: string, tooltipContent?: string): HTMLElement {
		const headerTextElement = document.createElement('span');
		headerTextElement.id = this.TAB_HEADER_TEXT_WRAPPER_ID;
		headerTextElement.setAttribute('tab-id', tabItemId);
		const headerTextNode = document.createTextNode(tabItemHeaderText);
		headerTextElement.appendChild(headerTextNode);
		if (!_isNil(tooltipContent)) {
			this.tooltipService.buildTooltip(tooltipContent, headerTextElement);
		}
		headerTextElement.setAttribute('data-test-id', this.buildTabDataTestId(tabItemHeaderText));
		return headerTextElement;
	}

	/**
	 * Builds the dataTestId for the constructed tab header span element
	 * @param tabItemHeaderText Tab Item Header Text to use within span element; serves as the prefix for dataTestId
	 */
	buildTabDataTestId(tabItemHeaderText: string) {
		return tabItemHeaderText.toLowerCase().replace(/\s/g, '') + '.navigationTab';
	}

	/***********************************************************************
	 * Tab Item Selection
	 ***********************************************************************/

	/**
	 * Is Tab Item Selected
	 * Returns a boolean value representing whether or not the provided tab ID is related to the currently
	 * selected Tab Item.
	 * @param tabItemId Tab ID to use to test against the selected Tab Item.
	 * @returns boolean Whether or not the tab item provided is selected.
	 */
	isTabItemSelected(tabItemId: string): boolean {
		const selectedTabId = this.getSelectedTabItemId();
		return tabItemId === selectedTabId;
	}

	/**
	 * Get Selected Tab Item ID
	 * Gets the selected Tab Item ID
	 * @returns string ID of the selected Tab Item Element
	 */
	getSelectedTabItemId(): string {
		const tabItemElement = this.getTabItemElements()[this.tabComponent.selectedItem] as HTMLElement;
		return this.getTabItemIdByElement(tabItemElement);
	}

	/**
	 * Select Tab Item By Id
	 * Selects the item corresponding to the provided Tab Item id.
	 * @param tabItemId Tab Item id to use to select the Tab Item.
	 */
	selectTabItemById(tabItemId: string) {
		this.tabComponent.select(this.getTabItemElementIndexById(tabItemId));
	}

	/***********************************************************************
	 * Tab Item Lookups
	 ***********************************************************************/

	/**
	 * Get Stateful Tab Items
	 * Gets the items within the Tab Component. This list represents the array of data used to build the Tab Item Elements.
	 * @returns StatefulTabItem[] Array of the current stateful items within the component.
	 */
	getStatefulTabItems(): StatefulTabItem[] {
		return this.tabComponent.items as StatefulTabItem[];
	}

	/**
	 * Get Tab Item Elements
	 * Gets the tab item HTMLElements in the form of a NodeList using a private property on the Tab Component.
	 * WARNING: Accessing private properties on a class is a known bad practice, but in this instance, it seems
	 * to be the only way to acquire the list of tab elements in a predictable manner.
	 * @returns NodeList Node list of elements representing tab items.
	 */
	getTabItemElements(): NodeList {
		return this.tabComponent['tbItem'];
	}

	/**
	 * Get Tab Item By Id
	 * Gets a Tab Item by the provided Id.
	 * @param tabItemId Tab Item id to use to retrieve the Tab Item.
	 */
	getTabItemById(tabItemId: string): StatefulTabItem {
		return _find(this.getStatefulTabItems(), ['id', tabItemId]);
	}

	/**
	 * Get Tab Item Id By Element
	 * Gets the Tab Item Id of the provided Tab Item Element.
	 * @param tabItemElement Tab Item Element to extract the Id from.
	 * @returns string Id of the Tab Item associated with the provided Tab Item Element.
	 */
	getTabItemIdByElement(tabItemElement: HTMLElement): string {
		const tabItemHeaderTextElement = tabItemElement.querySelector(`#${this.TAB_HEADER_TEXT_WRAPPER_ID}`);
		return tabItemHeaderTextElement.getAttribute('tab-id');
	}

	/**
	 * Get Tab Item By Element
	 * Get the Tab Item associated with the provided Tab Item Element.
	 * @param tabItemElement Tab Item Element to use to identify the associated Tab Item.
	 * @returns StatefulTabItem Tab Item associated with the provided Tab Item Element.
	 */
	getTabItemByElement(tabItemElement: HTMLElement): StatefulTabItem {
		const tabItemId = this.getTabItemIdByElement(tabItemElement);
		return this.getTabItemById(tabItemId);
	}

	/**
	 * Get Tab Item By Element Index
	 * Gets a Tab Item associated with a particular Tab Item Element at a specific index.
	 * @param tabItemElementIndex Index of the Tab Item Element to the Tab Item.
	 * @returns StatefulTabItem Tab Item of of the index of the Tab Item Element provided.
	 */
	getTabItemByElementIndex(tabItemElementIndex: number): StatefulTabItem {
		return this.getTabItemByElement(this.getTabItemElements()[tabItemElementIndex] as HTMLElement);
	}

	/**
	 * Get Tab Item Index By Id
	 * Gets the index of the Tab Item using the provided Tab Item Id.
	 * @param tabItemId Tab Item Id to use to target the Tab Item Element
	 * @returns number Index of the Tab Item associated with the provided Tab Item Id.
	 */
	getTabItemIndexById(tabItemId: string): number {
		return _findIndex(this.getStatefulTabItems(), ['id', tabItemId]);
	}

	/**
	 * Get Tab Item Element Index By Id
	 * Gets the index of the Tab Item Element using the provided Tab Item Id.
	 * @param tabItemId Tab Item Id to use to target the Tab Item Element.
	 * @returns number Index of the Tab Item Element associated with the provided Tab Item Id.
	 */
	getTabItemElementIndexById(tabItemId: string): number {
		return _findIndex(Array.from(this.getTabItemElements()), (tabItemElement: HTMLElement) => tabItemId === this.getTabItemIdByElement(tabItemElement));
	}

	/**
	 * Is Initialized
	 * This is responsible for storing the state of the component in terms of being initialized. This
	 * will also emit the initialized output if the previous value was false.
	 * @param isInitialized Incoming isInitialized value
	 */
	set isInitialized(isInitialized: boolean) {
		if (!this._isInitialized && isInitialized) {
			this.initialized.emit();
			this.validateTabSelection();
		}

		this._isInitialized = isInitialized;
	}
}
