<form class="form-horizontal">
	<div class="row margin-bottom-sm">
		<div class="col-md-6 col-lg-4">
			<div class="form-group margin-bottom-0" [attr.data-test-id]="'readOnlyApplyFullFormGroup'">
				<label class="col-sm-4 control-label">Payment Amount</label>
				<div class="col-sm-8">
					@if (paymentGroup.applyFull) {
						<p class="form-control-static">{{ paymentGroup.paymentAmount | currency }} (Apply in Full selected)</p>
					}
					@if (!paymentGroup.applyFull) {
						<p class="form-control-static">{{ paymentGroup.paymentAmount | currency }}</p>
					}
				</div>
			</div>
			<div class="form-group margin-bottom-0" [attr.data-test-id]="'readOnlyCreditCardFormGroup'">
				<label class="col-sm-4 control-label">Payment Method</label>
				<div class="col-sm-8">
					@if (paymentGroup.creditCardType) {
						<p class="form-control-static">{{ paymentGroup.paymentMethodType }} - {{ paymentGroup.creditCardType }}</p>
					}
					@if (!paymentGroup.creditCardType) {
						<p class="form-control-static">{{ paymentGroup.paymentMethodType }}</p>
					}
				</div>
			</div>
		</div>
		<div class="col-md-6 col-lg-4">
			<div class="form-group margin-bottom-0" [attr.data-test-id]="'readOnlyReferenceNumberFormGroup'">
				<label class="col-sm-4 control-label">Reference/Check #</label>
				<div class="col-sm-8">
					<p class="form-control-static">{{ paymentGroup.referenceNumber }}</p>
				</div>
			</div>
			<div class="form-group margin-bottom-0" [attr.data-test-id]="'readOnlyPaymentDateFormGroup'">
				<label class="col-sm-4 control-label">Payment Date</label>
				<div class="col-sm-8">
					<p class="form-control-static">{{ paymentGroup.paymentDate | date: dateFormat }}</p>
				</div>
			</div>
			<div class="form-group margin-bottom-0" [attr.data-test-id]="'readOnlyLocationFormGroup'">
				<label class="col-sm-4 control-label">Location</label>
				<div class="col-sm-8">
					<p class="form-control-static">{{ locationName }}</p>
				</div>
			</div>
		</div>
		<div class="col-md-6 col-lg-4">
			<div class="form-group margin-bottom-0" [attr.data-test-id]="'readOnlyCommentsFormGroup'">
				<label class="col-sm-4 control-label">Comments</label>
				<div class="col-sm-8">
					<div class="form-control-static">{{ paymentGroup.comment }}</div>
				</div>
			</div>
		</div>
	</div>
	<div class="h385">
		<ag-grid-angular
			class="ag-theme-alpine hFull"
			pmsAgAutoFitColumns
			#invoicePaymentsGrid
			[gridOptions]="invoicePaymentsGridOptions"
			[rowData]="invoicePayments"
			(gridReady)="updateAggregateRow()"
			[pagination]="false"
			[attr.data-test-id]="'readOnlyinvoicePaymentFormGroup'">
		</ag-grid-angular>
	</div>
</form>
