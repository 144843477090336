// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { DurationResponse } from './duration-response';

/** See com.rev360.pms.api.controller.patient.opticalsummary.OpticalSummaryLatestFindingsContactLensTrialResponse */
/* istanbul ignore next */
@GandalfModel
export class OpticalSummaryLatestFindingsContactLensTrialResponse extends GandalfModelBase {

	@GandalfProperty()
	contactLensTrialId: number;

	@GandalfProperty()
	odAddDesignation: string;

	@GandalfProperty()
	odAddPower: number;

	@GandalfProperty()
	odAxis: number;

	@GandalfProperty()
	odBaseCurve: number;

	@GandalfProperty()
	odComment: string;

	@GandalfProperty()
	odCylinder: number;

	@GandalfProperty()
	odDiameter: number;

	@GandalfProperty()
	odIsDispensed: boolean;

	@GandalfProperty()
	odIsMonovision: boolean;

	@GandalfProperty()
	odLocationProductName: string;

	@GandalfProperty()
	odOvernightSchedule: DurationResponse;

	@GandalfProperty()
	odReplacementSchedule: DurationResponse;

	@GandalfProperty()
	odSphere: number;

	@GandalfProperty()
	osAddDesignation: string;

	@GandalfProperty()
	osAddPower: number;

	@GandalfProperty()
	osAxis: number;

	@GandalfProperty()
	osBaseCurve: number;

	@GandalfProperty()
	osComment: string;

	@GandalfProperty()
	osCylinder: number;

	@GandalfProperty()
	osDiameter: number;

	@GandalfProperty()
	osIsDispensed: boolean;

	@GandalfProperty()
	osIsMonovision: boolean;

	@GandalfProperty()
	osLocationProductName: string;

	@GandalfProperty()
	osOvernightSchedule: DurationResponse;

	@GandalfProperty()
	osReplacementSchedule: DurationResponse;

	@GandalfProperty()
	osSphere: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	overallLensType: constants.ContactLensPrescriptionType;

}
