export class BasePanelMenuItem {

	handleClick(event, item) {
		if (item.disabled) {
			event.preventDefault();
			return;
		}

		item.expanded = !item.expanded;

		if (!item.url) {
			event.preventDefault();
		}

		if (item.command) {
			item.command({
				originalEvent: event,
				item,
			});
		}
	}
}
