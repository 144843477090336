<ejs-dialog
	#modal
	(close)="closeModal(false)"
	cssClass="modal-lg"
	[attr.data-test-id]="'addPatientToWaitListModal'">
	<ng-template #header>
		<div class="dlg-template">Add Patient to Wait List</div>
	</ng-template>
	<ng-template #content>
		<div class="row">
			<div class="col-sm-6">
				<form
					#templateForm="ngForm"
					(ngSubmit)="submitForm()"
					[formGroup]="waitListComponentForm"
					class="form-horizontal">
					<h4 class="section-title bg-default mrgn-btm">Details</h4>
					@if (waitListComponentForm.invalid && templateForm.submitted) {
						<gandalf-lib-validation-messages
							[form]="waitListComponentForm"
							[requestObj]="waitingListRequest"
							[attr.data-test-id]="'addPatientToWaitListModalValidationMessages'">
						</gandalf-lib-validation-messages>
					}
					<div class="form-group" [attr.data-test-id]="'waitListAddPatientSearch'">
						<label class="col-sm-4 control-label required">Patient</label>
						<div class="col-sm-8">
							@if (!readOnlyPatient) {
								<div>
									<pms-search-patient
										formControlName="patientId"
										[hideNavigateToPatient]="true"
										[activeOnly]="true"
										[isFocused]="focusPatientSearch">
									</pms-search-patient>
								</div>
							}
							@if (readOnlyPatient) {
								<p class="form-control-static">{{ patientNameLabel }}</p>
							}
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'waitListAddLocationDropdown'">
						<label class="col-sm-4 control-label">Location</label>
						<div class="col-sm-8">
							<ejs-dropdownlist [dataSource]="locationList" formControlName="locationId" placeholder="All Locations"
											  [showClearButton]="true"></ejs-dropdownlist>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'waitListAddProviderDropdown'">
						<label class="col-sm-4 control-label">Provider</label>
						<div class="col-sm-8">
							<ejs-dropdownlist [dataSource]="providerList" formControlName="providerId" placeholder="All Providers"
											  [showClearButton]="true"></ejs-dropdownlist>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'waitListAddEncounterTemplateDropdown'">
						<label class="col-sm-4 control-label required">Type</label>
						<div class="col-sm-8">
							<ejs-dropdownlist [dataSource]="encounterTemplateList" formControlName="templateId" placeholder="Select Type"></ejs-dropdownlist>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'waitListAddPriorityEnumSelect'">
						<label class="col-sm-4 control-label required">Priority</label>
						<div class="col-sm-8">
							<pms-enum-select-button
								[enumList]="waitListPriorityOptions"
								formControlName="priority">
							</pms-enum-select-button>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'waitListAddEndDatePicker'">
						<label class="control-label col-sm-4">End Date</label>
						<div class="col-sm-8">
							<ejs-datepicker formControlName="endDate"
											placeholder="mm/dd/yyyy"
											[min]="dateMin"
											[max]="dateMax"
											[format]="dateFormat"></ejs-datepicker>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'waitListAddComments'">
						<label class="control-label col-sm-4">Comments</label>
						<div class="col-sm-8">
							<ejs-textbox
								[multiline]="true"
								class="h-rows-5"
								formControlName="comments">
							</ejs-textbox>
						</div>
					</div>
				</form>
			</div>
			<div class="col-sm-6" [attr.data-test-id]="'dayTimePreferencesSection'">
				<h4 class="section-title bg-default mrgn-btm">Day/Time Preferences</h4>
				<form
					#templateForm="ngForm"
					(ngSubmit)="submitForm()"
					[formGroup]="preferenceComponentForm"
					class="form-horizontal">
					<div class="form-group" [attr.data-test-id]="'waitListAddPreferencesSundayDropdown'">
						<label class="col-sm-4 control-label">Sunday</label>
						<div class="col-sm-8">
							<pms-constant-dropdown
								dataTestIdPrefix="sunday"
								[constantList]="schedulingPreferencesTimeOfDay"
								formControlName="sundayTime"
								placeholder="Select Sunday Preferences"
								[showClearButton]="true"
							></pms-constant-dropdown>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'waitListAddPreferencesMondayDropdown'">
						<label class="col-sm-4 control-label">Monday</label>
						<div class="col-sm-8">
							<pms-constant-dropdown
								dataTestIdPrefix="monday"
								[constantList]="schedulingPreferencesTimeOfDay"
								formControlName="mondayTime"
								placeholder="Select Monday Preferences"
								[showClearButton]="true"
							></pms-constant-dropdown>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'waitListAddPreferencesTuesdayDropdown'">
						<label class="col-sm-4 control-label">Tuesday</label>
						<div class="col-sm-8">
							<pms-constant-dropdown
								dataTestIdPrefix="tuesday"
								[constantList]="schedulingPreferencesTimeOfDay"
								formControlName="tuesdayTime"
								placeholder="Select Tuesday Preferences"
								[showClearButton]="true"
							></pms-constant-dropdown>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'waitListAddPreferencesWednesdayDropdown'">
						<label class="col-sm-4 control-label">Wednesday</label>
						<div class="col-sm-8">
							<pms-constant-dropdown
								dataTestIdPrefix="wednesday"
								[constantList]="schedulingPreferencesTimeOfDay"
								formControlName="wednesdayTime"
								placeholder="Select Wednesday Preferences"
								[showClearButton]="true"
							></pms-constant-dropdown>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'waitListAddPreferencesThursdayDropdown'">
						<label class="col-sm-4 control-label">Thursday</label>
						<div class="col-sm-8">
							<pms-constant-dropdown
								dataTestIdPrefix="thursday"
								[constantList]="schedulingPreferencesTimeOfDay"
								formControlName="thursdayTime"
								placeholder="Select Thursday Preferences"
								[showClearButton]="true"
							></pms-constant-dropdown>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'waitListAddPreferencesFridayDropdown'">
						<label class="col-sm-4 control-label">Friday</label>
						<div class="col-sm-8">
							<pms-constant-dropdown
								dataTestIdPrefix="friday"
								[constantList]="schedulingPreferencesTimeOfDay"
								formControlName="fridayTime"
								placeholder="Select Friday Preferences"
								[showClearButton]="true"
							></pms-constant-dropdown>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'waitListAddPreferencesSaturdayDropdown'">
						<label class="col-sm-4 control-label">Saturday</label>
						<div class="col-sm-8">
							<pms-constant-dropdown
								dataTestIdPrefix="saturday"
								[constantList]="schedulingPreferencesTimeOfDay"
								formControlName="saturdayTime"
								placeholder="Select Saturday Preferences"
								[showClearButton]="true"
							></pms-constant-dropdown>
						</div>
					</div>
				</form>
			</div>
		</div>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button type="button" (click)="closeModal(false)" iconCss="fa fa-close" [attr.data-test-id]="'waitListAddCancelButton'">Cancel</button>
		<button ejs-button type="submit" [isPrimary]="true" (click)="templateForm.onSubmit($event)" [attr.data-test-id]="'waitListAddSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>
