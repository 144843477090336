<ejs-dialog
	#modal
	(close)="closeModal()"
	[attr.data-test-id]="'uploadDocumentToFolderModal'">
	<ng-template #header>
		<div class="dlg-template">Upload File</div>
	</ng-template>
	<ng-template #content>
		<div #uploadDropArea>
			@if (fileUploadSettings) {
				<ejs-uploader
					[asyncSettings]="fileUploadSettings"
					[multiple]="false"
					[buttons]="{browse: 'Browse...'}"
					[dropArea]="uploadDropArea"
					(success)="onSuccess($event)"
					[attr.data-test-id]="'uploadDocumentToFolderModalUploader'">
				</ejs-uploader>
			}
		</div>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button type="button" (click)="closeModal()" iconCss="fa fa-close" [attr.data-test-id]="'uploadDocumentToFolderModalCancelButton'">Cancel
		</button>
	</ng-template>

</ejs-dialog>
