/* eslint-disable max-len */
import { RemoveFormValuePayload, UpdateFormSubmittedPayload, UpdateFormValuePayload } from '@app-store/utils/legacy-stateful-component-utils.service';
import { CreatePatientProviderRequest } from '@gandalf/model/create-patient-provider-request';
import { PatientProviderResponse } from '@gandalf/model/patient-provider-response';
import { UpdatePatientProviderRequest } from '@gandalf/model/update-patient-provider-request';
import { Action } from '@ngrx/store';

export enum PatientProvidersDetailActionTypes {
	UPDATE_PATIENT_PROVIDERS_DETAIL_FORM_MODE = '[Update Patient Providers Detail Form Mode] Updates the mode of the Patient Providers Detail form within the store.',
	UPDATE_PATIENT_PROVIDERS_DETAIL_FORM_VALUE = '[Update Patient Providers Detail Form Value] Updates the value of the Patient Providers Detail form within the store.',
	UPDATE_PATIENT_PROVIDERS_DETAIL_FORM_SUBMITTED = '[Update Patient Providers Detail Form Submitted] Updates the value of the Patient Providers Detail form submission within the store.',
	UPDATE_PATIENT_PROVIDERS_DETAIL_TAB_VALUE = '[Update Patient Providers Detail Tab Value] Updates the value of the selected Patient Providers Details tab within the store.',
	REMOVE_PATIENT_PROVIDERS_DETAIL = '[Remove Patient Providers Detail] Removes an entire Patient Providers Detail entry from the store.',
	REMOVE_PATIENT_PROVIDERS_DETAIL_FORM_VALUE = '[Remove Patient Providers Form value] Removes an entire Patient Providers form value entry from the store.',
	UPDATE_PATIENT_PROVIDERS_TABLE_CONFIG_VALUE = '[Update Patient Providers Table Config Value] Updates the value of the Patient Providers Table Config within the store.',
}

export class UpdatePatientProvidersDetailFormMode implements Action {
	readonly type = PatientProvidersDetailActionTypes.UPDATE_PATIENT_PROVIDERS_DETAIL_FORM_MODE;
	constructor(public payload: {
		patientId: number;
		personProviderId: number;
		providerId: number;
		providerRoleId: number;
		detailMode: boolean;
		newEdit: boolean;
	}) {}
}

export class UpdatePatientProvidersDetailFormValue implements Action {
	readonly type = PatientProvidersDetailActionTypes.UPDATE_PATIENT_PROVIDERS_DETAIL_FORM_VALUE;
	constructor(public payload: UpdateFormValuePayload<CreatePatientProviderRequest | UpdatePatientProviderRequest>) {}
}

export class UpdatePatientProvidersSelectedDetailsTab implements Action {
	readonly type = PatientProvidersDetailActionTypes.UPDATE_PATIENT_PROVIDERS_DETAIL_TAB_VALUE;
	constructor(public payload: {patientId: number, tabIndex: number}) {}
}

export class UpdatePatientProvidersDetailFormSubmitted implements Action {
	readonly type = PatientProvidersDetailActionTypes.UPDATE_PATIENT_PROVIDERS_DETAIL_FORM_SUBMITTED;
	constructor(public payload: UpdateFormSubmittedPayload) {}
}

export class UpdatePatientProvidersTableConfigValue implements Action {
	readonly type = PatientProvidersDetailActionTypes.UPDATE_PATIENT_PROVIDERS_TABLE_CONFIG_VALUE;
	constructor(public payload: {patientId: number, gridConfig: string, results: PatientProviderResponse[] }) {}
}

export class RemovePatientProvidersDetail implements Action {
	readonly type = PatientProvidersDetailActionTypes.REMOVE_PATIENT_PROVIDERS_DETAIL;
	constructor(public payload: { patientId: number }) {}
}

export class RemovePatientProvidersDetailFormValue implements Action {
	readonly type = PatientProvidersDetailActionTypes.REMOVE_PATIENT_PROVIDERS_DETAIL_FORM_VALUE;
	constructor(public payload: RemoveFormValuePayload) {}
}


export type PatientProvidersDetailActions =
	UpdatePatientProvidersDetailFormMode
	| UpdatePatientProvidersDetailFormValue
	| UpdatePatientProvidersDetailFormSubmitted
	| UpdatePatientProvidersSelectedDetailsTab
	| RemovePatientProvidersDetail
	| UpdatePatientProvidersTableConfigValue;
