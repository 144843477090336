import { Component, Input, OnInit } from '@angular/core';
import { MenuEventArgs } from '@syncfusion/ej2-angular-splitbuttons';
import { AgGridAngular } from 'ag-grid-angular';
import { GridUtil } from '../../utils/grid-util/grid-util';
import { EXPORT_ICON, EXPORT_TEXT, TableToolItem } from '../table-action-menu/table-action-menu.component';

@Component({
	selector: 'rev-ag-grid-container',
	templateUrl: './ag-grid-container.component.html',
	styles: [],
})
export class AgGridContainerComponent implements OnInit {

	@Input()
	agGrid: AgGridAngular;

	@Input()
	items: TableToolItem[];

	@Input()
	exportFileName: string;

	@Input()
	showToolbar = true;

	isGridReady = false;

	constructor() {
	}

	ngOnInit(): void {
		this.agGrid.gridReady.subscribe(() => {
			this.isGridReady = true;
			if (!this.items && !this.agGrid.suppressCsvExport) {
				this.createMenu();
			}
		});

		this.agGrid.rowDataUpdated.subscribe(() => {
			if (!this.items && !this.agGrid.suppressCsvExport) {
				this.createMenu();
			}
		});
	}

	createMenu() {
		this.items = [
			{
				text: EXPORT_TEXT,
				iconCss: 'fa fa-download',
				dataTestId: 'Export',
			},
		];
	}

	itemSelected(event: MenuEventArgs) {
		const currentItem = event.item as TableToolItem;
		this.runItemAction(currentItem);
	}


	runItemAction(currentItem: TableToolItem) {
		if (currentItem.action) {
			currentItem.action();
		} else if (currentItem.text === EXPORT_TEXT || currentItem.iconCss === EXPORT_ICON) {
			this.exportToCsv();
		}
	}

	exportToCsv() {
		GridUtil.exportAGGridToCsv(this.agGrid);
	}

	hasToolBarItems(): boolean {
		return !!(this.agGrid?.api?.getDisplayedRowCount() && this.items?.length);
	}

	shouldShowToolbar() {
		return this.isGridReady && this.showToolbar;
	}

}
