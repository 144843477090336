import { _isArray, _isArrayLike, _isEmpty, _isNil, _isString } from '../lodash/lodash';

export class ObjectUtils {

	constructor() {
	}

	/**
	 * Checks if object is null, undefined or is an empty array
	 * Note: This will return true for an empty string
	 */
	static isNilOrEmpty(object: any) {
		if (_isArrayLike(object)) {
			return _isEmpty(object);
		}

		return _isNil(object);
	}

	/**
	 * Checks if the object is an array with at least one element
	 */
	static isNotEmptyArray(object: any) {
		return _isArray(object) && object.length > 0;
	}

	/**
	 * Checks if the object is an string with at least one character
	 */
	static isNotEmptyString(object: any) {
		return _isString(object) && object.length > 0;
	}
}
