// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { QueryCriteriaFieldRequest } from './query-criteria-field-request';

// @ts-ignore
import { QuerySelectFieldRequest } from './query-select-field-request';

// @ts-ignore
import { QuerySortFieldRequest } from './query-sort-field-request';

/** See com.rev360.pms.api.controller.query.UpdateQueryRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateQueryRequest extends GandalfModelBase {

	@GandalfProperty()
	categoryId: number;

	@GandalfArray(QueryCriteriaFieldRequest)
	criteriaFields: QueryCriteriaFieldRequest[];

	@GandalfValidator({ sizeString: { message: 'Description cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	description: string;

	@GandalfValidator({ notNull: { message: 'ID is required' } })
	@GandalfProperty({ isRequired: true })
	id: number;

	@GandalfValidator({ notNull: { message: 'Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Name must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ isRequired: true })
	name: string;

	@GandalfValidator({ sizeArray: { min: 1, max: 2147483647, message: 'Access Roles is required' } })
	@GandalfArray(Number)
	roleIds: number[];

	@GandalfArray(QuerySelectFieldRequest)
	selectFields: QuerySelectFieldRequest[];

	@GandalfArray(QuerySortFieldRequest)
	sortFields: QuerySortFieldRequest[];

}
