@if (getFormControl()) {
	<ejs-numerictextbox
		#numericInput
		[format]="params.format"
		[min]="params.min"
		[showSpinButton]="false"
		(keydown.enter)="setFormValue()"
		(keydown.tab)="setFormValue()"
		[formControl]="getFormControl()">
	</ejs-numerictextbox>
}
