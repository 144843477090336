import { Component, OnInit, ViewChild } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
	selector: 'pms-comment-modal',
	templateUrl: './comment-modal.component.html',
	styles: [],
})
export class CommentModalComponent implements OnInit {
	@ViewChild('modal') modal: DialogComponent;

	comments = '';
	title;
	maxLength;
	commentLabel;
	DEFAULT_MAX_LENGTH = 255;

	constructor(
		private ref: DynamicModalRef,
		public modalConfig: ModalConfig,

	) {}

	ngOnInit(): void {
		this.parseModalConfig(this.modalConfig.data);
	}

	closeModal(confirmed = false) {
		this.ref.close(this.modal, { confirmed, comments: this.comments });
	}

	parseModalConfig(data: any) {
		this.title = data.title;
		this.maxLength = !_isNil(data.maxLength) ? data.maxLength : this.DEFAULT_MAX_LENGTH;
		this.commentLabel = data.commentLabel;
	}

}
