import { FormStateMap } from '@app-store/reducers/forms.reducer';
import { _get } from '@core/lodash/lodash';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectFormsState = createFeatureSelector<FormStateMap>('forms');

export const selectFormState = (props: {key: string}) =>
	createSelector(selectFormsState, (state: FormStateMap) => state.get(props.key));

export const selectFormSubmitted = (props: {key: string}) =>
	createSelector(selectFormsState, (state: FormStateMap) => _get(state.get(props.key), ['submitted']));
