import { Component, ViewChild } from '@angular/core';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { ToasterService } from '@core/toaster/toaster.service';
import { WEBCAM_ERRORS } from '@shared/constants/webcam.constants';
import { ToastModel } from '@syncfusion/ej2-angular-notifications';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';

@Component({
	selector: 'pms-webcam-modal',
	templateUrl: './webcam-modal.component.html',
	styles: [],
})
export class WebcamModalComponent {

	@ViewChild('modal')
	modal: DialogComponent;

	trigger: Subject<void> = new Subject<void>();

	capturedImage: WebcamImage;

	constructor(
		public dynamicModalRef: DynamicModalRef,
		public modalConfig: ModalConfig,
		public toasterService: ToasterService,
	) {
	}

	triggerSnapshot() {
		this.trigger.next();
	}

	get triggerObservable(): Observable<void> {
		return this.trigger.asObservable();
	}

	handleInitError(error: WebcamInitError) {
		let toastModel: ToastModel;

		const foundError = WEBCAM_ERRORS.find(knownError => knownError.NAME === error.mediaStreamError.name);
		if (foundError) {
			toastModel = {};
			toastModel.title = foundError.TITLE;
			toastModel.content = foundError.CONTENT;
		}
		this.toasterService.showError(toastModel);

		this.closeModal();
	}

	captureImage(webcamImage: WebcamImage) {
		this.capturedImage = webcamImage;
	}

	/* istanbul ignore next: closeModal */
	closeModal(save = false) {
		this.dynamicModalRef.close(this.modal, save ? this.capturedImage : null);
	}
}
