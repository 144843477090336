import { Pipe, PipeTransform } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { Big } from 'big.js';

@Pipe({
	name: 'plusMinus',
})
export class PlusMinusPipe implements PipeTransform {
	transform(value: any, precision = 2): string {
		if (!isNaN(Number(value)) && !_isNil(value)) {
			const fixedValue = Big(value).toFixed(precision);
			if (value >= 0) {
				return `+${fixedValue}`;
			} else {
				return fixedValue.toString(); // negative places - automatically
			}
		}
		return '';
	}
}
