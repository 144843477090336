import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CategoryResponseForDropdown, CategoryService } from '@core/category/category.service';
import { DropdownFilter } from '@core/dropdown-filter/dropdown-filter.decorator';
import { EmployeeService } from '@core/employee/employee.service';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { ObservableGridAdapter } from '@core/grid/observable-grid-adapter';
import { _isNil, _some } from '@core/lodash/lodash';
import { FormattedQueryResults, QueryService } from '@core/query/query.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { SECURITY_CONSTANTS } from '@core/security/security.constants';
import { UrlService } from '@core/url-util/url.service';
import { CategoryEntityType, CustomReportsMasterCategory, QueryTemplateCategory } from '@gandalf/constants';
import { EmployeeWithSecurityRolesResponse } from '@gandalf/model/employee-with-security-roles-response';
import { QuerySummaryResponse } from '@gandalf/model/query-summary-response';
import { QueryBuilderComponent } from '@shared/component/query/query-builder/query-builder.component';
import { ImportQueriesComponent } from '@shared/component/query/query-dashboard/import-queries.component';
import { URL_PRINT_ENDPOINTS } from '@shared/constants/url.constants';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { MenuEventArgs, MenuItemModel } from '@syncfusion/ej2-angular-navigations';
import { DataManager } from '@syncfusion/ej2-data';
import { ColumnModel, PageSettingsModel, RowSelectingEventArgs, SortSettingsModel } from '@syncfusion/ej2-grids';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridOptions } from 'ag-grid-community';
import { DialogUtil, EnumUtil, GridUtil, ModalManagerService, PAGE_LENGTH, PAGE_LENGTH_LIST, TableToolItem } from 'morgana';
import { combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';

export enum NEW_REPORT_ACTION {
	ADD = 'ADD',
	IMPORT = 'IMPORT',
}

@Component({
	selector: 'pms-query-dashboard',
	templateUrl: './query-dashboard.component.html',
	styles: [],
})
export class QueryDashboardComponent implements OnInit {

	@Input()
	queryTemplateCategory: QueryTemplateCategory;

	@Input()
	categoryEntityType: CategoryEntityType;

	@Input()
	customReportsMasterCategory: CustomReportsMasterCategory;

	@Input()
	headerText: string;

	@Input()
	backButtonText: string;

	@ViewChild('queryGrid')
	queryGrid: GridComponent;

	@ViewChild('resultsGrid')
	resultsGrid: GridComponent;

	@DropdownFilter()
	shouldFilter: (options: any[]) => boolean;

	@ViewChild('queryBuilder')
	queryBuilder: QueryBuilderComponent;

	@ViewChild('agGrid')
	agGrid: AgGridAngular;

	@ViewChild('buttonColumn')
	buttonColumn: TemplateRef<any>;

	nameSearchInput = '';
	reportQueryBuilderTableFeatureFlagOn = false;
	toolbar: TableToolItem[];
	gridColumns: ColDef[];
	gridOptions: GridOptions = GridUtil.buildGridOptions({
		rowClass: 'row-link',
		suppressRowClickSelection: true,
	});
	isSearching = false;
	isRunningQuery = false;
	queries: QuerySummaryResponse[];
	categories: CategoryResponseForDropdown[];
	employee: EmployeeWithSecurityRolesResponse;
	selectedCategoryName;
	pageSettings: PageSettingsModel = {
		pageSizes: PAGE_LENGTH_LIST,
		pageSize: PAGE_LENGTH.PAGE_10,
	};
	sortSettings: SortSettingsModel = {
		columns: [
			{field: 'name', direction: 'Ascending'},
		],
	};
	newReportMenuItems: MenuItemModel[];
	isCustomReports: boolean;
	isPatientSearch: boolean;
	resultsGridMenuItems: TableToolItem[];
	allowCreate: boolean;
	allowEdit: boolean;
	allowDelete: boolean;
	allowRun: boolean;
	allowActions: boolean;
	searchResultsQuery: QuerySummaryResponse;
	resultsGridAdapter: ObservableGridAdapter<FormattedQueryResults>;

	constructor(
		private queryService: QueryService,
		private categoryService: CategoryService,
		public featureService: FeatureService,
		public modalManagerService: ModalManagerService,
		private urlService: UrlService,
		private securityManager: SecurityManagerService,
		private employeeService: EmployeeService,
	) {
	}

	ngOnInit() {
		this.reportQueryBuilderTableFeatureFlagOn = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.REPORTING.QUERY_BUILDER.AG_GRID);
		this.isCustomReports = EnumUtil.equals(this.queryTemplateCategory, QueryTemplateCategory.CUSTOM_REPORTS);
		this.isPatientSearch = EnumUtil.equals(this.queryTemplateCategory, QueryTemplateCategory.PATIENT_SEARCH);
		this.newReportMenuItems = [
			{
				text: 'NEW REPORT',
				separator: true,
			},
			{
				text: 'Add Report',
				id: NEW_REPORT_ACTION.ADD,
			},
			{
				text: 'Import Report',
				id: NEW_REPORT_ACTION.IMPORT,
			},
		];
		if (this.isCustomReports) {
			// remove 'Add Report' action for custom reports: they can only be imported.
			this.newReportMenuItems.splice(1, 1);
		}
		this.checkPermissions();
		this.loadData();
	}

	checkPermissions() {
		this.allowCreate = this.securityManager.hasPermission(SECURITY_CONSTANTS.RESOURCE_MARKETING_PATIENT_SEARCH_CREATE);
		this.allowEdit = this.securityManager.hasPermission(SECURITY_CONSTANTS.RESOURCE_MARKETING_PATIENT_SEARCH_EDIT);
		this.allowDelete = this.securityManager.hasPermission(SECURITY_CONSTANTS.RESOURCE_MARKETING_PATIENT_SEARCH_DELETE);
		this.allowRun = this.securityManager.hasPermission(SECURITY_CONSTANTS.RESOURCE_MARKETING_PATIENT_SEARCH_EXECUTE);
		// Only show the action button column if user can run a query (custom or normal) or for normal queries if user can create or delete.
		this.allowActions = this.allowRun || (!this.isCustomReports && (this.allowCreate || this.allowDelete));
	}

	loadData() {
		this.isSearching = true;
		combineLatest([
			this.queryService.findByTemplateCategoryAndCustomReportsMasterCategory(this.queryTemplateCategory, this.customReportsMasterCategory),
			this.categoryService.findActiveByEntityType(this.categoryEntityType),
			this.employeeService.getLoggedInEmployeeWithSecurityRoles(),
		]).subscribe(([queries, categories, employee]) => {
			this.isSearching = false;
			this.categories = categories;
			this.employee = employee;
			this.queries = queries;
			this.filterQueries();
		});
	}

	filterQueryGrid() {
		if (!_isNil(this.selectedCategoryName)) {
			this.queryGrid.filterByColumn('categoryName', 'equal', this.selectedCategoryName);
		} else {
			this.queryGrid.clearFiltering(['categoryName']);
		}
		this.queryGrid.goToPage(1);
	}

	newReport(selectedItem: MenuEventArgs) {
		this.resetResultsGrid();
		switch (selectedItem.item.id as NEW_REPORT_ACTION) {
			case NEW_REPORT_ACTION.ADD:
				this.queryBuilder.newQuery();
				break;
			case NEW_REPORT_ACTION.IMPORT:
				this.showImportQueries();
				break;
		}
	}

	editQuery(event: RowSelectingEventArgs) {
		// do not actually select the row: clicking on any column except the action buttons will open the query builder
		event.cancel = true;
		if (this.queryGrid.getRowInfo(event.target).column['field'] !== 'action') {
			this.resetResultsGrid();
			const query = event.data as QuerySummaryResponse;
			this.queryBuilder.editQuery(query.id);
		}
	}

	queryUpdated(updatedQuery: QuerySummaryResponse) {
		// update query if it is in the list already; otherwise add it.
		const index = this.queries.findIndex(query => query.id === updatedQuery.id);
		if (index >= 0) {
			this.queries[index] = updatedQuery;
		} else {
			this.queries.push(updatedQuery);
		}
		this.filterQueries();
		if (!this.reportQueryBuilderTableFeatureFlagOn) {
			this.queryGrid.refresh();
		}
	}

	/* istanbul ignore next */
	confirmDeleteQuery(query: QuerySummaryResponse) {
		this.resetResultsGrid();
		const dialog = DialogUtil.confirm({
			title: 'Delete Query',
			content: `Are you sure you want to delete the query named "${query.name}"?`,
			okButton: {
				click: () => {
					dialog.close();
					this.deleteQuery(query.id);
				},
			},
		});
	}

	deleteQuery(queryId: number) {
		this.queryService.deleteQueryById(queryId).subscribe(() => {
			const index = this.queries.findIndex(query => query.id === queryId);
			this.queries.splice(index, 1);
			if (this.reportQueryBuilderTableFeatureFlagOn) {
				this.filterQueries();
			} else {
				this.queryGrid.refresh();
			}
		});
	}

	showImportQueries() {
		this.modalManagerService.open(ImportQueriesComponent, {
			data: {
				queryTemplateCategory: this.queryTemplateCategory,
				customReportsMasterCategory: this.customReportsMasterCategory,
			},
		}).onClose.subscribe(importedQueries => importedQueries.forEach(query => this.queryUpdated(query)));
	}

	copyQuery(query: QuerySummaryResponse) {
		this.resetResultsGrid();
		this.queryBuilder.copyQuery(query.id);
	}

	printQuery(query: QuerySummaryResponse) {
		this.urlService.openTabWithPost(URL_PRINT_ENDPOINTS.QUERY_PRINT, {queryId: query.id});
	}

	exportQuery(query: QuerySummaryResponse) {
		this.urlService.openTabWithPost(URL_PRINT_ENDPOINTS.QUERY_EXPORT, {queryId: query.id});
	}

	printAddressLabels(query: QuerySummaryResponse) {
		this.urlService.openTabWithPost(URL_PRINT_ENDPOINTS.QUERY_ADDRESS_LABELS, {queryId: query.id});
	}

	runQuery(query: QuerySummaryResponse) {
		this.resetResultsGrid(query);
		// disable the query and results grid to prevent concurrent execution of queries.
		// cannot use pmsGridSubmit here because we do not want to go back to page 1
		this.queryGrid?.showSpinner();
		// we have to wait until after results grid is reset to show spinner: see resultsGridDataBound()
		this.isRunningQuery = true;
		this.queryService.runQuery(query.id, this.resultsGrid.pageSettings.pageSize, 0).subscribe(queryResults => {
			this.queryGrid?.hideSpinner();
			this.resultsGrid.hideSpinner();
			this.isRunningQuery = false;
			// null results indicates the query failed; do not initialize results grid in that case
			if (queryResults) {
				this.initResultsGrid(query, queryResults);
			}
		});
	}

	/* istanbul ignore next */
	resultsGridDataBound() {
		// wait for results grid to finish resetting before checking spinner
		setTimeout(() => this.checkResultsGridSpinner());
	}

	checkResultsGridSpinner() {
		// resetting the results grid will hide the spinner so if the initial query is still running we need to show it again
		if (this.isRunningQuery && _isNil(this.resultsGridAdapter)) {
			// cannot use pmsGridSubmit here due to a bug with dynamic columns and the goToPage function.
			this.resultsGrid.showSpinner();
		}
	}

	resetResultsGrid(query: QuerySummaryResponse = null) {
		if (!this.resultsGrid) {
			// results grid is hidden for custom reports or if the user is not allowed to run a query
			return;
		}
		this.searchResultsQuery = query;
		if (this.resultsGridAdapter) {
			// disable the previous adapter if it exists: this will prevent it from sending an additional request when we reset to the first page
			this.resultsGridAdapter.active = false;
		}
		this.resultsGridAdapter = null;
		// cannot use goToPage(1) because of a bug with dynamic columns.
		this.resultsGrid.pageSettings.currentPage = 1;
		this.resultsGrid.dataSource = [];
		// need to add one empty column to show "no records" message
		this.resultsGrid.columns = [{}];
	}

	initResultsGrid(query: QuerySummaryResponse, queryResults: FormattedQueryResults) {
		this.resultsGridMenuItems = [
			{
				text: 'Export',
				iconCss: 'fa fa-download',
				action: () => this.exportQuery(query),
				dataTestId: 'Export',
			},
			{
				text: 'Print',
				iconCss: 'fa fa-print',
				action: () => this.printQuery(query),
				dataTestId: 'Print',
			},
		];
		if (queryResults.allowAddressLabels) {
			this.resultsGridMenuItems.push({
				text: 'Address Labels',
				iconCss: 'fa fa-address-card',
				action: () => this.printAddressLabels(query),
				dataTestId: 'PrintLabels',
			});
		}
		// recreate the adapter and datasource; the initial results will be used for the first page
		this.resultsGridAdapter = new ObservableGridAdapter(queryResults, () => this.runQueryPage(query, queryResults.total));
		this.resultsGrid.dataSource = new DataManager({adaptor: this.resultsGridAdapter});
		// dynamic results grid columns determined by the initial results
		this.resultsGrid.columns = queryResults.fields.map(resultField => ({
			field: resultField.fieldId,
			headerText: resultField.label,
			width: resultField.width,
			clipMode: 'EllipsisWithTooltip',
		} as ColumnModel));
	}

	runQueryPage(query: QuerySummaryResponse, total) {
		const limit = this.resultsGrid.pageSettings.pageSize;
		// convert page to offset
		const offset = (this.resultsGrid.pageSettings.currentPage - 1) * limit;
		// disable the query grid to prevent concurrent execution of queries. results grid will already have spinner from page change.
		// cannot use pmsGridSubmit here because we do not want to go back to page 1.
		this.queryGrid?.showSpinner();
		this.isRunningQuery = true;
		return this.queryService.runQuery(query.id, limit, offset, total).pipe(
			tap(() => {
				this.queryGrid?.hideSpinner();
				this.isRunningQuery = false;
			}),
		);
	}

	canRunQuery(query: QuerySummaryResponse) {
		// user must have permission to run queries AND have at least one of the query access roles.
		return this.allowRun && this.employee.securityRoles.some(role => query.roleIds.includes(role.id));
	}

	reportAccessRole(query: QuerySummaryResponse) {
		return _some(this.employee.securityRoles, role => query.roleIds.includes(role.id));
	}

	filterQueries() {
		this.queries = this.queries.filter(query => this.reportAccessRole(query));
	}

	onGridReady() {
		this.buildColumns();
		this.agGrid.api.setGridOption('columnDefs', this.gridColumns);
		this.filterAgGridByCategory();
	}

	buildColumns() {
		this.gridColumns = [
			GridUtil.buildColumn('Category', 'categoryName', {
				minWidth: 125,
				sortable: true,
				hide: !this.categoryEntityType,
			}),
			GridUtil.buildColumn('Name', 'name', {
				minWidth: 125,
				sortable: true,
				sort: 'asc',
				comparator: (valueA, valueB) => valueA.toLowerCase().localeCompare(valueB.toLowerCase()),
			}),
			GridUtil.buildColumnWithEllipses('Description', 'description', {
				minWidth: 200,
				sortable: true,
			}),
		];

		if (this.allowActions) {
			this.gridColumns.push(GridUtil.buildButtonColumn('', this.buttonColumn, {
				width: 110,
			}));
		}
	}

	rowClicked(event) {
		if (!this.allowEdit) {
			return;
		}
		event.cancel = true;
		const query = event.data as QuerySummaryResponse;
		this.queryBuilder.editQuery(query.id);
	}

	searchNameFilter() {
		GridUtil.applyTextContainsFilter(this.agGrid, this.applyTextFilter, 'name', this.nameSearchInput);
	}

	applyTextFilter = () => !_isNil(this.nameSearchInput);

	filterAgGridByCategory() {
		GridUtil.applyFilter(this.agGrid, this.applyCategoryFilter, 'categoryName', this.selectedCategoryName);
	}

	applyCategoryFilter = () => !_isNil(this.selectedCategoryName);

	displayRunReportButton(query: QuerySummaryResponse) {
		return !this.isCustomReports && this.canRunQuery(query);
	}

	displayCopyButton() {
		return !this.isCustomReports && this.allowCreate;
	}

	displayDeleteButton() {
		return !this.isCustomReports && this.allowDelete;
	}

	displayPrintOrDownloadButton(query: QuerySummaryResponse) {
		return this.isCustomReports && this.canRunQuery(query);
	}

	displayAddressLabelButton(query: QuerySummaryResponse) {
		return this.isCustomReports && this.canRunQuery(query) && query.masterAllowAddressLabels;
	}
}
