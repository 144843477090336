// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeRequest } from './contact-lens-eye-request';

// @ts-ignore
import { CreateContactLensPrescriptionRequest } from './create-contact-lens-prescription-request';

/** See com.rev360.pms.api.controller.prescription.UpdateContactLensPrescriptionRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateContactLensPrescriptionRequest extends CreateContactLensPrescriptionRequest {

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Authorization Type is required' } })
	@GandalfProperty({ isRequired: true })
	authorizationType: constants.PrescriptionAuthorizationType;

	@GandalfValidator({ notNull: { message: 'Authorize Prescription indicator is required' } })
	@GandalfProperty({ isRequired: true })
	authorizePrescription: boolean;

	@GandalfProperty()
	authorizedByProviderId: number;

	@GandalfValidator({ notNull: { message: 'Contact Lens Prescription is required' } })
	@GandalfProperty({ isRequired: true })
	contactLensPrescriptionId: number;

	@GandalfProperty()
	deliveryMethodReferenceId: number;

	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	description: string;

	@GandalfValidator({ sizeString: { message: 'Prescribed Lens Drop cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	dropsName: string;

	@GandalfProperty()
	encounterId: number;

	@GandalfValidator({ sizeString: { message: 'Prescribed Enzymatic Cleaner cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	enzymaticCleanerName: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	expirationDate: Date;

	@GandalfValidator({ sizeString: { message: 'Prescribed Lens Cleaner cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	lensCleanerName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	modality: constants.ContactLensModality;

	@GandalfSubRequestLabel('OD')
	@GandalfProperty()
	od: ContactLensEyeRequest;

	@GandalfProperty()
	odDispenseAmount: number;

	@GandalfProperty()
	odDispenseUnitId: number;

	@GandalfSubRequestLabel('OS')
	@GandalfProperty()
	os: ContactLensEyeRequest;

	@GandalfProperty()
	osDispenseAmount: number;

	@GandalfProperty()
	osDispenseUnitId: number;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Lens Type is required' } })
	@GandalfProperty({ isRequired: true })
	overallLensType: constants.ContactLensPrescriptionType;

	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientId: number;

	@GandalfValidator({ sizeString: { message: 'Prescribed Disinfection Solution cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	solutionName: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate: Date;

	@GandalfValidator({ notNull: { message: 'Use Signature is required' } })
	@GandalfProperty({ isRequired: true })
	useSignature: boolean;

}
