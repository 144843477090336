// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FieldValueRequest } from './field-value-request';

/** See com.rev360.pms.api.controller.encounter.dynamic.WorkflowTestRequest */
/* istanbul ignore next */
@GandalfModel
export class WorkflowTestRequest extends GandalfModelBase {

	@GandalfProperty()
	comment: string;

	@GandalfProperty()
	fileCount: number;

	@GandalfProperty()
	hasTestValuesChanged: boolean;

	@GandalfArray(FieldValueRequest)
	values: FieldValueRequest[];

	@GandalfLabel('Version')
	@GandalfValidator({ notNull: { message: 'Version is required' } })
	@GandalfProperty({ isRequired: true })
	version: number;

	@GandalfProperty()
	workflowTestId: number;

}
