<ejs-dialog
		#modal
		(close)="closeModal()"
		cssClass="modal-lg"
		[attr.data-test-id]="'selectInsuranceModal'">
	<ng-template #header>
		<div class="dlg-template">Select Insurance</div>
	</ng-template>
	<ng-template #content>
		<div>
			<ejs-grid
					#grid
					autoFitColumns
					class="table-rowlink"
					gridLines="Horizontal"
					[dataSource]="insuranceList"
					[allowSorting]="true"
					[allowSelection]="true"
					[allowResizing]="true"
					(rowSelected)="closeModal($event)"
					[pmsGridSubmit]="isSearchingOrSubmitting"
					[pmsGridSubmitDefaultSortOptions]="sortOptions"
					[sortSettings]="sortOptions"
					[attr.data-test-id]="'selectInsuranceModalGrid'">
				<e-columns>
					<e-column field="priority.value" headerText="Priority"></e-column>
					<e-column field="type.value" headerText="Type"></e-column>
					<e-column field="companyName" headerText="Company Name"></e-column>
					<e-column field="policyNumber" headerText="Policy Number"></e-column>
					<e-column field="groupNumber" headerText="Group Number"></e-column>
					<e-column field="status.label" headerText="Status"></e-column>
				</e-columns>
			</ejs-grid>
		</div>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'selectInsuranceModalCancelButton'">Cancel</button>
	</ng-template>
</ejs-dialog>
