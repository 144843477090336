import { Injectable } from '@angular/core';
import { PostalCodeSearchRequest } from '@gandalf/model/postal-code-search-request';
import { ReferenceDataResponse } from '@gandalf/model/reference-data-response';
import { AddressGandalfService } from '@gandalf/services';
import { OptionItem } from 'morgana';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class AddressService {

	constructor(
		private addressGandalfService: AddressGandalfService,
	) {
	}

	/**
	 * Converts a single ReferenceDataResponse containing State/Province into a StateProvinceForDropdown
	 */
	static createStateProvinceForDropdown(stateProvince: ReferenceDataResponse): OptionItem {
		return {
			label: stateProvince.description,
			value: stateProvince.description,
		};
	}

	/**
	 * Finds all State/Province reference data for populating an address form
	 */
	findStateProvincesForDropdown() {
		return this.addressGandalfService.findStateProvinces().pipe(
			map(stateProvinces => stateProvinces.map(AddressService.createStateProvinceForDropdown)),
		);
	}

	/* istanbul ignore next: gandalf */
	findStateProvinces() {
		return this.addressGandalfService.findStateProvinces();
	}

	findStateProvinceByPostalCode(postalCode: string) {
		const request: PostalCodeSearchRequest = new PostalCodeSearchRequest();
		request.postalCode = postalCode;
		return this.addressGandalfService.findCityStateProvinceByPostalCode(request);
	}

	getStateProvinceByPracticeLocation(practiceLocationId: number) {
		return this.addressGandalfService.getStateProvinceByPracticeLocation(practiceLocationId);
	}

	/* istanbul ignore next: gandalf */
	getPracticeLocationAddress(practiceLocationId: number) {
		return this.addressGandalfService.getPracticeLocationAddress(practiceLocationId);
	}

	/* istanbul ignore next: gandalf */
	getPatientAddress(patientId: number) {
		return this.addressGandalfService.getPatientAddress(patientId);
	}
}
