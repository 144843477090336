export const FILTER_THRESHOLD = 8;

export function DropdownFilter(): (target: object, propertyKey: string) => void {
	return (target: object, propertyKey: string) => {
		const shouldFilter = (optionArray: any[]) => {
			if (optionArray) {
				return optionArray.length >= FILTER_THRESHOLD;
			}
			return false;
		};
		target[propertyKey] = shouldFilter;
	};
}
