import { PaginationState } from '@app-store/reducers/grid.reducer';
import { Action } from '@ngrx/store';
import { ColumnState } from 'ag-grid-community';

export enum GridActionTypes {
	UPDATE_GRID_COLUMNS_STATE = '[Update Grid Columns State] Updates the state of columns within a grid.',
	UPDATE_GRID_FILTER_STATE = '[Update Grid Filter State] Updates the state of the filter within a grid.',
	UPDATE_GRID_PAGINATION_STATE = '[Update Grid Pagination State] Updates the state of the pagination within a grid.',
	UPDATE_GRID_SELECTION_STATE = '[Update Grid Selection State] Updates the state of the selection within a grid.',
	UPDATE_GRID_HIGHLIGHTED_STATE = '[Update Grid Highlighted State] Updates the state of the highlighting within a grid.',
	REMOVE_STATE = '[Remove State] Removes the state of a grid.',
}

export class UpdateGridColumnsState implements Action {
	readonly type = GridActionTypes.UPDATE_GRID_COLUMNS_STATE;
	constructor(public payload: {key: string, columnsState: ColumnState[]}) {}
}

export class UpdateGridFilterState implements Action {
	readonly type = GridActionTypes.UPDATE_GRID_FILTER_STATE;
	constructor(public payload: {key: string, filterState: any}) {}
}

export class UpdateGridPaginationState implements Action {
	readonly type = GridActionTypes.UPDATE_GRID_PAGINATION_STATE;
	constructor(public payload: {key: string, paginationState: PaginationState}) {}
}

export class UpdateGridSelectionState implements Action {
	readonly type = GridActionTypes.UPDATE_GRID_SELECTION_STATE;
	constructor(public payload: {key: string, selectionState: any}) {}
}

export class UpdateGridRowHighlightedState implements Action {
	readonly type = GridActionTypes.UPDATE_GRID_HIGHLIGHTED_STATE;
	constructor(public payload: {key: string, highlightedRowIndex: number}) {}
}

export class RemoveGridState implements Action {
	readonly type = GridActionTypes.REMOVE_STATE;
	constructor(public payload: {key: string}) {}
}

export type GridActions =
	UpdateGridColumnsState
	| UpdateGridFilterState
	| UpdateGridPaginationState
	| UpdateGridSelectionState
	| UpdateGridRowHighlightedState
	| RemoveGridState;
