// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { MasterReferenceDataResponse } from './master-reference-data-response';

// @ts-ignore
import { PracticeLocationSummaryResponse } from './practice-location-summary-response';

/** See com.rev360.pms.api.controller.patient.immunization.PatientVaccinationListResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientVaccinationListResponse extends GandalfModelBase {

	@GandalfProperty()
	administeredAmount: number;

	@GandalfProperty()
	administeredAmountUnit: MasterReferenceDataResponse;

	@GandalfProperty()
	administeredAtLocation: PracticeLocationSummaryResponse;

	@GandalfProperty()
	administeredOn: Date;

	@GandalfProperty()
	comments: string;

	@GandalfProperty()
	cvxDetailCode: string;

	@GandalfProperty()
	cvxDetailShortName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.VaccinationStatus;

	@GandalfProperty()
	vaccinationId: number;

}
