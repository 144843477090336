import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpErrorService } from '@core/global-error-handler/http-error.service';
import { _isNil } from '@core/lodash/lodash';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DialogUtil } from 'morgana';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

	constructor(
		private httpErrorService: HttpErrorService,
	) {}

	/* istanbul ignore next */
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				const alertModelOptions = this.httpErrorService.getAlertModel(error);
				if (!_isNil(alertModelOptions)) {
					DialogUtil.alert(alertModelOptions);
				}
				// rethrow the error so that it may be handled at a more specific level
				return throwError(() => error);
			}),
		);
	}
}
