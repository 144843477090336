import { InjectFlags, Injector, NgModule, Type } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';

@NgModule()
export class InjectorContainerModule {
	static injector: Injector;

	constructor(injector: Injector) {
		InjectorContainerModule.injector = injector;
	}

	/* eslint-disable-next-line deprecation/deprecation */
	static getInjectable<T>(token: Type<T>, notFoundValue?: T, flags?: InjectFlags) {
		return _isNil(this.injector) ? null : this.injector.get(token, notFoundValue, flags);
	}
}
