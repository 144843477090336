import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { StatefulRouteService } from '@app-store/services/stateful-route.service';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { FEATURE_NAVIGATION_EVENT, HIT_PMS_HTML_EVENTS } from '@core/legacy/hit-pms-html.constants';
import { INTER_APP_CONSTANTS } from '@core/legacy/inter-app.constants';
import { LegacyNavigationService } from '@core/legacy/legacy-navigation.service';
import { _find, _isNil } from '@core/lodash/lodash';
import { ModalManagerService } from 'morgana';
import { NavigationService } from '@core/navigation/navigation.service';
import { QuickAddContactModalComponent } from '@shared/component/add-patient/quick-add-contact-modal/quick-add-contact-modal.component';
import { QuickAddFamilyMemberModalComponent } from '@shared/component/add-patient/quick-add-family-member-modal/quick-add-family-member-modal.component';
import { PatientViews, PatientViewsArray, PatientViewTabs } from '../../patient-views.constant';

@Injectable({
	providedIn: 'root',
})
export class PatientEventService {

	private isFeatureOn = false;

	constructor(
		private eventsManagerService: EventsManagerService,
		private ngZone: NgZone,
		private router: Router,
		private legacyNavigationService: LegacyNavigationService,
		private featureService: FeatureService,
		private navigationService: NavigationService,
		private modalManagerService: ModalManagerService,
	) {
	}

	subscribeToLegacyEvents() {
		this.isFeatureOn = this.featureService.isFeatureOn(FEATURE_FLAGS.MODULES.PATIENTS);

		if (this.isFeatureOn) {

			this.eventsManagerService.subscribe(
				HIT_PMS_HTML_EVENTS.PATIENTS.PATIENT_MODULE_OPEN_APPOINTMENT,
				(event) => this.navigationService.navigateToPatientAppointment(event.argument.patientId, event.argument.appointmentId),
			);

			this.eventsManagerService.subscribe(
				HIT_PMS_HTML_EVENTS.PATIENTS.PATIENT_MODULE_OPEN_ENCOUNTER_NO_HTML,
				(event) => this.ngZone.run(() => this.navigationService.navigateToEncounter(event.argument.encounterId, event.argument.patientId)),
			);

			this.eventsManagerService.subscribe(
				INTER_APP_CONSTANTS.REV360.PATIENTS.OPEN_POD,
				(event) => this.navigationService.navigateToPatient(event.argument.patientId),
			);

			this.eventsManagerService.subscribe(
				INTER_APP_CONSTANTS.REV360.PATIENTS.FAMILY_MEMBER_QUICK_ADD,
				(event) => this.navigateToFamilyMemberQuickAdd(event),
			);

			this.eventsManagerService.subscribe(
				INTER_APP_CONSTANTS.REV360.PATIENTS.CONTACT_QUICK_ADD,
				(event) => this.navigateToContactQuickAdd(event),
			);

			this.eventsManagerService.subscribe(
				INTER_APP_CONSTANTS.REV360.PATIENTS.ENCOUNTER_QUICK_ADD,
				(event) => this.navigateToEncounterQuickAdd(event),
			);

			this.eventsManagerService.subscribe(
				INTER_APP_CONSTANTS.REV360.PATIENTS.PATIENT_FLEX_COMPONENT_SELECT,
				() => this.navigateToLegacy(),
			);

			this.eventsManagerService.subscribe(
				INTER_APP_CONSTANTS.REV360.PATIENTS.PATIENTS_SEARCH_DASHBOARD_SELECTED,
				() => this.navigateToSearchDashboard(),
			);

			this.eventsManagerService.subscribe(
				INTER_APP_CONSTANTS.REV360.PATIENTS.EXPAND_POD,
				(event) => this.openPod(event),
			);

			this.eventsManagerService.subscribe(
				INTER_APP_CONSTANTS.REV360.PATIENTS.OPEN_PATIENT_ITEM,
				(event) => this.openItemPod(event),
			);

			this.eventsManagerService.subscribe(
				INTER_APP_CONSTANTS.REV360.PATIENTS.OPEN_APPOINTMENT,
				(event) => this.navigationService.navigateToPatientAppointment(event.argument.patientId, event.argument.appointmentId),
			);

			this.eventsManagerService.subscribe(
				INTER_APP_CONSTANTS.REV360.PATIENTS.PATIENT_COMPONENT_SELECT,
				(event) => this.selectPatientComponent(event),
			);

			this.eventsManagerService.subscribe(
				INTER_APP_CONSTANTS.REV360.PATIENTS.ADD_POD,
				(event) => this.openAndAddPod(event),
			);

			this.eventsManagerService.subscribe(HIT_PMS_HTML_EVENTS.PATIENTS.PATIENTS_MODULE_DISPLAY_COMMUNICATION_DOCUMENT, async (_event) => {
				if (this.featureService.isFeatureOff(FEATURE_FLAGS.FEATURES.PATIENTS.HEADER.COMMUNICATE)) {
					await this.ngZone.run(() => this.router.navigateByUrl('/patients'));
				}
			});

			this.eventsManagerService.subscribe(
				INTER_APP_CONSTANTS.REV360.PATIENTS.OPEN_ENCOUNTER,
				(event) => this.ngZone.run(() => this.navigationService.navigateToEncounter(event.argument.encounterId, event.argument.patientId)),
			);
		}

		this.eventsManagerService.subscribe(FEATURE_NAVIGATION_EVENT, async (event) => {
			if (event.argument.featureKey === FEATURE_FLAGS.FEATURES.PATIENTS.HEADER.COMMUNICATE) {
				await this.ngZone.run(async () => {
					await this.router.navigateByUrl(`/patients/patient/${event.argument.payload.patientId}`);
					setTimeout(() => {
						this.eventsManagerService.publish(INTER_APP_CONSTANTS.REV360.PATIENTS.OPEN_COMMUNICATION_MODAL, event.argument.payload);
					}, 300);
				});
			}
		});
	}

	async openAndAddPod(event) {
		await this.openPod(event);
		setTimeout(() => {
			this.addPod(event);
		}, 400);
	}

	addPod(event) {
		this.eventsManagerService.publish(INTER_APP_CONSTANTS.REV360.PATIENTS.COMPONENT_ADD, {
			patientId: event.argument.patientId,
			tab: event.argument.tab,
		});
	}

	async openItemPod(event) {
		await this.openPod(event);
		setTimeout(() => {
			this.eventsManagerService.publish(INTER_APP_CONSTANTS.REV360.PATIENTS.OPEN_POD_ITEM, {
				patientId: event.argument.patientId,
				tab: event.argument.tab,
				itemId: event.argument.itemId,
			});
		}, 400);
	}

	async openPod(event): Promise<void> {
		if (!(await this.openPodWithOptions(event))) {
			const tabView = this.findPatientTabByIndex(event.argument.tab.value);

			if (tabView) {
				await this.router.navigate(['patients', 'patient', event.argument.patientId, ...tabView.url.split('/')]);
			}
		}
	}

	async selectPatientComponent(event) {
		let selectedTab;

		switch (event.argument.component) {
			case 'diagnosisHistory':
				await this.navigationService.navigateToDiagnosisHistory(event.argument.payload.patientId, event.argument.payload.itemId);
				break;
			case 'insurance':
				await this.navigationService.navigateToInsurance(event.argument.payload.patientId, event.argument.payload.itemId);
				break;
			case 'prescriptions':
				selectedTab = Object.values(PatientViewTabs.RX).find(tab => tab.tabIndex === event.argument.payload.typeId);
				await this.navigationService.navigateToPrescription(selectedTab.tabIndex, event.argument.payload.patientId, event.argument.payload.itemId);
				break;
			case 'ordersMedical':
				await this.navigationService.navigateToOrdersMedical(event.argument.payload.patientId, event.argument.payload.itemId);
				break;
			case 'task':
				await this.navigationService.navigateToTask(event.argument.payload.patientId, event.argument.payload.itemId);
				break;
			case 'recall':
				await this.navigationService.navigateToRecall(event.argument.payload.patientId, event.argument.payload.itemId);
				break;
			case 'note':
				await this.navigationService.navigateToNote(event.argument.payload.patientId, event.argument.payload.itemId);
				break;
		}
	}

	private async openPodWithOptions(event): Promise<boolean> {
		if (_isNil(event.argument) || _isNil(event.argument.options)) {
			return false;
		}

		let selectedTab;

		switch (event.argument.tab.value) {
			case PatientViews.PRESCRIPTIONS.tabIndex:
				selectedTab = Object.values(PatientViewTabs.RX).find(tab => tab.tabIndex === event.argument.options.typeId);
				await this.navigationService.navigateToPrescription(selectedTab.tabIndex, event.argument.patientId);
				break;
		}

		return true;
	}

	private findPatientTabByIndex(tabIndex: number) {
		return _find(PatientViewsArray, {tabIndex});
	}

	navigateToFamilyMemberQuickAdd(event) {
		this.modalManagerService.open(QuickAddFamilyMemberModalComponent, {
			data: {
				patientId: event.argument.patientId,
			},
		});
	}

	navigateToContactQuickAdd(event) {
		this.modalManagerService.open(QuickAddContactModalComponent, {
			data: {patientId: event.argument.patientId},
		});
	}

	navigateToEncounterQuickAdd(event) {
		return this.ngZone.run(async () => {
			await this.navigateToLegacy();
			this.showLegacyEncounterQuickAdd(event);
		});
	}

	navigateToSearchDashboard() {
		const baseUrl = `/patients/search`;
		return this.ngZone.run(() => StatefulRouteService.routePastPersistedRoute(this.router, baseUrl));
	}

	navigateToPatientPod(event) {
		return this.ngZone.run(async () => {
			await this.navigateToLegacy();
			this.showLegacyPatientPod(event);
		});
	}

	navigateToLegacy() {
		return this.router.navigateByUrl('/legacy');
	}

	showLegacyEncounterQuickAdd(event) {
		this.legacyNavigationService.showLegacy('patient', ['encounterQuickAdd', event.argument.patientId]);
	}

	showLegacyContactQuickAdd(event) {
		this.legacyNavigationService.showLegacy('patient', ['contactQuickAdd', event.argument.patientId]);
	}

	showLegacyFamilyMemberQuickAdd(event) {
		this.legacyNavigationService.showLegacy('patient', ['familyMemberQuickAdd', event.argument.patientId]);
	}

	showLegacyPatientPod(event) {
		this.legacyNavigationService.showLegacy('patient', [
			'pod',
			event.argument.patientId,
			event.argument.podName,
			event.argument.subNav,
		]);
	}
}
