// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreateRelatedCategoryRequest } from './create-related-category-request';

// @ts-ignore
import { UpdateRelatedCategoryRequest } from './update-related-category-request';

/** See com.rev360.pms.api.controller.shared.category.AssignRelatedCategoriesRequest */
/* istanbul ignore next */
@GandalfModel
export class AssignRelatedCategoriesRequest extends GandalfModelBase {

	@GandalfArray(CreateRelatedCategoryRequest)
	createRelatedCategoryRequests: CreateRelatedCategoryRequest[];

	@GandalfLabel('Parent')
	@GandalfValidator({ notNull: { message: 'Parent is required' } })
	@GandalfProperty({ isRequired: true })
	parentId: number;

	@GandalfConstantDecorator()
	@GandalfLabel('Related Entity Type')
	@GandalfValidator({ notNull: { message: 'Related Entity Type is required' } })
	@GandalfProperty({ isRequired: true })
	relatedEntityType: constants.RelatedEntityType;

	@GandalfArray(UpdateRelatedCategoryRequest)
	updateRelatedCategoryRequests: UpdateRelatedCategoryRequest[];

}
