import { Component, Input } from '@angular/core';
import { ContactLensPrescriptionResponse } from '@gandalf/model/contact-lens-prescription-response';
import { ContactLensTrialResponse } from '@gandalf/model/contact-lens-trial-response';

@Component({
	selector: 'pms-contact-lens-type-soft',
	templateUrl: './contact-lens-type-soft.component.html',
})
export class ContactLensTypeSoftComponent {

	@Input()
	contactLensPrescription: ContactLensPrescriptionResponse | ContactLensTrialResponse;

	constructor() {
	}

}
