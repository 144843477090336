<div class="panel panel-default" [attr.data-test-id]="'queryDashboardPanel'">
	<div class="panel-heading">
		<h4 class="panel-title">{{headerText}}</h4>
		<ul class="nav-tools" *ngIf="allowCreate">
			<li>
				<a
					ejs-dropdownbutton
					revTooltip
					tooltipContent="Add"
					[items]="newReportMenuItems"
					iconCss="fa fa-plus"
					cssClass="e-success e-caret-hide"
					(select)="newReport($event)"
					[disabled]="isSearching || isRunningQuery"
					[attr.data-test-id]="'addNewReportButton'">
				</a>
			</li>
		</ul>
	</div>
	<div class="panel-body" [hidden]="queryBuilder.open">
		<ejs-grid #queryGrid
			*ngIf="!reportQueryBuilderTableFeatureFlagOn"
			autoFitColumns
			[autoFitBlacklist]="['description']"
			[staticColumns]="['action']"
			[autoFitTriggers]="['paging']"
			[pmsGridSubmit]="isSearching"
			[pmsGridSubmitDefaultSortOptions]="sortSettings"
			[dataSource]="queries"
			gridLines="Horizontal"
			[allowSorting]="true"
			[class.table-rowlink]="allowEdit"
			[allowSelection]="allowEdit"
			(rowSelecting)="editQuery($event)"
			[allowPaging]="true"
			[pageSettings]="pageSettings"
			[allowResizing]="true"
			[allowFiltering]="true"
			[filterSettings]="{showFilterBarStatus: false}"
				  [attr.data-test-id]="'queryDashboardPanelGrid'">
			<ng-template #toolbarTemplate let-data *ngIf="!!categoryEntityType">
				<div class="e-control e-toolbar e-lib e-keyboard">
					<div class="e-toolbar-items e-tbar-pos">
						<div class="e-toolbar-left">
							<div class="e-toolbar-item">
								<label>Category</label>
								<ejs-dropdownlist
									[dataSource]="categories"
									[fields]="{text: 'label', value: 'label'}"
									[allowFiltering]="shouldFilter(categories)"
									[(ngModel)]="selectedCategoryName"
									(ngModelChange)="filterQueryGrid()"
									[showClearButton]="true"
									placeholder="All Categories">
								</ejs-dropdownlist>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
			<e-columns>
				<e-column field="categoryName" headerText="Category" *ngIf="!!categoryEntityType"></e-column>
				<e-column field="name" headerText="Name"></e-column>
				<e-column field="description" headerText="Description" clipMode="EllipsisWithTooltip"></e-column>
				<e-column
					field="action"
					headerText=""
					[allowSorting]="false"
					[allowResizing]="false"
					[width]="120"
					clipMode="Clip"
					*ngIf="allowActions">
					<ng-template #template let-data>
						<ng-container *ngIf="!isCustomReports">
							<rev-button
									*ngIf="canRunQuery(data)"
									buttonIcon="file-text"
									buttonStyle="primary"
									buttonTooltip="Run Report"
									revEventStopPropagation
									(buttonClick)="runQuery(data)"
									dataTestId="queryListRunButton">
							</rev-button>
							<rev-button
									*ngIf="allowCreate"
									[revGridButton]="'copy'"
									(buttonClick)="copyQuery(data)"
									dataTestId="queryListCopyButton">
							</rev-button>
							<rev-button
									*ngIf="allowDelete"
									[revGridButton]="'delete'"
									(buttonClick)="confirmDeleteQuery(data)"
									dataTestId="queryListDeleteButton">
							</rev-button>
						</ng-container>
						<ng-container *ngIf="isCustomReports && canRunQuery(data)">
							<rev-button
									buttonIcon="download"
									buttonStyle="primary"
									buttonTooltip="Export"
									revEventStopPropagation
									(buttonClick)="exportQuery(data)"
									dataTestId="queryListExportButton">
							</rev-button>
							<rev-button
									[revGridButton]="'print'"
									(buttonClick)="printQuery(data)"
									dataTestId="queryListPrintButton">
							</rev-button>
							<rev-button
									*ngIf="data.masterAllowAddressLabels"
									buttonIcon="address-card"
									buttonStyle="primary"
									buttonTooltip="Address Labels"
									revEventStopPropagation
									(buttonClick)="printAddressLabels(data)"
									dataTestId="queryListLabelsButton">
							</rev-button>
						</ng-container>
					</ng-template>
				</e-column>
			</e-columns>
		</ejs-grid>
		<rev-ag-grid-container [agGrid]="agGrid" *ngIf="reportQueryBuilderTableFeatureFlagOn">
			<ng-container rev-table-action-menu-left>
				<div class="e-toolbar-item">
					<input class="e-input w150"
						   type="text"
						   id="filter"
						   placeholder="Filter by Name"
						   [revFocusInput]="true"
						   (input)="searchNameFilter()"
						   [(ngModel)]="nameSearchInput"
						   [attr.data-test-id]="'queryListSearchFilterInput'">
				</div>
				<div class="e-toolbar-item" *ngIf="isPatientSearch">
					<label>Category</label>
					<ejs-dropdownlist
							[dataSource]="categories"
							[fields]="{text: 'label', value: 'label'}"
							[allowFiltering]="shouldFilter(categories)"
							[(ngModel)]="selectedCategoryName"
							(ngModelChange)="filterAgGridByCategory()"
							[showClearButton]="true"
							placeholder="All Categories"
							[attr.data-test-id]="'queryDashboardCategoryDropdown'">
					</ejs-dropdownlist>
				</div>
			</ng-container>
			<ng-container rev-container-grid>
				<ag-grid-angular class="ag-theme-alpine"
								 pmsAgAutoFitColumns
								 #agGrid
								 (rowDataUpdated)="filterAgGridByCategory()"
								 [suppressCsvExport]="true"
								 [pmsAgGridSubmit]="isSearching"
								 [rowData]="queries"
								 [domLayout]="'autoHeight'"
								 [gridOptions]="gridOptions"
								 [pagination]="true"
								 (rowClicked)="rowClicked($event)"
								 (gridReady)="onGridReady()"
								 [attr.data-test-id]="'queryListTable'">
				</ag-grid-angular>
			</ng-container>
		</rev-ag-grid-container>
		<ng-container *ngIf="!isCustomReports && allowRun">
			<h4 class="section-title bg-default mrgn-btm mrgn-top">Report Results: {{searchResultsQuery?.name}}</h4>
			<ejs-grid #resultsGrid
				[allowPaging]="true"
				[pageSettings]="pageSettings"
				[allowResizing]="true"
				[allowSelection]="false"
				(dataBound)="resultsGridDataBound()"
				gridLines="Horizontal"
				[attr.data-test-id]="'queryResultsTable'">
				<ng-template #toolbarTemplate let-data>
					<rev-table-action-menu [table]="resultsGrid" [items]="resultsGridMenuItems"></rev-table-action-menu>
				</ng-template>
				<e-columns>
					<e-column></e-column>
				</e-columns>
			</ejs-grid>
		</ng-container>
	</div>
	<pms-query-builder #queryBuilder
		[queryTemplateCategory]="queryTemplateCategory"
		[categoryEntityType]="categoryEntityType"
		[categories]="categories"
		[backButtonText]="backButtonText"
		(queryUpdated)="queryUpdated($event)">
	</pms-query-builder>
</div>

<ng-template #buttonColumn let-data>
	<rev-button
			*ngIf="displayRunReportButton(data)"
			buttonIcon="file-text"
			buttonStyle="primary"
			buttonTooltip="Run Report"
			revEventStopPropagation
			(buttonClick)="runQuery(data)"
			dataTestId="queryListRunButton">
	</rev-button>
	<rev-button
			*ngIf="displayCopyButton()"
			[revGridButton]="'copy'"
			(buttonClick)="copyQuery(data)"
			dataTestId="queryListCopyButton">
	</rev-button>
	<rev-button
			*ngIf="displayDeleteButton()"
			[revGridButton]="'delete'"
			(buttonClick)="confirmDeleteQuery(data)"
			dataTestId="queryListDeleteButton">
	</rev-button>
	<rev-button
			*ngIf="displayPrintOrDownloadButton(data)"
			buttonIcon="download"
			buttonStyle="primary"
			buttonTooltip="Export"
			revEventStopPropagation
			(buttonClick)="exportQuery(data)"
			dataTestId="queryListExportButton">
	</rev-button>
	<rev-button
			*ngIf="displayPrintOrDownloadButton(data)"
			[revGridButton]="'print'"
			(buttonClick)="printQuery(data)"
			dataTestId="queryListPrintButton">
	</rev-button>
	<rev-button
			*ngIf="displayAddressLabelButton(data)"
			buttonIcon="address-card"
			buttonStyle="primary"
			buttonTooltip="Address Labels"
			revEventStopPropagation
			(buttonClick)="printAddressLabels(data)"
			dataTestId="queryListLabelsButton">
	</rev-button>
</ng-template>
