/* eslint-disable max-len */

import {
	RemoveFormValuePayload,
	UpdateFormModePayload,
	UpdateFormSubmittedPayload,
	UpdateFormValuePayload
} from '@app-store/utils/legacy-stateful-component-utils.service';
import { UpdateStatePropertyPayload } from '@app-store/utils/update-state-property-payload';
import { UpdateAppointmentDescriptionRequest } from '@gandalf/model/update-appointment-description-request';
import { Action } from '@ngrx/store';

export enum AppointmentDetailActionTypes {
	UPDATE_APPOINTMENT_DETAIL_FORM_MODE = '[Update Appointment Detail Form Mode] Updates the mode of the Appointment Detail form within the store.',
	UPDATE_APPOINTMENT_DETAIL_FORM_VALUE = '[Update Appointment Detail Form Value] Updates the value of the Appointment Detail form within the store.',
	UPDATE_APPOINTMENT_DETAIL_FORM_SUBMITTED = '[Update Appointment Detail Form Submitted Value] Updates the submitted property of the Appointment Detail Form within the store',
	REMOVE_APPOINTMENT_DETAIL_FORM_VALUE = '[Remove Appointment tDetail Form Value] Removes the value of the Appointment Detail form within the store.',
	UPDATE_APPOINTMENT_DETAIL_PROPERTY = '[Update Appointment Detail Property] Update Appointment Detail Property',
	REMOVE_APPOINTMENT_DETAIL = '[Remove Appointment Detail] Removes an entire Appointment Detail entry from the store.',
}

export class UpdateAppointmentDetailFormMode implements Action {
	readonly type = AppointmentDetailActionTypes.UPDATE_APPOINTMENT_DETAIL_FORM_MODE;
	constructor(public payload: {
		patientId: number;
		updateFormModePayload: UpdateFormModePayload;
	}) {}
}

export class UpdateAppointmentDetailFormValue implements Action {
	readonly type = AppointmentDetailActionTypes.UPDATE_APPOINTMENT_DETAIL_FORM_VALUE;
	constructor(public payload: {
		patientId: number;
		updateFormValuePayload: UpdateFormValuePayload<UpdateAppointmentDescriptionRequest>;
	}) {}
}

export class UpdateAppointmentDetailFormSubmitted implements Action {
	readonly type = AppointmentDetailActionTypes.UPDATE_APPOINTMENT_DETAIL_FORM_SUBMITTED;
	constructor(public payload: {
		patientId: number;
		updateFormSubmittedPayload: UpdateFormSubmittedPayload;
	}) {}
}

export class RemoveAppointmentDetailFormValue implements Action {
	readonly type = AppointmentDetailActionTypes.REMOVE_APPOINTMENT_DETAIL_FORM_VALUE;
	constructor(public payload: {
		patientId: number;
		removeFormValuePayload: RemoveFormValuePayload;
	}) {}
}

export class UpdateAppointmentDetailProperty implements Action {
	readonly type = AppointmentDetailActionTypes.UPDATE_APPOINTMENT_DETAIL_PROPERTY;
	constructor(public payload: {
		patientId: number;
		updateStatePropertyPayload: UpdateStatePropertyPayload;
	}) {}
}

export class RemoveAppointmentDetail implements Action {
	readonly type = AppointmentDetailActionTypes.REMOVE_APPOINTMENT_DETAIL;
	constructor(public payload: { patientId: number, appointmentId: number }) {}
}

export type AppointmentDetailActions =
	UpdateAppointmentDetailFormMode
	| UpdateAppointmentDetailFormValue
	| UpdateAppointmentDetailFormSubmitted
	| RemoveAppointmentDetailFormValue
	| UpdateAppointmentDetailProperty
	| RemoveAppointmentDetail;
