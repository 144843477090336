import { Component, OnInit, ViewChild } from '@angular/core';
import { FolderService } from '@core/folder/folder.service';
import { _isNil } from '@core/lodash/lodash';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { FileService } from '@core/file/file.service';
import { UrlService } from '@core/url-util/url.service';
import { PatientFileEntityType } from '@gandalf/constants';
import { FILE_UPLOAD_MAX_SIZE, SYNCFUSION_UPLOAD_SUCCESS_STATUS_CODE } from '@shared/constants/file-upload.constants';
import { URL_PATIENT_FILE_ENDPOINTS } from '@shared/constants/url.constants';
import { TreeNode } from '@shared/interfaces/treeview';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { FieldsSettingsModel } from '@syncfusion/ej2-navigations/src/treeview/treeview-model';
import { SelectedEventArgs } from '@syncfusion/ej2-inputs/src/uploader/uploader';

@Component({
	selector: 'pms-file-upload-modal',
	templateUrl: './file-upload-modal.component.html',
	styles: [],
})
export class FileUploadModalComponent implements OnInit {
	@ViewChild('modal')
	modal: DialogComponent;
	@ViewChild('uploader')
	uploadObj: UploaderComponent;

	buttons = {
		browse: 'Browse...',
		clear: document.createElement('div'),
		upload: document.createElement('div'),
	};

	maxFileUploadSize = FILE_UPLOAD_MAX_SIZE;
	folderList: TreeNode[] = [];
	selectedFolderId: string;
	treeFields: FieldsSettingsModel;
	parentId: number;
	patientId: number;
	entityType: PatientFileEntityType;
	encounterId: number;
	errorMessage: string;

	constructor(
		private folderService: FolderService,
		private modalConfig: ModalConfig,
		private ref: DynamicModalRef,
		private urlService: UrlService,
	) {
		this.patientId = this.modalConfig.data.patientId;
		this.parentId = this.modalConfig.data.parentId;
		this.entityType = this.modalConfig.data.entityType;
		this.encounterId = this.modalConfig.data.encounterId;
	}

	ngOnInit() {
		this.folderService.getPatientFolders().subscribe(folders => {
			this.setupFolderTree(folders);
		});
	}

	setupFolderTree(folders) {
		this.folderList = folders;
		this.folderList[0].expanded = true;
		this.folderList[0].selected = true;
		this.treeFields = {
			id: 'data',
			text: 'label',
			tooltip: 'label',
			child: 'children',
			expanded: 'expanded',
			selected: 'selected',
			dataSource: this.folderList,
		};
		this.selectedFolderId = this.folderList[0].data as string;
	}

	getUrl() {
		let urlParams = '?' + this.urlService.getStrutsParameters(new Date());
		urlParams += `&patientId=${this.patientId.toString()}`;
		urlParams += `&entityId=${this.entityType.value}`;
		urlParams += `&parentId=${this.parentId.toString()}`;
		if (!_isNil(this.encounterId)) {
			urlParams += `&encounterId=${this.encounterId.toString()}`;
		}
		urlParams += `&folderId=${this.selectedFolderId}`;
		urlParams += `&outputPatientFileIds=${true}`;
		return URL_PATIENT_FILE_ENDPOINTS.UPLOAD + urlParams;
	}

	get settings() {
		return {
			saveUrl: this.getUrl(),
		};
	}

	filesSelected(event: SelectedEventArgs) {
		const errors: string[] = [];
		event.filesData?.forEach(file => {
			if (FileService.fileExtensionIsBlacklisted(file.name)) {
				errors.push(file.name);
				event.cancel = true;
			}
		});
		if (errors.length > 0) {
			this.errorMessage = `No files were uploaded. Invalid file type(s) selected: ${errors.join(', ')}`;
		} else {
			this.errorMessage = undefined;
		}
	}

	uploadSuccess(event, uploader) {
		const allFilesUploaded = !uploader.filesData.find(file => file.statusCode !== SYNCFUSION_UPLOAD_SUCCESS_STATUS_CODE);
		if (allFilesUploaded) {
			this.closeModal(true);
		}
	}

	/* istanbul ignore next: closeModal */
	closeModal(result = false) {
		this.ref.close(this.modal, result);
	}

	selectValue(event) {
		this.selectedFolderId = event.nodeData.id;
	}

	upload() {
		if (!_isNil(this.selectedFolderId) && this.uploadEnabled()) {
			this.uploadObj.upload();
		}
	}

	uploadEnabled() {
		return this.uploadObj?.getFilesData()?.length > 0;
	}

}

