// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { OrderDashboardSortRequest } from './order-dashboard-sort-request';

/** See com.rev360.pms.api.controller.order.OrdersAdvancedSearchRequest */
/* istanbul ignore next */
@GandalfModel
export class OrdersAdvancedSearchRequest extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	dueDateEnd: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dueDateStart: Date;

	@GandalfValidator({ notNull: { message: 'Limit is required' } })
	@GandalfValidator({ min: { min: 1, message: 'Limit must be greater than 0' } })
	@GandalfProperty({ isRequired: true })
	limit: number;

	@GandalfProperty()
	locationId: number;

	@GandalfProperty()
	masterOrderProcessorId: number;

	@GandalfValidator({ notNull: { message: 'Offset is required' } })
	@GandalfValidator({ min: { min: 0, message: 'Offset must be greater than or equal to 0' } })
	@GandalfProperty({ isRequired: true })
	offset: number;

	@GandalfProperty()
	onHold: boolean;

	@GandalfProperty({ propertyType: 'LocalDate' })
	orderDateEnd: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	orderDateStart: Date;

	@GandalfValidator({ min: { min: 1, message: 'Order # may not be less than 1' } })
	@GandalfValidator({ max: { max: 9223372036854775807, message: 'Order # may not be more than 19 digits' } })
	@GandalfProperty()
	orderId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	orderSearchStatusCode: constants.OrderSearchStatusCode;

	@GandalfConstantDecorator()
	@GandalfProperty()
	orderSearchType: constants.OrderSearchType;

	@GandalfProperty()
	patientId: number;

	@GandalfProperty()
	patientNotNotified: boolean;

	@GandalfArray(OrderDashboardSortRequest)
	sort: OrderDashboardSortRequest[];

	@GandalfProperty()
	total: number;

	@GandalfProperty()
	vendorId: number;

	@GandalfValidator({ sizeString: { fieldLabel: 'Vendor Order #', maxLength: 1000, minLength: 0, message: 'Vendor Order # must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	vendorOrderId: string;

}
