import { InvoicePayment, PaymentItemPermissions, ReceivePaymentsService } from '@accounting/invoices/receive-payments/receive-payments.service';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { PaymentComponentService } from '@core/accounting/payment/payment-component.service';
import { GridUtil, GridUtilService, ModalManagerService } from 'morgana';
import { UserLocationsService } from '@core/user-locations/user-locations.service';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';

@Component({
	selector: 'pms-read-only-payment',
	templateUrl: './read-only-payment.component.html',
})
export class ReadOnlyPaymentComponent implements OnInit {

	@Output()
	openInvoiceDetail: EventEmitter<number> = new EventEmitter();

	@ViewChild('invoicePaymentsGrid')
	invoicePaymentsGrid: AgGridAngular;

	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	invoicePayments: InvoicePayment[];
	locationName: string;
	invoicePaymentsGridOptions: GridOptions;
	paymentItemPermissions: PaymentItemPermissions;

	constructor(
		private receivePaymentsService: ReceivePaymentsService,
		private userLocationService: UserLocationsService,
		private modalManagerService: ModalManagerService,
		public paymentComponentService: PaymentComponentService,
		private gridUtilService: GridUtilService,
	) {
	}

	ngOnInit() {
		this.initData();
		this.initGrid();
	}

	initData() {
		this.locationName = this.userLocationService.getUserLocations().find(location => location.value === this.paymentGroup.paymentLocationId).label;
		this.paymentItemPermissions = this.receivePaymentsService.getPaymentItemPermissions(this.paymentGroup.payments);
		this.invoicePayments = this.paymentGroup.payments.map(payment => this.receivePaymentsService.buildExistingInvoicePayment(payment, false));
	}

	/* istanbul ignore next */
	initGrid() {
		this.invoicePaymentsGridOptions = GridUtil.buildGridOptions({
			pagination: false,
			domLayout: 'normal',
			suppressCellFocus: true,
			suppressRowClickSelection: true,
			rowBuffer: 0,
			columnDefs: [
				GridUtil.buildHyperlinkColumn('#', 'invoiceId', data => this.openInvoiceDetail.emit(data.invoiceId)),
				this.gridUtilService.buildDateColumn('Invoice Date', 'invoiceDate', DATE_FORMATS.MM_DD_YYYY, {width: 115}),
				this.gridUtilService.buildDateColumn('Service Date', 'serviceDate', DATE_FORMATS.MM_DD_YYYY, {width: 118}),
				GridUtil.buildColumn('Patient Name', 'patientName', {tooltipField: 'patientName', minWidth: 150, flex: 1}),
				this.gridUtilService.buildCurrencyColumn('Total', 'total', {width: 80}),
				this.gridUtilService.buildCurrencyColumn('Payment', 'paymentAmount', {width: 95}),
				this.gridUtilService.buildHyperlinkCurrencyColumn('Items', 'itemsTotal', data => this.openTransferModal(data), data => !data.isAggregateRow, {
					width: 85,
					hide: !this.paymentItemPermissions.allowPayByItem,
				}),
				this.gridUtilService.buildHyperlinkCurrencyColumn('Transfer', 'transferTotal', data => this.openTransferModal(data), data => !data.isAggregateRow, {width: 90}),
			],
		});
	}

	get paymentGroup() {
		return this.paymentComponentService.paymentGroup;
	}

	openTransferModal(invoicePayment: InvoicePayment) {
		this.receivePaymentsService.openTransferModal(this.modalManagerService, invoicePayment, [], true, this.paymentItemPermissions);
	}

	updateAggregateRow() {
		GridUtil.setAgGridAggregateRow(this.invoicePaymentsGrid, [{
			isAggregateRow: true,
			paymentAmount: Number(GridUtil.sumCurrencyItems(this.invoicePayments, 'paymentAmount')),
			itemsTotal: Number(GridUtil.sumCurrencyItems(this.invoicePayments, 'itemsTotal')),
			transferTotal: Number(GridUtil.sumCurrencyItems(this.invoicePayments, 'transferTotal')),
		}]);
	}
}
