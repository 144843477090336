import { Component, Input } from '@angular/core';
import { EnumUtil, OptionItem } from 'morgana';
import { ReferenceDataService } from '@core/reference-data/reference-data.service';
import { PrescriptionAuthorizationType, ReferenceDataMasterCategory } from '@gandalf/constants';
import { ContactLensPrescriptionResponse } from '@gandalf/model/contact-lens-prescription-response';
import { UpdateContactLensPrescriptionDeliveryMethodRequest } from '@gandalf/model/update-contact-lens-prescription-delivery-method-request';
import { ContactLensPrescriptionDetailsService } from '@shared/component/contact-lens-prescription-details/contact-lens-prescription-details.service';

@Component({
	selector: 'pms-contact-lens-electronic-delivery-method',
	templateUrl: './contact-lens-electronic-delivery-method.component.html',
	styles: [],
})
export class ContactLensElectronicDeliveryMethodComponent {

	@Input()
	set contactLensPrescription(contactLensPrescription: ContactLensPrescriptionResponse) {
		this._contactLensPrescription = contactLensPrescription;
		this.deliveryMethodReferenceId = this._contactLensPrescription.deliveryMethod?.id;
	}

	get contactLensPrescription(): ContactLensPrescriptionResponse {
		return this._contactLensPrescription;
	}

	private _contactLensPrescription: ContactLensPrescriptionResponse;

	editMode = false;

	deliveryMethodReferenceId: number;

	deliveryMethodOptions: OptionItem[];

	private serverCallInProgress = false;

	constructor(
		private referenceDataService: ReferenceDataService,
		private contactLensPrescriptionDetailsService: ContactLensPrescriptionDetailsService,
	) {
	}

	editModeOn() {
		if (!this.deliveryMethodOptions) {
			if (this.serverCallInProgress) {
				return;
			}
			this.serverCallInProgress = true;
			this.referenceDataService.getActiveReferenceDataByCategoryIdForDropdown(ReferenceDataMasterCategory.CL_ELECTRONIC_DELIVERY_METHODS.value)
				.subscribe(deliveryMethods => {
					this.deliveryMethodOptions = deliveryMethods;
					this.editMode = true;
					this.serverCallInProgress = false;
				});
		} else {
			this.editMode = true;
		}
	}

	cancel() {
		this.deliveryMethodReferenceId = this._contactLensPrescription.deliveryMethod?.id;
		this.editMode = false;
	}

	save() {
		if (this.serverCallInProgress) {
			return;
		}
		this.serverCallInProgress = true;
		const request = new UpdateContactLensPrescriptionDeliveryMethodRequest();
		request.contactLensPrescriptionId = this._contactLensPrescription.id;
		request.deliveryMethodReferenceId = this.deliveryMethodReferenceId;
		this.contactLensPrescriptionDetailsService.updateContactLensPrescriptionDeliveryMethod(request).subscribe((data) => {
			this.contactLensPrescription = data;
			this.editMode = false;
			this.serverCallInProgress = false;
		});
	}

	isExternalAuth() {
		return EnumUtil.equals(this._contactLensPrescription.authorizationType, PrescriptionAuthorizationType.EXTERNAL_PROVIDER);
	}

}
