import { Pipe, PipeTransform } from '@angular/core';
import { ArrayUtil } from '@core/array-util/array-util';

@Pipe({name: 'oxfordComma'})
export class OxfordCommaPipe implements PipeTransform {

	transform(value: string[]): string {
		return ArrayUtil.joinWithOxfordComma(...value);
	}

}
