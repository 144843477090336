/* eslint-disable max-len */
import { UpdateStatePropertyPayload } from '@app-store/utils/update-state-property-payload';
import { Action } from '@ngrx/store';

export enum PaymentActionTypes {
	UPDATE_PAYMENT_DASHBOARD_VALUE = '[Update PAYMENT DASHBOARD State] Updates the value of the PAYMENT DASHBOARD',
	REMOVE_PAYMENT_DASHBOARD = '[Remove PAYMENT DASHBOARD Table State] Removes the value of the PAYMENT DASHBOARD within the store.',

}

export class UpdatePaymentDashboardValue implements Action {
	readonly type = PaymentActionTypes.UPDATE_PAYMENT_DASHBOARD_VALUE;
	constructor(public payload: UpdateStatePropertyPayload) {}
}

export class RemovePaymentDashboardState implements Action {
	readonly type = PaymentActionTypes.REMOVE_PAYMENT_DASHBOARD;

	constructor() {
	}
}

export type PaymentActions =
	UpdatePaymentDashboardValue
	| RemovePaymentDashboardState;
