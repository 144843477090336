import { Component, OnInit, ViewChild } from '@angular/core';
import { EmployeeService, EmployeeWithRolesDropdownResponse } from '@core/employee/employee.service';
import { DynamicModalRef, EnumUtil, ModalConfig } from 'morgana';
import { _findIndex, _isNil, _map } from '@core/lodash/lodash';
import { SecurityRoleDropdownResponse, SecurityRoleService } from '@core/security/security-role.service';
import { CustomFilterScheduleItemStatus, ScheduleItemType } from '@gandalf/constants';
import { ScheduleFilterRequest } from '@gandalf/model/schedule-filter-request';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { combineLatest } from 'rxjs';

@Component({
	selector: 'pms-schedule-filter-modal',
	templateUrl: './schedule-filter-modal.component.html',
	styles: [],
})
export class ScheduleFilterModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('employeeListBox')
	employeeListBox;

	scheduleFilters: ScheduleFilterRequest;
	statuses = [
		CustomFilterScheduleItemStatus.ACTIVE,
		CustomFilterScheduleItemStatus.IN_PROGRESS,
		CustomFilterScheduleItemStatus.COMPLETED,
		CustomFilterScheduleItemStatus.CANCELED,
		CustomFilterScheduleItemStatus.PATIENT_CANCELED,
		CustomFilterScheduleItemStatus.RESCHEDULED,
		CustomFilterScheduleItemStatus.NO_SHOW,
		CustomFilterScheduleItemStatus.AVAILABLE_SLOTS,
		CustomFilterScheduleItemStatus.UNAVAILABLE_SLOTS,
	];

	itemTypes = [
		ScheduleItemType.APPOINTMENT,
		ScheduleItemType.BUSY,
		ScheduleItemType.OFFICE_CLOSED,
		ScheduleItemType.EMPLOYEE_HOURS,
		ScheduleItemType.APPOINTMENT_SLOT,
	];

	securityRoles: SecurityRoleDropdownResponse[];
	employees: EmployeeWithRolesDropdownResponse[];

	selectedPersonIds: number[];
	selectedSecurityRoleIds: number[];
	selectedItemTypes: number[];
	selectedStatuses: number[];
	initialized = false;

	constructor(
		public dynamicModalRef: DynamicModalRef,
		public securityRoleService: SecurityRoleService,
		public employeeService: EmployeeService,
		private modalConfig: ModalConfig,
	) {
	}

	ngOnInit() {
		this.parseModalConfig(this.modalConfig.data);
		combineLatest([
			this.securityRoleService.findSecurityRolesForDropdown(),
			this.employeeService.findActiveEmployeesWithSecurityRolesByPractice(),
		]).subscribe(([securityRoles, employees]) => {
			this.setInitialData(securityRoles, employees);
		});
	}

	setInitialData(securityRoles: SecurityRoleDropdownResponse[], employees: EmployeeWithRolesDropdownResponse[]) {
		this.securityRoles = securityRoles;
		this.sortEmployees(employees);
		this.selectedPersonIds = this.scheduleFilters.personIds.filter(personId => this.employees.find(employee => employee.personId === personId));
		this.selectedSecurityRoleIds = this.scheduleFilters.roleIds.filter(roleId => this.securityRoles.find(role => role.value === roleId));
		this.selectedItemTypes = this.scheduleFilters.itemTypes.filter(itemType => this.itemTypes.find(
			knownType => EnumUtil.equals(knownType, itemType)),
		).map(itemType => itemType.value);
		this.selectedStatuses = this.scheduleFilters.statuses.filter(status => this.statuses.find(
			knownStatus => EnumUtil.equals(knownStatus, status)),
		).map(status => status.value);
		this.initialized = true;
	}

	private sortEmployees(employees: EmployeeWithRolesDropdownResponse[]) {
		this.employees = [];
		// for each sortPersonId try to find a matching employee from returned list
		this.scheduleFilters.sortPersonIds.forEach(sortPersonId => {
			const foundIndex = _findIndex(employees, employee => employee.personId === sortPersonId);
			// if employee was found add to component's employee array and remove from the returned array
			if (foundIndex !== -1) {
				this.employees.push(employees[foundIndex]);
				employees.splice(foundIndex, 1);
			}
		});
		// employees that were not matched will display at beginning of the list
		this.employees.unshift(...employees);
	}

	parseModalConfig(data) {
		this.scheduleFilters = data.scheduleFilters;
	}

	handleEmployeeSecurityRoleSelect(event) {
		this.selectedPersonIds = [];
		this.employees.forEach(employee => {
			if (!_isNil(employee.securityRoles.find(role => role.id === event.itemData.id))) {
				this.selectedPersonIds.push(employee.personId);
			}
		});
	}

	save() {
		const request = new ScheduleFilterRequest();
		request.personIds = this.selectedPersonIds;
		request.roleIds = this.selectedSecurityRoleIds;
		request.itemTypes = EnumUtil.mapValuesToEnum(this.selectedItemTypes, ScheduleItemType);
		request.statuses = EnumUtil.mapValuesToEnum(this.selectedStatuses, CustomFilterScheduleItemStatus);
		request.sortPersonIds = _map(this.employeeListBox.listData, 'personId');
		this.closeModal(request);
	}

	/* istanbul ignore next: closeModal */
	closeModal(request?) {
		this.dynamicModalRef.close(this.modal, request);
	}
}
