import { ITableState } from '@app-store/reducers';
import { InvoiceActions, InvoiceActionTypes } from '@invoices-store/actions/invoice.actions';

export type InvoiceDocumentsAndImagesTableStateMap = Map<number, ITableState>;

export const initialState: InvoiceDocumentsAndImagesTableStateMap = new Map();

export function reducer(state = initialState, action: InvoiceActions): InvoiceDocumentsAndImagesTableStateMap {
	switch (action.type) {

		case InvoiceActionTypes.UPDATE_INVOICE_DOCUMENTS_AND_IMAGES_TABLE_VALUE: {
			const invoiceTableStateMap = new Map(state);
			const invoiceTableState = invoiceTableStateMap.get(action.payload.invoiceId) || {};
			invoiceTableStateMap.set(action.payload.invoiceId, {
				...invoiceTableState,
				tableConfigurationJson: action.payload.value,
			});
			return invoiceTableStateMap;
		}

		case InvoiceActionTypes.REMOVE_INVOICE_DOCUMENTS_AND_IMAGES_TABLE_STATE: {
			const invoiceStateMap = new Map(state);
			invoiceStateMap.delete(action.payload.invoiceId);
			return invoiceStateMap;
		}

		default: {
			return state;
		}
	}
}
