<div class="col-xs-6 col-sm-4 col-lg-3">
	<div class="form-group margin-bottom-0" [attr.data-test-id]="'invoiceServiceDateFormGroup'">
		<label class="col-sm-4 control-label">Service Date</label>
		<div class="col-sm-8">
			<p class="form-control-static">
				@if (displayServiceDateLink()) {
					<a revTooltip [tooltipContent]="serviceDateTooltipContent" [attr.data-test-id]="'invoiceNavigateServiceDateButton'"
					   (click)="navigateToEncounterOrAppointment()">{{ invoice.serviceDate | date: dateFormat }}</a>
				}
				@if (!displayServiceDateLink()) {
					<span>{{ invoice.serviceDate | date: dateFormat }}</span>
				}
				@if (canChangeEncounter()) {
					<a (click)="openEncounterOrAppointmentSelectModal()" class="margin-left-xs" revTooltip tooltipContent="Edit"
					   [attr.data-test-id]="'invoiceEditServiceDateButton'"><i class="fa fa-pencil"></i></a>
				}
				@if (canDisassociateEncounter()) {
					<a (click)="openDisassociateModal(true)" class="margin-left-xs" revTooltip tooltipContent="Remove"
					   [attr.data-test-id]="'invoiceRemoveServiceDateButton'">
						<i class="fa fa-close"></i>
					</a>
				}
				@if (canDisassociateAppointment()) {
					<a (click)="openDisassociateModal(false)" class="margin-left-xs" revTooltip tooltipContent="Remove">
						<i class="fa fa-close"></i>
					</a>
				}
			</p>
		</div>
	</div>
	@if (isInvoicePayerType(payerType.PATIENT)) {
		<div class="form-group margin-bottom-0" [attr.data-test-id]="'financeChargePlanFormGroup'">
			<label class="col-sm-4 control-label">Finance Charge Plan</label>
			<div class="col-sm-8">
				<p class="form-control-static">
					@if (financeChargePlan) {
						<span>{{ financeChargePlan.name }}</span>
					}
					@if (!financeChargePlan) {
						<span>None</span>
					}
					@if (canChangeFinanceChargePlan()) {
						<a (click)="openFinanceChargePlanSelectModal()" class="margin-left-xs" revTooltip tooltipContent="Edit"
						   [attr.data-test-id]="'invoiceEditFinanceChargePlanButton'"><i class="fa fa-pencil"></i></a>
					}
				</p>
			</div>
		</div>
	}
	@if (isInvoicePayerType(payerType.INSURANCE)) {
		<div class="form-group margin-bottom-0" [attr.data-test-id]="'feeScheduleNameFormGroup'">
			<label class="col-sm-4 control-label">Fee Schedule</label>
			<div class="col-sm-8">
				<p class="form-control-static">
					@if (invoice.feeScheduleName) {
						<span>{{ invoice.feeScheduleName }}</span>
					}
					@if (!invoice.feeScheduleName) {
						<span>None</span>
					}
				</p>
			</div>
		</div>
	}
	@if (isInvoicePayerType(payerType.INSURANCE)) {
		<div class="form-group margin-bottom-0" [attr.data-test-id]="'feeScheduleDateFormGroup'">
			<label class="col-sm-4 control-label">Fee Date</label>
			<div class="col-sm-8">
				<p class="form-control-static">
					@if (invoice.feeScheduleDate) {
						<span>{{ invoice.feeScheduleDate | date: dateFormat }}</span>
					}
					@if (!invoice.feeScheduleDate) {
						<span>None</span>
					}
				</p>
			</div>
		</div>
	}
</div>
<div class="col-xs-6 col-sm-4 col-lg-3">
	@if (invoice.firstStatementPrintDate) {
		<div class="form-group margin-bottom-0" [attr.data-test-id]="'firstStatementPrintDateFormGroup'">
			<label class="col-sm-4 control-label">First Stmt Print Date</label>
			<div class="col-sm-8">
				<p class="form-control-static">{{ invoice.firstStatementPrintDate | date: dateFormat }}</p>
			</div>
		</div>
	}
	@if (invoice.statementPrintDate) {
		<div class="form-group margin-bottom-0" [attr.data-test-id]="'lastStatementPrintDateFormGroup'">
			<label class="col-sm-4 control-label">Last Stmt Print Date</label>
			<div class="col-sm-8">
				<p class="form-control-static">{{ invoice.statementPrintDate | date: dateFormat }}</p>
			</div>
		</div>
	}
</div>
@if (patientCredit) {
	<div class="col-xs-12 col-sm-8 col-lg-3 text-right" [attr.data-test-id]="'availableCreditSection'">
		@if (isInvoicePayerType(payerType.PATIENT)) {
			<div class="alert alert-success display-inline-block text-nowrap margin-bottom-0 padding-sm">
				{{ patientCredit | currency }} Available Credit
			</div>
		}
	</div>
}
