<form class="form-horizontal" [formGroup]="componentForm">
	<div class="form-group">
		<label class="col-sm-4 col-md-3 control-label required">First Name</label>
		<div class="col-sm-8 col-md-9">
			<input class="form-control" type="text" class="e-input" formControlName="firstName" pmsCapitalizeFirstLetter/>
		</div>
	</div>
	<div class="form-group">
		<label class="col-sm-4 col-md-3 control-label required">Last Name</label>
		<div class="col-sm-8 col-md-9">
			<input class="form-control" type="text" class="e-input" formControlName="lastName" pmsCapitalizeFirstLetter/>
		</div>
	</div>
	<div class="form-group">
		<label class="col-sm-4 col-md-3 control-label">Middle Name</label>
		<div class="col-sm-8 col-md-9">
			<input class="form-control" type="text" class="e-input" formControlName="middleName" pmsCapitalizeFirstLetter/>
		</div>
	</div>
	<div class="form-group">
		<label class="col-sm-4 col-md-3 control-label">Suffix</label>
		<div class="col-sm-4 col-md-5">
			<input class="e-input" formControlName="suffix" />
		</div>
		<div class="col-sm-4">
			<span class="help-block">(eg. Jr, Sr)</span>
		</div>
	</div>
	<div class="form-group">
		<label class="col-sm-4 col-md-3 control-label">Nickname</label>
		<div class="col-sm-8 col-md-9">
			<input type="text" class="e-input" formControlName="nickname" pmsCapitalizeFirstLetter/>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'contactDemographicsDateOfBirthFormGroup'">
		<label class="col-sm-4 col-md-3 control-label">DOB</label>
		<div class="col-sm-8 col-md-9">
			<ejs-datepicker placeholder="mm/dd/yyyy"
							[format]="dateFormat"
							formControlName="dob">
			</ejs-datepicker>
		</div>
	</div>
	<div class="form-group" *pmsUsaOnly>
		<label class="col-sm-4 col-md-3 control-label">SSN</label>
		<div class="col-sm-8 col-md-9">
			<ejs-maskedtextbox
					formControlName="ssn"
					mask="000-00-0000">
			</ejs-maskedtextbox>
		</div>
	</div>
	<div class="form-group" *pmsCanadaOnly>
		<label class="col-sm-4 col-md-3 control-label">SIN/PHN</label>
		<div class="col-sm-8 col-md-9">
			<input class="e-input" type="text" formControlName="sin" pmsSinInput/>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'contactDemographicsSexFormGroup'">
		<label class="col-sm-4 col-md-3 control-label">Sex</label>
		<div class="col-sm-8 col-md-9">
			<pms-enum-select-button
					[enumList]="genderOptions"
					[allOption]="false"
					formControlName="gender">
			</pms-enum-select-button>
		</div>
	</div>
</form>
