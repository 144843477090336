import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { _isNil } from '@core/lodash/lodash';
import { ReferenceDataService } from '@core/reference-data/reference-data.service';
import { Gender, PracticeFolderIdentifier, PreferredPhoneType, ReferenceDataMasterCategory } from '@gandalf/constants';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { URL_PRACTICE_FILE_ENDPOINTS } from '@shared/constants/url.constants';
import { OptionItem } from 'morgana';

@Component({
	selector: 'pms-employee-personal-details',
	templateUrl: './employee-personal-details.component.html',
})
export class EmployeePersonalDetailsComponent implements OnInit {

	@Input()
	set formGroup(formGroup: UntypedFormGroup) {
		this.personalFormGroup = formGroup;
		this.addressFormGroup = formGroup.get('address') as UntypedFormGroup;
	}

	@Input()
	employeeId;

	employeeFolder = PracticeFolderIdentifier.EMPLOYEE_PHOTOGRAPHS;
	uploadUrl = URL_PRACTICE_FILE_ENDPOINTS.UPLOAD;

	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	personalFormGroup: UntypedFormGroup;
	addressFormGroup: UntypedFormGroup;
	genders = [
		Gender.MALE,
		Gender.FEMALE,
		Gender.UNKNOWN,
	];
	preferredPhone = [
		PreferredPhoneType.HOME,
		PreferredPhoneType.WORK,
		PreferredPhoneType.CELL,
	];
	titles: OptionItem[];

	constructor(
		private referenceDataService: ReferenceDataService,
	) {
	}

	ngOnInit() {
		this.referenceDataService.getReferenceDataByCategoryIdForDropdown(ReferenceDataMasterCategory.PERSON_DEMO_TITLE.value).subscribe(titles => {
			this.titles = titles;
		});
	}

	get isCreating() {
		return _isNil(this.employeeId);
	}

	get uploadName() {
		return this.isCreating
			? undefined
			: `${this.personalFormGroup.get('lastName').value}_${this.personalFormGroup.get('firstName').value}_${this.employeeId}.png`.replace(/\s/g, '');
	}
}
