import { Component, OnInit, ViewChild } from '@angular/core';
import { DebounceSearch } from '@core/debounce/debounce-search';
import { DynamicModalRef, EnumUtil, ModalConfig } from 'morgana';
import { _isNil } from '@core/lodash/lodash';
import { ContactLensPrescriptionType, RxDisplayStatusLegacy } from '@gandalf/constants';
import { ContactLensPrescriptionLookupResponse } from '@gandalf/model/contact-lens-prescription-lookup-response';
import { ContactLensPrescriptionDetailsService } from '@shared/component/contact-lens-prescription-details/contact-lens-prescription-details.service';
import { TABLE_DATE_FORMATS } from '@shared/constants/date-format.constants';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
	selector: 'pms-contact-lens-prescription-lookup-modal',
	templateUrl: './contact-lens-prescription-lookup-modal.component.html',
	styles: [],
})
export class ContactLensPrescriptionLookupModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('grid')
	grid: GridComponent;

	personId: number;
	isSearching = false;
	gridData: ContactLensPrescriptionLookupResponse[];
	gridDateFormat = TABLE_DATE_FORMATS.MM_DD_YYYY;
	isTrialOrder: boolean;
	debouncedFilter = new DebounceSearch(() => this.filterTable());
	statusFilter;
	filterOptions = [RxDisplayStatusLegacy.TRIAL, RxDisplayStatusLegacy.ACTIVE];

	constructor(
		private dynamicModalRef: DynamicModalRef,
		public modalConfig: ModalConfig,
		private contactLensPrescriptionDetailsService: ContactLensPrescriptionDetailsService,
	) { }

	ngOnInit() {
		this.parseConfigData(this.modalConfig.data);
		if (this.isTrialOrder) {
			this.refreshTrialList();
		} else {
			this.refreshPrescriptionList();
		}
	}

	refreshPrescriptionList() {
		this.isSearching = true;
		this.contactLensPrescriptionDetailsService.searchContactLensRx(this.personId).subscribe((data) => {
			this.gridData = data as ContactLensPrescriptionLookupResponse[];
			this.isSearching = false;
		});
	}

	refreshTrialList() {
		this.isSearching = true;
		this.contactLensPrescriptionDetailsService.searchContactLensTrials(this.personId).subscribe((data) => {
			this.gridData = data as ContactLensPrescriptionLookupResponse[];
			this.isSearching = false;
		});
	}

	parseConfigData(data) {
		this.personId = data.personId;
		this.isTrialOrder = data.isTrialOrder;
	}

	/* istanbul ignore next: closeModal */
	closeModal(id?: any, isTrial?: boolean) {
		const response = {trialId: null, prescriptionId: null};
		if (isTrial) {
			response.trialId = id;
		} else {
			response.prescriptionId = id;
		}
		this.dynamicModalRef.close(this.modal, response);
	}

	isOverallLensTypeSoft(response: ContactLensPrescriptionLookupResponse) {
		return EnumUtil.equals(response.overallLensType, ContactLensPrescriptionType.SOFT);
	}

	filterTable() {
		if (this.statusFilter && !_isNil(this.statusFilter.value)) {
			this.grid.filterByColumn('rxDisplayStatus.label', 'equal', this.statusFilter.label);
		} else {
			this.grid.clearFiltering(['rxDisplayStatus.label']);
		}
	}

	debounceFilter() {
		this.debouncedFilter.valueChanged.next({
			statusFilter: this.statusFilter,
		});
	}
}
