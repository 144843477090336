import { Injectable } from '@angular/core';
import { SortingService } from 'morgana';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { AuthorizeEyeglassPrescriptionsRequest } from '@gandalf/model/authorize-eyeglass-prescriptions-request';
import { CancelPrescriptionRequest } from '@gandalf/model/cancel-prescription-request';
import { CreateEyeglassPrescriptionRequest } from '@gandalf/model/create-eyeglass-prescription-request';
import { CreateEyeglassPrescriptionsRequest } from '@gandalf/model/create-eyeglass-prescriptions-request';
import { CreateOrderFromEyeglassPrescriptionRequest } from '@gandalf/model/create-order-from-eyeglass-prescription-request';
import { RefillEyeglassPrescriptionRequest } from '@gandalf/model/refill-eyeglass-prescription-request';
import { UpdateEyeglassPrescriptionRequest } from '@gandalf/model/update-eyeglass-prescription-request';
import { EyeglassPrescriptionGandalfService } from '@gandalf/services';
import { map } from 'rxjs/operators';
import {
	LocationLevelPrescriptionExpirationRequest
} from '@gandalf/model/location-level-prescription-expiration-request';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class EyeglassPrescriptionService {

	constructor(
		private eyeglassPrescriptionGandalfService: EyeglassPrescriptionGandalfService,
	) {
	}

	findEyeglassPrescriptionHistoryByPatientId(patientId: number) {
		return this.eyeglassPrescriptionGandalfService.findEyeglassPrescriptionHistoryByPatientId(patientId).pipe(
			map(eyeglassPrescriptions => SortingService.sortBy(eyeglassPrescriptions, ['startDate', 'id'], ['desc', 'desc'])),
		);
	}

	/*istanbul ignore next: gandalf*/
	@ShowSavedSuccessToast()
	authorizeEyeglassPrescriptions(request: AuthorizeEyeglassPrescriptionsRequest) {
		return this.eyeglassPrescriptionGandalfService.authorizeEyeglassPrescriptions(request);
	}

	/*istanbul ignore next: gandalf*/
	convertCylinder(eyeglassPrescriptionId: number) {
		return this.eyeglassPrescriptionGandalfService.convertCylinder(eyeglassPrescriptionId);
	}

	/*istanbul ignore next: gandalf*/
	createOrderFromEyeglassPrescription(request: CreateOrderFromEyeglassPrescriptionRequest) {
		return this.eyeglassPrescriptionGandalfService.createOrderFromEyeglassPrescription(request);
	}

	@ShowSavedSuccessToast()
	refillEyeglassPrescription(request: RefillEyeglassPrescriptionRequest) {
		return this.eyeglassPrescriptionGandalfService.refillEyeglassPrescription(request);
	}

	/*istanbul ignore next: gandalf*/
	enablePhr(eyeglassPrescriptionId: number) {
		return this.eyeglassPrescriptionGandalfService.enablePhr(eyeglassPrescriptionId);
	}

	/*istanbul ignore next: gandalf*/
	disablePhr(eyeglassPrescriptionId: number) {
		return this.eyeglassPrescriptionGandalfService.disablePhr(eyeglassPrescriptionId);
	}

	/*istanbul ignore next: gandalf*/
	cancelEyeglassPrescription(cancelEyeglassPrescriptionRequest: CancelPrescriptionRequest) {
		return this.eyeglassPrescriptionGandalfService.cancelEyeglassPrescription(cancelEyeglassPrescriptionRequest);
	}

	/*istanbul ignore next: gandalf*/
	@ShowSavedSuccessToast()
	createEyeglassPrescription(createEyeglassPrescriptionRequest: CreateEyeglassPrescriptionRequest) {
		return this.eyeglassPrescriptionGandalfService.createEyeglassPrescription(createEyeglassPrescriptionRequest);
	}

	/*istanbul ignore next: gandalf*/
	getEyeglassPrescription(eyeglassPrescriptionId: number) {
		return this.eyeglassPrescriptionGandalfService.getEyeglassPrescriptionById(eyeglassPrescriptionId);
	}

	/*istanbul ignore next: gandalf*/
	@ShowSavedSuccessToast()
	updateEyeglassPrescription(updateEyeglassPrescriptionRequest: UpdateEyeglassPrescriptionRequest) {
		return this.eyeglassPrescriptionGandalfService.updateEyeglassPrescription(updateEyeglassPrescriptionRequest);
	}

	/*istanbul ignore next: gandalf*/
	@ShowSavedSuccessToast()
	createEyeglassPrescriptions(createEyeglassPrescriptionsRequest: CreateEyeglassPrescriptionsRequest) {
		return this.eyeglassPrescriptionGandalfService.createEyeglassPrescriptions(createEyeglassPrescriptionsRequest);
	}

	/* istanbul ignore next: gandalf */
	findEyeglassPrescriptionExpirationLocationPreference(request: LocationLevelPrescriptionExpirationRequest): Observable<string> {
		return this.eyeglassPrescriptionGandalfService.findEyeglassPrescriptionExpirationLocationPreference(request);
	}
}
