<div class="rev-custom-tooltip-content">
	<h5 class="margin-top-0">Day/Time Preferences</h5>
	@if (patientSchedulingPreference) {
		<dl class='dl-horizontal margin-0'>
			<dt>Sunday</dt>
			<dd>{{ patientSchedulingPreference.sunday }}</dd>
			<dt>Monday</dt>
			<dd>{{ patientSchedulingPreference.monday }}</dd>
			<dt>Tuesday</dt>
			<dd>{{ patientSchedulingPreference.tuesday }}</dd>
			<dt>Wednesday</dt>
			<dd>{{ patientSchedulingPreference.wednesday }}</dd>
			<dt>Thursday</dt>
			<dd>{{ patientSchedulingPreference.thursday }}</dd>
			<dt>Friday</dt>
			<dd>{{ patientSchedulingPreference.friday }}</dd>
			<dt>Saturday</dt>
			<dd>{{ patientSchedulingPreference.saturday }}</dd>
		</dl>
	}
</div>
