// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.patient.performedservice.PatientPerformedServiceResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientPerformedServiceResponse extends GandalfModelBase {

	@GandalfProperty()
	description: string;

	@GandalfProperty()
	diagnoses: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	encounterDate: Date;

	@GandalfProperty()
	name: string;

	@GandalfProperty()
	performedServiceId: number;

	@GandalfProperty()
	providerName: PersonNameResponse;

}
