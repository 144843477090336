import { Component, Inject } from '@angular/core';
import { IFRAME_HEIGHT_ADJUSTMENT } from '@shared/constants/ui-format.constants';
import { DOCUMENT } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { IframeContainerService } from './iframe-container.service';

@Component({
	standalone: true,
	selector: 'pms-iframe-container',
	templateUrl: './iframe-container.component.html',
	imports: [
		SharedModule,
	],
})
export class IframeContainerComponent {

	constructor(
		@Inject(DOCUMENT) public document: Document,
		public iframeContainerService: IframeContainerService,
	) {
	}

	get iframeHeight() {
		return this.document.defaultView.innerHeight - IFRAME_HEIGHT_ADJUSTMENT;
	}
}
