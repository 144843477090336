import { Injectable } from '@angular/core';
import { _assign } from '@core/lodash/lodash';
import { SummaryPodService } from '@core/summary-pod/summary-pod.service';
import { AddFamilyMemberRequest } from '@gandalf/model/add-family-member-request';
import { AddFamilyMemberWithEmergencyContactRequest } from '@gandalf/model/add-family-member-with-emergency-contact-request';
import { FamilyAccountMemberDetailResponse } from '@gandalf/model/family-account-member-detail-response';
import { FamilyAccountMembersDetailResponse } from '@gandalf/model/family-account-members-detail-response';
import { PatientNameResponse } from '@gandalf/model/patient-name-response';
import { QuickAddFamilyMemberRequest } from '@gandalf/model/quick-add-family-member-request';
import { UpdateFamilyAccountMemberRequest } from '@gandalf/model/update-family-account-member-request';
import { UpdateFamilyAccountMemberWithEmergencyContactRequest } from '@gandalf/model/update-family-account-member-with-emergency-contact-request';
import { PatientFamilyGandalfService } from '@gandalf/services';
import { PatientNamePipe } from '@shared/pipes/patient-name/patient-name.pipe';
import { PersonNamePipe } from '@shared/pipes/person-name/person-name.pipe';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export interface FormattedPatientNameResponseForDropdown extends PatientNameResponse {
	label: string;
	value: any;
}

export interface FamilyAccountMemberWithFormattedName extends FamilyAccountMemberDetailResponse {
	formattedName: string;
}

export class FamilyAccountMembersDetailWithFormattedName extends FamilyAccountMembersDetailResponse {
	formattedFamilyMembers: FamilyAccountMemberWithFormattedName[];
}

@Injectable({
	providedIn: 'root',
})
export class PatientFamilyService {
	constructor(
		private patientFamilyGandalfService: PatientFamilyGandalfService,
		private patientNamePipe: PatientNamePipe,
		private personNamePipe: PersonNamePipe,
		private summaryPodService: SummaryPodService,
	) {
	}

	quickAddPatientFamilyMember(request: QuickAddFamilyMemberRequest) {
		return this.patientFamilyGandalfService.quickAddFamilyMember(request).pipe(
			tap(() => this.summaryPodService.updateFamilySummaryPod(request.patientId)),
		);
	}

	addPatientToFamilyAccount(request: AddFamilyMemberRequest) {
		return this.patientFamilyGandalfService.addPatientToFamilyAccount(request).pipe(
			tap(() => this.summaryPodService.updateFamilySummaryPod(request.familyPatientId)),
		);
	}

	addPatientToFamilyAccountWithEmergencyContact(request: AddFamilyMemberWithEmergencyContactRequest) {
		return this.patientFamilyGandalfService.addFamilyMemberWithEmergencyContactToFamilyAccount(request).pipe(
			tap(() => this.summaryPodService.updateFamilySummaryPod(request.familyPatientId)),
		);
	}

	/* istanbul ignore next: gandalf */
	getPatientFamilyInformationByPatientId(patientId: number) {
		return this.patientFamilyGandalfService.getPatientFamilyInformationByPatientId(patientId);
	}

	getFamilyMembersByPatientId(patientId: number): Observable<PatientNameResponse[]> {
		return this.patientFamilyGandalfService.getFamilyMembersByPatientId(patientId);
	}

	getFamilyMembersByPatientIdForDropdown(patientId: number): Observable<FormattedPatientNameResponseForDropdown[]> {
		return this.getFamilyMembersByPatientId(patientId).pipe(
			map(familyMembers => familyMembers.map(familyMember => this.formatPatientNameResponseForDropdown(familyMember))),
		);
	}

	formatPatientNameResponseForDropdown(patientName: PatientNameResponse): FormattedPatientNameResponseForDropdown {
		const response = patientName as FormattedPatientNameResponseForDropdown;
		response.label = this.patientNamePipe.transform(patientName);
		response.value = patientName.patientId;
		return response;
	}

	getFamilyAccountMemberDetailsByPatientId(patientId: number): Observable<FamilyAccountMembersDetailWithFormattedName> {
		return this.patientFamilyGandalfService.getFamilyAccountMemberDetailsByPatientId(patientId).pipe(
			map(response => _assign(new FamilyAccountMembersDetailWithFormattedName(), {
				formattedFamilyMembers: response.familyMembers.map(familyMember => this.formatFamilyAccountMemberDetails(familyMember)),
				isPerformanceLimited: response.isPerformanceLimited,
			})),
		);
	}

	formatFamilyAccountMemberDetails(familyAccountMember: FamilyAccountMemberDetailResponse): FamilyAccountMemberWithFormattedName {
		const response = familyAccountMember as FamilyAccountMemberWithFormattedName;
		response.formattedName = this.personNamePipe.transform(familyAccountMember.name);
		return response;
	}

	deletePatientFromFamilyAccount(patientId: number) {
		return this.patientFamilyGandalfService.deletePatientFromFamilyAccount(patientId).pipe(
			tap(() => this.summaryPodService.updateFamilySummaryPod(patientId)),
		);
	}

	deleteFamilyAccountMemberById(familyAccountMemberId: number) {
		return this.patientFamilyGandalfService.deleteFamilyAccountMemberById(familyAccountMemberId);
	}

	updateFamilyAccountMember(request: UpdateFamilyAccountMemberRequest) {
		return this.patientFamilyGandalfService.updateFamilyAccountMember(request);
	}

	updateFamilyAccountMemberWithEmergencyContact(request: UpdateFamilyAccountMemberWithEmergencyContactRequest) {
		return this.patientFamilyGandalfService.updateFamilyAccountMemberWithEmergencyContact(request);
	}

}
