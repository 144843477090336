// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { InsuranceCoveragesResponse } from './insurance-coverages-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.insurance.PersonCoverageResponse */
/* istanbul ignore next */
@GandalfModel
export class PersonCoverageResponse extends GandalfModelBase {

	@GandalfArray(InsuranceCoveragesResponse)
	insuranceCoveragesResponse: InsuranceCoveragesResponse[];

	@GandalfProperty()
	patientId: number;

	@GandalfProperty()
	personNameResponse: PersonNameResponse;

	@GandalfProperty()
	role: ReferenceDataResponse;

}
