import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { _isNil } from '@core/lodash/lodash';
import { DynamicModalRef, ModalConfig, PAGE_LENGTH, PAGE_LENGTH_LIST } from 'morgana';
import { SnomedCodeService } from '@core/snomed/snomed-code.service';
import { SearchSnomedCodesRequest } from '@gandalf/model/search-snomed-codes-request';
import { SnomedCodeResponse } from '@gandalf/model/snomed-code-response';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { atLeastOne } from '@shared/validators/atleastOne.validation';
import { GridComponent, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';

@Component({
	selector: 'pms-snomed-diagnosis-add-modal',
	templateUrl: './snomed-diagnosis-add-modal.component.html',
	styles: [],
})
export class SnomedDiagnosisAddModalComponent implements OnInit {

	@ViewChild('diagnosisAddModal')
	modal: DialogComponent;

	@ViewChild('searchResultsGrid')
	grid: GridComponent;

	pageSettings: PageSettingsModel = {
		pageSize: PAGE_LENGTH.PAGE_10,
		pageSizes: PAGE_LENGTH_LIST,
	};

	snomedCodes: SnomedCodeResponse[];
	snomedSearchRequest: SearchSnomedCodesRequest;
	formGroup: UntypedFormGroup;
	isSearching = false;
	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	passedQuery: string;

	constructor(
		private dynamicModalRef: DynamicModalRef,
		private modalConfig: ModalConfig,
		private gandalfFormBuilder: GandalfFormBuilder,
		private snomedCodeService: SnomedCodeService,
	) {
	}

	ngOnInit() {
		this.parseModalConfig(this.modalConfig.data);
		this.createForm();
	}

	parseModalConfig(data) {
		this.passedQuery = data.query ? data.query.trim() : undefined;
	}

	createForm() {
		this.snomedSearchRequest = new SearchSnomedCodesRequest();
		if (!_isNil(this.passedQuery) && this.passedQuery.match(/^[0-9]+$/)) {
			this.snomedSearchRequest.code = this.passedQuery;
		} else {
			this.snomedSearchRequest.description = this.passedQuery;
		}
		this.formGroup = this.gandalfFormBuilder.group(this.snomedSearchRequest, {
			validators: [
				atLeastOne(
					Validators.required,
					['code', 'description'],
					'Please specify a Code or Description',
				)],
		});
		if (!_isNil(this.passedQuery) && this.passedQuery !== '') {
			this.searchSnomedCode();
		}
	}

	searchSnomedCode() {
		if (this.formGroup.invalid || this.isSearching) {
			return;
		}
		this.isSearching = true;
		this.snomedCodeService.searchSnomedCodes(this.formGroup.value).subscribe(snomedCodes => {
			this.snomedCodes = snomedCodes;
			this.isSearching = false;
		});
	}

	clearSearch() {
		this.formGroup.reset();
		this.snomedCodes = [];
	}

	/* istanbul ignore next */
	closeModal(result?: SnomedCodeResponse) {
		this.dynamicModalRef.close(this.modal, result);
	}
}
