/* eslint-disable-next-line */
import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MorganaModule } from 'morgana';
import { throwIfAlreadyLoaded } from './module-import-guard';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		MorganaModule,
	],
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		throwIfAlreadyLoaded(parentModule, 'CoreModule');
	}
}
