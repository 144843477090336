import { Injectable } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { UrlService } from '@core/url-util/url.service';
import { FileExtensionBlacklist } from '@gandalf/constants';
import { IncorporateExternalDataRequest } from '@gandalf/model/incorporate-external-data-request';
import { PatientFileInfoResponse } from '@gandalf/model/patient-file-info-response';
import { PatientFileListResponse } from '@gandalf/model/patient-file-list-response';
import { PracticeFileInfoResponse } from '@gandalf/model/practice-file-info-response';
import { PracticeFileResponse } from '@gandalf/model/practice-file-response';
import { IncorporateExternalDataGandalfService } from '@gandalf/services';
import { EnumUtil, ModalManagerService } from 'morgana';
import { PdfViewerModalComponent } from '@shared/component/pdf-viewer-modal/pdf-viewer-modal.component';
import { ImagePreviewModalComponent } from '@shared/component/image-preview-modal/image-preview-modal.component';

export enum FileType {
	FOLDER = 'folder',
	DOC = 'doc',
	PDF = 'pdf',
	POSTSCRIPT = 'ps',
	EXCEL = 'xls',
	POWERPOINT = 'ppt',
	FLASH = 'swf',
	XML = 'xml',
	ZIP = 'zip',
	JPEG = 'jpg',
	BITMAP = 'bmp',
	GIF = 'gif',
	PNG = 'png',
	SVG = 'svg',
	TIFF = 'tiff',
	MP3 = 'mp3',
	HTML = 'html',
	PLAINTEXT = 'txt',
	RICHTEXT = 'rtx',
	RTF = 'rtf',
	CSS = 'css',
	VRML = 'vrml',
	WML = 'wml',
	MPEG = 'mpeg',
	MOV = 'mov',
	AVI = 'avi',
	MOVIE = 'movie',
	DCM = 'dcm',
	OTHER = 'other',
}

@Injectable({
	providedIn: 'root',
})
export class FileService {

	private static fileTypeMap = new Map<string, FileType>([
		['application/msword', FileType.DOC],
		['application/pdf', FileType.PDF],
		['application/postscript', FileType.POSTSCRIPT],
		['application/vnd.ms-excel', FileType.EXCEL],
		['application/vnd.ms-powerpoint', FileType.POWERPOINT],
		['application/x-shockwave-flash', FileType.FLASH],
		['application/xml', FileType.XML],
		['application/zip', FileType.ZIP],
		['image/jpeg', FileType.JPEG],
		['image/bmp', FileType.BITMAP],
		['image/gif', FileType.GIF],
		['image/png', FileType.PNG],
		['image/svg+xml', FileType.SVG],
		['image/tiff', FileType.TIFF],
		['audio/mpeg', FileType.MP3],
		['text/html', FileType.HTML],
		['text/plain', FileType.PLAINTEXT],
		['text/richtext', FileType.RICHTEXT],
		['text/rtf', FileType.RTF],
		['text/css', FileType.CSS],
		['model/vrml', FileType.VRML],
		['vnd.wap.wml', FileType.WML],
		['video/mpeg', FileType.MPEG],
		['video/quicktime', FileType.MOV],
		['video/x-msvideo', FileType.AVI],
		['video/x-sgi-movie', FileType.MOVIE],
		['application/dicom', FileType.DCM],
	]);

	constructor(
		private securityManagerService: SecurityManagerService,
		private urlService: UrlService,
		private incorporateExternalDataGandalfService: IncorporateExternalDataGandalfService,
		private modalManagerService: ModalManagerService,
	) {

	}

	static getFileTypeFromMimeType(mimeType: string): FileType {
		let type = FileService.fileTypeMap.get(mimeType);
		if (_isNil(type)) {
			type = FileType.OTHER;
		}
		return type;
	}

	static mimeTypeIsPdf(mimeType: string) {
		return FileService.getFileTypeFromMimeType(mimeType) === FileType.PDF;
	}

	static mimeTypeIsImage(mimeType: string) {
		const fileType = FileService.getFileTypeFromMimeType(mimeType);
		return fileType === FileType.GIF || fileType === FileType.JPEG || fileType === FileType.PNG;
	}

	static getFileExtensionFromFileName(fileName: string): string {
		const extensionIndex = _isNil(fileName) ? -1 : fileName.lastIndexOf('.');
		return extensionIndex === -1 ? undefined : fileName.substring(extensionIndex + 1).toLocaleLowerCase();
	}

	static fileExtensionIsBlacklisted(fileName: string): boolean {
		return !_isNil(EnumUtil.findEnumByValue(FileService.getFileExtensionFromFileName(fileName), FileExtensionBlacklist));
	}

	downloadPatientFile(patientId: number, patientFileId: number) {
		this.downloadFile(this.urlService.getPatientFileUrl(patientId, patientFileId));
	}

	downloadPracticeFile(practiceFileId: number) {
		this.downloadFile(this.urlService.getPracticeFileUrl(this.securityManagerService.getUserSession().practiceId, practiceFileId));
	}

	downloadFile(fileUrl: string) {
		this.urlService.openPopupWindow(fileUrl, '_blank');
	}

	@ShowSavedSuccessToast()
	incorporateExternalData(request: IncorporateExternalDataRequest) {
		return this.incorporateExternalDataGandalfService.incorporateExternalData(request);
	}

	/**
	 * Determines if the mime-type for a given file allows for a preview action (PDF/Image)
	 */
	isMimeTypePreviewable(mimeType: string): boolean {
		if (_isNil(mimeType)) {
			return false;
		}

		return FileService.mimeTypeIsPdf(mimeType) || FileService.mimeTypeIsImage(mimeType);
	}

	/**
	 * Determines if a patient file should be shown in a view window (PDF/Image) or should be downloaded to the user.
	 */
	viewOrDownloadPatientFile(patientId: number, patientFile: PatientFileInfoResponse | PatientFileListResponse, printPdf: boolean = false) {
		if (_isNil(patientFile)) {
			return;
		}

		this.viewOrDownloadFile(patientFile.fileName, this.urlService.getPatientFileUrl(patientId, patientFile.patientFileId), patientFile.mimeType, printPdf);
	}

	/**
	 * Determines if a practice file should be shown in a view window (PDF/Image) or should be downloaded to the user.
	 */
	viewOrDownloadPracticeFile(practiceFile: PracticeFileInfoResponse | PracticeFileResponse) {
		if (_isNil(practiceFile)) {
			return;
		}

		const practiceId = this.securityManagerService.getUserSession().practiceId;
		this.viewOrDownloadFile(practiceFile.fileName, this.urlService.getPracticeFileUrl(practiceId, practiceFile.practiceFileId), practiceFile.mimeType);
	}

	viewOrDownloadFile(fileName: string, fileUrl: string, mimeType: string, printPdf: boolean = false) {
		if (FileService.mimeTypeIsPdf(mimeType)) {
			this.openPdfViewerModal(fileName, fileUrl, printPdf);
		} else if (FileService.mimeTypeIsImage(mimeType)) {
			this.openImagePreviewModal(fileUrl);
		} else {
			this.downloadFile(fileUrl);
		}
	}

	openPdfViewerModal(fileName: string, fileUrl: string, openPrint: boolean = false) {
		this.modalManagerService.open(PdfViewerModalComponent, {
			data: {
				title: fileName,
				fileUrl,
				fileName,
				openPrint,
			},
		});
	}

	openImagePreviewModal(filePath: string) {
		this.modalManagerService.open(ImagePreviewModalComponent, {data: {filePath}});
	}

}
