// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreateCarePlanItemTemplateMedicationPrescriptionRequest } from './create-care-plan-item-template-medication-prescription-request';

/** See com.rev360.pms.api.controller.admin.dataconfiguration.careplan.UpdateCarePlanItemTemplateMedicationPrescriptionRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateCarePlanItemTemplateMedicationPrescriptionRequest extends CreateCarePlanItemTemplateMedicationPrescriptionRequest {

	@GandalfValidator({ notNull: { message: 'Medication Prescription is required' } })
	@GandalfProperty({ isRequired: true })
	carePlanItemTemplateId: number;

	@GandalfValidator({ sizeString: { message: 'Description cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	description: string;

	@GandalfProperty()
	dispensingInfoAmount: number;

	@GandalfProperty()
	dispensingInfoUnitId: number;

	@GandalfValidator({ notNull: { message: 'Do Not Substitute is required' } })
	@GandalfProperty({ isRequired: true })
	doNotSubstitute: boolean;

	@GandalfProperty()
	dosageAmount: number;

	@GandalfProperty()
	dosageUnitId: number;

	@GandalfValidator({ notNull: { message: 'Drug is required' } })
	@GandalfProperty({ isRequired: true })
	drugId: number;

	@GandalfProperty()
	durationAmount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	durationUnit: constants.DurationUnit;

	@GandalfProperty()
	frequencyId: number;

	@GandalfValidator({ sizeString: { message: 'Instructions cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	instructions: string;

	@GandalfValidator({ notNull: { message: 'Checkout Task is required' } })
	@GandalfProperty({ isRequired: true })
	isCheckoutTask: boolean;

	@GandalfProperty()
	numRefillsAllowed: number;

	@GandalfProperty()
	routeId: number;

}
