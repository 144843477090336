import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { CacheKeyStateMap, reducer as cacheKeyReducer } from './cacheKey.reducer';
import { reducer as responseReducer, ResponseStateMap } from './response.reducer';

export interface GandalfState {
	cacheKeys: CacheKeyStateMap;
	responses: ResponseStateMap;
}

export const reducers: ActionReducerMap<GandalfState> = {
	cacheKeys: cacheKeyReducer,
	responses: responseReducer,
};

export const selectGandalfState = createFeatureSelector<GandalfState>('gandalf');
