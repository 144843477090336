<ejs-dialog #locationConfigDeleteModal
			cssClass="modal-lg"
			(close)="closeModal()"
			[attr.data-test-id]="'addInsuranceCompanyModal'">
	<ng-template #header>
		<div class="dlg-template">Add Insurance Company</div>
	</ng-template>
	<ng-template #content>
		<form #templateForm="ngForm"
			  class="form-horizontal"
			  [formGroup]="componentForm"
			  (ngSubmit)="save()">
			@if (componentForm.invalid && templateForm.submitted) {
				<gandalf-lib-validation-messages
					[form]="componentForm"
					[requestObj]="createCompanyRequest">
				</gandalf-lib-validation-messages>
			}
			<pms-insurance-company-information [formGroup]="componentForm" [isCreating]="true"></pms-insurance-company-information>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<div>
			<button ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'addInsuranceCompanyModalCancelButton'">Cancel</button>
			<button type="button" ejs-button [isPrimary]="true" (click)="submitForm($event)">Save</button>
		</div>
	</ng-template>
</ejs-dialog>

