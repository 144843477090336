import { Observable } from 'rxjs';

export enum GandalfActionTypes {
	BUST_CACHE = '[Bust Cache] Busts the cache associated with the provided cache key.',
	CALL_ENDPOINT = '[Call Endpoint] Call the endpoint associated with the action.',
}

export class BustCache {
	readonly type = GandalfActionTypes.BUST_CACHE;
	constructor(public payload: {cacheKey: string}) {}
}

export class CallEndpoint<T = any> {
	readonly type = GandalfActionTypes.CALL_ENDPOINT;
	constructor(public payload: {endpoint: Observable<T>, endpointId: string, cacheKeys: string[]}) {}
}

export type GandalfActions =
	BustCache
	| CallEndpoint;
