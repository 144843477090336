// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.query.QueryCriteriaFieldResponse */
/* istanbul ignore next */
@GandalfModel
export class QueryCriteriaFieldResponse extends GandalfModelBase {

	@GandalfProperty()
	id: string;

	@GandalfProperty()
	label: string;

	@GandalfProperty()
	operand1: string;

	@GandalfProperty()
	operand1Label: string;

	@GandalfProperty()
	operand2: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	operator: constants.QueryCriteriaOperator;

	@GandalfConstantDecorator()
	@GandalfProperty()
	outerOperator: constants.QueryCriteriaOuterOperator;

	@GandalfConstantDecorator()
	@GandalfProperty()
	type: constants.QueryFieldType;

}
