// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.connect.campaign.CreateConnectCampaignDraftRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateConnectCampaignDraftRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Subject cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	emailSubject: string;

	@GandalfValidator({ sizeString: { message: 'Message cannot be longer than 65535 characters', minLength: 0, maxLength: 65535 } })
	@GandalfProperty()
	emailTemplate: string;

	@GandalfValidator({ notNull: { message: 'Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Name must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ isRequired: true })
	name: string;

	@GandalfProperty()
	practiceLocationId: number;

	@GandalfProperty()
	queryId: number;

	@GandalfValidator({ notNull: { message: 'Use Patient Primary Location is required' } })
	@GandalfProperty({ isRequired: true })
	usePatientPrimaryLocation: boolean;

}
