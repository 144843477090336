import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { _isEmpty, _isNil, _maxBy } from '@core/lodash/lodash';
import { ButtonMenuDivider, ButtonMenuItem, DialogUtil, ModalManagerService } from 'morgana';
import { UrlService } from '@core/url-util/url.service';
import { InsuranceClaimStatusResponse } from '@gandalf/model/insurance-claim-status-response';
import { InvoiceInsuranceClaimResponse } from '@gandalf/model/invoice-insurance-claim-response';
import { InvoiceResponse } from '@gandalf/model/invoice-response';
import { ClaimProcessingStatus, ClaimSubmitMechanism, InsuranceClaimStatus } from '@gandalf/constants';
import { BaseComponent } from '@shared/component/base.component';
import { TABLE_DATE_FORMATS } from '@shared/constants/date-format.constants';
import { URL_ACCOUNTING_ENDPOINTS } from '@shared/constants/url.constants';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { Dialog } from '@syncfusion/ej2-angular-popups';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InvoiceService } from '@accounting/core/accounting/invoice-service/invoice.service';
import { ClaimService } from '../../../core/claim/claim.service';
import { ClaimMessagesModalComponent } from './claim-messages-modal/claim-messages-modal.component';
import { EnterClaimSubmissionDateModalComponent } from './enter-claim-submission-date-modal/enter-claim-submission-date-modal.component';

interface InsuranceClaimResponseWithButtonList {
	insuranceClaim: InvoiceInsuranceClaimResponse;
	buttonList: ButtonMenuItem[];
	showSubmitButton: boolean;
	processingStatusText: string;
	mostRecentStatusMessage: string;
}

@Component({
	selector: 'pms-claims-history',
	templateUrl: './claims-history.component.html',
	styles: [],
})
export class ClaimsHistoryComponent extends BaseComponent implements OnInit {
	@Input()
	invoice: InvoiceResponse;

	@Input()
	updateOn: Observable<number>;

	@Input()
	isPaymentProcessing: boolean;

	@ViewChild('grid')
	grid: GridComponent;

	ELLIPSIS_ITEM_IDS = {
		PRINT: 'print',
		VIEW_DATA_FILE: 'viewDataFile',
		SUBMIT: 'submit',
		MARK_FOR_RESUBMIT: 'markForResubmit',
		MARK_AS_SUBMITTED: 'markAsSubmitted',
		PREVIEW: 'preview',
		MARK_AS_DELETED: 'markAsDeleted',
	};

	insuranceClaimStatus: InsuranceClaimResponseWithButtonList[];
	isSearching = true;
	tableDateFormat = TABLE_DATE_FORMATS.MM_DD_YYYY;
	staticColumnList = [
		'insuranceClaim.id',
		'insuranceClaim.claimDate',
		'insuranceClaim.priority',
		'processingStatusText',
		'insuranceClaim.claimSubmitDate',
		'insuranceClaim.claimSubmissionMechanism',
		'allMessages',
		'buttons',
	];


	constructor(
		private claimService: ClaimService,
		private invoiceService: InvoiceService,
		private urlService: UrlService,
		public modalManagerService: ModalManagerService,
	) {
		super();
	}

	ngOnInit() {
		this.getData();
		if (!_isNil(this.updateOn)) {
			this.registerListener();
		}
	}

	registerListener() {
		this.updateOn.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
			this.getData();
		});
	}

	getData() {
		this.invoiceService.findClaimsByInvoiceId(this.invoice.id).subscribe(data => {
			this.handleData(data);
		});
	}

	handleData(insuranceClaims: InvoiceInsuranceClaimResponse[]) {
		this.insuranceClaimStatus = [];
		insuranceClaims.forEach((claim) => {
			const buttonList = this.getButtonList(claim);
			const showSubmitButton = this.showSubmitButton(claim);
			const processingStatusText = this.claimService.buildProcessingStatus(claim);
			const mostRecentStatusMessage = this.getMostRecentStatusMessage(claim.claimStatusMessages);
			this.insuranceClaimStatus.push({
				insuranceClaim: claim,
				buttonList,
				showSubmitButton,
				processingStatusText,
				mostRecentStatusMessage,
			});
		});
		this.isSearching = false;
		this.grid.refresh();
	}

	getMostRecentStatusMessage(statusMessages: InsuranceClaimStatusResponse[]) {
		let mostRecentMessage = '';
		if (!_isEmpty(statusMessages)) {
			mostRecentMessage = _maxBy(statusMessages, 'statusDate').statusMessage;
		}
		return mostRecentMessage;
	}

	getButtonList(claim: InvoiceInsuranceClaimResponse) {
		const buttonList = [
			new ButtonMenuItem(this.ELLIPSIS_ITEM_IDS.PREVIEW, 'Preview'),
			new ButtonMenuItem(this.ELLIPSIS_ITEM_IDS.PRINT, 'Print'),
			new ButtonMenuItem(this.ELLIPSIS_ITEM_IDS.VIEW_DATA_FILE, 'View Data File'),
			new ButtonMenuDivider(),
		];

		const canMarkClaimForResubmit = this.claimService.canMarkClaimForResubmit(claim.processingStatus, claim.status);
		const canMarkClaimAsSubmitted = this.claimService.canMarkClaimAsSubmitted(claim.processingStatus, claim.status);
		const canMarkClaimAsDeleted = this.claimService.canMarkClaimAsDeleted(claim.processingStatus, claim.status);

		if (!canMarkClaimForResubmit && !canMarkClaimAsSubmitted && !canMarkClaimAsDeleted) {
			buttonList.splice(3, 1);
		}

		if (canMarkClaimForResubmit) {
			buttonList.push(new ButtonMenuItem(this.ELLIPSIS_ITEM_IDS.MARK_FOR_RESUBMIT, 'Mark for Resubmit'));
		}

		if (canMarkClaimAsSubmitted) {
			buttonList.push(new ButtonMenuItem(this.ELLIPSIS_ITEM_IDS.MARK_AS_SUBMITTED, 'Mark as Submitted'));
		}

		if (canMarkClaimAsDeleted) {
			buttonList.push(new ButtonMenuItem(this.ELLIPSIS_ITEM_IDS.MARK_AS_DELETED, 'Mark as Deleted'));
		}

		return buttonList;
	}

	showSubmitButton(claim: InvoiceInsuranceClaimResponse) {
		return claim.processingStatus === ClaimProcessingStatus.TRANSMIT_READY
			&& claim.status !== InsuranceClaimStatus.DELETED
			&& claim.claimSubmissionMechanism === ClaimSubmitMechanism.ELECTRONIC;
	}

	onEllipsisItemSelect(ellipsisItemId: string, insuranceClaimId: number) {
		switch (ellipsisItemId) {
			case this.ELLIPSIS_ITEM_IDS.PRINT:
				this.openPrintClaim(insuranceClaimId);
				break;
			case this.ELLIPSIS_ITEM_IDS.VIEW_DATA_FILE:
				this.openViewDataFile(insuranceClaimId);
				break;
			case this.ELLIPSIS_ITEM_IDS.PREVIEW:
				this.openPreviewClaim(insuranceClaimId);
				break;
			case this.ELLIPSIS_ITEM_IDS.MARK_FOR_RESUBMIT:
				this.resetClaim(insuranceClaimId);
				break;
			case this.ELLIPSIS_ITEM_IDS.MARK_AS_SUBMITTED:
				this.modalManagerService.open(
					EnterClaimSubmissionDateModalComponent, { data: {claimIds: [insuranceClaimId]} },
				).onClose.subscribe(() => {
					this.getData();
				});
				break;
			case this.ELLIPSIS_ITEM_IDS.MARK_AS_DELETED:
				this.deleteClaimConfirmation(insuranceClaimId);
				break;
		}
	}

	/* istanbul ignore next: gandalf */
	deleteClaimConfirmation(claimId: number) {
		const dialog = DialogUtil.confirm({
			title: 'Delete Claim',
			content: `Are you sure you want to mark this claim as deleted?`,
			okButton: {
				click: () => this.saveMarkAsDeleted(claimId, dialog),
			},
		});
	}

	openPrintClaim(insuranceClaimId: number) {
		this.urlService.openTabWithPost(URL_ACCOUNTING_ENDPOINTS.PRINT_CLAIM, {claimId: insuranceClaimId});
	}

	openPreviewClaim(insuranceClaimId: number) {
		this.urlService.openTabWithPost(URL_ACCOUNTING_ENDPOINTS.PREVIEW_CLAIM, {claimId: insuranceClaimId});
	}

	openViewDataFile(insuranceClaimId: number) {
		this.claimService.displayRawClaims([insuranceClaimId]);
	}

	submitClaim(insuranceClaimId: number) {
		this.claimService.submitClaim(insuranceClaimId).subscribe((_response) => {
			this.getData();
		});
	}

	resetClaim(insuranceClaimId: number) {
		this.invoiceService.resetClaim(insuranceClaimId).subscribe(() => {
			this.getData();
		});
	}

	saveMarkAsDeleted(claimId: number, dialog: Dialog) {
		this.invoiceService.deleteClaim(claimId).subscribe(() => {
			this.getData();
			dialog.close();
		});
	}

	openAllMessagesModal(insuranceClaim: InvoiceInsuranceClaimResponse) {
		this.modalManagerService.open(
			ClaimMessagesModalComponent, { data: {invoice: this.invoice, insuranceClaim} },
		);
	}

}
