<div class="margin-bottom-sm">
	<button (click)="addItem()" *ngIf="canAddItems()" cssClass="e-success margin-bottom-sm" ejs-button iconCss="fa fa-plus" [attr.data-test-id]="'invoiceAddItemButton'">Add</button>
	<ejs-grid #grid
			  [dataSource]="invoiceItemsForTable"
			  autoFitColumns
			  class="table-rowlink"
			  (queryCellInfo)="handleQueryCellInfo($event)"
			  [autoFitBlacklist]="['description']"
			  [staticColumns]="['postedOn', 'formattedDiagnoses', 'quantity', 'unitPrice', 'discountTotal', 'taxAmount', 'extendedPrice', 'adjustmentTotal', 'amountPaid', 'balance', 'secondaryAction', 'action']"
			  [allowPaging]="false"
			  [allowSelection]="!isPaymentProcessing"
			  (rowSelected)="openInvoiceItemDetailsModal($event)"
			  (rowSelecting)="checkRowSelection($event, grid)"
			  [allowSorting]="true"
			  [allowResizing]="true"
			  gridLines="Horizontal"
			  allowPaging="true"
			  [attr.data-test-id]="'invoiceBilledItemsTable'">
		<e-columns>
			<e-column field="id" headerText="ID" [visible]="false" [isPrimaryKey]="true"></e-column>
			<e-column [width]="96" field="postedOn" headerText="Post Date" [format]="tableDateFormat"></e-column>
			<e-column field="code" headerText="Code"></e-column>
			<e-column field="formattedModifiers" headerText="Modifiers"></e-column>
			<e-column [width]="250" clipMode="EllipsisWithTooltip" field="formattedDiagnoses" headerText="Diagnoses">
				<ng-template #template let-data>
					<ng-container *ngFor="let diagnoses of data.formattedDiagnoses; let formattedDiagnosesIndex = index">
						<ng-container *ngFor="let diagnosis of diagnoses; trackBy: trackByDiagnosisId; let diagnosesChunkIndex = index">
							{{diagnosis.code}}<i *ngIf="diagnosis.isPrimary" class="fa fa-star margin-left-xs"></i>{{diagnosesChunkIndex === diagnoses.length - 1 ? '' : ', '}}
						</ng-container>
						<br *ngIf="formattedDiagnosesIndex !== data.formattedDiagnoses.length - 1">
					</ng-container>
				</ng-template>
			</e-column>
			<e-column clipMode="EllipsisWithTooltip" field="description" headerText="Description" [customAttributes]="{ autofitMinWidth: 150 }"></e-column>
			<e-column [width]="60" field="quantity" headerText="Qty">
				<ng-template #template let-data>
					<ng-container *ngIf="canEditQuantity(data); else readOnlyQuantity" revEventStopPropagation>
						<input
							#qty
							[value]="data.quantity"
							(change)="updateQuantity(qty, data)"
							class="e-input"
							revDigitOnlyInput
							revEventStopPropagation
							type="text"
							dataTestId="invoiceDetailsQuantityInput"/>
					</ng-container>
					<ng-template  #readOnlyQuantity>
						<span [class.text-strike-through]="isItemRemoved(data)" revTooltip [tooltipContent]="quantityTooltip(data)">{{data.quantity}}</span>
					</ng-template>
				</ng-template>
			</e-column>
			<e-column [width]="95" headerText="Unit Price" field="unitPrice" type="number" format="C2"></e-column>
			<e-column [width]="95" headerText="Discounts" field="discountTotal" type="number" format="C2"></e-column>
			<e-column [width]="75" headerText="Tax" field="taxAmount" type="number" format="C2"></e-column>
			<e-column [width]="95" headerText="Ext. Price" field="extendedPrice" type="number" format="C2"></e-column>
			<e-column [width]="112" headerText="Adjustments" type="number" field="adjustmentTotal" format="C2"></e-column>
			<e-column [width]="80" headerText="Paid" type="number" field="amountPaid" format="C2"></e-column>
			<e-column [width]="85" headerText="Balance" type="number" field="balance" textAlign="Right" format="C2"></e-column>
			<e-column [width]="37" field="secondaryAction" [visible]="canAssignCategories()" headerText="" [allowSorting]="false" [allowResizing]="false">
				<ng-template #template let-data>
					<rev-button
							buttonIcon="tags"
							buttonStyle="primary"
							buttonTooltip="Assign Categories"
							revEventStopPropagation
							(buttonClick)="openCategoriesModal(data.invoiceItemId)"
							dataTestId="invoiceDetailsItemCategoriesButton">
					</rev-button>
				</ng-template>
			</e-column>
			<e-column [width]="73" field="action" headerText="" [visible]="showActionColumn()" [allowSorting]="false" [allowResizing]="false">
				<ng-template #template let-data>
					<rev-button
							*ngIf="canSplitItem(data)"
							buttonIcon="arrows-h"
							buttonStyle="primary"
							buttonTooltip="Split"
							revEventStopPropagation
							(buttonClick)="splitItem(data)"
							dataTestId="invoiceDetailsItemSplitButton">
					</rev-button>
					<rev-button
							*ngIf="canRemoveItem(data)"
							[revGridButton]="'remove'"
							(buttonClick)="requestRemoveItem(data)"
							dataTestId="invoiceDetailsItemRemoveButton">
					</rev-button>
				</ng-template>
			</e-column>
		</e-columns>
	</ejs-grid>
</div>
<div class="row">
	<div class="col-sm-6 col-md-4 col-lg-3">
		<ejs-checkbox
				[checked]="showAllInvoices"
				(change)="toggleShowAllInvoiceItems($event.checked)"
				[attr.data-test-id]="'invoiceShowAllItemsCheckbox'"
				label="Show All">
		</ejs-checkbox>
	</div>
	<div class="col-sm-6 col-md-4 col-md-offset-4 col-lg-3 col-lg-offset-6" [attr.data-test-id]="'invoiceDetailsBalanceDueTable'">
		<table class="table table-bordered table-condensed text-right margin-bottom-0">
			<tbody>
			<tr class="bg-default text-uppercase">
				<td>Sub Total</td>
				<td [attr.data-test-id]="'invoiceDetailAggregateTableSubTotalAmount'">{{invoice.subTotalAmount| currency}}</td>
			</tr>
			<tr>
				<td>Discounts</td>
				<td [attr.data-test-id]="'invoiceDetailAggregateTableDiscountTotalAmount'">{{invoice.discountTotalAmount | currency}}</td>
			</tr>
			<tr>
				<td>Tax</td>
				<td [attr.data-test-id]="'invoiceDetailAggregateTableTaxTotalAmount'">{{invoice.taxTotalAmount | currency}}</td>
			</tr>
			<tr class="bg-default text-uppercase">
				<td>Total</td>
				<td [attr.data-test-id]="'invoiceDetailAggregateTableTotalAmount'">{{invoice.totalAmount | currency}}</td>
			</tr>
			<tr>
				<td>Adjustments</td>
				<td [attr.data-test-id]="'invoiceDetailAggregateTableAdjustableTotalAmount'">{{invoice.adjustmentTotalAmount | currency}}</td>
			</tr>
			<tr>
				<td>Payments Received</td>
				<td [attr.data-test-id]="'invoiceDetailAggregateTableAmountPaid'">{{invoice.amountPaid | currency}}</td>
			</tr>
			</tbody>
			<tfoot>
			<tr class="bg-info text-uppercase">
				<td><strong>Balance Due</strong></td>
				<td><strong [attr.data-test-id]="'invoiceDetailAggregateTableBalanceDue'">{{invoice.balance | currency}}</strong></td>
			</tr>
			</tfoot>
		</table>
	</div>
</div>

<div class="ng-hide" #adjustmentTooltip [attr.data-test-id]="'adjustmentTableTooltip'">
	<table class="table table-bordered table-condensed margin-bottom-0">
		<thead>
		<tr>
			<th>Adjustment</th>
			<th>Details</th>
			<th class="text-right">Amount</th>
		</tr>
		</thead>
		<tbody>
		<tr *ngFor="let adjustment of sortBy(filterAdjustments(tooltipData?.itemAdjustments, tooltipDiscount), 'id')">
			<td [attr.data-test-id]="'adjustmentTypeTooltip'">{{adjustment.type}}</td>
			<td [attr.data-test-id]="'adjustmentDetailsTooltip'">{{adjustment.details}}</td>
			<td [attr.data-test-id]="'adjustmentAmountTooltip'" class="text-right">{{adjustment.amount | currency}}</td>
		</tr>
		</tbody>
	</table>
</div>

<div class="ng-hide" #taxTooltip>
	<table class="table table-bordered table-condensed margin-bottom-0" [attr.data-test-id]="'detailItemTaxTooltip'">
		<thead>
		<tr>
			<th>Tax</th>
			<th class="text-right">Amount</th>
		</tr>
		</thead>
		<tbody>
		<tr *ngFor="let tax of sortBy(tooltipData?.itemTaxes, 'taxId')">
			<td>{{tax.taxName}}</td>
			<td class="text-right">{{tax.taxAmount | currency}}</td>
		</tr>
		</tbody>
	</table>
</div>

<div class="ng-hide" #inactiveICD10MasterDxTooltip></div>
