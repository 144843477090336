import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormGroup, Validators } from '@angular/forms';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { HIT_PMS_HTML_PREFERENCES } from '@core/legacy/hit-pms-html.constants';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { FeatureFlagApplicationKey, PreferredPhoneType } from '@gandalf/constants';
import { conditionallyRequiredValidator } from '@shared/validators/conditionally-required-validation';
import { preferredPhoneTypeValidator } from '@shared/validators/preferred-phone-type.validation';
import { PatientResponse } from '@gandalf/model/patient-response';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { DialogUtil } from 'morgana';
import { DatePipe } from '@angular/common';
import { FeatureService } from '@core/feature/feature.service';
import { PatientService } from '@core/patient/patient.service';
import { Dialog } from '@syncfusion/ej2-angular-popups';
import { DemographicsService } from '../../../../patients/demographics/demographics.service';

@Component({
	selector: 'pms-phone-information-form',
	templateUrl: './phone-information-form.component.html',
	styles: [],
})
export class PhoneInformationFormComponent implements OnInit {

	@Input()
	patientResponse: PatientResponse;

	componentForm: UntypedFormGroup;
	preferredPhoneTypeOptions = [
		PreferredPhoneType.HOME,
		PreferredPhoneType.WORK,
		PreferredPhoneType.CELL,
	];
	isHomePhoneRequired: boolean;
	isWorkPhoneRequired: boolean;
	isCellPhoneRequired: boolean;
	isEmailRequired: boolean;
	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	isRevconnectAntiSpamFeatureOn = false;
	_isLoading = false;

	constructor(
		private securityManagerService: SecurityManagerService,
		public controlContainer: ControlContainer,
		public datePipe: DatePipe,
		public featureService: FeatureService,
		public patientService: PatientService,
		public demographicsService: DemographicsService,
	) {
	}

	ngOnInit() {
		this.componentForm = this.controlContainer.control as UntypedFormGroup;
		this.isRevconnectAntiSpamFeatureOn = this.featureService.isFeatureOn(FeatureFlagApplicationKey.REVCONNECT_ANTI_SPAM.value);
		this.handleFormState();
		this.isEmailRequired = this.securityManagerService.preferenceValueIsOn(
			HIT_PMS_HTML_PREFERENCES.DEMOGRAPHICS_EMAIL_REQUIRED.name,
			HIT_PMS_HTML_PREFERENCES.DEMOGRAPHICS_EMAIL_REQUIRED.defaultValue,
		);

		this.componentForm.setValidators([
			conditionallyRequiredValidator(
				'email',
				() => this.componentForm && this.componentForm.get('email').enabled && this.isEmailRequired,
				'emailRequired',
				'Email is required',
			),
			preferredPhoneTypeValidator(
				Validators.required,
				'preferredPhoneType',
				{
					home: {
						formControlName: 'homePhone',
						message: 'Home Phone is required',
					},
					work: {
						formControlName: 'workPhone',
						message: 'Work Phone is required',
					},
					cell: {
						formControlName: 'cellPhone',
						message: 'Cell Phone is required',
					},
				},
			),
		]);
	}

	resolveCellPhone() {
		const dialog = DialogUtil.confirm({
			title: 'Confirm Cell Phone Number',
			content: `This cell phone number was flagged as deactivated by a cell phone carrier on `
				+ `${this.datePipe.transform(this.patientResponse.cellPhoneDeactivatedDate, this.dateFormat)}.`
				+ `<br><br>Review the cell phone number and text message opt out status with the patient.`
				+ `<br><br>Is ${this.componentForm.get('cellPhone').value} the correct number for this patient?`,
			okButton: {
				click: () => this.reactivatePatientCellPhone(dialog),
			},
		});
	}

	reactivatePatientCellPhone(confirmDialog: Dialog) {
		if (this._isLoading) {
			return;
		}

		this._isLoading = true;
		this.patientService.reactivatePatientCellPhoneIfExists(this.patientResponse.patientId).subscribe(() => {
			this.patientResponse.cellPhoneDeactivatedDate = null;
			this.demographicsService.resolvedCellPhone.next();
			this._isLoading = false;
			confirmDialog.close();
		});
	}

	showCellPhoneDeactivated() {
		return this.isRevconnectAntiSpamFeatureOn
			&& !!this.patientResponse?.cellPhoneDeactivatedDate
			&& this.componentForm.get('cellPhone').value === this.patientResponse.phoneNumbers.cellPhone;
	}

	handleFormState() {
		FormUtilsService.reactToValueChanges(this.componentForm, () => this.setRequiredFields(), true);
	}

	setRequiredFields() {
		const emailInput = this.componentForm.get('email');
		const emailDeclinedInput = this.componentForm.get('emailDeclined');

		FormUtilsService.disabledWhen(emailInput, emailDeclinedInput.value || this.controlContainer.disabled);
		FormUtilsService.disabledWhen(emailDeclinedInput, emailInput.value || this.controlContainer.disabled);

		if (this.componentForm.get('preferredPhoneType').value) {
			// need to compare enum values as these enum references are not propagated to the form
			this.isHomePhoneRequired = this.componentForm.get('preferredPhoneType').value.value === PreferredPhoneType.HOME.value;
			this.isWorkPhoneRequired = this.componentForm.get('preferredPhoneType').value.value === PreferredPhoneType.WORK.value;
			this.isCellPhoneRequired = this.componentForm.get('preferredPhoneType').value.value === PreferredPhoneType.CELL.value;
		}
	}
}
