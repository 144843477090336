import { ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { _isNil } from '@core/lodash/lodash';
import { ModalManagerService } from 'morgana';
import { LoincCodeRequest } from '@gandalf/model/loinc-code-request';
import { LabOrderService } from '../../../../core/lab-order/lab-order.service';
import { LabOrderLookupModalComponent } from './lab-order-lookup-modal/lab-order-lookup-modal.component';

@Component({
	selector: 'pms-lab-order-lookup',
	templateUrl: './lab-order-lookup.component.html',
	styles: [],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => LabOrderLookupComponent),
			multi: true,
		},
		ModalManagerService,
	],
})
export class LabOrderLookupComponent implements ControlValueAccessor {

	labOrderName: string;

	loincNum: string;

	disabled = false;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private modalManagerService: ModalManagerService,
		private labOrderService: LabOrderService,
	) { }

	onModelChange: (_: any) => void = () => {
	};
	onModelTouched: () => void = () => {
	};

	lookupLabOrder() {
		const loincCodeRequest = new LoincCodeRequest();
		loincCodeRequest.loincNum = this.loincNum;

		this.labOrderService.getLoincCodeByNum(loincCodeRequest).subscribe(loincCode => {
			this.setLabOrderName(loincCode.formattedCode);
		});
	}

	setLabOrderName(name: string) {
		this.labOrderName = name;
	}

	handleLabOrderLookupModalClose(selectedLoincNum?) {
		if (!_isNil(selectedLoincNum)) {
			this.loincNum = selectedLoincNum;
			this.onModelChange(this.loincNum);
			this.lookupLabOrder();
		}
	}

	/* istanbul ignore next: Modal */
	openLookupModal() {
		this.modalManagerService.open(LabOrderLookupModalComponent, {
			data: {
				searchText: this.labOrderName,
			},
		}).onClose.subscribe(selectedLabOrderId => {
			this.handleLabOrderLookupModalClose(selectedLabOrderId);
		});
	}

	/*istanbul ignore next*/
	writeValue(value?: string): void {
		this.loincNum = value;

		if (!_isNil(value)) {
			this.lookupLabOrder();
		}

		this.changeDetectorRef.markForCheck();
	}

	/*istanbul ignore next*/
	registerOnChange(fn: (_: any) => void): void {
		this.onModelChange = fn;
	}

	/*istanbul ignore next*/
	registerOnTouched(fn: () => void): void {
		this.onModelTouched = fn;
	}

	/*istanbul ignore next*/
	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

}
