/* eslint-disable max-len */
import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { _isNil } from '@core/lodash/lodash';
import { FeatureFlagApplicationKey, PatientCommunicationOptIn } from '@gandalf/constants';
import { PatientResponse } from '@gandalf/model/patient-response';
import { FeatureService } from '@core/feature/feature.service';

@Component({
	selector: 'pms-communication-preferences-form',
	templateUrl: './communication-preferences-form.component.html',
	styles: [],
})
export class CommunicationPreferencesFormComponent implements OnInit {

	@Input()
	phoneInformationForm: UntypedFormGroup;

	@Input()
	patientResponse: PatientResponse;

	componentForm: UntypedFormGroup;

	communicationOptInOptions = [
		PatientCommunicationOptIn.YES,
		PatientCommunicationOptIn.NO,
	];

	constructor(
		public controlContainer: ControlContainer,
		private featureService: FeatureService,
	) { }

	ngOnInit() {
		this.componentForm = this.controlContainer.control as UntypedFormGroup;
		this.handleFormState();
	}

	handleFormState() {
		FormUtilsService.reactToValueChanges(this.phoneInformationForm, () => {
			this.setFormControlEnabledState();
		}, true);

		FormUtilsService.reactToValueChanges(this.componentForm, () => {
			this.setFormControlEnabledState();
		}, true);
	}

	setFormControlEnabledState() {
		if (this.phoneInformationForm.get('homePhone').value && this.controlContainer.control.enabled) {
			FormUtilsService.enabledWhen(this.componentForm.get('appointmentsHomePhoneAllowed'), this.isOptedIn('appointmentsOptIn'));
			FormUtilsService.enabledWhen(this.componentForm.get('recallsHomePhoneAllowed'), this.isOptedIn('recallsOptIn'));
			FormUtilsService.enabledWhen(this.componentForm.get('ordersHomePhoneAllowed'), this.isOptedIn('ordersOptIn'));
			FormUtilsService.enabledWhen(this.componentForm.get('generalHomePhoneAllowed'), this.isOptedIn('generalOptIn'));
		} else {
			this.componentForm.get('appointmentsHomePhoneAllowed').disable();
			this.componentForm.get('recallsHomePhoneAllowed').disable();
			this.componentForm.get('ordersHomePhoneAllowed').disable();
			this.componentForm.get('generalHomePhoneAllowed').disable();
		}

		if (this.phoneInformationForm.get('workPhone').value && this.controlContainer.control.enabled) {
			FormUtilsService.enabledWhen(this.componentForm.get('appointmentsWorkPhoneAllowed'), this.isOptedIn('appointmentsOptIn'));
			FormUtilsService.enabledWhen(this.componentForm.get('recallsWorkPhoneAllowed'), this.isOptedIn('recallsOptIn'));
			FormUtilsService.enabledWhen(this.componentForm.get('ordersWorkPhoneAllowed'), this.isOptedIn('ordersOptIn'));
			FormUtilsService.enabledWhen(this.componentForm.get('generalWorkPhoneAllowed'), this.isOptedIn('generalOptIn'));
		} else {
			this.componentForm.get('appointmentsWorkPhoneAllowed').disable();
			this.componentForm.get('recallsWorkPhoneAllowed').disable();
			this.componentForm.get('ordersWorkPhoneAllowed').disable();
			this.componentForm.get('generalWorkPhoneAllowed').disable();
		}

		if (this.phoneInformationForm.get('cellPhone').value && this.controlContainer.control.enabled) {
			FormUtilsService.enabledWhen(this.componentForm.get('appointmentsCellPhoneAllowed'), this.isOptedIn('appointmentsOptIn'));
			FormUtilsService.enabledWhen(this.componentForm.get('recallsCellPhoneAllowed'), this.isOptedIn('recallsOptIn'));
			FormUtilsService.enabledWhen(this.componentForm.get('ordersCellPhoneAllowed'), this.isOptedIn('ordersOptIn'));
			FormUtilsService.enabledWhen(this.componentForm.get('generalCellPhoneAllowed'), this.isOptedIn('generalOptIn'));
			FormUtilsService.enabledWhen(this.componentForm.get('appointmentsTextAllowed'), this.isOptedIn('appointmentsOptIn'));
			FormUtilsService.enabledWhen(this.componentForm.get('recallsTextAllowed'), this.isOptedIn('recallsOptIn'));
			FormUtilsService.enabledWhen(this.componentForm.get('ordersTextAllowed'), this.isOptedIn('ordersOptIn'));
			FormUtilsService.enabledWhen(this.componentForm.get('generalTextAllowed'), this.isOptedIn('generalOptIn'));
		} else {
			this.componentForm.get('appointmentsCellPhoneAllowed').disable();
			this.componentForm.get('recallsCellPhoneAllowed').disable();
			this.componentForm.get('ordersCellPhoneAllowed').disable();
			this.componentForm.get('generalCellPhoneAllowed').disable();
			this.componentForm.get('appointmentsTextAllowed').disable();
			this.componentForm.get('recallsTextAllowed').disable();
			this.componentForm.get('ordersTextAllowed').disable();
			this.componentForm.get('generalTextAllowed').disable();
		}

		if (this.phoneInformationForm.get('email').value && this.controlContainer.control.enabled) {
			FormUtilsService.enabledWhen(this.componentForm.get('appointmentsEmailAllowed'), this.isOptedIn('appointmentsOptIn'));
			FormUtilsService.enabledWhen(this.componentForm.get('recallsEmailAllowed'), this.isOptedIn('recallsOptIn'));
			FormUtilsService.enabledWhen(this.componentForm.get('ordersEmailAllowed'), this.isOptedIn('ordersOptIn'));
			FormUtilsService.enabledWhen(this.componentForm.get('generalEmailAllowed'), this.isOptedIn('generalOptIn'));
		} else {
			this.componentForm.get('appointmentsEmailAllowed').disable();
			this.componentForm.get('recallsEmailAllowed').disable();
			this.componentForm.get('ordersEmailAllowed').disable();
			this.componentForm.get('generalEmailAllowed').disable();
		}

		FormUtilsService.enabledWhen(this.componentForm.get('generalMailAllowed'), this.isOptedIn('generalOptIn') && this.controlContainer.control.enabled);
		FormUtilsService.enabledWhen(this.componentForm.get('recallsMailAllowed'), this.isOptedIn('recallsOptIn') && this.controlContainer.control.enabled);
		FormUtilsService.enabledWhen(this.componentForm.get('ordersMailAllowed'), this.isOptedIn('ordersOptIn') && this.controlContainer.control.enabled);
		FormUtilsService.enabledWhen(this.componentForm.get('appointmentsMailAllowed'), this.isOptedIn('appointmentsOptIn') && this.controlContainer.control.enabled);
	}

	setAllValidOptions(checked: boolean) {
		this.setAllValidHomePhoneOptions(checked);
		this.setAllValidWorkPhoneOptions(checked);
		this.setAllValidCellPhoneOptions(checked);
		this.setAllValidEmailOptions(checked);
		this.setAllValidTextOptions(checked);
		this.setAllValidMailOptions(checked);
	}

	setAllValidHomePhoneOptions(checked: boolean) {
		this.setControlIfEnabled('appointmentsHomePhoneAllowed', checked);
		this.setControlIfEnabled('recallsHomePhoneAllowed', checked);
		this.setControlIfEnabled('ordersHomePhoneAllowed', checked);
		this.setControlIfEnabled('generalHomePhoneAllowed', checked);
	}

	setAllValidCellPhoneOptions(checked: boolean) {
		this.setControlIfEnabled('appointmentsCellPhoneAllowed', checked);
		this.setControlIfEnabled('recallsCellPhoneAllowed', checked);
		this.setControlIfEnabled('ordersCellPhoneAllowed', checked);
		this.setControlIfEnabled('generalCellPhoneAllowed', checked);
	}

	setAllValidWorkPhoneOptions(checked: boolean) {
		this.setControlIfEnabled('appointmentsWorkPhoneAllowed', checked);
		this.setControlIfEnabled('recallsWorkPhoneAllowed', checked);
		this.setControlIfEnabled('ordersWorkPhoneAllowed', checked);
		this.setControlIfEnabled('generalWorkPhoneAllowed', checked);
	}

	setAllValidEmailOptions(checked: boolean) {
		this.setControlIfEnabled('appointmentsEmailAllowed', checked);
		this.setControlIfEnabled('recallsEmailAllowed', checked);
		this.setControlIfEnabled('ordersEmailAllowed', checked);
		this.setControlIfEnabled('generalEmailAllowed', checked);
	}

	setAllValidMailOptions(checked: boolean) {
		this.setControlIfEnabled('appointmentsMailAllowed', checked);
		this.setControlIfEnabled('recallsMailAllowed', checked);
		this.setControlIfEnabled('ordersMailAllowed', checked);
		this.setControlIfEnabled('generalMailAllowed', checked);
	}

	setAllValidTextOptions(checked: boolean) {
		this.setControlIfEnabled('appointmentsTextAllowed', checked);
		this.setControlIfEnabled('recallsTextAllowed', checked);
		this.setControlIfEnabled('ordersTextAllowed', checked);
		this.setControlIfEnabled('generalTextAllowed', checked);
	}

	setControlIfEnabled(controlName: string, value: boolean) {
		if (this.componentForm.get(controlName).enabled) {
			this.componentForm.get(controlName).setValue(value);
			this.componentForm.get(controlName).markAsDirty();
		}
	}

	isOptedIn(controlName: string) {
		if (!_isNil(this.componentForm.get(controlName).value)) {
			return this.componentForm.get(controlName).value.value === PatientCommunicationOptIn.YES.value;
		}

		return false;
	}

	buildDoNotTextTooltip() {
		if (this.featureService.isFeatureOff(FeatureFlagApplicationKey.REVCONNECT_ANTI_SPAM.value) || this.componentForm.get('doNotText').enabled) {
			return null;
		} else if (this.patientResponse?.cellPhoneDeactivatedDate) {
			return 'Resolve carrier deactivated status to edit opt out.';
		} else if (this.patientResponse?.cellPhoneStoppedDate) {
			return 'Patient has unsubscribed from text messages and must reply START to resubscribe.';
		} else {
			return null;
		}
	}
}
