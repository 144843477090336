import { AccountingService } from '@accounting/core/accounting/accounting.service';
import { PaymentContainerComponent } from '@accounting/invoices/receive-payments/payment-container/payment-container.component';
import { ReceivePaymentsPayer } from '@accounting/invoices/receive-payments/receive-payments.service';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { PaymentComponentService } from '@core/accounting/payment/payment-component.service';
import { DebounceSearch } from '@core/debounce/debounce-search';
import { EnumUtil, GridUtil, LoadingOverlayMethod, ModalManagerService } from 'morgana';
import { _find, _isEmpty, _isNil } from '@core/lodash/lodash';
import { UserLocationsService } from '@core/user-locations/user-locations.service';
import { ReceivePaymentPayerResponse } from '@gandalf/model/receive-payment-payer-response';
import { PaymentGroupSourceType, PaymentSearchPayerType } from '@gandalf/constants';
import { PaymentTabState, ReceivePaymentState } from '@payments-store/reducers/payment-tab.reducer';
import { BaseComponent } from '@shared/component/base.component';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'pms-payment',
	templateUrl: './payment.component.html',
	providers: [ModalManagerService, PaymentComponentService],
})
export class PaymentComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

	@Input()
	paymentGroupId: number;

	@Input()
	state: PaymentTabState;

	@Output()
	stateChange = new EventEmitter<PaymentTabState>();

	@Output()
	closePayment: EventEmitter<void> = new EventEmitter();

	@ViewChild('payerGrid')
	payerGrid: AgGridAngular;

	@ViewChild('paymentContainer')
	paymentContainer: PaymentContainerComponent;

	_isLoading: boolean;
	selectedLocationId: number;
	selectedLocationName: string;
	payerType: PaymentSearchPayerType;
	manuallySelectInvoices = false;
	nameFilter = '';
	debouncedFilter = new DebounceSearch(() => this.filterTable());
	payers: ReceivePaymentPayerResponse[];
	selectedPayer: ReceivePaymentsPayer;
	receivePaymentState: ReceivePaymentState;
	gridOptions: GridOptions;

	constructor(
		private accountingService: AccountingService,
		private userLocationsService: UserLocationsService,
		public paymentComponentService: PaymentComponentService,
	) {
		super();
	}

	ngOnInit() {
		this.initSubscriptions();
		this.setupPaymentFromState();
	}

	ngAfterViewInit() {
		this.initGrid();
	}

	ngOnDestroy() {
		this.emitState();
		super.ngOnDestroy();
	}

	initGrid() {
		this.gridOptions = GridUtil.buildGridOptions({
			rowClass: 'row-link',
			pagination: false,
			rowSelection: 'single',
			onRowDataUpdated: this.filterTable,
			onRowClicked: this.selectPayer,
			columnDefs: [
				GridUtil.buildColumn('Type', 'type.label', {
					sortable: true,
					resizable: true,
				}),
				GridUtil.buildColumn('Name', 'name', {
					sortable: true,
					resizable: true,
					flex: 1,
				}),
				GridUtil.buildColumn('Payer ID', 'insurancePayerId', {
					sortable: true,
					resizable: true,
					hide: this.showInsurancePayerId(),
				}),
			],
		});
	}

	initSubscriptions() {
		this.paymentComponentService.receivePaymentStateChanged.pipe(takeUntil(this.unsubscribe)).subscribe(state => this.receivePaymentState = state);
		this.paymentComponentService.paymentClosed.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.closePayment.emit());
	}

	setupPaymentFromState() {
		if (this.paymentGroupId <= 0) {
			this.paymentGroupId = null;
		}
		this.selectedPayer = this.state.selectedPayer as any;
		if (!this.isPayerSelected) {
			// Get state information and setup the tab
			this.selectedLocationId = this.state.locationId;
			if (!_isNil(this.selectedLocationId)) {
				this.selectedLocationName = _find(this.userLocationsService.getUserActiveLocations(), (location) => location.value === this.selectedLocationId).label;
			} else {
				this.selectedLocationName = 'All';
			}
			this.payerType = this.state.payerType;
			this.nameFilter = this.state.nameFilter;
			this.manuallySelectInvoices = this.state.manuallySelect;
			this.findPayers().subscribe(payers => {
				this.payers = payers;
			});
		}
	}

	get isPayerSelected() {
		return !_isNil(this.selectedPayer) || !_isNil(this.paymentGroupId);
	}

	filterTable = () => {
		GridUtil.applyTextContainsFilter(this.payerGrid, this.applyNameFilter, 'name', this.nameFilter);
	};

	applyNameFilter = () => !_isEmpty(this.nameFilter);

	@LoadingOverlayMethod()
	findPayers() {
		return this.accountingService.findReceivePaymentPayers(this.selectedLocationId, this.payerType);
	}

	get paymentTitle() {
		return this.isPayerSelected ? this.paymentComponentService.paymentTitle : `Select Payer (Location: ${this.selectedLocationName})`;
	}

	debounceFilter() {
		this.debouncedFilter.valueChanged.next({nameFilter: this.nameFilter});
	}

	clear() {
		this.nameFilter = '';
		this.filterTable();
	}

	selectPayer = (event) => {
		const invoicePayer = event.data as ReceivePaymentPayerResponse;
		this.selectedPayer = {
			payerId: invoicePayer.id,
			entityId: invoicePayer.entityId,
			type: invoicePayer.type,
			defaultPaymentMethod: invoicePayer.defaultPaymentMethod,
			name: invoicePayer.name,
			sourceType: this.getPayerSourceType(),
			sourcePracticeLocationId: this.selectedLocationId,
		} as ReceivePaymentsPayer;
	};

	getPayerSourceType() {
		if (this.manuallySelectInvoices) {
			return PaymentGroupSourceType.MANUAL;
		} else if (!_isNil(this.selectedLocationId)) {
			return PaymentGroupSourceType.LOCATION;
		} else {
			return PaymentGroupSourceType.PAYER;
		}
	}

	emitState() {
		this.stateChange.emit(new PaymentTabState(
			this.paymentGroupId,
			this.payerType,
			this.selectedLocationId,
			{
				nameFilter: this.nameFilter,
				manuallySelect: this.manuallySelectInvoices,
				selectedPayer: this.selectedPayer,
				receivePaymentState: this.receivePaymentState,
			} as any,
		));
	}

	showInsurancePayerId() {
		return !EnumUtil.equals(this.payerType, PaymentSearchPayerType.INSURANCE);
	}
}
