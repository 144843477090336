<ejs-dialog #modal
	(close)="closeModal(action.CANCEL)"
	cssClass="modal-sm"
	[attr.data-test-id]="'unsavedChangesModal'">
	<ng-template #header>
		<div class="dlg-template">Unsaved Changes</div>
	</ng-template>
	<ng-template #content>
		<p>Unsaved changes were detected. Would you like to save your changes before you proceed?</p>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button type="button" iconCss="fa fa-close" (click)="closeModal(action.CANCEL)" [attr.data-test-id]="'unsavedChangesCancelButton'">Cancel</button>
		<button ejs-button type="button" cssClass="e-danger" (click)="closeModal(action.DISCARD)" [attr.data-test-id]="'unsavedChangesDiscardButton'">Discard</button>
		<button ejs-button type="button" [isPrimary]="true" (click)="closeModal(action.SAVE)" [attr.data-test-id]="'unsavedChangesSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>
