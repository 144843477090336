import { Injector, Type } from '@angular/core';
import { StatefulBaseComponent } from '@app-store/stateful-base.component';

export interface StatefulComponentWithParent extends StatefulBaseComponent {
	injector: Injector;
}

export const registerStatefulChildKey = Symbol('RegisterStatefulChild');

export interface RegisterStatefulChildParams {
	parents: Type<StatefulBaseComponent>[];
}

export function RegisterStatefulComponentChild(params: RegisterStatefulChildParams) {
	return function <T extends new(...args: any[]) => StatefulComponentWithParent>(target: T) {
		Reflect.defineMetadata(registerStatefulChildKey, params, target);
	};
}
