import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data, Params, RouterStateSnapshot } from '@angular/router';
import { RouterState } from '@app-store/reducers';
import { RouterStateSerializer } from '@ngrx/router-store';

@Injectable()
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterState> {
	serialize(routerState: RouterStateSnapshot): RouterState {
		const { url } = routerState;
		const params = this.mergeRouteParams(routerState.root, r => r.params);
		const queryParams = this.mergeRouteParams(routerState.root, r => r.queryParams);
		const data = this.mergeRouteData(routerState.root);

		return { url, params, queryParams, data };
	}

	private mergeRouteParams(route: ActivatedRouteSnapshot, getter: (r: ActivatedRouteSnapshot) => Params): Params {
		if (!route) {
			return {};
		}
		const currentParams = getter(route);
		const primaryChild = route.children.find(c => c.outlet === 'primary') || route.firstChild;
		return {...currentParams, ...this.mergeRouteParams(primaryChild, getter)};
	}

	private mergeRouteData(route: ActivatedRouteSnapshot): Data {
		if (!route) {
			return {};
		}
		const currentData = route.data;
		const primaryChild = route.children.find(c => c.outlet === 'primary') || route.firstChild;
		return {...currentData, ...this.mergeRouteData(primaryChild)};
	}
}
