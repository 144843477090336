import { AccountingService } from '@accounting/core/accounting/accounting.service';
import { InvoiceService } from '@accounting/core/accounting/invoice-service/invoice.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, UntypedFormGroup } from '@angular/forms';
import { DialogUtil, DynamicModalRef, EnumUtil, ModalConfig, ModalManagerService } from 'morgana';
import { OpenEdgePaymentService } from '@core/open-edge-payment/open-edge-payment.service';
import { VoidPaymentRequest } from '@gandalf/model/void-payment-request';
import { OpenEdgeCommonVoidStatus, PaymentMethodCreditCardType } from '@gandalf/constants';
import { OpenEdgeVoidTransactionResponse } from '@gandalf/model/open-edge-void-transaction-response';
import { PrintUtilService } from '@shared/Utils/print-util.service';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';

export class OpenEdgeManualVoidModalResult {
	manualVoidProcessRequested = false;
}

const UNHANDLED_ERROR_TEXT = 'An Error has Occurred. Select "Retry" to continue.';
const REFUND_RESULT_TEXT = 'The payment was voided successfully. The cardholder will see a charge posted to their account,' +
	' which will be refunded in 3-5 business days.';
const VOID_RESULT_TEXT = 'The payment was voided successfully. The cardholder may see a pending charge on their account,' +
	' which will be removed within 1-3 business days.';

@Component({
	selector: 'pms-open-edge-void-payment-modal',
	templateUrl: './open-edge-void-payment-modal.component.html',
	providers: [ModalManagerService],
	styles: [],
})
export class OpenEdgeVoidPaymentModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('templateForm')
	templateForm: NgForm;

	invoiceId: number;
	paymentId: number;
	payerName: string;
	paymentMethodCardType: PaymentMethodCreditCardType;
	paymentAmount: number;
	last4CreditCard: string;

	componentForm: UntypedFormGroup;
	voidPaymentRequest: VoidPaymentRequest;
	isVoiding = false;
	errorMessage: string;

	constructor(
		private ref: DynamicModalRef,
		private modalConfig: ModalConfig,
		private gandalfFormBuilder: GandalfFormBuilder,
		private openEdgePaymentService: OpenEdgePaymentService,
		private accountingService: AccountingService,
		private invoiceService: InvoiceService,
		private printUtilService: PrintUtilService,
	) {

	}

	ngOnInit() {
		this.parseModalConfig(this.modalConfig.data);
		this.createForm();
		this.initializeForm();
	}

	parseModalConfig(data: any) {
		this.invoiceId = data.invoiceId;
		this.paymentId = data.paymentId;
		this.payerName = data.payerName;
		this.paymentMethodCardType = data.paymentMethodCardType;
		this.paymentAmount = data.paymentAmount;
		this.last4CreditCard = data.last4CreditCard;
	}

	createForm() {
		this.voidPaymentRequest = new VoidPaymentRequest();
		this.voidPaymentRequest.paymentId = this.paymentId;
		this.componentForm = this.gandalfFormBuilder.group(this.voidPaymentRequest);
	}

	/**
	 * Used to initialize any form values
	 *   - set comments value to empty string so that its length starts at 0 as this is used in the view
	 */
	initializeForm() {
		this.componentForm.controls.comments.setValue('');
	}

	/* istanbul ignore next */
	submitForm(event: any) {
		this.templateForm.onSubmit(event);
	}

	/* istanbul ignore next */
	closeModal(result?) {
		this.ref.close(this.modal, result);
	}

	voidPayment() {
		if (this.componentForm.invalid) {
			return;
		}

		this.errorMessage = null;
		this.isVoiding = true;
		this.openEdgePaymentService.voidSaleTransaction(this.paymentId).subscribe({
			next: response => this.handleOpenEdgeVoidResponse(response),
			error: () => this.displayError(UNHANDLED_ERROR_TEXT),
		});
	}

	handleOpenEdgeVoidResponse(response: OpenEdgeVoidTransactionResponse) {
		const request = this.componentForm.value as VoidPaymentRequest;
		if (EnumUtil.equalsOneOf(response.result, OpenEdgeCommonVoidStatus.VOIDED, OpenEdgeCommonVoidStatus.RETURNED)) {
			request.paymentTransactionId = response.paymentTransactionId;
			this.accountingService.voidPayment(request)
				.subscribe({
					next: () => this.onVoidSuccess(response),
					error: () => this.displayError(UNHANDLED_ERROR_TEXT),
				});
		} else if (EnumUtil.equals(response.result, OpenEdgeCommonVoidStatus.ERROR)) {
			this.displayError(`Error: ${response.errorMessage}`);
		}
	}

	displayError(msg: string) {
		this.errorMessage = msg;
		this.isVoiding = false;
	}

	onVoidSuccess(response: OpenEdgeVoidTransactionResponse) {
		const dialogContent = EnumUtil.equals(response.result, OpenEdgeCommonVoidStatus.VOIDED) ? VOID_RESULT_TEXT : REFUND_RESULT_TEXT;
		this.invoiceService.refreshInvoice(this.invoiceId);
		this.closeModal();
		DialogUtil.alert({
			title: 'Successfully Processed',
			content: dialogContent,
			close: () => this.printUtilService.printFormattedText(response.customerReceipt),
		});
	}

	get primaryButtonText() {
		return this.errorMessage ? 'Retry' : 'Void';
	}

	applyManualVoid() {
		const openEdgeManualVoidModalResult = new OpenEdgeManualVoidModalResult();
		openEdgeManualVoidModalResult.manualVoidProcessRequested = true;

		this.closeModal(openEdgeManualVoidModalResult);
	}
}
