// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { OpticalOrderPodOrderResponse } from './optical-order-pod-order-response';

/** See com.rev360.pms.api.controller.patient.opticalsummary.OpticalOrderPodFrameResponse */
/* istanbul ignore next */
@GandalfModel
export class OpticalOrderPodFrameResponse extends OpticalOrderPodOrderResponse {

	@GandalfProperty()
	comments: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	createdOn: Date;

	@GandalfProperty()
	frameBrand: string;

	@GandalfProperty()
	frameColor: string;

	@GandalfProperty()
	frameEye: number;

	@GandalfProperty()
	frameModel: string;

	@GandalfProperty()
	instructions: string;

	@GandalfProperty()
	onHold: boolean;

	@GandalfProperty()
	orderId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	orderType: constants.OrderType;

	@GandalfConstantDecorator()
	@GandalfProperty()
	source: constants.OrderFrameSource;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.OrderStatusCode;

	@GandalfProperty({ propertyType: 'LocalDate' })
	statusDate: Date;

}
