// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.patient.PatientBirthdaySearchRequest */
/* istanbul ignore next */
@GandalfModel
export class PatientBirthdaySearchRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Birth month cannot be null' } })
	@GandalfProperty({ isRequired: true })
	birthMonth: number;

	@GandalfProperty()
	locationId: number;

	@GandalfValidator({ min: { min: 0, message: 'Maximum age must be greater than or equal to 0' } })
	@GandalfProperty()
	maximumAge: number;

	@GandalfValidator({ min: { min: 0, message: 'Minimum age must be greater than or equal to 0' } })
	@GandalfProperty()
	minimumAge: number;

}
