import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { DynamicModalRef } from 'morgana';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';
import { CreateGuestInvoiceRequest } from '@gandalf/model/create-guest-invoice-request';
import { AccountingService } from '../../core/accounting/accounting.service';

@Component({
	selector: 'pms-new-guest-invoice-modal',
	templateUrl: './new-guest-invoice-modal.component.html',
	styles: [],
})
export class NewGuestInvoiceModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('templateForm')
	templateForm: NgForm;

	request: CreateGuestInvoiceRequest;
	formGroup: UntypedFormGroup;

	constructor(
		private ref: DynamicModalRef,
		public formBuilder: GandalfFormBuilder,
		private accountingService: AccountingService,
	) {
	}

	ngOnInit() {
		this.buildRequest();
		this.buildForm();
	}

	closeModal(result?) {
		this.ref.close(this.modal, result);
	}

	buildRequest() {
		this.request = new CreateGuestInvoiceRequest();
	}

	buildForm() {
		this.formGroup = this.formBuilder.group(this.request);
	}

	/* istanbul ignore next */
	submitForm(event) {
		this.templateForm.onSubmit(event);
	}

	createGuestInvoice() {
		if (this.formGroup.invalid) {
			return;
		}

		const createGuestInvoiceRequest: CreateGuestInvoiceRequest = this.formGroup.getRawValue();
		this.accountingService.createGuestInvoice(createGuestInvoiceRequest).subscribe((newInvoice) => {
			this.closeModal(newInvoice);
		});
	}

}
