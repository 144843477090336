import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';

export type HydrationPredicate<R = any> = (value: R) => boolean;

export interface GandalfCacheableConfigurableOptions<R = any> {
	hydrationPredicate?: HydrationPredicate<R>;
	useCacheValue?: boolean;
}

export class GandalfCacheableOptions<Request, Response = any> {
	request: Request;

	/**
	 * Hydration Predicate
	 * This defines the logic that will be used to determine whether or not the value of the cache should be used,
	 * or if the cache should be (re)hydrated by calling through to the endpoint.
	 */
	hydrationPredicate: HydrationPredicate<Response>;

	/**
	 * Use Cache Value
	 * The value provided here determines whether or not the service call should result in a call to the API or not.
	 * This provides developers with a way to override the cache and force a service call to make the call to the API.
	 */
	useCacheValue = true;

	constructor(request: Request) {
		this.request = request;
		this.hydrationPredicate = this.buildDefaultHydrationPredicate();
	}

	buildDefaultHydrationPredicate() {
		return (value: Response) => isUndefined(value);
	}

	mergeConfigurableOptions(options: GandalfCacheableConfigurableOptions<Response>) {
		this.hydrationPredicate = get(options, ['hydrationPredicate'], this.hydrationPredicate);
		this.useCacheValue = get(options, ['useCacheValue'], this.useCacheValue);
	}
}
