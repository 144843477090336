/* eslint-disable @typescript-eslint/naming-convention */

import { PatientDashboardSummaryResponse } from '@gandalf/model/patient-dashboard-summary-response';
import { Action } from '@ngrx/store';

export enum PatientDashboardActionTypes {
	GET_PATIENT_DASHBOARD_SUMMARY = '[Patient Dashboard Summary] Get Patient Dashboard Summary',
	GET_PATIENT_DASHBOARD_SUMMARY_SUCCESS = '[Patients Dashboard Summary Success] Get Patient Dashboard Summary Success',
	GET_PATIENT_DASHBOARD_SUMMARY_FAILURE = '[Patients Dashboard Summary Failure] Get Patient Dashboard Summary Failure',
	REMOVE_PATIENT_DASHBOARD = '[Remove Patient Dashboard] Remove Patient Dashboard',
}

export class GetPatientDashboardSummary implements Action {
	readonly type = PatientDashboardActionTypes.GET_PATIENT_DASHBOARD_SUMMARY;
	constructor(public payload: { patientId: number }) {}
}

export class GetPatientDashboardSummary_Success implements Action {
	readonly type = PatientDashboardActionTypes.GET_PATIENT_DASHBOARD_SUMMARY_SUCCESS;
	constructor(public payload: { patientId: number, dashboardSummary: PatientDashboardSummaryResponse }) {}
}

export class GetPatientDashboardSummary_Failure implements Action {
	readonly type = PatientDashboardActionTypes.GET_PATIENT_DASHBOARD_SUMMARY_FAILURE;
	constructor(public payload: any) {}
}

export class RemovePatientDashboard implements Action {
	readonly type = PatientDashboardActionTypes.REMOVE_PATIENT_DASHBOARD;
	constructor(public payload: { patientId: number }) {}
}

export type PatientDashboardActions =
	GetPatientDashboardSummary
	| GetPatientDashboardSummary_Success
	| GetPatientDashboardSummary_Failure
	| RemovePatientDashboard;
