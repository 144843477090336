import { AbstractControl, AbstractControlOptions, AsyncValidatorFn, UntypedFormArray, ValidatorFn } from '@angular/forms';
import isNil from 'lodash/isNil';
import { GandalfModelBase } from '../gandalf-model-base.class';
import { GandalfFormBuilder } from './gandalf-form-builder.service';

export class GandalfFormArray extends UntypedFormArray {

	requestItemOptions: AbstractControlOptions | null = null;

	/* istanbul ignore next */
	constructor(
		private formBuilder: GandalfFormBuilder,
		private arrayType: new() => GandalfModelBase,
		controls: AbstractControl[],
		validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
		asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
	) {
		super(controls, validatorOrOpts, asyncValidator);
	}

	pushRequestItem(requestObject: GandalfModelBase, options?: { emitEvent?: boolean }) {
		const group = this.formBuilder.group(requestObject, this.requestItemOptions);
		this.push(group, options);
		return group;
	}

	pushRequestItems(requestObjects: GandalfModelBase[], options?: { emitEvent?: boolean }) {
		for (const requestObject of requestObjects) {
			this.pushRequestItem(requestObject, options);
		}
	}

	reset(value?: any[], options?: { onlySelf?: boolean, emitEvent?: boolean }) {
		this.checkLength(value, options);
		super.reset(value, options);
	}

	setValue(value: any[], options?: { onlySelf?: boolean, emitEvent?: boolean }) {
		this.checkLength(value, options);
		super.setValue(value, options);
	}

	checkLength(value?: any[], options?: { onlySelf?: boolean, emitEvent?: boolean }) {
		if (value?.length !== this.length) {
			this.clear(options);
			if (!isNil(value)) {
				this.pushRequestItems(value.map(() => new this.arrayType()), options);
			}
		}
	}
}
