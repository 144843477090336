/* eslint-disable @typescript-eslint/naming-convention */

import { EncounterTabSubType } from '@encounters-store/reducers/encounter-tab.reducer';
import { Action } from '@ngrx/store';

export enum EncounterTabActionTypes {
	ADD_ENCOUNTER_TAB = '[Add Encounter Tab] Add Encounter Tab to the Encounters Tab Collection',
	REMOVE_ENCOUNTER_TAB = '[Remove Encounter Tab] Remove Encounter Tab from the Encounters Tab Collection',
	UPDATE_ENCOUNTER_TAB_CURRENT_ROUTE = '[Update Encounter Tab Current Route] Update the Encounter Tab Current Route',
}

export class AddEncounterTab implements Action {
	readonly type = EncounterTabActionTypes.ADD_ENCOUNTER_TAB;
	constructor(public payload: {
		patientId: number;
		tabSubType: EncounterTabSubType;
		objectId: number;
		headerText: string;
	}) {}
}

export class RemoveEncounterTab implements Action {
	readonly type = EncounterTabActionTypes.REMOVE_ENCOUNTER_TAB;
	constructor(public payload: {
		tabSubType: EncounterTabSubType;
		patientId: number;
		objectId: number;
	}) {}
}

export class UpdateEncounterTabCurrentRoute implements Action {
	readonly type = EncounterTabActionTypes.UPDATE_ENCOUNTER_TAB_CURRENT_ROUTE;
	constructor(public payload: {
		tabSubType: EncounterTabSubType;
		objectId: number;
		currentRoute: string;
	}) {}
}

export type EncounterTabActions =
	| AddEncounterTab
	| RemoveEncounterTab
	| UpdateEncounterTabCurrentRoute;
