import { LegacyStatefulComponentUtilsService } from '@app-store/utils/legacy-stateful-component-utils.service';
import { AppointmentResponse } from '@gandalf/model/appointment-response';
import { AppointmentDetailActions, AppointmentDetailActionTypes } from '@patients-store/actions/appointment-detail.actions';
import { LegacyStatefulComponentBaseState } from '@shared/decorators/legacy-stateful-component.decorator';

export class AppointmentDetailState extends LegacyStatefulComponentBaseState {
	appointment: AppointmentResponse;
}

export type AppointmentDetailStateMap = Map<number, Map<number, AppointmentDetailState>>;

export const initialState: AppointmentDetailStateMap = new Map();

export function reducer(state = initialState, action: AppointmentDetailActions): AppointmentDetailStateMap {
	switch (action.type) {

		case AppointmentDetailActionTypes.UPDATE_APPOINTMENT_DETAIL_FORM_VALUE: {
			const appointmentDetailStateMap = new Map(state);
			const appointmentDetailStateEntry = appointmentDetailStateMap.get(action.payload.patientId)
				|| new Map([[action.payload.updateFormValuePayload.objectId, new AppointmentDetailState()]]);
			const appointmentDetailState = LegacyStatefulComponentUtilsService.updateFormValue(
				appointmentDetailStateEntry,
				AppointmentDetailState,
				action.payload.updateFormValuePayload,
			);
			appointmentDetailStateMap.set(action.payload.patientId, appointmentDetailState);
			return appointmentDetailStateMap;
		}

		case AppointmentDetailActionTypes.UPDATE_APPOINTMENT_DETAIL_FORM_SUBMITTED: {
			const appointmentDetailStateMap = new Map(state);
			const appointmentDetailStateEntry = appointmentDetailStateMap.get(action.payload.patientId)
				|| new Map([[action.payload.updateFormSubmittedPayload.objectId, new AppointmentDetailState()]]);
			const appointmentDetailState = LegacyStatefulComponentUtilsService.updateFormSubmitted(
				appointmentDetailStateEntry,
				AppointmentDetailState,
				action.payload.updateFormSubmittedPayload,
			);
			appointmentDetailStateMap.set(action.payload.patientId, appointmentDetailState);
			return appointmentDetailStateMap;
		}

		case AppointmentDetailActionTypes.REMOVE_APPOINTMENT_DETAIL_FORM_VALUE: {
			const appointmentDetailStateMap = new Map(state);
			const appointmentDetailStateEntry = appointmentDetailStateMap.get(action.payload.patientId)
				|| new Map([[action.payload.removeFormValuePayload.objectId, new AppointmentDetailState()]]);
			const appointmentDetailState = LegacyStatefulComponentUtilsService.removeFormValue(
				appointmentDetailStateEntry,
				action.payload.removeFormValuePayload,
			);
			appointmentDetailStateMap.set(action.payload.patientId, appointmentDetailState);
			return appointmentDetailStateMap;
		}

		case AppointmentDetailActionTypes.UPDATE_APPOINTMENT_DETAIL_PROPERTY: {
			const appointmentDetailStateMap = new Map(state);
			const appointmentDetailStateEntry = appointmentDetailStateMap.get(action.payload.patientId)
				|| new Map([[action.payload.updateStatePropertyPayload.objectId, new AppointmentDetailState()]]);
			const appointmentDetailState = LegacyStatefulComponentUtilsService.updateProperty(
				appointmentDetailStateEntry,
				AppointmentDetailState,
				action.payload.updateStatePropertyPayload,
			);
			appointmentDetailStateMap.set(action.payload.patientId, appointmentDetailState);
			return appointmentDetailStateMap;
		}

		case AppointmentDetailActionTypes.REMOVE_APPOINTMENT_DETAIL: {
			const appointmentDetailStateMap = new Map(state);
			appointmentDetailStateMap.get(action.payload.patientId).delete(action.payload.appointmentId);
			return appointmentDetailStateMap;
		}

		default: {
			return state;
		}
	}
}
