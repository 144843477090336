import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { _find, _isNil } from '@core/lodash/lodash';
import { DynamicModalRef, ForceDecimalPipe, ModalConfig } from 'morgana';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { AddAdhocInvoiceItemRequest } from '@gandalf/model/add-adhoc-invoice-item-request';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountingService, FormattedTaxResponse } from '../../core/accounting/accounting.service';
import { InvoiceItemPricingUtil } from '../../core/accounting/invoice-item-pricing/invoice-item-pricing-util';
import { InvoiceService } from '../../core/accounting/invoice-service/invoice.service';

@Component({
	selector: 'pms--modal',
	templateUrl: './adhoc-item-modal.component.html',
	styles: [],
})

export class AdhocItemModalComponent implements OnInit, OnDestroy {

	@ViewChild('modal')
	modal: DialogComponent;
	@ViewChild('templateForm')
	templateForm: NgForm;
	invoiceId: number;
	locationId: number;
	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	locationTaxList: FormattedTaxResponse[];
	unsubscribe$ = new Subject<void>();
	componentForm: UntypedFormGroup;
	addAdhocItemRequest: AddAdhocInvoiceItemRequest;
	totalString = '0.00';
	subTotalString = '0.00';

	constructor(
		private ref: DynamicModalRef,
		private invoiceService: InvoiceService,
		private modalConfig: ModalConfig,
		private accountingService: AccountingService,
		private securityManagerService: SecurityManagerService,
		private gandalfFormBuilder: GandalfFormBuilder,
		private forceDecimalPipe: ForceDecimalPipe,
	) {
	}

	ngOnInit() {
		this.invoiceId = this.modalConfig.data.invoiceId;
		this.locationId = this.modalConfig.data.locationId;
		this.getLocationTaxes();
		this.addAdhocItemRequest = this.prepareInitialRequest();
		this.initializeForm(this.addAdhocItemRequest);
	}

	prepareInitialRequest(): AddAdhocInvoiceItemRequest {
		const request = new AddAdhocInvoiceItemRequest();
		request.invoiceId = this.invoiceId;
		request.postDate = new Date();
		request.quantity = 1;
		request.personId = this.securityManagerService.getUserSession().userEntityPersonId;
		return request;
	}

	initializeForm(request) {
		this.componentForm = this.gandalfFormBuilder.group(request);
		this.handleFormState();
	}

	handleFormState() {
		FormUtilsService.reactToValueChanges(this.componentForm, () => this.updateSubTotalAndTotal());
	}

	updateSubTotalAndTotal() {
		const unitPrice = this.componentForm.get('unitPrice').value;
		const quantity = this.componentForm.get('quantity').value;
		const taxRate = this.getLocationTaxRate();
		if (!_isNil(unitPrice) && !_isNil(quantity)) {
			const subTotal = InvoiceItemPricingUtil.calculateSubTotal(unitPrice, quantity).toString();
			const total = InvoiceItemPricingUtil.calculateExtPrice(unitPrice, quantity, 0, taxRate).toString();
			this.subTotalString = this.forceDecimalPipe.transform(subTotal, 2, '0.00');
			this.totalString = this.forceDecimalPipe.transform(total, 2, '0.00');
		}
	}

	getLocationTaxRate(): number {
		const taxId = this.componentForm.get('taxId').value;
		if (!_isNil(taxId)) {
			const locationTax = _find(this.locationTaxList, ['id', taxId]);
			return locationTax.rate;
		}
	}

	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	getLocationTaxes() {
		this.accountingService.findTaxesByPracticeLocationId(this.locationId).pipe(takeUntil(this.unsubscribe$)).subscribe((taxes) => {
			this.locationTaxList = taxes;
		});
	}

	closeModal() {
		this.ref.close(this.modal);
	}

	submitForm(event) {
		this.templateForm.onSubmit(event);
	}

	save() {
		if (this.componentForm.invalid) {
			return;
		}

		const request = this.componentForm.value as AddAdhocInvoiceItemRequest;
		this.accountingService.addInvoiceItemAdhoc(request).subscribe(_data => {
			this.invoiceService.refreshInvoice(this.invoiceId);
			this.closeModal();
		});
	}
}
