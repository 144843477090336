// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.encounter.testlibrary.AdminEditWorkflowTestTemplateFieldOptionRequest */
/* istanbul ignore next */
@GandalfModel
export class AdminEditWorkflowTestTemplateFieldOptionRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Active is required' } })
	@GandalfProperty({ isRequired: true })
	active: boolean;

	@GandalfValidator({ notNull: { message: 'Display Order is required' } })
	@GandalfProperty({ isRequired: true })
	displayOrder: number;

	@GandalfValidator({ notNull: { message: 'Option is required' } })
	@GandalfProperty({ isRequired: true })
	id: number;

	@GandalfValidator({ notNull: { message: 'Abnormal Finding is required' } })
	@GandalfProperty({ isRequired: true })
	isAbnormal: boolean;

	@GandalfValidator({ notNull: { message: 'Default Normal is required' } })
	@GandalfProperty({ isRequired: true })
	isDefaultNormal: boolean;

}
