import { Action } from '@ngrx/store';

export enum CacheKeyActionTypes {
	ADD_CACHE_KEY_ENDPOINT = '[Add Cache Key Endpoint] Add an endpoint to the associated cache key map.',
	REMOVE_CACHE_KEY = '[Remove Cache Key] Remove a Cache Key.',
}

export class AddCacheKeyEndpoint {
	readonly type = CacheKeyActionTypes.ADD_CACHE_KEY_ENDPOINT;
	constructor(public payload: {cacheKey: string, endpointId: string}) {}
}

export class RemoveCacheKey implements Action {
	type = CacheKeyActionTypes.REMOVE_CACHE_KEY;
	constructor(public payload: {cacheKey: string}) {}
}

export type CacheKeyActions =
	AddCacheKeyEndpoint
	| RemoveCacheKey;
