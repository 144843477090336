import { Directive } from '@angular/core';
import { AnimationSettingsModel, DialogComponent, PositionDataModel } from '@syncfusion/ej2-angular-popups';
import { MODAL } from '../../constants/modal.constants';

const defaultAnimationSettings: AnimationSettingsModel = {
	effect: 'None',
};

const defaultPosition: PositionDataModel = {
	X: 'center',
	Y: 'top',
};

/* eslint-disable @angular-eslint/directive-selector */
@Directive({
	selector: 'ejs-dialog:not([revOverrideModalBase])',
})
export class ModalBaseDirective {

	constructor(
		private dialogComponent: DialogComponent,
	) {
		this.populateBaseProperties();
	}

	populateBaseProperties() {
		this.dialogComponent.showCloseIcon = true;
		this.dialogComponent.animationSettings = defaultAnimationSettings;
		this.dialogComponent.position = defaultPosition;
		this.dialogComponent.allowDragging = true;
		this.dialogComponent.isModal = true;
		this.dialogComponent.zIndex = MODAL.Z_INDEX_STANDARD;
		if (!this.dialogComponent.cssClass) {
			this.dialogComponent.cssClass = 'modal-md';
		}
	}
}
