import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { _pull } from '@core/lodash/lodash';
import { RouterUtilsService } from '@core/router-utils/router-utils.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { HIT_PMS_HTML_EVENTS } from '../legacy/hit-pms-html.constants';

@Injectable({
	providedIn: 'root',
})
export class FeatureModificationService {

	flagsToTurnOn: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
	flagsToTurnOff: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

	constructor(
		private router: Router,
		private securityManager: SecurityManagerService,
		private eventsManager: EventsManagerService,
		private routerUtilsService: RouterUtilsService,
	) {
	}

	/*istanbul ignore next*/
	subscribeToRouteChanges() {
		this.routerUtilsService.observeNavigationEvents(NavigationStart, false)
			.pipe(take(2))
			.subscribe((event: NavigationStart) => {
				this.parseUrlParams(event);
			});

		this.eventsManager.subscribe(HIT_PMS_HTML_EVENTS.SECURITY.SECURITY_SESSION_STARTED, () => this.modifyFeatureFlags(), this);
	}

	parseUrlParams(event: NavigationStart) {
		const urlTree = this.router.parseUrl(event.url);
		const flagsToTurnOnParam = urlTree.queryParamMap.get('flagsOn');
		const flagsToTurnOffParam = urlTree.queryParamMap.get('flagsOff');

		if (flagsToTurnOnParam) {
			this.flagsToTurnOn.next(flagsToTurnOnParam.split(','));
		}
		if (flagsToTurnOffParam) {
			this.flagsToTurnOff.next(flagsToTurnOffParam.split(','));
		}
	}

	/* eslint-disable max-len */
	modifyFeatureFlags() {
		if (this.securityManager.checkLogin()) {
			this.securityManager.getUserSession().enabledFeatureFlags = this.securityManager.getUserSession().enabledFeatureFlags.concat(this.flagsToTurnOn.getValue());
			_pull(this.securityManager.getUserSession().enabledFeatureFlags, ...this.flagsToTurnOff.getValue());
		}
	}
}
