import { Injectable, Optional } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { EnumClass, EnumUtil, ObjectUtils, SortingService, YesNoPipe } from 'morgana';
import { _filter, _find, _findIndex, _get, _includes, _isArray, _isFinite, _isNil, _map, _maxBy, _union, _uniq } from '@core/lodash/lodash';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { FieldValueBooleanRequest } from '@gandalf/model/field-value-boolean-request';
import { FieldValueDateRequest } from '@gandalf/model/field-value-date-request';
import { FieldValueDecimalRequest } from '@gandalf/model/field-value-decimal-request';
import { FieldValueLargeStringRequest } from '@gandalf/model/field-value-large-string-request';
import { FieldValueMultiSelectionRequest } from '@gandalf/model/field-value-multi-selection-request';
import { FieldValueRequest } from '@gandalf/model/field-value-request';
import { FieldValueSelectionRequest } from '@gandalf/model/field-value-selection-request';
import { FieldValueSmallStringRequest } from '@gandalf/model/field-value-small-string-request';
import { UpdateWorkflowTestsRequest } from '@gandalf/model/update-workflow-tests-request';
import { WorkflowTestRequest } from '@gandalf/model/workflow-test-request';
import { FieldDataType, FieldUIComponentType, FormFieldDefaultNormal } from '@gandalf/constants';
import { AddDynamicTestToDynamicScreenRequest } from '@gandalf/model/add-dynamic-test-to-dynamic-screen-request';
import { CreateEyeglassPrescriptionFromRefractionRequest } from '@gandalf/model/create-eyeglass-prescription-from-refraction-request';
import { DynamicScreenResponse } from '@gandalf/model/dynamic-screen-response';
import { DynamicTestResponse } from '@gandalf/model/dynamic-test-response';
import { FieldOptionResponse } from '@gandalf/model/field-option-response';
import { FieldValueBooleanResponse } from '@gandalf/model/field-value-boolean-response';
import { FieldValueDateResponse } from '@gandalf/model/field-value-date-response';
import { FieldValueDecimalResponse } from '@gandalf/model/field-value-decimal-response';
import { FieldValueLargeStringResponse } from '@gandalf/model/field-value-large-string-response';
import { FieldValueMultiSelectionResponse } from '@gandalf/model/field-value-multi-selection-response';
import { FieldValueResponse } from '@gandalf/model/field-value-response';
import { FieldValueSelectionResponse } from '@gandalf/model/field-value-selection-response';
import { FieldValueSmallStringResponse } from '@gandalf/model/field-value-small-string-response';
import { FindWorkflowScreenRequest } from '@gandalf/model/find-workflow-screen-request';
import { FindWorkflowScreensRequest } from '@gandalf/model/find-workflow-screens-request';
import { FormComponentResponse } from '@gandalf/model/form-component-response';
import { FormFieldResponse } from '@gandalf/model/form-field-response';
import { FormFieldSelectionResponse } from '@gandalf/model/form-field-selection-response';
import { FormFieldStepperResponse } from '@gandalf/model/form-field-stepper-response';
import { DynamicScreenGandalfService, EyeglassPrescriptionGandalfService } from '@gandalf/services';
import { TimepickerFormatterService } from '@shared/directives/timepicker-formatter/timepicker-formatter.service';
import { NumberCustomFormatterPipe } from '@shared/pipes/number-custom-formatter/number-custom-formatter.pipe';
import { GandalfConstant, GandalfModel, GandalfProperty, GandalfValidator } from 'gandalf';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LocationLevelPrescriptionExpirationRequest } from '@gandalf/model/location-level-prescription-expiration-request';
import { DEFAULT_LABEL_HEIGHT, DEFAULT_LABEL_WIDTH, FORM_FIELD_DEFAULT_DIMENSIONS } from './form-field/form-field.constants';

export type IFormFieldResponse = FormFieldResponse | FormFieldSelectionResponse | FormFieldStepperResponse;

// The height of the header in a test
export const TEST_HEADER_HEIGHT = 25;

// The amount of padding (including borders) on both sides of a test
export const TEST_LAYOUT_PADDING = 14;

// The amount of padding to include at the bottom of the screen
export const SCREEN_LAYOUT_BOTTOM_PADDING = 20;

// The amount of space needed to display the header buttons
export const TEST_HEADER_BUTTONS_WIDTH = 164;

// The HTML border of the test is 1px on each side
export const HTML_BORDER_WIDTH = 2;

// The right eye
export const OD = 'OD';

// The left eye
export const OS = 'OS';

// Both eyes
export const OU = 'OU';

// A type that represents the eyes
export type FieldNameEyes = typeof OD | typeof OS | typeof OU;


export const ShouldFocusFieldSymbol = Symbol('FirstFieldInScreen');

@GandalfModel
export class FieldValueTimeRequest extends FieldValueSmallStringRequest {

	@GandalfValidator({ sizeString: { message: 'Value cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	value: string;

	@GandalfProperty()
	fieldId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	timeValue: Date;
}

@Injectable({
	providedIn: 'root',
})
export class DynamicScreenService {
	createdPresentIllnessSubject = new Subject<void>();
	private _triggeredServices = new Subject<boolean>();

	constructor(
		private dynamicScreenGandalfService: DynamicScreenGandalfService,
		private timepickerFormatterService: TimepickerFormatterService,
		private eyeglassPrescriptionGandalfService: EyeglassPrescriptionGandalfService,
		@Optional() private numberCustomFormatterPipe: NumberCustomFormatterPipe,
		@Optional() private yesNoPipe: YesNoPipe,
	) {
	}

	getDynamicScreenByWorkflowScreenId(request: FindWorkflowScreenRequest): Observable<DynamicScreenResponse> {
		return this.dynamicScreenGandalfService.getDynamicScreenByWorkflowScreenId(request).pipe(
			map(dynamicScreen => this.calculateWidthHeightForTests(dynamicScreen)),
			map(dynamicScreen => this.removeBlankSelectionOptionsForScreen(dynamicScreen)),
			map(dynamicScreen => this.sortForTabbing(dynamicScreen)),
		);
	}

	getDynamicScreensByWorkflowScreenIds(request: FindWorkflowScreensRequest): Observable<DynamicScreenResponse[]> {
		return this.dynamicScreenGandalfService.getDynamicScreensByWorkflowScreenIds(request).pipe(
			map(dynamicScreens => dynamicScreens.map(dynamicScreen => this.calculateWidthHeightForTests(dynamicScreen))),
			map(dynamicScreens => dynamicScreens.map(dynamicScreen => this.removeBlankSelectionOptionsForScreen(dynamicScreen))),
			map(dynamicScreens => dynamicScreens.map(dynamicScreen => this.sortForTabbing(dynamicScreen))),
		);
	}

	getDynamicScreenByWorkflowScreenTemplateId(workflowScreenTemplateId: number): Observable<DynamicScreenResponse> {
		return this.dynamicScreenGandalfService.getDynamicScreenByWorkflowScreenTemplateId(workflowScreenTemplateId).pipe(
			map(dynamicScreen => this.calculateWidthHeightForTests(dynamicScreen)),
			map(dynamicScreen => this.removeBlankSelectionOptionsForScreen(dynamicScreen)),
			map(dynamicScreen => this.sortForTabbing(dynamicScreen)),
		);
	}

	findDynamicScreenByOrderId(orderId: number): Observable<DynamicScreenResponse> {
		return this.dynamicScreenGandalfService.findDynamicScreenByOrderId(orderId).pipe(
			map(dynamicScreen => this.calculateWidthHeightForTests(dynamicScreen)),
			map(dynamicScreen => this.removeBlankSelectionOptionsForScreen(dynamicScreen)),
			map(dynamicScreen => this.sortForTabbing(dynamicScreen)),
		);
	}

	addDynamicTestToDynamicScreen(request: AddDynamicTestToDynamicScreenRequest): Observable<DynamicTestResponse> {
		return this.dynamicScreenGandalfService.addDynamicTestToDynamicScreen(request);
	}

	getDynamicTestPreview(testTemplateId: number): Observable<DynamicTestResponse> {
		return this.dynamicScreenGandalfService.getDynamicTestPreviewByTemplateId(testTemplateId).pipe(
			tap(dynamicTestResponse => {
				this.calculateWidthHeightForTest(dynamicTestResponse);
				this.removeBlankSelectionOptions(dynamicTestResponse);
				dynamicTestResponse.children = this.sortFieldsForTabbing(dynamicTestResponse.children);
			}),
		);
	}

	getDeviceMeasurementScreenById(deviceMeasurementId: number): Observable<DynamicScreenResponse> {
		return this.dynamicScreenGandalfService.getDeviceMeasurementScreenById(deviceMeasurementId).pipe(
			map(dynamicScreen => this.calculateWidthHeightForTests(dynamicScreen)),
			map(dynamicScreen => this.removeBlankSelectionOptionsForScreen(dynamicScreen)),
			map(dynamicScreen => this.sortForTabbing(dynamicScreen)),
		);
	}

	/* istanbul ignore next: gandalf */
	updateWorkflowTests(request: UpdateWorkflowTestsRequest) {
		return this.dynamicScreenGandalfService.updateWorkflowTests(request);
	}

	@ShowSavedSuccessToast()
	/* istanbul ignore next: gandalf */
	createPresentIllnessFromTriggeredScreen(request: UpdateWorkflowTestsRequest) {
		return this.dynamicScreenGandalfService.createPresentIllnessFromTriggeredScreen(request);
	}

	@ShowSavedSuccessToast()
	/* istanbul ignore next: gandalf */
	createPresentIllnessFromWizard(request: UpdateWorkflowTestsRequest) {
		return this.dynamicScreenGandalfService.createPresentIllnessFromWizard(request);
	}

	@ShowSavedSuccessToast()
	/* istanbul ignore next: gandalf */
	createEyeglassPrescriptionFromRefraction(request: CreateEyeglassPrescriptionFromRefractionRequest) {
		return this.dynamicScreenGandalfService.createEyeglassPrescriptionFromRefraction(request);
	}

	buildWorkflowTestRequest(dynamicTestResponse: DynamicTestResponse): WorkflowTestRequest {
		const workflowTestRequest = new WorkflowTestRequest();
		workflowTestRequest.workflowTestId = dynamicTestResponse.id;
		workflowTestRequest.comment = dynamicTestResponse.comment;
		workflowTestRequest.fileCount = dynamicTestResponse.fileCount;
		workflowTestRequest.values = this.buildFieldValueRequests(dynamicTestResponse.children, dynamicTestResponse.values);
		workflowTestRequest.version = dynamicTestResponse.version;
		return workflowTestRequest;
	}

	buildFieldValueRequests(
		children: FormComponentResponse[],
		values: FieldValueResponse[],
	): FieldValueRequest[] {
		const fields: IFormFieldResponse[] = this.getFormFieldResponses(children);
		return fields.map(field => {
			const fieldValueResponse: FieldValueResponse = this.getFieldValueResponse(field, values);
			return this.buildFieldValueRequest(field, fieldValueResponse);
		});
	}

	getFormFieldResponses(children: FormComponentResponse[]): IFormFieldResponse[] {
		const formFields: IFormFieldResponse[] = [];
		children.forEach(child => {
			if (!this.isFieldLabel(child)) {
				formFields.push(child as IFormFieldResponse);
			}
		});
		return formFields;
	}

	buildFormFieldValuePairs(
		children: FormComponentResponse[],
		values: FieldValueResponse[],
	) {
		const formFieldValuePairs = {};
		const fields: IFormFieldResponse[] = this.getFormFieldResponses(children);
		fields.forEach(field => {
			const fieldValueResponse = this.getFieldValueResponse(field, values);
			const value = this.getFormattedValueForFormField(
				field.uiComponentType,
				fieldValueResponse,
				field,
			);

			formFieldValuePairs[field.name] = value;
		});

		return formFieldValuePairs;
	}

	/**
	 * Returns true if the form component is a label (not an input field)
	 */
	isFieldLabel(formComponentResponse: FormComponentResponse): boolean {
		return formComponentResponse['@class'] === 'FormLabelResponse';
	}

	/**
	 * Returns true if the form component is a field and has a name that indicates it is an OD field
	 */
	isFieldOD(formComponentResponse: FormComponentResponse): boolean {
		if (this.isFieldLabel(formComponentResponse)) {
			return false;
		}

		return this.fieldMatchesEye((formComponentResponse as FormFieldResponse).name, OD);
	}

	/**
	 * Returns true if the form component is a field and has a name that indicates it is an OS field
	 */
	isFieldOS(formComponentResponse: FormComponentResponse): boolean {
		if (this.isFieldLabel(formComponentResponse)) {
			return false;
		}

		return this.fieldMatchesEye((formComponentResponse as FormFieldResponse).name, OS);
	}

	/**
	 * Returns true if the name indicates it is a field for a specific eye
	 */
	private fieldMatchesEye(name: string, eye: FieldNameEyes): boolean {
		return name.startsWith(eye + ' ') || name.endsWith(' ' + eye) || name === eye;
	}

	/**
	 * Returns the corresponding OS field if the form component is an OD field
	 */
	getMatchingOsFieldName(formComponentResponse: FormComponentResponse) {
		if (!this.isFieldOD(formComponentResponse)) {
			return undefined;
		}

		const name = (formComponentResponse as FormFieldResponse).name;
		if (name.startsWith(OD + ' ')) {
			return OS + ' ' + name.substring(3);
		} else if (name.endsWith(' ' + OD)) {
			return name.slice(0, -3) + ' ' + OS;
		} else if (name === OD) {
			return OS;
		}
	}

	getFormFieldTabIndex(formComponentResponse: FormComponentResponse): number {
		return this.isFieldLabel(formComponentResponse) ? 0 : (formComponentResponse as FormFieldResponse).tabIndex;
	}

	getFieldValueResponse(field: IFormFieldResponse, values: FieldValueResponse[]): FieldValueResponse {
		return _find(values, {fieldId: field.fieldId});
	}

	buildFieldValueRequest(formFieldResponse: IFormFieldResponse, fieldValueResponse: FieldValueResponse): FieldValueRequest {
		const fieldValueRequest: FieldValueRequest = this.buildConcreteFieldValueRequest(
			formFieldResponse.uiComponentType,
			formFieldResponse.formFieldDataType,
			fieldValueResponse,
		);
		if (!_isNil(fieldValueRequest)) {
			fieldValueRequest.fieldId = formFieldResponse.fieldId;
		}
		return fieldValueRequest;
	}

	private buildConcreteFieldValueRequest(
		uiComponentType: FieldUIComponentType,
		formFieldDataType: FieldDataType,
		fieldValueResponse: FieldValueResponse,
	): FieldValueRequest {
		switch (uiComponentType.value) {
			case FieldUIComponentType.TEXT.value: {
				const textRequest = new FieldValueSmallStringRequest();
				textRequest['@class'] = 'FieldValueSmallStringRequest';
				if (!_isNil(fieldValueResponse)) {
					textRequest.value = (fieldValueResponse as FieldValueSmallStringResponse).value;
				}
				return textRequest;
			}
			case FieldUIComponentType.TEXTAREA.value: {
				const textAreaRequest = new FieldValueLargeStringRequest();
				textAreaRequest['@class'] = 'FieldValueLargeStringRequest';
				if (!_isNil(fieldValueResponse)) {
					textAreaRequest.value = (fieldValueResponse as FieldValueLargeStringResponse).value;
				}
				return textAreaRequest;
			}
			case FieldUIComponentType.SELECTION.value: {
				const selectionRequest = new FieldValueSelectionRequest();
				selectionRequest['@class'] = 'FieldValueSelectionRequest';
				if (!_isNil(fieldValueResponse) && !_isNil((fieldValueResponse as FieldValueSelectionResponse).value)) {
					selectionRequest.optionId = (fieldValueResponse as FieldValueSelectionResponse).value.id;
				}
				return selectionRequest;
			}
			case FieldUIComponentType.MULTI_SELECTION.value: {
				const multiSelectionRequest = new FieldValueMultiSelectionRequest();
				multiSelectionRequest['@class'] = 'FieldValueMultiSelectionRequest';
				if (!_isNil(fieldValueResponse) && !_isNil((fieldValueResponse as FieldValueMultiSelectionResponse).values)) {
					multiSelectionRequest.optionIds = _map((fieldValueResponse as FieldValueMultiSelectionResponse).values, value => value.id);
				}
				return multiSelectionRequest;
			}
			case FieldUIComponentType.DATE.value: {
				const dateRequest = new FieldValueDateRequest();
				dateRequest['@class'] = 'FieldValueDateRequest';
				if (!_isNil(fieldValueResponse)) {
					dateRequest.value = (fieldValueResponse as FieldValueDateResponse).value;
				}
				return dateRequest;
			}
			case FieldUIComponentType.TIME.value: {
				/*
				The server is going to need a FieldValueSmallStringRequest, but the UI requires a Date for operating on, so we will use FieldValueTimeRequest
				to encapsulate both the `value` to be sent to the server and the `timeValue` used by the client.
				*/
				const timeRequest = new FieldValueTimeRequest();
				timeRequest['@class'] = 'FieldValueSmallStringRequest';
				if (!_isNil(fieldValueResponse) && !_isNil((fieldValueResponse as FieldValueSmallStringResponse).value)) {
					timeRequest.value = (fieldValueResponse as FieldValueSmallStringResponse).value;
					timeRequest.timeValue = this.timepickerFormatterService.parseTime((fieldValueResponse as FieldValueSmallStringResponse).value).toDate();
				}
				return timeRequest;
			}
			case FieldUIComponentType.NUMBER_STEPPER.value: {
				/**
				 * Theoretically we should be checking if the type is a number or a decimal but the flex did not
				 * and we need to maintain backwards compatibility
				 */
				const stepperDecimalRequest = new FieldValueDecimalRequest();
				stepperDecimalRequest['@class'] = 'FieldValueDecimalRequest';
				if (!_isNil(fieldValueResponse)) {
					stepperDecimalRequest.value = (fieldValueResponse as FieldValueDecimalResponse).value;
				}
				return stepperDecimalRequest;
			}
			case FieldUIComponentType.CHECKBOX.value: {
				const checkboxRequest = new FieldValueBooleanRequest();
				checkboxRequest['@class'] = 'FieldValueBooleanRequest';
				if (!_isNil(fieldValueResponse)) {
					checkboxRequest.value = (fieldValueResponse as FieldValueBooleanResponse).value;
				}
				return checkboxRequest;
			}
			case FieldUIComponentType.RADIO.value: {
				const radioRequest = new FieldValueSelectionRequest();
				radioRequest['@class'] = 'FieldValueSelectionRequest';
				if (!_isNil(fieldValueResponse) && !_isNil((fieldValueResponse as FieldValueSelectionResponse).value)) {
					radioRequest.optionId = (fieldValueResponse as FieldValueSelectionResponse).value.id;
				}
				return radioRequest;
			}
			case FieldUIComponentType.NONE.value:
			case FieldUIComponentType.OK_NOT_OK.value:
			default:
				return null;
		}
	}

	private getFormattedValueForFormField(
		uiComponentType: FieldUIComponentType,
		fieldValueResponse: FieldValueResponse,
		field: IFormFieldResponse,
	) {
		switch (uiComponentType.value) {
			case FieldUIComponentType.TEXT.value:
			case FieldUIComponentType.TEXTAREA.value:
			case FieldUIComponentType.DATE.value:
			case FieldUIComponentType.TIME.value:
				return !_isNil(fieldValueResponse) ? fieldValueResponse['value'] : null;
			case FieldUIComponentType.SELECTION.value:
			case FieldUIComponentType.RADIO.value:
				if (!_isNil(fieldValueResponse) && !_isNil((fieldValueResponse as FieldValueSelectionResponse).value)) {
					return (fieldValueResponse as FieldValueSelectionResponse).value.value;
				}
				return null;
			case FieldUIComponentType.MULTI_SELECTION.value:
				if (!_isNil(fieldValueResponse) && !_isNil((fieldValueResponse as FieldValueMultiSelectionResponse).values)) {
					return _map((fieldValueResponse as FieldValueMultiSelectionResponse).values, value => value.value).join(', ');
				}
				return null;
			case FieldUIComponentType.NUMBER_STEPPER.value:
				if (!_isNil(fieldValueResponse)) {
					return this.numberCustomFormatterPipe.transform(
						(fieldValueResponse as FieldValueDecimalResponse).value,
						(field as FormFieldStepperResponse).format,
						(field as FormFieldStepperResponse).precision,
						false,
					);
				}
				return null;
			case FieldUIComponentType.CHECKBOX.value:
				if (!_isNil(fieldValueResponse)) {
					return this.yesNoPipe.transform((fieldValueResponse as FieldValueBooleanResponse).value);
				}
				return null;
			case FieldUIComponentType.NONE.value:
			case FieldUIComponentType.OK_NOT_OK.value:
			default:
				return null;
		}
	}

	getFieldValueRequestIndex(componentForm: UntypedFormGroup, fieldId: number): number {
		const workflowTestRequest: WorkflowTestRequest = componentForm.value as WorkflowTestRequest;
		return _findIndex(workflowTestRequest.values, {fieldId});
	}

	getFieldValueByFieldId(componentForm: UntypedFormGroup, fieldId: number) {
		const workflowTestRequest: WorkflowTestRequest = componentForm.value as WorkflowTestRequest;
		const valueObject = _find(workflowTestRequest.values, {fieldId});

		if (Object.prototype.hasOwnProperty.call(valueObject, 'optionId')) {
			return valueObject['optionId'];
		} else if (Object.prototype.hasOwnProperty.call(valueObject, 'optionIds')) {
			return valueObject['optionIds'];
		} else if (Object.prototype.hasOwnProperty.call(valueObject, 'timeValue')) {
			return valueObject['timeValue'];
		} else {
			return valueObject['value'];
		}
	}

	getFormControl(componentForm: UntypedFormGroup, fieldId: number, path?: string): AbstractControl {
		const valueIndex: number = this.getFieldValueRequestIndex(componentForm, fieldId);
		return this.getFormControlByIndex(componentForm, valueIndex, path);
	}

	getFormControlByIndex(componentForm: UntypedFormGroup, valueIndex: number, path?: string): AbstractControl {
		const computedPath = path ? `.${path}` : '';
		return componentForm.get(`values.${valueIndex}${computedPath}`);
	}

	/**
	 * Returns the value that should be set for the form component when a "default normal" request is made
	 * @param formField The form component to update for default normal
	 * @param currentValue The current value of the form component
	 * @param options Optional parameter to specify a list of options to find default normal with. If not provided, active options will be used
	 */
	getFormFieldDefaultNormal(formField: FormComponentResponse, currentValue: any, options?: FieldOptionResponse[]): any {
		if (this.isFieldLabel(formField)) {
			return undefined;
		}
		let optionsContext = null;
		const formFieldResponse = formField as FormFieldResponse;
		switch (formFieldResponse.uiComponentType.value) {
			case FieldUIComponentType.TEXT.value:
			case FieldUIComponentType.TEXTAREA.value:
				if (formFieldResponse.defaultNormal === 'null' || formFieldResponse.defaultNormal === 'undefined') {
					return null;
				}
				return formFieldResponse.defaultNormal;
			case FieldUIComponentType.NUMBER_STEPPER.value: {
				if (!_isFinite(Number(formFieldResponse.defaultNormal)) || Number(formFieldResponse.defaultNormal) === 0) {
					return null;
				}
				return formFieldResponse.defaultNormal;
			}
			case FieldUIComponentType.SELECTION.value:
			case FieldUIComponentType.RADIO.value: {
				optionsContext = _isNil(options) ? this.getActiveOptions(formField as FormFieldSelectionResponse, currentValue) : options;
				const fieldOptionResponse: FieldOptionResponse = _find(optionsContext, 'isDefaultNormal');
				return _isNil(fieldOptionResponse) ? null : fieldOptionResponse.id;
			}
			case FieldUIComponentType.MULTI_SELECTION.value:
				optionsContext = _isNil(options) ? this.getActiveOptions(formField as FormFieldSelectionResponse, currentValue) : options;
				// We need to retain any currently selected values and union them with any default normal values
				return _union(_map(_filter(optionsContext, 'isDefaultNormal'), 'id'), currentValue as Array<number>);
			case FieldUIComponentType.DATE.value:
				return formFieldResponse.defaultNormal === FormFieldDefaultNormal.TODAY.value ? new Date() : null;
			case FieldUIComponentType.TIME.value:
				return formFieldResponse.defaultNormal === FormFieldDefaultNormal.NOW.value ? new Date() : null;
			case FieldUIComponentType.CHECKBOX.value:
				return formFieldResponse.defaultNormal === FormFieldDefaultNormal.TRUE.value;
			case FieldUIComponentType.NONE.value:
			case FieldUIComponentType.OK_NOT_OK.value:
			default:
				return null;
		}
	}

	/**
	 * Returns the value that should be set when copying a value between OD/OS fields
	 * @param sourceFormComponent The source form component to pull the value from
	 * @param sourceValue The current value of the source form component
	 * @param sourceOptions The options related to the source component (if it supports options)
	 * @param destinationOptions The options related to the destination component (if it supports options)
	 */
	getFormFieldCopyValue(
		sourceFormComponent: FormComponentResponse,
		sourceValue: any,
		sourceOptions: FieldOptionResponse[],
		destinationOptions: FieldOptionResponse[],
	): any {
		if (this.isFieldLabel(sourceFormComponent)) {
			return undefined;
		}

		const formFieldResponse = sourceFormComponent as FormFieldResponse;
		switch (formFieldResponse.uiComponentType.value) {
			case FieldUIComponentType.TEXT.value:
			case FieldUIComponentType.TEXTAREA.value:
			case FieldUIComponentType.NUMBER_STEPPER.value:
			case FieldUIComponentType.DATE.value:
			case FieldUIComponentType.TIME.value:
			case FieldUIComponentType.CHECKBOX.value:
				return sourceValue;
			case FieldUIComponentType.SELECTION.value:
			case FieldUIComponentType.RADIO.value: {
				const sourceOptionId: number = sourceValue as number;
				const sourceOption = _find(sourceOptions, option => option.id === sourceOptionId);
				return this.getFormFieldOptionIdByValue(_get(sourceOption, 'value'), destinationOptions);
			}
			case FieldUIComponentType.MULTI_SELECTION.value: {
				const sourceOptionIds: number[] = sourceValue as number[];
				const sourceValues = _map(_filter(sourceOptions, option => _includes(sourceOptionIds, option.id)), 'value');
				return this.getFormFieldOptionIdsByValues(sourceValues, destinationOptions);
			}
			case FieldUIComponentType.NONE.value:
			case FieldUIComponentType.OK_NOT_OK.value:
			default:
				return null;
		}
	}

	/**
	 * Returns the FieldOptionResponse.id that has the matching value
	 */
	getFormFieldOptionIdByValue(value: string, options: FieldOptionResponse[]) {
		if (_isNil(value)) {
			return null;
		}

		const fieldOptionResponse = _find(options, option => option.value === value);
		return _isNil(fieldOptionResponse) ? null : fieldOptionResponse.id;
	}

	/**
	 * Returns the FieldOptionResponse.ids that have the matching values
	 */
	getFormFieldOptionIdsByValues(values: string[], options: FieldOptionResponse[]) {
		if (_isNil(values)) {
			return null;
		}

		return _map(_filter(options, option => _includes(values, option.value)), 'id');
	}

	/**
	 * Returns the FieldOptionResponses that have the matching values
	 */
	getFormFieldOptionsByIds(ids: number[], options: FieldOptionResponse[]): FieldOptionResponse[] {
		if (_isNil(ids)) {
			return null;
		}

		return _filter(options, option => _includes(ids, option.id));
	}

	/**
	 * Returns the FieldOptionResponse that has a matching value
	 */
	getFormFieldOptionById(id: number, options: FieldOptionResponse[]): FieldOptionResponse {
		if (_isNil(id)) {
			return null;
		}

		return _find(options, option => id === option.id);
	}

	/**
	 * Returns the Enum entry that matches the selected FieldOptionResponse.id
	 */
	/* eslint-disable-next-line max-len */
	convertFormFieldOptionToEnumValue<GandalfConstantType extends GandalfConstant<string>>(optionId: number, options: FieldOptionResponse[], enumClass: EnumClass<GandalfConstantType>) {
		if (_isNil(optionId)) {
			return null;
		}

		const fieldOptionResponse = _find(options, option => option.id === optionId);
		return _isNil(fieldOptionResponse) ? null : EnumUtil.findEnumByValue(fieldOptionResponse.value, enumClass);
	}

	/**
	 * Returns the unique refraction names for the Test based on the field mappings
	 */
	getTestRefractionNames(dynamicTest: DynamicTestResponse): string[] {
		const fields: IFormFieldResponse[] = this.getFormFieldResponses(dynamicTest.children);
		return _uniq(fields.map(field => field.refractionName).filter(name => !_isNil(name) && name !== 'undefined'));
	}

	/**
	 * Sorts the tests and fields on the screen to ensure proper tabbing order.
	 */
	sortForTabbing(dynamicScreen: DynamicScreenResponse): DynamicScreenResponse {
		if (_isNil(dynamicScreen) || _isNil(dynamicScreen.tests)) {
			return dynamicScreen;
		}
		dynamicScreen.tests = this.sortTestsForTabbing(dynamicScreen.tests);
		dynamicScreen.tests.forEach(test => {
			test.children = this.sortFieldsForTabbing(test.children);
		});

		this.markFirstFormControlOnScreenForFocus(dynamicScreen);

		return dynamicScreen;
	}

	/**
	 * Sorts the tests on the screen to tab in "reading" order (left to right, top to bottom).
	 */
	sortTestsForTabbing(tests: DynamicTestResponse[]): DynamicTestResponse[] {
		return SortingService.sortBy(tests, [test => test.position.top, test => test.position.left]);
	}

	/**
	 * Sorts the fields on the test to tab based on the supplied tabIndex of the child or in "reading" order (left to right, top to bottom).
	 */
	sortFieldsForTabbing(fields: FormComponentResponse[]): FormComponentResponse[] {
		return SortingService.sortBy(fields,
			[
				child => this.getFormFieldTabIndex(child),
				child => child.position.top, child => child.position.left,
			],
		);
	}

	calculateWidthHeightForTests(dynamicScreenResponse: DynamicScreenResponse): DynamicScreenResponse {
		if (_isNil(dynamicScreenResponse) || _isNil(dynamicScreenResponse.tests)) {
			return dynamicScreenResponse;
		}
		dynamicScreenResponse.tests.forEach((dynamicTest) => this.calculateWidthHeightForTest(dynamicTest));
		return dynamicScreenResponse;
	}

	calculateWidthHeightForTest(dynamicTest: DynamicTestResponse) {
		if (dynamicTest.children) {
			// The admin setup includes the header of the test in the calculated height, along with padding. We need to remove the height for the test component.
			// When no user set height/width is specified, we add in some padding since that is not included in the calculation of the max height/width calculation.
			if (dynamicTest.position.height) {
				dynamicTest.position.height = dynamicTest.position.height - TEST_HEADER_HEIGHT;
			} else {
				dynamicTest.position.height = this.getMaxHeightControl(dynamicTest.children) + TEST_LAYOUT_PADDING;
			}
			if (!dynamicTest.position.width) {
				// The max width control can be less than the minimum width required to properly display the header/toolbar.
				// Therefore we should set the width to whichever is greater.
				dynamicTest.position.width = Math.max(this.getMaxWidthControl(dynamicTest.children) + TEST_LAYOUT_PADDING, this.calculateMinimumHeaderWidth(dynamicTest));
			}
		}
	}

	removeBlankSelectionOptionsForScreen(dynamicScreenResponse: DynamicScreenResponse): DynamicScreenResponse {
		if (_isNil(dynamicScreenResponse) || _isNil(dynamicScreenResponse.tests)) {
			return dynamicScreenResponse;
		}
		dynamicScreenResponse.tests.forEach((dynamicTest) => this.removeBlankSelectionOptions(dynamicTest));
		return dynamicScreenResponse;
	}

	removeBlankSelectionOptions(dynamicTest: DynamicTestResponse): DynamicTestResponse {
		if (dynamicTest.children) {
			dynamicTest.children.forEach(component => {
				if (component instanceof FormFieldSelectionResponse && component.options) {
					// all dropdowns have a clear button so we can remove "blank" options that were unintentionally saved from legacy
					component.options = component.options.filter(option => !ObjectUtils.isNilOrEmpty(option.value));
				}
			});
		}
		return dynamicTest;
	}

	/**
	 * Returns the active options for the form field, optionally will include inactive options if supplied inactive values
	 * @param formField selection/radio/multiselection form field to return active options
	 * @param values optional selected value(s) to conditionally include inactive selected options
	 */
	getActiveOptions(formField: FormFieldSelectionResponse, values?: any) {
		return formField.options.filter(option => this.isOptionActiveOrSelected(option, values));
	}

	private isOptionActiveOrSelected(option: FieldOptionResponse, selectedOptions: any) {
		let isOptionSelected = false;
		if (!_isNil(selectedOptions) && _isArray(selectedOptions)) {
			isOptionSelected = !_isNil((selectedOptions as []).find(selectedOption => selectedOption === option.id));
		} else if (!_isNil(selectedOptions)) {
			isOptionSelected = selectedOptions === option.id;
		}
		return option.active || isOptionSelected;
	}

	private getMaxHeightControl(formControls: FormComponentResponse[]): number {
		const maxHeightControl = _maxBy(formControls, (control) => {
			control.position.height = control.position.height || this.getDefaultFieldHeight(control['uiComponentType']);
			return control.position.top + control.position.height;
		});

		return maxHeightControl.position.top + maxHeightControl.position.height;
	}

	private getMaxWidthControl(formControls: FormComponentResponse[]): number {
		const maxWidthControl = _maxBy(formControls, (control) => {
			control.position.width = control.position.width || this.getDefaultFieldWidth(control['uiComponentType']);
			return control.position.left + control.position.width;
		});

		return maxWidthControl.position.left + maxWidthControl.position.width;
	}

	getDefaultFieldWidth(fieldUIComponentType: FieldUIComponentType): number {
		if (_isNil(fieldUIComponentType)) {
			return DEFAULT_LABEL_WIDTH;
		}

		return FORM_FIELD_DEFAULT_DIMENSIONS[fieldUIComponentType.value].width;
	}

	getDefaultFieldHeight(fieldUIComponentType: FieldUIComponentType): number {
		if (_isNil(fieldUIComponentType)) {
			return DEFAULT_LABEL_HEIGHT;
		}

		return FORM_FIELD_DEFAULT_DIMENSIONS[fieldUIComponentType.value].height;
	}

	getScreenHeight(dynamicScreenResponse: DynamicScreenResponse): number {
		if (ObjectUtils.isNilOrEmpty(dynamicScreenResponse?.tests)) {
			return 0;
		}
		const maxHeightTest = _maxBy(dynamicScreenResponse.tests, (test) => test.position.top + test.position.height);
		return maxHeightTest.position.top + maxHeightTest.position.height + TEST_HEADER_HEIGHT + TEST_LAYOUT_PADDING + SCREEN_LAYOUT_BOTTOM_PADDING;
	}

	isFieldWithOptions(componentType: FieldUIComponentType): boolean {
		return EnumUtil.equalsOneOf(componentType, FieldUIComponentType.SELECTION, FieldUIComponentType.MULTI_SELECTION, FieldUIComponentType.RADIO);
	}

	markFirstFormControlOnScreenForFocus(dynamicScreen: DynamicScreenResponse) {
		const children = this.getScreenFormFields(dynamicScreen);
		if (children.length > 0) {
			children[0][ShouldFocusFieldSymbol] = 1;
		}
	}

	getScreenFormFields(dynamicScreen: DynamicScreenResponse) {
		const children = [];
		for (const test of dynamicScreen.tests) {
			for (const child of test.children) {
				if (!this.isFieldLabel(child)) {
					children.push(child);
				}
			}
		}
		return children;
	}

	markNextControlForFocus(dynamicScreen: DynamicScreenResponse, uiComponentId: number) {
		let next = false;
		let uidFocused = null;
		this.getScreenFormFields(dynamicScreen).forEach((child) => {
			if (next) {
				if (_isNil(child[ShouldFocusFieldSymbol])) {
					child[ShouldFocusFieldSymbol] = 1;
				}
				child[ShouldFocusFieldSymbol] += 1;
				uidFocused = child.uiComponentId;
				next = false;
			} else if (child.uiComponentId === uiComponentId) {
				next = true;
				child[ShouldFocusFieldSymbol] = undefined;
			} else {
				child[ShouldFocusFieldSymbol] = undefined;
			}
		});
		return uidFocused;
	}

	servicesTriggered() {
		this._triggeredServices.next(true);
	}

	get triggeredServices() {
		return this._triggeredServices.asObservable();
	}

	calculateMinimumHeaderWidth(dynamicTest: DynamicTestResponse) {
		return this.calculateFontWidthOfTitle(dynamicTest) + TEST_HEADER_BUTTONS_WIDTH + HTML_BORDER_WIDTH;
	}

	/**
	 * in consideration of font kerning, we need to know
	 * the exact width of the title as it was in the Flex,
	 * then remove that dummy element once we know that.
	 */
	/* istanbul ignore next */
	calculateFontWidthOfTitle(dynamicTest: DynamicTestResponse) {
		const text = document.createElement('span');
		document.body.appendChild(text);
		text.style.fontFamily = 'Arial';
		text.style.fontWeight = 'Bold';
		text.style.fontSize = '12px';
		text.style.height = 'auto';
		text.style.width = 'auto';
		text.style.position = 'absolute';
		text.style.whiteSpace = 'no-wrap';
		text.innerHTML = dynamicTest.name;

		const width = Math.ceil(text.clientWidth);

		document.body.removeChild(text);

		return width;
	}

	/* istanbul ignore next: gandalf */
	findEyeglassPrescriptionExpirationLocationPreference(request: LocationLevelPrescriptionExpirationRequest): Observable<string> {
		return this.eyeglassPrescriptionGandalfService.findEyeglassPrescriptionExpirationLocationPreference(request);
	}
}

