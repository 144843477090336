import { Injectable, NgZone } from '@angular/core';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { HIT_PMS_HTML_EVENTS } from '@core/legacy/hit-pms-html.constants';
import { TypeSafeModalManagerService } from 'morgana';
import { ManageUpdatesModalComponent } from '../manage-updates-modal/manage-updates-modal.component';

@Injectable({
	providedIn: 'root',
})
export class ManageUpdatesSubscriptionService {
	constructor(
		public zone: NgZone,
		private eventsManager: EventsManagerService,
	) { }

	subscribeToFeatureEvent(typeSafeModalManagerService: TypeSafeModalManagerService) {
		this.eventsManager.subscribe(HIT_PMS_HTML_EVENTS.HEADER.OPEN_MANAGE_UPDATES, () => this.openManageUpdateModal(typeSafeModalManagerService), this);
	}

	openManageUpdateModal(typeSafeModalManagerService: TypeSafeModalManagerService) {
		this.zone.run(() => {
			typeSafeModalManagerService.open(ManageUpdatesModalComponent, {});
		});
	}
}
