import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { BaseCurveUnit } from '@gandalf/constants';

@Pipe({
	name: 'baseCurve',
})
@Injectable({
	providedIn: 'root',
})
export class BaseCurvePipe implements PipeTransform {

	transform(value: string | number, unit?: BaseCurveUnit): string {
		if (isNaN(Number(value))) {
			return '';
		}
		const unitValue = !_isNil(unit) ? unit.value : '';
		return !_isNil(value) ? Number(value).toFixed(2) + unitValue : '';
	}

}
