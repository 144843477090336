import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'ensureHttp',
})
@Injectable({
	providedIn: 'root',
})
export class EnsureHttpPipe implements PipeTransform {

	/**
	 * Prepend a URL with 'http://' if it doesn't currently start with 'http:' or 'https:'
	 */
	transform(url: string) {
		if (!url) {
			return url;
		}
		return /^http(s)?:/.test(url) ? url : 'http://' + url;
	}

}
