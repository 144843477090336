// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.patient.PatientMinerSearchResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientMinerSearchResponse extends GandalfModelBase {

	@GandalfProperty()
	address: string;

	@GandalfProperty()
	city: string;

	@GandalfProperty()
	contactLensOdManufacturer: string;

	@GandalfProperty()
	contactLensOdModel: string;

	@GandalfProperty()
	contactLensOsManufacturer: string;

	@GandalfProperty()
	contactLensOsModel: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dob: Date;

	@GandalfProperty()
	email: string;

	@GandalfProperty()
	eyeglassManufacturer: string;

	@GandalfProperty()
	eyeglassModel: string;

	@GandalfProperty()
	eyeglassOdCylinder: number;

	@GandalfProperty()
	eyeglassOdSphere: number;

	@GandalfProperty()
	eyeglassOsCylinder: number;

	@GandalfProperty()
	eyeglassOsSphere: number;

	@GandalfProperty()
	firstName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	gender: constants.Gender;

	@GandalfProperty()
	genderString: string;

	@GandalfProperty()
	lastExamDate: Date;

	@GandalfProperty()
	lastName: string;

	@GandalfProperty()
	middleInitial: string;

	@GandalfProperty()
	nextApptDate: Date;

	@GandalfProperty()
	nextRecallDate: Date;

	@GandalfProperty()
	patientId: number;

	@GandalfProperty()
	postalCode: string;

	@GandalfProperty()
	practiceId: number;

	@GandalfProperty()
	preferredPhoneNumber: string;

	@GandalfProperty()
	primaryLocationId: number;

	@GandalfProperty()
	stateProvince: string;

	@GandalfProperty()
	suffix: string;

	@GandalfProperty()
	title: string;

}
