// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreateGeneralMedicationPrescriptionRequest } from './create-general-medication-prescription-request';

/** See com.rev360.pms.api.controller.prescription.UpdateGeneralMedicationPrescriptionRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateGeneralMedicationPrescriptionRequest extends CreateGeneralMedicationPrescriptionRequest {

	@GandalfProperty()
	encounterId: number;

	@GandalfValidator({ notNull: { message: 'General Medication is required' } })
	@GandalfProperty({ isRequired: true })
	generalMedicationId: number;

	@GandalfValidator({ notNull: { message: 'ID is required' } })
	@GandalfProperty({ isRequired: true })
	id: number;

	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	instructions: string;

	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	stopDate: Date;

}
