// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AdminWorkflowScreenTemplateTestResponse } from './admin-workflow-screen-template-test-response';

/** See com.rev360.pms.api.controller.admin.encounter.screenlibrary.AdminWorkflowScreenTemplateResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminWorkflowScreenTemplateResponse extends GandalfModelBase {

	@GandalfProperty()
	canScreenExcludePullForward: boolean;

	@GandalfProperty()
	categoryId: number;

	@GandalfProperty()
	displayOrder: number;

	@GandalfProperty()
	excludePullForward: boolean;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	isStatic: boolean;

	@GandalfProperty()
	label: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	layout: constants.WorkflowScreenTemplateLayout;

	@GandalfProperty()
	masterId: number;

	@GandalfProperty()
	name: string;

	@GandalfArray(AdminWorkflowScreenTemplateTestResponse)
	workflowScreenTemplateTests: AdminWorkflowScreenTemplateTestResponse[];

}
