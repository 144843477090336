// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { SortingRequest } from './sorting-request';

/** See com.rev360.pms.api.controller.common.PagingAndSortingRequest */
/* istanbul ignore next */
@GandalfModel
export class PagingAndSortingRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Page Number is required' } })
	@GandalfValidator({ min: { min: 1, message: 'Page Number cannot be less than 1' } })
	@GandalfProperty({ isRequired: true })
	pageNumber: number;

	@GandalfValidator({ notNull: { message: 'Page Size is required' } })
	@GandalfValidator({ min: { min: 1, message: 'Page Size cannot be less than 1' } })
	@GandalfProperty({ isRequired: true })
	pageSize: number;

	@GandalfArray(SortingRequest)
	sorts: SortingRequest[];

}
