// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.CodeEncounterRequest */
/* istanbul ignore next */
@GandalfModel
export class CodeEncounterRequest extends GandalfModelBase {

	@GandalfLabel('Additional CPT')
	@GandalfValidator({ sizeString: { message: 'Additional CPT cannot be longer than 10 characters', minLength: 0, maxLength: 10 } })
	@GandalfProperty()
	additionalCpt: string;

	@GandalfLabel('Final CPT')
	@GandalfValidator({ notNull: { message: 'Final CPT is required' } })
	@GandalfValidator({ sizeString: { message: 'Final CPT must be between 1 and 10 characters', minLength: 1, maxLength: 10 } })
	@GandalfProperty({ isRequired: true })
	cpt: string;

	@GandalfLabel('Encounter')
	@GandalfValidator({ notNull: { message: 'Encounter is required' } })
	@GandalfProperty({ isRequired: true })
	encounterId: number;

	@GandalfLabel('Professional Time Accepted')
	@GandalfValidator({ notNull: { message: 'Professional Time Accepted is required' } })
	@GandalfProperty({ isRequired: true })
	professionalTimeAccepted: boolean;

}
