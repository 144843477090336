import { Injectable } from '@angular/core';
import { HIT_PMS_HTML_PREFERENCES } from '@core/legacy/hit-pms-html.constants';
import { _filter, _find, _isEmpty } from '@core/lodash/lodash';
import { ModalManagerService, SortingService } from 'morgana';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { DefaultErrorToastModel, DefaultInfoToastModel, DefaultWarningToastModel } from '@core/toaster/toaster.constants';
import { ToasterService } from '@core/toaster/toaster.service';
import { AlertDisplayType, AlertSeverity, AlertStatus } from '@gandalf/constants';
import { AlertResponse } from '@gandalf/model/alert-response';
import { AlertsModalComponent } from '@shared/component/alerts/alerts-modal.component';
import { ALERTS_MODAL_CONSTANTS } from '@shared/constants/alerts-modal.constants';
import { ToastModel } from '@syncfusion/ej2-notifications';

@Injectable({
	providedIn: 'root',
})
export class AlertsService {

	constructor(
		public modalManagerService: ModalManagerService,
		public securityManagerService: SecurityManagerService,
		public toasterService: ToasterService,
	) {
	}

	static sortAlertsBySeverityThenId(alerts: AlertResponse[]): AlertResponse[] {
		return SortingService.sortBy(alerts, [alert => alert.severity.value, 'id'], ['desc', 'desc']);
	}

	static prepareAlertsForModalDisplayTypeModal(alerts: AlertResponse[]): AlertResponse[] {
		return _find(alerts, {status: AlertStatus.ACTIVE, displayType: AlertDisplayType.MODAL})
			? AlertsService.filterForActiveAlerts(alerts)
			: [];
	}

	static filterForActiveAlerts(alerts: AlertResponse[]): AlertResponse[] {
		return _filter(alerts, {status: AlertStatus.ACTIVE});
	}

	getAlertBannerDuration(): number {
		return this.securityManagerService.preferenceValue(HIT_PMS_HTML_PREFERENCES.TOAST_MESSAGES_DURATION.name) * 1000;
	}

	displayBannerAlerts(patientAlerts: AlertResponse[]) {
		const bannerAlerts = _filter(patientAlerts, {displayType: AlertDisplayType.BANNER});
		// reverse is here to make sure that the banners are pushed to display in order of least severe to most severe,
		// so that on screen the most severe appear at the top, which opposes how they are sorted with sortAlertsBySeverityThenId
		bannerAlerts.reverse().forEach(alert => {
			this.toasterService.showDefaultToast(this.formatToaster(alert));
		});
	}

	formatToaster(alert: AlertResponse): ToastModel {
		let toastOverrides: ToastModel;
		switch (alert.severity) {
			case AlertSeverity.HIGH:
				toastOverrides = DefaultErrorToastModel;
				break;
			case AlertSeverity.MEDIUM:
				toastOverrides = DefaultWarningToastModel;
				break;
			case AlertSeverity.LOW:
				toastOverrides = DefaultInfoToastModel;
				break;
		}
		toastOverrides.content = `${alert.category}: ${alert.description}`;
		toastOverrides.timeOut = this.getAlertBannerDuration();
		return toastOverrides;
	}

	openAlertsModal(alerts: AlertResponse[], type: ALERTS_MODAL_CONSTANTS) {
		if (type === ALERTS_MODAL_CONSTANTS.DISPLAY_TYPE) {
			alerts = AlertsService.prepareAlertsForModalDisplayTypeModal(alerts);
		}

		if (!_isEmpty(alerts)) {
			this.modalManagerService.open(AlertsModalComponent, {
				data: {
					alerts,
					type,
				},
			});
		}
	}
}
