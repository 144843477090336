// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.assessment.MedicalDecisionMakingResponse */
/* istanbul ignore next */
@GandalfModel
export class MedicalDecisionMakingResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	encounterDate: Date;

	@GandalfProperty()
	id: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	medicalDecisionMakingComplexity: constants.MedicalDecisionMakingComplexity;

	@GandalfConstantDecorator()
	@GandalfProperty()
	medicalDecisionMakingConsultation: constants.MedicalDecisionMakingConsultation;

	@GandalfConstantDecorator()
	@GandalfProperty()
	medicalDecisionMakingNumDxLevel: constants.MedicalDecisionMakingNumDx;

	@GandalfConstantDecorator()
	@GandalfProperty()
	medicalDecisionMakingRisk: constants.MedicalDecisionMakingRisk;

}
