// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientEmploymentResponse } from './patient-employment-response';

// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.patient.PatientQuickViewResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientQuickViewResponse extends GandalfModelBase {

	@GandalfArray(ReferenceDataResponse)
	categories: ReferenceDataResponse[];

	@GandalfProperty()
	dominantEye: ReferenceDataResponse;

	@GandalfProperty()
	dominantHand: ReferenceDataResponse;

	@GandalfProperty()
	driversLicense: string;

	@GandalfProperty()
	employment: PatientEmploymentResponse;

	@GandalfProperty()
	patientComment: string;

	@GandalfProperty()
	patientPhotoId: number;

}
