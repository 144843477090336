<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-sm"
	[attr.data-test-id]="'appointmentSendMessageConfirmModal'">
	<ng-template #header>
		<div class="dlg-template">Send Message</div>
	</ng-template>
	<ng-template #content>
		<p>Send a message to this patient?</p>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button type="button" (click)="closeModal()" [attr.data-test-id]="'appointmentSendMessageConfirmModalNoButton'">No</button>
		<button ejs-button type="button" revFocusInput [isPrimary]="true" (click)="submitForm()" [attr.data-test-id]="'appointmentSendMessageConfirmModalYesButton'">Yes</button>
	</ng-template>
</ejs-dialog>
