/* eslint-disable @angular-eslint/directive-class-suffix */
import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({
	/* eslint-disable-next-line @angular-eslint/directive-selector */
	selector: 'pmsBaseComponent',
})
export class BaseComponent implements OnDestroy {
	unsubscribe: Subject<void> = new Subject<void>();

	closeSubscriptions() {
		if (!this.unsubscribe.closed) {
			this.unsubscribe.next();
			this.unsubscribe.complete();
		}
	}

	ngOnDestroy() {
		this.closeSubscriptions();
	}
}
