import { AlertDialogArgs, ButtonArgs, ConfirmDialogArgs, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { lastValueFrom, Observable } from 'rxjs';
import { _assign, _cloneDeep, _isNil } from '../lodash/lodash';
import { MODAL } from '../../constants/modal.constants';

export enum ConfirmDialogResult {
	YES,
	NO,
	CLOSE,
}

export class DialogUtil {

	static confirm(overrides?: Partial<ConfirmDialogArgs>) {
		const args = this.buildConfirmOverrideArgs(overrides);
		return DialogUtility.confirm(args);
	}

	static confirmAsObservable(overrides?: Partial<ConfirmDialogArgs>): Observable<ConfirmDialogResult> {
		return new Observable<ConfirmDialogResult>(subscriber => {
			const args = this.buildConfirmOverrideArgs(overrides);
			args.okButton.click = function() {
				subscriber.next(ConfirmDialogResult.YES);
				subscriber.complete();
				this.close();
			};
			args.cancelButton.click = function() {
				subscriber.next(ConfirmDialogResult.NO);
				subscriber.complete();
				this.close();
			};
			args.close = function() {
				subscriber.next(ConfirmDialogResult.CLOSE);
				subscriber.complete();
			};
			DialogUtility.confirm(args);
		});
	}

	static confirmAsPromise(overrides?: Partial<ConfirmDialogArgs>) {
		return lastValueFrom(DialogUtil.confirmAsObservable(overrides));
	}

	static alert(overrides?: Partial<AlertDialogArgs>) {
		const args = this.buildAlertOverrideArgs(overrides);
		return DialogUtility.alert(args);
	}

	private static buildConfirmOverrideArgs(overrides?: Partial<ConfirmDialogArgs>): ConfirmDialogArgs {
		if (!_isNil(overrides?.okButton)) {
			const okButtonArgs = _cloneDeep(DEFAULT_CONFIRM_OK_BUTTON_ARGS);
			overrides.okButton = _assign(okButtonArgs, overrides.okButton);
		}
		if (!_isNil(overrides?.cancelButton)) {
			const cancelButtonArgs = _cloneDeep(DEFAULT_CANCEL_BUTTON_ARGS);
			overrides.cancelButton = _assign(cancelButtonArgs, overrides.cancelButton);
		}
		const confirmDialogArgs = _cloneDeep(DEFAULT_CONFIRM_ARGS);
		return _assign(confirmDialogArgs, overrides);
	}

	private static buildAlertOverrideArgs(overrides?: Partial<AlertDialogArgs>): AlertDialogArgs {
		if (!_isNil(overrides?.okButton)) {
			const okButtonArgs = _cloneDeep(DEFAULT_ALERT_OK_BUTTON_ARGS);
			overrides.okButton = _assign(okButtonArgs, overrides.okButton);
		}
		const alertDialogArgs = _cloneDeep(DEFAULT_ALERT_ARGS);
		return _assign(alertDialogArgs, overrides);
	}
}

const DEFAULT_CONFIRM_OK_BUTTON_ARGS: ButtonArgs = {
	cssClass: 'e-primary pull-right',
	text: 'Yes',
};

const DEFAULT_ALERT_OK_BUTTON_ARGS: ButtonArgs = {
	cssClass: 'e-primary pull-right',
	text: 'OK',
};

const DEFAULT_CANCEL_BUTTON_ARGS: ButtonArgs = {
	text: 'No',
};

const DEFAULT_CONFIRM_ARGS: ConfirmDialogArgs = {
	title: 'Title',
	content: 'Content',
	okButton: DEFAULT_CONFIRM_OK_BUTTON_ARGS,
	cancelButton: DEFAULT_CANCEL_BUTTON_ARGS,
	showCloseIcon: true,
	closeOnEscape: true,
	position: {X: 'center', Y: 'top'},
	isModal: true,
	isDraggable: true,
	animationSettings: {effect: 'None'},
	cssClass: 'modal-sm',
	zIndex: MODAL.Z_INDEX_STANDARD,
};

const DEFAULT_ALERT_ARGS: AlertDialogArgs = {
	title: 'Title',
	content: 'Content',
	okButton: DEFAULT_ALERT_OK_BUTTON_ARGS,
	showCloseIcon: true,
	closeOnEscape: true,
	position: {X: 'center', Y: 'top'},
	isModal: true,
	isDraggable: true,
	animationSettings: {effect: 'None'},
	cssClass: 'modal-sm',
	zIndex: MODAL.Z_INDEX_STANDARD,
};

