export const DATE_FORMATS = {
	MM_DD_YYYY: 'MM/dd/yyyy', // specifically used for the DatePipe
	MM_DD_YYYY_H_MM_A: 'MM/dd/yyyy h:mm a',
	MM_DD_YYYY_H_MM_SS_A: 'MM/dd/yyyy h:mm:ss a', // 08/02/1985 8:30:00 AM
	MMM_D_H_MM_A: 'MMM d h:mm a', // Sep 12 8:30 AM
	MMM_D: 'MMM d', // Sep 12
	MMMM_DD_YYYY_H_MM_SS: 'MMMM dd, yyyy H:mm:ss', // August 2, 1985 15:30:00
	YYYYMMDD_HMMSS: 'yyyyMMdd-Hmmss', // 19850822-83000
	H_MM_A: 'h:mm a', // 8:30 AM
	H_MM_SS_A: 'h:mm:ss a', // 8:30:55 AM
	HH_MM: 'HH:mm',
	HH_MM_A: 'hh:mm a', // 08:30 AM
	L: 'L', // 9, 12
	LL: 'LL', // 09, 12
	LLL: 'LLL', // Sep
	LLLL: 'LLLL', // September
	E: 'E', // Mon
	EEEE: 'EEEE', // Monday
	E_MMM_D_Y: 'E MMM d, y', // Fri Feb 25, 2022
	EEE_MM_DD_YYYY: 'EEE MM/dd/yyyy', // Tue 09/03/2019
	EEE_MM_DD_YYYY_h_mm_a: 'EEE MM/dd/yyyy h:mm a', // Tue 09/03/2019 8:30 PM
	EEE_MMMM_DD_YYYY: 'EEE MMMM dd, yyyy', // Tue September 03, 2020
	EEE_MMM_DD_YYYY_h_mm_a: 'EEE MMM dd, yyyy \'at\' h:mm a', // Tue Sept 03, 2020 at 1:00 pm
	EEEE_MM_DD_YYYY_h_mm_a: 'EEEE MM/dd/yyyy \'at\' h:mm a', // Tuesday 09/03/2019 at 8:30 PM
} as const;

export const TABLE_DATE_FORMATS = {
	MM_DD_YYYY: {type: 'date', format: DATE_FORMATS.MM_DD_YYYY},
	MM_DD_YYYY_H_MM_A: {type : 'dateTime', format: DATE_FORMATS.MM_DD_YYYY_H_MM_A},
	H_MM_A: {type : 'time', format: DATE_FORMATS.H_MM_A},
	MM_DD_YYYY_H_MM_SS_A: {type : 'dateTime', format: DATE_FORMATS.MM_DD_YYYY_H_MM_SS_A},
	EEE_MM_DD_YYYY : {type: 'date', format: DATE_FORMATS.EEE_MM_DD_YYYY},
	EEE_MM_DD_YYYY_h_mm_a : {type: 'dateTime', format: DATE_FORMATS.EEE_MM_DD_YYYY_h_mm_a},
} as const;

export const ALL_DATE_FORMATS = [
	DATE_FORMATS.MM_DD_YYYY_H_MM_A,
	DATE_FORMATS.MM_DD_YYYY_H_MM_SS_A,
	DATE_FORMATS.MMMM_DD_YYYY_H_MM_SS,
	DATE_FORMATS.H_MM_A,
	DATE_FORMATS.L,
	DATE_FORMATS.LL,
	DATE_FORMATS.LLL,
	DATE_FORMATS.LLLL,
] as const;
