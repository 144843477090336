import { Injectable, isDevMode } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class EnvironmentService {

	constructor() {
	}

	isDevMode() {
		return isDevMode();
	}
}
