import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { LoincCodeResponse } from '@gandalf/model/loinc-code-response';
import { SearchLoincCodesRequest } from '@gandalf/model/search-loinc-codes-request';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { PageSettingsModel, RowSelectEventArgs } from '@syncfusion/ej2-grids';
import { GandalfFormBuilder } from 'gandalf';
import { DynamicModalRef, ModalConfig, PAGE_LENGTH, PAGE_LENGTH_LIST } from 'morgana';
import { LabOrderService } from '../../../../../core/lab-order/lab-order.service';


@Component({
	selector: 'pms-lab-order-lookup-modal',
	templateUrl: './lab-order-lookup-modal.component.html',
	styles: [],
})
export class LabOrderLookupModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	labOrders: LoincCodeResponse[];
	searchText: string;
	labOrderSearchRequest: SearchLoincCodesRequest;
	componentForm: UntypedFormGroup;
	isSearching = false;

	pageSettings: PageSettingsModel = {
		pageSizes: PAGE_LENGTH_LIST,
		pageSize: PAGE_LENGTH.PAGE_10,
	};

	constructor(
		private dynamicModalRef: DynamicModalRef,
		private config: ModalConfig,
		private labOrderService: LabOrderService,
		private gandalfFormBuilder: GandalfFormBuilder,
	) { }

	ngOnInit() {
		this.parseConfigData(this.config.data);
		this.createForm();

		if (this.searchText) {
			this.searchLabOrders();
		}
	}

	parseConfigData(data: any) {
		if (data) {
			this.searchText = data.searchText;
		}
	}

	createForm() {
		this.labOrderSearchRequest = new SearchLoincCodesRequest();
		this.labOrderSearchRequest.searchTerm = this.searchText;

		this.componentForm = this.gandalfFormBuilder.group(this.labOrderSearchRequest);
	}

	searchLabOrders() {
		this.componentForm.updateValueAndValidity();

		if (this.componentForm.invalid) {
			return;
		}

		this.isSearching = true;
		this.labOrderService.searchLabOrders(this.componentForm.value).subscribe(data => {
			this.labOrders = data;
			this.isSearching = false;
		});
	}

	labOrderSelected(event: RowSelectEventArgs) {
		this.closeModal((event.data as LoincCodeResponse).loincNum);
	}

	clearSearch(templateForm: FormGroupDirective) {
		templateForm.resetForm();
		this.labOrders = [];
	}

	/* istanbul ignore next: closeModal */
	closeModal(labOrderId?) {
		this.dynamicModalRef.close(this.modal, labOrderId);
	}
}
