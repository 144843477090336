import { Injectable } from '@angular/core';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { HeaderGandalfService } from '@gandalf/services';

@Injectable({
	providedIn: 'root',
})
export class EditProfileService {

	constructor(
		private headerGandalfService: HeaderGandalfService,
	) {
	}

	/* istanbul ignore next: gandalf */
	getEmployeeProfileById() {
		return this.headerGandalfService.getCurrentEmployeeProfile();
	}

	/* istanbul ignore next: gandalf */
	@ShowSavedSuccessToast()
	updateEmployeeProfile(request) {
		return this.headerGandalfService.updateCurrentEmployeeProfile(request);
	}

	/* istanbul ignore next: gandalf */
	getPracticeRxntEnabled() {
		return this.headerGandalfService.getPracticeRxntEnabled();
	}
}
