// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AdminAddWorkflowTestTemplateFieldOptionRequest } from './admin-add-workflow-test-template-field-option-request';

// @ts-ignore
import { AdminEditWorkflowTestTemplateFieldOptionRequest } from './admin-edit-workflow-test-template-field-option-request';

/** See com.rev360.pms.api.controller.admin.encounter.testlibrary.AdminWorkflowTestTemplateFormFieldRequest */
/* istanbul ignore next */
@GandalfModel
export class AdminWorkflowTestTemplateFormFieldRequest extends GandalfModelBase {

	@GandalfArray(AdminAddWorkflowTestTemplateFieldOptionRequest)
	addOptions: AdminAddWorkflowTestTemplateFieldOptionRequest[];

	@GandalfValidator({ sizeString: { message: 'Default Normal cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	defaultNormal: string;

	@GandalfValidator({ notNull: { message: 'Field is required' } })
	@GandalfProperty({ isRequired: true })
	formFieldId: number;

	@GandalfValidator({ min: { min: -1000000, message: 'Maximum must be greater than or equal to -1,000,000' } })
	@GandalfValidator({ max: { max: 1000000, message: 'Maximum must be less than or equal to 1,000,000' } })
	@GandalfProperty()
	max: number;

	@GandalfValidator({ min: { min: -1000000, message: 'Minimum must be greater than or equal to -1,000,000' } })
	@GandalfValidator({ max: { max: 1000000, message: 'Minimum must be less than or equal to 1,000,000' } })
	@GandalfProperty()
	min: number;

	@GandalfValidator({ notNull: { message: 'Tab Index is required' } })
	@GandalfValidator({ min: { min: 0, message: 'Tab Index must be greater than or equal to 0' } })
	@GandalfProperty({ isRequired: true })
	tabIndex: number;

	@GandalfArray(AdminEditWorkflowTestTemplateFieldOptionRequest)
	updateOptions: AdminEditWorkflowTestTemplateFieldOptionRequest[];

}
