// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { MasterReferenceDataResponse } from './master-reference-data-response';

/** See com.rev360.pms.api.controller.drug.PracticeDrugListResponse */
/* istanbul ignore next */
@GandalfModel
export class PracticeDrugListResponse extends GandalfModelBase {

	@GandalfProperty()
	dispensingAmount: number;

	@GandalfProperty()
	dispensingUnit: MasterReferenceDataResponse;

	@GandalfProperty()
	doNotSubstitute: boolean;

	@GandalfProperty()
	dosageAmount: number;

	@GandalfProperty()
	dosageUnit: MasterReferenceDataResponse;

	@GandalfProperty()
	drugId: number;

	@GandalfProperty()
	drugName: string;

	@GandalfProperty()
	durationAmount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	durationUnit: constants.DurationUnit;

	@GandalfProperty()
	frequency: MasterReferenceDataResponse;

	@GandalfProperty()
	instructions: string;

	@GandalfProperty()
	numRefillsAllowed: number;

	@GandalfProperty()
	practiceDrugId: number;

	@GandalfProperty()
	route: MasterReferenceDataResponse;

	@GandalfProperty()
	shortName: string;

}
