import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'pmsSafeHtml'})
@Injectable({
	providedIn: 'root',
})
export class SafeHtmlPipe implements PipeTransform {
	constructor(private domSanitizer: DomSanitizer) {
	}

	/**
	 * Marks the given value as trusted HTML. WARNING: calling this with untrusted user data exposes your application to XSS security risks!
	 */
	transform(value: string) {
		return this.domSanitizer.bypassSecurityTrustHtml(value);
	}
}
