// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.person.diagnosis.CreatePersonDiagnosisRequest */
/* istanbul ignore next */
@GandalfModel
export class CreatePersonDiagnosisRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Comment cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	comment: string;

	@GandalfValidator({ notNull: { message: 'Diagnosis Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	diagnosisDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	diagnosisLocation: constants.DiagnosisLocation;

	@GandalfConstantDecorator()
	@GandalfProperty()
	diagnosisLocationQualifier: constants.DiagnosisLocationQualifier;

	@GandalfProperty()
	encounterId: number;

	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientId: number;

	@GandalfValidator({ notNull: { message: 'Diagnosis is required' } })
	@GandalfProperty({ isRequired: true })
	practiceDiagnosisId: number;

	@GandalfValidator({ sizeString: { message: 'Reason cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	reason: string;

}
