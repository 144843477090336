// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { PhoneNumbersResponse } from './phone-numbers-response';

// @ts-ignore
import { ProviderInsuranceIdentifierResponse } from './provider-insurance-identifier-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.provider.externalprovider.ExternalProviderResponse */
/* istanbul ignore next */
@GandalfModel
export class ExternalProviderResponse extends ProviderResponse {

	@GandalfProperty()
	address: AddressResponse;

	@GandalfProperty()
	altPracticeName: string;

	@GandalfProperty()
	credentials: string;

	@GandalfProperty()
	deaNum: string;

	@GandalfProperty()
	description: string;

	@GandalfProperty()
	directAddress: string;

	@GandalfProperty()
	ein: string;

	@GandalfProperty()
	email: string;

	@GandalfProperty()
	firstName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	gender: constants.Gender;

	@GandalfProperty()
	lastName: string;

	@GandalfProperty()
	licenseNum: string;

	@GandalfProperty()
	nickName: string;

	@GandalfProperty()
	npi: string;

	@GandalfProperty()
	personId: number;

	@GandalfProperty()
	phoneNumbers: PhoneNumbersResponse;

	@GandalfProperty()
	providerDisplayColor: number;

	@GandalfProperty()
	providerId: number;

	@GandalfArray(ProviderInsuranceIdentifierResponse)
	providerInsuranceIdentifierResponses: ProviderInsuranceIdentifierResponse[];

	@GandalfProperty()
	providerType: ReferenceDataResponse;

	@GandalfProperty()
	providerTypeId: number;

	@GandalfProperty()
	registrationNum: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.ProviderStatus;

	@GandalfProperty()
	suffix: string;

	@GandalfProperty()
	titleId: number;

	@GandalfProperty()
	titleName: string;

	@GandalfProperty()
	upin: string;

}
