import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'secondsToFormattedTime',
})
export class SecondsToFormattedTimePipe implements PipeTransform {

	transform(seconds: number = 0): string {
		if (seconds < 0) {
			seconds -= 60;
		}
		return (seconds < 0 ? '-' : '') + this.applyLeadingZero(seconds / 3600) + ':' + this.applyLeadingZero((seconds / 60) % 60);
	}

	private applyLeadingZero(value: number) {
		value = Math.floor(Math.abs(value));
		return value.toString().padStart(2, '0');
	}

}
