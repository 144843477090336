import { ApplicationRef, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ApplicationService {

	private isStableObservable = new Subject<boolean>();
	isStable = false;

	constructor(
		private applicationRef: ApplicationRef,
	) { }

	monitorApplicationStableState() {
		this.applicationRef.isStable.subscribe((isStable) => {
			this.isStableObservable.next(isStable);
			this.isStable = isStable;
		});
	}

	whenStable() {
		return this.isStableObservable.pipe(
			filter(isStable => isStable),
			take(1),
		);
	}
}
