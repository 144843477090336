// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeResponse } from './contact-lens-eye-response';

/** See com.rev360.pms.api.controller.encounter.history.contactlens.ContactLensHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensHistoryResponse extends GandalfModelBase {

	@GandalfProperty()
	comments: string;

	@GandalfProperty()
	continuousWearPeriodAmount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	continuousWearPeriodUnit: constants.DurationUnit;

	@GandalfProperty()
	dailyWearingTimeAmount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	dailyWearingTimeUnit: constants.DurationUnit;

	@GandalfProperty()
	dropsUsed: string;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	lensAgeAmount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	lensAgeUnit: constants.DurationUnit;

	@GandalfProperty()
	od: ContactLensEyeResponse;

	@GandalfProperty()
	os: ContactLensEyeResponse;

	@GandalfProperty()
	otherSupplies: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	overallLensType: constants.ContactLensHistoryType;

	@GandalfProperty()
	replacementScheduleAmount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	replacementScheduleUnit: constants.DurationUnit;

	@GandalfProperty()
	solutionUsed: string;

	@GandalfProperty()
	supplyRemainingAmount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	supplyRemainingPacking: constants.ContactLensHistoryPackaging;

	@GandalfProperty()
	todaysWearingTimeAmount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	todaysWearingTimeUnit: constants.DurationUnit;

}
