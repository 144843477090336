import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { ModalBase } from 'morgana';
import { FormGroup, NgForm } from '@angular/forms';
import { _assign } from '@core/lodash/lodash';
import { GandalfFormBuilder } from 'gandalf';
import { AskQuestionRequest } from '@gandalf/model/ask-question-request';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { NavigationMenuService } from '../services/navigation-menu.service';

@Component({
	selector: 'pms-ask-meaningful-use-modal',
	templateUrl: './ask-meaningful-use-modal.component.html',
	styles: [],
})
export class AskMeaningfulUseModalComponent extends ModalBase<null, AskQuestionRequest> implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('templateForm')
	templateForm: NgForm;

	formGroup: FormGroup;
	isSaving = false;
	request = new AskQuestionRequest();

	constructor(
		private gandalfFormBuilder: GandalfFormBuilder,
		private securityManagerService: SecurityManagerService,
		private navigationMenuService: NavigationMenuService,
	) {
		super();
	}

	ngOnInit() {
		this.request.emailAddress = this.securityManagerService.getUserSession().userEmail;
		this.formGroup = this.gandalfFormBuilder.group(this.request);
	}

	closeModal() {
		this.dynamicModalRef.close(this.modal, _assign(this.request, this.formGroup.value));
	}

	submitForm(event) {
		this.templateForm.onSubmit(event);
	}

	submit() {
		if (this.formGroup.invalid || this.isSaving) {
			return;
		}
		this.isSaving = true;
		this.navigationMenuService.askMeaningfulUseQuestion(this.formGroup.value).subscribe(() => {
			this.closeModal();
		});
	}
}
