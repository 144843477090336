import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { AlertSeverity } from '@gandalf/constants';


/**
 * @ngdoc pipe
 * @description
 * Angular filter for taking a severity label and returning a color class.
 *
 * @usage
 * {{alert.severity.label | severityClass}}
 */

@Pipe({
	name: 'severityClass',
})
@Injectable({
	providedIn: 'root',
})
export class AlertSeverityClassPipe implements PipeTransform {

	transform(severityLabel: string): string {
		switch (severityLabel) {
			case AlertSeverity.LOW.label:
				return 'info';
				break;
			case AlertSeverity.MEDIUM.label:
				return 'warning';
				break;
			case AlertSeverity.HIGH.label:
				return 'danger';
				break;
		}
	}

}
