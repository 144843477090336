// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.document.CreatePatientDocumentRequest */
/* istanbul ignore next */
@GandalfModel
export class CreatePatientDocumentRequest extends GandalfModelBase {

	@GandalfLabel('Encounter')
	@GandalfProperty()
	encounterId: number;

	@GandalfLabel('Patient')
	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientId: number;

	@GandalfLabel('Recipient')
	@GandalfValidator({ notNull: { message: 'Recipient is required' } })
	@GandalfProperty({ isRequired: true })
	recipientId: number;

	@GandalfConstantDecorator()
	@GandalfLabel('Recipient Type')
	@GandalfValidator({ notNull: { message: 'Recipient Type is required' } })
	@GandalfProperty({ isRequired: true })
	recipientType: constants.PatientDocumentRecipientType;

	@GandalfLabel('Save To Folder')
	@GandalfValidator({ notNull: { message: 'Save To Folder is required' } })
	@GandalfValidator({ sizeString: { message: 'Save To Folder must be between 1 and 50 characters', minLength: 1, maxLength: 50 } })
	@GandalfProperty({ isRequired: true })
	saveToFolder: string;

	@GandalfLabel('Template')
	@GandalfValidator({ notNull: { message: 'Template is required' } })
	@GandalfProperty({ isRequired: true })
	templateId: number;

}
