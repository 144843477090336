// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ScheduleFilterResponse } from './schedule-filter-response';

// @ts-ignore
import { StringAdditionalPreferenceResponse } from './string-additional-preference-response';

/** See com.rev360.pms.api.controller.shared.preferences.EmployeePreferencesResponse */
/* istanbul ignore next */
@GandalfModel
export class EmployeePreferencesResponse extends GandalfModelBase {

	@GandalfProperty()
	scheduleFilters: ScheduleFilterResponse;

	@GandalfArray(StringAdditionalPreferenceResponse)
	stringAdditionalPreferences: StringAdditionalPreferenceResponse[];

}
