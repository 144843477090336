// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.twowaytext.TwoWayTextNewConversationPatientResponse */
/* istanbul ignore next */
@GandalfModel
export class TwoWayTextNewConversationPatientResponse extends GandalfModelBase {

	@GandalfProperty()
	cellPhone: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	gender: constants.Gender;

	@GandalfProperty()
	name: PatientNameResponse;

	@GandalfProperty()
	optedIn: boolean;

	@GandalfProperty()
	patientId: number;

	@GandalfProperty()
	patientPhotoId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	personDateOfBirth: Date;

}
