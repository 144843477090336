// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AdminLocationOrderProcessingResponse } from './admin-location-order-processing-response';

// @ts-ignore
import { AdminPracticeLocationDetailsResponse } from './admin-practice-location-details-response';

// @ts-ignore
import { AdminPracticeLocationDistributionCenterResponse } from './admin-practice-location-distribution-center-response';

// @ts-ignore
import { AdminPracticeLocationExternalSystemsResponse } from './admin-practice-location-external-systems-response';

// @ts-ignore
import { AdminPracticeLocationOfficeHoursResponse } from './admin-practice-location-office-hours-response';

// @ts-ignore
import { AdminPracticeLocationPhrResponse } from './admin-practice-location-phr-response';

// @ts-ignore
import { StringAdditionalPreferenceResponse } from './string-additional-preference-response';

/** See com.rev360.pms.api.controller.admin.general.location.AdminPracticeLocationResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminPracticeLocationResponse extends GandalfModelBase {

	@GandalfArray(StringAdditionalPreferenceResponse)
	additionalPreferences: StringAdditionalPreferenceResponse[];

	@GandalfProperty()
	details: AdminPracticeLocationDetailsResponse;

	@GandalfProperty()
	distributionCenter: AdminPracticeLocationDistributionCenterResponse;

	@GandalfArray(AdminPracticeLocationExternalSystemsResponse)
	externalSystems: AdminPracticeLocationExternalSystemsResponse[];

	@GandalfProperty()
	officeHours: AdminPracticeLocationOfficeHoursResponse;

	@GandalfProperty()
	orderProcessing: AdminLocationOrderProcessingResponse;

	@GandalfProperty()
	phr: AdminPracticeLocationPhrResponse;

	@GandalfProperty()
	practiceLocationId: number;

}
