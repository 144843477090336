// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressOptionalRequest } from './address-optional-request';

/** See com.rev360.pms.api.controller.shared.common.PreviousAddressRequest */
/* istanbul ignore next */
@GandalfModel
export class PreviousAddressRequest extends AddressOptionalRequest {

	@GandalfValidator({ sizeString: { message: 'Address line 1 cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	addressLine1: string;

	@GandalfValidator({ sizeString: { message: 'Address line 2 cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	addressLine2: string;

	@GandalfValidator({ sizeString: { message: 'City cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	city: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate: Date;

	@GandalfLabel('Zip Code')
	@GandalfValidator({ sizeString: { message: 'Zip Code cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty()
	nonStandardPostalCode: string;

	@GandalfLabel('Zip Code')
	@GandalfValidator({ sizeString: { message: 'Zip Code cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfValidator({ postalCode: { message: 'Zip code is invalid' } })
	@GandalfProperty()
	postalCode: string;

	@GandalfValidator({ notNull: { message: 'Start Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	startDate: Date;

	@GandalfValidator({ sizeString: { message: 'State Province cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	stateProvince: string;

}
