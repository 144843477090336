// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreateEyeglassPrescriptionBaseRequest } from './create-eyeglass-prescription-base-request';

/** See com.rev360.pms.api.controller.prescription.CreateEyeglassPrescriptionsRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateEyeglassPrescriptionsRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Authorization is required' } })
	@GandalfProperty({ isRequired: true })
	authorizePrescription: boolean;

	@GandalfValidator({ notNull: { message: 'At least one prescription required.' } })
	@GandalfValidator({ sizeArray: { min: 1, max: 2147483647, message: 'At least one prescription required.' } })
	@GandalfArray(CreateEyeglassPrescriptionBaseRequest)
	eyeglassPrescriptions: CreateEyeglassPrescriptionBaseRequest[];

	@GandalfValidator({ notNull: { message: 'Signature is required' } })
	@GandalfProperty({ isRequired: true })
	useProviderSignature: boolean;

}
