import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { CreateProductBrandRequest } from '@gandalf/model/create-product-brand-request';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';
import { ManufacturerService } from '../../../manufacturer.service';

@Component({
	selector: 'pms-product-brand-modal',
	templateUrl: './product-brand-modal.component.html',
	styles: [],
})
export class ProductBrandModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('componentForm')
	componentForm: NgForm;

	formGroup: UntypedFormGroup;
	request: CreateProductBrandRequest;
	manufacturerId: number;

	constructor(
		public dynamicModalRef: DynamicModalRef,
		public modalConfig: ModalConfig,
		public manufacturerService: ManufacturerService,
		public gandalfFormBuilder: GandalfFormBuilder,
	) {
	}

	ngOnInit() {
		this.manufacturerId = this.modalConfig.data.manufacturerId;
		this.createForm();
	}

	addProductBrand() {
		const request = this.formGroup.value;
		request.manufacturerId = this.manufacturerId;

		if (this.formGroup.invalid) {
			return;
		}

		this.manufacturerService.addProductBrand(request).subscribe(() => {
			this.closeModal(true);
		});
	}

	createForm() {
		this.request = new CreateProductBrandRequest();
		this.request.manufacturerId = this.manufacturerId;
		this.formGroup = this.gandalfFormBuilder.group(this.request);
	}

	closeModal(refreshRequested: boolean) {
		this.dynamicModalRef.close(this.modal, refreshRequested);
	}

	submitForm(event: any) {
		this.componentForm.onSubmit(event);
	}
}
