import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { HIT_PMS_HTML_PREFERENCES } from '@core/legacy/hit-pms-html.constants';
import { _isEqual, _isNaN, _isNil } from '@core/lodash/lodash';
import { ObjectUtils } from 'morgana';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { BaseComponent } from '@shared/component/base.component';
import { NumberCustomFormatterPipe } from '@shared/pipes/number-custom-formatter/number-custom-formatter.pipe';
import { Big } from 'big.js';
import { fromEvent } from 'rxjs';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';

const MOUSE_DOWN_INTERVAL = 50;
const MOUSE_STEPPER_DELAY = 500;

enum STEPPER_DIRECTION {
	DOWN = 'DOWN',
	UP = 'UP',
}

// This mimics FormFieldStepperResponse for easier usage on dynamic screens
export interface NumberStepperOptions {
	format: string;
	maximum: number;
	minimum: number;
	stepSize: number;
	majorStepSize: number;
	precision: number;
	truncate?: boolean;
	autoSelectSingleOption?: boolean;
	noValidOptions?: boolean;
}

@Component({
	selector: 'pms-number-stepper',
	templateUrl: './number-stepper.component.html',
	styles: [],
	providers: [NumberCustomFormatterPipe],
})
export class NumberStepperComponent extends BaseComponent implements OnInit, ControlValueAccessor {
	get disabled(): boolean {
		return this.formControlDisabled || (this.numberStepperOptions && this.numberStepperOptions.noValidOptions);
	}

	@Input()
	set numberStepperOptions(value: NumberStepperOptions) {
		this._numberStepperOptions = value;
		this.onNumberStepperOptionsChange();
	}

	get numberStepperOptions(): NumberStepperOptions {
		return this._numberStepperOptions;
	}

	private _numberStepperOptions: NumberStepperOptions;

	private initialized = false;

	@Input()
	wrap = true;

	@Input()
	width: number;

	@Input()
	height: number;

	@Input()
	class: string;

	@Input()
	placeholder = '';

	@Input()
	clearOutOfBoundValues = false;

	@Output()
	inputFocus = new EventEmitter<void>();

	@ViewChild('inputElement')
	inputElement: ElementRef<HTMLInputElement>;

	private formControlDisabled: boolean;

	private readonly defaults = {
		minimum: 0,
		maximum: 10,
		precision: 0,
		stepSize: 1,
		majorStepSize: 1,
		wrap: true,
		format: null,
	};

	private mouseDownTimeout: number;
	private mouseDownInterval: number;

	private _formattedValue: string;

	focused = false;
	stepperDirection = STEPPER_DIRECTION;
	get formattedValue() {
		return this._formattedValue;
	}

	set formattedValue(val) {
		this._formattedValue = val;
	}

	get value() {
		return this.ngControl?.control?.value;
	}

	set value(val: number) {
		if (this.ngControl?.control) {

			const hasChanges = this.hasChanges(val);

			this.ngControl.control.setValue(val, {emitEvent: hasChanges});

			if (hasChanges) {
				this.ngControl.control.markAsDirty();
			}
		}
	}

	get maximum() {
		return !_isNil(this.numberStepperOptions.maximum) ? this.numberStepperOptions.maximum : this.defaults.maximum;
	}

	get minimum() {
		return !_isNil(this.numberStepperOptions.minimum) ? this.numberStepperOptions.minimum : this.defaults.minimum;
	}

	get precision() {
		return !_isNil(this.numberStepperOptions.precision) ? this.numberStepperOptions.precision : this.defaults.precision;
	}

	get stepSize() {
		const stepSize = !_isNil(this.numberStepperOptions.stepSize) ? this.numberStepperOptions.stepSize : this.defaults.stepSize;
		return Number(Big(stepSize).round(3, Big.roundHalfUp));
	}

	get majorStepSize() {
		const majorStepSize = !_isNil(this.numberStepperOptions.majorStepSize) ? this.numberStepperOptions.majorStepSize : this.defaults.majorStepSize;
		return Number(Big(majorStepSize).round(3, Big.roundHalfUp));
	}

	get startValue() {
		return this.isInBounds(0) ? 0 : this.minimum;
	}

	get format() {
		return !_isNil(this.numberStepperOptions.format) ? this.numberStepperOptions.format : this.defaults.format;
	}

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private securityManagerService: SecurityManagerService,
		private numberCustomFormatterPipe: NumberCustomFormatterPipe,
		@Optional() @Self() private ngControl: NgControl,
	) {
		super();

		this.ngControl.valueAccessor = this;
	}

	ngOnInit() {
		this.onValueChange(this.ngControl?.control?.value);
		this.ngControl.control.markAsPristine({ onlySelf: true });

		this.changeDetectorRef.detectChanges();

		this.initialized = true;

		this.ngControl.control.valueChanges
			.pipe(
				distinctUntilChanged(_isEqual),
				takeUntil(this.unsubscribe),
			)
			.subscribe(() => {
				this.onValueChange(this.ngControl?.control?.value);
			});
	}

	onModelChange: (_: any) => void = () => {
	};
	onModelTouched: () => void = () => {
	};

	private hasChanges(newValue: number) {
		// this can't have changes until it's initialized
		if (!this.initialized) {
			return false;
		}

		const currentValue = this.ngControl?.control.value;
		// if the new and original value are both empty/nil then there are no changes
		if (ObjectUtils.isNilOrEmpty(currentValue) && ObjectUtils.isNilOrEmpty(newValue)) {
			return false;
		}

		// it has changes if the current value does not equal the new value
		return currentValue !== newValue;
	}

	private isIgnoreRoundingEnabled() {
		return this.securityManagerService.preferenceValueIsOn(
			HIT_PMS_HTML_PREFERENCES.STEPPERS_TEXT_ENABLED.name,
			HIT_PMS_HTML_PREFERENCES.STEPPERS_TEXT_ENABLED.defaultValue,
		);
	}

	private onValueChange(value) {
		this.value = this.getValidNumericalValue(value);
		this.formatValue(this.value);
	}

	writeValue(_value: any): void {
	}

	registerOnChange(fn: (_: any) => void): void {
		this.onModelChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onModelTouched = fn;
	}

	onStepperUp() {
		if (this.disabled) {
			return;
		}
		this.onKeydownUpArrow(new KeyboardEvent(null, null));
		this.inputElement.nativeElement.focus();
	}

	onStepperDown() {
		if (this.disabled) {
			return;
		}
		this.onKeydownDownArrow(new KeyboardEvent(null, null));
		this.inputElement.nativeElement.focus();
	}

	onKeydownRightArrow(event: KeyboardEvent) {
		if (event.ctrlKey) {
			return;
		}

		let newValue;
		if (!this.isNan(this.value)) {
			newValue = Number(this.getValueRoundedToNearestStep()) + this.majorStepSize;

			if (!this.isInBounds(newValue) && this.wrap) {
				// calculate how far above the maximum we are
				const delta = (newValue - this.maximum);
				newValue = this.minimum + delta;
			}
		} else {
			newValue = this.startValue;
		}

		this.onValueChange(newValue);
		return false;
	}

	onKeydownLeftArrow(event: KeyboardEvent) {
		if (event.ctrlKey) {
			return;
		}

		let newValue;
		if (!this.isNan(this.value)) {
			newValue = Number(this.getValueRoundedToNearestStep()) - this.majorStepSize;

			if (!this.isInBounds(newValue) && this.wrap) {
				// calculate how far below the minimum we are
				const delta = (this.minimum - newValue);
				newValue = this.maximum - delta;
			}
		} else {
			newValue = this.startValue;
		}

		this.onValueChange(newValue);
		return false;
	}

	onKeydownUpArrow(_event: KeyboardEvent) {
		let newValue;
		if (!this.isNan(this.value)) {
			newValue = Number(this.getValueRoundedToNearestStep()) + this.stepSize;

			if (!this.isInBounds(newValue) && this.wrap) {
				newValue = this.minimum;
			}
		} else {
			newValue = this.startValue;
		}

		this.onValueChange(newValue);
		return false;
	}

	onKeydownDownArrow(_event: KeyboardEvent) {
		let newValue;
		if (!this.isNan(this.value)) {
			newValue = Number(this.getValueRoundedToNearestStep()) - this.stepSize;

			if (!this.isInBounds(newValue) && this.wrap) {
				newValue = this.maximum;
			}
		} else {
			newValue = this.startValue;
		}

		this.onValueChange(newValue);
		return false;
	}

	onKeydownHome(_event: KeyboardEvent) {
		this.onValueChange(this.minimum);
	}

	onKeydownEnd(_event: KeyboardEvent) {
		this.onValueChange(this.maximum);
	}

	onFocus() {
		this.focused = true;
		this.inputFocus.emit();
	}

	onBlur() {
		this.focused = false;
		this.onValueChange(this._formattedValue);
	}

	isInBounds(val: number) {
		return this.isNan(val) || (val >= this.minimum && val <= this.maximum);
	}

	isNan(val: any) {
		if (ObjectUtils.isNilOrEmpty(val)) {
			return true;
		}
		return _isNaN(Number(val));
	}

	/**
	 * Runs the current value back through validation to make sure the value is valid
	 */
	resetCurrentValueToValidOption() {
		this.onValueChange(this.value);
	}

	formatValue(numericalValue: number) {
		this._formattedValue = this.numberCustomFormatterPipe.transform(
			numericalValue,
			this.numberStepperOptions.format,
			this.precision,
			this.numberStepperOptions.truncate,
		);
	}

	/**
	 * This will return the current value rounded to the nearest step but it does not check min/max boundaries
	 */
	getValueRoundedToNearestStep() {
		return this._roundToNearestStep(this.value);
	}

	/**
	 * This will round to the nearest step but it does not check min/max boundaries
	 */
	private _roundToNearestStep(value) {
		return Big(Number(value)).minus(this.minimum).div(this.stepSize).round(0, Big.roundHalfUp).mul(this.stepSize).plus(this.minimum);
	}

	/*istanbul ignore next*/
	setDisabledState(isDisabled: boolean): void {
		this.formControlDisabled = isDisabled;
	}

	appendPxOrUndefined(value: number) {
		return value ? value.toString() + 'px' : undefined;
	}

	onlyValidOption() {
		// if not null and not 0 and the min and max are the same, it's the only valid option
		if (!!this.minimum && this.minimum === this.maximum) {
			return this.minimum;
		}
		return null;
	}

	onNumberStepperOptionsChange() {
		// if auto select single option is on and there is only one option available, set the value, format and return
		if (this.numberStepperOptions.autoSelectSingleOption) {
			const onlyValidOption = this.onlyValidOption();
			if (!_isNil(onlyValidOption)) {
				this.onValueChange(onlyValidOption);
				return;
			}
		}

		// If there are no valid options set the value to null and return
		if (this.numberStepperOptions.noValidOptions) {
			this.onValueChange(null);
			return;
		}

		// otherwise just make sure the current value falls within the bounds and steps allowed
		this.resetCurrentValueToValidOption();
	}

	/**
	 * Gets a numerical value from an incoming string or number value, that is within bounds of the number stepper and rounded if necessary
	 */
	private getValidNumericalValue(incomingValue: string | number) {

		// There should not be a numerical value if there are no valid options
		if (this.numberStepperOptions?.noValidOptions) {
			return null;
		}

		// Return null if it's not a number
		if (this.isNan(incomingValue)) {
			return null;
		}

		// Cast to a number once checked for invalid number string
		const incomingValueAsNumber = Number(incomingValue);

		// return closest boundary if it's out of bounds unless option is provided to clear the value
		if (!this.isInBounds(incomingValueAsNumber)) {
			if (this.clearOutOfBoundValues) {
				return null;
			}
			if (incomingValueAsNumber < this.minimum) {
				return this.minimum;
			} else {
				return this.maximum;
			}
		}

		// If ignoring rounding return as is
		if (this.isIgnoreRoundingEnabled()) {
			return Number(incomingValueAsNumber);
		}

		// round to nearest step size
		const val = this._roundToNearestStep(incomingValueAsNumber);

		// Check boundaries after rounding
		if (val.lt(this.minimum)) {
			return this.minimum;
		} else if (val.gt(this.maximum)) {
			return this.maximum;
		}

		return Number(val);
	}

	stepperMouseDown(direction: STEPPER_DIRECTION) {
		if (!this.disabled) {
			this.mouseDownTimeout = window.setTimeout(() => {
				this.mouseDownInterval = window.setInterval(() => {
					direction === STEPPER_DIRECTION.DOWN ? this.onStepperDown() : this.onStepperUp();
					this.changeDetectorRef.detectChanges();
				}, MOUSE_DOWN_INTERVAL);
			}, MOUSE_STEPPER_DELAY);

			// Window mouseup event is needed to cover the scenario that the user keeps the mouse held down and
			// moves the mouse out of the stepper element
			fromEvent(window, 'mouseup').pipe(take(1)).subscribe((_event: Event) => {
				this.stepperMouseUp();
			});
		}
	}

	stepperMouseUp() {
		if (this.mouseDownInterval) {
			window.clearInterval(this.mouseDownInterval);
		}
		if (this.mouseDownTimeout) {
			window.clearTimeout(this.mouseDownTimeout);
		}
	}

}
