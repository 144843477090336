import { Component, Input, OnInit, Optional } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import { EnumUtil, OptionItem } from 'morgana';
import { InsuranceService } from '@core/insurance/insurance.service';
import { _cloneDeep } from '@core/lodash/lodash';
import { ReferenceDataService } from '@core/reference-data/reference-data.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import {
	ClaimFilingCode,
	ClaimSubmitMechanism,
	GroupNumberQualifier,
	InsuranceClassification,
	PaymentMethodType,
	ReferenceDataMasterCategory
} from '@gandalf/constants';
import { combineLatest } from 'rxjs';

@Component({
	selector: 'pms-insurance-company-information',
	templateUrl: './insurance-company-information.component.html',
	styles: [],
})
export class InsuranceCompanyInformationComponent implements OnInit {

	@Input() formGroup: UntypedFormGroup;
	@Input() isCreating: boolean;

	claimSubmissionMechanisms = EnumUtil.buildGandalfEnumSubsetList(
		EnumUtil.removeValues(ClaimSubmitMechanism.VALUES.values, [ClaimSubmitMechanism.NONELECTRONIC]),
		'Claim Submission Mechanism',
	);
	paymentMethodTypes = _cloneDeep(PaymentMethodType.VALUES);
	groupNumberQualifiers = GroupNumberQualifier.VALUES;
	claimFilingCodes = ClaimFilingCode.VALUES;

	// MasterReferenceData and ReferenceData-related enums have to use a normal ejs-dropdown.
	insurancePriorities: OptionItem[];
	insuranceTypes: OptionItem[];
	insuranceClassifications: OptionItem[];

	constructor(
		private insuranceService: InsuranceService,
		private securityManagerService: SecurityManagerService,
		public referenceDataService: ReferenceDataService,
		@Optional() public controlContainer: ControlContainer,
	) {
	}

	ngOnInit() {
		this.initialize();
	}

	initialize() {
		if (!this.formGroup && this.controlContainer) {
			this.formGroup = this.controlContainer.control as UntypedFormGroup;
		}
		combineLatest([
			this.referenceDataService.getActiveReferenceDataByCategoryIdForDropdown(ReferenceDataMasterCategory.INSURANCE_TYPE.value),
			this.referenceDataService.getActiveReferenceDataByCategoryIdForDropdown(ReferenceDataMasterCategory.INSURANCE_PRIORITY.value),
			this.insuranceService.findPracticePaymentMethods(),
			this.referenceDataService.getMasterReferenceDataByCategory(ReferenceDataMasterCategory.INSURANCE_CLASS),
		]).subscribe(([insuranceTypes, insurancePriorities, paymentMethods, insuranceClassifications]) => {
			this.insuranceTypes = insuranceTypes;
			this.insurancePriorities = insurancePriorities;
			this.paymentMethodTypes = {
				label: 'Payment Methods',
				values: paymentMethods,
			};
			if (this.isCanada) {
				insuranceClassifications = insuranceClassifications.filter(classification => classification.value !== InsuranceClassification.VSP.value);
			}
			this.insuranceClassifications = insuranceClassifications;
		});
	}

	get isCanada(): boolean {
		return this.securityManagerService.isCanada();
	}
}
