import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ScrollUtilService {

	static scrollToTop() {
		window.scrollTo(0, 0);
	}

	static scrollElementToBottom(element: HTMLElement) {
		element.scrollTop = element.scrollHeight;
	}
}
