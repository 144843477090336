<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-lg">
	<ng-template #header>
		<div class="dlg-template">Calendar Filter</div>
	</ng-template>
	<ng-template #content>
		@if (initialized) {
			<div>
				<div class="row">
					<div class="col-sm-12 col-md-6 col-lg-3" [attr.data-test-id]="'calendarItemTypesFilter'">
						<label>Calendar Item Types</label>
						<ejs-listbox
							class="margin-bottom-md"
							height="350px"
							[(value)]="selectedItemTypes"
							[dataSource]="itemTypes"
							[selectionSettings]="{showCheckbox: true, showSelectAll: true}"
							sortOrder="Ascending"
							[fields]="{text: 'label', value: 'value'}">
						</ejs-listbox>
					</div>
					<div class="col-sm-12 col-md-6 col-lg-3" [attr.data-test-id]="'calendarItemStatusFilter'">
						<label>Calendar Item Status</label>
						<ejs-listbox
							class="margin-bottom-md"
							height="350px"
							[(value)]="selectedStatuses"
							[dataSource]="statuses"
							[selectionSettings]="{showCheckbox: true, showSelectAll: true}"
							[fields]="{text: 'label', value: 'value'}">
						</ejs-listbox>
					</div>
					<div class="col-sm-12 col-md-6 col-lg-3" [attr.data-test-id]="'employeesFilter'">
						<label>Employees</label>
						<ejs-dropdownlist
							class="margin-bottom-sm"
							[dataSource]="securityRoles"
							[showClearButton]="true"
							placeholder="Any Group"
							(change)="handleEmployeeSecurityRoleSelect($event)">
						</ejs-dropdownlist>
						<ejs-listbox
							class="margin-bottom-md"
							height="350px"
							#employeeListBox
							[(value)]="selectedPersonIds"
							[dataSource]="employees"
							[selectionSettings]="{showCheckbox: true, showSelectAll: true}"
							[allowDragAndDrop]="true"
							[fields]="{text: 'label', value: 'value'}">
						</ejs-listbox>
					</div>
					<div class="col-sm-12 col-md-6 col-lg-3" [attr.data-test-id]="'userRolesFilter'">
						<label>User Roles</label>
						<ejs-listbox
							class="margin-bottom-md"
							height="350px"
							[(value)]="selectedSecurityRoleIds"
							[dataSource]="securityRoles"
							[selectionSettings]="{showCheckbox: true, showSelectAll: true}"
							[fields]="{text: 'label', value: 'value'}">
						</ejs-listbox>
					</div>
				</div>
			</div>
		}
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'calendarFilterCancel'">Cancel</button>
		<button ejs-button [isPrimary]="true" (click)="save()" [attr.data-test-id]="'calendarFilterSave'">Save</button>
	</ng-template>
</ejs-dialog>
