// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.assessmentandplan.UpdatePatientTriggerRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdatePatientTriggerRequest extends GandalfModelBase {

	@GandalfLabel('Comments')
	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	comments: string;

	@GandalfConstantDecorator()
	@GandalfLabel('Patient Trigger Outcome Confirmation Status')
	@GandalfValidator({ notNull: { message: 'Patient Trigger Outcome Confirmation Status is required' } })
	@GandalfProperty({ isRequired: true })
	confirmationStatus: constants.PatientTriggerOutcomeConfirmation;

	@GandalfLabel('Patient Trigger Outcome')
	@GandalfValidator({ notNull: { message: 'Patient Trigger Outcome is required' } })
	@GandalfProperty({ isRequired: true })
	patientTriggerOutcomeId: number;

	@GandalfLabel('Snomed Code')
	@GandalfProperty()
	snomedCodeId: number;

}
