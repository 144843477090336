import { Injectable } from '@angular/core';
import { EnumUtil, ObjectUtils, SortingService } from 'morgana';
import { GandalfTheGreyService } from '@core/gandalf-the-grey/gandalf-the-grey.service';
import { _assign, _isNil } from '@core/lodash/lodash';
import { OptionItemResponse, OptionItemService } from '@core/option-item/option-item.service';
import { PrescriptionUtil } from '@core/prescription-util/prescription-util';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { ContactLensPrescriptionType } from '@gandalf/constants';
import { ContactLensEyeSoftResponse } from '@gandalf/model/contact-lens-eye-soft-response';
import { ContactLensPropertyValuesResponse } from '@gandalf/model/contact-lens-property-values-response';
import { ContactLensTrialListResponse } from '@gandalf/model/contact-lens-trial-list-response';
import { CreateContactLensPrescriptionRequest } from '@gandalf/model/create-contact-lens-prescription-request';
import { CreateContactLensTrialOrderRequest } from '@gandalf/model/create-contact-lens-trial-order-request';
import { CreatePrescriptionOrderRequest } from '@gandalf/model/create-prescription-order-request';
import { EncounterCreateContactLensTrialRequest } from '@gandalf/model/encounter-create-contact-lens-trial-request';
import { EncounterUpdateContactLensTrialRequest } from '@gandalf/model/encounter-update-contact-lens-trial-request';
import { OdOsLocationProductIdsActiveResponse } from '@gandalf/model/od-os-location-product-ids-active-response';
import { PropertyValuesResponse } from '@gandalf/model/property-values-response';
import { ReferenceDataResponse } from '@gandalf/model/reference-data-response';
import { RefillContactLensPrescriptionRequest } from '@gandalf/model/refill-contact-lens-prescription-request';
import { UpdateContactLensPrescriptionRequest } from '@gandalf/model/update-contact-lens-prescription-request';
import { ContactLensPrescriptionGandalfService, ContactLensTrialGandalfService } from '@gandalf/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductColorResponse } from '@gandalf/model/product-color-response';
import { DynamicScreenService } from '../../../../dynamic-test-screen/dynamic-screen.service';

export interface FormattedContactLensTrialListResponse extends ContactLensTrialListResponse {
	modelOd: string;
	isMonovisionOd: boolean;
	baseCurveOd: number;
	sphereOd: number;
	cylinderOd: number;
	axisOd: string;
	addPowerOd: number;
	addDesignationOd: string;
	diameterOd: number;

	modelOs: string;
	isMonovisionOs: boolean;
	baseCurveOs: number;
	sphereOs: number;
	cylinderOs: number;
	axisOs: string;
	addPowerOs: number;
	addDesignationOs: string;
	diameterOs: number;
	commentsOdOrOs: string;
}

interface FormattedContactLensPropertyValuesResponse extends ContactLensPropertyValuesResponse {
	addDesignations: OptionItemResponse<ReferenceDataResponse, number>[];
}

@Injectable({
	providedIn: 'root',
})
export class ContactLensTrialService {

	constructor(
		public contactLensTrialGandalfService: ContactLensTrialGandalfService,
		public contactLensPrescriptionGandalfService: ContactLensPrescriptionGandalfService,
		public dynamicScreenService: DynamicScreenService,
		private gandalfTheGreyService: GandalfTheGreyService,
	) {
	}

	findContactLensTrialsByEncounterId(encounterId: number): Observable<FormattedContactLensTrialListResponse[]> {
		return this.contactLensTrialGandalfService.findContactLensTrialsForEncounter(encounterId).pipe(
			map(trials => SortingService.sortBy(trials, ['trialDate', 'id'], ['desc', 'desc'])),
			map(contactLensTrials => contactLensTrials.map(
				contactLensTrial => this.formatContactLensTrialResponse(contactLensTrial))),
		);
	}

	formatContactLensTrialResponse(response: ContactLensTrialListResponse) {
		const formattedResponse = response as FormattedContactLensTrialListResponse;

		if (!_isNil(response.od)) {
			formattedResponse.modelOd = response.od.locationProductName;
			formattedResponse.isMonovisionOd = response.od.isMonovision;
			formattedResponse.baseCurveOd = response.od.baseCurve;
			formattedResponse.sphereOd = response.od.sphere;
			formattedResponse.cylinderOd = response.od.cylinder;
			formattedResponse.axisOd = PrescriptionUtil.formatAxis(response.od.axis);
			formattedResponse.addPowerOd = response.od.addPower;
			formattedResponse.diameterOd = response.od.diameter;
		}
		if (!_isNil(response.os)) {
			formattedResponse.modelOs = response.os.locationProductName;
			formattedResponse.isMonovisionOs = response.os.isMonovision;
			formattedResponse.baseCurveOs = response.os.baseCurve;
			formattedResponse.sphereOs = response.os.sphere;
			formattedResponse.cylinderOs = response.os.cylinder;
			formattedResponse.axisOs = PrescriptionUtil.formatAxis(response.os.axis);
			formattedResponse.addPowerOs = response.os.addPower;
			formattedResponse.diameterOs = response.os.diameter;
		}
		formattedResponse.commentsOdOrOs = ObjectUtils.isNotEmptyString(response.commentsOd) ? response.commentsOd : response.commentsOs;

		this.setAddDesignation(formattedResponse);

		return formattedResponse;
	}

	setAddDesignation(response: FormattedContactLensTrialListResponse) {
		if (this.isSoftPrescriptionType(response)) {
			const odContactLensEyeSoftResponse = response.od as ContactLensEyeSoftResponse;
			const osContactLensEyeSoftResponse = response.os as ContactLensEyeSoftResponse;
			if (odContactLensEyeSoftResponse && odContactLensEyeSoftResponse.addDesignation) {
				response.addDesignationOd = odContactLensEyeSoftResponse.addDesignation.value;
			}
			if (osContactLensEyeSoftResponse && osContactLensEyeSoftResponse.addDesignation) {
				response.addDesignationOs = osContactLensEyeSoftResponse.addDesignation.value;
			}
		}
	}

	/* istanbul ignore next: gandalf */
	isSoftPrescriptionType(response: ContactLensTrialListResponse): boolean {
		return EnumUtil.equals(response.overallLensType, ContactLensPrescriptionType.SOFT);
	}

	@ShowSavedSuccessToast()
	/* istanbul ignore next: gandalf */
	createOrderFromContactLensTrialToast(createContactLensTrialOrderRequest: CreateContactLensTrialOrderRequest) {
		return this.contactLensTrialGandalfService.createOrderFromContactLensTrial(createContactLensTrialOrderRequest);
	}

	/* istanbul ignore next: gandalf */
	createOrderFromContactLensTrialNoToast(createContactLensTrialOrderRequest: CreateContactLensTrialOrderRequest) {
		return this.contactLensTrialGandalfService.createOrderFromContactLensTrial(createContactLensTrialOrderRequest);
	}

	/* istanbul ignore next: gandalf */
	createContactLensPrescriptionFromTrial(trialId: number) {
		return this.contactLensTrialGandalfService.createContactLensPrescriptionFromTrial(trialId);
	}

	/* istanbul ignore next: gandalf */
	deleteContactLensTrialById(trialId: number) {
		return this.contactLensTrialGandalfService.deleteContactLensTrialById(trialId);
	}

	getProductColorsForDropdown(locationProductId: number): Observable<OptionItemResponse<ProductColorResponse, number>[]> {
		return this.contactLensTrialGandalfService.findProductColorsForLocationProduct(locationProductId).pipe(
			map(items => OptionItemService.toSortedOptionItems(items, item => item.id, item => item.name)),
		);
	}

	/* istanbul ignore next: gandalf */
	getEyeDockUrl(locationProductId: number) {
		return this.contactLensTrialGandalfService.getEyeDockUrl(locationProductId);
	}

	/* istanbul ignore next: gandalf */
	getEmptyTrialsPerformanceScreen() {
		return this.contactLensTrialGandalfService.getEmptyPerformanceScreen().pipe(
			map(dynamicScreen => this.dynamicScreenService.calculateWidthHeightForTests(dynamicScreen)),
			map(dynamicScreen => this.dynamicScreenService.removeBlankSelectionOptionsForScreen(dynamicScreen)),
			map(dynamicScreen => this.dynamicScreenService.sortForTabbing(dynamicScreen)),
		);
	}

	/* istanbul ignore next: gandalf */
	findKeratometryTestByPatientId(patientId: number) {
		return this.contactLensTrialGandalfService.findKeratometryTestByPatientId(patientId);
	}

	/* istanbul ignore next: gandalf */
	createContactLensTrial(request: EncounterCreateContactLensTrialRequest) {
		return this.contactLensTrialGandalfService.createContactLensTrial(request);
	}

	/* istanbul ignore next: gandalf */
	updateContactLensTrial(request: EncounterUpdateContactLensTrialRequest) {
		return this.contactLensTrialGandalfService.updateContactLensTrial(request);
	}

	/* istanbul ignore next: gandalf */
	getContactLensTrialById(contactLensTrialId: number) {
		return this.contactLensTrialGandalfService.getContactLensTrialById(contactLensTrialId);
	}

	/* istanbul ignore next: gandalf */
	createContactLensPrescription(request: CreateContactLensPrescriptionRequest) {
		return this.contactLensPrescriptionGandalfService.createContactLensPrescription(request);
	}

	/* istanbul ignore next: gandalf */
	updateContactLensPrescription(request: UpdateContactLensPrescriptionRequest) {
		return this.contactLensPrescriptionGandalfService.updateContactLensPrescription(request);
	}

	/* istanbul ignore next: gandalf */
	refillContactLensPrescription(request: RefillContactLensPrescriptionRequest) {
		return this.contactLensPrescriptionGandalfService.refillContactLensPrescription(request);
	}

	/* istanbul ignore next: gandalf */
	getContactLensPrescriptionById(id: number) {
		return this.contactLensTrialGandalfService.getContactLensPrescriptionById(id);
	}
	/* istanbul ignore next: gandalf */
	createOrderFromPrescriptionNoToast(request: CreatePrescriptionOrderRequest) {
		return this.contactLensTrialGandalfService.createOrderFromPrescription(request);
	}

	@ShowSavedSuccessToast()
	createOrderFromPrescription(request: CreatePrescriptionOrderRequest) {
		return this.contactLensTrialGandalfService.createOrderFromPrescription(request);
	}

	getContactLensPropertyValues(productId: number) {
		const serviceName = 'Inventory';
		const endpointName = 'getContactLensPropertyValues';
		return this.gandalfTheGreyService.execute(serviceName, endpointName, {id: productId}).pipe(
			map(response => this.handleContactLensPropertyValues(response)),
		);
	}

	handleContactLensPropertyValues(response: any): FormattedContactLensPropertyValuesResponse {
		const lensPropertyValuesResponse = new ContactLensPropertyValuesResponse();
		lensPropertyValuesResponse.sphere = this.handlePropertyValueResponse(response.sphere);
		lensPropertyValuesResponse.baseCurve = this.handlePropertyValueResponse(response.baseCurve);
		lensPropertyValuesResponse.diameter = this.handlePropertyValueResponse(response.diameter);
		lensPropertyValuesResponse.cylinder = this.handlePropertyValueResponse(response.cylinder);
		lensPropertyValuesResponse.axis = this.handlePropertyValueResponse(response.axis);
		lensPropertyValuesResponse.add = this.handlePropertyValueResponse(response.add);
		return _assign(lensPropertyValuesResponse, { addDesignations: this.handleReferenceDataResponse(response.addDesignations) });
	}

	handlePropertyValueResponse(propertyValue: any) {
		const propertyValuesResponse = new PropertyValuesResponse();
		propertyValuesResponse.majorStepSize = propertyValue.majorStepSize;
		propertyValuesResponse.minorStepSize = propertyValue.minorStepSize;
		propertyValuesResponse.minValue = propertyValue.minValue;
		propertyValuesResponse.maxValue = propertyValue.maxValue;
		propertyValuesResponse.hasValidValues = propertyValue.hasValidValues;
		return propertyValuesResponse;
	}

	handleReferenceDataResponse(referenceDataResponses: any[]) {
		const data: OptionItemResponse<ReferenceDataResponse, number>[] = [];
		referenceDataResponses.forEach(item => {
			const referenceDataResponse = new ReferenceDataResponse();
			referenceDataResponse.id = item.id;
			referenceDataResponse.active = true;
			referenceDataResponse.categoryId = item.categoryId;
			referenceDataResponse.description = item.description;
			referenceDataResponse.masterId = item.masterId;
			referenceDataResponse.sortOrder = item.sortOrder;
			referenceDataResponse.value = item.value;
			data.push(OptionItemService.toOptionItem(referenceDataResponse, response => response.id, response => response.value));
		});
		return data;
	}

	checkProductsActiveAndCallOnConfirm(odLocationProductId: number, osLocationProductId: number, confirmedCallback: () => void) {
		const request = new OdOsLocationProductIdsActiveResponse();
		request.odLocationProductId = odLocationProductId;
		request.osLocationProductId = osLocationProductId;
		return this.contactLensPrescriptionGandalfService.checkOdOsLocationProductIdsActive(request).subscribe(result => {
			if (PrescriptionUtil.hasInactiveODProductOrOSProduct(result)) {
				PrescriptionUtil.openInactiveOdOrOsProductModal(result, confirmedCallback);
			} else {
				confirmedCallback();
			}
		});
	}
}
