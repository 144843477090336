// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.schedule.scheduleitem.FindScheduleItemsByIdRequest */
/* istanbul ignore next */
@GandalfModel
export class FindScheduleItemsByIdRequest extends GandalfModelBase {

	@GandalfLabel('Location')
	@GandalfValidator({ notNull: { message: 'Location is required' } })
	@GandalfProperty({ isRequired: true })
	locationId: number;

	@GandalfArray(Number)
	scheduleItemIds: number[];

}
