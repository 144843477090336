// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.history.presentillness.CreatePresentIllnessRequest */
/* istanbul ignore next */
@GandalfModel
export class CreatePresentIllnessRequest extends GandalfModelBase {

	@GandalfProperty()
	contextSelectionId: number;

	@GandalfValidator({ sizeString: { message: 'Context Text cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	contextText: string;

	@GandalfValidator({ sizeString: { message: 'Condition cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	diagnosisText: string;

	@GandalfProperty()
	durationAmount: number;

	@GandalfValidator({ sizeString: { message: 'Duration Text cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	durationText: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	durationUnit: constants.DurationUnit;

	@GandalfValidator({ notNull: { message: 'Encounter is required' } })
	@GandalfProperty({ isRequired: true })
	encounterId: number;

	@GandalfArray(Number)
	factorsSelectionIds: number[];

	@GandalfValidator({ sizeString: { message: 'Modifying Factors Text cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	factorsText: string;

	@GandalfProperty()
	locationSelectionId: number;

	@GandalfValidator({ sizeString: { message: 'Location Text cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	locationText: string;

	@GandalfArray(Number)
	qualitySelectionIds: number[];

	@GandalfValidator({ sizeString: { message: 'Quality Text cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	qualityText: string;

	@GandalfProperty()
	severitySelectionId: number;

	@GandalfValidator({ sizeString: { message: 'Severity Text cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	severityText: string;

	@GandalfArray(Number)
	signsSelectionIds: number[];

	@GandalfValidator({ sizeString: { message: 'Assoc S&S Text cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	signsText: string;

	@GandalfProperty()
	timingSelectionId: number;

	@GandalfValidator({ sizeString: { message: 'Timing Text cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	timingText: string;

}
