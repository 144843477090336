<form [formGroup]="formGroup" *ngIf="open" #form="ngForm" (ngSubmit)="save()">
	<div class="panel-feature">
		<button ejs-button type="button" iconCss="fa fa-chevron-left" (click)="close()"
				[attr.data-test-id]="'queryBuilderBackButton'">{{backButtonText}}</button>
	</div>
	<div class="panel-body">
		<div class="row">
			<div class="col-sm-12">
				<gandalf-lib-validation-messages
						*ngIf="formGroup.invalid && form.submitted"
						[form]="formGroup"
						[requestObj]="request"
						[attr.data-test-id]="'queryBuilderValidationMessages'">
				</gandalf-lib-validation-messages>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12">
				<h4 class="section-title bg-default mrgn-btm">{{isTrigger ? 'Search Definition' : 'Query Details'}}</h4>
				<div class="form-horizontal margin-bottom-md">
					<div class="row">
						<div class="col-md-4">
							<div class="form-group" [attr.data-test-id]="'queryNameFormGroup'">
								<label class="col-sm-4 control-label" [class.required]="isNormalQuery">Name</label>
								<div class="col-sm-8">
									<input type="text" class="e-input" revFocusInput formControlName="name" *ngIf="isNormalQuery"/>
									<p class="form-control-static" *ngIf="!isNormalQuery">{{formGroup.value.name}}</p>
								</div>
							</div>
							<div class="form-group" [attr.data-test-id]="'queryDescriptionFormGroup'">
								<label class="col-sm-4 control-label">Description</label>
								<div class="col-sm-8">
									<ejs-textbox
											[multiline]="true"
											class="h-rows-2"
											formControlName="description"
											*ngIf="isNormalQuery">
									</ejs-textbox>
									<p class="form-control-static" *ngIf="!isNormalQuery">{{formGroup.value.description}}</p>
								</div>
							</div>
							<div class="form-group" *ngIf="!!categoryEntityType" [attr.data-test-id]="'queryCategoryFormGroup'">
								<label class="col-sm-4 control-label">Category</label>
								<div class="col-sm-8">
									<ejs-dropdownlist
											[dataSource]="categories"
											[showClearButton]="true"
											placeholder="Select Category"
											formControlName="categoryId">
									</ejs-dropdownlist>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group" *ngIf="!isTrigger" [attr.data-test-id]="'queryAccessRolesFormGroup'">
								<label class="col-sm-4 control-label required">Access Roles</label>
								<div class="col-sm-8">
									<ejs-multiselect
											[dataSource]="roles"
											[fields]="{text: 'label', value: 'value'}"
											mode="CheckBox"
											[showDropDownIcon]="true"
											[showSelectAll]="true"
											[enableSelectionOrder]="false"
											placeholder="Select Access Roles"
											formControlName="roleIds">
									</ejs-multiselect>
								</div>
							</div>
							<ng-container *ngIf="isPatientTrigger">
								<div class="form-group">
									<label class="col-sm-4 control-label">Bibliographic Citation</label>
									<div class="col-sm-8">
										<input type="text" class="e-input" formControlName="interventionBibliographicCitation"/>
									</div>
								</div>
								<div class="form-group">
									<label class="col-sm-4 control-label">Developer</label>
									<div class="col-sm-8">
										<input type="text" class="e-input" formControlName="interventionDeveloper"/>
									</div>
								</div>
								<div class="form-group">
									<label class="col-sm-4 control-label">Developer Funding</label>
									<div class="col-sm-8">
										<input type="text" class="e-input" formControlName="interventionDeveloperFunding"/>
									</div>
								</div>
							</ng-container>
						</div>
						<div class="col-md-4" *ngIf="isPatientTrigger">
							<div class="form-group">
								<label class="col-sm-4 control-label">Release Date</label>
								<div class="col-sm-8">
									<ejs-datepicker formControlName="interventionReleaseDate" placeholder="mm/dd/yyyy"
													[format]="dateFormat"></ejs-datepicker>
								</div>
							</div>
							<div class="form-group">
								<label class="col-sm-4 control-label">Revision Date</label>
								<div class="col-sm-8">
									<ejs-datepicker formControlName="interventionRevisionDate" placeholder="mm/dd/yyyy"
													[format]="dateFormat"></ejs-datepicker>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<hr>
		<div class="row" *ngIf="isPatientTrigger">
			<div class="col-sm-12">
				<div class="form-horizontal margin-bottom-md">
					<div class="row">
						<div class="col-md-4">
							<div class="form-group" [attr.data-test-id]="'queryUseOfRace'">
								<label class="col-sm-4 control-label">Use of Race</label>
								<div class="col-sm-8">
									<ejs-textbox
										[multiline]="true"
										class="h-rows-2"
										formControlName="useOfRace">
									</ejs-textbox>
								</div>
							</div>
							<div class="form-group" [attr.data-test-id]="'queryUseOfSexualOrientation'">
								<label class="col-sm-4 control-label">Use of Sexual Orientation</label>
								<div class="col-sm-8">
									<ejs-textbox
										[multiline]="true"
										class="h-rows-2"
										formControlName="useOfSexualOrientation">
									</ejs-textbox>
								</div>
							</div>
							<div class="form-group" [attr.data-test-id]="'queryUseOfDateOfBirth'">
								<label class="col-sm-4 control-label">Use of Date of Birth</label>
								<div class="col-sm-8">
									<ejs-textbox
										[multiline]="true"
										class="h-rows-2"
										formControlName="useOfDateOfBirth">
									</ejs-textbox>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group" [attr.data-test-id]="'queryUseOfEthnicity'">
								<label class="col-sm-4 control-label">Use of Ethnicity</label>
								<div class="col-sm-8">
									<ejs-textbox
										[multiline]="true"
										class="h-rows-2"
										formControlName="useOfEthnicity">
									</ejs-textbox>
								</div>
							</div>
							<div class="form-group" [attr.data-test-id]="'queryUseOfGenderIdentity'">
								<label class="col-sm-4 control-label">Use of Gender Identity</label>
								<div class="col-sm-8">
									<ejs-textbox
										[multiline]="true"
										class="h-rows-2"
										formControlName="useOfGenderIdentity">
									</ejs-textbox>
								</div>
							</div>
							<div class="form-group" [attr.data-test-id]="'queryUseOfSocialDeterminants'">
								<label class="col-sm-4 control-label">Use of Social Determinants</label>
								<div class="col-sm-8">
									<ejs-textbox
										[multiline]="true"
										class="h-rows-2"
										formControlName="useOfSocialDeterminants">
									</ejs-textbox>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group" [attr.data-test-id]="'queryUseOfLanguage'">
								<label class="col-sm-4 control-label">Use of Language</label>
								<div class="col-sm-8">
									<ejs-textbox
										[multiline]="true"
										class="h-rows-2"
										formControlName="useOfLanguage">
									</ejs-textbox>
								</div>
							</div>
							<div class="form-group" [attr.data-test-id]="'queryUseOfSex'">
								<label class="col-sm-4 control-label">Use of Sex</label>
								<div class="col-sm-8">
									<ejs-textbox
										[multiline]="true"
										class="h-rows-2"
										formControlName="useOfSex">
									</ejs-textbox>
								</div>
							</div>
							<div class="form-group" [attr.data-test-id]="'queryUseOfHealthStatusAssessments'">
								<label class="col-sm-4 control-label">Use of Health Status Assessments</label>
								<div class="col-sm-8">
									<ejs-textbox
										[multiline]="true"
										class="h-rows-2"
										formControlName="useOfHealthStatusAssessments">
									</ejs-textbox>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-4 col-md-4 col-lg-3" [hidden]="!isNormalQuery">
				<h4 class="section-title bg-default mrgn-btm">Available Fields</h4>
				<div class="margin-bottom-md">
					<div class="panel panel-default h500 overflow-auto">
						<ejs-treeview #avaiableFieldsTree
									  [fields]="availableFieldsModel"
									  [allowMultiSelection]="true"
									  [allowDragAndDrop]="true"
									  (nodeDragStart)="availableFieldDragStart($event)"
									  (nodeDragging)="availableFieldDragging($event)"
									  (nodeDragStop)="availableFieldDragStop($event)"
									  [attr.data-test-id]="'queryBuilderAvailableFieldsMenu'">
						</ejs-treeview>
					</div>
				</div>
			</div>
			<div [className]="isNormalQuery ? 'col-sm-8 col-md-8 col-lg-9' : 'col-sm-12'">
				<div class="e-droppable" id="searchCriteria" [hidden]="!isNormalQuery && !searchCriteriaGrid.currentViewData.length">
					<h4 class="section-title bg-default mrgn-btm">Search Criteria</h4>
					<div class="margin-bottom-md" [attr.data-test-id]="'searchCriteriaGrid'">
						<ejs-grid #searchCriteriaGrid
								  [dataSource]="criteriaFields"
								  [hidden]="!searchCriteriaGrid.currentViewData.length"
								  gridLines="Horizontal"
								  [allowSelection]="true"
								  [editSettings]="{allowAdding: true, allowDeleting: true}"
								  [allowRowDragAndDrop]="isNormalQuery"
								  [attr.data-test-id]="'queryBuilderSearchCriteriaGrid'"
						>
							<e-columns>
								<e-column field="label" headerText="Field" clipMode="EllipsisWithTooltip" width="auto"></e-column>
								<e-column field="operator" headerText="Operator" [width]="200" clipMode="Clip" *ngIf="isNormalQuery">
									<ng-template #template let-data>
										<pms-constant-dropdown
												[constantList]="data.operators"
												[(ngModel)]="data.operator" [ngModelOptions]="{standalone: true}"
												(ngModelChange)="onCriteriaOperatorChange(data)"
												placeholder="Select Operator"
												revEventStopPropagation
												dataTestIdPrefix="searchCriteria">
										</pms-constant-dropdown>
									</ng-template>
								</e-column>
								<e-column
										field="operator.value"
										headerText="Operator"
										[width]="200"
										clipMode="EllipsisWithTooltip"
										*ngIf="!isNormalQuery">
								</e-column>
								<e-column field="operand1" headerText="Comparison Value(s)" [width]="375" clipMode="Clip">
									<ng-template #template let-data>
										<ng-container [ngSwitch]="data.operandComponent">
											<input
													*ngSwitchCase="operandComponent.TEXT"
													type="text" class="e-input"
													[(ngModel)]="data.operand1" [ngModelOptions]="{standalone: true}"
													(ngModelChange)="onCriteriaOperandChange(data)"
													revEventStopPropagation/>
											<input
													*ngSwitchCase="operandComponent.NUMBER"
													type="number" class="e-input"
													[(ngModel)]="data.operand1" [ngModelOptions]="{standalone: true}"
													(ngModelChange)="onCriteriaOperandChange(data)"
													revEventStopPropagation/>
											<div class="input-range" *ngSwitchCase="operandComponent.NUMBER_RANGE">
												<div class="input-range-input">
													<input
															type="number" class="e-input"
															[(ngModel)]="data.operand1" [ngModelOptions]="{standalone: true}"
															(ngModelChange)="onCriteriaOperandChange(data)"
															revEventStopPropagation/>
												</div>
												<div class="input-range-label">
													<label class="control-label">to</label>
												</div>
												<div class="input-range-input">
													<input
															type="number" class="e-input"
															[(ngModel)]="data.operand2" [ngModelOptions]="{standalone: true}"
															(ngModelChange)="onCriteriaOperandChange(data)"
															revEventStopPropagation/>
												</div>
											</div>
											<ejs-datepicker
													*ngSwitchCase="operandComponent.DATE"
													placeholder="mm/dd/yyyy"
													[(ngModel)]="data.operand1" [ngModelOptions]="{standalone: true}"
													(ngModelChange)="onCriteriaOperandChange(data)"
													[format]="dateFormat"
													revEventStopPropagation>
											</ejs-datepicker>
											<div class="input-range" *ngSwitchCase="operandComponent.DATE_RANGE">
												<div class="input-range-input">
													<ejs-datepicker
															placeholder="mm/dd/yyyy"
															[(ngModel)]="data.operand1" [ngModelOptions]="{standalone: true}"
															(ngModelChange)="onCriteriaOperandChange(data)"
															[format]="dateFormat"
															revEventStopPropagation>
													</ejs-datepicker>
												</div>
												<div class="input-range-label">
													<label class="control-label">to</label>
												</div>
												<div class="input-range-input">
													<ejs-datepicker
															placeholder="mm/dd/yyyy"
															[(ngModel)]="data.operand2" [ngModelOptions]="{standalone: true}"
															(ngModelChange)="onCriteriaOperandChange(data)"
															[format]="dateFormat"
															revEventStopPropagation>
													</ejs-datepicker>
												</div>
											</div>
											<pms-constant-dropdown
													*ngSwitchCase="operandComponent.DATE_STANDARD_RANGE"
													[constantList]="dateRangeTypeValues"
													[(ngModel)]="data.operand1" [ngModelOptions]="{standalone: true}"
													(ngModelChange)="onCriteriaOperandChange(data)"
													placeholder="Select"
													revEventStopPropagation>
											</pms-constant-dropdown>
											<ng-container *ngSwitchCase="operandComponent.DATE_WITHIN">
												<input
														type="number"
														class="e-input display-inline-block wAuto vertical-align-middle margin-right-sm"
														[(ngModel)]="data.operand1" [ngModelOptions]="{standalone: true}"
														(ngModelChange)="onCriteriaOperandChange(data)"
														revEventStopPropagation/>
												<pms-constant-dropdown
														[constantList]="queryCriteriaWithinUnitValues"
														[(ngModel)]="data.operand2" [ngModelOptions]="{standalone: true}"
														(ngModelChange)="onCriteriaOperandChange(data)"
														placeholder="Select"
														class="display-inline-block wAuto vertical-align-middle"
														revEventStopPropagation>
												</pms-constant-dropdown>
											</ng-container>
											<ejs-dropdownlist
													*ngSwitchCase="operandComponent.SELECT"
													[dataSource]="data.options"
													[(ngModel)]="data.operand1" [ngModelOptions]="{standalone: true}"
													placeholder="Select"
													(ngModelChange)="onCriteriaOperandChange(data)"
													revEventStopPropagation>
											</ejs-dropdownlist>
											<pms-enum-select-button
													*ngSwitchCase="operandComponent.BOOLEAN"
													[enumList]="queryBooleanValues"
													[(ngModel)]="data.operand1" [ngModelOptions]="{standalone: true}"
													(ngModelChange)="onCriteriaOperandChange(data)"
													revEventStopPropagation>
											</pms-enum-select-button>
										</ng-container>
									</ng-template>
								</e-column>
								<e-column
										field="remove"
										headerText=""
										[width]="47"
										clipMode="Clip"
										*ngIf="isNormalQuery">
									<ng-template #template let-data>
										<rev-button
												[revGridButton]="'remove'"
												(buttonClick)="removeField(searchCriteriaGrid, data)"
												dataTestId="queryBuilderCriteriaFieldRemoveButton">
										</rev-button>
									</ng-template>
								</e-column>
								<e-column field='uid' [visible]="false" [isPrimaryKey]="true"></e-column>
							</e-columns>
						</ejs-grid>
						<div class="panel panel-default text-center" [hidden]="!!searchCriteriaGrid.currentViewData.length">
							<div class="panel-body" [attr.data-test-id]="'queryBuilderAvailableFieldsPanel'">
								Drag field(s) from Available Fields list here.
							</div>
						</div>
					</div>
				</div>
				<div class="row" *ngIf="!isTrigger">
					<div class="col-sm-12 col-lg-6 e-droppable" id="resultFields">
						<h4 class="section-title bg-default mrgn-btm">Result Fields</h4>
						<div class="margin-bottom-md">
							<ejs-grid #resultFieldsGrid
									  [dataSource]="formGroup.value.selectFields"
									  [hidden]="!resultFieldsGrid.currentViewData.length"
									  gridLines="Horizontal"
									  [allowSelection]="true"
									  [editSettings]="{allowAdding: true, allowDeleting: true}"
									  [allowRowDragAndDrop]="isNormalQuery"
									  [attr.data-test-id]="'queryBuilderResultFieldGrid'">
								<e-columns>
									<e-column field="label" headerText="Field" clipMode="EllipsisWithTooltip"></e-column>
									<e-column
											field="remove"
											headerText=""
											[width]="47"
											clipMode="Clip"
											*ngIf="isNormalQuery">
										<ng-template #template let-data>
											<rev-button
													[revGridButton]="'remove'"
													(buttonClick)="removeField(resultFieldsGrid, data)"
													dataTestId="queryBuilderResultFieldRemoveButton">
											</rev-button>
										</ng-template>
									</e-column>
								</e-columns>
							</ejs-grid>
							<div class="panel panel-default text-center" [hidden]="!!resultFieldsGrid.currentViewData.length">
								<div class="panel-body" [attr.data-test-id]="'queryBuilderResultFieldPanel'">
									Drag field(s) from Available Fields list here.
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-12 col-lg-6 e-droppable" id="sortFields">
						<h4 class="section-title bg-default mrgn-btm">Sort Fields</h4>
						<div class="margin-bottom-md">
							<ejs-grid #sortFieldsGrid
									  [dataSource]="formGroup.value.sortFields"
									  [hidden]="!sortFieldsGrid.currentViewData.length"
									  gridLines="Horizontal"
									  [allowSelection]="true"
									  [editSettings]="{allowAdding: true, allowDeleting: true}"
									  [allowRowDragAndDrop]="isNormalQuery"
									  [attr.data-test-id]="'queryBuilderSortFieldGrid'">
								<e-columns>
									<e-column field="label" headerText="Field" clipMode="EllipsisWithTooltip" width="auto"></e-column>
									<e-column field="direction" headerText="Sort" [width]="130" clipMode="Clip" *ngIf="isNormalQuery">
										<ng-template #template let-data>
											<pms-enum-select-button
													[enumList]="querySortDirectionValues"
													[(ngModel)]="data.direction"
													[ngModelOptions]="{standalone: true}"
													(ngModelChange)="onSortFieldChange(data)"
													revEventStopPropagation>
											</pms-enum-select-button>
										</ng-template>
									</e-column>
									<e-column
											field="direction.value"
											headerText="Sort"
											[width]="130"
											clipMode="EllipsisWithTooltip"
											*ngIf="!isNormalQuery">
									</e-column>
									<e-column
											field="remove"
											headerText=""
											[width]="47"
											clipMode="Clip"
											*ngIf="isNormalQuery">
										<ng-template #template let-data>
											<rev-button
													[revGridButton]="'remove'"
													(buttonClick)="removeField(sortFieldsGrid, data)"
													dataTestId="queryBuilderSortFieldRemoveButton">
											</rev-button>
										</ng-template>
									</e-column>
								</e-columns>
							</ejs-grid>
							<div class="panel panel-default text-center" [hidden]="!!sortFieldsGrid.currentViewData.length">
								<div class="panel-body" [attr.data-test-id]="'queryBuilderSortFieldPanel'">
									Drag field(s) from Available Fields list here.
								</div>
							</div>
						</div>
					</div>
				</div>
				<pms-query-trigger-search-results #queryTriggerSearchResults
												  [trigger]="queryTrigger"
												  *ngIf="isTrigger"
												  [attr.data-test-id]="'queryBuilderSearchResults'">
				</pms-query-trigger-search-results>
			</div>
		</div>
	</div>
	<div class="panel-footer">
		<button ejs-button type="submit" [isPrimary]="true" [attr.data-test-id]="'queryBuilderSaveButton'">Save</button>
		<button ejs-button type="button" iconCss="fa fa-close" (click)="close()" [attr.data-test-id]="'queryBuilderCancelButton'">Cancel</button>
	</div>
</form>
