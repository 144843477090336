<ejs-dialog
	#modal
	cssClass="modal-md"
	(close)="closeModal()"
	[closeOnEscape]="!isCreditCardProcessing()"
	[showCloseIcon]="!isCreditCardProcessing()"
	[attr.data-test-id]="'openEdgeProcessPaymentModal'">
	<ng-template #header>
		<div class="dlg-template">Process Payment</div>
	</ng-template>
	<ng-template #content>
		@if (errors.length) {
			<div class="alert alert-danger" [attr.data-test-id]="'openEdgeProcessPaymentModalValidationMessages'">
				<ul>
					@for (error of errors; track error) {
						<li><strong>{{ error }}</strong></li>
					}
				</ul>
			</div>
		}
		<div class="text-center">
			<p>Total Amount<strong class="margin-left-sm">{{ amount | currency }}</strong></p>
		</div>
		<pms-credit-card-form [isCardFormLoaded]="isCardFormLoaded()" [isProcessing]="isCreditCardProcessing()"></pms-credit-card-form>

		@if (showSaveCreditCardOption()) {
			<div class="text-center margin-top-lg">
				<ejs-checkbox #checkbox label="Save credit card on file" [(ngModel)]="saveCreditCard"
							  [attr.data-test-id]="'openEdgeProcessPaymentModalSaveCCOption'"></ejs-checkbox>
			</div>
		}
	</ng-template>
	<ng-template #footerTemplate>
		@if (!isCreditCardProcessing()) {
			<button ejs-button cssClass="e-link pull-left margin-left-0" (click)="applyPaymentWithoutProcessingCard()"
					[attr.data-test-id]="'applyPaymentWithoutProcessingCardButton'">Apply payment without processing card
			</button>
			<button ejs-button iconCss="fa fa-times" type="button" (click)="closeModal()" [attr.data-test-id]="'processPaymentCancelButton'">Cancel</button>
			<button ejs-button [isPrimary]="true" type="button" (click)="processPayment()" [disabled]="processPaymentDisabled()"
					[attr.data-test-id]="'processPaymentProcessPaymentButton'">{{ processPaymentText }}
			</button>
		}
	</ng-template>
</ejs-dialog>

