import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
/**
 * This service provides new unique negative ids for routing/state management of new entity creation screens
 */
export class NewEntityUniqueIdService {

	private lastId = -1;

	constructor() {
	}

	get nextId() {
		return this.lastId--;
	}
}
