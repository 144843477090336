// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

/** See com.rev360.pms.api.controller.patient.PatientEmploymentResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientEmploymentResponse extends GandalfModelBase {

	@GandalfProperty()
	address: AddressResponse;

	@GandalfProperty()
	employerName: string;

	@GandalfProperty()
	position: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.PatientEmploymentStatus;

}
