// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.note.NoteResponse */
/* istanbul ignore next */
@GandalfModel
export class NoteResponse extends GandalfModelBase {

	@GandalfProperty()
	createdBy: string;

	@GandalfProperty()
	createdOn: Date;

	@GandalfProperty()
	currentUser: string;

	@GandalfProperty()
	editable: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	entityType: constants.EntityType;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	parentId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.NoteStatus;

	@GandalfProperty()
	text: string;

	@GandalfProperty()
	version: number;

}
