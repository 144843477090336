import { Component, OnInit } from '@angular/core';
import { FeatureService } from '@core/feature/feature.service';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { timer } from 'rxjs';
import { DatePipe } from '@angular/common';
import { AuthenticationService } from '@core/authentication/authentication.service';
import { PreferenceDefaults, PreferenceName } from '@gandalf/constants';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';

@Component({
	selector: 'pms-footer',
	templateUrl: './footer.component.html',
	styles: [],
})

export class FooterComponent implements OnInit {
	clockDayOfWeek: string;
	clockMonthDay: string;
	clockYear: number;
	clockTimeFormat: string = DATE_FORMATS.H_MM_SS_A;
	clockTime: Date;
	readonly versionNumber = '7.6.2';
	readonly versionDate = '06/21/2024';

	isFooterFeatureFlagOn = true;

	constructor(
		private authenticationService: AuthenticationService,
		private securityManagerService: SecurityManagerService,
		private featureService: FeatureService,
		private datePipe: DatePipe,
	) {
	}

	ngOnInit() {
		this.authenticationService.loggedIn.subscribe(() => this.loggedIn());
		timer(0, 1000).subscribe(() => {
			this.updateClock();
		});
	}

	loggedIn() {
		this.isFooterFeatureFlagOn = this.featureService.isFeatureOn(FEATURE_FLAGS.MODULES.FOOTER);
		this.clockTimeFormat =
			!this.securityManagerService.preferenceValueIsOn(PreferenceName.DATE_TIME_DISPLAY_SECONDS.value, PreferenceDefaults.DATE_TIME_DISPLAY_SECONDS.value)
				? DATE_FORMATS.H_MM_A
				: DATE_FORMATS.H_MM_SS_A;
	}

	updateClock() {
		this.clockTime = new Date();
		this.clockDayOfWeek = this.datePipe.transform(this.clockTime, DATE_FORMATS.E);
		this.clockMonthDay = `${this.datePipe.transform(this.clockTime, DATE_FORMATS.LLL)} ${this.clockTime.getDate()}`;
		this.clockYear = this.clockTime.getFullYear();
	}
}
