import { Pipe, PipeTransform } from '@angular/core';
import { _isEmpty } from '@core/lodash/lodash';

@Pipe({
	name: 'databaseIds',
})
export class DatabaseIdsPipe implements PipeTransform {

	transform(databaseIds: number[]): string {
		return _isEmpty(databaseIds) ? '' : databaseIds.map(id => `#${id}`).join(', ');
	}
}
