// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { MipsQualityResultResponse } from './mips-quality-result-response';

/** See com.rev360.pms.api.controller.reporting.admin.MipsQualityReportResponse */
/* istanbul ignore next */
@GandalfModel
export class MipsQualityReportResponse extends GandalfModelBase {

	@GandalfArray(MipsQualityResultResponse)
	mipsQualityResults: MipsQualityResultResponse[];

	@GandalfProperty()
	qualityCompositeScore: number;

	@GandalfProperty()
	totalCehrtBonus: number;

	@GandalfProperty()
	totalHighPriorityBonus: number;

	@GandalfProperty()
	totalPoints: number;

}
