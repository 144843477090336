import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { FormattedFeeScheduleDropdownResponse, FormattedPracticeInsuranceCompanyResponse, InsuranceService } from '@core/insurance/insurance.service';
import { HIT_PMS_HTML_PERMISSIONS } from '@core/legacy/hit-pms-html.constants';
import { _isNil } from '@core/lodash/lodash';
import { ModalManagerService, OptionItem } from 'morgana';
import { PersonDetailsService } from '@core/person-details/person-details.service';
import { ReferenceDataService } from '@core/reference-data/reference-data.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { PolicyHolderRelationship, ReferenceDataMasterCategory } from '@gandalf/constants';
import { AddressResponse } from '@gandalf/model/address-response';
import { PersonInsuranceResponse } from '@gandalf/model/person-insurance-response';
import { PersonNameResponse } from '@gandalf/model/person-name-response';
import { PracticeInsuranceCompanyModalResponse } from '@gandalf/model/practice-insurance-company-modal-response';
import { ConstantDropdownComponent } from '@shared/component/constant-dropdown/constant-dropdown.component';
import { AddCompanyModalComponent } from '@shared/component/insurance/add-company/add-company-modal.component';
import { PolicyHolderModalComponent } from '@shared/component/insurance/policy-holder/policy-holder-modal.component';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { GandalfConstantList } from 'gandalf';
import { combineLatest } from 'rxjs';

export const selfPolicyHolderRelationshipOptions: GandalfConstantList<PolicyHolderRelationship> = {
	label: 'Policy Holder Relationship',
	values: [
		PolicyHolderRelationship.SELF,
	],
};

export const vspPolicyHolderRelationshipOptions: GandalfConstantList<PolicyHolderRelationship> = {
	label: 'Policy Holder Relationship',
	values: [
		PolicyHolderRelationship.SPOUSE,
		PolicyHolderRelationship.CHILD,
		PolicyHolderRelationship.OTHER,
		PolicyHolderRelationship.STUDENT,
	],
};

export const basicPolicyHolderRelationshipOptions: GandalfConstantList<PolicyHolderRelationship> = {
	label: 'Policy Holder Relationship',
	values: [
		PolicyHolderRelationship.SPOUSE,
		PolicyHolderRelationship.CHILD,
		PolicyHolderRelationship.OTHER,
	],
};

@Component({
	selector: 'pms-insurance-basic-information-form',
	templateUrl: './insurance-basic-information-form.component.html',
	styles: [],
	providers: [ModalManagerService],
})
export class InsuranceBasicInformationFormComponent implements OnInit {

	@Input()
	patientId: number;

	@Input()
	personId: number;

	@Input()
	personInsuranceId: number;

	@Input()
	canSelectPolicyHolder: boolean;

	@Input()
	selectedIntakeInsuranceId: number;

	@Output()
	policyHolderIsSelf = new EventEmitter<boolean>();

	@Output()
	nonPolicyHolderPersonInsurance = new EventEmitter<PersonInsuranceResponse>();

	@ViewChild('feeScheduleDropdown')
	feeScheduleDropdown: DropDownListComponent;

	@ViewChild('priorityDropdown')
	priorityDropdown: DropDownListComponent;

	@ViewChild('typeDropdown')
	typeDropdown: DropDownListComponent;

	@ViewChild('companyDropdown')
	companyDropdown: DropDownListComponent;

	@ViewChild('policyHolderRelationshipDropdown')
	policyHolderRelationshipDropdown: ConstantDropdownComponent;

	componentForm: UntypedFormGroup;
	companyOptions: FormattedPracticeInsuranceCompanyResponse[];
	priorityOptions: OptionItem[];
	typeOptions: OptionItem[];
	policyHolderRelationshipOptions: GandalfConstantList<PolicyHolderRelationship>;
	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	canAddCompany = false;
	feeSchedules: FormattedFeeScheduleDropdownResponse[];
	policyHolderDob: Date;
	isOwnPolicy = true;
	policyHolderName: PersonNameResponse;
	selectedCompanyName: string;
	disablePolicyHolderChanges = false;
	personName: PersonNameResponse;
	companyAddress: AddressResponse;

	@Input()
	isUpdating: boolean;

	constructor(
		private referenceDataService: ReferenceDataService,
		private securityManagerService: SecurityManagerService,
		private modalManagerService: ModalManagerService,
		private insuranceService: InsuranceService,
		private changeDetectorRef: ChangeDetectorRef,
		public controlContainer: ControlContainer,
		private personDetailsService: PersonDetailsService,
	) {
	}

	ngOnInit() {
		this.componentForm = this.controlContainer.control as UntypedFormGroup;
		this.canAddCompany = this.securityManagerService.hasPermission(HIT_PMS_HTML_PERMISSIONS.RESOURCE_PATIENT_ADD_INSURANCE_COMPANY);
		this.getData();
		this.handleFormState();
	}

	refreshDropdowns() {
		this.feeScheduleDropdown?.refresh();
		this.priorityDropdown?.refresh();
		this.typeDropdown?.refresh();
		this.companyDropdown?.refresh();
	}

	getData() {
		combineLatest([
			this.insuranceService.findActivePracticeFeeSchedules(),
			this.referenceDataService.getActiveReferenceDataByCategoryIdForDropdown(ReferenceDataMasterCategory.INSURANCE_PRIORITY.value),
			this.referenceDataService.getActiveReferenceDataByCategoryIdForDropdown(ReferenceDataMasterCategory.INSURANCE_TYPE.value),
			this.isUpdating
				? this.insuranceService.getPracticeInsuranceCompanyByPersonInsuranceId(this.personInsuranceId)
				: this.insuranceService.findActivePracticeInsuranceCompanies(),
			this.personDetailsService.getPersonNameByPersonId(this.personId),
		]).subscribe(([feeSchedules, priorities, types, companyOptions, personName]) => {
			this.feeSchedules = feeSchedules;
			this.priorityOptions = priorities;
			this.typeOptions = types;
			this.companyOptions = companyOptions;
			if (this.componentForm.get('companyId').value) {
				this.companyAddress = companyOptions.find(response => response.id === this.componentForm.get('companyId').value)?.address;
			}
			this.personName = personName;
			if (this.isOwnPolicy) {
				this.policyHolderName = this.personName;
			}
			this.refreshDropdowns();
		});
	}

	/* istanbul ignore next */
	handleFormState() {
		FormUtilsService.reactToValueChanges(this.componentForm, this.updatePolicyHolderRelationshipDropdownOptions, true);
	}

	updatePolicyHolderRelationshipDropdownOptions = () => {
		FormUtilsService.disabledWhen(this.componentForm.get('policyHolderRelationship'), this.isOwnPolicy);
		if (this.isOwnPolicy) {
			this.policyHolderRelationshipOptions = selfPolicyHolderRelationshipOptions;
		} else {
			if (this.selectedCompanyName &&
				(this.selectedCompanyName.toUpperCase() === 'VSP' ||
					this.selectedCompanyName.toUpperCase() === 'VISION SERVICE PLAN')) {
				this.policyHolderRelationshipOptions = vspPolicyHolderRelationshipOptions;
			} else {
				this.policyHolderRelationshipOptions = basicPolicyHolderRelationshipOptions;
			}
		}
	};

	/* istanbul ignore next */
	openPolicyHolderModal() {
		this.modalManagerService.open(PolicyHolderModalComponent, {
			data: {
				patientId: this.patientId,
				personId: this.personId,
				selectedIntakeInsuranceId: this.selectedIntakeInsuranceId,
			},
		}).onClose.subscribe((selectedPolicy) => {
			this.setSelfPolicyOrNonSelfPolicyValues(selectedPolicy);
		});
	}

	setSelfPolicyOrNonSelfPolicyValues(selectedPolicy) {
		if (selectedPolicy.personInsuranceId) {
			combineLatest([
				this.insuranceService.findPracticeInsuranceCompanies(),
				this.insuranceService.getPersonInsuranceById(selectedPolicy.personInsuranceId),
			]).subscribe(([companyOptions, personInsuranceResponse]) => {
				this.updatePolicyFields(personInsuranceResponse);
				this.companyOptions = companyOptions;
			});
		} else if (selectedPolicy.isSelfSelect) {
			this.insuranceService.findPracticeInsuranceCompanies().subscribe((companyOptions) => {
				this.companyOptions = companyOptions;
			});
			this.selfPolicySelect();
		}
	}

	selfPolicySelect() {
		this.setSelfPolicy();
		this.canAddCompany = true;
		this.componentForm.get('personInsurancePolicyId').setValue(null);
		this.componentForm.get('policyHolderRelationship').setValue(PolicyHolderRelationship.SELF);

		this.componentForm.get('companyId').enable();
		this.componentForm.get('planName').enable();
		this.componentForm.get('groupNumber').enable();
		this.componentForm.get('feeScheduleId').enable();
		this.componentForm.get('effectiveDate').enable();
		this.componentForm.get('termedDate').enable();
		this.componentForm.get('familyDeductible').enable();
		this.componentForm.get('generalCoPay').enable();
		this.componentForm.get('medicalExamCoPay').enable();
		this.componentForm.get('routineExamCoPay').enable();
		this.componentForm.get('contactLensFittingCoPay').enable();
		this.componentForm.get('coInsurancePercentage').enable();
		this.componentForm.get('materialCoPay').enable();
		this.componentForm.get('minFrameAllowance').enable();
		this.componentForm.get('maxFrameAllowance').enable();
		this.componentForm.get('contactLensAllowance').enable();
		this.componentForm.get('rxBinNumber').enable();
		this.componentForm.get('rxPcnNumber').enable();
		this.policyHolderIsSelf.emit(true);
	}

	setSelfPolicy() {
		this.isOwnPolicy = true;
		this.policyHolderDob = null;
		this.policyHolderName = this.personName;
		this.canAddCompany = false;
		this.updatePolicyHolderRelationshipDropdownOptions();
	}

	updatePolicyFields(personInsuranceResponse: PersonInsuranceResponse) {
		this.setNonSelfPolicy(personInsuranceResponse);

		this.componentForm.patchValue({
			planName: personInsuranceResponse.planName,
			policyHolderRelationship: null,
			companyId: personInsuranceResponse.companyId,
			priorityId: personInsuranceResponse.priorityId,
			insuranceTypeId: personInsuranceResponse.insuranceTypeId,
			personInsurancePolicyId: personInsuranceResponse.policyId,
			policyNumber: personInsuranceResponse.policyNumber,
			groupNumber: personInsuranceResponse.groupNumber,
			feeScheduleId: personInsuranceResponse.feeScheduleId,
			effectiveDate: personInsuranceResponse.effectiveDate,
			termedDate: personInsuranceResponse.termedDate,
			familyDeductible: personInsuranceResponse.familyDeductible,
			generalCoPay: personInsuranceResponse.policyGeneralCoPay,
			medicalExamCoPay: personInsuranceResponse.policyMedicalExamCoPay,
			routineExamCoPay: personInsuranceResponse.policyRoutineExamCoPay,
			contactLensFittingCoPay: personInsuranceResponse.policyContactLensFittingCoPay,
			coInsurancePercentage: personInsuranceResponse.coInsurancePercentage,
			materialCoPay: personInsuranceResponse.policyMaterialCoPay,
			minFrameAllowance: personInsuranceResponse.policyMinFrameAllowance,
			maxFrameAllowance: personInsuranceResponse.policyMaxFrameAllowance,
			contactLensAllowance: personInsuranceResponse.policyContactLensAllowance,
			rxBinNumber: personInsuranceResponse.policyRxBinNumber,
			rxPcnNumber: personInsuranceResponse.policyRxPcnNumber,
		});

		if (personInsuranceResponse.policyHolderPersonId !== this.personId) {
			this.componentForm.get('companyId').disable();
			this.componentForm.get('planName').disable();
			this.componentForm.get('groupNumber').disable();
			this.componentForm.get('feeScheduleId').disable();
			this.componentForm.get('planName').disable();
			this.componentForm.get('effectiveDate').disable();
			this.componentForm.get('termedDate').disable();
			this.componentForm.get('familyDeductible').disable();
			this.componentForm.get('generalCoPay').disable();
			this.componentForm.get('medicalExamCoPay').disable();
			this.componentForm.get('routineExamCoPay').disable();
			this.componentForm.get('contactLensFittingCoPay').disable();
			this.componentForm.get('coInsurancePercentage').disable();
			this.componentForm.get('materialCoPay').disable();
			this.componentForm.get('minFrameAllowance').disable();
			this.componentForm.get('maxFrameAllowance').disable();
			this.componentForm.get('contactLensAllowance').disable();
			this.componentForm.get('rxBinNumber').disable();
			this.componentForm.get('rxPcnNumber').disable();
		} else {
			this.componentForm.get('companyId').disable();
		}

		this.policyHolderIsSelf.emit(false);
		this.nonPolicyHolderPersonInsurance.emit(personInsuranceResponse);
	}

	setNonSelfPolicy(personInsuranceResponse: PersonInsuranceResponse) {
		this.setPersonInfo(personInsuranceResponse);
		this.isOwnPolicy = false;
		this.canAddCompany = false;
		this.updatePolicyHolderRelationshipDropdownOptions();
		this.changeDetectorRef.detectChanges();
	}

	setPersonInfo(personInsuranceResponse: PersonInsuranceResponse) {
		this.policyHolderDob = personInsuranceResponse.policyHolderDob;
		this.policyHolderName = personInsuranceResponse.policyHolderName;
	}

	/* istanbul ignore next */
	openAddCompanyModal() {
		this.modalManagerService.open(AddCompanyModalComponent, {}).onClose.subscribe((company: PracticeInsuranceCompanyModalResponse) => {
			if (!_isNil(company)) {
				this.insuranceService.findActivePracticeInsuranceCompanies().subscribe(companies => {
					this.companyOptions = companies;
					this.setFormDataFromCompany(company);
				});
			}
		});
	}

	setFormDataFromCompany(company: FormattedPracticeInsuranceCompanyResponse | PracticeInsuranceCompanyModalResponse) {
		this.componentForm.get('companyId').setValue(company.id);
		if (_isNil(this.componentForm.get('priorityId').value)) {
			this.componentForm.get('priorityId').setValue(company.defaultPriorityReferenceId);
		}
		if (_isNil(this.componentForm.get('insuranceTypeId').value)) {
			this.componentForm.get('insuranceTypeId').setValue(company.defaultInsuranceTypeReferenceId);
		}
		this.companyAddress = company.address;
	}

	companySelected(event: any) {
		if (!_isNil(event.itemData)) {
			this.selectedCompanyName = event.itemData.label;
			this.setFormDataFromCompany(event.itemData as FormattedPracticeInsuranceCompanyResponse);
		}
	}
}
