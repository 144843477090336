// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ServiceAdjustmentResponse } from './service-adjustment-response';

/** See com.rev360.pms.api.controller.accounting.payment.ServiceLineResponse */
/* istanbul ignore next */
@GandalfModel
export class ServiceLineResponse extends GandalfModelBase {

	@GandalfArray(ServiceAdjustmentResponse)
	adjustments: ServiceAdjustmentResponse[];

	@GandalfProperty({ propertyType: 'Money' })
	billed: number;

	@GandalfArray(String)
	modifiers: string[];

	@GandalfProperty()
	procedureCode: string;

	@GandalfProperty({ propertyType: 'Money' })
	providerPaid: number;

	@GandalfArray(String)
	remarkCodes: string[];

	@GandalfProperty({ propertyType: 'LocalDate' })
	serviceDate: Date;

	@GandalfProperty()
	units: string;

}
