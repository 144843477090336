// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.barcode.LocationProductItemForBarcodePrintingResponse */
/* istanbul ignore next */
@GandalfModel
export class LocationProductItemForBarcodePrintingResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	calculatedRetailPrice: number;

	@GandalfProperty()
	frameBridge: number;

	@GandalfProperty()
	frameColorName: string;

	@GandalfProperty()
	frameEye: number;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	itemSku: string;

	@GandalfProperty()
	itemUpc: string;

	@GandalfProperty()
	locationBarcodeIdentifier: string;

	@GandalfProperty()
	manufacturerName: string;

	@GandalfProperty()
	productName: string;

	@GandalfProperty()
	quantityInStock: number;

}
