<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-md"
	[attr.data-test-id]="'grantCreditModal'">
	<ng-template #header>
		<div class="dlg-template">Grant Credit</div>
	</ng-template>
	<ng-template #content>
		<form class="form-horizontal" [formGroup]="componentForm" (ngSubmit)="submit()" #templateForm="ngForm">
			@if (componentForm.invalid && templateForm.submitted) {
				<gandalf-lib-validation-messages
					[form]="componentForm"
					[requestObj]="request"
					[attr.data-test-id]="'grantCreditModalValidationMessages'">
				</gandalf-lib-validation-messages>
			}
			<h4 class="section-title bg-default mrgn-btm" [attr.data-test-id]="'grantCreditModalTitle'">Invoice #{{ invoiceId }} for
				<strong>{{ patient | patientName }}</strong></h4>
			<div class="form-group" [attr.data-test-id]="'currentBalanceLabelGroup'">
				<label class="col-sm-3 control-label">Current Balance</label>
				<div class="col-sm-9">
					<div class="row">
						<div class="col-sm-4">
							<p class="form-control-static">{{ currentBalance | currency }}</p>
						</div>
					</div>
				</div>
			</div>
			<hr>
			<div class="form-group" formGroupName="patientCredit">
				<div class="col-sm-3">
					<ejs-checkbox
						(change)="handleCheckbox($event)"
						[(checked)]="hasPatientCredit"
						label="Credit Patient"
						class="e-checkbox-wrapper"
						role="checkbox"
						[attr.data-test-id]="'creditPatientCheckBox'">
					</ejs-checkbox>
				</div>
				<div class="col-sm-9">
					<div class="row">
						<div class="col-sm-4">
							<pms-always-set-currency-input min="0" formControlName="amount" [attr.data-test-id]="'patientCreditAmountInput'"></pms-always-set-currency-input>
						</div>
					</div>
				</div>
			</div>
			<hr>
			<div class="form-group" formGroupName="familyCredit">
				<div class="col-sm-3">
					<ejs-checkbox
						(change)="handleCheckbox($event)"
						[(checked)]="hasFamilyCredit"
						[disabled]="hasNoFamily"
						label="Credit Family Member"
						class="e-checkbox-wrapper"
						role="checkbox"
						aria-checked="false"
						[attr.data-test-id]="'familyCreditCheckBox'">
					</ejs-checkbox>
				</div>
				<div class="col-sm-9">
					<div class="row">
						<div class="col-sm-4">
							<pms-always-set-currency-input min="0" formControlName="amount" [attr.data-test-id]="'familyCreditAmountInput'"></pms-always-set-currency-input>
						</div>
						<div class="col-sm-4">
							<ejs-dropdownlist
								[dataSource]="familyMembers"
								formControlName="familyMemberPatientId"
								[attr.data-test-id]="'creditFamilyMemberSelector'">
							</ejs-dropdownlist>
						</div>
					</div>
				</div>
			</div>
			<hr>
			<div class="form-group" formGroupName="patientRefund">
				<div class="col-sm-3">
					<ejs-checkbox
						(change)="handleCheckbox($event)"
						[(checked)]="hasPatientRefund"
						label="Refund Patient"
						class="e-checkbox-wrapper"
						role="checkbox"
						aria-checked="false"
						[attr.data-test-id]="'patientRefundCheckBox'">
					</ejs-checkbox>
				</div>
				<div class="col-sm-9">
					<div class="row">
						<div class="col-sm-4">
							<pms-always-set-currency-input min="0" formControlName="amount" [attr.data-test-id]="'patientRefundAmountInput'"></pms-always-set-currency-input>
						</div>
					</div>
					<div class="row margin-top-sm">
						<div class="col-sm-4">
							<label class="required">Method</label>
							<pms-constant-dropdown
								formControlName="method"
								placeholder="Select Method"
								[constantList]="methods"
								[attr.data-test-id]="'patientRefundMethodSelector'">
							</pms-constant-dropdown>
						</div>
						<div class="col-sm-4">
							<label>Ref #</label>
							<input class="e-input" type="text" formControlName="referenceNumber" [attr.data-test-id]="'patientRefundReferenceNumberInput'">
						</div>
						<div class="col-sm-4">
							<label>Location</label>
							<ejs-dropdownlist
								[dataSource]="locations"
								formControlName="locationId"
								[attr.data-test-id]="'patientRefundLocationSelector'">
							</ejs-dropdownlist>
						</div>
					</div>
				</div>
			</div>
			<hr>
			<div class="form-group" formGroupName="familyRefund">
				<div class="col-sm-3">
					<ejs-checkbox
						[(checked)]="hasFamilyRefund"
						(change)="handleCheckbox($event)"
						[disabled]="hasNoFamily"
						label="Refund Family Member"
						class="e-checkbox-wrapper"
						role="checkbox"
						aria-checked="false"
						[attr.data-test-id]="'familyRefundCheckBox'">
					</ejs-checkbox>
				</div>
				<div class="col-sm-9">
					<div class="row">
						<div class="col-sm-4">
							<pms-always-set-currency-input min="0" formControlName="amount" [attr.data-test-id]="'familyRefundAmountInput'"></pms-always-set-currency-input>
						</div>
						<div class="col-sm-4">
							<ejs-dropdownlist
								[dataSource]="familyMembers"
								formControlName="familyMemberPatientId"
								[attr.data-test-id]="'familyRefundFamilyMemberSelector'">
							</ejs-dropdownlist>
						</div>
					</div>
					<div class="row margin-top-sm">
						<div class="col-sm-4">
							<label class="required">Method</label>
							<pms-constant-dropdown
								formControlName="method"
								placeholder="Select Method"
								[constantList]="methods"
								[attr.data-test-id]="'familyRefundMethodSelector'">
							</pms-constant-dropdown>
						</div>
						<div class="col-sm-4">
							<label>Ref #</label>
							<input class="e-input" type="text" formControlName="referenceNumber" [attr.data-test-id]="'familyRefundReferenceNumberInput'">
						</div>
						<div class="col-sm-4">
							<label>Location</label>
							<ejs-dropdownlist
								[dataSource]="locations"
								formControlName="locationId"
								[attr.data-test-id]="'familyRefundLocationSelector'">
							</ejs-dropdownlist>
						</div>
					</div>
				</div>
			</div>
			@if (isInsuranceInvoice) {
				<hr>
				<div class="form-group" formGroupName="insuranceRefund">
					<div class="col-sm-3">
						<ejs-checkbox
							[(checked)]="hasInsuranceRefund"
							(change)="handleCheckbox($event)"
							label="Refund Insurance"
							class="e-checkbox-wrapper"
							role="checkbox"
							aria-checked="false"
							[attr.data-test-id]="'insuranceRefundCheckBox'">
						</ejs-checkbox>
					</div>
					<div class="col-sm-9">
						<div class="row">
							<div class="col-sm-4">
								<pms-always-set-currency-input min="0" formControlName="amount" [attr.data-test-id]="'insuranceRefundAmountInput'"></pms-always-set-currency-input>
							</div>
						</div>
						<div class="row margin-top-sm">
							<div class="col-sm-4">
								<label class="required">Method</label>
								<pms-constant-dropdown
									formControlName="method"
									placeholder="Select Method"
									[constantList]="methods"
									[attr.data-test-id]="'insuranceRefundMethodSelector'">
								</pms-constant-dropdown>
							</div>
							<div class="col-sm-4">
								<label>Ref #</label>
								<input class="e-input" type="text" formControlName="referenceNumber" [attr.data-test-id]="'insuranceRefundReferenceNumberInput'">
							</div>
							<div class="col-sm-4">
								<label>Location</label>
								<ejs-dropdownlist
									[dataSource]="locations"
									formControlName="locationId"
									[attr.data-test-id]="'insuranceRefundLocationSelector'">
								</ejs-dropdownlist>
							</div>
						</div>
					</div>
				</div>
			}
			<hr>
			<div class="form-group" [attr.data-test-id]="'endingBalanceLabelGroup'">
				<label class="col-sm-3 control-label">Ending Balance</label>
				<div class="col-sm-9">
					<div class="row">
						<div class="col-sm-4">
							<p class="form-control-static" [class.text-danger]="endingBalance > 0">{{ endingBalance | currency }}</p>
						</div>
					</div>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button type="button" iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'grantCreditModalCancelButton'">Cancel</button>
		<button ejs-button type="button" [isPrimary]="true" (click)="submitForm($event)" [attr.data-test-id]="'grantCreditModalSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>
