import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { HIT_PMS_HTML_EVENTS } from '@core/legacy/hit-pms-html.constants';
import { ChangeRouteEventData } from '../../app-routing.module';


@Injectable({
	providedIn: 'root',
})
export class StateManagementService {

	private urlStore: { [key: string]: string} = {};

	constructor(
		private eventsManagerService: EventsManagerService,
		private router: Router,
		private ngZone: NgZone,
	) {
	}

	subscribeToRouteChange() {
		this.eventsManagerService.subscribe(
			HIT_PMS_HTML_EVENTS.APPLICATION.CHANGE_ROUTE,
			(eventData: ChangeRouteEventData) => this.navigateFromUrlStoreFromOutsideAngular(eventData),
			this,
		);
	}

	async navigateFromUrlStoreFromOutsideAngular(eventData: ChangeRouteEventData) {
		const baseRoute = eventData.argument.primaryContext;
		const storedUrl = this.grabFromStore(baseRoute);

		if (storedUrl) {
			await this.ngZone.run(() => this.router.navigateByUrl(storedUrl));
		}
	}

	async navigateFromUrlStore(primaryContext: string) {
		const storedUrl = this.grabFromStore(primaryContext);
		if (storedUrl) {
			await this.router.navigateByUrl(storedUrl);
		}
	}

	storeUrl(urlSegments: string[]) {
		if (!urlSegments || urlSegments.length <= 1) {
			return;
		}

		// skip [0] as it's the root /
		const baseUrl = urlSegments[1];
		this.urlStore[baseUrl] = urlSegments.join('/');
	}

	grabFromStore(key: string) {
		return this.urlStore[key];
	}


}
