import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { _isEmpty, _isNil } from '@core/lodash/lodash';
import { SocialSecurityNumberService } from '@core/patient/social-security-number.service';
import { BaseComponent } from '@shared/component/base.component';

@Component({
	selector: 'pms-social-security-number-input',
	templateUrl: './social-security-number-input.component.html',
	styles: [],
})
export class SocialSecurityNumberInputComponent extends BaseComponent implements OnInit {

	@Input()
	readonly disabled = false;

	@Input()
	readonly fullSsnAvailable: boolean;

	@Input()
	readonly last4Ssn: string;

	@Input()
	last4SsnControl: AbstractControl | UntypedFormControl;

	@Input()
	ssnControl: AbstractControl | UntypedFormControl;

	@Input()
	patientId: number;

	get canViewPatientSsn(): boolean {
		return this.socialSecurityNumberService.canViewPatientSsn;
	}

	get showFullSsnInput(): boolean {
		return this.fullSsnAvailable
			? (this.ssnControl.enabled || this.disabled) && (!!this.fullSsn || this.ssnControl.touched)
			: this.ssnControl.enabled || this.disabled;
	}

	get disableSsnInput(): boolean {
		return !!this.last4SsnControl.value || this.disabled;
	}

	get disableLast4SsnInput(): boolean {
		return !!this.ssnControl.value || !!this.fullSsnDisplay || this.disabled;
	}

	get showSsnLookupButton(): boolean {
		return this.fullSsnAvailable && this.canViewPatientSsn && _isNil(this.last4SsnControl.value);
	}

	fullSsn: string;
	fullSsnDisplay: string = null;

	constructor(
		private socialSecurityNumberService: SocialSecurityNumberService,
	) {
		super();
	}

	ngOnInit() {
		if (this.fullSsnAvailable && !_isEmpty(this.last4Ssn) && _isNil(this.ssnControl.value)) {
			this.fullSsnDisplay = `xxx-xx-${this.last4Ssn}`;
		}

		this.observeSsnChanges();
		this.observeLast4SsnChanges();
	}

	getPatientSSN() {
		if (this.canViewPatientSsn && this.patientId) {
			this.socialSecurityNumberService.getPatientFullSsnById(this.patientId).subscribe(fullSsn => {
				this.fullSsn = fullSsn;
				this.fullSsnDisplay = null;
				this.ssnControl.setValue(fullSsn);
				this.ssnControl.markAsTouched();

				if (!this.disabled) {
					this.ssnControl.enable();
				}
			});
		}
	}

	observeSsnChanges() {
		const reactions = () => {
			FormUtilsService.disabledWhen(this.last4SsnControl, this.disableLast4SsnInput);
		};

		FormUtilsService.reactToValueChanges(this.ssnControl, reactions, true, this.unsubscribe);
	}

	observeLast4SsnChanges() {
		const reactions = (value) => {
			FormUtilsService.disabledWhen(this.ssnControl, this.disableSsnInput);
			if (_isEmpty(value)) {
				this.last4SsnControl.setValue(null);
			}
		};

		FormUtilsService.reactToValueChanges(this.last4SsnControl, reactions, true, this.unsubscribe);
	}

}
