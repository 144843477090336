// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { BaseInventoryReportResponse } from './base-inventory-report-response';

/** See com.rev360.pms.api.controller.reporting.inventory.ContactLensInventoryReportResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensInventoryReportResponse extends BaseInventoryReportResponse {

	@GandalfProperty()
	addDesignation: string;

	@GandalfProperty()
	addPower: number;

	@GandalfProperty()
	axis: number;

	@GandalfProperty()
	baseCurve: number;

	@GandalfProperty()
	brand: string;

	@GandalfProperty()
	category: string;

	@GandalfProperty()
	code: string;

	@GandalfProperty()
	collection: string;

	@GandalfProperty()
	cylinder: number;

	@GandalfProperty()
	diameter: number;

	@GandalfProperty()
	isTrial: boolean;

	@GandalfProperty()
	itemDescription: string;

	@GandalfProperty()
	lensColor: string;

	@GandalfProperty()
	lensColorCode: string;

	@GandalfProperty()
	locationId: number;

	@GandalfProperty({ propertyType: 'Money' })
	locationProductActualCost: number;

	@GandalfProperty()
	locationProductItemId: number;

	@GandalfProperty()
	manufacturer: string;

	@GandalfProperty()
	model: string;

	@GandalfProperty({ propertyType: 'Money' })
	retail: number;

	@GandalfProperty()
	sku: string;

	@GandalfProperty()
	sphere: number;

	@GandalfProperty()
	stock: number;

	@GandalfProperty()
	upc: string;

	@GandalfProperty({ propertyType: 'Money' })
	wholesale: number;

}
