// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.insurance.InsuranceCoveragesResponse */
/* istanbul ignore next */
@GandalfModel
export class InsuranceCoveragesResponse extends GandalfModelBase {

	@GandalfProperty()
	insuranceCompanyName: string;

	@GandalfProperty()
	insuranceType: ReferenceDataResponse;

	@GandalfProperty()
	personInsuranceId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	personInsuranceStatus: constants.PersonInsuranceStatus;

}
