import { AccountingViewService } from '@accounting/core/accounting/accounting-view-util/accounting-view.service';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { InvoiceResponse } from '@gandalf/model/invoice-response';
import { UpdateInvoiceDateRequest } from '@gandalf/model/update-invoice-date-request';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { GandalfFormBuilder } from 'gandalf';
import { InvoiceService } from '@accounting/core/accounting/invoice-service/invoice.service';
import { AccountingService } from '@accounting/core/accounting/accounting.service';

@Component({
	selector: 'pms-invoice-date',
	templateUrl: './invoice-date.component.html',
	styles: [],
})
export class InvoiceDateComponent implements OnInit, OnChanges {

	@Input()
	isReadOnly: boolean;

	@Input()
	isPaymentProcessing: boolean;

	@Input()
	invoice: InvoiceResponse;

	allowEdit: boolean;
	editMode: boolean;
	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	componentForm: UntypedFormGroup;

	constructor(
		private accountingService: AccountingService,
		private gandalfFormBuilder: GandalfFormBuilder,
		private invoiceService: InvoiceService,
		private accountingViewService: AccountingViewService,
	) {
	}

	ngOnInit() {
		this.initializeForm();
	}

	ngOnChanges(_changes: SimpleChanges): void {
		this.initializeForm();
	}

	initializeForm() {
		this.allowEdit = this.isEditAllowed();
		this.editMode = false;
		const request = new UpdateInvoiceDateRequest();
		request.invoiceDate = this.invoice.invoiceDate;
		request.invoiceId = this.invoice.id;
		this.componentForm = this.gandalfFormBuilder.group(request);
	}

	editInvoiceDate() {
		this.editMode = true;
	}

	cancelEditInvoiceDate() {
		this.editMode = false;
	}

	updateInvoiceDate() {
		if (this.componentForm.invalid) {
			return false;
		}

		const request = this.componentForm.value as UpdateInvoiceDateRequest;

		this.accountingService.updateInvoiceDate(request).subscribe((invoice) => {
			this.invoiceService.refreshInvoiceDetailsInvoice(invoice.id);
		});
		this.cancelEditInvoiceDate();
	}

	getAgeColorClass(): string {
		let colorClass = '';
		if (this.invoice.age >= 31 && this.invoice.age <= 60) {
			colorClass = 'info';
		} else if (this.invoice.age >= 61 && this.invoice.age <= 90) {
			colorClass = 'warning';
		} else if (this.invoice.age >= 91) {
			colorClass = 'danger';
		}
		return colorClass;
	}

	isEditAllowed() {
		return !this.isReadOnly && !this.isPaymentProcessing && this.accountingViewService.canChangeInvoiceDate(this.invoice);
	}

}
