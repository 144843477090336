/* istanbul ignore file */
import { createSelector } from '@ngrx/store';
import { InvoiceTabStateMap } from '@invoices-store/reducers/invoice-tab.reducer';
import { InvoicesState, selectInvoicesState } from '../reducers';

export const selectInvoiceTabState = createSelector(selectInvoicesState, state => state.invoiceTabState);

export const selectInvoiceTabStateById = (props: {id: number}) =>
	createSelector(selectInvoiceTabState, (state: InvoiceTabStateMap) => state.get(props.id));

export const selectNewestTabId = createSelector(
	selectInvoiceTabState,
	invoiceTabState => {
		let min = 0;
		invoiceTabState.forEach((value, key) => min = Math.min(key, min));
		return min;
	},
);

export const selectInvoiceDetailsState = (props: {invoiceId: number}) =>
	createSelector(selectInvoicesState, (state: InvoicesState) => state.invoiceDetailsTableState.get(props.invoiceId));

export const selectInvoiceDetailsInvoiceState = (props: {invoiceId: number}) =>
	createSelector(selectInvoicesState, (state: InvoicesState) => {
		const invoiceDetailsState = state.invoiceDetailsTableState.get(props.invoiceId);
		return invoiceDetailsState ? invoiceDetailsState.invoice : null;
	});

export const selectInvoiceDetailsShowAllState = (props: {invoiceId: number}) =>
	createSelector(selectInvoicesState, (state: InvoicesState) => {
		const invoiceDetailsState = state.invoiceDetailsTableState.get(props.invoiceId);
		return invoiceDetailsState ? invoiceDetailsState.showAll : null;
	});

export const selectInvoiceDetailsCurrentTabState = (props: {invoiceId: number}) =>
	createSelector(selectInvoicesState, (state: InvoicesState) => {
		const invoiceDetailsState = state.invoiceDetailsTableState.get(props.invoiceId);
		return invoiceDetailsState ? invoiceDetailsState.currentTab : null;
	});


export const selectInvoicePaymentHistoryState = (props: {invoiceId: number}) =>
	createSelector(selectInvoicesState, (state: InvoicesState) => state.invoicePaymentHistoryTableState.get(props.invoiceId).tableConfigurationJson);

export const selectInvoiceClaimHistoryState = (props: {invoiceId: number}) =>
	createSelector(selectInvoicesState, (state: InvoicesState) => state.invoiceClaimHistoryTableState.get(props.invoiceId).tableConfigurationJson);

export const selectInvoiceStatementHistoryState = (props: {invoiceId: number}) =>
	createSelector(selectInvoicesState, (state: InvoicesState) => state.invoiceStatementHistoryTableState.get(props.invoiceId).tableConfigurationJson);

export const selectInvoiceDocumentsAndImagesState = (props: {invoiceId: number}) =>
	createSelector(selectInvoicesState, (state: InvoicesState) => state.invoiceDocumentsAndImagesTableState.get(props.invoiceId).tableConfigurationJson);

export const selectInvoiceCurrentTabRoute = createSelector(
	selectInvoicesState,
	(state) => state.invoiceCurrentTabState.route,
);
