// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.practicepreferences.UpdatePasswordPolicyRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdatePasswordPolicyRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Idle timeout is required' } })
	@GandalfValidator({ min: { min: 1, message: 'Idle timeout may not be less than 1 minute' } })
	@GandalfValidator({ max: { max: 240, message: 'Idle timeout may not be more than 240 minutes' } })
	@GandalfProperty({ isRequired: true })
	autoLockPeriod: number;

	@GandalfValidator({ notNull: { message: 'Password expiration days is required' } })
	@GandalfValidator({ min: { min: 1, message: 'Password expiration days must be greater than 0 days' } })
	@GandalfValidator({ max: { max: 999, message: 'Password expiration days may not be more than 999 days' } })
	@GandalfProperty({ isRequired: true })
	expirationDays: number;

	@GandalfValidator({ notNull: { message: 'Minimum amount of numbers is required' } })
	@GandalfValidator({ min: { min: 0, message: 'Minimum amount of numbers may not be less than 0' } })
	@GandalfValidator({ max: { max: 5, message: 'Minimum amount of numbers may not be more than 5' } })
	@GandalfProperty({ isRequired: true })
	minDigits: number;

	@GandalfValidator({ notNull: { message: 'Minimum password length is required' } })
	@GandalfValidator({ min: { min: 8, message: 'Minimum password length may not be less than 8' } })
	@GandalfValidator({ max: { max: 16, message: 'Minimum password length may not be more than 16' } })
	@GandalfProperty({ isRequired: true })
	minLength: number;

	@GandalfValidator({ notNull: { message: 'Minimum number of lowercase letters is required' } })
	@GandalfValidator({ min: { min: 0, message: 'Minimum number of lowercase letters may not be less than 0' } })
	@GandalfValidator({ max: { max: 5, message: 'Minimum number of lowercase letters may not be more than 5' } })
	@GandalfProperty({ isRequired: true })
	minLowerCase: number;

	@GandalfValidator({ notNull: { message: 'Minimum number of special characters is required' } })
	@GandalfValidator({ min: { min: 0, message: 'Minimum number of special characters may not be less than 0' } })
	@GandalfValidator({ max: { max: 5, message: 'Minimum number of special characters may not be more than 5' } })
	@GandalfProperty({ isRequired: true })
	minSpecialChars: number;

	@GandalfValidator({ notNull: { message: 'Minimum number of uppercase letters is required' } })
	@GandalfValidator({ min: { min: 0, message: 'Minimum number of uppercase letters may not be less than 0' } })
	@GandalfValidator({ max: { max: 5, message: 'Minimum number of uppercase letters may not be more than 5' } })
	@GandalfProperty({ isRequired: true })
	minUpperCase: number;

	@GandalfValidator({ notNull: { message: 'Password different from last is required' } })
	@GandalfValidator({ min: { min: 0, message: 'Password different from last may not be less than 0' } })
	@GandalfValidator({ max: { max: 999, message: 'Password different from last may not be more than 999' } })
	@GandalfProperty({ isRequired: true })
	numPasswordChanges: number;

	@GandalfValidator({ notNull: { message: 'Password expires is required' } })
	@GandalfProperty({ isRequired: true })
	passwordExpires: boolean;

}
