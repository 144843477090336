import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { FileService } from '@core/file/file.service';
import { UrlService } from '@core/url-util/url.service';
import { FILE_UPLOAD_MAX_SIZE, SYNCFUSION_UPLOAD_SUCCESS_STATUS_CODE } from '@shared/constants/file-upload.constants';
import { URL_PATIENT_FILE_ENDPOINTS } from '@shared/constants/url.constants';
import { AsyncSettingsModel } from '@syncfusion/ej2-angular-inputs';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { SelectedEventArgs } from '@syncfusion/ej2-inputs/src/uploader/uploader';

@Component({
	selector: 'pms-patient-file-upload-modal',
	templateUrl: './patient-file-upload-modal.component.html',
})
export class PatientFileUploadModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	fileUploadSettings: AsyncSettingsModel;
	maxFileUploadSize = FILE_UPLOAD_MAX_SIZE;

	folderId: string;
	patientId: number;
	errorMessage: string;

	constructor(
		public dynamicModalRef: DynamicModalRef,
		public modalConfig: ModalConfig,
		private urlService: UrlService,
	) {
	}

	ngOnInit() {
		this.parseModalConfig(this.modalConfig.data);
		this.setFileUploadSettings();
	}

	parseModalConfig(data: any) {
		this.folderId = data.folderId;
		this.patientId = data.patientId;
	}

	setFileUploadSettings() {
		let urlParams = '?' + this.urlService.getStrutsParameters(new Date());
		urlParams += `&folderId=${this.folderId}`;
		urlParams += `&patientId=${this.patientId}`;
		this.fileUploadSettings = {saveUrl: URL_PATIENT_FILE_ENDPOINTS.UPLOAD + urlParams};
	}

	filesSelected(event: SelectedEventArgs) {
		const errors: string[] = [];
		event.filesData?.forEach(file => {
			if (FileService.fileExtensionIsBlacklisted(file.name)) {
				errors.push(file.name);
				event.cancel = true;
			}
		});
		if (errors.length > 0) {
			this.errorMessage = `No files were uploaded. Invalid file type(s) selected: ${errors.join(', ')}`;
		} else {
			this.errorMessage = undefined;
		}
	}

	uploadSuccess(event, uploader) {
		const allFilesUploaded = !uploader.filesData.find(file => file.statusCode !== SYNCFUSION_UPLOAD_SUCCESS_STATUS_CODE);
		if (allFilesUploaded) {
			this.closeModal(true);
		}
	}

	/* istanbul ignore next: closeModal */
	closeModal(fileUploaded = false) {
		this.dynamicModalRef.close(this.modal, fileUploaded);
	}
}
