// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.AddPatientOwnedFrameRequest */
/* istanbul ignore next */
@GandalfModel
export class AddPatientOwnedFrameRequest extends GandalfModelBase {

	@GandalfProperty()
	a: number;

	@GandalfProperty()
	b: number;

	@GandalfProperty()
	brandId: number;

	@GandalfProperty()
	bridge: number;

	@GandalfProperty()
	circumference: number;

	@GandalfProperty()
	collectionId: number;

	@GandalfValidator({ sizeString: { message: 'Color Code cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	colorCode: string;

	@GandalfValidator({ sizeString: { message: 'Color Name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	colorName: string;

	@GandalfProperty()
	dbl: number;

	@GandalfProperty()
	ed: number;

	@GandalfProperty()
	edAngle: number;

	@GandalfProperty()
	eye: number;

	@GandalfValidator({ notNull: { message: 'Location is required' } })
	@GandalfProperty({ isRequired: true })
	locationId: number;

	@GandalfValidator({ sizeString: { message: 'Model cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	locationProductName: string;

	@GandalfValidator({ notNull: { message: 'Manufacturer is required' } })
	@GandalfProperty({ isRequired: true })
	manufacturerId: number;

	@GandalfProperty()
	screwToScrew: number;

	@GandalfProperty()
	temple: number;

	@GandalfValidator({ sizeString: { message: 'Temple Unit cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfProperty()
	templeUnit: string;

}
