// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.UpdatePatientQuickViewRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdatePatientQuickViewRequest extends GandalfModelBase {

	@GandalfLabel('Dominant Eye')
	@GandalfProperty()
	dominantEyeId: number;

	@GandalfLabel('Dominant Hand')
	@GandalfProperty()
	dominantHandId: number;

	@GandalfLabel('Drivers License')
	@GandalfValidator({ sizeString: { message: 'Drivers License cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfProperty()
	driversLicense: string;

	@GandalfLabel('Employer Name')
	@GandalfValidator({ sizeString: { message: 'Employer Name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	employerName: string;

	@GandalfLabel('Patient Categories')
	@GandalfArray(Number)
	patientCategoryIds: number[];

	@GandalfLabel('General Notes')
	@GandalfValidator({ sizeString: { message: 'General Notes cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	patientComment: string;

	@GandalfLabel('Patient')
	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientId: number;

	@GandalfLabel('Photo')
	@GandalfProperty()
	photoId: number;

	@GandalfLabel('Employment Position')
	@GandalfValidator({ sizeString: { message: 'Employment Position cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	position: string;

	@GandalfConstantDecorator()
	@GandalfLabel('Employment Status')
	@GandalfProperty()
	status: constants.PatientEmploymentStatus;

}
