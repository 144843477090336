<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-lg"
	[attr.data-test-id]="'addInsurancePolicyModal'">
	<ng-template #header>
		<div class="dlg-template">Insurance Coverage</div>
	</ng-template>
	<ng-template #content>
		<div *ngIf="showSelectedIntakeInsurance" class="panel-body">
			<div class="text-left alert alert-warning">
				<p><strong>Please complete the required fields below. Click 'Save' to complete.</strong></p>
				<p *ngIf="selectedIntakeInsurance.policyHolder">Policy Holder Name: <strong>{{selectedIntakeInsurance.policyHolder}}</strong></p>
				<p *ngIf="selectedIntakeInsurance.policyHolderDob">Policy Holder DOB: <strong>{{selectedIntakeInsurance.policyHolderDob | date: dateFormat}}</strong></p>
				<p *ngIf="selectedIntakeInsurance.policyHolderRelationship">Policy Holder Relationship: <strong>{{selectedIntakeInsurance.policyHolderRelationship}}</strong></p>
				<p *ngIf="selectedIntakeInsurance.policyNumber">Policy #: <strong>{{selectedIntakeInsurance.policyNumber}}</strong></p>
				<p *ngIf="selectedIntakeInsurance.patientFileInfoResponse">Insurance Card: <a
						revEventStopPropagation
						(click)="viewInsuranceFile(selectedIntakeInsurance.patientFileInfoResponse)">View file</a></p>
			</div>
		</div>
		<form [formGroup]="componentForm" (ngSubmit)="save()" #templateForm="ngForm">
			<div class="row">
				<div class="col-sm-12">
					<gandalf-lib-validation-messages
							*ngIf="componentForm.invalid && templateForm.submitted"
							[form]="componentForm"
							[requestObj]="!personInsuranceId ? createInsuranceRequest: updateInsuranceRequest">
					</gandalf-lib-validation-messages>
				</div>
			</div>
			<ejs-tab id="tabView" overflowMode="Popup" [animation]="tabAnimation">
				<e-tabitems>
					<e-tabitem [header]="{text:'Details'}">
						<ng-template #content>
							<h4 class="section-title bg-default mrgn-btm">Basic Information</h4>
							<div class="row">
								<pms-insurance-basic-information-form
									#basicInformationComponent
									[patientId]="patientId"
									[personId]="personId"
									[personInsuranceId]="personInsuranceId"
									[canSelectPolicyHolder]="canSelectPolicyHolder"
									[isUpdating]="isUpdating()"
									(nonPolicyHolderPersonInsurance)="onNonPolicyHolderPersonInsuranceChange($event)"
									(policyHolderIsSelf)="policyHolderIsSelf($event)"
									formGroupName="patientInsuranceBasicInformationRequest">
								</pms-insurance-basic-information-form>
							</div>
							<div class="row">
								<div class="col-md-6 col-lg-4">
									<h4 class="section-title bg-default mrgn-btm">
										Individual Exam/General Benefits
										<a class="pull-right" (click)="copyBasicInformationToGeneralSubrequest()">
											<i class="fa fa-clone" revTooltip tooltipContent="Copy from Basic Information"></i>
										</a>
									</h4>
									<pms-general-benefits-form formGroupName="patientGeneralBenefitsRequest"></pms-general-benefits-form>
								</div>
								<div class="col-md-6 col-lg-4">
									<h4 class="section-title bg-default mrgn-btm">
										Individual Material Benefits
										<a class="pull-right" (click)="copyBasicInformationToMaterialSubrequest()">
											<i class="fa fa-clone" revTooltip tooltipContent="Copy from Basic Information"></i>
										</a>
									</h4>
									<pms-individual-benefits-form formGroupName="patientMaterialBenefitsRequest"></pms-individual-benefits-form>
								</div>
								<div class="col-md-6 col-lg-4">
									<ng-container *ngIf="patientId && showDocsAndImages">
										<h4 class="section-title bg-default mrgn-btm">Policy Documents & Images</h4>
										<pms-patient-documents
												[disabled]="!personInsuranceId"
												[patientId]="patientId"
												[parentId]="personInsuranceId"
												[entityType]="insurancePatientFileEntityType">
										</pms-patient-documents>
									</ng-container>
								</div>
							</div>
						</ng-template>
					</e-tabitem>
					<e-tabitem [header]="{text:'Policy Holder Benefits'}">
						<ng-template #content>
							<ejs-textbox
								[multiline]="true"
								class="h-rows-30"
								formControlName="policyHolderBenefits">
							</ejs-textbox>
						</ng-template>
					</e-tabitem>
					<e-tabitem [header]="{text:'Patient Benefits'}">
						<ng-template #content>
							<ejs-textbox
								[multiline]="true"
								class="h-rows-30"
								formControlName="patientBenefits">
							</ejs-textbox>
							<button class="margin-top-sm" ejs-button (click)="copyPolicyBenefitsToPatientBenefits()">Copy from policy</button>
						</ng-template>
					</e-tabitem>
				</e-tabitems>
			</ejs-tab>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button *ngIf="existingPersonInsurance && !isActive" ejs-button cssClass="pull-left margin-left-0 e-success" iconCss="fa fa-check" (click)="activatePersonInsurance()" [attr.data-test-id]="'addInsurancePolicyModalActivateButton'">Activate</button>
		<button *ngIf="existingPersonInsurance && isActive" ejs-button cssClass="pull-left margin-left-0 e-danger" iconCss="fa fa-close" (click)="deactivatePersonInsurance()" [attr.data-test-id]="'addInsurancePolicyModalDeactivateButton'">Deactivate</button>
		<button ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'addInsurancePolicyModalCancelButton'">Cancel</button>
		<button ejs-button type="button" [isPrimary]="true" (click)="submitForm($event)" [attr.data-test-id]="'addInsurancePolicyModalSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>
