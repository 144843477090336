<ejs-dialog
	#modal
	(close)="closeModal()"
	[showCloseIcon]="!isVoiding"
	[closeOnEscape]="!isVoiding"
	cssClass="modal-md"
	[attr.data-test-id]="'openEdgeVoidPaymentModal'">
	<ng-template #header>
		<div class="dlg-template">Void Payment</div>
	</ng-template>
	<ng-template #content>
		<div [class.card_loader]="isVoiding"></div>
		@if (errorMessage) {
			<div class="alert alert-danger">
				<strong>{{ errorMessage }}</strong>
			</div>
		}
		<form [formGroup]="componentForm"
			  [hidden]="isVoiding"
			  (ngSubmit)="voidPayment()"
			  #templateForm="ngForm"
			  class="form-horizontal">
			<div class="row">
				<div class="col-sm-12">
					@if (componentForm.invalid && templateForm.submitted) {
						<gandalf-lib-validation-messages
							[form]="componentForm"
							[requestObj]="voidPaymentRequest"
							[attr.data-test-id]="'openEdgeVoidPaymentModalValidationMessages'">
						</gandalf-lib-validation-messages>
					}
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'openEdgeVoidPaymentModalIDSection'">
				<label class="col-sm-3 control-label">ID</label>
				<div class="col-sm-9">
					<p class="form-control-static">{{ paymentId }}</p>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'openEdgeVoidPaymentModalPayerSection'">
				<label class="col-sm-3 control-label">Payer</label>
				<div class="col-sm-9">
					<p class="form-control-static">{{ payerName }}</p>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'openEdgeVoidPaymentModalMethodSection'">
				<label class="col-sm-3 control-label">Method</label>
				<div class="col-sm-9">
					<p class="form-control-static">Credit Card - {{ paymentMethodCardType.label }} {{ last4CreditCard }}</p>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'openEdgeVoidPaymentModalAmountSection'">
				<label class="col-sm-3 control-label">Amount</label>
				<div class="col-sm-9">
					<p class="form-control-static">{{ paymentAmount | currency }}</p>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'openEdgeVoidPaymentModalReasonSection'">
				<label class="col-sm-3 control-label required">Reason</label>
				<div class="col-sm-9">
					<ejs-textbox class="h-rows-5" revFocusInput [multiline]="true" maxlength="255" formControlName="comments"></ejs-textbox>
					<div>
						{{ componentForm.controls['comments']?.value.length }} of 255 characters
					</div>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		@if (!isVoiding) {
			<button
				ejs-button
				cssClass="e-link pull-left margin-left-0"
				(click)="applyManualVoid()"
				[attr.data-test-id]="'openEdgeVoidManuallyButton'">Void manually
			</button>
			<button
				ejs-button
				iconCss="fa fa-times"
				type="button"
				(click)="closeModal()"
				[attr.data-test-id]="'openEdgeVoidPaymentModalCancelButton'">Cancel
			</button>
			<button
				ejs-button
				type="submit"
				[isPrimary]="true"
				(click)="submitForm($event)"
				[attr.data-test-id]="'openEdgeVoidPaymentModalVoidButton'">{{ primaryButtonText }}
			</button>
		}
	</ng-template>
</ejs-dialog>
