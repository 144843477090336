import { Injectable } from '@angular/core';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { INTER_APP_CONSTANTS } from '@core/legacy/inter-app.constants';
import { LegacyNavigationService } from '@core/legacy/legacy-navigation.service';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LegacyModuleManagerService {

	isPatientsAppLoaded = false;
	patientsAppLoaded = new Subject<boolean>();

	constructor(
		private legacyNavigationService: LegacyNavigationService,
		private eventsManagerService: EventsManagerService,
	) {
		const event = this.eventsManagerService.subscribe(INTER_APP_CONSTANTS.REV360.PATIENTS.PATIENT_APP_LOADED, () => {
			this.isPatientsAppLoaded = true;
			this.patientsAppLoaded.next(true);
			this.eventsManagerService.unsubscribeEvent(event);
		});
	}

	bootstrapPatientApp() {
		this.legacyNavigationService.bootstrapModule('patients');
	}
}
