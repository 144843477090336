import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DynamicModalRef, ModalConfig, ModalManagerService } from 'morgana';
import { ProviderService } from '@core/provider/provider.service';
import { ProviderResponse } from '@gandalf/model/provider-response';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UpdateInvoiceProviderRequest } from '@gandalf/model/update-invoice-provider-request';
import { AccountingService } from '../../core/accounting/accounting.service';
import { InvoiceService } from '../../core/accounting/invoice-service/invoice.service';

@Component({
	selector: 'pms-provider-select-modal',
	templateUrl: './provider-select-modal.component.html',
	styles: [],
})
export class ProviderSelectModalComponent implements OnInit, OnDestroy {

	@ViewChild('modal')
	modal: DialogComponent;
	invoiceId: number;
	invoiceProviderId: number;
	providerList: ProviderResponse[];
	private unsubscribe$ = new Subject<void>();


	constructor(
		public ref: DynamicModalRef,
		public modalManagerService: ModalManagerService,
		public providerService: ProviderService,
		public invoiceService: InvoiceService,
		public modalConfig: ModalConfig,
		public accountingService: AccountingService,
	) {
	}

	ngOnInit() {
		this.invoiceId = this.modalConfig.data.invoiceId;
		this.getProviders();
	}

	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	getProviders() {
		this.providerService.findActivePracticeProvidersForDropdown().pipe(takeUntil(this.unsubscribe$)).subscribe((providers) => {
			this.initializeProviderDropdown(providers);
		});
	}

	initializeProviderDropdown(providers: ProviderResponse[]) {
		this.providerList = providers;
		this.invoiceProviderId = this.modalConfig.data.providerId;
	}

	/**
	 * Used Gandalf the White to update the selected provider. Once complete
	 * the invoice is refreshed with the updated data and the modal is closed.
	 */
	saveProviderAndRefreshInvoice() {
		const request: UpdateInvoiceProviderRequest = new UpdateInvoiceProviderRequest();
		request.invoiceId = this.invoiceId;
		request.providerId = this.invoiceProviderId;

		this.accountingService.updateInvoiceProvider(request).subscribe(() => {
			this.invoiceService.refreshInvoice(this.invoiceId);
			this.closeModal(true);
		});
	}

	closeModal(updated: boolean) {
		this.ref.close(this.modal, updated);
	}

}
