// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { RecurPatternDailyResponse } from './recur-pattern-daily-response';

// @ts-ignore
import { RecurPatternMonthlyResponse } from './recur-pattern-monthly-response';

// @ts-ignore
import { RecurPatternWeeklyResponse } from './recur-pattern-weekly-response';

// @ts-ignore
import { RecurPatternYearlyResponse } from './recur-pattern-yearly-response';

/** See com.rev360.pms.api.controller.schedule.scheduleitem.recurrence.RecurringDefinitionResponse */
/* istanbul ignore next */
@GandalfModel
export class RecurringDefinitionResponse extends GandalfModelBase {

	@GandalfProperty()
	dailyRecurPattern: RecurPatternDailyResponse;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	endType: constants.RecurEndType;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	monthlyRecurPattern: RecurPatternMonthlyResponse;

	@GandalfProperty()
	numRecurOccurrences: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	recurPatternType: constants.RecurPatternType;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate: Date;

	@GandalfProperty()
	weeklyRecurPattern: RecurPatternWeeklyResponse;

	@GandalfProperty()
	yearlyRecurPattern: RecurPatternYearlyResponse;

}
