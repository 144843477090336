import { Component, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import { Gender } from '@gandalf/constants';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';

@Component({
	selector: 'pms-contact-demographics-form',
	templateUrl: './contact-demographics-form.component.html',
	styles: [],
})
export class ContactDemographicsFormComponent implements OnInit {

	componentForm: UntypedFormGroup;
	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	genderOptions = [
		Gender.MALE,
		Gender.FEMALE,
		Gender.UNKNOWN,
	];

	constructor(
		public controlContainer: ControlContainer,
	) { }

	ngOnInit() {
		this.componentForm = this.controlContainer.control as UntypedFormGroup;
	}

}
