import { AbstractControl, ValidatorFn } from '@angular/forms';

const SSN_REGEX_STRING = '^([0-9]{9})$|([0-9]{3}-[0-9]{2}-[0-9]{4})$';
/**
 * Validates that a form control's input contains 9 digits and nothing else.
 */
export const validateSocialSecurityNumber: ValidatorFn = (control: AbstractControl) => {
	const value: any = control.value;
	if (!value) {
		return null;
	}

	const input = value.toString();
	if (input === '') {
		return null;
	}

	if (input.match(SSN_REGEX_STRING)) {
		return null;
	}

	return {
		socialSecurityNumber: {
			valid: false,
		},
	};
};
