import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { InsuranceService } from '@core/insurance/insurance.service';
import { CreatePracticeInsuranceCompanyRequest } from '@gandalf/model/create-practice-insurance-company-request';
import { PracticeInsuranceCompanyModalResponse } from '@gandalf/model/practice-insurance-company-modal-response';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';
import { DynamicModalRef } from 'morgana';

@Component({
	selector: 'pms-add-company-modal',
	templateUrl: './add-company-modal.component.html',
	styles: [],
})
export class AddCompanyModalComponent implements OnInit {

	@ViewChild('templateForm')
	templateForm: NgForm;

	@ViewChild('modal')
	modal: DialogComponent;

	createCompanyRequest: CreatePracticeInsuranceCompanyRequest;
	componentForm: UntypedFormGroup;

	constructor(
		private gandalfFormBuilder: GandalfFormBuilder,
		private ref: DynamicModalRef,
		private insuranceService: InsuranceService,
	) { }

	ngOnInit() {
		this.createCompanyRequest = new CreatePracticeInsuranceCompanyRequest();
		this.componentForm = this.gandalfFormBuilder.group(this.createCompanyRequest);
	}

	/* istanbul ignore next */
	closeModal(result?: PracticeInsuranceCompanyModalResponse) {
		this.ref.close(this.modal, result);
	}

	/* istanbul ignore next */
	submitForm(event) {
		this.templateForm.onSubmit(event);
	}

	save() {
		if (this.componentForm.invalid) {
			return;
		}

		this.insuranceService.createPracticeInsuranceCompany(this.componentForm.value).subscribe(response => {
			this.closeModal(response);
		});
	}
}
