// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.dynamic.RefractionEyeRequest */
/* istanbul ignore next */
@GandalfModel
export class RefractionEyeRequest extends GandalfModelBase {

	@GandalfProperty()
	axis: number;

	@GandalfProperty()
	balanced: boolean;

	@GandalfProperty()
	cylinder: number;

	@GandalfProperty()
	horizontalPrism: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	horizontalPrismOrientation: constants.HorizontalPrismOrientation;

	@GandalfProperty()
	intermediateAdd: number;

	@GandalfProperty()
	nearAdd: number;

	@GandalfProperty()
	sphere: number;

	@GandalfProperty()
	verticalPrism: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	verticalPrismOrientation: constants.VerticalPrismOrientation;

}
