import { Injectable } from '@angular/core';
import { SortingService } from 'morgana';
import { TemplateStatus } from '@gandalf/constants';
import { EncounterTemplateResponse } from '@gandalf/model/encounter-template-response';
import { EncounterTemplateGandalfService } from '@gandalf/services';
import { map } from 'rxjs/operators';

export interface EncounterTemplateForDropdown extends EncounterTemplateResponse {
	label: string;
	value: any;
}

@Injectable({
	providedIn: 'root',
})
export class EncounterTemplateService {

	constructor(
		private encounterTemplateGandalfService: EncounterTemplateGandalfService,
	) {
	}

	/* istanbul ignore next */
	private static sortEncounterTemplateForDropdown(templateA: EncounterTemplateResponse, templateB: EncounterTemplateResponse) {
		if (templateA.templateStatus === TemplateStatus.ACTIVE && templateB.templateStatus === TemplateStatus.INACTIVE) {
			return -1;
		}
		if (templateA.templateStatus === TemplateStatus.INACTIVE && templateB.templateStatus === TemplateStatus.ACTIVE) {
			return 1;
		}

		const nameSort = templateA.name.localeCompare(templateB.name);
		if (nameSort !== 0) {
			return nameSort;
		}

		return templateA.encounterTemplateId - templateB.encounterTemplateId;
	}

	/* istanbul ignore next */
	private static formatEncounterTemplateForDropdown(template: EncounterTemplateResponse) {
		const formattedTemplate = template as EncounterTemplateForDropdown;
		formattedTemplate.label = template.templateStatus === TemplateStatus.INACTIVE ? '(Inactive) ' + template.name : template.name;
		formattedTemplate.value = template.encounterTemplateId;

		return formattedTemplate;
	}

	static formatActiveEncounterTemplateForDropdown(template: EncounterTemplateResponse): EncounterTemplateForDropdown {
		const formattedTemplate = template as EncounterTemplateForDropdown;
		formattedTemplate.label = template.name;
		formattedTemplate.value = template.encounterTemplateId;

		return formattedTemplate;
	}

	/* istanbul ignore next */
	findEncounterTemplatesForDropdown() {
		return this.encounterTemplateGandalfService.findEncounterTemplates().pipe(
			map(encounterTemplates => encounterTemplates.sort(EncounterTemplateService.sortEncounterTemplateForDropdown)),
			map(encounterTemplates => encounterTemplates.map(template => EncounterTemplateService.formatEncounterTemplateForDropdown(template))),
		);
	}

	findActiveEncounterTemplatesForDropdown() {
		return this.encounterTemplateGandalfService.findActiveEncounterTemplates().pipe(
			map(encounterTemplates => SortingService.sortBy(encounterTemplates, ['name', 'encounterTemplateId'], ['asc', 'asc'])),
			map(encounterTemplates => encounterTemplates.map(
				template => EncounterTemplateService.formatActiveEncounterTemplateForDropdown(template)),
			),
		);
	}
}
