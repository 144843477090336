import { Pipe, PipeTransform } from '@angular/core';
import { _isEmpty } from '@core/lodash/lodash';

@Pipe({
	name: 'camelCase',
})
export class CamelCasePipe implements PipeTransform {

	transform(value: string): string {
		const words = _isEmpty(value) ? [''] : value.split(' ');
		const camelCaseWords = words.map((word, index) => {
			if (index === 0) {
				return word.toLowerCase();
			}
			return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
		});
		return camelCaseWords.join('');
	}
}
