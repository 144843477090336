// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { LoincCodeResponse } from './loinc-code-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

/** See com.rev360.pms.api.controller.laborder.LabOrderListResponse */
/* istanbul ignore next */
@GandalfModel
export class LabOrderListResponse extends GandalfModelBase {

	@GandalfProperty()
	description: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate: Date;

	@GandalfProperty()
	fileCount: number;

	@GandalfProperty()
	hasInterpretation: boolean;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	labName: string;

	@GandalfProperty()
	order: LoincCodeResponse;

	@GandalfProperty({ propertyType: 'LocalDate' })
	orderDate: Date;

	@GandalfProperty()
	orderedBy: ProviderResponse;

	@GandalfProperty({ propertyType: 'LocalDate' })
	performedDate: Date;

	@GandalfProperty()
	result: string;

	@GandalfProperty()
	resultNum: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	resultType: constants.LabOrderResultType;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.LabOrderStatus;

}
