/* eslint-disable @typescript-eslint/naming-convention */

import { Action } from '@ngrx/store';

export enum PatientTabActionTypes {
	ADD_PATIENT_TAB = '[Add Patient Tab] Add Patient Tab to the Patients Tab Collection',
	REMOVE_PATIENT_TAB = '[Remove Patient Tab] Remove Patient Tab from the Patients Tab Collection',
	REMOVE_PATIENT_ENCOUNTER_TABS = '[Remove Patient Encounter Tabs] Removes Encounter tabs related to a patient.',
	UPDATE_PATIENT_TAB_HEADER_TEXT = '[Update Patient Tab Header Text] Updates the Patient Tab Header Text of a patient tab.',
	UPDATE_PATIENT_TAB_CURRENT_ROUTE = '[Update Patient Tab Current Route] Update the Patient Tabs Current Route',
}

export class AddPatientTab implements Action {
	readonly type = PatientTabActionTypes.ADD_PATIENT_TAB;
	constructor(public payload: { patientId: number, headerText: string }) {}
}

export class RemovePatientTab implements Action {
	readonly type = PatientTabActionTypes.REMOVE_PATIENT_TAB;
	constructor(public payload: { patientId: number }) {}
}

export class RemovePatientEncounterTabs implements Action {
	readonly type = PatientTabActionTypes.REMOVE_PATIENT_ENCOUNTER_TABS;
	constructor(public payload: { patientId: number }) {}
}

export class UpdatePatientTabHeaderText implements Action {
	readonly type = PatientTabActionTypes.UPDATE_PATIENT_TAB_HEADER_TEXT;
	constructor(public payload: {patientId: number, headerText: string}) {}
}

export class UpdatePatientTabCurrentRoute implements Action {
	readonly type = PatientTabActionTypes.UPDATE_PATIENT_TAB_CURRENT_ROUTE;
	constructor(public payload: { patientId: number, currentRoute: string }) {}
}

export type PatientTabActions =
	| AddPatientTab
	| RemovePatientTab
	| UpdatePatientTabCurrentRoute
	| UpdatePatientTabHeaderText;
