import { Injectable } from '@angular/core';
import { FeatureFlagGandalfService } from '@gandalf/services';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class FeatureFlagService {

	constructor(private featureFlagGandalfService: FeatureFlagGandalfService) { }

	getNewFeatureFlagCount(): Observable<number> {
		return this.featureFlagGandalfService.findFeatureFlagsCountForToasterMessage();
	}
}
