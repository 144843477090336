// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.insurance.PersonInsurancePolicyResponse */
/* istanbul ignore next */
@GandalfModel
export class PersonInsurancePolicyResponse extends GandalfModelBase {

	@GandalfProperty()
	coInsurancePercentage: number;

	@GandalfProperty()
	companyAddress: AddressResponse;

	@GandalfProperty()
	companyDefaultInsuranceTypeReferenceId: number;

	@GandalfProperty()
	companyDefaultPriorityReferenceId: number;

	@GandalfProperty()
	companyId: number;

	@GandalfProperty({ propertyType: 'Money' })
	contactLensAllowance: number;

	@GandalfProperty({ propertyType: 'Money' })
	contactLensFittingCoPay: number;

	@GandalfProperty()
	effectiveDate: Date;

	@GandalfProperty({ propertyType: 'Money' })
	familyDeductible: number;

	@GandalfProperty()
	feeScheduleId: number;

	@GandalfProperty({ propertyType: 'Money' })
	generalCoPay: number;

	@GandalfProperty()
	groupNumber: string;

	@GandalfProperty()
	id: number;

	@GandalfProperty({ propertyType: 'Money' })
	materialCoPay: number;

	@GandalfProperty({ propertyType: 'Money' })
	maxFrameAllowance: number;

	@GandalfProperty({ propertyType: 'Money' })
	medicalExamCoPay: number;

	@GandalfProperty({ propertyType: 'Money' })
	minFrameAllowance: number;

	@GandalfProperty()
	personNameResponse: PersonNameResponse;

	@GandalfProperty()
	policyHolderDob: Date;

	@GandalfProperty({ propertyType: 'Money' })
	routineExamCoPay: number;

	@GandalfProperty()
	rxBinNumber: string;

	@GandalfProperty()
	rxPcnNumber: string;

	@GandalfProperty()
	termedDate: Date;

}
