// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { DurationResponse } from './duration-response';

// @ts-ignore
import { MasterReferenceDataResponse } from './master-reference-data-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.patient.pod.PatientPodMedicationResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientPodMedicationResponse extends GandalfModelBase {

	@GandalfConstantDecorator()
	@GandalfProperty()
	authorizationType: constants.PrescriptionAuthorizationType;

	@GandalfProperty()
	authorizedBy: PersonNameResponse;

	@GandalfProperty()
	bodyLocation: MasterReferenceDataResponse;

	@GandalfProperty()
	description: string;

	@GandalfProperty()
	dosageAmount: number;

	@GandalfProperty()
	dosageUnit: MasterReferenceDataResponse;

	@GandalfProperty()
	drugName: string;

	@GandalfProperty()
	duration: DurationResponse;

	@GandalfProperty({ propertyType: 'LocalDate' })
	expirationDate: Date;

	@GandalfProperty()
	frequency: MasterReferenceDataResponse;

	@GandalfProperty()
	medicationPrescriptionId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	modifiedDate: Date;

	@GandalfProperty()
	route: MasterReferenceDataResponse;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.PrescriptionCurrentStatus;

	@GandalfConstantDecorator()
	@GandalfProperty()
	type: constants.MedicationPrescriptionType;

}
