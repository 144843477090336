// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.assessmentandplan.RunPatientTriggersRequest */
/* istanbul ignore next */
@GandalfModel
export class RunPatientTriggersRequest extends GandalfModelBase {

	@GandalfLabel('Encounter')
	@GandalfValidator({ notNull: { message: 'Encounter is required' } })
	@GandalfProperty({ isRequired: true })
	encounterId: number;

	@GandalfLabel('Patient')
	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientId: number;

	@GandalfConstantDecorator()
	@GandalfLabel('Query Template Category')
	@GandalfValidator({ notNull: { message: 'Query Template Category is required' } })
	@GandalfProperty({ isRequired: true })
	queryTemplateCategory: constants.QueryTemplateCategory;

}
