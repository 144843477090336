// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AdminOpenEdgeCardReaderResponse } from './admin-open-edge-card-reader-response';

/** See com.rev360.pms.api.controller.admin.solutions.openedge.OpenEdgeSettingsResponse */
/* istanbul ignore next */
@GandalfModel
export class OpenEdgeSettingsResponse extends GandalfModelBase {

	@GandalfArray(AdminOpenEdgeCardReaderResponse)
	cardReaders: AdminOpenEdgeCardReaderResponse[];

	@GandalfProperty()
	isActive: boolean;

	@GandalfProperty()
	motoAccountAuthKey: string;

	@GandalfProperty()
	motoAccountTerminalId: string;

	@GandalfProperty()
	motoAccountXWebId: string;

	@GandalfProperty()
	openEdgeSettingsId: number;

	@GandalfProperty()
	payOnlineEnabled: boolean;

	@GandalfProperty()
	payOnlineUrlPath: string;

	@GandalfProperty()
	practiceLocationId: number;

	@GandalfProperty()
	retailAccountAuthKey: string;

	@GandalfProperty()
	retailAccountTerminalId: string;

	@GandalfProperty()
	retailAccountXWebId: string;

	@GandalfProperty()
	statementPayOnlineEnabled: boolean;

}
