import { Injectable } from '@angular/core';
import { ModalManagerService } from 'morgana';
import { LoadingModalComponent } from '@shared/component/loading-modal/loading-modal.component';

@Injectable({
	providedIn: 'root',
})
export class LoadingService {

	isModalOpen = false;

	constructor(
		public modalManagerService: ModalManagerService,
	) {
	}

	showModal(title: string = 'Please Wait...', message: string = 'Loading...') {
		if (this.isModalOpen) {
			return;
		}
		this.isModalOpen = true;
		this.modalManagerService.open(LoadingModalComponent, {
			data: {
				title,
				message,
			},
		}).onClose.subscribe();
	}

	hideModal() {
		if (!this.isModalOpen) {
			return;
		}
		this.modalManagerService.close();
		this.isModalOpen = false;
	}

}
