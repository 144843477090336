// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientWorkflowScreenMenuResponse } from './patient-workflow-screen-menu-response';

/** See com.rev360.pms.api.controller.patient.encounter.workflow.PatientWorkflowStepMenuResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientWorkflowStepMenuResponse extends GandalfModelBase {

	@GandalfProperty()
	displayOrder: number;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	name: string;

	@GandalfArray(PatientWorkflowScreenMenuResponse)
	workflowScreens: PatientWorkflowScreenMenuResponse[];

}
