import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { InvoicesModule } from '@accounting/invoices/invoices.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { reducers } from '@app-store/reducers';
import { CustomRouterStateSerializer } from '@app-store/serializers/CustomRouterStateSerializer';
import { CoreModule } from '@core/core.module';
import { HttpErrorInterceptor } from '@core/global-error-handler/http-error-interceptor';
import { RevRouteReuseStrategy } from '@core/route-reuse.strategy';
import { MODEL_STORE } from '@gandalf/model/model-store';
import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { patientEffects } from '@patient-store/effects';
import { reducers as PatientReducers } from '@patient-store/reducers';
import { practiceEffects } from '@practice-store/effects';
import { reducers as PracticeReducers } from '@practice-store/reducers';
import { SharedModule } from '@shared/shared.module';
import { GandalfStoreModule } from 'gandalf';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderModule } from './header/header.module';
import { InjectorContainerModule } from './injector-container.module';
import { FooterModule } from './footer/footer.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { IframeContainerComponent } from './iframe-container/iframe-container.component';

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		CoreModule,
		AppRoutingModule,
		HttpClientModule,
		InjectorContainerModule,
		SharedModule,
		HeaderModule,
		IframeContainerComponent,
		GandalfStoreModule,
		FooterModule,
		// NgRx Imports
		StoreModule.forRoot(reducers, {
			runtimeChecks: {
				strictStateImmutability: false,
				strictActionImmutability: false,
			},
		}),
		StoreModule.forFeature('patient', PatientReducers),
		StoreModule.forFeature('practice', PracticeReducers),
		EffectsModule.forFeature(patientEffects),
		EffectsModule.forFeature(practiceEffects),
		EffectsModule.forRoot([]),
		StoreRouterConnectingModule.forRoot({
			stateKey: 'router',
		}),
		StoreDevtoolsModule.instrument({
			name: 'PMS NgRx Devtools',
			maxAge: 25,
			logOnly: environment.production,
			connectInZone: true,
		}),
		// Needed since we can access Invoice module components throughout the app before accessing the invoices screen.
		InvoicesModule,
	],
	exports: [
		BrowserModule,
	],
	providers: [
		// This provides the model store object as a service that can be injected. --Gandalf
		{provide: 'MODEL_STORE', useValue: {...MODEL_STORE} },
		{
			provide: RouteReuseStrategy,
			useClass: RevRouteReuseStrategy,
		},
		{provide: RouterStateSerializer, useClass: CustomRouterStateSerializer},
		// receives any errors from http requests
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpErrorInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent, HeaderComponent, FooterComponent],
})
export class AppModule {
}
