import { Injectable } from '@angular/core';
import { PracticeSmartApplicationGandalfService } from '@gandalf/services';
import { GetPracticeLaunchUrlRequest } from '@gandalf/model/get-practice-launch-url-request';
import { UrlService } from '@core/url-util/url.service';

@Injectable({
	providedIn: 'root',
})
export class SmartApplicationService {

	constructor(
		private practiceSmartApplicationGandalfService: PracticeSmartApplicationGandalfService,
		private urlService: UrlService,
	) {}

	/* istanbul ignore next: gandalf */
	findEnabledSmartApplications() {
		return this.practiceSmartApplicationGandalfService.findEnabledSmartApplications();
	}

	/* istanbul ignore next: gandalf */
	updateOrCreatePatientCcdaExport(patientId) {
		return this.practiceSmartApplicationGandalfService.updateOrCreatePatientCcdaExport(patientId);
	}

	/* istanbul ignore next: gandalf */
	findProviderDetails() {
		return this.practiceSmartApplicationGandalfService.findProviderDetails();
	}

	/* istanbul ignore next: gandalf */
	getPracticeLaunchUrl(request: GetPracticeLaunchUrlRequest) {
		return this.practiceSmartApplicationGandalfService.getPracticeLaunchUrl(request);
	}

	launchSmartApplicationWindow(practiceLaunchUrl: string) {
		this.urlService.goToWebpage(practiceLaunchUrl, 'SMART App Launch');
	}
}
