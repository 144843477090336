<div class="panel panel-default">
	<div class="panel-heading">
		<h4 class="panel-title">{{ categoryTitle }}</h4>
		<ul class="nav-tools">
			<li>
				<a class="e-control e-btn e-lib e-success" (click)="openCategory(null)" revTooltip tooltipContent="Add">
					<i class="fa fa-plus"></i>
				</a>
			</li>
		</ul>
	</div>
	<pms-category-list #list
					   [hidden]="detailMode"
					   [entityType]="entityType"
					   (openEvent)="openCategory($event)">
	</pms-category-list>
	@if (detailMode && !hasSubCategories) {
		<pms-category-detail
			[categoryTitle]="categoryTitle"
			[categoryData]="categoryData"
			[entityType]="entityType"
			(returnEvent)="closeCategory($event)">
		</pms-category-detail>
	}
	@if (detailMode && hasSubCategories) {
		<pms-advanced-category-detail
			[categoryTitle]="categoryTitle"
			[categoryData]="categoryData"
			[entityType]="entityType"
			(returnEvent)="closeCategory($event)">
		</pms-advanced-category-detail>
	}
</div>
