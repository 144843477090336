<form
	[formGroup]="formGroup"
	#templateForm="ngForm"
	(ngSubmit)="save()">
	@if (formGroup.invalid && templateForm.submitted) {
		<gandalf-lib-validation-messages
			[form]="formGroup"
			[requestObj]="request">
		</gandalf-lib-validation-messages>
	}
	<div class="panel-body">
		<div class="row">
			<div class="col-md-6">
				<h4 class="section-title bg-default mrgn-btm">Additional Claim Information</h4>
				<div class="form-group">
					<label class="col-sm-4 control-label" revTooltip tooltipContent="Loop 2310A">Referring Provider</label>
					<div class="col-sm-8">
						<ejs-dropdownlist
							[dataSource]="providerList"
							formControlName="referringProviderId"
							[showClearButton]="true"
							placeholder="Select Provider">
						</ejs-dropdownlist>
					</div>
				</div>
				<div class="form-group">
					<label class="col-sm-4 control-label" revTooltip tooltipContent="Driver's License">Driver's License</label>
					<div class="col-sm-8">
						<p class="form-control-static">{{ addlClaimInfo?.driversLicense }}</p>
					</div>
				</div>
				<div class="form-group">
					<label class="col-sm-4 control-label" revTooltip tooltipContent="Notes">Notes</label>
					<div class="col-sm-8">
						<ejs-textbox class="e-input" formControlName="localUse" [multiline]="true"></ejs-textbox>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
@if (!isReadOnly) {
	<div class="panel-footer">
		<button type="button" ejs-button [isPrimary]="true" (click)="submitForm($event)">Save</button>
		<button ejs-button type="button" iconCss="fa fa-close" (click)="cancel()">Cancel</button>
	</div>
}
