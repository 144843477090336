<ejs-dialog
		#modal
		(close)="closeModal()"
		cssClass="modal-sm"
		[attr.data-test-id]="'appointmentNoShowModal'">
	<ng-template #header>
		<div class="dlg-template">Appointment No-show</div>
	</ng-template>
	<ng-template #content>
		<p>Are you sure you want to mark this appointment as a no-show?</p>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button (click)="closeModal()" [attr.data-test-id]="'appointmentNoShowNoButton'">No</button>
		<button ejs-button [isPrimary]="true" (click)="setNoShowAppointment()" [attr.data-test-id]="'appointmentNoShowYesButton'">Yes</button>
	</ng-template>
</ejs-dialog>
