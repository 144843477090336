import { Injectable } from '@angular/core';
import { OptionItemService } from '@core/option-item/option-item.service';
import { SortingService } from 'morgana';
import { CategoryEntityType, RelatedEntityType } from '@gandalf/constants';
import { CategoryResponse } from '@gandalf/model/category-response';
import { FindCategoriesRequest } from '@gandalf/model/find-categories-request';
import { FindRelatedCategoriesRequest } from '@gandalf/model/find-related-categories-request';
import { AssignCategoryModalGandalfService } from '@gandalf/services';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class AssignCategoriesService {

	constructor(
		private assignCategoryModalGandalfService: AssignCategoryModalGandalfService,
	) {
	}

	sortCategories = (categories: CategoryResponse[]) => SortingService.sortBy(
		categories,
		['sortOrder', 'name', 'id'],
		['asc', 'asc', 'asc'],
	);

	filterSortSubCategoriesForDropdown = (categories: CategoryResponse[]) => {
		categories.forEach(category => {
			category.subCategories = this.sortCategories(category.subCategories.filter(subCategory => subCategory.active)).map(
				subCategories => OptionItemService.toOptionItem(subCategories, item => item.id, item => item.name),
			);
		});
		return categories;
	};

	findActiveByEntityType(entityType: CategoryEntityType) {
		const request = new FindCategoriesRequest();
		request.entityType = entityType;
		return this.assignCategoryModalGandalfService.findActiveByEntityType(request).pipe(
			map(this.sortCategories),
			map(this.filterSortSubCategoriesForDropdown),
		);
	}

	findRelatedCategoriesForEntity(parentId: number, relatedEntityType: RelatedEntityType) {
		const request = new FindRelatedCategoriesRequest();
		request.parentId = parentId;
		request.relatedEntityType = relatedEntityType;
		return this.assignCategoryModalGandalfService.findRelatedCategoriesForEntity(request);
	}

	/* istanbul ignore next: gandalf */
	assignRelatedCategories(request) {
		return this.assignCategoryModalGandalfService.assignRelatedCategories(request);
	}

}

