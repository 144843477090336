// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.schedule.scheduleitem.recurrence.CreateRecurPatternYearlyRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateRecurPatternYearlyRequest extends GandalfModelBase {

	@GandalfValidator({ min: { min: 1, message: 'Day of month must be between 1 and 31' } })
	@GandalfValidator({ max: { max: 31, message: 'Day of month must be between 1 and 31' } })
	@GandalfProperty()
	yearlyDayOfMonth: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	yearlyDaySelect: constants.DaySelect;

	@GandalfConstantDecorator()
	@GandalfProperty()
	yearlyMonthSelect: constants.MonthSelect;

	@GandalfConstantDecorator()
	@GandalfProperty()
	yearlyMonthSelect2: constants.MonthSelect;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Pattern type must be set' } })
	@GandalfProperty({ isRequired: true })
	yearlyPatternType: constants.YearlyPatternType;

	@GandalfConstantDecorator()
	@GandalfProperty()
	yearlyWeekSelect: constants.WeekSelect;

}
