import { CurrencyPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { PrintBarcodeLabelDataRequest } from '@core/printer/PrintBarcodeLabelDataRequest';
import { PrintBarcodeLabelRequest } from '@core/printer/PrintBarcodeLabelRequest';
import { TemplateSearchObjectRequest } from '@core/printer/TemplateSearchObjectRequest';
import { LocationProductItemForBarcodePrintingResponse } from '@gandalf/model/location-product-item-for-barcode-printing-response';
import { PracticeLocationDefaultLabelPrinterResponse } from '@gandalf/model/practice-location-default-label-printer-response';
import { URL_PRINT_ENDPOINTS } from '@shared/constants/url.constants';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PrinterService {

	constructor(
		public httpClient: HttpClient,
		private currencyPipe: CurrencyPipe,
	) {
	}

	private static createDataElement(dataId: string, dataValue: string, labelId: string, labelText: string) {
		if (_isNil(dataValue)) {
			dataValue = '';
		}
		return new PrintBarcodeLabelDataRequest(dataId, dataValue, labelId, dataValue !== '' ? labelText : '');
	}

	printLabelsForItem(
		labelPrintingRequest: LocationProductItemForBarcodePrintingResponse,
		defaultPrinter: PracticeLocationDefaultLabelPrinterResponse,
		labelCount: number,
	) {
		// Starting the iteration at 1, so the for loop is printing one less than the label count.
		// The return statement prints the last one. Because of the chaining of these functions being setup to allow the user to interrupt
		// this will allow all the labels for an item to finish printing before returning the function to print the last one.
		// The calling function will get that back and allow the next item to be passed in and printed.
		for (let i = 1; i < labelCount; i++) {
			this.printLabelForItem(labelPrintingRequest, defaultPrinter).subscribe();
		}
		return lastValueFrom(this.printLabelForItem(labelPrintingRequest, defaultPrinter));
	}

	printLabelForItem(
		labelPrintingRequest: LocationProductItemForBarcodePrintingResponse,
		defaultPrinter: PracticeLocationDefaultLabelPrinterResponse,
	) {
		let printRetailPrice = true;

		// We are working with a third party that currently handle the label templates and tell us how to populate them.
		// Until we bring those templates into our DB, we chose not to do custom label templates.
		// We have a large customer that asked for us to remove retail price from labels. This String here matches a DB value that
		// a customer using a specific label type and specifically chooses the no retail price option.  I intentionally
		// did not make this a constant as it is a hack and should be temporary until we proceed with the template project to
		// own the templates in our DB.  I didn't want this String ending up used anywhere else giving the idea
		// that we were wanting this implementation to be accepted for more than just this specific use case.
		if (defaultPrinter.templateDescription === 'TT368TL-No-Retail-Price') {
			printRetailPrice = false;
		}

		const templateSearchObject = [
			new TemplateSearchObjectRequest('LabelName', defaultPrinter.templateKey),
			new TemplateSearchObjectRequest('LabelId', defaultPrinter.templateLayout),
		];

		return this.printLabel(new PrintBarcodeLabelRequest(
			'Barcode Label',
			this.createLabelData(labelPrintingRequest, printRetailPrice),
			defaultPrinter.printerName,
			templateSearchObject,
		));
	}

	createLabelData(labelPrintingRequest: LocationProductItemForBarcodePrintingResponse, printRetailPrice: boolean) {
		const itemPrice: number = labelPrintingRequest.calculatedRetailPrice;

		const labelDataElements: PrintBarcodeLabelDataRequest[] = [];

		if (printRetailPrice) {
			labelDataElements.push(PrinterService.createDataElement('Price', this.currencyPipe.transform(itemPrice), 'PriceLabel', 'Price: '));
		} else {
			labelDataElements.push(PrinterService.createDataElement('Price', '', 'PriceLabel', ''));
		}

		labelDataElements.push(
			PrinterService.createDataElement('BarCode', labelPrintingRequest.itemUpc, 'BarcodeLabel', 'Barcode: '),
		);
		labelDataElements.push(
			PrinterService.createDataElement('Manufacturer', labelPrintingRequest.manufacturerName, 'ManufacturerLabel', 'Manufacturer: '),
		);
		labelDataElements.push(
			PrinterService.createDataElement('Model', labelPrintingRequest.productName, 'ModelLabel', 'Model: '),
		);
		labelDataElements.push(
			PrinterService.createDataElement('ProductNumber', labelPrintingRequest.itemSku, 'ProductNumberLabel', 'Product Number: '),
		);
		labelDataElements.push(
			PrinterService.createDataElement('LocationId', labelPrintingRequest.locationBarcodeIdentifier, '', ''),
		);
		labelDataElements.push(
			PrinterService.createDataElement('Color', labelPrintingRequest.frameColorName, 'ColorLabel', 'Color: '),
		);

		let frameBridge = '';
		if (!_isNil(labelPrintingRequest.frameBridge)) {
			frameBridge = labelPrintingRequest.frameBridge.toString();
		}
		labelDataElements.push(
			PrinterService.createDataElement('Bridge', frameBridge, 'BridgeLabel', 'Bridge: '),
		);

		let frameEye = '';
		if (!_isNil(labelPrintingRequest.frameEye)) {
			frameEye = labelPrintingRequest.frameEye.toString();
		}

		labelDataElements.push(
			PrinterService.createDataElement('Eye', frameEye, 'EyeLabel', 'Eye: '),
		);

		return [labelDataElements];
	}

	printLabel(printBarcodeLabelRequest: PrintBarcodeLabelRequest) {
		return this.httpClient.post(URL_PRINT_ENDPOINTS.PRINT_LABEL.BASE + URL_PRINT_ENDPOINTS.PRINT_LABEL.PRINT, printBarcodeLabelRequest);
	}
}
