import { HttpHeaders } from '@angular/common/http';

export const REV_GANDALF = 'Rev-Gandalf';

export const GANDALF_HTTP_OPTIONS = {
	headers: new HttpHeaders({
		[REV_GANDALF]: '0.1',
	}),
};

/**
 * The name of the property which indicates the JSON subtype, used for
 * polymorphic requests. This corresponds to the value the backend code expects.
 */
export const GANDALF_JSON_SUBTYPE_PROPERTY = '@class';

export class GandalfConstant<T> {

	constructor(private _value: T, private _label: string) {
	}

	get value(): T {
		return this._value;
	}

	get label(): string {
		return this._label;
	}

	// This is so we don't need to explicitly grab the label with the decorator methods.
	toString(): string {
		return this.label;
	}

	// This is so that when data is sent to the server, it sends the data that the server expects.
	toJSON() {
		return this.value;
	}
}

export interface GandalfConstantList<GandalfConstantType extends GandalfConstant<any> = GandalfConstant<any>> {
	readonly label: string;
	readonly values: GandalfConstantType[];
	readonly default?: GandalfConstantType;
}
