<ejs-dialog
	#modal
	(close)="closeModal()"
	[attr.data-test-id]="'manualProcessPaymentModal'">
	<ng-template #header>
		<div class="dlg-template">Process Payment</div>
	</ng-template>
	<ng-template #content>
		<form class="form-horizontal" #ngForm="ngForm" (ngSubmit)="submit()" [formGroup]="formGroup">
			@if (formGroup.invalid && ngForm.submitted) {
				<gandalf-lib-validation-messages
					[form]="formGroup"
					[requestObj]="request"
					[attr.data-test-id]="'manualProcessPaymentModalValidationMessage'">
				</gandalf-lib-validation-messages>
			}
			<div class="form-group" [attr.data-test-id]="'totalAmountFormGroup'">
				<label class="col-sm-3 control-label">Total Amount</label>
				<div class="col-sm-9">
					<p class="form-control-static">{{ totalAmount | currency : 'USD' }}</p>
				</div>
			</div>
			<div class="form-group">
				<label class="col-sm-3 control-label">Card Type</label>
				<div class="col-sm-9">
					<ejs-dropdownlist
						placeholder="Select Card Type"
						[showClearButton]="true"
						formControlName="creditCardType"
						[dataSource]="creditCardTypes">
					</ejs-dropdownlist>
				</div>
			</div>
			<div class="form-group">
				<label class="col-sm-3 control-label">Reference/Check #</label>
				<div class="col-sm-9">
					<input type="text" class="e-input" formControlName="referenceNumber">
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" type="button" (click)="closeModal()" [attr.data-test-id]="'processPaymentCancelButton'">Cancel</button>
		<button [isPrimary]="true" ejs-button type="button" (click)="submitForm($event)" [attr.data-test-id]="'processPaymentApplyPaymentButton'">Apply
			Payment
		</button>
	</ng-template>
</ejs-dialog>
