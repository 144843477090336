// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { MasterReferenceDataResponse } from './master-reference-data-response';

// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.prescription.ContactLensEyeOptionsResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensEyeOptionsResponse extends GandalfModelBase {

	@GandalfArray(ReferenceDataResponse)
	addDesignations: ReferenceDataResponse[];

	@GandalfArray(MasterReferenceDataResponse)
	addZoneSizes: MasterReferenceDataResponse[];

	@GandalfArray(MasterReferenceDataResponse)
	designs: MasterReferenceDataResponse[];

	@GandalfArray(MasterReferenceDataResponse)
	edgeLifts: MasterReferenceDataResponse[];

	@GandalfArray(ReferenceDataResponse)
	lensMaterialsHybrid: ReferenceDataResponse[];

	@GandalfArray(ReferenceDataResponse)
	lensMaterialsRgp: ReferenceDataResponse[];

	@GandalfArray(ReferenceDataResponse)
	lensMaterialsSoft: ReferenceDataResponse[];

	@GandalfArray(MasterReferenceDataResponse)
	limbalClearances: MasterReferenceDataResponse[];

	@GandalfArray(MasterReferenceDataResponse)
	peripheralEdges: MasterReferenceDataResponse[];

	@GandalfArray(MasterReferenceDataResponse)
	profiles: MasterReferenceDataResponse[];

	@GandalfArray(MasterReferenceDataResponse)
	segmentSizes: MasterReferenceDataResponse[];

	@GandalfArray(MasterReferenceDataResponse)
	skirtCurveRadii: MasterReferenceDataResponse[];

	@GandalfArray(MasterReferenceDataResponse)
	specialCharacteristics: MasterReferenceDataResponse[];

}
