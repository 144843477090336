<ejs-dropdownlist
        class="margin-bottom-sm"
        [dataSource]="groups"
        [showClearButton]="true"
        placeholder="Any Group"
        (change)="handleGroupSelectionChanged($event)"
></ejs-dropdownlist>
<ejs-listbox
        class="margin-bottom-md"
		height="350px"
		[(value)]="value"
        [dataSource]="displayedEmployees"
        [selectionSettings]="{showCheckbox: true, showSelectAll: true}"
        [allowDragAndDrop]="false"
        [fields]="{text: 'label', value: 'value'}"
        (change)="valueChanged()"></ejs-listbox>
