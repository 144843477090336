<form class="form-horizontal" [formGroup]="componentForm">
	<div class="col-md-6 col-lg-3">
		<div class="form-group" [attr.data-test-id]="'basicInformationCompanyFormGroup'">
			<label class="col-sm-4 control-label required">Company</label>
			<div class="col-sm-8">
				<div class="e-input-group">
					@if (companyOptions) {
						<ejs-dropdownlist #companyDropdown
										  [dataSource]="companyOptions"
										  formControlName="companyId"
										  (change)="companySelected($event)"
										  placeholder="Select Company">
						</ejs-dropdownlist>
					}
					@if (canAddCompany) {
						<span
							(click)="openAddCompanyModal()"
							revTooltip tooltipContent="Add Insurance Company"
							class="e-input-group-icon fa fa-plus"
							[attr.data-test-id]="'addInsuranceCompanyButton'">
            </span>
					}
				</div>
				@if (companyAddress) {
					<p class="margin-top-sm margin-bottom-0 padding-0">
						@if (companyAddress.address1) {
							<span>{{ companyAddress.address1 }}<br></span>
						}
						@if (companyAddress.address2) {
							<span>{{ companyAddress.address2 }}<br></span>
						}
						@if (companyAddress.city || companyAddress.stateProvince || companyAddress.postalCode) {
							<span>
                {{ companyAddress.city }}{{ companyAddress.stateProvince ? ', ' : '' }}{{ companyAddress.stateProvince }}{{ companyAddress.postalCode ? ', ' : '' }}{{ companyAddress.postalCode }}
              </span>
						}
					</p>
				}
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'basicInformationPriorityFormGroup'">
			<label class="col-sm-4 control-label required">Priority</label>
			<div class="col-sm-8">
				@if (priorityOptions) {
					<ejs-dropdownlist #priorityDropdown
									  [dataSource]="priorityOptions"
									  formControlName="priorityId"
									  placeholder="Select Priority">
					</ejs-dropdownlist>
				}
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'basicInformationTypeFormGroup'">
			<label class="col-sm-4 control-label required">Type</label>
			<div class="col-sm-8">
				@if (typeOptions) {
					<ejs-dropdownlist #typeDropdown
									  [dataSource]="typeOptions"
									  formControlName="insuranceTypeId"
									  placeholder="Select Type">
					</ejs-dropdownlist>
				}
			</div>
		</div>
		<div class="form-group">
			<div class="col-sm-8 col-sm-offset-4">
				<ejs-checkbox formControlName="referralRequired" label="Referral Required"></ejs-checkbox>
			</div>
		</div>
	</div>

	<div class="col-md-6 col-lg-3">
		<div class="form-group" [attr.data-test-id]="'basicInformationPlanFormGroup'">
			<label class="col-sm-4 control-label">Plan Name</label>
			<div class="col-sm-8">
				<input class="e-input form-control" formControlName="planName"/>
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'basicInformationPolicyHolderFormGroup'">
			<label class="col-sm-4 control-label required">Policy Holder</label>
			<div class="col-sm-8">
				<span class="form-control-static margin-right-sm display-inline-block">{{ policyHolderName | personName }}</span>
				@if (canSelectPolicyHolder) {
					<button ejs-button
							revTooltip
							[disabled]="disablePolicyHolderChanges"
							tooltipContent="Select Policy Holder"
							(click)="openPolicyHolderModal()"
							iconCss="fa fa-search"
							[attr.data-test-id]="'basicInformationPolicyHolderLookupButton'">
					</button>
				}
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'basicInformationPolicyDateOfBirthFormGroup'">
			<label class="col-sm-4 control-label required">Policy Holder DOB</label>
			<div class="col-sm-8">
				<p class="form-control-static">{{ policyHolderDob ? (policyHolderDob | date : 'MM/dd/yyyy') : '' }}</p>
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'basicInformationRelationshipFormGroup'">
			<label class="col-sm-4 control-label required">Relationship</label>
			<div class="col-sm-8">
				<pms-constant-dropdown
					#policyHolderRelationshipDropdown
					[constantList]="policyHolderRelationshipOptions"
					formControlName="policyHolderRelationship">
				</pms-constant-dropdown>
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'basicInformationPolicyNumberFormGroup'">
			<label class="col-sm-4 control-label required">Policy #</label>
			<div class="col-sm-8">
				<input class="e-input form-control" formControlName="policyNumber"/>
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'basicInformationGroupNumberFormGroup'">
			<label class="col-sm-4 control-label">{{ 'Group #' | canadaLabel : 'Group/Version Code' }}</label>
			<div class="col-sm-8">
				<input class="e-input form-control" formControlName="groupNumber"/>
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'basicInformationFeeScheduleFormGroup'">
			<label class="col-sm-4 control-label">Fee Schedule</label>
			<div class="col-sm-8">
				@if (feeSchedules) {
					<ejs-dropdownlist
						#feeScheduleDropdown
						formControlName="feeScheduleId"
						placeholder="Select Fee Schedule"
						[showClearButton]="true"
						[dataSource]="feeSchedules">
					</ejs-dropdownlist>
				}
			</div>
		</div>
	</div>

	<div class="col-md-6 col-lg-3">
		<div class="form-group" [attr.data-test-id]="'basicInformationEffectiveDateFormGroup'">
			<label class="col-sm-4 control-label">Effective Date</label>
			<div class="col-sm-8">
				<ejs-datepicker placeholder="mm/dd/yyyy"
								formControlName="effectiveDate"
								[format]="dateFormat">
				</ejs-datepicker>
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'basicInformationTermedDateFormGroup'">
			<label class="col-sm-4 control-label">Termed Date</label>
			<div class="col-sm-8">
				<ejs-datepicker placeholder="mm/dd/yyyy"
								formControlName="termedDate"
								[format]="dateFormat">
				</ejs-datepicker>
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'basicInformationFamilyDeductibleFormGroup'">
			<label class="col-sm-4 control-label">Family Deductible</label>
			<div class="col-sm-8">
				<pms-currency-input formControlName="familyDeductible" placeholder="" nanValue=""></pms-currency-input>
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'basicInformationGeneralCoPayFormGroup'">
			<label class="col-sm-4 control-label">General Co-Pay</label>
			<div class="col-sm-8">
				<pms-currency-input formControlName="generalCoPay" placeholder="" nanValue=""></pms-currency-input>
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'basicInformationMedicalExamCoPayFormGroup'">
			<label class="col-sm-4 control-label">Medical Exam Co-Pay</label>
			<div class="col-sm-8">
				<pms-currency-input formControlName="medicalExamCoPay" placeholder="" nanValue=""></pms-currency-input>
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'basicInformationRoutineExamCoPayFormGroup'">
			<label class="col-sm-4 control-label">Routine Exam Co-Pay</label>
			<div class="col-sm-8">
				<pms-currency-input formControlName="routineExamCoPay" placeholder="" nanValue=""></pms-currency-input>
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'basicInformationContactLensFittingFormGroup'">
			<label class="col-sm-4 control-label">CL Fitting Co-Pay</label>
			<div class="col-sm-8">
				<pms-currency-input formControlName="contactLensFittingCoPay" placeholder="" nanValue=""></pms-currency-input>
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'basicInformationCoInsuranceFormGroup'">
			<label class="col-sm-4 control-label">Co-Insurance(%)</label>
			<div class="col-sm-8">
				<input class="e-input form-control" revDigitOnlyInput formControlName="coInsurancePercentage"/>
			</div>
		</div>
	</div>

	<div class="col-md-6 col-lg-3">
		<div class="form-group" [attr.data-test-id]="'basicInformationMaterialCoPayFormGroup'">
			<label class="col-sm-4 control-label">Material Co-Pay</label>
			<div class="col-sm-8">
				<pms-currency-input formControlName="materialCoPay" placeholder="" nanValue=""></pms-currency-input>
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'basicInformationMinimumFrameAllowanceFormGroup'">
			<label class="col-sm-4 control-label">Min Frame Allowance</label>
			<div class="col-sm-8">
				<pms-currency-input formControlName="minFrameAllowance" placeholder="" nanValue=""></pms-currency-input>
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'basicInformationMaximumFrameAllowanceFormGroup'">
			<label class="col-sm-4 control-label">Max Frame Allowance</label>
			<div class="col-sm-8">
				<pms-currency-input formControlName="maxFrameAllowance" placeholder="" nanValue=""></pms-currency-input>
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'basicInformationContactLensAllowanceFormGroup'">
			<label class="col-sm-4 control-label">Contact Lens Allowance</label>
			<div class="col-sm-8">
				<pms-currency-input formControlName="contactLensAllowance" placeholder="" nanValue=""></pms-currency-input>
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'basicInformationRxBinFormGroup'">
			<label class="col-sm-4 control-label">Rx BIN #</label>
			<div class="col-sm-8">
				<input class="e-input form-control" formControlName="rxBinNumber"/>
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'basicInformationRxPcnFormGroup'">
			<label class="col-sm-4 control-label">Rx PCN #</label>
			<div class="col-sm-8">
				<input class="e-input form-control" formControlName="rxPcnNumber"/>
			</div>
		</div>
	</div>
</form>
