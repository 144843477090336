// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { OrderPrescriptionResponse } from './order-prescription-response';

/** See com.rev360.pms.api.controller.order.eyefinity.OrderEyeglassPrescriptionResponse */
/* istanbul ignore next */
@GandalfModel
export class OrderEyeglassPrescriptionResponse extends OrderPrescriptionResponse {

	@GandalfConstantDecorator()
	@GandalfProperty()
	authorizationType: constants.PrescriptionAuthorizationType;

	@GandalfProperty({ propertyType: 'LocalDate' })
	expirationDate: Date;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	odCylinder: number;

	@GandalfProperty()
	odIntermediateAdd: number;

	@GandalfProperty()
	odNearAdd: number;

	@GandalfProperty()
	odSphere: number;

	@GandalfProperty()
	osCylinder: number;

	@GandalfProperty()
	osIntermediateAdd: number;

	@GandalfProperty()
	osNearAdd: number;

	@GandalfProperty()
	osSphere: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.PrescriptionStatus;

}
