// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AppointmentInsuranceVerificationResponse } from './appointment-insurance-verification-response';

// @ts-ignore
import { EmployeeNameResponse } from './employee-name-response';

// @ts-ignore
import { EncounterTemplateResponse } from './encounter-template-response';

// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { PhoneNumbersResponse } from './phone-numbers-response';

// @ts-ignore
import { PracticeLocationSummaryResponse } from './practice-location-summary-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

// @ts-ignore
import { RecurringDefinitionResponse } from './recurring-definition-response';

// @ts-ignore
import { ScheduleItemResponse } from './schedule-item-response';

// @ts-ignore
import { SecurityRoleResponse } from './security-role-response';

/** See com.rev360.pms.api.controller.schedule.scheduleitem.AppointmentResponse */
/* istanbul ignore next */
@GandalfModel
export class AppointmentResponse extends ScheduleItemResponse {

	@GandalfProperty()
	actualEndDate: Date;

	@GandalfProperty()
	actualStartDate: Date;

	@GandalfProperty()
	appointmentInsuranceVerificationResponse: AppointmentInsuranceVerificationResponse;

	@GandalfProperty()
	confirmationRequired: boolean;

	@GandalfProperty()
	confirmed: boolean;

	@GandalfProperty()
	createdOn: Date;

	@GandalfProperty()
	description: string;

	@GandalfProperty()
	displayColor: number;

	@GandalfProperty()
	employee: EmployeeNameResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	encounterApprovalStatus: constants.EncounterApprovalStatus;

	@GandalfProperty()
	encounterId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	encounterStatus: constants.EncounterStatus;

	@GandalfProperty()
	endDate: Date;

	@GandalfProperty()
	groupingPersonId: number;

	@GandalfProperty()
	id: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	interviewStatus: constants.InterviewStatus;

	@GandalfProperty()
	locationId: number;

	@GandalfProperty()
	locationSummary: PracticeLocationSummaryResponse;

	@GandalfProperty({ propertyType: 'LocalDate' })
	patientDob: Date;

	@GandalfProperty()
	patientEmail: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	patientEstablishedStatus: constants.EstablishedStatus;

	@GandalfConstantDecorator()
	@GandalfProperty()
	patientGender: constants.Gender;

	@GandalfProperty()
	patientId: number;

	@GandalfProperty()
	patientName: PatientNameResponse;

	@GandalfProperty()
	patientPHRLogin: boolean;

	@GandalfProperty()
	patientPersonId: number;

	@GandalfProperty()
	patientPhoneNumbers: PhoneNumbersResponse;

	@GandalfProperty()
	patientScheduled: boolean;

	@GandalfProperty()
	provider: ProviderResponse;

	@GandalfProperty()
	recurringDefinition: RecurringDefinitionResponse;

	@GandalfProperty()
	relatedAppointmentId: number;

	@GandalfProperty()
	role: SecurityRoleResponse;

	@GandalfProperty()
	startDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.ScheduleItemStatus;

	@GandalfProperty()
	subType: string;

	@GandalfProperty()
	subTypeId: number;

	@GandalfProperty()
	summary: string;

	@GandalfProperty()
	template: EncounterTemplateResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	type: constants.ScheduleItemType;

	@GandalfProperty()
	updatedOn: Date;

}
