<ejs-grid
	class="table-view"
	[dataSource]="invoiceStatements"
	[allowSelection]="false"
	[allowSorting]="true"
	[sortSettings]="sortOptions"
	autoFitColumns
	[autoFitBlacklist]="['message']"
	gridLines="Horizontal"
	[attr.data-test-id]="'invoiceDetailsStatementHistoryGrid'">
	<e-columns>
		<e-column headerText="Print Date" field="printDate" [format]="tableDateFormat"></e-column>
		<e-column headerText="Due Date" field="dueDate"></e-column>
		<e-column headerText="Message" field="message" clipMode="EllipsisWithTooltip"></e-column>
		<e-column headerText="Invoice Balance" field="invoiceBalance" format="C2"></e-column>
		<e-column headerText="Stmt. Balance" field="payorBalance" format="C2"></e-column>
	</e-columns>
</ejs-grid>
