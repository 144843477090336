import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { ChangeEventArgs, TimePickerComponent } from '@syncfusion/ej2-angular-calendars';
import { TimepickerFormatterService } from './timepicker-formatter.service';

@Directive({
	selector: 'ejs-timepicker[pmsTimepickerFormatter]',
})
export class TimepickerFormatterDirective {

	@Output()
	timeChangedByFormatter = new EventEmitter<ChangeEventArgs>();

	constructor(
		private timepickerComponent: TimePickerComponent,
		private timepickerFormatterService: TimepickerFormatterService,
	) {
		this.timepickerComponent.open.subscribe(() => {
			// if the minute value isn't 0 or the step value
			if (this.timepickerComponent.value && this.timepickerComponent.value.getMinutes() % this.timepickerComponent.step !== 0) {
				const scrollDate = this.timepickerComponent.value;
				scrollDate.setMinutes(this.timepickerComponent.step);
				this.timepickerComponent.scrollTo = scrollDate;
			}
		});
	}

	/**
	 * Sets the timepicker value to the current datetime
	 */
	@HostListener('dblclick')
	onDblClick() {
		if (this.timepickerComponent.enabled) {
			this.timepickerComponent.value = new Date();
			this.timepickerComponent.dataBind();

			const eventArgs: ChangeEventArgs = {
				isInteracted: true,
				value: this.timepickerComponent.value,
				element: (this.timepickerComponent as any).inputElement,
			};
			this.timeChangedByFormatter.emit(eventArgs);
		}
	}

	/**
	 * Selects the string text of the timepicker
	 */
	@HostListener('focus')
	onFocus() {
		setTimeout(() => {
			(this.timepickerComponent as any).inputElement.select();
		});
	}

	/**
	 * Attempts to parse the inputted timepicker string value to a proper datetime and sets it on the timepicker
	 * Shorthand notation and normal time formats are allowed and attempted to be parsed
	 */
	@HostListener('blur')
	onBlur() {
		const inputValue: string = (this.timepickerComponent as any).inputElement.value;
		const convertedDate = this.parseTime(inputValue);

		if (convertedDate) {
			this.timepickerComponent.value = convertedDate.toDate();
			this.timepickerComponent.dataBind();

			const eventArgs: ChangeEventArgs = {
				isInteracted: true,
				value: this.timepickerComponent.value,
				element: (this.timepickerComponent as any).inputElement,
			};
			this.timeChangedByFormatter.emit(eventArgs);
		}
	}

	/**
	 * Retrieves the valid or invalid parsed time from the inputted value
	 */
	parseTime(value: string) {
		return this.timepickerFormatterService.parseTime(value);
	}

}
