// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.schedule.UpdateScheduleViewPreferencesRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateScheduleViewPreferencesRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Schedule grouping preference is required' } })
	@GandalfProperty({ isRequired: true })
	enableGroupingPreference: boolean;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Schedule increment preference is required' } })
	@GandalfProperty({ isRequired: true })
	incrementPreference: constants.ScheduleIncrementPreferenceValues;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Schedule layout preference is required' } })
	@GandalfProperty({ isRequired: true })
	layoutPreference: constants.ScheduleLayoutPreferenceValues;

	@GandalfConstantDecorator()
	@GandalfProperty()
	scheduleView: constants.ScheduleView;

}
