// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { LoincCodeResponse } from './loinc-code-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

/** See com.rev360.pms.api.controller.patient.pod.PatientPodLabOrderResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientPodLabOrderResponse extends GandalfModelBase {

	@GandalfProperty()
	description: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate: Date;

	@GandalfProperty()
	fileCount: number;

	@GandalfProperty()
	hasInterpretation: boolean;

	@GandalfProperty()
	labName: string;

	@GandalfProperty()
	labOrderId: number;

	@GandalfProperty()
	order: LoincCodeResponse;

	@GandalfProperty({ propertyType: 'LocalDate' })
	orderDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	orderStatus: constants.LabOrderStatus;

	@GandalfProperty()
	orderedBy: ProviderResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	resultStatus: constants.LabOrderResultStatus;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate: Date;

}
