import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { VoidInvoiceRequest } from '@gandalf/model/void-invoice-request';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';
import { AccountingService } from '../../core/accounting/accounting.service';
import { InvoiceService } from '../../core/accounting/invoice-service/invoice.service';

@Component({
	selector: 'pms-void-invoice-comment',
	templateUrl: './void-invoice-comment-modal.component.html',
	styles: [],
})
export class VoidInvoiceCommentModalComponent implements OnInit {
	@ViewChild('modal')
	modal: DialogComponent;
	@ViewChild('templateForm')
	templateForm: NgForm;
	componentForm: UntypedFormGroup;
	voidInvoiceRequest: VoidInvoiceRequest;

	constructor(
		public ref: DynamicModalRef,
		public modalConfig: ModalConfig,
		public invoiceService: InvoiceService,
		private gandalfFormBuilder: GandalfFormBuilder,
		public accountingService: AccountingService,
	) { }

	ngOnInit() {
		this.createForm();
		this.initializeForm();
	}

	createForm() {
		this.voidInvoiceRequest = new VoidInvoiceRequest();
		this.voidInvoiceRequest.invoiceId = this.modalConfig.data.invoiceId;
		this.componentForm = this.gandalfFormBuilder.group(this.voidInvoiceRequest);
	}

	/**
	 * Used to initialize any form values
	 *   - set comments value to empty string so that it's length starts at 0 as this is used in the view
	 */
	initializeForm() {
		this.componentForm.controls.comments.setValue('');
	}

	submitForm(event: any) {
		this.templateForm.onSubmit(event);
	}

	closeModal() {
		this.ref.close(this.modal);
	}

	/**
	 * Checks the validity of the form.  If invalid marks form controls as dirty and leaves the method.
	 * If valid it used Gandalf the Black to call the legacy java code and void the invoice. Once complete
	 * the invoice is refreshed with the updated data and the modal is closed.
	 */
	voidInvoice() {
		if (this.componentForm.invalid) {
			return;
		}

		const request: VoidInvoiceRequest = new VoidInvoiceRequest();
		request.invoiceId = this.modalConfig.data.invoiceId;
		request.comments = this.componentForm.controls.comments.value;

		this.accountingService.voidInvoice(request).subscribe({
			next: () => {
				this.invoiceService.refreshInvoice(this.modalConfig.data.invoiceId);
				this.closeModal();
			},
			error: () => this.closeModal(), // close the modal if the server returns an error message
		});
	}
}
