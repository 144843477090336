// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.messaging.MessageParticipantNameResponse */
/* istanbul ignore next */
@GandalfModel
export class MessageParticipantNameResponse extends GandalfModelBase {

	@GandalfProperty()
	directName: string;

	@GandalfProperty()
	messageParticipantId: number;

	@GandalfProperty()
	person: PersonNameResponse;

}
