// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.SchedulingPreferencesResponse */
/* istanbul ignore next */
@GandalfModel
export class SchedulingPreferencesResponse extends GandalfModelBase {

	@GandalfConstantDecorator()
	@GandalfProperty()
	fridayTime: constants.SchedulingPreferencesTimeOfDay;

	@GandalfProperty()
	generalNote: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	mondayTime: constants.SchedulingPreferencesTimeOfDay;

	@GandalfConstantDecorator()
	@GandalfProperty()
	saturdayTime: constants.SchedulingPreferencesTimeOfDay;

	@GandalfConstantDecorator()
	@GandalfProperty()
	scheduleRisk: constants.SchedulingPreferencesScheduleRisk;

	@GandalfConstantDecorator()
	@GandalfProperty()
	sundayTime: constants.SchedulingPreferencesTimeOfDay;

	@GandalfConstantDecorator()
	@GandalfProperty()
	thursdayTime: constants.SchedulingPreferencesTimeOfDay;

	@GandalfConstantDecorator()
	@GandalfProperty()
	tuesdayTime: constants.SchedulingPreferencesTimeOfDay;

	@GandalfConstantDecorator()
	@GandalfProperty()
	wednesdayTime: constants.SchedulingPreferencesTimeOfDay;

}
