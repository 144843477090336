import { _get } from '@core/lodash/lodash';
import { RouteBuilderService } from '@core/router-utils/route-builder.service';
import { PayerType } from '@gandalf/constants';
import { InvoiceTabActions, InvoiceTabActionTypes } from '@invoices-store/actions/invoice-tab.actions';
import { ReceivePaymentState } from '@payments-store/reducers/payment-tab.reducer';
import { StatefulTabItem } from '@shared/directives/stateful-tab/stateful-tab-item';
import { StatefulTabType } from '@shared/directives/stateful-tab/stateful-tab-type';
import { ReceivePaymentsPayer } from '@accounting/invoices/receive-payments/receive-payments.service';

export type InvoiceModuleTabState = InvoiceTabState | InvoicePaymentState;
export type InvoiceTabStateMap = Map<any, InvoiceModuleTabState>;

export type InvoiceTabSubType = 'payment' | 'invoice';

export class InvoiceTabState extends StatefulTabItem<InvoiceTabSubType> {
	constructor(invoiceId:  number) {
		super(
			RouteBuilderService.getInvoiceRoute(invoiceId),
			StatefulTabType.INVOICES_INVOICE_TAB,
			{headerText: `#${invoiceId}`, subType: 'invoice' as InvoiceTabSubType},
		);
	}
}

export interface InvoicePaymentStateOptions {
	nameFilter?: string;
	manuallySelect?: boolean;
	selectedPayer?: ReceivePaymentsPayer;
	receivePaymentState?: ReceivePaymentState;
}

export class InvoicePaymentState extends StatefulTabItem<InvoiceTabSubType> {
	payerType: PayerType;
	locationId: number;
	manuallySelect: boolean;
	nameFilter: string;
	selectedPayer: ReceivePaymentsPayer;
	receivePaymentState: ReceivePaymentState;

	constructor(
		paymentId: number,
		payerType: PayerType,
		locationId: number,
		options?: InvoicePaymentStateOptions,
	) {
		super(
			RouteBuilderService.getInvoicePaymentRoute(paymentId),
			StatefulTabType.INVOICES_PAYMENT_TAB,
			{headerText: 'New payment', subType: 'payment' as InvoiceTabSubType},
		);
		this.payerType = payerType;
		this.locationId = locationId;
		this.manuallySelect = _get(options, ['manuallySelect'], false);
		this.nameFilter = _get(options, ['nameFilter'], '');
		this.selectedPayer = _get(options, ['selectedPayer']);
		this.receivePaymentState = _get(options, ['receivePaymentState']);
	}
}

export const initialState: InvoiceTabStateMap = new Map();

export function reducer(state = initialState, action: InvoiceTabActions): InvoiceTabStateMap {
	switch (action.type) {
		case InvoiceTabActionTypes.ADD_INVOICE_TAB: {
			const invoiceTabStateMap = new Map(state);
			if (!invoiceTabStateMap.get(action.payload.invoiceId)) {
				invoiceTabStateMap.set(action.payload.invoiceId, new InvoiceTabState(action.payload.invoiceId));
				return invoiceTabStateMap;
			} else {
				return state;
			}
		}
		case InvoiceTabActionTypes.REMOVE_INVOICE_TAB: {
			const invoiceTabStateMap = new Map(state);
			invoiceTabStateMap.delete(action.payload.invoiceId);
			return invoiceTabStateMap;
		}
		case InvoiceTabActionTypes.UPDATE_INVOICE_TAB_CURRENT_ROUTE: {
			const invoiceTabStateMap = new Map(state);
			const invoiceTabState = invoiceTabStateMap.get(action.payload.invoiceId);
			invoiceTabState.currentRoute = action.payload.currentRoute;
			invoiceTabStateMap.set(action.payload.invoiceId, invoiceTabState);
			return invoiceTabStateMap;
		}
		// Payment Tabs
		case InvoiceTabActionTypes.ADD_PAYMENT_TAB: {
			const invoiceTabStateMap = new Map(state);
			const paymentId = action.payload.paymentId;
			if (!invoiceTabStateMap.get(paymentId)) {
				invoiceTabStateMap.set(paymentId, new InvoicePaymentState(
					paymentId,
					action.payload.payerType,
					action.payload.locationId,
				));
				return invoiceTabStateMap;
			} else {
				return state;
			}
		}
		case InvoiceTabActionTypes.UPDATE_PAYMENT_TAB_PAYER_SELECTION_STATE: {
			const invoiceTabStateMap = new Map(state);
			const currentTabState = invoiceTabStateMap.get(action.payload.paymentId) as InvoicePaymentState;
			if (currentTabState) {
				invoiceTabStateMap.set(action.payload.paymentId, {...currentTabState,
					payerType: action.payload.payerType,
					nameFilter: action.payload.nameFilter,
					manuallySelect: action.payload.manuallySelect,
					selectedPayer: action.payload.payer,
					receivePaymentState: action.payload.receivePaymentState,
				});
				return invoiceTabStateMap;
			}
			return state;
		}
		case InvoiceTabActionTypes.REMOVE_PAYMENT_TAB: {
			const invoiceTabStateMap = new Map(state);
			invoiceTabStateMap.delete(action.payload.paymentId);
			return invoiceTabStateMap;
		}
		case InvoiceTabActionTypes.UPDATE_PAYMENT_TAB_CURRENT_ROUTE: {
			const invoiceTabStateMap = new Map(state);
			const invoiceTabState = invoiceTabStateMap.get(action.payload.paymentId);
			invoiceTabState.currentRoute = action.payload.currentRoute;
			invoiceTabStateMap.set(action.payload.paymentId, invoiceTabState);
			return invoiceTabStateMap;
		}
		default: {
			return state;
		}
	}
}
