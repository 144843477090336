// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.license.AgreementDetailsResponse */
/* istanbul ignore next */
@GandalfModel
export class AgreementDetailsResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	baaSignByDate: Date;

	@GandalfProperty()
	baaSigned: boolean;

	@GandalfProperty({ propertyType: 'LocalDate' })
	licenseSignByDate: Date;

	@GandalfProperty()
	licenseSigned: boolean;

}
