// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { UserSummaryResponse } from './user-summary-response';

/** See com.rev360.pms.api.controller.inventory.reconciliation.ReconciliationReportResponse */
/* istanbul ignore next */
@GandalfModel
export class ReconciliationReportResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	countedActualCost: number;

	@GandalfProperty()
	countedItems: number;

	@GandalfProperty({ propertyType: 'Money' })
	countedValue: number;

	@GandalfProperty({ propertyType: 'Money' })
	countedWholesalePrice: number;

	@GandalfProperty()
	createdBy: UserSummaryResponse;

	@GandalfProperty()
	createdOn: Date;

	@GandalfProperty()
	finalizedBy: UserSummaryResponse;

	@GandalfProperty()
	finalizedOn: Date;

	@GandalfProperty()
	isReconciled: boolean;

	@GandalfProperty()
	name: string;

	@GandalfProperty({ propertyType: 'Money' })
	reconciledActualCost: number;

	@GandalfProperty()
	reconciledBy: UserSummaryResponse;

	@GandalfProperty()
	reconciledItems: number;

	@GandalfProperty()
	reconciledOn: Date;

	@GandalfProperty({ propertyType: 'Money' })
	reconciledValue: number;

	@GandalfProperty({ propertyType: 'Money' })
	reconciledWholesalePrice: number;

	@GandalfProperty({ propertyType: 'Money' })
	reportedActualCost: number;

	@GandalfProperty()
	reportedItems: number;

	@GandalfProperty({ propertyType: 'Money' })
	reportedValue: number;

	@GandalfProperty({ propertyType: 'Money' })
	reportedWholesalePrice: number;

}
