<div class="text-center">
	<img [src]="imgSrc">
	<div class="btn-toolbar mrgn-top" role="toolbar">
		<button
			ejs-button
			iconCss="fa fa-camera"
			type="button"
			(click)="openWebcamModal()"
			revTooltip
			tooltipContent="Capture image from webcam"
			[attr.data-test-id]="'photoFileTransferCameraCaptureButton'">
		</button>
		<button
			ejs-button
			iconCss="fa fa-upload"
			type="button"
			(click)="fileUpload()"
			revTooltip
			tooltipContent="Upload image (.jpg, .gif, .png)"
			[attr.data-test-id]="'photoFileTransferUploadButton'">
		</button>
		<button
			[disabled]="noImage()"
			ejs-button
			type="button"
			iconCss="fa fa-search-plus"
			revTooltip
			tooltipContent="Magnify image"
			(click)="preview()"
			[attr.data-test-id]="'photoFileTransferSearchButton'">
		</button>
		<button
			[disabled]="noImage()"
			ejs-button
			type="button"
			iconCss="fa fa-close"
			revTooltip
			tooltipContent="Remove image"
			(click)="(updatePracticeFileId(null))"
			[attr.data-test-id]="'photoFileTransferRemoveButton'">
		</button>
	</div>
</div>
