// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.ongoingcare.RefractiveCareResponse */
/* istanbul ignore next */
@GandalfModel
export class RefractiveCareResponse extends GandalfModelBase {

	@GandalfProperty()
	encounterId: number;

	@GandalfProperty()
	odAxis: number;

	@GandalfProperty()
	odCylinder: number;

	@GandalfProperty()
	odSphere: number;

	@GandalfProperty()
	odVisualAcuity: string;

	@GandalfProperty()
	osAxis: number;

	@GandalfProperty()
	osCylinder: number;

	@GandalfProperty()
	osSphere: number;

	@GandalfProperty()
	osVisualAcuity: string;

	@GandalfProperty()
	templateName: string;

	@GandalfProperty()
	testDateTime: Date;

	@GandalfProperty()
	testName: string;

}
