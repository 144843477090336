import { Pipe, PipeTransform } from '@angular/core';

/**
 * @ngdoc pipe
 * @description
 * Angular pipe for displaying a phone number in a standard format: (555) 555-1234
 *
 * @usage
 * {{ phoneNumber | phone }}
 */
@Pipe({
	name: 'phone',
})
export class PhonePipe implements PipeTransform {

	transform(value: any): any {
		let len: number;
		let val: string;

		if (value) {
			val = value.toString().replace(/\D/g, '');
			len = val.length;
			if (len < 4) {
				return val;
			} else if ((3 < len && len < 6)) {
				return '(' + (val.substring(0, 3)) + ')' + ' ' + (val.substring(3));
			} else if (len > 5) {
				return '(' + (val.substring(0, 3)) + ')' + ' ' + (val.substring(3, 6)) + '-' + (val.substring(6, 10));
			}
		}
		return value;
	}

}
