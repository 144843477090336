// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.shared.person.PhoneNumbersResponse */
/* istanbul ignore next */
@GandalfModel
export class PhoneNumbersResponse extends GandalfModelBase {

	@GandalfProperty()
	cellPhone: string;

	@GandalfProperty()
	cellPhoneExt: string;

	@GandalfProperty()
	fax: string;

	@GandalfProperty()
	homePhone: string;

	@GandalfProperty()
	homePhoneExt: string;

	@GandalfProperty()
	pager: string;

	@GandalfProperty()
	preferredPhoneNumber: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	preferredPhoneType: constants.PreferredPhoneType;

	@GandalfProperty()
	workPhone: string;

	@GandalfProperty()
	workPhoneExt: string;

}
