import { Action } from '@ngrx/store';

export enum HierarchiesActionTypes {
	ADD_STATEFUL_COMPONENT_CHILD = '[Add Stateful Component Child] Adds a stateful component child.',
	REMOVE_HIERARCHY_STATE = '[Remove Hierarchy State] Remove the hierarchy state from the store',
}

export class AddStatefulComponentChild implements Action {
	readonly type = HierarchiesActionTypes.ADD_STATEFUL_COMPONENT_CHILD;
	constructor(public payload: {key: string, childKey: string}) {}
}

export class RemoveHierarchyState implements Action {
	readonly type = HierarchiesActionTypes.REMOVE_HIERARCHY_STATE;
	constructor(public payload: {key: string}) {}
}

export type HierarchiesActions =
	AddStatefulComponentChild
	| RemoveHierarchyState;
