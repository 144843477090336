import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { CalendarUtilsService } from '@core/calendar-utils/calendar-utils.service';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { _isEmpty, _isNil } from '@core/lodash/lodash';
import { DynamicModalRef, ModalConfig, PAGE_LENGTH, PAGE_LENGTH_LIST } from 'morgana';
import { UserLocationsService } from '@core/user-locations/user-locations.service';
import { PatientSearchStatus } from '@gandalf/constants';
import { PatientSearchRequest } from '@gandalf/model/patient-search-request';
import { FormattedPatientSearchResponse, SearchPatientService } from '@shared/component/search-patient/search-patient.service';
import { DATE_FORMATS, TABLE_DATE_FORMATS } from '@shared/constants/date-format.constants';
import { atLeastOne } from '@shared/validators/atleastOne.validation';
import { RowSelectEventArgs, SortSettingsModel } from '@syncfusion/ej2-angular-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';
import { OptionItemResponse } from '@core/option-item/option-item.service';
import { PracticeLocation } from '@core/security-manager/security-manager.service';

export interface PatientSearchReturnObject {
	addFamilyMember?: boolean;
	patient: FormattedPatientSearchResponse;
}

@Component({
	selector: 'pms-patient-search-modal',
	templateUrl: './patient-search-modal.component.html',
	styles: [],
})
export class PatientSearchModalComponent implements OnInit {
	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('lastName')
	lastNameField: ElementRef;

	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	dateMax: Date;
	locations: OptionItemResponse<PracticeLocation, number>[];
	patientSearchStatus = PatientSearchStatus.VALUES.values;
	isSearching = false;
	componentForm: UntypedFormGroup;
	patientSearchRequest: PatientSearchRequest;
	sortOptions: SortSettingsModel;
	dobFormatter = TABLE_DATE_FORMATS.MM_DD_YYYY;
	canAddFamilyMember = false;
	patientName: string;
	activeOnly: boolean;
	patientData: FormattedPatientSearchResponse[];
	pageSettings = {
		pageSizes: PAGE_LENGTH_LIST,
		pageSize: PAGE_LENGTH.PAGE_10,
	};

	constructor(
		public dynamicModalRef: DynamicModalRef,
		private modalConfig: ModalConfig,
		public searchPatientService: SearchPatientService,
		private gandalfFormBuilder: GandalfFormBuilder,
		private calendarUtilsService: CalendarUtilsService,
		private userLocationsService: UserLocationsService,
	) {
	}

	ngOnInit() {
		this.parseModalConfig(this.modalConfig);
		this.dateMax = this.calendarUtilsService.getMaxDateForNavigator();
		this.locations = this.userLocationsService.getUserLocations();

		this.sortOptions = {
			columns: [
				{field: 'formattedName', direction: 'Ascending'},
			],
		};
		this.createForm();
		this.patientData = [];
	}

	parseModalConfig(modalConfig: ModalConfig){
		this.canAddFamilyMember = !!modalConfig.data.canAddFamilyMember;
		this.activeOnly = !!modalConfig.data.activeOnly;
		this.patientName = modalConfig.data.patientName;
	}

	onGridCreated() {
		if (!_isNil(this.componentForm.get('lastName').value) || !_isNil(this.componentForm.get('firstName').value)) {
			this.search();
		}
	}

	createForm() {
		this.patientSearchRequest = new PatientSearchRequest();
		//This is especially important if activeOnly is true. If you are changing this be sure to set it to Active if activeOnly is true.
		this.patientSearchRequest.patientSearchStatus = PatientSearchStatus.ACTIVE;

		let firstName = null;
		let lastName = null;
		if (this.patientName) {
			const splitString = this.patientName.split(',');
			lastName = splitString[0].trim();
			firstName = splitString.length > 1 ? splitString[1].trim() : null;
		}
		this.patientSearchRequest.lastName = lastName ? lastName.trim() : null;
		this.patientSearchRequest.firstName = firstName ? firstName.trim() : null;
		this.componentForm = this.gandalfFormBuilder.group(this.patientSearchRequest, {
			validators: [
				atLeastOne(
					Validators.required,
					this.searchPatientService.getAtLeastOneFields(),
					this.searchPatientService.getAtLeastOneMessage(),
				)],
		});
		this.patientSearchRequest.lastName = null;
		this.patientSearchRequest.firstName = null;
	}

	clear(templateForm: FormGroupDirective) {
		templateForm.resetForm(this.patientSearchRequest);
		this.lastNameField.nativeElement.focus();
		this.patientData = [];
	}

	search() {
		if (this.componentForm.invalid) {
			FormUtilsService.markFormControlsDirty(this.componentForm);
			return;
		}
		this.isSearching = true;
		this.searchPatientService.searchPatients(this.componentForm.value).subscribe(data => {
			this.isSearching = false;
			this.patientData = data;
		});
	}

	closeModal(selectEvent?: RowSelectEventArgs, addFamilyMember = false) {
		const returnObject = selectEvent ? {
			patient: selectEvent.data as FormattedPatientSearchResponse,
			addFamilyMember,
		} : null;

		this.dynamicModalRef.close(this.modal, returnObject ? returnObject : null);
	}

	addFamilyMember(event: MouseEvent, patient: FormattedPatientSearchResponse) {
		this.closeModal({
			data: patient,
		}, true);
	}

	nothingIsFilled() {
		if (_isNil(this.componentForm)) {
			return true;
		}

		return !!(_isEmpty(this.componentForm.get('firstName').value) &&
			_isEmpty(this.componentForm.get('lastName').value) &&
			_isEmpty(this.componentForm.get('phoneNumber').value) &&
			_isEmpty(this.componentForm.get('socialSecurityNumber').value) &&
			_isEmpty(this.componentForm.get('socialInsuranceNumber').value) &&
			_isEmpty(this.componentForm.get('patientId').value) &&
			_isNil(this.componentForm.get('dateOfBirth').value));
	}

	isSearchDisabled() {
		if (this.nothingIsFilled() || this.isSearching) {
			return true;
		}

		// disable searching if first or last name are less than 2 characters (only if not empty)
		if (!_isEmpty(this.componentForm.get('firstName').value) && this.componentForm.get('firstName').value.length < 2) {
			return true;
		}

		if (!_isEmpty(this.componentForm.get('lastName').value) && this.componentForm.get('lastName').value.length < 2) {
			return true;
		}

		return false;
	}
}
