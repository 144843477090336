// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { RefundInsuranceResponse } from './refund-insurance-response';

/** See com.rev360.pms.api.controller.admin.vendorsandpartners.insurancecompanies.AdminInsuranceRefundResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminInsuranceRefundResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	credit: number;

	@GandalfArray(RefundInsuranceResponse)
	insuranceCompanyRefunds: RefundInsuranceResponse[];

}
