import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouterParam } from '@app-store/selectors';
import { canMatchAuthGuard } from '@core/authentication/auth.guard';
import { InvoicePaymentTabComponent } from './invoice-payment-tab/invoice-payment-tab.component';
import { InvoiceTabComponent } from './invoice-tab/invoice-tab.component';
import { InvoicesDashboardComponent } from './invoices-dashboard/invoices-dashboard.component';
import { InvoicesComponent } from './invoices.component';

const routes: Routes = [
	{
		path: '',
		component: InvoicesComponent,
		// Extra auth guard check since this module is imported at the app root level which makes
		// it visible at the root app level before logging in.
		canMatch: [canMatchAuthGuard],
		children: [
			{
				path: 'dashboard',
				component: InvoicesDashboardComponent,
			},
			{
				path: `invoice/:${RouterParam.INVOICES_INVOICE_ID}`,
				component: InvoiceTabComponent,
			},
			{
				path: `payment/:${RouterParam.INVOICES_INVOICE_PAYMENT_ID}`,
				component: InvoicePaymentTabComponent,
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class InvoicesRoutingModule { }
