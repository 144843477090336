<ejs-dialog
    #modal
    (close)="closeModal()"
    cssClass="modal-md"
    [attr.data-test-id]="'patientFolderModal'">
    <ng-template #header>
        <div class="dlg-template">Select Folder</div>
    </ng-template>
    <ng-template #content>
        <form class="form-horizontal">
            <div class="form-group">
                <label class="col-sm-3 control-label required">Save To</label>
                <div class="col-sm-9">
                    <div class="panel panel-default h350 overflow-auto">
                        <ejs-treeview (nodeSelected)="selectValue($event)"
                                      [fields]="treeFields">
                        </ejs-treeview>
                    </div>
                </div>
            </div>
        </form>
    </ng-template>

    <ng-template #footerTemplate>
        <button ejs-button type="button" (click)="closeModal()" iconCss="fa fa-close" [attr.data-test-id]="'patientFolderModalCancelButton'">Cancel</button>
        <button ejs-button type="button" (click)="submit()" [isPrimary]="true" [attr.data-test-id]="'patientFolderModalDrawButton'">{{submitText}}</button>
    </ng-template>

</ejs-dialog>
