import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	HostListener,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { _isNil } from '@core/lodash/lodash';
import { ForceDecimalPipe } from 'morgana';

@Component({
	selector: 'pms-always-set-currency-input',
	templateUrl: './always-set-currency-input.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AlwaysSetCurrencyInputComponent),
			multi: true,
		},
	],
})
export class AlwaysSetCurrencyInputComponent implements ControlValueAccessor, OnChanges {

	@ViewChild('inputElement', {static: true})
	inputElement: ElementRef;

	@Input()
	htmlId: string;

	@Input()
	min: number;

	@Input()
	focus: boolean;

	@Output()
	valueChange = new EventEmitter();

	@Output()
	inputBlur = new EventEmitter();

	currencyString = '0.00';

	disabled = false;

	constructor(
		private forceDecimal: ForceDecimalPipe,
		private changeDetectorRef: ChangeDetectorRef,
	) {
	}

	onModelChange: (_: any) => void = () => {
	};

	onModelTouched: () => void = () => {
	};

	@HostListener('mousewheel', ['$event'])
	onMouseWheelChrome(event: any) {
		this.disableScroll(event);
	}

	@HostListener('DOMMouseScroll', ['$event'])
	onMouseWheelFirefox(event: any) {
		this.disableScroll(event);
	}

	@HostListener('onmousewheel', ['$event'])
	onMouseWheelIE(event: any) {
		this.disableScroll(event);
	}

	ngOnChanges(simpleChange: SimpleChanges) {
		if (simpleChange?.focus && simpleChange.focus.currentValue) {
			this.highlight();
		}
	}

	disableScroll(event) {
		if (event.target.type === 'number') {
			event.preventDefault();
		}
	}

	highlight() {
		this.inputElement.nativeElement.select();
	}

	writeValue(value: number): void {
		if (!_isNil(this.min) && value < this.min) {
			this.writeValue(this.min);
		} else {
			this.currencyString = !_isNil(value) ? value.toString() : '0.00';
			if (this.currencyString) {
				this.formatCost();
			}
			this.changeDetectorRef.markForCheck();
		}
	}

	registerOnChange(fn: (_: any) => void): void {
		this.onModelChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onModelTouched = fn;
	}

	onblur() {
		this.formatCost();
		this.inputBlur.emit();
	}

	formatCost() {
		const valueNumber = parseFloat(this.currencyString);
		if (!_isNil(this.min) && valueNumber < this.min) {
			this.writeValue(this.min);
		}
		this.currencyString = this.forceDecimal.transform(this.currencyString, 2, '0.00');
		this.onModelChange(parseFloat(this.currencyString));
		this.valueChange.emit();
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	setFocus() {
		this.inputElement.nativeElement.focus();
	}
}
