import { AccountingService } from '@accounting/core/accounting/accounting.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { CreditCardType, PaymentMethodCreditCardType, PaymentMethodType } from '@gandalf/constants';
import { AccountingUpdatePaymentGroupRequest } from '@gandalf/model/accounting-update-payment-group-request';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { EnumUtil, ModalBase } from 'morgana';
import { GandalfConstant, GandalfConstantList, GandalfFormBuilder } from 'gandalf';
import { ToasterService } from '@core/toaster/toaster.service';
import { _filter } from '@core/lodash/lodash';

export interface EditPaymentModalData {
	paymentGroupId: number;
	paymentMethodType: PaymentMethodType;
	referenceNumber: string;
	paymentDate: Date;
	paymentMethodCreditCardType: PaymentMethodCreditCardType;
}

@Component({
	selector: 'pms-edit-payment-modal',
	templateUrl: './edit-payment-modal.component.html',
	styles: [],
})
export class EditPaymentModalComponent extends ModalBase<EditPaymentModalData, EditPaymentModalData> implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('templateForm')
	templateForm: FormGroupDirective;

	formGroup: UntypedFormGroup;
	paymentMethods: GandalfConstantList<PaymentMethodType>;
	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	creditCardTypes: GandalfConstantList<CreditCardType>;
	request: AccountingUpdatePaymentGroupRequest;
	paymentMethodIsCredit: boolean;
	constructor(
		private accountingService: AccountingService,
		private toasterService: ToasterService,
		private gandalfFormBuilder: GandalfFormBuilder,
	){
		super();
	}

	ngOnInit(): void {
		this.initFormGroup();
		this.setupPaymentsAndCreditCardsValues();
	}

	initFormGroup() {
		this.request = new AccountingUpdatePaymentGroupRequest();
		this.request.paymentGroupId = this.data.paymentGroupId;
		this.request.paymentMethodType = this.data.paymentMethodType;
		this.request.referenceNumber = this.data.referenceNumber;
		this.request.paymentDate = this.data.paymentDate;
		this.request.paymentMethodCreditCardType = this.data.paymentMethodCreditCardType;
		this.formGroup = this.gandalfFormBuilder.group(this.request);
		this.paymentMethodIsCredit = EnumUtil.equals(this.data.paymentMethodType, PaymentMethodType.CREDIT);
	}

	setupPaymentsAndCreditCardsValues() {
		this.accountingService.findPaymentPreferences().subscribe(accountingPaymentPreferencesResponse => {
			const changeablePaymentMethods: GandalfConstantList<PaymentMethodType> = {
				label: PaymentMethodType.VALUES.label,
				values: _filter(PaymentMethodType.VALUES.values, value => !EnumUtil.equals(value, PaymentMethodType.CREDIT)),
			};
			this.paymentMethods = this.buildOrderedGandalfSubsetList(changeablePaymentMethods, accountingPaymentPreferencesResponse.paymentMethods);
			this.creditCardTypes = this.buildOrderedGandalfSubsetList(CreditCardType.VALUES, accountingPaymentPreferencesResponse.creditCardTypes);
		});
	}

	/* istanbul ignore next */
	buildOrderedGandalfSubsetList<T extends GandalfConstant<any>>(constantList: GandalfConstantList<T>, expectedValues: T[]): GandalfConstantList<T> {
		return EnumUtil.buildGandalfEnumSubsetList(EnumUtil.enumOrderedSubset([...constantList.values], expectedValues), constantList.label);
	}

	/* istanbul ignore next */
	closeModal(result?: EditPaymentModalData) {
		this.dynamicModalRef.close(this.modal, result);
	}

	save() {
		if (this.formGroup.invalid) {
			return;
		} else {
			this.request  = this.formGroup.getRawValue() as AccountingUpdatePaymentGroupRequest;
			this.accountingService.updatePaymentGroup(this.request).subscribe(() => {
				this.toasterService.showSavedSuccess();
				this.closeModal(this.request);
			});
		}
	}

	/* istanbul ignore next */
	submitForm(event) {
		this.templateForm.onSubmit(event);
	}

	isCreditCardPaymentMethod() {
		return EnumUtil.equals(this.formGroup.get('paymentMethodType').value, PaymentMethodType.CREDIT_CARD);
	}

	showPaymentMethodDropdown() {
		return !!(this.paymentMethods && !this.paymentMethodIsCredit);
	}

	setCreditCardType() {
		if(!this.isCreditCardPaymentMethod()) {
			this.formGroup.get('paymentMethodCreditCardType').setValue(null);
		}
	}
}
