// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CarePlanItemResponse } from './care-plan-item-response';

/** See com.rev360.pms.api.controller.encounter.plan.PersonDiagnosisWithCarePlansResponse */
/* istanbul ignore next */
@GandalfModel
export class PersonDiagnosisWithCarePlansResponse extends GandalfModelBase {

	@GandalfArray(CarePlanItemResponse)
	carePlanItems: CarePlanItemResponse[];

	@GandalfProperty()
	code: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	codeSet: constants.CodeSet;

	@GandalfProperty({ propertyType: 'LocalDate' })
	diagnosisDate: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	disabledDate: Date;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	isPrimary: boolean;

	@GandalfProperty()
	shortDescription: string;

	@GandalfProperty()
	showDisabledWarning: boolean;

}
