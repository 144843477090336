<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-lg"
	[attr.data-test-id]="'receivePaymentModal'">
	<ng-template #header>
		<div class="dlg-template">Payment Items</div>
	</ng-template>
	<ng-template #content>
		<form class="form-horizontal"
			  [formGroup]="formGroup"
			  #templateForm="ngForm">
			<gandalf-lib-validation-messages
					*ngIf="formGroup.invalid && templateForm.submitted"
					[form]="formGroup"
					[validateSubArrays]="false"
					[requestObj]="request"
					[attr.data-test-id]="'receivePaymentModalValidationMessages'"></gandalf-lib-validation-messages>
			<h4 class="section-title bg-default mrgn-btm">
				Invoice #{{invoiceId}} for <strong>{{patientInfo?.patientNameResponse | patientName}}</strong>
				<div class="alert alert-success display-inline-block text-nowrap padding-sm margin-bottom-0 margin-left-sm" *ngIf="patientCredit > 0">{{patientCredit | currency}} Available Credit</div>
			</h4>
			<div class="margin-bottom-sm">
				<div *ngIf="showTable && !readOnly">
					<ng-container *ngIf="canTransferOrWriteoff(); else noOptions">
						<div class="form-group">
							<label class="col-sm-2 control-label">{{ transferTypeText() }}</label>
							<div class="col-sm-10">
								<div class="form-inline">
									<span class="display-inline-block margin-right-md vertical-align-middle" *ngIf="canTransferAndWriteoff()">
										<pms-enum-select-button
												formControlName="transferType"
												[enumList]="transferTypes"
												[allOption]="false">
										</pms-enum-select-button>
									</span>
									<span class="display-inline-block margin-right-sm">
										<ejs-dropdownlist id="transferReason"
														  *ngIf="showTransferReasonsDropdown"
														  [dataSource]="transferReasons"
														  formControlName="transferReferenceId"
														  placeholder="Select Transfer Reason"
														  [width]="400"
														  [showClearButton]="true">
										</ejs-dropdownlist>
										<ejs-dropdownlist id="writeoffReason"
														  *ngIf="showWriteoffReasonsDropdown"
														  [dataSource]="writeoffReasons"
														  formControlName="writeoffReferenceId"
														  placeholder="Select Write-off Reason"
														  [width]="400"
														  [showClearButton]="true">
										</ejs-dropdownlist>
									</span>
									<span class="display-inline-block" *ngIf="useItemTransfersOnPayments && showIncludeAllItems()">
										<ejs-checkbox #checkbox label="Include All Items in Transfer" name="includeAllItems" formControlName="includeAllItems"></ejs-checkbox>
									</span>
								</div>
							</div>
						</div>
						<div class="form-group" *ngIf="showTransferReasonsDropdown">
							<label class="col-sm-2 control-label">{{ transferToText }}</label>
							<div class="col-sm-10">
								<div class="form-inline">
									<span class="display-inline-block margin-right-md vertical-align-middle" *ngIf="!removeTransferToInsuranceOption && !removeTransferToPatientOption">
										<pms-enum-select-button
												formControlName="payerType"
												[enumList]="payerTypes"
												(click)="updatePersonInsuranceDropdown()"
												[allOption]="false">
										</pms-enum-select-button>
									</span>
									<span class="display-inline-block" *ngIf="!showPayerInvoiceOptions">
										<ejs-dropdownlist id="insurance"
														  *ngIf="personInsuranceList"
														  [dataSource]="personInsuranceList"
														  formControlName="personInsuranceId"
														  placeholder="Select Insurance"
														  [width]="400"
														  [showClearButton]="true">
										</ejs-dropdownlist>
									</span>
									<ng-container *ngIf="showPayerInvoiceOptions">
										<span class="display-inline-block margin-x-xs margin-right-sm">
											<ejs-radiobutton label="New Invoice" name="transferToNewOrExistingInvoice"
															 [value]="TRANSFER_TO_NEW_INVOICE_LABEL"
															 formControlName="transferToNewOrExistingInvoice">
											</ejs-radiobutton>
										</span>
										<span class="display-inline-block margin-x-xs margin-right-sm" *ngIf="showExistingInvoiceOption">
											<ejs-radiobutton (change)="retainInvoiceDropdownInvoiceId()" label="Existing Invoice"
															 name="transferToNewOrExistingInvoice" [value]="TRANSFER_TO_EXISTING_INVOICE_LABEL"
															 formControlName="transferToNewOrExistingInvoice">
											</ejs-radiobutton>
										</span>
										<span class="display-inline-block margin-left-sm" *ngIf="showExistingInvoiceDropdown">
											<ejs-dropdownlist id="invoices" #invoiceDropdown
															  [dataSource]="transferToInvoiceList"
															  formControlName="transferToExistingInvoiceId"
															  placeholder="Select Invoice"
															  [width]="275">
											</ejs-dropdownlist>
										</span>
									</ng-container>
								</div>
							</div>
						</div>
						<div class="form-group" *ngIf="!useItemTransfersOnPayments">
							<label class="col-sm-2 control-label">Amount</label>
							<div class="col-sm-10">
								<div class="form-inline">
									<span class="display-inline-block">
										<pms-always-set-currency-input formControlName="transferAmount" [min]="0"></pms-always-set-currency-input>
									</span>
								</div>
							</div>
						</div>
						<div class="form-group">
							<div class="col-sm-10 col-sm-offset-2">
								<button ejs-button
										cssClass="e-success"
										type="button"
										iconCss="fa fa-plus"
										(keydown.tab)="onTabKeydown($event)"
										(click)="addTransfer($event)"
										[attr.data-test-id]="'AddTransferWriteOffButton'">
									Add Transfer / Write-off
								</button>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
			<div class="margin-bottom-sm" *ngIf="showTable && useItemTransfersOnPayments">

				<ag-grid-angular class="ag-theme-alpine"
								 pmsAgAutoFitColumns
								 #paymentItemGrid
								 *ngIf="isAgGridFeatureFlagOn"
								 [domLayout]="'autoHeight'"
								 [gridOptions]="paymentGridOptions"
								 [rowData]="invoiceItems"
								 (gridReady)="onPaymentItemGridReady($event)"
								 (cellFocused)="onCellFocus($event)"
								 (cellMouseDown)="onCellClick($event)"
								 [pagination]="false"
								 [attr.data-test-id]="'receivePaymentModalAgGrid'">
				</ag-grid-angular>

				<ejs-grid #grid
						  *ngIf="!isAgGridFeatureFlagOn"
						  pmsInputSpecialKeyHandlerDirective
						  #tabDirective="InputSpecialKeyHandlerDirective"
						  [pmsGridSubmit]="isSearching"
						  [dataSource]="invoiceItems"
						  (queryCellInfo)="setTooltip($event)"
						  [staticColumns]="staticColumns"
						  autoFitColumns
						  [autoFitBlacklist]="['invoiceItem.description']"
						  [allowPaging]="false"
						  [allowSelection]="false"
						  [allowSorting]="false"
						  [allowResizing]="true"
						  gridLines="Horizontal"
						  [attr.data-test-id]="'receivePaymentModalGrid'">
					<e-columns>
						<e-column field="invoiceItem.id" headerText="ID" [visible]="false" [isPrimaryKey]="true"></e-column>
						<e-column field="invoiceItem.code" headerText="Code"></e-column>
						<e-column clipMode="EllipsisWithTooltip" field="invoiceItem.description" headerText="Description"
								  [customAttributes]="{ autofitMinWidth: 50 }"></e-column>
						<e-column width="63" field="invoiceItem.quantity" headerText="Qty"></e-column>
						<e-column headerText="Unit Price" field="invoiceItem.unitPrice" format="C2"></e-column>
						<e-column headerText="Sub-Total" field="subtotal" format="C2"></e-column>
						<e-column headerText="Discounts" field="totalDiscount" format="C2"></e-column>
						<e-column headerText="Tax" field="tax" format="C2"></e-column>
						<e-column headerText="Ext. Price" field="extPrice" format="C2"></e-column>
						<e-column width="150" headerText="Payment" format="C2" type="number" field="paymentAmount" *ngIf="showPaymentColumn">
							<ng-template #template let-data>
								<pms-always-set-currency-input
										[formControl]="getInvoiceItemControl('paymentAmount', data.invoiceItem.id)">
								</pms-always-set-currency-input>
							</ng-template>
						</e-column>
						<!-- Multiple Columns for transfer amounts -->
						<e-column width="150" headerText="Transfer {{i+1}}" format="C2" type="number" field="transferAmount{{transfer.id}}"
								  *ngFor="let transfer of transfers; index as i">
							<ng-template #template let-data>
								<pms-always-set-currency-input
										[formControl]="getInvoiceItemControl('transferAmount'+ transfer.id, data.invoiceItem.id)">
								</pms-always-set-currency-input>
							</ng-template>
						</e-column>
						<e-column [visible]="!readOnly" width="120" headerText="Adjustments" type="number" field="adjustmentTotal" format="C2">
							<ng-template #template let-data>
								<div>{{getItemByItem(data.invoiceItem.id).adjustmentTotal | currency}}</div>
							</ng-template>
						</e-column>
						<e-column [visible]="!readOnly" width="100" headerText="Paid" type="number" field="paymentTotalAmount" format="C2">
							<ng-template #template let-data>
								<div>{{getItemByItem(data.invoiceItem.id).paymentTotalAmount | currency}}</div>
							</ng-template>
						</e-column>
						<e-column [visible]="!readOnly" width="100" headerText="Balance" type="number" field="balance" textAlign="Right">
							<ng-template #template let-data>
								<div [class.text-danger]="getItemByItem(data.invoiceItem.id).balance < 0"> {{ getItemByItem(data.invoiceItem.id).balance | currency }}</div>
							</ng-template>
						</e-column>
					</e-columns>
					<e-aggregates>
						<e-aggregate>
							<e-columns>
								<e-column field="paymentAmount" type="sum" format="C2">
									<ng-template #footerTemplate let-data>
										{{paymentAmountSum | currency}}
									</ng-template>
								</e-column>
								<e-column field="adjustmentTotal" type="sum" format="C2">
									<ng-template #footerTemplate let-data>
										{{adjustmentTotalSum | currency}}
									</ng-template>
								</e-column>
								<e-column field="paymentTotalAmount" type="sum" format="C2">
									<ng-template #footerTemplate let-data>
										{{paymentTotalAmountSum | currency}}
									</ng-template>
								</e-column>
								<e-column field="{{'transferAmount'+transfer.id}}" type="sum" format="C2" *ngFor="let transfer of transfers">
									<ng-template #footerTemplate let-data>
										{{ getSum('transferAmount' + transfer.id) | currency}}
									</ng-template>
								</e-column>
								<e-column field="balance" type="sum" format="C2">
									<ng-template #footerTemplate let-data>
										{{balanceSum | currency}}
									</ng-template>
								</e-column>
							</e-columns>
						</e-aggregate>
					</e-aggregates>
				</ejs-grid>
			</div>
			<div class="row">
				<div class="col-sm-12 col-md-8 col-lg-9">
					<div class="margin-bottom-sm">
						<div class="margin-bottom-sm">
							<ag-grid-angular class="ag-theme-alpine"
											 *ngIf="isAgGridFeatureFlagOn"
											 pmsAgAutoFitColumns
											 #transferWriteOffGrid
											 [domLayout]="'autoHeight'"
											 [gridOptions]="transferGridOptions"
											 [rowData]="transfers"
											 (gridReady)="onTransferItemGridReady($event)"
											 [pagination]="false"
											 [attr.data-test-id]="'receivePaymentModalTransferWriteOffAgGrid'">
							</ag-grid-angular>

							<ejs-grid #transferWriteOffGrid
									  *ngIf="!isAgGridFeatureFlagOn"
									  [dataSource]="transfers"
									  [allowPaging]="false"
									  [allowSelection]="false"
									  [allowResizing]="true"
									  gridLines="Horizontal"
									  allowPaging="true"
									  [attr.data-test-id]="'receivePaymentModalTransferWriteOffGrid'">
								<e-columns>
									<e-column width="27" headerText="#" field="index">
										<ng-template #template let-data>
											{{ getTransferNumber(data) }}
										</ng-template>
									</e-column>
									<e-column width="120" headerText="Type" field="transferRequest.transferType">
										<ng-template #template let-data>
											{{ getTypeLabel(data.transferRequest)}}
										</ng-template>
									</e-column>
									<e-column width="100" headerText="Reason" field="transferRequest.reason" clipMode="EllipsisWithTooltip">
										<ng-template #template let-data>
											{{ getReason(data.transferRequest) }}
										</ng-template>
									</e-column>
									<e-column width="100" headerText="To" field="transferRequest.transferTo" clipMode="EllipsisWithTooltip">
										<ng-template #template let-data>
											{{ getTransferTo(data.transferRequest) }}
										</ng-template>
									</e-column>
									<e-column width="80" headerText="All Items" field="transferRequest.includeAllItems" [visible]="useItemTransfersOnPayments">
										<ng-template #template let-data>
											{{ data.transferRequest.includeAllItems | yesNo }}
										</ng-template>
									</e-column>
									<e-column headerText="Amount" field="transferRequest.transferAmount" format="C2"
											  [visible]="!useItemTransfersOnPayments"></e-column>
									<e-column width="50" headerText="" field="remove">
										<ng-template #template let-data>
											<rev-button
													*ngIf="!readOnly"
													buttonIcon="minus"
													buttonStyle="danger"
													buttonTooltip="Remove this transfer/write-off"
													revEventStopPropagation
													(buttonClick)="removeTransfer(data.index, data.transferRequest)"
													dataTestId="transferModalRemoveButton">
											</rev-button>
										</ng-template>
									</e-column>
								</e-columns>
							</ejs-grid>
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-4 col-lg-3">
					<table class="table table-bordered table-condensed text-right margin-bottom-0">
						<tbody>
						<tr class="bg-default text-uppercase" *ngIf="!readOnly">
							<td>Current Balance</td>
							<td [attr.data-test-id]="'receivePaymentTotalCurrentBalanceLabel'">{{ totalCurrentBalance | currency}}</td>
						</tr>
						<tr>
							<td>Invoice Paid</td>
							<td [attr.data-test-id]="'receivePaymentInvoicePaymentAmountLabel'">{{ invoicePaymentAmount | currency }}</td>
						</tr>
						<tr *ngIf="showPaymentColumn">
							<td>Items Paid</td>
							<td [attr.data-test-id]="'receivePaymentPaymentTotalLabel'">{{ itemPaymentTotal | currency }}</td>
						</tr>
						<tr>
							<td>Amount Transferred</td>
							<td [attr.data-test-id]="'receivePaymentTransferTotalLabel'">{{ itemTransfersTotal | currency }}</td>
						</tr>
						</tbody>
						<tfoot *ngIf="!readOnly">
						<tr class="bg-info text-uppercase">
							<td><strong>After Payments and Transfers</strong></td>
							<td [attr.data-test-id]="'receivePaymentTotalBalanceAfterLabel'"><strong>{{ totalBalanceAfterTransferAndPayment | currency }}</strong></td>
						</tr>
						</tfoot>
					</table>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button cssClass="pull-left margin-left-0" type="button" (click)="autoPay()" *ngIf="useItemTransfersOnPayments && !readOnly" [attr.data-test-id]="'receivePaymentModalAutoPayButton'">AutoPay</button>
		<button ejs-button iconCss="fa fa-times" type="button" (click)="closeModal()" [attr.data-test-id]="'receivePaymentModalCancelButton'">Cancel</button>
		<button ejs-button [isPrimary]="true" type="submit" (click)="closeModal(true)" *ngIf="!readOnly" [attr.data-test-id]="'receivePaymentModalSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>
<ng-template #noOptions>
	You do not have permission to write-off items associated with this invoice. Contact your administrator to get permission.
</ng-template>

<ng-template #adjustmentTooltip let-data>
	<div class="rev-custom-tooltip-content" [attr.data-test-id]="'receivePaymentModalAdjustmentTooltip'" *ngIf="filterAdjustments(data?.invoiceItemDetails?.itemAdjustments, false)?.length && filterAdjustments(data?.invoiceItemDetails?.itemAdjustments, false).length !== 0">
		<table class="table table-bordered table-condensed margin-bottom-0">
			<thead>
			<tr>
				<th>Adjustment</th>
				<th>Details</th>
				<th class="text-right">Amount</th>
			</tr>
			</thead>
			<tbody>
			<tr *ngFor="let adjustment of sortBy(filterAdjustments(data?.invoiceItemDetails?.itemAdjustments, false), 'id')">
				<td>{{adjustment.type}}</td>
				<td>{{adjustment.details}}</td>
				<td class="text-right">{{adjustment.amount | currency}}</td>
			</tr>
			</tbody>
		</table>
	</div>
</ng-template>

<ng-template #totalDiscountTooltip let-data>
	<div class="rev-custom-tooltip-content" [attr.data-test-id]="'receivePaymentModalTotalDiscountTooltip'" *ngIf="filterAdjustments(data?.invoiceItemDetails?.itemAdjustments, true)?.length && filterAdjustments(data?.invoiceItemDetails?.itemAdjustments, true).length !== 0">
		<table class="table table-bordered table-condensed margin-bottom-0">
			<thead>
			<tr>
				<th>Adjustment</th>
				<th>Details</th>
				<th class="text-right">Amount</th>
			</tr>
			</thead>
			<tbody>
			<tr *ngFor="let adjustment of sortBy(filterAdjustments(data?.invoiceItemDetails?.itemAdjustments, true), 'id')">
				<td>{{adjustment.type}}</td>
				<td>{{adjustment.details}}</td>
				<td class="text-right">{{adjustment.amount | currency}}</td>
			</tr>
			</tbody>
		</table>
	</div>
</ng-template>

<ng-template #taxTooltip let-data>
	<div class="rev-custom-tooltip-content" [attr.data-test-id]="'receivePaymentModalTaxTooltip'" *ngIf="data?.invoiceItemDetails?.itemTaxes?.length &&  data?.invoiceItemDetails?.itemTaxes?.length !== 0">
		<table class="table table-bordered table-condensed margin-bottom-0">
			<thead>
			<tr>
				<th>Tax</th>
				<th class="text-right">Amount</th>
			</tr>
			</thead>
			<tbody>
			<tr *ngFor="let tax of sortBy(data?.invoiceItemDetails?.itemTaxes, 'taxId')">
				<td>{{tax.taxName}}</td>
				<td class="text-right">{{tax.taxAmount | currency}}</td>
			</tr>
			</tbody>
		</table>
	</div>
</ng-template>

<ng-template #transferAmountColumn let-data let-column="params.column">
	<ng-container *ngIf="getInvoiceItemControl(column.getColId(), data?.invoiceItem?.id)">
		<pms-always-set-currency-input
				*ngIf="!isAggregateRow(data)"
				[revFocusInput]="isCellSelected(data?.invoiceItem?.id, column.getColId())"
				[highlightInputOnFocus]="true"
                (inputBlur)="onInputBlur()"
				[formControl]="getInvoiceItemControl(column.getColId(), data.invoiceItem.id)">
		</pms-always-set-currency-input>
	</ng-container>

	<span *ngIf="isAggregateRow(data)">{{getTransferAmountValue(column.getColId())}}</span>
</ng-template>

<ng-template #paymentAmountColumn let-data>
	<ng-container *ngIf="getInvoiceItemControl('paymentAmount', data?.invoiceItem?.id)">
		<pms-always-set-currency-input
				*ngIf="!isAggregateRow(data)"
				[revFocusInput]="isCellSelected(data?.invoiceItem?.id, 'paymentAmount')"
				[highlightInputOnFocus]="true"
                (inputBlur)="onInputBlur()"
				[formControl]="getInvoiceItemControl('paymentAmount', data.invoiceItem.id)">
		</pms-always-set-currency-input>
	</ng-container>

	<span *ngIf="isAggregateRow(data)">{{data.paymentAmount | currency}}</span>
</ng-template>

<ng-template #adjustmentsColumn let-data>
	<ng-container *ngIf="getItemByItem(data?.invoiceItem?.id)">
		<div *ngIf="!isAggregateRow(data)">{{getItemByItem(data.invoiceItem.id).adjustmentTotal | currency}}</div>
	</ng-container>
	<span *ngIf="isAggregateRow(data)" class="text-right">{{data.adjustmentTotal | currency}}</span>
</ng-template>

<ng-template #paymentTotalColumn let-data>
	<ng-container *ngIf="getItemByItem(data?.invoiceItem?.id)">
		<div *ngIf="!isAggregateRow(data)">{{getItemByItem(data.invoiceItem.id).paymentTotalAmount | currency}}</div>
	</ng-container>
	<span *ngIf="isAggregateRow(data)" class="text-right">{{data.paymentTotalAmount | currency}}</span>
</ng-template>

<ng-template #balanceColumn let-data>
	<ng-container *ngIf="getItemByItem(data?.invoiceItem?.id)">
		<div *ngIf="!isAggregateRow(data)" [class.text-danger]="getItemByItem(data.invoiceItem.id).balance < 0"> {{ getItemByItem(data.invoiceItem.id).balance | currency }}</div>
	</ng-container>
	<span *ngIf="isAggregateRow(data)" class="text-right">{{data.balance | currency}}</span>
</ng-template>

<ng-template #transferNumberColumn let-data>
	{{ getTransferNumber(data) }}
</ng-template>

<ng-template #transferTypeColumn let-data>
	{{ getTypeLabel(data.transferRequest)}}
</ng-template>

<ng-template #transferReasonColumn let-data>
	<span revTooltip [tooltipContent]="getReason(data.transferRequest)">{{getReason(data.transferRequest)}}</span>
</ng-template>

<ng-template #transferToColumn let-data>
	<span revTooltip [tooltipContent]="getTransferTo(data.transferRequest)">{{getTransferTo(data.transferRequest)}}</span>
</ng-template>

<ng-template #transferRemoveColumn let-data>
	<rev-button
			buttonIcon="minus"
			buttonStyle="danger"
			buttonTooltip="Remove this transfer/write-off"
			revEventStopPropagation
			(buttonClick)="removeTransfer(getTransferIndexById(data.id), data)"
			dataTestId="receivePaymentsTransferRemoveButton">
	</rev-button>
</ng-template>


<div class="ng-hide" #adjustmentSyncfusionTooltip [attr.data-test-id]="'receivePaymentModalSyncFusionTooltip'">
	<table class="table table-bordered table-condensed margin-bottom-0">
		<thead>
		<tr>
			<th>Adjustment</th>
			<th>Details</th>
			<th class="text-right">Amount</th>
		</tr>
		</thead>
		<tbody>
		<tr *ngFor="let adjustment of sortBy(filterAdjustments(tooltipData?.invoiceItemDetails?.itemAdjustments, tooltipDiscount), 'id')">
			<td>{{adjustment.type}}</td>
			<td>{{adjustment.details}}</td>
			<td class="text-right">{{adjustment.amount | currency}}</td>
		</tr>
		</tbody>
	</table>
</div>
<div class="ng-hide" #taxSyncfusionTooltip [attr.data-test-id]="'receivePaymentModalTaxSyncfusionTooltip'">
	<table class="table table-bordered table-condensed margin-bottom-0">
		<thead>
		<tr>
			<th>Tax</th>
			<th class="text-right">Amount</th>
		</tr>
		</thead>
		<tbody>
		<tr *ngFor="let tax of sortBy(tooltipData?.invoiceItemDetails?.itemTaxes, 'taxId')">
			<td>{{tax.taxName}}</td>
			<td class="text-right">{{tax.taxAmount | currency}}</td>
		</tr>
		</tbody>
	</table>
</div>
