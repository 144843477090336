<div class="panel panel-default" [attr.data-test-id]="'queryBuilderPanel'">
	<div class="panel-heading">
		<h4 class="panel-title">{{headerText}}</h4>
		<ul class="nav-tools">
			<li>
				<a class="e-control e-btn e-lib e-success" (click)="queryBuilder.newQueryTrigger()" revTooltip tooltipContent="Add" [attr.data-test-id]="'queryTriggerAddNewButton'">
					<i class="fa fa-plus"></i>
				</a>
			</li>
		</ul>
	</div>
	<div class="panel-body" [hidden]="queryBuilder.open">
		<ejs-grid #grid
				autoFitColumns
				[autoFitBlacklist]="['description']"
				[staticColumns]="['action']"
				[autoFitTriggers]="['paging']"
				[pmsGridSubmit]="isSearching"
				[pmsGridSubmitDefaultSortOptions]="sortSettings"
				[dataSource]="triggers"
				gridLines="Horizontal"
				[allowSorting]="true"
				class="table-rowlink"
				(queryCellInfo)="queryCellInfo($event)"
				[allowSelection]="true"
				(rowSelecting)="editTrigger($event)"
				[allowPaging]="true"
				[pageSettings]="pageSettings"
				[allowResizing]="true"
				[allowFiltering]="true"
				[allowExcelExport]="true"
				(excelQueryCellInfo)="exportFunction($event)"
				[filterSettings]="{showFilterBarStatus: false}"
				(created)="loadData()"
				  [attr.data-test-id]="'queryBuilderPanelGrid'">
			<ng-template #toolbarTemplate let-data>
				<rev-table-action-menu [table]="grid">
					<ng-container rev-table-action-menu-left>
						<div class="e-toolbar-item">
							<label>Category</label>
							<ejs-dropdownlist
									[dataSource]="categories"
									revOverrideDropdownlistBase
									[fields]="{text: 'label', value: 'label'}"
									[allowFiltering]="shouldFilter(categories)"
									[(ngModel)]="filterCategoryName"
									(ngModelChange)="filterTriggers()"
									[showClearButton]="true"
									placeholder="All Categories"
									[attr.data-test-id]="'queryTriggerCategorySelector'">
							</ejs-dropdownlist>
						</div>
						<div class="e-toolbar-item">
							<label>Status</label>
							<pms-enum-select-button
									[(ngModel)]="filterStatus"
									(ngModelChange)="filterTriggers()"
									[enumList]="triggerStatuses"
									[allOption]="true"
									[attr.data-test-id]="'queryBuilderPanelTriggerStatusButtons'">
							</pms-enum-select-button>
						</div>
					</ng-container>
				</rev-table-action-menu>
			</ng-template>
			<e-columns>
				<e-column field="categoryName" headerText="Category"></e-column>
				<e-column field="name" headerText="Name"></e-column>
				<e-column field="description" headerText="Description" clipMode="EllipsisWithTooltip" [customAttributes]="{autofitMinWidth: 120}"></e-column>
				<e-column
						*ngIf="showLockedColumn"
						field="isLocked"
						headerText="Editable">
					<ng-template #template let-data>
						{{!data.isLocked | yesNo}}
					</ng-template>
				</e-column>
				<e-column field="status.label" headerText="Status"></e-column>
				<e-column
						field="action"
						headerText=""
						[allowSorting]="false"
						[allowResizing]="false"
						[width]="90"
						clipMode="Clip"
						[customAttributes]="{exportable: false}">
					<ng-template #template let-data>
						<rev-button
								*ngIf="!data.isLocked"
								[revGridButton]="'copy'"
								(buttonClick)="queryBuilder.copyTrigger(data.id)"
								dataTestId="queryTriggerCopyButton">
						</rev-button>
						<rev-button
								*ngIf="!isTriggerActive(data)"
								[revGridButton]="'activate'"
								(buttonClick)="activate(data)"
								dataTestId="queryTriggerActivateButton">
						</rev-button>
						<rev-button
								*ngIf="isTriggerActive(data)"
								[revGridButton]="'deactivate'"
								(buttonClick)="deactivate(data)"
								dataTestId="queryTriggerDeactivateButton">
						</rev-button>
					</ng-template>
				</e-column>
			</e-columns>
		</ejs-grid>
	</div>
	<pms-query-builder #queryBuilder
			[queryTemplateCategory]="queryTemplateCategory"
			[categoryEntityType]="categoryEntityType"
			[backButtonText]="backButtonText"
			[categories]="categories"
			(queryTriggerUpdated)="triggerUpdated($event)">
	</pms-query-builder>
</div>
