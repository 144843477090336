// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PracticeDiagnosisResponse } from './practice-diagnosis-response';

/** See com.rev360.pms.api.controller.patient.pod.PatientPodPersonDiagnosisResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientPodPersonDiagnosisResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	diagnosisDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	diagnosisLocation: constants.DiagnosisLocation;

	@GandalfConstantDecorator()
	@GandalfProperty()
	diagnosisLocationQualifier: constants.DiagnosisLocationQualifier;

	@GandalfProperty()
	needConversion: boolean;

	@GandalfProperty()
	personDiagnosisId: number;

	@GandalfProperty()
	practiceDiagnosis: PracticeDiagnosisResponse;

	@GandalfProperty()
	reason: string;

	@GandalfProperty()
	showDisabledWarning: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.PersonDiagnosisStatus;

	@GandalfProperty()
	updatedOn: Date;

}
