import { NgModule, NgZone } from '@angular/core';
import { ExtraOptions, Router, RouterModule, Routes } from '@angular/router';
import { AuthenticationService } from '@core/authentication/authentication.service';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { FeatureModificationService } from '@core/feature/feature-modification.service';
import { HIT_PMS_HTML_EVENTS } from '@core/legacy/hit-pms-html.constants';
import { _pullAll } from '@core/lodash/lodash';
import { canActivateLegacyGuard } from '@core/legacy/legacy.guard';
import { canMatchAuthGuard } from '@core/authentication/auth.guard';
import { APP_ROUTING_CONSTANTS } from './app-routing.constants';
import { canDeactivateLockScreenGuard } from './lock/lock-screen.guard';

export interface ChangeRouteEventData {
	argument: {
		primaryContext: string;
		subContexts: string[];
	};
	eventName: string;
}

const routes: Routes = [
	{
		path: '',
		redirectTo: 'home',
		pathMatch: 'full',
	},
	{
		path: 'lock',
		loadChildren: () => import('./lock/lock.module').then(m => m.LockModule),
		canDeactivate: [canDeactivateLockScreenGuard],
		pathMatch: 'full',
	},
	{
		path: 'legacy',
		loadChildren: () => import('./legacy/legacy.module').then(m => m.LegacyModule),
		canMatch: [canMatchAuthGuard],
		canActivate: [canActivateLegacyGuard],
		data: {key: APP_ROUTING_CONSTANTS.LEGACY},
	},
	{
		path: 'accounting/invoices',
		loadChildren: () => import('./accounting/invoices/invoices.module').then(m => m.InvoicesModule),
		canMatch: [canMatchAuthGuard],
		canActivate: [canActivateLegacyGuard],
		data: {key: APP_ROUTING_CONSTANTS.ACCOUNTING.INVOICES},
	},
	{
		path: 'accounting/claims',
		loadChildren: () => import('./accounting/claims/claims.module').then(m => m.ClaimsModule),
		canMatch: [canMatchAuthGuard],
		canActivate: [canActivateLegacyGuard],
		data: {key: APP_ROUTING_CONSTANTS.ACCOUNTING.CLAIMS},
	},
	{
		path: 'accounting/payments',
		loadChildren: () => import('./accounting/payments/payments.module').then(m => m.PaymentsModule),
		canMatch: [canMatchAuthGuard],
		canActivate: [canActivateLegacyGuard],
		data: {key: APP_ROUTING_CONSTANTS.ACCOUNTING.PAYMENTS},
	},
	{
		path: 'admin',
		loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
		canMatch: [canMatchAuthGuard],
		canActivate: [canActivateLegacyGuard],
		data: {key: APP_ROUTING_CONSTANTS.ADMIN},
	},
	{
		path: 'connect',
		loadChildren: () => import('./connect/connect.module').then(m => m.ConnectModule),
		canMatch: [canMatchAuthGuard],
		canActivate: [canActivateLegacyGuard],
		data: {key: APP_ROUTING_CONSTANTS.CONNECT.DASHBOARD},
	},
	{
		path: 'connect/connectDashboard',
		loadChildren: () => import('./connect/connect.module').then(m => m.ConnectModule),
		canMatch: [canMatchAuthGuard],
		canActivate: [canActivateLegacyGuard],
		data: {key: APP_ROUTING_CONSTANTS.CONNECT.DASHBOARD},
	},
	{
		path: 'connect/connectCampaigns',
		loadChildren: () => import('./connect/campaign/connect-campaign.module').then(m => m.ConnectCampaignModule),
		canMatch: [canMatchAuthGuard],
		canActivate: [canActivateLegacyGuard],
		data: {key: APP_ROUTING_CONSTANTS.CONNECT.CAMPAIGN},
	},
	{
		path: 'connect/connectTwoWayText',
		loadChildren: () => import('./connect/two-way-text/two-way-text.module').then(m => m.TwoWayTextModule),
		canMatch: [canMatchAuthGuard],
		canActivate: [canActivateLegacyGuard],
		data: {key: APP_ROUTING_CONSTANTS.CONNECT.TWO_WAY_TEXT},
	},
	{
		path: 'home',
		loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
		canMatch: [canMatchAuthGuard],
		canActivate: [canActivateLegacyGuard],
		data: {key: APP_ROUTING_CONSTANTS.HOME},
	},
	{
		path: 'inventory/products',
		loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule),
		canMatch: [canMatchAuthGuard],
		canActivate: [canActivateLegacyGuard],
		data: {key: APP_ROUTING_CONSTANTS.INVENTORY.PRODUCTS},
	},
	{
		path: 'inventory/services',
		loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule),
		canMatch: [canMatchAuthGuard],
		canActivate: [canActivateLegacyGuard],
		data: {key: APP_ROUTING_CONSTANTS.INVENTORY.SERVICES},
	},
	{
		path: 'inventory/transfers',
		loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule),
		canMatch: [canMatchAuthGuard],
		canActivate: [canActivateLegacyGuard],
		data: {key: APP_ROUTING_CONSTANTS.INVENTORY.TRANSFERS},
	},
	{
		path: 'inventory/reconciliation',
		loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule),
		canMatch: [canMatchAuthGuard],
		canActivate: [canActivateLegacyGuard],
		data: {key: APP_ROUTING_CONSTANTS.INVENTORY.RECONCILIATION},
	},
	{
		path: 'inventory/vsp',
		loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule),
		canMatch: [canMatchAuthGuard],
		canActivate: [canActivateLegacyGuard],
		data: {key: APP_ROUTING_CONSTANTS.INVENTORY.VSP},
	},
	{
		path: 'marketing',
		loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
		canMatch: [canMatchAuthGuard],
	},
	{
		path: 'messaging',
		loadChildren: () => import('./messaging/messaging.module').then(m => m.MessagingModule),
		canMatch: [canMatchAuthGuard],
		canActivate: [canActivateLegacyGuard],
		data: {key: APP_ROUTING_CONSTANTS.MESSAGING},
	},
	{
		path: 'orders',
		redirectTo: 'orders/ordersEnhanced',
		pathMatch: 'full',
	},
	{
		path: 'orders/ordersEnhanced',
		loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule),
		canMatch: [canMatchAuthGuard],
		canActivate: [canActivateLegacyGuard],
		data: {key: APP_ROUTING_CONSTANTS.ORDERS},
	},
	{
		path: 'orders/ordersLegacy',
		loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule),
		canMatch: [canMatchAuthGuard],
		canActivate: [canActivateLegacyGuard],
		data: {key: APP_ROUTING_CONSTANTS.LEGACY_ORDERS},
	},
	{
		path: 'patients',
		loadChildren: () => import('./patients/patients.module').then(m => m.PatientsModule),
		canMatch: [canMatchAuthGuard],
		canActivate: [canActivateLegacyGuard],
		data: {key: APP_ROUTING_CONSTANTS.PATIENTS},
	},
	{
		path: 'schedule',
		loadChildren: () => import('./schedule/schedule.module').then(m => m.SchedulingModule),
		canMatch: [canMatchAuthGuard],
	},
	{
		path: 'tasks',
		loadChildren: () => import('../app/tasks/tasks.module').then(m => m.TasksModule),
		canMatch: [canMatchAuthGuard],
		canActivate: [canActivateLegacyGuard],
		data: {key: APP_ROUTING_CONSTANTS.TASKS},
	},
	{
		path: 'revEngage',
		loadComponent: () => import('./revengage/rev-engage.component').then(m => m.RevEngageComponent),
		canMatch: [canMatchAuthGuard],
		canActivate: [canActivateLegacyGuard],
		data: {
			key: APP_ROUTING_CONSTANTS.REV_ENGAGE,
			shouldReuse: true,
		},
	},
];

const config: ExtraOptions = {
	useHash: true,
	enableTracing: false,
};

@NgModule({
	imports: [
		RouterModule.forRoot(
			routes,
			config,
		),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {

	constructor(
		private router: Router,
		private zone: NgZone,
		private featureModificationService: FeatureModificationService,
		private authenticationService: AuthenticationService,
		private eventsManager: EventsManagerService,
	) {
		this.featureModificationService.subscribeToRouteChanges();

		// This handles external events to request module/url changes, it allows the non-routed portions of the PMS to be routed via angular
		this.eventsManager.subscribe(HIT_PMS_HTML_EVENTS.APPLICATION.CHANGE_ROUTE, async (eventData: ChangeRouteEventData) => {
			if (eventData && eventData.argument) {
				let changeRoute = [eventData.argument.primaryContext];
				if (eventData.argument.subContexts && eventData.argument.subContexts.length !== 0) {
					changeRoute = changeRoute.concat(eventData.argument.subContexts);
					changeRoute = _pullAll(changeRoute, [undefined, null]);
				}
				// Hack - need to wait for the pms2 authentication to complete before navigating, hit-pms-html has no view into this
				// this wait should only happen on the first navigation (from the login button)
				if (!this.authenticationService.isAuthenticated.getValue()) {
					this.authenticationService.isAuthenticated.subscribe(async (isAuthenticated) => {
						if (isAuthenticated) {
							await this.forceNavigation(changeRoute);
						}
					});
				} else {
					await this.forceNavigation(changeRoute);
				}
			}
		});
	}

	forceNavigation(route: string[]) {
		return this.zone.run(() => this.router.navigate(route));
	}

}
