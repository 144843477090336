import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { ReferenceDataService } from '@core/reference-data/reference-data.service';
import { ReferenceDataMasterCategory } from '@gandalf/constants';
import { CreateReferenceDataRequest } from '@gandalf/model/create-reference-data-request';
import { ReferenceDataResponse } from '@gandalf/model/reference-data-response';
import { UpdateReferenceDataRequest } from '@gandalf/model/update-reference-data-request';
import { GandalfFormBuilder } from 'gandalf';

@Component({
	selector: 'pms-reference-data-detail',
	templateUrl: './reference-data-detail.component.html',
	styles: [],
})
export class ReferenceDataDetailComponent implements OnInit {

	@Input()
	referenceData: ReferenceDataResponse;

	@Input()
	category: ReferenceDataMasterCategory;

	@Input()
	mapsToMaster = '';

	@Input()
	headerText = '';

	@Output()
	returnEvent = new EventEmitter<boolean>();

	requestObj: CreateReferenceDataRequest | UpdateReferenceDataRequest;
	isCreating: boolean;
	formGroup: UntypedFormGroup;

	constructor(
		public gandalfFormBuilder: GandalfFormBuilder,
		public referenceDataService: ReferenceDataService,
	) {
	}

	ngOnInit(): void {
		this.isCreating = !this.referenceData;
		this.createForm();
	}

	createForm() {
		this.requestObj = this.isCreating
			? new CreateReferenceDataRequest()
			: new UpdateReferenceDataRequest();
		this.formGroup = this.gandalfFormBuilder.group(this.requestObj);

		if (this.isCreating) {
			this.formGroup.get('categoryId').setValue(this.category.value);
			this.formGroup.get('active').setValue(true);
		} else {
			this.formGroup.reset(this.referenceData);
			FormUtilsService.enabledWhen(this.formGroup.get('value'), this.referenceData.editable);
			FormUtilsService.enabledWhen(this.formGroup.get('active'), (this.referenceData.editable && this.canBeDeactivated()));
		}
	}

	saveChanges() {
		if (this.formGroup.invalid) {
			return;
		}
		const createOrUpdateRequest = this.formGroup.getRawValue();
		if (this.isCreating) {
			this.referenceDataService.createReferenceData(createOrUpdateRequest).subscribe(() => {
				this.returnToList(true);
			});
		} else {
			this.referenceDataService.updateReferenceData(createOrUpdateRequest).subscribe(() => {
				this.returnToList(true);
			});
		}
	}

	isEditable() {
		return this.isCreating || this.referenceData.editable;
	}

	canBeDeactivated() {
		if (
			this.referenceData.categoryId === ReferenceDataMasterCategory.PERSON_DEMO_ETHNICITY.value ||
			this.referenceData.categoryId === ReferenceDataMasterCategory.PERSON_DEMO_LANGUAGE.value ||
			this.referenceData.categoryId === ReferenceDataMasterCategory.PERSON_DEMO_RACE.value
		) {
			return (!this.referenceData.masterId);
		}

		return true;
	}

	returnToList(refreshRequested: boolean) {
		this.returnEvent.emit(refreshRequested);
	}
}
