<ejs-dialog
	[attr.data-test-id]="'duplicatePatientsDialog'"
	#modal
	(close)="closeModal()"
	cssClass="modal-sm">
	<ng-template #header>
		<div class="dlg-template">Duplicate Patients</div>
	</ng-template>
	<ng-template #content>
		<p>This patient may already exist. Here are some possible matches:</p>
		<ul class="list-unstyled">
			@for (duplicate of duplicates; track duplicate) {
				<li>
					<span>{{ duplicate.patientNameResponse.lastName }}, {{ duplicate.patientNameResponse.firstName }}
						, {{ duplicate.dateOfBirth | date: 'MM/dd/yyyy' }}</span>
					@if (duplicate.matchType === patientDuplicateMatchType.SSN) {
						<span class="margin-left-xs">{{ duplicate.ssn }}</span>
					}
					@if (duplicate.matchType === patientDuplicateMatchType.LAST_4_SSN) {
						<span class="margin-left-xs">{{ getLast4Ssn(duplicate) }}</span>
					}
					<span class="margin-left-xs">({{ getMatchTypeLabel(duplicate.matchType) }})</span>
				</li>
			}
		</ul>
		<p>Would you still like to create this patient?</p>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button (click)="closeModal()" [attr.data-test-id]="'duplicatePatientsNo'">No</button>
		<button ejs-button [isPrimary]="true" (click)="createPatient()" [attr.data-test-id]="'duplicatePatientsYes'">Yes</button>
	</ng-template>
</ejs-dialog>
