import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { _isNil } from '@core/lodash/lodash';

/**
 * Custom Route Reuse Strategy
 * This class replaces the default route reuse strategy that is provided by Angular.
 * By default the router does not make any attempt to store component state. In our
 * use case, we need component state to remain intact. This allows us to flag routes
 * that we would like to maintain their state in between route changes.
 */
@Injectable()
export class RevRouteReuseStrategy implements RouteReuseStrategy {

	private handlers = new Map<string, DetachedRouteHandle>();

	/**
	 * Should Detach
	 * Determines if this route (and its subtree) should be detached to be reused later.
	 * @param route Route being processed.
	 */
	shouldDetach(route: ActivatedRouteSnapshot): boolean {
		return !!route.data && !!route.data['shouldReuse'];
	}

	/**
	 * Should Attach
	 * Determines if this route (and its subtree) should be reattached
	 * @param route Route being processed.
	 */
	shouldAttach(route: ActivatedRouteSnapshot): boolean {
		return this.handlers.has(this.getRouteKey(route));
	}

	/**
	 * Store
	 * Stores the detached route
	 * @param route Route being processed.
	 * @param handle The route tree being stored.
	 */
	store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
		this.handlers.set(this.getRouteKey(route), handle);
	}

	/**
	 * Retrieve
	 * Retrieve the previously stored route from the route handlers
	 * @param route Route being used to retrieve the DetachedRouteHandle.
	 */
	retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
		if (!this.handlers.has(this.getRouteKey(route))) {
			return null;
		}
		return this.handlers.get(this.getRouteKey(route));
	}

	/**
	 * Should Reuse Route
	 * Determines if a route should be reused by looking at the future and current routes
	 * @param future The future route being processed.
	 * @param curr The current route being processed.
	 */
	shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
		return future.url.join('/') === curr.url.join('/');
	}

	/**
	 * Provides a way to manually delete a detached route from storage and to destroy
	 * its component without requiring the activation of a route change.
	 */
	clearRoute(key: string) {
		const handle = this.handlers.get(key);
		if (handle) {
			(handle as any).componentRef.destroy();
		}
		this.handlers.delete(key);
	}

	/**
	 * Assembles a route key by following the full route path from the root
	 */
	private getRouteKey(route: ActivatedRouteSnapshot): string {
		return route.pathFromRoot
			.filter(activatedRoute => !_isNil(activatedRoute.url))
			.map(activatedRoute => activatedRoute.url.join('/'))
			.join('/')
			.replace(new RegExp('//', 'g'), '/');
	}

}
