import { Injectable } from '@angular/core';
import { SortingService } from 'morgana';
import { SummaryPodService } from '@core/summary-pod/summary-pod.service';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { ContactPersonNameResponse } from '@gandalf/model/contact-person-name-response';
import { CreateContactRequest } from '@gandalf/model/create-contact-request';
import { DeleteContactRequest } from '@gandalf/model/delete-contact-request';
import { QuickAddContactRequest } from '@gandalf/model/quick-add-contact-request';
import { UpdateContactRequest } from '@gandalf/model/update-contact-request';
import { PatientContactGandalfService } from '@gandalf/services';
import { PersonNamePipe } from '@shared/pipes/person-name/person-name.pipe';
import { map, tap } from 'rxjs/operators';
import { PatientContactEmergencyContactStatusRequest } from '@gandalf/model/patient-contact-emergency-contact-status-request';

export interface FormattedContactResponse extends ContactPersonNameResponse {
	value: number;
	label: string;
}
@Injectable({
	providedIn: 'root',
})
export class PatientContactService {

	constructor(
		private patientContactGandalfService: PatientContactGandalfService,
		private personNamePipe: PersonNamePipe,
		private summaryPodService: SummaryPodService,
	) { }

	quickAddContact(request: QuickAddContactRequest) {
		return this.patientContactGandalfService.quickAddContact(request).pipe(
			tap(() => this.summaryPodService.updateFamilySummaryPod(request.patientId)),
		);
	}

	/* istanbul ignore next: gandalf */
	getContact(contactId: number) {
		return this.patientContactGandalfService.getContactById(contactId);
	}

	/* istanbul ignore next: gandalf */
	getPatientContactInformationByPatientId(patientId: number) {
		return this.patientContactGandalfService.getPatientContactInformationByPatientId(patientId);
	}

	@ShowSavedSuccessToast()
	createContact(request: CreateContactRequest) {
		return this.patientContactGandalfService.createContact(request).pipe(
			tap(() => this.summaryPodService.updateFamilySummaryPod(request.patientId)),
		);
	}

	@ShowSavedSuccessToast()
	updateContact(request: UpdateContactRequest) {
		return this.patientContactGandalfService.updateContact(request).pipe(
			tap(() => this.summaryPodService.updateFamilySummaryPod(request.patientId)),
		);
	}

	findContactListByPatientId(patientId: number) {
		return this.patientContactGandalfService.findContactListByPatientId(patientId).pipe(
			map(contacts => SortingService.sortBy(contacts, ['id'], ['asc'])),
		);
	}

	deleteContact(request: DeleteContactRequest) {
		return this.patientContactGandalfService.deleteContact(request).pipe(
			tap(() => this.summaryPodService.updateFamilySummaryPod(request.patientId)),
		);
	}

	findContactsByPatientId(patientId: number) {
		return this.patientContactGandalfService.findContactsByPatientId(patientId).pipe(
			map(contacts => contacts.map(contact => this.formatContact(contact))),
			map(contacts => SortingService.sortBy(contacts, ['lastName', 'personId'], ['asc', 'asc'])),
		);
	}

	formatContact(contact: ContactPersonNameResponse) {
		const formattedContact = contact as FormattedContactResponse;
		formattedContact.label = this.personNamePipe.transform(contact);
		formattedContact.value = contact.contactId;

		return formattedContact;
	}

	getContactWithEmergencyContact(patientId: number, contactId: number) {
		const request = new PatientContactEmergencyContactStatusRequest();
		request.parentPatientId = patientId;
		request.contactId = contactId;
		return this.patientContactGandalfService.getContactByWithEmergencyContact(request);
	}
}
