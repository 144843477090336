// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensPrescriptionResponse } from './contact-lens-prescription-response';

// @ts-ignore
import { ContactLensTrialResponse } from './contact-lens-trial-response';

// @ts-ignore
import { OpticalOrderDetailResponse } from './optical-order-detail-response';

/** See com.rev360.pms.api.controller.patient.opticalsummary.OpticalOrderContactLensDetailResponse */
/* istanbul ignore next */
@GandalfModel
export class OpticalOrderContactLensDetailResponse extends OpticalOrderDetailResponse {

	@GandalfProperty()
	odDispense: number;

	@GandalfProperty()
	odPackaging: string;

	@GandalfProperty()
	orderComments: string;

	@GandalfProperty()
	orderDetailId: number;

	@GandalfProperty()
	orderInstructions: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	orderType: constants.OrderType;

	@GandalfProperty()
	osDispense: number;

	@GandalfProperty()
	osPackaging: string;

	@GandalfProperty()
	prescription: ContactLensPrescriptionResponse;

	@GandalfProperty()
	trial: ContactLensTrialResponse;

}
