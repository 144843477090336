import { _findIndex, _join } from '@core/lodash/lodash';

export class ArrayUtil {

	/**
	 * Allows for automatically recognizing whether a row should be updated or pushed onto the end.
	 * @param dataList The array of objects being added to or updated.
	 * @param newRow The potentially-new data in question.
	 * @param identifier The field on the objects to compare for uniqueness.
	 * @param forceInsert If all fields are possibly identical but you know you need to insert anyways.
	 */
	static upsertRow<T>(dataList: T[], newRow: T, identifier: string, forceInsert = false): T[] {
		if (forceInsert) {
			dataList.push(newRow);
		} else {
			const index = _findIndex(dataList, data => data[identifier] === newRow[identifier]);
			if (index >= 0) {
				dataList[index] = newRow;
			} else {
				dataList.push(newRow);
			}
		}
		return dataList;
	}

	static joinWithOxfordComma(...values: string[]): string {
		switch (values.length) {
			case 0:
				return '';
			case 1:
				return '' + values[0];
			case 2:
				return values[0] + ' and ' + values[1];
			default: {
				const last = values.pop();
				return _join(values, ', ') + ', and ' + last;
			}
		}
	}
}
