// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.encounter.testlibrary.AdminAddWorkflowTestTemplateFieldOptionRequest */
/* istanbul ignore next */
@GandalfModel
export class AdminAddWorkflowTestTemplateFieldOptionRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Abbreviation cannot be longer than 25 characters', minLength: 0, maxLength: 25 } })
	@GandalfProperty()
	abbreviation: string;

	@GandalfValidator({ notNull: { message: 'Active is required' } })
	@GandalfProperty({ isRequired: true })
	active: boolean;

	@GandalfValidator({ notNull: { message: 'Display Order is required' } })
	@GandalfProperty({ isRequired: true })
	displayOrder: number;

	@GandalfValidator({ notNull: { message: 'Abnormal Finding is required' } })
	@GandalfProperty({ isRequired: true })
	isAbnormal: boolean;

	@GandalfValidator({ notNull: { message: 'Default Normal is required' } })
	@GandalfProperty({ isRequired: true })
	isDefaultNormal: boolean;

	@GandalfValidator({ notNull: { message: 'Value is required' } })
	@GandalfValidator({ sizeString: { message: 'Value must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ isRequired: true })
	value: string;

}
