<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-sm"
	[attr.data-test-id]="'confirmBarcodeSelectionModal'">
	<ng-template #header>
		<div class="dlg-template">Select Item</div>
	</ng-template>
	<ng-template #content>
		<ejs-grid
			#grid
			class="table-rowlink"
			[dataSource]="tableData"
			[allowSorting]="true"
			[allowSelection]="true"
			[allowResizing]="false"
			gridLines="Horizontal"
			(rowSelected)="closeModal($event.data)"
			[allowPaging]="false"
			[attr.data-test-id]="'confirmBarcodeSelectionGrid'">
			<e-columns>
				<e-column field="description" headerText="Description"></e-column>
			</e-columns>
		</ejs-grid>

	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'confirmBarcodeSelectionCancelButton'">Cancel</button>
	</ng-template>
</ejs-dialog>
