import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
	selector: 'ejs-numerictextbox[pmsConvertZeroToNullEjsNumeric]',
})
export class ConvertZeroToNullEjsNumericDirective implements AfterViewInit {

	constructor(
		private elementRef: ElementRef,
	) { }

	ngAfterViewInit() {
		this.processInput(this.getEjsNumericTextboxValue());
	}

	@HostListener('input', ['$event.target.value'])
	processInput(value: any): void {
		const normalizedValue = parseInt(value, 10) === 0 ? null : value;
		if (value !== normalizedValue) {
			this.setEjsNumericTextboxValue('');
		}
	}

	getEjsNumericTextboxValue() {
		return this.elementRef.nativeElement.ej2_instances[0].value;
	}

	setEjsNumericTextboxValue(value) {
		return this.elementRef.nativeElement.ej2_instances[0].value = value;
	}
}
