import { AccountingViewService } from '@accounting/core/accounting/accounting-view-util/accounting-view.service';
import { InvoiceService } from '@accounting/core/accounting/invoice-service/invoice.service';
import {
	OpenEdgeManualVoidModalResult,
	OpenEdgeVoidPaymentModalComponent
} from '@accounting/invoices/open-edge-void-payment-modal/open-edge-void-payment-modal.component';
import { VoidPaymentWithCommentModalComponent } from '@accounting/invoices/void-payment-with-comment-modal/void-payment-with-comment-modal.component';
import { Component, Input, OnInit } from '@angular/core';
import { DialogUtil, EnumUtil, ModalManagerService, TypeSafeModalManagerService } from 'morgana';
import { EVENT_MANAGER_CONSTANTS } from '@core/events-manager/events-manager.constants';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { _isNil } from '@core/lodash/lodash';
import { OpenEdgePaymentService } from '@core/open-edge-payment/open-edge-payment.service';
import { InvoiceResponse } from '@gandalf/model/invoice-response';
import { PaymentStatus, PaymentTransactionModality, PaymentTransactionProcessor } from '@gandalf/constants';
import { AccountingInvoicePaymentResponse } from '@gandalf/model/accounting-invoice-payment-response';
import { OpenEdgePaymentTransactionResponse } from '@gandalf/model/open-edge-payment-transaction-response';
import { BaseComponent } from '@shared/component/base.component';
import { TABLE_DATE_FORMATS } from '@shared/constants/date-format.constants';
import { SortSettingsModel } from '@syncfusion/ej2-angular-grids';
import { takeUntil } from 'rxjs/operators';
import { AccountingService } from '../../../core/accounting/accounting.service';

@Component({
	selector: 'pms-invoice-payment-history',
	templateUrl: './invoice-payment-history.component.html',
	styles: [],
	providers: [TypeSafeModalManagerService],
})
export class InvoicePaymentHistoryComponent extends BaseComponent implements OnInit {

	@Input()
	invoiceId: number;

	@Input()
	amountPaid: number;

	@Input()
	isReadOnly: boolean;

	@Input()
	isPaymentProcessing: boolean;

	invoicePayments: AccountingInvoicePaymentResponse[];
	tableDateFormat = TABLE_DATE_FORMATS.MM_DD_YYYY;
	sortOptions: SortSettingsModel;
	invoice: InvoiceResponse;

	constructor(
		private accountingService: AccountingService,
		private invoiceService: InvoiceService,
		private accountingViewService: AccountingViewService,
		private modalManagerService: ModalManagerService,
		private eventsManagerService: EventsManagerService,
		private openEdgePaymentService: OpenEdgePaymentService,
		private featureService: FeatureService,
		private typeSafeModalManagerService: TypeSafeModalManagerService,
	) {
		super();
	}

	ngOnInit() {
		this.listenForInvoiceUpdates();
		this.sortOptions = {
			columns: [
				{field: 'paymentDate', direction: 'Descending'},
				{field: 'paymentGroupId', direction: 'Descending'},
			],
		};
	}

	getInvoicePayments() {
		this.accountingService.findInvoicePaymentHistory(this.invoiceId).subscribe((payments) => {
			this.invoicePayments = payments;
		});
	}

	receivePayment() {
		if (!_isNil(this.invoice)) {
			this.invoiceService.openReceivePaymentModal(this.typeSafeModalManagerService, this.invoice);
		}
	}

	canReceivePayment() {
		return !this.isReadOnly && !this.isPaymentProcessing && !_isNil(this.invoice) && this.accountingViewService.canReceivePayment(this.invoice);
	}

	openPayment(rowEvent: any) {
		if (!_isNil(this.invoice)) {
			this.invoiceService.openReceivePaymentModal(this.typeSafeModalManagerService, this.invoice, rowEvent.data);
		}
	}

	listenForInvoiceUpdates() {
		this.invoiceService.getInvoiceDetailsInvoiceState(this.invoiceId)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((invoice) => {
				this.invoice = invoice;
				this.getInvoicePayments();
			});
	}

	voidPayment(payment: AccountingInvoicePaymentResponse) {
		if (this.accountingViewService.canVoidOpenEdgePayment(payment.practiceLocationId)) {
			this.openEdgePaymentService.findOpenEdgeSaleTransaction(payment.id)
				.subscribe(paymentTransaction => this.showStandardOrOpenEdgeVoidPaymentModal(paymentTransaction, payment));
		} else {
			this.openStandardVoidPaymentModal(payment);
		}
	}

	showStandardOrOpenEdgeVoidPaymentModal(paymentTransaction: OpenEdgePaymentTransactionResponse, payment: AccountingInvoicePaymentResponse) {
		if (paymentTransaction) {
			if (EnumUtil.equals(paymentTransaction.transactionModality, PaymentTransactionModality.CREDIT)) {
				this.openOpenEdgeVoidPaymentModal(payment);
			} else if (EnumUtil.equals(paymentTransaction.transactionModality, PaymentTransactionModality.DEBIT)) {
				this.debitConfirmOpenStandardVoidPaymentModal(payment);
			} else {
				this.openStandardVoidPaymentModal(payment);
			}
		} else {
			this.openStandardVoidPaymentModal(payment);
		}
	}

	debitConfirmOpenStandardVoidPaymentModal(payment: AccountingInvoicePaymentResponse) {
		const dialog = DialogUtil.confirm({
			title: 'Void Debit Payment Manually',
			content: '<p>This debit card payment cannot be voided through the payment processor integration. ' +
				'Voiding this payment manually will remove the payment but it <b>will not adjust the amount charged to the debit card.</b> ' +
				'The card adjustment will need to be processed as a refund from within the patient account.</p>' +
				'<p>Would you still like to void this payment manually?</p>',
			okButton: {
				click: () => {
					dialog.close();
					this.openStandardVoidPaymentModal(payment);
				},
			},
			cancelButton: {
				click: () => {
					dialog.close();
				},
			},
		});
	}

	openOpenEdgeVoidPaymentModal(payment: AccountingInvoicePaymentResponse) {
		const payload = {
			data: {
				invoiceId: this.invoiceId,
				paymentId: payment.id,
				payerName: payment.paymentPayerName,
				paymentMethodCardType: payment.paymentMethodCardType,
				paymentAmount: payment.amount,
				last4CreditCard: this.getLast4CreditCardDigits(payment),
			},
		};
		this.modalManagerService.open(OpenEdgeVoidPaymentModalComponent, payload).onClose.subscribe(result => this.processOnCloseVoidModalResult(result, payment));
	}

	processOnCloseVoidModalResult(result: OpenEdgeManualVoidModalResult, payment: AccountingInvoicePaymentResponse) {
		if (!_isNil(result) && result.manualVoidProcessRequested) {
			this.openStandardVoidPaymentModal(payment);
		} else {
			this.onModalClose();
		}
	}

	getLast4CreditCardDigits(payment: AccountingInvoicePaymentResponse) {
		return payment.comment?.split('Last 4: ').pop() || '';
	}

	openStandardVoidPaymentModal(payment: AccountingInvoicePaymentResponse) {
		const payload = {
			data: {
				invoiceId: this.invoiceId,
				paymentId: payment.id,
			},
		};
		this.modalManagerService.open(VoidPaymentWithCommentModalComponent, payload).onClose.subscribe(() => this.onModalClose());
	}

	onModalClose() {
		this.eventsManagerService.publish(EVENT_MANAGER_CONSTANTS.ACCOUNTING.PAYMENTS_UPDATED);
		this.invoiceService.refreshInvoiceDetailsInvoice(this.invoiceId);
	}

	canVoidPayment(payment: AccountingInvoicePaymentResponse) {
		return this.accountingViewService.canVoidPayment(this.invoice, payment);
	}

	printReceipt(payment: AccountingInvoicePaymentResponse) {
		this.accountingService.printReceiptsRelatedToPaymentTransaction(payment.paymentTransaction.id);
	}

	canPrintReceipt(payment: AccountingInvoicePaymentResponse) {
		if (!EnumUtil.equals(payment.paymentTransaction?.transactionProcessor, PaymentTransactionProcessor.OPENEDGE)) {
			return false;
		}
		return this.canPrintSaleReceipt(payment) || this.canPrintVoidReceipt(payment);
	}

	canPrintSaleReceipt(payment: AccountingInvoicePaymentResponse) {
		return this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.ACCOUNTING.INVOICE.GLOBAL_PAYMENTS_PRINT_SALE_RECEIPT)
			&& EnumUtil.equals(payment.paymentStatus, PaymentStatus.APPROVED);
	}

	canPrintVoidReceipt(payment: AccountingInvoicePaymentResponse) {
		return this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.ACCOUNTING.INVOICE.GLOBAL_PAYMENTS_PRINT_VOID_RECEIPT)
			&& EnumUtil.equals(payment.paymentStatus, PaymentStatus.VOID);
	}
}
