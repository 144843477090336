// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.inventory.reconciliation.ReconciliationItemResponse */
/* istanbul ignore next */
@GandalfModel
export class ReconciliationItemResponse extends GandalfModelBase {

	@GandalfProperty()
	countedItems: number;

	@GandalfProperty()
	itemDescription: string;

	@GandalfProperty()
	itemSku: string;

	@GandalfProperty()
	itemUpc: string;

	@GandalfProperty()
	notes: string;

	@GandalfProperty()
	productCategoryName: string;

	@GandalfProperty()
	productManufacturerName: string;

	@GandalfProperty()
	productName: string;

	@GandalfProperty()
	reconciledItems: number;

	@GandalfProperty()
	reportedItems: number;

}
