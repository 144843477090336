import { Injectable, NgZone } from '@angular/core';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { HIT_PMS_HTML_EVENTS } from '@core/legacy/hit-pms-html.constants';
import { TypeSafeModalManagerService } from 'morgana';
import { EditProfileModalComponent } from '../edit-profile-modal/edit-profile-modal.component';

@Injectable({
	providedIn: 'root',
})
export class EditProfileSubscriptionService {

	constructor(
		public zone: NgZone,
		private eventsManager: EventsManagerService,
	) {
	}

	subscribeToFeatureEvent(typeSafeModalManagerService: TypeSafeModalManagerService) {
		this.eventsManager.subscribe(HIT_PMS_HTML_EVENTS.HEADER.REV_360_OPEN_EDIT_PROFILE, () => this.openEditProfileModal(typeSafeModalManagerService), this);
	}

	openEditProfileModal(typeSafeModalManagerService: TypeSafeModalManagerService) {
		this.zone.run(() => {
			typeSafeModalManagerService.open(EditProfileModalComponent, {});
		});
	}
}
