import { LegacyStatefulComponentUtilsService } from '@app-store/utils/legacy-stateful-component-utils.service';
import { PracticeConsentFormResponse } from '@gandalf/model/practice-consent-form-response';
import { PracticeConsentsActions, PracticeConsentsActionTypes } from '@practice-store/actions/consents.actions';

export class PracticeConsentsState {
	availableConsentForms: PracticeConsentFormResponse[];
}

export const initialState: PracticeConsentsState = new PracticeConsentsState();

export function reducer(state = initialState, action: PracticeConsentsActions): PracticeConsentsState {
	switch (action.type) {

		case PracticeConsentsActionTypes.UPDATE_PRACTICE_CONSENTS_PROPERTY: {
			return LegacyStatefulComponentUtilsService.updateProperty(state, PracticeConsentsState, action.payload);
		}

		default: {
			return state;
		}
	}
}
