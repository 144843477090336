import { Injectable } from '@angular/core';
import { _isNil, _map } from '@core/lodash/lodash';
import { ConnectMessageForWaitingListRequest } from '@gandalf/model/connect-message-for-waiting-list-request';
import { CreateWaitingListRequest } from '@gandalf/model/create-waiting-list-request';
import { UpdateWaitingListRequest } from '@gandalf/model/update-waiting-list-request';
import { WaitingListResponse } from '@gandalf/model/waiting-list-response';
import { WaitingListGandalfService } from '@gandalf/services';
import { FormattedNamePipe } from '@shared/pipes/formatted-name/formatted-name.pipe';
import { PatientNamePipe } from '@shared/pipes/patient-name/patient-name.pipe';
import { SortingService } from 'morgana';
import { map } from 'rxjs/operators';

export interface FormattedWaitListEntry extends WaitingListResponse {
	formattedPatientName?: string;
	formattedProviderName?: string;
	priorityValue?: number;
	patientSchedulingPreferenceComponent?: string;
}

@Injectable({
	providedIn: 'root',
})
export class WaitListService {

	constructor(
		private waitingListGandalfService: WaitingListGandalfService,
		private patientNamePipe: PatientNamePipe,
		private formattedNamePipe: FormattedNamePipe,
	) {
	}

	/* istanbul ignore next: gandalf */
	findEmployeeAccessibleWaitingList() {
		return this.waitingListGandalfService.findEmployeeAccessibleWaitingList().pipe(
			map(waitList => this.formatPatientName(waitList)),
			map(waitList => this.formatProviderName(waitList)),
			map(waitList => this.setPriorityValueForSorting(waitList)),
			map(waitList => this.convertNullToNegativeDay(waitList)),
			map(waitList => this.addEntryForTooltip(waitList)),
			map(waitlist =>
				SortingService.sortBy(waitlist, ['priorityValue', 'numberOfDaysOnWaitList', 'waitListId'], ['desc', 'desc', 'desc']),
			),
		);
	}

	/* istanbul ignore next: gandalf */
	createWaitingList(request: CreateWaitingListRequest) {
		return this.waitingListGandalfService.createWaitingList(request);
	}

	updateWaitingList(request: UpdateWaitingListRequest) {
		return this.waitingListGandalfService.updateWaitingList(request);
	}

	/* istanbul ignore next: gandalf */
	deleteWaitListEntry(waitListId) {
		return this.waitingListGandalfService.deactivateWaitingListEntry(waitListId);
	}

	/* istanbul ignore next: gandalf */
	sendWaitingListConnectMessage(request: ConnectMessageForWaitingListRequest) {
		return this.waitingListGandalfService.sendWaitingListConnectMessage(request);
	}

	getWaitingListCountForLocation(locationId: number) {
		return this.waitingListGandalfService.getWaitingListCountForLocation(locationId);
	}

	private formatPatientName(waitList: WaitingListResponse[]) {
		return _map(waitList, entry => {
			(entry as FormattedWaitListEntry).formattedPatientName = this.patientNamePipe.transform(entry.patientName);
			return entry;
		});
	}

	private formatProviderName(waitList: WaitingListResponse[]) {
		return _map(waitList, entry => {
			(entry as FormattedWaitListEntry).formattedProviderName = this.formattedNamePipe.transform(entry.provider?.firstName, entry.provider?.lastName);
			return entry;
		});
	}

	private convertNullToNegativeDay(waitList: WaitingListResponse[]) {
		return _map(waitList, entry => {
			if (_isNil(entry.numberOfDaysOnWaitList)) {
				entry.numberOfDaysOnWaitList = -1;
			}
			return entry;
		});
	}

	private setPriorityValueForSorting(waitList: WaitingListResponse[]) {
		return _map(waitList, entry => {
			(entry as FormattedWaitListEntry).priorityValue = entry.priority.value;
			return entry;
		});
	}

	// This is to make a popover work because a valid, not undefined or null, value field exists.
	private addEntryForTooltip(waitList: WaitingListResponse[]) {
		return _map(waitList, entry => {
			(entry as FormattedWaitListEntry).patientSchedulingPreferenceComponent = 'requiredString';
			return entry;
		});
	}
}
