// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.twowaytext.CreateTwoWayTextConversationRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateTwoWayTextConversationRequest extends GandalfModelBase {

	@GandalfLabel('Message text')
	@GandalfValidator({ notNull: { message: 'Message text is required' } })
	@GandalfValidator({ sizeString: { message: 'Message text must be between 1 and 306 characters', minLength: 1, maxLength: 306 } })
	@GandalfProperty({ isRequired: true })
	content: string;

	@GandalfLabel('Patient')
	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientId: number;

}
