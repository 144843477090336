import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { InjectorContainerModule } from '../../injector-container.module';
import { GandalfStoreService } from '../gandalf-store.service';

export interface GandalfCacheBusterOptions {
	cacheKeys: string[];
}

export function GandalfCacheBuster(options: GandalfCacheBusterOptions) {

	return function(target: any, propertyKey: string, descriptor: TypedPropertyDescriptor<(...args: any[]) => Observable<any>>) {
		const serviceMethod = descriptor.value;

		descriptor.value = function(...args: any[]) {
			const gandalfStoreService = InjectorContainerModule.getInjectable(GandalfStoreService);
			return serviceMethod.apply(this, args).pipe(
				tap(() => options.cacheKeys.forEach(cacheKey => gandalfStoreService.bustCache(cacheKey))),
			);
		};
	};
}
