// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.prescription.RxSelectContactLensResponse */
/* istanbul ignore next */
@GandalfModel
export class RxSelectContactLensResponse extends GandalfModelBase {

	@GandalfConstantDecorator()
	@GandalfProperty()
	contactLensType: constants.ContactLensType;

	@GandalfProperty({ propertyType: 'LocalDate' })
	expirationDate: Date;

	@GandalfProperty()
	odManufacturerName: string;

	@GandalfProperty()
	odProductName: string;

	@GandalfProperty()
	osManufacturerName: string;

	@GandalfProperty()
	osProductName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	rxDisplayStatus: constants.RxDisplayStatusLegacy;

	@GandalfProperty()
	rxId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate: Date;

}
