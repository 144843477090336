import { Injectable } from '@angular/core';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { PatientPreferencesGandalfService } from '@gandalf/services';

@Injectable({
	providedIn: 'root',
})
export class PreferencesService {

	constructor(
		private patientPreferencesGandalfService: PatientPreferencesGandalfService,
	) {
	}

	/* istanbul ignore next: gandalf */
	findPatientSchedulingPreferencesByPatientId(patientId) {
		return this.patientPreferencesGandalfService.findPatientSchedulingPreferencesByPatientId(patientId);
	}

	/* istanbul ignore next: gandalf */
	@ShowSavedSuccessToast()
	updateSchedulingPreference(request) {
		return this.patientPreferencesGandalfService.updateSchedulingPreference(request);
	}
}
