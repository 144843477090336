import { Component, Input } from '@angular/core';
import { PrescriptionUtil } from '@core/prescription-util/prescription-util';
import { EyeglassPrescriptionResponse } from '@gandalf/model/eyeglass-prescription-response';
import { EyeglassLensRxService } from '@shared/component/eyeglass-lens-rx-container/eyeglass-lens-rx.service';

@Component({
	selector: 'pms-eyeglass-rx',
	templateUrl: './eyeglass-rx.component.html',
})
export class EyeglassRxComponent {

	@Input()
	eyeglassPrescription: EyeglassPrescriptionResponse;

	@Input()
	showViewRxHistoryButton: boolean;

	PrescriptionUtil = PrescriptionUtil;

	constructor(
		public eyeglassLensRxService: EyeglassLensRxService,
	) {
	}

	openRxSelectModal() {
		this.eyeglassLensRxService.openRxSelectModal();
	}
}
