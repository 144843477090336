import { Injectable } from '@angular/core';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { ToasterService } from '@core/toaster/toaster.service';
import { CreateManufacturerRequest } from '@gandalf/model/create-manufacturer-request';
import { CreateProductBrandCollectionRequest } from '@gandalf/model/create-product-brand-collection-request';
import { CreateProductBrandRequest } from '@gandalf/model/create-product-brand-request';
import { ManufacturerResponse } from '@gandalf/model/manufacturer-response';
import { ManufacturerSummaryResponse } from '@gandalf/model/manufacturer-summary-response';
import { ProductBrandResponse } from '@gandalf/model/product-brand-response';
import { ProductCategoryResponse } from '@gandalf/model/product-category-response';
import { SmartflowMasterManufacturerResponse } from '@gandalf/model/smartflow-master-manufacturer-response';
import { UpdateManufacturerRequest } from '@gandalf/model/update-manufacturer-request';
import { AdminManufacturerGandalfService } from '@gandalf/services';
import { FormattedSingleLineAddressPipe } from '@shared/pipes/formatted-single-line-address/formatted-single-line-address.pipe';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BetaSystemCode } from '@gandalf/constants';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';


export interface ManufacturerSummaryResponseForTable extends ManufacturerSummaryResponse {
	fullAddress?: string;
}

export interface ManufacturerResponseWithAddress extends ManufacturerResponse {
	addressLine1: string;
	addressLine2: string;
	city: string;
	stateProvince: string;
	postalCode: string;
	nonStandardPostalCode: string;
}

export interface SmartFlowMasterManufacturerResponseForDropdown extends SmartflowMasterManufacturerResponse {
	label: string;
	value: any;
}

export interface ProductCategoryResponseForDropdown extends ProductCategoryResponse {
	label: string;
	value: any;
}

@Injectable({
	providedIn: 'root',
})
export class ManufacturerService {

	constructor(
		public manufacturerGandalfService: AdminManufacturerGandalfService,
		public formattedAddressPipe: FormattedSingleLineAddressPipe,
		public toasterService: ToasterService,
		public securityManagerService: SecurityManagerService,
	) {
	}

	/*istanbul ignore next*/
	findPracticeManufacturers(): Observable<ManufacturerSummaryResponseForTable[]> {
		return this.manufacturerGandalfService.findPracticeManufacturers().pipe(
			map(manufacturers => manufacturers.map(manufacturer => this.addFlattenedFullAddress(manufacturer))));
	}

	findProductCategories(): Observable<ProductCategoryResponseForDropdown[]> {
		return this.manufacturerGandalfService.findProductCategories().pipe(
			map(productCategories => productCategories.map(
				productCategory => this.formatProductCategoryForDropdown(productCategory),
			)),
		);
	}

	addProductBrandCollection(request: CreateProductBrandCollectionRequest): Observable<ProductBrandResponse> {
		return this.manufacturerGandalfService.addProductBrandCollection(request);
	}

	addProductBrand(request: CreateProductBrandRequest): Observable<ManufacturerResponse> {
		return this.manufacturerGandalfService.addProductBrand(request);
	}

	activateProductBrand(productBrandId: number): Observable<void> {
		return this.manufacturerGandalfService.activateProductBrand(productBrandId);
	}

	deactivateProductBrand(productBrandId: number): Observable<void> {
		return this.manufacturerGandalfService.deactivateProductBrand(productBrandId);
	}

	activateProductBrandCollection(productBrandCollectionId: number): Observable<void> {
		return this.manufacturerGandalfService.activateProductBrandCollection(productBrandCollectionId);
	}

	deactivateProductBrandCollection(productBrandCollectionId: number): Observable<void> {
		return this.manufacturerGandalfService.deactivateProductBrandCollection(productBrandCollectionId);
	}

	@ShowSavedSuccessToast()
	activateManufacturer(manufacturerId: number): Observable<ManufacturerSummaryResponse> {
		return this.manufacturerGandalfService.activateManufacturer(manufacturerId);
	}

	@ShowSavedSuccessToast()
	deactivateManufacturer(manufacturerId: number): Observable<ManufacturerSummaryResponse> {
		return this.manufacturerGandalfService.deactivateManufacturer(manufacturerId);
	}

	/*istanbul ignore next*/
	getManufacturer(manufacturerId: number): Observable<ManufacturerResponseWithAddress> {
		return this.manufacturerGandalfService.getManufacturerById(manufacturerId).pipe(
			map(manufacturer => this.flattenManufacturerAddress(manufacturer)),
		);
	}

	/*istanbul ignore next*/
	@ShowSavedSuccessToast()
	createManufacturer(request: CreateManufacturerRequest): Observable<ManufacturerResponse> {
		return this.manufacturerGandalfService.createManufacturer(request).pipe(
			map(manufacturer => this.flattenManufacturerAddress(manufacturer)),
		);
	}

	@ShowSavedSuccessToast()
	updateManufacturer(request: UpdateManufacturerRequest): Observable<ManufacturerResponse> {
		return this.manufacturerGandalfService.updateManufacturer(request).pipe(
			map(manufacturer => this.flattenManufacturerAddress(manufacturer)),
		);
	}

	/*istanbul ignore next*/
	findActiveSmartflowMasterManufacturers(): Observable<SmartFlowMasterManufacturerResponseForDropdown[]> {
		return this.manufacturerGandalfService.findActiveSmartflowMasterManufacturers().pipe(
			map(smartflowMasterManufacturers => smartflowMasterManufacturers.map(
				smartflowMasterManufacturer => this.formatSmartflowMasterManufacturerForDropdown(smartflowMasterManufacturer))));
	}

	findBrandsAndCollections(manufacturerId: number): Observable<ProductBrandResponse[]> {
		return this.manufacturerGandalfService.findProductBrandsByManufacturerId(manufacturerId);
	}

	addFlattenedFullAddress(manufacturer: ManufacturerSummaryResponse): ManufacturerSummaryResponseForTable {
		const manufacturerResponseForTable = manufacturer as ManufacturerSummaryResponseForTable;
		if (manufacturerResponseForTable.address) {
			manufacturerResponseForTable.fullAddress = this.formattedAddressPipe.transform(
				manufacturerResponseForTable.address,
			);
		}
		return manufacturerResponseForTable;
	}

	flattenManufacturerAddress(manufacturerResponse: ManufacturerResponse): ManufacturerResponseWithAddress {
		const manufacturerResponseWithAddress: ManufacturerResponseWithAddress =
			manufacturerResponse as ManufacturerResponseWithAddress;
		if (manufacturerResponse.address) {
			manufacturerResponseWithAddress.addressLine1 = manufacturerResponse.address.address1;
			manufacturerResponseWithAddress.addressLine2 = manufacturerResponse.address.address2;
			manufacturerResponseWithAddress.city = manufacturerResponse.address.city;
			manufacturerResponseWithAddress.stateProvince = manufacturerResponse.address.stateProvince;
			if (this.securityManagerService.hasBeta(BetaSystemCode.NON_STANDARD_ZIP_CODES)) {
				manufacturerResponseWithAddress.nonStandardPostalCode = manufacturerResponse.address.postalCode;
			} else {
				manufacturerResponseWithAddress.postalCode = manufacturerResponse.address.postalCode;
			}
		}
		return manufacturerResponseWithAddress;
	}

	formatSmartflowMasterManufacturerForDropdown(smartflowMasterManufacturer: SmartflowMasterManufacturerResponse):
		SmartFlowMasterManufacturerResponseForDropdown {

		const formattedSmartflowMasterManufacturer = smartflowMasterManufacturer as SmartFlowMasterManufacturerResponseForDropdown;
		formattedSmartflowMasterManufacturer.label = smartflowMasterManufacturer.name;
		formattedSmartflowMasterManufacturer.value = smartflowMasterManufacturer.smartFlowCode;

		return formattedSmartflowMasterManufacturer;
	}

	formatProductCategoryForDropdown(productCategory: ProductCategoryResponse): ProductCategoryResponseForDropdown {
		const formattedProductCategory = productCategory as ProductCategoryResponseForDropdown;
		formattedProductCategory.label = productCategory.name;
		formattedProductCategory.value = productCategory.id;

		return formattedProductCategory;
	}
}
