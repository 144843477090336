// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreateWorkflowStepTemplateRequest } from './create-workflow-step-template-request';

/** See com.rev360.pms.api.controller.admin.encounter.UpdateWorkflowStepTemplateRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateWorkflowStepTemplateRequest extends CreateWorkflowStepTemplateRequest {

	@GandalfValidator({ notNull: { message: 'Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Name must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ isRequired: true })
	name: string;

	@GandalfArray(Number)
	workflowScreenTemplateIds: number[];

	@GandalfValidator({ notNull: { message: 'Workflow Step Template is required' } })
	@GandalfProperty({ isRequired: true })
	workflowStepTemplateId: number;

}
