import { Directive, ElementRef, HostListener } from '@angular/core';

/**
 * Put this attribute directive on a text input field in order to force the field to only accept digits.
 * Since this only allows positive numbers, it works well to put this on an input that has "type='number' min='0'" (unless the input is for an ID value).
 */
@Directive({
	selector: 'input[revDigitOnlyInput]',
})
export class DigitOnlyInputDirective {

	constructor(private elementRef: ElementRef) {
	}

	/**
	 * Modern browsers support retrieving pasted text.  We'll intercept pasted text, remove non-digit characters,
	 * and update the text field.
	 */
	@HostListener('paste', ['$event'])
	onPaste(event: ClipboardEvent) {
		const pastedText = event.clipboardData.getData('Text');
		const fixedText = this.fixPastedText(pastedText);
		if (pastedText !== fixedText) {
			// It would be nice to use ClipboardEvent.setData() to change what was pasted, but apparently this isn't
			// well supported due to browser security concerns.  So we'll just update the text field ourselves.
			event.preventDefault();
			const nativeElement = this.elementRef.nativeElement;
			nativeElement.value = `${nativeElement.value}${fixedText}`;
		}
	}

	@HostListener('keypress', ['$event'])
	onKeypress(event: KeyboardEvent) {
		if (event.code === 'Tab' || event.key === 'Enter') {
			return;
		}

		if (this.shouldIgnoreKeypress(event.key)) {
			event.preventDefault();
		}
	}

	/**
	 * Removes non-digits from the pasted text.
	 * @param input The pasted text
	 * @return corrected text to use instead
	 */
	fixPastedText(input: string) {
		return input.replace(/\D/g, '');	// \D matches non-digits
	}

	/**
	 * Ensures that only digits can be entered in the text field.
	 * @param key The key that was pressed
	 */
	shouldIgnoreKeypress(key: string) {
		return (/\D/.exec(key)) !== null;
	}

}
