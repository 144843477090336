// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ReferenceDataSummaryResponse } from './reference-data-summary-response';

/** See com.rev360.pms.api.controller.admin.accounting.feeschedule.FeeScheduleRangeResponse */
/* istanbul ignore next */
@GandalfModel
export class FeeScheduleRangeResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	beginDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	computedStatus: constants.FeeScheduleRangeComputedStatus;

	@GandalfProperty()
	feeScheduleId: number;

	@GandalfProperty()
	feeScheduleName: string;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	practiceLocationId: number;

	@GandalfProperty()
	practiceLocationName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.FeeScheduleRangeStatus;

	@GandalfProperty()
	writeoffReason: ReferenceDataSummaryResponse;

}
