// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.solutions.openedge.OpenEdgeSettingsRequest */
/* istanbul ignore next */
@GandalfModel
export class OpenEdgeSettingsRequest extends GandalfModelBase {

	@GandalfLabel('MOTO Account AuthKey')
	@GandalfValidator({ alphanumeric: { message: 'MOTO Account AuthKey must be alphanumeric' } })
	@GandalfValidator({ notNull: { message: 'MOTO Account AuthKey is required' } })
	@GandalfValidator({ sizeString: { message: 'MOTO Account AuthKey must be 32 characters in length', minLength: 32, maxLength: 32 } })
	@GandalfProperty({ isRequired: true })
	motoAccountAuthKey: string;

	@GandalfLabel('MOTO Account Terminal ID')
	@GandalfValidator({ digitsOnly: { message: 'MOTO Account Terminal ID must contain only digits' } })
	@GandalfValidator({ notNull: { message: 'MOTO Account Terminal ID is required' } })
	@GandalfValidator({ sizeString: { message: 'MOTO Account Terminal ID must be 8 characters in length', minLength: 8, maxLength: 8 } })
	@GandalfProperty({ isRequired: true })
	motoAccountTerminalId: string;

	@GandalfLabel('MOTO Account XWeb ID')
	@GandalfValidator({ digitsOnly: { message: 'MOTO Account XWeb ID must contain only digits' } })
	@GandalfValidator({ notNull: { message: 'MOTO Account XWeb ID is required' } })
	@GandalfValidator({ sizeString: { message: 'MOTO Account XWeb ID must be 12 characters in length', minLength: 12, maxLength: 12 } })
	@GandalfProperty({ isRequired: true })
	motoAccountXWebId: string;

	@GandalfLabel('Pay Online')
	@GandalfValidator({ notNull: { message: 'Pay Online is required' } })
	@GandalfProperty({ isRequired: true })
	payOnlineEnabled: boolean;

	@GandalfLabel('Pay Online URL')
	@GandalfValidator({ sizeString: { message: 'Pay Online URL must be between 3 and 30 characters', minLength: 3, maxLength: 30 } })
	@GandalfProperty()
	payOnlineUrlPath: string;

	@GandalfLabel('Retail Account AuthKey')
	@GandalfValidator({ alphanumeric: { message: 'Retail Account AuthKey must be alphanumeric' } })
	@GandalfValidator({ notNull: { message: 'Retail Account AuthKey is required' } })
	@GandalfValidator({ sizeString: { message: 'Retail Account AuthKey must be 32 characters in length', minLength: 32, maxLength: 32 } })
	@GandalfProperty({ isRequired: true })
	retailAccountAuthKey: string;

	@GandalfLabel('Retail Account Terminal ID')
	@GandalfValidator({ digitsOnly: { message: 'Retail Account Terminal ID must contain only digits' } })
	@GandalfValidator({ notNull: { message: 'Retail Account Terminal ID is required' } })
	@GandalfValidator({ sizeString: { message: 'Retail Account Terminal ID must be 8 characters in length', minLength: 8, maxLength: 8 } })
	@GandalfProperty({ isRequired: true })
	retailAccountTerminalId: string;

	@GandalfLabel('Retail Account XWeb ID')
	@GandalfValidator({ digitsOnly: { message: 'Retail Account XWeb ID must contain only digits' } })
	@GandalfValidator({ notNull: { message: 'Retail Account XWeb ID is required' } })
	@GandalfValidator({ sizeString: { message: 'Retail Account XWeb ID must be 12 characters in length', minLength: 12, maxLength: 12 } })
	@GandalfProperty({ isRequired: true })
	retailAccountXWebId: string;

	@GandalfLabel('Statement Pay Online')
	@GandalfValidator({ notNull: { message: 'Statement Pay Online is required' } })
	@GandalfProperty({ isRequired: true })
	statementPayOnlineEnabled: boolean;

}
