import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { BarcodeService, FormattedBarcodeSearchResponse } from '@core/barcode/barcode.service';
import { _isNil } from '@core/lodash/lodash';
import { ModalManagerService } from 'morgana';
import { ToasterService } from '@core/toaster/toaster.service';
import { BarcodeSearchType } from '@gandalf/constants';
import { BarcodeSearchRequest } from '@gandalf/model/barcode-search-request';
// eslint-disable-next-line max-len
import { ConfirmBarcodeSelectionModalComponent } from '@shared/component/barcode-search/confirm-barcode-selection-modal/confirm-barcode-selection-modal.component';
import { GandalfFormBuilder } from 'gandalf';

export enum ContactLensFilterOptions {
	NONE,
	TRIAL,
	RX,
}

@Component({
	selector: 'pms-barcode-search',
	templateUrl: './barcode-search.component.html',
	providers: [ModalManagerService],
})
export class BarcodeSearchComponent implements OnInit {
	@Input()
	locationId: number;

	@Input()
	productCategoryId: number;

	@Input()
	searchType: BarcodeSearchType;

	@Input()
	label: string;

	@Input()
	buttonLabel: string;

	@Input()
	trialFilter = ContactLensFilterOptions.NONE;

	@Input()
	focusInput = true;

	@Output()
	results = new EventEmitter<FormattedBarcodeSearchResponse>();

	@ViewChild('barcodeForm')
	barcodeForm: NgForm;

	barcodeSearchRequest: BarcodeSearchRequest;
	componentForm: UntypedFormGroup;

	constructor(
		public barcodeService: BarcodeService,
		public modalManagerService: ModalManagerService,
		public toasterService: ToasterService,
		public gandalfFormBuilder: GandalfFormBuilder,
	) {
	}

	ngOnInit() {
		this.createForm();
	}

	createForm() {
		this.barcodeSearchRequest = new BarcodeSearchRequest();
		this.barcodeSearchRequest.locationId = this.locationId;
		this.barcodeSearchRequest.productCategoryId = this.productCategoryId;
		this.barcodeSearchRequest.searchType = this.searchType;
		this.componentForm = this.gandalfFormBuilder.group(this.barcodeSearchRequest);
	}

	search() {
		if (this.componentForm.invalid) {
			return;
		}
		this.barcodeService.searchBarcode(this.componentForm.value).subscribe(barcodeSearchResults => {
			this.handleBarcodeResult(barcodeSearchResults);
		});
	}

	handleBarcodeResult(barcodeSearchResults: FormattedBarcodeSearchResponse[]) {
		if (barcodeSearchResults.length < 1) {
			this.barcodeForm.resetForm(this.barcodeSearchRequest);
			this.toasterService.showError({content: 'No Items Found'});
		} else if (barcodeSearchResults.length > 1) {
			this.confirmSelection(barcodeSearchResults);
		} else {
			this.checkAndEmitResult(barcodeSearchResults[0]);
		}
	}

	checkAndEmitResult(barcodeSearchResult: FormattedBarcodeSearchResponse) {
		if (this.checkForValidResult(barcodeSearchResult)) {
			this.results.emit(barcodeSearchResult);
			this.componentForm.get('barcodeValue').setValue('');
		}
	}

	confirmSelection(results: FormattedBarcodeSearchResponse[]) {
		this.modalManagerService.open(ConfirmBarcodeSelectionModalComponent, {data: {results}}).onClose.subscribe(result => {
			if (result) {
				this.checkAndEmitResult(result);
			}
		});
	}

	checkForValidResult(barcodeSearchResult: FormattedBarcodeSearchResponse) {
		if (this.trialFilter === ContactLensFilterOptions.NONE) {
			return true;
		} else if (this.trialFilter === ContactLensFilterOptions.TRIAL && !_isNil(barcodeSearchResult.locationProductItemId) && !barcodeSearchResult.isTrial) {
			this.toasterService.showError({content: `${barcodeSearchResult.productName} is not a trial lens`});
			return false;
		} else if (this.trialFilter === ContactLensFilterOptions.RX && !_isNil(barcodeSearchResult.locationProductItemId) && barcodeSearchResult.isTrial) {
			this.toasterService.showError({content: `${barcodeSearchResult.productName} is a trial lens`});
			return false;
		}
		return true;
	}
}
