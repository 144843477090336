// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { PhoneNumbersResponse } from './phone-numbers-response';

// @ts-ignore
import { ReferenceDataSummaryResponse } from './reference-data-summary-response';

/** See com.rev360.pms.api.controller.patient.PatientEmergencyContactResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientEmergencyContactResponse extends GandalfModelBase {

	@GandalfProperty()
	address: AddressResponse;

	@GandalfProperty()
	email: string;

	@GandalfProperty()
	emergencyContactId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	emergencyContactType: constants.EmergencyContactType;

	@GandalfConstantDecorator()
	@GandalfProperty()
	patientStatus: constants.PatientStatus;

	@GandalfProperty()
	person: PersonNameResponse;

	@GandalfProperty()
	phoneNumbers: PhoneNumbersResponse;

	@GandalfProperty()
	role: ReferenceDataSummaryResponse;

}
