import 'reflect-metadata';
import { setMetadataPropertyValue } from '../metadata/gandalf-metadata-util';

/**
 * Metadata key indicating the exact type of a GandalfConstant property
 */
export const GANDALF_CONSTANT_METADATA_KEY = Symbol('GandalfConstant');

/**
 * Decorator for a property whose type is a Gandalf Constant.
 *
 * This decorator stores the appropriate Gandalf metadata about the constant, so it can be accessed by other Gandalf utilities.
 */
export function GandalfConstantDecorator() {
	return (target: object, propertyKey: string) => {
		// Determine the type of constant and store it in metadata
		const propertyType = Reflect.getMetadata('design:type', target, propertyKey);
		setMetadataPropertyValue(target, GANDALF_CONSTANT_METADATA_KEY, propertyKey, propertyType);
	};
}
