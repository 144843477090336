<mat-select
	#matSelect
	[formControl]="ngControl.control"
	[class.disabled]="isDisabled()"
	(focus)="onFocus()"
	disableRipple
	(openedChange)="onOpenChanged($event)"
	(keydown.ArrowLeft)="onKeydownLeftArrow($event)"
	(keydown.ArrowRight)="onKeydownRightArrow($event)"
	(keydown.enter)="onEnter($event)">
	<mat-select-trigger class="hide-border">
    <span class="e-input-group e-control-wrapper e-extra-small e-ddl e-lib" #tooltipElement>
      <input tabindex="-1" role="textbox" type="text" class="e-input" readonly="" [value]="selectedAbbreviation ? selectedAbbreviation : ''">
		@if (showClearButton && hasSelectedLabel() && !ngControl.control.disabled) {
			<span class="e-clear-icon multiselect-close" (click)="clear($event)" role="button"></span>
		}
    </span>
	</mat-select-trigger>
	@if (shouldDisplayFilter) {
		<div class="e-ddl e-popup e-multi-select-list-wrapper">
      <span class="e-filter-parent">
        <span class="e-input-group e-control-wrapper e-input-focus">
          <input [revFocusInput]="focusSearchBar" class="e-input-filter e-input focus-visible" [placeholder]="filterBarPlaceholder" (input)="filter()"
				 [ngModelOptions]="{standalone: true}" [(ngModel)]="filterText" (keydown.enter)="onEnter($event)"/>
        </span>
      </span>
		</div>
	}
	@if (!filteredOptions) {
		@for (option of dataSource; track trackByValue($index, option)) {
			<mat-option [value]="option[valueField]">{{ option[labelField] }}</mat-option>
		}
	}
	@if (filteredOptions) {
		@for (option of filteredOptions; track trackByValue($index, option)) {
			<mat-option [value]="option[valueField]">{{ option[labelField] }}</mat-option>
		}
	}
</mat-select>
