// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.datalog.DataLogDiffResponse */
/* istanbul ignore next */
@GandalfModel
export class DataLogDiffResponse extends GandalfModelBase {

	@GandalfConstantDecorator()
	@GandalfProperty()
	currentDataLogAction: constants.DataLogAction;

	@GandalfProperty()
	currentDataLogXml: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	previousDataLogAction: constants.DataLogAction;

	@GandalfProperty()
	previousDataLogXml: string;

}
