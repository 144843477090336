// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.OrderStatusHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class OrderStatusHistoryResponse extends GandalfModelBase {

	@GandalfProperty()
	description: string;

	@GandalfProperty()
	endDate: Date;

	@GandalfProperty()
	orderId: number;

	@GandalfProperty()
	orderOnHoldHistoryId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	orderStatusCode: constants.OrderStatusCode;

	@GandalfProperty()
	orderStatusId: number;

	@GandalfProperty()
	parentHistoryId: number;

	@GandalfProperty()
	startDate: Date;

	@GandalfProperty()
	statusLabel: string;

	@GandalfProperty()
	updatedBy: string;

}
