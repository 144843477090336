@if (hasLocationId()) {
	<img class="h75 wAuto margin-bottom-sm"
		 alt="" [src]="practiceLocationLogoUrl"
		 (load)="onLogoLoaded($event)"
		 (error)="onLogoLoadingFailed($event)"/>
}

<ng-content select="[pms-print-header]"></ng-content>
<ng-content select="[pms-print-body]"></ng-content>
<ng-content select="[pms-print-footer]"></ng-content>
