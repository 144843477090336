import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { CreateProductBrandCollectionRequest } from '@gandalf/model/create-product-brand-collection-request';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';
import { ManufacturerService } from '../../../manufacturer.service';

@Component({
	selector: 'pms-product-brand-collection-modal',
	templateUrl: './product-brand-collection-modal.component.html',
	styles: [],
})
export class ProductBrandCollectionModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('componentForm')
	componentForm: NgForm;

	formGroup: UntypedFormGroup;
	request: CreateProductBrandCollectionRequest;
	productBrandId: number;

	constructor(
		public dynamicModalRef: DynamicModalRef,
		public modalConfig: ModalConfig,
		public manufacturerService: ManufacturerService,
		public gandalfFormBuilder: GandalfFormBuilder,
	) {
	}

	ngOnInit() {
		this.productBrandId = this.modalConfig.data.productBrandId;
		this.createForm();
	}

	addProductBrandCollection() {
		const request = this.formGroup.value;
		request.productBrandId = this.productBrandId;

		this.manufacturerService.addProductBrandCollection(request).subscribe(() => {
			this.closeModal(true);
		});
	}

	createForm() {
		this.request = new CreateProductBrandCollectionRequest();
		this.request.productBrandId = this.productBrandId;
		this.formGroup = this.gandalfFormBuilder.group(this.request);
	}

	closeModal(refreshRequested: boolean) {
		this.dynamicModalRef.close(this.modal, refreshRequested);
	}

	submitForm(event: any) {
		this.componentForm.onSubmit(event);
	}
}
