// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientPodContactLensEyeResponse } from './patient-pod-contact-lens-eye-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.patient.pod.PatientPodContactLensResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientPodContactLensResponse extends GandalfModelBase {

	@GandalfProperty()
	authorizedBy: PersonNameResponse;

	@GandalfProperty()
	contactLensPrescriptionId: number;

	@GandalfProperty()
	contactLensTrialId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	expirationDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	lensType: constants.ContactLensPrescriptionType;

	@GandalfProperty()
	od: PatientPodContactLensEyeResponse;

	@GandalfProperty()
	odDispensed: boolean;

	@GandalfProperty()
	os: PatientPodContactLensEyeResponse;

	@GandalfProperty()
	osDispensed: boolean;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.PrescriptionCurrentStatus;

}
