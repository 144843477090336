import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { DynamicModalRef, EnumUtil, ModalConfig } from 'morgana';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { FormattedPatientNameResponseForDropdown, PatientFamilyService } from '@core/patient/family/patient-family.service';
import { PatientService } from '@core/patient/patient.service';
import { UserLocationsService } from '@core/user-locations/user-locations.service';
import { FamilyCreditRequest } from '@gandalf/model/family-credit-request';
import { FamilyRefundRequest } from '@gandalf/model/family-refund-request';
import { GrantCreditsRequest } from '@gandalf/model/grant-credits-request';
import { InsuranceRefundRequest } from '@gandalf/model/insurance-refund-request';
import { PatientCreditRequest } from '@gandalf/model/patient-credit-request';
import { PatientRefundRequest } from '@gandalf/model/patient-refund-request';
import { PayerType, RefundFrom } from '@gandalf/constants';
import { PatientNameResponse } from '@gandalf/model/patient-name-response';
import { assertTrue } from '@shared/validators/assert-true.validation';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { ChangeEventArgs } from '@syncfusion/ej2-buttons';
import { Big } from 'big.js';
import { GandalfFormBuilder } from 'gandalf';
import { combineLatest } from 'rxjs';
import { OptionItemResponse } from '@core/option-item/option-item.service';
import { PracticeLocation } from '@core/security-manager/security-manager.service';
import { AccountingService } from '../../core/accounting/accounting.service';
import { InvoiceItemPricingUtil } from '../../core/accounting/invoice-item-pricing/invoice-item-pricing-util';
import { InvoiceService } from '../../core/accounting/invoice-service/invoice.service';

@Component({
	selector: 'pms-grant-credit-modal',
	templateUrl: './grant-credit-modal.component.html',
	styles: [],
})
export class GrantCreditModalComponent implements OnInit {
	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('templateForm')
	templateForm: NgForm;

	request: GrantCreditsRequest;
	patient: PatientNameResponse;
	componentForm: UntypedFormGroup;
	invoiceId: number;
	currentBalance: number;
	hasPatientCredit = false;
	hasFamilyCredit = false;
	hasPatientRefund = false;
	hasFamilyRefund = false;
	hasInsuranceRefund = false;
	hasNoFamily = true;
	familyMembers: FormattedPatientNameResponseForDropdown[];
	methods = RefundFrom.VALUES;
	locations: OptionItemResponse<PracticeLocation, number>[];
	isInsuranceInvoice: boolean;
	endingBalance: number;

	constructor(
		public ref: DynamicModalRef,
		public modalConfig: ModalConfig,
		public accountingService: AccountingService,
		public gandalfFormBuilder: GandalfFormBuilder,
		public patientService: PatientService,
		public patientFamilyService: PatientFamilyService,
		public userLocationsService: UserLocationsService,
		public invoiceService: InvoiceService,
	) {
	}

	ngOnInit() {
		this.initializeRequest();
		this.initializeForm(this.request);
		this.locations = this.userLocationsService.getUserActiveLocations();
		this.accountingService.getInvoiceById(this.modalConfig.data.invoiceId).subscribe((invoice) => {
			this.handleInvoice(invoice);
		});
	}

	handleInvoice(invoice) {
		this.invoiceId = invoice.id;
		this.isInsuranceInvoice = EnumUtil.equals(invoice.payerType, PayerType.INSURANCE);
		this.componentForm.get('patientRefund.locationId').setValue(invoice.locationId);
		this.componentForm.get('familyRefund.locationId').setValue(invoice.locationId);
		this.componentForm.get('insuranceRefund.locationId').setValue(invoice.locationId);
		this.currentBalance = invoice.balance;
		combineLatest([
			this.patientService.getPatientNameById(invoice.patientId),
			this.patientFamilyService.getFamilyMembersByPatientIdForDropdown(invoice.patientId),
		]).subscribe(([patient, familyMembers]) => {
			this.patient = patient;
			this.familyMembers = familyMembers;
			if (this.familyMembers.length > 0) {
				this.hasNoFamily = false;
				this.componentForm.get('familyCredit.familyMemberPatientId').setValue(this.familyMembers[0].value);
				this.componentForm.get('familyRefund.familyMemberPatientId').setValue(this.familyMembers[0].value);
			} else {
				this.hasNoFamily = true;
			}
		});
	}

	initializeRequest() {
		const request = new GrantCreditsRequest();
		request.invoiceId = this.modalConfig.data.invoiceId;
		request.patientCredit = new PatientCreditRequest();
		request.patientCredit.amount = 0;
		request.familyCredit = new FamilyCreditRequest();
		request.familyCredit.amount = 0;
		request.patientRefund = new PatientRefundRequest();
		request.patientRefund.amount = 0;
		request.familyRefund = new FamilyRefundRequest();
		request.familyRefund.amount = 0;
		request.insuranceRefund = new InsuranceRefundRequest();
		request.insuranceRefund.amount = 0;
		this.request = request;
	}

	initializeForm(request: GrantCreditsRequest) {
		const endingBalanceAssertion = () => (this.endingBalance <= 0);
		const atLeastOneSelectedAssertion = () => (this.hasPatientCredit || this.hasFamilyCredit || this.hasPatientRefund || this.hasFamilyRefund || this.hasInsuranceRefund);
		this.componentForm = this.gandalfFormBuilder.group(request, {
			validators: [
				assertTrue(endingBalanceAssertion, [], 'Ending Balance', 'Ending balance cannot be greater than $0.00'),
				assertTrue(atLeastOneSelectedAssertion, [], 'NoCreditSelected', 'At least one credit must be selected'),
			],
		});
		this.enableDisableCredits();
		FormUtilsService.reactToValueChanges(this.componentForm, this.calculateEndingBalance, true);
	}

	closeModal() {
		this.ref.close(this.modal);
	}

	calculateEndingBalance = () => {
		let newBalance = new Big(this.currentBalance || 0);
		if (this.hasPatientCredit) {
			newBalance = InvoiceItemPricingUtil.calculateBalance(newBalance, this.componentForm.get('patientCredit.amount').value);
		}
		if (this.hasFamilyCredit) {
			newBalance = InvoiceItemPricingUtil.calculateBalance(newBalance, this.componentForm.get('familyCredit.amount').value);
		}
		if (this.hasPatientRefund) {
			newBalance = InvoiceItemPricingUtil.calculateBalance(newBalance, this.componentForm.get('patientRefund.amount').value);
		}
		if (this.hasFamilyRefund) {
			newBalance = InvoiceItemPricingUtil.calculateBalance(newBalance, this.componentForm.get('familyRefund.amount').value);
		}
		if (this.hasInsuranceRefund) {
			newBalance = InvoiceItemPricingUtil.calculateBalance(newBalance, this.componentForm.get('insuranceRefund.amount').value);
		}
		this.endingBalance = Number(newBalance);
	};

	enableDisableCredits() {
		FormUtilsService.enabledWhen(this.componentForm.get('patientCredit'), this.hasPatientCredit);
		if (!this.hasPatientCredit) {
			this.componentForm.get('patientCredit.amount').setValue(0);
		}

		FormUtilsService.enabledWhen(this.componentForm.get('familyCredit'), this.hasFamilyCredit);
		if (!this.hasFamilyCredit) {
			this.componentForm.get('familyCredit.amount').setValue(0);
		}

		FormUtilsService.enabledWhen(this.componentForm.get('patientRefund'), this.hasPatientRefund);
		if (!this.hasPatientRefund) {
			this.componentForm.get('patientRefund.amount').setValue(0);
		}

		FormUtilsService.enabledWhen(this.componentForm.get('familyRefund'), this.hasFamilyRefund);
		if (!this.hasFamilyRefund) {
			this.componentForm.get('familyRefund.amount').setValue(0);
		}

		FormUtilsService.enabledWhen(this.componentForm.get('insuranceRefund'), this.hasInsuranceRefund);
		if (!this.hasInsuranceRefund) {
			this.componentForm.get('insuranceRefund.amount').setValue(0);
		}
	}

	submit() {
		this.componentForm.updateValueAndValidity();
		if (this.componentForm.invalid) {
			return;
		}
		this.accountingService.grantInvoiceCredits(this.componentForm.value).subscribe(() => {
			this.invoiceService.refreshInvoice(this.invoiceId);
			this.closeModal();
		});
	}

	/* istanbul ignore next */
	handleCheckbox(_event: ChangeEventArgs) {
		this.enableDisableCredits();
	}

	/* istanbul ignore next */
	submitForm(event) {
		this.templateForm.onSubmit(event);
	}
}
