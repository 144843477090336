// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AdminEmployeeExternalSystemsRequest } from './admin-employee-external-systems-request';

// @ts-ignore
import { AdminEmployeeLocationAccessRequest } from './admin-employee-location-access-request';

// @ts-ignore
import { AdminEmployeeLoginRestrictionRequest } from './admin-employee-login-restriction-request';

// @ts-ignore
import { AdminEmployeeProviderDetailsRequest } from './admin-employee-provider-details-request';

// @ts-ignore
import { AdminEmployeeRolesRequest } from './admin-employee-roles-request';

// @ts-ignore
import { AdminUpdateEmployeeLoginDetailsRequest } from './admin-update-employee-login-details-request';

// @ts-ignore
import { EmployeePreferencesRequest } from './employee-preferences-request';

// @ts-ignore
import { PersonDetailsRequest } from './person-details-request';

/** See com.rev360.pms.api.controller.admin.employee.detailed.request.AdminUpdateEmployeeRequest */
/* istanbul ignore next */
@GandalfModel
export class AdminUpdateEmployeeRequest extends GandalfModelBase {

	@GandalfSubRequestLabel('External System IDs')
	@GandalfValidator({ notNull: { message: 'External System Information is required' } })
	@GandalfProperty({ isRequired: true })
	adminEmployeeExternalSystems: AdminEmployeeExternalSystemsRequest;

	@GandalfSubRequestLabel('Location Access')
	@GandalfValidator({ notNull: { message: 'Employee Location Access is required' } })
	@GandalfProperty({ isRequired: true })
	adminEmployeeLocationAccess: AdminEmployeeLocationAccessRequest;

	@GandalfSubRequestLabel('Login Details')
	@GandalfValidator({ notNull: { message: 'Login Restriction details are required' } })
	@GandalfProperty({ isRequired: true })
	adminEmployeeLoginRestriction: AdminEmployeeLoginRestrictionRequest;

	@GandalfSubRequestLabel('Provider Details')
	@GandalfProperty()
	adminEmployeeProviderDetails: AdminEmployeeProviderDetailsRequest;

	@GandalfSubRequestLabel('User Roles')
	@GandalfValidator({ notNull: { message: 'Employee roles are required' } })
	@GandalfProperty({ isRequired: true })
	adminEmployeeRoles: AdminEmployeeRolesRequest;

	@GandalfSubRequestLabel('Login Details')
	@GandalfValidator({ notNull: { message: 'Login details are required' } })
	@GandalfProperty({ isRequired: true })
	adminUpdateEmployeeLoginDetails: AdminUpdateEmployeeLoginDetailsRequest;

	@GandalfValidator({ notNull: { message: 'Employee is required' } })
	@GandalfProperty({ isRequired: true })
	employeeId: number;

	@GandalfValidator({ notNull: { message: 'Employee is provider is required' } })
	@GandalfProperty({ isRequired: true })
	employeeIsProvider: boolean;

	@GandalfSubRequestLabel('Preferences')
	@GandalfValidator({ notNull: { message: 'Employee Preferences are required' } })
	@GandalfProperty({ isRequired: true })
	employeePreferences: EmployeePreferencesRequest;

	@GandalfSubRequestLabel('Personal Details')
	@GandalfValidator({ notNull: { message: 'Personal details are required' } })
	@GandalfProperty({ isRequired: true })
	personDetails: PersonDetailsRequest;

}
