import { statefulKeyKey } from '@app-store/decorators/stateful-key.decorator';
import { StatefulBaseComponent } from '@app-store/stateful-base.component';
import { _every, _filter, _isNil, _pick } from '@core/lodash/lodash';

export interface DestructuredStoreKey {
	name: string;
	keys: {[key: string]: any};
}

export class StatefulComponentUtil {

	/**
	 * Given a name, typically a component name, and potentially a set of identifying keys, typically an object
	 * with identifying properties e.g. patientId, encounterId, return a string beginning with the componentName
	 * followed by a JSON string of given object
	 */
	static buildStoreKey(name: string, keys?: {[key: string]: any}) {
		const keysString = keys ? JSON.stringify(keys, Object.keys(keys).sort()) : '';
		return name + keysString;
	}

	/**
	 * The opposite of {@link buildStoreKey} given a property formatted string, aka as storeKey,
	 * will return a {@link DestructuredStoreKey} with the name of the component and an object of keys
	 */
	static destructureStoreKey(storeKey: string): DestructuredStoreKey {
		const keysIndex = storeKey.indexOf('{');
		return {
			name: storeKey.substring(0, keysIndex !== -1 ? keysIndex : storeKey.length),
			keys: keysIndex !== -1 ? JSON.parse(storeKey.slice(keysIndex)) : undefined,
		};
	}

	/**
	 * Given a stateMap, name (likely a componentName), and a set of keys will return an array of states that
	 * matches with the name and all given keys.
	 */
	static findAllEntries(stateMap: Map<string, any>, name: string, keys: {[key: string]: any}) {
		return _filter(Array.from(stateMap), ([storeKey, _storeKeyValue]) => {
			const destructuredStoreKey = StatefulComponentUtil.destructureStoreKey(storeKey);

			if (destructuredStoreKey.name !== name) {
				return false;
			}

			if (!_isNil(keys) && !_isNil(destructuredStoreKey.keys)) {
				return _every(keys, (value, index) => destructuredStoreKey.keys[index] === value);
			} else {
				return false;
			}
		});
	}

	/**
	 * Given a StatefulBaseComponent return a string containing the componentName followed by a JSON string of its StatefulKeys
	 */
	static buildStoreKeyFromInstance(instance: StatefulBaseComponent): string {
		const cacheKeys = StatefulComponentUtil.compileCacheKeysFromInstance(instance);
		return StatefulComponentUtil.buildStoreKey(instance.componentName, cacheKeys);
	}

	/**
	 * Given a StatefulBaseComponent will return an object that has all properties in the component marked with @StatefulKey()
	 * example return object {encounterId: 407, patientId: 932}
	 */
	static compileCacheKeysFromInstance(instance: StatefulBaseComponent): object {
		const cacheKeys = Reflect.getMetadata(statefulKeyKey, instance);
		return cacheKeys ? _pick(instance, cacheKeys) : undefined;
	}
}
