import { Directive, HostListener } from '@angular/core';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { _isArrayLike, _isNil } from '../../utils/lodash/lodash';

const FILTER_THRESHOLD = 8;

@Directive({
	/* eslint-disable-next-line @angular-eslint/directive-selector */
	selector: 'ejs-dropdownlist:not([revOverrideDropdownlistBase])',
})
export class DropdownlistBaseDirective {

	popupOpen = false;

	constructor(
		private dropdownListComponent: DropDownListComponent,
	) {
		this.populateBaseProperties();
	}

	populateBaseProperties() {
		this.dropdownListComponent.fields = {text: 'label', value: 'value'};

		// We will default filtering to true, for proper SyncFusion initialization
		this.dropdownListComponent.allowFiltering = true;
	}

	/**
	 * Determine if dropdownlist should show a filter bar
	 * Checks if dataSource is not null/undefined and if the dataSource has a length property
	 * Lastly checks to see if length of dataSource is equal to or above the configured threshold
	 */
	shouldFilter() {
		if (_isNil(this.dropdownListComponent.dataSource) || !_isArrayLike(this.dropdownListComponent.dataSource)) {
			return false;
		} else {
			return (this.dropdownListComponent.dataSource as Array<any>).length >= FILTER_THRESHOLD;
		}
	}

	@HostListener('actionBegin')
	actionBegin() {
		// do not update allowFiltering property if popup is open to avoid syncfusion bug
		if (!this.popupOpen) {
			// note beforeOpen calls the same code due to another syncfusion bug with dynamic dropdown options
			this.dropdownListComponent.allowFiltering = this.shouldFilter();
		}
	}

	@HostListener('beforeOpen')
	beforeOpen() {
		this.dropdownListComponent.allowFiltering = this.shouldFilter();
		this.popupOpen = true;
	}

	@HostListener('close')
	close() {
		this.popupOpen = false;
	}

}
