/* eslint-disable eqeqeq */
/* eslint-disable max-len */
import { GandalfConstant, GandalfConstantList } from 'gandalf';
import { _cloneDeep, _filter, _find, _isNil } from '../lodash/lodash';

export interface EnumClass<GandalfConstantType extends GandalfConstant<any>> {
	VALUES: GandalfConstantList<GandalfConstantType>;
}

export class EnumUtil {

	static mapValuesToEnum<ValueType, GandalfConstantType extends GandalfConstant<ValueType>>(valueArray: ValueType[], enumList: EnumClass<GandalfConstantType>): GandalfConstantType[] {
		if (!valueArray) {
			return null;
		}

		return valueArray.map(value => this.findEnumByValue(value, enumList));
	}

	static pullValuesFromEnumList<ValueType>(enumList: GandalfConstant<ValueType>[]): ValueType[] {
		return enumList.map(enumConstant => enumConstant.value);
	}

	static findEnumByValue<ValueType, GandalfConstantType extends GandalfConstant<ValueType>>(value: ValueType, enumClass: EnumClass<GandalfConstantType>): GandalfConstantType | undefined {
		return enumClass.VALUES.values.find(enumSearch => enumSearch.value === value);
	}

	static includesEnum<GandalfConstantType extends GandalfConstant<any>>(enumArray: GandalfConstantType[], enumValue: GandalfConstantType): boolean {
		if (!enumArray) {
			return false;
		}

		return Object.values(enumArray).includes(enumValue);
	}

	static isNullOrEqual<GandalfConstantType extends GandalfConstant<any>>(a: GandalfConstantType, b: GandalfConstantType) {
		if (_isNil(a) && _isNil(b)) {
			return true;
		}
		return EnumUtil.equals(a, b);
	}

	static equals<GandalfConstantType extends GandalfConstant<any>>(a: GandalfConstantType, b: GandalfConstantType) {
		return a && b && a.value == b.value;
	}

	static equalsOneOf<GandalfConstantType extends GandalfConstant<any>>(constant: GandalfConstantType, ...others: GandalfConstantType[]) {
		return constant && others && !!others.find(otherConstant => this.equals(constant, otherConstant));
	}

	/**
	 * This will return the values in the second list but ordered using the first list
	 * @param orderedValues - All possible values in the correct order
	 * @param expectedValues - The values that should be returned
	 */
	static enumOrderedSubset<GandalfConstantType extends GandalfConstant<any>>(orderedValues: GandalfConstantType[], expectedValues: GandalfConstantType[]): GandalfConstantType[] {
		return [...orderedValues]
			.filter(pref => !!expectedValues.find(myPref => myPref.value === pref.value));
	}

	/**
	 * This will return a new array of values with the second array removed from the first
	 * @param originalValues - The original values on the GandalfConstant
	 * @param removeValues - The values that should be removed
	 */
	static removeValues<GandalfConstantType extends GandalfConstant<any>>(originalValues: GandalfConstantType[], removeValues: GandalfConstantType[]): GandalfConstantType[] {
		return _filter(originalValues, originalValue => !_find(removeValues, removeValue => removeValue.value === originalValue.value));
	}

	/**
	 * This will return a GandalfConstantsList from an array of Gandalf constants
	 * @param enumList - Array values of GandalfConstants
	 * @param label - Gandalf constants label
	 */
	static buildGandalfEnumSubsetList<GandalfConstantType extends GandalfConstant<any>>(enumList: GandalfConstantType[], label: string): GandalfConstantList<GandalfConstantType> {
		return {label, values: enumList};
	}

	/**
	 * Adds the value in front of the constant label
	 * @param enumValues - The values list of the enum to use, i.e. FacilityType.VALUES.
	 * @usage - this.facilityTypes = EnumUtil.prependValuesToLabels(FacilityType.VALUES);
	 */
	static prependValuesToLabels<GandalfConstantType extends GandalfConstant<any>>(enumValues: GandalfConstantList<GandalfConstantType>): GandalfConstantList<GandalfConstantType> {
		const constantList = _cloneDeep(enumValues);
		constantList.values.map(constant => {
			if (constant['_label'].length > 0) {
				constant['_label'] = `${constant.value} - ${constant.label}`;
			}
		});
		return constantList;
	}
}
