<div [class]="styleClass" [ngStyle]="style" [ngClass]="'ui-panelmenu ui-widget'">
	@for (item of model; track item; let f = $first; let l = $last) {
		<div class="ui-panelmenu-panel" [ngClass]="{'ui-helper-hidden': item.visible === false}">
			<div [ngClass]="{'ui-widget ui-panelmenu-header ui-state-default':true,'ui-corner-top':f,'ui-corner-bottom':l&&!item.expanded,
                    'ui-state-active':item.expanded,'ui-state-disabled':item.disabled}" [class]="item.styleClass" [ngStyle]="item.style">
				@if (!item.routerLink) {
					<a [href]="item.url||'#'" (click)="handleClick($event,item)"
					   [attr.target]="item.target" [attr.title]="item.title" class="ui-panelmenu-header-link"
					   [attr.data-test-id]="item.automationId">
						@if (item.items) {
							<span class="ui-panelmenu-icon fa" [ngClass]="{'fa-chevron-right':!item.expanded,'fa-chevron-down':item.expanded}"></span
							>
						}
						@if (item.icon) {
							<span class="ui-menuitem-icon" [ngClass]="item.icon"></span
							>
						}<span class="ui-menuitem-text">{{ item.label }}</span>
					</a>
				}
				@if (item.routerLink) {
					<a [routerLink]="item.routerLink" [queryParams]="item.queryParams" [routerLinkActive]="'ui-state-active'"
					   [routerLinkActiveOptions]="item.routerLinkActiveOptions||{exact:false}"
					   (click)="handleClick($event,item)" [attr.target]="item.target" [attr.title]="item.title" class="ui-panelmenu-header-link"
					   [attr.data-test-id]="item.automationId">
						@if (item.items) {
							<span class="ui-panelmenu-icon fa " [ngClass]="{'fa-chevron-right':!item.expanded,'fa-chevron-down':item.expanded}"></span
							>
						}
						@if (item.icon) {
							<span class="ui-menuitem-icon" [ngClass]="item.icon"></span
							>
						}<span class="ui-menuitem-text">{{ item.label }}</span>
					</a>
				}
			</div>
			@if (item.items) {
				<div class="ui-panelmenu-content-wrapper"
					 [@rootItem]="item.expanded ? {value: 'visible', params: {transitionParams: transitionOptions}} : {value: 'hidden', params: {transitionParams: transitionOptions}}"
					 (@rootItem.done)="onToggleDone()"
					 [ngClass]="{'ui-panelmenu-content-wrapper-overflown': !item.expanded||animating}">
					<div class="ui-panelmenu-content ui-widget-content">
						<pms-panel-menu-sub (itemSelected)="onItemSelection($event)" [selectedItem]="selectedItem" [item]="item" [noRouterLinks]="noRouterLinks"
											[expanded]="true" [transitionOptions]="transitionOptions" class="ui-panelmenu-root-submenu"></pms-panel-menu-sub>
					</div>
				</div>
			}
		</div>
	}
</div>
