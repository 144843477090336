<ejs-dialog
		#modal
		cssClass="modal-lg"
		(close)="closeModal()"
		[attr.data-test-id]="'pdViewHistoryModal'">
	<ng-template #header>
		Select PD
	</ng-template>
	<ng-template #content>
		<rev-ag-grid-container [agGrid]="agGrid">
			<ng-container rev-table-action-menu-left>
				<div class="e-toolbar-item">
					<label>Source</label>
					<pms-enum-select-button
							name="sourceFilter"
							[(ngModel)]="sourceFilter"
							[enumList]="sourceFilterOptions"
							[allOption]="true"
                            (valueChange)="filterTable()">
					</pms-enum-select-button>
				</div>
			</ng-container>
			<ng-container rev-container-grid>
				<ag-grid-angular class="ag-theme-alpine"
								 pmsAgAutoFitColumns
								 #agGrid
								 [rowData]="filteredPdHistory"
								 [pmsAgGridSubmit]="isSearching"
								 (rowClicked)="onPdSelected($event)"
								 [gridOptions]="pdHistoryGridOptions"
								 (gridReady)="onGridReady()"
								 [suppressCsvExport]="true"
								 [pagination]="false"
								 [attr.data-test-id]="'pdViewHistoryModalTable'">
				</ag-grid-angular>
			</ng-container>
		</rev-ag-grid-container>
	</ng-template>
	<ng-template #footerTemplate>
		<button type="button" ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'pdViewHistoryModalCancelButton'">Cancel</button>
	</ng-template>
</ejs-dialog>

<ng-template #eyeTemplate let-data>
	<span class="label label-success">OD</span>
	<br/>
	<span class="label label-info">OS</span>
</ng-template>

<ng-template #monoDistPdTemplate let-data>
	<div class="nostretch">
		<span>{{prescriptionUtil.formatPupillaryDistance(data.odMonoDistancePupillaryDistance)}} </span>
		<br/>
		<span>{{prescriptionUtil.formatPupillaryDistance(data.osMonoDistancePupillaryDistance)}} </span>
	</div>
</ng-template>

<ng-template #monoNearPdTemplate let-data>
	<span>{{prescriptionUtil.formatPupillaryDistance(data.odMonoNearPupillaryDistance)}} </span>
	<br/>
	<span>{{prescriptionUtil.formatPupillaryDistance(data.osMonoNearPupillaryDistance)}} </span>
</ng-template>
