export abstract class StatefulServiceBase {
	private _storeKey: string;

	get storeKey(): string {
		return this._storeKey;
	}

	set storeKey(key: string) {
		this._storeKey = key;
	}
}
