// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.CreatePatientOtherInformationRequest */
/* istanbul ignore next */
@GandalfModel
export class CreatePatientOtherInformationRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Alternate ID cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	alternateId: string;

	@GandalfValidator({ notNull: { message: 'DOB is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	dob: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	establishedStatus: constants.EstablishedStatus;

	@GandalfProperty()
	ethnicityDeclined: boolean;

	@GandalfArray(Number)
	ethnicityIds: number[];

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Gender is required' } })
	@GandalfProperty({ isRequired: true })
	gender: constants.Gender;

	@GandalfConstantDecorator()
	@GandalfProperty()
	genderIdentity: constants.GenderIdentity;

	@GandalfProperty()
	guarantorId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	interpreterAssistance: constants.PersonInterpreterAssistance;

	@GandalfProperty()
	languageDeclined: boolean;

	@GandalfProperty()
	languageId: number;

	@GandalfValidator({ sizeString: { message: 'Last 4 SSN must be 4 characters in length', minLength: 4, maxLength: 4 } })
	@GandalfProperty()
	last4Ssn: string;

	@GandalfProperty()
	raceDeclined: boolean;

	@GandalfArray(Number)
	raceIds: number[];

	@GandalfConstantDecorator()
	@GandalfProperty()
	sexualOrientation: constants.SexualOrientation;

	@GandalfValidator({ sizeString: { message: 'SIN/PHN cannot be longer than 128 characters', minLength: 0, maxLength: 128 } })
	@GandalfValidator({ sin: { message: 'SIN/PHN must only contain alphanumeric or dash characters' } })
	@GandalfProperty()
	sin: string;

	@GandalfValidator({ sizeString: { message: 'SSN cannot be longer than 128 characters', minLength: 0, maxLength: 128 } })
	@GandalfValidator({ socialSecurityNumber: { message: 'Social security number must contain 9 digits' } })
	@GandalfProperty()
	ssn: string;

}
