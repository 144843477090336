// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FieldDefinitionResponse } from './field-definition-response';

/** See com.rev360.pms.api.controller.encounter.dynamic.form.FieldDefinitionReviewOfSystemsResponse */
/* istanbul ignore next */
@GandalfModel
export class FieldDefinitionReviewOfSystemsResponse extends GandalfModelBase {

	@GandalfProperty()
	cardiovascularFieldDefinition: FieldDefinitionResponse;

	@GandalfProperty()
	constitutionalFieldDefinition: FieldDefinitionResponse;

	@GandalfProperty()
	endocrineFieldDefinition: FieldDefinitionResponse;

	@GandalfProperty()
	entFieldDefinition: FieldDefinitionResponse;

	@GandalfProperty()
	gastrointestinalFieldDefinition: FieldDefinitionResponse;

	@GandalfProperty()
	genitourinaryFieldDefinition: FieldDefinitionResponse;

	@GandalfProperty()
	hematLymphaticFieldDefinition: FieldDefinitionResponse;

	@GandalfProperty()
	immunologicalFieldDefinition: FieldDefinitionResponse;

	@GandalfProperty()
	musculoSkeletalFieldDefinition: FieldDefinitionResponse;

	@GandalfProperty()
	neuroFieldDefinition: FieldDefinitionResponse;

	@GandalfProperty()
	psychologicalFieldDefinition: FieldDefinitionResponse;

	@GandalfProperty()
	respiratoryFieldDefinition: FieldDefinitionResponse;

	@GandalfProperty()
	skinFieldDefinition: FieldDefinitionResponse;

}
