import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { ErrorMappings, HttpError } from '@core/global-error-handler/http-error.class';
import { _isNil } from '@core/lodash/lodash';

@Injectable({
	providedIn: 'root',
})
export class HttpErrorService {

	errorMappings: ErrorMappings;

	constructor(
		private eventManager: EventsManagerService,
	) {
		this.errorMappings = new ErrorMappings(this.eventManager);
	}

	getAlertModel(error: HttpErrorResponse) {
		const customErrorMessage = this.getCustomErrorMessage(error);
		const customAction = this.getCustomErrorAction(error);
		const errorModel = new HttpError(error);

		if (customAction) {
			customAction();
			return null;
		}

		if (customErrorMessage) {
			errorModel.content = customErrorMessage;
			return errorModel;
		} else {
			return null;
		}
	}

	getCustomErrorMessage(error: HttpErrorResponse) {
		let customErrorMessage = null;
		const mappedError = this.getMappedError(error);

		if (!_isNil(mappedError)) {
			// use the mapped message if one is present, otherwise check if we should use the server message or call an action
			if (mappedError.message) {
				customErrorMessage = mappedError.message;
			} else if (mappedError.useServerMessage) {
				customErrorMessage = error.error ? error.error.message : null;
			} else {
				customErrorMessage = error.message;
			}
		}
		return customErrorMessage;
	}

	getCustomErrorAction(error: HttpErrorResponse) {
		let customAction = null;

		const mappedError = this.getMappedError(error);
		if (!_isNil(mappedError) && mappedError.action) {
			customAction = mappedError.action;
		}

		return customAction;
	}

	getMappedError(error: HttpErrorResponse) {
		return this.errorMappings.errorMappings[error.status];
	}
}
