import { Directive, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormGroup } from '@angular/forms';
import { ChangeEventArgs, MaskedTextBoxComponent } from '@syncfusion/ej2-angular-inputs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * Put this attribute directive on an ejs-maskedtextbox field,
 * in order to apply the proper masked format on a formControl
 */
@Directive({
	selector: 'ejs-maskedtextbox[pmsMaskedInput]',
})
export class MaskedInputDirective implements OnInit, OnDestroy {

	@Input() pmsMaskedInput: string;
	control: AbstractControl | null;

	isUpdating = false;
	unsubscribe: Subject<void> = new Subject<void>();

	constructor(
		public maskedTextBox: MaskedTextBoxComponent,
		public controlContainer: ControlContainer,
	) {
	}

	/**
	 * makes sure that any existing value on the form control is applied to the text box on initialization
	 */
	@HostListener('created')
	onCreated() {
		this.maskedTextBox.value = this.control.value;
	}

	/**
	 * when changes are made to the masked input, copy the full masked value over to the formControl
	 */
	@HostListener('change', ['$event'])
	onChange(event: ChangeEventArgs) {
		if (event.isInteracted) {
			this.isUpdating = true;

			// clear control value if masked input is empty
			if (!this.maskedTextBox.value) {
				this.updateControlValue('');
			} else {
				this.updateControlValue(this.maskedTextBox.getMaskedValue());
			}

			this.isUpdating = false;
		}
	}

	ngOnInit() {
		this.handleFormState();
	}

	ngOnDestroy() {
		if (!this.unsubscribe.closed) {
			this.unsubscribe.next();
			this.unsubscribe.complete();
		}
	}

	/* istanbul ignore next */
	handleFormState() {
		this.control = (this.controlContainer.control as UntypedFormGroup).get(this.pmsMaskedInput);
		this.control.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
			if (!this.isUpdating) {
				this.maskedTextBox.value = this.control.value;
			}
		});
	}

	/**
	 * Sets the control value to the masked input value.
	 * removes any underscore characters, as syncfusion's masked input box does not automatically remove them
	 */
	updateControlValue(maskedValue: string) {
		this.control.setValue(maskedValue.replace(/_/g, ''));
	}
}
