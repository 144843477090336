// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { BaseSalesReportResponse } from './base-sales-report-response';

/** See com.rev360.pms.api.controller.reporting.inventory.sales.EyeglassFrameSalesReportResponse */
/* istanbul ignore next */
@GandalfModel
export class EyeglassFrameSalesReportResponse extends BaseSalesReportResponse {

	@GandalfProperty({ propertyType: 'Money' })
	averageAgeOfSales: number;

	@GandalfProperty()
	brand: string;

	@GandalfProperty()
	bridge: number;

	@GandalfProperty()
	category: string;

	@GandalfProperty()
	collection: string;

	@GandalfProperty()
	color: string;

	@GandalfProperty()
	colorCode: string;

	@GandalfProperty({ propertyType: 'Money' })
	costOfSales: number;

	@GandalfProperty()
	eye: number;

	@GandalfProperty()
	itemDescription: string;

	@GandalfProperty()
	locationId: number;

	@GandalfProperty({ propertyType: 'Money' })
	locationProductActualCost: number;

	@GandalfProperty()
	locationProductItemId: number;

	@GandalfProperty()
	manufacturer: string;

	@GandalfProperty()
	model: string;

	@GandalfProperty({ propertyType: 'Money' })
	salesAdjustments: number;

	@GandalfProperty({ propertyType: 'Money' })
	salesDiscountAmount: number;

	@GandalfProperty({ propertyType: 'Money' })
	salesGrossSales: number;

	@GandalfProperty({ propertyType: 'Money' })
	salesNetSales: number;

	@GandalfProperty()
	salesQuantity: number;

	@GandalfProperty({ propertyType: 'Money' })
	salesTaxAmount: number;

	@GandalfProperty()
	sku: string;

	@GandalfProperty()
	stock: number;

	@GandalfProperty()
	temple: number;

	@GandalfProperty()
	upc: string;

	@GandalfProperty({ propertyType: 'Money' })
	wholesaleOfSales: number;

}
