import { Pipe, PipeTransform } from '@angular/core';
import { _isNil, _padStart } from '@core/lodash/lodash';

/**
 * @ngdoc pipe
 * @description
 * Take a number and make sure it's at least three digits long, i.e. 3 becomes 003.
 *
 * @param value - The number being padded.
 *
 * @usage
 * {{ 3 | padAxis }}
 */
@Pipe({name: 'padAxis'})
export class PadAxisPipe implements PipeTransform {

	transform(value: number, addX = false): string {
		if (_isNil(value)) {
			return '';
		}

		return (addX ? 'x ' : '') + _padStart(value.toString(), 3, '0');
	}
}
