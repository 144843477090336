import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalBase } from 'morgana';
import { PracticeLocationSummaryResponse } from '@gandalf/model/practice-location-summary-response';
import { GandalfFormBuilder, TypedFormGroup } from 'gandalf';
import { CreateTrizettoExternalCredentialRequest } from '@gandalf/model/create-trizetto-external-credential-request';
import { UpdateTrizettoExternalCredentialRequest } from '@gandalf/model/update-trizetto-external-credential-request';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { _isNil } from '@core/lodash/lodash';
import { NgForm } from '@angular/forms';
import { OptionItemResponse } from '@core/option-item/option-item.service';
import { PracticeLocation } from '@core/security-manager/security-manager.service';
import { TrizettoTemplateService } from '../trizetto-template.service';

interface TrizettoExternalCredentialData {
	trizettoExternalCredentialId: number;
	userName: string;
	password: string;
	assignedLocations: PracticeLocationSummaryResponse[];
	availableLocations: OptionItemResponse<PracticeLocation, number>[];
}

@Component({
	selector: 'pms-trizetto-external-credential-modal',
	templateUrl: './trizetto-external-credential-modal.component.html',
	styles: [],
})
export class TrizettoExternalCredentialModalComponent extends ModalBase<TrizettoExternalCredentialData, boolean> implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('ngForm')
	ngForm: NgForm;

	request: CreateTrizettoExternalCredentialRequest | UpdateTrizettoExternalCredentialRequest;
	formGroup: TypedFormGroup<CreateTrizettoExternalCredentialRequest | UpdateTrizettoExternalCredentialRequest>;
	isCreate = false;
	availableLocations: (PracticeLocationSummaryResponse | OptionItemResponse<PracticeLocation, number>)[];

	constructor(
		private gandalfFormBuilder: GandalfFormBuilder,
		private trizettoTemplateService: TrizettoTemplateService,
	) {
		super();
	}

	ngOnInit() {
		this.parseConfig();
	}

	parseConfig() {
		if (_isNil(this.data?.trizettoExternalCredentialId)) {
			this.isCreate = true;
			this.request = new CreateTrizettoExternalCredentialRequest();
			this.formGroup = this.gandalfFormBuilder.groupTyped(this.request);
		} else {
			const updateRequest = new UpdateTrizettoExternalCredentialRequest();
			updateRequest.revClearExternalCredentialId = this.data.trizettoExternalCredentialId;
			updateRequest.userName = this.data.userName;
			updateRequest.password = this.data.password;
			updateRequest.locationIds = this.data.assignedLocations.map(location => location.id);
			this.request = updateRequest;
			this.formGroup = this.gandalfFormBuilder.groupTyped(this.request);
		}
		this.availableLocations = [...this.data.availableLocations, ...(this.data.assignedLocations || [])];
	}

	/* istanbul ignore next: closeModal */
	closeModal(refreshData = false) {
		this.dynamicModalRef.close(this.modal, refreshData);
	}

	submitForm(event) {
		this.ngForm.onSubmit(event);
	}

	saveCredential() {
		if (this.formGroup.invalid) {
			return;
		}

		if (this.isCreate) {
			const createFormGroup = this.formGroup.value as CreateTrizettoExternalCredentialRequest;
			this.trizettoTemplateService.createTrizettoExternalCredential(createFormGroup).subscribe(() => this.closeModal(true));
		} else {
			const updateFormGroup = this.formGroup.value as UpdateTrizettoExternalCredentialRequest;
			this.trizettoTemplateService.updateTrizettoExternalCredential(updateFormGroup).subscribe(() => this.closeModal(true));
		}
	}

}
