import { _find, _get, _isNil } from '@core/lodash/lodash';
import { createSelector } from '@ngrx/store';
import { selectPatientsState } from '@patients-store/reducers';
import { EncounterHistoryStateMap } from '@patients-store/reducers/encounter-history.reducer';

export const selectEncounterHistoryState =
	createSelector(selectPatientsState, state => state.encounterHistory);

export const selectPatientEncounterHistory = (props: {patientId: number}) =>
	createSelector(selectEncounterHistoryState, (state: EncounterHistoryStateMap) => state.get(props.patientId));

export const selectPatientEncounter = (props: {patientId: number, encounterId: number}) =>
	createSelector(selectEncounterHistoryState, (state: EncounterHistoryStateMap) => {
		const patientEncounters = _get(state.get(props.patientId), ['encounters']);
		return !_isNil(patientEncounters) ? _find(patientEncounters, ['id', props.encounterId]) : undefined;
	});
