// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.consent.CreatePatientConsentRequest */
/* istanbul ignore next */
@GandalfModel
export class CreatePatientConsentRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	comments: string;

	@GandalfValidator({ notNull: { message: 'Consent Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	consentDate: Date;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Consent Mechanism is required' } })
	@GandalfProperty({ isRequired: true })
	consentMechanism: constants.PatientConsentMechanism;

	@GandalfProperty({ propertyType: 'LocalDate' })
	expirationDate: Date;

	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientId: number;

	@GandalfValidator({ notNull: { message: 'Consent Type is required' } })
	@GandalfProperty({ isRequired: true })
	practiceConsentFormId: number;

}
