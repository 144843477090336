<div class="margin-top-sm">
	<div class="pull-right margin-left-sm">
		@if (isInvoicePayerTypeInsurance()) {
			<button
				[disabled]="isPaymentProcessing"
				ejs-button
				type="button"
				(click)="openPreviewClaim()"
				[attr.data-test-id]="'invoiceHeaderPreviewClaimButton'">
				Preview Claim
			</button>
		}
		@if (canPrintClaim()) {
			<button
				[disabled]="isPaymentProcessing"
				ejs-button
				type="button"
				(click)="openPrintClaim()"
				[attr.data-test-id]="'invoiceHeaderPrintClaimButton'">
				Print Claim
			</button>
		}
		@if (canPrintClaim()) {
			<button
				[disabled]="isPaymentProcessing"
				ejs-button type="button"
				(click)="openViewDataFile()"
				[attr.data-test-id]="'invoiceHeaderViewDataFileButton'">
				View Data File
			</button>
		}
		@if (canPrintInvoice()) {
			<button
				[disabled]="isPaymentProcessing"
				ejs-button
				type="button"
				iconCss="fa fa-print"
				revTooltip
				tooltipContent="Print Invoice"
				[isPrimary]="true"
				(click)="openPrintInvoice()"
				[attr.data-test-id]="'invoicePrintButton'">
			</button>
		}
	</div>
	<div>
		<div class="margin-right-md display-inline-block vertical-align-middle">
			<rev-status-select-button
				[disabled]="!canChangeAuthorizedStatus()"
				[options]="approvedStatusSelectOptions"
				[(ngModel)]="invoice.approved"
				[ngModelOptions]="{standalone: true}"
				(onChange)="updateApproved($event)"
				[attr.data-test-id]="'invoiceReceiveUpdateButton'">
			</rev-status-select-button>
		</div>
		@if (canReceivePayment()) {
			<button
				ejs-button
				type="button"
				(click)="openReceivePaymentModal()"
				[disabled]="isPaymentProcessing"
				[attr.data-test-id]="'invoiceReceivePaymentButton'">Receive Payment
			</button>
		}
		<button ejs-button type="button" [disabled]="isPaymentProcessing" (click)="openDiagnosesModal()" [attr.data-test-id]="'invoiceHeaderDiagnosisButton'">
			Diagnoses
		</button>
		@if (canAddPatientPortion()) {
			<button ejs-button type="button" [disabled]="isPaymentProcessing" (click)="addPatientPortion()"
					[attr.data-test-id]="'invoiceHeaderPatientPortionButton'">Patient Portion
			</button>
		}
		@if (canAddFeeSchedule()) {
			<button ejs-button type="button" [disabled]="isPaymentProcessing" (click)="addFeeSchedule()" [attr.data-test-id]="'invoiceHeaderAddFeeButton'">Add
				Fee Schedule
			</button>
		}
		@if (canRemoveFeeSchedule()) {
			<button ejs-button type="button" [disabled]="isPaymentProcessing" (click)="removeFeeSchedule()"
					[attr.data-test-id]="'invoiceHeaderRemoveFeeButton'">Remove Fee Schedule
			</button>
		}
		@if (canDiscountItems()) {
			<button ejs-button type="button" [disabled]="isPaymentProcessing" (click)="discountItems()" [attr.data-test-id]="'invoiceHeaderDiscountButton'">
				Discount Items
			</button>
		}
		@if (canTransferItems()) {
			<button ejs-button type="button" [disabled]="isPaymentProcessing" (click)="transferItems()"
					[attr.data-test-id]="'invoiceHeaderTransferItemsButton'">Transfer Items
			</button>
		}
		<button
			ejs-dropdownbutton
			[disabled]="isPaymentProcessing"
			[items]="ellipsisItemList"
			(select)="onEllipsisItemSelect($event.item.id)"
			cssClass="e-caret-hide"
			iconCss="fa fa-ellipsis-h"
			[attr.data-test-id]="'invoiceHeaderEllipsisButton'">
		</button>
	</div>
</div>
