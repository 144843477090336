import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ButtonComponent } from '@syncfusion/ej2-angular-buttons';
import { interval, Observable, Subject } from 'rxjs';
import { takeUntil, tap, throttle } from 'rxjs/operators';

@Directive({
	/* eslint-disable-next-line @angular-eslint/directive-selector */
	selector: '[ejs-button][throttledClick]',
})
export class ThrottledClickDirective implements OnInit, OnDestroy {
	@Input()
	throttleDuration = 1000;

	@Input()
	disabledWhileThrottled = true;

	@Output()
	throttledClick: EventEmitter<void> = new EventEmitter();

	private unsubscribe: Subject<void> = new Subject<void>();

	private _clicks: Subject<void> = new Subject<void>();
	private _throttledClicks: Observable<void>;
	private _throttleInterval = interval(this.throttleDuration);

	constructor(
		private buttonComponent: ButtonComponent,
	) {}

	ngOnInit() {
		this._throttledClicks = this.clicks.pipe(
			throttle(this.getConfiguredThrottle()),
			takeUntil(this.unsubscribe),
		);

		this.throttledClicks.subscribe(() => {
			this.throttledClick.emit();
		});
	}

	ngOnDestroy() {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	@HostListener('click') onClick() {
		this._clicks.next();
	}

	private getConfiguredThrottle() {
		return () => {
			this.setDisabled(true);
			return this.throttleInterval.pipe(
				tap(() => this.setDisabled(false)),
			);
		};
	}

	private setDisabled(disabled: boolean) {
		if (this.disabledWhileThrottled) {
			this.buttonComponent.disabled = disabled;
		}
	}

	get clicks() {
		return this._clicks.asObservable();
	}

	get throttledClicks() {
		return this._throttledClicks;
	}

	get throttleInterval() {
		return this._throttleInterval;
	}
}
