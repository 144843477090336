import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, skip } from 'rxjs/operators';

export class ObservableUtils {

	constructor() {
	}

	/**
	 * Creates an Observable that only fires when the given BehaviorSubject value is set to a given value, i.e. whenever BehaviorSubject.next is called (even if
	 * the value is unchanged). This is intented to be used to simplify creating "events" from a BehaviorSubject without the boilerplate to skip the initial value
	 * and check the current value.
	 */
	static behaviorSubjectChange<Type>(subject: BehaviorSubject<Type>, value: Type): Observable<void> {
		// skip the current value of the BehaviorSubject, filter out wrong values and map to undefined (void)
		return subject.pipe(skip(1), filter(subjectValue => subjectValue === value), map(_ => undefined));
	}
}
