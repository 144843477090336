import { Component, ViewChild } from '@angular/core';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { SelectedDrug } from '@shared/interfaces/selected-drug';
import { FilterService } from '@syncfusion/ej2-angular-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
	selector: 'pms-common-medications-drug-search-modal',
	templateUrl: './common-medications-drug-search-modal.component.html',
	styles: [],
	providers: [FilterService],
})
export class CommonMedicationsDrugSearchModalComponent {

	@ViewChild('modal')
	modal: DialogComponent;

	query: string;

	constructor(
		public dynamicModalRef: DynamicModalRef,
		private modalConfig: ModalConfig,
	) {
		this.query = this.modalConfig.data.query;
	}

	closeModal(selectedDrug?: SelectedDrug) {
		this.dynamicModalRef.close(this.modal, selectedDrug);
	}
}
