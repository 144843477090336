// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.connect.campaign.ConnectCampaignListResponse */
/* istanbul ignore next */
@GandalfModel
export class ConnectCampaignListResponse extends GandalfModelBase {

	@GandalfProperty()
	connectCampaignId: number;

	@GandalfProperty()
	createdOn: Date;

	@GandalfProperty()
	ineligibleCount: number;

	@GandalfProperty()
	name: string;

	@GandalfProperty()
	openedCount: number;

	@GandalfProperty()
	optedOutCount: number;

	@GandalfProperty()
	queryName: string;

	@GandalfProperty()
	scheduledDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.ConnectCampaignStatus;

	@GandalfProperty()
	subject: string;

	@GandalfProperty()
	totalCount: number;

	@GandalfProperty()
	undeliveredCount: number;

}
