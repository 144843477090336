import {Component, Inject, OnInit} from '@angular/core';
import { HumanReadableInsuranceRemittanceResponse } from '@gandalf/model/human-readable-insurance-remittance-response';

import {
	RemittanceAdvicePrintComponent
} from '@accounting/invoices/receive-payments/remittance-advice-print/remittance-advice-print.component';
import {POPOUT_DATA, PopoutData} from '@core/popout/popout.token';
import {AccountingService} from '@accounting/core/accounting/accounting.service';
import {PrintService} from '@core/print/print.service';

export class RemittanceAdviceViewComponentData implements PopoutData {
	paymentGroupId: number;
	title = 'Remittance Advice';
}

@Component({
	selector: 'pms-remittance-advice-view',
	templateUrl: './remittance-advice-view.component.html',
})
export class RemittanceAdviceViewComponent implements OnInit {

	paymentGroupId: number;
	eraResponses: HumanReadableInsuranceRemittanceResponse[];
	_isLoading: boolean;

	constructor(
		@Inject(POPOUT_DATA) public data: RemittanceAdviceViewComponentData,
		private accountingService: AccountingService,
		private printService: PrintService,

	) {
		this.paymentGroupId = this.data.paymentGroupId;
	}

	ngOnInit(): void {
		this.getData();
	}

	getData() {
		this._isLoading = true;
		this.accountingService.findHumanReadableInsuranceRemittancesByPaymentGroup(this.paymentGroupId).subscribe((responses) => {
			this.eraResponses = responses;
			this._isLoading = false;
		});
	}

	printRemittanceAdvice() {
		this.printService.openPrint(RemittanceAdvicePrintComponent, {
			data: {
				eraResponses: this.eraResponses,
			},
		},
		);
	}
}
