// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.insurance.CreatePatientInsuranceBasicInformationRequest */
/* istanbul ignore next */
@GandalfModel
export class CreatePatientInsuranceBasicInformationRequest extends GandalfModelBase {

	@GandalfProperty()
	coInsurancePercentage: number;

	@GandalfValidator({ notNull: { message: 'Company is required' } })
	@GandalfProperty({ isRequired: true })
	companyId: number;

	@GandalfProperty({ propertyType: 'Money' })
	contactLensAllowance: number;

	@GandalfProperty({ propertyType: 'Money' })
	contactLensFittingCoPay: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	effectiveDate: Date;

	@GandalfProperty({ propertyType: 'Money' })
	familyDeductible: number;

	@GandalfProperty()
	feeScheduleId: number;

	@GandalfProperty({ propertyType: 'Money' })
	generalCoPay: number;

	@GandalfValidator({ sizeString: { message: 'Group Number cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	groupNumber: string;

	@GandalfValidator({ notNull: { message: 'Type is required' } })
	@GandalfProperty({ isRequired: true })
	insuranceTypeId: number;

	@GandalfProperty({ propertyType: 'Money' })
	materialCoPay: number;

	@GandalfProperty({ propertyType: 'Money' })
	maxFrameAllowance: number;

	@GandalfProperty({ propertyType: 'Money' })
	medicalExamCoPay: number;

	@GandalfProperty({ propertyType: 'Money' })
	minFrameAllowance: number;

	@GandalfProperty()
	personInsurancePolicyId: number;

	@GandalfValidator({ sizeString: { message: 'Plan Name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	planName: string;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Policyholder Relationship is required' } })
	@GandalfProperty({ isRequired: true })
	policyHolderRelationship: constants.PolicyHolderRelationship;

	@GandalfValidator({ notNull: { message: 'Policy Number is required' } })
	@GandalfValidator({ sizeString: { message: 'Policy Number must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ isRequired: true })
	policyNumber: string;

	@GandalfValidator({ notNull: { message: 'Priority is required' } })
	@GandalfProperty({ isRequired: true })
	priorityId: number;

	@GandalfProperty()
	referralRequired: boolean;

	@GandalfProperty({ propertyType: 'Money' })
	routineExamCoPay: number;

	@GandalfValidator({ sizeString: { message: 'RX BIN Number cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	rxBinNumber: string;

	@GandalfValidator({ sizeString: { message: 'RX PCN Number cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	rxPcnNumber: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	termedDate: Date;

}
