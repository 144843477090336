import { Injectable } from '@angular/core';
import { GridResults } from '@core/grid/observable-grid-adapter';
import { _isNil } from '@core/lodash/lodash';
import { SortingService } from 'morgana';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { QueryResultsResponse } from '@gandalf/model/query-results-response';
import { RunQueryRequest } from '@gandalf/model/run-query-request';
import { CustomReportsMasterCategory, QueryTemplateCategory } from '@gandalf/constants';
import { CreateQueryRequest } from '@gandalf/model/create-query-request';
import { FindQueriesRequest } from '@gandalf/model/find-queries-request';
import { ImportMasterQueriesRequest } from '@gandalf/model/import-master-queries-request';
import { QueryFieldCategoryResponse } from '@gandalf/model/query-field-category-response';
import { QueryTemplateCategoryResponse } from '@gandalf/model/query-template-category-response';
import { UpdateCustomQueryRequest } from '@gandalf/model/update-custom-query-request';
import { UpdateQueryRequest } from '@gandalf/model/update-query-request';
import { QueryGandalfService } from '@gandalf/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GandalfTheGreyService } from '@core/gandalf-the-grey/gandalf-the-grey.service';

export interface TreeNode {
	[key: string]: any;
	id: string;
	parentId?: string;
	label: string;
	data: any;
	hasChildren: boolean;
}

export interface QueryTemplateCategoryTree extends QueryTemplateCategoryResponse {
	treeNodes: TreeNode[];
}

export interface FormattedQueryResults extends QueryResultsResponse, GridResults {
}

@Injectable({
	providedIn: 'root',
})
export class QueryService {

	private templateCategories: Observable<QueryTemplateCategoryTree[]>;

	constructor(
		public queryGandalfService: QueryGandalfService,
		private gandalfTheGreyService: GandalfTheGreyService,
	) {
	}

	findByTemplateCategoryAndCustomReportsMasterCategory(
		templateCategory: QueryTemplateCategory,
		customReportsMasterCategory: CustomReportsMasterCategory,
	) {
		const request = new FindQueriesRequest();
		request.templateCategory = templateCategory;
		request.customReportsMasterCategory = customReportsMasterCategory;
		return this.queryGandalfService.findByTemplateCategoryAndCustomReportsMasterCategory(request).pipe(
			map(queries => SortingService.sortBy(queries, ['id'], ['asc'])),
		);
	}

	getTemplateCategoryTree(templateCategory: QueryTemplateCategory) {
		// cache all template category trees; backend configuration does not change
		if (!this.templateCategories) {
			this.templateCategories = this.queryGandalfService.getTemplateCategories().pipe(
				map(templateCategories => templateCategories.map(templateCategoryData => this.buildTemplateCategoryTree(templateCategoryData))),
			);
		}
		return this.templateCategories.pipe(
			map(templateCategories => templateCategories.find(templateCategoryTree => templateCategoryTree.templateCategory === templateCategory)),
		);
	}

	private buildTemplateCategoryTree(templateCategory: QueryTemplateCategoryResponse) {
		const templateCategoryTree = templateCategory as QueryTemplateCategoryTree;
		// flat list of nodes; tree structure determined by parentId field; order in this list determines order in tree.
		templateCategoryTree.treeNodes = [];
		templateCategory.fieldCategories.forEach(fieldCategory =>
			this.addFieldCategoryTreeNodes(templateCategoryTree.treeNodes, null, fieldCategory));
		return templateCategoryTree;
	}

	private addFieldCategoryTreeNodes(
		treeNodes: TreeNode[],
		parentCategory: QueryFieldCategoryResponse,
		fieldCategory: QueryFieldCategoryResponse,
	) {
		// add the node for the category
		treeNodes.push({
			id: fieldCategory.label,
			parentId: parentCategory ? parentCategory.label : null,
			label: fieldCategory.label,
			data: fieldCategory,
			hasChildren: true,
		});
		if (fieldCategory.fields) {
			// add a node for each field (category is the parent)
			treeNodes.push(...fieldCategory.fields.map(field => ({
				id: field.id,
				parentId: fieldCategory.label,
				label: field.label,
				data: field,
				hasChildren: false,
			})));
		}
		if (fieldCategory.subCategories) {
			// recursively add sub categories (will always be ordered after the fields).
			fieldCategory.subCategories.forEach(subCategory => this.addFieldCategoryTreeNodes(treeNodes, fieldCategory, subCategory));
		}
	}

	getQueryById(queryId) {
		return this.queryGandalfService.getQueryById(queryId);
	}

	@ShowSavedSuccessToast()
	createQuery(query: CreateQueryRequest) {
		return this.queryGandalfService.createQuery(query);
	}

	@ShowSavedSuccessToast()
	updateQuery(query: UpdateQueryRequest) {
		return this.queryGandalfService.updateQuery(query);
	}

	updateCustomQuery(query: UpdateCustomQueryRequest) {
		return this.queryGandalfService.updateCustomQuery(query);
	}

	deleteQueryById(queryId: number) {
		return this.queryGandalfService.deleteById(queryId);
	}

	findMasterQueriesByTemplateCategoryAndCustomReportsMasterCategory(
		templateCategory: QueryTemplateCategory,
		customReportsMasterCategory: CustomReportsMasterCategory,
	) {
		const request = new FindQueriesRequest();
		request.templateCategory = templateCategory;
		request.customReportsMasterCategory = customReportsMasterCategory;
		return this.queryGandalfService.findMasterQueriesByTemplateCategoryAndCustomReportsMasterCategory(request).pipe(
			map(queries => SortingService.sortBy(queries, ['id'], ['asc'])),
		);
	}

	@ShowSavedSuccessToast()
	importMasterQueries(masterQueryIds: ImportMasterQueriesRequest) {
		return this.queryGandalfService.importMasterQueries(masterQueryIds);
	}

	runQuery(queryId, limit, offset, total = null) {
		const request = new RunQueryRequest();
		request.queryId = queryId;
		request.limit = limit;
		request.offset = offset;
		// if total is undefined the backend will recompute it.
		request.total = total;
		return this.gandalfTheGreyService.execute('Query', 'runQuery', request).pipe(
			map(queryResults => this.formatQueryResults(queryResults)),
		);
	}

	formatQueryResults(queryResults: QueryResultsResponse) {
		if (_isNil(queryResults)) {
			// mimic legacy behavior and show no results if query fails
			return null;
		}
		const formatted = queryResults as FormattedQueryResults;
		// replace all dots from fieldId otherwise grid column will consider it as a nested object
		formatted.fields.forEach(field => field.fieldId = field.fieldId.replace(/\./g, '-'));
		if (queryResults.results) {
			// for each result convert fieldId/value pairs to an object/map for grid columns
			formatted.pageResults = queryResults.results.map(result => {
				const gridObject = {};
				if (result.values) {
					// replace all dots from fieldId otherwise grid column will consider it as a nested object
					result.values.forEach(fieldValue => gridObject[fieldValue.fieldId.replace(/\./g, '-')] = fieldValue.value);
				}
				return gridObject;
			});
		} else {
			formatted.pageResults = [];
		}
		return formatted;
	}

}
