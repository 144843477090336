import { setMetadataPropertyValue } from '../metadata/gandalf-metadata-util';

/**
 * Metadata key used to store a property's Subrequest Label
 */
export const GANDALF_SUBREQUEST_METADATA_KEY = Symbol('GandalfSubRequestLabel');

/**
 * Decorator to identify the request sublabel metadata for a Gandalf Model property.
 *
 * The sublabel is stored in Gandalf metadata, so it can be accessed by other Gandalf utilities.
 *
 * The subrequest label can be accessed via:
 * modelInstance.$subRequestLabel.propertyName
 */
export function GandalfSubRequestLabel(labelString: string) {
	return (target: object, propertyName: string) => {
		if (labelString !== undefined && labelString !== '') {
			setMetadataPropertyValue(target, GANDALF_SUBREQUEST_METADATA_KEY, propertyName, labelString);
		}
	};
}
