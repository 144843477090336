// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.schedule.waitinglist.WaitingListResponse */
/* istanbul ignore next */
@GandalfModel
export class WaitingListResponse extends GandalfModelBase {

	@GandalfProperty()
	comments: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	createdOnDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	emailEligibility: constants.PatientCommunicationEligibility;

	@GandalfProperty()
	emailErrorMessage: string;

	@GandalfProperty()
	emailIsValid: boolean;

	@GandalfProperty()
	encounterType: string;

	@GandalfProperty()
	encounterTypeId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate: Date;

	@GandalfProperty()
	hasCellPhone: boolean;

	@GandalfProperty()
	hasRevConnectAppointmentReminder: boolean;

	@GandalfProperty()
	nextAppointmentDate: Date;

	@GandalfProperty()
	nextAppointmentId: number;

	@GandalfProperty()
	nextAppointmentType: string;

	@GandalfProperty()
	numberOfDaysOnWaitList: number;

	@GandalfProperty()
	patientId: number;

	@GandalfProperty()
	patientName: PatientNameResponse;

	@GandalfProperty()
	patientPrimaryLocationId: number;

	@GandalfProperty()
	preferredPhoneNumber: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	priority: constants.WaitListPriority;

	@GandalfProperty()
	provider: PersonNameResponse;

	@GandalfProperty()
	providerId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	textEligibility: constants.PatientCommunicationEligibility;

	@GandalfProperty()
	textErrorMessage: string;

	@GandalfProperty()
	waitListId: number;

	@GandalfProperty()
	waitListLocationId: number;

}
