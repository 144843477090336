import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { _isNil } from '../../utils/lodash/lodash';

@Component({
	selector: 'rev-conditional-hyperlink-cell-renderer',
	templateUrl: './conditional-hyperlink-cell-renderer.component.html',
})
export class ConditionalHyperlinkCellRendererComponent implements ICellRendererAngularComp {
	assertion: (rowData) => boolean;
	navigationCall: (rowData) => void;
	data = null;
	value = null;

	refresh(params: ICellRendererParams): boolean {
		this.data = params.data;
		this.value = !_isNil(params.valueFormatted) ? params.valueFormatted : params.value;
		return true;
	}

	agInit(params: ICellRendererParams): void {
		if (!_isNil(params['assertion'])) {
			this.assertion = params['assertion'];
		} else {
			this.assertion = () => true;
		}

		if (!_isNil(params['navigationCall'])) {
			this.navigationCall = params['navigationCall'];
		} else {
			this.navigationCall = () => {};
		}
		this.refresh(params);
	}

	onLinkClick(event: MouseEvent) {
		event.stopPropagation();
		this.navigationCall(this.data);
	}
}
