import { Component, Input, OnInit } from '@angular/core';
import { AddressService } from '@core/address/address.service';
import { AddressResponse } from '@gandalf/model/address-response';
import { ReferenceDataResponse } from '@gandalf/model/reference-data-response';

@Component({
	selector: 'pms-address-display',
	templateUrl: './address-display.component.html',
})
export class AddressDisplayComponent implements OnInit {

	@Input()
	address: AddressResponse;

	stateReferences: ReferenceDataResponse[];
	loading: boolean;

	constructor(public addressService: AddressService) {
	}

	ngOnInit() {
		this.loading = true;
		this.addressService.findStateProvinces().subscribe(stateReferences => {
			this.loading = false;
			this.stateReferences = stateReferences;
		});
	}

	getCityStateZip() {
		let cityStateZip = this.address.city ? this.address.city : '';
		const state = this.getAbbreviatedState();
		cityStateZip = this.appendWithSeparator(cityStateZip, state, ', ');
		// if state missing use comma to separate city and zip
		cityStateZip = this.appendWithSeparator(cityStateZip, this.address.postalCode, state ? ' ' : ', ');
		return cityStateZip;
	}

	getAbbreviatedState() {
		if (this.address.stateProvince) {
			// reference data description contains full state name, value contains abbreviation
			const stateReference = this.stateReferences.find(state => state.description.toLowerCase() === this.address.stateProvince.toLowerCase());
			return (stateReference && stateReference.value) ? stateReference.value : this.address.stateProvince;
		} else {
			return null;
		}
	}

	appendWithSeparator(target: string, value: string, separator: string) {
		// remove empty values before joining with separator
		return [target, value].filter(Boolean).join(separator);
	}

}
