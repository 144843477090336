// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.claims.InsuranceClaimStatusResponse */
/* istanbul ignore next */
@GandalfModel
export class InsuranceClaimStatusResponse extends GandalfModelBase {

	@GandalfProperty()
	claimStatusId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.ClaimStatusStatus;

	@GandalfProperty({ propertyType: 'LocalDate' })
	statusDate: Date;

	@GandalfProperty()
	statusMessage: string;

}
