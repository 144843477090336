import { Injectable } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';
import { _every, _map } from '@core/lodash/lodash';
import { RouterEventConstructor } from '@core/router-utils/router-event-constructor';
import { RouterEventObserver } from '@core/router-utils/router-event-observer';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class RouterUtilsService {

	constructor(
		private router: Router,
	) {
	}

	/**
	 * Observe Navigation Events
	 * Returns a configured Observable that emits Navigation Events as configured.
	 * @param eventClass Event class to listen for and emit.
	 * @param restrictContext Restrict the observables context of operation to sub-routes of the original route.
	 */
	observeNavigationEvents<T extends RouterEvent>(
		eventClass: RouterEventConstructor<T>,
		restrictContext: boolean = true,
	): Observable<T> {
		const observerClass = new RouterEventObserver(this.router);
		return observerClass.observeRouterEvents(eventClass, restrictContext);
	}

	/**
	 * Is Current Route Child Route
	 * Takes a child route and the current url and checks to see whether the child route is active
	 * within the current route. This can be especially tricky with route parameters represented by numbers
	 * since similar numbers may represent a match. To get around this we normalize any match to the nearest
	 * forward slash, then split the segments into an array where they are checked for equality on an
	 * individual basis.
	 * @param currentUrl The full URL of the current router.
	 * @param childRoute The child route being verified.
	 */
	isCurrentRouteChildRoute(currentUrl: string, childRoute: string) {
		const regExMatcher = new RegExp(childRoute);
		const regExMatch = currentUrl.match(regExMatcher);

		if (regExMatch) {
			currentUrl = currentUrl.split('?')[0];
			const lastSlashIndex = currentUrl.indexOf('/', regExMatch.index + childRoute.length);
			const relativeUrlMatch = currentUrl.substring(regExMatch.index, lastSlashIndex !== -1 ? lastSlashIndex : undefined);
			const relativeUrlArray = relativeUrlMatch.split('/');
			const routerLinkArray = childRoute.split('/');
			return _every(_map(routerLinkArray, segment => relativeUrlArray.includes(segment)));
		}

		return false;
	}
}
