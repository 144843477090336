// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AllergyResponse } from './allergy-response';

// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.person.DrugAllergyResponse */
/* istanbul ignore next */
@GandalfModel
export class DrugAllergyResponse extends AllergyResponse {

	@GandalfProperty({ propertyType: 'LocalDate' })
	deactivationDate: Date;

	@GandalfProperty()
	description: string;

	@GandalfProperty()
	displayName: string;

	@GandalfProperty()
	drugClassId: number;

	@GandalfProperty()
	drugCode: string;

	@GandalfProperty()
	drugId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate: Date;

	@GandalfProperty()
	externalId: string;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	personId: number;

	@GandalfArray(Number)
	reactionIds: number[];

	@GandalfArray(ReferenceDataResponse)
	reactions: ReferenceDataResponse[];

	@GandalfConstantDecorator()
	@GandalfProperty()
	severity: constants.AllergySeverity;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.AllergyStatus;

	@GandalfProperty()
	treatment: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	updatedDate: Date;

}
