import { Injectable } from '@angular/core';
import { GandalfTheGreyService } from '@core/gandalf-the-grey/gandalf-the-grey.service';
import { _isNil } from '@core/lodash/lodash';
import { SortingService } from 'morgana';
import { PatientFileListResponse } from '@gandalf/model/patient-file-list-response';
import { SearchPatientFilesRequest } from '@gandalf/model/search-patient-files-request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class PatientDocumentsService {

	constructor(
		private gandalfTheGreyService: GandalfTheGreyService,
	) {
	}

	/**
	 * Returns the patient files for a patient and optional entity
	 */
	getPatientFiles(request: SearchPatientFilesRequest): Observable<PatientFileListResponse[]> {
		const serviceName = 'Patient';
		const endpointName = 'getPatientFilesForEntity';
		const legacyRequest = this.buildLegacyFileSearchRequest(serviceName, endpointName, request);

		return this.gandalfTheGreyService.execute(serviceName, endpointName, legacyRequest).pipe(
			map(responses => responses.map(response => this.handleLegacyFileResponse(response))),
			map(responses => SortingService.sortBy(responses, ['fileDate', 'fileId'], ['desc', 'desc'])),
		);
	}

	/**
	 * Maps an expected request to a legacy request (PatientFileEntityRequest) to be used by gandalf-the-grey
	 * @param serviceName The service to use in the legacy system
	 * @param endpointName The endpoint to use on the service
	 * @param request The original request
	 */
	buildLegacyFileSearchRequest(serviceName: string, endpointName: string, request: SearchPatientFilesRequest): any {
		const legacyRequest = this.gandalfTheGreyService.createRequestInstance(serviceName, endpointName);
		legacyRequest.patientId = request.patientId;
		if (!_isNil(request.entityType)) {
			legacyRequest.entityId = request.entityType.value;
			legacyRequest.parentId = request.parentId;
		}
		return legacyRequest;
	}

	/**
	 * Maps a legacy response (PatientFileDTO) to the expected response
	 * @param legacyResponse The SalesReportDTO from the legacy system
	 */
	handleLegacyFileResponse(legacyResponse: any): PatientFileListResponse {
		const response = new PatientFileListResponse();
		response.patientFileId = legacyResponse.id;
		response.fileId = legacyResponse.file.id;
		response.fileName = legacyResponse.file.fileName;
		response.fileDate = legacyResponse.file.updatedOn;
		response.fileDescription = legacyResponse.file.description;
		response.mimeType = legacyResponse.file.mimeType;
		response.folderId = legacyResponse.file.folderId;
		return response;
	}

	/**
	 * Deletes a file based on its id
	 * @param patientFileId The id of the patient file to delete
	 */
	deleteFile(patientFileId: number) {
		const serviceName = 'Patient';
		const endpointName = 'deleteFile';
		const legacyRequest = this.buildLegacyDeleteRequest(serviceName, endpointName, patientFileId);

		return this.gandalfTheGreyService.execute(serviceName, endpointName, legacyRequest);
	}

	buildLegacyDeleteRequest(serviceName: string, endpointName: string, patientFileId: number): any {
		const legacyRequest = this.gandalfTheGreyService.createRequestInstance(serviceName, endpointName);
		legacyRequest.id = patientFileId;
		return legacyRequest;
	}
}
