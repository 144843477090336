import { Injectable } from '@angular/core';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { CreatePatientConsentRequest } from '@gandalf/model/create-patient-consent-request';
import { PatientConsentResponse } from '@gandalf/model/patient-consent-response';
import { PracticeConsentFormResponse } from '@gandalf/model/practice-consent-form-response';
import { UpdatePatientConsentRequest } from '@gandalf/model/update-patient-consent-request';
import { PatientConsentGandalfService } from '@gandalf/services';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ConsentsService {

	constructor(
		private consentsGandalfService: PatientConsentGandalfService,
	) { }

	/* istanbul ignore next: gandalf */
	getPatientConsent(consentId: number): Observable<PatientConsentResponse> {
		return this.consentsGandalfService.getPatientConsent(consentId);
	}

	/* istanbul ignore next: gandalf */
	findActivePracticeConsentForms(): Observable<PracticeConsentFormResponse[]> {
		return this.consentsGandalfService.findActivePracticeConsentForms();
	}

	/* istanbul ignore next: gandalf */
	findAllConsentsByPatientId(patientId: number): Observable<PatientConsentResponse[]> {
		return this.consentsGandalfService.findAllConsentsByPatientId(patientId);
	}

	/* istanbul ignore next: gandalf */
	@ShowSavedSuccessToast()
	createPatientConsent(request: CreatePatientConsentRequest): Observable<PatientConsentResponse> {
		return this.consentsGandalfService.createPatientConsent(request);
	}

	/* istanbul ignore next: gandalf */
	@ShowSavedSuccessToast()
	updatePatientConsent(request: UpdatePatientConsentRequest): Observable<PatientConsentResponse> {
		return this.consentsGandalfService.updatePatientConsent(request);
	}

	/* istanbul ignore next: gandalf */
	deactivatePatientConsent(patientConsentId: number): Observable<void> {
		return this.consentsGandalfService.deactivatePatientConsent(patientConsentId);
	}

}
