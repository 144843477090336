// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.prescription.RxSelectEyeglassResponse */
/* istanbul ignore next */
@GandalfModel
export class RxSelectEyeglassResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	expirationDate: Date;

	@GandalfProperty()
	eyeglassPrescriptionId: number;

	@GandalfProperty()
	odAxis: number;

	@GandalfProperty()
	odBalanced: boolean;

	@GandalfProperty()
	odCylinder: number;

	@GandalfProperty()
	odHorizontalPrism: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	odHorizontalPrismOrientation: constants.HorizontalPrismOrientation;

	@GandalfProperty()
	odNearAdd: number;

	@GandalfProperty()
	odPrism: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	odPrismOrientation: constants.VerticalPrismOrientation;

	@GandalfProperty()
	odSphere: number;

	@GandalfProperty()
	osAxis: number;

	@GandalfProperty()
	osBalanced: boolean;

	@GandalfProperty()
	osCylinder: number;

	@GandalfProperty()
	osHorizontalPrism: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	osHorizontalPrismOrientation: constants.HorizontalPrismOrientation;

	@GandalfProperty()
	osNearAdd: number;

	@GandalfProperty()
	osPrism: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	osPrismOrientation: constants.VerticalPrismOrientation;

	@GandalfProperty()
	osSphere: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate: Date;

	@GandalfProperty()
	usedForReasonName: string;

}
