import { Injectable, Type } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { ActivationStart, Event, NavigationStart, Router } from '@angular/router';
import { Iframe } from './iframe';

@Injectable({
	providedIn: 'root',
})
export class IframeContainerService {

	iframes: Iframe[] = [];

	constructor(
		private router: Router,
	) {
		this.router.events.subscribe(event => this.handleRouterEvent(event));
	}

	handleRouterEvent(event: Event) {
		if (event instanceof NavigationStart) {
			this.iframes.forEach(iframe => iframe.deactivate());
		} else if (event instanceof ActivationStart && !_isNil(event?.snapshot?.component)) {
			this.iframes.find(iframe => iframe.component === event.snapshot.component)?.activate();
		}
	}

	/**
	 * Add a stateful iframe for a unique owner component. The iframe will be active, but disabled until the URL is set.
	 */
	createIframe(component: Type<any>): Iframe {
		const iframe = new Iframe();
		iframe.component = component;
		this.iframes.push(iframe);
		return iframe;
	}
}
