import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'textProperCase',
})
export class TextProperCasePipe implements PipeTransform {

	transform(text: string): string {
		if (!text) {
			return undefined;
		} else {
			return text.replace(/(?:^|\s)\S/g, a => a.toUpperCase());
		}
	}

}
