import { Injectable } from '@angular/core';
import { SortingService, YesNoPipe } from 'morgana';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { CreateReferenceDataRequest } from '@gandalf/model/create-reference-data-request';
import { MasterReferenceEthnicityDataResponse } from '@gandalf/model/master-reference-ethnicity-data-response';
import { MasterReferenceLanguageDataResponse } from '@gandalf/model/master-reference-language-data-response';
import { MasterReferenceRaceDataResponse } from '@gandalf/model/master-reference-race-data-response';
import { ReferenceDataResponse } from '@gandalf/model/reference-data-response';
import { UpdateReferenceDataRequest } from '@gandalf/model/update-reference-data-request';
import { UpdateReferenceDataSortOrderRequest } from '@gandalf/model/update-reference-data-sort-order-request';
import { AdminDataConfigurationGandalfService, ReferenceDataGandalfService } from '@gandalf/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ReferenceDataResponseForTable extends ReferenceDataResponse {
	editableText: string;
}

export interface ReferenceDataDropdownResponse extends ReferenceDataResponse {
	label: string;
	value: any;
}

@Injectable({
	providedIn: 'root',
})
export class AdminReferenceDataService {

	constructor(
		private referenceDataGandalfService: ReferenceDataGandalfService,
		private dataConfigGandalfService: AdminDataConfigurationGandalfService,
		private yesNoPipe: YesNoPipe,
	) {
	}

	getReferenceDataByCategoryIdForTable(categoryId: number): Observable<ReferenceDataResponseForTable[]> {
		return this.referenceDataGandalfService.findReferenceDataByCategoryId(categoryId).pipe(
			map(dataArray => SortingService.sortBy(dataArray, ['sortOrder', 'value', 'id'])),
			map(sortedReferenceData => sortedReferenceData.map(datum => this.formatReferenceDataResponseForTable(datum))),
		);
	}

	getReferenceDataByCategoryIdForDropdown(categoryId: number): Observable<ReferenceDataDropdownResponse[]> {
		return this.referenceDataGandalfService.findReferenceDataByCategoryId(categoryId).pipe(
			map(dataArray => SortingService.sortBy(dataArray, ['sortOrder', 'value', 'id'])),
			map(sortedReferenceData => sortedReferenceData.map(datum => this.formatReferenceDataForDropdown(datum))),
		);
	}

	@ShowSavedSuccessToast()
	createReferenceData(request: CreateReferenceDataRequest): Observable<ReferenceDataResponse> {
		return this.referenceDataGandalfService.createReferenceData(request);
	}

	@ShowSavedSuccessToast()
	updateReferenceData(request: UpdateReferenceDataRequest): Observable<ReferenceDataResponse> {
		return this.referenceDataGandalfService.updateReferenceData(request);
	}

	updateReferenceDataSortOrder(referenceDataList: ReferenceDataResponseForTable[]): Observable<ReferenceDataResponseForTable[]> {
		const requestArray = this.formatSortOrderRequestArray(referenceDataList);
		return this.dataConfigGandalfService.updateReferenceDataSortOrder(requestArray).pipe(
			map(referenceData => SortingService.sortBy(referenceData, ['sortOrder', 'value', 'id'])),
			map(sortedReferenceData => sortedReferenceData.map(datum => this.formatReferenceDataResponseForTable(datum))),
		);
	}

	getExtendedEthnicityReferenceData(): Observable<ReferenceDataResponseForTable[]> {
		return this.dataConfigGandalfService.findExtendedEthnicityReferenceData().pipe(
			map(referenceData => SortingService.sortBy(referenceData,
				['categoryId', 'sortOrder', 'id'])),
			map(sortedReferenceData => sortedReferenceData.map(
				datum => this.formatReferenceDataResponseForTable(datum))),
		);
	}

	getExtendedRaceReferenceData(): Observable<ReferenceDataResponseForTable[]> {
		return this.dataConfigGandalfService.findExtendedRaceReferenceData().pipe(
			map(referenceData => SortingService.sortBy(referenceData,
				['categoryId', 'sortOrder', 'id'])),
			map(sortedReferenceData => sortedReferenceData.map(
				datum => this.formatReferenceDataResponseForTable(datum))),
		);
	}

	getMasterReferenceEthnicityData(): Observable<MasterReferenceEthnicityDataResponse[]> {
		return this.dataConfigGandalfService.findMasterReferenceEthnicityData();
	}

	getMasterReferenceLanguageData(): Observable<MasterReferenceLanguageDataResponse[]> {
		return this.dataConfigGandalfService.findMasterReferenceLanguageData();
	}

	getMasterReferenceRaceData(): Observable<MasterReferenceRaceDataResponse[]> {
		return this.dataConfigGandalfService.findMasterReferenceRaceData();
	}

	formatReferenceDataResponseForTable(referenceData: ReferenceDataResponse): ReferenceDataResponseForTable {
		const tableResponse = referenceData as ReferenceDataResponseForTable;
		tableResponse.editableText = this.yesNoPipe.transform(referenceData.editable);
		return tableResponse;
	}

	formatSortOrderRequestArray(referenceDataList: ReferenceDataResponseForTable[]): UpdateReferenceDataSortOrderRequest[] {
		return referenceDataList.map((referenceData) => {
			const request = new UpdateReferenceDataSortOrderRequest();
			request.referenceDataId = referenceData.id;
			request.sortOrder = referenceData.sortOrder;
			return request;
		});
	}

	formatReferenceDataForDropdown(referenceData: ReferenceDataResponse): ReferenceDataDropdownResponse {
		return {...referenceData, label: referenceData.value, value: referenceData.id};
	}
}
