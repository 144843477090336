import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { INTER_APP_CONSTANTS } from '@core/legacy/inter-app.constants';
import { DynamicPrintRef } from '@core/print/dynamic-print-ref';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { ReconciliationBatchResponse } from '@gandalf/model/reconciliation-batch-response';
import { ReconciliationItemResponse } from '@gandalf/model/reconciliation-item-response';
import { ReconciliationReportResponse } from '@gandalf/model/reconciliation-report-response';

@Component({
	selector: 'pms-inventory-reconciliation-print',
	templateUrl: './inventory-reconciliation-print.component.html',
	styles: [],
})
export class InventoryReconciliationPrintComponent implements OnInit, AfterViewInit {

	componentViewLoaded = false;
	dataLoaded = false;
	reconciliationItems: ReconciliationItemResponse[];
	batches: ReconciliationBatchResponse[];
	report: ReconciliationReportResponse;

	constructor(
		public securityManagerService: SecurityManagerService,
		private dynamicPrintRef: DynamicPrintRef,
		private eventsManagerService: EventsManagerService,
		private ngZone: NgZone,
	) {
	}

	ngOnInit(): void {
		this.eventsManagerService.subscribe(INTER_APP_CONSTANTS.REV360.RECONCILIATION.LOAD_DATA, (event) => {
			this.ngZone.run(() => {
				this.componentViewLoaded = true;
				this.parseConfigData(event.argument);
				this.checkPrintReady();
			});
		});
	}

	ngAfterViewInit() {
		this.componentViewLoaded = true;
		this.checkPrintReady();
	}

	parseConfigData(data: any) {
		this.report = data.report;
		this.batches = data.batches;
		this.reconciliationItems = data.reconciliationItems;
		this.dataLoaded = true;
	}

	checkPrintReady() {

		if (this.dataLoaded && this.componentViewLoaded) {
			this.dynamicPrintRef.printReady();
		}
	}
}
