import { Component, TemplateRef } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'rev-template-cell-renderer',
	template: `<ng-container *ngTemplateOutlet="template; context: templateContext"></ng-container>`,
})
export class TemplateCellRendererComponent implements AgRendererComponent {
	template: TemplateRef<any>;
	templateContext: { $implicit: any, params: any };

	refresh(params: any): boolean {
		this.templateContext = {
			$implicit: params.data,
			params,
		};
		return true;
	}

	agInit(params: ICellRendererParams): void {
		this.template = params['ngTemplate'];
		this.refresh(params);
	}
}
