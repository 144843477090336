import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, UntypedFormGroup } from '@angular/forms';
import { DiagnosisService } from '@core/diagnosis/diagnosis.service';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { SummaryPodService } from '@core/summary-pod/summary-pod.service';
import { PersonDiagnosisResponse } from '@gandalf/model/person-diagnosis-response';
import { ResolvePersonDiagnosisRequest } from '@gandalf/model/resolve-person-diagnosis-request';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';
import { EventService } from '@core/event/event.service';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';

@Component({
	selector: 'pms-resolve-person-diagnosis-modal',
	templateUrl: './resolve-person-diagnosis-modal.component.html',
	styles: [],
})
export class ResolvePersonDiagnosisModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('resolveDiagnosisForm')
	resolveDiagnosisForm: NgForm;

	personDiagnosisId: number;
	patientId: number;
	request: ResolvePersonDiagnosisRequest;
	componentForm: UntypedFormGroup;
	personDiagnosis: PersonDiagnosisResponse = null;
	dateFormat = DATE_FORMATS.MM_DD_YYYY;

	constructor(
		private gandalfFormBuilder: GandalfFormBuilder,
		private dynamicModalRef: DynamicModalRef,
		private modalConfig: ModalConfig,
		private diagnosisService: DiagnosisService,
		private summaryPodService: SummaryPodService,
		private eventService: EventService,
	) {

	}

	ngOnInit() {
		this.parseConfigData(this.modalConfig.data);
		this.getPersonDiagnosis();
		this.createForm();
	}

	createForm() {
		this.request = new ResolvePersonDiagnosisRequest();
		this.request.id = this.personDiagnosisId;
		this.request.dateResolved = new Date();
		this.request.comment = null;
		this.componentForm = this.gandalfFormBuilder.group(this.request);
	}

	parseConfigData(data: any) {
		this.personDiagnosisId = data.personDiagnosisId;
		this.patientId = data.patientId;
	}

	submitForm(event) {
		this.resolveDiagnosisForm.onSubmit(event);
	}

	save() {
		if (this.componentForm.invalid) {
			return;
		}
		this.diagnosisService.resolveDiagnosis(this.componentForm.value).subscribe(
			() => {
				this.diagnosisUpdated();
				this.closeModal(true);
			},
		);
	}

	/* istanbul ignore next: closeModal */
	closeModal(requestRefresh= false) {
		this.dynamicModalRef.close(this.modal, requestRefresh);
	}

	getPersonDiagnosis() {
		this.diagnosisService.getPersonDiagnosisById(this.personDiagnosisId).subscribe(personDiagnosis => {
			this.personDiagnosis = personDiagnosis;
		});
	}

	diagnosisUpdated() {
		this.summaryPodService.updateDiagnosisHistorySummaryPod(this.patientId);
		this.eventService.publishUpdatePatientDiagnoses(this.patientId);
	}
}
