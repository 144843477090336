import { Injectable } from '@angular/core';
import { EnumUtil } from 'morgana';
import { _isNil } from '@core/lodash/lodash';
import { DurationUnit } from '@gandalf/constants';
import dayjs, { ManipulateType } from 'dayjs';

@Injectable({
	providedIn: 'root',
})
export class DateUtilsService {

	constructor() { }

	static getDayJSUnitFromDurationUnit(durationUnit: DurationUnit): ManipulateType {
		if (EnumUtil.equals(durationUnit, DurationUnit.SECOND)) {
			return 'second';
		} else if (EnumUtil.equals(durationUnit, DurationUnit.MINUTE)) {
			return 'minute';
		} else if (EnumUtil.equals(durationUnit, DurationUnit.HOUR)) {
			return 'hour';
		} else if (EnumUtil.equals(durationUnit, DurationUnit.DAY)) {
			return 'day';
		} else if (EnumUtil.equals(durationUnit, DurationUnit.WEEK)) {
			return 'week';
		} else if (EnumUtil.equals(durationUnit, DurationUnit.MONTH)) {
			return 'month';
		} else if (EnumUtil.equals(durationUnit, DurationUnit.YEAR)) {
			return 'year';
		}
	}

	static addDuration(date: Date, durationUnit: DurationUnit, durationAmount: number): Date {
		const dayJsDurationUnit = this.getDayJSUnitFromDurationUnit(durationUnit);
		return dayjs(date).add(durationAmount, dayJsDurationUnit).toDate();
	}

	/**
	 * Compares two dates to confirm if they are the same day.  Two nil values are considered equal.
	 */
	static isSameDay(currentDate: Date, newDate: Date) {
		if (_isNil(currentDate) && _isNil(newDate)) {
			return true;
		} else if (_isNil(currentDate) || _isNil(newDate)) {
			return false;
		} else {
			return dayjs(currentDate).isSame(dayjs(newDate), 'day');
		}
	}

	/**
	 * Compares two dates to confirm if they are the same month.  Two nil values are considered equal.
	 */
	static isSameMonth(currentDate: Date, newDate: Date) {
		if (_isNil(currentDate) && _isNil(newDate)) {
			return true;
		} else if (_isNil(currentDate) || _isNil(newDate)) {
			return false;
		} else {
			return dayjs(currentDate).isSame(dayjs(newDate), 'month');
		}
	}

	/**
	 * Compares two dates to confirm if they are the same year.  Two nil values are considered equal.
	 */
	static isSameYear(currentDate: Date, newDate: Date) {
		if (_isNil(currentDate) && _isNil(newDate)) {
			return true;
		} else if (_isNil(currentDate) || _isNil(newDate)) {
			return false;
		} else {
			return dayjs(currentDate).isSame(dayjs(newDate), 'year');
		}
	}

	/**
	 * Compares two dates to confirm if they are the same, or if the sourceDate is before the targetDate.
	 * Returns true if both sourceDate and targetDate are null or undefined.
	 * Returns false if either but not both sourceDate and targetDate are null/undefined.
	 */
	static isSameOrBefore(sourceDate: Date, targetDate: Date, unit?: ManipulateType) {
		if (_isNil(sourceDate) && _isNil(targetDate)) {
			return true;
		} else if (_isNil(sourceDate) || _isNil(targetDate)) {
			return false;
		} else {
			return dayjs(sourceDate).isSame(targetDate, unit) || dayjs(sourceDate).isBefore(targetDate, unit);
		}
	}

	static dateIsBeforeOrTheSameAsToday(date: Date) {
		return date
			? this.isSameOrBefore(date, new Date(), this.getDayJSUnitFromDurationUnit(DurationUnit.DAY))
			: false;
	}
}
