import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import get from 'lodash/get';
import { EMPTY, MonoTypeOperatorFunction, Observable } from 'rxjs';
import { delay, map, take, tap } from 'rxjs/operators';
import { BustCache, CallEndpoint } from './actions/gandalf.actions';
import { UpdateEndpointResponse } from './actions/response.actions';
import { GandalfCacheableOptions } from './gandalf-cacheable-options';
import { ResponseState } from './reducers/response.reducer';
import { GandalfState } from './reducers/root';
import { selectGandalfResponse } from './selectors/response.selectors';

@Injectable({
	providedIn: 'root',
})
export class GandalfStoreService {

	constructor(
		private store: Store<GandalfState>,
	) { }

	/**
	 * Bust Cache
	 * Dispatches an event to bust caches assigned to the provided cache key.
	 * @param cacheKey Cache Key to use as the predicate for which caches to bust.
	 */
	bustCache(cacheKey: string) {
		this.store.dispatch(new BustCache({cacheKey}));
	}

	updateEndpointResponse<R>(endpointId: string, response: R) {
		this.store.dispatch(new UpdateEndpointResponse({endpointId, response}));
	}

	/**
	 * Build Cacheable Endpoint
	 * Builds an endpoint that using caching functionality, stores the responses that it retrieves and is able
	 * to leverage the stored responses to eliminate redundant API calls from occurring.
	 * @param endpointUrl URL of the endpoint being built.
	 * @param endpoint Configured endpoint of the API.
	 * @param cacheKeys Cache Keys to assign to the endpoint.
	 * @param options Optional arguments to configure additional features of the endpoint.
	 */
	buildCacheableEndpoint<T, R>(
		endpointUrl: string,
		endpoint: Observable<T>,
		cacheKeys: string[],
		options: GandalfCacheableOptions<R>,
	): Observable<T> {
		const endpointId = this.buildEndpointId(endpointUrl, options);

		if (options.useCacheValue) {
			return this.store.select(selectGandalfResponse({key: endpointId})).pipe(
				delay(0),
				this.hydrateEndpointIf(options.hydrationPredicate, endpoint, endpointId, cacheKeys),
				map((endpointResponse: ResponseState) => {
					if (Array.isArray(endpointResponse.response)) {
						return [...endpointResponse.response];
					} else if (endpointResponse.response.prototype instanceof Object) {
						return {...endpointResponse.response};
					}
					return endpointResponse.response;
				}),
				take(1),
			);
		} else {
			return endpoint.pipe(
				tap(response => this.updateEndpointResponse(endpointId, response)),
			);
		}
	}

	/**
	 * Hydrate Endpoint If
	 * RxJs Custom Operator that is used to hydrate cacheable endpoints with data if the provided predicate is met.
	 * @param predicate Predicate that determines whether the API call needs to be made to hydrate the endpoint.
	 * @param endpoint Configured endpoint of the API.
	 * @param endpointId Endpoint ID of the endpoint being processed.
	 * @param cacheKeys Cache Keys to assign to the endpoint cache created if the API call is made.
	 */
	private hydrateEndpointIf<T>(
		predicate: (value: T) => boolean,
		endpoint: Observable<T>,
		endpointId: string,
		cacheKeys: string[],
	): MonoTypeOperatorFunction<T> {
		const store = this.store;
		return (source: Observable<T>) => new Observable(observer => source.subscribe({
			next(value: T) {
				if (predicate(value)) {
					store.dispatch(new CallEndpoint({endpoint, endpointId, cacheKeys}));
					return EMPTY;
				} else {
					observer.next(value);
				}
			},
			error(err) {
				observer.error(err);
			},
			complete() {
				observer.complete();
			},
		}));
	}

	/**
	 * Build Endpoint Id
	 * Builds an endpoint id given the url and the options.
	 * @param endpointUrl URL of the endpoint.
	 * @param options Gandalf Cacheable Options object provided.
	 */
	private buildEndpointId<R>(endpointUrl: string, options: GandalfCacheableOptions<R>) {
		return get(options, 'request')
			? endpointUrl + JSON.stringify(options.request, Object.keys(options.request).sort())
			: endpointUrl;
	}
}
