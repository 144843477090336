// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.twowaytext.TwoWayTextFindNewConversationPatientsRequest */
/* istanbul ignore next */
@GandalfModel
export class TwoWayTextFindNewConversationPatientsRequest extends GandalfModelBase {

	@GandalfLabel('Cell Phone')
	@GandalfValidator({ phoneNumber: { message: 'Cell Phone must contain at least 10 digits' } })
	@GandalfProperty()
	cellPhone: string;

	@GandalfLabel('DOB')
	@GandalfProperty({ propertyType: 'LocalDate' })
	dateOfBirth: Date;

	@GandalfLabel('First Name')
	@GandalfValidator({ sizeString: { fieldLabel: 'First Name', maxLength: 1000, minLength: 2, message: 'First Name must be between 2 and 1000 characters' } })
	@GandalfProperty()
	firstName: string;

	@GandalfLabel('Last Name')
	@GandalfValidator({ sizeString: { fieldLabel: 'Last Name', maxLength: 1000, minLength: 2, message: 'Last Name must be between 2 and 1000 characters' } })
	@GandalfProperty()
	lastName: string;

	@GandalfLabel('Location')
	@GandalfProperty()
	locationId: number;

	@GandalfLabel('ID')
	@GandalfValidator({ max: { max: 999999999999999999, message: 'ID may not be more than 18 digits' } })
	@GandalfProperty()
	patientId: number;

	@GandalfLabel('SSN')
	@GandalfValidator({ socialSecurityNumber: { message: 'Social security number must contain 9 digits' } })
	@GandalfProperty()
	socialSecurityNumber: string;

}
