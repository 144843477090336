import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { RemoveFormState, UpdateFormMeta, UpdateFormMode, UpdateFormSubmitted, UpdateFormValue } from '@app-store/actions/forms.actions';
import { State } from '@app-store/reducers';
import { FormMeta, FormState } from '@app-store/reducers/forms.reducer';
import { selectFormState, selectFormSubmitted } from '@app-store/selectors/forms.selectors';
import { StatefulServiceBase } from '@app-store/services/stateful-service-base';
import { _isNil } from '@core/lodash/lodash';
import { Store } from '@ngrx/store';
import { FormMode } from '@shared/constants/form-mode.enum';
import { Observable } from 'rxjs';

export enum FormPersistenceStrategy {
	/**
	 * Performs an update to the store upon every change within the form.
	 * This keeps the store constantly up to date with the current state of the form.
	 */
	EAGER = 'EAGER',

	/**
	 * Performs an update within the ngOnDestroy lifecycle hook, once during the component's lifecycle.
	 * This makes sure that upon destroying a component, the state of the form is persisted.
	 */
	LAZY = 'LAZY',
}

export const StatefulFormPersistenceStrategy: InjectionToken<FormPersistenceStrategy>
	= new InjectionToken<FormPersistenceStrategy>('FormPersistenceMode');

@Injectable()
export class StatefulFormService extends StatefulServiceBase {

	constructor(
		private store: Store<State>,
		@Optional() @Inject(StatefulFormPersistenceStrategy) private readonly _persistenceStrategy: FormPersistenceStrategy,
	) {
		super();
		this._persistenceStrategy = _isNil(_persistenceStrategy) ? FormPersistenceStrategy.LAZY : _persistenceStrategy;
	}

	updateFormMeta(meta: FormMeta) {
		this.store.dispatch(new UpdateFormMeta({key: this.storeKey, meta}));
	}

	updateFormMode(mode: FormMode) {
		this.store.dispatch(new UpdateFormMode({key: this.storeKey, mode}));
	}

	updateFormSubmitted(submitted: boolean) {
		this.store.dispatch(new UpdateFormSubmitted({key: this.storeKey, submitted}));
	}

	updateFormValue<T>(value: T) {
		this.store.dispatch(new UpdateFormValue({key: this.storeKey, value}));
	}

	observeFormSubmitted(): Observable<boolean> {
		return this.store.select(selectFormSubmitted({key: this.storeKey}));
	}

	selectFormState(): Observable<FormState> {
		return this.store.select(selectFormState({key: this.storeKey}));
	}

	removeFormState() {
		return this.store.dispatch(new RemoveFormState({key: this.storeKey}));
	}

	get persistenceStrategy() {
		return this._persistenceStrategy;
	}
}
