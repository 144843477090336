import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { DialogUtil, EnumUtil, SortingService } from 'morgana';
import { _isEmpty, _join, _map } from '@core/lodash/lodash';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { ToasterService } from '@core/toaster/toaster.service';
import { UrlService } from '@core/url-util/url.service';
import { ClaimsInsuranceClaimResponse } from '@gandalf/model/claims-insurance-claim-response';
import { ClaimSearchRequest } from '@gandalf/model/claim-search-request';
import { DeleteClaimsRequest } from '@gandalf/model/delete-claims-request';
import { EClaimsSearchRequest } from '@gandalf/model/e-claims-search-request';
import { InvoiceInsuranceClaimResponse } from '@gandalf/model/invoice-insurance-claim-response';
import { MarkClaimsAsCancelledRequest } from '@gandalf/model/mark-claims-as-cancelled-request';
import { MarkClaimAsSubmittedRequest } from '@gandalf/model/mark-claim-as-submitted-request';
import { ClaimGandalfService } from '@gandalf/services';
import { ClaimProcessingStatus, ClaimSubmitMechanism, InsuranceClaimStatus } from '@gandalf/constants';
import { ClaimSubmitErrorResponse } from '@gandalf/model/claim-submit-error-response';
import { ClaimSubmitResponse } from '@gandalf/model/claim-submit-response';
import { ResetClaimsRequest } from '@gandalf/model/reset-claims-request';
import { SubmitClaimsRequest } from '@gandalf/model/submit-claims-request';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { URL_ACCOUNTING_ENDPOINTS } from '@shared/constants/url.constants';
import { map, tap } from 'rxjs/operators';
import { FeatureService } from '@core/feature/feature.service';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ClaimService {
	CLAIM_SUBMIT_ERRORS = {
		INVALID_DIAGNOSIS: 'Invalid Diagnosis',
	};

	constructor(
		private urlService: UrlService,
		public claimGandalfService: ClaimGandalfService,
		private datePipe: DatePipe,
		private toasterService: ToasterService,
		private featureService: FeatureService,
	) {
	}

	canMarkClaimForResubmit(claimProcessStatus: ClaimProcessingStatus, claimStatus: InsuranceClaimStatus) {
		return (EnumUtil.equals(claimProcessStatus, ClaimProcessingStatus.TRANSMIT_MARKED) ||
				EnumUtil.equals(claimProcessStatus, ClaimProcessingStatus.TRANSMIT_COMPLETE) ||
				EnumUtil.equals(claimProcessStatus, ClaimProcessingStatus.TRANSMIT_ERROR))
			&& !EnumUtil.equals(claimStatus, InsuranceClaimStatus.DELETED);
	}

	canMarkClaimAsSubmitted(claimProcessStatus: ClaimProcessingStatus, claimStatus: InsuranceClaimStatus) {
		return (EnumUtil.equals(claimProcessStatus, ClaimProcessingStatus.TRANSMIT_READY) ||
				EnumUtil.equals(claimProcessStatus, ClaimProcessingStatus.TRANSMIT_ERROR)) &&
			!EnumUtil.equals(claimStatus, InsuranceClaimStatus.DELETED);
	}

	canMarkClaimAsDeleted(claimProcessStatus: ClaimProcessingStatus, claimStatus: InsuranceClaimStatus) {
		return ((EnumUtil.equals(claimProcessStatus, ClaimProcessingStatus.TRANSMIT_READY) ||
				EnumUtil.equals(claimProcessStatus, ClaimProcessingStatus.TRANSMIT_ERROR))
			&& !EnumUtil.equals(claimStatus, InsuranceClaimStatus.DELETED));
	}

	canSubmitClaim(claimProcessStatus: ClaimProcessingStatus, claimStatus: InsuranceClaimStatus, submitMechanism: ClaimSubmitMechanism) {
		return EnumUtil.equals(claimProcessStatus, ClaimProcessingStatus.TRANSMIT_READY)
			&& !EnumUtil.equals(claimStatus, InsuranceClaimStatus.DELETED)
			&& EnumUtil.equals(submitMechanism, ClaimSubmitMechanism.ELECTRONIC);
	}

	/* istanbul ignore next: gandalf */
	submitClaim(claimId: number): Observable<ClaimSubmitResponse> {
		return this.submitClaims([claimId]);
	}

	/* istanbul ignore next: gandalf */
	submitClaims(claimIds: number[]): Observable<ClaimSubmitResponse> {
		const request: SubmitClaimsRequest = new SubmitClaimsRequest();
		request.claimIds = claimIds;
		return this.urlService.makeStrutsRequest(URL_ACCOUNTING_ENDPOINTS.SUBMIT_CLAIM, request, ClaimSubmitResponse, SubmitClaimsRequest).pipe(
			tap(response => {
				this.handleSubmitClaimsResponse(response, claimIds);
			}),
		);
	}

	canMarkEClaimAsCancelled(claimStatus: InsuranceClaimStatus) {
		return EnumUtil.equals(claimStatus, InsuranceClaimStatus.REJECTED);
	}

	handleSubmitClaimsResponse(claimSubmitResponse: ClaimSubmitResponse, claimIds: number[]) {
		if (claimSubmitResponse.errors.length === 0) {
			const message = `${claimIds.length} claim(s) successfully submitted.`;
			claimSubmitResponse.generateRawClaims ? this.displayRawClaims(claimIds) : this.toasterService.showSavedSuccess({content: message});
		} else {
			const displayableClaimIds = claimIds.filter((claimId) => claimSubmitResponse.errors.map(error => error.claimId).indexOf(claimId) === -1);
			if (claimSubmitResponse.generateRawClaims && !_isEmpty(displayableClaimIds)) {
				this.displayRawClaims(displayableClaimIds);
			}
			this.handleSubmitClaimsErrors(claimSubmitResponse.errors);
		}
	}

	handleSubmitClaimsErrors(errors: ClaimSubmitErrorResponse[]) {
		const claimIdsWithInvalidDiagnosisError = [];
		let errorMessages = [];
		for (const error of errors) {
			if (errorMessages.length > 0) {
				break;
			}

			switch (error.message) {
				case this.CLAIM_SUBMIT_ERRORS.INVALID_DIAGNOSIS:
					claimIdsWithInvalidDiagnosisError.push(error.claimId);
					break;
				default:
					errorMessages = _map(errors, claimError => claimError.message);
			}
		}

		if (errorMessages.length > 0) {
			this.showUnmappedErrorModal(errorMessages);
		} else if (claimIdsWithInvalidDiagnosisError.length > 0) {
			this.showInvalidDiagnosisErrorModal(claimIdsWithInvalidDiagnosisError);
		}
	}

	showInvalidDiagnosisErrorModal(claimIds: number[]) {
		const batchClaimUpdateEnabled = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.ACCOUNTING.CLAIMS.BATCH_CLAIMS_UPDATE);
		const modalInfo = {
			title: batchClaimUpdateEnabled ? 'Claim Error' : 'Error Occurred - REQUEST DENIED',
			content: batchClaimUpdateEnabled
				? 'The following claim ids have no diagnosis or have an ICD-10 diagnosis that is not valid for the service '
					+ 'date indicated on the claim and were not submitted: ' + claimIds.join(', ') + '. For reference, please '
					+ 'write these ids down, correct, refresh the list, and try again.'
				: 'This batch of claims could not be submitted. The following claim ids have no diagnosis, are not ready to submit or have an '
					+ 'ICD-10 diagnosis that is not valid for the service date indicated on the claim: ' + claimIds.join(', ') + '. For reference, please '
					+ 'write these ids down, correct, refresh the list, and try again.',
		};
		DialogUtil.alert(modalInfo);
	}

	showUnmappedErrorModal(errors: string[]) {
		const modalInfo = {
			title: 'Claim submission error message(s)',
			content: _join(errors, '; '),
		};
		DialogUtil.alert(modalInfo);
	}

	displayRawClaims(claimIds: number[]) {
		const params = {
			claimIds: _join(claimIds),
			fileName: `claims_${claimIds.length}_${this.datePipe.transform(new Date(), DATE_FORMATS.YYYYMMDD_HMMSS)}.txt`,
			fileExt: 'txt',
		};
		this.urlService.openTabWithPost(URL_ACCOUNTING_ENDPOINTS.VIEW_DATA_FILE, params);
	}

	/* istanbul ignore next: gandalf */
	@ShowSavedSuccessToast()
	resetClaims(claimIds: number[]): Observable<void> {
		const request = new ResetClaimsRequest();
		request.insuranceClaimIds = claimIds;
		return this.claimGandalfService.resetClaims(request);
	}

	/* istanbul ignore next: gandalf */
	@ShowSavedSuccessToast()
	markAsCancelled(claimIds: number[]): Observable<void> {
		const request = new MarkClaimsAsCancelledRequest();
		request.claimIds = claimIds;
		return this.claimGandalfService.markClaimsAsCancelled(request);
	}

	/* istanbul ignore next: gandalf */
	@ShowSavedSuccessToast()
	markClaimsAsSubmitted(request: MarkClaimAsSubmittedRequest): Observable<void> {
		return this.claimGandalfService.markClaimsAsSubmitted(request);
	}

	@ShowSavedSuccessToast()
	deleteClaims(claimIds: number[]): Observable<void> {
		const request = new DeleteClaimsRequest();
		request.insuranceClaimIds = claimIds;
		return this.claimGandalfService.markClaimsAsDeleted(request);
	}

	searchClaims(request: ClaimSearchRequest) {
		return this.claimGandalfService.searchClaims(request).pipe(
			map(insuranceClaims => SortingService.sortBy(insuranceClaims, ['claimDate', 'id'], ['asc', 'asc'])),
		);
	}

	searchClaimsWithStatus(request: EClaimsSearchRequest) {
		return this.claimGandalfService.searchClaimsWithStatus(request).pipe(
			map(insuranceClaims => SortingService.sortBy(insuranceClaims, ['claimDate', 'id'], ['asc', 'asc'])),
			tap(insuranceClaims => insuranceClaims.forEach(claim => claim.claimStatusMessages = SortingService.sortBy(claim.claimStatusMessages, ['claimStatusId'], ['desc']))),
		);
	}


	buildProcessingStatus(claim: InvoiceInsuranceClaimResponse | ClaimsInsuranceClaimResponse) {
		let processingStatusText = claim.processingStatus.label;

		// check for status of deleted
		if (EnumUtil.equals(claim.status, InsuranceClaimStatus.DELETED)) {
			processingStatusText = '[Deleted] ' + processingStatusText;
		}

		// check for status of Error
		if (EnumUtil.equals(claim.processingStatus, ClaimProcessingStatus.TRANSMIT_ERROR)) {
			processingStatusText = processingStatusText + ' (' + claim.externalMessage + ')';
		}

		return processingStatusText;
	}
}
