import { Component, OnInit, ViewChild } from '@angular/core';
import { EncounterService } from '@core/encounter/encounter.service';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { EncounterTableResponse } from '@gandalf/model/encounter-table-response';
import { TABLE_DATE_FORMATS } from '@shared/constants/date-format.constants';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { SortSettingsModel } from '@syncfusion/ej2-grids';

@Component({
	selector: 'pms-encounter-select-by-patient-non-cancelled-modal',
	templateUrl: './encounter-select-by-patient-non-cancelled-modal.component.html',
})
export class EncounterSelectByPatientNonCancelledModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	encounters: EncounterTableResponse[] = [];
	tableDateFormat = TABLE_DATE_FORMATS.MM_DD_YYYY;
	sortOptions: SortSettingsModel;
	isSearching = false;

	constructor(
		public dynamicModalRef: DynamicModalRef,
		public modalConfig: ModalConfig,
		public encounterService: EncounterService,
	) {
	}

	ngOnInit() {
		this.setupTable();
	}

	setupTable() {
		this.sortOptions = {
			columns: [
				{field: 'encounterDate', direction: 'Descending'},
			],
		};
	}

	search() {
		this.isSearching = true;
		this.encounterService.findNonCanceledEncountersByPatientIdForTable(this.modalConfig.data.patientId).subscribe((data) => {
			this.isSearching = false;
			this.encounters = data;
		});
	}

	/* istanbul ignore next: closeModal */
	closeModal(encounter?: EncounterTableResponse) {
		this.dynamicModalRef.close(this.modal, encounter);
	}

}
