<ejs-dialog #modal (close)="closeModal()" cssClass="modal-md" [attr.data-test-id]="'selectSnomedCodeModal'">
	<ng-template #header>
		<div class="dlg-template">Select SNOMED Code</div>
	</ng-template>
	<ng-template #content>
		<div class="modal-feature">
			<form #form="ngForm" class="form-inline" [formGroup]="componentForm" (ngSubmit)="searchSnomedCodes()">
				@if (componentForm.invalid && form.submitted) {
					<gandalf-lib-validation-messages
						[form]="componentForm"
						[requestObj]="searchSnomedCodesRequest">
					</gandalf-lib-validation-messages>
				}
				<div class="form-group" [attr.data-test-id]="'selectSnomedCodeFormGroup'">
					<label for="code">Code</label>
					<input id="code" class="e-input margin-right-md" type="text" name="code" formControlName="code"/>
				</div>
				<div class="form-group" [attr.data-test-id]="'selectSnomedDescriptionFormGroup'">
					<label for="description">Description</label>
					<input id="description" class="e-input margin-right-md" type="text" name="description" formControlName="description" revFocusInput="true"/>
				</div>
				<div class="form-group">
					<button ejs-button type="submit" [isPrimary]="true" iconCss="fa fa-search" [attr.data-test-id]="'selectSnomedSearchButton'">Search</button>
					<button ejs-button type="button" iconCss="fa fa-close" (click)="clear()" [attr.data-test-id]="'selectSnomedDescriptionButton'">Clear
					</button>
				</div>
			</form>
		</div>
		<ejs-grid #grid
				  autoFitColumns
				  [autoFitBlacklist]="['description']"
				  gridLines="Horizontal"
				  [dataSource]="snomedCodes"
				  [allowPaging]="true"
				  [pageSettings]="pageSettings"
				  (rowSelected)="closeModal($event.data)"
				  class="table-rowlink"
				  [attr.data-test-id]="'selectSnomedTable'">
			<e-columns>
				<e-column field="code" headerText="Code"></e-column>
				<e-column field="description" headerText="Description"></e-column>
			</e-columns>
		</ejs-grid>
		@if (searchResultsTruncated()) {
			<div class="alert alert-warning">Search parameters too broad. Showing first {{ maxSearchResults }} results.</div>
		}
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'selectSnomedCancelButton'">Cancel</button>
	</ng-template>
</ejs-dialog>
