import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { InjectorContainerModule } from '../injector-container.module';
import { GandalfEffects } from './effects/gandalf.effects';
import { reducers } from './reducers/root';

@NgModule({
	imports: [
		InjectorContainerModule,
		EffectsModule.forFeature([GandalfEffects]),
		StoreModule.forFeature('gandalf', reducers),
	],
})
export class GandalfStoreModule {
}
