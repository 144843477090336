// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.accounting.PatientAccountBalancesResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientAccountBalancesResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	balance: number;

	@GandalfProperty({ propertyType: 'Money' })
	collectionsBalance: number;

	@GandalfProperty({ propertyType: 'Money' })
	credit: number;

	@GandalfProperty({ propertyType: 'Money' })
	insuranceBalance: number;

}
