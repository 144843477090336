export enum OPEN_EDGE_STATUSES {
	APPROVED = 'APPROVED',
	DECLINED = 'DECLINED',
	DECLINED_DUPLICATE = 'DECLINED_DUPLICATE',
	ERROR = 'ERROR',
	FAILED = 'FAILED',
	USER_CANCELED = 'USERCANCELLED',
	POS_CANCELED = 'POSCANCELLED',
}

export enum OPEN_EDGE_CARD_ENTRY_MODES {
	SWIPE = 'SWIPE',
	PROXIMITY = 'PROXIMITY',
	BARCODE = 'BARCODE',
	MANUAL = 'MANUAL',
}

export enum OPEN_EDGE_CARD_PAYMENT_TYPES {
	VISA = 'VISA',
	MASTERCARD = 'MASTERCARD',
	AMEX = 'AMEX',
	DISCOVER = 'DISCOVER',
	DEBIT = 'DEBIT',
	GIFT = 'GIFT',
	EBT = 'EBT',
	LEVELUP = 'LEVELUP',
	VOYAGER = 'VOYAGER',
	WEX = 'WEX',
	JCB = 'JCB',
	CUP = 'CUP',
	UNKNOWN = 'UNKNOWN',
	PRIVATELABEL = 'PRIVATELABEL',
}
