import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { EMPTY, Observable, Subject } from 'rxjs';

export class TypeSafeDynamicModalRef<R = void> {

	private readonly _onClose = new Subject<R>();

	constructor() {
	}

	close(modal: DialogComponent, result: R) {
		this._onClose.next(result);
		this._onClose.complete();
		// clean up ejs-dialog from the dom
		if (modal && modal.element) {
			modal.element.remove();
		}
	}

	get onClose(): Observable<R> {
		return this._onClose.asObservable();
	}
}

/**
 * This class is provided so calls to the modal manager service that fail do not throw errors when subscribed to onClose
 * These onClose subscriptions immediately complete and never emit.
 */
export class TypeSafeEmptyDynamicModalRef<R> extends TypeSafeDynamicModalRef<R> {

	get onClose() {
		return EMPTY;
	}
}
