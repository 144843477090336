import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CategoryEntityType } from '@gandalf/constants';
import { CategoryResponse } from '@gandalf/model/category-response';
import { CreateCategoryRequest } from '@gandalf/model/create-category-request';
import { UpdateCategoryRequest } from '@gandalf/model/update-category-request';
import { GandalfFormBuilder } from 'gandalf';
import { CategoryService } from '../../../../core/category/category.service';
import { FormUtilsService } from '../../../../core/form-utils/form-utils.service';

@Component({
	selector: 'pms-category-detail',
	templateUrl: './category-detail.component.html',
	styles: [],
})
export class CategoryDetailComponent implements OnInit {

	@Input()
	categoryTitle: string;

	@Input()
	categoryData: CategoryResponse;

	@Input()
	entityType: CategoryEntityType;

	@Output()
	returnEvent = new EventEmitter<boolean>();

	createOrUpdateRequest: CreateCategoryRequest | UpdateCategoryRequest;
	isCreating: boolean;
	formGroup: UntypedFormGroup;

	constructor(
		public gandalfFormBuilder: GandalfFormBuilder,
		public categoryService: CategoryService,
	) {
	}

	ngOnInit(): void {
		this.isCreating = !this.categoryData;
		this.createForm();
	}

	createForm() {
		this.createOrUpdateRequest = this.isCreating
			? new CreateCategoryRequest()
			: new UpdateCategoryRequest();
		this.formGroup = this.gandalfFormBuilder.group(this.createOrUpdateRequest);

		if (this.isCreating) {
			this.formGroup.get('entityType').setValue(this.entityType);
			this.formGroup.get('active').setValue(true);
		} else {
			this.formGroup.reset(this.categoryData);
			FormUtilsService.enabledWhen(this.formGroup.get('name'), this.categoryData.editable);
			FormUtilsService.enabledWhen(this.formGroup.get('active'), this.categoryData.editable);
		}
	}

	saveChanges() {
		if (this.formGroup.invalid) {
			return;
		}
		const createOrUpdateRequest = this.formGroup.getRawValue();
		if (this.isCreating) {
			this.categoryService.createCategory(createOrUpdateRequest).subscribe(() => {
				this.returnToList(true);
			});
		} else {
			this.categoryService.updateCategory(createOrUpdateRequest).subscribe(() => {
				this.returnToList(true);
			});
		}
	}

	returnToList(refreshRequested: boolean) {
		this.returnEvent.emit(refreshRequested);
	}
}
