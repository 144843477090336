import { State as AppState } from '@app-store/reducers';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as appointmentDetailReducer from '@patients-store/reducers/appointment-detail.reducer';
import * as encounterHistoryReducer from '@patients-store/reducers/encounter-history.reducer';
import * as patientAlertsReducer from '@patients-store/reducers/patient-alert.reducer';
import * as patientTabReducer from '@patients-store/reducers/patient-tab.reducer';
import * as patientDashboardReducer from './patient-dashboard.reducer';
import * as patientProviderReducer from './patient-providers.reducer';

export interface State extends AppState {
	patients: PatientsState;
}

export interface PatientsState {
	appointmentDetailState: appointmentDetailReducer.AppointmentDetailStateMap;
	encounterHistory: encounterHistoryReducer.EncounterHistoryStateMap;
	patientAlertState: patientAlertsReducer.PatientAlertStateMap;
	patientDashboardState: patientDashboardReducer.PatientDashboardStateMap;
	patientTabState: patientTabReducer.PatientTabStateMap;
	patientProviderState: patientProviderReducer.PatientProviderStateMap;
}

export const reducers: ActionReducerMap<PatientsState> = {
	appointmentDetailState: appointmentDetailReducer.reducer,
	encounterHistory: encounterHistoryReducer.reducer,
	patientAlertState: patientAlertsReducer.reducer,
	patientDashboardState: patientDashboardReducer.reducer,
	patientTabState: patientTabReducer.reducer,
	patientProviderState: patientProviderReducer.reducer,
};

export const selectPatientsState = createFeatureSelector<PatientsState>('patients');
