import { Injectable } from '@angular/core';
import { SortingService } from 'morgana';
import { FeatureFlagAccess } from '@gandalf/constants';
import { CreateFeatureFlagEmployeeRequest } from '@gandalf/model/create-feature-flag-employee-request';
import { FeatureFlagDetailResponse } from '@gandalf/model/feature-flag-detail-response';
import { UpdateFeatureFlagEmployeeRequest } from '@gandalf/model/update-feature-flag-employee-request';
import { FeatureFlagGandalfService } from '@gandalf/services';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ManageUpdatesService {
	constructor(
		private featureFlagGandalfService: FeatureFlagGandalfService,
	) { }

	getFeatureFlags() {
		return this.featureFlagGandalfService.findFeatureFlagsForManageUpdates().pipe(
			map(featureFlags => this.sortFeatureFlags(featureFlags)),
		);
	}

	createFeatureFlagEmployee(featureFlagId: number, access: FeatureFlagAccess) {
		const createFeatureFlagEmployeeRequest = new CreateFeatureFlagEmployeeRequest();
		createFeatureFlagEmployeeRequest.featureFlagId = featureFlagId;
		createFeatureFlagEmployeeRequest.access = access;
		return this.featureFlagGandalfService.createFeatureFlagEmployee(createFeatureFlagEmployeeRequest);
	}

	updateFeatureFlagEmployeeAccess(featureFlagEmployeeId: number, access: FeatureFlagAccess) {
		const updateFeatureFlagEmployeeRequest = new UpdateFeatureFlagEmployeeRequest();
		updateFeatureFlagEmployeeRequest.featureFlagEmployeeId = featureFlagEmployeeId;
		updateFeatureFlagEmployeeRequest.access = access;
		return this.featureFlagGandalfService.updateFeatureFlagEmployeeAccess(updateFeatureFlagEmployeeRequest);
	}

	sortFeatureFlags(featureFlags: FeatureFlagDetailResponse[]): FeatureFlagDetailResponse[] {
		return SortingService.sortBy(featureFlags, ['internalName', 'id'], ['asc', 'asc']);
	}
}
