import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { ModalManagerService, PAGE_LENGTH, PAGE_LENGTH_LIST } from 'morgana';
import { NotesService } from '@core/notes/notes.service';
import { EntityType } from '@gandalf/constants';
import { FindNotesByParentEntityRequest } from '@gandalf/model/find-notes-by-parent-entity-request';
import { NoteResponse } from '@gandalf/model/note-response';
import { BaseComponent } from '@shared/component/base.component';
import { EditNoteModalComponent } from '@shared/component/notes/edit-note-modal/edit-note-modal.component';
import { TABLE_DATE_FORMATS } from '@shared/constants/date-format.constants';
import { PageSettingsModel, SortSettingsModel } from '@syncfusion/ej2-angular-grids';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'pms-notes',
	templateUrl: './notes.component.html',
	styles: [],
})
export class NotesComponent extends BaseComponent implements OnInit {

	@Input()
	entityType: EntityType;

	@Input()
	parentId: number;

	@Input()
	isPaymentProcessing: boolean;

	@Input()
	updateOn: Observable<any> = null;

	@Output()
	update: EventEmitter<boolean> = new EventEmitter();

	notes: NoteResponse[] = [];
	isSearching = false;
	pageSettings: PageSettingsModel = {
		pageSizes: PAGE_LENGTH_LIST,
		pageSize: PAGE_LENGTH.PAGE_10,
	};
	sortSettings: SortSettingsModel = {
		columns: [
			{field: 'createdOn', direction: 'Descending'},
		],
	};
	dateFormat = TABLE_DATE_FORMATS.MM_DD_YYYY_H_MM_A;

	constructor(
		public modalManagerService: ModalManagerService,
		public notesService: NotesService,
	) {
		super();
	}

	ngOnInit() {
		this.findNotes();

		if (!_isNil(this.updateOn)) {
			this.updateOn.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
				this.findNotes();
			});
		}
	}

	findNotes() {
		const request = new FindNotesByParentEntityRequest();
		request.entityType = this.entityType;
		request.parentId = this.parentId;

		this.isSearching = true;

		this.notesService.findNotes(request).subscribe((notes) => {
			this.notes = notes;
			this.isSearching = false;
		});
	}

	addNewNote() {
		this.openNoteModal('Add Note');
	}

	editNote(row) {
		this.openNoteModal('Edit Note', row);
	}

	openNoteModal(modalTitle: string, note: NoteResponse = null) {
		this.modalManagerService.open(EditNoteModalComponent, {
			data: {
				modalTitle,
				parentId: this.parentId,
				entityType: this.entityType,
				note,
			},
		}).onClose.subscribe((response: boolean) => {
			if (response) {
				this.update.emit(response);
				this.findNotes();
			}
		});
	}
}
