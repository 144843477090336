// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { RefractionEyeResponse } from './refraction-eye-response';

/** See com.rev360.pms.api.controller.patient.opticalsummary.OpticalSummaryLatestFindingsRefractionResponse */
/* istanbul ignore next */
@GandalfModel
export class OpticalSummaryLatestFindingsRefractionResponse extends GandalfModelBase {

	@GandalfProperty()
	addVisualAcuity: string;

	@GandalfProperty()
	addVisualAcuityModifier: string;

	@GandalfProperty()
	comment: string;

	@GandalfProperty()
	instructions: string;

	@GandalfProperty()
	lastUpdated: Date;

	@GandalfProperty()
	od: RefractionEyeResponse;

	@GandalfProperty()
	odVisualAcuity: string;

	@GandalfProperty()
	odVisualAcuityModifier: string;

	@GandalfProperty()
	os: RefractionEyeResponse;

	@GandalfProperty()
	osVisualAcuity: string;

	@GandalfProperty()
	osVisualAcuityModifier: string;

	@GandalfProperty()
	ouVisualAcuity: string;

	@GandalfProperty()
	ouVisualAcuityModifier: string;

	@GandalfProperty()
	refractionName: string;

}
