import { Component, OnInit } from '@angular/core';
import { PreferenceDefaults, PreferenceName, PreferredPhoneType } from '@gandalf/constants';
import { ControlContainer, UntypedFormGroup, Validators } from '@angular/forms';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { _isEmpty } from '@core/lodash/lodash';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { preferredPhoneTypeValidator } from '@shared/validators/preferred-phone-type.validation';
import { revRequiredValidator } from '@shared/validators/rev-required-validation';
import { EnumUtil } from 'morgana';

@Component({
	selector: 'pms-family-member-phone-information-form',
	templateUrl: './family-member-phone-information-form.component.html',
	styles: [
	],
})
export class FamilyMemberPhoneInformationFormComponent implements OnInit {

	componentForm: UntypedFormGroup;

	preferredPhoneTypeOptions = [
		PreferredPhoneType.HOME,
		PreferredPhoneType.WORK,
		PreferredPhoneType.CELL,
	];

	isHomePhoneRequired: boolean;
	isWorkPhoneRequired: boolean;
	isCellPhoneRequired: boolean;
	isEmailRequired: boolean;
	isEmailDeclined: boolean;

	constructor(
		private securityManagerService: SecurityManagerService,
		public controlContainer: ControlContainer,
	) { }

	ngOnInit(): void {
		this.componentForm = this.controlContainer.control as UntypedFormGroup;
		this.isEmailRequired = this.securityManagerService.preferenceValueIsOn(
			PreferenceName.DEMOGRAPHICS_EMAIL_REQUIRED.value,
			PreferenceDefaults.DEMOGRAPHICS_EMAIL_REQUIRED.value,
		);

		this.addContactInformationValidators();
		this.setRequiredFields();
		this.handleFormState();
	}

	/* istanbul ignore next */
	getFormGroup(controlName: string): UntypedFormGroup {
		return this.componentForm.get(controlName) as UntypedFormGroup;
	}

	handleFormState() {
		FormUtilsService.reactToValueChanges(this.componentForm, () => this.setRequiredFields(), true);
	}

	setRequiredFields() {
		this.isEmailDeclined = this.componentForm.get('contactInformation.emailDeclined').value;
		this.setEmailRequired();
		this.setPreferredPhoneRequired();
	}

	setEmailRequired() {
		const emailInput = this.componentForm.get('contactInformation.email');
		const emailDeclinedInput = this.componentForm.get('contactInformation.emailDeclined');
		FormUtilsService.disabledWhen(emailInput, emailDeclinedInput.value || this.componentForm.disabled);
		FormUtilsService.disabledWhen(emailDeclinedInput, !_isEmpty(emailInput.value) || this.componentForm.disabled);
	}

	setPreferredPhoneRequired() {
		const preferredPhoneType = this.componentForm.get('contactInformation.phoneInformation.preferredPhoneType')?.value;
		if (preferredPhoneType) {
			this.isHomePhoneRequired = EnumUtil.equals(preferredPhoneType, PreferredPhoneType.HOME);
			this.isWorkPhoneRequired = EnumUtil.equals(preferredPhoneType, PreferredPhoneType.WORK);
			this.isCellPhoneRequired = EnumUtil.equals(preferredPhoneType, PreferredPhoneType.CELL);
		} else {
			this.setDefaultPreferredPhoneType();
		}
	}

	setDefaultPreferredPhoneType() {
		FormUtilsService.setValueWhenNil(this.componentForm.get('contactInformation.phoneInformation.preferredPhoneType'), PreferredPhoneType.HOME.value);
	}

	addContactInformationValidators() {
		this.componentForm.get('contactInformation.phoneInformation').setValidators([
			preferredPhoneTypeValidator(
				Validators.required,
				'preferredPhoneType',
				{
					home: {
						formControlName: 'homePhone',
						message: 'Home Phone is required',
					},
					work: {
						formControlName: 'workPhone',
						message: 'Work Phone is required',
					},
					cell: {
						formControlName: 'cellPhone',
						message: 'Cell Phone is required',
					},
				},
			),
		]);

		if (this.isEmailRequired) {
			this.componentForm.get('contactInformation.email').addValidators(revRequiredValidator('Email is required'));
		}
		this.componentForm.updateValueAndValidity();
	}
}
