// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { DynamicTestResponse } from './dynamic-test-response';

/** See com.rev360.pms.api.controller.encounter.dynamic.DynamicTestHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class DynamicTestHistoryResponse extends GandalfModelBase {

	@GandalfProperty()
	encounterDate: Date;

	@GandalfProperty()
	encounterId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	encounterStatus: constants.EncounterStatus;

	@GandalfProperty()
	test: DynamicTestResponse;

}
