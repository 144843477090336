// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.messaging.SearchMessageParticipantPersonsRequest */
/* istanbul ignore next */
@GandalfModel
export class SearchMessageParticipantPersonsRequest extends GandalfModelBase {

	@GandalfLabel('Folder')
	@GandalfValidator({ notNull: { message: 'Folder is required' } })
	@GandalfProperty({ isRequired: true })
	messageFolderId: number;

	@GandalfConstantDecorator()
	@GandalfLabel('Message Participant Status')
	@GandalfProperty()
	messageParticipantStatus: constants.MessageParticipantStatus;

	@GandalfConstantDecorator()
	@GandalfLabel('Message Priority')
	@GandalfProperty()
	messagePriority: constants.MessagePriority;

	@GandalfLabel('Page Number')
	@GandalfValidator({ notNull: { message: 'Page Number is required' } })
	@GandalfValidator({ min: { min: 1, message: 'Page Number must be at least 1' } })
	@GandalfProperty({ isRequired: true })
	pageNumber: number;

	@GandalfLabel('Page Size')
	@GandalfValidator({ notNull: { message: 'Page Size is required' } })
	@GandalfValidator({ min: { min: 1, message: 'Page Size must be between 1 and 1000' } })
	@GandalfValidator({ max: { max: 1000, message: 'Page Size must be between 1 and 1000' } })
	@GandalfProperty({ isRequired: true })
	pageSize: number;

	@GandalfConstantDecorator()
	@GandalfLabel('Sort Option')
	@GandalfValidator({ notNull: { message: 'Sort Option is required' } })
	@GandalfProperty({ isRequired: true })
	sortOption: constants.MessageParticipantSortOption;

	@GandalfConstantDecorator()
	@GandalfLabel('Sort Order')
	@GandalfValidator({ notNull: { message: 'Sort Order is required' } })
	@GandalfProperty({ isRequired: true })
	sortOrder: constants.SortOrder;

	@GandalfLabel('Starred')
	@GandalfProperty()
	starred: boolean;

}
