import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { State } from '@app-store/reducers';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { INTER_APP_CONSTANTS } from '@core/legacy/inter-app.constants';
import { AppointmentResponse } from '@gandalf/model/appointment-response';
import { CancelAppointmentRequest } from '@gandalf/model/cancel-appointment-request';
import { StartAppointmentRequest } from '@gandalf/model/start-appointment-request';
import { ScheduleGandalfService } from '@gandalf/services';
import { Store } from '@ngrx/store';
import { GetEncounterHistory } from '@patients-store/actions';
import { tap } from 'rxjs/operators';
import { LOAD_MODULE_WITH_ACTION } from '@core/legacy/hit-pms-html.constants';
import { StartAppointmentCalendarResponse } from '@gandalf/model/start-appointment-calendar-response';
import { EventService } from '@core/event/event.service';
import { APP_ROUTING_CONSTANTS } from '../../app-routing.constants';
import { WaitlistAppointmentEvent } from '../services/schedule-main/schedule-main.service';

@Injectable({
	providedIn: 'root',
})
export class ScheduleService {

	constructor(
		private scheduleGandalfService: ScheduleGandalfService,
		private eventsManagerService: EventsManagerService,
		private store: Store<State>,
		private eventService: EventService,
	) {
	}

	cancelAppointment(request: CancelAppointmentRequest, patientId: number) {
		return this.scheduleGandalfService.cancelAppointment(request).pipe(
			tap(() => {
				this.store.dispatch(new GetEncounterHistory({patientId}));
				this.eventsManagerService.publish(INTER_APP_CONSTANTS.REV360.PATIENTS.REFRESH_PATIENT_APPOINTMENTS, {patientId});
				this.eventsManagerService.publish(INTER_APP_CONSTANTS.REV360.PATIENTS.REFRESH_PATIENT_EXAM_HISTORY_POD, patientId);
				this.eventService.publishUpdatePatientAppointments(patientId);
			}),
		);
	}

	/* istanbul ignore next: gandalf */
	confirmAppointment(formGroup: UntypedFormGroup) {
		return this.scheduleGandalfService.confirmAppointment(formGroup.value);
	}

	/* istanbul ignore next: gandalf */
	confirmAppointmentCalendar(formGroup: UntypedFormGroup) {
		return this.scheduleGandalfService.confirmAppointmentCalendar(formGroup.value);
	}

	noShowAppointmentLegacy(appointmentId: number) {
		return this.scheduleGandalfService.noShowAppointmentLegacy(appointmentId).pipe(
			tap(appointmentResponse => {
				this.store.dispatch(new GetEncounterHistory({patientId: appointmentResponse.patientId}));
			}),
		);
	}

	noShowAppointment(appointmentId: number) {
		return this.scheduleGandalfService.noShowAppointment(appointmentId).pipe(
			tap(appointmentResponse => {
				this.store.dispatch(new GetEncounterHistory({patientId: appointmentResponse.patientName.patientId}));
			}),
		);
	}

	startAppointment(request: StartAppointmentRequest) {
		return this.scheduleGandalfService.startAppointment(request).pipe(
			tap((scheduleItemResponse: AppointmentResponse) => {
				this.store.dispatch(new GetEncounterHistory({patientId: scheduleItemResponse.patientId}));
			}),
		);
	}

	startAppointmentCalendar(request: StartAppointmentRequest) {
		return this.scheduleGandalfService.startAppointmentCalendar(request).pipe(
			tap((response: StartAppointmentCalendarResponse) => {
				this.store.dispatch(new GetEncounterHistory({patientId: response.appointmentCalendar.patientName.patientId}));
			}),
		);
	}

	/* istanbul ignore next: gandalf */
	findPatientAlertsByPatientId(patientId: number) {
		return this.scheduleGandalfService.findPatientAlertsByPatientId(patientId);
	}

	newAppointmentForPatientFromWaitlist(payload: WaitlistAppointmentEvent) {
		this.eventsManagerService.publish(LOAD_MODULE_WITH_ACTION, {
			module: APP_ROUTING_CONSTANTS.SCHEDULE,
			action: INTER_APP_CONSTANTS.REV360.SCHEDULE.NEW_APPOINTMENT_FROM_WAITLIST,
			routePastPersistedRoute: true,
			payload,
		});
	}
}
