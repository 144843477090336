<button
	type="button"
	class="e-control e-btn e-lib"
	revTooltip
	[matMenuTriggerFor]="buttonMenu"
	[tooltipContent]="buttonTooltip"
	[tooltipDisabled]="buttonHidden"
	[ngClass]="buildNgClass()"
	[disabled]="buttonDisabled"
	[attr.data-test-id]="dataTestId"
	[attr.data-tooltip-content]="buttonTooltip"
>
	@if (showIconLeft()) {
		<i class="e-btn-icon fa fa-{{buttonIcon}}" [ngClass]="buildIconLeftNgClass()"></i>
	}
	{{ buttonLabel }}
	@if (showIconRight()) {
		<i class="e-btn-icon fa fa-{{buttonIcon}}" [ngClass]="buildIconRightNgClass()"></i>
	}
</button>

<mat-menu #buttonMenu="matMenu">
	@for (buttonMenuItem of buttonMenuItems; track buttonMenuItem) {
		@if (buttonMenuItem.isDivider) {
			<mat-divider></mat-divider>
		} @else {
			<button
				mat-menu-item
				[disableRipple]="true"
				[disabled]="buttonMenuItem.disabled"
				(click)="clickMenuItem(buttonMenuItem)"
			>
				{{ buttonMenuItem.text }}
			</button>
		}
	}
</mat-menu>
