import { Component, Input, OnDestroy } from '@angular/core';
import { EnumUtil, ModalManagerService } from 'morgana';
import { _isEmpty, _isNil } from '@core/lodash/lodash';
import { PatientCommunicationService } from '@core/patient-communication/patient-communication.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { ToasterService } from '@core/toaster/toaster.service';
import { ConnectMessageCategory, ConnectMessageMethod } from '@gandalf/constants';
import { AppointmentResponse } from '@gandalf/model/appointment-response';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppointmentSendMessageConfirmModalLegacyComponent } from './appointment-send-message-confirm-modal-legacy.component';

interface IntItemModel {
	text: string;
	id: number;
	iconCss: string;
}

@Component({
	selector: 'pms-appointment-reminder-button-legacy',
	templateUrl: './appointment-reminder-button-legacy.component.html',
	styles: [],
	providers: [ModalManagerService],
})
export class AppointmentReminderButtonLegacyComponent implements OnDestroy {

	emailComType: IntItemModel = {
		text: 'Send Email',
		id: ConnectMessageMethod.EMAIL.value,
		iconCss: 'fa fa-envelope',
	};
	textComType: IntItemModel = {
		text: 'Send Text',
		id: ConnectMessageMethod.SMS.value,
		iconCss: 'fa fa-commenting',
	};
	messageTypes: IntItemModel[] = [this.emailComType, this.textComType];
	private unsubscribe$ = new Subject<void>();

	@Input() appointments: AppointmentResponse[];

	constructor(
		public modalManagerService: ModalManagerService,
		public securityManagerService: SecurityManagerService,
		public patientCommunicationService: PatientCommunicationService,
		public toasterService: ToasterService,
	) {
	}

	get appointmentStarted() {
		// if there's at least one appointment and an encounter status is present
		return this.appointments?.length > 0 && !_isNil(this.firstAppointment.encounterStatus);
	}

	get firstAppointment() {
		return this.appointments[0];
	}

	get useDropdown() {
		return this.isEmailable && this.isTextable;
	}

	get isEmailable() {
		return !_isEmpty(this.firstAppointment.patientEmail);
	}

	get isTextable() {
		return (this.securityManagerService.allowConnectAppointmentReminders()
			&& !_isEmpty(this.firstAppointment.patientPhoneNumbers.cellPhone));
	}

	confirmAppointmentMessage($event) {
		this.patientCommunicationService.canSendPatientMessage(
			this.firstAppointment.patientId,
			EnumUtil.findEnumByValue($event.item.id, ConnectMessageMethod),
			ConnectMessageCategory.APPOINTMENT,
		).subscribe((response) => {
			if (response.canSendPatientMessage === false) {
				this.dispatchToasterError(response);
			} else {
				this.dispatchSendMessageModal($event);
			}
		});
	}

	/* istanbul ignore next */
	dispatchToasterError(canSendResponse) {
		this.toasterService.showError({content: canSendResponse.statusMessage, timeOut: 0});
	}

	/* istanbul ignore next */
	dispatchSendMessageModal($event) {
		this.modalManagerService.open(AppointmentSendMessageConfirmModalLegacyComponent, {
			data: {
				appointment: this.firstAppointment,
				communicationMethod: EnumUtil.findEnumByValue($event.item.id, ConnectMessageMethod),
			},
		}).onClose.pipe(takeUntil(this.unsubscribe$))
			.subscribe((response) => {
				if (response) {
					this.toasterService.showSavedSuccess({content: 'Appointment Reminder Successfully Sent.'});
				}
			});
	}

	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
