import { Injectable } from '@angular/core';
import { DrugSearchRequest } from '@gandalf/model/drug-search-request';
import { DrugGandalfService } from '@gandalf/services';

@Injectable({
	providedIn: 'root',
})
export class DrugSearchService {

	constructor(
		private drugGandalfService: DrugGandalfService,
	) { }

	/* istanbul ignore next: gandalf */
	searchDrugs(request: DrugSearchRequest) {
		return this.drugGandalfService.searchDrugs(request);
	}

}
