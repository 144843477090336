import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { UserLocationsService } from '@core/user-locations/user-locations.service';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';
import { Observable } from 'rxjs';
import { OptionItemResponse } from '@core/option-item/option-item.service';
import { PracticeLocation } from '@core/security-manager/security-manager.service';

@Component({
	selector: 'pms-location-select-modal',
	templateUrl: './location-select-modal.component.html',
	styles: [],
})
export class LocationSelectModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;
	@ViewChild('templateForm')
	templateForm: NgForm;
	locationsList: OptionItemResponse<PracticeLocation, number>[];
	componentForm: UntypedFormGroup;
	request: any;
	onSubmit: (request: any) => Observable<any>;

	constructor(
		private ref: DynamicModalRef,
		private userLocationService: UserLocationsService,
		private modalConfig: ModalConfig,
		private gandalfFormBuilder: GandalfFormBuilder,
	) {
	}

	ngOnInit() {
		this.request = this.modalConfig.data.request;
		this.onSubmit = this.modalConfig.data.onSubmit;
		this.getUserLocations();
		this.initializeForm(this.request);
	}

	initializeForm(request) {
		this.componentForm = this.gandalfFormBuilder.group(request);
	}

	getUserLocations() {
		this.locationsList = this.userLocationService.getUserActiveLocations();
	}

	submitForm(event) {
		this.templateForm.onSubmit(event);
	}

	save() {
		if (this.componentForm.invalid) {
			return;
		}
		if (this.onSubmit) {
			this.onSubmit(this.componentForm.value).subscribe((result) => {
				this.closeModal(result);
			});
		} else {
			this.closeModal(this.componentForm.value);
		}
	}

	closeModal(result?) {
		this.ref.close(this.modal, result);
	}
}
