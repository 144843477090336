import { Directive, HostListener } from '@angular/core';
import dayjs from 'dayjs';
import { TimePickerComponent } from '@syncfusion/ej2-angular-calendars';

@Directive({
	/* eslint-disable-next-line @angular-eslint/directive-selector */
	selector: 'ejs-timepicker',
})
export class TimepickerKeylistenersDirective {

	constructor(
		private timepickerComponent: TimePickerComponent,
	) {
	}

	@HostListener('keydown.home', ['$event'])
	onHomeKeydown(event: KeyboardEvent) {
		event.preventDefault();
		const currentDate = new Date();
		currentDate.setHours(0, 0, 0, 0);
		this.timepickerComponent.value = currentDate;
		this.timepickerComponent.dataBind();
	}

	@HostListener('keydown.end', ['$event'])
	onEndKeydown(event: KeyboardEvent) {
		event.preventDefault();
		const currentDate = new Date();
		currentDate.setHours(23, 30, 0, 0);
		this.timepickerComponent.value = currentDate;
		this.timepickerComponent.dataBind();
	}

	@HostListener('keydown.ArrowUp', ['$event'])
	onUpKeydown(event: KeyboardEvent) {
		event.preventDefault();
		const currentValue = dayjs(this.timepickerComponent.value);
		if (currentValue.isValid()) {
			let stepDistance = currentValue.minute() % this.timepickerComponent.step;
			if (stepDistance === 0) {
				stepDistance = this.timepickerComponent.step;
			}
			const newDate = currentValue.subtract(stepDistance, 'minute');
			this.timepickerComponent.value = newDate.toDate();
		} else {
			const currentDate = new Date();
			currentDate.setHours(0, 0, 0, 0);
			this.timepickerComponent.value = currentDate;
		}
		this.timepickerComponent.dataBind();
	}

	@HostListener('keydown.ArrowDown', ['$event'])
	onDownKeydown(event: KeyboardEvent) {
		event.preventDefault();
		const currentValue = dayjs(this.timepickerComponent.value);
		if (currentValue.isValid()) {
			const stepDistance = this.timepickerComponent.step - (currentValue.minute() % this.timepickerComponent.step);
			const newDate = currentValue.add(stepDistance, 'minute');
			this.timepickerComponent.value = newDate.toDate();
		} else {
			const currentDate = new Date();
			currentDate.setHours(0, 0, 0, 0);
			this.timepickerComponent.value = currentDate;
		}
		this.timepickerComponent.dataBind();
	}
}
