/* eslint-disable @typescript-eslint/naming-convention */

import { UpdateStatePropertyPayload } from '@app-store/utils/update-state-property-payload';
import { Action } from '@ngrx/store';

export enum PracticeConsentsActionTypes {
	GET_PRACTICE_CONSENTS = '[Get Practice Consents] Get Practice Consents',
	UPDATE_PRACTICE_CONSENTS_PROPERTY = '[Update Practice Consents Property] Update Practice Consents Property',
}

export class GetPracticeConsents implements Action {
	readonly type = PracticeConsentsActionTypes.GET_PRACTICE_CONSENTS;
	constructor() {}
}

export class UpdatePracticeConsentsProperty implements Action {
	readonly type = PracticeConsentsActionTypes.UPDATE_PRACTICE_CONSENTS_PROPERTY;
	constructor(public payload: UpdateStatePropertyPayload) {}
}

export type PracticeConsentsActions =
	GetPracticeConsents
	| UpdatePracticeConsentsProperty;
