<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-lg"
	[attr.data-test-id]="'assignEmployeeModal'">
	<ng-template #header>
		<div class="dlg-template" [attr.data-test-id]="'assignEmployeeModalTitleBar'">Assign Employees</div>
	</ng-template>
	<ng-template #content>
		<ejs-grid #grid
				  [pmsGridSubmit]="isSaving"
				  [dataSource]="invoiceItems"
				  [staticColumns]="['unitPrice', 'extendedPrice', 'quantity', 'code']"
				  autoFitColumns
				  [autoFitBlacklist]="['description']"
				  [allowPaging]="false"
				  [allowSelection]="false"
				  [allowSorting]="true"
				  [sortSettings]="sortOptions"
				  [allowResizing]="true"
				  gridLines="Horizontal"
				  [attr.data-test-id]="'assignEmployeeModalGrid'">
			<e-columns>
				<e-column headerText="ID" field="id" [visible]="false" [isPrimaryKey]="true"></e-column>
				<e-column headerText="Code" field="code"></e-column>
				<e-column headerText="Description" field="description" [customAttributes]="{autofitMinWidth: 50 }"></e-column>
				<e-column headerText="Qty" field="quantity"></e-column>
				<e-column headerText="Unit Price" field="unitPrice" format="C2"></e-column>
				<e-column headerText="Ext. Price" field="extendedPrice" format="C2"></e-column>
				<e-column headerText="Employee" [allowSorting]="false">
					<ng-template #template let-data>
						<ejs-dropdownlist
								[dataSource]="employeeListByPersonId"
								[formControl]="getInvoiceItemControl('responsiblePersonId', data.id)"
								[showClearButton]="true"
								[attr.data-test-id]="'assignEmployeeSelector'">
						</ejs-dropdownlist>
					</ng-template>
				</e-column>
			</e-columns>
		</ejs-grid>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button type="button" iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'assignEmployeeModalCancelButton'">Cancel</button>
		<button ejs-button type="submit" [isPrimary]="true" (click)="submitForm()" [attr.data-test-id]="'assignEmployeeModalSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>
