/* eslint-disable max-len */
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { RouterParam } from '@app-store/selectors';
import { StatefulRouteService } from '@app-store/services/stateful-route.service';
import { RouterStoreUtils } from '@app-store/utils/router-store-utils';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { LegacyModuleManagerService } from '@core/legacy-module-manager/legacy-module-manager.service';
import { HIT_PMS_HTML_EVENTS } from '@core/legacy/hit-pms-html.constants';
import { INTER_APP_CONSTANTS } from '@core/legacy/inter-app.constants';
import { LegacyNavigationService } from '@core/legacy/legacy-navigation.service';
import { NavigationRequest } from '@core/legacy/navigation-request.class';
import { _find, _isNil } from '@core/lodash/lodash';
import { RouterUtilsService } from '@core/router-utils/router-utils.service';
import { BrowserUtil, ScrollUtilService } from 'morgana';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { PatientDefaultSummaryViewPreferenceValues, PreferenceName } from '@gandalf/constants';
import { Store } from '@ngrx/store';
import { RemovePatientTab } from '@patients-store/actions';
import { State } from '@patients-store/reducers';
import { isPatientStateLoaded, selectPatientTabState } from '@patients-store/selectors';
import { StatefulTabUtil } from '@shared/directives/stateful-tab/stateful-tab.util';
import { BehaviorSubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { NavTypes, PatientViews, PatientViewsArray } from '../../patient-views.constant';
import { PatientEventService } from '../patient-event/patient-event.service';
import { PatientComponentConfiguration } from '../patient-util/patient-util.service';
import { TabHistoryService } from '../tab-history/tab-history.service';

interface PatientScreens {
	openedScreens: Map<string, any>;
	dashboard: any;
	patient: any;
	scope: any;
}

@Injectable({
	providedIn: 'root',
})
export class PatientManagerService {

	patientMap = new Map<number, PatientScreens>();
	private isFeatureOn = false;
	private isBuildingDashboard = false;
	private queuedScreen: any;
	private fullScreen = false;
	private FULL_SCREEN_CLASS = 'patient-module-full-screen';
	private fullScreenChanged = new Subject<any>();

	constructor(
		private eventsManagerService: EventsManagerService,
		private legacyNavigationService: LegacyNavigationService,
		private router: Router,
		private ngZone: NgZone,
		private store: Store<State>,
		private patientEventService: PatientEventService,
		private featureService: FeatureService,
		private routerStoreUtils: RouterStoreUtils,
		private routerUtilsService: RouterUtilsService,
		private securityManagerService: SecurityManagerService,
		private tabHistoryService: TabHistoryService,
		public legacyModuleManagerService: LegacyModuleManagerService,
		@Inject(DOCUMENT) private document: Document,
	) {
	}

	subscribeToLegacyEvents() {
		this.isFeatureOn = this.featureService.isFeatureOn(FEATURE_FLAGS.MODULES.PATIENTS);

		if (this.isFeatureOn) {
			this.patientEventService.subscribeToLegacyEvents();

			this.subscribeToRouteChanges();

			this.eventsManagerService.subscribe(HIT_PMS_HTML_EVENTS.PATIENTS.PATIENT_SUMMARY_DASHBOARD_CLOSE_TAB_FOR_PATIENT, (event) => {
				if (this.router.url === '/legacy') {
					this.store.select(selectPatientTabState).pipe(take(1)).subscribe(async (tabState) => {
						if (tabState.keys.length === 1) {
							this.eventsManagerService.publish(INTER_APP_CONSTANTS.REV360.PATIENTS.PATIENTS_SEARCH_DASHBOARD_SELECTED);
						} else {
							const patientState = tabState.get(event.argument);
							const nextPatient = StatefulTabUtil.getNextTab(tabState, patientState);

							await this.ngZone.run(() => StatefulRouteService.routePastPersistedRoute(this.router, nextPatient.currentRoute));
						}

						this.store.dispatch(new RemovePatientTab({patientId: event.argument}));
						this.removeDOMSnapshot(event.argument);
					});
				} else {
					this.store.dispatch(new RemovePatientTab({patientId: event.argument}));
					this.removeDOMSnapshot(event.argument);
				}
			});

			this.eventsManagerService.subscribe(INTER_APP_CONSTANTS.REV360.PATIENTS.PATIENT_FLEX_COMPONENT_SELECT, (event) => {
				this.saveDOMSnapshot(event.argument.patientId);
			});

			this.eventsManagerService.subscribe(INTER_APP_CONSTANTS.REV360.PATIENTS.PATIENTS_SUMMARY_DASHBOARD_SELECT_TAB_FOR_PATIENT, (event) => {
				if (event.argument.view >= 0) {
					this.bootstrapLegacyPatientsApp();

					// check to see if patient state has been initialized
					this.store.select(isPatientStateLoaded).pipe(take(1)).subscribe(async patientStateLoaded => {
						if (patientStateLoaded) {
							this.store.select(selectPatientTabState).pipe(take(1)).subscribe(async tabState => {
								const patientState = tabState.get(event.argument.patientId);

								if (patientState) {
									await this.ngZone.run(() => StatefulRouteService.routePastPersistedRoute(this.router, patientState.currentRoute));
								}
							});
						}

						const view: any = _find(PatientViewsArray, {tabIndex: event.argument.view});
						if (view) {
							await this.ngZone.run(() => this.router.navigate(['patients', 'patient', event.argument.patientId, view.url]));
						}
					});
				}
			});

			this.eventsManagerService.subscribe(INTER_APP_CONSTANTS.REV360.PATIENTS.PATIENTS_MODULE_OPEN_PATIENT, (event) => this.navigateToPatientScreen(event));
			this.eventsManagerService.subscribe(HIT_PMS_HTML_EVENTS.PATIENTS.PATIENTS_MODULE_OPEN_PATIENT, (event) => this.navigateToPatientScreen(event));
			this.eventsManagerService.subscribe(INTER_APP_CONSTANTS.REV360.PATIENTS.FLEX_PATIENT_TAB_CHANGED, (event) => {
				event.argument.view = event.argument.dashboardView;
				return this.navigateToPatientScreen(event);
			});

			this.eventsManagerService.subscribe(INTER_APP_CONSTANTS.REV360.PATIENTS.PATIENT_TAB_MODULE_CLOSED, (event) => {
				const tabHistory = (window as any).tabHistory[event.argument];
				if (!tabHistory) {
					return;
				}

				tabHistory.pop();
				const view: any = _find(PatientViewsArray, {tabIndex: tabHistory[tabHistory.length - 1]});

				this.bootstrapLegacyPatientsApp();

				this.store.select(selectPatientTabState).pipe(take(1)).subscribe(async (tabState) => {
					const patientState = tabState.get(event.argument);
					if (patientState) {
						await this.ngZone.run(() => this.router.navigate(['patients', 'patient', event.argument, view.url]));
					}
				});
			});

			this.eventsManagerService.subscribe(HIT_PMS_HTML_EVENTS.PATIENTS.PATIENTS_MODULE_CLOSE_MODULE, (event) => {
				const tabHistory = (window as any).tabHistory[event.argument.patientId];
				if (!tabHistory) {
					return;
				}

				tabHistory.pop();
				const view: any = _find(PatientViewsArray, {tabIndex: tabHistory[tabHistory.length - 1]});

				this.bootstrapLegacyPatientsApp();

				this.store.select(selectPatientTabState).pipe(take(1)).subscribe(async (tabState) => {
					const patientState = tabState.get(event.argument.patientId);
					if (patientState) {
						await this.ngZone.run(() => this.router.navigate(['patients', 'patient', event.argument.patientId, view.url]));
					}
				});
			});
		}
	}

	navigateToPatientScreen(event): Promise<boolean> {
		this.bootstrapLegacyPatientsApp();
		if (!_isNil(event.argument.view) && event.argument.view >= 0) {
			const view: any = _find(PatientViewsArray, {tabIndex: event.argument.view});
			if (view) {
				return this.ngZone.run(() => StatefulRouteService.routePastPersistedRoute(this.router, ['patients', 'patient', event.argument.patientId, view.url]));
			} else {
				return Promise.resolve(false);
			}
		} else {
			const patientId = !_isNil(event.argument.patientId) ? event.argument.patientId : event.argument;
			return this.navigateToPreferredDashboard(patientId);
		}
	}

	subscribeToRouteChanges() {
		this.routerUtilsService.observeNavigationEvents(NavigationStart, false).subscribe(val => {
			const patientId = this.routerStoreUtils.getRouterParam(RouterParam.PATIENTS_PATIENT_ID);
			if (this.isNavigatingAwayFromPatient(patientId, this.router.url, val.url)) {
				this.saveDOMSnapshot(patientId);
			}
		});
	}

	isNavigatingAwayFromPatient(patientId: number, sourceURL: string, targetURL: string) {
		return patientId && this.isOnPatientRoute(patientId, sourceURL) && !this.isOnPatientRoute(patientId, targetURL);
	}

	buildPatientDashboard(patientId: number) {
		// don't build dashboard if it is already shown
		const isFinishedBuilding = new BehaviorSubject<boolean>(false);
		if (this.isDashboardShown(patientId) || this.isBuildingDashboard) {
			isFinishedBuilding.next(true);
			return isFinishedBuilding;
		}
		this.isBuildingDashboard = true;

		if (this.hasExitingDashboard(patientId)) {
			this.restoreDashboard(patientId);
			isFinishedBuilding.next(true);
		} else {
			this.eventsManagerService.publish(INTER_APP_CONSTANTS.REV360.PATIENTS.CREATE_LEGACY_PATIENT_DASHBOARD, {
				patientId, callback: (event) => {
					this.appendToPatientElement(event);
					isFinishedBuilding.next(true);
				},
			});
		}

		this.isBuildingDashboard = false;

		return isFinishedBuilding.asObservable();
	}

	appendToPatientElement = (event: any) => {
		event.template.addClass(PatientManagerService.getDashboardPatientClassName(event.patient.id));
		this.patientMap.set(event.patient.id, {
			openedScreens: new Map<string, any>(),
			dashboard: event.template,
			patient: event.patient,
			scope: event.scope,
		});

		this.appendScreenToPatientElement({template: event.template}, event.patient.id);
		// check if queued screen matches and then clear it
		if (this.queuedScreen && this.queuedScreen.patientId === event.patient.id) {
			this.buildScreen(this.queuedScreen.patientId, this.queuedScreen.screen);
		}

		this.queuedScreen = null;
	};

	appendPatientScreen(patientId: number, screen: string) {
		if (this.patientMap.get(patientId)) {
			this.buildScreen(patientId, screen);
		} else {
			this.queuedScreen = ({patientId, screen});
		}
	}

	saveDOMSnapshot(patientId: number) {
		const elements = this.document.querySelector('.' + PatientManagerService.getDashboardPatientClassName(patientId));
		this.appendScreenToStorageElement({template: elements});
	}

	removeDOMSnapshot(patientId: number) {
		const elements = this.document.querySelector('.' + PatientManagerService.getDashboardPatientClassName(patientId));
		if (elements) {
			this.eventsManagerService.publish(INTER_APP_CONSTANTS.REV360.PATIENTS.REMOVING_DASHBOARD, {patientId});
			elements.remove();
		}
	}

	addFlexPatient(patientId: number) {
		const navigationRequest = new NavigationRequest('patients', ['patientTabSelected', patientId], 'patient');
		navigationRequest.showHideContainers = false;
		navigationRequest.switchTabs = false;

		this.legacyNavigationService.sendNavigationRequest(navigationRequest);
		const view = this.getDashboardPreferenceIndex();
		this.eventsManagerService.publish(INTER_APP_CONSTANTS.REV360.PATIENTS.SYNC_PATIENT_FLEX, {
			patientId,
			view,
		});
	}

	private navigateToPreferredDashboard(patientId: number): Promise<boolean> {
		return this.ngZone.run(() => {
			const dashboardPreference = this.securityManagerService.preferenceValue(PreferenceName.PATIENTS_DEFAULT_SUMMARY_VIEW.value);
			const viewUrl = dashboardPreference === PatientDefaultSummaryViewPreferenceValues.PATIENT_SUMMARY.value ? PatientViews.PATIENT_SUMMARY.url : PatientViews.OPTICAL_SUMMARY.url;
			return StatefulRouteService.routePastPersistedRoute(this.router, ['patients', 'patient', patientId, viewUrl]);
		});
	}

	getDashboardPreferenceIndex() {
		const dashboardPreference = this.securityManagerService.preferenceValue(PreferenceName.PATIENTS_DEFAULT_SUMMARY_VIEW.value);
		const viewIndex = dashboardPreference === PatientDefaultSummaryViewPreferenceValues.PATIENT_SUMMARY.value ? PatientViews.PATIENT_SUMMARY.tabIndex : PatientViews.OPTICAL_SUMMARY.tabIndex;

		return viewIndex;
	}

	isOnPatientRoute(patientId: number, route: string) {
		return route.indexOf(`/patient/${patientId}/`) !== -1;
	}

	private buildScreen(patientId: number, screen: string) {
		const patient = this.patientMap.get(patientId).patient;
		const scope = this.patientMap.get(patientId).scope;

		this.eventsManagerService.publish(INTER_APP_CONSTANTS.REV360.PATIENTS.GET_PATIENT_SCREEN, {
			patient,
			scope,
			screen,
			callback: this.appendScreenToPatientElement,
		});
	}

	private appendScreenToPatientElement = (event: any, patientId: number) => {
		if (this.isDashboardShown(patientId)) {
			return;
		}

		const element = this.document.querySelector('.patient-container');
		if (element && event.template.appendTo) {
			event.template.appendTo(element);
		} else if (element && event.template.append) {
			element.append(event.template);
		}
	};

	private appendScreenToStorageElement = (event: any) => {
		const element = this.document.querySelector('.patient-container-storage');
		if (element && event.template) {
			element.append(event.template);
		}
	};

	private hasExitingDashboard(patientId: number) {
		const storageElem = this.document.querySelector('.patient-container-storage');
		if (!storageElem) {
			return false;
		}

		return !!storageElem.querySelector('.' + PatientManagerService.getDashboardPatientClassName(patientId));
	}

	private restoreDashboard(patientId: number) {
		const storageElem = this.document.querySelector('.patient-container-storage');
		if (!storageElem) {
			return false;
		}

		const elem = storageElem.querySelector('.' + PatientManagerService.getDashboardPatientClassName(patientId));
		this.appendScreenToPatientElement({template: elem}, patientId);
	}

	isDashboardShown(patientId: number) {
		const element = this.document.querySelector('.patient-container');
		if (!element) {
			return false;
		}

		return !!element.querySelector('.' + PatientManagerService.getDashboardPatientClassName(patientId));
	}

	private static getDashboardPatientClassName(patientId: number) {
		return `dashboard-patient-${patientId}`;
	}

	showLegacyComponent(config: PatientComponentConfiguration) {
		/*
		* setTimeout is here to deal with a race condition in the buildPatientDashboard. If the patient dashboard is built twice it causes
		* all the eventsManager subscriptions to fire multiple times
		 */
		setTimeout(() => {
			if (config.isFlex) {
				this.saveDOMSnapshot(config.patientId);
				return this.eventsManagerService.hideContainerAndPublish(INTER_APP_CONSTANTS.REV360.PATIENTS.SELECT_PATIENT_MODULE, {
					tabIndex: config.tabIndex,
					navType: NavTypes.EXPAND,
					patientId: config.patientId,
				});
			} else {
				this.buildPatientDashboard(config.patientId).subscribe(() => {
					this.eventsManagerService.publish(INTER_APP_CONSTANTS.REV360.PATIENTS.SELECT_PATIENT_MODULE, {
						tabIndex: config.tabIndex,
						navType: NavTypes.EXPAND,
						patientId: config.patientId,
					});
				});
			}
		});
	}

	private bootstrapLegacyPatientsApp() {
		this.legacyModuleManagerService.bootstrapPatientApp();
	}

	initializePatientScreen(tabIndex: number, scrollToTop = true, forceAddToTabHistory = false) {
		const patientId = this.routerStoreUtils.getRouterParam(RouterParam.PATIENTS_PATIENT_ID);
		const encounterId = this.routerStoreUtils.getRouterParam(RouterParam.ENCOUNTERS_ENCOUNTER_ID);

		this.saveDOMSnapshot(patientId);

		// don't save tab history on an EMR screen
		if (forceAddToTabHistory || !encounterId) {
			this.tabHistoryService.addToTabHistory(patientId, tabIndex);
		}

		if (scrollToTop) {
			ScrollUtilService.scrollToTop();
		}
	}

	async returnToPreviousTab(patientId: number) {
		const view = this.tabHistoryService.getPreviousPatientTab(patientId);

		if (view) {
			await this.router.navigate(['patients', 'patient', patientId, view.url]);
			// prevent chrome from randomly selecting text on the previous tab from dblclick
			BrowserUtil.clearSelection();
		}
	}

	toggleFullScreen() {
		this.fullScreen = !this.fullScreen;
		this.onFullScreenChange();
	}

	private onFullScreenChange() {
		if (this.fullScreen) {
			this.addDocumentFullScreenClass();
		} else {
			this.removeDocumentFullScreenClass();
		}
		this.fullScreenChanged.next(true);
	}

	private removeDocumentFullScreenClass() {
		this.document.body.classList.remove(this.FULL_SCREEN_CLASS);
	}

	private addDocumentFullScreenClass() {
		this.document.body.classList.add(this.FULL_SCREEN_CLASS);
	}

	collapseFullScreen() {
		this.fullScreen = false;
		this.onFullScreenChange();
	}

	get isFullScreen() {
		return this.fullScreen;
	}

	observeFullScreenChange() {
		return this.fullScreenChanged.asObservable();
	}
}
