import { State as AppState } from '@app-store/reducers';
import * as invoiceClaimHistoryTableReducer from '@invoices-store/reducers/invoice-claim-history-table.reducer';
import { InvoiceClaimHistoryTableStateMap } from '@invoices-store/reducers/invoice-claim-history-table.reducer';
import * as invoiceCurrentTabState from '@invoices-store/reducers/invoice-current-tab.reducer';
import { InvoiceCurrentTabState } from '@invoices-store/reducers/invoice-current-tab.reducer';
import * as invoiceDetailsTableReducer from '@invoices-store/reducers/invoice-details.reducer';
import { InvoiceDetailsStateMap } from '@invoices-store/reducers/invoice-details.reducer';
import * as invoiceDocumentsAndImagesTableReducer from '@invoices-store/reducers/invoice-documents-and-images-table.reducer';
import { InvoiceDocumentsAndImagesTableStateMap } from '@invoices-store/reducers/invoice-documents-and-images-table.reducer';
import * as invoicePaymentHistoryTableReducer from '@invoices-store/reducers/invoice-payment-history-table.reducer';
import { InvoicePaymentHistoryTableStateMap } from '@invoices-store/reducers/invoice-payment-history-table.reducer';
import * as invoiceStatementHistoryTableReducer from '@invoices-store/reducers/invoice-statement-history-table.reducer';
import { InvoiceStatementHistoryTableStateMap } from '@invoices-store/reducers/invoice-statement-history-table.reducer';
import * as invoiceTabReducer from '@invoices-store/reducers/invoice-tab.reducer';
import { InvoiceTabStateMap } from '@invoices-store/reducers/invoice-tab.reducer';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

export interface State extends AppState {
	invoices: InvoicesState;
}

export interface InvoicesState {
	invoiceTabState: InvoiceTabStateMap;
	invoiceDetailsTableState: InvoiceDetailsStateMap;
	invoiceCurrentTabState: InvoiceCurrentTabState;
	invoicePaymentHistoryTableState: InvoicePaymentHistoryTableStateMap;
	invoiceClaimHistoryTableState: InvoiceClaimHistoryTableStateMap;
	invoiceStatementHistoryTableState: InvoiceStatementHistoryTableStateMap;
	invoiceDocumentsAndImagesTableState: InvoiceDocumentsAndImagesTableStateMap;
}

export const reducers: ActionReducerMap<InvoicesState> = {
	invoiceTabState: invoiceTabReducer.reducer,
	invoiceDetailsTableState: invoiceDetailsTableReducer.reducer,
	invoicePaymentHistoryTableState: invoicePaymentHistoryTableReducer.reducer,
	invoiceClaimHistoryTableState: invoiceClaimHistoryTableReducer.reducer,
	invoiceStatementHistoryTableState: invoiceStatementHistoryTableReducer.reducer,
	invoiceDocumentsAndImagesTableState: invoiceDocumentsAndImagesTableReducer.reducer,
	invoiceCurrentTabState: invoiceCurrentTabState.reducer,
};

export const selectInvoicesState = createFeatureSelector<InvoicesState>('invoices');
