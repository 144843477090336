// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { ReferenceDataSummaryResponse } from './reference-data-summary-response';

/** See com.rev360.pms.api.controller.patient.contact.ContactListResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactListResponse extends GandalfModelBase {

	@GandalfProperty()
	email: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	emergencyContactType: constants.EmergencyContactType;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	isEmergencyContact: boolean;

	@GandalfProperty()
	name: PersonNameResponse;

	@GandalfProperty()
	phone: string;

	@GandalfProperty()
	role: ReferenceDataSummaryResponse;

}
