import { Injectable } from '@angular/core';
import { LOCAL_STORAGE_CODES } from '@shared/constants/local-storage.constants';

@Injectable({
	providedIn: 'root',
})
export class LocalStorageService {

	constructor() {
	}

	setItem(key: LOCAL_STORAGE_CODES, value) {
		localStorage.setItem(key, value);
	}

	getItem(key: LOCAL_STORAGE_CODES) {
		return localStorage.getItem(key);
	}
}
