import { Injectable } from '@angular/core';
import { OptionItemService } from '@core/option-item/option-item.service';
import { SortingService } from 'morgana';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { CodeSet } from '@gandalf/constants';
import { CarePlanTemplateResponse } from '@gandalf/model/care-plan-template-response';
import { CreatePracticeDiagnosisRequest } from '@gandalf/model/create-practice-diagnosis-request';
import { FindPracticeDiagnosesRequest } from '@gandalf/model/find-practice-diagnoses-request';
import { PracticeDiagnosisListResponse } from '@gandalf/model/practice-diagnosis-list-response';
import { PracticeDiagnosisResponse } from '@gandalf/model/practice-diagnosis-response';
import { UpdatePracticeDiagnosisRequest } from '@gandalf/model/update-practice-diagnosis-request';
import { AdminDiagnosisGandalfService } from '@gandalf/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface CarePlanTemplateResponseForDropdown extends CarePlanTemplateResponse {
	value: number;
	label: string;
}

export interface PracticeDiagnosisResponseForTable extends PracticeDiagnosisListResponse {
	activeText: string;
	carePlanTemplateName: string;
}

@Injectable({
	providedIn: 'root',
})
export class AdminDiagnosisService {

	constructor(
		private diagnosisGandalfService: AdminDiagnosisGandalfService,
	) {
	}

	@ShowSavedSuccessToast()
	/*istanbul ignore next: gandalf*/
	activatePracticeDiagnosis(practiceDiagnosisId: number): Observable<void> {
		return this.diagnosisGandalfService.activatePracticeDiagnosis(practiceDiagnosisId);
	}

	@ShowSavedSuccessToast()
	/*istanbul ignore next: gandalf*/
	deactivatePracticeDiagnosis(practiceDiagnosisId: number): Observable<void> {
		return this.diagnosisGandalfService.deactivatePracticeDiagnosis(practiceDiagnosisId);
	}

	findPracticeDiagnosesForPractice(codeSet: CodeSet): Observable<PracticeDiagnosisResponseForTable[]> {
		const request = new FindPracticeDiagnosesRequest();
		request.codeSet = codeSet;
		return this.diagnosisGandalfService.findPracticeDiagnosesForPractice(request).pipe(
			map(diagnosisResponses => diagnosisResponses.map(response => this.formatPracticeDiagnosisResponseForTable(response))),
			map(diagnosisResponses => SortingService.sortBy(diagnosisResponses, ['code', 'id'], ['asc', 'asc'])),
		);
	}

	/*istanbul ignore next: gandalf*/
	@ShowSavedSuccessToast()
	createPracticeDiagnosis(request: CreatePracticeDiagnosisRequest): Observable<PracticeDiagnosisResponse> {
		return this.diagnosisGandalfService.createPracticeDiagnosis(request);
	}

	/*istanbul ignore next: gandalf*/
	@ShowSavedSuccessToast()
	updatePracticeDiagnosis(request: UpdatePracticeDiagnosisRequest): Observable<PracticeDiagnosisResponse> {
		return this.diagnosisGandalfService.updatePracticeDiagnosis(request);
	}

	findCarePlanTemplates(): Observable<CarePlanTemplateResponseForDropdown[]> {
		return this.diagnosisGandalfService.findCarePlanTemplates().pipe(
			map(templates => OptionItemService.toOptionItems(templates, item => item.carePlanTemplateId, item => item.name)),
			map(templates => SortingService.sortBy(templates, ['name', 'carePlanTemplateId'], ['asc', 'asc'])),
		);
	}

	formatPracticeDiagnosisResponseForTable(response: PracticeDiagnosisListResponse): PracticeDiagnosisResponseForTable {
		const tableResponse = response as PracticeDiagnosisResponseForTable;
		tableResponse.activeText = response.status.label;
		tableResponse.carePlanTemplateName = response.carePlanTemplate ? response.carePlanTemplate.name : null;
		return tableResponse;
	}
}
