/**
 * Registers a property name under the given metadata key, so it can be enumerated
 *
 * This doesn't associate a value to the property name, only saves the property name under that metadata key
 */
export function registerMetadataProperty(
	target: object,
	metadataKey: symbol,
	propertyName: string,
) {
	if (!Reflect.hasOwnMetadata(metadataKey, target)) {
		Reflect.defineMetadata(metadataKey, [propertyName], target);
	} else {
		const propertyNames: string[] = Reflect.getMetadata(metadataKey, target);
		if (!propertyNames) {
			Reflect.defineMetadata(metadataKey, [propertyName], target);
		} else if (propertyNames.indexOf(propertyName) === -1) {
			propertyNames.push(propertyName);
		}
	}
}

/**
 * Enumerates all property names which have been stored to this metadata key
 *
 * These property names will not necessarily have a value stored with them, but they have been registered as metadata properties.
 */
export function enumerateMetadataPropertyNames(target: object, metadataKey: symbol): Array<string> {
	return Reflect.getMetadata(metadataKey, target);
}

/**
 * Sets the metadata value associated with this property name, for the given metadata key
 */
export function setMetadataPropertyValue(
	target: object,
	metadataKey: symbol,
	propertyName: string,
	value: any,
) {
	registerMetadataProperty(target, metadataKey, propertyName);
	Reflect.defineMetadata(metadataKey, value, target, propertyName);
}

/**
 * Gets the metadata value associated with this property name, for the given metadata key
 */
export function getMetadataPropertyValue(
	target: object,
	metadataKey: symbol,
	propertyName: string,
) {
	return Reflect.getMetadata(metadataKey, target, propertyName);
}

/**
 * Creates a dictionary of property names to metadata values
 * @param target The object the metadata is associated with
 * @param metadataKey which Gandalf metadata is being queried
 */
export function getMetadataPropertyValues(target: object, metadataKey: symbol): object {
	const propertyDictionary = {};

	const propertyNames: string[] = enumerateMetadataPropertyNames(target, metadataKey);
	if (propertyNames) {
		propertyNames.forEach(propertyName => {
			propertyDictionary[propertyName] = getMetadataPropertyValue(target, metadataKey, propertyName);
		});
	}

	return propertyDictionary;
}
