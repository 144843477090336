import { AfterViewChecked, Directive, HostListener, Input } from '@angular/core';
import { _concat, _difference, _get, _includes, _isEmpty, _isNil } from '@core/lodash/lodash';
import { Action, GridActionEventArgs, GridComponent } from '@syncfusion/ej2-angular-grids';
import { Subject } from 'rxjs';
import { debounceTime, throttleTime } from 'rxjs/operators';

const DEFAULT_AUTOWIDTH_COLUMN_MIN_WIDTH = 100;

/**
 * Auto Fit Columns Directive
 * Automatically adjusts columns to be exactly as wide as needed to display the widest
 * cell in the column. This is useful for columns that should not be truncated.
 */
@Directive({
	/* eslint-disable-next-line @angular-eslint/directive-selector */
	selector: 'ejs-grid[autoFitColumns]',
	exportAs: 'AutoFitColumnsDirective',
})
export class AutoFitColumnsDirective implements AfterViewChecked {
	@Input()
	autoFitBlacklist: string[] = [];

	@Input()
	autoFitTriggers: Action[];

	@Input()
	staticColumns = [];

	gridIsShown = true;
	private dataBound = new Subject<void>();

	constructor(
		private grid: GridComponent,
	) {
		/**
		 * This allows us to use the dataBound event to trigger auto-fitting, which is ideal. What isn't ideal is that
		 * with the loading spinner and several other methods firing there are often multiple dataBound events fired
		 * during the initialization of a table. The debounce "kind of" makes sure that we are always reacting to last
		 * dataBound event upon initialization. Furthermore, the refreshColumns method on the grid triggers dataBound,
		 * so the throttle prevents an endless loop from occurring by throwing away any additional dataBound events
		 * that may happen as a by product of the previous event being processed.
		 */
		this.dataBound.pipe(
			debounceTime(250),
			throttleTime(1000),
		).subscribe(() => {
			this.runAutoFitSequence();
			this.calculateAutoWidthColumns();
		});

		this.grid?.created?.subscribe(() => {
			this.setDefaultBlacklistColumnWidth();
		});
	}

	setDefaultBlacklistColumnWidth() {
		this.autoFitBlacklist.forEach(fieldName => {
			this.grid.getColumnByField(fieldName).width = 'auto';
		});

		this.grid?.refreshColumns();
	}

	/**
	 * This checks for when the grid becomes visible on the screen and re runs the autoFitter
	 */
	ngAfterViewChecked() {
		if (!this.gridIsShown && !_isNil(this.grid.element.offsetParent)) {
			this.onDataBound();
		}
		this.gridIsShown = !_isNil(this.grid.element.offsetParent);
	}

	/**
	 * Host Listener: On Data Bound
	 * Listens the dataBound event on the parent grid component.
	 */
	@HostListener('dataBound')
	onDataBound() {
		if (!_isEmpty(this.grid.dataSource)) {
			this.dataBound.next();
		}
	}

	/**
	 * Host Listener: On Action Complete
	 * Runs when any action defined within the Actions interface is completed. The list used
	 * to predicate the event requestType is configured in the host component through the
	 * autoFitTriggers input property.
	 * @param event Event that has been completed.
	 */
	@HostListener('actionComplete', ['$event'])
	onActionComplete(event: GridActionEventArgs) {
		if (_includes(this.autoFitTriggers, event.requestType)) {
			this.runAutoFitSequence();
		}
	}

	/**
	 * Host Listener: Window Resize
	 * Calculates the auto width column widths based on the provided minWidth of the column.
	 * This allows the table column to switch between 'auto' and fixed widths, effectively allowing
	 * us to achieve a 'min-width' attribute on a single column within a 'table-layout: fixed' environment.
	 */
	@HostListener('window:resize')
	calculateAutoWidthColumns() {
		let refreshColumns = false;

		this.autoFitBlacklist.forEach(fieldName => {
			const index = this.grid.getColumnIndexByField(fieldName);
			const rawColumnWidth = this.getCellElementWidth(0, index);
			const minColumnWidth = this.getColumnMinWidth(fieldName);
			const columnWidthSetting = this.grid.getColumnByField(fieldName).width;
			const calculatedColumnWidth = rawColumnWidth <= minColumnWidth ? minColumnWidth : 'auto';

			if (columnWidthSetting !== calculatedColumnWidth) {
				this.grid.getColumnByField(fieldName).width = calculatedColumnWidth;
				refreshColumns = true;
			}
		});

		if (refreshColumns) {
			setTimeout(() => this.grid.refreshColumns());
		}
	}

	/**
	 * Run Auto Fit Sequence
	 * Runs the necessary methods to properly autoFit a grid using the provided configuration.
	 */
	runAutoFitSequence() {
		const fieldsToAutoFit = _difference(this.grid.getColumnFieldNames(), _concat(this.autoFitBlacklist, this.staticColumns));

		if (fieldsToAutoFit.length > 0) {
			this.setColumnsClipModeToClip(fieldsToAutoFit);
			setTimeout(() => this.grid.autoFitColumns(fieldsToAutoFit));
		}
	}

	/**
	 * Set Columns Clip Mode To Clip
	 * This sets the given set of column's clipMode to 'Clip'. For some reason this is
	 * required for the auto-fit to work properly and not show ellipses on all of the
	 * fields that were auto-fitted.
	 * @param fields Fields to auto-fit.
	 */
	private setColumnsClipModeToClip(fields: string[]) {
		fields
			.filter(fieldName => !!fieldName)
			.forEach(fieldName => {
				this.grid.getColumnByField(fieldName).clipMode = 'Clip';
			});
	}

	/* istanbul ignore next */
	getCellElementWidth(rowIndex: number, cellIndex: number): number {
		return _get((this.grid.getCellFromIndex(rowIndex, cellIndex) as HTMLElement), ['offsetWidth']);
	}

	getColumnMinWidth(fieldName: string): number {
		return _get(this.grid.getColumnByField(fieldName), 'customAttributes.autofitMinWidth', DEFAULT_AUTOWIDTH_COLUMN_MIN_WIDTH) as number;
	}
}
