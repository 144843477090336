export const HTTP_STATUS_CODE = {
	SUCCESS: {
		OK: 200,
	},
	CLIENT_ERROR: {
		BAD_REQUEST: 400,
		UNAUTHORIZED: 401,
		FORBIDDEN: 403,
		NOT_FOUND: 404,
		TEAPOT: 418,
	},
	SERVER_ERROR: {
		INTERNAL_SERVER_ERROR: 500,
	},


};
