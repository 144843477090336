// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.dataconfiguration.medication.UpdatePracticeDrugRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdatePracticeDrugRequest extends GandalfModelBase {

	@GandalfValidator({ min: { min: 0, message: 'Dispensing Info must be greater than or equal to 0' } })
	@GandalfProperty()
	dispensingInfoAmount: number;

	@GandalfProperty()
	dispensingInfoUnitId: number;

	@GandalfValidator({ notNull: { message: 'Do Not Substitute is required' } })
	@GandalfProperty({ isRequired: true })
	doNotSubstitute: boolean;

	@GandalfValidator({ min: { min: 0, message: 'Dosage Amount must be greater than or equal to 0' } })
	@GandalfProperty()
	dosageAmount: number;

	@GandalfProperty()
	dosageUnitId: number;

	@GandalfValidator({ min: { min: 0, message: 'Duration must be greater than or equal to 0' } })
	@GandalfProperty()
	durationAmount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	durationUnit: constants.DurationUnit;

	@GandalfProperty()
	frequencyId: number;

	@GandalfValidator({ sizeString: { message: 'Instructions cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	instructions: string;

	@GandalfValidator({ min: { min: 0, message: 'Number of Refills must be greater than or equal to 0' } })
	@GandalfProperty()
	numRefillsAllowed: number;

	@GandalfValidator({ notNull: { message: 'Practice Drug id is required' } })
	@GandalfProperty({ isRequired: true })
	practiceDrugId: number;

	@GandalfProperty()
	routeId: number;

}
