// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.vendorsandpartners.vendor.UpdateThirdPartyAdministratorRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateThirdPartyAdministratorRequest extends GandalfModelBase {

	@GandalfLabel('Address line 1')
	@GandalfValidator({ sizeString: { message: 'Address line 1 cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	addressLine1: string;

	@GandalfLabel('Address line 2')
	@GandalfValidator({ sizeString: { message: 'Address line 2 cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	addressLine2: string;

	@GandalfLabel('City')
	@GandalfValidator({ sizeString: { message: 'City cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	city: string;

	@GandalfLabel('Contact email')
	@GandalfValidator({ email: { message: 'Invalid Email format' } })
	@GandalfValidator({ sizeString: { message: 'Contact email cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	contactEmail: string;

	@GandalfLabel('Contact name')
	@GandalfValidator({ sizeString: { message: 'Contact name cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	contactName: string;

	@GandalfLabel('Contact phone')
	@GandalfValidator({ sizeString: { message: 'Contact phone cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfValidator({ phoneNumber: { message: 'Contact phone must contain at least 10 digits' } })
	@GandalfProperty()
	contactPhone: string;

	@GandalfLabel('Email')
	@GandalfValidator({ sizeString: { message: 'Email cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfValidator({ email: { message: 'Invalid Email format' } })
	@GandalfProperty()
	email: string;

	@GandalfLabel('Fax')
	@GandalfValidator({ sizeString: { message: 'Fax cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfValidator({ phoneNumber: { message: 'Fax must contain at least 10 digits' } })
	@GandalfProperty()
	fax: string;

	@GandalfProperty()
	id: number;

	@GandalfLabel('Main phone')
	@GandalfValidator({ sizeString: { message: 'Main phone cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfValidator({ phoneNumber: { message: 'Main phone must contain at least 10 digits' } })
	@GandalfProperty()
	mainPhone: string;

	@GandalfLabel('Third party administrator name')
	@GandalfValidator({ notNull: { message: 'Third party administrator name is required' } })
	@GandalfValidator({ sizeString: { message: 'Third party administrator name must be between 1 and 50 characters', minLength: 1, maxLength: 50 } })
	@GandalfProperty({ isRequired: true })
	name: string;

	@GandalfLabel('Zip code')
	@GandalfValidator({ sizeString: { message: 'Zip code cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty()
	nonStandardPostalCode: string;

	@GandalfLabel('NPI')
	@GandalfValidator({ sizeString: { message: 'NPI must be between 10 and 15 characters', minLength: 10, maxLength: 15 } })
	@GandalfProperty()
	npi: string;

	@GandalfLabel('Zip code')
	@GandalfValidator({ sizeString: { message: 'Zip code cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfValidator({ postalCode: { message: 'Zip code is invalid' } })
	@GandalfProperty()
	postalCode: string;

	@GandalfLabel('State province')
	@GandalfValidator({ sizeString: { message: 'State province cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	stateProvince: string;

	@GandalfLabel('Toll free')
	@GandalfValidator({ sizeString: { message: 'Toll free cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfValidator({ phoneNumber: { message: 'Toll free must contain at least 10 digits' } })
	@GandalfProperty()
	tollFree: string;

	@GandalfLabel('Web Site')
	@GandalfValidator({ sizeString: { message: 'Web Site cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	webSite: string;

}
