// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.insurance.PersonInsuranceTableResponse */
/* istanbul ignore next */
@GandalfModel
export class PersonInsuranceTableResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	coPay: number;

	@GandalfProperty()
	companyName: string;

	@GandalfProperty()
	groupNumber: string;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	policyNumber: string;

	@GandalfProperty()
	priority: ReferenceDataResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.PersonInsuranceStatus;

	@GandalfProperty()
	type: ReferenceDataResponse;

}
