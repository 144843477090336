// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { OrderProductOptionRequest } from './order-product-option-request';

/** See com.rev360.pms.api.controller.order.UpdateEyeglassLensOrderDetailRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateEyeglassLensOrderDetailRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Bifocal Size cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	bifocalSize: string;

	@GandalfProperty()
	locationProductId: number;

	@GandalfProperty()
	locationProductItemId: number;

	@GandalfSubRequestLabel('PAL')
	@GandalfProperty()
	pal: OrderProductOptionRequest;

	@GandalfValidator({ notNull: { message: 'Single Vision is required' } })
	@GandalfProperty({ isRequired: true })
	singleVision: boolean;

	@GandalfValidator({ sizeString: { message: 'Trifocal Size cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	trifocalSize: string;

}
