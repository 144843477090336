import { Component } from '@angular/core';

@Component({
	selector: 'pms-basic-print-template',
	templateUrl: './basic-print-template.component.html',
	styles: [],
})
export class BasicPrintTemplateComponent {

	constructor() {
	}

}
