import { ReceivePaymentsPayer } from '@accounting/invoices/receive-payments/receive-payments.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { PaymentComponentService } from '@core/accounting/payment/payment-component.service';
import { MODAL, ModalBase, ModalManagerService } from 'morgana';
import { _isNil } from '@core/lodash/lodash';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { BeforeCloseEventArgs } from '@syncfusion/ej2-popups/src/dialog/dialog';
import { take } from 'rxjs/operators';

export interface ReceivePaymentModalData {
	payer: ReceivePaymentsPayer;
	paymentGroupId?: number;
}

@Component({
	selector: 'pms-receive-payments-modal',
	templateUrl: './receive-payments-modal.component.html',
	providers: [ModalManagerService, PaymentComponentService],
})
export class ReceivePaymentsModalComponent extends ModalBase<ReceivePaymentModalData> implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	paymentGroupId: number;
	payer: ReceivePaymentsPayer;
	zIndexBehindHitPmsHtmlModal = MODAL.Z_INDEX_BEHIND_HIT_PMS_HTML_MODAL;

	constructor(
		public paymentComponentService: PaymentComponentService,
	) {
		super();
	}

	ngOnInit(): void {
		this.accessData();
		this.initSubscriptions();
	}

	initSubscriptions() {
		this.paymentComponentService.paymentClosed.pipe(take(1)).subscribe(() => this.dynamicModalRef.close(this.modal, null));
	}

	accessData() {
		this.paymentGroupId = this.data.paymentGroupId;
		if (_isNil(this.paymentGroupId)) {
			this.payer = this.data.payer;
		}
	}

	confirmCloseModal(event: BeforeCloseEventArgs) {
		event.cancel = true;
		this.paymentComponentService.confirmClosePayment();
	}
}
