<ejs-dialog
		#modal
		[closeOnEscape]="false"
		(close)="closeModal()">
	<ng-template #header>
		<div class="dlg-template">Payment Not Applied in RevolutionEHR</div>
	</ng-template>
	<ng-template #content>
		<div #divContent class="alert alert-danger">
			<p>
				<strong>
				A payment of {{amountApproved | currency}} was charged to the credit card but this payment could NOT be applied in RevolutionEHR.
				<br><br>
				Call Global Payments immediately to CANCEL the transaction.
				<br><br>
				</strong>
			</p>
			<ul>
				<li><strong>Transaction Date: {{transactionDate}}</strong></li>
				<li><strong>Type: {{paymentType}} ending in {{last4CardNumber}}</strong></li>
				<li><strong>Amount: {{amountApproved | currency}}</strong></li>
				<li><strong>Authorization Code: {{authorizationCode}}</strong></li>
				<li><strong>Reference Identifier: {{referenceId}}</strong></li>
			</ul>
		</div>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button type="button" iconCss="fa fa-times" (click)="closeModal()">Close</button>
		<button ejs-button type="button" class="pull-left" (click)="copyToClipboard()">Copy to Clipboard</button>
	</ng-template>
</ejs-dialog>
