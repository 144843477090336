import { ChangeDetectorRef, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { _isNil } from '@core/lodash/lodash';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { PasteCleanupSettingsModel, RichTextEditorComponent as SyncfusionRichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';

export const DRAG_DROP_TEXT = 'Text';

@Component({
	selector: 'pms-rich-text-editor',
	templateUrl: './rich-text-editor.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => RichTextEditorComponent),
			multi: true,
		},
	],
})
export class RichTextEditorComponent implements ControlValueAccessor {

	@ViewChild('richTextEditor')
	richTextEditor: SyncfusionRichTextEditorComponent;

	@Input()
	toolbar: ToolbarModule = {
		items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
			'SubScript', 'SuperScript', 'Alignments', 'UnorderedList',
			'OrderedList', 'Indent', 'Outdent', 'FontName',
			'FontSize', 'FontColor', 'CreateTable', 'Undo',
			'Redo', 'ClearFormat', 'SourceCode'],
	};

	@Input()
	height: number;

	editorValue: string;
	pasteCleanupSettings: PasteCleanupSettingsModel = {plainText: true};

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
	) {
	}

	onModelChange: (_: any) => void = () => {
	};

	onModelTouched: () => void = () => {
	};

	/* istanbul ignore next: not testable */
	getRange(event: any) {
		let range: Range;
		/* eslint-disable */
		if (this.richTextEditor.contentModule.getDocument().caretRangeFromPoint) {
			range = this.richTextEditor.contentModule.getDocument().caretRangeFromPoint(event.clientX, event.clientY);
		} else if (event.rangeParent) {
			range = this.richTextEditor.contentModule.getDocument().createRange();
			range.setStart(event.rangeParent, event.rangeOffset);
		}/* eslint-enable */

		return range;
	}

	/* istanbul ignore next: not testable */
	dropHandler = (event: any) => {
		event.preventDefault();
		if (this.richTextEditor.inputElement.contains(event.target)) {

			this.richTextEditor.selectRange(this.getRange(event));

			if (this.richTextEditor.formatter.getUndoRedoStack().length === 0) {
				this.richTextEditor.formatter.saveData();
			}

			this.richTextEditor.executeCommand('insertHTML', event.dataTransfer.getData('Text'));
			this.richTextEditor.formatter.saveData();
			this.richTextEditor.formatter.enableUndo(this.richTextEditor);
		}
	};

	/* istanbul ignore next: not testable */
	registerOnChange(fn: (_: any) => void) {
		this.onModelChange = fn;
	}

	/* istanbul ignore next: not testable */
	registerOnTouched(fn: () => void) {
		this.onModelTouched = fn;
	}

	writeValue(value: string) {
		if (_isNil(value)) {
			value = null;
		}
		this.editorValue = value;
		this.changeDetectorRef.markForCheck();
	}
}

