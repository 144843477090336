import { Injectable } from '@angular/core';
import { PatientGandalfService } from '@gandalf/services';
import { PatientSearchStringRequest } from '@gandalf/model/patient-search-string-request';
import { Observable } from 'rxjs';
import { PatientHeuristicSearchResponse } from '@gandalf/model/patient-heuristic-search-response';

@Injectable({
	providedIn: 'root',
})
export class PatientSearchService {

	constructor(
		private patientGandalfService: PatientGandalfService,
	) {
	}

	searchPatientsByString(request: PatientSearchStringRequest): Observable<PatientHeuristicSearchResponse> {
		return this.patientGandalfService.findPatientsHeuristic(request);
	}
}
