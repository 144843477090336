import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterParam } from '@app-store/selectors';
import { RouterStoreUtils } from '@app-store/utils/router-store-utils';
import { RemoveInvoicePaymentTab, SyncInvoicePaymentTab } from '@invoices-store/actions';
import { UpdateInvoicePaymentTabPayerSelectionState } from '@invoices-store/actions/invoice-tab.actions';
import { InvoicePaymentState, InvoiceTabStateMap } from '@invoices-store/reducers/invoice-tab.reducer';
import { selectInvoiceTabStateById } from '@invoices-store/selectors/invoice.selector';
import { Store } from '@ngrx/store';
import { DialogUtil } from 'morgana';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
	selector: 'pms-invoice-payment-tab',
	templateUrl: './invoice-payment-tab.component.html',
	styles: [],
})
export class InvoicePaymentTabComponent implements OnInit, OnDestroy {
	private unsubscribe$ = new Subject<void>();
	paymentId: number;
	state: InvoicePaymentState;
	removed = false;

	constructor(
		private routerStoreUtils: RouterStoreUtils,
		public store: Store<InvoiceTabStateMap>,
	) {
	}

	ngOnInit() {
		this.setupPaymentTab();
	}

	setupPaymentTab() {
		this.paymentId = this.routerStoreUtils.getRouterParam(RouterParam.INVOICES_INVOICE_PAYMENT_ID);
		this.store.dispatch(new SyncInvoicePaymentTab({paymentId: this.paymentId}));

		// Get state information and setup the tab
		this.store.select(selectInvoiceTabStateById({id: this.paymentId})).pipe(take(1)).subscribe((state: InvoicePaymentState) => {
			this.state = state;
		});
	}

	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
		if (!this.removed) { // when this is removed by its own call, make sure not to save its state again
			this.saveState();
		}
	}

	saveState() {
		this.store.dispatch(new UpdateInvoicePaymentTabPayerSelectionState({
			paymentId: this.paymentId,
			payerType: this.state.payerType,
			manuallySelect: this.state.manuallySelect,
			nameFilter: this.state.nameFilter,
			payer: this.state.selectedPayer,
			receivePaymentState: this.state.receivePaymentState,
		}));
	}

	removeTab(confirmed) {
		if (!confirmed) {
			this.showCloseConfirmation();
		} else {
			this.removed = true;
			this.store.dispatch(new RemoveInvoicePaymentTab({paymentId: this.paymentId}));
		}
	}

	showCloseConfirmation() {
		const dialog = DialogUtil.confirm(
			{
				title: 'Close Confirmation',
				content: 'Are you sure you wish to close and lose any unsaved changes?',
				okButton: {
					click: () => {
						this.removeTab(true);
						dialog.close();
					},
				},
			},
		);
	}
}
