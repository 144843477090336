import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';

/**
 * Make an element only display in Canada.
 * Usage:
 * <div *pmsCanadaOnly class="not-a-real-class"></div>
 */
@Directive({
	selector: '[pmsCanadaOnly]',
})
export class CanadaOnlyDirective implements OnInit {

	constructor(
		private securityManagerService: SecurityManagerService,
		private templateRef: TemplateRef<any>,
		private viewContainerRef: ViewContainerRef,
	) {
	}

	ngOnInit() {
		this.updateView();
	}

	updateView() {
		if (this.securityManagerService.isCanada()) {
			this.viewContainerRef.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainerRef.clear();
		}
	}
}
