import { Injectable } from '@angular/core';
import { TooltipEventArgs } from '@syncfusion/ej2-angular-popups';
import tippy, { Content, Instance, Props } from 'tippy.js';
import { _isNil } from '../../utils/lodash/lodash';

export interface Tooltip {
	instance: Instance;
	setContent: (content: Content) => void;
}

export const DEFAULT_DELAY_FOR_SERVER_CALLS = 300;

@Injectable({
	providedIn: 'root',
})
export class TooltipService {

	constructor() {
	}

	setGlobalDefaults() {
		tippy.setDefaultProps({
			duration: 0,
			placement: 'bottom',
			arrow: true,
			trigger: 'mouseenter',
		});
	}

	/* istanbul ignore next */
	buildTooltip(
		content: string | HTMLElement,
		element: HTMLElement,
		extendedBeforeRender?: (event: TooltipEventArgs) => void,
		tooltipOptions: Partial<Props> = {},
		allowHtml: boolean = false,
	) {
		const tippyContent = content instanceof HTMLElement ? content.innerHTML : content;

		let tooltipInstance = tippy(element, {
			content: tippyContent,
			allowHTML: allowHtml || content instanceof HTMLElement,
			theme: 'light-border',
			...tooltipOptions,
			onShow: ($event: any) => {
				if (!_isNil(extendedBeforeRender)) {
					const tooltipEventArgs: TooltipEventArgs = {
						event: $event,
						target: $event.reference,
						cancel: false,
						element: $event.reference,
						type: 'tooltip',
					};

					extendedBeforeRender(tooltipEventArgs);
				}
			},
		});

		// tippy will return null if a tooltip already exists on an element
		if (_isNil(tooltipInstance)) {
			tooltipInstance = element['_tippy'];
		}

		return {
			instance: tooltipInstance,
			setContent: tooltipInstance.setContent,
		};
	}
}
