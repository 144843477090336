// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.externaldata.ExternalDataListResponse */
/* istanbul ignore next */
@GandalfModel
export class ExternalDataListResponse extends GandalfModelBase {

	@GandalfProperty()
	externalDataId: number;

	@GandalfProperty()
	name: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	requestDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.ExternalDataStatus;

}
