/* eslint-disable @typescript-eslint/unbound-method */
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { _cloneDeep, _isNaN, _isNil } from '@core/lodash/lodash';
import { LogMethodTime } from '@core/performance/log-method-time.decorator';
import { NumericTextBoxComponent } from '@syncfusion/ej2-angular-inputs';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

export interface INumericCellEditorParams extends ICellEditorParams {
	getIsEditable: (rowNode: any, colId?: string) => boolean;
	formControlGetter: (data: any, colId?: string) => any;
	format: string;
	min: number;
}

@Component({
	selector: 'pms-numeric-cell-editor',
	templateUrl: './numeric-cell-editor.component.html',
	styles: [
	],
})
export class NumericCellEditorComponent implements ICellEditorAngularComp, AfterViewInit {

	@ViewChild('numericInput')
	numericInput: NumericTextBoxComponent;

	params: INumericCellEditorParams;
	data: any;
	value = 0;
	highlightAllOnFocus = true;

	constructor(
	) { }

	@LogMethodTime('numericCellEditor-agInit')
	agInit(params: ICellEditorParams): void {
		this.params = params as INumericCellEditorParams;
		this.data = _cloneDeep(params.data);
		this.setInitialState();
	}

	setInitialState() {
		const highlightAllOnFocus = true;

		this.value = 0;
		this.highlightAllOnFocus = highlightAllOnFocus;
	}

	isCancelBeforeStart() {
		return !this.isEditable();
	}

	isEditable() {
		if (this.data?.isAggregateRow) {
			return false;
		}

		return this.params.getIsEditable(this.params.node, this.params.column.getColId());
	}

	getValue(): any {
		return this.value;
	}

	isCancelAfterEnd() {
		this.setFormValue();
		return false;
	}

	getFormControl() {
		return this.params.formControlGetter(this.params.node, this.params.column.getColId());
	}

	ngAfterViewInit() {
		setTimeout(() => {
			if (this.highlightAllOnFocus && !_isNil(this.numericInput?.focusIn)) {
				this.numericInput.focusIn();
				this.highlightAllOnFocus = false;
			}
		});
	}

	setFormValue() {
		let valueNumber = parseFloat(this.numericInput.element.value);
		if (_isNaN(valueNumber)) {
			valueNumber = 0;
		}

		if (!_isNil(this.params.min) && valueNumber < this.params.min) {
			this.getFormControl().setValue(this.params.min);
		} else {
			this.getFormControl().setValue(valueNumber);
		}
	}
}
