import { ToasterService } from '@core/toaster/toaster.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { InjectorContainerModule } from '../../injector-container.module';

export function ShowSavedSuccessToast() {
	return function(target: any, propertyKey: string, descriptor: TypedPropertyDescriptor<(...args: any[]) => Observable<any>>) {
		const originalMethod = descriptor.value;

		descriptor.value = function(...args: any[]) {
			const toasterService = InjectorContainerModule.injector.get(ToasterService);
			return originalMethod.apply(this, args).pipe(
				tap(() => toasterService.showSavedSuccess()),
			);
		};
	};
}
