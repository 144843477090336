// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AppointmentCalendarResponse } from './appointment-calendar-response';

// @ts-ignore
import { AppointmentSidebarResponse } from './appointment-sidebar-response';

/** See com.rev360.pms.api.controller.schedule.scheduleitem.ValidatedCreateAppointmentResponse */
/* istanbul ignore next */
@GandalfModel
export class ValidatedCreateAppointmentResponse extends GandalfModelBase {

	@GandalfArray(AppointmentSidebarResponse)
	appointmentSidebarData: AppointmentSidebarResponse[];

	@GandalfArray(AppointmentCalendarResponse)
	appointments: AppointmentCalendarResponse[];

	@GandalfArray(String)
	messages: string[];

	@GandalfProperty()
	waitingListEntriesDeactivated: boolean;

}
