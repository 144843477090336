export const APP_ROUTING_CONSTANTS = {
	LEGACY: 'LegacyModule',
	ACCOUNTING: {
		INVOICES: 'AccountingModule-Invoices',
		CLAIMS: 'AccountingModule-Claims',
		PAYMENTS: 'AccountingModule-Payments',
	},
	ADMIN: 'AdminModule',
	CONNECT: {
		DASHBOARD: 'Connect-Dashboard',
		CAMPAIGN: 'Connect-Campaign',
		TWO_WAY_TEXT: 'Connect-Two-Way-Text',
	},
	HOME: 'HomeModule',
	INVENTORY: {
		PRODUCTS: 'InventoryModule-Products',
		SERVICES: 'InventoryModule-Services',
		TRANSFERS: 'InventoryModule-Transfers',
		RECONCILIATION: 'InventoryModule-Reconciliation',
		VSP: 'InventoryModule-VSP',
	},
	REPORTING: 'MarketingModule',
	MESSAGING: 'MessagingModule',
	ORDERS: 'OrdersModule-Enhanced',
	LEGACY_ORDERS: 'OrdersModule-Legacy',
	PATIENTS: 'PatientsModule',
	SCHEDULE: 'SchedulingModule',
	TASKS: 'TasksModule',
	DEV: {
		EMR: 'DevEmrModule',
	},
	REV_ENGAGE: 'RevEngageComponent',
};

export const APP_ROUTING_URL_CONSTANTS = {
	LEGACY: {
		key: APP_ROUTING_CONSTANTS.LEGACY,
		url: 'legacy',
	},
	ACCOUNTING_INVOICES: {
		key: APP_ROUTING_CONSTANTS.ACCOUNTING.INVOICES,
		url: 'accounting/invoices',
	},
	ACCOUNTING_CLAIMS: {
		key: APP_ROUTING_CONSTANTS.ACCOUNTING.CLAIMS,
		url: 'accounting/claims',
	},
	ACCOUNTING_PAYMENTS: {
		key: APP_ROUTING_CONSTANTS.ACCOUNTING.PAYMENTS,
		url: 'accounting/payments',
	},
	ADMIN: {
		key: APP_ROUTING_CONSTANTS.ADMIN,
		url: 'admin',
	},
	CONNECT_DASHBOARD: {
		key: APP_ROUTING_CONSTANTS.CONNECT.DASHBOARD,
		url: 'connect/connectDashboard',
	},
	CONNECT_CAMPAIGN: {
		key: APP_ROUTING_CONSTANTS.CONNECT.CAMPAIGN,
		url: 'connect/connectCampaign',
	},
	CONNECT_TWO_WAY_TEXT: {
		key: APP_ROUTING_CONSTANTS.CONNECT.TWO_WAY_TEXT,
		url: 'connect/connectTwoWayText',
	},
	HOME: {
		key: APP_ROUTING_CONSTANTS.HOME,
		url: 'home',
	},
	INVENTORY_PRODUCTS: {
		key: APP_ROUTING_CONSTANTS.INVENTORY.PRODUCTS,
		url: 'inventory/products',
	},
	INVENTORY_SERVICES: {
		key: APP_ROUTING_CONSTANTS.INVENTORY.SERVICES,
		url: 'inventory/services',
	},
	INVENTORY_TRANSFERS: {
		key: APP_ROUTING_CONSTANTS.INVENTORY.TRANSFERS,
		url: 'inventory/transfers',
	},
	INVENTORY_RECONCILIATION: {
		key: APP_ROUTING_CONSTANTS.INVENTORY.RECONCILIATION,
		url: 'inventory/reconciliation',
	},
	INVENTORY_VSP: {
		key: APP_ROUTING_CONSTANTS.INVENTORY.VSP,
		url: 'inventory/vsp',
	},
	REPORTING: {
		key: APP_ROUTING_CONSTANTS.REPORTING,
		url: 'marketing',
	},
	MESSAGING: {
		key: APP_ROUTING_CONSTANTS.MESSAGING,
		url: 'messaging',
	},
	ORDERS: {
		key: APP_ROUTING_CONSTANTS.ORDERS,
		url: 'orders/ordersEnhanced',
	},
	LEGACY_ORDERS: {
		key: APP_ROUTING_CONSTANTS.LEGACY_ORDERS,
		url: 'orders/ordersLegacy',
	},
	PATIENTS: {
		key: APP_ROUTING_CONSTANTS.PATIENTS,
		url: 'patients',
	},
	SCHEDULE: {
		key: APP_ROUTING_CONSTANTS.SCHEDULE,
		url: 'schedule/schedule',
	},
	TASKS: {
		key: APP_ROUTING_CONSTANTS.TASKS,
		url: 'tasks',
	},
	DEV_EMR: {
		key: APP_ROUTING_CONSTANTS.DEV.EMR,
		url: 'emr',
	},
};

export const APP_ROUTING_URL_MAP = [
	APP_ROUTING_URL_CONSTANTS.LEGACY,
	APP_ROUTING_URL_CONSTANTS.ACCOUNTING_CLAIMS,
	APP_ROUTING_URL_CONSTANTS.ACCOUNTING_INVOICES,
	APP_ROUTING_URL_CONSTANTS.ACCOUNTING_PAYMENTS,
	APP_ROUTING_URL_CONSTANTS.ADMIN,
	APP_ROUTING_URL_CONSTANTS.CONNECT_DASHBOARD,
	APP_ROUTING_URL_CONSTANTS.CONNECT_CAMPAIGN,
	APP_ROUTING_URL_CONSTANTS.HOME,
	APP_ROUTING_URL_CONSTANTS.INVENTORY_PRODUCTS,
	APP_ROUTING_URL_CONSTANTS.INVENTORY_RECONCILIATION,
	APP_ROUTING_URL_CONSTANTS.INVENTORY_SERVICES,
	APP_ROUTING_URL_CONSTANTS.INVENTORY_TRANSFERS,
	APP_ROUTING_URL_CONSTANTS.INVENTORY_VSP,
	APP_ROUTING_URL_CONSTANTS.REPORTING,
	APP_ROUTING_URL_CONSTANTS.MESSAGING,
	APP_ROUTING_URL_CONSTANTS.ORDERS,
	APP_ROUTING_URL_CONSTANTS.LEGACY_ORDERS,
	APP_ROUTING_URL_CONSTANTS.PATIENTS,
	APP_ROUTING_URL_CONSTANTS.SCHEDULE,
	APP_ROUTING_URL_CONSTANTS.TASKS,
	APP_ROUTING_URL_CONSTANTS.DEV_EMR,
];
