// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressOptionalRequest } from './address-optional-request';

// @ts-ignore
import { ContactDemographicsRequest } from './contact-demographics-request';

// @ts-ignore
import { ContactInformationRequest } from './contact-information-request';

/** See com.rev360.pms.api.controller.patient.contact.CreateContactRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateContactRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Address is required' } })
	@GandalfProperty({ isRequired: true })
	address: AddressOptionalRequest;

	@GandalfValidator({ notNull: { message: 'Contact Demographics is required' } })
	@GandalfProperty({ isRequired: true })
	contactDemographics: ContactDemographicsRequest;

	@GandalfLabel('Contact Information')
	@GandalfProperty()
	contactInformation: ContactInformationRequest;

	@GandalfLabel('Emergency Contact')
	@GandalfProperty()
	isEmergencyContact: boolean;

	@GandalfLabel('Primary Contact')
	@GandalfProperty()
	isPrimaryEmergencyContact: boolean;

	@GandalfValidator({ notNull: { message: 'Patient Id is required' } })
	@GandalfProperty({ isRequired: true })
	patientId: number;

	@GandalfValidator({ notNull: { message: 'Role is required' } })
	@GandalfProperty({ isRequired: true })
	roleId: number;

}
