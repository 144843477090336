// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { EncounterTableResponse } from './encounter-table-response';

// @ts-ignore
import { EyefinityOrderItemResponse } from './eyefinity-order-item-response';

// @ts-ignore
import { EyefinityOrderResponse } from './eyefinity-order-response';

// @ts-ignore
import { EyeglassLensMeasurementResponse } from './eyeglass-lens-measurement-response';

// @ts-ignore
import { EyeglassLensOrderDetailResponse } from './eyeglass-lens-order-detail-response';

// @ts-ignore
import { FrameColorResponse } from './frame-color-response';

// @ts-ignore
import { FrameSizeResponse } from './frame-size-response';

// @ts-ignore
import { LocationProductForOrderResponse } from './location-product-for-order-response';

// @ts-ignore
import { LocationProductItemForOrderResponse } from './location-product-item-for-order-response';

// @ts-ignore
import { OrderEyefinityFeeScheduleResponse } from './order-eyefinity-fee-schedule-response';

// @ts-ignore
import { OrderEyeglassPrescriptionResponse } from './order-eyeglass-prescription-response';

// @ts-ignore
import { OrderProcessorConfigResponse } from './order-processor-config-response';

// @ts-ignore
import { OrderVendorResponse } from './order-vendor-response';

// @ts-ignore
import { PatientCommunicationResponse } from './patient-communication-response';

// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.order.eyefinity.EyefinityEyeglassOrderResponse */
/* istanbul ignore next */
@GandalfModel
export class EyefinityEyeglassOrderResponse extends EyefinityOrderResponse {

	@GandalfProperty()
	arCoating: LocationProductForOrderResponse;

	@GandalfProperty()
	associatedAuthorization: string;

	@GandalfProperty()
	benefitContactLens: boolean;

	@GandalfProperty()
	benefitExam: boolean;

	@GandalfProperty()
	benefitFrame: boolean;

	@GandalfProperty()
	benefitLens: boolean;

	@GandalfProperty()
	bevelExternalIdentifier: string;

	@GandalfProperty()
	comment: string;

	@GandalfProperty({ propertyType: 'Money' })
	copayExam: number;

	@GandalfProperty({ propertyType: 'Money' })
	copayMaterials: number;

	@GandalfProperty()
	createdOn: Date;

	@GandalfProperty()
	distancePd: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dueDate: Date;

	@GandalfProperty()
	edge: LocationProductForOrderResponse;

	@GandalfProperty()
	encounter: EncounterTableResponse;

	@GandalfProperty({ propertyType: 'Money' })
	estimatedDiscounts: number;

	@GandalfProperty({ propertyType: 'Money' })
	estimatedInsuranceBenefit: number;

	@GandalfProperty({ propertyType: 'Money' })
	estimatedTaxes: number;

	@GandalfProperty()
	externalFrameSupplierIdentifier: string;

	@GandalfProperty()
	externalFrameSupplierName: string;

	@GandalfProperty()
	externalSupplierIdentifier: string;

	@GandalfProperty()
	externalSupplierName: string;

	@GandalfProperty()
	eyefinityFeeSchedule: OrderEyefinityFeeScheduleResponse;

	@GandalfProperty()
	eyefinityOrderDetailId: number;

	@GandalfArray(EyefinityOrderItemResponse)
	eyefinityOrderItems: EyefinityOrderItemResponse[];

	@GandalfProperty({ propertyType: 'Money' })
	frameAllowance: number;

	@GandalfProperty()
	frameColor: FrameColorResponse;

	@GandalfProperty({ propertyType: 'Money' })
	frameCost: number;

	@GandalfProperty({ propertyType: 'Money' })
	frameRemakeCost: number;

	@GandalfProperty()
	frameSize: FrameSizeResponse;

	@GandalfProperty({ propertyType: 'Money' })
	frameWholesaleAllowance: number;

	@GandalfProperty()
	glassCoating: LocationProductForOrderResponse;

	@GandalfProperty()
	instructions: string;

	@GandalfProperty()
	isValid: boolean;

	@GandalfProperty()
	legacyAuthorizationIdentifier: string;

	@GandalfProperty({ propertyType: 'Money' })
	lensCost: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	lensEye: constants.EyefinityLensLocation;

	@GandalfProperty()
	lensMaterialExternalIdentifier: string;

	@GandalfProperty({ propertyType: 'Money' })
	lensRemakeCost: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	lensRequest: constants.OrderLensRequest;

	@GandalfProperty()
	lensStyle: LocationProductForOrderResponse;

	@GandalfProperty()
	lensTypeExternalIdentifier: string;

	@GandalfProperty()
	lightDarkPercentage: number;

	@GandalfProperty()
	locationId: number;

	@GandalfProperty()
	locationName: string;

	@GandalfProperty()
	mirrorCoating: LocationProductForOrderResponse;

	@GandalfProperty()
	nearPd: number;

	@GandalfProperty()
	nosePads: boolean;

	@GandalfProperty()
	nosePadsText: string;

	@GandalfProperty()
	notificationComments: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	notificationDate: Date;

	@GandalfProperty()
	odDetail: EyeglassLensOrderDetailResponse;

	@GandalfProperty()
	odLensMeasurements: EyeglassLensMeasurementResponse;

	@GandalfProperty()
	onHold: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	opticalCenterType: constants.OrderOpticalCenterType;

	@GandalfConstantDecorator()
	@GandalfProperty()
	orderFrameIndication: constants.OrderFrameIndication;

	@GandalfProperty()
	orderFrameProduct: LocationProductForOrderResponse;

	@GandalfProperty()
	orderFrameProductItem: LocationProductItemForOrderResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	orderFrameSource: constants.OrderFrameSource;

	@GandalfProperty()
	orderId: number;

	@GandalfProperty()
	originalOrderId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	originalOrderReason: constants.OriginalOrderReason;

	@GandalfProperty()
	osDetail: EyeglassLensOrderDetailResponse;

	@GandalfProperty()
	osLensMeasurements: EyeglassLensMeasurementResponse;

	@GandalfProperty({ propertyType: 'Money' })
	otherCost: number;

	@GandalfProperty({ propertyType: 'Money' })
	otherRemakeCost: number;

	@GandalfProperty()
	pantoscopicTilt: number;

	@GandalfProperty()
	patient: PatientNameResponse;

	@GandalfProperty()
	patientCommunication: PatientCommunicationResponse;

	@GandalfProperty()
	patientNotified: boolean;

	@GandalfProperty()
	personId: number;

	@GandalfProperty()
	prescription: OrderEyeglassPrescriptionResponse;

	@GandalfProperty()
	pressOn: boolean;

	@GandalfProperty()
	pressOnText: string;

	@GandalfProperty()
	processor: OrderProcessorConfigResponse;

	@GandalfProperty()
	quantity: number;

	@GandalfProperty()
	sampleExternalIdentifier: string;

	@GandalfProperty()
	scratchCoating: LocationProductForOrderResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	segHeightType: constants.OrderSegHeightType;

	@GandalfProperty()
	shipAddress: AddressResponse;

	@GandalfProperty({ propertyType: 'Money' })
	shipCost: number;

	@GandalfProperty({ propertyType: 'Money' })
	shipRemakeCost: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	shipToType: constants.OrderShipToType;

	@GandalfConstantDecorator()
	@GandalfProperty()
	shippingCompany: constants.OrderShipmentCompany;

	@GandalfProperty()
	slabOff: boolean;

	@GandalfProperty()
	slabOffText: string;

	@GandalfProperty()
	smartflowOrderIdentifier: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	statusCode: constants.OrderStatusCode;

	@GandalfProperty()
	statusDate: Date;

	@GandalfProperty()
	thicknessExternalIdentifier: string;

	@GandalfProperty()
	tintColorExternalIdentifier: string;

	@GandalfProperty()
	tintColorOther: string;

	@GandalfProperty()
	tintType: LocationProductForOrderResponse;

	@GandalfProperty()
	trackingNumber: string;

	@GandalfProperty()
	tray: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	type: constants.OrderType;

	@GandalfProperty()
	uvCoating: LocationProductForOrderResponse;

	@GandalfProperty()
	validationMessage: string;

	@GandalfProperty()
	vendor: OrderVendorResponse;

	@GandalfProperty()
	vendorOrderIdentifier: string;

	@GandalfProperty()
	version: number;

	@GandalfProperty()
	wrapAngle: number;

}
