// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { EyeglassPrescriptionResponse } from './eyeglass-prescription-response';

// @ts-ignore
import { OpticalOrderDetailResponse } from './optical-order-detail-response';

/** See com.rev360.pms.api.controller.patient.opticalsummary.OpticalOrderEyeglassDetailResponse */
/* istanbul ignore next */
@GandalfModel
export class OpticalOrderEyeglassDetailResponse extends OpticalOrderDetailResponse {

	@GandalfProperty()
	arCoating: string;

	@GandalfProperty()
	arCoatingSide: string;

	@GandalfProperty()
	distancePd: number;

	@GandalfProperty()
	edgeTreatment: string;

	@GandalfProperty()
	frameBridge: number;

	@GandalfProperty()
	frameColor: string;

	@GandalfProperty()
	frameEye: number;

	@GandalfProperty()
	frameManufacturer: string;

	@GandalfProperty()
	frameProductName: string;

	@GandalfProperty()
	frameTemple: number;

	@GandalfProperty()
	material: string;

	@GandalfProperty()
	mounting: string;

	@GandalfProperty()
	nearPd: number;

	@GandalfProperty()
	odBaseCurve: number;

	@GandalfProperty()
	odBifocalSize: string;

	@GandalfProperty()
	odBlankSize: number;

	@GandalfProperty()
	odLensType: string;

	@GandalfProperty()
	odMonocularPdDistance: number;

	@GandalfProperty()
	odMonocularPdNear: number;

	@GandalfProperty()
	odOpticalCenter: number;

	@GandalfProperty()
	odPal: string;

	@GandalfProperty()
	odProductCode: string;

	@GandalfProperty()
	odSegHeight: number;

	@GandalfProperty()
	odTrifocalSize: string;

	@GandalfProperty()
	odVertexDistance: number;

	@GandalfProperty()
	orderDetailId: number;

	@GandalfProperty()
	osBaseCurve: number;

	@GandalfProperty()
	osBifocalSize: string;

	@GandalfProperty()
	osBlankSize: number;

	@GandalfProperty()
	osLensType: string;

	@GandalfProperty()
	osMonocularPdDistance: number;

	@GandalfProperty()
	osMonocularPdNear: number;

	@GandalfProperty()
	osOpticalCenter: number;

	@GandalfProperty()
	osPal: string;

	@GandalfProperty()
	osProductCode: string;

	@GandalfProperty()
	osSegHeight: number;

	@GandalfProperty()
	osTrifocalSize: string;

	@GandalfProperty()
	osVertexDistance: number;

	@GandalfProperty()
	pantoscopicTilt: number;

	@GandalfProperty()
	photochromic: string;

	@GandalfProperty()
	polarized: string;

	@GandalfProperty()
	prescription: EyeglassPrescriptionResponse;

	@GandalfProperty()
	quantity: number;

	@GandalfProperty()
	scratchCoat: string;

	@GandalfProperty()
	shade: string;

	@GandalfProperty()
	specialInstructions: string;

	@GandalfProperty()
	specialOption: string;

	@GandalfProperty()
	thickness: string;

	@GandalfProperty()
	tintFactor: string;

	@GandalfProperty()
	tintType: string;

	@GandalfProperty()
	uvTreatment: string;

	@GandalfProperty()
	wrapAngle: number;

}
