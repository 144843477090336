import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { _isEmpty } from '../../utils/lodash/lodash';

@Component({
	selector: 'rev-url-editor',
	templateUrl: './url-editor.component.html',
	styles: [],
})
export class UrlEditorComponent implements ControlValueAccessor, OnInit {

	@Input()
	prefixDataTestId: string;

	@ViewChild('urlInput')
	urlInput: ElementRef;

	urlText = '';
	showUrlHyperlink = false;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		@Optional() @Self() public ngControl: NgControl,
	) {
		this.ngControl.valueAccessor = this;
	}

	onModelChange: (_: any) => void = (_: any) => {
	};

	onModelTouched: () => void = () => {
	};

	writeValue(value: any): void {
		this.urlText = value;
		this.changeDetectorRef.markForCheck();
	}

	registerOnChange(fn: (_: any) => void): void {
		this.onModelChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onModelTouched = fn;
	}

	ngOnInit() {
		this.showUrlHyperlink = !_isEmpty(this.urlText);
	}

	enableUrlEdit() {
		this.showUrlHyperlink = false;
		setTimeout(() => this.urlInput.nativeElement.focus());
	}

	onInputValueChange() {
		if (!this.showUrlHyperlink) {
			this.onModelChange(this.urlText);
		}
	}

	getDataTestId(staticVal: string) {
		return `${this.prefixDataTestId}${staticVal}`;
	}
}
