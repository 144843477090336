// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.schedule.UpdateCustomFilterPreferencesRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateCustomFilterPreferencesRequest extends GandalfModelBase {

	@GandalfConstantDecorator()
	@GandalfArray(constants.ScheduleItemType)
	itemTypes: constants.ScheduleItemType[];

	@GandalfArray(Number)
	personIds: number[];

	@GandalfArray(Number)
	roleIds: number[];

	@GandalfArray(Number)
	sortPersonIds: number[];

	@GandalfConstantDecorator()
	@GandalfArray(constants.CustomFilterScheduleItemStatus)
	statuses: constants.CustomFilterScheduleItemStatus[];

}
