import { Pipe, PipeTransform } from '@angular/core';
import { CodeSet, DiagnosisLocation } from '@gandalf/constants';
import { PersonDiagnosisResponse } from '@gandalf/model/person-diagnosis-response';

@Pipe({
	name: 'diagnosis',
})
export class DiagnosisLocationPipe implements PipeTransform {

	transform(personDiagnosis: PersonDiagnosisResponse): DiagnosisLocation {
		if (personDiagnosis.practiceDiagnosis.codeSet === CodeSet.ICD10) {
			const description = personDiagnosis.practiceDiagnosis.longDescription.toLowerCase();
			if (description.match(/\bright\b/)) {
				return DiagnosisLocation.RIGHT_EYE;
			} else if (description.match(/\bleft\b/)) {
				return DiagnosisLocation.LEFT_EYE;
			} else if (description.includes('bilateral')) {
				return DiagnosisLocation.BOTH_EYES;
			}
			return DiagnosisLocation.UNKNOWN_EYE;
		}

		return personDiagnosis.diagnosisLocation ? personDiagnosis.diagnosisLocation : DiagnosisLocation.UNKNOWN_EYE;
	}

}
