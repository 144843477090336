import { Injectable, Pipe, PipeTransform } from '@angular/core';

/**
 * @ngdoc pipe
 * @description
 * Angular pipe showing and masking US social security numbers (ssn)
 *
 * @param value - value to be filtered
 * @param mask - optional boolean value to xxx text or not xxx out text
 * @usage
 * {{ value | ssn : mask}}
 */
@Injectable()
@Pipe({
	name: 'ssn',
})
export class SsnPipe implements PipeTransform {

	transform(value: string, mask?: boolean): string {
		let len: number;
		let val: string;
		if (mask === null) {
			mask = false;
		}
		if (value) {
			val = value.toString().replace(/\D/g, '');
			len = val.length;

			if (len < 4) {
				return val;
			} else if ((3 < len && len < 6)) {
				if (mask) {
					return 'xxx-' + (val.substring(3));
				} else {
					return val.substring(0, 3) + '-' + val.substring(3);
				}
			} else if (len > 5) {
				if (mask) {
					return 'xxx-xx-' + val.substring(5, 9);
				} else {
					return val.substring(0, 3) + '-' + val.substring(3, 5) + '-' + val.substring(5, 9);
				}
			}
		}
		return value;
	}

}
