import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

/**
 * @ngdoc pipe
 * @description
 * Angular pipe for displaying relative difference between a date and now
 *
 * @param date - a start date
 * @usage
 * {{ date | relativeDateDisplay}}
 */

@Pipe({
	name: 'relativeDateDisplay',
})
export class RelativeDateDisplayPipe implements PipeTransform {

	transform(date: string | Date | dayjs.Dayjs): string {
		const today = dayjs().startOf('day');
		const comparisonDate = dayjs(date).startOf('day');

		if (date) {
			if (today.diff(comparisonDate, 'year') > 1) {
				return dayjs(date).format('MM/DD/YYYY');
			} else if (today.diff(comparisonDate, 'day') > 0) {
				return dayjs(date).format('MMM D');
			} else {
				return dayjs(date).format('h:mm a');
			}
		}
	}

}
