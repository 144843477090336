import { Directive } from '@angular/core';
import { StatefulGridService } from '@app-store/services/stateful-grid.service';
import { StatefulBaseComponent } from '@app-store/stateful-base.component';
import { AgGridAngular } from 'ag-grid-angular';

type Constructor<T> = new (...args: any[]) => T;

export const statefulGridBaseKey = Symbol('StatefulGrid');

export interface StatefulGridBase extends StatefulBaseComponent {
	agGrid: AgGridAngular;
	statefulGridService: StatefulGridService;
}

export const StatefulGrid = <T extends Constructor<StatefulBaseComponent>>(base: T) => {

	@Directive()
	abstract class StatefulGridMixin extends base implements StatefulGridBase {
		abstract agGrid: AgGridAngular;
		abstract statefulGridService: StatefulGridService;
	}

	// Mark prototype as StatefulGrid for the StatefulComponent Decorator to consume.
	Reflect.defineMetadata(statefulGridBaseKey, true, StatefulGridMixin);
	return StatefulGridMixin;
};
