import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { GANDALF_HTTP_OPTIONS } from './gandalf.constants';
import { ModelManipulationService } from './model-manipulation.service';

// Number of milliseconds required between requests to not be considered a "duplicate"
export const DUPLICATE_REQUEST_TIMEOUT_MS = 250;

@Injectable({
	providedIn: 'root',
})
export class GandalfHttpUtilsService {

	private requestCache = new Map<string, Date>();
	private detectDuplicateRequests = isDevMode();

	constructor(private httpClient: HttpClient, private modelManipulationService: ModelManipulationService) {
	}

	/**
	 * gandalfRequest is called from the generated Gandalf service code.
	 * When the call returns, it parses the returned data into the proper objects/models.
	 */
	gandalfRequest(url: string, responseType, requestType?, requestBody?) {
		let cleanRequestBody;
		if (requestType) {
			if (Array.isArray(requestBody)) {
				cleanRequestBody = this.modelManipulationService.cleanGandalfList(requestType, requestBody);
			} else {
				cleanRequestBody = this.modelManipulationService.cleanGandalfModel(requestType, requestBody);
			}
		} else {
			cleanRequestBody = requestBody;
		}

		return this.httpClient.post(url, cleanRequestBody, GANDALF_HTTP_OPTIONS).pipe(
			tap(() => this.checkForDuplicateRequests(url, cleanRequestBody)),
			map((data) => {
				// Check and see if this is an array of objects being returned.
				if (Array.isArray(data)) {
					return this.modelManipulationService.parseGandalfList(responseType, data);
				} else {
					// change the response body here
					return this.modelManipulationService.parseGandalfModel(responseType, data);
				}
			}),
		);
	}

	checkForDuplicateRequests(url: string, requestBody?: any) {
		if (!this.detectDuplicateRequests) {
			return;
		}

		const key = url + '|' + JSON.stringify(requestBody);
		const now = new Date();
		this.requestCache.forEach((createdOn, k) => {
			if (now.getTime() - createdOn.getTime() > DUPLICATE_REQUEST_TIMEOUT_MS) {
				this.requestCache.delete(k);
			}
		});
		if (this.requestCache.get(key)) {
			this.notifyDuplicateRequest(url, requestBody);
		}
		this.requestCache.set(key, now);
	}

	notifyDuplicateRequest(url: string, requestBody?: any) {
		console.error('Gandalf detected a duplicate request!', url, requestBody);
	}

}
