<ejs-dialog
    #modal
	(close)="closeModal(false)"
	cssClass="modal-sm"
	[attr.data-test-id]="'commentModal'">
	<ng-template #header>
		<div class="dlg-template">{{title}}</div>
	</ng-template>
	<ng-template #content>
		<!--
		In the Flex, this is limited to 255, but because the service appends text to the end,
		actually inputting that many characters will cause an SQLException.
		To avoid this in the HTML version, the limit has been lowered to 235.
		-->
		<p>{{commentLabel}}</p>
		<ejs-textbox type="text" class="e-input h-rows-5"
					 [multiline]="true" [(ngModel)]="comments" [maxlength]="maxLength">
		</ejs-textbox>
		<p>{{ comments.length }} of {{maxLength}} characters</p>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button (click)="closeModal(false)" [attr.data-test-id]="'commentModalCancelButton'">Cancel</button>
		<button ejs-button [isPrimary]="true" (click)="closeModal(true)" [attr.data-test-id]="'commentModalApplyButton'">Apply</button>
	</ng-template>
</ejs-dialog>
