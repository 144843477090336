// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AssignFeeScheduleItemRequest } from './assign-fee-schedule-item-request';

/** See com.rev360.pms.api.controller.admin.accounting.feeschedule.AssignFeeScheduleItemListRequest */
/* istanbul ignore next */
@GandalfModel
export class AssignFeeScheduleItemListRequest extends GandalfModelBase {

	@GandalfArray(AssignFeeScheduleItemRequest)
	assignFeeScheduleItemRequests: AssignFeeScheduleItemRequest[];

}
