import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

/**
 * @ngdoc pipe
 * @description
 * Angular pipe for displaying year count between dates or current date
 *
 * @param startDate - a start date
 * @param endDate - optional date to end the count. if omitted current date will be used
 * @usage
 * {{ startDate | yearsDiff : endDate}}
 */
@Pipe({
	name: 'yearsDiff',
})
export class YearsDiffPipe implements PipeTransform {
	transform(start: Date, end?: Date): number {
		return dayjs(end).diff(start, 'year');
	}

}
