import { Directive, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GridUtil } from 'morgana';
import { _isNil } from '@core/lodash/lodash';
import { AgGridAngular } from 'ag-grid-angular';

@Directive({
	selector: 'ag-grid-angular[pmsAgGridSubmit]',
})
export class AgGridSubmitDirective implements OnChanges {

	@Input()
	pmsAgGridSubmit = false;

	@Input()
	resetPageOnSubmit = true;

	private isGridInitialized = false;
	constructor(
		private gridComponent: AgGridAngular,
	) {
	}

	@HostListener('gridReady')
	onGridReady() {
		this.isGridInitialized = true;

		if (this.pmsAgGridSubmit) {
			GridUtil.showAgGridSpinner(this.gridComponent);
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (_isNil(changes.pmsAgGridSubmit) || changes.pmsAgGridSubmit.firstChange) {
			return;
		}

		// if the current pmsGridSubmit value is false and has changed from the previous value of true (when a table is finished searching)
		// reset filters and page
		if (!changes.pmsAgGridSubmit.currentValue && changes.pmsAgGridSubmit.previousValue && this.resetPageOnSubmit) {
			this.goToFirstPage();
		}

		if (changes.pmsAgGridSubmit.currentValue && this.isGridInitialized) {
			GridUtil.showAgGridSpinner(this.gridComponent);
		}
		if (!changes.pmsAgGridSubmit.currentValue && this.isGridInitialized) {
			GridUtil.hideAgGridSpinner(this.gridComponent);
			GridUtil.showAgGridNoRowOverlay(this.gridComponent);
		}
	}

	goToFirstPage() {
		this.gridComponent?.api?.paginationGoToFirstPage();
	}

}
