<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-lg"
	[attr.data-test-id]="'selectAPatientFamilyMemberAddModal'">
	<ng-template #header>
		<div class="dlg-template">Select a Patient</div>
	</ng-template>
	<ng-template #content>
		<div class="modal-feature">
			<form class="form-horizontal" [formGroup]="searchForm" #templateSearchForm="ngForm" (ngSubmit)="search()">
				<div class="row">
					<div class="col-md-12">
						@if (searchForm.invalid && templateSearchForm.submitted) {
							<gandalf-lib-validation-messages
								[form]="searchForm"
								[requestObj]="patientSearchRequest"
								[attr.data-test-id]="'selectAPatientFamilyMemberAddModalValidationMessages'">
							</gandalf-lib-validation-messages>
						}
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12 col-md-6 col-lg-4">
						<div class="form-group">
							<label class="col-sm-4 col-md-3 control-label" for="lastName">Last Name</label>
							<div class="col-sm-8 col-md-9">
								<input #lastName id="lastName" revFocusInput type="text" class="e-input"
									   formControlName="lastName" pmsCapitalizeFirstLetter/>
							</div>
						</div>
						<div class="form-group">
							<label class="col-sm-4 col-md-3 control-label" for="firstName">First Name</label>
							<div class="col-sm-8 col-md-9">
								<input id="firstName" type="text" class="e-input" formControlName="firstName" pmsCapitalizeFirstLetter/>
							</div>
						</div>
						<div *pmsUsaOnly class="form-group">
							<label class="col-sm-4 col-md-3 control-label" for="ssn">SSN</label>
							<div class="col-sm-8 col-md-9">
								<ejs-maskedtextbox
									id="ssn"
									mask="000-00-0000"
									formControlName="socialSecurityNumber">
								</ejs-maskedtextbox>
							</div>
						</div>
						<div *pmsCanadaOnly class="form-group">
							<label class="col-sm-4 col-md-3 control-label" for="ssn">SIN/PHN</label>
							<div class="col-sm-8 col-md-9">
								<input pmsSinInput class="e-input" type="text" formControlName="socialInsuranceNumber">
							</div>
						</div>
					</div>
					<div class="col-sm-12 col-md-6 col-lg-4">
						<div class="form-group">
							<label class="col-sm-4 col-md-3 control-label" for="dob">DOB</label>
							<div class="col-sm-8 col-md-9">
								<ejs-datepicker
									formControlName="dateOfBirth"
									id="dob"
									[min]="dateMin"
									[max]="dateMax"
									placeholder="mm/dd/yyyy"
									[format]="dateFormat">
								</ejs-datepicker>
							</div>
						</div>
						<div class="form-group">
							<label class="col-sm-4 col-md-3 control-label" for="phone">Phone</label>
							<div class="col-sm-8 col-md-9">
								<ejs-maskedtextbox
									id="phone"
									mask="(000) 000-0000"
									formControlName="phoneNumber">
								</ejs-maskedtextbox>
							</div>
						</div>
						<div class="form-group">
							<label class="col-sm-4 col-md-3 control-label" for="patientId">ID</label>
							<div class="col-sm-8 col-md-9">
								<input id="patientId" type="text" revDigitOnlyInput class="e-input"
									   formControlName="patientId"/>
							</div>
						</div>
					</div>
					<div class="col-sm-12 col-md-6 col-lg-4">
						<div class="form-group">
							<label class="col-sm-4 col-md-3 control-label" for="location">Location</label>
							<div class="col-sm-8 col-md-9">
								<ejs-dropdownlist
									id="location"
									[dataSource]="locations"
									placeholder="All Locations"
									formControlName="locationId"
									[showClearButton]="true">
								</ejs-dropdownlist>
							</div>
						</div>
						<div class="form-group">
							<label class="col-sm-4 col-md-3 control-label">Status</label>
							<div class="col-sm-8 col-md-9">
								<pms-enum-select-button
									[enumList]="patientSearchStatus"
									[allOption]="true"
									formControlName="patientSearchStatus">
								</pms-enum-select-button>
							</div>
						</div>
					</div>
				</div>
				<div class="row margin-top-sm">
					<div class="col-sm-12">
						<button ejs-button type="submit" [isPrimary]="true" [disabled]="isSearchDisabled()"
								iconCss="fa fa-search" [attr.data-test-id]="'selectAPatientFamilyMemberAddSearchButton'">Search
						</button>
						<button ejs-button type="button" (click)="clear(templateSearchForm)" [disabled]="isSearching"
								iconCss="fa fa-times" [attr.data-test-id]="'selectAPatientFamilyMemberAddClearButton'">Clear
						</button>
					</div>
				</div>
			</form>
		</div>

		<ejs-grid #grid
				  autoFitColumns
				  class="table-rowlink"
				  [autoFitBlacklist]="[]"
				  [allowResizing]="true"
				  [dataSource]="patientData"
				  [allowSorting]="true"
				  [allowSelection]="true"
				  [selectionSettings]="{enableToggle: false}"
				  (rowSelected)="patientSelected($event)"
				  gridLines="Horizontal"
				  allowPaging="true"
				  [pmsGridSubmit]="isSearching"
				  [pmsGridSubmitDefaultSortOptions]="sortOptions"
				  [pageSettings]="pageSettings"
				  [attr.data-test-id]="'selectAPatientFamilyMemberAddModalGrid'">
			<e-columns>
				<e-column field="patientId" headerText="ID"></e-column>
				<e-column field="formattedName" headerText="Name"></e-column>
				<e-column field="dateOfBirth" headerText="DOB" [format]="dobFormatter"></e-column>
				<e-column field="preferredPhoneNumber" headerText="Pref. Phone"></e-column>
				<e-column field="formattedAddress" headerText="Address"></e-column>
			</e-columns>
		</ejs-grid>

		<div class="margin-top-md">
			<h4 class="section-title bg-default mrgn-btm">Patient Demographics</h4>
			<form class="form-horizontal" [formGroup]="componentForm" #templateForm="ngForm" (ngSubmit)="addToFamilyAccount()">
				<div class="row">
					<div class="col-md-12">
						@if (componentForm.invalid && templateForm.submitted) {
							<gandalf-lib-validation-messages
								[form]="componentForm"
								[requestObj]="addPatientToFamilyRequest"
								[attr.data-test-id]="'selectAPatientFamilyMemberAddModalPatientDemographicsValidationMessages'">
							</gandalf-lib-validation-messages>
						}
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 col-lg-4">
						<div class="form-group">
							<label [attr.data-test-id]="'selectAPatientFamilyMemberAddModalFamilyRoleDropdownLabel'" class="col-sm-4 control-label required">Family
								Role</label>
							<div class="col-sm-8">
								<ejs-dropdownlist [dataSource]="familyRoleOptions"
												  pmsAutoSelect
												  formControlName="roleId"
												  placeholder="Select Family Role">
								</ejs-dropdownlist>
							</div>
						</div>
						<div class="form-group">
							<div class="col-sm-offset-4 col-sm-8">
								<ejs-checkbox label="Head of Household" formControlName="headOfHousehold"></ejs-checkbox>
							</div>
						</div>
						@if (isEmergencyContactFeatureOn) {
							<div class="form-group">
								<div class="col-sm-8 col-sm-offset-4">
									<ejs-checkbox formControlName="isEmergencyContact" label="Emergency Contact"></ejs-checkbox>
								</div>
							</div>
							@if (componentForm.get('isEmergencyContact').value) {
								<div class="form-group">
									<div class="col-sm-8 col-sm-offset-4">
										<ejs-checkbox formControlName="isPrimaryEmergencyContact" label="Set as Primary"></ejs-checkbox>
									</div>
								</div>
							}
						}
					</div>
				</div>
			</form>
		</div>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'selectAPatientFamilyMemberAddModalCancelButton'">Cancel</button>
		<button ejs-button [isPrimary]="true" (click)="submitForm($event)" [attr.data-test-id]="'selectAPatientFamilyMemberAddModalSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>
