<h4 class="section-title bg-default mrgn-btm">Recommendations</h4>
@if (eyeglassPrescription) {
	<div>
		<div class="form-group" [attr.data-test-id]="'eyeglassRecommendationsMaterialFormGroup'">
			<label class="col-sm-5 control-label padding-x-0">Material</label>
			<div class="col-sm-7">
				<p class="margin-0">{{ eyeglassPrescription.material?.value }}
					@if (eyeglassPrescription.materialComment) {
						<span
						> - {{ eyeglassPrescription.materialComment }}</span>
					}
				</p>
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'eyeglassRecommendationsTintFormGroup'">
			<label class="col-sm-5 control-label padding-x-0">Tint</label>
			<div class="col-sm-7">
				<p class="margin-0">{{ eyeglassPrescription.tint?.value }}
					@if (eyeglassPrescription.tintComment) {
						<span> - {{ eyeglassPrescription.tintComment }}</span>
					}
				</p>
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'eyeglassRecommendationsAntiReflectiveFormGroup'">
			<label class="col-sm-5 control-label padding-x-0">AR Coating</label>
			<div class="col-sm-7">
				@if (eyeglassPrescription.arCoating) {
					<p class="margin-0">Yes
						@if (eyeglassPrescription.arCoatingComment) {
							<span> - {{ eyeglassPrescription.arCoatingComment }}</span>
						}
					</p>
				}
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'eyeglassRecommendationsPhotochromicFormGroup'">
			<label class="col-sm-5 control-label padding-x-0">Photochromic</label>
			<div class="col-sm-7">
				@if (eyeglassPrescription.photochromic) {
					<p class="margin-0">Yes
						@if (eyeglassPrescription.photochromicComment) {
							<span
							> - {{ eyeglassPrescription.photochromicComment }}</span>
						}
					</p>
				}
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'eyeglassRecommendationsUltraVioletFormGroup'">
			<label class="col-sm-5 control-label padding-x-0">UV Treatment</label>
			<div class="col-sm-7">
				@if (eyeglassPrescription.uvTreatment) {
					<p class="margin-0">Yes
						@if (eyeglassPrescription.uvTreatmentComment) {
							<span
							> - {{ eyeglassPrescription.uvTreatmentComment }}</span>
						}
					</p>
				}
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'eyeglassRecommendationsPolarizedFormGroup'">
			<label class="col-sm-5 control-label padding-x-0">Polarized</label>
			<div class="col-sm-7">
				@if (eyeglassPrescription.polarized) {
					<p class="margin-0">Yes
						@if (eyeglassPrescription.polarizedComment) {
							<span> - {{ eyeglassPrescription.polarizedComment }}</span>
						}
					</p>
				}
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'eyeglassRecommendationsLensTypeFormGroup'">
			<label class="col-sm-5 control-label padding-x-0">Lens Type</label>
			<div class="col-sm-7">
				<p class="margin-0">{{ eyeglassPrescription.lensType?.value }}
					@if (eyeglassPrescription.lensTypeComment) {
						<span> - {{ eyeglassPrescription.lensTypeComment }}</span>
					}
				</p>
			</div>
		</div>
	</div>
} @else {
	No Data
}
