<ejs-dialog
		#modal
		(close)="closeModal()"
		cssClass="modal-lg"
		[attr.data-test-id]="'carePlanItemHistoryModal'">
	<ng-template #header>
		<div class="dlg-template">Care Plan Item History</div>
	</ng-template>
	<ng-template #content>
		<form class="form-horizontal">
			<h4 class="section-title bg-default mrgn-btm">Diagnosis Information</h4>
			<div class="form-group" [attr.data-test-id]="'carePlanItemHistoryModalCodeFormGroup'">
				<label class="col-sm-4 col-md-2 col-lg-1 control-label">Code</label>
				<div class="col-sm-8 col-md-10 col-lg-11">
					<p class="form-control-static">
						<pms-diagnosis-code [practiceDiagnosis]="personDiagnosis?.practiceDiagnosis"></pms-diagnosis-code>
					</p>
					<p class="form-control-static" *ngIf="originalPersonDiagnosis?.parentDiagnosis">
						<pms-diagnosis-code [practiceDiagnosis]="originalPersonDiagnosis.parentDiagnosis?.practiceDiagnosis"></pms-diagnosis-code>
					</p>
					<p *ngFor="let childDiagnosis of originalPersonDiagnosis?.childDiagnoses" class="form-control-static">
						<pms-diagnosis-code [practiceDiagnosis]="childDiagnosis.practiceDiagnosis"></pms-diagnosis-code>
					</p>
				</div>
			</div>
			<div class="form-group">
				<label class="col-sm-4 col-md-2 col-lg-1 control-label">Dx Date</label>
				<div class="col-sm-8 col-md-10 col-lg-11">
					<p class="form-control-static">
						{{personDiagnosis?.diagnosisDate | date:dateFormat}}
					</p>
				</div>
			</div>
			<div class="form-group">
				<label class="col-sm-4 col-md-2 col-lg-1 control-label">Res. Date</label>
				<div class="col-sm-8 col-md-10 col-lg-11">
					<p class="form-control-static">
						{{personDiagnosis?.originalDiagnosisResolutionDate | date:dateFormat}}
					</p>
				</div>
			</div>
			<div class="form-group">
				<label class="col-sm-4 col-md-2 col-lg-1 control-label">Location</label>
				<div class="col-sm-8 col-md-10 col-lg-11">
					<p class="form-control-static">
						{{personDiagnosis?.diagnosisLocation}}
					</p>
				</div>
			</div>
			<h4 class="section-title bg-default mrgn-btm">Care Plan Item History</h4>
			<ejs-grid
					#grid
					class="table-rowlink"
					autoFitColumns
					[autoFitBlacklist]="['carePlanItem.description']"
					[staticColumns]="['carePlanItem.startDate', 'carePlanItem.stopDate']"
					[dataSource]="filteredCarePlanItems"
					[allowSorting]="true"
					[sortSettings]="sortSettings"
					[pmsGridSubmit]="isSearching"
					[allowResizing]="true"
					[allowFiltering]="true"
					(queryCellInfo)="applyBackgroundColor($event)"
					[filterSettings]="{ showFilterBarStatus: false }"
					gridLines="Horizontal"
					[allowExcelExport]="true"
					(rowSelecting)="setSelection($event)"
					(rowDataBound)="disableCheckbox($event)"
					(rowSelected)="setItemsSelected()"
					(rowDeselected)="setItemsSelected()"
					[attr.data-test-id]="'carePlanItemHistoryModalTable'"
			>
				<ng-template #toolbarTemplate *ngIf="mode !== cpiMode.SELECT">
					<rev-table-action-menu [table]="grid">
						<ng-container rev-table-action-menu-left>
							<div class="e-toolbar-item" [attr.data-test-id]="'carePlanItemHistoryModalFilterSection'">
								<label>Status</label>
								<pms-enum-select-button
										[enumList]="carePlanFilterOptions"
										[allOption]="true"
										[(ngModel)]="carePlanFilterStatus"
										[ngModelOptions]="{standalone: true}"
                                        (valueChange)="filterGrid()">
								</pms-enum-select-button>
							</div>
						</ng-container>
					</rev-table-action-menu>
				</ng-template>
				<e-columns>
					<e-column *ngIf="mode === cpiMode.SELECT" field="checkbox" type="checkbox" headerText="" [allowSorting]="false" [width]="36"></e-column>
					<e-column field="carePlanItem.category.label" headerText="Type" width="100"></e-column>
					<e-column field="carePlanItem.startDate" headerText="Start" width="110" [format]="tableDateFormat"></e-column>
					<e-column field="carePlanItem.stopDate" headerText="Stop" width="110" [format]="tableDateFormat"></e-column>
					<e-column field="practiceDiagnosis" headerText="Code" [sortComparer]="codeColumnComparator">
						<ng-template #template let-data>
								<span *ngIf="data.isShowDisabledWarning" revTooltip
									  tooltipContent="This code is no longer billable on/after {{data.practiceDiagnosis.disabledDate | date: dateFormat}}">
									{{ data.practiceDiagnosis.code}}
								</span>
							<span *ngIf="!data.isShowDisabledWarning">
									{{ data.practiceDiagnosis.code}}
								</span>
							<img src="assets/diagnosis/convert-icon.png" *ngIf="data.needConversion"  class="display-inline"/>
						</ng-template>
					</e-column>
					<e-column field="carePlanItem.id" [visible]="false"></e-column>
					<e-column field="carePlanItem.description" headerText="Description" clipMode="EllipsisWithTooltip" [customAttributes]="{ autofitMinWidth: 100 }">
						<ng-template #template let-data>
							<span revTooltip
								  [tooltipContent]="getTooltipContent(data)">
								{{data.carePlanItem.description}}
							</span>
						</ng-template>
					</e-column>
				</e-columns>
			</ejs-grid>
			<div *ngIf="mode === cpiMode.SELECT" class="col-sm-8">
				<div class="row">
					<div class="col-sm-8" [attr.data-test-id]="'carePlanItemHistoryModalCarePlanSelectedCountSection'">
						<p class="form-control-static">{{itemsSelected}} CPI selected</p>
					</div>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" type="button" (click)="closeModal()" [attr.data-test-id]="'carePlanItemHistoryModalCancelButton'">Cancel</button>
		<button *ngIf="mode === cpiMode.SELECT && itemsSelected > 0" ejs-button [isPrimary]="true" type="submit" (click)="selectCPI()" [attr.data-test-id]="'carePlanItemHistoryModalSelectButton'">Select</button>
	</ng-template>
</ejs-dialog>
