// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.reporting.patient.PatientRecallSearchResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientRecallSearchResponse extends GandalfModelBase {

	@GandalfProperty()
	address: string;

	@GandalfProperty()
	city: string;

	@GandalfProperty()
	comment: string;

	@GandalfProperty()
	date: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dob: Date;

	@GandalfProperty()
	email: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	gender: constants.Gender;

	@GandalfProperty()
	location: string;

	@GandalfProperty()
	patientName: PatientNameResponse;

	@GandalfProperty()
	phone: string;

	@GandalfProperty()
	reason: string;

	@GandalfProperty()
	state: string;

	@GandalfProperty()
	zip: string;

}
