// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressOptionalRequest } from './address-optional-request';

// @ts-ignore
import { OrderProductOptionRequest } from './order-product-option-request';

// @ts-ignore
import { UpdateEyeglassLensMeasurementsRequest } from './update-eyeglass-lens-measurements-request';

// @ts-ignore
import { UpdateEyeglassLensOrderDetailRequest } from './update-eyeglass-lens-order-detail-request';

// @ts-ignore
import { UpdateOrderRequest } from './update-order-request';

/** See com.rev360.pms.api.controller.order.UpdateEyeglassOrderRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateEyeglassOrderRequest extends UpdateOrderRequest {

	@GandalfSubRequestLabel('AR Coating')
	@GandalfProperty()
	arCoating: OrderProductOptionRequest;

	@GandalfValidator({ sizeString: { message: 'AR Coating Side cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	arCoatingSide: string;

	@GandalfValidator({ sizeString: { message: 'Comment cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	comment: string;

	@GandalfValidator({ min: { min: 0, message: 'Density % must be between 0 and 100' } })
	@GandalfValidator({ max: { max: 100, message: 'Density % must be between 0 and 100' } })
	@GandalfProperty()
	density: number;

	@GandalfProperty()
	distancePd: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dueDate: Date;

	@GandalfSubRequestLabel('Edge Treatment')
	@GandalfProperty()
	edgeTreatment: OrderProductOptionRequest;

	@GandalfProperty()
	encounterId: number;

	@GandalfLabel('Estimate Discounts')
	@GandalfProperty({ propertyType: 'Money' })
	estimatedDiscounts: number;

	@GandalfLabel('Estimated Insurance Benefit')
	@GandalfProperty({ propertyType: 'Money' })
	estimatedInsuranceBenefit: number;

	@GandalfValidator({ sizeString: { message: 'Frame Supplier cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	externalFrameSupplierIdentifier: string;

	@GandalfValidator({ sizeString: { message: 'Frame Supplier Name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	externalFrameSupplierName: string;

	@GandalfValidator({ sizeString: { message: 'Supplier cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	externalSupplierIdentifier: string;

	@GandalfValidator({ sizeString: { message: 'Supplier Name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	externalSupplierName: string;

	@GandalfProperty()
	frameColorId: number;

	@GandalfProperty({ propertyType: 'Money' })
	frameCost: number;

	@GandalfProperty()
	frameId: number;

	@GandalfProperty()
	frameItemId: number;

	@GandalfProperty({ propertyType: 'Money' })
	frameRemakeCost: number;

	@GandalfProperty()
	frameSizeId: number;

	@GandalfValidator({ sizeString: { message: 'Instructions cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	instructions: string;

	@GandalfProperty({ propertyType: 'Money' })
	lensCost: number;

	@GandalfProperty({ propertyType: 'Money' })
	lensRemakeCost: number;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Lens Request is required' } })
	@GandalfProperty({ isRequired: true })
	lensRequest: constants.OrderLensRequest;

	@GandalfValidator({ notNull: { message: 'Location is required' } })
	@GandalfProperty({ isRequired: true })
	locationId: number;

	@GandalfSubRequestLabel('Material')
	@GandalfProperty()
	material: OrderProductOptionRequest;

	@GandalfSubRequestLabel('Mirror Coating')
	@GandalfProperty()
	mirrorCoating: OrderProductOptionRequest;

	@GandalfSubRequestLabel('Mounting Type')
	@GandalfProperty()
	mountingType: OrderProductOptionRequest;

	@GandalfProperty()
	nearPd: number;

	@GandalfValidator({ sizeString: { message: 'Notification Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	notificationComments: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	notificationDate: Date;

	@GandalfSubRequestLabel('OD')
	@GandalfValidator({ notNull: { message: 'OD details are required' } })
	@GandalfProperty({ isRequired: true })
	odLensDetail: UpdateEyeglassLensOrderDetailRequest;

	@GandalfSubRequestLabel('OD')
	@GandalfValidator({ notNull: { message: 'OD measurements are required' } })
	@GandalfProperty({ isRequired: true })
	odLensMeasurements: UpdateEyeglassLensMeasurementsRequest;

	@GandalfConstantDecorator()
	@GandalfProperty()
	opticalCenterType: constants.OrderOpticalCenterType;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Frame Indicator is required' } })
	@GandalfProperty({ isRequired: true })
	orderFrameIndication: constants.OrderFrameIndication;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Frame Source is required' } })
	@GandalfProperty({ isRequired: true })
	orderFrameSource: constants.OrderFrameSource;

	@GandalfValidator({ notNull: { message: 'Order is required' } })
	@GandalfProperty({ isRequired: true })
	orderId: number;

	@GandalfSubRequestLabel('OS')
	@GandalfValidator({ notNull: { message: 'OS details are required' } })
	@GandalfProperty({ isRequired: true })
	osLensDetail: UpdateEyeglassLensOrderDetailRequest;

	@GandalfSubRequestLabel('OS')
	@GandalfValidator({ notNull: { message: 'OS measurements are required' } })
	@GandalfProperty({ isRequired: true })
	osLensMeasurements: UpdateEyeglassLensMeasurementsRequest;

	@GandalfSubRequestLabel('Other Coating')
	@GandalfProperty()
	otherCoating: OrderProductOptionRequest;

	@GandalfProperty({ propertyType: 'Money' })
	otherCost: number;

	@GandalfProperty({ propertyType: 'Money' })
	otherRemakeCost: number;

	@GandalfProperty()
	pantoscopicTilt: number;

	@GandalfValidator({ notNull: { message: 'Patient Notified is required' } })
	@GandalfProperty({ isRequired: true })
	patientNotified: boolean;

	@GandalfSubRequestLabel('Photochromic')
	@GandalfProperty()
	photochromic: OrderProductOptionRequest;

	@GandalfSubRequestLabel('Polarized')
	@GandalfProperty()
	polarized: OrderProductOptionRequest;

	@GandalfProperty()
	prescriptionId: number;

	@GandalfProperty()
	processorId: number;

	@GandalfValidator({ notNull: { message: 'Quantity is required' } })
	@GandalfProperty({ isRequired: true })
	quantity: number;

	@GandalfSubRequestLabel('Scratch Coating')
	@GandalfProperty()
	scratchCoating: OrderProductOptionRequest;

	@GandalfConstantDecorator()
	@GandalfProperty()
	segHeightType: constants.OrderSegHeightType;

	@GandalfValidator({ sizeString: { message: 'Shade cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	shade: string;

	@GandalfProperty({ propertyType: 'Money' })
	shipCost: number;

	@GandalfProperty({ propertyType: 'Money' })
	shipRemakeCost: number;

	@GandalfProperty()
	shipToOtherAddress: AddressOptionalRequest;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Ship To is required' } })
	@GandalfProperty({ isRequired: true })
	shipToType: constants.OrderShipToType;

	@GandalfConstantDecorator()
	@GandalfProperty()
	shippingCompany: constants.OrderShipmentCompany;

	@GandalfSubRequestLabel('Special Option')
	@GandalfProperty()
	specialOption: OrderProductOptionRequest;

	@GandalfSubRequestLabel('Thickness')
	@GandalfProperty()
	thickness: OrderProductOptionRequest;

	@GandalfSubRequestLabel('Tint Factor')
	@GandalfProperty()
	tintFactor: OrderProductOptionRequest;

	@GandalfSubRequestLabel('Tint Type')
	@GandalfProperty()
	tintType: OrderProductOptionRequest;

	@GandalfValidator({ sizeString: { message: 'Tracking # cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	trackingNumber: string;

	@GandalfValidator({ sizeString: { message: 'Tray cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	tray: string;

	@GandalfSubRequestLabel('UV Treatment')
	@GandalfProperty()
	uvTreatment: OrderProductOptionRequest;

	@GandalfProperty()
	vendorId: number;

	@GandalfValidator({ sizeString: { message: 'Vendor Order # cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	vendorOrderIdentifier: string;

	@GandalfValidator({ notNull: { message: 'Version is required' } })
	@GandalfProperty({ isRequired: true })
	version: number;

	@GandalfProperty()
	wrapAngle: number;

}
