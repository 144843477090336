// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ProviderResponse } from './provider-response';

// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.provider.externalprovider.ExternalProviderDropdownResponse */
/* istanbul ignore next */
@GandalfModel
export class ExternalProviderDropdownResponse extends ProviderResponse {

	@GandalfProperty()
	altPracticeName: string;

	@GandalfProperty()
	firstName: string;

	@GandalfProperty()
	lastName: string;

	@GandalfProperty()
	npi: string;

	@GandalfProperty()
	personId: number;

	@GandalfProperty()
	providerDisplayColor: number;

	@GandalfProperty()
	providerId: number;

	@GandalfProperty()
	providerType: ReferenceDataResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.ProviderStatus;

}
