// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { RecurringDefinitionResponse } from './recurring-definition-response';

/** See com.rev360.pms.api.controller.schedule.scheduleitem.sidebar.ScheduleItemSidebarResponse */
/* istanbul ignore next */
@GandalfModel
export class ScheduleItemSidebarResponse extends GandalfModelBase {

	@GandalfProperty()
	createdOn: Date;

	@GandalfProperty()
	description: string;

	@GandalfProperty()
	recurringDefinition: RecurringDefinitionResponse;

	@GandalfProperty()
	scheduleItemId: number;

	@GandalfProperty()
	summary: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	type: constants.ScheduleItemType;

}
