// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.eyefinity.EyefinityOrderItemResponse */
/* istanbul ignore next */
@GandalfModel
export class EyefinityOrderItemResponse extends GandalfModelBase {

	@GandalfProperty()
	billableItemId: number;

	@GandalfProperty({ propertyType: 'Money' })
	eyefinityChargeback: number;

	@GandalfProperty({ propertyType: 'Money' })
	eyefinityChargebackBeforeCopay: number;

	@GandalfProperty()
	eyefinityFeeOptionId: number;

	@GandalfProperty()
	eyefinityGenerated: boolean;

	@GandalfProperty()
	eyefinityOrderItemId: number;

	@GandalfProperty({ propertyType: 'Money' })
	eyefinityPays: number;

	@GandalfProperty({ propertyType: 'Money' })
	eyefinityReimbursement: number;

	@GandalfProperty({ propertyType: 'Money' })
	eyefinityServiceFee: number;

	@GandalfProperty({ propertyType: 'Money' })
	eyefinityTotal: number;

	@GandalfProperty()
	hcpcsCode: string;

	@GandalfProperty({ propertyType: 'Money' })
	invoiceCost: number;

	@GandalfProperty()
	itemIsCovered: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	itemType: constants.EyefinityOrderItemType;

	@GandalfProperty()
	locationProductId: number;

	@GandalfProperty()
	locationProductItemId: number;

	@GandalfProperty()
	name: string;

	@GandalfProperty({ propertyType: 'Money' })
	patientCopay: number;

	@GandalfProperty({ propertyType: 'Money' })
	patientCopayAmount: number;

	@GandalfProperty({ propertyType: 'Money' })
	patientPortion: number;

	@GandalfProperty({ propertyType: 'Money' })
	patientPortionExceedingAllowance: number;

	@GandalfProperty({ propertyType: 'Money' })
	patientPortionForNonCoveredItem: number;

	@GandalfProperty({ propertyType: 'Money' })
	patientPortionFromUnallocatedCost: number;

	@GandalfProperty({ propertyType: 'Money' })
	patientTotal: number;

	@GandalfProperty({ propertyType: 'Money' })
	patientTransferAmount: number;

	@GandalfProperty()
	quantity: number;

	@GandalfProperty({ propertyType: 'Money' })
	recoupmentAmount: number;

	@GandalfProperty()
	rememberItem: boolean;

	@GandalfProperty({ propertyType: 'Money' })
	retailCost: number;

	@GandalfProperty()
	serviceId: number;

	@GandalfProperty({ propertyType: 'Money' })
	unallocatedCost: number;

	@GandalfProperty({ propertyType: 'Money' })
	writeoffAmount: number;

}
