// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.inventory.cost.BaseCostReportResponse */
/* istanbul ignore next */
@GandalfModel
export class BaseCostReportResponse extends GandalfModelBase {

	@GandalfProperty()
	brand: string;

	@GandalfProperty()
	category: string;

	@GandalfProperty()
	collection: string;

	@GandalfProperty({ propertyType: 'Money' })
	costOfStock: number;

	@GandalfProperty()
	itemDescription: string;

	@GandalfProperty()
	locationId: number;

	@GandalfProperty({ propertyType: 'Money' })
	locationProductActualCost: number;

	@GandalfProperty()
	locationProductItemId: number;

	@GandalfProperty()
	manufacturer: string;

	@GandalfProperty()
	model: string;

	@GandalfProperty({ propertyType: 'Money' })
	retail: number;

	@GandalfProperty({ propertyType: 'Money' })
	retailOfStock: number;

	@GandalfProperty()
	sku: string;

	@GandalfProperty()
	stock: number;

	@GandalfProperty()
	upc: string;

	@GandalfProperty({ propertyType: 'Money' })
	wholesale: number;

	@GandalfProperty({ propertyType: 'Money' })
	wholesaleOfStock: number;

}
