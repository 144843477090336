// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.CreatePatientCommunicationPreferencesRequest */
/* istanbul ignore next */
@GandalfModel
export class CreatePatientCommunicationPreferencesRequest extends GandalfModelBase {

	@GandalfProperty()
	appointmentsCellPhoneAllowed: boolean;

	@GandalfProperty()
	appointmentsEmailAllowed: boolean;

	@GandalfProperty()
	appointmentsHomePhoneAllowed: boolean;

	@GandalfProperty()
	appointmentsMailAllowed: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	appointmentsOptIn: constants.PatientCommunicationOptIn;

	@GandalfProperty()
	appointmentsTextAllowed: boolean;

	@GandalfProperty()
	appointmentsWorkPhoneAllowed: boolean;

	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	comments: string;

	@GandalfProperty()
	doNotCallCell: boolean;

	@GandalfProperty()
	doNotCallHome: boolean;

	@GandalfProperty()
	doNotCallWork: boolean;

	@GandalfProperty()
	doNotEmail: boolean;

	@GandalfProperty()
	doNotMail: boolean;

	@GandalfProperty()
	doNotText: boolean;

	@GandalfProperty()
	generalCellPhoneAllowed: boolean;

	@GandalfProperty()
	generalEmailAllowed: boolean;

	@GandalfProperty()
	generalHomePhoneAllowed: boolean;

	@GandalfProperty()
	generalMailAllowed: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	generalOptIn: constants.PatientCommunicationOptIn;

	@GandalfProperty()
	generalTextAllowed: boolean;

	@GandalfProperty()
	generalWorkPhoneAllowed: boolean;

	@GandalfProperty()
	ordersCellPhoneAllowed: boolean;

	@GandalfProperty()
	ordersEmailAllowed: boolean;

	@GandalfProperty()
	ordersHomePhoneAllowed: boolean;

	@GandalfProperty()
	ordersMailAllowed: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	ordersOptIn: constants.PatientCommunicationOptIn;

	@GandalfProperty()
	ordersTextAllowed: boolean;

	@GandalfProperty()
	ordersWorkPhoneAllowed: boolean;

	@GandalfProperty()
	recallsCellPhoneAllowed: boolean;

	@GandalfProperty()
	recallsEmailAllowed: boolean;

	@GandalfProperty()
	recallsHomePhoneAllowed: boolean;

	@GandalfProperty()
	recallsMailAllowed: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	recallsOptIn: constants.PatientCommunicationOptIn;

	@GandalfProperty()
	recallsTextAllowed: boolean;

	@GandalfProperty()
	recallsWorkPhoneAllowed: boolean;

}
