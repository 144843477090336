// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.laborder.CreateLabOrderRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateLabOrderRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Description cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	description: string;

	@GandalfProperty()
	encounterId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate: Date;

	@GandalfProperty()
	fileCount: number;

	@GandalfProperty()
	frequencyId: number;

	@GandalfValidator({ sizeString: { message: 'Interpretation Notes cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	interpretationNotes: string;

	@GandalfValidator({ sizeString: { message: 'Interpretation Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	interpretationOtherComments: string;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Interpretation Status is required' } })
	@GandalfProperty({ isRequired: true })
	interpretationStatus: constants.LabOrderInterpretationStatus;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Lab Type is required' } })
	@GandalfProperty({ isRequired: true })
	labType: constants.LabOrderLabType;

	@GandalfProperty()
	labVendorId: number;

	@GandalfProperty()
	nonCpoeEntry: boolean;

	@GandalfValidator({ sizeString: { message: 'Normal Range cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	normalRange: string;

	@GandalfValidator({ sizeString: { message: 'Order Comment cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	orderComment: string;

	@GandalfValidator({ notNull: { message: 'Order Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	orderDate: Date;

	@GandalfValidator({ sizeString: { message: 'Order Instructions cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	orderInstructions: string;

	@GandalfValidator({ notNull: { message: 'Order is required' } })
	@GandalfValidator({ sizeString: { message: 'Order must be between 1 and 8 characters', minLength: 1, maxLength: 8 } })
	@GandalfProperty({ isRequired: true })
	orderLoincNum: string;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Order Type is required' } })
	@GandalfProperty({ isRequired: true })
	orderType: constants.LabOrderType;

	@GandalfValidator({ notNull: { message: 'Ordered By is required' } })
	@GandalfProperty({ isRequired: true })
	orderedByProviderId: number;

	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	reportDate: Date;

	@GandalfValidator({ sizeString: { message: 'Result Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	resultComments: string;

	@GandalfValidator({ sizeString: { message: 'Lab Note cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	resultLabNote: string;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Result Status is required' } })
	@GandalfProperty({ isRequired: true })
	resultStatus: constants.LabOrderResultStatus;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Result Type is required' } })
	@GandalfProperty({ isRequired: true })
	resultType: constants.LabOrderResultType;

	@GandalfProperty()
	resultUnitId: number;

	@GandalfValidator({ sizeString: { message: 'Result Value cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	resultValue: string;

	@GandalfValidator({ sizeString: { message: 'Specimen Disposition cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	specimenDisposition: string;

	@GandalfProperty()
	specimenSourceId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate: Date;

	@GandalfValidator({ sizeString: { message: 'Test Name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	testName: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	testPerformedDate: Date;

	@GandalfProperty()
	testTypeId: number;

}
