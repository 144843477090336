import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicModalRef } from 'morgana';
import { ProviderService } from '@core/provider/provider.service';
import { ProviderStatus } from '@gandalf/constants';
import { ExternalProviderDropdownResponse } from '@gandalf/model/external-provider-dropdown-response';
import { ProviderResponse } from '@gandalf/model/provider-response';
import { ProviderNamePipe } from '@shared/pipes/provider-name/provider-name.pipe';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { SortSettingsModel } from '@syncfusion/ej2-grids';

@Component({
	selector: 'pms-select-external-provider-modal',
	templateUrl: './select-external-provider-modal.component.html',
	styles: [],
})
export class SelectExternalProviderModalComponent implements OnInit {
	externalProviders: ProviderResponse[];
	statusFilter = ProviderStatus.ACTIVE;
	statusFilterOptions = [ProviderStatus.ACTIVE, ProviderStatus.INACTIVE];
	sortOptions: SortSettingsModel = {
		columns: [
			{field: 'lastName', direction: 'Ascending'},
		],
	};

	@ViewChild(GridComponent)
	grid: GridComponent;

	@ViewChild('modal')
	modal: DialogComponent;

	constructor(
		private dynamicModalRef: DynamicModalRef,
		private providerNamePipe: ProviderNamePipe,
		private providersService: ProviderService,
	) { }

	ngOnInit() {
		this.providersService.findExternalProviders().subscribe(providers => {
			this.externalProviders = providers;
		});
	}

	getProviderName(provider: ExternalProviderDropdownResponse) {
		return this.providerNamePipe.transform(provider as ProviderResponse);
	}

	closeModal(selectedProvider?: ExternalProviderDropdownResponse) {
		this.dynamicModalRef.close(this.modal, selectedProvider);
	}

	filterTable() {
		if (this.statusFilter.label !== 'All') {
			this.grid.filterByColumn('status.label', 'equal', this.statusFilter.label);
		} else {
			this.grid.clearFiltering(['status.label']);
		}
	}
}
