// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FieldValueMultiSelectionDiffResponse } from './field-value-multi-selection-diff-response';

/** See com.rev360.pms.api.controller.encounter.history.ros.ReviewOfSystemsDiffResponse */
/* istanbul ignore next */
@GandalfModel
export class ReviewOfSystemsDiffResponse extends GandalfModelBase {

	@GandalfProperty()
	cardiovascular: FieldValueMultiSelectionDiffResponse;

	@GandalfProperty()
	commentsFromIntake: string;

	@GandalfProperty()
	constitutional: FieldValueMultiSelectionDiffResponse;

	@GandalfProperty()
	endocrine: FieldValueMultiSelectionDiffResponse;

	@GandalfProperty()
	ent: FieldValueMultiSelectionDiffResponse;

	@GandalfProperty()
	gastrointestinal: FieldValueMultiSelectionDiffResponse;

	@GandalfProperty()
	genitourinary: FieldValueMultiSelectionDiffResponse;

	@GandalfProperty()
	hasChanges: boolean;

	@GandalfProperty()
	hasReviewOfSystemsFromIntake: boolean;

	@GandalfProperty()
	hematLymphatic: FieldValueMultiSelectionDiffResponse;

	@GandalfProperty()
	immunological: FieldValueMultiSelectionDiffResponse;

	@GandalfProperty()
	musculoskeletal: FieldValueMultiSelectionDiffResponse;

	@GandalfProperty()
	neurological: FieldValueMultiSelectionDiffResponse;

	@GandalfProperty()
	psychological: FieldValueMultiSelectionDiffResponse;

	@GandalfProperty()
	respiratory: FieldValueMultiSelectionDiffResponse;

	@GandalfProperty()
	skin: FieldValueMultiSelectionDiffResponse;

}
