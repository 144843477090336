// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

/** See com.rev360.pms.api.controller.insurance.PracticeInsuranceCompanyModalResponse */
/* istanbul ignore next */
@GandalfModel
export class PracticeInsuranceCompanyModalResponse extends GandalfModelBase {

	@GandalfProperty()
	address: AddressResponse;

	@GandalfProperty()
	defaultInsuranceTypeReferenceId: number;

	@GandalfProperty()
	defaultPriorityReferenceId: number;

	@GandalfProperty()
	id: number;

}
