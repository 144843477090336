import { ITableState, TableState } from '@app-store/reducers';
import { LegacyStatefulComponentUtilsService } from '@app-store/utils/legacy-stateful-component-utils.service';
import { PatientProvidersDetailActionTypes } from '@patients-store/actions';
import { LegacyStatefulComponentBaseState } from '@shared/decorators/legacy-stateful-component.decorator';

export class PatientProvidersState extends LegacyStatefulComponentBaseState {
	personProviderId: number;
	providerId: number;
	providerRoleId: number;
	detailMode = false;
	newEdit = false;
	selectedIndex = 0;
	tableState: ITableState = new TableState();

	constructor() {
		super();
	}
}

export type PatientProviderStateMap = Map<number, PatientProvidersState>;

export const initialState: PatientProviderStateMap = new Map();

export function reducer(state = initialState, action: any): PatientProviderStateMap {
	switch (action.type) {

		case PatientProvidersDetailActionTypes.UPDATE_PATIENT_PROVIDERS_DETAIL_FORM_VALUE: {
			return LegacyStatefulComponentUtilsService.updateFormValue(state, PatientProvidersState, action.payload);
		}

		case PatientProvidersDetailActionTypes.UPDATE_PATIENT_PROVIDERS_DETAIL_FORM_SUBMITTED: {
			return LegacyStatefulComponentUtilsService.updateFormSubmitted(state, PatientProvidersState, action.payload);
		}

		case PatientProvidersDetailActionTypes.REMOVE_PATIENT_PROVIDERS_DETAIL_FORM_VALUE: {
			return LegacyStatefulComponentUtilsService.removeFormValue(state, action.payload);
		}

		case PatientProvidersDetailActionTypes.UPDATE_PATIENT_PROVIDERS_DETAIL_FORM_MODE: {
			const providerStateMap = new Map(state);
			let patientState = providerStateMap.get(action.payload.patientId);

			if (!patientState) {
				patientState = new PatientProvidersState();
			}
			patientState.detailMode = action.payload.detailMode;
			patientState.newEdit = action.payload.newEdit;
			patientState.personProviderId = action.payload.personProviderId;
			patientState.providerId = action.payload.providerId;
			patientState.providerRoleId = action.payload.providerRoleId;

			providerStateMap.set(action.payload.patientId, patientState);

			return providerStateMap;
		}

		case PatientProvidersDetailActionTypes.UPDATE_PATIENT_PROVIDERS_DETAIL_TAB_VALUE: {
			const providerStateMap = new Map(state);
			let patientState = providerStateMap.get(action.payload.patientId);

			if (!patientState) {
				patientState = new PatientProvidersState();
			}
			patientState.selectedIndex = action.payload.tabIndex;

			providerStateMap.set(action.payload.patientId, patientState);
			return providerStateMap;
		}

		case PatientProvidersDetailActionTypes.UPDATE_PATIENT_PROVIDERS_TABLE_CONFIG_VALUE: {
			const providerStateMap = new Map(state);
			let patientState = providerStateMap.get(action.payload.patientId);

			if (!patientState) {
				patientState = new PatientProvidersState();
			}

			patientState.tableState.tableConfigurationJson = action.payload.gridConfig;
			patientState.tableState.tableResults = action.payload.results;
			patientState.tableState.isSearched = true;

			providerStateMap.set(action.payload.patientId, patientState);

			return providerStateMap;
		}

		case PatientProvidersDetailActionTypes.REMOVE_PATIENT_PROVIDERS_DETAIL: {
			const providerStateMap = new Map(state);
			providerStateMap.delete(action.payload.patientId);
			return providerStateMap;
		}

		default: {
			return state;
		}
	}
}
