export const XHR_READY_STATES = {
	// Client has been created. open() has not been called yet.
	UNSENT: 0,

	// open() has been called.
	OPENED: 1,

	// send() has been called, and headers and status are available.
	HEADERS_RECEIVED: 2,

	// Downloading; responseText holds partial data.
	LOADING: 3,

	// The operation is complete.
	DONE: 4,
};
