// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.history.medication.MedicationReviewHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class MedicationReviewHistoryResponse extends GandalfModelBase {

	@GandalfConstantDecorator()
	@GandalfProperty()
	category: constants.ReviewHistoryCategory;

	@GandalfProperty()
	comments: string;

	@GandalfProperty()
	encounterId: number;

	@GandalfProperty()
	noKnownMedications: boolean;

	@GandalfProperty()
	personId: number;

	@GandalfProperty()
	providerReviewAttested: boolean;

	@GandalfProperty()
	providerReviewDate: Date;

	@GandalfProperty()
	providerReviewedByName: string;

	@GandalfProperty()
	reconciliationPerformed: boolean;

	@GandalfProperty()
	reviewDate: Date;

	@GandalfProperty()
	reviewId: number;

	@GandalfProperty()
	reviewedByName: string;

	@GandalfProperty()
	reviewedByUserId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	subCategory: constants.ReviewHistorySubCategory;

}
