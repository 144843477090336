export enum NavTypes {
	EXPAND,
	ADD,
	OPEN,
}

export const PatientViews = {
	PATIENT_SUMMARY: {
		tabIndex: 0,
		isPorted: false,
		hasLegacy: false,
		url: 'patient-summary',
		podName: 'Patient Summary',
	},
	OPTICAL_SUMMARY: {
		tabIndex : 1,
		isPorted: false,
		hasLegacy: false,
		url: 'optical-summary',
		podName: 'Optical Summary',
	},
	DEMOGRAPHICS: {
		tabIndex : 2,
		isPorted: false,
		hasLegacy: false,
		url: 'demographics',
		podName: 'Demographics',
	},
	FAMILY_CONTACTS: {
		tabIndex : 3,
		isPorted: false,
		hasLegacy: false,
		url: 'family-contacts',
		podName: 'Family/Contacts',
	},
	PRESCRIPTIONS: {
		tabIndex : 4,
		isPorted: false,
		hasLegacy: false,
		url: 'prescription',
		podName: 'Rx',
	},
	EXAM_HISTORY: {
		tabIndex : 5,
		isPorted: false,
		hasLegacy: false,
		url: 'encounters',
		podName: 'Exam History',
	},
	ACCOUNT: {
		tabIndex : 6,
		isPorted: false,
		hasLegacy: false,
		url: 'account',
		podName: 'Account',
	},
	NOTES: {
		tabIndex : 7,
		isPorted: false,
		hasLegacy: true,
		url: 'notes',
		podName: 'Notes',
	},
	INSURANCE: {
		tabIndex : 8,
		isPorted: false,
		hasLegacy: false,
		url: 'insurance',
		podName: 'Insurance',
	},
	DIAGNOSIS_HISTORY: {
		tabIndex : 9,
		isPorted: false,
		hasLegacy: false,
		url: 'diagnosis-history',
		podName: 'Diagnosis History',
	},
	RECALL: {
		tabIndex : 10,
		isPorted: false,
		hasLegacy: false,
		url: 'recall',
		podName: 'Recall',
	},
	ORDERS_MEDICAL: {
		tabIndex : 11,
		isPorted: false,
		hasLegacy: false,
		url: 'orders-medical',
		podName: 'Orders - Medical',
	},
	ORDERS_OPTICAL: {
		tabIndex : 12,
		isPorted: false,
		hasLegacy: false,
		url: 'orders-optical',
		podName: 'Orders - Optical',
	},
	TASKS: {
		tabIndex : 32,
		isPorted: false,
		hasLegacy: false,
		url: 'tasks',
		podName: 'Tasks',
	},
	CONNECT: {
		tabIndex: 29,
		isPorted: false,
		hasLegacy: false,
		url: 'rev-connect',
		podName: 'Connect',
	},
	ALERTS: {
		tabIndex : 13,
		isPorted: false,
		hasLegacy: false,
		url: 'alerts',
		podName: 'Alerts',
	},
	ALLERGIES: {
		tabIndex : 14,
		isPorted: true,
		hasLegacy: false,
		url: 'allergies',
		podName: 'Allergies',
	},
	AMENDMENTS: {
		tabIndex : 15,
		isPorted: false,
		hasLegacy: false,
		url: 'amendments',
		podName: 'Amendments',
	},
	CONSENTS: {
		tabIndex : 16,
		isPorted: false,
		hasLegacy: true,
		url: 'consents',
		podName: 'Consents',
	},
	DOCUMENTS_IMAGES: {
		tabIndex : 17,
		isPorted: false,
		hasLegacy: false,
		url: 'files',
		podName: 'Documents/Images',
	},
	EXTERNAL_DATA: {
		tabIndex : 31,
		isPorted: false,
		hasLegacy: false,
		url: 'external-data',
		podName: 'External Data',
	},
	FAMILY_MEDICAL: {
		tabIndex : 18,
		isPorted: false,
		hasLegacy: false,
		url: 'family-medical-hx',
		podName: 'Family Medical Hx',
	},
	FAMILY_OCULAR: {
		tabIndex : 19,
		isPorted: false,
		hasLegacy: false,
		url: 'family-optical-hx',
		featureFlag: 'module.patients.component.familyOcular',
		podName: 'Family Ocular Hx',
	},
	HEALTH_CONCERNS: {
		tabIndex : 33,
		isPorted: false,
		hasLegacy: false,
		url: 'health-concerns',
		podName: 'Health Concerns',
	},
	HEALTH_GOALS: {
		tabIndex : 34,
		isPorted: false,
		hasLegacy: false,
		url: 'health-goals',
		podName: 'Health Goals',
	},
	IMMUNIZATIONS: {
		tabIndex : 20,
		isPorted: false,
		hasLegacy: false,
		url: 'immunizations',
		podName: 'Immunizations',
	},
	IMPLANTABLE_DEVICES: {
		tabIndex: 30,
		isPorted: false,
		hasLegacy: false,
		url: 'implantable-devices',
		podName: 'Implantable Devices',
	},
	INTERVIEW_HISTORY: {
		tabIndex : 21,
		isPorted: false,
		hasLegacy: false,
		url: 'interview-history',
		podName: 'Interview History',
	},
	ONGOING_CARE: {
		tabIndex : 22,
		isPorted: false,
		hasLegacy: false,
		url: 'ongoing-care',
		podName: 'Ongoing Care',
	},
	PHARMACY: {
		tabIndex : 23,
		isPorted: false,
		hasLegacy: false,
		url: 'pharmacy',
		podName: 'Pharmacy',
	},
	PREFERENCES: {
		tabIndex : 24,
		isPorted: false,
		hasLegacy: true,
		url: 'preferences',
		podName: 'Preferences',
	},
	PROVIDERS: {
		tabIndex : 25,
		isPorted: false,
		hasLegacy: false,
		url: 'providers',
		podName: 'Providers',
	},
	REFERRALS: {
		tabIndex : 26,
		isPorted: false,
		hasLegacy: false,
		url: 'referrals',
		featureFlag: 'module.patients.component.referrals',
		podName: 'Referrals',
	},
	SERVICE_HISTORY: {
		tabIndex : 27,
		isPorted: false,
		hasLegacy: false,
		url: 'service-history',
	},
	SURGERY_HISTORY: {
		tabIndex : 28,
		isPorted: false,
		hasLegacy: false,
		url: 'surgery-history',
		podName: 'Service History',
	},
};

export const PatientViewTabs = {
	RX: {
		EYEGLASS: {
			url: 'prescription/eyeglass',
			detailUrl: 'eyeglass-prescription-detail',
			tabIndex: 1,
			rxPodTabIndex: 0,
		},
		CONTACT_LENS: {
			url: 'prescription/contact-lens',
			detailUrl: 'contact-lens-detail',
			tabIndex: 2,
			rxPodTabIndex: 1,
		},
		MEDICATION: {
			url: 'prescription/medications',
			detailUrl: 'medication-prescription-detail',
			tabIndex: 3,
			rxPodTabIndex: 2,
		},
	},
	FAMILY_CONTACTS: {
		FAMILY_MEMBERS: {
			url: 'family-contacts/family-members',
			detailUrl: 'family-members-detail',
			tabIndex: 1,
			rxPodTabIndex: 0,
		},
		CONTACTS: {
			url: 'family-contacts/contacts',
			detailUrl: 'contacts-detail',
			tabIndex: 2,
			rxPodTabIndex: 1,
		},
	},
};

export const PatientMedicationRoutes = {
	GENERAL: 'general-medication-detail',
	EXTERNAL: 'erx-prescription-detail',
	INTERNAL: 'medication-prescription-detail',
};

export const PatientViewsArray = [
	PatientViews.PATIENT_SUMMARY,
	PatientViews.OPTICAL_SUMMARY,
	PatientViews.DEMOGRAPHICS,
	PatientViews.FAMILY_CONTACTS,
	PatientViews.PRESCRIPTIONS,
	PatientViews.EXAM_HISTORY,
	PatientViews.ACCOUNT,
	PatientViews.NOTES,
	PatientViews.INSURANCE,
	PatientViews.DIAGNOSIS_HISTORY,
	PatientViews.RECALL,
	PatientViews.ORDERS_MEDICAL,
	PatientViews.ORDERS_OPTICAL,
	PatientViews.TASKS,
	PatientViews.CONNECT,
	PatientViews.ALERTS,
	PatientViews.ALLERGIES,
	PatientViews.AMENDMENTS,
	PatientViews.CONSENTS,
	PatientViews.DOCUMENTS_IMAGES,
	PatientViews.EXTERNAL_DATA,
	PatientViews.FAMILY_MEDICAL,
	PatientViews.FAMILY_OCULAR,
	PatientViews.HEALTH_CONCERNS,
	PatientViews.HEALTH_GOALS,
	PatientViews.IMMUNIZATIONS,
	PatientViews.IMPLANTABLE_DEVICES,
	PatientViews.INTERVIEW_HISTORY,
	PatientViews.ONGOING_CARE,
	PatientViews.PHARMACY,
	PatientViews.PREFERENCES,
	PatientViews.PROVIDERS,
	PatientViews.REFERRALS,
	PatientViews.SERVICE_HISTORY,
	PatientViews.SURGERY_HISTORY,
];
