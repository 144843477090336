// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.vendorsandpartners.framesdata.FramesDataSubscriptionResponse */
/* istanbul ignore next */
@GandalfModel
export class FramesDataSubscriptionResponse extends GandalfModelBase {

	@GandalfProperty()
	billingPostalCode: string;

	@GandalfProperty()
	framesDataExpireDate: Date;

	@GandalfProperty()
	framesDataRenewalMessage: string;

	@GandalfProperty()
	framesDataRenewalUrl: string;

	@GandalfProperty()
	framesDataResponseMessage: string;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	internalContactEmployeeId: number;

	@GandalfProperty()
	numberOfLocations: number;

	@GandalfProperty()
	shippingPostalCode: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.FramesDataSubscriptionStatus;

	@GandalfProperty({ propertyType: 'LocalDate' })
	updatedOn: Date;

	@GandalfProperty()
	username: string;

	@GandalfProperty()
	version: number;

}
