// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.person.diagnosis.UpdatePersonDiagnosisLocationAndQualifierRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdatePersonDiagnosisLocationAndQualifierRequest extends GandalfModelBase {

	@GandalfConstantDecorator()
	@GandalfProperty()
	diagnosisLocation: constants.DiagnosisLocation;

	@GandalfConstantDecorator()
	@GandalfProperty()
	diagnosisLocationQualifier: constants.DiagnosisLocationQualifier;

	@GandalfValidator({ notNull: { message: 'Diagnosis is required' } })
	@GandalfProperty({ isRequired: true })
	id: number;

}
