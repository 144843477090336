// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PracticeFamilyHealthHistoryFilterRequest } from './practice-family-health-history-filter-request';

/** See com.rev360.pms.api.controller.admin.dataconfiguration.UpdatePracticeFamilyHealthFiltersRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdatePracticeFamilyHealthFiltersRequest extends GandalfModelBase {

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: '{jakarta.validation.constraints.NotNull.message}' } })
	@GandalfProperty({ isRequired: true })
	conditionType: constants.FamilyHealthHistoryConditionType;

	@GandalfArray(PracticeFamilyHealthHistoryFilterRequest)
	practiceFamilyHealthFilters: PracticeFamilyHealthHistoryFilterRequest[];

}
