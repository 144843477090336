import { PaymentComponent } from '@accounting/invoices/payment/payment.component';
import { TransferCollectionsInvoiceModalComponent } from '@accounting/invoices/transfer-collections-invoice-modal/transfer-collections-invoice-modal.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { AppointmentSelectComponent } from '@accounting/invoices/encounter-appointment-select-modal/appointment-select/appointment-select.component';
import { EncounterAppointmentSelectModalComponent } from '@accounting/invoices/encounter-appointment-select-modal/encounter-appointment-select-modal.component';
import { EncounterSelectComponent } from '@accounting/invoices/encounter-appointment-select-modal/encounter-select/encounter-select.component';
import { ManualProcessPaymentModalComponent } from '@accounting/invoices/manual-process-payment-modal/manual-process-payment-modal.component';
import { OpenEdgeManualCardModalComponent } from '@accounting/invoices/open-edge-manual-card-modal/open-edge-manual-card-modal.component';
import { OpenEdgeOnFileCardModalComponent } from '@accounting/invoices/open-edge-on-file-card-modal/open-edge-on-file-card-modal.component';
import { OpenEdgeReadCardModalComponent } from '@accounting/invoices/open-edge-read-card-modal/open-edge-read-card-modal.component';
/* eslint-disable-next-line max-len */
import { OpenEdgeValidationKeyMismatchModalComponent } from '@accounting/invoices/open-edge-read-card-modal/open-edge-validation-key-mismatch-modal/open-edge-validation-key-mismatch-modal.component';
import { OpenEdgeVoidPaymentModalComponent } from '@accounting/invoices/open-edge-void-payment-modal/open-edge-void-payment-modal.component';
import { PaymentContainerComponent } from '@accounting/invoices/receive-payments/payment-container/payment-container.component';
import { ReadOnlyPaymentComponent } from '@accounting/invoices/receive-payments/read-only-payment/read-only-payment.component';
import { ReceivePaymentsActionsComponent } from '@accounting/invoices/receive-payments/receive-payments-actions/receive-payments-actions.component';
import { ReceivePaymentsModalComponent } from '@accounting/invoices/receive-payments/receive-payments-modal/receive-payments-modal.component';
import { ReceivePaymentsComponent } from '@accounting/invoices/receive-payments/receive-payments/receive-payments.component';
import { EditPaymentModalComponent } from '@accounting/invoices/edit-payment-modal/edit-payment-modal.component';
import { RemittanceAdviceComponent } from '@accounting/invoices/receive-payments/remittance-advice/remittance-advice.component';
import { RemittanceAdvicePrintComponent } from '@accounting/invoices/receive-payments/remittance-advice-print/remittance-advice-print.component';
import { RemittanceAdviceViewComponent } from '@accounting/invoices/receive-payments/remittance-advice-view/remittance-advice-view.component';
import { AddDiscountModalComponent } from './add-discount-modal/add-discount-modal.component';
import { AddInvoiceItemModalComponent } from './add-invoice-item-modal/add-invoice-item-modal.component';
import { AdhocItemModalComponent } from './adhoc-item-modal/adhoc-item-modal.component';
import { AssignEmployeesModalComponent } from './assign-employees-modal/assign-employees-modal.component';
import { DiagnosesModalComponent } from './diagnoses-modal/diagnoses-modal.component';
import { DiscountItemsModalComponent } from './discount-items-modal/discount-items-modal.component';
import { FinanceChargePlanSelectModalComponent } from './finance-charge-plan-select-modal/finance-charge-plan-select-modal.component';
import { GrantCreditModalComponent } from './grant-credit-modal/grant-credit-modal.component';
import { InsuranceSelectModalComponent } from './insurance-select-modal/insurance-select-modal.component';
import { InvoiceDetailsModalComponent } from './invoice-details-modal/invoice-details-modal.component';
import { AdditionalClaimInformationCanadaComponent } from './invoice-details/additional-claim-information/additional-claim-information-canada.component';
import { AdditionalClaimInformationComponent } from './invoice-details/additional-claim-information/additional-claim-information.component';
import { ClaimMessagesModalComponent } from './invoice-details/claims-history/claim-messages-modal/claim-messages-modal.component';
import { ClaimsHistoryComponent } from './invoice-details/claims-history/claims-history.component';
/* eslint-disable-next-line max-len */
import { EnterClaimSubmissionDateModalComponent } from './invoice-details/claims-history/enter-claim-submission-date-modal/enter-claim-submission-date-modal.component';
import { InvoiceDetailsBasicInfoComponent } from './invoice-details/invoice-details-basic-info/invoice-details-basic-info.component';
import { InvoiceDetailsButtonBarComponent } from './invoice-details/invoice-details-button-bar/invoice-details-button-bar.component';
import { InvoiceDateComponent } from './invoice-details/invoice-details-header/invoice-date/invoice-date.component';
import { InvoiceDetailsHeaderComponent } from './invoice-details/invoice-details-header/invoice-details-header.component';
import { InvoiceStatusComponent } from './invoice-details/invoice-details-header/invoice-status/invoice-status.component';
import { InvoiceDetailsItemsTableComponent } from './invoice-details/invoice-details-items-table/invoice-details-items-table.component';
import { InvoiceDetailsComponent } from './invoice-details/invoice-details.component';
import { InvoicePaymentHistoryComponent } from './invoice-details/invoice-payment-history/invoice-payment-history.component';
import { InvoiceStatementHistoryComponent } from './invoice-details/invoice-statement-history/invoice-statement-history.component';
import { InvoiceItemDetailsModalComponent } from './invoice-item-details-modal/invoice-item-details-modal.component';
import { InvoicePaymentTabComponent } from './invoice-payment-tab/invoice-payment-tab.component';
import { InvoiceTabComponent } from './invoice-tab/invoice-tab.component';
import { InvoicesDashboardComponent } from './invoices-dashboard/invoices-dashboard.component';
import { InvoicesComponent } from './invoices.component';
import { NewGuestInvoiceModalComponent } from './new-guest-invoice-modal/new-guest-invoice-modal.component';
import { PatientPortionModalComponent } from './patient-portion-modal/patient-portion-modal.component';
import { PrintInvoiceStatementsModalComponent } from './print-invoice-statements-modal/print-invoice-statements-modal.component';
import { ProviderSelectModalComponent } from './provider-select-modal/provider-select-modal.component';
import { ReceivePaymentsTransferItemsModalComponent } from './receive-payment-transfer-items-modal/receive-payments-transfer-items-modal.component';
import { SelectInvoiceModalComponent } from './select-invoice-modal/select-invoice-modal.component';
import { SplitPaymentModalComponent } from './split-payment-modal/split-payment-modal.component';
import { TransferInvoiceModalComponent } from './transfer-invoice-modal/transfer-invoice-modal.component';
import { TransferItemsModalComponent } from './transfer-items-modal/transfer-items-modal.component';
import { VoidInvoiceCommentModalComponent } from './void-invoice-comment-modal/void-invoice-comment-modal.component';
import { VoidInvoiceWithPaymentWarningModalComponent } from './void-invoice-with-payment-warning-modal/void-invoice-with-payment-warning-modal.component';
import { VoidPaymentWithCommentModalComponent } from './void-payment-with-comment-modal/void-payment-with-comment-modal.component';

@NgModule({
	declarations: [
		AddDiscountModalComponent,
		AddInvoiceItemModalComponent,
		AdhocItemModalComponent,
		AssignEmployeesModalComponent,
		DiagnosesModalComponent,
		DiscountItemsModalComponent,
		EditPaymentModalComponent,
		EncounterSelectComponent,
		EncounterAppointmentSelectModalComponent,
		FinanceChargePlanSelectModalComponent,
		InvoicesComponent,
		InvoicesDashboardComponent,
		InvoiceTabComponent,
		ProviderSelectModalComponent,
		TransferInvoiceModalComponent,
		TransferCollectionsInvoiceModalComponent,
		VoidInvoiceCommentModalComponent,
		VoidInvoiceWithPaymentWarningModalComponent,
		VoidPaymentWithCommentModalComponent,
		OpenEdgeVoidPaymentModalComponent,
		InsuranceSelectModalComponent,
		TransferItemsModalComponent,
		ReceivePaymentsTransferItemsModalComponent,
		GrantCreditModalComponent,
		ReceivePaymentsModalComponent,
		ReceivePaymentsComponent,
		ReadOnlyPaymentComponent,
		PaymentContainerComponent,
		ReceivePaymentsActionsComponent,
		InvoiceItemDetailsModalComponent,
		InvoiceDetailsModalComponent,
		InvoiceDetailsHeaderComponent,
		InvoiceDetailsItemsTableComponent,
		InvoiceDetailsButtonBarComponent,
		InvoiceDetailsBasicInfoComponent,
		InvoiceDetailsComponent,
		AdditionalClaimInformationComponent,
		AdditionalClaimInformationCanadaComponent,
		InvoiceStatementHistoryComponent,
		NewGuestInvoiceModalComponent,
		InvoicePaymentHistoryComponent,
		ClaimsHistoryComponent,
		EnterClaimSubmissionDateModalComponent,
		ClaimMessagesModalComponent,
		PatientPortionModalComponent,
		SplitPaymentModalComponent,
		PrintInvoiceStatementsModalComponent,
		InvoiceStatusComponent,
		InvoicePaymentTabComponent,
		SelectInvoiceModalComponent,
		InvoiceDateComponent,
		PaymentComponent,
		AppointmentSelectComponent,
		OpenEdgeReadCardModalComponent,
		OpenEdgeValidationKeyMismatchModalComponent,
		ManualProcessPaymentModalComponent,
		OpenEdgeManualCardModalComponent,
		OpenEdgeOnFileCardModalComponent,
		RemittanceAdviceComponent,
		RemittanceAdvicePrintComponent,
		RemittanceAdviceViewComponent,
	],
	exports: [
		AddDiscountModalComponent,
		AddInvoiceItemModalComponent,
		AdhocItemModalComponent,
		AssignEmployeesModalComponent,
		DiagnosesModalComponent,
		DiscountItemsModalComponent,
		EncounterAppointmentSelectModalComponent,
		FinanceChargePlanSelectModalComponent,
		ProviderSelectModalComponent,
		TransferInvoiceModalComponent,
		TransferCollectionsInvoiceModalComponent,
		VoidInvoiceCommentModalComponent,
		VoidInvoiceWithPaymentWarningModalComponent,
		VoidPaymentWithCommentModalComponent,
		OpenEdgeVoidPaymentModalComponent,
		InsuranceSelectModalComponent,
		TransferItemsModalComponent,
		ReceivePaymentsTransferItemsModalComponent,
		GrantCreditModalComponent,
		InvoiceItemDetailsModalComponent,
		InvoiceDetailsModalComponent,
		InvoiceDetailsHeaderComponent,
		InvoiceDetailsItemsTableComponent,
		InvoiceDetailsButtonBarComponent,
		InvoiceDetailsBasicInfoComponent,
		InvoicePaymentHistoryComponent,
		InvoiceStatementHistoryComponent,
		NewGuestInvoiceModalComponent,
		EnterClaimSubmissionDateModalComponent,
		ClaimMessagesModalComponent,
		PatientPortionModalComponent,
		SplitPaymentModalComponent,
		SelectInvoiceModalComponent,
		PaymentComponent,
		OpenEdgeReadCardModalComponent,
		OpenEdgeValidationKeyMismatchModalComponent,
		ManualProcessPaymentModalComponent,
		OpenEdgeManualCardModalComponent,
		OpenEdgeOnFileCardModalComponent,
		RemittanceAdviceComponent,
		RemittanceAdvicePrintComponent,
		RemittanceAdviceViewComponent,
	],
	imports: [
		SharedModule,
	],
})
export class InvoicesSharedModule {
}
