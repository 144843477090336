<div class="e-control e-toolbar e-lib e-keyboard">
	<div class="e-toolbar-items e-tbar-pos">
		<div class="e-toolbar-left">
			<ng-content select="[rev-table-action-menu-left]"></ng-content>
		</div>
		<div class="e-toolbar-center">
			<ng-content select="[rev-table-action-menu-center]"></ng-content>
		</div>
		<div class="e-toolbar-right">
			<ng-content select="[rev-table-action-menu-right]"></ng-content>
			@if (table.getRows()?.length && items?.length) {
				<div class="e-toolbar-item e-search-wrapper e-template" aria-disabled="false">
					<button ejs-dropdownbutton [items]="items" iconCss="fa fa-gear" cssClass="e-caret-hide" (select)="itemSelected($event)"
							[attr.data-test-id]="'tableOptionsButton'"></button>
				</div>
			}
		</div>
	</div>
</div>
