import { Injectable } from '@angular/core';
import { SortingService } from 'morgana';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { ToasterService } from '@core/toaster/toaster.service';
import { BetaSystemCode, PreferredPhoneType } from '@gandalf/constants';
import { CreateFramesDataSubscriptionRequest } from '@gandalf/model/create-frames-data-subscription-request';
import { CreatePharmacyRequest } from '@gandalf/model/create-pharmacy-request';
import { CreateSalesRepresentativeRequest } from '@gandalf/model/create-sales-representative-request';
import { CreateThirdPartyAdministratorRequest } from '@gandalf/model/create-third-party-administrator-request';
import { CreateVendorLocationConfigRequest } from '@gandalf/model/create-vendor-location-config-request';
import { CreateVendorRequest } from '@gandalf/model/create-vendor-request';
import { FramesDataSubscriptionResponse } from '@gandalf/model/frames-data-subscription-response';
import { PharmacyBrandResponse } from '@gandalf/model/pharmacy-brand-response';
import { PharmacyResponse } from '@gandalf/model/pharmacy-response';
import { PharmacySummaryResponse } from '@gandalf/model/pharmacy-summary-response';
import { PracticeLocationSummaryResponse } from '@gandalf/model/practice-location-summary-response';
import { SalesRepresentativeResponse } from '@gandalf/model/sales-representative-response';
import { SalesRepresentativesSummaryResponse } from '@gandalf/model/sales-representatives-summary-response';
import { ThirdPartyAdministratorResponse } from '@gandalf/model/third-party-administrator-response';
import { UpdateFramesDataSubscriptionRequest } from '@gandalf/model/update-frames-data-subscription-request';
import { UpdatePharmacyRequest } from '@gandalf/model/update-pharmacy-request';
import { UpdateSalesRepresentativeRequest } from '@gandalf/model/update-sales-representative-request';
import { UpdateThirdPartyAdministratorRequest } from '@gandalf/model/update-third-party-administrator-request';
import { UpdateVendorLocationConfigRequest } from '@gandalf/model/update-vendor-location-config-request';
import { UpdateVendorRequest } from '@gandalf/model/update-vendor-request';
import { VendorLocationConfigResponse } from '@gandalf/model/vendor-location-config-response';
import { VendorResponse } from '@gandalf/model/vendor-response';
import { VendorSearchRequest } from '@gandalf/model/vendor-search-request';
import { VendorSummaryResponse } from '@gandalf/model/vendor-summary-response';
import { VendorsAndPartnersGandalfService } from '@gandalf/services';
import { FormattedSingleLineAddressPipe } from '@shared/pipes/formatted-single-line-address/formatted-single-line-address.pipe';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface VendorSummaryResponseForTable extends VendorSummaryResponse {
	fullAddress?: string;
}

export interface ThirdPartyAdministratorResponseForTable extends ThirdPartyAdministratorResponse {
	fullAddress?: string;
}

export interface PharmacySummaryResponseForTable extends PharmacySummaryResponse {
	fullAddress?: string;
}

export interface VendorResponseWithAddress extends VendorResponse {
	addressLine1: string;
	addressLine2: string;
	city: string;
	stateProvince: string;
	postalCode: string;
	nonStandardPostalCode: string;
}

export interface ThirdPartyAdministratorResponseWithAddress extends ThirdPartyAdministratorResponse {
	addressLine1: string;
	addressLine2: string;
	city: string;
	stateProvince: string;
	postalCode: string;
	nonStandardPostalCode: string;
}

export interface PharmacyResponseWithAddress extends PharmacyResponse {
	addressLine1: string;
	addressLine2: string;
	city: string;
	stateProvince: string;
	postalCode: string;
}

export interface SalesRepresentativeResponseFlattened extends SalesRepresentativeResponse {
	addressLine1: string;
	addressLine2: string;
	city: string;
	stateProvince: string;
	postalCode: string;
	nonStandardPostalCode: string;
	preferredPhoneType: PreferredPhoneType;
	homePhone: string;
	homePhoneExt: string;
	workPhone: string;
	workPhoneExt: string;
	cellPhone: string;
	cellPhoneExt: string;
	pager: string;
	fax: string;
}

@Injectable({
	providedIn: 'root',
})
export class VendorsAndPartnersService {

	constructor(
		public vendorsGandalfService: VendorsAndPartnersGandalfService,
		public formattedAddressPipe: FormattedSingleLineAddressPipe,
		public toasterService: ToasterService,
		public securityManagerService: SecurityManagerService,
	) {

	}

	/*istanbul ignore next*/
	findVendors(search: VendorSearchRequest) {
		return this.vendorsGandalfService.findVendors(search).pipe(
			map(vendors => vendors.map(vendor => this.addFlattenedFullAddress(vendor))),
		);
	}

	/*istanbul ignore next*/
	findAllPharmacies(): Observable<PharmacySummaryResponseForTable[]> {
		return this.vendorsGandalfService.findAllPharmacies().pipe(
			map(pharmacies => pharmacies.map(pharmacy => this.getPharmacyFullAddress(pharmacy))),
		);
	}

	findActiveSmartflowPracticeSuppliers() {
		return this.vendorsGandalfService.findActiveSmartflowPracticeSuppliers();
	}

	getVendorLocationConfigurationsByVendor(vendorId: number): Observable<VendorLocationConfigResponse[]> {
		return this.vendorsGandalfService.findVendorLocationConfigsByVendor(vendorId);
	}

	@ShowSavedSuccessToast()
	createVendorLocationConfig(createRequest: CreateVendorLocationConfigRequest): Observable<VendorLocationConfigResponse> {
		return this.vendorsGandalfService.createVendorLocationConfig(createRequest);
	}

	@ShowSavedSuccessToast()
	updateVendorLocationConfig(updateRequest: UpdateVendorLocationConfigRequest): Observable<VendorLocationConfigResponse> {
		return this.vendorsGandalfService.updateVendorLocationConfig(updateRequest);
	}

	deleteVendorLocationConfig(vendorId: number) {
		return this.vendorsGandalfService.deleteVendorLocationConfig(vendorId);
	}

	findLocationsWithoutVendorConfigsForVendor(vendorId: number): Observable<PracticeLocationSummaryResponse[]> {
		return this.vendorsGandalfService.findLocationsWithoutVendorConfigsForVendor(vendorId);
	}

	/*istanbul ignore next*/
	@ShowSavedSuccessToast()
	deactivateVendor(vendorId: number): Observable<void> {
		return this.vendorsGandalfService.deactivateVendor(vendorId);
	}

	/*istanbul ignore next*/
	@ShowSavedSuccessToast()
	activateVendor(vendorId: number): Observable<void> {
		return this.vendorsGandalfService.activateVendor(vendorId);
	}

	/*istanbul ignore next*/
	@ShowSavedSuccessToast()
	deactivateSalesRepresentative(salesRepresentativeId: number): Observable<void> {
		return this.vendorsGandalfService.deactivateSalesRepresentative(salesRepresentativeId);
	}

	/*istanbul ignore next*/
	@ShowSavedSuccessToast()
	activateSalesRepresentative(salesRepresentativeId: number): Observable<void> {
		return this.vendorsGandalfService.activateSalesRepresentative(salesRepresentativeId);
	}

	/*istanbul ignore next*/
	@ShowSavedSuccessToast()
	deactivateThirdPartyAdministrator(thirdPartyAdministratorId: number): Observable<void> {
		return this.vendorsGandalfService.deactivateThirdPartyAdministrator(thirdPartyAdministratorId);
	}

	/*istanbul ignore next*/
	@ShowSavedSuccessToast()
	activateThirdPartyAdministrator(thirdPartyAdministratorId: number): Observable<void> {
		return this.vendorsGandalfService.activateThirdPartyAdministrator(thirdPartyAdministratorId);
	}

	/*istanbul ignore next*/
	@ShowSavedSuccessToast()
	activatePharmacy(pharmacyId: number): Observable<void> {
		return this.vendorsGandalfService.activatePharmacyById(pharmacyId);
	}

	/*istanbul ignore next*/
	@ShowSavedSuccessToast()
	deactivatePharmacy(pharmacyId: number): Observable<void> {
		return this.vendorsGandalfService.deactivatePharmacyById(pharmacyId);
	}

	/*istanbul ignore next*/
	getVendor(vendorId: number): Observable<VendorResponse> {
		return this.vendorsGandalfService.getVendor(vendorId).pipe(
			map(vendor => this.flattenVendorAddress(vendor)),
		);
	}

	/*istanbul ignore next*/
	getPharmacy(pharmacyId: number): Observable<PharmacyResponseWithAddress> {
		return this.vendorsGandalfService.getPharmacyById(pharmacyId).pipe(
			map(pharmacy => this.flattenPharmacyAddress(pharmacy)),
		);
	}

	findPharmacyBrands(): Observable<PharmacyBrandResponse[]> {
		return this.vendorsGandalfService.findPharmacyBrands();
	}

	/*istanbul ignore next*/
	@ShowSavedSuccessToast()
	updateVendor(request: UpdateVendorRequest): Observable<VendorResponse> {
		return this.vendorsGandalfService.updateVendor(request).pipe(
			map(vendor => this.flattenVendorAddress(vendor)),
		);
	}

	/*istanbul ignore next*/
	@ShowSavedSuccessToast()
	createSalesRepresentative(request: CreateSalesRepresentativeRequest): Observable<void> {
		return this.vendorsGandalfService.createSalesRepresentative(request);
	}

	/*istanbul ignore next*/
	@ShowSavedSuccessToast()
	updateSalesRepresentative(request: UpdateSalesRepresentativeRequest): Observable<void> {
		return this.vendorsGandalfService.updateSalesRepresentative(request);
	}

	/*istanbul ignore next*/
	@ShowSavedSuccessToast()
	createThirdPartyAdministrator(request: CreateThirdPartyAdministratorRequest): Observable<void> {
		return this.vendorsGandalfService.createThirdPartyAdministrator(request);
	}

	/*istanbul ignore next*/
	@ShowSavedSuccessToast()
	updateThirdPartyAdministrator(request: UpdateThirdPartyAdministratorRequest): Observable<void> {
		return this.vendorsGandalfService.updateThirdPartyAdministrator(request);
	}

	/*istanbul ignore next*/
	@ShowSavedSuccessToast()
	updatePharmacy(request: UpdatePharmacyRequest): Observable<void> {
		return this.vendorsGandalfService.updatePharmacy(request);
	}

	/*istanbul ignore next*/
	@ShowSavedSuccessToast()
	createPharmacy(request: CreatePharmacyRequest): Observable<void> {
		return this.vendorsGandalfService.createPharmacy(request);
	}

	/*istanbul ignore next*/
	@ShowSavedSuccessToast()
	createVendor(request: CreateVendorRequest): Observable<VendorResponse> {
		return this.vendorsGandalfService.createVendor(request).pipe(
			map(vendor => this.flattenVendorAddress(vendor)),
		);
	}

	/*istanbul ignore next*/
	findPracticeSalesRepresentatives(): Observable<SalesRepresentativesSummaryResponse[]> {
		return this.vendorsGandalfService.findPracticeSalesRepresentatives().pipe();
	}

	/*istanbul ignore next*/
	getSalesRepresentativeById(id: number): Observable<SalesRepresentativeResponseFlattened> {
		return this.vendorsGandalfService.getSalesRepresentativeById(id).pipe(
			map(salesRepresentative => this.flattenSalesRepresentativeData(salesRepresentative)),
		);
	}

	/*istanbul ignore next*/
	findAllThirdPartyAdministrators(): Observable<ThirdPartyAdministratorResponseForTable[]> {
		return this.vendorsGandalfService.findAllThirdPartyAdministrators().pipe(
			map(thirdPartyAdministrators => thirdPartyAdministrators.map(
				thirdPartyAdministrator => this.getThirdPartyFullAddress(thirdPartyAdministrator))),
		);
	}

	/*istanbul ignore next*/
	getThirdPartyAdministrator(id: number): Observable<ThirdPartyAdministratorResponse> {
		return this.vendorsGandalfService.getThirdPartyAdministratorById(id).pipe(
			map(thirdPartyAdministrator => this.flattenThirdPartyAddress(thirdPartyAdministrator)),
		);
	}

	/*istanbul ignore next*/
	findFramesDataSubscription(): Observable<FramesDataSubscriptionResponse> {
		return this.vendorsGandalfService.findFramesDataSubscription();
	}

	/*istanbul ignore next*/
	@ShowSavedSuccessToast()
	createFramesDataSubscription(request: CreateFramesDataSubscriptionRequest): Observable<FramesDataSubscriptionResponse> {
		return this.vendorsGandalfService.createFramesDataSubscription(request);
	}

	/*istanbul ignore next*/
	@ShowSavedSuccessToast()
	updateFramesDataSubscription(request: UpdateFramesDataSubscriptionRequest): Observable<FramesDataSubscriptionResponse> {
		return this.vendorsGandalfService.updateFramesDataSubscription(request);
	}

	/*istanbul ignore next*/
	@ShowSavedSuccessToast()
	disableFramesDataSubscription(framesDataSubscriptionId: number): Observable<FramesDataSubscriptionResponse> {
		return this.vendorsGandalfService.disableFramesDataSubscription(framesDataSubscriptionId);
	}

	findSmartflowMasterSupplierLocations(smartflowMasterSupplierId: number) {
		return this.vendorsGandalfService.findSmartflowMasterSupplierLocations(smartflowMasterSupplierId).pipe(
			map(locations => SortingService.sortBy(locations, ['name', 'id'], ['asc', 'asc'])),
		);
	}

	addFlattenedFullAddress(vendor: VendorSummaryResponse): VendorSummaryResponseForTable {
		const vendorResponseForTable = vendor as VendorSummaryResponseForTable;
		if (vendorResponseForTable.address) {
			vendorResponseForTable.fullAddress = this.formattedAddressPipe.transform(
				vendorResponseForTable.address,
			);
		}
		return vendorResponseForTable;
	}

	getThirdPartyFullAddress(thirdPartyAdministrator: ThirdPartyAdministratorResponse): ThirdPartyAdministratorResponseForTable {
		const thirdPartyAdministratorResponseForTable = thirdPartyAdministrator as ThirdPartyAdministratorResponseForTable;
		if (thirdPartyAdministratorResponseForTable.address) {
			thirdPartyAdministratorResponseForTable.fullAddress = this.formattedAddressPipe.transform(
				thirdPartyAdministratorResponseForTable.address,
			);
		}
		return thirdPartyAdministratorResponseForTable;
	}

	/*istanbul ignore next*/
	getPharmacyFullAddress(pharmacy: PharmacySummaryResponse): PharmacySummaryResponseForTable {
		const pharmacyResponseForTable = pharmacy as PharmacySummaryResponseForTable;
		if (pharmacyResponseForTable.address) {
			pharmacyResponseForTable.fullAddress = this.formattedAddressPipe.transform(
				pharmacyResponseForTable.address,
			);
		}
		return pharmacyResponseForTable;
	}


	flattenVendorAddress(vendor: VendorResponse): VendorResponseWithAddress {
		const vendorResponse: VendorResponseWithAddress = vendor as VendorResponseWithAddress;
		vendorResponse.addressLine1 = vendor.address.address1;
		vendorResponse.addressLine2 = vendor.address.address2;
		vendorResponse.city = vendor.address.city;
		vendorResponse.stateProvince = vendor.address.stateProvince;
		if (this.securityManagerService.hasBeta(BetaSystemCode.NON_STANDARD_ZIP_CODES)) {
			vendorResponse.nonStandardPostalCode = vendor.address.postalCode;
		} else {
			vendorResponse.postalCode = vendor.address.postalCode;
		}

		return vendorResponse;
	}

	flattenThirdPartyAddress(thirdPartyAdministrator: ThirdPartyAdministratorResponse): ThirdPartyAdministratorResponseWithAddress {
		const thirdPartyResponse: ThirdPartyAdministratorResponseWithAddress =
			thirdPartyAdministrator as ThirdPartyAdministratorResponseWithAddress;
		thirdPartyResponse.addressLine1 = thirdPartyAdministrator.address.address1;
		thirdPartyResponse.addressLine2 = thirdPartyAdministrator.address.address2;
		thirdPartyResponse.city = thirdPartyAdministrator.address.city;
		thirdPartyResponse.stateProvince = thirdPartyAdministrator.address.stateProvince;
		if (this.securityManagerService.hasBeta(BetaSystemCode.NON_STANDARD_ZIP_CODES)) {
			thirdPartyResponse.nonStandardPostalCode = thirdPartyAdministrator.address.postalCode;
		} else {
			thirdPartyResponse.postalCode = thirdPartyAdministrator.address.postalCode;
		}

		return thirdPartyResponse;
	}

	flattenPharmacyAddress(pharmacyResponse: PharmacyResponse): PharmacyResponseWithAddress {
		const pharmacyResponseWithAddress: PharmacyResponseWithAddress =
			pharmacyResponse as PharmacyResponseWithAddress;
		pharmacyResponseWithAddress.addressLine1 = pharmacyResponse.address.address1;
		pharmacyResponseWithAddress.addressLine2 = pharmacyResponse.address.address2;
		pharmacyResponseWithAddress.city = pharmacyResponse.address.city;
		pharmacyResponseWithAddress.stateProvince = pharmacyResponse.address.stateProvince;
		pharmacyResponseWithAddress.postalCode = pharmacyResponse.address.postalCode;
		return pharmacyResponseWithAddress;
	}

	flattenSalesRepresentativeData(salesRepresentativeResponse: SalesRepresentativeResponse): SalesRepresentativeResponseFlattened {
		const salesRepresentativeResponseFlattened: SalesRepresentativeResponseFlattened =
			salesRepresentativeResponse as SalesRepresentativeResponseFlattened;
		salesRepresentativeResponseFlattened.addressLine1 = salesRepresentativeResponse.address.address1;
		salesRepresentativeResponseFlattened.addressLine2 = salesRepresentativeResponse.address.address2;
		salesRepresentativeResponseFlattened.city = salesRepresentativeResponse.address.city;
		salesRepresentativeResponseFlattened.stateProvince = salesRepresentativeResponse.address.stateProvince;
		if (this.securityManagerService.hasBeta(BetaSystemCode.NON_STANDARD_ZIP_CODES)) {
			salesRepresentativeResponseFlattened.nonStandardPostalCode = salesRepresentativeResponse.address.postalCode;
		} else {
			salesRepresentativeResponseFlattened.postalCode = salesRepresentativeResponse.address.postalCode;
		}

		salesRepresentativeResponseFlattened.preferredPhoneType = salesRepresentativeResponse.phoneNumbers.preferredPhoneType;
		salesRepresentativeResponseFlattened.homePhone = salesRepresentativeResponse.phoneNumbers.homePhone;
		salesRepresentativeResponseFlattened.homePhoneExt = salesRepresentativeResponse.phoneNumbers.homePhoneExt;
		salesRepresentativeResponseFlattened.workPhone = salesRepresentativeResponse.phoneNumbers.workPhone;
		salesRepresentativeResponseFlattened.workPhoneExt = salesRepresentativeResponse.phoneNumbers.workPhoneExt;
		salesRepresentativeResponseFlattened.cellPhone = salesRepresentativeResponse.phoneNumbers.cellPhone;
		salesRepresentativeResponseFlattened.cellPhoneExt = salesRepresentativeResponse.phoneNumbers.cellPhoneExt;
		salesRepresentativeResponseFlattened.pager = salesRepresentativeResponse.phoneNumbers.pager;
		salesRepresentativeResponseFlattened.fax = salesRepresentativeResponse.phoneNumbers.fax;

		return salesRepresentativeResponseFlattened;
	}
}
