import { AccountingViewService } from '@accounting/core/accounting/accounting-view-util/accounting-view.service';
import { InvoiceService } from '@accounting/core/accounting/invoice-service/invoice.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicModalRef, ModalConfig, ModalManagerService } from 'morgana';
import { OpenEdgePaymentService } from '@core/open-edge-payment/open-edge-payment.service';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { VoidInvoiceCommentModalComponent } from '../void-invoice-comment-modal/void-invoice-comment-modal.component';

@Component({
	selector: 'pms-void-invoice-with-payment-warning',
	templateUrl: './void-invoice-with-payment-warning-modal.component.html',
	styles: [],
})
export class VoidInvoiceWithPaymentWarningModalComponent implements OnInit {
	@ViewChild('modal')
	modal: DialogComponent;
	invoiceId: number;
	isLoaded = false;
	useOpenEdgeWarning = false;

	constructor(
		public ref: DynamicModalRef,
		public modalConfig: ModalConfig,
		public modalManagerService: ModalManagerService,
		private openEdgePaymentService: OpenEdgePaymentService,
		private invoiceService: InvoiceService,
		private accountingViewService: AccountingViewService,
	) { }

	ngOnInit() {
		this.invoiceId = this.modalConfig.data.invoiceId;
		this.checkForActiveOpenEdgePayments();
	}

	checkForActiveOpenEdgePayments() {
		if (this.accountingViewService.isOpenEdgeEnabledForAnyLocation()) {
			this.openEdgePaymentService.existsActiveOpenEdgePaymentByInvoice(this.invoiceId)
				.subscribe(result => this.handleOpenEdgeResult(result));
		} else {
			this.isLoaded = true;
		}
	}

	handleOpenEdgeResult(result: boolean) {
		this.useOpenEdgeWarning = result;
		this.isLoaded = true;
	}

	/* istanbul ignore next */
	closeModal() {
		this.ref.close(this.modal);
	}

	/**
	 * opens void invoice modal and closes this modal
	 */
	proceedWithVoidInvoice() {
		this.closeModal();
		this.modalManagerService.open(VoidInvoiceCommentModalComponent, { data: {
			invoiceId: this.invoiceId,
		},
		}).onClose.subscribe(() => this.invoiceService.refreshInvoiceDetailsInvoice(this.invoiceId));
	}

}
