import { PropertiesActions, PropertiesActionTypes } from '@app-store/actions/properties.actions';

export type PropertiesStateMap = Map<string, any>;

export const initialState: PropertiesStateMap = new Map();

export function reducer(state = initialState, action: PropertiesActions): PropertiesStateMap {
	switch (action.type) {

		case PropertiesActionTypes.UPDATE_PROPERTY_VALUE: {
			const {key, propertyKey, value} = action.payload;
			const propertiesStateMap = new Map(state);
			const propertiesState = propertiesStateMap.get(key) || {};
			return propertiesStateMap.set(key, {...propertiesState, [propertyKey]: value});
		}

		case PropertiesActionTypes.REMOVE_PROPERTIES: {
			const propertiesStateMap = new Map(state);
			propertiesStateMap.delete(action.payload.key);
			return propertiesStateMap;
		}

		default: {
			return state;
		}
	}
}
