<ejs-dialog #productBrandModal
			cssClass="modal-sm"
			(close)="closeModal(false)">
	<ng-template #header>
		<div class="dlg-template">Add Collection</div>
	</ng-template>
	<ng-template #content>
		<form class="form-horizontal"
			  #componentForm="ngForm"
			  [formGroup]="formGroup"
			  (ngSubmit)="addProductBrandCollection()"
			  class="form-horizontal">
			<div class="row">
				<div class="col-sm-12">
					@if (formGroup.invalid && componentForm.submitted) {
						<gandalf-lib-validation-messages
							[form]="formGroup"
							[requestObj]="request">
						</gandalf-lib-validation-messages>
					}
				</div>
			</div>
			<div class="form-group">
				<label class="col-sm-3 control-label">Name</label>
				<div class="col-sm-9">
					<input type="text" class="e-input" formControlName="name"/>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" (click)="closeModal(false)">Cancel</button>
		<button ejs-button isPrimary="true" type="submit" (click)="submitForm($event)">Save</button>
	</ng-template>
</ejs-dialog>
