import { setMetadataPropertyValue } from '../metadata/gandalf-metadata-util';

/**
 * Metadata key indicating the type stored in an array
 */
export const GANDALF_ARRAY_TYPE_METADATA_KEY = Symbol('GandalfArrayType');

/**
 * Metadata key indicating an array property contains a special type, ie LocalDate or Money
 */
export const GANDALF_ARRAY_TYPE_QUALIFIER_METADATA_KEY = Symbol('GandalfArrayTypeQualifier');

/**
 * Decorator to indicate the type contained within an array.
 *
 * This type information is stored in Gandalf metadata and accessed by other Gandalf utilities.
 *
 * @param type non-primitive class representing the type in the array
 * @param typeQualifier a Gandalf-specific identifier indicating special handling for this type, for example 'LocalDate' or 'Money'.
 */
export function GandalfArray(type?: any, typeQualifier?: string) {
	return (targetObject: object, propertyName: string) => {
		// Store the array's type in metadata
		setMetadataPropertyValue(targetObject, GANDALF_ARRAY_TYPE_METADATA_KEY, propertyName, type);

		if (typeQualifier) {
			// Store the type qualifier in metadata
			setMetadataPropertyValue(targetObject, GANDALF_ARRAY_TYPE_QUALIFIER_METADATA_KEY, propertyName, { name: typeQualifier });
		}
	};
}
