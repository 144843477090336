// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.dataconfiguration.diagnosis.CreatePracticeDiagnosisRequest */
/* istanbul ignore next */
@GandalfModel
export class CreatePracticeDiagnosisRequest extends GandalfModelBase {

	@GandalfProperty()
	carePlanTemplateId: number;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'CodeSet is required' } })
	@GandalfProperty({ isRequired: true })
	codeSet: constants.CodeSet;

	@GandalfValidator({ notNull: { message: 'Diagnosis is required' } })
	@GandalfProperty({ isRequired: true })
	diagnosisId: number;

}
