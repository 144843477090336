// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { UpdateRecurringDefinitionRequest } from './update-recurring-definition-request';

/** See com.rev360.pms.api.controller.schedule.scheduleitem.UpdateRecurringBusyTimeRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateRecurringBusyTimeRequest extends GandalfModelBase {

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Change mode is required' } })
	@GandalfProperty({ isRequired: true })
	changeMode: constants.UpdateMode;

	@GandalfValidator({ sizeString: { message: 'Reason cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	description: string;

	@GandalfProperty()
	displayColor: number;

	@GandalfValidator({ notNull: { message: 'End time is required' } })
	@GandalfProperty({ isRequired: true })
	endDate: Date;

	@GandalfValidator({ notNull: { message: 'Busy time ID is required' } })
	@GandalfProperty({ isRequired: true })
	id: number;

	@GandalfValidator({ notNull: { message: 'Recurring definition is required' } })
	@GandalfProperty({ isRequired: true })
	recurringDefinition: UpdateRecurringDefinitionRequest;

	@GandalfValidator({ notNull: { message: 'Start Time is required' } })
	@GandalfProperty({ isRequired: true })
	startDate: Date;

}
