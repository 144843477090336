@if (displayPagination()) {
	<div class="e-gridpager e-control e-pager e-lib">
		<div class="e-mfirst e-icons e-icon-first" [class.e-disable]="isOnFirstPage()" [class.e-firstpagedisabled]="isOnFirstPage()"
			 [class.e-pager-default]="!isOnFirstPage()" title="Go to first page" (click)="goToFirstPage()" tabindex="-1"></div>
		<div class="e-mprev e-icons e-icon-prev" [class.e-disable]="isOnFirstPage()" [class.e-prevpagedisabled]="isOnFirstPage()"
			 [class.e-pager-default]="!isOnFirstPage()" title="Go to previous page" (click)="goToPreviousPage()" tabindex="-1"></div>
		<div class="e-pagercontainer" role="navigation">
			<div class="e-first e-icons e-icon-first" [class.e-disable]="isOnFirstPage()" [class.e-firstpagedisabled]="isOnFirstPage()"
				 [class.e-pager-default]="!isOnFirstPage()" [class.e-firstpage]="!isOnFirstPage()" (click)="goToFirstPage()" title="Go to first page"
				 tabindex="-1"></div>
			<div class="e-prev e-icons e-icon-prev" [class.e-disable]="isOnFirstPage()" [class.e-prevpagedisabled]="isOnFirstPage()"
				 [class.e-pager-default]="!isOnFirstPage()" [class.e-prevpage]="!isOnFirstPage()" (click)="goToPreviousPage()" title="Go to previous page"
				 tabindex="-1"></div>
			<div class="e-numericcontainer">
				@for (page of visiblePages; track page) {
					<div>
						<a class="e-link e-numericitem e-spacing" [class.e-currentitem]="isOnPage(page - 1)" (click)="goToPage(page - 1)" role="link"
						   tabindex="-1">{{ page }}</a>
					</div>
				}
				<div>@if (!isOnLastPageSet()) {
					<a class="e-link e-np e-spacing e-numericitem e-pager-default" (click)="goToNextPageSet()" title="Go to next pager" role="link"
					   tabindex="-1">...</a>
				}</div>
			</div>
			<div class="e-next e-icons e-icon-next e-nextpage" [class.e-disable]="isOnLastPage()" [class.e-prevpagedisabled]="isOnLastPage()"
				 [class.e-pager-default]="!isOnLastPage()" [class.e-nextpage]="!isOnLastPage()" (click)="goToNextPage()" title="Go to next page"
				 tabindex="-1"></div>
			<div class="e-last e-icons e-icon-last" [class.e-disable]="isOnLastPage()" [class.e-lastpagedisabled]="isOnLastPage()"
				 [class.e-pager-default]="!isOnLastPage()" [class.e-lastpage]="!isOnLastPage()" (click)="goToLastPage()" title="Go to last page"
				 tabindex="-1"></div>
		</div>
		<div class="e-parentmsgbar"><span class="e-pagenomsg text-right">{{ getCurrentPageDisplay() }} of {{ getTotalPages() }} pages </span><span
			class="e-pagecountmsg text-right">({{ getTotalItems() }} items)</span></div>
		<div class="e-mnext e-icons e-icon-next e-nextpage e-pager-default" title="Go to next page" (click)="goToNextPage()" tabindex="-1"></div>
		<div class="e-mlast e-icons e-icon-last e-lastpage e-pager-default" title="Go to last page" (click)="goToLastPage()" tabindex="-1"></div>
		<div class="e-pagesizes">
			<div class="e-pagerdropdown">
				<ejs-dropdownlist
					#dropdown
					[dataSource]="pageOptions"
					[(ngModel)]="itemsPerPage"
					(change)="itemsPerPageChanged($event)">
				</ejs-dropdownlist>
			</div>
			<div class="e-pagerconstant"><span class="e-constant">Items per page</span></div>
		</div>
	</div>
}
