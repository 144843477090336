import { Component, Input } from '@angular/core';
import { DialogUtil, EnumUtil } from 'morgana';
import { _isEmpty, _isNil } from '@core/lodash/lodash';
import { PatientCommunicationService } from '@core/patient-communication/patient-communication.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { ToasterService } from '@core/toaster/toaster.service';
import { ConnectMessageCategory, ConnectMessageMethod } from '@gandalf/constants';
import { AppointmentSidebarResponse } from '@gandalf/model/appointment-sidebar-response';
import { ConnectMessageForPatientAppointmentRequest } from '@gandalf/model/connect-message-for-patient-appointment-request';
import { SendConnectMessageService } from '@core/send-connect-message/send-connect-message.service';

interface IntItemModel {
	text: string;
	id: number;
	iconCss: string;
}

@Component({
	selector: 'pms-appointment-reminder-button',
	templateUrl: './appointment-reminder-button.component.html',
	styles: [],
})
export class AppointmentReminderButtonComponent {

	emailComType: IntItemModel = {
		text: 'Send Email',
		id: ConnectMessageMethod.EMAIL.value,
		iconCss: 'fa fa-envelope',
	};
	textComType: IntItemModel = {
		text: 'Send Text',
		id: ConnectMessageMethod.SMS.value,
		iconCss: 'fa fa-commenting',
	};
	messageTypes: IntItemModel[] = [this.emailComType, this.textComType];

	@Input()
	appointment: AppointmentSidebarResponse;

	constructor(
		public securityManagerService: SecurityManagerService,
		public patientCommunicationService: PatientCommunicationService,
		public toasterService: ToasterService,
		private sendConnectMessageService: SendConnectMessageService,
	) {
	}

	get appointmentStarted() {
		// if there's at least one appointment and an encounter status is present
		return !_isNil(this.appointment.encounterStatus);
	}

	get useDropdown() {
		return this.isEmailable && this.isTextable;
	}

	get isEmailable() {
		return !_isEmpty(this.appointment.patientEmail);
	}

	get isTextable() {
		return (this.securityManagerService.allowConnectAppointmentReminders()
			&& !_isEmpty(this.appointment.patientPhoneNumbers.cellPhone));
	}

	confirmAppointmentMessage($event) {
		this.patientCommunicationService.canSendPatientMessage(
			this.appointment.patientId,
			EnumUtil.findEnumByValue($event.item.id, ConnectMessageMethod),
			ConnectMessageCategory.APPOINTMENT,
		).subscribe((response) => {
			if (response.canSendPatientMessage === false) {
				this.dispatchToasterError(response);
			} else {
				this.dispatchSendMessageModal($event);
			}
		});
	}

	/* istanbul ignore next */
	dispatchToasterError(canSendResponse) {
		this.toasterService.showError({content: canSendResponse.statusMessage, timeOut: 0});
	}

	/* istanbul ignore next */
	dispatchSendMessageModal($event) {
		const confirmDialog = DialogUtil.confirm({
			title: 'Send Message',
			content: `<p>Send a message to this patient?</p>`,
			okButton: {
				click: () => {
					this.onConfirmedSendMessage($event);
					confirmDialog.close();
				},
			},
		});
	}

	onConfirmedSendMessage($event) {
		const request = new ConnectMessageForPatientAppointmentRequest();
		request.appointmentId = this.appointment.scheduleItemId;
		request.patientId = this.appointment.patientId;
		request.method = EnumUtil.findEnumByValue($event.item.id, ConnectMessageMethod);
		this.sendConnectMessageService.sendConnectMessageForPatientAppointment(request).subscribe();
		this.toasterService.showSavedSuccess({content: 'Appointment Reminder Successfully Sent.'});
	}
}
