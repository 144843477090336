import { Directive, HostListener } from '@angular/core';
import { FilteringEventArgs, MultiSelectComponent } from '@syncfusion/ej2-angular-dropdowns';
import { Query } from '@syncfusion/ej2-data';

@Directive({
	/* eslint-disable-next-line @angular-eslint/directive-selector */
	selector: 'ejs-multiselect:not([pmsOverrideMultiselectFiltering])',
})
export class MultiselectFilteringDirective {

	constructor(
		private multiSelectComponent: MultiSelectComponent,
	) { }

	@HostListener('filtering', ['$event'])
	onFiltering(e: FilteringEventArgs) {
		let query: Query = new Query();
		// frame the query based on search string with filter type.
		query = (e.text !== '') ? query.where(this.multiSelectComponent.fields.text, 'contains', e.text, true) : query;
		// pass the filter data source, filter query to updateData method.
		e.updateData(this.multiSelectComponent.dataSource, query);
	}
}
