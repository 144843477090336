import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ValidationMessagesComponent } from './forms/validation-messages/validation-messages.component';

@NgModule({
	declarations: [
		ValidationMessagesComponent,
	],
	exports: [
		ValidationMessagesComponent,
	],
	imports: [
		CommonModule,
	],
	providers: [
	],
})
export class GandalfModule {
}
