// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.vendorsandpartners.vendor.UpdateSalesRepresentativeRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateSalesRepresentativeRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Address line 1 cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	addressLine1: string;

	@GandalfValidator({ sizeString: { message: 'Address line 2 cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	addressLine2: string;

	@GandalfValidator({ sizeString: { message: 'Cell phone cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfValidator({ phoneNumber: { message: 'Cell phone must contain at least 10 digits' } })
	@GandalfProperty()
	cellPhone: string;

	@GandalfValidator({ sizeString: { message: 'Cell phone extension cannot be longer than 10 characters', minLength: 0, maxLength: 10 } })
	@GandalfProperty()
	cellPhoneExt: string;

	@GandalfValidator({ sizeString: { message: 'City cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	city: string;

	@GandalfValidator({ sizeString: { message: 'Comment cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	comment: string;

	@GandalfValidator({ sizeString: { message: 'Company name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	companyName: string;

	@GandalfValidator({ sizeString: { message: 'Credentials cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty()
	credentials: string;

	@GandalfValidator({ email: { message: 'Invalid Email format' } })
	@GandalfValidator({ sizeString: { message: 'Email cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	email: string;

	@GandalfValidator({ sizeString: { message: 'Fax cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfValidator({ phoneNumber: { message: 'Fax must contain at least 10 digits' } })
	@GandalfProperty()
	fax: string;

	@GandalfValidator({ notNull: { message: 'First name is required' } })
	@GandalfValidator({ sizeString: { message: 'First name must be between 1 and 50 characters', minLength: 1, maxLength: 50 } })
	@GandalfProperty({ isRequired: true })
	firstName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	gender: constants.Gender;

	@GandalfValidator({ sizeString: { message: 'Home phone cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfValidator({ phoneNumber: { message: 'Home phone must contain at least 10 digits' } })
	@GandalfProperty()
	homePhone: string;

	@GandalfValidator({ sizeString: { message: 'Home phone extension cannot be longer than 10 characters', minLength: 0, maxLength: 10 } })
	@GandalfProperty()
	homePhoneExt: string;

	@GandalfValidator({ notNull: { message: 'Last name is required' } })
	@GandalfValidator({ sizeString: { message: 'Last name must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ isRequired: true })
	lastName: string;

	@GandalfValidator({ sizeString: { message: 'NickName cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	nickName: string;

	@GandalfValidator({ sizeString: { message: 'Zip code cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty()
	nonStandardPostalCode: string;

	@GandalfValidator({ sizeString: { message: 'Pager cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfValidator({ phoneNumber: { message: 'Pager must contain at least 10 digits' } })
	@GandalfProperty()
	pager: string;

	@GandalfValidator({ postalCode: { message: 'Zip code is invalid' } })
	@GandalfValidator({ sizeString: { message: 'Zip code cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty()
	postalCode: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	preferredPhoneType: constants.PreferredPhoneType;

	@GandalfValidator({ notNull: { message: 'Sales Representative Id is required' } })
	@GandalfProperty({ isRequired: true })
	salesRepresentativeId: number;

	@GandalfValidator({ sizeString: { message: 'State/Province cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	stateProvince: string;

	@GandalfValidator({ sizeString: { message: 'Suffix cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfProperty()
	suffix: string;

	@GandalfProperty()
	titleId: number;

	@GandalfValidator({ sizeString: { message: 'Work phone cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfValidator({ phoneNumber: { message: 'Work phone must contain at least 10 digits' } })
	@GandalfProperty()
	workPhone: string;

	@GandalfValidator({ sizeString: { message: 'Work phone extension cannot be longer than 10 characters', minLength: 0, maxLength: 10 } })
	@GandalfProperty()
	workPhoneExt: string;

}
