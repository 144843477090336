// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.contactlens.KeratometryTestResponse */
/* istanbul ignore next */
@GandalfModel
export class KeratometryTestResponse extends GandalfModelBase {

	@GandalfProperty()
	odHorizontalMeridian: number;

	@GandalfProperty()
	odHorizontalPower: number;

	@GandalfProperty()
	odVerticalMeridian: number;

	@GandalfProperty()
	odVerticalPower: number;

	@GandalfProperty()
	osHorizontalMeridian: number;

	@GandalfProperty()
	osHorizontalPower: number;

	@GandalfProperty()
	osVerticalMeridian: number;

	@GandalfProperty()
	osVerticalPower: number;

}
