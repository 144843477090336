import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { UpdatePatientFileRequest } from '@gandalf/model/update-patient-file-request';
import { UpdatePracticeFileRequest } from '@gandalf/model/update-practice-file-request';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';

export interface AdditionalUpdateFileInfo {
	createdOn: Date;
	createdBy: string;
	fileType: string;
	fileSize: string;
}

@Component({
	selector: 'pms-update-file-modal',
	templateUrl: './update-file-modal.component.html',
	styles: [],
})
export class UpdateFileModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('ngForm')
	ngForm: NgForm;

	formGroup: UntypedFormGroup;
	request: UpdatePatientFileRequest | UpdatePracticeFileRequest;
	additionalInfo: AdditionalUpdateFileInfo;

	dateFormat = DATE_FORMATS.MM_DD_YYYY_H_MM_A;

	constructor(
		public dynamicModalRef: DynamicModalRef,
		public modalConfig: ModalConfig,
		public gandalfFormBuilder: GandalfFormBuilder,
	) {
	}

	ngOnInit() {
		this.parseModalConfig(this.modalConfig.data);
		this.formGroup = this.gandalfFormBuilder.group(this.request);
	}

	parseModalConfig(data: any) {
		this.request = data.request;
		this.additionalInfo = data.additionalInfo;
	}

	updateFile() {
		if (this.formGroup.invalid) {
			return;
		}
		this.closeModal(this.formGroup.value);
	}

	/* istanbul ignore next: closeModal */
	closeModal(result?) {
		this.dynamicModalRef.close(this.modal, result);
	}

	submitForm(event) {
		this.ngForm.onSubmit(event);
	}
}
