import { HierarchiesActions, HierarchiesActionTypes } from '@app-store/actions/hierarchies.actions';

export class HierarchyState {
	children: Set<string>;
}

export type HierarchiesStateMap = Map<string, HierarchyState>;

export const initialState: HierarchiesStateMap = new Map();

export function reducer(state = initialState, action: HierarchiesActions): HierarchiesStateMap {
	switch (action.type) {

		case HierarchiesActionTypes.ADD_STATEFUL_COMPONENT_CHILD: {
			const {key, childKey} = action.payload;
			const hierarchyStateMap = new Map(state);
			const hierarchyState = hierarchyStateMap.get(key) || new HierarchyState();
			const children = hierarchyState.children || new Set<string>();
			return hierarchyStateMap.set(key, {...hierarchyState, children: children.add(childKey)});
		}

		case HierarchiesActionTypes.REMOVE_HIERARCHY_STATE: {
			const hierarchyStateMap = new Map(state);
			hierarchyStateMap.delete(action.payload.key);
			return hierarchyStateMap;
		}

		default: {
			return state;
		}
	}
}
