// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { UpdatePracticeLocationTransferTaxRequest } from './update-practice-location-transfer-tax-request';

/** See com.rev360.pms.api.controller.admin.accounting.UpdatePracticeLocationTransferTaxesRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdatePracticeLocationTransferTaxesRequest extends GandalfModelBase {

	@GandalfProperty()
	locationId: number;

	@GandalfArray(UpdatePracticeLocationTransferTaxRequest)
	transferTaxes: UpdatePracticeLocationTransferTaxRequest[];

}
