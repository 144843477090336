import { NgModule } from '@angular/core';
import {
	ButtonModule as SyncfusionButtonModule,
	CheckBoxModule as SyncfusionCheckBoxModule,
	RadioButtonModule as SyncfusionRadioButtonModule,
	SwitchModule as SyncfusionSwitchModule
} from '@syncfusion/ej2-angular-buttons';
import {
	CalendarModule as SyncfusionCalendarModule,
	DatePickerModule as SyncfusionDatePickerModule,
	TimePickerModule as SyncfusionTimePickerModule
} from '@syncfusion/ej2-angular-calendars';
import {
	CheckBoxSelectionService as SyncfusionCheckboxSelectionService,
	ComboBoxModule as SyncfusionComboBoxModule,
	DropDownListAllModule as SyncfusionDropdownModule,
	ListBoxModule as SyncfusionListBoxModule,
	MultiSelectModule as SyncfusionMultiSelectModule
} from '@syncfusion/ej2-angular-dropdowns';
import {
	FilterService as SyncfusionFilterService,
	GridAllModule as SyncfusionGridModule,
	GroupService as SyncfusionGroupService,
	PagerModule as SyncfusionPagerModule,
	PageService as SyncfusionPageService,
	SelectionService as SyncfusionSelectionService,
	SortService as SyncfusionSortService
} from '@syncfusion/ej2-angular-grids';
import {
	ColorPickerModule as SyncfusionColorPickerModule,
	MaskedTextBoxModule as SyncfusionMaskedTextBoxModule,
	NumericTextBoxModule as SyncfusionNumericTextBoxModule,
	SliderModule as SyncfusionSliderModule,
	TextBoxModule as SyncfusionTextBoxModule,
	UploaderModule as SyncfusionUploaderModule
} from '@syncfusion/ej2-angular-inputs';
import { SplitterModule as SyncfusionSplitterModule } from '@syncfusion/ej2-angular-layouts';
import { ListViewModule as SyncfusionListViewModule } from '@syncfusion/ej2-angular-lists';
import {
	AccordionModule as SyncfusionAccordionModule,
	MenuModule as SyncfusionMenuModule,
	TabModule as SyncfusionTabModule,
	TreeViewModule as SyncfusionTreeViewModule
} from '@syncfusion/ej2-angular-navigations';
import { ToastModule as SyncfusionToastModule } from '@syncfusion/ej2-angular-notifications';
import { DialogModule as SyncfusionDialogModule, TooltipModule as SyncfusionTooltipModule } from '@syncfusion/ej2-angular-popups';
import { RichTextEditorAllModule as SyncfusionRichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { DropDownButtonModule as SyncfusionDropDownButtonModule, SplitButtonModule as SyncfusionSplitButtonModule } from '@syncfusion/ej2-angular-splitbuttons';

@NgModule({
	declarations: [
	],
	imports: [
		SyncfusionButtonModule,
		SyncfusionCheckBoxModule,
		SyncfusionComboBoxModule,
		SyncfusionDropdownModule,
		SyncfusionGridModule,
		SyncfusionCalendarModule,
		SyncfusionDatePickerModule,
		SyncfusionDialogModule,
		SyncfusionSplitButtonModule,
		SyncfusionToastModule,
		SyncfusionTreeViewModule,
		SyncfusionUploaderModule,
		SyncfusionTimePickerModule,
		SyncfusionTabModule,
		SyncfusionSwitchModule,
		SyncfusionSplitterModule,
		SyncfusionSliderModule,
		SyncfusionRadioButtonModule,
		SyncfusionPagerModule,
		SyncfusionNumericTextBoxModule,
		SyncfusionMenuModule,
		SyncfusionAccordionModule,
		SyncfusionDropDownButtonModule,
		SyncfusionTooltipModule,
		SyncfusionTextBoxModule,
		SyncfusionListBoxModule,
		SyncfusionListViewModule,
		SyncfusionMultiSelectModule,
		SyncfusionColorPickerModule,
		SyncfusionMaskedTextBoxModule,
		SyncfusionRichTextEditorAllModule,
	],
	exports: [
		SyncfusionButtonModule,
		SyncfusionCheckBoxModule,
		SyncfusionComboBoxModule,
		SyncfusionDropdownModule,
		SyncfusionGridModule,
		SyncfusionCalendarModule,
		SyncfusionDatePickerModule,
		SyncfusionDialogModule,
		SyncfusionSplitButtonModule,
		SyncfusionToastModule,
		SyncfusionTreeViewModule,
		SyncfusionUploaderModule,
		SyncfusionTimePickerModule,
		SyncfusionTabModule,
		SyncfusionSwitchModule,
		SyncfusionSplitterModule,
		SyncfusionSliderModule,
		SyncfusionRadioButtonModule,
		SyncfusionPagerModule,
		SyncfusionNumericTextBoxModule,
		SyncfusionMenuModule,
		SyncfusionAccordionModule,
		SyncfusionDropDownButtonModule,
		SyncfusionTooltipModule,
		SyncfusionTextBoxModule,
		SyncfusionListBoxModule,
		SyncfusionListViewModule,
		SyncfusionMultiSelectModule,
		SyncfusionColorPickerModule,
		SyncfusionMaskedTextBoxModule,
		SyncfusionRichTextEditorAllModule,
	],
	providers: [
		SyncfusionSelectionService,
		SyncfusionPageService,
		SyncfusionSortService,
		SyncfusionFilterService,
		SyncfusionGroupService,
		SyncfusionCheckboxSelectionService,
	],
})
export class MorganaSyncfusionModule {
}
