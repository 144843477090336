// Please, for the love of my sanity, keep this alphabetized. -mw

export const NUMBER_STEPPER_OPTIONS_TEMPLATE = {
	ADMIN: {
		DYNAMIC_TESTS: {
			NUMBER_STEPPER_PROPERTY: {
				format: '0.00',
				majorStepSize: 1,
				maximum: 1_000_000.00,
				minimum: -1_000_000.00,
				precision: 2,
				stepSize: 0.01,
			},
		},
	},
	EYEGLASS: {
		AXIS: {
			format: '000',
			majorStepSize: 10,
			maximum: 180,
			minimum: 0,
			precision: 0,
			stepSize: 1,
		},
		CYLINDER: {
			format: '+0.00',
			majorStepSize: 1,
			maximum: 20,
			minimum: -20,
			precision: 2,
			stepSize: 0.25,
		},
		HORIZONTAL_PRISM: {
			format: '0.00',
			majorStepSize: 1,
			maximum: 25,
			minimum: 0,
			precision: 2,
			stepSize: 0.25,
		},
		INTERMEDIATE_ADD: {
			format: '0.00',
			majorStepSize: 1,
			maximum: 5,
			minimum: 0,
			precision: 2,
			stepSize: 0.25,
		},
		MEASUREMENT: {
			BASE_CURVE: {
				format: '0.0',
				majorStepSize: 1,
				maximum: 10,
				minimum: 0,
				precision: 1,
				stepSize: 0.1,
			},
			BLANK_SIZE: {
				format: '0',
				majorStepSize: 5,
				maximum: 80,
				minimum: 55,
				precision: 0,
				stepSize: 1,
			},
			MPD_D: {
				format: '0.0',
				majorStepSize: 1,
				maximum: 49.5,
				minimum: 10.0,
				precision: 1,
				stepSize: 0.1,
			},
			MPD_N: {
				format: '0.0',
				majorStepSize: 1,
				maximum: 49.5,
				minimum: 10.0,
				precision: 1,
				stepSize: 0.1,
			},
			DISTANCE_PD: {
				format: '0.0',
				majorStepSize: 1,
				maximum: 99.0,
				minimum: 25.0,
				precision: 1,
				stepSize: 0.1,
			},
			DISTANCE_PD_RX: {
				format: '0.0',
				majorStepSize: 1,
				maximum: 99.0,
				minimum: 25.0,
				precision: 1,
				stepSize: 0.5,
			},
			MONOCULAR_PD_DISTANCE: {
				format: '0.0',
				majorStepSize: 1,
				maximum: 49.5,
				minimum: 10,
				precision: 1,
				stepSize: 0.1,
			},
			MONOCULAR_PD_NEAR: {
				format: '0.0',
				majorStepSize: 1,
				maximum: 49.5,
				minimum: 10,
				precision: 1,
				stepSize: 0.1,
			},
			NEAR_PD: {
				format: '0.0',
				majorStepSize: 1,
				maximum: 99.0,
				minimum: 25.0,
				precision: 1,
				stepSize: 0.1,
			},
			NEAR_PD_RX: {
				format: '0.0',
				majorStepSize: 1,
				maximum: 99.0,
				minimum: 25.0,
				precision: 1,
				stepSize: 0.5,
			},
			OPTICAL_CENTER: {
				format: '0.0',
				majorStepSize: 1,
				maximum: 50,
				minimum: 0,
				precision: 1,
				stepSize: 0.1,
			},
			PANTOSCOPIC_TILT: {
				format: '0.0',
				majorStepSize: 1,
				maximum: 25.0,
				minimum: -5.0,
				precision: 1,
				stepSize: 0.1,
			},
			SEG_HEIGHT: {
				format: '0.0',
				majorStepSize: 4,
				maximum: 40,
				minimum: 0,
				precision: 1,
				stepSize: 0.5,
			},
			VERTEX_DISTANCE: {
				format: '0.0',
				majorStepSize: 1,
				maximum: 25,
				minimum: 5,
				precision: 1,
				stepSize: 0.1,
			},
			WRAP_ANGLE: {
				format: '0.0',
				majorStepSize: 1,
				maximum: 30.0,
				minimum: 0.0,
				precision: 1,
				stepSize: 0.1,
			},
		},
		NEAR_ADD: {
			format: '0.00',
			majorStepSize: 1,
			maximum: 25,
			minimum: 0,
			precision: 2,
			stepSize: 0.25,
		},
		SPHERE: {
			format: '+0.00',
			majorStepSize: 1,
			maximum: 40,
			minimum: -40,
			precision: 2,
			stepSize: 0.25,
		},
		VERTICAL_PRISM: {
			format: '0.00',
			majorStepSize: 1,
			maximum: 25,
			minimum: 0,
			precision: 2,
			stepSize: 0.25,
		},
	},
	HYBRID_CONTACT: {
		ADD_POWER: {
			format: '+0.00',
			majorStepSize: 1,
			maximum: 25,
			minimum: 0,
			precision: 2,
			stepSize: 0.25,
		},
		AXIS: {
			format: '000',
			majorStepSize: 10,
			maximum: 180,
			minimum: 0,
			precision: 0,
			stepSize: 1,
		},
		BASE_CURVE_MM: {
			format: '0.00',
			majorStepSize: 1,
			maximum: 12,
			minimum: 5,
			precision: 1,
			stepSize: 0.05,
		},
		CYLINDER: {
			format: '+0.00',
			majorStepSize: 1,
			maximum: 20,
			minimum: -20,
			precision: 2,
			stepSize: 0.25,
		},
		DIAMETER: {
			format: '0.0',
			majorStepSize: 1,
			maximum: 20,
			minimum: 6,
			precision: 1,
			stepSize: 0.1,
		},
		SPHERE: {
			format: '+0.00',
			majorStepSize: 1,
			maximum: 40,
			minimum: -40,
			precision: 2,
			stepSize: 0.25,
		},
		VAULT: {
			format: '00',
			majorStepSize: 50,
			maximum: 700,
			minimum: 50,
			precision: 0,
			stepSize: 5,
		},
	},
	RGP_CONTACT: {
		ADD_DIAM: {
			format: '0.00',
			majorStepSize: .1,
			maximum: 8.0,
			minimum: 0.5,
			precision: 2,
			stepSize: .05,
		},
		ADD_POWER: {
			format: '+0.00',
			majorStepSize: 1,
			maximum: 25,
			minimum: 0,
			precision: 2,
			stepSize: 0.25,
		},
		AXIS: {
			format: '000',
			majorStepSize: 10,
			maximum: 180,
			minimum: 0,
			precision: 0,
			stepSize: 1,
		},
		BACK_OPT_DIAM: {
			format: '0.0',
			majorStepSize: 1,
			maximum: 10.0,
			minimum: 0,
			precision: 1,
			stepSize: .1,
		},
		BASE_CURVE_MM: {
			format: '0.00',
			majorStepSize: 1,
			maximum: 12,
			minimum: 4,
			precision: 2,
			stepSize: 0.01,
			truncate: true,
		},
		BASE_CURVE_D: {
			format: '0.00',
			majorStepSize: .12,
			maximum: 80,
			minimum: 35,
			precision: 2,
			stepSize: 0.01,
			truncate: true,
		},
		BASE_CURVE2_MM: {
			format: '0.00',
			majorStepSize: 1,
			maximum: 12,
			minimum: 4,
			precision: 2,
			stepSize: 0.01,
		},
		BASE_CURVE2_D: {
			format: '0.00',
			majorStepSize: .12,
			maximum: 80,
			minimum: 35,
			precision: 2,
			stepSize: 0.01,
		},
		CENTER_THICKNESS: {
			format: '0.00',
			majorStepSize: .1,
			maximum: 1.0,
			minimum: 0.01,
			precision: 2,
			stepSize: .01,
		},
		CYLINDER: {
			format: '+0.00',
			majorStepSize: 1,
			maximum: 20,
			minimum: -20,
			precision: 3,
			stepSize: 0.125,
			truncate: true,
		},
		DIAMETER: {
			format: '0.0',
			majorStepSize: 1,
			maximum: 20,
			minimum: 5,
			precision: 1,
			stepSize: 0.1,
		},
		DIST_ZONE: {
			format: '0.0',
			majorStepSize: 1,
			maximum: 10.0,
			minimum: 0,
			precision: 1,
			stepSize: .1,
		},
		EDGE_LIFT_AMOUNT: {
			format: '0.00',
			majorStepSize: 0.1,
			maximum: 10,
			minimum: -10,
			precision: 2,
			stepSize: .01,
		},
		FIRST_CURVE_RADIUS: {
			format: '0.00',
			majorStepSize: .1,
			maximum: 20,
			minimum: 4,
			precision: 2,
			stepSize: .05,
		},
		FIRST_CURVE_WIDTH: {
			format: '0.00',
			majorStepSize: .1,
			maximum: 4,
			minimum: .1,
			precision: 2,
			stepSize: .05,
		},
		FOURTH_CURVE_RADIUS: {
			format: '0.00',
			majorStepSize: .1,
			maximum: 20,
			minimum: 4,
			precision: 2,
			stepSize: .05,
		},
		FOURTH_CURVE_WIDTH: {
			format: '0.00',
			majorStepSize: .1,
			maximum: 4,
			minimum: .1,
			precision: 2,
			stepSize: .05,
		},
		INT_SEG: {
			format: '0.0',
			majorStepSize: 1,
			maximum: 5.0,
			minimum: 0,
			precision: 1,
			stepSize: .1,
		},
		LANDING_ZONE_ANGLE: {
			format: '00',
			majorStepSize: 1,
			maximum: 38,
			minimum: 28,
			precision: 0,
			stepSize: 1,
		},
		OPTIC_ZONE: {
			format: '0.0',
			majorStepSize: 1,
			maximum: 11.5,
			minimum: 1.5,
			precision: 1,
			stepSize: .1,
		},
		PERIPH_CURVE_RADIUS: {
			format: '0.00',
			majorStepSize: .1,
			maximum: 20,
			minimum: 4,
			precision: 2,
			stepSize: .01,
		},
		PERIPH_CURVE_WIDTH: {
			format: '0.00',
			majorStepSize: .1,
			maximum: 4,
			minimum: .1,
			precision: 2,
			stepSize: .01,
		},
		RETURN_ZONE_DIAMETER: {
			format: '000',
			majorStepSize: 25,
			maximum: 675,
			minimum: 450,
			precision: 0,
			stepSize: 25,
		},
		SAG_DEPTH: {
			format: '0.00',
			majorStepSize: .1,
			maximum: 8.0,
			minimum: 2.5,
			precision: 1,
			stepSize: .01,
		},
		SECOND_CURVE_RADIUS: {
			format: '0.00',
			majorStepSize: .1,
			maximum: 20,
			minimum: 4,
			precision: 2,
			stepSize: .05,
		},
		SECOND_CURVE_WIDTH: {
			format: '0.00',
			majorStepSize: .1,
			maximum: 4,
			minimum: .1,
			precision: 2,
			stepSize: .05,
		},
		SECONDARY_CURVE_RADIUS: {
			format: '0.00',
			majorStepSize: .1,
			maximum: 20,
			minimum: 4,
			precision: 2,
			stepSize: .05,
		},
		SECONDARY_CURVE_WIDTH: {
			format: '0.00',
			majorStepSize: .1,
			maximum: 4,
			minimum: .1,
			precision: 2,
			stepSize: .05,
		},
		SEG_HT: {
			format: '0.0',
			majorStepSize: 1,
			maximum: 10,
			minimum: 0,
			precision: 1,
			stepSize: .1,
		},
		SPHERE: {
			format: '+0.00',
			majorStepSize: 1,
			maximum: 50,
			minimum: -50,
			precision: 3,
			stepSize: 0.125,
			truncate: true,
		},
		SPHERE2: {
			format: '+0.00',
			majorStepSize: 1,
			maximum: 50,
			minimum: -50,
			precision: 3,
			stepSize: 0.125,
			truncate: true,
		},
		THIRD_CURVE_RADIUS: {
			format: '0.00',
			majorStepSize: .1,
			maximum: 20,
			minimum: 4,
			precision: 2,
			stepSize: .05,
		},
		THIRD_CURVE_WIDTH: {
			format: '0.00',
			majorStepSize: .1,
			maximum: 4,
			minimum: .1,
			precision: 2,
			stepSize: .05,
		},
		TRUNCATION: {
			format: '0.0',
			majorStepSize: 1,
			maximum: 2,
			minimum: 0,
			precision: 1,
			stepSize: .1,
		},
		VAULT: {
			format: '00',
			majorStepSize: 50,
			maximum: 700,
			minimum: 50,
			precision: 0,
			stepSize: 5,
		},
	},
	SOFT_CONTACT: {
		ADD_POWER: {
			format: '+0.00',
			majorStepSize: 1,
			maximum: 25,
			minimum: 0,
			precision: 2,
			stepSize: 0.25,
		},
		AXIS: {
			format: '000',
			majorStepSize: 10,
			maximum: 180,
			minimum: 0,
			precision: 0,
			stepSize: 1,
		},
		BASE_CURVE: {
			format: '0.00',
			majorStepSize: 1,
			maximum: 12,
			minimum: 5,
			precision: 1,
			stepSize: 0.05,
		},
		CYLINDER: {
			format: '+0.00',
			majorStepSize: 1,
			maximum: 20,
			minimum: -20,
			precision: 2,
			stepSize: 0.25,
		},
		DIAMETER: {
			format: '0.0',
			majorStepSize: 1,
			maximum: 20,
			minimum: 6,
			precision: 1,
			stepSize: 0.1,
		},
		SPHERE: {
			format: '+0.00',
			majorStepSize: 1,
			maximum: 40,
			minimum: -40,
			precision: 2,
			stepSize: 0.25,
		},
	},
	IOP: {
		TARGET_IOP: {
			format: '0',
			majorStepSize: 5,
			maximum: 99,
			minimum: 0,
			precision: 0,
			stepSize: 1,
		},
	},
};
