import { Injectable } from '@angular/core';
import { _find } from '@core/lodash/lodash';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { PatientDefaultSummaryViewPreferenceValues, PreferenceName } from '@gandalf/constants';
import { PatientViews, PatientViewsArray } from '../../patient-views.constant';

@Injectable({
	providedIn: 'root',
})
export class TabHistoryService {

	constructor(
		private securityManagerService: SecurityManagerService,
	) {
	}

	getTabHistory(patientId?: number) {
		if (patientId) {
			return (window as any).tabHistory[patientId];
		} else {
			return (window as any).tabHistory;
		}
	}

	pushTabHistory(patientId: number, tabIndex: number) {
		this.getTabHistory(patientId).push(tabIndex);
	}

	initializeTabHistory() {
		(window as any).tabHistory = {};
	}

	addToTabHistory(patientId: number, tabIndex: number) {
		if (!this.getTabHistory()) {
			this.initializeTabHistory();
		}

		if (!this.getTabHistory(patientId)) {
			this.addDefaultPatientLandingToTabHistory(patientId);
		}

		const patientTabHistory = this.getTabHistory(patientId);

		if (patientTabHistory && patientTabHistory[patientTabHistory.length - 1] !== tabIndex) {
			this.pushTabHistory(patientId, tabIndex);
		}
	}

	addDefaultPatientLandingToTabHistory(patientId: number) {
		const dashboardPreference = this.securityManagerService.preferenceValue(PreferenceName.PATIENTS_DEFAULT_SUMMARY_VIEW.value);

		(window as any).tabHistory[patientId] = [
			dashboardPreference === PatientDefaultSummaryViewPreferenceValues.PATIENT_SUMMARY.value ?
				PatientViews.PATIENT_SUMMARY.tabIndex
				: PatientViews.OPTICAL_SUMMARY.tabIndex,
		];
	}

	getPreviousPatientTab(patientId: number) {
		if (!this.getTabHistory()) {
			this.initializeTabHistory();
		}

		if (!this.getTabHistory(patientId)) {
			return;
		}

		const tabHistory = this.getTabHistory(patientId);

		tabHistory.pop();
		const view: any = _find(PatientViewsArray, {tabIndex: tabHistory[tabHistory.length - 1]});

		return view;
	}
}
