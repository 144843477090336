import { Injectable } from '@angular/core';
import { UpdateStatePropertyPayload } from '@app-store/utils/update-state-property-payload';
import { ConsentsService } from '@core/consents/consents.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GetPatientConsents, PatientConsentsActionTypes, UpdatePatientConsentsProperty } from '@patient-store/actions/consents.actions';
import { PatientConsentsState } from '@patient-store/reducers/consents.reducer';
import { exhaustMap, map } from 'rxjs/operators';

@Injectable()
export class PatientConsentsEffects {

	getPatientConsents = createEffect(() => this.action.pipe(
		ofType<GetPatientConsents>(PatientConsentsActionTypes.GET_PATIENT_CONSENTS),
		exhaustMap(action => this.consentsService.findAllConsentsByPatientId(action.payload.patientId).pipe(
			map(consents => new UpdatePatientConsentsProperty(
				UpdateStatePropertyPayload.build(PatientConsentsState, 'patientConsents', consents, action.payload.patientId)),
			),
		)),
	));

	constructor(
		private action: Actions,
		private consentsService: ConsentsService,
	) {}

}
