import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { UrlService } from '@core/url-util/url.service';

@Component({
	selector: 'pms-logo-print-template',
	templateUrl: './logo-print-template.component.html',
	styles: [],
})
export class LogoPrintTemplateComponent implements OnInit {

	@Input()
	set logoLocationId(value: number) {
		this._logoLocationId = value;
		this.setLogoUrl();
	}

	get logoLocationId() {
		return this._logoLocationId;
	}

	@Output()
	templateReadyForPrint = new EventEmitter<void>();

	practiceLocationLogoUrl: string;
	_logoLocationId: number;

	constructor(
		private urlService: UrlService,
	) {
	}

	ngOnInit(): void {
		this.setLogoUrl();
	}

	setLogoUrl() {
		if (!this.hasLocationId()) {
			return;
		}

		this.practiceLocationLogoUrl = this.urlService.getPracticeLocationLogo(this.logoLocationId);
	}

	onLogoLoaded(_event: Event) {
		setTimeout(() => {
			this.templateReadyForPrint.emit();
		}, 50);
	}

	onLogoLoadingFailed(_event: any) {
		this.templateReadyForPrint.emit();
	}

	hasLocationId() {
		return !_isNil(this.logoLocationId);
	}
}
