// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { TwoWayTextMessageResponse } from './two-way-text-message-response';

/** See com.rev360.pms.api.controller.twowaytext.TwoWayTextConversationResponse */
/* istanbul ignore next */
@GandalfModel
export class TwoWayTextConversationResponse extends GandalfModelBase {

	@GandalfProperty()
	cellPhone: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	classification: constants.ConnectConversationClassification;

	@GandalfProperty()
	connectConversationId: number;

	@GandalfProperty()
	hasPatientMatches: boolean;

	@GandalfProperty()
	isPatientCellPhoneChanged: boolean;

	@GandalfArray(TwoWayTextMessageResponse)
	messages: TwoWayTextMessageResponse[];

	@GandalfConstantDecorator()
	@GandalfProperty()
	patientEligibility: constants.PatientCommunicationEligibility;

	@GandalfProperty()
	patientEligibilityMessage: string;

	@GandalfProperty()
	patientName: PatientNameResponse;

	@GandalfProperty()
	patientPhotoId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	readStatus: constants.ConnectConversationReadStatus;

}
