/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { UpdateStatePropertyPayload } from '@app-store/utils/update-state-property-payload';
import { _cloneDeep, _isNil } from '@core/lodash/lodash';
import { FormMode } from '@shared/constants/form-mode.enum';
import { LegacyStatefulComponentBaseState } from '@shared/decorators/legacy-stateful-component.decorator';

export interface UpdateFormValuePayload<T> {
	objectId?: number;
	value: T;
}

export interface UpdateFormModePayload {
	objectId?: number;
	mode: FormMode;
}

export interface UpdateFormSubmittedPayload {
	objectId?: number;
	submitted: boolean;
}

export interface RemoveFormValuePayload {
	objectId?: number;
}

@Injectable({
	providedIn: 'root',
})
export class LegacyStatefulComponentUtilsService {

	constructor() { }

	/**
	 * Update Form Value
	 * Updates the form value of the Stateful Component state provided.
	 * @param state State to be updated and returned.
	 * @param defaultState Default state object to build and use if the provided state is undefined.
	 * @param payload Payload to use to update the state.
	 */
	static updateFormValue<T extends LegacyStatefulComponentBaseState, U>(state: T, defaultState: new () => T, payload: UpdateFormValuePayload<U>): T;
	static updateFormValue<T extends LegacyStatefulComponentBaseState, U>(state: Map<number, T>, defaultState: new () => T, payload: UpdateFormValuePayload<U>): Map<number, T>;
	static updateFormValue<T extends LegacyStatefulComponentBaseState, U>(state: T | Map<number, T>, defaultState: new () => T, payload: UpdateFormValuePayload<U>) {
		if (state instanceof Map) {
			const stateMap = _cloneDeep(state);
			const objectState = stateMap.get(payload.objectId) || new defaultState();
			stateMap.set(payload.objectId, this.updateFormValueProperty(objectState, payload.value));
			return stateMap;
		} else {
			const objectState = _isNil(state) ? new defaultState() : _cloneDeep(state);
			return this.updateFormValueProperty(objectState, payload.value);
		}
	}

	/**
	 * Update Form Mode
	 * Updates the form mode of the Stateful Component state provided.
	 * @param state State to be updated and returned.
	 * @param defaultState Default state object to build and use if the provided state is undefined.
	 * @param payload Payload to use to update the state.
	 */
	static updateFormMode<T extends LegacyStatefulComponentBaseState>(state: T, defaultState: new () => T, payload: UpdateFormModePayload): T;
	static updateFormMode<T extends LegacyStatefulComponentBaseState>(state: Map<number, T>, defaultState: new () => T, payload: UpdateFormModePayload): Map<number, T>;
	static updateFormMode<T extends LegacyStatefulComponentBaseState>(state: T | Map<number, T>, defaultState: new () => T, payload: UpdateFormModePayload) {
		if (state instanceof Map) {
			const stateMap = _cloneDeep(state);
			const objectState = stateMap.get(payload.objectId) || new defaultState();
			stateMap.set(payload.objectId, this.updateFormModeProperty(objectState, payload.mode));
			return stateMap;
		} else {
			const objectState = _isNil(state) ? new defaultState() : _cloneDeep(state);
			return this.updateFormModeProperty(objectState, payload.mode);
		}
	}

	/**
	 * Update Form Submitted
	 * Updates the form submission status of the Stateful Component State provided.
	 * @param state State to be updated and returned.
	 * @param defaultState Default state object to build and use if the provided state is undefined.
	 * @param payload Payload to use to update the state.
	 */
	static updateFormSubmitted<T extends LegacyStatefulComponentBaseState>(state: T, defaultState: new () => T, payload: UpdateFormSubmittedPayload): T;
	static updateFormSubmitted<T extends LegacyStatefulComponentBaseState>(state: Map<number, T>, defaultState: new () => T, payload: UpdateFormSubmittedPayload): Map<number, T>;
	static updateFormSubmitted<T extends LegacyStatefulComponentBaseState>(state: T | Map<number, T>, defaultState: new () => T, payload: UpdateFormSubmittedPayload) {
		if (state instanceof Map) {
			const stateMap = _cloneDeep(state);
			const objectState = stateMap.get(payload.objectId) || new defaultState();
			stateMap.set(payload.objectId, this.updateFormSubmittedProperty(objectState, payload.submitted));
			return stateMap;
		} else {
			const objectState = _isNil(state) ? new defaultState() : _cloneDeep(state);
			return this.updateFormSubmittedProperty(objectState, payload.submitted);
		}
	}

	/**
	 * Remove Form Value
	 * Removes the form value of the Stateful Component state provided.
	 * @param state State to be updated and returned.
	 * @param payload Payload to use to remove the targeted form value.
	 */
	static removeFormValue<T extends LegacyStatefulComponentBaseState>(state: T): T;
	static removeFormValue<T extends LegacyStatefulComponentBaseState>(state: Map<number, T>, payload: RemoveFormValuePayload): Map<number, T>;
	static removeFormValue<T extends LegacyStatefulComponentBaseState>(state: T | Map<number, T>, payload?: RemoveFormValuePayload) {
		if (state instanceof Map) {
			const stateMap = _cloneDeep(state);
			const objectState = stateMap.get(payload.objectId);
			stateMap.set(payload.objectId, this.updateFormValueProperty(objectState, undefined));
			return stateMap;
		} else {
			return this.updateFormValueProperty(_cloneDeep(state), undefined);
		}
	}

	/**
	 * Update Property
	 * Updates the property corresponding to the key provided with the value provided.
	 * @param state State to be updated and returned
	 * @param defaultState Default state object to build and use if the provided state is undefined.
	 * @param payload Payload to use to update the property.
	 */
	static updateProperty<T>(state: T, defaultState: new () => T, payload: UpdateStatePropertyPayload): T;
	static updateProperty<T>(state: Map<number, T>, defaultState: new () => T, payload: UpdateStatePropertyPayload): Map<number, T>;
	static updateProperty<T>(state: T | Map<number, T>, defaultState: new () => T, payload: UpdateStatePropertyPayload) {
		if (state instanceof Map) {
			const stateMap = new Map<number, T>(state as Iterable<[number, T]>);
			const objectState = stateMap.get(payload.objectId) || new defaultState();
			objectState[payload.key] = payload.value;
			stateMap.set(payload.objectId, objectState);
			return stateMap;
		} else {
			const objectState = _isNil(state) ? new defaultState() : {...state};
			objectState[payload.key] = payload.value;
			return objectState;
		}
	}


	private static updateFormValueProperty<T extends LegacyStatefulComponentBaseState>(state: T, value: any): T {
		state.formState.value = value;
		return state;
	}

	private static updateFormModeProperty<T extends LegacyStatefulComponentBaseState>(state: T, mode: FormMode): T {
		state.formState.mode = mode;
		return state;
	}

	private static updateFormSubmittedProperty<T extends LegacyStatefulComponentBaseState>(state: T, submitted: boolean): T {
		state.formState.submitted = submitted;
		return state;
	}
}
