import { Injectable } from '@angular/core';
import { APP_ROUTING_URL_CONSTANTS } from '../../app-routing.constants';

@Injectable({
	providedIn: 'root',
})
export class RouteBuilderService {

	constructor() { }

	static getOrderRouter(orderId: number) {
		return `${APP_ROUTING_URL_CONSTANTS.ORDERS.url}/order/${orderId}`;
	}

	static getInvoiceRoute(invoiceId: number) {
		return `${APP_ROUTING_URL_CONSTANTS.ACCOUNTING_INVOICES.url}/invoice/${invoiceId}`;
	}

	static getInvoicePaymentRoute(paymentId: number) {
		return `${APP_ROUTING_URL_CONSTANTS.ACCOUNTING_INVOICES.url}/payment/${paymentId}`;
	}

	static getPaymentRoute(paymentId: number) {
		return `${APP_ROUTING_URL_CONSTANTS.ACCOUNTING_PAYMENTS.url}/payment/${paymentId}`;
	}

	static getPatientRoute(patientId: number) {
		return `/patients/patient/${patientId}`;
	}

	static getPatientDemographics(patientId: number) {
		return `/${APP_ROUTING_URL_CONSTANTS.PATIENTS.url}/patient/${patientId}/demographics`;
	}

	static getEncounterRoute(patientId: number, encounterId: number) {
		return `/patients/patient/${patientId}/encounters/encounter/${encounterId}`;
	}

	static getPatientEncounterHistoryRoute(patientId: number) {
		return `/patients/patient/${patientId}/encounters/encounter-history`;
	}

	static getPatientAppointmentHistoryRoute(patientId: number) {
		return `/patients/patient/${patientId}/encounters/appointment-history`;
	}

	static getEncountersAppointmentRoute(patientId: number, appointmentId: number) {
		return `/patients/patient/${patientId}/encounters/appointment/${appointmentId}`;
	}
}
