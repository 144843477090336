import { ITableState } from '@app-store/reducers';
import { InvoiceActions, InvoiceActionTypes } from '@invoices-store/actions/invoice.actions';

export type InvoiceClaimHistoryTableStateMap = Map<number, ITableState>;

export const initialState: InvoiceClaimHistoryTableStateMap = new Map();

export function reducer(state = initialState, action: InvoiceActions): InvoiceClaimHistoryTableStateMap {
	switch (action.type) {

		case InvoiceActionTypes.UPDATE_INVOICE_CLAIM_HISTORY_TABLE_VALUE: {
			const invoiceTableStateMap = new Map(state);
			const invoiceTableState = invoiceTableStateMap.get(action.payload.invoiceId) || {};
			invoiceTableStateMap.set(action.payload.invoiceId, {
				...invoiceTableState,
				tableConfigurationJson: action.payload.value,
			});
			return invoiceTableStateMap;
		}

		case InvoiceActionTypes.REMOVE_INVOICE_CLAIM_HISTORY_TABLE_STATE: {
			const invoiceStateMap = new Map(state);
			invoiceStateMap.delete(action.payload.invoiceId);
			return invoiceStateMap;
		}

		default: {
			return state;
		}
	}
}
