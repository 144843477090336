// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

/** See com.rev360.pms.api.controller.admin.general.location.AdminPracticeLocationAddressResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminPracticeLocationAddressResponse extends GandalfModelBase {

	@GandalfProperty()
	address: AddressResponse;

	@GandalfProperty()
	id: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	locationStatus: constants.LocationStatus;

	@GandalfProperty()
	name: string;

}
