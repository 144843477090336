<div class="panel-feature">
	<button ejs-button iconCss="fa fa-chevron-left"
			(click)="returnToList()">All {{ codeSet }} Diagnoses
	</button>
</div>
<form #diagnosisForm="ngForm" class="form-horizontal" [formGroup]="formGroup">
	<div class="panel-body">
		@if (formGroup.invalid && diagnosisForm.submitted) {
			<gandalf-lib-validation-messages
				[form]="formGroup" [requestObj]="createUpdateRequest">
			</gandalf-lib-validation-messages>
		}
		<div class="row">
			<div class="col-md-6 col-lg-4">
				<div class="form-group">
					<label class="control-label col-sm-4 required">{{ codeSet }}</label>
					<div class="col-sm-8">
						@if (isCreating) {
							<div class="e-input-group">
								@if (!diagnosisData?.code) {
									<input type="text" class="e-input" placeholder="Search Diagnoses"
										   [(ngModel)]="query" [ngModelOptions]="{standalone: true}"
										   (keydown.enter)="openAddDiagnosisModal($event)"
										   [attr.data-test-id]="'adminDiagnosisSearchText'"/>
								}
								@if (diagnosisData?.code) {
									<input [readonly]="true" class="form-control-static e-input"
										   [value]="diagnosisData?.code"/>
								}
								@if (!diagnosisData.code) {
									<span revTooltip tooltipContent="Search"
										  (click)="openAddDiagnosisModal($event)"
										  class="e-input-group-icon fa fa-search"
										  [attr.data-test-id]="'adminDiagnosisSearchButton'">
                  </span>
								}
								@if (showClearButton()) {
									<span revTooltip tooltipContent="Clear"
										  (click)="clearForm()"
										  class="e-input-group-icon fa fa-close">
                  </span>
								}
							</div>
						}
						@if (!isCreating) {
							<p class="form-control-static"> {{ diagnosisData.code }} </p>
						}
					</div>
				</div>
				<div class="form-group">
					<label class="control-label col-sm-4" [attr.data-test-id]="'adminDiagnosisShortDescriptionLabel'">Short Description</label>
					<div class="col-sm-8">
						<p class="form-control-static" [attr.data-test-id]="'adminDiagnosisShortDescriptionValue'"> {{ diagnosisData.shortDescription }} </p>
					</div>
				</div>
				<div class="form-group">
					<label class="control-label col-sm-4" [attr.data-test-id]="'adminDiagnosisLongDescriptionLabel'">Long Description</label>
					<div class="col-sm-8">
						<p class="form-control-static" [attr.data-test-id]="'adminDiagnosisLongDescriptionValue'"> {{ diagnosisData.longDescription }} </p>
					</div>
				</div>
				<div class="form-group">
					<label class="control-label col-sm-4" [attr.data-test-id]="'adminDiagnosisCarePlanLabel'">Care Plan</label>
					<div class="col-sm-8">
						@if (diagnosisData?.code) {
							<ejs-dropdownlist
								[dataSource]="carePlanTemplates"
								formControlName="carePlanTemplateId"
								[showClearButton]="true"
								placeholder="Select Care Plan"
							></ejs-dropdownlist>
						}
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="panel-footer">
		<button ejs-button type="submit" [isPrimary]="true" (click)="saveChanges()">Save
		</button>
		<button ejs-button type="button" iconCss="fa fa-close" (click)="returnToList()">Cancel</button>
	</div>
</form>
