// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.employee.credential.AdminUpdateEmployeeExternalCredentialRequest */
/* istanbul ignore next */
@GandalfModel
export class AdminUpdateEmployeeExternalCredentialRequest extends GandalfModelBase {

	@GandalfLabel('Employee External Credential')
	@GandalfValidator({ notNull: { message: 'Employee External Credential is required' } })
	@GandalfProperty({ isRequired: true })
	employeeExternalCredentialId: number;

	@GandalfLabel('Location(s)')
	@GandalfValidator({ sizeArray: { min: 1, max: 2147483647, message: 'Location(s) is required' } })
	@GandalfValidator({ notNull: { message: 'Location(s) is required' } })
	@GandalfArray(Number)
	locationIds: number[];

	@GandalfLabel('Password')
	@GandalfValidator({ notNull: { message: 'Password is required' } })
	@GandalfValidator({ sizeString: { message: 'Password must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ isRequired: true })
	password: string;

	@GandalfLabel('Login Name')
	@GandalfValidator({ notNull: { message: 'Login Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Login Name must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ isRequired: true })
	userName: string;

}
