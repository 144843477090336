import { throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';

export const LoadingOverlayMethod = () => function(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
	const originalMethod = descriptor.value;

	descriptor.value = function(...args: any[]) {
		target._isLoading = true;
		const returnVal = originalMethod.apply(this, args);
		return returnVal.pipe(
			take(1),
			catchError((err) => {
				target._isLoading = false;
				return throwError(() => err);
			}),
			tap(() => {
				target._isLoading = false;
			}));
	};
};
