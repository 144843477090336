import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { CancelAppointmentRequest } from '@gandalf/model/cancel-appointment-request';
import { BaseComponent } from '@shared/component/base.component';
import { RadioButtonComponent } from '@syncfusion/ej2-angular-buttons';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';
import { takeUntil } from 'rxjs/operators';
import { EventService } from '@core/event/event.service';
import { ScheduleService } from '../../../schedule/schedule-main/schedule.service';

@Component({
	selector: 'pms-cancel-appointment-modal',
	templateUrl: './cancel-appointment-modal.component.html',
	styles: [],
})
export class CancelAppointmentModalComponent extends BaseComponent implements OnInit {
	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('templateForm')
	templateForm: NgForm;

	@ViewChild('cancelRecurringAppointmentsRadioButton')
	cancelRecurringAppointmentsRadioButton: RadioButtonComponent;

	@ViewChild('cancelThisAppointmentOnlyRadioButton')
	cancelThisAppointmentOnlyRadioButton: RadioButtonComponent;

	reason: string;
	componentForm: UntypedFormGroup;
	cancelAppointmentRequest: CancelAppointmentRequest;
	appointmentId: number;
	patientId: number;
	relatedAppointmentId: number;

	constructor(
		public ref: DynamicModalRef,
		private modalConfig: ModalConfig,
		private scheduleService: ScheduleService,
		private gandalfFormBuilder: GandalfFormBuilder,
		private eventService: EventService,
	) {
		super();
	}

	ngOnInit() {
		this.parseConfigData(this.modalConfig.data);
		this.createForm();
	}

	parseConfigData(data: any) {
		this.appointmentId = data.appointmentId;
		this.patientId = data.patientId;
		this.relatedAppointmentId = data.relatedAppointmentId;
	}

	createForm() {
		this.cancelAppointmentRequest = new CancelAppointmentRequest();
		this.cancelAppointmentRequest.appointmentId = this.appointmentId;
		this.cancelAppointmentRequest.patientInitiated = false;
		this.cancelAppointmentRequest.cancelFutureRecurringAppointments = false;
		this.componentForm = this.gandalfFormBuilder.group(this.cancelAppointmentRequest);
	}

	submitForm(event: any) {
		this.templateForm.onSubmit(event);
	}

	/* istanbul ignore next: closeModal */
	closeModal(refreshAppointments?: boolean) {
		this.ref.close(this.modal, refreshAppointments);
	}

	setCancelAppointment() {
		if (this.componentForm.invalid) {
			FormUtilsService.markFormControlsDirty(this.componentForm);
			return;
		}

		if (this.relatedAppointmentId) {
			this.componentForm.get('cancelFutureRecurringAppointments').setValue(this.cancelRecurringAppointmentsRadioButton.checked);
		}

		this.scheduleService.cancelAppointment(this.componentForm.value, this.patientId)
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(() => {
				this.closeModal(true);
				this.recallUpdated();
			});
	}

	setRadioButtonToCancelFutureRelatedAppointments() {
		this.cancelThisAppointmentOnlyRadioButton.checked = false;
		this.cancelRecurringAppointmentsRadioButton.checked = true;
	}

	setRadioButtonToCancelThisAppointmentOnly() {
		this.cancelThisAppointmentOnlyRadioButton.checked = true;
		this.cancelRecurringAppointmentsRadioButton.checked = false;
	}

	recallUpdated() {
		this.eventService.publishUpdatePatientRecalls(this.patientId);
	}
}
