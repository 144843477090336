<div class="form-horizontal" [formGroup]="componentForm">
	<ng-container formGroupName="phoneInformation">
		<div class="form-group">
			<label class="col-sm-3 control-label">Pref.</label>
			<div class="col-sm-9">
				<pms-enum-select-button
						[attr.data-test-id]="'phoneEmailPrefButtons'"
						[enumList]="preferredPhoneTypeOptions"
						formControlName="preferredPhoneType">
				</pms-enum-select-button>
			</div>
		</div>
		<div [attr.data-test-id]="'phoneEmailHomePhoneInput'" class="form-group">
			<label class="col-xs-12 col-sm-3 control-label">Home</label>
			<div class="col-xs-7 col-sm-6 col-md-5">
				<pms-phone-number-input formControlName="homePhone"></pms-phone-number-input>
			</div>
			<div class="col-xs-5 col-sm-3 col-md-4">
				<div class="e-input-group">
					<span class="e-input-group-icon">x</span>
					<input class="e-input" type="text" formControlName="homePhoneExtension" />
				</div>
			</div>
		</div>
		<div [attr.data-test-id]="'phoneEmailWorkPhoneInput'" class="form-group">
			<label class="col-xs-12 col-sm-3 control-label">Work</label>
			<div class="col-xs-7 col-sm-6 col-md-5">
				<pms-phone-number-input formControlName="workPhone"></pms-phone-number-input>
			</div>
			<div class="col-xs-5 col-sm-3 col-md-4">
				<div class="e-input-group">
					<span class="e-input-group-icon">x</span>
					<input class="e-input" type="text" formControlName="workPhoneExtension" />
				</div>
			</div>
		</div>
		<div [attr.data-test-id]="'phoneEmailCellPhoneInput'" class="form-group">
			<label class="col-xs-12 col-sm-3 control-label">Cell</label>
			<div class="col-xs-7 col-sm-6 col-md-5">
				<pms-phone-number-input formControlName="cellPhone"></pms-phone-number-input>
			</div>
			<div class="col-xs-5 col-sm-3 col-md-4">
				<div class="e-input-group">
					<span class="e-input-group-icon">x</span>
					<input class="e-input" type="text" formControlName="cellPhoneExtension" />
				</div>
			</div>
		</div>
		<div [attr.data-test-id]="'phoneEmailPagerInput'" class="form-group">
			<label class="col-sm-3 control-label">Pager</label>
			<div class="col-sm-9">
				<pms-phone-number-input formControlName="pager"></pms-phone-number-input>
			</div>
		</div>
	</ng-container>
	<div [attr.data-test-id]="'phoneEmailEmailInput'" class="form-group">
		<label class="col-sm-3 control-label">Email</label>
		<div class="col-sm-9">
			<input class="e-input margin-bottom-sm" type="text" formControlName="email" />
		</div>
	</div>
</div>
