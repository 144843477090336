/* istanbul ignore file */

import { AlertResponse } from '@gandalf/model/alert-response';
import { PatientAlertActions, PatientAlertActionTypes } from '@patients-store/actions/patient-alert.actions';

export type PatientAlertStateMap = Map<number, PatientAlertState>;

export class PatientAlertState {
	alertsModalShown = false;
	alerts: AlertResponse[];
}

export const initialState: PatientAlertStateMap = new Map();

export function reducer(state = initialState, action: PatientAlertActions): PatientAlertStateMap {
	switch (action.type) {

		case PatientAlertActionTypes.GET_PATIENT_ALERTS_SUCCESS: {
			const patientAlertStateMap = new Map(state);
			const patientAlertState = patientAlertStateMap.get(action.payload.patientId) || new PatientAlertState();
			patientAlertState.alerts = action.payload.alerts;
			patientAlertStateMap.set(action.payload.patientId, patientAlertState);
			return patientAlertStateMap;
		}

		case PatientAlertActionTypes.REMOVE_PATIENT_ALERT: {
			const patientAlertStateMap = new Map(state);
			patientAlertStateMap.delete(action.payload.patientId);
			return patientAlertStateMap;
		}

		case PatientAlertActionTypes.SET_PATIENT_ALERT_MODAL_SHOWN: {
			const patientAlertStateMap = new Map(state);
			const patientAlertState = patientAlertStateMap.get(action.payload.patientId);
			if (patientAlertState) {
				patientAlertState.alertsModalShown = true;
				patientAlertStateMap.set(action.payload.patientId, patientAlertState);
				return patientAlertStateMap;
			} else {
				return state;
			}
		}

		default: {
			return state;
		}
	}
}
