// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { EmployeeNameResponse } from './employee-name-response';

/** See com.rev360.pms.api.controller.admin.solutions.twelve84.Twelve84SubscriptionTableResponse */
/* istanbul ignore next */
@GandalfModel
export class Twelve84SubscriptionTableResponse extends GandalfModelBase {

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	kitReceivedByFirstName: string;

	@GandalfProperty()
	kitReceivedByLastName: string;

	@GandalfProperty()
	kitReceivedDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	kitStatus: constants.TwelveEightyFourKitStatus;

	@GandalfProperty()
	locationContact: EmployeeNameResponse;

	@GandalfProperty()
	practiceLocationId: number;

	@GandalfProperty()
	practiceLocationName: string;

	@GandalfProperty()
	subscribedByFirstName: string;

	@GandalfProperty()
	subscribedByLastName: string;

	@GandalfProperty()
	subscriptionDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	twelve84SubscriptionStatus: constants.TwelveEightyFourSubscriptionStatus;

}
