// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.prescription.ContactLensPrescriptionLookupResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensPrescriptionLookupResponse extends GandalfModelBase {

	@GandalfProperty()
	addDesignationOd: string;

	@GandalfProperty()
	addDesignationOs: string;

	@GandalfProperty()
	addPowerOd: number;

	@GandalfProperty()
	addPowerOs: number;

	@GandalfProperty()
	authorizedBy: string;

	@GandalfProperty()
	axisOd: number;

	@GandalfProperty()
	axisOs: number;

	@GandalfProperty()
	baseCurveOd: number;

	@GandalfProperty()
	baseCurveOs: number;

	@GandalfProperty()
	comments: string;

	@GandalfProperty()
	cylinderOd: number;

	@GandalfProperty()
	cylinderOs: number;

	@GandalfProperty()
	diameterOd: number;

	@GandalfProperty()
	diameterOs: number;

	@GandalfProperty()
	isMonovisionOd: boolean;

	@GandalfProperty()
	isMonovisionOs: boolean;

	@GandalfProperty()
	isTrial: boolean;

	@GandalfProperty()
	modelOd: string;

	@GandalfProperty()
	modelOs: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	overallLensType: constants.ContactLensPrescriptionType;

	@GandalfConstantDecorator()
	@GandalfProperty()
	rxDisplayStatus: constants.RxDisplayStatusLegacy;

	@GandalfProperty()
	rxId: number;

	@GandalfProperty()
	sphereOd: number;

	@GandalfProperty()
	sphereOs: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate: Date;

}
