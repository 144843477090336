// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.healthgoal.UpdatePatientHealthGoalRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdatePatientHealthGoalRequest extends GandalfModelBase {

	@GandalfLabel('Date Reported')
	@GandalfValidator({ notNull: { message: 'Date Reported is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	dateReported: Date;

	@GandalfLabel('Goal')
	@GandalfValidator({ notNull: { message: 'Goal is required' } })
	@GandalfValidator({ sizeString: { message: 'Goal must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ isRequired: true })
	goal: string;

	@GandalfLabel('Inactive Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	inactiveDate: Date;

	@GandalfLabel('Patient Health Goal')
	@GandalfValidator({ notNull: { message: 'Patient Health Goal is required' } })
	@GandalfProperty({ isRequired: true })
	patientHealthGoalId: number;

	@GandalfLabel('Value')
	@GandalfValidator({ sizeString: { message: 'Value cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	value: string;

}
