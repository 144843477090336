<ejs-dialog #modal (close)="closeModal()" cssClass="modal-md" [attr.data-test-id]="'diagnosisDetailsModal'">
	<ng-template #header>
		<div class="dlg-template">Dx Details</div>
	</ng-template>

	<ng-template #content>
		<ejs-tab #tab *ngIf='showTabs' overflowMode="Popup" [animation]="tabAnimation">
			<e-tabitems>
				<e-tabitem>
					<ng-template #headerText>
						<span [attr.data-test-id]="'diagnosisDetailsTab'">Dx Details</span>
					</ng-template>
					<ng-template #content>
						<ng-container *ngTemplateOutlet="diagnosisForm">
						</ng-container>
					</ng-template>
				</e-tabitem>
				<e-tabitem>
					<ng-template #headerText>
						<span [attr.data-test-id]="'diagnosisDetailsNotesTab'">Notes</span>
					</ng-template>
					<ng-template #content>
						<pms-notes [entityType]="notesEntityType" [parentId]="personDiagnosis.id"></pms-notes>
					</ng-template>
				</e-tabitem>
			</e-tabitems>
		</ejs-tab>
		<div *ngIf='!showTabs'>
			<ng-container *ngTemplateOutlet="diagnosisForm">
			</ng-container>
		</div>
	</ng-template>

	<ng-template #footerTemplate>
		<button ejs-button type="button" iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'diagnosisDetailCancelButton'">Cancel</button>
		<button *ngIf='!isModalReadOnly()' ejs-button type="button" [isPrimary]="true"
				(click)="submitForm($event)" [attr.data-test-id]="'diagnosisDetailSaveButton'">Save
		</button>
	</ng-template>
</ejs-dialog>

<ng-template #diagnosisForm>
	<form #diagnosisDetailsForm="ngForm" class="form-horizontal" [formGroup]="componentForm" (ngSubmit)="save()">
		<gandalf-lib-validation-messages *ngIf="componentForm.invalid && diagnosisDetailsForm.submitted"
										 [form]="componentForm"
										 [requestObj]="request"
										 [attr.data-test-id]="'diagnosisDetailValidationMessages'">
		</gandalf-lib-validation-messages>
		<div class="form-group" [attr.data-test-id]="'diagnosisDetailCodeSetFormGroup'">
			<label for="codeSet" class="col-sm-2 control-label required">Code</label>
			<div class="col-sm-10">
				<p class="form-control-static" *ngIf="!isPersonDiagnosisNil()">
					<pms-diagnosis-code [practiceDiagnosis]="personDiagnosis.practiceDiagnosis"></pms-diagnosis-code>
				</p>
				<p class="form-control-static" *ngIf="showParentDiagnosis()">
					<pms-diagnosis-code [practiceDiagnosis]="personDiagnosis.parentDiagnosis.practiceDiagnosis"></pms-diagnosis-code>
					<span>
						<button
								ejs-button
								revTooltip
								tooltipContent="Unmap from {{personDiagnosis.parentDiagnosis.practiceDiagnosis.codeSet.label}}"
								type="button"
								cssClass="margin-left-sm"
								iconCss="fa fa-close"
								(click)="openConfirmUnmapParent(personDiagnosis.parentDiagnosis)"
								[attr.data-test-id]="'diagnosisDetailParentDiagnosisCloseButton'"></button>
					</span>
				</p>
				<p *ngFor="let childDiagnosis of filterChildDiagnosesToShow()" class="form-control-static">
					<pms-diagnosis-code [practiceDiagnosis]="childDiagnosis.practiceDiagnosis"></pms-diagnosis-code>
					<span>
					<button
							ejs-button
							revTooltip
							tooltipContent="Unmap from {{childDiagnosis.practiceDiagnosis.codeSet.label}}"
							type="button"
							cssClass="margin-left-sm"
							iconCss="fa fa-close"
							(click)="openConfirmUnmapChild(childDiagnosis)"
							[attr.data-test-id]="'diagnosisDetailChildDiagnosisCloseButton'"></button>
				</span>
				</p>
				<ejs-checkbox id="needConversion" #checkbox label="Do not convert to ICD-10" (change)="updateNeedConversion()"
							  [checked]="!(personDiagnosis?.needConversion)" *ngIf="this.showConvertFlag()" [attr.data-test-id]="'diagnosisDetailNeedConversionCheckbox'"></ejs-checkbox>
				<pms-enum-select-button
						id="codeSet"
						[enumList]="codeSet"
						[(ngModel)]="selectedCodeSet"
						[ngModelOptions]="{standalone: true}"
                        (valueChange)="processFormChanges(componentForm.value)"
						*ngIf="isPersonDiagnosisNil()"
				></pms-enum-select-button>
			</div>
		</div>
		<div class="form-group" *ngIf="componentForm.contains('description')" [attr.data-test-id]="'diagnosisDetailDescriptionLabelGroup'">
			<label for="description" class="col-sm-2 control-label"></label>
			<div class="col-sm-10">
				<div class="e-input-group">
					<ejs-autocomplete id='description' #searchDiagnosisAutoComplete
									  (created)="focusAutoCompleteComponent()"
									  [dataSource]='diagnoses'
									  suggestionCount="500"
									  formControlName="description"
									  placeholder="Select Diagnosis"
									  [fields]='fields'
									  [minLength]="MIN_LENGTH"
									  (dataBound)="onDataBound()"
									  (filtering)='loadDiagnoses($event)'
									  (select)="updatePracticeDiagnosis($event)"
									  [showClearButton]="false"
									  [highlight]="true"
									  revTooltip
									  [tooltipContent]="componentForm.get('description').value"
									  [tooltipDisabled]="!isDiagnosisSelected() || personDiagnosis"></ejs-autocomplete>
					<span revTooltip
						  *ngIf="!personDiagnosis && !isDiagnosisSelected()"
						  (click)="openSelectDiagnosisModal()"
						  tooltipContent="Select Dx Code"
						  class="e-input-group-icon fa fa-search"
						  [attr.data-test-id]="'selectDiagnosisCodeSearchButton'">
					</span>
					<span revTooltip tooltipContent="Clear"
						  *ngIf="!personDiagnosis && isDiagnosisSelected()"
						  (click)="clearSelectedDiagnosis()"
						  class="e-input-group-icon fa fa-close"
						  [attr.data-test-id]="'selectDiagnosisDetailClearButton'">
					</span>
				</div>
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'diagnosisDetailDxDateLabelGroup'">
			<label for="diagnosisDate" class="col-sm-2 control-label required">Dx Date</label>
			<div class="col-sm-10">
				<p class="form-control-static" *ngIf="!isModalFullyEditable()">
					{{personDiagnosis.diagnosisDate | date:dateFormat}}
				</p>
				<ejs-datepicker id="diagnosisDate"
								placeholder="mm/dd/yyyy"
								formControlName="diagnosisDate"
								*ngIf="isModalFullyEditable()"
								[format]="dateFormat">
				</ejs-datepicker>
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'diagnosisDetailResDateLabelGroup'">
			<label class="col-sm-2 control-label">Res. Date</label>
			<div class="col-sm-10">
				<p class="form-control-static" *ngIf="!isModalFullyEditable()">
					{{personDiagnosis?.originalDiagnosisResolutionDate | date:dateFormat}}
				</p>
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'diagnosisDetailLocationLabelGroup'">
			<label for="location" class="col-sm-2 control-label">Location</label>
			<div class="col-sm-10">
				<p class="form-control-static" *ngIf="!componentForm.contains('diagnosisLocation')">
					<span *ngIf="!isPersonDiagnosisNil()">
						{{personDiagnosis.diagnosisLocation}}
					</span>
					<span *ngIf="isPersonDiagnosisNil()">
						N/A
					</span>
				</p>
				<pms-constant-dropdown
						id="location"
						[constantList]="diagnosisLocation"
						formControlName="diagnosisLocation"
						[showClearButton]="false"
						placeholder="Select Location"
						*ngIf="componentForm.contains('diagnosisLocation')">
				</pms-constant-dropdown>
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'diagnosisDetailQualifierLabelGroup'">
			<label for="qualifier" class="col-sm-2 control-label">Qualifier</label>
			<div class="col-sm-10">
				<p class="form-control-static"
				   *ngIf="!componentForm.contains('diagnosisLocationQualifier')">
					<span *ngIf="personDiagnosis">
						{{personDiagnosis.diagnosisLocationQualifier ? personDiagnosis.diagnosisLocationQualifier : 'N/A'}}
					</span>
					<span *ngIf="!personDiagnosis">
						N/A
					</span>
				</p>
				<pms-constant-dropdown
						id="qualifier"
						[constantList]="diagnosisLocationQualifiers"
						formControlName="diagnosisLocationQualifier"
						[showClearButton]="false"
						placeholder="N/A"
						*ngIf="componentForm.contains('diagnosisLocationQualifier')">
				</pms-constant-dropdown>
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'diagnosisDetailReasonLabelGroup'">
			<label for="reason" class="col-sm-2 control-label">Reason</label>
			<div class="col-sm-10">
				<p class="form-control-static" *ngIf="!componentForm.contains('reason')">
					{{personDiagnosis.reason}}
				</p>
				<ejs-textbox id="reason" formControlName="reason" class="h-rows-5"
							 [multiline]="true" *ngIf="componentForm.contains('reason')"></ejs-textbox>
			</div>
		</div>
	</form>
</ng-template>
