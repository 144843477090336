// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PredictiveInterventionAttributeRequest } from './predictive-intervention-attribute-request';

/** See com.rev360.pms.api.controller.patient.intervention.UpdatePredictiveInterventionRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdatePredictiveInterventionRequest extends GandalfModelBase {

	@GandalfLabel('Attributes')
	@GandalfValidator({ notNull: { message: 'Attributes is required' } })
	@GandalfArray(PredictiveInterventionAttributeRequest)
	attributes: PredictiveInterventionAttributeRequest[];

	@GandalfLabel('Predictive Intervention')
	@GandalfValidator({ notNull: { message: 'Predictive Intervention is required' } })
	@GandalfProperty({ isRequired: true })
	predictiveInterventionId: number;

}
