import { Injectable } from '@angular/core';
import { StatefulTabItem } from '@shared/directives/stateful-tab/stateful-tab-item';
import { StatefulTabType } from '@shared/directives/stateful-tab/stateful-tab-type';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class StatefulTabService {

	private tabSelections = new Subject<StatefulTabItem>();

	constructor() { }

	observeTabSelections(type: StatefulTabType) {
		return this.tabSelections.pipe(filter(tabItem => tabItem.type === type));
	}

	emitTabSelection(tabItem: StatefulTabItem) {
		this.tabSelections.next(tabItem);
	}
}
