import { Component, Input } from '@angular/core';
import { VerificationStatus } from '@gandalf/constants';
import { SCHEDULE_STATUS_ICON_COLORS } from '@shared/constants/schedule-status-icon-colors.constants';

@Component({
	selector: 'pms-insurance-status-icon',
	templateUrl: './insurance-status-icon.component.html',
	styles: [],
})
export class InsuranceStatusIconComponent {

	@Input() status: VerificationStatus;
	@Input() comment: string;

	constructor() {
	}

	getTooltipText(): string {
		let tooltipText = '';
		switch (this.status?.value) {
			case VerificationStatus.VERIFIED_VALID.value:
				tooltipText = 'Insurance Verified Valid';
				break;
			case VerificationStatus.VERIFIED_INVALID.value:
				tooltipText = 'Insurance Verified Not Valid';
				break;
			case VerificationStatus.NOT_VERIFIED.value:
			default:
				tooltipText = 'Insurance Not Verified';
				break;
		}

		if (this.comment) {
			tooltipText += ': ' + this.comment;
		}

		return tooltipText;
	}

	getColorClass(): string {
		switch (this.status?.value) {
			case VerificationStatus.VERIFIED_VALID.value:
				return SCHEDULE_STATUS_ICON_COLORS.GREEN;
			case VerificationStatus.VERIFIED_INVALID.value:
				return SCHEDULE_STATUS_ICON_COLORS.RED;
			case VerificationStatus.NOT_VERIFIED.value:
			default:
				return SCHEDULE_STATUS_ICON_COLORS.YELLOW;
		}
	}
}
