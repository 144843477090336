// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.twowaytext.TwoWayTextMessageResponse */
/* istanbul ignore next */
@GandalfModel
export class TwoWayTextMessageResponse extends GandalfModelBase {

	@GandalfProperty()
	automatedMessageRecipient: PatientNameResponse;

	@GandalfProperty()
	connectConversationMessageId: number;

	@GandalfProperty()
	content: string;

	@GandalfProperty()
	date: Date;

	@GandalfProperty()
	personName: PersonNameResponse;

	@GandalfProperty()
	personPhotoId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	senderType: constants.ConnectConversationSenderType;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.ConnectConversationMessageStatus;

}
