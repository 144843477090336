// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { UpdateOfficeHoursDailyRequest } from './update-office-hours-daily-request';

/** See com.rev360.pms.api.controller.admin.general.location.UpdateOfficeHoursWeeklyRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateOfficeHoursWeeklyRequest extends GandalfModelBase {

	@GandalfSubRequestLabel('Friday')
	@GandalfValidator({ notNull: { message: 'Friday hours are required' } })
	@GandalfProperty({ isRequired: true })
	friday: UpdateOfficeHoursDailyRequest;

	@GandalfSubRequestLabel('Monday')
	@GandalfValidator({ notNull: { message: 'Monday hours are required' } })
	@GandalfProperty({ isRequired: true })
	monday: UpdateOfficeHoursDailyRequest;

	@GandalfSubRequestLabel('Saturday')
	@GandalfValidator({ notNull: { message: 'Saturday hours are required' } })
	@GandalfProperty({ isRequired: true })
	saturday: UpdateOfficeHoursDailyRequest;

	@GandalfSubRequestLabel('Sunday')
	@GandalfValidator({ notNull: { message: 'Sunday hours are required' } })
	@GandalfProperty({ isRequired: true })
	sunday: UpdateOfficeHoursDailyRequest;

	@GandalfSubRequestLabel('Thursday')
	@GandalfValidator({ notNull: { message: 'Thursday hours are required' } })
	@GandalfProperty({ isRequired: true })
	thursday: UpdateOfficeHoursDailyRequest;

	@GandalfSubRequestLabel('Tuesday')
	@GandalfValidator({ notNull: { message: 'Tuesday hours are required' } })
	@GandalfProperty({ isRequired: true })
	tuesday: UpdateOfficeHoursDailyRequest;

	@GandalfSubRequestLabel('Wednesday')
	@GandalfValidator({ notNull: { message: 'Wednesday hours are required' } })
	@GandalfProperty({ isRequired: true })
	wednesday: UpdateOfficeHoursDailyRequest;

}
