// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.accounting.StatementPayerResponse */
/* istanbul ignore next */
@GandalfModel
export class StatementPayerResponse extends GandalfModelBase {

	@GandalfProperty()
	dueDate: string;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	message: string;

	@GandalfProperty({ propertyType: 'Money' })
	payerBalance: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	printDate: Date;

}
