import { Injectable } from '@angular/core';
import { LicenseGandalfService } from '@gandalf/services';

@Injectable({
	providedIn: 'root',
})
export class LicenseService {

	constructor(
		public licenseGandalfService: LicenseGandalfService,
	) {
	}

	/*istanbul ignore next: gandalf*/
	getAgreementDetails() {
		return this.licenseGandalfService.getAgreementDetails();
	}
}
