import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { HIT_PMS_HTML_EVENTS } from '@core/legacy/hit-pms-html.constants';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { NotesService } from '@core/notes/notes.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { SECURITY_CONSTANTS } from '@core/security/security.constants';
import { EntityType } from '@gandalf/constants';
import { CreateNoteRequest } from '@gandalf/model/create-note-request';
import { NoteResponse } from '@gandalf/model/note-response';
import { UpdateNoteRequest } from '@gandalf/model/update-note-request';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';

@Component({
	selector: 'pms-edit-note-component',
	templateUrl: './edit-note-modal.component.html',
	styles: [],
})
export class EditNoteModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('templateForm')
	templateForm: NgForm;

	entityType: EntityType;
	parentId: number;
	note: NoteResponse;

	componentForm: UntypedFormGroup;
	modalTitle: string;
	noteRequest: UpdateNoteRequest | CreateNoteRequest;
	cancelButtonText: string;

	get isCreating() {
		return !this.note;
	}

	constructor(
		private dynamicModalRef: DynamicModalRef,
		private gandalfFormBuilder: GandalfFormBuilder,
		private config: ModalConfig,
		private notesService: NotesService,
		private securityManager: SecurityManagerService,
	) { }

	ngOnInit() {
		this.parseConfigData(this.config.data);
		this.createForm();
	}

	parseConfigData(data: any) {
		this.entityType = data.entityType;
		this.parentId = data.parentId;
		this.note = data.note;
		this.modalTitle = data.modalTitle;
	}

	createForm() {
		if (!this.isCreating) {
			const updateNoteRequest = new UpdateNoteRequest();
			updateNoteRequest.id = this.note.id;
			updateNoteRequest.version = this.note.version;
			updateNoteRequest.text = this.note.text;
			this.noteRequest = updateNoteRequest;
		} else {
			const createNoteRequest = new CreateNoteRequest();
			createNoteRequest.entityType = this.entityType;
			createNoteRequest.parentId = this.parentId;
			this.noteRequest = createNoteRequest;
		}
		this.componentForm = this.gandalfFormBuilder.group(this.noteRequest);

		this.cancelButtonText = this.isNoteEditable() ? 'Cancel' : 'Close';
	}

	/* istanbul ignore next: closeModal */
	closeDialog(refreshGrid: boolean = false) {
		this.dynamicModalRef.close(this.modal, refreshGrid);
	}

	saveNote() {
		if (!this.componentForm.valid) {
			return;
		}

		if (!this.isCreating) {
			this.notesService.updateNote(this.componentForm.value).subscribe(() => {
				this.closeDialog(true);
			});
		} else {
			this.notesService.createNote(this.componentForm.value).subscribe(() => {
				this.closeDialog(true);
			});
		}
	}

	isNoteEditable() {
		if (this.isCreating) {
			return true;
		}

		return this.note.createdBy !== HIT_PMS_HTML_EVENTS.NOTES.SYSTEM_USER &&
			this.note.editable &&
			(this.note.createdBy === this.securityManager.getUserSession().user.userName ||
				this.securityManager.hasPermission(SECURITY_CONSTANTS.RESOURCE_PATIENT_NOTE_OVERRIDE));
	}
}
