import { Injectable } from '@angular/core';
import { SortingService } from 'morgana';
import { EmployeeStatus } from '@gandalf/constants';
import { EmployeeResponse } from '@gandalf/model/employee-response';
import { EmployeeWithSecurityRolesResponse } from '@gandalf/model/employee-with-security-roles-response';
import { PracticeLocationSummaryResponse } from '@gandalf/model/practice-location-summary-response';
import { EmployeeGandalfService } from '@gandalf/services';
import { EmployeeNamePipe } from '@shared/pipes/employee-dropdown-name/employee-name.pipe';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class EmployeeDropdownResponse extends EmployeeResponse {
	label: string;
	value: any;

	constructor() {
		super();
	}
}

export class PracticeLocationDropdownResponse extends PracticeLocationSummaryResponse {
	label: string;
	value: any;
	openEdgeIsActive: boolean;

	constructor() {
		super();
	}
}

export class EmployeeWithRoles extends EmployeeWithSecurityRolesResponse {
	label: string;
	isSelected: boolean;

	constructor() {
		super();
	}
}

export interface EmployeeWithRolesDropdownResponse extends EmployeeWithSecurityRolesResponse {
	label: string;
	value: any;
}

@Injectable({
	providedIn: 'root',
})
export class EmployeeService {

	constructor(
		private employeeGandalfService: EmployeeGandalfService,
		private employeeNamePipe: EmployeeNamePipe,
	) {
	}

	/**
	 * Sorts employees by status, lastName, firstName, id (Tiebreaker)
	 */
	static sortEmployeesForDropdown(
		employeeA: EmployeeResponse | EmployeeWithSecurityRolesResponse,
		employeeB: EmployeeResponse | EmployeeWithSecurityRolesResponse,
	) {
		// Status sort
		if (employeeA.status === EmployeeStatus.ACTIVE && employeeB.status === EmployeeStatus.INACTIVE) {
			return -1;
		}
		if (employeeA.status === EmployeeStatus.INACTIVE && employeeB.status === EmployeeStatus.ACTIVE) {
			return 1;
		}

		// lastName sort
		const lastNameSort = employeeA.lastName.localeCompare(employeeB.lastName);
		if (lastNameSort !== 0) {
			return lastNameSort;
		}

		// firstName sort
		const firstNameSort = employeeA.firstName.localeCompare(employeeB.firstName);
		if (firstNameSort !== 0) {
			return firstNameSort;
		}

		// Id sort
		return employeeA.employeeId - employeeB.employeeId;
	}

	findPracticeEmployeesForDropdown() {
		return this.employeeGandalfService.findPracticeEmployees().pipe(
			map(employees => employees.sort(EmployeeService.sortEmployeesForDropdown)),
			map(employees => employees.map(employee => this.formatEmployeesForDropdown(employee))),
		);
	}

	findActiveEmployeesForDropdown(): Observable<EmployeeDropdownResponse[]> {
		return this.employeeGandalfService.findActiveEmployees().pipe(
			map(employees => employees.sort(EmployeeService.sortEmployeesForDropdown)),
			map(employees => employees.map(employee => this.formatEmployeesForDropdown(employee))),
		);
	}

	findActiveEmployeesWithPersonIdForDropdown(): Observable<EmployeeDropdownResponse[]> {
		return this.employeeGandalfService.findActiveEmployees().pipe(
			map(employees => employees.sort(EmployeeService.sortEmployeesForDropdown)),
			map(employees => employees.map(employee => this.formatEmployeesWithPersonIdForDropdown(employee))),
		);
	}

	findActiveEmployeesWithSecurityRolesByPractice(): Observable<EmployeeWithRolesDropdownResponse[]> {
		return this.employeeGandalfService.findActiveEmployeesWithSecurityRolesByPractice().pipe(
			map(employees => employees.sort(EmployeeService.sortEmployeesForDropdown)),
			map(employees => employees.map(employee => this.formatEmployeesWithPersonIdValueForDropdown(employee))),
		);
	}

	findEmployeesWithUserRoles(practiceLocationId: number) {
		return this.employeeGandalfService.findActiveEmployeesWithSecurityRolesByPracticeLocationId(practiceLocationId).pipe(
			map(employees => employees.map(employee => this.formatEmployeesWithSecurityRolesForSelection(employee))),
			map(employees => SortingService.sortBy(employees, ['lastName', 'firstName', 'employeeId'])),
		);
	}

	findEmployeesWithUserRolesForDropdown(practiceLocationId: number) {
		return this.employeeGandalfService.findActiveEmployeesWithSecurityRolesByPracticeLocationId(practiceLocationId).pipe(
			map(employees => employees.map(employee => this.formatEmployeesWithSecurityRolesForDropdown(employee))),
		);
	}

	formatEmployeesForDropdown(employee: EmployeeResponse): EmployeeDropdownResponse {
		const formattedEmployee = employee as EmployeeDropdownResponse;
		formattedEmployee.label = this.employeeNamePipe.transform(employee, true);
		formattedEmployee.value = employee.employeeId;

		return formattedEmployee;
	}

	formatEmployeesWithPersonIdForDropdown(employee: EmployeeResponse): EmployeeDropdownResponse {
		const formattedEmployee = employee as EmployeeDropdownResponse;
		formattedEmployee.label = this.employeeNamePipe.transform(employee, true);
		formattedEmployee.value = employee.personId;

		return formattedEmployee;
	}

	formatEmployeesWithSecurityRolesForSelection(employee: EmployeeWithSecurityRolesResponse): EmployeeWithRoles {
		const formattedEmployee = employee as EmployeeWithRoles;
		formattedEmployee.label = this.employeeNamePipe.transform(employee, true);
		formattedEmployee.isSelected = false;
		return formattedEmployee;
	}

	formatEmployeesWithSecurityRolesForDropdown(employee: EmployeeWithSecurityRolesResponse): EmployeeWithRolesDropdownResponse {
		const formattedEmployee = employee as EmployeeWithRolesDropdownResponse;
		formattedEmployee.label = this.employeeNamePipe.transform(employee, true);
		formattedEmployee.value = employee.employeeId;

		return formattedEmployee;
	}

	formatEmployeesWithPersonIdValueForDropdown(employee: EmployeeWithSecurityRolesResponse): EmployeeWithRolesDropdownResponse {
		const formattedEmployee = employee as EmployeeWithRolesDropdownResponse;
		formattedEmployee.label = this.employeeNamePipe.transform(employee, true);
		formattedEmployee.value = employee.personId;

		return formattedEmployee;
	}

	getLoggedInEmployeeWithSecurityRoles() {
		return this.employeeGandalfService.getLoggedInEmployeeWithSecurityRoles();
	}

}
