import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { _isEmpty, _isNil } from '@core/lodash/lodash';
import { EnumUtil, ModalManagerService } from 'morgana';
import { NavigationService } from '@core/navigation/navigation.service';
import { PatientService } from '@core/patient/patient.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { SECURITY_CONSTANTS } from '@core/security/security.constants';
import { InvoiceResponse } from '@gandalf/model/invoice-response';
import { UpdateInvoiceLocationRequest } from '@gandalf/model/update-invoice-location-request';
import { PayerType } from '@gandalf/constants';
import { AlertResponse } from '@gandalf/model/alert-response';
import { PatientNameResponse } from '@gandalf/model/patient-name-response';
import { AlertsModalComponent } from '@shared/component/alerts/alerts-modal.component';
import { AlertsService } from '@shared/component/alerts/alerts.service';
import { BaseComponent } from '@shared/component/base.component';
import { LocationSelectModalComponent } from '@shared/component/location-select-modal/location-select-modal.component';
import { ALERTS_MODAL_CONSTANTS } from '@shared/constants/alerts-modal.constants';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountingViewService } from '../../../core/accounting/accounting-view-util/accounting-view.service';
import { AccountingService } from '../../../core/accounting/accounting.service';
import { InvoiceService } from '../../../core/accounting/invoice-service/invoice.service';
import { ProviderSelectModalComponent } from '../../provider-select-modal/provider-select-modal.component';

@Component({
	selector: 'pms-invoice-details-header',
	templateUrl: './invoice-details-header.component.html',
	styles: [],
	providers: [
		ModalManagerService,
	],
})
export class InvoiceDetailsHeaderComponent extends BaseComponent implements OnInit {

	@Input()
	invoice: InvoiceResponse;

	@Input()
	isReadOnly: boolean;

	@Input()
	isPaymentProcessing: boolean;

	@Output()
	returnEvent = new EventEmitter<any>();

	patientName: PatientNameResponse;
	patientAlerts: AlertResponse[];
	firstSortedActiveAlert: AlertResponse;
	dateFormat = DATE_FORMATS.MM_DD_YYYY;

	constructor(
		private patientService: PatientService,
		public modalManagerService: ModalManagerService,
		private navigationService: NavigationService,
		private securityManagerService: SecurityManagerService,
		private accountingService: AccountingService,
		private invoiceService: InvoiceService,
		private accountingViewService: AccountingViewService,
	) {
		super();
	}

	ngOnInit() {
		this.populateHeader();
	}

	populateHeader() {
		if (!this.isGuestInvoice()) {
			combineLatest([
				this.patientService.getAlertsByPatientId(this.invoice.patientId),
				this.patientService.getPatientNameById(this.invoice.patientId),
			]).subscribe(([alerts, patientName]) => {
				this.handlePatientAlerts(AlertsService.sortAlertsBySeverityThenId(alerts));
				this.patientName = patientName;
			});
		}
	}

	isGuestInvoice() {
		return EnumUtil.equals(this.invoice.payerType, PayerType.ANONYMOUS);
	}

	hasPermissionToGoToPatient(): boolean {
		return this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_PATIENT_VIEW);
	}

	handlePatientAlerts(alerts: AlertResponse[]) {
		const sortedActiveAlerts = AlertsService.filterForActiveAlerts(alerts);
		// if we have no ACTIVE alerts, we do not want to display anything alerts related
		if (!_isEmpty(sortedActiveAlerts)) {
			this.patientAlerts = alerts;
			// necessary to display most severe ACTIVE alert for color of flag icon
			this.firstSortedActiveAlert = sortedActiveAlerts[0];
		} else {
			this.patientAlerts = null;
			this.firstSortedActiveAlert = null;
		}
	}

	displayStatusAlertsModal() {
		this.modalManagerService.open(AlertsModalComponent, {data: {alerts: this.patientAlerts, type: ALERTS_MODAL_CONSTANTS.STATUS}});
	}

	async navigateToPatient() {
		await this.navigationService.navigateToPatient(this.invoice.patientId);
		this.returnEvent.emit();
	}

	canChangeLocation() {
		return !this.isReadOnly && !this.isPaymentProcessing && this.accountingViewService.canChangeLocation(this.invoice);
	}

	editLocation() {
		const request = new UpdateInvoiceLocationRequest();
		request.invoiceId = this.invoice.id;
		request.locationId = this.invoice.locationId;
		this.modalManagerService.open(LocationSelectModalComponent,
			{
				data: {
					request,
					onSubmit: (updateRequest) => this.accountingService.updateInvoiceLocation(updateRequest),
				},
			},
		).onClose
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(locationSelect => {
				if (!_isNil(locationSelect)) {
					this.invoiceService.refreshInvoiceDetailsInvoice(this.invoice.id);
				}
			});
	}

	canChangeProvider() {
		return !this.isReadOnly && !this.isPaymentProcessing && this.accountingViewService.canChangeProvider(this.invoice);
	}

	editProvider() {
		const payload = {
			data: {
				invoiceId: this.invoice.id,
				providerId: this.invoice.providerId,
			},
		};
		this.modalManagerService.open(ProviderSelectModalComponent, payload).onClose.subscribe((updated) => {
			if (updated) {
				this.invoiceService.refreshInvoiceDetailsInvoice(this.invoice.id);
			}
		});
	}

	canRemoveProvider() {
		return !this.isReadOnly && !this.isPaymentProcessing && this.accountingViewService.canRemoveProvider(this.invoice);
	}

	checkRemoveProvider() {
		this.invoiceService.confirmDisassociateProviderFromInvoice(this.invoice.id);
	}
}
