import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { EMPTY, Observable, Subject } from 'rxjs';

export class DynamicModalRef {
	private readonly _onClose = new Subject<any>();

	constructor() { }

	close(modal: DialogComponent, result?: any) {
		this._onClose.next(result);
		this._onClose.complete();

		// clean up ejs-dialog from the dom
		if (modal && modal.element) {
			modal.element.remove();
		}
	}
	get onClose(): Observable<any> {
		return this._onClose.asObservable();
	}
}

/**
 * This class is provided so calls to the modal manager service that fail do not throw errors when subscribed to onClose
 * These onClose subscriptions immediately complete and never emit.
 */
export class EmptyDynamicModalRef extends DynamicModalRef {

	get onClose(): Observable<any> {
		return EMPTY;
	}
}

