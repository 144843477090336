import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { CalendarUtilsService } from '@core/calendar-utils/calendar-utils.service';
import { EncounterTemplateForDropdown, EncounterTemplateService } from '@core/encounterTemplate/encounterTemplate.service';
import { EVENT_MANAGER_CONSTANTS } from '@core/events-manager/events-manager.constants';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { _isNil } from '@core/lodash/lodash';
import { PatientService } from '@core/patient/patient.service';
import { ProviderDropdownResponse, ProviderService } from '@core/provider/provider.service';
import { UserLocationsService } from '@core/user-locations/user-locations.service';
import { WaitListService } from '@core/waitlist/wait-list.service';
import { SchedulingPreferencesTimeOfDay, WaitListPriority } from '@gandalf/constants';
import { CreateWaitingListRequest } from '@gandalf/model/create-waiting-list-request';
import { PatientSchedulingPreferenceResponse } from '@gandalf/model/patient-scheduling-preference-response';
import { UpdateSchedulingPreferenceRequest } from '@gandalf/model/update-scheduling-preference-request';
import { BaseComponent } from '@shared/component/base.component';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { PatientNamePipe } from '@shared/pipes/patient-name/patient-name.pipe';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfConstantList, GandalfFormBuilder } from 'gandalf';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { combineLatest, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OptionItemResponse } from '@core/option-item/option-item.service';
import { PracticeLocation } from '@core/security-manager/security-manager.service';
import { PreferencesService } from '../../../patients/preferences/preferences.service';

@Component({
	selector: 'pms-waitlist-add-modal',
	templateUrl: './waitlist-add-modal.component.html',
	styles: [],
})
export class WaitlistAddModalComponent extends BaseComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('templateForm')
	templateForm: NgForm;

	patientId: number;
	readOnlyPatient = false;
	patientNameLabel: string;
	waitListComponentForm: UntypedFormGroup;
	preferenceComponentForm: UntypedFormGroup;
	waitingListRequest: CreateWaitingListRequest;
	preferenceRequest: UpdateSchedulingPreferenceRequest;
	locationList: OptionItemResponse<PracticeLocation, number>[];
	providerList: ProviderDropdownResponse[];
	encounterTemplateList: EncounterTemplateForDropdown[];
	waitListPriorityOptions = WaitListPriority.VALUES.values;
	dateMin: Date;
	dateMax: Date;
	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	defaultDate = new Date();
	focusPatientSearch = false;
	isSaving = false;
	isWaitListFeatureFlagOn: boolean;
	schedulingPreferencesTimeOfDay: GandalfConstantList<SchedulingPreferencesTimeOfDay> = {
		label: SchedulingPreferencesTimeOfDay.VALUES.label,
		values: [
			SchedulingPreferencesTimeOfDay.EARLY_MORNING,
			SchedulingPreferencesTimeOfDay.LATE_MORNING,
			SchedulingPreferencesTimeOfDay.LUNCH,
			SchedulingPreferencesTimeOfDay.AFTERNOON,
			SchedulingPreferencesTimeOfDay.EVENING,
			SchedulingPreferencesTimeOfDay.ANY,
		],
	};

	constructor(
		public dynamicModalRef: DynamicModalRef,
		public modalConfig: ModalConfig,
		public gandalfFormBuilder: GandalfFormBuilder,
		private userLocationsService: UserLocationsService,
		private encounterTemplateService: EncounterTemplateService,
		private providerService: ProviderService,
		private calendarUtilsService: CalendarUtilsService,
		public preferencesService: PreferencesService,
		public waitListService: WaitListService,
		public patientService: PatientService,
		private patientNamePipe: PatientNamePipe,
		private featureService: FeatureService,
		private eventsManagerService: EventsManagerService,
	) {
		super();
	}

	ngOnInit() {
		this.handleFeatureFlag();
		this.parseConfigData();
		this.dateMin = this.calendarUtilsService.getMinDateForNavigator();
		this.dateMax = this.calendarUtilsService.getMaxDateForNavigator();
		this.prepareInitialRequest();
		this.buildWaitListForm();
		this.buildPreferenceForm();
		this.requestData();
	}

	handleFeatureFlag() {
		this.isWaitListFeatureFlagOn = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.PATIENTS.WAIT_LIST);
	}

	parseConfigData() {
		if (!_isNil(this.modalConfig.data) && this.isWaitListFeatureFlagOn) {
			this.patientId = this.modalConfig.data.patientId;
			this.readOnlyPatient = true;
		}
	}

	prepareInitialRequest() {
		this.waitingListRequest = new CreateWaitingListRequest();
		this.preferenceRequest = new UpdateSchedulingPreferenceRequest();
	}

	buildWaitListForm() {
		this.waitListComponentForm = this.gandalfFormBuilder.group(this.waitingListRequest);
		this.waitListComponentForm.get('priority').setValue(WaitListPriority.NORMAL);
		this.waitListComponentForm.get('patientId').setValue(this.patientId);
		this.waitListComponentForm.disable();
		this.handleFormState();
	}

	handleFormState() {
		this.waitListComponentForm.get('patientId').valueChanges
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(val => {
				this.patientId = val;
				if (_isNil(this.patientId)) {
					const preferences = new PatientSchedulingPreferenceResponse();
					this.mapResponseToRequest(preferences);
				} else {
					this.updateFormWithSelectedPatientDetails(this.patientId);
				}
			});
	}

	mapResponseToRequest(patientSchedulingPreference: PatientSchedulingPreferenceResponse) {
		this.preferenceRequest = new UpdateSchedulingPreferenceRequest();
		this.preferenceRequest.patientId = this.patientId;
		this.preferenceRequest.allowPhrScheduling = patientSchedulingPreference.allowPhrScheduling;
		this.preferenceRequest.sundayTime = patientSchedulingPreference.sunday;
		this.preferenceRequest.mondayTime = patientSchedulingPreference.monday;
		this.preferenceRequest.tuesdayTime = patientSchedulingPreference.tuesday;
		this.preferenceRequest.wednesdayTime = patientSchedulingPreference.wednesday;
		this.preferenceRequest.thursdayTime = patientSchedulingPreference.thursday;
		this.preferenceRequest.fridayTime = patientSchedulingPreference.friday;
		this.preferenceRequest.saturdayTime = patientSchedulingPreference.saturday;
		this.preferenceRequest.scheduleRisk = patientSchedulingPreference.scheduleRisk;
		this.preferenceRequest.generalNote = patientSchedulingPreference.generalNote;
		this.buildPreferenceForm();
	}

	updateFormWithSelectedPatientDetails(patientId: number) {
		this.preferencesService.findPatientSchedulingPreferencesByPatientId(patientId)
			.subscribe((preferences) => {
				this.mapResponseToRequest(preferences);
			});
	}

	buildPreferenceForm() {
		this.preferenceComponentForm = this.gandalfFormBuilder.group(this.preferenceRequest);
		this.preferenceComponentForm.get('patientId').setValue(this.patientId);
	}

	requestData() {
		this.locationList = this.userLocationsService.getUserActiveLocations();
		combineLatest([
			this.providerService.findActivePracticeProvidersForDropdown(),
			this.encounterTemplateService.findActiveEncounterTemplatesForDropdown(),
			this.patientId ? this.patientService.getPatientNameById(this.patientId) : of(null),
		])
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(([providerList, encounterTemplateList, patientName]) => {
				this.providerList = providerList;
				this.encounterTemplateList = encounterTemplateList;
				this.patientNameLabel = this.patientNamePipe.transform(patientName);

				this.waitListComponentForm.enable();
				this.focusPatientSearch = true;
			});
	}

	submitForm() {
		if (!this.waitListComponentForm.valid || !this.preferenceComponentForm.valid || this.isSaving) {
			return;
		}
		this.isSaving = true;

		combineLatest([
			this.saveWaitList(),
			this.savePreferences(),
		]).subscribe(
			() => {
				this.closeModal(true);
				this.eventsManagerService.publish(EVENT_MANAGER_CONSTANTS.PATIENTS.SCHEDULING_PREFERENCES_UPDATED, {});
			},
		);
	}

	saveWaitList() {
		const request: CreateWaitingListRequest = this.waitListComponentForm.value;
		return this.waitListService.createWaitingList(request);
	}

	savePreferences() {
		const request: UpdateSchedulingPreferenceRequest = this.preferenceComponentForm.value;
		request.patientId = this.waitListComponentForm.get('patientId').value;
		request.sundayTime = this.convertNilToNone(request.sundayTime);
		request.mondayTime = this.convertNilToNone(request.mondayTime);
		request.tuesdayTime = this.convertNilToNone(request.tuesdayTime);
		request.wednesdayTime = this.convertNilToNone(request.wednesdayTime);
		request.thursdayTime = this.convertNilToNone(request.thursdayTime);
		request.fridayTime = this.convertNilToNone(request.fridayTime);
		request.saturdayTime = this.convertNilToNone(request.saturdayTime);
		return this.preferencesService.updateSchedulingPreference(request);
	}

	convertNilToNone(value) {
		return _isNil(value) ? SchedulingPreferencesTimeOfDay.NONE : value;
	}

	/* istanbul ignore next: closeModal */
	closeModal(refreshRequested: boolean) {
		this.dynamicModalRef.close(this.modal, refreshRequested);
	}

}
