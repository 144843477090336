import { Component, Input } from '@angular/core';
import { EnumUtil } from 'morgana';
import { InterviewStatus } from '@gandalf/constants';
import { SCHEDULE_STATUS_ICON_COLORS } from '@shared/constants/schedule-status-icon-colors.constants';

@Component({
	selector: 'pms-interview-status-icon',
	templateUrl: './interview-status-icon.component.html',
	styles: [],
})
export class InterviewStatusIconComponent {

	@Input() status: InterviewStatus;

	constructor() {
	}

	getTooltipText(): string {
		return this.status ? this.status.label : 'Interview Not Started';
	}

	getColorClass() {
		if (EnumUtil.equals(this.status, InterviewStatus.COMPLETED)) {
			return SCHEDULE_STATUS_ICON_COLORS.GREEN;
		} else if (EnumUtil.equals(this.status, InterviewStatus.INCOMPLETE)) {
			return SCHEDULE_STATUS_ICON_COLORS.BLUE;
		} else {
			return SCHEDULE_STATUS_ICON_COLORS.YELLOW;
		}
	}

}
