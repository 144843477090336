// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.openedge.OpenEdgeTransactionDetailsResponse */
/* istanbul ignore next */
@GandalfModel
export class OpenEdgeTransactionDetailsResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	amount: number;

	@GandalfProperty()
	cardLast4: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	cardType: constants.PaymentMethodCreditCardType;

	@GandalfProperty()
	customerReceipt: string;

	@GandalfProperty()
	errorMessage: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	paymentStatus: constants.OpenEdgeCommonPaymentStatus;

	@GandalfProperty()
	paymentStoredTokenId: number;

	@GandalfProperty()
	paymentTransactionId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.OpenEdgeCommonTransactionStatus;

	@GandalfProperty()
	transactionId: string;

}
