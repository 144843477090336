import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { CodeSet, DiagnosisLocation } from '@gandalf/constants';

@Injectable({
	providedIn: 'root',
})
@Pipe({
	name: 'encounterDiagnosisLocation',
})
export class EncounterDiagnosisLocationPipe implements PipeTransform {

	transform(codeSet: CodeSet, longDescription: string, diagnosisLocation: DiagnosisLocation): DiagnosisLocation {
		if (codeSet === CodeSet.ICD10) {
			const description = longDescription.toLowerCase();
			if (description.match(/\bright\b/)) {
				return DiagnosisLocation.RIGHT_EYE;
			} else if (description.match(/\bleft\b/)) {
				return DiagnosisLocation.LEFT_EYE;
			} else if (description.includes('bilateral')) {
				return DiagnosisLocation.BOTH_EYES;
			}
			return DiagnosisLocation.UNKNOWN_EYE;
		}

		return diagnosisLocation ? diagnosisLocation : DiagnosisLocation.UNKNOWN_EYE;
	}
}
