import {Component, Input, OnInit, Optional} from '@angular/core';
import {ControlContainer, UntypedFormGroup} from '@angular/forms';
import {AddressService} from '@core/address/address.service';
import {SecurityManagerService} from '@core/security-manager/security-manager.service';
import {BetaSystemCode} from '@gandalf/constants';
import {CityStateProvinceResponse} from '@gandalf/model/city-state-province-response';
import {combineLatest} from 'rxjs';
import {OptionItem} from 'morgana';

@Component({
	selector: 'pms-address-form',
	templateUrl: './address-form.component.html',
	styles: [],
})
export class AddressFormComponent implements OnInit {

	private _parentFormGroup: UntypedFormGroup;

	@Input()
	set parentFormGroup(formGroup: UntypedFormGroup) {
		this._parentFormGroup = formGroup;
		this.determineRequiredFields();

	}

	get parentFormGroup(): UntypedFormGroup {
		return this._parentFormGroup;
	}

	@Input()
	autoselectStateProvince: boolean;

	@Input()
	isCreating: boolean;

	@Input()
	isCityConditionallyRequired: boolean;

	stateProvinces: OptionItem[];
	isPostalCodeRequired = false;
	isCityRequired = false;
	isAddressLine1Required = false;
	isStateProvinceRequired = false;

	constructor(
		private addressService: AddressService,
		private securityManagerService: SecurityManagerService,
		@Optional() private controlContainer: ControlContainer,
	) {
	}

	ngOnInit() {
		if (!this.parentFormGroup && this.controlContainer) {
			this.parentFormGroup = this.controlContainer.control as UntypedFormGroup;
		}

		const employeeLocationId = this.securityManagerService.getUserSession().locationId;

		this.determineRequiredFields();

		if (this.isCreating && this.autoselectStateProvince) {
			combineLatest([
				this.addressService.findStateProvincesForDropdown(),
				this.addressService.getStateProvinceByPracticeLocation(employeeLocationId),
			]).subscribe(([allStateProvinces, currentStateProvince]) => {
				this.stateProvinces = allStateProvinces;
				this.parentFormGroup.patchValue({stateProvince: currentStateProvince});
			});
		} else {
			this.addressService.findStateProvincesForDropdown().subscribe(
				(allStateProvinces) => this.stateProvinces = allStateProvinces,
			);
		}
	}

	onPostalCodeChange() {
		const postalCodeControl = this.parentFormGroup.get(this.postalCodeFormControlName);
		const city = this.parentFormGroup.get('city').value as string;

		// If City is not set and postal code is valid, lookup the city and state/province
		if (!city && postalCodeControl.valid && postalCodeControl.value) {
			const postalCode = postalCodeControl.value as string;
			this.addressService.findStateProvinceByPostalCode(postalCode)
				.subscribe(cityStateProvince => this.selectCityStateProvince(cityStateProvince));
		}
	}

	onPostalCodeBlur() {
		const postalCodeControl = this.parentFormGroup.get('postalCode');
		this.parentFormGroup.get('postalCode').setValue(postalCodeControl.value.replace(/-/g, ''));
	}

	get postalCodeFormControlName() {
		return this.allowNonStandardZipCodes ? 'nonStandardPostalCode' : 'postalCode';
	}

	get allowNonStandardZipCodes() {
		return this.securityManagerService.hasBeta(BetaSystemCode.NON_STANDARD_ZIP_CODES);
	}

	/**
	 * Updates form data based on the search results.
	 */
	private selectCityStateProvince(cityStateProvince: CityStateProvinceResponse) {
		if (cityStateProvince) {
			// Apply the found City and State/Province to the form
			this.parentFormGroup.patchValue(cityStateProvince);
		} else {
			// Postal code is not found, so clear out the selected State/Province
			this.parentFormGroup.patchValue({
				stateProvince: null,
			});
		}
	}

	private determineRequiredFields() {
		this.isPostalCodeRequired = this.hasRequiredValidator(this.postalCodeFormControlName);
		this.isCityRequired = this.hasRequiredValidator('city');
		this.isAddressLine1Required = this.hasRequiredValidator('addressLine1');
		this.isStateProvinceRequired = this.hasRequiredValidator('stateProvince');
	}

	private hasRequiredValidator(controlName: string) {
		const control = this.parentFormGroup.get(controlName) as any;
		if (control && control.validator) {
			return control.validator(controlName) && control.validator(controlName).required;
		}
		return false;
	}
}
