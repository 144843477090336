import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalBase } from 'morgana';
import { PracticeLocationSummaryResponse } from '@gandalf/model/practice-location-summary-response';
import { GandalfFormBuilder, TypedFormGroup } from 'gandalf';
import { CreateRevClearExternalCredentialRequest } from '@gandalf/model/create-rev-clear-external-credential-request';
import { UpdateRevClearExternalCredentialRequest } from '@gandalf/model/update-rev-clear-external-credential-request';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { _isNil } from '@core/lodash/lodash';
import { NgForm } from '@angular/forms';
import { OptionItemResponse } from '@core/option-item/option-item.service';
import { PracticeLocation } from '@core/security-manager/security-manager.service';
import { SecurityRoleDropdownResponse } from '@core/security/security-role.service';
import { EmployeeWithRolesDropdownResponse } from '@core/employee/employee.service';
import { UpdateNotificationPersonsRequest } from '@gandalf/model/update-notification-persons-request';
import { RevClearExternalCredentialResponse } from '@gandalf/model/rev-clear-external-credential-response';
import { AutopostingConfigurationRevClearTemplateService } from '../autoposting-configuration-rev-clear-template.service';

interface RevClearExternalCredentialData {
	revClearExternalCredentialId: number;
	userName: string;
	password: string;
	autoPostingEnabled: boolean;
	assignedLocations: PracticeLocationSummaryResponse[];
	availableLocations: OptionItemResponse<PracticeLocation, number>[];
	hasAutoPosting: boolean;
	securityRoles: SecurityRoleDropdownResponse[];
	employees: EmployeeWithRolesDropdownResponse[];
}

@Component({
	selector: 'pms-autoposting-configuration-rev-clear-external-credential-modal',
	templateUrl: './autoposting-configuration-rev-clear-external-credential-modal.component.html',
	styles: [],
})
export class AutopostingConfigurationRevClearExternalCredentialModalComponent extends ModalBase<RevClearExternalCredentialData, boolean> implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('ngForm')
	ngForm: NgForm;

	request: CreateRevClearExternalCredentialRequest | UpdateRevClearExternalCredentialRequest;
	formGroup: TypedFormGroup<CreateRevClearExternalCredentialRequest | UpdateRevClearExternalCredentialRequest>;
	isCreate = false;
	hasAutoPosting = false;
	availableLocations: (PracticeLocationSummaryResponse | OptionItemResponse<PracticeLocation, number>)[];
	selectedPersonIds: number[];
	securityRoles: SecurityRoleDropdownResponse[];
	employees: EmployeeWithRolesDropdownResponse[];

	constructor(
		private gandalfFormBuilder: GandalfFormBuilder,
		private autopostingConfigurationRevClearTemplateService: AutopostingConfigurationRevClearTemplateService,
	) {
		super();
	}

	ngOnInit() {
		this.parseConfig();
		this.getData();
	}

	getData() {
		if (this.data.revClearExternalCredentialId) {
			this.autopostingConfigurationRevClearTemplateService.findEmployeePersonIdsForNotifications(this.data.revClearExternalCredentialId).subscribe(personIdsToBeNotified => {
				this.selectedPersonIds = personIdsToBeNotified;
			});
		}
	}

	parseConfig() {
		this.hasAutoPosting = this.data.hasAutoPosting;
		this.securityRoles = this.data.securityRoles;
		this.employees = this.data.employees;
		if (_isNil(this.data.revClearExternalCredentialId)) {
			this.isCreate = true;
			this.request = new CreateRevClearExternalCredentialRequest();
			this.request.autoPostingEnabled = false;
			this.formGroup = this.gandalfFormBuilder.groupTyped(this.request);
		} else {
			const updateRequest = new UpdateRevClearExternalCredentialRequest();
			updateRequest.revClearExternalCredentialId = this.data.revClearExternalCredentialId;
			updateRequest.userName = this.data.userName;
			updateRequest.password = this.data.password;
			updateRequest.autoPostingEnabled = this.data.autoPostingEnabled;
			updateRequest.locationIds = this.data.assignedLocations.map(location => location.id);
			this.request = updateRequest;
			this.formGroup = this.gandalfFormBuilder.groupTyped(this.request);
		}
		this.availableLocations = [...this.data.availableLocations, ...(this.data.assignedLocations || [])];
	}

	handleEmployeeSecurityRoleSelect(event: any) {
		this.selectedPersonIds = [];
		this.employees.forEach(employee => {
			if (!_isNil(employee.securityRoles.find(role => role.id === event.itemData.id))) {
				this.selectedPersonIds.push(employee.personId);
			}
		});
	}

	/* istanbul ignore next: closeModal */
	closeModal(refreshData = false) {
		this.dynamicModalRef.close(this.modal, refreshData);
	}

	submitForm(event) {
		this.ngForm.onSubmit(event);
	}

	saveCredential() {
		if (this.formGroup.invalid) {
			return;
		}
		if (this.isCreate) {
			this.autopostingConfigurationRevClearTemplateService.createRevClearExternalCredential(this.formGroup.value as CreateRevClearExternalCredentialRequest)
				.subscribe(credential => {
					this.updateNotificationPersons(credential);
				});
		} else {
			this.autopostingConfigurationRevClearTemplateService.updateRevClearExternalCredential(this.formGroup.value as UpdateRevClearExternalCredentialRequest)
				.subscribe(credential => {
					this.updateNotificationPersons(credential);
				});
		}
	}

	updateNotificationPersons(response: RevClearExternalCredentialResponse) {
		const request = new UpdateNotificationPersonsRequest();
		request.personIds = this.selectedPersonIds;
		request.revClearExternalCredentialId = response.revClearExternalCredentialId;
		this.autopostingConfigurationRevClearTemplateService.updateNotificationPersons(request).subscribe();
		this.closeModal(true);
	}
}
