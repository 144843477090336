// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { WorkflowStepTemplateResponse } from './workflow-step-template-response';

/** See com.rev360.pms.api.controller.admin.encounter.InterviewTemplateDetailResponse */
/* istanbul ignore next */
@GandalfModel
export class InterviewTemplateDetailResponse extends GandalfModelBase {

	@GandalfProperty()
	interviewTemplateId: number;

	@GandalfProperty()
	masterIdentifier: number;

	@GandalfProperty()
	name: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	templateStatus: constants.TemplateStatus;

	@GandalfArray(WorkflowStepTemplateResponse)
	workflowStepTemplateResponses: WorkflowStepTemplateResponse[];

}
