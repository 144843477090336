// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressOptionalRequest } from './address-optional-request';

// @ts-ignore
import { CreatePatientBasicInformationRequest } from './create-patient-basic-information-request';

// @ts-ignore
import { CreatePatientCommunicationPreferencesRequest } from './create-patient-communication-preferences-request';

// @ts-ignore
import { CreatePatientOtherInformationRequest } from './create-patient-other-information-request';

// @ts-ignore
import { CreatePatientPhoneInformationRequest } from './create-patient-phone-information-request';

/** See com.rev360.pms.api.controller.patient.QuickAddPatientRequest */
/* istanbul ignore next */
@GandalfModel
export class QuickAddPatientRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Address is Required' } })
	@GandalfProperty({ isRequired: true })
	address: AddressOptionalRequest;

	@GandalfValidator({ notNull: { message: 'Basic Information is required' } })
	@GandalfProperty({ isRequired: true })
	patientBasicInformation: CreatePatientBasicInformationRequest;

	@GandalfValidator({ notNull: { message: 'Communication Preferences is Required' } })
	@GandalfProperty({ isRequired: true })
	patientCommunicationPreferences: CreatePatientCommunicationPreferencesRequest;

	@GandalfValidator({ notNull: { message: 'Other Information is Required' } })
	@GandalfProperty({ isRequired: true })
	patientOtherInformation: CreatePatientOtherInformationRequest;

	@GandalfValidator({ notNull: { message: 'Phone Information is Required' } })
	@GandalfProperty({ isRequired: true })
	patientPhoneInformation: CreatePatientPhoneInformationRequest;

}
