// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.immunization.PatientVaccinationResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientVaccinationResponse extends GandalfModelBase {

	@GandalfProperty()
	administeredAmount: number;

	@GandalfProperty()
	administeredAmountUnitId: number;

	@GandalfProperty()
	administeredAtLocationId: number;

	@GandalfProperty()
	administeredByEmployeeId: number;

	@GandalfProperty()
	administeredOn: Date;

	@GandalfProperty()
	administeredRouteId: number;

	@GandalfProperty()
	administeredSiteId: number;

	@GandalfProperty()
	comments: string;

	@GandalfProperty()
	cvxDetailCode: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate: Date;

	@GandalfProperty()
	notGiven: boolean;

	@GandalfProperty()
	notGivenReasonId: number;

	@GandalfProperty()
	orderedByProviderId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	substanceExpireDate: Date;

	@GandalfProperty()
	substanceLot: string;

	@GandalfProperty()
	substanceManufacturerId: string;

	@GandalfProperty()
	vaccinationId: number;

}
