// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

/** See com.rev360.pms.api.controller.admin.vendorsandpartners.pharmacy.PharmacyResponse */
/* istanbul ignore next */
@GandalfModel
export class PharmacyResponse extends GandalfModelBase {

	@GandalfProperty()
	address: AddressResponse;

	@GandalfProperty()
	email: string;

	@GandalfProperty()
	faxNumber: string;

	@GandalfProperty()
	locationName: string;

	@GandalfProperty()
	mainPhone: string;

	@GandalfProperty()
	ncpdp: string;

	@GandalfProperty()
	npi: string;

	@GandalfProperty()
	pharmacyBrandId: number;

	@GandalfProperty()
	pharmacyBrandName: string;

	@GandalfProperty()
	pharmacyId: number;

	@GandalfProperty()
	webSite: string;

}
