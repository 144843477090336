import { Directive, HostListener } from '@angular/core';
import { _isEmpty } from '@core/lodash/lodash';
import { QueryCellInfoEventArgs } from '@syncfusion/ej2-angular-grids';

@Directive({
	/* eslint-disable-next-line @angular-eslint/directive-selector */
	selector: 'ejs-grid:not([pmsDoNotNormalizeEmptyStrings])',
})
export class NormalizeEmptyStringsDirective {

	constructor() { }

	@HostListener('queryCellInfo', ['$event'])
	onQueryCellInfo(event: QueryCellInfoEventArgs) {
		Object.entries(event.data).forEach(([key, value]) => {
			event.data[key] = typeof value === 'string' && _isEmpty(value) ? null : value;
		});
	}
}
