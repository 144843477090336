<h4 class="section-title bg-default mrgn-btm" [attr.data-test-id]="'queryTriggerPanelHeader'">Search Results</h4>
<div class="row">
	<div class="col-sm-12 col-lg-6">
		<div class="margin-bottom-md">
			<ejs-grid
					#searchResultGrid
					[hidden]="!(searchResults?.length)"
					[dataSource]="searchResults"
					[pmsGridSubmit]="isLoading"
					[pmsGridSubmitDefaultSortOptions]="searchResultSortSettings"
					[allowSelection]="false"
					(recordDoubleClick)="removeSearchResult($event.rowData)"
					[allowPaging]="true"
					[pageSettings]="pageSettings"
					[allowSorting]="true"
					[sortSettings]="searchResultSortSettings"
					[allowResizing]="true"
					[editSettings]="{ allowAdding: true, allowDeleting: true }"
					gridLines="Horizontal"
					class="table-rowlink"
					autoFitColumns
					[autoFitBlacklist]="['description']"
					[staticColumns]="['remove']"
					[attr.data-test-id]="'queryTriggerPanelGrid'">
				<e-columns>
					<e-column field="type" headerText="Type"></e-column>
					<e-column field="description" headerText="Description" clipMode="EllipsisWithTooltip"></e-column>
					<e-column
							field="remove"
							headerText=""
							[width]="50"
							clipMode="Clip"
							[allowResizing]="false"
							[allowSorting]="false">
						<ng-template #template let-data>
							<rev-button
									[revGridButton]="'remove'"
									(buttonClick)="removeSearchResult(data)"
									dataTestId="queryTriggerSearchResultRemoveButton">
							</rev-button>
						</ng-template>
					</e-column>
				</e-columns>
			</ejs-grid>
			<div class="panel panel-default text-center" [hidden]="!!(searchResults?.length)">
				<div class="panel-body">
					Select Care Plan Template(s) or Item(s).
				</div>
			</div>
		</div>
	</div>
	<div class="col-sm-12 col-lg-6">
		<div class="margin-bottom-md">
			<ejs-tab overflowMode="Popup" [animation]="tabAnimation">
				<e-tabitems>
					<e-tabitem [header]="{text: 'Care Plan Templates'}">
						<ng-template #content>
							<ejs-grid
									#carePlanTemplateGrid
									[dataSource]="carePlanTemplates"
									[query]="carePlanTemplateQuery"
									[pmsGridSubmit]="isLoading"
									[pmsGridSubmitDefaultSortOptions]="templateSortSettings"
									[allowSelection]="true"
									(rowSelecting)="openCarePlanTemplateModal($event)"
									[allowPaging]="true"
									[pageSettings]="pageSettings"
									[allowSorting]="true"
									[sortSettings]="templateSortSettings"
									[allowResizing]="true"
									gridLines="Horizontal"
									class="table-rowlink"
									[attr.data-test-id]="'queryTriggerPanelCarePlanGrid'">
								<e-columns>
									<e-column field="name" headerText="Name" width="auto" clipMode="EllipsisWithTooltip"></e-column>
									<e-column
											field="add"
											headerText=""
											[width]="50"
											clipMode="Clip"
											[allowResizing]="false"
											[allowSorting]="false">
										<ng-template #template let-data>
											<rev-button
													[revGridButton]="'add'"
													(buttonClick)="addCarePlanTemplate(data)"
													dataTestId="queryTriggerCarePlanTemplateAddButton">
											</rev-button>
										</ng-template>
									</e-column>
								</e-columns>
							</ejs-grid>
						</ng-template>
					</e-tabitem>
					<e-tabitem [header]="{text: 'Care Plan Items'}">
						<ng-template #content>
							<ejs-grid
									#carePlanItemTemplateGrid
									[dataSource]="carePlanItemTemplates"
									[query]="carePlanItemTemplateQuery"
									[pmsGridSubmit]="isLoading"
									[pmsGridSubmitDefaultSortOptions]="itemTemplateSortSettings"
									[allowSelection]="false"
									(recordDoubleClick)="addCarePlanItemTemplate($event.rowData)"
									[allowPaging]="true"
									[pageSettings]="pageSettings"
									[allowSorting]="true"
									[sortSettings]="itemTemplateSortSettings"
									[allowResizing]="true"
									[allowFiltering]="true"
									[filterSettings]="{showFilterBarStatus: false}"
									gridLines="Horizontal"
									class="table-rowlink"
									autoFitColumns
									[autoFitBlacklist]="['description']"
									[staticColumns]="['add']"
									[attr.data-test-id]="'queryTriggerPanelCarePlanItemsGrid'">
								<ng-template #toolbarTemplate let-data>
									<rev-table-action-menu [table]="carePlanItemTemplateGrid">
										<ng-container rev-table-action-menu-left>
											<div class="e-toolbar-item">
												<label>Type</label>
												<pms-constant-dropdown
														[constantList]="carePlanItemCategories"
														[(ngModel)]="carePlanItemTemplateCategory"
														[ngModelOptions]="{standalone: true}"
														placeholder="All Types"
														showClearButton="true"
                                                        (selectionChange)="filterCarePlanItemTemplates()"
												></pms-constant-dropdown>
											</div>
										</ng-container>
									</rev-table-action-menu>
								</ng-template>
								<e-columns>
									<e-column field="category.label" headerText="Type"></e-column>
									<e-column field="description" headerText="Description" clipMode="EllipsisWithTooltip"></e-column>
									<e-column
											field="add"
											headerText=""
											[width]="50"
											clipMode="Clip"
											[allowResizing]="false"
											[allowSorting]="false">
										<ng-template #template let-data>
											<rev-button
													[revGridButton]="'add'"
													(buttonClick)="addCarePlanItemTemplate(data)"
													dataTestId="queryTriggerCarePlanItemAddButton">
											</rev-button>
										</ng-template>
									</e-column>
								</e-columns>
							</ejs-grid>
						</ng-template>
					</e-tabitem>
				</e-tabitems>
			</ejs-tab>
		</div>
	</div>
</div>
