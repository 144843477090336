import { Component, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { PolicyHolderRelationship } from '@gandalf/constants';
import { EnumUtil } from 'morgana';

@Component({
	selector: 'pms-family-patient-insurance-form',
	templateUrl: './family-patient-insurance-form.component.html',
	styles: [],
})
export class FamilyPatientInsuranceFormComponent implements OnInit {

	componentForm: UntypedFormGroup;
	policyHolderRelationshipOptions = EnumUtil.buildGandalfEnumSubsetList(
		[
			PolicyHolderRelationship.OTHER,
			PolicyHolderRelationship.SPOUSE,
			PolicyHolderRelationship.CHILD,
		],
		PolicyHolderRelationship.VALUES.label,
	);

	constructor(
		public controlContainer: ControlContainer,
	) { }

	ngOnInit() {
		this.componentForm = this.controlContainer.control as UntypedFormGroup;
		this.componentForm.get('relationshipToInsured').setValue(PolicyHolderRelationship.OTHER);
		this.handleFormState();
	}

	handleFormState() {
		FormUtilsService.reactToValueChanges(this.componentForm, () => {
			FormUtilsService.disabledWhen(this.componentForm.get('relationshipToInsured'), !this.componentForm.get('copyInsurance').value);
		}, true);
	}

}
