// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.vendorsandpartners.insurancecompanies.CreditInsuranceResponse */
/* istanbul ignore next */
@GandalfModel
export class CreditInsuranceResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	amount: number;

	@GandalfProperty()
	comments: string;

	@GandalfProperty()
	creditDate: Date;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	invoiceId: number;

	@GandalfProperty()
	referenceNumber: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.CreditStatus;

}
