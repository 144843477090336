import { Pipe, PipeTransform } from '@angular/core';

/**
	* @ngdoc filter
	* @description
	* Angular filter for truncating text
	*
	* @param input - this is the string that will be truncated if exceeding characters
	* @param characters - the amount of characters to allow in the result string
	* @usage
	* {{ 'welcome.login.invalidCredentials' | localText:10}}
	*/

@Pipe({
	name: 'textTruncate',
})
export class TextTruncatePipe implements PipeTransform {

	transform(input: string, characters: number): string {
		const truncate = () => {

			if (!input || input.length <= 0) {
				return input;
			}

			if (!characters) {
				return input;
			}

			if (characters < 0) {
				return input;
			}

			if (input.length <= characters) {
				return input + '';
			} else {
				input = input + '';
				return input.substring(0, characters) + '\u2026';
			}
		};

		return truncate();
	}

}
