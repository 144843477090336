import { Injectable } from '@angular/core';
import { AdminRevClearGandalfService } from '@gandalf/services';
import { Observable } from 'rxjs';
import { RevClearExternalCredentialResponse } from '@gandalf/model/rev-clear-external-credential-response';
import { CreateRevClearExternalCredentialRequest } from '@gandalf/model/create-rev-clear-external-credential-request';
import { UpdateRevClearExternalCredentialRequest } from '@gandalf/model/update-rev-clear-external-credential-request';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { map } from 'rxjs/operators';
import { FeatureService } from '@core/feature/feature.service';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { PreferenceDefaults, PreferenceName } from '@gandalf/constants';

export interface FormattedRevClearExternalCredentialResponse extends RevClearExternalCredentialResponse {
	formattedLocationListString: string;
}

@Injectable({
	providedIn: 'root',
})
export class RevClearTemplateService {

	constructor(
		private adminRevClearGandalfService: AdminRevClearGandalfService,
		private featureService: FeatureService,
		private securityManager: SecurityManagerService,
	) {
	}

	findRevClearExternalCredentials(): Observable<FormattedRevClearExternalCredentialResponse[]> {
		return this.adminRevClearGandalfService.findAllRevClearExternalCredentials().pipe(
			map(credentials => credentials.map(credential => this.formatCredential(credential))),
		);
	}

	/* istanbul ignore next: gandalf */
	disableRevClearExternalCredential(revClearExternalCredentialId: number) {
		return this.adminRevClearGandalfService.disableRevClearExternalCredential(revClearExternalCredentialId);
	}

	/* istanbul ignore next: gandalf */
	enableRevClearExternalCredential(revClearExternalCredentialId: number) {
		return this.adminRevClearGandalfService.enableRevClearExternalCredential(revClearExternalCredentialId);
	}

	/* istanbul ignore next: gandalf */
	createRevClearExternalCredential(request: CreateRevClearExternalCredentialRequest) {
		return this.adminRevClearGandalfService.createRevClearExternalCredential(request);
	}

	/* istanbul ignore next: gandalf */
	updateRevClearExternalCredential(request: UpdateRevClearExternalCredentialRequest) {
		return this.adminRevClearGandalfService.updateRevClearExternalCredential(request);
	}

	hasAutoPosting(): boolean {
		return this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.ACCOUNTING.AUTO_POSTING)
			&& this.securityManager.practiceHasRevClearEraSubscription()
			&& this.securityManager.preferenceValueIsOn(PreferenceName.ALLOW_ERA_AUTO_POSTING.value, PreferenceDefaults.ALLOW_ERA_AUTO_POSTING.value);
	}

	formatCredential(credential: RevClearExternalCredentialResponse) {
		const formattedCredential = credential as FormattedRevClearExternalCredentialResponse;
		formattedCredential.formattedLocationListString = formattedCredential.locations.map(location => location.name).join(', ');
		return formattedCredential;
	}
}
