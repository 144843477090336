import { Component, TemplateRef } from '@angular/core';
import { _cloneDeep, _isNil } from '@core/lodash/lodash';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';

@Component({
	selector: 'pms-tooltip-cell-renderer',
	template: `<div class="custom-tooltip" *ngTemplateOutlet="template; context: templateContext"></div>`,
	styles: [
		`
      :host {
        position: absolute;
      }

      :host.ag-tooltip-hiding {
        opacity: 0;
      }

      .custom-tooltip p {
        margin: 5px;
        white-space: nowrap;
      }

      .custom-tooltip p:first-of-type {
        font-weight: bold;
      }
    `,
	],
})
export class TooltipCellRendererComponent implements ITooltipAngularComp {
	template: TemplateRef<any>;
	templateContext: { $implicit: any, params: any };

	refresh(params: any): boolean {
		this.templateContext = {
			$implicit: _cloneDeep(params.data),
			params,
		};
		return true;
	}

	agInit(params: ITooltipParams): void {
		this.template = params['ngTemplate'];
		if (!_isNil(params['externalData'])) {
			params.data['externalData'] = params['externalData'];
		}
		this.refresh(params);
	}
}
