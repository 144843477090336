// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.devicemeasurement.OpticalDeviceLensMeasurementResponse */
/* istanbul ignore next */
@GandalfModel
export class OpticalDeviceLensMeasurementResponse extends GandalfModelBase {

	@GandalfProperty()
	monocularPdDistance: number;

	@GandalfProperty()
	monocularPdNear: number;

	@GandalfProperty()
	opticalCenter: number;

	@GandalfProperty()
	segHeight: number;

	@GandalfProperty()
	vertexDistance: number;

}
