<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-md"
	[attr.data-test-id]="'uploadedFilesModal'">
	<ng-template #header>
		<div class="dlg-template">{{title}}</div>
	</ng-template>
	<ng-template #content>
		<pms-patient-documents #documents
				[patientId]="patientId"
				[parentId]="parentId"
				[entityType]="entityType"
				[encounterId]="encounterId"
				[disabled]="disabled">
		</pms-patient-documents>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button type="button" (click)="closeModal()" iconCss="fa fa-close" [attr.data-test-id]="'uploadedFilesModalCloseButton'">Close</button>
	</ng-template>
</ejs-dialog>
