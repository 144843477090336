// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CarePlanItemResponse } from './care-plan-item-response';

// @ts-ignore
import { PersonDiagnosisResponse } from './person-diagnosis-response';

/** See com.rev360.pms.api.controller.encounter.history.reasonforvisit.HistoryDiagnosisCarePlanItemResponse */
/* istanbul ignore next */
@GandalfModel
export class HistoryDiagnosisCarePlanItemResponse extends GandalfModelBase {

	@GandalfProperty()
	carePlanItem: CarePlanItemResponse;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	isPrimary: boolean;

	@GandalfProperty()
	personDiagnosis: PersonDiagnosisResponse;

	@GandalfProperty()
	reasonForVisitId: number;

}
