<ejs-dialog
	#modal
	[zIndex]="zIndexBehindHitPmsHtmlModal"
	(close)="closeModal()"
	cssClass="modal-lg"
	[attr.data-test-id]="'selectInvoiceItemsModal'">
	<ng-template #header>
		<div class="dlg-template">Select Invoice Item</div>
	</ng-template>
	<ng-template #content>
		<div class="modal-feature">
			@if (invoice) {
				<pms-barcode-search
					[locationId]="invoice.locationId"
					[searchType]="searchType"
					label="Add by Barcode"
					buttonLabel="Add"
					(results)="onBarcodeResult($event)"
					[attr.data-test-id]="'selectInvoiceItemsModalSearchByBarcodeComponent'">
				</pms-barcode-search>
			}
		</div>
		<div id="wrap">
			<div id="sidebar">
				<pms-panel-menu [model]="menuItems" [noRouterLinks]="true"></pms-panel-menu>
			</div>
			<div id="main">
				@if (productCategory) {
					<pms-product-selection #productSelectionComponent
										   (locationProductSelected)="onLocationProductSelection($event)"
										   (locationProductItemSelected)="onLocationProductItemSelection($event)"
										   [productCategory]="productCategory"
										   [locationId]="invoice?.locationId"></pms-product-selection>
				}
				@if (serviceType) {
					<pms-service-selection (serviceSelected)="onServiceSelection($event)"
										   [serviceType]="serviceType"
										   [locationId]="invoice?.locationId"></pms-service-selection>
				}

			</div>
			<div class="clearfix"></div>
		</div>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'selectInvoiceItemsModalCloseButton'">Close</button>
	</ng-template>
</ejs-dialog>
