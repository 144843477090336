// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.schedule.scheduleitem.StartAppointmentRequest */
/* istanbul ignore next */
@GandalfModel
export class StartAppointmentRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Schedule Item Id must not be null' } })
	@GandalfProperty({ isRequired: true })
	appointmentId: number;

	@GandalfProperty()
	employeeId: number;

	@GandalfProperty()
	providerId: number;

}
