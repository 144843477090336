// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PracticeIdentifiersResponse } from './practice-identifiers-response';

// @ts-ignore
import { PracticeLocationIdentifiersResponse } from './practice-location-identifiers-response';

/** See com.rev360.pms.api.controller.practice.PracticeAndLocationsIdentifiersResponse */
/* istanbul ignore next */
@GandalfModel
export class PracticeAndLocationsIdentifiersResponse extends GandalfModelBase {

	@GandalfArray(PracticeLocationIdentifiersResponse)
	locations: PracticeLocationIdentifiersResponse[];

	@GandalfProperty()
	practice: PracticeIdentifiersResponse;

}
