import { Pipe, PipeTransform } from '@angular/core';
import { ReferenceDataResponse } from '@gandalf/model/reference-data-response';

@Pipe({
	name: 'insurancePolicyPriorityType',
})
export class InsurancePolicyPriorityTypePipe implements PipeTransform {

	transform(priority: ReferenceDataResponse, type?: ReferenceDataResponse): any {
		return priority.value.concat(' - ', type.value);
	}

}
