import { PublishEvent, PublishOptions } from '@app-store/actions/events.actions';
import { StatefulEventDefinition } from '@app-store/constants/event.constants';
import { ConsumerEventsStateMap, PublishedEventStateMap, StatefulEvent } from '@app-store/reducers/events.reducer';
import { Store } from '@ngrx/store';
import { EMPTY, from, Observable, of } from 'rxjs';
import { InjectorContainerModule } from '../../injector-container.module';

export interface ObserverOptions<Args = unknown> {
	storeKeyArgs?: Args;
	replayMode?: ReplayMode;
}

export enum ReplayMode {
	// Since we check this value for definition 0 is not valid here.
	REPLAY_ALL = 1,
	REPLAY_ALL_UNCONSUMED = 2,
	REPLAY_LAST_UNCONSUMED = 3,
}

export class StatefulEventUtil {

	static publish<Event extends StatefulEventDefinition, Payload = unknown>(event: Event, options?: PublishOptions<Event, Payload>) {
		const store = InjectorContainerModule.getInjectable(Store);
		store.dispatch(new PublishEvent({eventDefinition: event, options}));
	}

	static mergeObserverOptionDefaults(options: ObserverOptions): ObserverOptions {
		return {...options,
			storeKeyArgs: options?.storeKeyArgs,
			replayMode: options?.replayMode || ReplayMode.REPLAY_LAST_UNCONSUMED,
		};
	}

	static buildObserverEventStream(
		eventStoreKey: string,
		eventState: PublishedEventStateMap,
		consumerState: ConsumerEventsStateMap,
		options: ObserverOptions,
		emissionIndex: number,
	): Observable<StatefulEvent> {
		const lastConsumed = consumerState?.get(eventStoreKey)?.lastConsumed || 0;
		const lastEventId = eventState.size;

		if (emissionIndex === 0) {
			switch (options.replayMode) {

				case ReplayMode.REPLAY_ALL: {
					return from(Array.from(eventState.values()));
				}

				case ReplayMode.REPLAY_ALL_UNCONSUMED: {
					const unconsumedEvents: StatefulEvent[] = [];
					const unconsumedCount = lastEventId - lastConsumed;
					for (let unconsumedOffset = 0; unconsumedOffset < unconsumedCount; unconsumedOffset++) {
						unconsumedEvents.push(eventState.get(lastConsumed + unconsumedOffset + 1));
					}
					return from(unconsumedEvents);
				}

				case ReplayMode.REPLAY_LAST_UNCONSUMED: {
					if (lastEventId === lastConsumed) {
						return EMPTY;
					} else {
						return of(eventState.get(lastEventId));
					}
				}
			}

		} else {
			return of(eventState.get(lastEventId));
		}
	}
}
