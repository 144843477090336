import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { CreditCardFormService } from '@core/credit-card-form/credit-card-form.service';
import { _isNil } from '@core/lodash/lodash';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { OpenEdgePaymentService } from '@core/open-edge-payment/open-edge-payment.service';
import { ToasterService } from '@core/toaster/toaster.service';
import { UserLocationsService } from '@core/user-locations/user-locations.service';
import { OpenEdgeCreateSavedCardRequest } from '@gandalf/model/open-edge-create-saved-card-request';
import { OpenEdgeSaveCardResponse } from '@gandalf/model/open-edge-save-card-response';
import { BaseComponent } from '@shared/component/base.component';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'pms-open-edge-save-card-modal',
	templateUrl: './open-edge-save-card-modal.component.html',
	providers: [CreditCardFormService],
	styles: [],
})
export class OpenEdgeSaveCardModalComponent extends BaseComponent implements OnInit, AfterViewInit {

	@ViewChild('modal')
	modal: DialogComponent;

	personId: number;
	errors: string[] = [];

	constructor(
		private modalConfig: ModalConfig,
		private dynamicModalRef: DynamicModalRef,
		private openEdgePaymentService: OpenEdgePaymentService,
		private userLocationsService: UserLocationsService,
		private creditCardFormService: CreditCardFormService,
		private toasterService: ToasterService,
	) {
		super();
	}

	ngOnInit(): void {
		this.parseConfig(this.modalConfig.data);
	}

	ngAfterViewInit() {
		this.loadConfiguration();
	}

	parseConfig(data: any) {
		this.personId = data.personId;
	}

	processPaymentDisabled() {
		return this.creditCardFormService.creditCardFormDisabled();
	}

	isCardFormLoaded() {
		return this.creditCardFormService.getCardFormLoaded();
	}

	isCreditCardProcessing() {
		return this.creditCardFormService.getCreditCardProcessing();
	}

	loadConfiguration() {
		this.openEdgePaymentService.globalPaymentsErrorSubject.pipe(
			takeUntil(this.unsubscribe)).subscribe(error => this.handleGlobalPaymentError(error),
		);
		this.openEdgePaymentService.configureOpenEdgeGlobalPayments();
		setTimeout(this.initializeCardForm);
	}

	initializeCardForm = () => {
		this.creditCardFormService.getCreditCardForm(this.createCardInfo, this.creditCardProcessingError);
	};

	handleGlobalPaymentError(error) {
		this.creditCardFormService.handleGlobalPaymentsError(error);
		this.errors = this.creditCardFormService.getCreditCardProcessingError();
	}

	saveCardInfo() {
		this.errors = [];
		this.creditCardFormService.processCard();
	}

	creditCardProcessingError = (errors) => {
		this.errors = errors;
	};

	createCardInfo = (temporaryToken: string) => {
		const request = new OpenEdgeCreateSavedCardRequest();
		request.personId = this.personId;
		request.temporaryToken = temporaryToken;
		request.practiceLocationId = this.userLocationsService.getCurrentUserLocation().id;

		this.openEdgePaymentService.createOpenEdgeSavedCard(request).subscribe(
			transaction => this.handleOpenEdgeTransaction(transaction),
		);
	};

	handleOpenEdgeTransaction(transaction: OpenEdgeSaveCardResponse) {
		if (_isNil(transaction.paymentStoredTokenId)) {
			this.errors = ['Credit card not saved. Please review and try again.'];
			this.creditCardFormService.setProcessing(false);
		} else {
			this.toasterService.showSavedSuccess();
			this.closeModal();
		}
	}

	/* istanbul ignore next: closeModal */
	closeModal() {
		this.creditCardFormService.disposeCardForm();
		this.dynamicModalRef.close(this.modal);
	}
}
