import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DebounceSearch } from '@core/debounce/debounce-search';
import { DiagnosisService } from '@core/diagnosis/diagnosis.service';
import { _filter, _includes } from '@core/lodash/lodash';
import { DynamicModalRef, ModalConfig, PAGE_LENGTH, PAGE_LENGTH_LIST } from 'morgana';
import { CodeSet } from '@gandalf/constants';
import { AssessmentPracticeDiagnosisResponse } from '@gandalf/model/assessment-practice-diagnosis-response';
import { FindPracticeDiagnosesRequest } from '@gandalf/model/find-practice-diagnoses-request';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { PageSettingsModel, RowSelectEventArgs } from '@syncfusion/ej2-angular-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
	selector: 'pms-select-diagnosis-modal',
	templateUrl: './select-diagnosis-modal.component.html',
	styles: [],
})
export class SelectDiagnosisModalComponent implements OnInit {
	@ViewChild('modal')
	modal: DialogComponent;

	codeSet: CodeSet;

	practiceDiagnoses: AssessmentPracticeDiagnosisResponse[];
	isSearching = true;
	filteredDiagnoses: AssessmentPracticeDiagnosisResponse[];
	debouncedFilter = new DebounceSearch(() => this.filterTable());
	codeFilter = '';
	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	pageSettings: PageSettingsModel = {
		pageSizes: PAGE_LENGTH_LIST,
		pageSize: PAGE_LENGTH.PAGE_10,

	};

	@Output()
	diagnosisSelected = new EventEmitter<AssessmentPracticeDiagnosisResponse>();

	constructor(
		private dynamicModalRef: DynamicModalRef,
		private config: ModalConfig,
		public diagnosisService: DiagnosisService,
	) {

	}

	ngOnInit() {
		this.parseConfigData(this.config.data);
		this.getDiagnoses();
	}

	/*istanbul ignore next*/
	parseConfigData(data) {
		this.codeSet = data.codeSet;
	}

	closeModal(selectedDiagnosis?: AssessmentPracticeDiagnosisResponse) {
		this.dynamicModalRef.close(this.modal, selectedDiagnosis);
	}

	getDiagnoses() {
		const request = new FindPracticeDiagnosesRequest();
		request.codeSet = this.codeSet;
		this.diagnosisService.findActivePracticeDiagnoses(request).subscribe((data: AssessmentPracticeDiagnosisResponse[]) => {
			this.practiceDiagnoses = data;
			this.filterTable();
			this.isSearching = false;
		});
	}

	filterTable(): void {
		if (this.codeFilter) {
			this.filteredDiagnoses = _filter(this.practiceDiagnoses, diagnosis => this.showDiagnosis(diagnosis));
		} else {
			this.filteredDiagnoses = this.practiceDiagnoses;
		}
	}

	/*istanbul ignore next*/
	showDiagnosis(diagnosis: AssessmentPracticeDiagnosisResponse): boolean {
		if (this.codeFilter) {
			return _includes(diagnosis.code.toLowerCase(), this.codeFilter.toLowerCase())
				|| _includes(diagnosis.shortDescription.toLowerCase(), this.codeFilter.toLowerCase());
		}
		return true;
	}

	/*istanbul ignore next*/
	debounceFilter(): void {
		this.debouncedFilter.valueChanged.next({
			codeFilter: this.codeFilter,
		});
	}

	/*istanbul ignore next*/
	onRowSelected(event: RowSelectEventArgs) {
		this.closeModal(event.data as AssessmentPracticeDiagnosisResponse);
	}
}
