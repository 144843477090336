<ng-container [formGroup]="formGroup">
	<div class="wrap">
		<div class="sidebar">
			<div class="list-group w200 hidden-xs affix">
				<a (click)="scrollTo(preferencesEncounters)" class="list-group-item">Encounters</a>
				<a (click)="scrollTo(preferencesGeneral)" class="list-group-item">General</a>
				<a (click)="scrollTo(preferencesPatients)" class="list-group-item">Patients</a>
				<a (click)="scrollTo(preferencesSchedule)" class="list-group-item">Schedule</a>
			</div>
		</div>

		<div class="main">
			<h4 #preferencesEncounters class="section-title bg-default mrgn-btm">Encounters</h4>
			<div class="row">
				<div class="col-sm-12">
					<div class="form-group">
						<pms-additional-preference-label
							[label]="PreferenceName.ENCOUNTER_FORWARD_TEST_NOTES"
							tooltipContent="Include test notes when bringing forward test data.">
						</pms-additional-preference-label>
						<div class="col-md-8">
							<rev-status-select-button
								[options]="preferenceYesNoOptions"
								[formControl]="getFormControl(PreferenceName.ENCOUNTER_FORWARD_TEST_NOTES)">
							</rev-status-select-button>
						</div>
					</div>
					<div class="form-group">
						<pms-additional-preference-label
							[label]="PreferenceName.ENCOUNTER_TEST_HISTORY_GRID"
							tooltipContent="Display test history in grid view.">
						</pms-additional-preference-label>
						<div class="col-md-8">
							<rev-status-select-button
								[options]="preferenceYesNoOptions"
								[formControl]="getFormControl(PreferenceName.ENCOUNTER_TEST_HISTORY_GRID)">
							</rev-status-select-button>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'alwaysUseSignatureFormGroup'">
						<pms-additional-preference-label
							[label]="PreferenceName.PROVIDER_ALWAYS_USE_SIGNATURE_IMAGE"
							tooltipContent="Always use my signature image on Rx printouts.">
						</pms-additional-preference-label>
						@if (!isSignatureFlagOn) {
							<div class="col-md-8">
								<rev-status-select-button
									[options]="preferenceYesNoOptions"
									[formControl]="getFormControl(PreferenceName.PROVIDER_ALWAYS_USE_SIGNATURE_IMAGE)">
								</rev-status-select-button>
							</div>
						}
						@if (isSignatureFlagOn) {
							<div class="col-sm-6">
                <span class="display-inline-block vertical-align-middle margin-x-xs margin-right-md">
                  <ejs-checkbox
					  label="Eyeglass"
					  name="eyeglassSignature"
					  [(ngModel)]="isEyeglassSignatureChecked"
					  [ngModelOptions]="{standalone:true}"
					  (ngModelChange)="onCheckboxChange(PreferenceName.PROVIDER_EYEGLASS_USE_SIGNATURE_IMAGE, isEyeglassSignatureChecked)"></ejs-checkbox>
                </span>
								<span class="display-inline-block vertical-align-middle margin-x-xs margin-right-md">
                  <ejs-checkbox
					  label="Contact Lens"
					  name="contactLens"
					  [(ngModel)]="isContactLensSignatureChecked"
					  [ngModelOptions]="{standalone:true}"
					  (ngModelChange)="onCheckboxChange(PreferenceName.PROVIDER_CONTACT_LENS_USE_SIGNATURE_IMAGE, isContactLensSignatureChecked)"></ejs-checkbox>
                </span>
								<span class="display-inline-block vertical-align-middle margin-x-xs margin-right-md">
                  <ejs-checkbox
					  label="Medication"
					  name="medicationSignature"
					  [(ngModel)]="isMedicationSignatureChecked"
					  [ngModelOptions]="{standalone:true}"
					  (ngModelChange)="onCheckboxChange(PreferenceName.PROVIDER_MEDICATION_USE_SIGNATURE_IMAGE, isMedicationSignatureChecked)"></ejs-checkbox>
                </span>
							</div>
						}
					</div>
					<div class="form-group" [attr.data-test-id]="'defaultAssessmentTabFormGroup'">
						<pms-additional-preference-label
							[label]="PreferenceName.AUTODX_DEFAULT_ASSESSMENT_TAB"
							tooltipContent="Determines the tab to display by default on the Assessment Screen.">
						</pms-additional-preference-label>
						<div class="col-md-8">
							<rev-status-select-button
								[options]="preferenceAutoDxDefaultTabOptions"
								[formControl]="getFormControl(PreferenceName.AUTODX_DEFAULT_ASSESSMENT_TAB)">
							</rev-status-select-button>
						</div>
					</div>
				</div>
			</div>

			<h4 #preferencesGeneral class="section-title bg-default mrgn-btm">General</h4>
			<div class="row">
				<div class="col-sm-12">
					<div class="form-group">
						<pms-additional-preference-label
							[label]="PreferenceName.REMOVE_USERNAME_ON_LOGOUT"
							tooltipContent="Remove username on logout.">
						</pms-additional-preference-label>
						<div class="col-md-8">
							<rev-status-select-button
								[options]="preferenceYesNoOptions"
								[formControl]="getFormControl(PreferenceName.REMOVE_USERNAME_ON_LOGOUT)">
							</rev-status-select-button>
						</div>
					</div>
					<div class="form-group">
						<pms-additional-preference-label
							[label]="PreferenceName.DOCUMENTS_DISPLAY_DETAILED_VIEW"
							tooltipContent="Documents display in detailed view by default.">
						</pms-additional-preference-label>
						<div class="col-md-8">
							<rev-status-select-button
								[options]="preferenceYesNoOptions"
								[formControl]="getFormControl(PreferenceName.DOCUMENTS_DISPLAY_DETAILED_VIEW)">
							</rev-status-select-button>
						</div>
					</div>

					<div class="form-group">
						<div class="col-md-4 control-label display-inline-block"><h4 class="media-heading margin-0">Date/Time Display</h4></div>
					</div>
					<div class="form-group">
						<pms-additional-preference-label
							[label]="PreferenceName.DATE_TIME_DISPLAY_SECONDS"
							tooltipContent="Display seconds in the Date/Time display.">
						</pms-additional-preference-label>
						<div class="col-md-8">
							<rev-status-select-button
								[options]="preferenceYesNoOptions"
								[formControl]="getFormControl(PreferenceName.DATE_TIME_DISPLAY_SECONDS)">
							</rev-status-select-button>
						</div>
					</div>
					<div class="form-group">
						<div class="col-md-4 control-label display-inline-block"><h4 class="media-heading margin-0">Banner Messages</h4></div>
					</div>
					<div class="form-group" [attr.data-test-id]="'bannerMessageDurationFormGroup'">
						<pms-additional-preference-label
							[label]="PreferenceName.TOAST_MESSAGES_DURATION"
							tooltipContent="The number of seconds a Banner Message is displayed before it is automatically dismissed. Setting duration to 0 results in banner message requiring manual dismissal.">
						</pms-additional-preference-label>
						<div class="col-md-8">
							<input
								type="number"
								class="e-input display-inline-block w75 vertical-align-middle"
								[min]="0"
								[max]="maxStepper"
								(change)="enforceRange(PreferenceName.TOAST_MESSAGES_DURATION)"
								[formControl]="getFormControl(PreferenceName.TOAST_MESSAGES_DURATION)"
								revDigitOnlyInput/>
						</div>
					</div>
					@if (showRevConnectSection()) {
						<div class="form-group" [attr.data-test-id]="'revConnectFormGroup'">
							<div class="col-md-4 control-label display-inline-block"><h4 class="media-heading margin-0">RevConnect</h4></div>
						</div>
						<div class="form-group" [attr.data-test-id]="'twoWayTextNotificationsFormGroup'">
							<pms-additional-preference-label
								[label]="PreferenceName.TWO_WAY_TEXT_NOTIFICATIONS"
								tooltipContent="Determines whether or not Two-Way Text banner notifications will display while logged in.">
							</pms-additional-preference-label>
							<div class="col-md-8">
								<rev-status-select-button
									[options]="preferenceYesNoOptions"
									[formControl]="getFormControl(PreferenceName.TWO_WAY_TEXT_NOTIFICATIONS)"
									[attr.data-test-id]="'enableTwoWayTextNotificationsButtons'">
								</rev-status-select-button>
							</div>
						</div>
					}
				</div>
			</div>

			<h4 #preferencesPatients class="section-title bg-default mrgn-btm">Patients</h4>
			<div class="row">
				<div class="col-sm-12">
					<div class="form-group" [attr.data-test-id]="'patientDefaultSummaryFormGroup'">
						<pms-additional-preference-label
							[label]="PreferenceName.PATIENTS_DEFAULT_SUMMARY_VIEW"
							tooltipContent="Determines default view for the Patients Summary screen.">
						</pms-additional-preference-label>
						<div class="col-md-8">
							<rev-status-select-button
								[options]="preferenceDefaultPatientViewOptions"
								[formControl]="getFormControl(PreferenceName.PATIENTS_DEFAULT_SUMMARY_VIEW)">
							</rev-status-select-button>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'opticalDefaultSummaryFormGroup'">
						<pms-additional-preference-label
							[label]="PreferenceName.OPTICAL_SUMMARY_DEFAULT_VIEW"
							tooltipContent="Determines default view for the Optical Summary screen.">
						</pms-additional-preference-label>
						<div class="col-md-8">
							<rev-status-select-button
								[options]="preferenceOpticalViewOptions"
								[formControl]="getFormControl(PreferenceName.OPTICAL_SUMMARY_DEFAULT_VIEW)">
							</rev-status-select-button>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'ongoingCareDefaultFormGroup'">
						<pms-additional-preference-label
							[label]="PreferenceName.ONGOINGCARE_DEFAULT_TAB"
							tooltipContent="Determines default view for the Ongoing Care screen.">
						</pms-additional-preference-label>
						<div class="col-md-8">
							<rev-status-select-button
								[options]="preferenceOngoingCareDefaultTabViewOptions"
								[formControl]="getFormControl(PreferenceName.ONGOINGCARE_DEFAULT_TAB)">
							</rev-status-select-button>
						</div>
					</div>
					<div class="form-group">
						<pms-additional-preference-label
							[label]="PreferenceName.PATIENT_POD_FAMILYCONTACTS_ENABLED"
							tooltipContent="Show the Family/Contacts pod on the patient summary.">
						</pms-additional-preference-label>
						<div class="col-md-8">
							<rev-status-select-button
								[options]="preferenceYesNoOptions"
								[formControl]="getFormControl(PreferenceName.PATIENT_POD_FAMILYCONTACTS_ENABLED)">
							</rev-status-select-button>
						</div>
					</div>
					<div class="form-group">
						<pms-additional-preference-label
							[label]="PreferenceName.PATIENT_POD_RX_ENABLED"
							tooltipContent="Show the Rx pod on the patient summary.">
						</pms-additional-preference-label>
						<div class="col-md-8">
							<rev-status-select-button
								[options]="preferenceYesNoOptions"
								[formControl]="getFormControl(PreferenceName.PATIENT_POD_RX_ENABLED)">
							</rev-status-select-button>
						</div>
					</div>
					<div class="form-group">
						<pms-additional-preference-label
							[label]="PreferenceName.PATIENT_POD_ACCOUNT_ENABLED"
							tooltipContent="Show the Account pod on the patient summary.">
						</pms-additional-preference-label>
						<div class="col-md-8">
							<rev-status-select-button
								[options]="preferenceYesNoOptions"
								[formControl]="getFormControl(PreferenceName.PATIENT_POD_ACCOUNT_ENABLED)">
							</rev-status-select-button>
						</div>
					</div>
					<div class="form-group">
						<pms-additional-preference-label
							[label]="PreferenceName.PATIENT_POD_EXAMHISTORY_ENABLED"
							tooltipContent="Show the Exam History pod on the patient summary.">
						</pms-additional-preference-label>
						<div class="col-md-8">
							<rev-status-select-button
								[options]="preferenceYesNoOptions"
								[formControl]="getFormControl(PreferenceName.PATIENT_POD_EXAMHISTORY_ENABLED)">
							</rev-status-select-button>
						</div>
					</div>
					<div class="form-group">
						<pms-additional-preference-label
							[label]="PreferenceName.PATIENT_POD_NOTES_ENABLED"
							tooltipContent="Show the Notes pod on the patient summary.">
						</pms-additional-preference-label>
						<div class="col-md-8">
							<rev-status-select-button
								[options]="preferenceYesNoOptions"
								[formControl]="getFormControl(PreferenceName.PATIENT_POD_NOTES_ENABLED)">
							</rev-status-select-button>
						</div>
					</div>
					<div class="form-group">
						<pms-additional-preference-label
							[label]="PreferenceName.PATIENT_POD_INSURANCE_ENABLED"
							tooltipContent="Show the Insurance pod on the patient summary.">
						</pms-additional-preference-label>
						<div class="col-md-8">
							<rev-status-select-button
								[options]="preferenceYesNoOptions"
								[formControl]="getFormControl(PreferenceName.PATIENT_POD_INSURANCE_ENABLED)">
							</rev-status-select-button>
						</div>
					</div>
					<div class="form-group">
						<pms-additional-preference-label
							[label]="PreferenceName.PATIENT_POD_DIAGNOSISHISTORY_ENABLED"
							tooltipContent="Show the Diagnosis History pod on the patient summary.">
						</pms-additional-preference-label>
						<div class="col-md-8">
							<rev-status-select-button
								[options]="preferenceYesNoOptions"
								[formControl]="getFormControl(PreferenceName.PATIENT_POD_DIAGNOSISHISTORY_ENABLED)">
							</rev-status-select-button>
						</div>
					</div>
					<div class="form-group">
						<pms-additional-preference-label
							[label]="PreferenceName.PATIENT_POD_RECALL_ENABLED"
							tooltipContent="Show the Recall pod on the patient summary.">
						</pms-additional-preference-label>
						<div class="col-md-8">
							<rev-status-select-button
								[options]="preferenceYesNoOptions"
								[formControl]="getFormControl(PreferenceName.PATIENT_POD_RECALL_ENABLED)">
							</rev-status-select-button>
						</div>
					</div>
					<div class="form-group">
						<pms-additional-preference-label
							[label]="PreferenceName.PATIENT_POD_LABORDERS_ENABLED"
							tooltipContent="Show the Orders-Medical pod on the patient summary.">
						</pms-additional-preference-label>
						<div class="col-md-8">
							<rev-status-select-button
								[options]="preferenceYesNoOptions"
								[formControl]="getFormControl(PreferenceName.PATIENT_POD_LABORDERS_ENABLED)">
							</rev-status-select-button>
						</div>
					</div>
					<div class="form-group">
						<pms-additional-preference-label
							[label]="PreferenceName.PATIENT_POD_OPTICALORDERS_ENABLED"
							tooltipContent="Show the Orders-Optical pod on the patient summary.">
						</pms-additional-preference-label>
						<div class="col-md-8">
							<rev-status-select-button
								[options]="preferenceYesNoOptions"
								[formControl]="getFormControl(PreferenceName.PATIENT_POD_OPTICALORDERS_ENABLED)">
							</rev-status-select-button>
						</div>
					</div>
					<div class="form-group">
						<pms-additional-preference-label
							[label]="PreferenceName.PATIENT_POD_TASKS_ENABLED"
							tooltipContent="Show the Tasks pod on the patient summary.">
						</pms-additional-preference-label>
						<div class="col-md-8">
							<rev-status-select-button
								[options]="preferenceYesNoOptions"
								[formControl]="getFormControl(PreferenceName.PATIENT_POD_TASKS_ENABLED)">
							</rev-status-select-button>
						</div>
					</div>
					@if (revConnectEnabled) {
						<div class="form-group">
							<pms-additional-preference-label
								[label]="PreferenceName.PATIENT_POD_CONNECT_ENABLED"
								tooltipContent="Show the RevConnect pod on the patient summary.">
							</pms-additional-preference-label>
							<div class="col-md-8">
								<rev-status-select-button
									[options]="preferenceYesNoOptions"
									[formControl]="getFormControl(PreferenceName.PATIENT_POD_CONNECT_ENABLED)"
									[attr.data-test-id]="'enableRevConnectButtons'">
								</rev-status-select-button>
							</div>
						</div>
					}
					<div class="form-group" [attr.data-test-id]="'ccdaDisplayFormGroup'">
						<pms-additional-preference-label
							[label]="PreferenceName.EMPLOYEE_CCDA_DISPLAY"
							tooltipContent="Choose the order of sections and which sections to display in C-CDA documents.">
						</pms-additional-preference-label>
						<div class="col-md-8">
							<button (click)="openCcdaModal()" ejs-button type="button" cssClass="e-link">View Defaults</button>
						</div>
					</div>
				</div>
			</div>

			<h4 #preferencesSchedule class="section-title bg-default mrgn-btm">Schedule</h4>
			<div class="row">
				<div class="col-sm-12">
					@if (!isUserProfile) {
						<div class="form-group">
							<pms-additional-preference-label
								[label]="PreferenceName.SCHEDULE_VALIDATION_ENABLED"
								[tooltipContent]="'Enable or Disable scheduler validation.'">
							</pms-additional-preference-label>
							<div class="col-md-8">
								<rev-status-select-button
									[options]="preferenceYesNoOptions"
									(onChange)="onScheduleValidationEnabledChange()"
									[formControl]="getFormControl(PreferenceName.SCHEDULE_VALIDATION_ENABLED)">
								</rev-status-select-button>
							</div>
						</div>
					}
					@if (!isUserProfile) {
						<div class="form-group">
							<pms-additional-preference-label
								[label]="PreferenceName.SCHEDULE_OFFICE_HOURS"
								[tooltipContent]="'Enable or Disable the start or end time of the appointment falls during closed time for the office hours.'">
							</pms-additional-preference-label>
							<div class="col-md-8">
								<rev-status-select-button
									[options]="preferenceYesNoOptions"
									[formControl]="getFormControl(PreferenceName.SCHEDULE_OFFICE_HOURS)">
								</rev-status-select-button>
							</div>
						</div>
					}
					@if (!isUserProfile) {
						<div class="form-group">
							<pms-additional-preference-label
								[label]="PreferenceName.SCHEDULE_EMPLOYEE_HOURS"
								[tooltipContent]="'Enable or Disable the start or end time of the appointment does not fall in the configured range for employee hours for the provider associated to the appointment.'">
							</pms-additional-preference-label>
							<div class="col-md-8">
								<rev-status-select-button
									[options]="preferenceYesNoOptions"
									[formControl]="getFormControl(PreferenceName.SCHEDULE_EMPLOYEE_HOURS)">
								</rev-status-select-button>
							</div>
						</div>
					}
					@if (!isUserProfile) {
						<div class="form-group">
							<pms-additional-preference-label
								[label]="PreferenceName.SCHEDULE_BUSY_TIME"
								[tooltipContent]="'Enable or Disable the start or end time of the appointment falls during a Busy time that has been configured for the provider associated to the appointment.'">
							</pms-additional-preference-label>
							<div class="col-md-8">
								<rev-status-select-button
									[options]="preferenceYesNoOptions"
									[formControl]="getFormControl(PreferenceName.SCHEDULE_BUSY_TIME)">
								</rev-status-select-button>
							</div>
						</div>
					}
					@if (!isUserProfile) {
						<div class="form-group">
							<pms-additional-preference-label
								[label]="PreferenceName.SCHEDULE_MULTIPLE_ACTIVE_APPOINTMENTS"
								[tooltipContent]="'Enable or Disable the validation of multiple active appointments for a patient.'">
							</pms-additional-preference-label>
							<div class="col-md-8">
								<rev-status-select-button
									[options]="preferenceYesNoOptions"
									[formControl]="getFormControl(PreferenceName.SCHEDULE_MULTIPLE_ACTIVE_APPOINTMENTS)">
								</rev-status-select-button>
							</div>
						</div>
					}
					<div class="form-group">
						<pms-additional-preference-label
							[label]="PreferenceName.SCHEDULE_MY_PATIENTS_ONLY"
							tooltipContent="Today's list shows my patients only.">
						</pms-additional-preference-label>
						<div class="col-md-8">
							<rev-status-select-button
								[options]="preferenceYesNoOptions"
								[formControl]="getFormControl(PreferenceName.SCHEDULE_MY_PATIENTS_ONLY)">
							</rev-status-select-button>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'scheduleFilterFormGroup'">
						<pms-additional-preference-label
							[label]="PreferenceName.SCHEDULE_FILTER_DEFAULT"
							tooltipContent="The default filter when viewing the schedule.">
						</pms-additional-preference-label>
						<div class="col-md-8">
							<button (click)="openCustomFilterModal()" ejs-button type="button" cssClass="e-link">View Filters</button>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'schedulePodDefaultFormGroup'">
						<pms-additional-preference-label
							[label]="PreferenceName.SCHEDULEPOD_TAB"
							tooltipContent="Determines what tab is defaulted in the homepage schedule pod.">
						</pms-additional-preference-label>
						<div class="col-md-8">
							<rev-status-select-button
								[options]="preferenceSchedulePodDefaultTabOptions"
								[formControl]="getFormControl(PreferenceName.SCHEDULEPOD_TAB)">
							</rev-status-select-button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="clearfix"></div>
	</div>
</ng-container>
