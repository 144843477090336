// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { InvoiceItemResponse } from './invoice-item-response';

/** See com.rev360.pms.api.controller.accounting.InvoiceResponse */
/* istanbul ignore next */
@GandalfModel
export class InvoiceResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	adjustmentTotalAmount: number;

	@GandalfProperty()
	age: number;

	@GandalfProperty({ propertyType: 'Money' })
	amountPaid: number;

	@GandalfProperty()
	appointmentId: number;

	@GandalfProperty()
	approved: boolean;

	@GandalfProperty({ propertyType: 'Money' })
	balance: number;

	@GandalfProperty({ propertyType: 'Money' })
	discountTotalAmount: number;

	@GandalfProperty()
	encounterId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	feeScheduleDate: Date;

	@GandalfProperty()
	feeScheduleId: number;

	@GandalfProperty()
	feeScheduleName: string;

	@GandalfProperty()
	financeChargePlanId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	firstStatementPrintDate: Date;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	insurancePriorityValue: string;

	@GandalfProperty()
	insuranceTypeValue: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	invoiceDate: Date;

	@GandalfArray(InvoiceItemResponse)
	items: InvoiceItemResponse[];

	@GandalfProperty()
	locationId: number;

	@GandalfProperty()
	locationName: string;

	@GandalfProperty()
	orderId: number;

	@GandalfProperty()
	patientId: number;

	@GandalfProperty()
	payerAddress: AddressResponse;

	@GandalfProperty()
	payerAllowItemDiscounts: boolean;

	@GandalfProperty()
	payerAllowTransferTax: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	payerDefaultPaymentMethod: constants.PaymentMethodType;

	@GandalfProperty()
	payerEmail: string;

	@GandalfProperty()
	payerEntityId: number;

	@GandalfProperty()
	payerId: number;

	@GandalfProperty()
	payerName: string;

	@GandalfProperty()
	payerPhone: string;

	@GandalfProperty()
	payerPolicyFeeScheduleId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	payerType: constants.PayerType;

	@GandalfProperty()
	personInsuranceId: number;

	@GandalfProperty()
	providerId: number;

	@GandalfProperty()
	providerName: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	serviceDate: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	statementPrintDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.InvoiceStatus;

	@GandalfProperty({ propertyType: 'Money' })
	subTotalAmount: number;

	@GandalfProperty({ propertyType: 'Money' })
	taxTotalAmount: number;

	@GandalfProperty({ propertyType: 'Money' })
	totalAmount: number;

	@GandalfProperty()
	transferTaxInvoiceId: number;

}
