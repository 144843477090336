// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.CanSendPatientMessageRequest */
/* istanbul ignore next */
@GandalfModel
export class CanSendPatientMessageRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: '{jakarta.validation.constraints.NotNull.message}' } })
	@GandalfProperty({ isRequired: true })
	communicationCategory: number;

	@GandalfValidator({ notNull: { message: '{jakarta.validation.constraints.NotNull.message}' } })
	@GandalfProperty({ isRequired: true })
	communicationTarget: number;

	@GandalfValidator({ notNull: { message: '{jakarta.validation.constraints.NotNull.message}' } })
	@GandalfProperty({ isRequired: true })
	patientId: number;

}
