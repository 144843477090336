// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.admin.vendorsandpartners.insurancecompanies.practiceinsuranceremittancecodeadjustment.PracticeInsuranceRemittanceCodeAdjustmentResponse */
/* istanbul ignore next */
@GandalfModel
export class PracticeInsuranceRemittanceCodeAdjustmentResponse extends GandalfModelBase {

	@GandalfProperty()
	accountingAdjustmentReason: ReferenceDataResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	autoPostingAdjustmentType: constants.PaymentTransferMethodType;

	@GandalfProperty()
	description: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	groupCode: constants.RemittanceAdjustmentGroupCode;

	@GandalfProperty()
	isActive: boolean;

	@GandalfProperty()
	practiceInsuranceRemittanceCodeAdjustmentId: number;

	@GandalfProperty()
	x12Code: string;

}
