// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.BillableItemProductResponse */
/* istanbul ignore next */
@GandalfModel
export class BillableItemProductResponse extends GandalfModelBase {

	@GandalfProperty()
	billableItemId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	billableItemStatus: constants.BillableItemStatus;

	@GandalfProperty()
	code: string;

	@GandalfProperty()
	description: string;

	@GandalfProperty()
	invoiceId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	invoiceStatus: constants.BillingInvoiceStatus;

	@GandalfProperty()
	nonBillable: boolean;

	@GandalfProperty()
	nonBillableReason: string;

	@GandalfProperty()
	payerAssigned: string;

	@GandalfProperty({ propertyType: 'Money' })
	retailPrice: number;

}
