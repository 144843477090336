import { Big } from 'big.js';
import { _get, _isNil, _reduce } from '../lodash/lodash';

const CURRENCY_PRECISION = 2;

export class MoneyUtil {

	/**
	 * Sums specified currency fields in provided list of object items.
	 */
	static sumCurrencyItems(list: any[], accessor: string): Big {
		return _reduce(list, (sum, item) => {
			const existingSum = this.roundCurrency(sum);
			let value = _get(item, accessor);
			// if the value is undefined, treat it as 0 so the summation can continue
			value = _isNil(value) ? 0 : value;
			if (typeof value !== 'number') {
				throw new Error(`Identified item was not a number`);
			}
			const newItem = this.roundCurrency(value);
			return existingSum.plus(newItem);
		}, Big(0));
	}

	/**
	 * Rounds currency value to two decimal places using half-up rounding
	 * i.e. 1.235 will round to 1.24 and 1.232 will round to 1.23
	 */
	static roundCurrency(value: Big | number): Big {
		return _isNil(value) ? Big(0) : Big(value).round(CURRENCY_PRECISION, Big.roundHalfUp);
	}

	/**
	 * Gets sum total for array of currency values
	 */
	static sumCurrencyValues(values: (Big | number)[]): Big {
		return Big(values.reduce(
			(sum, item) => {
				const existingSum = this.roundCurrency(sum);
				const newItem = this.roundCurrency(item);
				return existingSum.plus(newItem);
			}, 0,
		));
	}
}

