import { Injectable } from '@angular/core';
import { EyeglassPrescriptionGandalfService, OrderPatientPupillaryDistanceGandalfService } from '@gandalf/services';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class EyeglassLensRxService {

	private openRxSelectModalSubject = new Subject<void>();

	constructor(
		public eyeglassPrescriptionGandalfService: EyeglassPrescriptionGandalfService,
		private orderPatientPupillaryDistanceGandalfService: OrderPatientPupillaryDistanceGandalfService,
	) {
	}

	/* istanbul ignore next: getter */
	getOpenRxSelectModalSubject() {
		return this.openRxSelectModalSubject;
	}

	/* istanbul ignore next: next */
	openRxSelectModal() {
		this.openRxSelectModalSubject.next();
	}

	/* istanbul ignore next: gandalf */
	getPrescriptionById(prescriptionId) {
		return this.eyeglassPrescriptionGandalfService.getEyeglassPrescriptionById(prescriptionId);
	}

	/* istanbul ignore next: gandalf */
	getMostRecentEyeglassPrescription(patientId) {
		return this.eyeglassPrescriptionGandalfService.findLastNotStoppedEyeglassPrescription(patientId);
	}

	/* istanbul ignore next: gandalf */
	getPupillaryDistanceHistoryByPatientId(patientId) {
		return this.orderPatientPupillaryDistanceGandalfService.findPupillaryDistanceHistoryByPatientId(patientId);
	}
}
