import { InvoicesSharedModule } from '@accounting/invoices/invoices-shared.module';
import { NgModule } from '@angular/core';
import { invoicesEffects } from '@invoices-store/effects';
import { reducers } from '@invoices-store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '@shared/shared.module';
import { InvoicesRoutingModule } from './invoices-routing.module';

@NgModule({
	imports: [
		EffectsModule.forFeature(invoicesEffects),
		InvoicesRoutingModule,
		InvoicesSharedModule,
		SharedModule,
		StoreModule.forFeature('invoices', reducers),
	],
})
export class InvoicesModule {
}
