<div class="table-responsive margin-bottom-md">
	<table class="table table-hover table-nowrap table-bordered text-right margin-0" [attr.data-test-id]="'contactLensTypeHybridTable'">
		<thead>
		<tr>
			<th></th>
			<th>MV</th>
			<th class="text-right">BC</th>
			<th class="text-right">Vault</th>
			<th class="text-right">Sph</th>
			<th class="text-right">Diam</th>
			<th class="text-right">Add</th>
			<th>Add Zn Sz</th>
			<th>Seg Sz</th>
			<th>Skirt Curve</th>
			<th>Design</th>
		</tr>
		</thead>
		<tbody>
		<tr>
			<td class="nostretch text-left">
				<span class="label label-success">OD</span><br>
				<span class="label label-info">OS</span>
			</td>
			<td class="text-left">
				{{ contactLensPrescription.contactLensEyeHybridOd?.isMonovision | yesNo : 'Yes' : '' }}<br>
				{{ contactLensPrescription.contactLensEyeHybridOs?.isMonovision | yesNo : 'Yes' : '' }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeHybridOd?.baseCurve | baseCurve }}<br>
				{{ contactLensPrescription.contactLensEyeHybridOs?.baseCurve | baseCurve }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeHybridOd?.vault }}<br>
				{{ contactLensPrescription.contactLensEyeHybridOs?.vault }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeHybridOd?.sphere | plusMinus }}<br>
				{{ contactLensPrescription.contactLensEyeHybridOs?.sphere | plusMinus }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeHybridOd?.diameter | forceDecimalNullSafe : 1 }}<br>
				{{ contactLensPrescription.contactLensEyeHybridOs?.diameter | forceDecimalNullSafe : 1 }}
			</td>
			<td>
				{{ contactLensPrescription.contactLensEyeHybridOd?.addPower | plusMinus }}<br>
				{{ contactLensPrescription.contactLensEyeHybridOs?.addPower | plusMinus }}
			</td>
			<td class="text-left">
				{{ contactLensPrescription.contactLensEyeHybridOd?.addZoneSize?.value }}<br>
				{{ contactLensPrescription.contactLensEyeHybridOs?.addZoneSize?.value }}
			</td>
			<td class="text-left">
				{{ contactLensPrescription.contactLensEyeHybridOd?.segmentSize?.value }}<br>
				{{ contactLensPrescription.contactLensEyeHybridOs?.segmentSize?.value }}
			</td>
			<td class="text-left">
				{{ contactLensPrescription.contactLensEyeHybridOd?.skirtCurveRadius?.value }}<br>
				{{ contactLensPrescription.contactLensEyeHybridOs?.skirtCurveRadius?.value }}
			</td>
			<td class="text-left">
				{{ contactLensPrescription.contactLensEyeHybridOd?.design?.value }}<br>
				{{ contactLensPrescription.contactLensEyeHybridOs?.design?.value }}
			</td>
		</tr>
		</tbody>
	</table>
</div>
