import { Injectable } from '@angular/core';
import { OptionItem, SortingService } from 'morgana';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { ReferenceDataMasterCategory } from '@gandalf/constants';
import { CreateReferenceDataRequest } from '@gandalf/model/create-reference-data-request';
import { ReferenceDataResponse } from '@gandalf/model/reference-data-response';
import { UpdateReferenceDataRequest } from '@gandalf/model/update-reference-data-request';
import { MasterReferenceDataGandalfService, ReferenceDataGandalfService } from '@gandalf/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OptionItemResponse } from '@core/option-item/option-item.service';

export type ReferenceDataOptionItemLabel = 'value' | 'description';
export type ReferenceDataOptionItemValue = 'id' | 'description' | 'value';

export interface ReferenceDataResponseForTable extends ReferenceDataResponse {
	editableText: string;
}

@Injectable({
	providedIn: 'root',
})
export class ReferenceDataService {

	constructor(
		private referenceDataGandalfService: ReferenceDataGandalfService,
		private masterReferenceDataGandalfService: MasterReferenceDataGandalfService,
	) {
	}

	getReferenceDataByCategoryId(categoryId: number): Observable<ReferenceDataResponse[]> {
		return this.referenceDataGandalfService.findReferenceDataByCategoryId(categoryId).pipe(
			map(dataArray => SortingService.sortBy(dataArray, ['sortOrder', 'value', 'id'])),
		);
	}

	getActiveReferenceDataByCategoryId(categoryId: number): Observable<ReferenceDataResponse[]> {
		return this.referenceDataGandalfService.findActiveReferenceDataByCategoryId(categoryId).pipe(
			map(dataArray => SortingService.sortBy(dataArray, ['sortOrder', 'value', 'id'])),
		);
	}

	getReferenceDataByCategoryIdForDropdown(categoryId: number, markInactive = false): Observable<OptionItemResponse<ReferenceDataResponse>[]> {
		return this.getReferenceDataByCategoryId(categoryId).pipe(
			map(dataArray => dataArray.map(data => ({
				...data,
				label: ((markInactive && !data.active) ? '(Inactive) ' : '') + data.value,
				value: data.id,
			} as OptionItemResponse<ReferenceDataResponse>))),
		);
	}

	getActiveReferenceDataByCategoryIdForDropdown(categoryId: number): Observable<OptionItem[]> {
		return this.getActiveReferenceDataByCategoryId(categoryId).pipe(
			map(dataArray => dataArray.map(data => ({...data, label: data.value, value: data.id}))),
		);
	}

	@ShowSavedSuccessToast()
	createReferenceData(request: CreateReferenceDataRequest): Observable<ReferenceDataResponse> {
		return this.referenceDataGandalfService.createReferenceData(request);
	}

	@ShowSavedSuccessToast()
	updateReferenceData(request: UpdateReferenceDataRequest): Observable<ReferenceDataResponse> {
		return this.referenceDataGandalfService.updateReferenceData(request);
	}

	/**
	 * To Option Items
	 * Takes an array of ReferenceData items and converts them into an array
	 * of OptionItems.
	 * @param referenceData Array of reference data to process into OptionItems.
	 * @param includeInactive Whether or not to include the inactive reference data items.
	 */
	toOptionItems(referenceData: ReferenceDataResponse[], includeInactive: boolean = false): OptionItem[] {
		return SortingService.sortBy(referenceData.filter(data => includeInactive ? true : data.active), 'sortOrder')
			.map(data => ({label: data.value, value: data.id}));
	}

	/**
	 * To Custom Select Items
	 * Takes an array of ReferenceData items and converts them into an array
	 * of OptionItems using the provided configuration
	 * @param referenceData ReferenceData list of items to convert.
	 * @param labelProperty Property to use for the label select item property.
	 * @param valueProperty Property to use for the value select item property.
	 * @param includeInactive Whether or not to include the inactive reference data items.
	 */
	toCustomOptionItems(
		referenceData: ReferenceDataResponse[],
		labelProperty: ReferenceDataOptionItemLabel,
		valueProperty: ReferenceDataOptionItemValue,
		includeInactive: boolean = false,
	): OptionItem[] {
		return SortingService.sortBy(referenceData.filter(data => includeInactive ? true : data.active), ['sortOrder'])
			.map(data => ({label: data[labelProperty], value: data[valueProperty]}));
	}

	getMasterReferenceDataByCategory(category: ReferenceDataMasterCategory): Observable<OptionItem[]> {
		return this.masterReferenceDataGandalfService.findMasterReferenceDataByCategoryId(category.value).pipe(
			map(dataArray => SortingService.sortBy(dataArray, ['sortOrder', 'value', 'id'])),
			map(dataArray => dataArray.map(data => ({
				label: data.value,
				value: data.id,
			}))),
		);
	}

	getActiveMasterReferenceDataByCategory(category: ReferenceDataMasterCategory): Observable<OptionItem[]> {
		return this.masterReferenceDataGandalfService.findMasterReferenceDataByCategoryId(category.value).pipe(
			map(dataArray => dataArray.filter(data => data.active)),
			map(dataArray => SortingService.sortBy(dataArray, ['sortOrder', 'value', 'id'])),
			map(dataArray => dataArray.map(data => ({label: data.value, value: data.id}))),
		);
	}
}
