import { ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { _isNil } from '@core/lodash/lodash';
import { ForceDecimalPipe } from 'morgana';

@Component({
	selector: 'pms-currency-input',
	templateUrl: './currency-input.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CurrencyInputComponent),
			multi: true,
		},
	],
})
export class CurrencyInputComponent implements OnInit, ControlValueAccessor {

	@ViewChild('inputElement')
	inputElement: ElementRef;

	@Input()
	htmlId: string;

	@Input()
	placeholder: string;

	@Input()
	min: number;

	@Input()
	nanValue = '0.00';

	@Output()
	valueChange = new EventEmitter();

	@Output()
	inputBlur = new EventEmitter();

	currencyString: string;

	disabled = false;

	constructor(
		private forceDecimal: ForceDecimalPipe,
		private changeDetectorRef: ChangeDetectorRef,
	) {
	}

	ngOnInit() {
		if (_isNil(this.placeholder)) {
			this.placeholder = '0.00';
		}
	}

	onModelChange: (_: any) => void = () => {
	};

	onModelTouched: () => void = () => {
	};

	@HostListener('mousewheel', ['$event'])
	onMouseWheelChrome(event: any) {
		this.disableScroll(event);
	}

	@HostListener('DOMMouseScroll', ['$event'])
	onMouseWheelFirefox(event: any) {
		this.disableScroll(event);
	}

	@HostListener('onmousewheel', ['$event'])
	onMouseWheelIE(event: any) {
		this.disableScroll(event);
	}

	disableScroll(event) {
		if (event.target.type === 'number') {
			event.preventDefault();
		}
	}

	writeValue(value: number): void {
		if (!_isNil(this.min) && value < this.min) {
			this.writeValue(this.min);
		} else {
			this.currencyString = !_isNil(value) ? value.toString() : null;
			if (this.currencyString) {
				this.formatCost();
			}
			this.changeDetectorRef.markForCheck();
		}
	}

	registerOnChange(fn: (_: any) => void): void {
		this.onModelChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onModelTouched = fn;
	}

	onblur() {
		this.formatCost();
		this.inputBlur.emit();
	}

	formatCost() {
		const valueNumber = parseFloat(this.currencyString);

		if (!_isNil(this.min) && valueNumber < this.min) {
			this.writeValue(this.min);
		} else {
			this.currencyString = this.forceDecimal.transform(valueNumber, 2, this.nanValue);
			this.onModelChange(parseFloat(this.currencyString));
			this.valueChange.emit();
		}
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	setFocus() {
		this.inputElement.nativeElement.focus();
	}
}
