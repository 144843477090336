// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { TwoWayTextConversationResponse } from './two-way-text-conversation-response';

// @ts-ignore
import { TwoWayTextNewConversationPatientResponse } from './two-way-text-new-conversation-patient-response';

/** See com.rev360.pms.api.controller.twowaytext.TwoWayTextConversationOrPatientMatchesResponse */
/* istanbul ignore next */
@GandalfModel
export class TwoWayTextConversationOrPatientMatchesResponse extends GandalfModelBase {

	@GandalfProperty()
	conversation: TwoWayTextConversationResponse;

	@GandalfProperty()
	conversationInaccessible: boolean;

	@GandalfProperty()
	hasPatientWithInaccessibleLocations: boolean;

	@GandalfArray(TwoWayTextNewConversationPatientResponse)
	patients: TwoWayTextNewConversationPatientResponse[];

}
