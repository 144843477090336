// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { PatientEmploymentResponse } from './patient-employment-response';

// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { PhoneNumbersResponse } from './phone-numbers-response';

// @ts-ignore
import { PracticeLocationSummaryResponse } from './practice-location-summary-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

// @ts-ignore
import { ReferenceDataSummaryResponse } from './reference-data-summary-response';

/** See com.rev360.pms.api.controller.patient.PatientDashboardSummaryResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientDashboardSummaryResponse extends GandalfModelBase {

	@GandalfProperty()
	allowLogin: boolean;

	@GandalfProperty()
	comment: string;

	@GandalfProperty()
	dateOfBirth: Date;

	@GandalfProperty()
	dominantEye: ReferenceDataSummaryResponse;

	@GandalfProperty()
	dominantHand: ReferenceDataSummaryResponse;

	@GandalfProperty()
	email: string;

	@GandalfProperty()
	emailValid: boolean;

	@GandalfProperty()
	employment: PatientEmploymentResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	gender: constants.Gender;

	@GandalfProperty()
	guarantorId: number;

	@GandalfProperty()
	hasFullSsn: boolean;

	@GandalfProperty()
	homeAddress: AddressResponse;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	isOnWaitList: boolean;

	@GandalfProperty()
	last4ssn: string;

	@GandalfProperty()
	name: PatientNameResponse;

	@GandalfProperty()
	personId: number;

	@GandalfProperty()
	phoneNumbers: PhoneNumbersResponse;

	@GandalfProperty()
	photoId: number;

	@GandalfProperty()
	primaryLocation: PracticeLocationSummaryResponse;

	@GandalfProperty()
	primaryProvider: ProviderResponse;

	@GandalfProperty()
	sin: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.PatientStatus;

	@GandalfProperty()
	tempAddress: AddressResponse;

	@GandalfProperty()
	userName: string;

}
