// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.reporting.accounting.SalesReportResponse */
/* istanbul ignore next */
@GandalfModel
export class SalesReportResponse extends GandalfModelBase {

	@GandalfProperty()
	adjustmentId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	adjustmentType: constants.InvoiceItemAdjustmentType;

	@GandalfProperty({ propertyType: 'Money' })
	adjustments: number;

	@GandalfProperty()
	categoryId: string;

	@GandalfProperty()
	categoryName: string;

	@GandalfProperty({ propertyType: 'Money' })
	discounts: number;

	@GandalfProperty({ propertyType: 'Money' })
	gross: number;

	@GandalfProperty()
	invoiceId: number;

	@GandalfProperty()
	itemDescription: string;

	@GandalfProperty()
	itemId: number;

	@GandalfProperty()
	itemName: string;

	@GandalfProperty()
	itemNameId: string;

	@GandalfProperty()
	itemTaxId: number;

	@GandalfProperty()
	itemTaxName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	itemType: constants.InvoiceItemType;

	@GandalfProperty()
	locationId: number;

	@GandalfProperty()
	locationName: string;

	@GandalfProperty()
	manufacturerId: number;

	@GandalfProperty()
	manufacturerName: string;

	@GandalfProperty({ propertyType: 'Money' })
	net: number;

	@GandalfProperty()
	patient: PatientNameResponse;

	@GandalfProperty()
	payerEntityId: number;

	@GandalfProperty()
	payerId: number;

	@GandalfProperty()
	payerName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	payerType: constants.PayerType;

	@GandalfProperty()
	provider: PersonNameResponse;

	@GandalfProperty()
	qtySold: number;

	@GandalfProperty()
	reportCategoryId: string;

	@GandalfProperty()
	reportCategoryName: string;

	@GandalfProperty()
	reportName: string;

	@GandalfProperty()
	reportNameId: string;

	@GandalfProperty()
	reportType: string;

	@GandalfProperty()
	reportTypeString: string;

	@GandalfProperty()
	responsibleForSale: PersonNameResponse;

	@GandalfProperty({ propertyType: 'Money' })
	taxes: number;

}
