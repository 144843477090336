<ng-container *ngIf="!isAgGridFeatureFlagOn">
	<ejs-grid #grid
			  autoFitColumns
			  [autoFitBlacklist]="['comments']"
			  [staticColumns]="['buttons', 'authorizedBy']"
			  [dataSource]="tableList"
			  [pmsGridSubmit]="isSearching"
			  gridLines="Horizontal"
			  [allowSorting]="true"
			  [allowSelection]="true"
			  [allowPaging]="true"
			  [pageSettings]="pageSettings"
			  [allowResizing]="true"
			  [allowFiltering]="true"
			  class="table-rowlink"
			  [filterSettings]="{showFilterBarStatus: false}"
			  (rowSelected)="rowSelected($event.data)"
			  [attr.data-test-id]="'contactLensHistoryModalGrid'">
		<ng-template #toolbarTemplate let-data>
			<rev-table-action-menu [table]="grid">
				<ng-container rev-table-action-menu-left>
					<div class="e-toolbar-item">
						<rev-status-select-button
							[(ngModel)]="currentFilter"
							[ngModelOptions]="{standalone: true}"
							[options]="filterOptions"
							(onChange)="filterTable()">
						</rev-status-select-button>
					</div>
					<div class="e-toolbar-item">
						<ejs-checkbox
							[ngModel]="showDiscontinued"
							label="Show Discontinued"
							(checkedChange)="discontinuedChange($event)">
						</ejs-checkbox>
					</div>
				</ng-container>
			</rev-table-action-menu>
		</ng-template>
		<e-columns>
			<e-column field="startDate" headerText="Date" [format]="tableDateFormat"></e-column>
			<e-column field="status" headerText="Status"></e-column>
			<e-column field="authorizedBy" headerText="Auth By" width="175">
				<ng-template #template let-data>
					{{data.authorizedBy | providerName}}
					<div *ngIf="useButtons && !data.authorizedBy">
						<rev-button
								*ngIf="showAuthorize(data)"
								buttonLabel="Auth"
								buttonStyle="primary"
								revEventStopPropagation
								(buttonClick)="authorizeAndVerifyProduct(data)"
								dataTestId="prescriptionAuthorizeButton">
						</rev-button>
						<rev-button
								*ngIf="showAuthAndSign(data)"
								buttonLabel="Auth & Sign"
								buttonStyle="primary"
								revEventStopPropagation
								(buttonClick)="authorizeAndSignAndVerifyProduct(data)"
								dataTestId="prescriptionAuthorizeAndSignButton">
						</rev-button>
					</div>
				</ng-template>
			</e-column>
			<e-column field="osOd" headerText=""
					  [customAttributes]="{class: 'align-top'}">
				<ng-template #template let-data>
					<span class="label label-success"><span *ngIf="data.hasOd">OD</span></span>
					<br>
					<span class="label label-info" *ngIf="data.hasOs">OS</span>
				</ng-template>
			</e-column>
			<e-column field="locationProductName" headerText="Model" clipMode="EllipsisWithTooltip"
					  [customAttributes]="{class: 'align-top'}">
				<ng-template #template let-data>
					<span>
						{{ data.odLocationProductName }}
					</span>
					<br/>
					<span>
						{{ data.osLocationProductName }}
					</span>
				</ng-template>
			</e-column>

			<e-column field="overallLensType" headerText="Type"
					  [customAttributes]="{class: 'align-top'}">
				<ng-template #template let-data>
					<span *ngIf="data.hasOd">
						{{data.lensType}}
					</span>
					<br/>
					<span *ngIf="data.hasOs">
						{{data.lensType}}
					</span>
				</ng-template>
			</e-column>

			<e-column field="isMonovision" headerText="MV"
					  [customAttributes]="{class: 'align-top'}">
				<ng-template #template let-data>
					<span *ngIf="data.hasOd">
						{{ data.odIsMono | yesNo:undefined:'' }}
					</span>
					<br/>
					<span *ngIf="data.hasOs">
						{{data.osIsMono | yesNo:undefined:'' }}
					</span>
				</ng-template>
			</e-column>

			<e-column field="baseCurve" headerText="BC"
					  [customAttributes]="{class: 'align-top'}">
				<ng-template #template let-data>
					<span>
						{{PrescriptionUtil.formatBaseCurve(data.odBaseCurve)}}
					</span>
					<br/>
					<span>
						{{PrescriptionUtil.formatBaseCurve(data.osBaseCurve)}}
					</span>
				</ng-template>
			</e-column>

			<e-column field="sphere" headerText="Sph"
					  [customAttributes]="{class: 'align-top'}">
				<ng-template #template let-data>
					<span>
						{{PrescriptionUtil.formatSphere(data.odSphere)}}
					</span>
					<br/>
					<span>
						{{PrescriptionUtil.formatSphere(data.osSphere)}}
					</span>
				</ng-template>
			</e-column>

			<e-column field="cylinder" headerText="Cyl"
					  [customAttributes]="{class: 'align-top'}">
				<ng-template #template let-data>
					<span>
						{{PrescriptionUtil.formatCylinder(data.odCylinder)}}
					</span>
					<br/>
					<span>
						{{PrescriptionUtil.formatCylinder(data.osCylinder)}}
					</span>
				</ng-template>
			</e-column>

			<e-column field="axis" headerText="Axis"
					  [customAttributes]="{class: 'align-top'}">
				<ng-template #template let-data>
					<span>
						{{PrescriptionUtil.formatAxis(data.odAxis)}}
					</span>
					<br/>
					<span>
						{{PrescriptionUtil.formatAxis(data.osAxis)}}
					</span>
				</ng-template>
			</e-column>

			<e-column field="addDesignation" headerText="Add"
					  [customAttributes]="{class: 'align-top'}">
				<ng-template #template let-data>
					<span>
						{{data.odAddDesignation}}
					</span>
					<br/>
					<span>
						{{data.osAddDesignation}}
					</span>
				</ng-template>
			</e-column>

			<e-column field="diameter" headerText="Diam"
					  [customAttributes]="{class: 'align-top'}">
				<ng-template #template let-data>
					<span>
						{{ data.odDiameter | forceDecimalNullSafe : 1}}
					</span>
					<br/>
					<span>
						{{data.osDiameter | forceDecimalNullSafe : 1}}
					</span>
				</ng-template>
			</e-column>
			<!-- Additional columns -->
			<e-column [visible]="showExtraColumns" field="odDispensed" headerText="Disp"
					  [customAttributes]="{class: 'align-top'}">
				<ng-template #template let-data>
					<span>
						{{ data.odDispensed !== null ? (data.odDispensed | yesNo) : '' }}
					</span>
					<br/>
					<span>
						{{ data.osDispensed !== null ? (data.osDispensed | yesNo) : '' }}
					</span>
				</ng-template>
			</e-column>
			<e-column [visible]="showExtraColumns" field="phr" headerText="PHR">
				<ng-template #template let-data>
					<span>
						{{ data.isPHREnabled !== null ? (data.isPHREnabled | yesNo) : 'N/A' }}
					</span>
				</ng-template>
			</e-column>
			<e-column [visible]="showExtraColumns" field="comments" headerText="Comments" clipMode="EllipsisWithTooltip"
					  [customAttributes]="{class: 'align-top', autofitMinWidth: 100}">
				<ng-template #template let-data>
					<span>
						{{ data.odComments }}
					</span>
					<br/>
					<span>
						{{ data.osComments }}
					</span>
				</ng-template>
			</e-column>

			<e-column field="buttons" width="140" headerText="" [visible]="useButtons">
				<ng-template #template let-data>
					<rev-button
							*ngIf="showPrint(data)"
							[revGridButton]="'print'"
							(buttonClick)="print(data)"
							dataTestId="prescriptionPrintButton">
					</rev-button>
					<rev-button
							*ngIf="PrescriptionUtil.showCreateOrder(
								data.authType,
								data.prescriptionStatus,
								data.expirationDate,
								PrescriptionUtil.isContactLensTrial(data))"
							[revGridButton]="'createOrder'"
							(buttonClick)="createOrder(data)"
							dataTestId="prescriptionCreateOrderButton">
					</rev-button>
					<rev-button-menu
							*ngIf="data.buttonOptions.length > 0"
							[revGridButton]="'moreActions'"
							[buttonMenuItems]="data.buttonOptions"
							(menuItemClick)="ellipsesButtonAction(data, $event)"
							dataTestId="contactLensPrescriptionMenuButton">
					</rev-button-menu>
				</ng-template>
			</e-column>
		</e-columns>
	</ejs-grid>
</ng-container>

<ng-container *ngIf="isAgGridFeatureFlagOn">
	<rev-ag-grid-container [agGrid]="agGrid">
		<ng-container rev-table-action-menu-left>
			<div class="e-toolbar-item">
				<rev-status-select-button
						[(ngModel)]="currentFilter"
						[ngModelOptions]="{standalone: true}"
						[options]="filterOptions"
						(onChange)="filterTable()"
						[attr.data-test-id]="'contactLensHistoryTableStatusButton'">
				</rev-status-select-button>
			</div>
			<div class="e-toolbar-item">
				<ejs-checkbox
						[ngModel]="showDiscontinued"
						label="Show Discontinued"
						(checkedChange)="discontinuedChange($event)"
						[attr.data-test-id]="'showDiscontinuedCheckbox'">
				</ejs-checkbox>
			</div>
		</ng-container>
		<ng-container rev-container-grid>
			<ag-grid-angular class="ag-theme-alpine"
							 pmsAgAutoFitColumns
							 #agGrid
							 [pmsAgGridSubmit]="isSearching"
							 [rowData]="tableList"
							 (rowClicked)="agGridRowClicked($event)"
							 [gridOptions]="contactLensPrescriptionGridOptions"
							 (gridReady)="onGridReady($event)"
							 [pagination]="true"
							 [attr.data-test-id]="'contactLensPrescriptionTable'"
							 [suppressCsvExport]="true">
			</ag-grid-angular>
		</ng-container>
	</rev-ag-grid-container>
</ng-container>

<ng-template #authBy let-data>
	{{data.authorizedBy | providerName}}
	<div *ngIf="useButtons && !data.authorizedBy">
		<rev-button
				*ngIf="showAuthorize(data)"
				buttonLabel="Auth"
				buttonStyle="primary"
				revEventStopPropagation
				(buttonClick)="authorizeAndVerifyProduct(data)"
				dataTestId="prescriptionAuthorizeButton">
		</rev-button>
		<rev-button
				*ngIf="showAuthAndSign(data)"
				buttonLabel="Auth & Sign"
				buttonStyle="primary"
				revEventStopPropagation
				(buttonClick)="authorizeAndSignAndVerifyProduct(data)"
				dataTestId="prescriptionAuthorizeAndSignButton">
		</rev-button>
	</div>
</ng-template>

<ng-template #authByTooltip let-data>
	<div class="rev-custom-tooltip-content" *ngIf="data.authorizedBy">{{data.authorizedBy | providerName}}</div>
</ng-template>

<ng-template #osOd let-data>
	<span class="label label-success"><span *ngIf="data.hasOd">OD</span></span>
	<br>
	<span class="label label-info" *ngIf="data.hasOs">OS</span>
</ng-template>

<ng-template #model let-data>
	<span>{{ data.odLocationProductName }}</span>
	<br/>
	<span>{{ data.osLocationProductName }}</span>
</ng-template>

<ng-template #type let-data>
	<span *ngIf="data.hasOd">{{data.lensType}}</span>
	<br/>
	<span *ngIf="data.hasOs">{{data.lensType}}</span>
</ng-template>

<ng-template #mv let-data>
	<span *ngIf="data.hasOd">{{ data.odIsMono | yesNo:undefined:'' }}</span>
	<br/>
	<span *ngIf="data.hasOs">{{data.osIsMono | yesNo:undefined:'' }}</span>
</ng-template>

<ng-template #bc let-data>
	<span>{{PrescriptionUtil.formatBaseCurve(data.odBaseCurve)}}</span>
	<br/>
	<span>{{PrescriptionUtil.formatBaseCurve(data.osBaseCurve)}}</span>
</ng-template>

<ng-template #sph let-data>
	<span>{{PrescriptionUtil.formatSphere(data.odSphere)}}</span>
	<br/>
	<span>{{PrescriptionUtil.formatSphere(data.osSphere)}}</span>
</ng-template>

<ng-template #cyl let-data>
	<span>{{PrescriptionUtil.formatCylinder(data.odCylinder)}}</span>
	<br/>
	<span>{{PrescriptionUtil.formatCylinder(data.osCylinder)}}</span>
</ng-template>

<ng-template #axis let-data>
	<span>{{PrescriptionUtil.formatAxis(data.odAxis)}}</span>
	<br/>
	<span>{{PrescriptionUtil.formatAxis(data.osAxis)}}</span>
</ng-template>

<ng-template #add let-data>
	<span>{{data.odAddDesignation}}</span>
	<br/>
	<span>{{data.osAddDesignation}}</span>
</ng-template>

<ng-template #addTooltipTemplate let-data>
	<div class="rev-custom-tooltip-content">
		<span>{{data.odAddDesignation}}</span>
		<br/>
		<span>{{data.osAddDesignation}}</span>
	</div>
</ng-template>

<ng-template #diam let-data>
	<span>{{ data.odDiameter | forceDecimalNullSafe : 1}}</span>
	<br/>
	<span>{{data.osDiameter | forceDecimalNullSafe : 1}}</span>
</ng-template>

<ng-template #disp let-data>
	<span>{{ data.odDispensed !== null ? (data.odDispensed | yesNo) : '' }}</span>
	<br/>
	<span>{{ data.osDispensed !== null ? (data.osDispensed | yesNo) : '' }}</span>
</ng-template>

<ng-template #phr let-data>
	<span>{{ data.isPHREnabled !== null ? (data.isPHREnabled | yesNo) : 'N/A' }}</span>
</ng-template>

<ng-template #comments let-data>
	<span>{{ data.odComments }}</span>
	<br/>
	<span>{{ data.osComments }}</span>
</ng-template>

<ng-template #commentsTooltip let-data>
	<div class="rev-custom-tooltip-content">
		<span *ngIf="shouldDisplayOdComments(data.odComments)">{{ data.odComments }}</span>
		<br/>
		<span>{{ data.osComments }}</span>
	</div>
</ng-template>

<ng-template #actions let-data>
	<rev-button
			*ngIf="showPrint(data)"
			[revGridButton]="'print'"
			(buttonClick)="print(data)"
			dataTestId="prescriptionPrintButton">
	</rev-button>
	<rev-button
			*ngIf="PrescriptionUtil.showCreateOrder(
					data.authType,
					data.prescriptionStatus,
					data.expirationDate,
					PrescriptionUtil.isContactLensTrial(data))"
			[revGridButton]="'createOrder'"
			(buttonClick)="createOrder(data)"
			dataTestId="prescriptionCreateOrderButton">
	</rev-button>
	<rev-button-menu
			*ngIf="data.buttonOptions.length > 0"
			[revGridButton]="'moreActions'"
			[buttonMenuItems]="data.buttonOptions"
			(menuItemClick)="ellipsesButtonAction(data, $event)"
			dataTestId="contactLensPrescriptionMenuButton">
	</rev-button-menu>
</ng-template>
