import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { _get } from '@core/lodash/lodash';
import { DynamicModalRef, ModalConfig, ModalManagerService, PAGE_LENGTH, PAGE_LENGTH_LIST } from 'morgana';
import { SnomedCodeService } from '@core/snomed/snomed-code.service';
import { SnomedType } from '@gandalf/constants';
import { SearchSnomedCodesRequest } from '@gandalf/model/search-snomed-codes-request';
import { SnomedCodeResponse } from '@gandalf/model/snomed-code-response';
import { atLeastOne } from '@shared/validators/atleastOne.validation';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { PageSettingsModel } from '@syncfusion/ej2-grids';
import { GandalfFormBuilder } from 'gandalf';

export interface SelectSnomedCodeModalData {
	description?: string;
	type?: SnomedType;
}

@Component({
	selector: 'pms-select-snomed-code-modal',
	templateUrl: './select-snomed-code-modal.component.html',
	styles: [],
	providers: [ModalManagerService],
})
export class SelectSnomedCodeModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	componentForm: UntypedFormGroup;
	maxSearchResults = 100;
	pageSettings: PageSettingsModel = {
		pageSizes: PAGE_LENGTH_LIST,
		pageSize: PAGE_LENGTH.PAGE_10,
	};
	searchSnomedCodesRequest: SearchSnomedCodesRequest;
	snomedCodes: SnomedCodeResponse[] = [];

	constructor(
		private dynamicModalRef: DynamicModalRef,
		private gandalfFormBuilder: GandalfFormBuilder,
		private modalConfig: ModalConfig,
		private snomedCodeService: SnomedCodeService,
	) { }

	ngOnInit() {
		this.searchSnomedCodesRequest = new SearchSnomedCodesRequest();
		this.componentForm = this.gandalfFormBuilder.group(this.searchSnomedCodesRequest);
		this.componentForm.setValidators([
			atLeastOne(Validators.required,
				['code', 'description'],
				'At least 1 character needs to be entered in Code or Description'),
		]);
		this.parseConfigData(this.modalConfig.data);
	}

	parseConfigData(data: SelectSnomedCodeModalData) {
		const type = _get(data, ['type']);
		if (type) {
			this.componentForm.get('type').setValue(type);
		}

		const description = _get(data, ['description']);
		if (description) {
			this.componentForm.get('description').setValue(description);
			this.searchSnomedCodes();
		}
	}

	clear() {
		this.componentForm.reset();
		this.snomedCodes = [];
	}

	closeModal(result?: SnomedCodeResponse) {
		this.dynamicModalRef.close(this.modal, result);
	}

	searchSnomedCodes() {
		if (this.componentForm.valid) {
			this.snomedCodeService.searchSnomedCodes(this.componentForm.value)
				.subscribe(codes => this.snomedCodes = codes);
		}
	}

	searchResultsTruncated() {
		return this.snomedCodes.length === this.maxSearchResults;
	}

}
