import { Directive, HostBinding, Optional, ViewContainerRef } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { _get } from '@core/lodash/lodash';

@Directive({
	/* eslint-disable-next-line @angular-eslint/directive-selector */
	selector: 'form:not([noPmsValidationCss])',
})
export class ValidationCssDirective {

	constructor(
		@Optional() private controlContainer: ControlContainer,
		@Optional() private viewContainerRef: ViewContainerRef,
	) {
	}

	@HostBinding('class.submitted')
	get isSubmitted() {
		if (_get(this.viewContainerRef, ['_view', 'component', 'formSubmitted'])) {
			return _get(this.viewContainerRef, ['_view', 'component', 'formSubmitted']);
		}

		if (this.controlContainer) {
			return (this.controlContainer as any).submitted;
		}

		return false;
	}

}
