// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.accounting.feeschedule.FeeScheduleItemServiceCptResponse */
/* istanbul ignore next */
@GandalfModel
export class FeeScheduleItemServiceCptResponse extends GandalfModelBase {

	@GandalfProperty()
	description: string;

	@GandalfProperty()
	id: number;

	@GandalfProperty({ propertyType: 'Money' })
	maximumAllowable: number;

	@GandalfProperty()
	modifier: string;

	@GandalfProperty()
	name: string;

	@GandalfProperty()
	serviceId: number;

	@GandalfProperty({ propertyType: 'Money' })
	usualAndCustomaryFee: number;

}
