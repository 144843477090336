// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.patient.PatientNameResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientNameResponse extends GandalfModelBase {

	@GandalfConstantDecorator()
	@GandalfProperty()
	activeStatus: constants.PatientStatus;

	@GandalfProperty()
	credentials: string;

	@GandalfProperty()
	firstName: string;

	@GandalfProperty()
	lastName: string;

	@GandalfProperty()
	middleName: string;

	@GandalfProperty()
	nickname: string;

	@GandalfProperty()
	patientId: number;

	@GandalfProperty()
	personId: number;

	@GandalfProperty()
	pronunciation: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.EstablishedStatus;

	@GandalfProperty()
	suffix: string;

	@GandalfProperty()
	title: ReferenceDataResponse;

}
