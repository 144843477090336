// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { ReferenceDataSummaryResponse } from './reference-data-summary-response';

/** See com.rev360.pms.api.controller.patient.opticalsummary.OpticalSummaryLatestFindingsEyeglassResponse */
/* istanbul ignore next */
@GandalfModel
export class OpticalSummaryLatestFindingsEyeglassResponse extends GandalfModelBase {

	@GandalfProperty()
	arCoating: boolean;

	@GandalfProperty()
	arCoatingComment: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	authorizationType: constants.PrescriptionAuthorizationType;

	@GandalfProperty()
	authorizedPrescriber: PersonNameResponse;

	@GandalfProperty()
	distancePd: number;

	@GandalfProperty()
	eyeglassPrescriptionId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	eyeglassPrescriptionStatus: constants.PrescriptionCurrentStatus;

	@GandalfProperty()
	instructions: string;

	@GandalfProperty()
	lensType: ReferenceDataSummaryResponse;

	@GandalfProperty()
	lensTypeComment: string;

	@GandalfProperty()
	material: ReferenceDataSummaryResponse;

	@GandalfProperty()
	materialComment: string;

	@GandalfProperty()
	nearPd: number;

	@GandalfProperty()
	odAxis: number;

	@GandalfProperty()
	odCylinder: number;

	@GandalfProperty()
	odHorizontalPrism: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	odHorizontalPrismOrientation: constants.HorizontalPrismOrientation;

	@GandalfProperty()
	odNearAdd: number;

	@GandalfProperty()
	odPrescriptionBalance: boolean;

	@GandalfProperty()
	odSphere: number;

	@GandalfProperty()
	odVerticalPrism: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	odVerticalPrismOrientation: constants.VerticalPrismOrientation;

	@GandalfProperty()
	osAxis: number;

	@GandalfProperty()
	osCylinder: number;

	@GandalfProperty()
	osHorizontalPrism: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	osHorizontalPrismOrientation: constants.HorizontalPrismOrientation;

	@GandalfProperty()
	osNearAdd: number;

	@GandalfProperty()
	osPrescriptionBalance: boolean;

	@GandalfProperty()
	osSphere: number;

	@GandalfProperty()
	osVerticalPrism: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	osVerticalPrismOrientation: constants.VerticalPrismOrientation;

	@GandalfProperty()
	photochromic: boolean;

	@GandalfProperty()
	photochromicComment: string;

	@GandalfProperty()
	polarized: boolean;

	@GandalfProperty()
	polarizedComment: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	prescriptionExpireDate: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	prescriptionStartDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	prescriptionStatus: constants.PrescriptionStatus;

	@GandalfProperty()
	prescriptionUses: ReferenceDataSummaryResponse;

	@GandalfProperty()
	tint: ReferenceDataSummaryResponse;

	@GandalfProperty()
	tintComment: string;

	@GandalfProperty()
	uvTreatment: boolean;

	@GandalfProperty()
	uvTreatmentComment: string;

}
