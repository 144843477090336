// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { InterviewResponse } from './interview-response';

// @ts-ignore
import { PatientFileInfoResponse } from './patient-file-info-response';

/** See com.rev360.pms.api.controller.encounter.interview.InterviewHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class InterviewHistoryResponse extends InterviewResponse {

	@GandalfProperty()
	appointmentEncounterTemplateName: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	appointmentStartDate: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	encounterDate: Date;

	@GandalfProperty()
	encounterId: number;

	@GandalfProperty()
	encounterType: string;

	@GandalfProperty()
	intakeId: number;

	@GandalfProperty()
	intakePatientFile: PatientFileInfoResponse;

	@GandalfProperty({ propertyType: 'LocalDate' })
	interviewDate: Date;

	@GandalfProperty()
	interviewId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	interviewStatus: constants.InterviewStatus;

}
