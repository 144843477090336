import { Injectable } from '@angular/core';
import { EnumUtil, SortingService } from 'morgana';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { BusinessStatus } from '@gandalf/constants';
import { CreateLabOrderRequest } from '@gandalf/model/create-lab-order-request';
import { LabOrderListResponse } from '@gandalf/model/lab-order-list-response';
import { LabOrderResponse } from '@gandalf/model/lab-order-response';
import { LabVendorResponse } from '@gandalf/model/lab-vendor-response';
import { LoincCodeRequest } from '@gandalf/model/loinc-code-request';
import { LoincCodeResponse } from '@gandalf/model/loinc-code-response';
import { SearchLoincCodesRequest } from '@gandalf/model/search-loinc-codes-request';
import { UpdateLabOrderRequest } from '@gandalf/model/update-lab-order-request';
import { LabOrderGandalfService } from '@gandalf/services';
import { ProviderNamePipe } from '@shared/pipes/provider-name/provider-name.pipe';
import { map } from 'rxjs/operators';

export interface FormattedLabOrderList extends LabOrderListResponse {
	orderedByFullName: string;
	orderName: string;
	resultValue: string;
}

export interface FormattedLabOrder extends LabOrderResponse {
	orderLoincNum: string;
}

export interface FormattedLoincCode extends LoincCodeResponse {
	formattedCode: string;
}

export interface FormattedLabVendor extends LabVendorResponse {
	label: string;
	value: any;
}

@Injectable({
	providedIn: 'root',
})
export class LabOrderService {

	constructor(
		private labOrderGandalfService: LabOrderGandalfService,
		private providerNamePipe: ProviderNamePipe,
	) { }

	searchLabOrders(request: SearchLoincCodesRequest) {
		return this.labOrderGandalfService.searchLoincCodes(request).pipe(
			map(loinCodes => loinCodes.map(loinCode => this.formatLoincCode(loinCode))),
		);
	}

	/* istanbul ignore next: gandalf */
	@ShowSavedSuccessToast()
	completeLabOrder(orderId: number) {
		return this.labOrderGandalfService.completeLabOrder(orderId);
	}

	/* istanbul ignore next: gandalf */
	@ShowSavedSuccessToast()
	cancelLabOrder(orderId: number) {
		return this.labOrderGandalfService.cancelLabOrder(orderId);
	}

	getLoincCodeByNum(request: LoincCodeRequest) {
		return this.labOrderGandalfService.getLoincCodeByNum(request).pipe(
			map(loinCode => this.formatLoincCode(loinCode)),
		);
	}

	findLabOrders(patientId: number) {
		return this.labOrderGandalfService.findLabOrdersByPatientId(patientId).pipe(
			map(response => SortingService.sortBy(response, ['orderDate', 'id'], ['desc', 'desc'])),
			map(labOrders => labOrders.map(labOrder => this.formatLabOrderList(labOrder))),
		);
	}

	findLabOrderById(labOrderId: number) {
		return this.labOrderGandalfService.findLabOrderById(labOrderId).pipe(
			map(labOrder => this.formatLabOrder(labOrder)),
		);
	}

	findLabVendorsForDropdown() {
		return this.labOrderGandalfService.findLabVendors().pipe(
			map(response => SortingService.sortBy(response, ['status', 'name', 'vendorId'], ['asc', 'asc', 'asc'])),
			map(labVendors => labVendors.map(labVendor => this.formatLabVendor(labVendor))),
		);
	}

	/* istanbul ignore next: gandalf */
	@ShowSavedSuccessToast()
	createLabOrder(request: CreateLabOrderRequest) {
		return this.labOrderGandalfService.createLabOrder(request);
	}

	/* istanbul ignore next: gandalf */
	@ShowSavedSuccessToast()
	updateLabOrder(request: UpdateLabOrderRequest) {
		return this.labOrderGandalfService.updateLabOrder(request);
	}

	formatLoincCode(loincCode: LoincCodeResponse) {
		const formattedLoincCode = loincCode as FormattedLoincCode;
		formattedLoincCode.formattedCode = loincCode.loincNum + ': ' + loincCode.shortName;

		return formattedLoincCode;
	}

	formatLabVendor(labVendor: LabVendorResponse) {
		const formattedLabVendor = labVendor as FormattedLabVendor;
		formattedLabVendor.label = EnumUtil.equals(labVendor.status, BusinessStatus.INACTIVE) ? '(Inactive) ' : '';
		formattedLabVendor.label += labVendor.name;

		formattedLabVendor.value = labVendor.vendorId;

		return formattedLabVendor;
	}

	formatLabOrderList(labOrderList: LabOrderListResponse) {
		const formattedLabOrder = labOrderList as FormattedLabOrderList;
		formattedLabOrder.orderedByFullName = this.providerNamePipe.transform(labOrderList.orderedBy);
		formattedLabOrder.orderName = labOrderList.order.loincNum + ': ' + labOrderList.order.shortName;
		formattedLabOrder.resultValue = labOrderList.resultNum ? labOrderList.resultNum.toString() : labOrderList.result;

		return formattedLabOrder;
	}

	formatLabOrder(labOrder: LabOrderResponse) {
		const formattedLabOrder = labOrder as FormattedLabOrder;
		formattedLabOrder.orderLoincNum = labOrder.order.loincNum;

		return formattedLabOrder;
	}
}
