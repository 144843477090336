// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientVaccinationRequest } from './patient-vaccination-request';

/** See com.rev360.pms.api.controller.patient.immunization.UpdatePatientVaccinationRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdatePatientVaccinationRequest extends PatientVaccinationRequest {

	@GandalfLabel('Administered Amount')
	@GandalfProperty()
	administeredAmount: number;

	@GandalfLabel('Administered Amount Unit')
	@GandalfProperty()
	administeredAmountUnitId: number;

	@GandalfLabel('Administered At')
	@GandalfProperty()
	administeredAtLocationId: number;

	@GandalfLabel('Administered By')
	@GandalfProperty()
	administeredByEmployeeId: number;

	@GandalfLabel('Administered On')
	@GandalfProperty()
	administeredOn: Date;

	@GandalfLabel('Administered Route')
	@GandalfProperty()
	administeredRouteId: number;

	@GandalfLabel('Administered Site')
	@GandalfProperty()
	administeredSiteId: number;

	@GandalfLabel('Comments')
	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	comments: string;

	@GandalfLabel('CVX Code')
	@GandalfValidator({ notNull: { message: 'CVX Code is required' } })
	@GandalfValidator({ sizeString: { min: 1, max: 20, message: 'CVX Code must be between 1 and 20 characters' } })
	@GandalfProperty({ isRequired: true })
	cvxDetailCode: string;

	@GandalfLabel('End Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate: Date;

	@GandalfLabel('Not Given')
	@GandalfProperty()
	notGiven: boolean;

	@GandalfLabel('Reason')
	@GandalfProperty()
	notGivenReasonId: number;

	@GandalfLabel('Ordered By')
	@GandalfProperty()
	orderedByProviderId: number;

	@GandalfLabel('Substance Expiration Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	substanceExpireDate: Date;

	@GandalfLabel('Substance Lot')
	@GandalfValidator({ sizeString: { message: 'Substance Lot cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	substanceLot: string;

	@GandalfLabel('Substance Manufacturer')
	@GandalfValidator({ sizeString: { min: 1, max: 255, message: 'Substance Manufacturer must be between 1 and 255 characters' } })
	@GandalfProperty()
	substanceManufacturerId: string;

	@GandalfLabel('Immunization')
	@GandalfValidator({ notNull: { message: 'Immunization is required' } })
	@GandalfProperty({ isRequired: true })
	vaccinationId: number;

}
