import {
	ApplicationRef,
	ComponentFactoryResolver,
	ComponentRef,
	ElementRef,
	EmbeddedViewRef,
	Injectable,
	Injector,
	isDevMode,
	Type
} from '@angular/core';
import { DynamicPrintRef, EmptyPrintModalRef } from '@core/print/dynamic-print-ref';
import { DynamicPrintInjector } from '@core/print/dynamic-print.injector';
import { PrintComponentConfig } from '@core/print/print-component-config';
import { take } from 'rxjs/operators';
import { _isNil } from '@core/lodash/lodash';
import { NavigationEnd } from '@angular/router';
import { RouterUtilsService } from '@core/router-utils/router-utils.service';
import { LoadingService } from '../loading/loading.service';

@Injectable({
	providedIn: 'root',
})
export class PrintService {

	private printSection: ElementRef;
	private printComponentRef: ComponentRef<any>;
	isOpen = false;

	constructor(
		/* eslint-disable-next-line deprecation/deprecation */
		private componentFactoryResolver: ComponentFactoryResolver,
		private injector: Injector,
		private appRef: ApplicationRef,
		private loadingService: LoadingService,
		private routerUtilsService: RouterUtilsService,
	) {
		this.setupConsoleCommand();
		this.routerUtilsService.observeNavigationEvents(NavigationEnd)
			.subscribe(() => this.destroyPrintComponent());
	}

	/**
	 * Sets the element used for appending any print content
	 */
	setPrintSection(printSection: ElementRef) {
		this.printSection = printSection;
	}

	/**
	 * Creates a component dynamically to be displayed as printable content.
	 * The component created must call the onPrintReady method for the print preview to open.
	 */
	openPrint(component: Type<any>, data: PrintComponentConfig = {}) {
		if (this.isOpen) {
			if (isDevMode()) {
				console.error(
					'Two print layouts cannot be created at the same time!',
					'The previous printout must be closed before opening a new one',
				);
			}
			// There is already an active print open
			// returns an empty subject here will ensure runtime safety while not triggering a second
			// print from occurring.
			return new EmptyPrintModalRef();
		}

		this.isOpen = true;
		return this.createPrintLayout(component, data);
	}

	private createPrintLayout(component: Type<any>, data: PrintComponentConfig) {
		this.loadingService.showModal();

		const map = new WeakMap();
		map.set(PrintComponentConfig, data);

		const printRef = new DynamicPrintRef();
		map.set(DynamicPrintRef, printRef);

		// window.print() is not a blocking operation in iOS and destroying the print component after calling it
		// will show a blank page in the print preview dialog, and there is no way to know when it is closed.
		// Instead, destroy the previous print component before attaching a new one.
		this.destroyPrintComponent();

		// Wait until the component declares it's ready for printing
		// This allows the component to fetch server data if needed
		printRef.onPrintReady.pipe(take(1)).subscribe(() => {
			this.loadingService.hideModal();
			setTimeout(() => {
				window.print();
				this.isOpen = false;
			}, 50);
		});

		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
		const componentRef = componentFactory.create(new DynamicPrintInjector(this.injector, map));
		this.appRef.attachView(componentRef.hostView);

		// Appends the newly created component to the printSection element in the DOM
		const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
		this.printSection.nativeElement.appendChild(domElem);

		this.printComponentRef = componentRef;

		return printRef;
	}

	/**
	 * Destroys the component instance and removes it from the DOM
	 */
	private destroyPrintComponent() {
		if (!_isNil(this.printComponentRef?.hostView)) {
			this.appRef.detachView(this.printComponentRef.hostView);
			this.printComponentRef.destroy();
		}
	}

	/**
	 * Creates a printElement function on the window to be used in the browser console that accepts an HTML element.
	 * This can be called in chrome by highlighting the element in the Elements tab, and running window.printElement($0)
	 */
	private setupConsoleCommand() {
		(window as any).printElement = (element) => {
			const child = this.printSection.nativeElement.appendChild(element.cloneNode(true));
			window.print();
			child.remove();
		};
	}

}
