// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.reporting.schedule.EncounterReportResponse */
/* istanbul ignore next */
@GandalfModel
export class EncounterReportResponse extends GandalfModelBase {

	@GandalfProperty()
	approvalStatus: string;

	@GandalfProperty()
	employeeFirstName: string;

	@GandalfProperty()
	employeeLastName: string;

	@GandalfProperty()
	encounterDate: Date;

	@GandalfProperty()
	encounterId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	establishedStatus: constants.EstablishedStatus;

	@GandalfProperty()
	locationName: string;

	@GandalfProperty()
	patientId: number;

	@GandalfProperty()
	patientName: PatientNameResponse;

	@GandalfProperty()
	providerFirstName: string;

	@GandalfProperty()
	providerLastName: string;

	@GandalfProperty()
	status: string;

	@GandalfProperty()
	subTypeValue: string;

	@GandalfProperty()
	templateName: string;

}
