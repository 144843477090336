// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { UpdateExternalProviderRequest } from './update-external-provider-request';

/** See com.rev360.pms.api.controller.patient.provider.CreatePatientProviderRequest */
/* istanbul ignore next */
@GandalfModel
export class CreatePatientProviderRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Provider is required' } })
	@GandalfProperty({ isRequired: true })
	externalProvider: UpdateExternalProviderRequest;

	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientId: number;

	@GandalfValidator({ notNull: { message: 'Provider Role is required' } })
	@GandalfProperty({ isRequired: true })
	providerRoleId: number;

}
