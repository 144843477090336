import { Component, OnInit, ViewChild } from '@angular/core';
import { _assign, _isNil } from '@core/lodash/lodash';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { PracticeFileResponse } from '@gandalf/model/practice-file-response';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

const TOP_LEFT_X = 0;
const TOP_LEFT_Y = 0;
const HEIGHT_PERCENT = 0.75;
const BUTTON_WIDTH = 50;
const BUTTON_HEIGHT = 50;
const DEFAULT_ZOOM = 1;
const ZOOM_STEP = 0.2;
const FONT_SIZE = 40;
const BUTTON_CONTAINER_X = 100;
const BUTTON_CONTAINER_Y = 50;

interface Rectangle {
	x: number;
	y: number;
	width: number;
	height: number;

}

interface ButtonRect extends Rectangle {
	text: string;
	method: (mousePos: MousePosition) => void;
}

export interface MousePosition {
	x: number;
	y: number;
}

@Component({
	selector: 'pms-image-preview-modal',
	templateUrl: './image-preview-modal.component.html',
	styles: [],
})
export class ImagePreviewModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('imagePreview')
	set canvas(canvas: any) {
		if (_isNil(canvas)) {
			return;
		}
		this._canvas = canvas.nativeElement;
		this.initializeCanvas();
	}

	get canvas() {
		return this._canvas;
	}

	private _canvas: HTMLCanvasElement;
	filePath: string;

	imageObj: HTMLImageElement;
	canvasContext: any;
	imageRectangle: Rectangle;
	mosDownPos = {x: TOP_LEFT_X, y: TOP_LEFT_Y, down: false, imgX: TOP_LEFT_X, imgY: TOP_LEFT_Y};
	buttonContainer: Rectangle;
	buttons: ButtonRect[];
	zoomLevel = DEFAULT_ZOOM;

	constructor(
		public dynamicModalRef: DynamicModalRef,
		public modalConfig: ModalConfig,
	) {
	}

	ngOnInit() {
		this.parseModalConfig(this.modalConfig.data);
	}

	parseModalConfig(data: any) {
		this.filePath = data.filePath;
	}

	initializeCanvas() {
		this.canvasContext = this.canvas.getContext('2d');
		this.canvasContext.mozImageSmoothingEnabled = true;
		this.canvasContext.webkitImageSmoothingEnabled = true;
		this.canvasContext.imageSmoothingQuality = 'high';
		this.canvasContext.msImageSmoothingEnabled = true;
		this.canvasContext.imageSmoothingEnabled = true;

		const parentElement = this.canvas.parentElement;
		this.canvas.width = parentElement.clientWidth;
		this.canvas.height = window.innerHeight * HEIGHT_PERCENT;
		this.initializeButtons();
		this.imageObj = new Image();
		this.imageObj.onload = () => this.imageObjectOnload();
		this.imageObj.src = this.filePath;
	}

	initializeButtons() {
		this.buttonContainer = {
			x: this.canvas.width - BUTTON_CONTAINER_X,
			y: BUTTON_CONTAINER_Y,
			width: BUTTON_WIDTH,
			height: BUTTON_HEIGHT * 3,
		};

		const buttonBase = {
			x: this.buttonContainer.x,
			y: this.buttonContainer.y,
			width: BUTTON_WIDTH,
			height: BUTTON_HEIGHT,
		};

		this.buttons = [];
		this.buttons.push(
			_assign({}, buttonBase, {
				text: '\uf00e',
				method: (mousePos: MousePosition) => this.zoomIn(mousePos),
			}),
			_assign({}, buttonBase, {
				y: this.buttonContainer.y + BUTTON_HEIGHT,
				text: '\uf010',
				method: (mousePos: MousePosition) => this.zoomOut(mousePos),
			}),
			_assign({}, buttonBase, {
				y: this.buttonContainer.y + BUTTON_HEIGHT * 2,
				text: '\uf021',
				method: (mousePos: MousePosition) => this.resetImagePosition(mousePos),
			}),
		);
	}

	imageObjectOnload() {
		const fakeMouseCoords = {x: -1, y: -1} as MousePosition;
		this.resetImagePosition(fakeMouseCoords);
		this.mainDraw(fakeMouseCoords);

		this.canvas.addEventListener('touchmove', (evt: TouchEvent) => {
			const mousePos = this.getTouchPos(evt);
			if (mousePos.x < TOP_LEFT_X || mousePos.x > this.canvas.width
				|| mousePos.y < TOP_LEFT_Y || mousePos.y > this.canvas.height) {
				this.mosDownPos.down = false;
			}
			this.mainDraw(mousePos);
			evt.preventDefault();
		});

		this.canvas.addEventListener('mousemove', (evt) => {
			this.mainDraw(this.getMousePos(evt));
		}, false);

		this.canvas.addEventListener('mousedown', (evt) => {
			const mousePos = this.getMousePos(evt);
			this.onMouseOrTouchDown(mousePos);
		});

		this.canvas.addEventListener('touchstart', (evt) => {
			const mousePos = this.getTouchPos(evt);
			this.onMouseOrTouchDown(mousePos);
		});

		this.canvas.addEventListener('mouseup', () => {
			this.mosDownPos.down = false;
		});

		this.canvas.addEventListener('mouseout', () => {
			this.mosDownPos.down = false;
		});

		this.canvas.addEventListener('touchend', () => {
			this.mosDownPos.down = false;
		});
	}

	/* istanbul ignore next: closeModal */
	closeModal(result?: PracticeFileResponse) {
		this.dynamicModalRef.close(this.modal, result);
	}

	getMousePos(evt: MouseEvent) {
		const rect = this.canvas.getBoundingClientRect();
		return {
			x: evt.clientX - rect.left,
			y: evt.clientY - rect.top,
		} as MousePosition;
	}

	getTouchPos(touchEvent: TouchEvent) {
		const rect = this.canvas.getBoundingClientRect();
		return {
			x: touchEvent.touches[0].clientX - rect.left,
			y: touchEvent.touches[0].clientY - rect.top,
		} as MousePosition;
	}

	resetImagePosition(mousePos: MousePosition) {
		this.zoomLevel = DEFAULT_ZOOM;
		let newWidth = this.imageObj.width;
		let newHeight = this.imageObj.height;
		if (newWidth > this.canvas.width || newHeight > this.canvas.height) {
			const ratio = this.imageObj.width / this.imageObj.height;
			newWidth = this.canvas.width;
			newHeight = newWidth / ratio;
			if (newHeight > this.canvas.height) {
				newHeight = this.canvas.height;
				newWidth = newHeight * ratio;
			}
		}

		this.imageRectangle = {
			x: TOP_LEFT_X,
			y: TOP_LEFT_Y,
			width: newWidth,
			height: newHeight,
		};
		this.mainDraw(mousePos);
	}

	mainDraw(mousePos: MousePosition) {
		this.canvas.style.cursor = 'default';
		this.canvasContext.clearRect(TOP_LEFT_X, TOP_LEFT_Y, this.canvas.width, this.canvas.height);
		if (this.isCursorInBox(mousePos, this.imageRectangle, true)) {
			this.canvas.style.cursor = 'pointer';
		}
		if (this.mosDownPos.down) {
			this.imageRectangle.x = this.mosDownPos.imgX + (mousePos.x - this.mosDownPos.x);
			this.imageRectangle.y = this.mosDownPos.imgY + (mousePos.y - this.mosDownPos.y);
		}
		this.drawImage();
		this.drawButtons(mousePos);
	}

	isCursorInBox(mousePos: MousePosition, rectangle: Rectangle, zoom = false) {
		let zoomLevel = DEFAULT_ZOOM;
		if (zoom) {
			zoomLevel = this.zoomLevel;
		}
		return mousePos.x >= rectangle.x && mousePos.x <= rectangle.x + rectangle.width * zoomLevel &&
			mousePos.y >= rectangle.y && mousePos.y <= rectangle.y + rectangle.height * zoomLevel;
	}

	drawButtons(mousePos: MousePosition) {
		this.canvasContext.fillStyle = '#FFFFFF';
		this.canvasContext.fillRect(this.buttonContainer.x, this.buttonContainer.y, this.buttonContainer.width, this.buttonContainer.height);
		this.canvasContext.rect(this.buttonContainer.x, this.buttonContainer.y, this.buttonContainer.width, this.buttonContainer.height);
		this.canvasContext.stroke();
		this.buttons.forEach((button) => {
			this.drawIcon(mousePos, button);
		});
	}

	drawIcon(mousePos: MousePosition, button: ButtonRect) {
		if (this.isCursorInBox(mousePos, button)) {
			this.canvasContext.fillStyle = '#AAAAAA';
			// the +1s and -2s are so the rectangle doesn't cover the outline
			this.canvasContext.fillRect(button.x + 1, button.y + 1, button.width - 2, button.height - 2);
			this.canvas.style.cursor = 'pointer';
		}

		this.canvasContext.font = FONT_SIZE.toString() + 'px FontAwesome';
		this.canvasContext.fillStyle = '#000000';
		this.canvasContext.fillText(button.text, button.x + (BUTTON_WIDTH - FONT_SIZE) / 2, button.y + FONT_SIZE);
	}

	drawImage() {
		this.canvasContext.drawImage(this.imageObj,
			this.imageRectangle.x,
			this.imageRectangle.y,
			this.imageRectangle.width * this.zoomLevel,
			this.imageRectangle.height * this.zoomLevel);
	}

	zoomIn(mousePos: MousePosition) {
		this.zoomLevel += ZOOM_STEP;
		this.mainDraw(mousePos);
	}

	zoomOut(mousePos: MousePosition) {
		this.zoomLevel -= ZOOM_STEP;
		this.mainDraw(mousePos);
	}

	onMouseOrTouchDown(mousePos: MousePosition) {
		if (this.isCursorInBox(mousePos, this.imageRectangle, true)) {
			this.mosDownPos.x = mousePos.x;
			this.mosDownPos.y = mousePos.y;
			this.mosDownPos.imgX = this.imageRectangle.x;
			this.mosDownPos.imgY = this.imageRectangle.y;
			this.mosDownPos.down = true;
		}
		this.buttons.forEach(button => {
			if (this.isCursorInBox(mousePos, button)) {
				button.method(mousePos);
			}
		});
	}
}
