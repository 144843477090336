import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Column, ExcelExportProperties, ExcelQueryCellInfoEventArgs, GridComponent } from '@syncfusion/ej2-angular-grids';
import { ItemModel, MenuEventArgs } from '@syncfusion/ej2-angular-splitbuttons';
import { GridUtil } from '../../utils/grid-util/grid-util';

export const EXPORT_TEXT = 'Export';
export const EXPORT_CSV = '.csv';
export const EXPORT_ICON = 'fa fa-download';

export interface TableToolItem extends ItemModel {
	action?: () => void;
	buttonText?: string;
	dataTestId: string;
}

@Component({
	selector: 'rev-table-action-menu',
	templateUrl: './table-action-menu.component.html',
	styles: [],
})
export class TableActionMenuComponent implements OnInit {
	@Input()
	table: GridComponent;

	@Input()
	items: TableToolItem[];

	@Input()
	exportFileName: string;

	constructor(
		private datePipe: DatePipe,
	) {
	}

	ngOnInit() {
		if (!this.items && this.table.allowExcelExport) {
			this.createMenu();
		}

		if (this.table && this.table.allowExcelExport) {
			this.table.excelQueryCellInfo.subscribe((cell: ExcelQueryCellInfoEventArgs) => {
				this.formatCellValues(cell);
			});
		}
	}

	formatCellValues(cell: ExcelQueryCellInfoEventArgs) {
		if (cell.value && cell.column.format && cell.column.format['type']
			&& (cell.column.format['type'] === 'date' || cell.column.format['type'] === 'dateTime')) {
			cell.value = this.datePipe.transform(cell.value as string, cell.column.format['format']);
		}
		cell.value = GridUtil.sanitizeCellDataForExport(cell.value);
	}

	createMenu() {
		this.items = [
			{
				text: EXPORT_TEXT,
				iconCss: EXPORT_ICON,
				dataTestId: 'Export',
			},
		];
	}

	async itemSelected(event: MenuEventArgs) {
		const currentItem = event.item as TableToolItem;
		if (currentItem.action) {
			currentItem.action();
		} else if (currentItem.text === EXPORT_TEXT || currentItem.iconCss === EXPORT_ICON) {
			await this.exportToCsv();
		}
	}

	async exportToCsv() {
		// Set non-exportable columns visible to false, export-only columns visible to true
		this.table.getColumns().forEach(column => {
			this.getPreExportColumnVisibility(column);
		});

		let csvExportProperties: ExcelExportProperties;
		if (this.exportFileName) {
			csvExportProperties = {
				fileName: this.exportFileName.concat(EXPORT_CSV),
			};
		}

		await this.table.csvExport(csvExportProperties);
		// Set non-exportable and export-only columns to their previous visibility
		this.table.getColumns().forEach(column => {
			this.getPostExportColumnVisibility(column);
		});
	}

	getPreExportColumnVisibility(column: Column) {
		if (column.customAttributes && column.customAttributes['exportable'] === false) {
			column.customAttributes['visibilityBeforeExport'] = column.visible; // Save the current visibility
			column.visible = false;
		}
		if (column.customAttributes && column.customAttributes['exportOnly'] === true) {
			column.customAttributes['visibilityBeforeExport'] = column.visible;
			column.visible = true;
		}
		return column;
	}

	getPostExportColumnVisibility(column: Column) {
		if (column.customAttributes && column.customAttributes['exportable'] === false) {
			column.visible = column.customAttributes['visibilityBeforeExport'] as boolean; // Restore the visibility
		}
		if (column.customAttributes && column.customAttributes['exportOnly'] === true) {
			column.visible = column.customAttributes['visibilityBeforeExport'] as boolean;
		}
		return column;
	}
}
