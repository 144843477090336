// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { EmployeeResponse } from './employee-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

/** See com.rev360.pms.api.controller.encounter.EncounterResponse */
/* istanbul ignore next */
@GandalfModel
export class EncounterResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	approvalDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	approvalStatus: constants.EncounterApprovalStatus;

	@GandalfProperty()
	employee: EmployeeResponse;

	@GandalfProperty()
	encounterDate: Date;

	@GandalfProperty()
	firstApprovalDate: Date;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	locationId: number;

	@GandalfProperty()
	locationName: string;

	@GandalfProperty()
	provider: ProviderResponse;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.EncounterStatus;

	@GandalfProperty()
	subTypeName: string;

	@GandalfProperty()
	templateName: string;

	@GandalfProperty()
	wasSigned: boolean;

}
