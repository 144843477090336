<h4 class="section-title bg-default mrgn-btm" [attr.data-test-id]="'eyeglassRxAuthorizationHeader'">Authorization</h4>
@if (eyeglassPrescription) {
	<div>
		<div class="form-group" [attr.data-test-id]="'eyeglassRxAuthorizationEyeglassPrescriptionLabelGroup'">
			<label class="col-sm-5 control-label padding-x-0">Auth Type</label>
			<div class="col-sm-7">
				<p class="margin-0">{{ eyeglassPrescription.prescriptionAuthorizationType }}</p>
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'eyeglassRxAuthorizationAuthDateLabelGroup'">
			<label class="col-sm-5 control-label padding-x-0">Auth Date</label>
			<div class="col-sm-7">
				<p class="margin-0">{{ eyeglassPrescription.authorizationDate | date:dateFormat }}</p>
			</div>
		</div>
		<div class="form-group" [attr.data-test-id]="'eyeglassRxAuthorizationAuthByLabelGroup'">
			<label class="col-sm-5 control-label padding-x-0">Auth By</label>
			<div class="col-sm-7">
				<p class="margin-0">{{ eyeglassPrescription.authorizedBy| personName }}</p>
			</div>
		</div>
	</div>
} @else {
	No Data
}
