import { Pipe, PipeTransform } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { EstablishedStatus } from '@gandalf/constants';
import { PatientNameResponse } from '@gandalf/model/patient-name-response';

@Pipe({
	name: 'patientName',
})
export class PatientNamePipe implements PipeTransform {

	transform(patientNameResponse: PatientNameResponse, extended = false, showNewStatusIndicator = true): string {
		if (_isNil(patientNameResponse)) {
			return '';
		}

		let fullName = '';

		if (extended) {
			fullName = fullName.concat(patientNameResponse.lastName, ', ', patientNameResponse.firstName);
			fullName += patientNameResponse.middleName ? ' ' + patientNameResponse.middleName : '';
			fullName += patientNameResponse.suffix ? ', ' + patientNameResponse.suffix : '';
			fullName += patientNameResponse.nickname ? ' "' + patientNameResponse.nickname + '"' : '';
		} else {
			const nicknameCheck = patientNameResponse.nickname ? ' "' + patientNameResponse.nickname + '"' : '';
			fullName = fullName.concat(patientNameResponse.lastName, ', ', patientNameResponse.firstName, nicknameCheck);
		}
		if (showNewStatusIndicator && patientNameResponse.status === EstablishedStatus.NEW) {
			fullName = fullName.concat('*');
		}

		return fullName;
	}
}
