import { Injectable } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { AddressResponse } from '@gandalf/model/address-response';

@Injectable({
	providedIn: 'root',
})
export class AddressUtilService {

	static addressToAddressLine(... addresses: AddressResponse[]) {
		addresses.forEach((address) => {
			if (!_isNil(address)) {
				(address as any).addressLine1 = address.address1;
				(address as any).addressLine2 = address.address2;
			}
		});
	}
}
