<ejs-dialog #modal cssClass="modal-lg"
			[zIndex]="zIndexBehindHitPmsHtmlModal"
			(close)="closeModal()"
			[attr.data-test-id]="'contactLensPrescriptionModal'">
	<ng-template #header>
		<div class="dlg-template" *ngIf="!isUpdating">Create Contact Lens Rx</div>
		<div class="dlg-template" *ngIf="isUpdating">Contact Lens Rx</div>
	</ng-template>
	<ng-template #content>
		<div class="modal-feature text-right" *ngIf="canPrint()">
			<button type="submit" ejs-button [isPrimary]="true" iconCss="fa fa-print" revTooltip tooltipContent="Print" (click)="print()" [attr.data-test-id]="'contactLensPrintButton'"></button>
		</div>
		<gandalf-lib-validation-messages
			*ngIf="!!componentForm && formGroup.invalid && componentForm.submitted"
			[form]="formGroup"
			[requestObj]="request">
		</gandalf-lib-validation-messages>
		<form class="form-horizontal" [formGroup]="formGroup" #templateForm="ngForm" *ngIf="formGroup">
			<ng-template #rxInfo>
				<!-- Displays the read only view for a prescription -->
				<pms-contact-lens-prescription-details
						*ngIf="displayReadOnlyForm()"
						[prescriptionId]="clrx?.id"
						[showAdditionalInfo]="true">
				</pms-contact-lens-prescription-details>
				<!-- Displays the editable view for a prescription -->
				<div [hidden]="displayReadOnlyForm()" [attr.data-test-id]="'contactLensPrescriptionDetailsEdit'">
					<rev-loading-overlay [loading]="!isClDataLoaded">
						<div class="row">
							<div class="col-md-12 col-lg-9" *ngIf="!!request">
								<pms-contact-lens-details
										[disabled]="isDisabledForm"
										[isUpdating]="isUpdating || isRefilling"
										[canUpdateType]="isCreating()"
										[showHistoryButton]="isCreating()"
										[contactLensFilter]="contactLensFilter"
										[locationId]="locationId"
										[encounterId]="encounterId"
										[isRefilling]="isRefilling"
										[canUpdateProduct]="!isRefilling"
										[parentFormGroup]="formGroup"
										[parentRequest]="request"
										[patientId]="patientId"
										[odEyeResponse]="clrx?.contactLensEyeOd"
										[osEyeResponse]="clrx?.contactLensEyeOs"
										[onlyContactLensHistory]="isPatients"
										[isTrialOrRx]="true">
								</pms-contact-lens-details>
								<h4 class="section-title bg-default mrgn-btm">Dispensing Information</h4>
								<div class="row">
									<div class="col-sm-6">
										<div class="form-group">
											<div class="control-label col-sm-3">
												<h4 class="margin-0"><span class="label label-success">OD</span>
												</h4>
											</div>
											<div class="col-sm-9"></div>
										</div>
										<div class="form-group" [attr.data-test-id]="'odDispenseQuantityFormGroup'">
											<label class="control-label col-sm-3">Quantity</label>
											<div class="col-sm-9">
												<div class="row">
													<div class="col-md-6 margin-bottom-xs">
														<input revDigitOnlyInput type="number" [min]="0"
															   class="e-input" formControlName="odDispenseAmount"/>
													</div>
													<div class="col-md-6 margin-bottom-xs">
														<ejs-dropdownlist
																[dataSource]="dispensingUnits"
																formControlName="odDispenseUnitId"
																[showClearButton]="true"
																placeholder="Select Packaging">
														</ejs-dropdownlist>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-6">
										<div class="form-group">
											<div class="control-label col-sm-3">
												<h4 class="margin-0"><span class="label label-info">OS</span></h4>
											</div>
											<div class="col-sm-9">
												<button ejs-button
														type="button"
														(click)="copyDispensedODToOS()"
														*ngIf="!isDisabledForm"
														[attr.data-test-id]="'dispensingInformationOSisOD'">
													OS=OD
												</button>
											</div>
										</div>
										<div class="form-group" [attr.data-test-id]="'osDispenseQuantityFormGroup'">
											<label class="control-label col-sm-3">Quantity</label>
											<div class="col-sm-9">
												<div class="row">
													<div class="col-md-6 margin-bottom-xs">
														<input revDigitOnlyInput type="number" [min]="0"
															   class="e-input" formControlName="osDispenseAmount"/>
													</div>
													<div class="col-md-6 margin-bottom-xs">
														<ejs-dropdownlist
																[dataSource]="dispensingUnits"
																formControlName="osDispenseUnitId"
																[showClearButton]="true"
																placeholder="Select Packaging">
														</ejs-dropdownlist>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div [attr.data-test-id]="'instructionsSchedulesSection'">
									<h4 class="section-title bg-default mrgn-btm">Schedules</h4>
									<div class="row">
										<div class="col-sm-6">
											<div class="form-group" [attr.data-test-id]="'modalityFormGroup'">
												<label class="col-sm-3 control-label">Modality</label>
												<div class="col-sm-9">
													<pms-enum-select-button
															[enumList]="modalities"
															formControlName="modality">
													</pms-enum-select-button>
												</div>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-sm-6" [formGroup]="formGroup.get('od')" [attr.data-test-id]="'odSchedulesSection'">
											<div class="form-group" [attr.data-test-id]="'odLabelGroup'">
												<div class="control-label col-sm-3">
													<h4 class="margin-0"><span class="label label-success">OD</span></h4>
												</div>
												<div class="col-sm-9"></div>
											</div>
											<div class="form-group" [attr.data-test-id]="'odOvernightGroup'">
												<label class="control-label col-sm-3">Overnight</label>
												<div class="col-sm-9">
													<div class="row">
														<div class="col-lg-3 margin-bottom-xs">
															<input revDigitOnlyInput type="number" [min]="0"
																   formControlName="overnightDurationAmount"
																   class="e-input"/>
														</div>
														<div class="col-lg-9 margin-bottom-xs">
															<pms-enum-select-button
																	formControlName="overnightDurationUnit"
																	[enumList]="overnightDurations">
															</pms-enum-select-button>
														</div>
													</div>
												</div>
											</div>
											<div class="form-group" [attr.data-test-id]="'odReplacementGroup'">
												<label class="control-label col-sm-3">Replacement</label>
												<div class="col-sm-9">
													<div class="row">
														<div class="col-lg-3 margin-bottom-xs">
															<input revDigitOnlyInput type="number" [min]="0"
																   formControlName="replacementDurationAmount"
																   class="e-input"/>
														</div>
														<div class="col-lg-9 margin-bottom-xs">
															<pms-enum-select-button
																	formControlName="replacementDurationUnit"
																	[enumList]="replacementDurations">
															</pms-enum-select-button>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-sm-6" [formGroup]="formGroup.get('os')" [attr.data-test-id]="'osSchedulesSection'">
											<div class="form-group" [attr.data-test-id]="'osLabelGroup'">
												<div class="control-label col-sm-3">
													<h4 class="margin-0"><span class="label label-info">OS</span></h4>
												</div>
												<div class="col-sm-9">
													<button ejs-button
															type="button"
															(click)="copyScheduleODToOS()"
															*ngIf="!isDisabledForm"
															[attr.data-test-id]="'schedulesOSisOD'">
														OS=OD
													</button>
												</div>
											</div>
											<div class="form-group" [attr.data-test-id]="'osOvernightGroup'">
												<label class="control-label col-sm-3">Overnight</label>
												<div class="col-sm-9">
													<div class="row">
														<div class="col-lg-3 margin-bottom-xs">
															<input revDigitOnlyInput type="number" [min]="0"
																   formControlName="overnightDurationAmount"
																   class="e-input"/>
														</div>
														<div class="col-lg-9 margin-bottom-xs">
															<pms-enum-select-button
																	formControlName="overnightDurationUnit"
																	[enumList]="overnightDurations">
															</pms-enum-select-button>
														</div>
													</div>
												</div>
											</div>
											<div class="form-group" [attr.data-test-id]="'osReplacementGroup'">
												<label class="control-label col-sm-3">Replacement</label>
												<div class="col-sm-9">
													<div class="row">
														<div class="col-lg-3 margin-bottom-xs">
															<input revDigitOnlyInput type="number" [min]="0"
																   formControlName="replacementDurationAmount"
																   class="e-input"/>
														</div>
														<div class="col-lg-9 margin-bottom-xs">
															<pms-enum-select-button
																	formControlName="replacementDurationUnit"
																	[enumList]="replacementDurations">
															</pms-enum-select-button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="row">
									<div class="col-sm-6" [attr.data-test-id]="'instructionsCommentsSection'">
										<h4 class="section-title bg-default mrgn-btm">Comments</h4>
										<div class="margin-bottom-md">
											<ejs-textbox formControlName="description"
														 class="h-rows-10"
														 [multiline]="true">
											</ejs-textbox>
										</div>
									</div>
									<div class="col-sm-6" [attr.data-test-id]="'instructionsPrescribedItemsSection'">
										<h4 class="section-title bg-default mrgn-btm">Prescribed Items</h4>
										<div class="form-group">
											<label class="col-sm-3 control-label">Disinfection Solution</label>
											<div class="col-sm-9">
												<span class="form-control-static margin-right-sm display-inline-block vertical-align-middle">{{formGroup.getRawValue().solutionName}}</span>
												<button ejs-button type="button" [isPrimary]="true" iconCss="fa fa-search" (click)="openSolutionSearch()" *ngIf="!isDisabledForm" [attr.data-test-id]="'disinfectionSolutionLookupButton'"></button>
											</div>
										</div>
										<div class="form-group">
											<label class="col-sm-3 control-label">Lens Cleaner</label>
											<div class="col-sm-9">
												<span class="form-control-static margin-right-sm display-inline-block vertical-align-middle">{{formGroup.getRawValue().lensCleanerName}}</span>
												<button ejs-button type="button" [isPrimary]="true" iconCss="fa fa-search" (click)="openLensCleanerSearch()" *ngIf="!isDisabledForm" [attr.data-test-id]="'lensCleanerLookupButton'"></button>
											</div>
										</div>
										<div class="form-group">
											<label class="col-sm-3 control-label">Lens Drop</label>
											<div class="col-sm-9">
												<span class="form-control-static margin-right-sm display-inline-block vertical-align-middle">{{formGroup.getRawValue().dropsName}}</span>
												<button ejs-button type="button" [isPrimary]="true" iconCss="fa fa-search" (click)="openDropsSearch()" *ngIf="!isDisabledForm" [attr.data-test-id]="'lensDropLookupButton'"></button>
											</div>
										</div>
										<div class="form-group">
											<label class="col-sm-3 control-label">Enzymatic Cleaner</label>
											<div class="col-sm-9">
												<span class="form-control-static margin-right-sm display-inline-block vertical-align-middle">{{formGroup.getRawValue().enzymaticCleanerName}}</span>
												<button ejs-button type="button" [isPrimary]="true" iconCss="fa fa-search" (click)="openEnzCleanerSearch()" *ngIf="!isDisabledForm" [attr.data-test-id]="'enzymaticCleanerLookupButton'"></button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-12 col-lg-3">
								<div class="row">
									<div class="col-sm-12">
										<h4 class="section-title bg-default mrgn-btm">Authorization</h4>
										<div class="form-group" [attr.data-test-id]="'prescriptionAuthorizationType'">
											<label class="col-sm-4 control-label">Rx Type</label>
											<div class="col-sm-8">
												<pms-enum-select-button
														[enumList]="authTypes"
														formControlName="authorizationType"
                                                        (valueChange)="onAuthTypeChanged()">
												</pms-enum-select-button>
											</div>
										</div>
										<div class="form-group" [attr.data-test-id]="'prescriptionAuthorizationFormGroup'">
											<!-- If External Rx Type, label should be "Provider" instead of "Auth By" -->
											<label class="col-sm-4 control-label">{{isExternalAuth() ? 'Provider' : 'Auth By'}}</label>
											<div class="col-sm-8">
												<!-- If Internal Rx Type, auth by value goes in <p> with .form-control-static class -->
												<p class="form-control-static" *ngIf="!isExternalAuth()">Not Authorized</p>
												<ejs-dropdownlist
														*ngIf="isExternalAuth()"
														[dataSource]="externalProviders"
														formControlName="authorizedByProviderId"
														[showClearButton]="true"
														placeholder="Select Provider">
												</ejs-dropdownlist>
											</div>
										</div>
										<div class="form-group" *ngIf="!isExternalAuth() && clrx?.authorizationDate">
											<label class="col-sm-4 control-label">Auth Date</label>
											<p class="form-control-static" *ngIf="!isRefilling">{{clrx?.authorizationDate | date:dateFormat}}</p>
										</div>
									</div>
									<div class="col-sm-12">
										<h4 class="section-title bg-default mrgn-btm">Dates</h4>
										<div class="form-group" [attr.data-test-id]="'contactLensPrescriptionStartDateFormGroup'">
											<label class="col-sm-4 control-label">Start Date</label>
											<div class="col-sm-8">
												<ejs-datepicker
														formControlName="startDate"
														placeholder="mm/dd/yyyy"
														[format]="dateFormat">
												</ejs-datepicker>
											</div>
										</div>
										<div class="form-group" [attr.data-test-id]="'contactLensPrescriptionExpirationDateFormGroup'">
											<label class="col-sm-4 control-label">Expiration Date</label>
											<div class="col-sm-8">
												<ejs-datepicker
														formControlName="expirationDate"
														placeholder="mm/dd/yyyy"
														[format]="dateFormat">
												</ejs-datepicker>
											</div>
										</div>
										<div class="form-group" *ngIf="clrx?.endDate">
											<label class="col-sm-4 control-label">End Date</label>
											<p class="form-control-static">{{clrx?.endDate | date:dateFormat}}</p>
										</div>
									</div>
								</div>
								<ng-container *ngIf="!isExternalAuth()">
									<h4 class="section-title bg-default mrgn-btm">Electronic Delivery of Rx</h4>
									<p [attr.data-test-id]="'deliveryMethodConsentText'">Patient consent obtained for electronic delivery of Rx by:</p>
									<div class="form-group" [attr.data-test-id]="'deliveryMethodFormGroup'">
										<label class="col-sm-4 control-label">Delivery Method</label>
										<div class="col-sm-8">
											<ejs-dropdownlist
													[dataSource]="deliveryMethodOptions"
													formControlName="deliveryMethodReferenceId"
													[showClearButton]="true"
													placeholder="Select Delivery Method">
											</ejs-dropdownlist>
										</div>
									</div>
								</ng-container>
								<pms-contact-lens-trial-refractive-information *ngIf="isCreating()"></pms-contact-lens-trial-refractive-information>
							</div>
						</div>
					</rev-loading-overlay>
				</div>
			</ng-template>
			<ejs-tab *ngIf="clrx && !isRefilling else rxInfo" id="tabView" overflowMode="Popup" [animation]="tabAnimation">
				<e-tabitems>
					<e-tabitem>
						<ng-template #headerText>
							<span [attr.data-test-id]="'prescriptionDetailsTab'">Details</span>
						</ng-template>
						<ng-template #content>
							<ng-container *ngTemplateOutlet="rxInfo"></ng-container>
						</ng-template>
					</e-tabitem>

					<e-tabitem *ngIf="clrx && !isRefilling">
						<ng-template #headerText>
							<span [attr.data-test-id]="'prescriptionNotesTab'">Notes</span>
						</ng-template>
						<ng-template #content>
							<pms-notes [entityType]="prescriptionEntityType" [parentId]="clrx.id"></pms-notes>
						</ng-template>
					</e-tabitem>
				</e-tabitems>
			</ejs-tab>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button type="button" ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'cancelButton'">Cancel</button>
		<ng-container *ngIf="isClDataLoaded">
			<button type="submit" ejs-button [isPrimary]="true" (click)="submit($event, true, true)" *ngIf="canSign()" [attr.data-test-id]="'saveAuthSignButton'">Save & Auth & Sign</button>
			<button type="submit" ejs-button [isPrimary]="true" (click)="submit($event, true, alwaysSignWhenAuthorizing())" *ngIf="canAuthorize()" [attr.data-test-id]="'saveAuthButton'">Save & Auth</button>
			<button type="submit" ejs-button [isPrimary]="true" (click)="submit($event, false, false)" *ngIf="!isDisabledForm"[attr.data-test-id]="'saveButton'">Save</button>
		</ng-container>
	</ng-template>
</ejs-dialog>
