import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { PatientFamilyService } from '@core/patient/family/patient-family.service';
import { PatientService } from '@core/patient/patient.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { BetaSystemCode, PolicyHolderRelationship } from '@gandalf/constants';
import { CreatePatientFamilyDemographicsRequest } from '@gandalf/model/create-patient-family-demographics-request';
import { CreatePatientFamilyInformationRequest } from '@gandalf/model/create-patient-family-information-request';
import { CreatePatientFamilyInsuranceRequest } from '@gandalf/model/create-patient-family-insurance-request';
import { QuickAddFamilyMemberRequest } from '@gandalf/model/quick-add-family-member-request';
import { FormattedQuickAddPatient } from '@shared/component/add-patient/quick-add-patient-modal/quick-add-patient-modal.component';
import { FormattedNamePipe } from '@shared/pipes/formatted-name/formatted-name.pipe';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';
import { PhoneInformationRequest } from '@gandalf/model/phone-information-request';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { FamilyMemberContactInformationRequest } from '@gandalf/model/family-member-contact-information-request';
import { EventService } from '@core/event/event.service';

@Component({
	selector: 'pms-quick-add-family-member-modal',
	templateUrl: './quick-add-family-member-modal.component.html',
	styles: [],
})
export class QuickAddFamilyMemberModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('templateForm')
	templateForm: NgForm;
	quickAddFamilyMemberRequest: QuickAddFamilyMemberRequest;
	patientId: number;
	componentForm: UntypedFormGroup;
	emergencyContactFeature: boolean;
	hasNonStandardZipCodes: boolean;

	get isEmergencyContact() {
		return this.componentForm.get('isEmergencyContact')?.value;
	}

	constructor(
		private ref: DynamicModalRef,
		private modalConfig: ModalConfig,
		private featureService: FeatureService,
		private securityManagerService: SecurityManagerService,
		private patientService: PatientService,
		private gandalfFormBuilder: GandalfFormBuilder,
		private patientFamilyService: PatientFamilyService,
		private formattedNamePipe: FormattedNamePipe,
		private eventService: EventService,
	) {
	}

	ngOnInit() {
		this.hasNonStandardZipCodes = this.securityManagerService.hasBeta(BetaSystemCode.NON_STANDARD_ZIP_CODES);
		this.parseConfigData(this.modalConfig.data);
		this.buildForm();
		this.emergencyContactFeature = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.EMERGENCY_CONTACT);
	}

	parseConfigData(configData) {
		this.patientId = configData.patientId;
	}

	getFormGroup(controlName: string): UntypedFormGroup {
		return this.componentForm.get(controlName) as UntypedFormGroup;
	}

	buildForm() {
		this.patientFamilyService.getPatientFamilyInformationByPatientId(this.patientId).subscribe(familyInformation => {
			this.quickAddFamilyMemberRequest = new QuickAddFamilyMemberRequest();
			this.quickAddFamilyMemberRequest.createPatientFamilyDemographicsRequest = new CreatePatientFamilyDemographicsRequest();
			this.quickAddFamilyMemberRequest.addressOptionalRequest = this.patientService.getEmptyAddressRequest();
			this.quickAddFamilyMemberRequest.createPatientFamilyInformationRequest = new CreatePatientFamilyInformationRequest();
			this.quickAddFamilyMemberRequest.createPatientFamilyInsuranceRequest = new CreatePatientFamilyInsuranceRequest();
			this.quickAddFamilyMemberRequest.contactInformation = new FamilyMemberContactInformationRequest();
			this.quickAddFamilyMemberRequest.contactInformation.phoneInformation = new PhoneInformationRequest();


			this.quickAddFamilyMemberRequest.patientId = this.patientId;
			this.quickAddFamilyMemberRequest.usePatientAddress = true;

			this.quickAddFamilyMemberRequest.createPatientFamilyInsuranceRequest.copyInsurance = false;
			this.quickAddFamilyMemberRequest.createPatientFamilyInsuranceRequest.relationshipToInsured = PolicyHolderRelationship.UNKNOWN;
			this.quickAddFamilyMemberRequest.createPatientFamilyInformationRequest.headOfHousehold = false;
			this.quickAddFamilyMemberRequest.createPatientFamilyDemographicsRequest.lastName = familyInformation.patientName.lastName;
			this.quickAddFamilyMemberRequest.addressOptionalRequest.addressLine1 = familyInformation.homeAddress.address1;
			this.quickAddFamilyMemberRequest.addressOptionalRequest.addressLine2 = familyInformation.homeAddress.address2;
			this.quickAddFamilyMemberRequest.addressOptionalRequest.city = familyInformation.homeAddress.city;
			this.quickAddFamilyMemberRequest.addressOptionalRequest.stateProvince = familyInformation.homeAddress.stateProvince;
			if (this.hasNonStandardZipCodes) {
				this.quickAddFamilyMemberRequest.addressOptionalRequest.nonStandardPostalCode = familyInformation.homeAddress.postalCode;
			} else {
				this.quickAddFamilyMemberRequest.addressOptionalRequest.postalCode = familyInformation.homeAddress.postalCode;
			}

			if (this.emergencyContactFeature) {
				this.quickAddFamilyMemberRequest.contactInformation.phoneInformation.preferredPhoneType = familyInformation.phoneNumbers.preferredPhoneType;
				this.quickAddFamilyMemberRequest.contactInformation.phoneInformation.homePhone = familyInformation.phoneNumbers.homePhone;
				this.quickAddFamilyMemberRequest.contactInformation.phoneInformation.homePhoneExtension = familyInformation.phoneNumbers.homePhoneExt;
				this.quickAddFamilyMemberRequest.contactInformation.phoneInformation.workPhone = familyInformation.phoneNumbers.workPhone;
				this.quickAddFamilyMemberRequest.contactInformation.phoneInformation.workPhoneExtension = familyInformation.phoneNumbers.workPhoneExt;
				this.quickAddFamilyMemberRequest.contactInformation.phoneInformation.cellPhone = familyInformation.phoneNumbers.cellPhone;
				this.quickAddFamilyMemberRequest.contactInformation.phoneInformation.cellPhoneExtension = familyInformation.phoneNumbers.cellPhoneExt;
				this.quickAddFamilyMemberRequest.contactInformation.phoneInformation.pager = familyInformation.phoneNumbers.pager;
				this.quickAddFamilyMemberRequest.contactInformation.email = familyInformation.email;
				this.quickAddFamilyMemberRequest.contactInformation.emailDeclined = familyInformation.emailDeclined;
				this.quickAddFamilyMemberRequest.isEmergencyContact = false;
				this.quickAddFamilyMemberRequest.isPrimaryEmergencyContact = false;
			}

			this.componentForm = this.gandalfFormBuilder.group(this.quickAddFamilyMemberRequest);

			if (!this.emergencyContactFeature) {
				this.componentForm.get('contactInformation').disable();
			}

			this.patientService.setRequiredValidatorPostalCode(this.componentForm.get('addressOptionalRequest') as UntypedFormGroup);
			this.handleFormState();
		});
	}

	handleFormState() {
		FormUtilsService.reactToValueChanges(this.componentForm, () => {
			this.pullForwardAddress();
		}, true);
	}

	pullForwardAddress() {
		FormUtilsService.setValueWhen(
			this.componentForm.get('addressOptionalRequest.addressLine1'),
			this.quickAddFamilyMemberRequest.addressOptionalRequest.addressLine1,
			this.componentForm.get('usePatientAddress').value);
		FormUtilsService.setValueWhen(
			this.componentForm.get('addressOptionalRequest.addressLine2'),
			this.quickAddFamilyMemberRequest.addressOptionalRequest.addressLine2,
			this.componentForm.get('usePatientAddress').value);
		FormUtilsService.setValueWhen(
			this.componentForm.get('addressOptionalRequest.city'),
			this.quickAddFamilyMemberRequest.addressOptionalRequest.city,
			this.componentForm.get('usePatientAddress').value);
		FormUtilsService.setValueWhen(
			this.componentForm.get('addressOptionalRequest.stateProvince'),
			this.quickAddFamilyMemberRequest.addressOptionalRequest.stateProvince,
			this.componentForm.get('usePatientAddress').value);
		if (this.hasNonStandardZipCodes) {
			FormUtilsService.setValueWhen(
				this.componentForm.get('addressOptionalRequest.nonStandardPostalCode'),
				this.quickAddFamilyMemberRequest.addressOptionalRequest.nonStandardPostalCode,
				this.componentForm.get('usePatientAddress').value);
		} else {
			FormUtilsService.setValueWhen(
				this.componentForm.get('addressOptionalRequest.postalCode'),
				this.quickAddFamilyMemberRequest.addressOptionalRequest.postalCode,
				this.componentForm.get('usePatientAddress').value);
		}
		FormUtilsService.disabledWhen(this.componentForm.get('addressOptionalRequest'), this.componentForm.get('usePatientAddress').value);
	}

	submitForm(event: any) {
		this.templateForm.onSubmit(event);
	}

	save() {
		if (this.componentForm.invalid) {
			return;
		}

		this.patientFamilyService.quickAddPatientFamilyMember(this.componentForm.getRawValue()).subscribe(patient => {
			this.eventService.publishUpdatePatientFamilyMembers(this.patientId);
			const formattedPatient = patient as FormattedQuickAddPatient;
			formattedPatient.formattedName = this.formattedNamePipe.transform(
				patient.firstName,
				patient.lastName,
			);

			this.closeModal(formattedPatient);
		});
	}

	closeModal(result?: FormattedQuickAddPatient) {
		this.ref.close(this.modal, result);
	}

}
