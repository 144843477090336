// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FieldValueMultiSelectionResponse } from './field-value-multi-selection-response';

/** See com.rev360.pms.api.controller.encounter.history.pfsh.FamilyHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class FamilyHistoryResponse extends GandalfModelBase {

	@GandalfProperty()
	comments: string;

	@GandalfProperty()
	eyeConditions: FieldValueMultiSelectionResponse;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	medicalConditions: FieldValueMultiSelectionResponse;

}
