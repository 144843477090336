import { Component } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';
import { PatientNameResponse } from '@gandalf/model/patient-name-response';

@Component({
	selector: 'pms-patient-popover-ag-grid',
	templateUrl: './patient-popover-ag-grid.component.html',
	styles: [
		`
			:host {
				position: absolute;
				transition: opacity 1s;
			}

			:host.ag-tooltip-hiding {
				display: none;
			}
		`,
	],
})
export class PatientPopoverAgGridComponent implements ITooltipAngularComp {
	patientName: PatientNameResponse;

	constructor() {}

	agInit(params: ITooltipParams): void {
		this.patientName = params.value;
	}
}
