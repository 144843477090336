import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { UrlService } from '@core/url-util/url.service';
import { PracticeFolderIdentifier } from '@gandalf/constants';
import { AsyncSettingsModel } from '@syncfusion/ej2-angular-inputs';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
	selector: 'pms-upload-document-to-folder-modal',
	templateUrl: './upload-document-to-folder-modal.component.html',
	styles: [],
})
export class UploadDocumentToFolderModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	fileUploadSettings: AsyncSettingsModel;

	folderId: PracticeFolderIdentifier;

	uploadUrl: string;
	extraUrlParams: string;

	constructor(
		public dynamicModalRef: DynamicModalRef,
		public modalConfig: ModalConfig,
		private urlService: UrlService,
		private securityManagerService: SecurityManagerService,
	) {
	}

	ngOnInit() {
		this.parseModalConfig(this.modalConfig.data);
		this.setFileUploadSettings();
	}

	parseModalConfig(data: any) {
		this.folderId = data.folderId;
		this.uploadUrl = data.uploadUrl;
		this.extraUrlParams = data.extraUrlParams;
	}

	onSuccess(event) {
		this.closeModal(event.e.target.response);
	}

	setFileUploadSettings() {
		let urlParams = '?' + this.urlService.getStrutsParameters(new Date());
		urlParams += `&practiceId=${this.securityManagerService.getUserSession().practiceId}`;
		urlParams += `&folderId=${this.folderId.value}`;
		urlParams += this.extraUrlParams;
		this.fileUploadSettings = {saveUrl: this.uploadUrl + urlParams};
	}

	/* istanbul ignore next: closeModal */
	closeModal(practiceFileId?) {
		this.dynamicModalRef.close(this.modal, practiceFileId);
	}

}
