import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[revEventStopPropagation]',
})
export class EventStopPropagationDirective {

	@HostListener('click', ['$event'])
	onClick(event: MouseEvent) {
		event.stopPropagation();
	}

	@HostListener('dblclick', ['$event'])
	onDblClick(event: MouseEvent) {
		event.stopPropagation();
	}

	@HostListener('keydown', ['$event'])
	onKeydown(event: KeyboardEvent) {
		event.stopPropagation();
	}

}
