import { _cloneDeep } from '@core/lodash/lodash';
import { RouteBuilderService } from '@core/router-utils/route-builder.service';
import { EncounterTabActions, EncounterTabActionTypes } from '@encounters-store/actions/encounter-tab.actions';
import { StatefulTabItem } from '@shared/directives/stateful-tab/stateful-tab-item';
import { StatefulTabType } from '@shared/directives/stateful-tab/stateful-tab-type';
import { StatefulTabItemTypeMap } from '@shared/directives/stateful-tab/stateful-tab.directive';
import { StatefulTabUtil } from '@shared/directives/stateful-tab/stateful-tab.util';

export type EncounterTabStateMap = StatefulTabItemTypeMap<EncounterTabState, EncounterTabSubType>;

export enum EncounterTabSubType {
	ENCOUNTER = 'encounter',
	APPOINTMENT = 'appointment',
}

export class EncounterTabState extends StatefulTabItem<EncounterTabSubType> {
	constructor(tabSubType: EncounterTabSubType, patientId: number, objectId: number, headerText: string, sortOrder: number) {
		super(
			getEncounterTabDefaultRoute(tabSubType, patientId, objectId),
			StatefulTabType.ENCOUNTERS_ENCOUNTER_TAB,
			{parentId: patientId, subType: tabSubType, headerText, sortOrder},
		);
	}
}

export const initialState: EncounterTabStateMap = new Map([
	[EncounterTabSubType.ENCOUNTER, new Map()],
	[EncounterTabSubType.APPOINTMENT, new Map()],
]) as EncounterTabStateMap;

export function reducer(state = initialState, action: EncounterTabActions): EncounterTabStateMap {
	switch (action.type) {

		case EncounterTabActionTypes.ADD_ENCOUNTER_TAB: {
			const encounterTabStateMap: EncounterTabStateMap = _cloneDeep(state);
			if (!encounterTabStateMap.get(action.payload.tabSubType).get(action.payload.objectId)) {
				const sortOrder = StatefulTabUtil.getNextSortOrder(encounterTabStateMap);
				encounterTabStateMap.get(action.payload.tabSubType).set(action.payload.objectId, new EncounterTabState(
					action.payload.tabSubType,
					action.payload.patientId,
					action.payload.objectId,
					action.payload.headerText,
					sortOrder,
				));
				return encounterTabStateMap;
			} else {
				return state;
			}
		}

		case EncounterTabActionTypes.REMOVE_ENCOUNTER_TAB: {
			const encounterTabStateMap: EncounterTabStateMap = _cloneDeep(state);
			encounterTabStateMap.get(action.payload.tabSubType).delete(action.payload.objectId);
			return encounterTabStateMap;
		}

		case EncounterTabActionTypes.UPDATE_ENCOUNTER_TAB_CURRENT_ROUTE: {
			const encounterTabStateMap: EncounterTabStateMap = _cloneDeep(state);
			const encounterTabState = encounterTabStateMap.get(action.payload.tabSubType).get(action.payload.objectId);
			// sometimes the encounter is routed away from before it's done loading
			// so check that the state is there before trying to apply current route to it
			if (encounterTabState) {
				encounterTabState.currentRoute = action.payload.currentRoute;
				encounterTabStateMap.get(action.payload.tabSubType).set(action.payload.objectId, encounterTabState);
			}
			return encounterTabStateMap;
		}

		default: {
			return state;
		}
	}
}

function getEncounterTabDefaultRoute(type: EncounterTabSubType, patientId: number, objectId: number) {
	switch (type) {
		case EncounterTabSubType.APPOINTMENT:
			return RouteBuilderService.getEncountersAppointmentRoute(patientId, objectId);
		case EncounterTabSubType.ENCOUNTER:
			return RouteBuilderService.getEncounterRoute(patientId, objectId);
	}
}
