<div class="margin-top-lg" [attr.data-test-id]="'cardLoaderModal'">
	<div [class.card_loader]="!isCardFormLoaded" [class.approving_loader]="isProcessing"></div>
	<div [hidden]="processPaymentDisabled()">
		<div class="oe-card">
			<div class="oe-card-number" [attr.data-test-id]="'cardLoaderCardNumberLabelGroup'">
				<label for="card-number" class="oe-card-label">Card Number</label>
				<div id="card-number"></div>
			</div>
			<div class="oe-card-data">
				<label for="card-expiration" class="oe-card-label oe-card-expiration">Expiration</label>
				<label for="card-cvv" class="oe-card-label oe-card-cvv">CVV</label>
				<div id="card-expiration" class="oe-card-expiration" [attr.data-test-id]="'cardLoaderOeCardExpirationValue'"></div>
				<div id="card-cvv" class="oe-card-cvv" [attr.data-test-id]="'cardLoaderOeCardCvvValue'"></div>
			</div>
			<!--submit iframe required for CC validation-->
			<div id="submit" [hidden]="true"></div>
		</div>
	</div>
</div>
