@for (iframe of iframeContainerService.iframes; track iframe) {
	@if (iframe.isEnabled) {
		<rev-loading-overlay [hidden]="!iframe.isActive" [loading]="!iframe.isLoaded">
			<div style="position: relative">
				@if (iframe.openInTab) {
					<a (click)="iframe.openInTab()" revTooltip [tooltipContent]="iframe.openInTabTooltip" style="position: absolute; top: 20px; right: 20px">
						<i class="fa fa-external-link fa-2x"></i>
					</a>
				}
				@if (iframe.url) {
					<iframe
						[hidden]="!iframe.isLoaded"
						[src]="iframe.url"
						(load)="iframe.loaded($any($event.target))"
						[height]="iframeHeight"
						style="width: 100%; border: 0"
						referrerpolicy="no-referrer"></iframe>
				}
			</div>
		</rev-loading-overlay>
	}
}
