// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { PracticeLocationSummaryResponse } from './practice-location-summary-response';

/** See com.rev360.pms.api.controller.reporting.accounting.ReceiptsReportResponse */
/* istanbul ignore next */
@GandalfModel
export class ReceiptsReportResponse extends GandalfModelBase {

	@GandalfProperty()
	amount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	cardType: constants.CreditCardType;

	@GandalfProperty({ propertyType: 'LocalDate' })
	groupPaymentDate: Date;

	@GandalfProperty()
	groupReferenceNum: string;

	@GandalfProperty()
	invoiceId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	method: constants.PaymentMethodType;

	@GandalfProperty()
	methodDetail: string;

	@GandalfProperty()
	patient: PatientNameResponse;

	@GandalfProperty()
	payerEntityId: number;

	@GandalfProperty()
	payerId: number;

	@GandalfProperty()
	payerName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	payerType: constants.PayerType;

	@GandalfProperty()
	paymentGroupId: number;

	@GandalfProperty()
	paymentId: number;

	@GandalfProperty()
	practiceLocation: PracticeLocationSummaryResponse;

	@GandalfProperty()
	provider: PersonNameResponse;

	@GandalfProperty()
	quantity: number;

}
